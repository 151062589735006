import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';

import {
  templatizeAndSanitizeHtml,
  getDraftTemplateAndMessagesFromBulk,
} from 'components/outreach/utils';
import { OUTREACH_ENDPOINT } from 'constants/apis';
import { CONTACTS_BASE_URL } from 'constants/constants';
import { OUTREACH_MESSAGE_STATUSES } from 'constants/outreach-message';
import { resetComposeForm } from 'reducers/outreach/compose-form';
import { getBulkDraftContacts } from 'reducers/outreach/mockData';
import { addBulkDraftToDraftListActionDispatcher } from 'reducers/outreach/outreach-drafts';
import {
  CLEAR_ERROR_MESSAGES,
  sendOutreachMessageFormTestActionCreator,
  sendOutreachMessagesActionCreator,
} from 'reducers/outreach/outreach-messages';
import {
  closeScheduleModalActionCreator,
  toggleScheduleModalErrorActionCreator,
} from 'reducers/outreach/outreach-schedule-modal';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { launchStoryByIdActionCreator } from 'reducers/stories/story-hub';
import { outreachComposeFormValuesSelector } from 'selectors/outreach';
import {
  convertDateTimeSelectionToUnix,
  unixTimeIsInFuture,
} from 'utils/times/times';

import {
  performGet as performMockGet,
  performDelete as performMockDelete,
  performPost as performMockPost,
} from './mockEndpoints';

export const ADD_BULK_OUTREACH_CONTEXT =
  'bulk-outreach/ADD_BULK_OUTREACH_CONTEXT';
export const UPDATE_TEMPLATE_FIELDS = 'bulk-outreach/UPDATE_TEMPLATE_FIELDS';
export const GET_BULK_DRAFT = 'bulk-outreach/GET_BULK_DRAFT';
export const GET_BULK_DRAFT_SUCCESS = 'bulk-outreach/GET_BULK_DRAFT_SUCCESS';
export const GET_BULK_DRAFT_ERROR = 'bulk-outreach/GET_BULK_DRAFT_ERROR';
export const SAVE_BULK_DRAFT = 'bulk-outreach/SAVE_BULK_DRAFT';
export const SAVE_BULK_DRAFT_ERROR = 'bulk-outreach/SAVE_BULK_DRAFT_ERROR';
export const SAVE_BULK_DRAFT_SUCCESS = 'bulk-outreach/SAVE_BULK_DRAFT_SUCCESS';
export const SET_BULK_MESSAGE_SUBSTITUTIONS =
  'bulk-outreach/SET_BULK_MESSAGE_SUBSTITUTIONS';
export const SET_BULK_MESSAGE_CONTACTS =
  'bulk-outreach/SET_BULK_MESSAGE_CONTACTS';
export const SEND_BULK_MESSAGES = 'bulk-outreach/SEND_BULK_MESSAGES';
export const SEND_BULK_MESSAGES_SUCCESS =
  'bulk-outreach/SEND_BULK_MESSAGES_SUCCESS';
export const SEND_BULK_MESSAGES_ERROR =
  'bulk-outreach/SEND_BULK_MESSAGES_ERROR';
export const SET_BULK_SEND_STEP = 'bulk-outreach/SET_BULK_SEND_STEP';
export const UPDATE_MESSAGE_FOR_CONTACT =
  'bulk-outreach/UPDATE_MESSAGE_FOR_CONTACT';
export const UNSET_BULK_MESSAGE_CONTACTS =
  'bulk-outreach/UNSET_BULK_MESSAGE_CONTACTS';
export const RESET_BULK_SEND_ERROR = 'bulk-outreach/RESET_BULK_SEND_ERROR';
export const RESET_TEMPLATE_FIELDS = 'bulk-outreach/RESET_TEMPLATE_FIELDS';
export const RESET_PERSONALIZATIONS = 'bulk-outreach/RESET_PERSONALIZATIONS';

export const SAVE_BULK_SCHEDULE = 'bulk-outreach/SAVE_BULK_SCHEDULE';
export const SAVE_BULK_SCHEDULE_ERROR =
  'bulk-outreach/SAVE_BULK_SCHEDULE_ERROR';
export const SAVE_BULK_SCHEDULE_SUCCESS =
  'bulk-outreach/SAVE_BULK_SCHEDULE_SUCCESS';
export const REMOVE_BULK_SCHEDULE = 'bulk-outreach/REMOVE_BULK_SCHEDULE';
export const REMOVE_BULK_SCHEDULE_ERROR =
  'bulk-outreach/REMOVE_BULK_SCHEDULE_ERROR';
export const REMOVE_BULK_SCHEDULE_SUCCESS =
  'bulk-outreach/REMOVE_BULK_SCHEDULE_SUCCESS';

export const BULK_SEND_STEPS = {
  loading: 'LOADING',
  compose: 'COMPOSE',
  personalize: 'PERSONALIZE',
  success: 'SUCCESS',
};

const initialState = {
  id: null,
  loadError: false,
  loadSuccess: false,
  error: '',
  contacts: [
    // Should be array of objects with this shape:
    // {
    //   contactType: OUTREACH_CONTACT_TYPES.author,
    //   emails: <array of strings>,
    //   id: <int>,
    //   name: <string>,
    //   substitutions: <object>
    // }
  ],
  templateFields: {
    from: null,
    to: [],
    cc: [],
    bcc: [],
    campaigns: [],
    subject: '',
    body: '',
    replyToMessageId: '',
    stories: [],
    contexts: [],
    attachments: [],
  },
  substitutions: {},
  context: {},
  customMessagesByContactIds: {
    // 1032: {
    //   subject: 'This is a different subject',
    // },
  },
  sendingMessages: false,
  step: BULK_SEND_STEPS.compose,
  // Schedule
  deletingBulkScheduleError: null,
  savingSchedule: false,
  savingScheduleError: null,
  schedule: null,
};

const outreachBulkSendReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BULK_OUTREACH_CONTEXT:
      return {
        ...state,
        context: {
          ...state.context,
          ...action.payload,
        },
      };
    case RESET_BULK_SEND_ERROR:
      return {
        ...state,
        error: false,
      };
    case RESET_TEMPLATE_FIELDS:
      return {
        ...state,
        id: null,
        templateFields: initialState.templateFields,
        sendingMessages: false,
        step: BULK_SEND_STEPS.compose,
        context: {},
        substitutions: {},
      };
    case SET_BULK_MESSAGE_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case SET_BULK_MESSAGE_SUBSTITUTIONS:
      return {
        ...state,
        substitutions: {
          ...action.payload,
        },
      };
    case SEND_BULK_MESSAGES:
      return {
        ...state,
        sendingMessages: true,
        error: false,
      };
    case SEND_BULK_MESSAGES_SUCCESS:
      return {
        ...state,
        sendingMessages: false,
        error: false,
      };
    case SEND_BULK_MESSAGES_ERROR:
      return {
        ...state,
        sendingMessages: false,
        error: action.payload,
      };
    case SET_BULK_SEND_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case RESET_PERSONALIZATIONS:
      return {
        ...state,
        customMessagesByContactIds: {},
      };
    case UNSET_BULK_MESSAGE_CONTACTS:
      return {
        ...state,
        customMessagesByContactIds: {},
        contacts: [],
      };
    case UPDATE_MESSAGE_FOR_CONTACT:
      return {
        ...state,
        customMessagesByContactIds: {
          ...state.customMessagesByContactIds,
          [action.payload.contactId]: {
            ...state.customMessagesByContactIds[action.payload.contactId],
            ...action.payload.updates,
          },
        },
      };
    case UPDATE_TEMPLATE_FIELDS:
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          ...action.payload,
        },
      };
    case SAVE_BULK_DRAFT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_BULK_DRAFT:
      return {
        ...state,
        loadSuccess: false,
        loadError: false,
        step: BULK_SEND_STEPS.loading,
      };
    case GET_BULK_DRAFT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadSuccess: true,
        loadError: false,
      };
    case GET_BULK_DRAFT_ERROR:
      return {
        ...state,
        loadSuccess: false,
        loadError: true,
      };

    case SAVE_BULK_SCHEDULE: {
      return {
        ...state,
        savingSchedule: true,
        savingScheduleError: null,
      };
    }
    case SAVE_BULK_SCHEDULE_ERROR: {
      return {
        ...state,
        savingSchedule: false,
        savingScheduleError: action.payload,
      };
    }
    case SAVE_BULK_SCHEDULE_SUCCESS: {
      const schedule = {
        timestamp: action.payload.timestamp,
        timezone: action.payload.timezone,
      };
      return {
        ...state,
        savingSchedule: false,
        savingScheduleError: null,
        schedule,
      };
    }
    case REMOVE_BULK_SCHEDULE: {
      return {
        ...state,
        deletingBulkScheduleError: null,
      };
    }
    case REMOVE_BULK_SCHEDULE_ERROR: {
      return {
        ...state,
        deletingBulkScheduleError: action.payload,
      };
    }
    case REMOVE_BULK_SCHEDULE_SUCCESS: {
      return {
        ...state,
        deletingBulkScheduleError: null,
        schedule: null,
      };
    }
    default:
      return state;
  }
};

/* Function for saving bulk schedule - dummy method for now */
export const saveBulkOutreachScheduleActionCreator = (
  id,
  params,
) => async () => {
  return performMockPost(`${OUTREACH_ENDPOINT}/bulk/schedule/${id}`, params);
};

/* Function for deleting bulk schedule - dummy method for now */
const removeBulkoutreachScheduleActionCreator = id => async () => {
  return performMockDelete(`${OUTREACH_ENDPOINT}/bulk/schedule/${id}`);
};

export const saveBulkOutreachScheduleActionDispatcher = (
  date,
  time,
  timezone,
  id,
) => async dispatch => {
  dispatch({ type: SAVE_BULK_SCHEDULE });
  const tz = timezone.zone;
  const timestamp = convertDateTimeSelectionToUnix(date, time, tz);

  // we check for this in UI but added check for selecting date/time in past
  if (!unixTimeIsInFuture(timestamp)) {
    dispatch({
      type: SAVE_BULK_SCHEDULE_ERROR,
      payload: 'Cannot select time in past',
    });
    return dispatch(toggleScheduleModalErrorActionCreator());
  }

  const params = {
    timestamp,
    timezone: timezone.zone,
    bulkId: id,
  };

  return dispatch(saveBulkOutreachScheduleActionCreator(id, params))
    .then(() => {
      const payload = params;
      dispatch({ type: SAVE_BULK_SCHEDULE_SUCCESS, payload });
      dispatch(closeScheduleModalActionCreator());
    })
    .catch(error => {
      dispatch({ type: SAVE_BULK_SCHEDULE_ERROR, payload: error.message });
      dispatch(toggleScheduleModalErrorActionCreator());
    });
};

export const removeBulkOutreachScheduleActionDispatcher = draftId => async dispatch => {
  dispatch({ type: REMOVE_BULK_SCHEDULE });
  return dispatch(removeBulkoutreachScheduleActionCreator(draftId))
    .then(() => {
      dispatch({ type: REMOVE_BULK_SCHEDULE_SUCCESS, payload: draftId });
      dispatch(closeScheduleModalActionCreator());
    })
    .catch(error => {
      dispatch({ type: REMOVE_BULK_SCHEDULE_ERROR, payload: error });
      return dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Sorry, your schedule could not be removed. Please try again.',
          status: 'danger',
        }),
      );
    });
};

export const getBulkDraftByIdActionCreator = () => async dispatch => {
  dispatch({ type: GET_BULK_DRAFT });
  const { data } = await performMockGet(`${OUTREACH_ENDPOINT}/bulk`); // TODO: /${bulkId}

  // TODO: fetch contacts w/ images
  const contacts = data.drafts.map(draft => ({
    id: draft.contact.id,
    fullName: draft.contact.name,
    publications: ['placeholder'],
    contacts: [draft.contact.email],
    emails: [draft.contact.email],
    image: {
      url: '',
      loading: false,
    },
    substitutions: draft.contact.substitutions,
  }));

  const personalizedDrafts = data.drafts.filter(draft => draft.personalized);
  const customMessagesByContactIds = keyBy(
    personalizedDrafts,
    draft => draft.contact.id,
  );
  const context = {
    successfulSendRedirect: `${CONTACTS_BASE_URL}/messages/${
      data.schedule ? 'scheduled' : 'drafts'
    }`,
  };

  dispatch({
    type: GET_BULK_DRAFT_SUCCESS,
    payload: {
      customMessagesByContactIds,
      contacts,
      context,
      id: data.id,
      templateFields: pick(data.template, [
        'from',
        'to',
        'cc',
        'bcc',
        'campaigns',
        'subject',
        'body',
        'replyToMessageId',
        'stories',
        'contexts',
        'attachments',
        'schedule',
      ]),
    },
  });
};

const setTemplateActionCreator = (
  templateFields,
  bulkOutreachContext = {},
  substitutions = {},
) => dispatch => {
  dispatch(resetComposeForm());
  dispatch({ type: RESET_TEMPLATE_FIELDS });
  dispatch({ type: UPDATE_TEMPLATE_FIELDS, payload: templateFields });
  dispatch({ type: ADD_BULK_OUTREACH_CONTEXT, payload: bulkOutreachContext });
  dispatch({ type: SET_BULK_MESSAGE_SUBSTITUTIONS, payload: substitutions });
};

export const setTemplateContextActionCreator = context => dispatch => {
  dispatch({ type: ADD_BULK_OUTREACH_CONTEXT, payload: context });
};

export const setBulkOutreachContactsActionCreator = (
  contacts,
  hasMockData = false,
) => dispatch => {
  const bulkSendContacts = hasMockData
    ? getBulkDraftContacts()
    : contacts
        ?.filter(
          contact =>
            (!!contact.id || !!contact.contactId || !!contact.influencerId) &&
            !!contact.emails,
        )
        .map(c => ({
          ...c,
          id: c.id ?? c.contactId ?? c.influencerId, // We are supporting connect influencers and pinpoint contacts [Bugfix EVER-12648]
        }));

  dispatch({ type: SET_BULK_MESSAGE_CONTACTS, payload: bulkSendContacts });
};

export const setBulkSendStepActionCreator = step => ({
  type: SET_BULK_SEND_STEP,
  payload: step,
});

export const sendBulkMessagesActionDispatcher = preppedMessages => async dispatch => {
  dispatch({ type: SEND_BULK_MESSAGES });
  dispatch(sendOutreachMessagesActionCreator(preppedMessages))
    .then(() => {
      dispatch({ type: SEND_BULK_MESSAGES_SUCCESS });
    })
    .catch(error => {
      dispatch({ type: SEND_BULK_MESSAGES_ERROR, payload: error });
    });
};

export const retryBulkSendDispatcher = preppedMessages =>
  sendBulkMessagesActionDispatcher(preppedMessages);

export const saveCurrentBulkAsDraftActionDispatcher = () => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const bulkInfo = state.outreachBulkSend;

  const { messages, template } = getDraftTemplateAndMessagesFromBulk(bulkInfo);

  dispatch({ type: SAVE_BULK_DRAFT });

  const { data } = await performMockPost(`${OUTREACH_ENDPOINT}/bulk`, {
    id: bulkInfo.id,
    drafts: messages,
    template,
  });

  dispatch({
    type: SAVE_BULK_DRAFT_SUCCESS,
    payload: data,
  });
  dispatch(addBulkDraftToDraftListActionDispatcher(data));
};

export const updateTemplateFromComposeForm = (saveDraft = false) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const formValues = outreachComposeFormValuesSelector(state);
  const payload = {
    bcc: formValues.bcc,
    body: formValues.body,
    campaigns: formValues.campaigns,
    cc: formValues.cc,
    from: formValues.from,
    subject: formValues.subject,
    attachments: formValues.attachments,
  };
  dispatch({
    type: UPDATE_TEMPLATE_FIELDS,
    payload,
  });

  if (saveDraft) {
    dispatch(saveCurrentBulkAsDraftActionDispatcher());
  }
};

export const updateMessageFieldsForContactId = (
  contactId,
  updates,
  saveOnUpdate = false,
) => dispatch => {
  dispatch({
    type: UPDATE_MESSAGE_FOR_CONTACT,
    payload: { contactId, updates },
  });
  if (saveOnUpdate) {
    dispatch(saveCurrentBulkAsDraftActionDispatcher());
  }
};

export const sendAndPublishActionDispatcher = () => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const bulkInfo = state.outreachBulkSend;

  const {
    messages: preppedMessages,
    messageStoryId,
  } = getDraftTemplateAndMessagesFromBulk(bulkInfo);

  if (messageStoryId) {
    dispatch(launchStoryByIdActionCreator(messageStoryId))
      .then(() => {
        dispatch(sendBulkMessagesActionDispatcher(preppedMessages));
      })
      .catch(error => {
        dispatch({ type: SEND_BULK_MESSAGES_ERROR, payload: error.message });
      });
  } else {
    dispatch(sendBulkMessagesActionDispatcher(preppedMessages));
  }
};

export const sendTestPersonalizedMessageActionCreator = contactId => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const bulkInfo = state.outreachBulkSend;
  const { contacts, templateFields, customMessagesByContactIds } = bulkInfo;

  const contact = contacts.find(c => c.id === contactId);

  const messageFields = {
    ...templateFields,
    ...customMessagesByContactIds[contact.id],
  };

  const message = {
    ...messageFields,
    contexts: [
      {
        contextType: 'recipientId',
        id: contact.recipientId,
      },
    ],
    body: templatizeAndSanitizeHtml(messageFields.body, {
      ...templateFields.substitutions,
      ...contact.substitutions,
    }),
    status: OUTREACH_MESSAGE_STATUSES.scheduled,
    stories: [],
    to: [messageFields.from],
  };

  dispatch(sendOutreachMessageFormTestActionCreator(message));
};

export const clearSendErrorActionDispatcher = () => dispatch => {
  dispatch({ type: RESET_BULK_SEND_ERROR });
  dispatch({ type: CLEAR_ERROR_MESSAGES });
};

export const clearPersonalizationsActionDispatcher = () => ({
  type: RESET_PERSONALIZATIONS,
});

export const clearTemplateAndContacts = () => dispatch => {
  dispatch(resetComposeForm());
  dispatch({ type: UNSET_BULK_MESSAGE_CONTACTS });
  dispatch({ type: RESET_TEMPLATE_FIELDS });
};

export const createBulkDraftActionCreator = ({
  contacts,
  template,
  context = {},
  hasMockData = false,
  substitutions = {},
  saveDraft = true,
}) => async dispatch => {
  if (!saveDraft) {
    dispatch(setBulkOutreachContactsActionCreator(contacts, hasMockData));
    return dispatch(setTemplateActionCreator(template, context, substitutions));
  }

  dispatch({ type: SAVE_BULK_DRAFT });
  const drafts = contacts.map(c => ({
    ...template,
    to: c.emails.map(e => ({
      contactType: c.contactType,
      email: e,
      id: c.id,
      name: c.fullName,
    })),
  }));

  return performMockPost(`${OUTREACH_ENDPOINT}/bulk`, {
    drafts,
    template,
  }).then(response => {
    dispatch({ type: SAVE_BULK_DRAFT_SUCCESS, payload: response.data });
    dispatch(
      setTemplateActionCreator(
        { ...template, id: response.data.id },
        context,
        substitutions,
      ),
    );
    dispatch(setBulkOutreachContactsActionCreator(contacts));
  });
};

export default outreachBulkSendReducer;
