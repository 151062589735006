export default {
  createSearch: {
    id: 'SEARCH_CREATE_SEARCH',
    description: 'Label for the create search button',
    defaultMessage: 'Create a Search',
  },
  newSearch: {
    id: 'SEARCH_NEW_SEARCH',
    description: 'Label for the new search button',
    defaultMessage: 'New Search',
  },
  emptyStateTitle: {
    id: 'SEARCH_EMPTY_STATE_TITLE',
    description: "Empty state title when users don't have searches",
    defaultMessage: "You don't have any searches.",
  },
  emptyStateText: {
    id: 'SEARCH_EMPTY_STATE_TEXT',
    description: "Empty state text when users don't have searches",
    defaultMessage:
      "You don't have any saved or shared searches. Create one for your company, organization, or client to get started.",
  },
  successfulCategoryUpdate: {
    id: 'SEARCHES_SUCCESSFUL_CATEGORY_UPDATE',
    description:
      'Kite message to point out that the category filter was successfully applied',
    defaultMessage: 'Successfully updated search category.',
  },
  unsuccessfulCategoryUpdate: {
    id: 'SEARCHES_UNSUCCESSFUL_CATEGORY_UPDATE',
    description:
      'Kite message to point out that there was an error updating a search category',
    defaultMessage: 'Failed to update search category',
  },
  searchDuplicatedSuccess: {
    id: 'SEARCHES_SUCCESSFUL_DUPLICATE',
    description:
      'Kite message to point out that a search was successfully duplicated',
    defaultMessage: 'Successfully duplicated search.',
  },
  searchDuplicatedError: {
    id: 'SEARCHES_UNSUCCESSFUL_DUPLICATE',
    description:
      'Kite message to point out that there was an error duplicating a search',
    defaultMessage: 'Failed to duplicate search.',
  },
  searchDeleteSuccess: {
    id: 'SEARCHES_SUCCESSFUL_DELETE',
    description:
      'Kite message to point out that a search was successfully deleted',
    defaultMessage: 'Successfully deleted search.',
  },
  searchDeleteError: {
    id: 'SEARCHES_UNSUCCESSFUL_DELETE',
    description:
      'Kite message to point out that there was an error deleting a search',
    defaultMessage: 'Failed to delete search.',
  },
  successfulSearchAddedToCampaign: {
    id: 'SEARCHES_ADD_CAMPAIGN_SUCCESS',
    description:
      'Kite message to point out that a search was added to a campaign',
    defaultMessage: 'Successfully add search to campaign.',
  },
  unsuccessfulSearchAddedToCampaign: {
    id: 'SEARCHES_ADD_CAMPAIGN_ERROR',
    description:
      'Kite message to point out that there was an error adding a search to a campaign',
    defaultMessage: 'Failed to add search to campaign',
  },
  colorUpdateSuccess: {
    id: 'SEARCHES_COLOR_UPDATE_SUCCESS',
    description:
      'Kite message to point out that the search color was successfully updated',
    defaultMessage: 'Default search color updated.',
  },
  colorUpdateError: {
    id: 'SEARCHES_COLOR_UPDATE_ERROR',
    description:
      'Kite message to point out that there was an error updating the search color',
    defaultMessage: 'Failed to update search color',
  },
  errorGettingSystemSearches: {
    id: 'SEARCHES_GETTING_SYSTEM_SEARCHES_ERROR',
    description:
      'Kite message to point out that there was an error retrieving system searches',
    defaultMessage: 'Failed to retrieve system searches',
  },
  errorGettingSystemSearchesPubStatus: {
    id: 'SEARCHES_GETTING_SYSTEM_SEARCHES_PUB_STATUS_ERROR',
    description:
      'Kite message to point out that there was an error retrieving system searches pub status',
    defaultMessage:
      'Failed to retrieve the publications status option of the system searches',
  },
  viewOnlyPermissionStatus: {
    id: 'SEARCHES_VIEW_ONLY_PERMISSION_STATUS',
    description: 'View only status for searches',
    defaultMessage: 'View-Only',
  },
  fullAccessPermissionStatus: {
    id: 'SEARCHES_FULL_ACCESS_PERMISSION_STATUS',
    description: 'Full access status for searches',
    defaultMessage: 'Full-Access',
  },
  updateSearchChangePermissionsSuccess: {
    id: 'SEARCHES_UPDATE_SHARE_PERMISSIONS_SUCCESS',
    description:
      'Kite message to point out that the sharing permissions of a search were successfully updated',
    defaultMessage:
      'Successfully changed shared search permissions to: {searchPermission}',
  },
  updateSearchChangePermissionsError: {
    id: 'SEARCHES_UPDATE_SHARE_PERMISSIONS_ERROR',
    description:
      'Kite message to point out that there was an error changing the  permissions of a search',
    defaultMessage: 'Failed to change shared search permissions.',
  },
  shareSearchSuccess: {
    id: 'SEARCHES_SEARCH_SHARED_SUCCESS',
    description:
      'Kite message to point out that a search was successfully shared',
    defaultMessage: 'Successfully shared search.',
  },
  shareSearchError: {
    id: 'SEARCHES_SEARCH_SHARED_ERROR',
    description:
      'Kite message to point out that there was an error sharing a search',
    defaultMessage: 'Failed to share search.',
  },
  spikeAlertTurnedOnSuccess: {
    id: 'SEARCHES_SPIKE_ALERT_TURNED_ON_SUCCESS',
    description:
      'Kite message to point out that spike alerts were successfully enabled for a search',
    defaultMessage: 'Spike alerts successfully turned {status} ',
  },
  spikeAlertTurnedOnError: {
    id: 'SEARCHES_SPIKE_ALERT_TURNED_ON_ERROR',
    description:
      'Kite message to point out that there was an error enabling spike alerts for a search',
    defaultMessage: 'Failed to turn spike alerts {status}',
  },
};
