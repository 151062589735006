import React, { Component } from 'react';

import { Pill as RoverPill, Icon } from '@cision/rover-ui';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../loader';

class Pill extends Component {
  static baseClass = 'tk-pill';

  deleteTag = () => {
    if (!this.props.id) {
      return;
    }

    this.props.deletePillHandler(this.props.id);
  };

  render() {
    const {
      className,
      color,
      includeMargin,
      inverseColors,
      isDeleting,
      removable,
      name,
      style,
      onClick,
    } = this.props;
    const mainClass = classnames(
      Pill.baseClass,
      className,
      `${Pill.baseClass}--color-${color}${(inverseColors && '-inverse') || ''}`,
      {
        [`${Pill.baseClass}--include-margin`]: includeMargin,
      },
    );

    let deleteElementContent = (
      <button
        data-qa="l7UdMVaz_yK96GPZlRhDg"
        className={`${Pill.baseClass}__delete-button`}
        onClick={this.deleteTag}
        type="button"
      >
        <Icon name="clear" />
      </button>
    );

    if (isDeleting) {
      deleteElementContent = (
        <div
          className={`${Pill.baseClass}__delete-loading-indicator-container`}
        >
          <Loader size="tiny" />
        </div>
      );
    }

    return (
      <RoverPill
        data-qa="CrRUnMDVQjNGuc-ccPgpi"
        className={mainClass}
        style={style}
        onClick={onClick}
      >
        <span className={`${Pill.baseClass}__name`}>{name}</span>
        {removable && (
          <RoverPill.Addon className={`${Pill.baseClass}__delete-container`}>
            {removable && deleteElementContent}
          </RoverPill.Addon>
        )}
      </RoverPill>
    );
  }
}

Pill.propTypes = {
  /** classNames to append to component */
  className: PropTypes.string,
  /** color of the pill */
  color: PropTypes.oneOf(['default', 'danger', 'warning', 'notify']),
  /** handler when delete "x" is clicked
   * handler has one argument (the id prop)
   * need removable prop = true,
   * need an id prop
   */
  deletePillHandler: PropTypes.func,
  /** a unique id to use in delete handler */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** add 5px margins to right and bottom */
  includeMargin: PropTypes.bool,
  /** inverse the colors so pill is no longer solid */
  inverseColors: PropTypes.bool,
  /** set to true to show loader instead of delete button */
  isDeleting: PropTypes.bool,
  /** text in pill */
  name: PropTypes.node.isRequired,
  /** should include delete button or not */
  removable: PropTypes.bool,
  /** function triggered when a Pill gets clicked */
  onClick: PropTypes.func,
  /** styles to add to pill */
  style: PropTypes.object,
};

Pill.defaultProps = {
  className: '',
  color: 'default',
  deletePillHandler: () => {},
  id: null,
  includeMargin: true,
  inverseColors: false,
  isDeleting: false,
  removable: true,
  onClick: () => {},
  style: {},
};

export default Pill;
