import { ASSET_POPUP_GET_STORY_ENDPOINT } from 'constants/apis';

import React, { Component } from 'react';

import classNames from 'classnames';
import TranslatedMessage from 'i18n/TranslatedMessage';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import { Control } from 'react-redux-form';

import { Button, InputFile, Progress, SvgIcon, Type } from '@trendkite/ui';

import BrowseModalUpload from '../browse-asset-library/BrowseModalUpload';

// i18n

import {
  logoDefaultProps,
  logoPropTypes,
} from '../story/templates/default-template';

import messages from './StoryLogo.messages';

import StoryLogoDisplay from './StoryLogoDisplay';
import StoryLogoToolbar from './StoryLogoToolbar';

class StoryLogo extends Component {
  static baseClass = 'tk-story-logo';

  state = {
    toolbarOpen: false,
    lastImgHeight: 0,
    logoSize: this.props.logoSize,
    logoSrc: this.props.logoSrc,
    uploadError: false,
    uploading: false,
    uploadProgress: null,
    browseAssetComponent: false,
    isFromStoryKit: true,
    showOtherUploadOptions: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    const propsToCheck = ['logoSize', 'logoSrc'];
    if (
      !isEqual(pick(this.props, propsToCheck), pick(nextProps, propsToCheck))
    ) {
      this.setState(() => ({
        logoSize: nextProps.logoSize,
        logoSrc: nextProps.logoSrc,
      }));
    }
  }

  makeBrowseAssetComponentFalse = () => {
    this.setState({ browseAssetComponent: false });
  };

  handleSelectedFilesfromPopUp = info => {
    const logoFormData = new FormData();
    logoFormData.append('id', info.id);
    logoFormData.append('type', 'ms-story-hero');
    logoFormData.append('isTK', info.isTK);
    this.setState(() => ({ uploading: true, uploadError: false }));
    this.uploadRequest.addEventListener(
      'readystatechange',
      this.onRequestStateChange,
    );
    this.uploadRequest.upload.addEventListener(
      'progress',
      this.onUploadProgress,
      false,
    );
    this.uploadRequest.open('POST', `${ASSET_POPUP_GET_STORY_ENDPOINT}`, true);
    this.uploadRequest.send(logoFormData);
    this.setState(() => ({ browseAssetComponent: false }));
  };

  onSelectedFile = files => {
    const { logoUploadPath } = this.props;
    const logoFormData = new FormData();

    const file = files.length === 1 ? files[0] : null;

    if (!file || !logoUploadPath) {
      return;
    }

    this.setState(() => ({ uploading: true, uploadError: false }));

    logoFormData.append('image', file);
    logoFormData.append('type', 'story');
    logoFormData.append('maxSize', 480);
    this.uploadRequest.addEventListener(
      'readystatechange',
      this.onRequestStateChange,
    );
    this.uploadRequest.upload.addEventListener(
      'progress',
      this.onUploadProgress,
      false,
    );
    this.uploadRequest.open('POST', `${logoUploadPath}`, true);
    this.uploadRequest.send(logoFormData);
  };

  onChangeFileInput = event => {
    const files = (event && event.target && event.target.files) || {};
    this.onSelectedFile(files);
  };

  onChangeLogo = (logoSize, logoSrc) => {
    const { dispatchMergeFormAction } = this.props;
    this.setState(() => ({ toolbarOpen: false }));
    dispatchMergeFormAction('storyContentForm', { logoSize, logoSrc });
  };

  onRequestStateChange = event => {
    const { logoSize } = this.state;
    let { logoSrc } = this.state;

    if (this.uploadRequest.readyState !== 4) {
      return;
    }

    this.uploadRequest.removeEventListener(
      'readystatechange',
      this.onRequestStateChange,
    );
    this.uploadRequest.upload.removeEventListener(
      'progress',
      this.onUploadProgress,
    );

    if (this.uploadRequest.status === 200) {
      const logoJson = JSON.parse(event.target.response);
      logoSrc = logoJson.logo || null;
    }

    if (!logoSrc) {
      this.setState(() => ({ uploading: false, uploadError: true }));
    }

    this.setState(() => ({ logoSrc, uploading: false, uploadError: false }));
    this.onChangeLogo(logoSize, logoSrc);
  };

  onUploadProgress = progress => {
    const { loaded, total } = progress;
    const uploadProgressPercent =
      typeof loaded === 'number' && typeof total === 'number' && total !== 0
        ? Math.round(100 * (loaded / total))
        : null;
    this.setState(() => ({ uploadProgress: uploadProgressPercent }));
  };

  onClickDisplay = () => {
    this.setState(state => ({ toolbarOpen: !state.toolbarOpen }));
  };

  replaceWithAssetLibrary = () => {
    this.setState(() => ({ browseAssetComponent: true }));
  };

  onKeyPressDisplay = event => {
    if (typeof event.which === 'number' && event.which === 13) {
      this.onClickDisplay();
    }
  };

  onSetImgDimensions = ({ imgHeight }) => {
    this.setState(() => ({ lastImgHeight: imgHeight }));
  };

  onClickUploadButton = () => {
    if (this.uploadLabel && typeof this.uploadLabel.click === 'function') {
      this.uploadLabel.click();
    }
  };

  setSize = logoSize => {
    const { logoSrc } = this.state;
    this.setState(() => ({ logoSize }));
    this.onChangeLogo(logoSize, logoSrc);
  };

  setSizeSm = () => {
    this.setSize('sm');
  };

  setSizeMd = () => {
    this.setSize('md');
  };

  setSizeLg = () => {
    this.setSize('lg');
  };

  removeLogo = () => {
    const { logoSize, logoSrc } = logoDefaultProps;
    this.setState(() => ({ logoSize, logoSrc }));
    this.onChangeLogo(logoSize, logoSrc);
  };

  handleClickOutside() {
    this.setState(() => ({ toolbarOpen: false }));
  }

  uploadRequest = new XMLHttpRequest();

  renderDisplayImg = () => {
    const { isEditable } = this.props;
    const {
      lastImgHeight,
      logoSize,
      logoSrc,
      toolbarOpen,
      uploading,
      browseAssetComponent,
      showOtherUploadOptions,
      isFromStoryKit,
    } = this.state;

    const imgClass = classNames(`${StoryLogo.baseClass}__img`, {
      [`${StoryLogo.baseClass}__img--editing`]: toolbarOpen,
      [`${StoryLogo.baseClass}__img--editable`]: isEditable,
    });

    if (!logoSrc || uploading) {
      return null;
    }

    return (
      <div
        data-qa="QPTpKg5EIUSJ_s5-1POaR"
        className={imgClass}
        onClick={this.onClickDisplay}
        onKeyPress={this.onKeyPressDisplay}
        role="button"
        tabIndex="0"
      >
        <StoryLogoDisplay
          isEditable
          lastHeight={lastImgHeight}
          onSetImgDimensions={this.onSetImgDimensions}
          logoSize={logoSize}
          logoSrc={logoSrc}
          browseAssetComponent={browseAssetComponent}
          showOtherUploadOptions={showOtherUploadOptions}
          handleSelectedFilesfromPopUp={this.handleSelectedFilesfromPopUp}
          makeBrowseAssetComponentFalse={this.makeBrowseAssetComponentFalse}
          onSelectFiles={this.onSelectedFile}
          isFromStoryKit={isFromStoryKit}
        />
      </div>
    );
  };

  renderStoryLogoToolbar = () => {
    const { isEditable, browseLink } = this.props;
    const { logoSize, logoSrc, toolbarOpen, uploading } = this.state;

    if (!logoSrc || uploading || !isEditable) {
      return null;
    }

    return (
      <StoryLogoToolbar
        logoSize={logoSize}
        removeLogo={this.removeLogo}
        setSizeSm={this.setSizeSm}
        setSizeMd={this.setSizeMd}
        setSizeLg={this.setSizeLg}
        toolbarOpen={toolbarOpen}
        browseLink={browseLink}
        replaceWithAssetLibrary={this.replaceWithAssetLibrary}
      />
    );
  };

  renderPlaceholder = () => {
    const { isEditable, browseLink } = this.props;
    const {
      logoSrc,
      uploading,
      uploadProgress,
      isFromStoryKit,
      showRestUploadOptions,
    } = this.state;

    if (logoSrc || !isEditable) {
      return null;
    }

    const placeholderClass = classNames(`${StoryLogo.baseClass}__placeholder`, {
      [`${StoryLogo.baseClass}__placeholder--clickable`]: !uploading,
    });

    return (
      <label
        className={placeholderClass}
        htmlFor={browseLink ? '' : 'logo-input-file'}
        ref={uploadLabel => {
          this.uploadLabel = uploadLabel;
        }}
      >
        <div className={`${StoryLogo.baseClass}__placeholder-label`}>
          <Type color="light" size="lg">
            <TranslatedMessage {...messages.companyLogo} />
          </Type>
        </div>
        {uploading && (
          <div className={`${StoryLogo.baseClass}__placeholder-loading`}>
            <Progress modifiers={['small']} value={uploadProgress / 100} />
            <div className={`${StoryLogo.baseClass}__progress-label`}>
              {typeof uploadProgress === 'number' && (
                <span>
                  <TranslatedMessage
                    {...messages.uploadProgess}
                    values={{ PROGRESS: uploadProgress || 0 }}
                  />
                </span>
              )}
              {typeof uploadProgress !== 'number' && (
                <span>
                  <TranslatedMessage {...messages.undefinedUploadProgress} />
                </span>
              )}
            </div>
          </div>
        )}
        {!uploading && !browseLink && (
          <div className={`${StoryLogo.baseClass}__placeholder-action`}>
            <Button
              data-qa="epdAWGzPuTOf-4Z75Zw7K"
              onClick={this.onClickUploadButton}
              modifiers={['link']}
            >
              <SvgIcon icon="upload" />
              &nbsp;&nbsp;
              <TranslatedMessage {...messages.uploadImage} />
            </Button>
          </div>
        )}
        {browseLink && !uploading && (
          <div className={`${StoryLogo.baseClass}__placeholder-action`}>
            <BrowseModalUpload
              handleSelectedFilesfromPopUp={this.handleSelectedFilesfromPopUp}
              showRestUploadOptions={showRestUploadOptions}
              onSelectFiles={this.onSelectedFile}
              isFromStoryKit={isFromStoryKit}
            />
          </div>
        )}
      </label>
    );
  };

  renderHiddenInputs = () => {
    const { isEditable } = this.props;

    if (!isEditable) {
      return null;
    }

    return (
      <div className={`${StoryLogo.baseClass}__hidden-inputs`}>
        <InputFile
          accept="image/*"
          id="logo-input-file"
          onChange={this.onChangeFileInput}
          tabIndex="-1"
        />
        <Control.text
          model=".logoSize"
          updateOn={['change']}
          validateOn={['change']}
        />
        <Control.text
          model=".logoSrc"
          updateOn={['change']}
          validateOn={['change']}
        />
      </div>
    );
  };

  render() {
    const { isEditable } = this.props;
    const { logoSrc } = this.state;
    const displayImg = this.renderDisplayImg();
    const storyLogoToolbar = this.renderStoryLogoToolbar();
    const placeholder = this.renderPlaceholder();
    const hiddenInputs = this.renderHiddenInputs();

    const mainClass = classNames(`${StoryLogo.baseClass}`, {
      [`${StoryLogo.baseClass}--editable`]: isEditable,
    });

    if (!logoSrc && !isEditable) {
      return null;
    }

    return (
      <div className={mainClass}>
        {displayImg}
        {storyLogoToolbar}
        {placeholder}
        {hiddenInputs}
      </div>
    );
  }
}

StoryLogo.propTypes = {
  isEditable: PropTypes.bool,
  ...logoPropTypes,
  logoUploadPath: PropTypes.string,
  dispatchMergeFormAction: PropTypes.func.isRequired,
  browseLink: PropTypes.bool,
};

StoryLogo.defaultProps = {
  isEditable: false,
  ...logoDefaultProps,
  logoUploadPath: '',
  browseLink: false,
};

export default enhanceWithClickOutside(StoryLogo);
