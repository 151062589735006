import uniq from 'lodash/uniq';

import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';
import { performGet, performPut } from 'services/rest-service/rest-service';
import {
  getCurrentUserId,
  getDisplayNamesByIds,
} from 'services/user-service/user-service';

import messages from '../../pages/Campaigns/Campaigns.messages';

import { UPDATE_CAMPAIGN_PROPERTY } from './campaign';

export const GET_CAMPAIGN_REPORTS = 'campaigns/reports/GET_CAMPAIGN_REPORTS';
export const GET_CAMPAIGN_REPORTS_RECEIVED =
  'campaigns/reports/GET_CAMPAIGNS_REPORTS_RECEIVED';
export const GET_CAMPAIGN_REPORTS_ERROR =
  'campaigns/reports/GET_CAMPAIGN_REPORTS_ERROR';
export const GET_CAMPAIGN_REPORT_OWNERS =
  'campaigns/reports/GET_CAMPAIGN_REPORT_OWNERS';
export const GET_CAMPAIGN_REPORT_OWNERS_SUCCESS =
  'campaigns/reports/GET_CAMPAIGN_REPORT_OWNERS_SUCCESS';
export const GET_CAMPAIGN_REPORT_OWNERS_ERROR =
  'campaigns/reports/GET_CAMPAIGN_REPORT_OWNERS_ERROR';
export const DELETE_CAMPAIGN_REPORT =
  'campaigns/reports/DELETE_CAMPAIGN_REPORT';
export const DELETE_CAMPAIGN_REPORT_SUCCESS =
  'campaigns/reports/DELETE_CAMPAIGN_REPORT_SUCCESS';
export const DELETE_CAMPAIGN_REPORT_ERROR =
  'campaigns/reports/DELETE_CAMPAIGN_REPORT_ERROR';

export const initialState = {
  reports: [],
  loading: false,
  error: false,
};

const campaignReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_REPORTS: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case GET_CAMPAIGN_REPORT_OWNERS_SUCCESS: {
      const displayNames = action.payload;
      const reportsWithNames = [...state.reports];
      reportsWithNames.forEach(
        report => (report.ownedBy = displayNames[report.userId] || ''),
      );
      return {
        ...state,
        reports: [...reportsWithNames],
      };
    }

    case GET_CAMPAIGN_REPORTS_RECEIVED: {
      const { reports } = action.payload;
      return {
        loading: false,
        error: false,
        reports,
      };
    }

    case GET_CAMPAIGN_REPORTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case DELETE_CAMPAIGN_REPORT: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case DELETE_CAMPAIGN_REPORT_SUCCESS: {
      const { reports } = action.payload;

      return {
        ...state,
        loading: false,
        reports,
      };
    }

    case DELETE_CAMPAIGN_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

const getReportOwnerNames = ownerIds => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_REPORT_OWNERS });

  try {
    const displayNames = await getDisplayNamesByIds(ownerIds);
    dispatch({
      type: GET_CAMPAIGN_REPORT_OWNERS_SUCCESS,
      payload: displayNames,
    });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_REPORT_OWNERS_ERROR });
    throw e;
  }
};

export const getCampaignReportsByCampaignId = campaignId => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_REPORTS });

  try {
    const reports = await performGet(
      `${CAMPAIGN_ENDPOINT}/${campaignId}/report`,
    );
    const currentUserId = getCurrentUserId();
    reports.data.forEach(report => {
      report.svgIcon = report.version === 1 ? 'reports' : 'reportSlideIcon';
      report.owned = report.userId === currentUserId;
      report.shared = report.userId !== currentUserId;
      report.url = '';
    });
    dispatch({
      type: GET_CAMPAIGN_REPORTS_RECEIVED,
      payload: { reports: reports.data },
    });
    const ownerIds = uniq(reports.data.map(report => report.userId));
    if (ownerIds.length > 0) {
      dispatch(getReportOwnerNames(ownerIds));
    }
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_REPORTS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Failed to fetch reports for your campaign.',
        status: 'danger',
      }),
    );
  }
};

export const deleteCampaignReports = (campaignId, reportList, intl) => async (
  dispatch,
  getState,
) => {
  const campaignReports = getState().campaignReportList.reports;

  const [updatePayload, updatedList] = campaignReports.reduce(
    ([minItemList, completeItemList], campaignReport) => {
      const omitReport = reportList.some(
        ({ id, version }) =>
          id === campaignReport.id && version === campaignReport.version,
      );

      const minItem = {
        id: campaignReport.id,
        version: campaignReport.version,
      };

      const minList = omitReport ? minItemList : minItemList.concat(minItem);
      const completeList = omitReport
        ? completeItemList
        : completeItemList.concat(campaignReport);

      return [minList, completeList];
    },
    [[], []],
  );

  dispatch({ type: DELETE_CAMPAIGN_REPORT });

  try {
    await performPut(`${CAMPAIGN_ENDPOINT}/${campaignId}`, {
      reports: updatePayload,
    });
    dispatch({
      type: DELETE_CAMPAIGN_REPORT_SUCCESS,
      payload: { reports: updatedList },
    });
    dispatch({ type: UPDATE_CAMPAIGN_PROPERTY, payload: updatePayload });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.reportsDeletedSuccess),
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: DELETE_CAMPAIGN_REPORT_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.reportsDeletedFailed),
        status: 'danger',
      }),
    );
  }
};

export default campaignReportsReducer;
