import compact from 'lodash/compact';
import difference from 'lodash/difference';
import find from 'lodash/find';

import findIndex from 'lodash/findIndex';
import indexOf from 'lodash/indexOf';

import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import xor from 'lodash/xor';

import { OUTREACH_ENDPOINT, STORY_BASE_ENDPOINT } from 'constants/apis';
import { USER_ROLES } from 'constants/constants';
import { pitchStage } from 'constants/story-constants';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import {
  impersonatorSelector,
  impersonatorHasAnyRoleSelector,
} from 'selectors/account';
import {
  isStoryPublishedSelector,
  isStoryPausedSelector,
  storySelector,
} from 'selectors/stories';
import fetchContactImages from 'services/contact-image-service/contact-image-service';

import {
  performPost,
  performGet,
  performPut,
  requestMultiple,
} from 'services/rest-service/rest-service';
import { defaultProps as outreachFilteredViewDefaultProps } from 'types/outreach-filtered-view';
import { pluralizeCount } from 'utils/strings';

export const GET_RECIPIENTS_LIST = 'stories/GET_RECIPIENTS_LIST';
export const GET_RECIPIENTS_LIST_SUCCESS =
  'stories/GET_RECIPIENTS_LIST_SUCCESS';
export const GET_RECIPIENTS_LIST_ERROR = 'stories/GET_RECIPIENTS_LIST_ERROR';
export const GET_FULL_RECIPIENTS_LIST = 'stories/GET_FULL_RECIPIENTS_LIST';
export const GET_FULL_RECIPIENTS_LIST_SUCCESS =
  'stories/GET_FULL_RECIPIENTS_LIST_SUCCESS';
export const GET_FULL_RECIPIENTS_LIST_ERROR =
  'stories/GET_FULL_RECIPIENTS_LIST_ERROR';
export const ADD_STORY_RECIPIENTS = 'story/ADD_STORY_RECIPIENTS';
export const ADD_STORY_RECIPIENTS_CANCEL =
  'stories/ADD_STORY_RECIPIENTS_CANCEL';
export const ADD_STORY_RECIPIENTS_SUCCESS =
  'story/ADD_STORY_RECIPIENTS_SUCCESS';
export const ADD_STORY_RECIPIENTS_ERROR = 'story/ADD_STORY_RECIPIENTS_ERROR';
export const UPDATE_RECIPIENT_SIDEBAR = 'story/UPDATE_RECIPIENT_SIDEBAR';
export const DELETE_RECIPIENTS_BY_ID = 'stories/DELETE_RECIPIENTS_BY_ID';
export const DELETE_RECIPIENTS_BY_ID_SUCCESS =
  'stories/DELETE_RECIPIENTS_BY_ID_SUCCESS';
export const DELETE_RECIPIENTS_BY_ID_ERROR =
  'stories/DELETE_RECIPIENTS_BY_ID_ERROR';
export const EDIT_RECIPIENT = 'stories/EDIT_RECIPIENT';
export const EDIT_RECIPIENT_SUCCESS = 'stories/EDIT_RECIPIENT_SUCCESS';
export const EDIT_RECIPIENT_BULK_SUCCESS =
  'stories/EDIT_RECIPIENT_BULK_SUCCESS';
export const EDIT_RECIPIENT_ERROR = 'stories/EDIT_RECIPIENT_ERROR';
export const ADD_IMAGES_TO_RECIPIENTS = 'stories/ADD_IMAGES_TO_RECIPIENTS';
export const EXPORT_RECIPIENTS = 'stories/EXPORT_RECIPIENTS';
export const EXPORT_RECIPIENTS_ERROR = 'stories/EXPORT_RECIPIENTS_ERROR';
export const EXPORT_RECIPIENTS_SUCCESS = 'stories/EXPORT_RECIPIENTS_SUCCESS';
export const SECURE_RECIPIENT = 'stories/SECURE_RECIPIENT';
export const SECURE_RECIPIENT_SUCCESS = 'stories/SECURE_RECIPIENT_SUCCESS';
export const SECURE_RECIPIENT_ERROR = 'stories/SECURE_RECIPIENT_ERROR';
export const FILTER_RECIPIENTS = 'stories/FILTER_RECIPIENTS';
export const SELECT_RECIPIENTS = 'stories/SELECT_RECIPIENTS';
export const DESELECT_RECIPIENTS = 'stories/DESELECT_RECIPIENTS';
export const GET_OUTREACH_METRICS = 'stories/GET_OUTREACH_METRICS';
export const GET_OUTREACH_METRICS_SUCCESS =
  'stories/GET_OUTREACH_METRICS_SUCCESS';
export const GET_OUTREACH_METRICS_ERROR = 'stories/GET_OUTREACH_METRICS_ERROR';
export const GET_RECIPIENT_ACTIVITY = 'stories/GET_RECIPIENT_ACTIVITY';
export const GET_RECIPIENT_ACTIVITY_SUCCESS =
  'stories/GET_RECIPIENT_ACTIVITY_SUCCESS';
export const GET_RECIPIENT_ACTIVITY_ERROR =
  'stories/GET_RECIPIENT_ACTIVITY_ERROR';

export const DEFAULT_PAGE_SIZE = 50;

export const initialState = {
  loading: false,
  loadingAll: false,
  saving: false,
  error: false,
  editError: false,
  exporting: false,
  sortBy: '',
  recipients: [],
  selectedRecipientIds: [],
  filter: '',
  deleting: [],
  sidebar: {
    selected: [],
    query: '',
    filters: [],
  },
  pageNum: 0,
  totalRecipientCount: 0,
  outreachMetrics: {
    pitched: 0,
    opened: 0,
    opportunities: 0,
    secured: 0,
  },
};

export const defaultError = 'Something went wrong.';

const storyRecipientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STORY_RECIPIENTS:
      return {
        ...state,
        error: false,
        saving: true,
        loading: true,
      };
    case ADD_STORY_RECIPIENTS_CANCEL:
      return {
        ...state,
        error: false,
        saving: false,
        loading: false,
      };
    case ADD_STORY_RECIPIENTS_SUCCESS: {
      const { recipientsList, pageNum, totalRecipientCount } = action.payload;
      return {
        ...state,
        error: false,
        saving: false,
        loading: false,
        recipients: recipientsList,
        filter: '',
        selectedRecipientIds: [],
        pageNum,
        totalRecipientCount,
      };
    }
    case EDIT_RECIPIENT:
      return {
        ...state,
        saving: true,
      };
    case EDIT_RECIPIENT_SUCCESS: {
      const updatedRecipient = action.payload;
      const recipientsArray = state.recipients;
      const match = find(recipientsArray, each => {
        return each.id === updatedRecipient.id;
      });
      const index = indexOf(recipientsArray, match);
      const recipients = [...recipientsArray];
      const { imageUrl, imageLoading } = recipients[index];

      recipients[index] = {
        ...updatedRecipient,
        imageUrl,
        imageLoading,
      };

      return {
        ...state,
        saving: false,
        recipients,
      };
    }
    case EDIT_RECIPIENT_BULK_SUCCESS: {
      const updatedRecipients = action.payload;
      const recipientsArray = state.recipients;
      const allRecipients = [...recipientsArray];
      const recipients = [];

      allRecipients.forEach(contact => {
        const updates = updatedRecipients[contact.id] || {};
        const { imageUrl, imageLoading } = contact;
        recipients.push({ ...contact, ...updates, imageUrl, imageLoading });
      });

      return {
        ...state,
        saving: false,
        recipients,
        editError: false,
      };
    }
    case EDIT_RECIPIENT_ERROR:
      return {
        ...state,
        saving: false,
        editError: true,
      };
    case SECURE_RECIPIENT:
      return {
        ...state,
        saving: true,
      };
    case SECURE_RECIPIENT_SUCCESS: {
      const securedRecipient = action.payload;
      const recipientsArray = state.recipients;
      const match = find(recipientsArray, each => {
        return each.id === securedRecipient.id;
      });
      const index = indexOf(recipientsArray, match);
      const recipients = [...recipientsArray];
      recipients[index] = securedRecipient;
      return {
        ...state,
        saving: false,
        recipients,
      };
    }
    case SECURE_RECIPIENT_ERROR:
      return {
        ...state,
        saving: false,
        error: true,
      };
    case GET_RECIPIENTS_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_RECIPIENTS_LIST_SUCCESS: {
      const {
        recipientsList,
        sortBy,
        pageNum,
        totalRecipientCount,
      } = action.payload;
      return {
        ...state,
        sortBy,
        loading: false,
        error: false,
        recipients: recipientsList,
        selectedRecipientIds: [],
        filter: '',
        pageNum,
        totalRecipientCount,
      };
    }
    case ADD_STORY_RECIPIENTS_ERROR:
    case GET_RECIPIENTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_FULL_RECIPIENTS_LIST:
      return {
        ...state,
        loadingAll: true,
      };
    case GET_FULL_RECIPIENTS_LIST_SUCCESS:
      return {
        ...state,
        loadingAll: false,
      };
    case GET_FULL_RECIPIENTS_LIST_ERROR:
      return {
        ...state,
        loadingAll: false,
        error: true,
      };
    case UPDATE_RECIPIENT_SIDEBAR:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          ...action.payload.sidebar,
        },
      };
    case DELETE_RECIPIENTS_BY_ID: {
      const { selectedRecipients } = action.payload;
      return {
        ...state,
        deleting: [...selectedRecipients],
      };
    }
    case DELETE_RECIPIENTS_BY_ID_SUCCESS: {
      const {
        selectedRecipients,
        filteredRecipients,
        newCount,
        newPageNum,
      } = action.payload;
      return {
        ...state,
        recipients: filteredRecipients,
        selectedRecipientIds: (state.selectedRecipientIds || []).filter(r =>
          selectedRecipients.includes(r.id),
        ),
        deleting: [],
        totalRecipientCount: newCount,
        pageNum: newPageNum,
      };
    }
    case DELETE_RECIPIENTS_BY_ID_ERROR:
      return {
        ...state,
        deleting: [],
        error: true,
      };
    case ADD_IMAGES_TO_RECIPIENTS: {
      const images = action.payload;
      const { recipients } = state;
      const recipientImages = recipients.map(recipient => {
        const image = find(images, { id: recipient.contactId?.toString() });
        if (image) {
          recipient.imageUrl = image.imageUrl;
        } else if (recipient.imageUrl === undefined) {
          recipient.imageUrl = '';
        }
        recipient.imageLoading = false;
        return recipient;
      });
      return {
        ...state,
        recipients: recipientImages,
      };
    }
    case EXPORT_RECIPIENTS:
      return {
        ...state,
        exporting: true,
      };
    case EXPORT_RECIPIENTS_ERROR:
    case EXPORT_RECIPIENTS_SUCCESS:
      return {
        ...state,
        exporting: false,
      };
    case FILTER_RECIPIENTS:
      return {
        ...state,
        filter: action.payload,
      };
    case SELECT_RECIPIENTS:
      return {
        ...state,
        selectedRecipientIds: xor(state.selectedRecipientIds, action.payload),
      };
    case DESELECT_RECIPIENTS: {
      const toDeselect = action.payload;
      let selectedRecipientIds = [];
      if (toDeselect) {
        selectedRecipientIds = difference(
          state.selectedRecipientIds,
          toDeselect,
        );
      }
      return {
        ...state,
        selectedRecipientIds,
      };
    }
    case GET_OUTREACH_METRICS_SUCCESS:
      return {
        ...state,
        outreachMetrics: action.payload,
      };
    case GET_RECIPIENT_ACTIVITY: {
      const recipients = [...state.recipients];
      const index = findIndex(recipients, { id: action.payload });
      recipients[index] = {
        ...recipients[index],
        threadDetails: { loading: true },
      };
      return {
        ...state,
        recipients,
      };
    }
    case GET_RECIPIENT_ACTIVITY_SUCCESS: {
      const recipients = [...state.recipients];
      const index = findIndex(recipients, { id: action.payload.recipientId });
      recipients[index] = {
        ...recipients[index],
        threadDetails: {
          messages: action.payload.messages,
          loading: false,
        },
      };
      return {
        ...state,
        recipients,
      };
    }
    case GET_RECIPIENT_ACTIVITY_ERROR: {
      const recipients = [...state.recipients];
      const index = findIndex(recipients, { id: action.payload });
      recipients[index].threadDetails = { loading: false };
      return {
        ...state,
        recipients,
      };
    }
    default:
      return state;
  }
};

export const filterRecipients = query => ({
  type: FILTER_RECIPIENTS,
  payload: query,
});
export const toggleSelectRecipients = recipientIds => ({
  type: SELECT_RECIPIENTS,
  payload: recipientIds,
});
export const deselectRecipients = ids => ({
  type: DESELECT_RECIPIENTS,
  payload: ids,
});

const prepareRecipientsForImages = recipientsDataArray => {
  const recipients = recipientsDataArray.map(recipient => {
    if (recipient.contactId) {
      recipient.imageLoading = true;
      if (!recipient.imageUrl) {
        recipient.imageUrl = '';
      }
    }

    return recipient;
  });
  return recipients;
};

const fetchImagesForRecipients = recipientsToRequestImagesFor => async dispatch => {
  const containsRequiredFieldsForImage = recipient =>
    recipient.twitterHandle !== undefined &&
    recipient.twitterHandle &&
    !!recipient.contactId;
  const requestImages = recipientsToRequestImagesFor.filter(
    containsRequiredFieldsForImage,
  );
  if (!requestImages.length) {
    dispatch({
      type: ADD_IMAGES_TO_RECIPIENTS,
      images: [],
    });

    return;
  }

  const authors = requestImages.map(recipient => {
    return {
      id: recipient.contactId.toString(),
      twitterUsername: recipient.twitterHandle,
    };
  });
  if (!authors.length) {
    dispatch({
      type: ADD_IMAGES_TO_RECIPIENTS,
      images: [],
    });

    return;
  }

  try {
    const response = await fetchContactImages(authors);
    dispatch({
      type: ADD_IMAGES_TO_RECIPIENTS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: ADD_IMAGES_TO_RECIPIENTS,
      payload: [],
    });
    throw e;
  }
};

const removeRecipientMentionsIfNotPublishedOrPaused = (recipients, state) => {
  if (!isStoryPublishedSelector(state) && !isStoryPausedSelector(state)) {
    return recipients.map(recipient => {
      return {
        ...recipient,
        mentions: 0,
      };
    });
  }
  return recipients;
};

export const getAllRecipientsForStoryByStoryId = async ({ storyId }) => {
  let response;
  let error;

  try {
    response = await performGet(
      `${STORY_BASE_ENDPOINT}/${storyId}/recipient?pageNumber=0&pageSize=200`,
    );
    response =
      response.data && Array.isArray(response.data.results)
        ? response.data.results
        : null;
    error = response === null ? { message: "Couldn't get recipients." } : error;
  } catch (err) {
    error = err;
  }

  if (error) {
    throw error;
  }

  return response;
};

export const getAllRecipientsForOutreach = id => async dispatch => {
  dispatch({ type: GET_FULL_RECIPIENTS_LIST });
  let response;

  try {
    response = await performGet(
      `${STORY_BASE_ENDPOINT}/${id}/recipient?pageNumber=0&pageSize=200`,
    );
  } catch (e) {
    dispatch({ type: GET_FULL_RECIPIENTS_LIST_ERROR });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There was a problem retrieving story contacts.',
        status: 'danger',
      }),
    );
    throw e;
  }

  const responseData = response.data;
  const data = {
    sortBy: '',
    totalRecipientCount: responseData.total,
    pageNum: responseData.pageNumber,
    pageSize: responseData.pageSize,
    recipientsList: responseData.results.map(recipient => ({
      ...recipient,
      imageLoading: false,
      imageUrl: recipient.influencerId != null ? recipient.imageUrl : '',
    })),
  };
  const recipients = prepareRecipientsForImages(responseData.results);

  dispatch({ type: GET_RECIPIENTS_LIST_SUCCESS, payload: data });
  dispatch({ type: GET_FULL_RECIPIENTS_LIST_SUCCESS });
  dispatch(fetchImagesForRecipients(recipients, true));
};

export const getRecipientsListActionDispatcher = (
  id,
  page,
  sortBy = '',
  searchString,
) => dispatch => {
  dispatch({ type: GET_RECIPIENTS_LIST });

  const urlParams = {
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: page || 0,
    sortBy: sortBy || undefined,
    searchString: searchString || undefined,
  };

  performGet(`${STORY_BASE_ENDPOINT}/${id}/recipient`, urlParams)
    .then(response => {
      const r = response.data;

      const data = {
        sortBy,
        totalRecipientCount: r.total,
        pageNum: r.pageNumber,
        pageSize: r.pageSize,
        recipientsList: prepareRecipientsForImages(r.results),
      };

      const recipientsToSecure = data.recipientsList
        ? data.recipientsList.filter(recipient => {
            return (
              recipient.mentions > 0 &&
              recipient.engagementStage !== pitchStage.secured &&
              recipient.engagementStage !== pitchStage.revoked
            );
          })
        : [];

      recipientsToSecure.forEach(recipient => {
        dispatch({ type: SECURE_RECIPIENT });
        recipient.engagementStage = pitchStage.secured;
        performPut(
          `${STORY_BASE_ENDPOINT}/${id}/recipient/${recipient.id}`,
          recipient,
        )
          .then(putResult => {
            dispatch({
              type: SECURE_RECIPIENT_SUCCESS,
              payload: putResult.data,
            });
          })
          .catch(() => {
            dispatch({ type: SECURE_RECIPIENT_ERROR });
          });
      });

      dispatch({
        type: GET_RECIPIENTS_LIST_SUCCESS,
        payload: data,
      });
      return data;
    })
    .then(data => {
      dispatch(fetchImagesForRecipients(data.recipientsList));
    })
    .catch(e => {
      dispatch({ type: GET_RECIPIENTS_LIST_ERROR });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'There was a problem retrieving story contacts.',
          status: 'danger',
        }),
      );
      throw e;
    });
};

export const updateStoryRecipientsActionCreator = (
  recipients,
  customGetRecipientsListActionDispatcher,
) => async (dispatch, getState) => {
  const currentStory = storySelector(getState());
  let responseData;
  let error;
  dispatch({ type: ADD_STORY_RECIPIENTS });
  dispatch(
    addPageMessageWithDefaultTimeout({
      text: `Adding ${pluralizeCount(
        recipients.length,
        'Contact',
      )}, please wait.`,
    }),
  );
  try {
    const response = await performPost(
      `${STORY_BASE_ENDPOINT}/${currentStory.id}/recipient`,
      { storyRecipients: recipients },
    );
    responseData = response.data;
  } catch (e) {
    error = e;
  }

  if (
    error &&
    error.response.data &&
    error.response.data.error === 'Recipient already exists'
  ) {
    /*
      When you bulk add contacts to a story from another list (like a saved contact list),
      there's no way to pre-check whether they're already on the story or not.
      The API returns an "already added" error, but the contacts stil get added,
      so we have to repopulate the list.
    */
    dispatch({ type: ADD_STORY_RECIPIENTS_CANCEL });
    const getRecipientsList =
      customGetRecipientsListActionDispatcher ||
      getRecipientsListActionDispatcher;
    dispatch(getRecipientsList(currentStory.id));
    return;
  }

  if (error) {
    dispatch({
      type: ADD_STORY_RECIPIENTS_ERROR,
      payload: { error: error.response.data },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Failed to add contacts to story.',
        status: 'danger',
      }),
    );

    throw error;
  }

  dispatch({
    type: ADD_STORY_RECIPIENTS_SUCCESS,
    payload: {
      totalRecipientCount: responseData.total,
      pageNum: responseData.pageNumber,
      pageSize: responseData.pageSize,
      recipientsList: removeRecipientMentionsIfNotPublishedOrPaused(
        responseData.results,
        getState(),
      ),
    },
  });

  dispatch(
    fetchImagesForRecipients(prepareRecipientsForImages(responseData.results)),
  );

  dispatch(
    addPageMessageWithDefaultTimeout({
      text: 'Successfully added contacts to story.',
      status: 'success',
    }),
  );
};

export const deleteRecipientsActionDispatcher = (
  storyId,
  selectedRecipients,
) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_RECIPIENTS_BY_ID,
    payload: { selectedRecipients },
  });

  if (!Array.isArray(selectedRecipients)) {
    dispatch({ type: DELETE_RECIPIENTS_BY_ID_ERROR });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There was a problem removing the contacts: Bad formatting',
        status: 'danger',
      }),
    );
  }

  const requestObjs = selectedRecipients.map(recipientId => {
    return {
      url: `${STORY_BASE_ENDPOINT}/${storyId}/recipient/${recipientId}`,
      method: 'delete',
    };
  });

  try {
    let newResponse;
    let filteredRecipients;
    let newPageNum = 0;

    await requestMultiple(requestObjs);
    const {
      stories: {
        storyRecipients: { recipients, totalRecipientCount, pageNum },
      },
    } = getState();
    const newCount = totalRecipientCount - selectedRecipients.length;
    filteredRecipients = recipients.filter(
      recipient => selectedRecipients.indexOf(recipient.id) === -1,
    );

    if (!filteredRecipients.length && newCount > 0) {
      const pageCalculated = Math.ceil(newCount / DEFAULT_PAGE_SIZE) - 1;
      newPageNum = pageCalculated >= pageNum ? pageNum : pageCalculated;
      newResponse = await performGet(
        `${STORY_BASE_ENDPOINT}/${storyId}/recipient?pageNumber=${newPageNum}&pageSize=${DEFAULT_PAGE_SIZE}`,
      );
      filteredRecipients = newResponse.data.results;
    }
    dispatch({
      type: DELETE_RECIPIENTS_BY_ID_SUCCESS,
      payload: { selectedRecipients, filteredRecipients, newCount, newPageNum },
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: `Successfully removed ${selectedRecipients.length} ${
          selectedRecipients.length === 1 ? 'contact' : 'contacts'
        }`,
        status: 'success',
      }),
    );
  } catch (error) {
    dispatch({
      type: DELETE_RECIPIENTS_BY_ID_ERROR,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There was a problem removing the contacts.',
        status: 'danger',
      }),
    );
    throw error;
  }
};

export const editRecipientsActionDispatcher = (
  storyId,
  recipientIds,
  editObject,
) => (dispatch, getState) => {
  dispatch({ type: EDIT_RECIPIENT });
  const {
    stories: {
      storyRecipients: { sortBy = '', pageNum },
    },
  } = getState();
  const needsRefresh =
    editObject.engagementStage !== undefined &&
    sortBy.startsWith('ENGAGEMENT_STAGE');

  const requestObject = recipientIds.map(id => {
    return {
      method: 'PUT',
      url: `${STORY_BASE_ENDPOINT}/${storyId}/recipient/${id}`,
      data: editObject,
      params: {},
    };
  });

  return requestMultiple(requestObject)
    .then(results => {
      const editedContacts = results.map(result => result.data);
      dispatch({
        type: EDIT_RECIPIENT_BULK_SUCCESS,
        payload: keyBy(editedContacts, contact => contact.id.toString()),
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: `Successfully updated ${pluralizeCount(
            recipientIds.length,
            'contact',
          )}.`,
          status: 'success',
          ttl: 3000,
        }),
      );
    })
    .then(() => {
      if (needsRefresh) {
        performGet(
          `${STORY_BASE_ENDPOINT}/${storyId}/recipient?pageNumber=${pageNum}${
            sortBy ? `&sortBy=${sortBy}` : ''
          }`,
        )
          .then(response => {
            const r = response.data;

            const data = {
              sortBy,
              totalRecipientCount: r.total,
              pageNum: r.pageNumber,
              pageSize: r.pageSize,
              recipientsList: prepareRecipientsForImages(r.results),
            };
            dispatch({ type: GET_RECIPIENTS_LIST_SUCCESS, payload: data });
            return data.recipientsList;
          })
          .then(data => dispatch(fetchImagesForRecipients(data)))
          .catch(e => {
            dispatch({ type: GET_RECIPIENTS_LIST_ERROR });
            throw e;
          });
      }
    })
    .catch(e => {
      dispatch({ type: EDIT_RECIPIENT_ERROR });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'There was a problem saving your changes.',
          status: 'danger',
        }),
      );
      throw e;
    });
};

export const getRecipientConversionMetricsActionDispatcher = storyId => dispatch => {
  dispatch({ type: GET_OUTREACH_METRICS });

  performGet(`${STORY_BASE_ENDPOINT}/${storyId}/recipient/conversion`)
    .then(result => {
      dispatch({
        type: GET_OUTREACH_METRICS_SUCCESS,
        payload: result.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_OUTREACH_METRICS_ERROR,
        payload: error.response.statusText,
      });
      throw error;
    });
};

export const handleStoryKitMessageSuccess = (
  messagesById,
  storyId,
) => dispatch => {
  const recipientIds = values(messagesById)
    .filter(message => !!message.stories && message.stories.length > 0)
    .map(message => {
      const recipientContext = find(message.contexts, {
        contextType: 'recipientId',
      });
      if (recipientContext) return recipientContext.id;
      return null;
    });

  if (storyId && recipientIds) {
    dispatch(
      editRecipientsActionDispatcher(storyId, compact(recipientIds), {
        engagementStage: pitchStage.pitched,
      }),
    );
  }
};

export const exportRecipientsToCSV = storyId => dispatch => {
  dispatch({ type: EXPORT_RECIPIENTS });

  return new Promise(resolve => {
    performGet(`${STORY_BASE_ENDPOINT}/${storyId}/recipient/?csv=true`)
      .then(response => {
        const url = response.config.url;
        dispatch({ type: EXPORT_RECIPIENTS_SUCCESS });
        resolve(url);
      })
      .catch(e => {
        dispatch({ type: EXPORT_RECIPIENTS_ERROR });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: 'There was a problem exporting the contacts',
            status: 'danger',
          }),
        );
        throw e;
      });
  });
};

export const getStoryThreadForRecipient = (recipientId, threadId) => async (
  dispatch,
  getState,
) => {
  let response;
  let error = null;

  const state = getState();
  const blockGetThreads =
    impersonatorSelector(state) &&
    !impersonatorHasAnyRoleSelector(state)([USER_ROLES.impersonatorOutreach]);
  if (blockGetThreads) return null;

  dispatch({ type: GET_RECIPIENT_ACTIVITY, payload: recipientId });

  const query = {
    pagination: {
      ...outreachFilteredViewDefaultProps.pagination,
    },
    sort: {
      field: 'Date',
      order: 'Asc',
    },
    filters: {
      threadId,
    },
  };

  try {
    response = await performPost(`${OUTREACH_ENDPOINT}/messages/query`, query);
  } catch (e) {
    error = e;
  }

  if (error) {
    return dispatch({
      type: GET_RECIPIENT_ACTIVITY_ERROR,
      payload: recipientId,
    });
  }

  return dispatch({
    type: GET_RECIPIENT_ACTIVITY_SUCCESS,
    payload: {
      recipientId,
      messages: response.data.results,
    },
  });
};

export const updateRecipientSidebar = sidebar => {
  return { type: UPDATE_RECIPIENT_SIDEBAR, payload: { sidebar } };
};

export const copyRecipientLinkSuccess = () => dispatch => {
  dispatch(
    addPageMessageWithDefaultTimeout({
      text: 'Link copied to your clipboard.',
      status: 'success',
      ttl: 3000,
    }),
  );
};

export default storyRecipientsReducer;
