import {
  MIGRATION_ENDPOINT_V2_DEV,
  MIGRATION_ENDPOINT_V2_PROD,
} from 'constants/apis';

function getMigrationEndpoint(): string {
  return window.location.hostname === 'localhost'
    ? 'http://localhost:1717/upgrades/migration'
    : window.location.hostname === 'app2.cision.com'
    ? MIGRATION_ENDPOINT_V2_PROD
    : MIGRATION_ENDPOINT_V2_DEV;
}

export const MIGRATION_ENDPOINT: string = getMigrationEndpoint();
