import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';

import withModifiers from '../withModifiers';

class MultiLineInput extends Component {
  static baseClass = 'tk-multi-line-input';

  componentDidMount = () => {
    if (this.props.isFocused) {
      this.focus();
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  };

  componentDidUpdate = prevProps => {
    const { isFocused } = this.props;
    const { isFocused: prevFocus } = prevProps;
    if (isFocused !== prevFocus && isFocused) {
      this.focus();
    }
  };

  componentWillUnmount = () => {
    this.blur();
  };

  onBlur = (previousRange, source, editor) => {
    // this fun is due to a bug with quill and pasting
    // fix came from: https://github.com/zenoamaro/react-quill/issues/276#issuecomment-353956356
    setTimeout(() => {
      const fixRange = editor.getSelection();
      if (fixRange) {
        // is a paste
        // get the editor instance.
        const editorInstance = this.editor.getEditor();
        editorInstance.setContents(editor.getContents());
        editorInstance.setSelection(fixRange);
        //editorInstance.focus();
      } else {
        // it's true blur.
        const text = editor.getText().trim();
        this.blur();
        this.props.onBlur(text);
      }
    }, 100); // 100ms is random...
  };

  onChange = (content, delta, source, editor) => {
    const text = editor.getText().trim();
    this.props.onChange(text);
  };

  onFocus = () => {
    this.focus();
    this.props.onFocus();
  };

  modules = {
    toolbar: false,
    clipboard: {
      matchVisual: false,
    },
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: () => {
            this.blur();
          },
        },
        enter: {
          key: 13,
          handler: () => {
            this.blur();
          },
        },
      },
    },
  };

  focus() {
    if (this.editor) {
      this.editor.focus();
      const quill = this.editor.getEditor();
      quill.setSelection(quill.getLength(), 0);
    }
  }

  blur() {
    if (this.editor) {
      this.editor.blur();
    }
  }

  render() {
    const {
      onFocus,
      isFocused,
      style,
      placeholder,
      modifiers,
      value,
    } = this.props;

    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${MultiLineInput.baseClass}--${modifier}`)
      : [];
    const mainClass = classnames(MultiLineInput.baseClass, prefixedModifiers, {
      [`${MultiLineInput.baseClass}--focus`]: isFocused,
    });

    return (
      <div className={`${MultiLineInput.baseClass}__container`}>
        <ReactQuill
          formats={[]}
          modules={this.modules}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={onFocus}
          ref={ref => (this.editor = ref)}
          value={value}
          className={mainClass}
          style={style}
          theme={null}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

MultiLineInput.propTypes = {
  isFocused: PropTypes.bool,
  modifiers: PropTypes.array,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
};

MultiLineInput.defaultProps = {
  isFocused: false,
  modifiers: [],
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  placeholder: '',
  value: '',
  style: {},
};

export default withModifiers(MultiLineInput);
