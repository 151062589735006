import {
  ACCESS_CONTROL_GROUPS_BASE_ENDPOINT,
  CHANGE_PASSWORD_ENDPOINT,
  CURRENCY_ENDPOINT,
  USER_SETTINGS_ENDPOINT,
  USER_SETTINGS_LANGUAGE_ENDPOINT,
  LINK_TRACKING_FLAG_ENDPOINT,
} from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';
import {
  performGet,
  csrfPerformPut,
  performPost,
} from 'services/rest-service/rest-service';
import { getCurrentUserId } from 'services/user-service/user-service';

import { updateUserCurrency } from './account';

export const GET_USER_SETTINGS = 'userSettings/GET_USER_SETTINGS';
export const GET_USER_SETTINGS_ERROR = 'userSettings/GET_USER_SETTINGS_ERROR';
export const GET_USER_SETTINGS_SUCCESS =
  'userSettings/GET_USER_SETTINGS_SUCCESS';
export const GET_LANGUAGE_REGIONS = 'userSettings/GET_LANGUAGE_REGIONS';
export const GET_LANGUAGE_REGIONS_ERROR =
  'userSettings/GET_LANGUAGE_REGIONS_ERROR';
export const GET_LANGUAGE_REGIONS_SUCCESS =
  'userSettings/GET_LANGUAGE_REGIONS_SUCCESS';
export const UPDATE_LANGUAGE = 'userSettings/UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_ERROR = 'userSettings/UPDATE_LANGUAGE_ERROR';
export const UPDATE_LANGUAGE_SUCCESS = 'userSettings/UPDATE_LANGUAGE_SUCCESS';
export const GET_CURRENCIES = 'userSettings/GET_CURRENCIES';
export const GET_CURRENCIES_ERROR = 'userSettings/GET_CURRENCIES_ERROR';
export const GET_CURRENCIES_SUCCESS = 'userSettings/GET_CURRENCIES_SUCCESS';
export const UPDATE_CURRENCY = 'userSettings/UPDATE_CURRENCY';
export const UPDATE_CURRENCY_ERROR = 'userSettings/UPDATE_CURRENCY_ERROR';
export const UPDATE_CURRENCY_SUCCESS = 'userSettings/UPDATE_CURRENCY_SUCCESS';
export const UPDATE_USER_NAME = 'userSettings/UPDATE_USER_NAME';
export const UPDATE_USER_NAME_ERROR = 'userSettings/UPDATE_USER_NAME_ERROR';
export const UPDATE_USER_NAME_SUCCESS = 'userSettings/UPDATE_USER_NAME_SUCCESS';
export const UPDATE_PASSWORD = 'userSettings/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_ERROR = 'userSettings/UPDATE_PASSWORD_ERROR';
export const UPDATE_PASSWORD_SUCCESS = 'userSettings/UPDATE_PASSWORD_SUCCESS';
export const GET_USER_GROUPS = 'userSettings/GET_USER_GROUPS';
export const GET_USER_GROUPS_ERROR = 'userSettings/GET_USER_GROUPS_ERROR';
export const GET_USER_GROUPS_SUCCESS = 'userSettings/GET_USER_GROUPS_SUCCESS';
export const GET_LINK_TRACKING_FLAG = 'userSettings/GET_LINK_TRACKING_FLAG';
export const LINK_TRACKING_FLAG_SUCCESS =
  'userSettings/LINK_TRACKING_FLAG_SUCCESS';
export const LINK_TRACKING_FLAG_FAILURE =
  'userSettings/LINK_TRACKING_FLAG_FAILURE';

export const initialState = {
  languageRegions: [],
  languageRegionsError: false,
  languageRegionsLoading: true,
  updateLanguageLoading: false,
  currencies: [],
  currencyError: false,
  currencyLoading: true,
  updateCurrencyLoading: false,
  settings: {},
  settingsError: false,
  settingsLoading: true,
  userGroups: {
    error: false,
    loading: true,
    data: [],
  },
  message: '',
  error: '',
};

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGE_REGIONS:
      return {
        ...state,
        languageRegionsError: false,
        languageRegionsLoading: true,
        languageRegions: [],
      };
    case GET_LANGUAGE_REGIONS_SUCCESS:
      return {
        ...state,
        languageRegionsError: false,
        languageRegionsLoading: false,
        languageRegions: action.payload.languageRegions,
      };
    case GET_LANGUAGE_REGIONS_ERROR:
      return {
        ...state,
        languageRegionsError: true,
        languageRegionsLoading: false,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        currencyError: false,
        currencyLoading: true,
        currencies: [],
      };
    case GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencyError: false,
        currencyLoading: false,
        currencies: action.payload.currencies,
      };
    case GET_CURRENCIES_ERROR:
      return {
        ...state,
        currencyError: true,
        currencyLoading: false,
      };
    case GET_USER_SETTINGS:
      return {
        ...state,
        settingsLoading: true,
        settingsError: false,
      };
    case GET_USER_SETTINGS_ERROR:
      return {
        ...state,
        settingsLoading: false,
        settingsError: true,
      };
    case GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsLoading: false,
        settingsError: false,
        settings: action.payload.settings,
      };
    case UPDATE_LANGUAGE:
      return {
        ...state,
        updateLanguageLoading: true,
      };
    case UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        updateLanguageLoading: false,
        settings: {
          ...state.settings,
          language: action.payload.language,
        },
      };
    case UPDATE_LANGUAGE_ERROR:
      return {
        ...state,
        updateLanguageLoading: false,
      };
    case UPDATE_CURRENCY:
      return {
        ...state,
        updateCurrencyLoading: true,
      };
    case UPDATE_CURRENCY_SUCCESS:
      return {
        ...state,
        updateCurrencyLoading: false,
        settings: {
          ...state.settings,
          user: {
            ...state.settings.user,
            currencyConversion: {
              id: action.payload.currency.id,
            },
          },
        },
      };
    case UPDATE_CURRENCY_ERROR:
      return {
        ...state,
        updateCurrencyLoading: false,
      };
    case UPDATE_USER_NAME_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      };
    case GET_USER_GROUPS:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          loading: true,
          error: false,
        },
      };
    case GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          loading: false,
          error: false,
          data: action.payload.userGroups,
        },
      };
    case GET_USER_GROUPS_ERROR:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          loading: false,
          error: true,
          data: [],
        },
      };

    case GET_LINK_TRACKING_FLAG:
      return {
        ...state,
        message: '',
        error: null,
      };

    case LINK_TRACKING_FLAG_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        error: null,
      };

    case LINK_TRACKING_FLAG_FAILURE:
      return {
        ...state,
        message: '',
        error: action.payload,
      };

    default:
      return state;
  }
};

export const updateLanguage = language => async dispatch => {
  dispatch({ type: UPDATE_LANGUAGE });

  try {
    await csrfPerformPut(
      `${USER_SETTINGS_LANGUAGE_ENDPOINT}?language=${language}`,
    );

    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Success!', // TODO: translate
        text: 'Language has been updated.', // TODO: translate
        status: 'success',
        ttl: 3000,
      }),
    );

    // the app header components' instances of injectIntl'd `intl` get out of sync
    // from the Context API's `intl` when we update the IntlProvider "locale".
    // as a workaround, we refresh the app so that header translations update. See more @ EVER-1748
    // revisit if we convert Header components to fn's with useIntl-Hook-based `intl` instances.
    window.location.reload();

    dispatch({ type: UPDATE_LANGUAGE_SUCCESS, payload: { language } });
  } catch (e) {
    dispatch({ type: UPDATE_LANGUAGE_ERROR });

    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Error!',
        text: e.message,
        status: 'danger',
        ttl: 3000,
      }),
    );
  }
};

export const getCurrencies = () => async dispatch => {
  dispatch({ type: GET_CURRENCIES });

  try {
    const result = await performGet(CURRENCY_ENDPOINT);

    dispatch({
      type: GET_CURRENCIES_SUCCESS,
      payload: { currencies: result.data },
    });
  } catch (e) {
    dispatch({ type: GET_CURRENCIES_ERROR });
  }
};

export const updateCurrency = currency => async dispatch => {
  dispatch({ type: UPDATE_CURRENCY });

  try {
    await csrfPerformPut(`${CURRENCY_ENDPOINT}?iso_code=${currency.isoCode}`);

    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Success!',
        text: 'Currency has been updated.',
        status: 'success',
        ttl: 3000,
      }),
    );

    dispatch({ type: UPDATE_CURRENCY_SUCCESS, payload: { currency } });
    dispatch(updateUserCurrency(currency));
  } catch (e) {
    dispatch({ type: UPDATE_CURRENCY_ERROR });

    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Error!',
        text: e.message,
        status: 'danger',
        ttl: 3000,
      }),
    );
  }
};

export const getUserSettings = () => async dispatch => {
  dispatch({ type: GET_USER_SETTINGS });
  try {
    const result = await performGet(`${USER_SETTINGS_ENDPOINT}/get`, {
      user_id: getCurrentUserId(),
    });

    dispatch({
      type: GET_USER_SETTINGS_SUCCESS,
      payload: { settings: result.data },
    });
  } catch (e) {
    dispatch({ type: GET_USER_SETTINGS_ERROR });
  }
};

export const updateUserName = (settingsId, fullName) => async dispatch => {
  dispatch({ type: UPDATE_USER_NAME });
  const names = fullName.split(' ');
  // This is duplicating the logic from angular
  let firstName = '';
  let lastName = '';
  if (names[0]) {
    firstName = encodeURIComponent(names[0]);
  }

  if (names[1]) {
    lastName = encodeURIComponent(names[1]);
  }

  try {
    await csrfPerformPut(
      `${USER_SETTINGS_ENDPOINT}/update/${settingsId}?first_name=${firstName}&last_name=${lastName}`,
    );

    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Success!',
        text: 'Name has been updated.',
        status: 'success',
        ttl: 3000,
      }),
    );

    dispatch({
      type: UPDATE_USER_NAME_SUCCESS,
      payload: { firstName, lastName },
    });
  } catch (e) {
    dispatch({ type: UPDATE_USER_NAME_ERROR });

    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Error!',
        text: e.message,
        status: 'danger',
        ttl: 3000,
      }),
    );
  }
};

export const updatePassword = (
  currentPassword,
  newPassword,
) => async dispatch => {
  dispatch({ type: UPDATE_PASSWORD });

  try {
    const result = await csrfPerformPut(
      `${CHANGE_PASSWORD_ENDPOINT}?user_id=${getCurrentUserId()}`,
      {
        current_pass: currentPassword,
        new_pass: newPassword,
      },
    );

    if (result.data.status === 'success') {
      dispatch(
        addPageMessage({
          isNewUI: true,
          title: 'Success!',
          text: 'Password has been updated.',
          status: 'success',
          ttl: 3000,
        }),
      );

      dispatch({ type: UPDATE_PASSWORD_SUCCESS });
    } else {
      throw new Error('Changing password failed.');
    }
  } catch (e) {
    dispatch({ type: UPDATE_PASSWORD_ERROR });

    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Error!',
        text: e.message,
        status: 'danger',
        ttl: 3000,
      }),
    );
  }
};

export const getUserAffiliatedGroups = () => async dispatch => {
  dispatch({ type: GET_USER_GROUPS });

  try {
    const currentUserId = getCurrentUserId();
    const result = await performGet(
      `${ACCESS_CONTROL_GROUPS_BASE_ENDPOINT}/user/${currentUserId}`,
    );

    dispatch({
      type: GET_USER_GROUPS_SUCCESS,
      payload: { userGroups: result?.data?.groups || [] },
    });
  } catch (e) {
    dispatch({ type: GET_USER_GROUPS_ERROR });
  }
};

export const linkTrackingForEmailSettingDispatcher = payload => async dispatch => {
  dispatch({ type: GET_LINK_TRACKING_FLAG });

  try {
    const bodyPayload = {
      sEmail: payload.sEmail,
      sType: payload.sType,
      bClickFlagEnable: payload.bClickFlagEnable,
      bIsAdminUser: payload.bIsAdminUser,
      iUserId: payload.iUserId,
    };
    const result = await performPost(LINK_TRACKING_FLAG_ENDPOINT, bodyPayload);
    if (result.message) {
      dispatch({ type: LINK_TRACKING_FLAG_SUCCESS, payload: result });
    } else {
      dispatch({ type: LINK_TRACKING_FLAG_FAILURE, payload: result.error });
    }
  } catch (error) {
    dispatch({ type: LINK_TRACKING_FLAG_FAILURE, payload: error.toString() });
  }
};

export default userSettingsReducer;
