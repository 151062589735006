export default {
  searchBySequenceIdPlaceHolder: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_SEARCH_BY_SEQUENCE_ID_PLACEHOLDER',
    defaultMessage: 'Search By Sequence ID',
    description:
      'Admin Page > Article Management > Edit Arcticle: Search by sequence id placeholder',
  },
  fieldsAvailableForModificationMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_FIELDS_AVAILABLE_FOR_MODIFICATION_MSG',
    defaultMessage:
      'The following fields of article {SEQUENCE_ID} are available for modification:',
    description:
      'Admin Page > Article Management > Edit Article: Fields available for modification message',
  },
  enterSequenceIdMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ENTER_SEQUENCE_ID_MSG',
    defaultMessage:
      'Enter the sequence id of an article you would like to edit and click Search',
    description:
      'Admin Page > Article Management > Edit Article: Enter the sequence id message',
  },
  noArticleFoundMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_NO_ARTICLE_FOUND_MSG',
    defaultMessage: 'Article {SEQUENCE_ID} not found, please try another one.',
    description:
      'Admin Page > Article Management > Edit Article: article not found message',
  },
  getArticleErrorMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_GET_ARTICLES_ERROR_MESSAGE',
    defaultMessage:
      'Could not get article {SEQUENCE_ID}. Please verify your search.',
    description:
      'Admin Page > Article Management > Edit Article: Get article error message',
  },
  updateArticleSuccessMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_UPDATE_ARTICLE_SUCCESS_MESSAGE',
    defaultMessage: 'Success! You have edited article {SEQUENCE_ID}',
    description:
      'Admin Page > Article Management > Edit Article: Update article success message',
  },
  updateArticleErrorMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_UPDATE_ARTICLE_ERROR_MESSAGE',
    defaultMessage: 'Could not update article {SEQUENCE_ID}',
    description:
      'Admin Page > Article Management > Edit Article: Update article error message',
  },
  editArticleLabel: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_EDIT_ARTICLE_LABEL',
    defaultMessage: 'Edit Article',
    description:
      'Admin Page > Article Management > Edit Article: Edit article label',
  },
};
