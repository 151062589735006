import {
  DEV_FEATURES,
  EARNED_SEARCH_RESOLVER,
  EARNED_SEARCHES_BASE_URL,
  REACT_SEARCH_BASE_URL,
  SEARCH_EDIT_BASE_URL,
} from 'constants/constants';
import { userHasSomeDevFeatureFlags } from 'services/feature-service/feature-service';

export const getRedirectSearchDetailUrl = (searchId, legacyFullPath = true) => {
  if (
    userHasSomeDevFeatureFlags([
      DEV_FEATURES.simpleEarnedSearch,
      DEV_FEATURES.advancedEarnedSearch,
    ])
  ) {
    return EARNED_SEARCH_RESOLVER.replace('{searchId}', searchId);
  }
  return getLegacySearchDetailUrl(searchId, legacyFullPath);
};

export const getLegacySearchDetailUrl = (searchId, legacyFullPath = true) =>
  legacyFullPath
    ? `${window.location.protocol}//${window.location.host}${SEARCH_EDIT_BASE_URL}${searchId}`
    : `${SEARCH_EDIT_BASE_URL}${searchId}`;

export const getRedirectSearchBaseUrl = () => {
  if (
    userHasSomeDevFeatureFlags([
      DEV_FEATURES.simpleEarnedSearch,
      DEV_FEATURES.advancedEarnedSearch,
    ])
  ) {
    return EARNED_SEARCHES_BASE_URL;
  }
  return REACT_SEARCH_BASE_URL;
};
