import messages from 'components/widgetWizard/sections/section-wizard.messages';

export const NO_DATA_COLOR = ['#CCCFD2'];

export const DATE_RANGE_COMPARE_TYPES = {
  previousYear: 'PREVIOUS_YEAR',
  previousPeriod: 'PREVIOUS_PERIOD',
  customStartDate: 'CUSTOM',
  removeDateRange: 'REMOVE',
};

export const DATE_RANGE_COMPARE_TYPES_MESSAGES = {
  [DATE_RANGE_COMPARE_TYPES.previousYear]: messages.labelPreviousYearDateRange,
  [DATE_RANGE_COMPARE_TYPES.previousPeriod]:
    messages.labelPreviousPeriodDateRange,
  [DATE_RANGE_COMPARE_TYPES.customStartDate]: messages.labelCustomDateRange,
  [DATE_RANGE_COMPARE_TYPES.removeDateRange]: messages.labelRemoveDateRange,
};

export const DATE_RANGE_OPTIONS_WITH_UNDEFINED_DAYS = [
  'WEEK_TO_DATE',
  'MONTH_TO_DATE',
  'QUARTER_TO_DATE',
  'YEAR_TO_DATE',
  'LAST_WEEK',
  'LAST_MONTH',
  'LAST_QUARTER',
  'LAST_YEAR',
];

export const GRANULARITY_TYPES = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const TIME_SERIES_GRANULARITY_TYPES = [
  GRANULARITY_TYPES.DAY,
  GRANULARITY_TYPES.WEEK,
  GRANULARITY_TYPES.MONTH,
];

export const WIDGET_V3_SOURCE = 'TRENDKITE_ES';

export const WIDGET_V3_SOURCE_TYPES = [
  'SEARCH',
  'PARENT_SEARCH',
  'CHILD_SEARCH',
];
