export default {
  requiredHeadlineValidationMessage: {
    id: 'WITH_EDITABLE_REQUIRED_HEADLINE_VALIDATION_MESSAGE',
    defaultMessage: 'A story title is required to publish',
    description: 'Message indicating the Story Headline field is required',
  },
  requiredSummaryValidationMessage: {
    id: 'WITH_EDITABLE_REQUIRED_SUMMARY_VALIDATION_MESSAGE',
    defaultMessage: 'A story summary is required to publish',
    description: 'Message indicating the Story Summary field is required',
  },
};
