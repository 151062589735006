let openHttpsCount = 0;

type OpenArgs = any[];

/*
  Monkey patch XMLHttpRequest.prototype.open to keep track of open calls
  https://stackoverflow.com/a/63451553/2555029
*/
(function () {
  const oldOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (...args: OpenArgs) {
    openHttpsCount++;
    this.addEventListener(
      'readystatechange',
      function () {
        if (this.readyState === 4) openHttpsCount--;
      },
      false,
    );
    oldOpen.call(this, ...args);
  };
})();

const getOpenHttpsCount = (): number => openHttpsCount;

export default getOpenHttpsCount;
