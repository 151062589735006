export default {
  placeholder: {
    id: 'STORY-SUMMARY-DISPLAY_PLACEHOLDER',
    defaultMessage: 'Add a summary to this story (Click to Edit)',
    description:
      'Campaigns > Story Kit > Story Edit Page: content summary placeholder',
  },
  title: {
    id: 'STORY-SUMMARY-DISPLAY_TITLE',
    defaultMessage: 'Summary',
    description:
      'Campaigns > Story Kit > Story Edit Page: title summary placeholder',
  },
};
