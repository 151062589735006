import React, { Component } from 'react';

import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { Control, Errors, Form } from 'react-redux-form';

import {
  Button,
  ErrorWithIntl,
  globalMessages as tkUiMessages,
  Input,
  TextArea,
} from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';
import {
  errorMessageI18nKeys,
  validators,
} from '../story/templates/default-template';

// i18n
import messages from './EditStoryContact.messages';

const formModel = 'storyPressContactForm';

const ContactError = props => (
  <ErrorWithIntl messages={messages} block paddingTop="5px" {...props} />
);

class EditStoryContact extends Component {
  state = {
    focus: false,
  };

  onAttemptSubmit = () => {
    const { dispatchSubmitFormAction } = this.props;
    dispatchSubmitFormAction(formModel); // Attempt to submit this form (fails if not valid)
  };

  onSubmit = form => {
    // This form was submitted (via Enter key or reducer action)
    const { dispatchMergeFormAction } = this.props;
    dispatchMergeFormAction('storyContentForm', { pressContact: form }); // Merge this form's data into storyContentForm, which handles its own submit / change watching
    this.props.onEndEditing();
  };

  focus() {
    this.setState(() => ({ focus: true }));
  }

  render() {
    const { isStoryPressContactFormValid, onEndEditing, intl } = this.props;
    const { focus } = this.state;

    const baseClass = 'tk-story-contact-form';

    return (
      <StorySection>
        <StorySectionHeader label={intl.formatMessage(messages.header)} />
        <div className={baseClass}>
          <Form
            component="div"
            model={formModel}
            onSubmit={this.onSubmit}
            validators={validators.pressContact}
          >
            <div className={`${baseClass}__row`}>
              <div className={`${baseClass}__name`}>
                <div className={`${baseClass}__first-name`}>
                  <Control.text
                    component={Input}
                    controlProps={{ focus }}
                    id="tkStoryContactFirstName"
                    model=".firstName"
                    placeholder={intl.formatMessage(
                      messages.firstNamePlaceholder,
                    )}
                  />
                  <Errors
                    className="errors"
                    component={ContactError}
                    messages={errorMessageI18nKeys.pressContact.firstName}
                    model=".firstName"
                    show="touched"
                  />
                </div>
                <div className={`${baseClass}__last-name`}>
                  <Control.text
                    id="tkStoryContactLastName"
                    model=".lastName"
                    component={Input}
                    placeholder={intl.formatMessage(
                      messages.lastNamePlaceholder,
                    )}
                  />
                  <Errors
                    className="errors"
                    component={ContactError}
                    messages={errorMessageI18nKeys.pressContact.lastName}
                    model=".lastName"
                    show="touched"
                  />
                </div>
              </div>
            </div>
            <div className={`${baseClass}__row`}>
              <Control.text
                id="tkStoryContactCompany"
                model=".company"
                component={Input}
                placeholder={intl.formatMessage(
                  messages.organizationPlaceholder,
                )}
              />
              <Errors
                className="errors"
                component={ContactError}
                messages={errorMessageI18nKeys.pressContact.company}
                model=".company"
                show="touched"
              />
            </div>
            <div className={`${baseClass}__row`}>
              <Control.text
                id="tkStoryContactEmail"
                model=".email"
                component={Input}
                placeholder={intl.formatMessage(messages.emailPlaceholder)}
              />
              <Errors
                className="errors"
                component={ContactError}
                messages={errorMessageI18nKeys.pressContact.email}
                model=".email"
                show="touched"
              />
            </div>
            <div className={`${baseClass}__row`}>
              <Control.text
                id="tkStoryContactPhone"
                model=".phone"
                component={Input}
                placeholder={intl.formatMessage(messages.phonePlaceholder)}
              />
            </div>
            <div className={`${baseClass}__row`}>
              <Control.textarea
                id="tkStoryContactAddress"
                model=".address"
                component={TextArea}
                placeholder={intl.formatMessage(
                  messages.mailingAddressPlaceholder,
                )}
              />
            </div>
          </Form>
          <div className={`${baseClass}__actions`}>
            <div className={`${baseClass}__cancel`}>
              <Button
                data-qa="wqoEdngCU0U62qCv-CtyK"
                modifiers={['round', 'tertiary', 'block']}
                onClick={onEndEditing}
              >
                <T {...tkUiMessages.discard} />
              </Button>
            </div>
            <div className={`${baseClass}__save`}>
              <Button
                data-qa="omO9-F0MtwVBgp_2zq0TA"
                disabled={!isStoryPressContactFormValid}
                modifiers={['round', 'primary', 'block']}
                onClick={this.onAttemptSubmit}
              >
                <T {...tkUiMessages.save} />
              </Button>
            </div>
          </div>
        </div>
      </StorySection>
    );
  }
}

EditStoryContact.propTypes = {
  onEndEditing: PropTypes.func.isRequired,
  dispatchMergeFormAction: PropTypes.func.isRequired,
  dispatchSubmitFormAction: PropTypes.func.isRequired,
  isStoryPressContactFormValid: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

EditStoryContact.defaultProps = {
  pressContact: null,
};

export default injectIntl(EditStoryContact, { forwardRef: true });
