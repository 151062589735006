import Moment from 'moment';
import Numeral from 'numeral';

import * as constants from '../../constants/constants';

const displayValueFormatter = function (value, valueType, isoCode) {
  if (value === '-') {
    return '-';
  }

  if (valueType === constants.VALUE_FORMATTER_DISPLAY_TYPES.number) {
    return value === '-'
      ? '-'
      : Numeral(value).format(constants.VALUE_FORMATTER_DISPLAY_FORMATS.number);
  } else if (
    valueType === constants.VALUE_FORMATTER_DISPLAY_TYPES.shortNumber
  ) {
    return value === '-'
      ? '-'
      : Numeral(value).format(
          constants.VALUE_FORMATTER_DISPLAY_FORMATS.shortNumber,
        );
  } else if (
    valueType ===
    constants.VALUE_FORMATTER_DISPLAY_TYPES.percentGivenZeroToHundredScale
  ) {
    return value === '-'
      ? '-%'
      : Numeral(value / 100).format(
          constants.VALUE_FORMATTER_DISPLAY_FORMATS.percent,
        );
  } else if (
    valueType ===
    constants.VALUE_FORMATTER_DISPLAY_TYPES.percentGivenZeroToOneScale
  ) {
    return value === '-'
      ? '-%'
      : Numeral(value).format(
          constants.VALUE_FORMATTER_DISPLAY_FORMATS.percent,
        );
  } else if (
    valueType === constants.VALUE_FORMATTER_DISPLAY_TYPES.currencyUSD
  ) {
    return value === '-'
      ? '$-.--'
      : Numeral(value).format(
          constants.VALUE_FORMATTER_DISPLAY_FORMATS.currencyUSD,
        );
  } else if (
    isoCode !== undefined &&
    valueType === constants.VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyDynamic
  ) {
    const symbol = constants.CURRENCY_MAP[isoCode] || isoCode;
    if (typeof parseInt(value, 10) === 'number' && parseInt(value, 10) < 1000) {
      return `${symbol}${Numeral(value).format(
        constants.VALUE_FORMATTER_DISPLAY_FORMATS.currencyDynamicRounded,
      )}`;
    }
    return `${symbol}${Numeral(value).format(
      constants.VALUE_FORMATTER_DISPLAY_FORMATS.currencyDynamicAbbreviated,
    )}`;
  } else if (
    valueType === constants.VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyUSD
  ) {
    if (typeof parseInt(value, 10) === 'number' && parseInt(value, 10) < 1000) {
      return Numeral(value).format(
        constants.VALUE_FORMATTER_DISPLAY_FORMATS.currencyUSDRounded,
      );
    }

    return Numeral(value).format(
      constants.VALUE_FORMATTER_DISPLAY_FORMATS.currencyUSDAbbreviated,
    );
  } else if (
    valueType === constants.VALUE_FORMATTER_DISPLAY_TYPES.timeGivenSeconds
  ) {
    const time = Moment.utc(0).seconds(value);
    const timeFormat =
      value / 60 >= 60
        ? constants.VALUE_FORMATTER_DISPLAY_FORMATS.hoursMinutesAndSeconds
        : constants.VALUE_FORMATTER_DISPLAY_FORMATS.minutesAndSeconds;
    return time.format(timeFormat);
  }
  return value;
};

export default displayValueFormatter;
