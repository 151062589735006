import difference from 'lodash/difference';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { getDevFeatureLocalOverrides } from 'components/DevFeatureLocalOverrides/service';

import {
  DEV_FEATURES,
  FILTER_MEDIA_TYPES,
  MEDIA_TYPES,
  FEATURES,
} from 'constants/constants';

export const accountSelector = state =>
  state.account ? state.account.account : {};
const devFeatureSelector = state =>
  state.account ? state.account.devFeatures : [];
export const featureSelector = state =>
  state.account ? state.account.features : [];
const rolesSelector = state => (state.account ? state.account.roles : []);
const impersonatorRolesSelector = state =>
  state.account ? state.account.impersonatorRoles : [];
export const userSelector = state => state.account;

const impersonatorAccountIdSelector = state =>
  state.account?.impersonatorAccount;
const impersonatorUserIdSelector = state => state.account?.impersonatorId;
const impersonatorUserNameSelector = state =>
  state.account?.impersonatorUserName;

export const impersonatorSelector = createSelector(
  impersonatorAccountIdSelector,
  impersonatorUserIdSelector,
  impersonatorUserNameSelector,
  (accountId, userId, userName) => !!(accountId && userId && userName),
);

export const loggedInSelector = createSelector(
  accountSelector,
  account => !!account && account.id !== -1,
);

export const usernameSelector = createSelector(
  userSelector,
  user => user.username,
);

export const userIdSelector = createSelector(userSelector, user => user?.id);

export const accountIdSelector = createSelector(
  userSelector,
  user => user.account?.id,
);

export const userFirstNameSelector = createSelector(
  userSelector,
  user => user.firstname || '',
);

export const identityInformationSelector = createSelector(
  accountSelector,
  userIdSelector,
  impersonatorAccountIdSelector,
  impersonatorUserIdSelector,
  (account, userId, impersonatorAccountId, impersonatorUserId) => ({
    accountId: account.id,
    impersonatorAccountId,
    impersonatorUserId,
    internal: account.internal,
    userId,
  }),
);

// used to distinguish between:
// account-level dev-ff's + local overrides
// vs just account-level dev-ff's (this)
export const hasAccountDevFeatureFlagSelector = createSelector(
  devFeatureSelector,
  devFeatures => memoize(devFeature => includes(devFeatures, devFeature)),
);

export const hasDevFeatureFlagSelector = createSelector(
  devFeatureSelector,
  devFeatures =>
    memoize(
      devFeature =>
        includes(devFeatures, devFeature) ||
        includes(getDevFeatureLocalOverrides(), devFeature),
    ),
);

export const hasFeatureFlagSelector = createSelector(
  featureSelector,
  features => memoize(feature => includes(features, feature)),
);

export const defaultOutreachIntegrationIdSelector = createSelector(
  userSelector,
  hasDevFeatureFlagSelector,
  (user, hasDevFeatureFlag) =>
    hasDevFeatureFlag(DEV_FEATURES.jorts)
      ? user.defaultOutreachIntegrationId
      : null,
);

export const allowedMediaTypesSelector = createSelector(
  hasFeatureFlagSelector,
  hasDevFeatureFlagSelector,
  (hasFeatureFlag, hasDevFeatureFlag) => {
    return FILTER_MEDIA_TYPES.reduce((list, thisType) => {
      if (
        !hasFeatureFlag('BROADCAST_DATA') &&
        (thisType === 'tv' || thisType === 'radio')
      ) {
        return list;
      }

      if (
        !(hasFeatureFlag('PRINT') || hasFeatureFlag('PRINT_UK')) &&
        thisType === 'printData'
      ) {
        return list;
      }

      if (!hasFeatureFlag('NEWSEDGE') && thisType === 'newsedge') {
        return list;
      }

      if (
        !hasDevFeatureFlag(DEV_FEATURES.userAddedArticleFilter) &&
        thisType === 'manuallyAdded'
      ) {
        return list;
      }

      list.push({
        ...MEDIA_TYPES[thisType],
        id: thisType,
      });

      return list;
    }, []);
  },
);

export const hasAnyRoleSelector = createSelector(
  rolesSelector,
  accountRoles => roles => intersection(accountRoles, roles).length > 0,
);

export const impersonatorHasAnyRoleSelector = createSelector(
  impersonatorRolesSelector,
  impersonatorRoles => roles =>
    intersection(impersonatorRoles, roles).length > 0,
);

// Return correct comparitor for comparing two arrays,
// returns function that takes a "superset" as the parameter, and returns
// if the subset is inclusive
// second parameter is if all of the subset needs to be in the superset, or
// if only some are required
const arrayComparisonCreator = (subset, requiresAll) => {
  return requiresAll
    ? superset => difference(subset, superset).length === 0
    : superset => intersection(subset, superset).length >= 1;
};

const arrayDoesNotContainCreator = subset => superset =>
  intersection(subset, superset).length === 0;

// these are selector creators, not selectors themselves, you pass in the subset
// of roles/features required, if all or some are required
// it returns a selector that memoizes state/roles array and only recalulates if
// the redux roles/features changes.
export const createHasRolesSelector = (queryRoles, requiresAll) => {
  return createSelector(
    rolesSelector,
    arrayComparisonCreator(queryRoles, requiresAll),
  );
};

export const createHasImpersonatorRolesSelector = (queryRoles, requiresAll) => {
  return createSelector(
    impersonatorRolesSelector,
    arrayComparisonCreator(queryRoles, requiresAll),
  );
};

export const createHasDevFeaturesSelector = (queryDevFeatures, requiresAll) => {
  return createSelector(
    devFeatureSelector,
    arrayComparisonCreator(queryDevFeatures, requiresAll),
  );
};

export const createNotHasDevFeaturesSelector = queryFeatures => {
  return createSelector(
    devFeatureSelector,
    arrayDoesNotContainCreator(queryFeatures),
  );
};

export const createHasFeaturesSelector = (queryFeatures, requiresAll) => {
  return createSelector(
    featureSelector,
    arrayComparisonCreator(queryFeatures, requiresAll),
  );
};

export const createNotHasFeaturesSelector = queryFeatures => {
  return createSelector(
    featureSelector,
    arrayDoesNotContainCreator(queryFeatures),
  );
};

export const hasConnectSocialSelector = createSelector(
  hasFeatureFlagSelector,
  hasFeatureFlag => {
    return hasFeatureFlag(FEATURES.connectSocial);
  },
);
