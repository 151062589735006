import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

const initialState = {
  loading: false,
  error: false,
  otherDocumentCount: 0,
  data: [],
};

const topTopicsReducerFactory = namespace => {
  const GET_TOPTOPICS_START = `ihub/${namespace}/GET_TOPTOPICS_START`;
  const GET_TOPTOPICS_SUCCESS = `ihub/${namespace}/GET_TOPTOPICS_SUCCESS`;
  const GET_TOPTOPICS_ERROR = `ihub/${namespace}/GET_TOPTOPICS_ERROR`;

  const getTopTopicsForInfluencer = influencer => async dispatch => {
    dispatch({ type: GET_TOPTOPICS_START });
    const payload = {
      otherDocumentCount: 0,
      data: [],
    };

    try {
      const response = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/influencer/${influencer.id}/followers-topics`,
      );

      if (response && response.data) {
        payload.otherDocumentCount = response.data.otherDocumentCount;
        payload.data = response.data.buckets;
        dispatch({ type: GET_TOPTOPICS_SUCCESS, payload: payload });
      }
    } catch (e) {
      dispatch({ type: GET_TOPTOPICS_ERROR, payload: e });
    }
  };

  const topTopicsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_TOPTOPICS_START:
        return {
          ...state,
          loading: true,
          error: false,
          otherDocumentCount: 0,
          data: [],
        };

      case GET_TOPTOPICS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          otherDocumentCount: action.payload.otherDocumentCount,
          data: [...action.payload.data],
        };

      case GET_TOPTOPICS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  return {
    topTopicsReducer,
    GET_TOPTOPICS_START,
    GET_TOPTOPICS_SUCCESS,
    GET_TOPTOPICS_ERROR,
    getTopTopicsForInfluencer,
  };
};

export default topTopicsReducerFactory;
