import isEqual from 'lodash/isEqual';

import usePreviousValue from './use-previous-value';

/**
 * Return a boolean to check if the value changed between renders.
 * It performs a _.isEqual check by default but it can be customised.
 */
function useValueDidNotChange(currentValue, equalityFn = isEqual) {
  const prevValue = usePreviousValue(currentValue);
  return equalityFn(prevValue, currentValue);
}

export default useValueDidNotChange;
