import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import './name.scss';

import { Body as Text } from '../type';
import ShimmerText from '../shimmer-text';
import TextTruncate from '../text-truncate';

const Name = ({
  className,
  linkUrl,
  loading,
  name,
  size,
  style,
  truncate,
  externalLink,
}) => {
  const nameColor = linkUrl ? 'normalWithHover' : 'dark';
  const nameSize = {
    sm: 'md',
    md: 'lg',
    lg: 'xxl',
  }[size];
  let renderedName = name;

  const mainClass = classnames(Name.baseClass, className, {
    [`${Name.baseClass}--truncate`]: truncate,
    [`${Name.baseClass}--${size}`]: size,
    [`${Name.baseClass}--linked`]: linkUrl,
  });

  if (linkUrl) {
    renderedName = (
      <Link to={linkUrl} className={`${Name.baseClass}__link`}>
        {renderedName}
      </Link>
    );
  } else if (externalLink) {
    renderedName = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={externalLink}
        className={`${Name.baseClass}__link`}
      >
        {renderedName}
      </a>
    );
  }
  renderedName = (
    <Text color={nameColor} size={nameSize}>
      {renderedName}
    </Text>
  );
  renderedName = truncate ? (
    <TextTruncate block>{renderedName}</TextTruncate>
  ) : (
    renderedName
  );

  return (
    <div className={mainClass} style={style}>
      {loading && <ShimmerText style={{ width: '6em' }} />}
      {!loading && renderedName}
    </div>
  );
};

Name.baseClass = 'tk-person-name';

Name.propTypes = {
  /** Optional custom class names, applied to the outer wrapper of the component (in addition to the normal classes). */
  className: PropTypes.string,
  /** A nav link for for the avatar image and name. Only works when it has access to an ancestor <Router/> component */
  linkUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Show a loading state for the whole person block */
  loading: PropTypes.bool,
  /** Full name */
  name: PropTypes.string.isRequired,
  /** Size variants */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /** Optional custom style object, applied to the outer wrapper of the component. */
  style: PropTypes.object,
  /** Limit each author details section to 1 line */
  truncate: PropTypes.bool,
  /** External link access, use an <a> for it */
  externalLink: PropTypes.string,
};

Name.defaultProps = {
  className: '',
  linkUrl: '',
  loading: false,
  size: 'md',
  style: {},
  truncate: false,
  externalLink: null,
};

export default Name;
