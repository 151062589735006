import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

import { OUTREACH_ENDPOINT } from 'constants/apis';
import { getAuthorProfilesByIds } from 'reducers/contacts/contacts';
import { getAllRecipientsForStoryByStoryId } from 'reducers/stories/story-recipients';
import { performGet } from 'services/rest-service/rest-service';

export const GET_UNCONTACTED_RECIPIENTS_FOR_STORY =
  'stories/GET_UNCONTACTED_RECIPIENTS_FOR_STORY';
export const GET_UNCONTACTED_RECIPIENTS_FOR_STORY_ERROR =
  'stories/GET_UNCONTACTED_RECIPIENTS_FOR_STORY_ERROR';
export const GET_UNCONTACTED_RECIPIENTS_FOR_STORY_SUCCESS =
  'stories/GET_UNCONTACTED_RECIPIENTS_FOR_STORY_SUCCESS';

export const initialState = {
  uncontactedRecipientIdsByStoryIds: {},
};

export const initialRecipients = {
  error: null,
  ids: [],
  loading: false,
  totalWithEmails: 0,
};

export const defaultError = 'Something went wrong.';

const outreachStoryRecipientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNCONTACTED_RECIPIENTS_FOR_STORY: {
      return {
        ...state,
        uncontactedRecipientIdsByStoryIds: {
          ...state.uncontactedRecipientIdsByStoryIds,
          [action.payload.storyId]: {
            ...(state.uncontactedRecipientIdsByStoryIds[
              action.payload.storyId
            ] || initialRecipients),
            loading: true,
          },
        },
      };
    }
    case GET_UNCONTACTED_RECIPIENTS_FOR_STORY_ERROR: {
      return {
        ...state,
        uncontactedRecipientIdsByStoryIds: {
          ...state.uncontactedRecipientIdsByStoryIds,
          [action.payload.storyId]: {
            error: action.payload.error || defaultError,
            loading: false,
          },
        },
      };
    }
    case GET_UNCONTACTED_RECIPIENTS_FOR_STORY_SUCCESS: {
      return {
        ...state,
        uncontactedRecipientIdsByStoryIds: {
          ...state.uncontactedRecipientIdsByStoryIds,
          [action.payload.storyId]: {
            error: null,
            ids: action.payload.ids || [],
            loading: false,
            totalWithEmails: action.payload.totalWithEmails,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

// API call to get contacted recipients from outreach service
const getContactedRecipientsForStoryByStoryId = async ({ storyId }) => {
  let response = await performGet(
    `${OUTREACH_ENDPOINT}/story/${storyId}/recipients`,
  );
  response = Array.isArray(response.data) ? response.data : null;

  if (response === null) {
    throw new Error("Couldn't get recipients.");
  }

  return response;
};

/*
  Wraps get all recipients API call to get Author IDs (aka Contact IDs) and write output to passed objects
*/
const getContactIdsByStoryId = async ({ contactIdKey, getter, storyId }) => {
  let errors = null;
  let ids;

  try {
    const recipients = await getter({ storyId });
    const recipientIds = Array.isArray(recipients)
      ? recipients.map(r => r[contactIdKey])
      : null;

    if (recipientIds) {
      ids = recipientIds;
    } else {
      ids = [];
      errors = defaultError;
    }
  } catch (err) {
    ids = [];
    errors = err.message;
  }

  return {
    ids,
    errors,
  };
};

const getStoryCleanupAction = ({
  storyId,
  storyIds,
  errorsByStoryId,
  nextUncontactedRecipientIds,
}) => {
  if (errorsByStoryId[storyId]) {
    storyIds.splice(storyIds.indexOf(storyId), 1);

    return {
      payload: {
        error: errorsByStoryId[storyId],
        storyId,
      },
      type: GET_UNCONTACTED_RECIPIENTS_FOR_STORY_ERROR,
    };
  }

  if (!nextUncontactedRecipientIds.length) {
    storyIds.splice(storyIds.indexOf(storyId), 1);

    return {
      payload: { storyId },
      type: GET_UNCONTACTED_RECIPIENTS_FOR_STORY_SUCCESS,
    };
  }

  return null;
};

export const getUncontactedRecipientsForStoriesByStoryIdActionCreator = ({
  storyIds: initStoryIds,
}) => async dispatch => {
  let uncontactedRecipientIdsByStoryId = {};
  let contactedRecipientIdsByStoryId = {};
  const storyIds = [...initStoryIds];
  let errorsByStoryId = {};
  let authorProfiles = [];
  let error;

  storyIds.forEach(storyId => {
    dispatch({
      payload: { storyId },
      type: GET_UNCONTACTED_RECIPIENTS_FOR_STORY,
    });
  });

  // Gate 1: All recipients for all stories
  // Get recipients' author IDs from API
  const getAllRecipientsPromises = storyIds.map(async storyId => {
    const { errors, ids } = await getContactIdsByStoryId({
      contactIdKey: 'contactId',
      getter: getAllRecipientsForStoryByStoryId,
      storyId,
    });

    errorsByStoryId = {
      ...errorsByStoryId,
      [storyId]: errors,
    };

    uncontactedRecipientIdsByStoryId = {
      ...uncontactedRecipientIdsByStoryId,
      [storyId]: ids,
    };
  });

  await Promise.all(getAllRecipientsPromises);

  // Add non-erroring results to our working copies
  [...storyIds].forEach(storyId => {
    const storyCleanupAction = getStoryCleanupAction({
      storyId,
      storyIds,
      errorsByStoryId,
      nextUncontactedRecipientIds: uncontactedRecipientIdsByStoryId[storyId],
    });

    if (storyCleanupAction) {
      dispatch(storyCleanupAction);
    }
  });

  // Gate 2: Remove recipients who've already been contacted
  // Get contacted IDs from API
  const getAllContactedRecipientsPromises = storyIds.map(async storyId => {
    const { errors, ids } = await getContactIdsByStoryId({
      contactIdKey: 'authorId',
      getter: getContactedRecipientsForStoryByStoryId,
      storyId,
    });

    errorsByStoryId = {
      ...errorsByStoryId,
      [storyId]: errors,
    };

    contactedRecipientIdsByStoryId = {
      ...contactedRecipientIdsByStoryId,
      [storyId]: ids,
    };
  });

  await Promise.all(getAllContactedRecipientsPromises);

  // Filter out contacted IDs from our working copies
  [...storyIds].forEach(storyId => {
    const uncontactedRecipientIds = difference(
      uncontactedRecipientIdsByStoryId[storyId],
      contactedRecipientIdsByStoryId[storyId],
    );

    const storyCleanupAction = getStoryCleanupAction({
      storyId,
      storyIds,
      errorsByStoryId,
      nextUncontactedRecipientIds: uncontactedRecipientIds,
    });

    if (storyCleanupAction) {
      dispatch(storyCleanupAction);
    }

    uncontactedRecipientIdsByStoryId[storyId] = uncontactedRecipientIds;
  });

  // Gate 3: Remove recipients who don't have email contact methods
  // Get author profiles from the API
  const authorIds = uniq(
    storyIds.reduce(
      (result, storyId) =>
        result.concat(uncontactedRecipientIdsByStoryId[storyId]),
      [],
    ),
  ).filter(authorId => authorId != null);

  try {
    authorProfiles = authorIds.length
      ? await getAuthorProfilesByIds({ authorIds })
      : authorProfiles;
  } catch (err) {
    error = err;
  }

  if (error) {
    storyIds.forEach(storyId =>
      dispatch({
        payload: {
          error,
          storyId,
        },
        type: GET_UNCONTACTED_RECIPIENTS_FOR_STORY_ERROR,
      }),
    );

    return;
  }

  // Filter out authors without email addresses
  authorProfiles = authorProfiles.reduce((result, authorProfile) => {
    if (
      authorProfile.contacts.filter(
        contact => contact.contactType.toLowerCase() === 'email',
      ).length
    ) {
      result.push(parseInt(authorProfile.id, 10));
    }

    return result;
  }, []);

  // Filter out email-less author IDs from our working copies, dispatch final result
  storyIds.forEach(storyId => {
    uncontactedRecipientIdsByStoryId[
      storyId
    ] = uncontactedRecipientIdsByStoryId[storyId].filter(
      recipientId => authorProfiles.indexOf(recipientId) >= 0,
    );

    dispatch({
      payload: {
        ids: uncontactedRecipientIdsByStoryId[storyId],
        totalWithEmails: authorProfiles.length,
        storyId,
      },
      type: GET_UNCONTACTED_RECIPIENTS_FOR_STORY_SUCCESS,
    });
  });
};

export default outreachStoryRecipientsReducer;
