export default {
  title: {
    id: 'STORY-BACKLINKS-DISPLAY_TITLE',
    defaultMessage: 'Preferred Story Backlinks',
    description:
      'Campaigns > Story Kit > Story Edit Page: contet preffered backlinks title',
  },
  recommendedAnchor: {
    id: 'STORY-BACKLINKS-DISPLAY_RECOMMENDED_ANCHOR',
    defaultMessage: 'Recommended Anchor Text: {BACKLINK_TEXT}',
    description:
      'Campaigns > Story Kit > Story Edit Page: contet preffered backlinks recommended anchor',
  },
  placeholder: {
    id: 'STORY-BACKLINKS-DISPLAY_PLACEHOLDER',
    defaultMessage: 'Include all of the backlinks related to this story',
    description:
      'Campaigns > Story Kit > Story Edit Page: contet preffered backlinks placeholder text',
  },
  addBacklinks: {
    id: 'STORY-BACKLINKS-DISPLAY_ADD_BACKLINKS',
    defaultMessage: 'Add Backlinks',
    description:
      'Campaigns > Story Kit > Story Edit Page: contet preffered backlinks add backlinks link',
  },
};
