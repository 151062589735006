import {
  Profile,
  ProfileResults,
} from 'pages/SocialSearch/components/ProfilelTypeAhead/types';

export interface ProfileStreamState {
  searchTerm: string;
  selectedProfile?: Profile;
  results: ProfileResults;
  creationResult?: {
    loading: boolean;
    createdQueryId?: string;
    error: boolean;
  };
}

export enum Actions {
  SEARCH_UPDATE = 'SEARCH_UPDATE',
  SELECT_PROFILE = 'SELECT_PROFILE',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  GET_PROFILES_START = 'GET_PROFILES_START',
  GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS',
  GET_PROFILES_FAIL = 'GET_PROFILES_FAIL',
  CREATE_PROFILE_QUERY_START = 'CREATE_PROFILE_QUERY_START',
  CREATE_PROFILE_QUERY_SUCCESS = 'CREATE_PROFILE_QUERY_SUCCESS',
  CREATE_PROFILE_QUERY_FAIL = 'CREATE_PROFILE_QUERY_FAIL',
}

interface ProfileStreamAction {
  payload?: any;
}

interface SearchUpdateAction extends ProfileStreamAction {
  type: Actions.SEARCH_UPDATE;
}

interface SelectProfileAction extends ProfileStreamAction {
  type: Actions.SELECT_PROFILE;
}

interface ClearSearchAction extends ProfileStreamAction {
  type: Actions.CLEAR_SEARCH;
}

interface GetProfilesStart extends ProfileStreamAction {
  type: Actions.GET_PROFILES_START;
}

interface GetProfilesSuccess extends ProfileStreamAction {
  type: Actions.GET_PROFILES_SUCCESS;
}

interface GetProfilesFail extends ProfileStreamAction {
  type: Actions.GET_PROFILES_FAIL;
}

interface CreateProfileQueryStart extends ProfileStreamAction {
  type: Actions.CREATE_PROFILE_QUERY_START;
}

interface CreateProfileQuerySuccess extends ProfileStreamAction {
  type: Actions.CREATE_PROFILE_QUERY_SUCCESS;
}

interface CreateProfileQueryFail extends ProfileStreamAction {
  type: Actions.CREATE_PROFILE_QUERY_FAIL;
}

export type ActionTypes =
  | SearchUpdateAction
  | SelectProfileAction
  | ClearSearchAction
  | GetProfilesStart
  | GetProfilesSuccess
  | GetProfilesFail
  | CreateProfileQueryStart
  | CreateProfileQuerySuccess
  | CreateProfileQueryFail;
