import React, { Component } from 'react';

import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import TemplateLink from './blots/template-link';
import TemplateString from './blots/template-string';

// override Quill to use style attr not classes for styling
const styleAlign = Quill.import('attributors/style/align');
Quill.register(styleAlign, true);

const styleFont = Quill.import('attributors/style/font');
Quill.register(styleFont, true);

const styleSize = Quill.import('attributors/style/size');
Quill.register(styleSize, true);

const Block = Quill.import('blots/block');

Quill.register('formats/templateLink', TemplateLink);
Quill.register('formats/templateString', TemplateString);

/**
 * Can be triggered by including a div in the value passed in of the type
 * <div class="ql-placeholder-content" placeholder="This is a placeholder from attribute"><br /></div>
 * Note that the <br /> is required.  The placeholder attribute determines the placeholder
 * text to be used.
 */
class PlaceholderBlot extends Block {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('placeholder', value.placeholder);
    return node;
  }

  static formats(domNode) {
    return {
      placeholder: domNode.getAttribute('placeholder'),
      class: domNode.getAttribute('class'),
    };
  }

  optimize(context) {
    super.optimize(context);
    if (
      this.children.length === 1 &&
      this.children.head.domNode.nodeName === 'BR'
    ) {
      if (!this.domNode.classList.contains('empty')) {
        this.domNode.classList.add('empty');
      }
    } else if (this.domNode.classList.contains('empty')) {
      this.domNode.classList.remove('empty');
    }
  }
}

PlaceholderBlot.blotName = 'placeholder';
PlaceholderBlot.tagName = 'DIV';
PlaceholderBlot.className = 'ql-placeholder-content';

Quill.register('formats/placeholder', PlaceholderBlot);

Block.tagName = 'DIV';
Quill.register(Block, true);

const link = Quill.import('formats/link');
link.sanitize = url => {
  if (!url) return url;

  if (url.startsWith('//')) {
    return url;
  }

  const colonIndex = url.indexOf(':');
  if (colonIndex < 0) {
    return `http://${url}`;
  }

  if (colonIndex === 0) {
    return 'about:blank';
  }

  const protocol = url.slice(0, url.indexOf(':'));
  if (['http', 'https', 'mailto', 'tel'].includes(protocol)) {
    return url;
  }

  return 'about:blank';
};

class WysiwygEditor extends Component {
  componentDidMount = () => {
    if (this.props.focus) {
      this.focus();
    }
  };

  componentDidUpdate = prevProps => {
    const { focus } = this.props;
    const { focus: prevFocus } = prevProps;
    if (focus !== prevFocus && focus) {
      this.focus();
    }
  };

  onChange = (content, delta, source, editor) => {
    const trimHTML = !!intersection(this.props.formats, [
      'templateString',
      'templateLink',
    ]).length;
    const trimmedContent = trimHTML
      ? editor.getHTML().trim()
      : editor.getText().trim();
    if (!trimmedContent) {
      this.props.onChange('');
    } else {
      this.props.onChange(content);
    }

    if (source === 'api') {
      this.props.setPristine();
    }
  };

  modules = {
    toolbar: {
      container: `#${this.props.toolbarId}`,
      handlers: {
        'template-string': value => {
          let blotName = 'templateString';
          if (value.toLowerCase().indexOf('link') >= 0) {
            // It seems like there should be a better way to do this, but I don't think there is
            // since we're mixing the 2 templates into one menu
            blotName = 'templateLink';
          }
          const quill = this.editor.getEditor();
          quill.insertEmbed(
            quill.getSelection(true).index,
            blotName,
            { 'data-template-key': value },
            Quill.sources.USER,
          );
          quill.setSelection(quill.getSelection(true).index + 1);
        },
      },
    },
  };

  focus() {
    const { focusCursorAtEnd } = this.props;
    if (this.editor) {
      const quill = this.editor.getEditor();
      if (quill && !quill.hasFocus()) {
        this.editor.focus();
        quill.setSelection(focusCursorAtEnd ? quill.getLength() : 0, 0);
      }
    }
  }

  blur() {
    if (this.editor) {
      this.editor.blur();
    }
  }

  formatListTags() {
    const listTags = document.querySelectorAll('ol, ul') ?? [];
    if (listTags.length > 0) {
      for (let tag = 0; tag < listTags.length; tag++) {
        listTags[tag].style = 'list-style-position: inside;';
      }
    }
  }

  render() {
    const { value, onBlur, onFocus, formats, scrollingContainer } = this.props;

    this.formatListTags();

    return (
      <div
        className="tk-wysiwyg-editor"
        data-ignore-pdf-export-correction-styles
        data-qa={this.props.dataQa}
      >
        <ReactQuill
          formats={formats}
          modules={this.modules}
          onChange={this.onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={ref => (this.editor = ref)}
          value={value}
          scrollingContainer={scrollingContainer}
          placeholder={this.props.placeholder || ''}
        />
      </div>
    );
  }
}

WysiwygEditor.propTypes = {
  /** autofocus element */
  focus: PropTypes.bool,
  /** Should the focus put the cursor at the end or the beginning of the text */
  focusCursorAtEnd: PropTypes.bool,
  onBlur: PropTypes.func,
  /** change event, passes html string as argument, will send empty string if
   * change deleted all text i.e. <p></p> -> '' */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  scrollingContainer: PropTypes.node,
  placeholder: PropTypes.string,
  setPristine: PropTypes.func,
  value: PropTypes.string,
  /** unique tag id of toolbar, this is so you can put the toolbar anywhere
   * in the dom and connect it to this editor */
  toolbarId: PropTypes.string.isRequired,
  formats: PropTypes.arrayOf(
    PropTypes.oneOf([
      'bold',
      'italic',
      'underline',
      'align',
      'list',
      'color',
      'size',
      'font',
      'link',
      'blockquote',
      'placeholder',
      'templateString',
      'templateLink',
    ]),
  ),
  dataQa: PropTypes.string,
};

WysiwygEditor.defaultProps = {
  focus: false,
  focusCursorAtEnd: true,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  scrollingContainer: null,
  placeholder: '',
  setPristine: () => {},
  value: '',
  formats: ['bold', 'italic', 'underline', 'align', 'list', 'color', 'link'],
  dataQa: '',
};

export default WysiwygEditor;
