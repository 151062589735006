export default {
  addUserLabel: {
    id: 'ADMIN_PAGE_ADD_USER_LABEL',
    defaultMessage: 'Add User',
    description: 'Admin Page > Add User: Add user label',
  },
  addSalesUserLabel: {
    id: 'ADMIN_PAGE_ADD_SALES_USER_LABEL',
    defaultMessage: 'Add Sales User',
    description: 'Admin Page > Add User: Add sales user label',
  },
  addPostSalesUserLabel: {
    id: 'ADMIN_PAGE_ADD_POST_SALES_USER_LABEL',
    defaultMessage: 'Add Post Sales User',
    description: 'Admin Page > Add User: Add post sales user label',
  },
  usernameLabel: {
    id: 'ADMIN_PAGE_ADD_USER_USERNAME_LABEL',
    defaultMessage: 'Primary Email Address (username)',
    description: 'Admin Page > Add User: Primary email address username label',
  },
  firstNameLabel: {
    id: 'ADMIN_PAGE_ADD_USER_FIRST_NAME_LABEL',
    defaultMessage: 'First Name',
    description: 'Admin Page > Add User: First name label',
  },
  lastNameLabel: {
    id: 'ADMIN_PAGE_ADD_USER_LAST_NAME_LABEL',
    defaultMessage: 'Last Name',
    description: 'Admin Page > Add User: Last name label',
  },
  sendNewUserEmailLabel: {
    id: 'ADMIN_PAGE_ADD_USER_SEND_NEW_USER_EMAIL_LABEL',
    defaultMessage: 'New User Email',
    description: 'Admin Page > Add User: Send new user email label',
  },
  newPasswordLabel: {
    id: 'ADMIN_PAGE_ADD_USER_NEW_PASSWORD_LABEL',
    defaultMessage: 'Password',
    description: 'Admin Page > Add User: Password label',
  },
  atLeastNCharactersLabel: {
    id: 'ADMIN_PAGE_ADD_USER_AT_LEAST_N_CHARACTERS_LABEL',
    defaultMessage: 'At least {MIN_CHARS} characters',
    description: 'Admin Page > Add User: At least N characters label',
  },
  numbersLabel: {
    id: 'ADMIN_PAGE_ADD_USER_NUMBERS_LABEL',
    defaultMessage: 'Numbers',
    description: 'Admin Page > Add User: Numbers label',
  },
  passwordMatchLabel: {
    id: 'ADMIN_PAGE_ADD_USER_PASSWORD_MATCH_LABEL',
    defaultMessage: 'Passwords Match',
    description: 'Admin Page > Add User: Passwords match label',
  },
  confirmNewPasswordLabel: {
    id: 'ADMIN_PAGE_ADD_USER_CONFIRM_NEW_PASSWORD_LABEL',
    defaultMessage: 'Confirm Password',
    description: 'Admin Page > Add User: Confirm password label',
  },
  addToExistingAccountLabel: {
    id: 'ADMIN_PAGE_ADD_USER_ADD_TO_EXISTING_ACCOUNT_LABEL',
    defaultMessage: 'Add to Existing Account',
    description: 'Admin Page > Add User: Add to existing account label',
  },
  addImpersonatorLabel: {
    id: 'ADMIN_PAGE_ADD_USER_IMPERSONATOR_LABEL',
    defaultMessage: 'Impersonator',
    description: 'Admin Page > Add User: Impersonator label',
  },
  addAdminLiteLabel: {
    id: 'ADMIN_PAGE_ADD_USER_ADMIN_LITE_LABEL',
    defaultMessage: 'Admin Lite',
    description: 'Admin Page > Add User: Admin lite label',
  },
  addAdminContentSearchLabel: {
    id: 'ADMIN_PAGE_ADD_USER_ADMIN_CONTENT_SEARCH_LABEL',
    defaultMessage: 'Admin Content Search',
    description: 'Admin Page > Add User: Admin content search label',
  },
  addCustomerAdminLabel: {
    id: 'ADMIN_PAGE_ADD_USER_CUSTOMER_ADMIN_LABEL',
    defaultMessage: 'Customer Admin',
    description: 'Admin Page > Add User: Customer admin label',
  },
  existingAccountLabel: {
    id: 'ADMIN_PAGE_ADD_USER_EXISTING_ACCOUNT_LABEL',
    defaultMessage: 'Existing Account',
    description: 'Admin Page > Add User: Existing account label',
  },
  searchByAccountNamePlaceholder: {
    id: 'ADMIN_PAGE_ADD_USER_SEARCH_BY_ACCOUNT_NAME_PLACEHOLDER',
    defaultMessage: 'Search By Account Name',
    description: 'Admin Page > Add User: Search by account name placeholder',
  },
  newAccountNameLabel: {
    id: 'ADMIN_PAGE_ADD_USER_NEW_ACCOUNT_NAME_LABEL',
    defaultMessage: 'New Account Name',
    description: 'Admin Page > Add User: New account name label',
  },
  globalCustomerIdLabel: {
    id: 'ADMIN_PAGE_ADD_USER_GLOBAL_CUSTOMER_ID_LABEL',
    defaultMessage: 'Global Customer ID',
    description: 'Admin Page > Add User: Global customer id label',
  },
  accountTypeLabel: {
    id: 'ADMIN_PAGE_ADD_USER_ACCOUNT_TYPE_LABEL',
    defaultMessage: 'Account Type',
    description: 'Admin Page > Add User: Account type label',
  },
  userTypeLabel: {
    id: 'ADMIN_PAGE_ADD_USER_TYPE_LABEL',
    defaultMessage: 'User Type',
    description: 'Admin Page > Add User: User type label',
  },
  selectPlaceholder: {
    id: 'ADMIN_PAGE_ADD_USER_SELECT_PLACEHOLDER',
    defaultMessage: 'Please Select One',
    description: 'Admin Page > Add User: Select placeholder',
  },
  enterErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_ENTER_ERROR_MESSAGE',
    defaultMessage: 'Please enter',
    description: 'Admin Page > Add User: Enter error message',
  },
  invalidEmailErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_INVALID_ERROR_MESSAGE',
    defaultMessage: 'Please enter a valid',
    description: 'Admin Page > Add User: Invalid error Mesage',
  },
  addUserSuccessMsg: {
    id: 'ADMIN_PAGE_ADD_USER_SUCCESS_ADD_MESSAGE',
    defaultMessage: 'Success! You have added user {USER_NAME}.',
    description: 'Admin Page > Add User: Success message',
  },
  addUserAccountSuccessMsg: {
    id: 'ADMIN_PAGE_ADD_USER_ACCOUNT_SUCCESS_MESSAGE',
    defaultMessage: 'Success! You have added account {ACCOUNT_NAME}.',
    description: 'Admin Page > Add User: Add account success message',
  },
  alreadyExistsErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_ALREADY_EXISTS_ERROR_MESSAGE',
    defaultMessage: 'User already exists.',
    description: 'Admin Page > Add User: Already exists error message',
  },
  addUserDefaultErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_DEFAULT_ERROR_MESSAGE',
    defaultMessage: 'Could not create new user.',
    description: 'Admin Page > Add User: Default error message',
  },
  invalidGlobalCustomerIdErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_INVALID_GLOBAL_CUSTOMER_ID_ERROR_MESSAGE',
    defaultMessage: 'Invalid global customer id or sfdcId.',
    description: 'Admin Page > Add User: Account invalid id error message',
  },
  accountDuplicateIdErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_ACCOUNT_DUPLICATE_ID_ERROR_MESSAGE',
    defaultMessage: 'Non-unique global customer id or sfdcId.',
    description: 'Admin Page > Add User: Account duplicate id error message',
  },
  addUserAccountDefaultErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_ACCOUNT_DEFAULT_ERROR_MESSAGE',
    defaultMessage: 'Could not create new account.',
    description: 'Admin Page > Add User: Account default error message',
  },
  sendNewUserEmailSuccessMsg: {
    id: 'ADMIN_PAGE_ADD_USER_SEND_NEW_USER_EMAIL_ADD_SUCCESS_MESSAGE',
    defaultMessage: 'New user email sent to: {USER_NAME}',
    description: 'Admin Page > Add User: Send new user email success message',
  },
  sendNewUserEmailErrorMsg: {
    id: 'ADMIN_PAGE_ADD_USER_SEND_NEW_USER_EMAIL_ADD_ERROR_MESSAGE',
    defaultMessage: 'The new user email for {USER_NAME} could not be sent.',
    description: 'Admin Page > Add User: Send new user email error message',
  },
};
