import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Type from '../type';
import withPadding from '../withPadding';

const Error = ({ block, className, children, style, size }) => {
  const mainClass = classNames(Error.baseClass, className);

  if (block) {
    return (
      <div className={mainClass} style={style}>
        <Type block color="danger" size={size}>
          {children}
        </Type>
      </div>
    );
  }

  return (
    <span className={mainClass} style={style}>
      <Type block={false} color="danger" size={size}>
        {children}
      </Type>
    </span>
  );
};

Error.baseClass = 'tk-form-error';

Error.propTypes = {
  block: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  size: PropTypes.string,
};

Error.defaultProps = {
  block: false,
  className: '',
  children: '',
  style: {
    padding: '',
  },
  size: 'md',
};

export default withPadding(Error);
