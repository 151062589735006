import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

import moment from 'moment';

import {
  ARTICLE_DELETE_ENDPOINT,
  ARTICLE_TAG_ENDPOINT,
  WIDGET_V3_ENDPOINT,
  NEW_REPORT_ENDPOINT,
  ADD_CAMPAIGN_TAGS_TO_ARTICLES_ENDPOINT,
  ARTICLES_UPDATE_SENTIMENT_ENDPOINT,
  DELETE_ARTICLE_FROM_TAG_ENDPOINT,
} from 'constants/apis';

import {
  DEFAULT_DATE_FORMAT_INTL,
  MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION,
} from 'constants/constants';
import constantMessages from 'constants/constants.messages';
import globalMessages from 'i18n/Global.messages';
import { ADD_ARTICLE_FLOW_ENDPOINTS_MAP } from 'pages/WidgetDrilldown/constants';
import {
  articlesSelectionDataSelector,
  uniqueArticleListSelector,
} from 'pages/WidgetDrilldown/drilldown-selector';
import { refreshSocialDataForArticles } from 'reducers/article-list-social-amp';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { getCanUseBulkTagging } from 'selectors/canUse/canUse';
import {
  performDelete,
  performGet,
  performPost,
  performPatch,
  isRequestCancel,
  getCancelTokenSource,
} from 'services/rest-service/rest-service';

import drilldownMessages from './DrilldownArticleList/form.messages';
import { isArticleDuplicated } from './utils';

export const GET_ARTICLE_LIST = 'dashboard/GET_ARTICLE_LIST';
export const GET_ARTICLE_LIST_ERROR = 'dashboard/GET_ARTICLE_LIST_ERROR';
export const GET_ARTICLE_LIST_SUCCESS = 'dashboard/GET_ARTICLE_LIST_SUCCESS';
export const EXTRACT_ARTICLE_BY_URL = 'dashboard/EXTRACT_ARTICLE_BY_URL';
export const EXTRACT_ARTICLE_BY_URL_ERROR =
  'dashboard/EXTRACT_ARTICLE_BY_URL_ERROR';
export const EXTRACT_ARTICLE_BY_URL_SUCCESS =
  'dashboard/EXTRACT_ARTICLE_BY_URL_SUCCESS';
export const LINK_ARTICLE_TO_SEARCH = 'dashboard/LINK_ARTICLE_TO_SEARCH';
export const LINK_ARTICLE_TO_SEARCH_ERROR =
  'dashboard/LINK_ARTICLE_TO_SEARCH_ERROR';
export const LINK_ARTICLE_TO_SEARCH_SUCCESS =
  'dashboard/LINK_ARTICLE_TO_SEARCH_SUCCESS';
export const ADD_ARTICLE = 'dashboard/ADD_ARTICLE';
export const ADD_ARTICLE_ERROR = 'dashboard/ADD_ARTICLE_ERROR';
export const ADD_ARTICLE_SUCCESS = 'dashboard/ADD_ARTICLE_SUCCESS';
export const CLEAR_ARTICLE_LIST = 'dashboard/CLEAR_ARTICLE_LIST';
export const REMOVE_ARTICLE_FROM_LIST = 'dashboard/REMOVE_ARTICLE_FROM_LIST';
export const REMOVE_ARTICLE_FROM_LIST_SUCCESS =
  'dashboard/REMOVE_ARTICLE_FROM_LIST_SUCCESS';
export const REMOVE_ARTICLE_FROM_LIST_ERROR =
  'dashboard/REMOVE_ARTICLE_FROM_LIST_ERROR';
export const REMOVE_ARTICLE_TAG_FROM_LIST =
  'dashboard/REMOVE_ARTICLE_TAG_FROM_LIST';
export const REMOVE_ARTICLE_TAG_FROM_LIST_SUCCESS =
  'dashboard/REMOVE_ARTICLE_TAG_FROM_LIST_SUCCESS';
export const REMOVE_ARTICLE_TAG_FROM_LIST_ERROR =
  'dashboard/REMOVE_ARTICLE_TAG_FROM_LIST_ERROR';
export const REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST =
  'dashboard/REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST';
export const REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_SUCCESS =
  'dashboard/REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_SUCCESS';
export const REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_ERROR =
  'dashboard/REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_ERROR';
export const ADD_ARTICLE_CAMPAIGN_TAG = 'dashboard/ADD_ARTICLE_CAMPAIGN_TAG';
export const ADD_ARTICLE_CAMPAIGN_TAG_SUCCESS =
  'dashboard/ADD_ARTICLE_CAMPAIGN_TAG_SUCCESS';
export const ADD_ARTICLE_CAMPAIGN_TAG_ERROR =
  'dashboard/ADD_ARTICLE_CAMPAIGN_TAG_ERROR';
export const TOGGLE_MEDIA_TYPE_FILTER = 'dashboard/TOGGLE_MEDIA_TYPE_FILTER';
export const CLEAR_ALL_MEDIA_TYPE_FILTER =
  'dashboard/CLEAR_ALL_MEDIA_TYPE_FILTER';
export const SET_DRILLDOWN_DATE_RANGE = 'dashboard/SET_DRILLDOWN_DATE_RANGE';
export const SET_ARTICLE_LIST_SORT_ASCENDING =
  'dashboard/SET_ARTICLE_LIST_SORT_ASCENDING';
export const UPDATE_ARTICLE_LIST_SORT_DIRECTION =
  'UPDATE_ARTICLE_LIST_SORT_DIRECTION';
export const UPDATE_ARTICLE_LIST_SORT_FIELD = 'UPDATE_ARTICLE_LIST_SORT_FIELD';
export const DRILLDOWN_SELECT_ARTICLES = 'DRILLDOWN_SELECT_ARTICLES';
export const DRILLDOWN_CLEAR_SELECTED_ARTICLES =
  'DRILLDOWN_CLEAR_SELECTED_ARTICLES';
export const DRILLDOWN_UNSELECT_ARTICLES = 'DRILLDOWN_UNSELECT_ARTICLES';
export const DRILLDOWN_BULK_PAGE_SELECT_ALL_ARTICLES =
  'DRILLDOWN_BULK_PAGE_SELECT_ALL_ARTICLES';
export const DRILLDOWN_BULK_PAGE_UNSELECT_ALL_ARTICLES =
  'DRILLDOWN_BULK_PAGE_UNSELECT_ALL_ARTICLES';
export const ADD_EXPORT_ID = 'ADD_EXPORT_ID';
export const SET_KEYWORD_FILTER_TEXT = 'SET_KEYWORD_FILTER_TEXT';
export const SET_TAG_MODAL_VISIBLE = 'dashboard/SET_TAG_MODAL_VISIBLE';
export const SET_ADD_ARTICLE_MODAL_VISIBLE =
  'dashboard/SET_ADD_ARTICLE_MODAL_VISIBLE';
export const SET_CAMPAIGN_TAG_MODAL_VISIBLE =
  'dashboard/SET_CAMPAIGN_TAG_MODAL_VISIBLE';
export const UPDATE_DRILLDOWN_SOCIAL_DATA_ARTICLES_LIST =
  'UPDATE_DRILLDOWN_SOCIAL_DATA_ARTICLES_LIST';

export const SET_ARTICLES_SENTIMENT = 'dashboard/SET_ARTICLES_SENTIMENT';
export const SET_ARTICLES_SENTIMENT_SUCCESS =
  'dashboard/SET_ARTICLES_SENTIMENT_SUCCESS';
export const SET_ARTICLES_SENTIMENT_ERROR =
  'dashboard/SET_ARTICLES_SENTIMENT_ERROR';
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';
export const RESET_QUERY_PARAMS = 'RESET_QUERY_PARAMS';
export const UPDATE_TAGS_FOR_ARTICLE_IDS =
  'dashboard/UPDATE_TAGS_FOR_ARTICLE_IDS';
export const SET_ANALYTICS_INTEGRATION = 'SET_ANALYTICS_INTEGRATION';
export const UPDATE_ANALYTIC_INTEGRATION = 'UPDATE_ANALYTIC_INTEGRATION';
export const ON_HOVER_ARTICLE_LIST_DRILLDOWN =
  'drilldown/ON_HOVER_ARTICLE_LIST_DRILLDOWN';
export const REMOVE_TAGS_FROM_ARTICLES_SUCCESS =
  'drilldown/REMOVE_TAGS_FROM_ARTICLES_SUCCESS';

export const RESET_DRILLDOWN = 'RESET_DRILLDOWN';

export const ADD_TAGS_MODAL_TAB = 'ADD_TAGS_MODAL_TAB';
export const REMOVE_TAGS_MODAL_TAB = 'REMOVE_TAGS_MODAL_TAB';

const initialState = {
  allArticlesSelected: false,
  articles: [],
  selectedIds: [],
  exportIds: [],
  articlesLoading: false,
  error: null,
  total: 0,
  mediaTypeFilters: [],
  keywordFilterText: null,
  dateRange: {},
  sortAscending: false,
  sortColumnTitle: '',
  articlePreview: null,
  articlePreviewLoading: false,
  tagModalVisible: false,
  currentTagModalTabOpen: ADD_TAGS_MODAL_TAB,
  addArticleModalVisible: false,
  campaignTagModalVisible: false,
  cancelTokenSource: null,
  queryParams: {},
  analyticsIntegration: null,
  articlesCount: 0,
  listRowOnHoverId: null,
};

const removeTagsIdFromTagsList = (tags, tagsIdToBeRemoved) =>
  (tags || []).filter(
    tag => tag.tagId !== tagsIdToBeRemoved.find(tagId => tagId === tag.tagId),
  );

const removeTagsIdFromChildArticleTagsList = (
  childArticles,
  tagIdToBeRemoved,
) =>
  childArticles.map(childArticle => ({
    ...childArticle,
    tags: removeTagsIdFromTagsList(childArticle.tags, tagIdToBeRemoved),
  }));

const removeTagsFromArticles = (
  currentArticles,
  selectedArticleIds,
  tagsIdToBeRemoved,
) =>
  currentArticles.map(currentArticle => {
    if (isArticleDuplicated(currentArticle)) {
      return {
        ...currentArticle,
        duplicatePublications: removeTagsIdFromChildArticleTagsList(
          currentArticle.duplicatePublications,
          tagsIdToBeRemoved,
        ),
      };
    } else if (selectedArticleIds.includes(currentArticle.id)) {
      return {
        ...currentArticle,
        tags: removeTagsIdFromTagsList(currentArticle.tags, tagsIdToBeRemoved),
      };
    }
    return {
      ...currentArticle,
    };
  });

const articleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE_LIST:
      return {
        ...state,
        articlesLoading: true,
        cancelTokenSource: action.payload?.cancelTokenSource,
        error: null,
      };
    case GET_ARTICLE_LIST_SUCCESS: {
      const articles = action.payload.articles || [];
      const articlesCount = articles.reduce((acc, article) => {
        if (article.duplicatePublications?.length) {
          return acc + article.duplicatePublications.length;
        }
        return acc + 1;
      }, 0);
      return {
        ...state,
        articlesLoading: false,
        error: null,
        articles,
        articlesCount,
        total: action.payload.total,
        maxPageNum: action.payload.maxPageNum,
        cancelTokenSource: null,
      };
    }
    case GET_ARTICLE_LIST_ERROR:
      return {
        ...state,
        articlesLoading: false,
        error: action.payload.error,
      };
    case EXTRACT_ARTICLE_BY_URL:
      return {
        ...state,
        articlePreviewLoading: true,
        error: null,
      };
    case EXTRACT_ARTICLE_BY_URL_SUCCESS:
      return {
        ...state,
        articlePreviewLoading: false,
        error: null,
        articlePreview: action.payload.articlePreview,
      };
    case EXTRACT_ARTICLE_BY_URL_ERROR:
      return {
        ...state,
        articlePreviewLoading: false,
        error: action.payload.error,
        articlePreview: null,
      };
    case LINK_ARTICLE_TO_SEARCH:
    case ADD_ARTICLE:
      return {
        ...state,
        articlePreviewLoading: true,
        error: null,
      };
    case LINK_ARTICLE_TO_SEARCH_SUCCESS:
    case ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        articlePreviewLoading: false,
        error: null,
        articlePreview: {
          ...state.articlePreview,
          ...action.payload.article,
          type: 'success_added',
        },
      };
    case LINK_ARTICLE_TO_SEARCH_ERROR:
    case ADD_ARTICLE_ERROR:
      return {
        ...state,
        articlePreviewLoading: false,
        error: action.payload.error,
      };
    case UPDATE_ARTICLE_LIST_SORT_DIRECTION:
      return {
        ...state,
        sortAscending: !state.sortAscending,
      };
    case UPDATE_ARTICLE_LIST_SORT_FIELD:
      return {
        ...state,
        sortColumnTitle: action.payload.sortColumnTitle,
      };
    case CLEAR_ARTICLE_LIST:
      return {
        ...state,
        articlesLoading: false,
        articles: [],
        error: null,
        total: 0,
        dateRange: {},
      };
    case REMOVE_ARTICLE_FROM_LIST:
      return {
        ...state,
        articlesLoading: false,
        error: null,
      };
    case REMOVE_ARTICLE_FROM_LIST_SUCCESS: {
      const articles = getArticlesListAfterDelete(
        state,
        action.payload.articleIds,
      );
      return {
        ...state,
        articlesLoading: false,
        articles: articles,
        total: state.total - action.payload.articleIds.length,
        articlesCount: articles.length,
        error: null,
      };
    }
    case REMOVE_ARTICLE_FROM_LIST_ERROR:
      return {
        ...state,
        articlesLoading: false,
        error: action.payload.error,
      };
    case REMOVE_ARTICLE_TAG_FROM_LIST:
      return {
        ...state,
        error: null,
      };
    case REMOVE_ARTICLE_TAG_FROM_LIST_SUCCESS: {
      const toRemoveId = action.payload;
      return {
        ...state,
        articles: removeTagFromArticle(state.articles, toRemoveId),
        error: null,
      };
    }
    case REMOVE_ARTICLE_TAG_FROM_LIST_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST:
      return {
        ...state,
        error: null,
      };
    case REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_SUCCESS: {
      const { articleCampaignId, selectedArticleId } = action.payload;
      return {
        ...state,
        articlesLoading: false,
        articles: state.articles.map(article => {
          if (article.id === selectedArticleId) {
            return {
              ...article,
              relatedCampaigns: article.relatedCampaigns.filter(
                campaign => campaign.campaignId !== articleCampaignId,
              ),
            };
          } else {
            return {
              ...article,
            };
          }
        }),
        error: null,
      };
    }
    case REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TOGGLE_MEDIA_TYPE_FILTER:
      return {
        ...state,
        mediaTypeFilters: action.payload.filter,
      };
    case CLEAR_ALL_MEDIA_TYPE_FILTER:
      return {
        ...state,
        mediaTypeFilters: [],
      };
    case SET_DRILLDOWN_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case DRILLDOWN_SELECT_ARTICLES: {
      return {
        ...state,
        selectedIds: uniq([...state.selectedIds, ...action.payload]),
      };
    }
    case DRILLDOWN_CLEAR_SELECTED_ARTICLES: {
      return {
        ...state,
        selectedIds: [],
      };
    }
    case DRILLDOWN_UNSELECT_ARTICLES: {
      return {
        ...state,
        selectedIds: uniq(difference(state.selectedIds, action.payload)),
        allArticlesSelected: false,
      };
    }
    case DRILLDOWN_BULK_PAGE_SELECT_ALL_ARTICLES: {
      return {
        ...state,
        selectedIds: action.payload.currentSelection,
        allArticlesSelected: !action.payload.pageSelection,
      };
    }

    case DRILLDOWN_BULK_PAGE_UNSELECT_ALL_ARTICLES: {
      return {
        ...state,
        selectedIds: [],
        allArticlesSelected: false,
      };
    }
    case ADD_EXPORT_ID:
      return {
        ...state,
        exportIds: state.exportIds.concat(action.payload),
      };
    case SET_KEYWORD_FILTER_TEXT:
      return {
        ...state,
        keywordFilterText: action.payload,
      };
    case SET_TAG_MODAL_VISIBLE:
      return {
        ...state,
        tagModalVisible: action.payload.visible,
        currentTagModalTabOpen: action.payload.tagModalTabId,
      };
    case SET_ADD_ARTICLE_MODAL_VISIBLE:
      return {
        ...state,
        addArticleModalVisible: action.payload,
      };
    case SET_CAMPAIGN_TAG_MODAL_VISIBLE:
      return {
        ...state,
        campaignTagModalVisible: action.payload,
      };
    case UPDATE_DRILLDOWN_SOCIAL_DATA_ARTICLES_LIST:
      return {
        ...state,
        articles: action.newArticleList,
      };
    case SET_ARTICLES_SENTIMENT:
      return {
        ...state,
        error: null,
      };
    case SET_ARTICLES_SENTIMENT_SUCCESS:
      return {
        ...state,
        articles: state.articles.map(art => {
          if (action.payload.articles.includes(art.id)) {
            return {
              ...art,
              sentiment: action.payload.sentiment,
            };
          }
          return art;
        }),
      };
    case SET_ARTICLES_SENTIMENT_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case SET_QUERY_PARAMS: {
      return {
        ...state,
        queryParams: { ...state.queryParams, ...action.payload.queryParams },
      };
    }

    case RESET_QUERY_PARAMS: {
      return {
        ...state,
        queryParams: {},
      };
    }
    case ADD_ARTICLE_CAMPAIGN_TAG: {
      const { articleIds, currentCampaign } = action.payload;

      const updatedArticles = state.articles.map(article => {
        if (articleIds.includes(article.id)) {
          return {
            ...article,
            relatedCampaigns: article.relatedCampaigns.concat(currentCampaign),
          };
        } else {
          return { ...article };
        }
      });
      return {
        ...state,
        articles: updatedArticles,
        error: null,
      };
    }
    case ADD_ARTICLE_CAMPAIGN_TAG_SUCCESS: {
      const { articleIds, data } = action.payload;

      const updatedArticles = state.articles.map(article => {
        if (articleIds.includes(article.id)) {
          return { ...article, relatedCampaigns: data.relatedCampaigns };
        } else {
          return { ...article };
        }
      });
      return {
        ...state,
        articlesLoading: false,
        articles: updatedArticles,
        error: false,
      };
    }
    case ADD_ARTICLE_CAMPAIGN_TAG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        articles: action.payload.articlesBeforeOptimisticUpdate,
      };
    }
    case UPDATE_TAGS_FOR_ARTICLE_IDS: {
      const { articleIds, tags, overrideArticleTags } = action.payload;
      return {
        ...state,
        articles: updateTagsForArticleList(
          state.articles,
          articleIds,
          tags,
          overrideArticleTags,
        ),
      };
    }
    case SET_ANALYTICS_INTEGRATION:
      return {
        ...state,
        analyticsIntegration: action.payload,
      };
    case UPDATE_ANALYTIC_INTEGRATION:
      return {
        ...state,
        analyticsIntegration: {
          ...state.analyticsIntegration,
          ...action.payload,
        },
      };
    case ON_HOVER_ARTICLE_LIST_DRILLDOWN:
      return {
        ...state,
        listRowOnHoverId: action.payload,
      };

    case RESET_DRILLDOWN: {
      return { ...initialState };
    }

    case SET_ARTICLE_LIST_SORT_ASCENDING: {
      return { ...state, sortAscending: action.payload };
    }

    case REMOVE_TAGS_FROM_ARTICLES_SUCCESS: {
      const { selectedArticlesId, tagsIdTobeRemoved } = action.payload;
      return {
        ...state,
        articles: removeTagsFromArticles(
          state.articles,
          selectedArticlesId,
          tagsIdTobeRemoved,
        ),
        error: null,
      };
    }

    default:
      return state;
  }
};

export const setHoverArticleIdActionCreator = id => ({
  type: ON_HOVER_ARTICLE_LIST_DRILLDOWN,
  payload: id,
});

export const resetDrilldown = () => ({
  type: 'RESET_DRILLDOWN',
});

const getArticlesListAfterDelete = (state, articleIds) => {
  const syndicatedIds = [];
  let toRemoveIds = articleIds || [];
  return state.articles.filter(article => {
    if (article.duplicatePublications && article.duplicatePublications.length) {
      article.duplicatePublications = article.duplicatePublications.filter(
        article =>
          toRemoveIds.every(toRemove => {
            if (article.id === toRemove) {
              syndicatedIds.push(article.id);
            }
            return article.id !== toRemove;
          }),
      );
      // syndicated article IDs must be removed because id is the same as parent for the first case.
      toRemoveIds = difference(toRemoveIds, syndicatedIds);
    }
    return toRemoveIds.every(toRemove => article.id !== toRemove);
  });
};

const createTagForArticle = (articleId, tag) => ({
  articleId: articleId,
  id: tag.id,
  tag: tag.tag.tag,
  tagId: tag.tag.id,
  tagType: tag.type,
});

const buildNewTagsForArticle = (
  articleId,
  overrideArticleTags,
  newTagsSelected,
) => {
  const filteredTagsForArticle = !overrideArticleTags
    ? newTagsSelected.filter(tag => tag.articleId.toString() === articleId)
    : newTagsSelected;
  return filteredTagsForArticle.map(tag => createTagForArticle(articleId, tag));
};

const getTagsForArticle = (article, newTagsSelected, overrideArticleTags) => {
  const buildNewTags = buildNewTagsForArticle(
    article.id,
    overrideArticleTags,
    newTagsSelected,
  );
  if (overrideArticleTags) {
    return [...buildNewTags];
  } else {
    return [...(article.tags || []), ...buildNewTags];
  }
};

const updateChildArticleList = (
  childArticles,
  newTagsSelected,
  overrideArticleTags,
  selectedArticleIds,
) =>
  childArticles.map(currentChildArticle => {
    const shouldUpdateCurrentChildArticle = selectedArticleIds.includes(
      currentChildArticle.id,
    );
    if (!shouldUpdateCurrentChildArticle) {
      return currentChildArticle;
    } else {
      return {
        ...currentChildArticle,
        tags: getTagsForArticle(
          currentChildArticle,
          newTagsSelected,
          overrideArticleTags,
        ),
      };
    }
  });

const updateTagsForArticleList = (
  currentStoredArticles,
  selectedArticleIds,
  newTagsSelected,
  overrideArticleTags,
) =>
  currentStoredArticles.map(currentArticle => {
    const isDuplicated = isArticleDuplicated(currentArticle);
    if (isDuplicated) {
      return {
        ...currentArticle,
        duplicatePublications: updateChildArticleList(
          currentArticle.duplicatePublications,
          newTagsSelected,
          overrideArticleTags,
          selectedArticleIds,
        ),
      };
    } else if (selectedArticleIds.includes(currentArticle.id)) {
      return {
        ...currentArticle,
        tags: getTagsForArticle(
          currentArticle,
          newTagsSelected,
          overrideArticleTags,
        ),
      };
    }
    return {
      ...currentArticle,
    };
  });

const removeTagIdFromTagsList = (tags, tagIdToBeRemoved) =>
  tags.filter(tag => tag.id !== tagIdToBeRemoved);

const removeTagIdFromChildArticleTagsList = (childArticles, tagIdToBeRemoved) =>
  childArticles.map(childArticle => ({
    ...childArticle,
    tags: removeTagIdFromTagsList(childArticle.tags, tagIdToBeRemoved),
  }));

const removeTagFromArticle = (currentArticles, tagIdToBeRemoved) =>
  currentArticles.map(article => {
    if (isArticleDuplicated(article)) {
      return {
        ...article,
        duplicatePublications: removeTagIdFromChildArticleTagsList(
          article.duplicatePublications,
          tagIdToBeRemoved,
        ),
      };
    }
    return {
      ...article,
      tags: removeTagIdFromTagsList(article.tags, tagIdToBeRemoved),
    };
  });

export const clearSelectedArticlesInDrilldown = () => ({
  type: DRILLDOWN_CLEAR_SELECTED_ARTICLES,
});

export const selectArticlesInDrilldown = (ids, intl) => (
  dispatch,
  getState,
) => {
  const userCanUseBulkTagging = getCanUseBulkTagging();
  const state = getState();
  if (userCanUseBulkTagging) {
    const { selectedIds } = articlesSelectionDataSelector(state);
    if (selectedIds.length === MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(drilldownMessages.bulkLimitExceededWarning, {
            BULK_LIMIT: MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION,
          }),
          status: 'danger',
        }),
      );
      return;
    }
  }
  dispatch({
    type: DRILLDOWN_SELECT_ARTICLES,
    payload: ids,
  });
};

export const unselectArticlesInDrilldown = ids => ({
  type: DRILLDOWN_UNSELECT_ARTICLES,
  payload: ids,
});

export const bulkSelectAllArticlesInDrilldown = intl => (
  dispatch,
  getState,
) => {
  const userCanUseBulkTagging = getCanUseBulkTagging();
  const state = getState();
  const uniqueArticleList = uniqueArticleListSelector(state);
  if (userCanUseBulkTagging) {
    if (uniqueArticleList?.length > MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(drilldownMessages.bulkLimitExceededWarning, {
            BULK_LIMIT: MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION,
          }),
          status: 'danger',
        }),
      );
      return;
    }
  }

  dispatch({
    type: DRILLDOWN_BULK_PAGE_SELECT_ALL_ARTICLES,
    payload: {
      pageSelection: getCanUseBulkTagging(),
      currentSelection: uniqueArticleList,
    },
  });
};

export const bulkUnselectAllArticlesInDrilldown = () => ({
  type: DRILLDOWN_BULK_PAGE_UNSELECT_ALL_ARTICLES,
});

export const getArticleListActionCreator = ({
  widgetId,
  startDate,
  endDate,
  pageNumber,
  searchId,
  mediaTypeFilters = [],
  dataPoint,
  keywordFilterText,
  sort = '',
  reportUuid = '',
  pageSize,
  widgetType,
  intl,
  checkDeletedDashboards = false,
}) => async dispatch => {
  const cancelTokenSource = getCancelTokenSource();
  const userCanUseBulkTagging = getCanUseBulkTagging();

  dispatch({
    type: GET_ARTICLE_LIST,
    payload: {
      cancelTokenSource,
    },
  });
  const queryParams = {
    startDate,
    endDate,
    pageNumber,
    searchId,
    viewFilters: mediaTypeFilters,
    keywordFilterText,
    dataPoint,
    sort,
    pageSize,
  };

  if (checkDeletedDashboards) {
    queryParams.checkDeletedDashboards = true;
  }

  try {
    const path = reportUuid
      ? `${NEW_REPORT_ENDPOINT}/${reportUuid}/widget`
      : WIDGET_V3_ENDPOINT;
    const result = await performGet(
      `${path}/${widgetId}/articles`,
      queryParams,
      null,
      { cancelToken: cancelTokenSource.token },
    );

    let articles = result.data.results || [];
    if (intl && window.activeUser.language === 'fr-ca') {
      articles = articles.map(article => {
        if (article.data_source?.toLowerCase() === 'tveyes') {
          const title = intl.formatMessage(
            drilldownMessages.tvEyesRadioClipFromPublisherTitle,
            {
              PUBLISHER: article.publisher,
              DATE: intl.formatDate(
                moment(article.publishDate).valueOf(),
                DEFAULT_DATE_FORMAT_INTL,
              ),
            },
          );
          return { ...article, title };
        }

        return article;
      });
    }

    dispatch({
      type: GET_ARTICLE_LIST_SUCCESS,
      payload: { articles, total: result.data.total },
    });
    if (!userCanUseBulkTagging) {
      dispatch(bulkUnselectAllArticlesInDrilldown());
    }
  } catch (e) {
    if (!isRequestCancel(e)) {
      dispatch({ type: GET_ARTICLE_LIST_ERROR, payload: { error: e } });
    }
  }
};

export const cancelArticleListRequest = () => (dispatch, getState) => {
  const state = getState();
  const articleList = state.dashboard.articleList;
  if (articleList.cancelTokenSource && articleList.articlesLoading) {
    articleList.cancelTokenSource.cancel('api request cancelled');
  }
};

export const toggleSortDirection = () => ({
  type: UPDATE_ARTICLE_LIST_SORT_DIRECTION,
});

export const setSortField = sortField => ({
  type: UPDATE_ARTICLE_LIST_SORT_FIELD,
  payload: { sortColumnTitle: sortField },
});

export const setSortAscending = sortAscending => ({
  type: SET_ARTICLE_LIST_SORT_ASCENDING,
  payload: sortAscending,
});

export const setSortFieldAndDirection = (
  sortField,
  sortAscending,
) => dispatch => {
  dispatch(setSortField(sortField));
  dispatch(setSortAscending(sortAscending));
};

export const clearArticleList = () => ({ type: CLEAR_ARTICLE_LIST });

export const removeArticleFromListActionCreator = (
  { articleIds, searchId, tagId, intl },
  callback = () => {},
) => async dispatch => {
  dispatch({ type: REMOVE_ARTICLE_FROM_LIST });
  const requestConfig = tagId
    ? {
        url: DELETE_ARTICLE_FROM_TAG_ENDPOINT.replace('{tagId}', tagId),
        data: {
          articlesIds: articleIds,
        },
      }
    : {
        url: ARTICLE_DELETE_ENDPOINT,
        data: {
          ids: articleIds,
          searchId,
        },
      };

  try {
    await performPost(requestConfig.url, requestConfig.data);
    dispatch({
      type: REMOVE_ARTICLE_FROM_LIST_SUCCESS,
      payload: { articleIds },
    });
    dispatch({
      type: DRILLDOWN_BULK_PAGE_UNSELECT_ALL_ARTICLES,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(globalMessages.deleteArticlesSuccess, {
          TOTAL_ARTICLES_COUNT: articleIds.length,
        }),
        status: 'success',
      }),
    );

    callback();
  } catch (e) {
    dispatch({ type: REMOVE_ARTICLE_FROM_LIST_ERROR, payload: { error: e } });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(globalMessages.deleteArticlesError, {
          TOTAL_ARTICLES_COUNT: articleIds.length,
        }),
        status: 'danger',
      }),
    );
  }
};

export const deleteTagByIdActionCreator = articleTagId => async dispatch => {
  dispatch({ type: REMOVE_ARTICLE_TAG_FROM_LIST });
  await performDelete(`${ARTICLE_TAG_ENDPOINT}/${articleTagId}/`)
    .then(() => {
      dispatch({
        type: REMOVE_ARTICLE_TAG_FROM_LIST_SUCCESS,
        payload: articleTagId,
      });
    })
    .catch(err =>
      dispatch({ type: REMOVE_ARTICLE_TAG_FROM_LIST_ERROR, payload: err }),
    );
};

export const deleteCampaignTagByIdActionCreator = (
  articleCampaignId,
  selectedArticleId,
) => async dispatch => {
  dispatch({ type: REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST });
  try {
    await performDelete(
      `${ARTICLE_TAG_ENDPOINT}/campaign/${articleCampaignId}/`,
      { articleId: selectedArticleId },
    );
    dispatch({
      type: REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_SUCCESS,
      payload: {
        articleCampaignId,
        selectedArticleId,
      },
    });
  } catch (e) {
    dispatch({
      type: REMOVE_ARTICLE_CAMPAIGN_TAG_FROM_LIST_ERROR,
      payload: e,
    });
  }
};

export const toggleMediaTypeFilter = filter => ({
  type: TOGGLE_MEDIA_TYPE_FILTER,
  payload: { filter },
});

export const clearAllMediaTypeFilter = () => ({
  type: CLEAR_ALL_MEDIA_TYPE_FILTER,
});

export const setDrilldownDateRange = (startDate, endDate) => ({
  type: SET_DRILLDOWN_DATE_RANGE,
  payload: { startDate, endDate },
});

export const addExportIdToArticleList = exportId => ({
  type: ADD_EXPORT_ID,
  payload: exportId,
});

export const setKeywordFilterText = text => ({
  type: SET_KEYWORD_FILTER_TEXT,
  payload: text,
});

export const extractArticleByUrlActionCreator = ({
  url,
  searchId,
  widgetId,
}) => async dispatch => {
  dispatch({ type: EXTRACT_ARTICLE_BY_URL });
  const queryParams = {
    url,
    searchId,
    widgetId,
  };

  const endpoint = ADD_ARTICLE_FLOW_ENDPOINTS_MAP.get;
  try {
    const result = await performGet(endpoint, queryParams);
    dispatch({
      type: EXTRACT_ARTICLE_BY_URL_SUCCESS,
      payload: { articlePreview: result.data },
    });
  } catch (e) {
    dispatch({ type: EXTRACT_ARTICLE_BY_URL_ERROR, payload: { error: e } });
  }
};

export const saveArticleActionCreator = ({
  searchId,
  widgetId,
  article,
  articleParameters,
  onSuccessHandler,
  intl,
}) => async dispatch => {
  dispatch({ type: ADD_ARTICLE });
  const queryParams = {
    searchId,
    widgetId,
  };

  const endpoint = ADD_ARTICLE_FLOW_ENDPOINTS_MAP.save;

  try {
    const result = await performPost(endpoint, article, queryParams);
    dispatch({ type: ADD_ARTICLE_SUCCESS, payload: { article: result.data } });

    if (onSuccessHandler) {
      onSuccessHandler();
      return;
    }

    dispatch(
      getArticleListActionCreator({
        widgetId,
        searchId,
        intl,
        ...articleParameters,
      }),
    );
  } catch (e) {
    dispatch({ type: ADD_ARTICLE_ERROR, payload: { error: e } });
  }
};

export const linkArticleToSearchActionCreator = ({
  searchId,
  widgetId,
  article,
  articleParameters,
  onSuccessHandler,
  intl,
}) => async dispatch => {
  dispatch({ type: LINK_ARTICLE_TO_SEARCH });
  const queryParams = {
    searchId,
    widgetId,
  };

  const endpoint = ADD_ARTICLE_FLOW_ENDPOINTS_MAP.linkToSearch;

  try {
    const result = await performPost(endpoint, article, queryParams);
    dispatch({
      type: LINK_ARTICLE_TO_SEARCH_SUCCESS,
      payload: { article: result.data },
    });

    if (onSuccessHandler) {
      onSuccessHandler();
      return;
    }

    dispatch(
      getArticleListActionCreator({
        widgetId,
        searchId,
        intl,
        ...articleParameters,
      }),
    );
  } catch (e) {
    dispatch({ type: LINK_ARTICLE_TO_SEARCH_ERROR, payload: { error: e } });
  }
};

export const setQueryParams = queryParams => ({
  type: SET_QUERY_PARAMS,
  payload: { queryParams },
});
const sumNewSocialData = newSocialData => {
  return (
    newSocialData.facebook_total_count +
    newSocialData.googleplus +
    newSocialData.linkedin +
    newSocialData.pinterest +
    newSocialData.reddit +
    newSocialData.twitter
  );
};

export const getArticleList = () => ({ type: GET_ARTICLE_LIST });

export const getArticleListError = payload => ({
  type: GET_ARTICLE_LIST_ERROR,
  payload,
});

export const getArticleListSuccess = payload => ({
  type: GET_ARTICLE_LIST_SUCCESS,
  payload,
});

const returnSocialDataUpdatedArticle = (selectedArticle, newSocialData) => {
  selectedArticle.socialFacebookTotal = newSocialData.facebook_total_count;
  selectedArticle.socialGoogleplus = newSocialData.googleplus;
  selectedArticle.socialLinkedin = newSocialData.linkedin;
  selectedArticle.socialPinterest = newSocialData.pinterest;
  selectedArticle.socialReddit = newSocialData.reddit;
  selectedArticle.socialRefreshDate = Math.floor(
    new Date(newSocialData.refreshDate).getTime() / 1000,
  );
  selectedArticle.socialTwitter = newSocialData.twitter;
  selectedArticle.socialSum = sumNewSocialData(newSocialData);
  return selectedArticle;
};

const returnUpdatedArticleList = (oldArticleList, newArticleList) => {
  let indexNewList = 0;
  return oldArticleList.map(article => {
    if (article.id !== newArticleList[indexNewList]?.id) {
      return article;
    }

    const newArticle = newArticleList[indexNewList];

    indexNewList =
      indexNewList < newArticleList.length - 1 ? ++indexNewList : indexNewList;

    return {
      ...article,
      ...newArticle,
    };
  });
};

const updateMultipleArticlesSocialData = (articleList, state) =>
  returnUpdatedArticleList(
    state.dashboard.articleList.articles,
    articleList.map(article => {
      return returnSocialDataUpdatedArticle(
        article,
        state.socialAmp.data[article.id],
      );
    }),
  );

export const refreshSocialDataForSelectedDrilldownArticles = (
  selectedArticlesList,
  intl,
) => async (dispatch, getState) => {
  await refreshSocialDataForArticles(selectedArticlesList, intl)(
    dispatch,
    getState,
  );
  const state = getState();
  const newList = updateMultipleArticlesSocialData(selectedArticlesList, state);
  dispatch({
    type: UPDATE_DRILLDOWN_SOCIAL_DATA_ARTICLES_LIST,
    newArticleList: newList,
  });
};

export const setModalVisibleActionCreator = (
  visible,
  tagModalTabId = ADD_TAGS_MODAL_TAB,
) => ({
  type: SET_TAG_MODAL_VISIBLE,
  payload: { visible: visible, tagModalTabId: tagModalTabId },
});

export const setAddArticleVisibleActionCreator = visible => ({
  type: SET_ADD_ARTICLE_MODAL_VISIBLE,
  payload: visible,
});

export const setCampaignModalVisibleActionCreator = visible => ({
  type: SET_CAMPAIGN_TAG_MODAL_VISIBLE,
  payload: visible,
});

export const setArticlesSentiment = (
  articleIds,
  sentiment,
  intl = null,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ARTICLES_SENTIMENT });
    await performPatch(ARTICLES_UPDATE_SENTIMENT_ENDPOINT, {
      articleIds,
      sentiment: sentiment.toUpperCase(),
    });
    dispatch({
      type: SET_ARTICLES_SENTIMENT_SUCCESS,
      payload: { articles: articleIds, sentiment },
    });
    if (intl) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(
            constantMessages.constantsSetSentimentArticleSuccess,
            {
              TOTAL_ARTICLES_COUNT: intl.formatNumber(articleIds.length),
              SENTIMENT: sentiment,
            },
          ),
          status: 'success',
        }),
      );
    }
  } catch (e) {
    dispatch({ type: SET_ARTICLES_SENTIMENT_ERROR, payload: { error: e } });
    if (intl) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(
            constantMessages.constantsSetSentimentArticleError,
            {
              TOTAL_ARTICLES_COUNT: intl.formatNumber(articleIds.length),
              SENTIMENT: sentiment,
            },
          ),
          status: 'error',
        }),
      );
    }
  }
};

export const addArticleCampaignTag = (
  articleIds,
  campaignIds,
  currentCampaignId,
  currentCampaignTitle,
) => async (dispatch, getState) => {
  const payload = {
    selectedArticles: articleIds,
    campaignIds: campaignIds,
  };
  const state = getState();
  const articlesBeforeOptimisticUpdate = state.dashboard.articleList.articles;

  try {
    dispatch({
      type: ADD_ARTICLE_CAMPAIGN_TAG,
      payload: {
        currentCampaign: {
          campaignId: currentCampaignId,
          campaignTitle: currentCampaignTitle,
        },
        articleIds: articleIds,
      },
    });
    const addCampaignTagToArticle = await performPost(
      ADD_CAMPAIGN_TAGS_TO_ARTICLES_ENDPOINT,
      payload,
    );
    dispatch({
      type: ADD_ARTICLE_CAMPAIGN_TAG_SUCCESS,
      payload: {
        data: addCampaignTagToArticle.data,
        articleIds: articleIds,
      },
    });
  } catch (e) {
    dispatch({
      type: ADD_ARTICLE_CAMPAIGN_TAG_ERROR,
      payload: {
        error: e,
        articlesBeforeOptimisticUpdate: articlesBeforeOptimisticUpdate,
      },
    });
  }
};

export const updateTagsForArticleIds = (
  articleIds,
  tags,
  overrideArticleTags,
) => ({
  type: UPDATE_TAGS_FOR_ARTICLE_IDS,
  payload: {
    articleIds,
    tags,
    overrideArticleTags,
  },
});

export const removeTagsForArticles = (
  selectedArticlesId,
  tagsIdTobeRemoved,
) => ({
  type: REMOVE_TAGS_FROM_ARTICLES_SUCCESS,
  payload: {
    selectedArticlesId,
    tagsIdTobeRemoved,
  },
});

export const setAnalyticIntegration = analyticIntegration => ({
  type: SET_ANALYTICS_INTEGRATION,
  payload: analyticIntegration,
});

export const updateAnalyticIntegration = analyticIntegrationData => ({
  type: UPDATE_ANALYTIC_INTEGRATION,
  payload: analyticIntegrationData,
});

export default articleListReducer;
