import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TextTruncate extends Component {
  static propTypes = {
    block: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    block: false,
    className: '',
  };

  render() {
    const { block, className, ...otherProps } = this.props;
    const baseClass = 'tk-text-truncate';
    const classes = classNames(baseClass, className);

    if (block) {
      return <div {...otherProps} className={classes} />;
    }

    return <span {...otherProps} className={classes} />;
  }
}

export default TextTruncate;
