import React, { Component } from 'react';

import globalMessages from 'i18n/Global.messages';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Button from '../button';
import ComponentType from '../component-type';

class CollapsibleList extends Component {
  static getDerivedStateFromProps(props, state) {
    const prevCollapsed = state.prevCollapsed;
    if (prevCollapsed !== props.collapsed) {
      return {
        collapsed: props.collapsed,
        prevCollapsed: props.collapsed,
      };
    }

    return null;
  }

  state = {
    collapsed: this.props.collapsed,
    prevCollapsed: this.props.collapsed,
  };

  onToggleCollapsed = () => {
    const { onToggleCollapsed } = this.props;
    const { collapsed } = this.state;
    onToggleCollapsed(!collapsed);
    this.setState(state => ({ collapsed: !state.collapsed }));
  };

  renderToggleComponent = ({
    children,
    collapsed,
    collapsedLimit,
    onToggleCollapsed,
    ToggleComponent,
    toggleComponentWrapper,
  }) => {
    const { intl } = this.props;
    let renderedComponent = null;

    if (ToggleComponent) {
      renderedComponent = (
        <ToggleComponent
          collapsed={collapsed}
          collapsedLimit={collapsedLimit}
          onToggleCollapsed={onToggleCollapsed}
        >
          {children}
        </ToggleComponent>
      );
    }

    if (!renderedComponent) {
      renderedComponent = (
        <Button
          data-qa="DC-twkGfyjnjfeUtLuYWG"
          key="collapsible-list__toggle"
          modifiers={['link', 'small']}
          onClick={onToggleCollapsed}
        >
          {collapsed &&
            intl.formatMessage(globalMessages.moreItems, {
              ITEMS_COUNT: intl.formatNumber(children.length - collapsedLimit),
            })}
          {!collapsed && intl.formatMessage(globalMessages.showLess)}
        </Button>
      );
    }

    if (toggleComponentWrapper) {
      return toggleComponentWrapper({ children: renderedComponent });
    }

    return renderedComponent;
  };

  render() {
    const { collapsedLimit, children: totalChildren } = this.props;

    const { collapsed } = this.state;

    const toggleComponent = this.renderToggleComponent({
      ...this.props,
      collapsed,
      onToggleCollapsed: this.onToggleCollapsed,
    });
    let children = [...totalChildren];

    if (collapsed && totalChildren.length > collapsedLimit) {
      children = children.slice(0, collapsedLimit);
    }

    if (totalChildren.length > collapsedLimit) {
      children.push(toggleComponent);
    }

    return children;
  }
}

CollapsibleList.propTypes = {
  /** Force collapsed state from parent */
  collapsed: PropTypes.bool,
  /** How many items appear when the list is collapsed */
  collapsedLimit: PropTypes.number,
  /** Array of all items. Each node needs a `key` prop so it can be rendered as an array. */
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  /** Callback in case you need the parent to be aware of collapsed / expanded state */
  onToggleCollapsed: PropTypes.func,
  /** Optional custom toggle component */
  ToggleComponent: ComponentType, // eslint-disable-line react/no-unused-prop-types
  /** Optional custom toggle component wrapper if you want to add styles without changing default behavior */
  toggleComponentWrapper: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    formatNumber: PropTypes.func,
  }).isRequired,
};

CollapsibleList.defaultProps = {
  collapsed: true,
  collapsedLimit: 10,
  onToggleCollapsed: () => {},
  ToggleComponent: null,
  toggleComponentWrapper: null,
};

export default injectIntl(CollapsibleList);
