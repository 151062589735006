import omit from 'lodash/omit';
import moment from 'moment';
import qs from 'qs';

import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import {
  SET_ARTICLE_LIST_ARTICLES,
  SET_ARTICLE_LIST_LOADING,
} from 'reducers/article-lists';
import { flattenArticles, GET_ARTICLES_RECEIVED } from 'reducers/articles';

import { currentArticleListSelector } from 'selectors/article-lists';
import { getApiQueryParamsFromList } from 'services/article-lists/article-lists';
import { performGet } from 'services/rest-service/rest-service';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const articlesReducerFactory = namespace => {
  const GET_ARTICLES_START = `ihub/${namespace}/GET_ARTICLES_START`;
  const GET_ARTICLES_SUCCESS = `ihub/${namespace}/GET_ARTICLES_SUCCESS`;
  const GET_ARTICLES_ERROR = `ihub/${namespace}/GET_ARTICLES_ERROR`;

  const articlesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_ARTICLES_START:
        return {
          ...state,
          loading: true,
          error: false,
          data: [],
        };

      case GET_ARTICLES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: [...action.payload],
        };

      case GET_ARTICLES_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  const getArticleListByInfluencerId = (id, customUrlParams = {}) => async (
    dispatch,
    getState,
  ) => {
    const state = getState();
    const articleList = currentArticleListSelector(state);
    const articleListQueryParams = getApiQueryParamsFromList(articleList);

    const data = {
      ...omit(articleListQueryParams, [
        'analyticsIntegrationId',
        'rangeType',
        'selectedSearchId',
      ]),
      dataPoint: undefined,
      startDate: moment.utc(articleList.startDate).valueOf(),
      endDate: moment.utc(articleList.endDate).valueOf(),
      detailed: false,
      removeDuplicates: false,
      isOutlet: false,
      ...customUrlParams,
    };

    const urlParams = qs.stringify(data, {
      indices: false,
      addQueryPrefix: true,
    });

    dispatch({ type: GET_ARTICLES_START });
    dispatch({
      type: SET_ARTICLE_LIST_LOADING,
      payload: { id: articleList.id },
    });
    let payload;

    try {
      payload = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/influencer/${id}/articles${urlParams}`,
      );
      dispatch({ type: GET_ARTICLES_SUCCESS, payload: payload.data });

      // const totalArticleCount = parseInt(paging.totalNumResults, 10) || 0;
      const { articleClipsAsMap, articleIds } = flattenArticles(payload.data);

      dispatch({
        type: GET_ARTICLES_RECEIVED,
        payload: {
          articles: articleClipsAsMap,
        },
      });

      dispatch({
        type: SET_ARTICLE_LIST_ARTICLES,
        payload: {
          id: articleList.id,
          articleIds,
          totalArticleCount: articleIds.length, // TODO: update when pagination available on response
        },
      });
    } catch (e) {
      dispatch({ type: GET_ARTICLES_ERROR, payload: e });
    }
  };

  const getRecentArticlesByInfluencerId = (
    id,
    params = {},
  ) => async dispatch => {
    dispatch({ type: GET_ARTICLES_START });
    let payload;
    try {
      const safeId = id.indexOf('mock-') === 0 ? id.substr(5) : id;
      payload = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/influencer/${safeId}/articles`,
        {
          isOutlet: false,
          ...params,
        },
      );
      dispatch({ type: GET_ARTICLES_SUCCESS, payload: payload.data });
    } catch (e) {
      dispatch({ type: GET_ARTICLES_ERROR, payload: e });
    }
  };

  return {
    GET_ARTICLES_START,
    GET_ARTICLES_SUCCESS,
    GET_ARTICLES_ERROR,
    articlesReducer,
    getArticleListByInfluencerId,
    getRecentArticlesByInfluencerId,
  };
};

export default articlesReducerFactory;
