import React from 'react';

import PropTypes from 'prop-types';

import { SvgIcon, withModifiers, WysiwygDisplay } from '@trendkite/ui';

const EmailBodyDisplay = ({ children, className, onClick }) => {
  return (
    <div className={`${className}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={`${EmailBodyDisplay.baseClass}__edit-icon`}
        onClick={onClick}
        role="button"
        tabIndex={0}
        data-qa="RCVhaxn4C32cbW6kCQZL2"
      >
        <SvgIcon icon="pencil" />
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        data-qa="22eMtqxIedcguPPnxHqX5"
        role="textbox"
        tabIndex={0}
        className={`${EmailBodyDisplay.baseClass}__content`}
        onClick={onClick}
      >
        <WysiwygDisplay content={children} />
      </div>
    </div>
  );
};

EmailBodyDisplay.baseClass = 'tk-email-body-display';

EmailBodyDisplay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

EmailBodyDisplay.defaultProps = {
  className: 'tk-email-body-display',
  onClick: () => {},
};

export default withModifiers(EmailBodyDisplay);
