import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const FloatingPanel = ({ children, modifiers, styles }) => {
  const baseClass = 'tk-floating-panel';
  const prefixedModifiers = Array.isArray(modifiers)
    ? modifiers.map(modifier => `${baseClass}--${modifier}`)
    : [];

  const mainClass = classNames(baseClass, prefixedModifiers);

  return (
    <div className={mainClass} style={styles}>
      {children}
    </div>
  );
};

FloatingPanel.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  styles: PropTypes.object,
};

FloatingPanel.defaultProps = {
  children: <span />,
  modifiers: [],
  styles: {},
};

export const FloatingPanelContent = ({ children }) => {
  const baseClass = 'tk-floating-panel__content';

  const mainClass = baseClass;

  return <div className={mainClass}>{children}</div>;
};

FloatingPanelContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FloatingPanel;
