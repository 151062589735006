import React, { useEffect, useMemo, useState } from 'react';

import { Button } from '@cision/rover-ui';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Kite, GridTable, SvgIcon, SearchInput } from '@trendkite/ui/index';

import SimplePagination from 'components/simple-pagination';
import { ADMIN_FEED_URL } from 'constants/constants';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';
import { FEED_ACTION_STATUSES } from 'pages/Admin/FeedManagement/FeedManagement';
import {
  deleteFeedActionCreator,
  getFeedsActionCreator,
} from 'reducers/admin/feed-management';
import { adminFeedActionStatusSelector } from 'selectors/admin/feed-management';

import adminStyles from '../../AdminTable.module.css';
import styles from '../FeedManagement.module.css';

import messages from '../Feeds.messages';

import FeedRow, { Feed } from './FeedRow/FeedRow';

interface Props {
  feeds: Feed[];
  initialLoading: boolean;
  paginator: {
    totalCount: number;
    pageSize: number;
    currentPage: number;
  };
  onGetFeeds(args?: Record<string, string | number>): void;
}

const FeedTable = ({ feeds, initialLoading, paginator, onGetFeeds }: Props) => {
  const { formatMessage } = useIntl();

  const [currentSort, setCurrentSort] = useState('createdOn');
  const [sortDirection, setSortDirection] = useState('desc');
  const [accountId, setAccountId] = useState('');

  const feedActionTranslations = {
    [FEED_ACTION_STATUSES.CREATE_SUCCESS]: messages.createFeedSuccessMessage,
    [FEED_ACTION_STATUSES.CREATE_FAILURE]: messages.createFeedFailureMessage,
    [FEED_ACTION_STATUSES.DELETE_SUCCESS]: messages.deleteFeedSuccessMessage,
    [FEED_ACTION_STATUSES.DELETE_FAILURE]: messages.deleteFeedFailureMessage,
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  const { push } = useHistory();

  const feedActionStatus = useSelector(adminFeedActionStatusSelector);

  const pagingLabel = useMemo(() => {
    return formatMessage(globalMessages.pagingLabel, {
      START_INDEX: (paginator.currentPage - 1) * paginator.pageSize + 1,
      END_INDEX: Math.min(
        paginator.currentPage * paginator.pageSize,
        paginator.totalCount,
      ),
      TOTAL: paginator.totalCount,
    });
  }, [paginator, formatMessage]);

  const getColumnTitles = () => {
    return [
      {
        content: '',
        key: 'select',
      },
      {
        content: intl.formatMessage(messages.feedId),
        isHeader: true,
        sortable: true,
        isSorting: currentSort === 'uuid',
        sortDirection,
        key: 'uuid',
      },
      {
        content: intl.formatMessage(messages.feedLink),
        isHeader: true,
        sortable: true,
        isSorting: currentSort === 'link',
        sortDirection,
        key: 'link',
      },
      {
        content: intl.formatMessage(messages.createdOn),
        isHeader: true,
        sortable: true,
        isSorting: currentSort === 'createdOn',
        sortDirection,
        key: 'createdOn',
      },
      {
        content: intl.formatMessage(messages.modifiedOn),
        isHeader: true,
        sortable: true,
        isSorting: currentSort === 'modifiedOn',
        sortDirection,
        key: 'modifiedOn',
      },
      {
        content: intl.formatMessage(messages.widgetTitleId),
        isHeader: true,
        sortable: true,
        isSorting: currentSort === 'widgetTitle',
        sortDirection,
        key: 'widgetTitle',
      },
      {
        content: intl.formatMessage(messages.dashboardAccountId),
        isHeader: true,
        sortable: true,
        isSorting: currentSort === 'accountId',
        sortDirection,
        key: 'accountId',
      },
      {
        content: '',
        key: 'actions',
      },
    ];
  };

  const columnStyle =
    '50px minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 0.6fr) minmax(100px, 0.6fr) minmax(50px, 0.5fr) minmax(50px, 0.5fr) minmax(50px, 0.2fr)';

  const getNegativeDirection = dir => (dir === 'asc' ? 'desc' : 'asc');

  const clearFilter = () => {
    onGetFeeds();
  };

  const handleTableSort = key => {
    setCurrentSort(key);
    const newSortDirection =
      currentSort === key ? getNegativeDirection(sortDirection) : sortDirection;
    setSortDirection(newSortDirection);

    onGetFeeds({
      sort: key,
      order: newSortDirection,
      ...(accountId ? { accountId } : {}),
    });
  };

  const handlePageChange = ({ selected }) => {
    const pageNumber = selected + 1;
    onGetFeeds({
      offset: pageNumber === 1 ? 0 : (pageNumber - 1) * paginator.pageSize,
      sort: currentSort,
      order: sortDirection,
      ...(accountId ? { accountId } : {}),
    });
  };

  const handleFilterCallback = filterValue => {
    if (filterValue) {
      onGetFeeds({ accountId: filterValue });
    } else {
      clearFilter();
    }
  };

  const handleInputSearchChange = e => {
    const { value } = e.target;
    setAccountId(value);
    if (!value) {
      clearFilter();
    }
  };

  const onDeleteFeed = id => {
    dispatch(deleteFeedActionCreator(id, intl));
  };

  const onNewFeed = () => {
    push(ADMIN_FEED_URL);
  };

  useEffect(() => {
    if (
      initialLoading ||
      feedActionStatus === FEED_ACTION_STATUSES.CREATE_SUCCESS
    ) {
      dispatch(getFeedsActionCreator({}, intl));
    }
  }, [feedActionStatus]);

  const FeedActionSuccessKiteComponent = () => {
    const showKite =
      feedActionStatus === FEED_ACTION_STATUSES.CREATE_SUCCESS ||
      feedActionStatus === FEED_ACTION_STATUSES.DELETE_SUCCESS;

    return (
      <Kite shouldShow={showKite} status="success" ttl={4000}>
        <TranslatedMessage {...feedActionTranslations[feedActionStatus]} />
      </Kite>
    );
  };

  const FeedActionFailureKiteComponent = () => {
    const showKite =
      feedActionStatus === FEED_ACTION_STATUSES.CREATE_FAILURE ||
      feedActionStatus === FEED_ACTION_STATUSES.DELETE_FAILURE;

    return (
      <Kite shouldShow={showKite} status="danger" ttl={4000}>
        <TranslatedMessage {...feedActionTranslations[feedActionStatus]} />
      </Kite>
    );
  };

  return (
    <>
      <div className={adminStyles.adminActionsSection}>
        <div className={adminStyles.adminTableSearchBar}>
          <SearchInput
            extraButton
            modifiers={['leftbutton']}
            handleChange={handleInputSearchChange}
            performSearchCallback={handleFilterCallback}
            placeholder={intl.formatMessage(messages.feedSearchPlaceholder)}
          />
        </div>
        <Button
          data-qa="g_0scSEC7E6NvFb3IRld-"
          level="primary"
          className={styles.newFeedButton}
          onClick={onNewFeed}
        >
          <TranslatedMessage {...messages.newFeed} />
        </Button>
      </div>
      {paginator?.currentPage > 0 && (
        <div
          className={classNames(
            adminStyles.listControlsItem,
            adminStyles.paginationControls,
          )}
        >
          <SimplePagination
            forcePage={paginator.currentPage - 1}
            pageCount={Math.ceil(paginator.totalCount / paginator.pageSize)}
            label={pagingLabel}
            pageChange={handlePageChange}
            labelElementClassName="SavedListTable__active-link"
          />
        </div>
      )}
      <GridTable
        columns={getColumnTitles()}
        columnStyle={columnStyle}
        onHeaderClick={handleTableSort}
        isSticky
        stickyHeaderAt={0}
        isHeader
      >
        {!initialLoading && feeds.length < 1 && (
          <div className="feeds-table-empty-results">
            <SvgIcon icon="search" modifiers={['right-small']} />
            {intl.formatMessage(messages.noFeedsFoundMsg)}
          </div>
        )}
        {feeds.length > 0 &&
          feeds.map(feed => {
            return (
              <FeedRow
                key={feed.id}
                feed={feed}
                columnStyle={columnStyle}
                onDeleteFeed={onDeleteFeed}
              />
            );
          })}
      </GridTable>
      <FeedActionSuccessKiteComponent />
      <FeedActionFailureKiteComponent />
    </>
  );
};

export default FeedTable;
