import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Blog = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M10 4v1.5c.286 0 .572.014.855.043.284.029.564.069.84.121a8.729 8.729 0 011.61.5 8.872 8.872 0 011.437.781 7.577 7.577 0 011.266 1.047 7.65 7.65 0 011.047 1.266 8.512 8.512 0 011.28 3.047c.053.276.093.556.122.84.029.283.043.569.043.855H20c0-1.38-.262-2.677-.785-3.89a10.114 10.114 0 00-2.145-3.18 10.114 10.114 0 00-3.18-2.145A9.717 9.717 0 0010 4zm0 3v1.5a5.504 5.504 0 013.05.918 5.564 5.564 0 011.531 1.531 5.372 5.372 0 01.814 1.969c.07.352.105.712.105 1.082H17c0-.969-.184-1.878-.55-2.727a7.172 7.172 0 00-1.497-2.226 7.172 7.172 0 00-2.226-1.496A6.794 6.794 0 0010 7zm1.5 3l-1 1L7 12l-3 6.5.398.398 3.633-3.64a.527.527 0 01-.011-.063.527.527 0 00-.012-.062.603.603 0 00-.004-.067.969.969 0 01.074-.457c.052-.12.123-.226.211-.32.094-.088.2-.159.32-.21A.969.969 0 019 14c.14 0 .27.026.39.078.12.052.227.123.32.211.09.094.16.2.212.32.052.12.078.25.078.391 0 .14-.026.27-.078.39-.052.12-.123.227-.211.32-.094.09-.2.16-.32.212a.969.969 0 01-.457.074.603.603 0 00-.067-.004.527.527 0 00-.062-.012.527.527 0 01-.063-.011l-3.64 3.633L5.5 20l6.5-3 1-3.5 1-1-2.5-2.5z"
      />
    </defs>
    <use xlinkHref="#a" />
  </svg>
);
Blog.propTypes = { fill: PropTypes.string };
Blog.defaultProps = { fill: iconColorsMap.mainicon };

export default Blog;
