import isEqual from 'lodash/isEqual';

import { UTC_OFFSET } from 'constants/constants';
import { UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD } from 'reducers/outreach/outreach-threads';

import {
  EmailAddDeleteRecipientError,
  EmailCreateDraftError,
  EmailCopyDraftError,
  EmailDeleteDraftError,
  FormFieldError,
  EmailGetDraftError,
  EmailGetOptOutError,
  EmailSendError,
  EmailSendPreviewError,
  EmailSetOptOutError,
  EmailState,
  EmailUpdateDraftError,
  EmailGetRecipientsError,
  EmailUnscheduleError,
  EmailUnscheduleBulkError,
} from './Email-types';

// prettier-ignore-start
export const ADD_ATTACHMENT = 'email/ADD_ATTACHMENT';
export const ADD_ATTACHMENT_ERROR = 'email/ADD_ATTACHMENT_ERROR';
export const ADD_ATTACHMENT_PROGRESS = 'email/ADD_ATTACHMENT_PROGRESS';
export const ADD_ATTACHMENT_SUCCESS = 'email/ADD_ATTACHMENT_SUCCESS';
export const ADD_IMAGE = 'email/ADD_IMAGE';
export const ADD_IMAGE_ERROR = 'email/ADD_IMAGE_ERROR';
export const ADD_IMAGE_PROGRESS = 'email/ADD_IMAGE_PROGRESS';
export const ADD_IMAGE_SUCCESS = 'email/ADD_IMAGE_SUCCESS';
export const ADD_IMAGE_TO_STATE = 'email/ADD_IMAGE_TO_STATE';
export const ADD_RECIPIENTS = 'email/ADD_RECIPIENTS';
export const ADD_RECIPIENTS_ERROR = 'email/ADD_RECIPIENTS_ERROR';
export const ADD_RECIPIENTS_SUCCESS = 'email/ADD_RECIPIENTS_SUCCESS';
export const CREATE_EMAIL_DRAFT = 'email/CREATE_EMAIL_DRAFT';
export const CREATE_EMAIL_DRAFT_ERROR = 'email/CREATE_EMAIL_DRAFT_ERROR';
export const CREATE_EMAIL_DRAFT_SUCCESS = 'email/CREATE_EMAIL_DRAFT_SUCCESS';
export const COPY_EMAIL_DRAFT = 'email/COPY_EMAIL_DRAFT';
export const COPY_EMAIL_DRAFT_ERROR = 'email/COPY_EMAIL_DRAFT_ERROR';
export const COPY_EMAIL_DRAFT_SUCCESS = 'email/COPY_EMAIL_DRAFT_SUCCESS';
export const DELETE_ATTACHMENT = 'email/DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_ERROR = 'email/DELETE_ATTACHMENT_ERROR';
export const DELETE_ATTACHMENT_SUCCESS = 'email/DELETE_ATTACHMENT_SUCCESS';
export const DELETE_EMAIL_DRAFT = 'email/DELETE_EMAIL_DRAFT';
export const DELETE_EMAIL_DRAFT_ERROR = 'email/DELETE_EMAIL_DRAFT_ERROR';
export const DELETE_EMAIL_DRAFT_SUCCESS = 'email/DELETE_EMAIL_DRAFT_SUCCESS';
export const DELETE_IMAGE = 'email/DELETE_IMAGE';
export const DELETING_IMAGES_PROGRESS = 'email/DELETING_IMAGES_PROGRESS';
export const DELETED_IMAGES = 'email/DELETED_IMAGES';
export const DELETE_RECIPIENTS = 'email/DELETE_RECIPIENTS';
export const DELETE_RECIPIENTS_ERROR = 'email/DELETE_RECIPIENTS_ERROR';
export const DELETE_RECIPIENTS_SUCCESS = 'email/DELETE_RECIPIENTS_SUCCESS';
export const GET_EMAIL_DRAFT = 'email/GET_EMAIL_DRAFT';
export const GET_EMAIL_DRAFT_ERROR = 'email/GET_EMAIL_DRAFT_ERROR';
export const GET_EMAIL_DRAFT_SUCCESS = 'email/GET_EMAIL_DRAFT_SUCCESS';
export const GET_OPT_OUT_ADDRESS = 'email/GET_OPT_OUT_ADDRESS';
export const GET_OPT_OUT_ADDRESS_ERROR = 'email/GET_OPT_OUT_ADDRESS_ERROR';
export const GET_OPT_OUT_ADDRESS_SUCCESS = 'email/GET_OPT_OUT_ADDRESS_SUCCESS';
export const GET_RECIPIENTS = 'email/GET_RECIPIENTS';
export const GET_RECIPIENTS_ERROR = 'email/GET_RECIPIENTS_ERROR';
export const GET_RECIPIENTS_SUCCESS = 'email/GET_RECIPIENTS_SUCCESS';
export const RESET_EMAIL = 'email/RESET_EMAIL';
export const RESET_EMAIL_DRAFT = 'email/RESET_EMAIL_DRAFT';
export const SEND_EMAIL = 'email/SEND_EMAIL';
export const SEND_EMAIL_ERROR = 'email/SEND_EMAIL_ERROR';
export const UNSCHEDULE_EMAIL_ERROR = 'email/UNSCHEDULE_EMAIL_ERROR';
export const UNSCHEDULE_EMAIL_SUCCESS = 'email/UNSCHEDULE_EMAIL_SUCCESS';
export const UNSCHEDULE_EMAIL_BULK_START = 'email/UNSCHEDULE_EMAIL_BULK_START';
export const UNSCHEDULE_EMAIL_BULK_SUCCESS =
  'email/UNSCHEDULE_EMAIL_BULK_SUCCESS';
export const UNSCHEDULE_EMAIL_BULK_ERROR = 'email/UNSCHEDULE_EMAIL_BULK_ERROR';
export const SEND_EMAIL_SUCCESS = 'email/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_PREVIEW = 'email/SEND_EMAIL_PREVIEW';
export const SEND_EMAIL_PREVIEW_ERROR = 'email/SEND_EMAIL_PREVIEW_ERROR';
export const SEND_EMAIL_PREVIEW_SUCCESS = 'email/SEND_EMAIL_PREVIEW_SUCCESS';
export const SEND_EMAIL_RESET_ERROR = 'SEND_EMAIL_RESET_ERROR';
export const SET_ADDITIONAL_RECIPIENTS = 'email/SET_ADDITIONAL_RECIPIENTS';
export const SET_BODY = 'email/SET_BODY';
export const SET_FROM = 'email/SET_FROM';
export const SET_OPT_OUT_ADDRESS = 'email/SET_OPT_OUT_ADDRESS';
export const SET_OPT_OUT_ADDRESS_ERROR = 'email/SET_OPT_OUT_ADDRESS_ERROR';
export const SET_OPT_OUT_ADDRESS_SUCCESS = 'email/SET_OPT_OUT_ADDRESS_SUCCESS';
export const SET_OPT_OUT_ADDRESS_EDITING = 'email/SET_OPT_OUT_ADDRESS_EDITING';
export const SET_REPLY_TO = 'email/SET_REPLY_TO';
export const SET_SENDER_NAME = 'email/SET_SENDER_NAME';
export const SET_SEND_TO_SELF = 'email/SET_SEND_TO_SELF';
export const SET_SUBJECT = 'email/SET_SUBJECT';
export const UPDATE_EMAIL_DRAFT = 'email/UPDATE_EMAIL_DRAFT';
export const UPDATE_EMAIL_DRAFT_ERROR = 'email/UPDATE_EMAIL_DRAFT_ERROR';
export const UPDATE_EMAIL_DRAFT_SUCCESS = 'email/UPDATE_EMAIL_DRAFT_SUCCESS';
export const SET_UPDATE_PENDING = 'email/SET_UPDATE_PENDING';
export const SET_ENTRY_LOCATION = 'email/SET_ENTRY_LOCATION';
export const SET_IMPORT_FROM_WORD_CANCELLED =
  'email/SET_IMPORT_FROM_WORD_CANCELLED';
export const SET_SCHEDULED = 'email/SET_SCHEDULED';
export const SET_SCHEDULED_TIMESTAMP = 'email/SET_SCHEDULED_TIMESTAMP';
export const SET_SCHEDULED_UTC_OFFSET = 'email/SET_SCHEDULED_UTC_OFFSET';
export const SET_SCHEDULED_TIMEZONE = 'email/SET_SCHEDULED_TIMEZONE';
export const SET_SCHEDULED_INVALID_DATE = 'email/SET_SCHEDULED_INVALID_DATE';
export const SET_SCHEDULED_MIN_TIME = 'email/SET_SCHEDULED_MIN_TIME';
export const SET_EDITOR_CONTENT_SIZE_DATA =
  'email/SET_EDITOR_CONTENT_SIZE_DATA';
export const SET_EMAIL_TITLE = 'email/SET_EMAIL_TITLE';
export const SET_CURRENT_FILE_DATA = 'email/SET_CURRENT_FILE_DATA';
export const RESET_CURRENT_FILE_DATA = 'email/RESET_CURRENT_FILE_DATA';
export const GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT =
  'email/GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT';
export const GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT_SUCCESS =
  'email/GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT_SUCCESS';
export const SET_VIRTUAL_SELECTION_RECIPIENTS_COUNT =
  'email/SET_VIRTUAL_SELECTION_RECIPIENTS_COUNT';
export const GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT_ERROR =
  'email/GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT_ERROR';
export const GET_EMAIL_VERIFICATION_START =
  'email/GET_EMAIL_VERIFICATION_START';
export const GET_EMAIL_VERIFICATION_SUCCESS =
  'email/GET_EMAIL_VERIFICATION_SUCCESS';
export const GET_EMAIL_VERIFICATION_ERROR =
  'email/GET_EMAIL_VERIFICATION_ERROR';
export const SET_EMAIL_VERIFICATION_START =
  'email/SET_EMAIL_VERIFICATION_START';
export const SET_EMAIL_VERIFICATION_SUCCESS =
  'email/SET_EMAIL_VERIFICATION_SUCCESS';
export const SET_EMAIL_VERIFICATION_ERROR =
  'email/SET_EMAIL_VERIFICATION_ERROR';
export const GET_VERIFIED_EMAILS = 'email/GET_VERIFIED_EMAILS';
export const SET_VERIFIED_EMAILS = 'email/SET_VERIFIED_EMAILS';
export const GET_VERIFIED_EMAILS_SUCCESS = 'email/GET_VERIFIED_EMAILS_SUCCESS';
export const GET_VERIFIED_EMAILS_ERROR = 'email/GET_VERIFIED_EMAILS_ERROR';
export const DELETE_VERIFIED_EMAIL = 'email/DELETE_VERIFIED_EMAIL';
export const DELETE_VERIFIED_EMAIL_SUCCESS =
  'email/DELETE_VERIFIED_EMAIL_SUCCESS';
export const DELETE_VERIFIED_EMAIL_ERROR = 'email/GET_VERIFIED_EMAIL_ERROR';
export const GET_SENDER_AUTHORIZATION_STATUS =
  'email/GET_SENDER_AUTHORIZATION_STATUS';
export const SET_SENDER_AUTHORIZATION_STATUS =
  'email/SET_SENDER_AUTHORIZATION_STATUS';
export const GET_SENDER_AUTHORIZATION_STATUS_SUCCESS =
  'email/GET_SENDER_AUTHORIZATION_STATUS_SUCCESS';
export const GET_SENDER_AUTHORIZATION_STATUS_ERROR =
  'email/GET_SENDER_AUTHORIZATION_STATUS_ERROR';
export const SET_EMAIL_SETTINGS_FULL_OPT_OUT_ADDRESS_TOGGLE =
  'email/SET_EMAIL_SETTINGS_FULL_OPT_OUT_ADDRESS_TOGGLE';
export const SET_ATTACHMENTS_TOTAL_SIZE = 'email/SET_ATTACHMENTS_TOTAL_SIZE';
export const SET_LINK_TRACKING_FOR_EMAIL_ANNOUNCEMENT =
  'email/SET_LINK_TRACKING_FOR_EMAIL_ANNOUNCEMENT';
export const SET_EMAIL_CHANGED = 'email/SET_EMAIL_CHANGED';
export const SET_LINK_TRACKING_RESET = 'email/SET_LINK_TRACKING_RESET';

// prettier-ignore-end

export const initialState: EmailState = {
  attachments: [],
  linkTrackingEnabled: true,
  attachmentsTotalSize: 0,
  editorContentSize: 0,
  currentFileData: {
    fileName: '',
    fileSize: 0,
  },
  defaultOptOutAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    companyName: '',
    country: 'United States',
    id: -1,
    state: 'AK',
    zipCode: '',
    fullOptOutAddress: '',
  },
  deletingImagesProgress: {
    image: 0,
    total: 0,
    progress: 0,
  },
  document: {
    additionalRecipients: [],
    body: '',
    from: [],
    id: '',
    isCopy: false,
    title: '',
    optOutSettings: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      companyName: '',
      country: 'United States',
      id: -1,
      state: 'AK',
      zipCode: '',
      fullOptOutAddress: '',
    },
    replyTo: [],
    senderName: [],
    sendToSelf: false,
    subject: '',
  },
  emailSettingsFullOptOutAddressToggle: false,
  emailVerification: {
    emailAddress: '',
    error: '',
    loading: false,
    verified: false,
  },
  entryLocation: '',
  error: {
    addRecipients: {
      type: EmailAddDeleteRecipientError.None,
      value: '',
    },
    createEmailDraft: {
      type: EmailCreateDraftError.None,
      value: '',
    },
    copyEmailDraft: {
      type: EmailCopyDraftError.None,
      value: '',
    },
    deleteEmailDraft: {
      type: EmailDeleteDraftError.None,
      value: '',
    },
    deleteRecipients: {
      type: EmailAddDeleteRecipientError.None,
      value: '',
    },
    getEmailDraft: {
      type: EmailGetDraftError.None,
      value: '',
    },
    getOptOutAddress: {
      type: EmailGetOptOutError.None,
      value: '',
    },
    getRecipients: {
      type: EmailGetRecipientsError.None,
      value: '',
    },
    sendEmail: {
      type: EmailSendError.None,
      value: '',
    },
    sendEmailPreview: {
      type: EmailSendPreviewError.None,
      value: '',
    },
    setOptOutAddress: {
      type: EmailSetOptOutError.None,
      value: '',
    },
    updateEmailDraft: {
      type: EmailUpdateDraftError.None,
      value: '',
    },
    unscheduleEmail: {
      type: EmailUnscheduleError.None,
      value: '',
    },
    unscheduleEmailBulk: {
      type: EmailUnscheduleBulkError.None,
      value: '',
    },
  },
  field: {
    recipients: {
      isValid: false,
      isDirty: false,
      error: FormFieldError.Required,
    },
    from: {
      isValid: false,
      isDirty: false,
      error: FormFieldError.Required,
    },
    subject: {
      isValid: false,
      isDirty: false,
      error: FormFieldError.Required,
    },
    body: {
      isValid: false,
      isDirty: false,
      error: FormFieldError.Required,
    },
  },
  images: [],
  loading: {
    addRecipients: false,
    createEmailDraft: false,
    copyEmailDraft: false,
    deleteAttachment: false,
    deleteEmailDraft: false,
    deleteImages: false,
    deleteRecipients: false,
    getEmailDraft: false,
    getOptOutAddress: false,
    getRecipients: false,
    sendEmail: false,
    sendEmailPreview: false,
    setOptOutAddress: false,
    updateEmailDraft: false,
    getVirtualSelectionRecipientsCount: false,
    getVerifiedEmails: false,
    getSenderAuthorizationStatus: false,
    deleteVerifiedEmail: false,
    unscheduleEmailBulk: false,
  },
  optOutAddressEditing: false,
  recipientItems: [],
  recipientItemsTotal: 0,
  recipientsTotal: 0,
  updatePending: false,
  importFromWord: {
    cancelled: false,
  },
  scheduling: {
    scheduled: false,
    timestamp: -1,
    utcOffset: UTC_OFFSET,
    invalidDate: false,
    minTime: '',
    utcTimezone: '',
  },
  campaignIds: [],
  campaigns: [],
  virtualSelectionRecipientsCount: 0,
  verifiedEmails: [],
  senderAuthorizationStatus: {
    authorized: false,
  },
  emailChanged: false,
  initialLinkTrackingReset: false,
};

const emailReducer = (
  state = { ...initialState },
  action: { type: string; payload: any },
): EmailState => {
  switch (action.type) {
    /* Add Attachment */

    case ADD_ATTACHMENT: {
      return {
        ...state,
        attachments: [
          ...state.attachments,
          {
            ...action.payload,
            errored: false,
            loading: true,
          },
        ],
      };
    }

    case ADD_ATTACHMENT_ERROR: {
      return {
        ...state,
        attachments: state.attachments.filter(
          attachment => attachment.localId !== action.payload,
        ),
      };
    }

    case ADD_ATTACHMENT_PROGRESS: {
      return {
        ...state,
        attachments: state.attachments.map(attachment => {
          if (attachment.localId === action.payload.localId) {
            return {
              ...attachment,
              progress: action.payload.progress,
            };
          }

          return attachment;
        }),
      };
    }

    case ADD_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        attachments: state.attachments.map(attachment => {
          if (attachment.localId === action.payload.localId) {
            return {
              ...attachment,
              attachmentId: action.payload.attachmentId,
              loading: false,
            };
          }

          return attachment;
        }),
      };
    }

    /* Add Image */

    case ADD_IMAGE: {
      return {
        ...state,
        images: [
          ...state.images,
          {
            ...action.payload,
            attachmentId: 0,
            errored: false,
            loading: true,
          },
        ],
      };
    }

    case ADD_IMAGE_ERROR: {
      return {
        ...state,
        images: state.images.filter(image => image.imageId !== action.payload),
      };
    }

    case ADD_IMAGE_PROGRESS: {
      return {
        ...state,
        images: state.images.map(image => {
          if (image.imageId === action.payload.imageId) {
            return {
              ...image,
              progress: action.payload.progress,
            };
          }

          return image;
        }),
      };
    }

    case ADD_IMAGE_SUCCESS: {
      return {
        ...state,
        images: state.images.map(image => {
          if (image.imageId === action.payload.imageId) {
            return {
              ...image,
              attachmentId: action.payload.attachmentId,
              loading: false,
            };
          }

          return image;
        }),
      };
    }

    case ADD_IMAGE_TO_STATE: {
      return {
        ...state,
        images: [
          ...state.images,
          {
            ...action.payload,
          },
        ],
      };
    }

    /* Add Recipients */

    case ADD_RECIPIENTS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          addRecipients: true,
        },
      };
    }

    case ADD_RECIPIENTS_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          addRecipients: action.payload,
        },
        loading: {
          ...state.loading,
          addRecipients: false,
        },
      };
    }

    case ADD_RECIPIENTS_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          addRecipients: false,
        },
      };
    }

    /* Create Email Draft */

    case CREATE_EMAIL_DRAFT: {
      return {
        ...initialState,
        entryLocation: action.payload,
        loading: {
          ...initialState.loading,
          createEmailDraft: true,
        },
      };
    }

    case CREATE_EMAIL_DRAFT_ERROR: {
      return {
        ...state,
        document: {
          ...state.document,
          id: '',
        },
        error: {
          ...state.error,
          createEmailDraft: action.payload,
        },
        loading: {
          ...state.loading,
          createEmailDraft: false,
        },
      };
    }

    case CREATE_EMAIL_DRAFT_SUCCESS: {
      return {
        ...state,
        document: {
          ...state.document,
          additionalRecipients: action.payload.document.additionalRecipients,
          body: action.payload.document.body,
          from: action.payload.document.from,
          id: action.payload.document.id,
          replyTo: action.payload.document.replyTo,
          senderName: action.payload.document.senderName,
          subject: action.payload.document.subject,
        },
        error: {
          ...state.error,
          createEmailDraft: action.payload.error,
        },
        field: {
          ...state.field,
          ...action.payload.field,
        },
        loading: {
          ...state.loading,
          createEmailDraft: false,
        },
      };
    }

    /* Delete Attachment */

    case DELETE_ATTACHMENT: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteAttachment: true,
        },
      };
    }

    case DELETE_ATTACHMENT_ERROR: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteAttachment: false,
        },
      };
    }

    case DELETE_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        attachments: state.attachments.filter(
          attachment => attachment.attachmentId !== action.payload,
        ),
        loading: {
          ...state.loading,
          deleteAttachment: false,
        },
      };
    }

    /* Delete Email Draft */

    case DELETE_EMAIL_DRAFT: {
      return {
        ...state,
        document: {
          ...initialState.document,
        },
        entryLocation: initialState.entryLocation,
        error: {
          ...state.error,
          deleteEmailDraft: initialState.error.deleteEmailDraft,
        },
        loading: {
          ...state.loading,
          deleteEmailDraft: true,
        },
      };
    }

    case DELETE_EMAIL_DRAFT_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          deleteEmailDraft: action.payload,
        },
        loading: {
          ...state.loading,
          deleteEmailDraft: false,
        },
      };
    }

    case DELETE_EMAIL_DRAFT_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteEmailDraft: false,
        },
      };
    }

    /* Delete Attachment */

    case DELETE_IMAGE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteImages: true,
        },
      };
    }

    case DELETING_IMAGES_PROGRESS: {
      return {
        ...state,
        deletingImagesProgress: {
          image: action.payload.image,
          total: action.payload.total,
          progress: action.payload.progress,
        },
      };
    }

    case DELETED_IMAGES: {
      return {
        ...state,
        images: state.images.filter(
          image => !action.payload.includes(image.attachmentId),
        ),
        loading: {
          ...state.loading,
          deleteImages: false,
        },
        deletingImagesProgress: initialState.deletingImagesProgress,
      };
    }

    /* Delete Recipients */

    case DELETE_RECIPIENTS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteRecipients: true,
        },
      };
    }

    case DELETE_RECIPIENTS_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          deleteRecipients: action.payload,
        },
        loading: {
          ...state.loading,
          deleteRecipients: false,
        },
      };
    }

    case DELETE_RECIPIENTS_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteRecipients: false,
        },
      };
    }

    /* Get Email Draft */

    case GET_EMAIL_DRAFT: {
      return {
        ...state,
        error: {
          ...state.error,
          getEmailDraft: initialState.error.getEmailDraft,
        },
        loading: {
          ...state.loading,
          getEmailDraft: true,
        },
      };
    }

    case GET_EMAIL_DRAFT_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          getEmailDraft: action.payload,
        },
        loading: {
          ...state.loading,
          getEmailDraft: false,
        },
      };
    }

    case GET_EMAIL_DRAFT_SUCCESS: {
      return {
        ...state,
        attachments: action.payload.attachments,
        document: {
          ...state.document,
          additionalRecipients: action.payload.document.additionalRecipients,
          body: action.payload.document.body,
          from: action.payload.document.from,
          senderName: action.payload.document.senderName,
          id: action.payload.document.id,
          replyTo: action.payload.document.replyTo,
          subject: action.payload.document.subject,
          optOutSettings:
            action.payload.document.optOutSettings ||
            state.document.optOutSettings,
          title: action.payload.document.title,
        },
        field: {
          ...state.field,
          ...action.payload.field,
        },
        loading: {
          ...state.loading,
          getEmailDraft: false,
        },
        campaigns: action.payload.campaigns,
        scheduling: {
          ...state.scheduling,
          scheduled: action.payload.scheduling.scheduled,
          timestamp: action.payload.scheduling.timestamp,
          utcOffset: action.payload.scheduling.utcOffset,
          utcTimezone: action.payload.scheduling.utcTimezone,
        },
        linkTrackingEnabled: action.payload.linkTrackingEnabled,
      };
    }

    case GET_EMAIL_VERIFICATION_START:
    case SET_EMAIL_VERIFICATION_START: {
      return {
        ...state,
        emailVerification: {
          ...initialState.emailVerification,
          loading: true,
        },
      };
    }

    case GET_EMAIL_VERIFICATION_SUCCESS:
    case SET_EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...state,
        emailVerification: {
          ...initialState.emailVerification,
          emailAddress: action.payload.emailAddress,
          verified: action.payload.verified,
        },
      };
    }

    case GET_EMAIL_VERIFICATION_ERROR:
    case SET_EMAIL_VERIFICATION_ERROR: {
      return {
        ...state,
        emailVerification: {
          ...initialState.emailVerification,
          error: action.payload,
        },
      };
    }

    /* Copy Email Draft */

    case COPY_EMAIL_DRAFT: {
      return {
        ...state,
        error: {
          ...state.error,
          copyEmailDraft: state.error.copyEmailDraft,
        },
        loading: {
          ...state.loading,
          copyEmailDraft: true,
        },
      };
    }

    case COPY_EMAIL_DRAFT_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          copyEmailDraft: action.payload.error,
        },
        loading: {
          ...state.loading,
          copyEmailDraft: false,
        },
      };
    }

    case COPY_EMAIL_DRAFT_SUCCESS: {
      return {
        ...state,
        document: {
          ...state.document,
          additionalRecipients: action.payload.additionalRecipients,
          body: action.payload.body,
          from: action.payload.from,
          id: action.payload.id,
          isCopy: true,
          replyTo: action.payload.replyTo,
          subject: action.payload.subject,
        },
        error: {
          ...state.error,
          copyEmailDraft: action.payload.error,
        },
        field: {
          ...state.field,
          ...action.payload.field,
        },
        loading: {
          ...state.loading,
          copyEmailDraft: false,
        },
      };
    }

    /* Get Opt Out Address */

    case GET_OPT_OUT_ADDRESS: {
      return {
        ...state,
        error: {
          ...state.error,
          getOptOutAddress: initialState.error.getOptOutAddress,
        },
        loading: {
          ...state.loading,
          getOptOutAddress: true,
        },
      };
    }

    case GET_OPT_OUT_ADDRESS_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          getOptOutAddress: action.payload,
        },
        loading: {
          ...state.loading,
          getOptOutAddress: false,
        },
      };
    }

    case GET_OPT_OUT_ADDRESS_SUCCESS: {
      const optOutSettingsUnchanged = isEqual(
        initialState.document.optOutSettings,
        state.document.optOutSettings,
      );
      const hasDefaultOptOutAddress = optOutSettingsUnchanged
        ? {
            document: {
              ...state.document,
              optOutSettings: action.payload.defaultOptOutAddress,
            },
          }
        : {};

      return {
        ...state,
        ...hasDefaultOptOutAddress,
        loading: {
          ...state.loading,
          getOptOutAddress: false,
        },
        defaultOptOutAddress: action.payload.defaultOptOutAddress,
        optOutAddressEditing: action.payload.optOutAddressEditing,
      };
    }

    /* Get Recipients */

    case GET_RECIPIENTS: {
      return {
        ...state,
        error: {
          ...state.error,
          getRecipients: initialState.error.getRecipients,
        },
        loading: {
          ...state.loading,
          getRecipients: true,
        },
      };
    }

    case GET_RECIPIENTS_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          getRecipients: action.payload,
        },
        loading: {
          ...state.loading,
          getRecipients: false,
        },
      };
    }

    case GET_RECIPIENTS_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          getRecipients: false,
        },
        recipientItems: action.payload.items,
        recipientItemsTotal: action.payload.total,
        recipientsTotal: action.payload.recipientsTotal,
      };
    }

    /* Reset Email */

    case RESET_EMAIL: {
      return {
        ...initialState,
        defaultOptOutAddress: state.defaultOptOutAddress,
      };
    }

    case RESET_EMAIL_DRAFT: {
      return {
        ...state,
        attachments: state.attachments,
        document: state.document,
        field: state.field,
        campaigns: state.campaigns,
        scheduling: state.scheduling,
      };
    }

    /* Send Email */

    case SEND_EMAIL: {
      return {
        ...state,
        error: {
          ...state.error,
          sendEmail: initialState.error.sendEmail,
        },
        loading: {
          ...state.loading,
          sendEmail: true,
        },
      };
    }

    case SEND_EMAIL_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          sendEmail: action.payload,
        },
        loading: {
          ...state.loading,
          sendEmail: false,
        },
      };
    }

    case UNSCHEDULE_EMAIL_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          unscheduleEmail: action.payload,
        },
      };
    }

    case UNSCHEDULE_EMAIL_BULK_START: {
      return {
        ...state,
        loading: {
          ...state.loading,
          unscheduleEmailBulk: true,
        },
      };
    }

    case UNSCHEDULE_EMAIL_BULK_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          unscheduleEmailBulk: false,
        },
      };
    }

    case UNSCHEDULE_EMAIL_BULK_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          unscheduleEmailBulk: action.payload,
        },
        loading: {
          ...state.loading,
          unscheduleEmailBulk: false,
        },
      };
    }

    case UNSCHEDULE_EMAIL_SUCCESS: {
      return {
        ...state,
      };
    }

    case SEND_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          sendEmail: false,
        },
      };
    }

    /* Send Email Preview */

    case SEND_EMAIL_PREVIEW: {
      return {
        ...state,
        error: {
          ...state.error,
          sendEmailPreview: initialState.error.sendEmailPreview,
        },
        loading: {
          ...state.loading,
          sendEmailPreview: true,
        },
      };
    }

    case SEND_EMAIL_PREVIEW_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          sendEmailPreview: action.payload,
        },
        loading: {
          ...state.loading,
          sendEmailPreview: false,
        },
      };
    }

    case SEND_EMAIL_PREVIEW_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          sendEmailPreview: false,
        },
      };
    }

    case SEND_EMAIL_RESET_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          sendEmail: {
            type: EmailSendError.None,
            value: '',
          },
          sendEmailPreview: {
            type: EmailSendPreviewError.None,
            value: '',
          },
        },
      };
    }

    case SET_ADDITIONAL_RECIPIENTS: {
      return {
        ...state,
        document: {
          ...state.document,
          additionalRecipients: action.payload,
        },
      };
    }

    case SET_BODY: {
      return {
        ...state,
        document: {
          ...state.document,
          body: action.payload.value,
        },
        field: {
          ...state.field,
          body: action.payload.field,
        },
      };
    }

    case SET_FROM: {
      return {
        ...state,
        document: {
          ...state.document,
          from: action.payload.value,
        },
        field: {
          ...state.field,
          from: action.payload.field,
        },
      };
    }

    case SET_SENDER_NAME: {
      return {
        ...state,
        document: {
          ...state.document,
          senderName: action.payload,
        },
      };
    }

    /* Set Opt Out Address */

    case SET_OPT_OUT_ADDRESS: {
      return {
        ...state,
        error: {
          ...state.error,
          setOptOutAddress: initialState.error.setOptOutAddress,
        },
        loading: {
          ...state.loading,
          setOptOutAddress: true,
        },
      };
    }

    case SET_OPT_OUT_ADDRESS_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          setOptOutAddress: action.payload,
        },
        loading: {
          ...state.loading,
          setOptOutAddress: false,
        },
      };
    }

    case SET_OPT_OUT_ADDRESS_SUCCESS: {
      return {
        ...state,
        document: {
          ...state.document,
          optOutSettings: {
            addressLine1: action.payload.addressLine1,
            addressLine2: action.payload.addressLine2,
            city: action.payload.city,
            companyName: action.payload.companyName,
            country: action.payload.country,
            id: action.payload.id,
            state: action.payload.state,
            zipCode: action.payload.zipCode,
            fullOptOutAddress: action.payload.fullOptOutAddress,
          },
        },
        loading: {
          ...state.loading,
          setOptOutAddress: false,
        },
        defaultOptOutAddress: action.payload.local
          ? {
              ...state.defaultOptOutAddress,
            }
          : {
              ...state.defaultOptOutAddress,
              addressLine1: action.payload.addressLine1,
              addressLine2: action.payload.addressLine2,
              city: action.payload.city,
              companyName: action.payload.companyName,
              country: action.payload.country,
              id: action.payload.id,
              state: action.payload.state,
              zipCode: action.payload.zipCode,
              fullOptOutAddress: action.payload.fullOptOutAddress,
            },
        optOutAddressEditing: false,
      };
    }

    case SET_OPT_OUT_ADDRESS_EDITING: {
      return {
        ...state,
        optOutAddressEditing: action.payload,
      };
    }

    case SET_REPLY_TO: {
      return {
        ...state,
        document: {
          ...state.document,
          replyTo: action.payload,
        },
      };
    }

    case SET_SEND_TO_SELF: {
      return {
        ...state,
        document: {
          ...state.document,
          sendToSelf: action.payload,
        },
      };
    }

    case SET_SUBJECT: {
      return {
        ...state,
        document: {
          ...state.document,
          subject: action.payload.value,
        },
        field: {
          ...state.field,
          subject: action.payload.field,
        },
      };
    }

    case UPDATE_EMAIL_DRAFT: {
      return {
        ...state,
        error: {
          ...state.error,
          updateEmailDraft: initialState.error.updateEmailDraft,
        },
        loading: {
          ...state.loading,
          updateEmailDraft: true,
        },
      };
    }

    case UPDATE_EMAIL_DRAFT_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          updateEmailDraft: action.payload,
        },
        loading: {
          ...state.loading,
          updateEmailDraft: false,
        },
      };
    }

    case UPDATE_EMAIL_DRAFT_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          updateEmailDraft: false,
        },
        linkTrackingEnabled: action.payload,
      };
    }

    case SET_ENTRY_LOCATION: {
      return {
        ...state,
        entryLocation: action.payload,
      };
    }

    case SET_UPDATE_PENDING: {
      return {
        ...state,
        updatePending: action.payload,
      };
    }

    case SET_IMPORT_FROM_WORD_CANCELLED: {
      return {
        ...state,
        importFromWord: {
          cancelled: action.payload,
        },
      };
    }

    case SET_SCHEDULED: {
      return {
        ...state,
        scheduling: {
          ...state.scheduling,
          scheduled: action.payload,
        },
      };
    }

    case SET_SCHEDULED_TIMESTAMP: {
      return {
        ...state,
        scheduling: {
          ...state.scheduling,
          timestamp: action.payload,
        },
      };
    }

    case SET_SCHEDULED_UTC_OFFSET: {
      return {
        ...state,
        scheduling: {
          ...state.scheduling,
          utcOffset: action.payload,
        },
      };
    }

    case SET_SCHEDULED_TIMEZONE: {
      return {
        ...state,
        scheduling: {
          ...state.scheduling,
          utcTimezone: action.payload,
        },
      };
    }

    case SET_SCHEDULED_INVALID_DATE: {
      return {
        ...state,
        scheduling: {
          ...state.scheduling,
          invalidDate: action.payload,
        },
      };
    }

    case SET_SCHEDULED_MIN_TIME: {
      return {
        ...state,
        scheduling: {
          ...state.scheduling,
          minTime: action.payload,
        },
      };
    }

    case SET_EMAIL_TITLE: {
      return {
        ...state,
        document: {
          ...state.document,
          title: action.payload,
        },
      };
    }

    case UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD: {
      const { campaigns, threadId } = action.payload;
      if (threadId !== state.document.id) {
        return state;
      }

      return {
        ...state,
        campaigns,
      };
    }

    case GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT: {
      return {
        ...state,
        loading: {
          ...state.loading,
          getVirtualSelectionRecipientsCount: true,
        },
      };
    }

    case SET_VIRTUAL_SELECTION_RECIPIENTS_COUNT: {
      return {
        ...state,
        virtualSelectionRecipientsCount: action.payload,
      };
    }

    case GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT_SUCCESS:
    case GET_VIRTUAL_SELECTION_RECIPIENTS_COUNT_ERROR: {
      return {
        ...state,
        loading: {
          ...state.loading,
          getVirtualSelectionRecipientsCount: false,
        },
      };
    }

    /* Verified Emails */

    case GET_VERIFIED_EMAILS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          getVerifiedEmails: true,
        },
      };
    }

    case DELETE_VERIFIED_EMAIL: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteVerifiedEmail: true,
        },
      };
    }

    case SET_VERIFIED_EMAILS: {
      return {
        ...state,
        verifiedEmails: action.payload,
      };
    }

    case GET_VERIFIED_EMAILS_SUCCESS:
    case GET_VERIFIED_EMAILS_ERROR: {
      return {
        ...state,
        loading: {
          ...state.loading,
          getVerifiedEmails: false,
        },
      };
    }

    case DELETE_VERIFIED_EMAIL_SUCCESS:
    case DELETE_VERIFIED_EMAIL_ERROR: {
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteVerifiedEmail: false,
        },
      };
    }

    case GET_SENDER_AUTHORIZATION_STATUS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          getSenderAuthorizationStatus: true,
        },
      };
    }

    case SET_SENDER_AUTHORIZATION_STATUS: {
      return {
        ...state,
        senderAuthorizationStatus: action.payload,
      };
    }

    case GET_SENDER_AUTHORIZATION_STATUS_SUCCESS:
    case GET_SENDER_AUTHORIZATION_STATUS_ERROR: {
      return {
        ...state,
        loading: {
          ...state.loading,
          getSenderAuthorizationStatus: false,
        },
      };
    }
    case SET_EMAIL_SETTINGS_FULL_OPT_OUT_ADDRESS_TOGGLE: {
      return {
        ...state,
        emailSettingsFullOptOutAddressToggle: action.payload,
      };
    }

    case SET_ATTACHMENTS_TOTAL_SIZE: {
      return {
        ...state,
        attachmentsTotalSize: state.attachments.reduce(
          (acc, item) => acc + item.size,
          0,
        ),
      };
    }

    case SET_EDITOR_CONTENT_SIZE_DATA: {
      return {
        ...state,
        editorContentSize: action.payload,
      };
    }

    case SET_CURRENT_FILE_DATA: {
      return {
        ...state,
        currentFileData: action.payload,
      };
    }

    case RESET_CURRENT_FILE_DATA: {
      return {
        ...state,
        currentFileData: initialState.currentFileData,
      };
    }

    case SET_LINK_TRACKING_FOR_EMAIL_ANNOUNCEMENT: {
      return {
        ...state,
        linkTrackingEnabled: action.payload,
      };
    }

    case SET_EMAIL_CHANGED:
      return {
        ...state,
        emailChanged: action.payload,
        initialLinkTrackingReset: !!action.payload,
      };

    case SET_LINK_TRACKING_RESET:
      return {
        ...state,
        initialLinkTrackingReset: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default emailReducer;
