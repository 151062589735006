export default {
  category: {
    id: 'DASHBOARD_GRID_LABEL_CATEGORY',
    description: 'dashboard grid label for category column',
    defaultMessage: 'CATEGORY',
  },
  owner: {
    id: 'DASHBOARD_GRID_LABEL_OWNER',
    description: 'dashboard grid label for owner column',
    defaultMessage: 'OWNER',
  },
  lastModified: {
    id: 'DASHBOARD_GRID_LABEL_LAST_MODIFIED',
    description: 'dashboard grid label for last modified column',
    defaultMessage: 'LAST MODIFIED',
  },
  searches: {
    id: 'DASHBOARD_GRID_LABEL_SEARCHES',
    description: 'dashboard grid label for searches column',
    defaultMessage: 'SEARCHES',
  },
  name: {
    id: 'DASHBOARD_GRID_LABEL_NAME',
    description: 'dashboard grid label for name column',
    defaultMessage: 'NAME',
  },
  archiveAnalytic: {
    id: 'DASHBOARD_GRID_LABEL_DASHBOARD_ARCHIVE',
    description: 'dashboard grid label for archive dashboard',
    defaultMessage: 'Archive',
  },
  restoreAnalytic: {
    id: 'DASHBOARD_GRID_LABEL_DASHBOARD_RESTORE',
    description: 'dashboard grid label for restore dashboard',
    defaultMessage: 'Restore',
  },
  deleteAnalytic: {
    id: 'DASHBOARD_GRID_ROW_DELETE_MENU_OPTION',
    description: 'Menu option to delete a dashboard',
    defaultMessage: 'Delete',
  },
  shareSettings: {
    id: 'DASHBOARD_GRID_LABEL_SHARE_SETTINGS',
    description: 'dashboard grid label for share settings',
    defaultMessage: 'Share Settings',
  },
  share: {
    id: 'DASHBOARD_GRID_LABEL_SHARE',
    description: 'dashboard grid label for share',
    defaultMessage: 'Share',
  },
  searchesRow: {
    id: 'DASHBOARD_GRID_ROW_SEARCHES_WORD',
    description: 'The pluralization of the word Search',
    defaultMessage: '{count} {count, plural, =1 {Search} other {Searches}}',
  },
  duplicate: {
    id: 'DASHBOARD_GRID_ROW_DUPLICATE_MENU_OPTION',
    description: 'Menu option to duplicate a dashboard',
    defaultMessage: 'Duplicate',
  },
  duplicateSuccess: {
    id: 'DASHBOARD_GRID_ROW_DUPLICATE_SUCCESS_MESSAGE',
    description:
      'Message displayed in a kite indicating a dashboard was successfully duplicated',
    defaultMessage: '{title} created.',
  },
  untitledAnalytic: {
    id: 'UNTITLED_DASHBOARD',
    description: 'Message displayed when dashboards title is empty',
    defaultMessage: 'Untitled Dashboard',
  },
};
