import React from 'react';

import PropTypes from 'prop-types';

import UniversalNavMenuItem from '../UniversalNavMenuItem';

const MenuLg = ({ menuProps, forwardedRef }) => (
  <ul className="universal-nav-menu" ref={forwardedRef}>
    {menuProps.map(menuItemProps => (
      <UniversalNavMenuItem key={menuItemProps.id} {...menuItemProps} />
    ))}
  </ul>
);

MenuLg.propTypes = {
  menuProps: PropTypes.arrayOf(PropTypes.object).isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default React.forwardRef((props, ref) => (
  <MenuLg {...props} forwardedRef={ref} />
));
