import moment from 'moment';

import { DATE_RANGE_COMPARE_TYPES } from 'components/widget/constants';

import {
  getDateRange,
  buildDateRangeParams,
  getWidgetV3CompareDateRange,
  hydrateWidgetData,
  getChartTypeFromMetrics,
  getWidgetTypeFromMetrics,
  sortDataByDataSources,
} from 'components/widgetV3/utils';
import {
  convertWidgetFormToV3Widget,
  createDateRangeCompareMetric,
} from 'components/widgetWizard/utils';
import {
  NEW_REPORT_ENDPOINT,
  NEW_REPORT_SLIDE_ENDPOINT,
  REPORT_UPDATE_TEXT_WIDGET,
  REPORT_IMAGE_WIDGET,
  REMOVE_WIDGET_FROM_SLIDE,
  WIDGET_V3_ENDPOINT,
  ADD_CAMPAIGNS_TO_REPORT,
  REPORT_CREATE_TEXT_WIDGET,
  REPORT_CREATE_SLIDE_TILE,
  REPORT_WIDGET_LAYOUT_SLIDE,
} from 'constants/apis';
import { DEV_FEATURES, WIDGET_TYPES } from 'constants/constants';
import { setLastViewedAnalyticActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-last-viewed-reducer';
import { allAnalyticActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-reducer';
import {
  bustWidgetDataCache,
  createMetricsForWidget,
  createWidgetPayload,
  UPDATE_V3_WIDGET,
  UPDATE_V3_WIDGET_ERROR,
  UPDATE_V3_WIDGET_SUCCESS,
} from 'pages/Dashboard/dashboard-hub-reducer';

import sidebarMessages from 'pages/Report/ReportSidebar/report-sidebar.messages';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';
import {
  TextWidget,
  WidgetSlideTile,
} from 'selectors/dashboards/dashboard-hub';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import {
  performPatch,
  performPost,
  performGet,
  performDelete,
  performPut,
} from 'services/rest-service/rest-service';

import {
  buildDateRangeCompareTimestamps,
  DATE_RANGE_KEYS,
  getTimestampsForAnyRange,
} from 'utils/date/date-util';

import { DEFAULT_GRID_SIZE, REPORT_EXPORT_STATUSES } from './constants';
import { ReportHub, reportSlides, ReportExport } from './report-hub';

import messages from './report.messages';
import {
  buildSlideTile,
  buildSlideTilePayload,
  getProcessedWidgetData,
} from './utils';

export const CREATE_REPORT = 'report/CREATE_REPORT';
export const CREATE_REPORT_ERROR = 'report/CREATE_REPORT_ERROR';
export const CREATE_REPORT_SUCCESS = 'report/CREATE_REPORT_SUCCESS';

export const DUPLICATE_REPORT = 'report/DUPLICATE_REPORT';
export const DUPLICATE_REPORT_ERROR = 'report/DUPLICATE_REPORT_ERROR';
export const DUPLICATE_REPORT_SUCCESS = 'report/DUPLICATE_REPORT_SUCCESS';

export const GET_REPORT = 'report/GET_REPORT';
export const GET_REPORT_ERROR = 'report/GET_REPORT_ERROR';
export const GET_REPORT_SUCCESS = 'report/GET_REPORT_SUCCESS';

export const UPDATE_REPORT_STATUS = 'report/UPDATE_REPORT_STATUS';
export const UPDATE_REPORT_STATUS_ERROR = 'report/UPDATE_REPORT_STATUS_ERROR';
export const UPDATE_REPORT_STATUS_SUCCESS =
  'report/UPDATE_REPORT_STATUS_SUCCESS';
export const SWITCH_CURRENT_SLIDE = 'report/SWITCH_CURRENT_SLIDE';
export const UPDATE_REPORT_SLIDES = 'report/UPDATE_REPORT_SLIDES';

export const REORDER_SLIDES = 'report/REORDER_SLIDES';
export const REORDER_SLIDES_ERROR = 'report/REORDER_SLIDES_ERROR';
export const REORDER_SLIDES_SUCCESS = 'report/REORDER_SLIDES_SUCCESS';

export const DELETE_SLIDE = 'report/DELETE_SLIDE';
export const DELETE_SLIDE_SUCCESS = 'report/DELETE_SLIDE_SUCCESS';
export const DELETE_SLIDE_ERROR = 'report/DELETE_SLIDE_ERROR';

export const UPDATE_TEXT_WIDGET_SUCCESS = 'report/UPDATE_TEXT_WIDGET_SUCCESS';
export const UPDATE_TEXT_WIDGET_ERROR = 'report/UPDATE_TEXT_WIDGET_ERROR';

export const UPDATE_IMAGE_WIDGET_SUCCESS = 'report/UPDATE_IMAGE_WIDGET_SUCCESS';
export const UPDATE_IMAGE_WIDGET_ERROR = 'report/UPDATE_IMAGE_WIDGET_ERROR';

export const REMOVE_IMAGE_WIDGET_SUCCESS = 'report/REMOVE_IMAGE_WIDGET_SUCCESS';
export const REMOVE_IMAGE_WIDGET_ERROR = 'report/REMOVE_IMAGE_WIDGET_ERROR';

export const DELETE_WIDGET_FROM_SLIDE =
  'widgetForm/DELETE_WIDGET_FROM_REPORT_SLIDE';
export const DELETE_WIDGET_FROM_SLIDE_SUCCESS =
  'widgetForm/DELETE_WIDGET_FROM_REPORT_SLIDE_SUCCESS';
export const DELETE_WIDGET_FROM_SLIDE_ERROR =
  'widgetForm/DELETE_WIDGET_FROM_REPORT_SLIDE_ERROR';

export const GET_CHART_DATA = 'report/GET_CHART_DATA';
export const GET_CHART_DATA_SUCCESS = 'report/GET_CHART_DATA_SUCCESS';
export const GET_CHART_DATA_ERROR = 'report/GET_CHART_DATA_ERROR';

export const UPDATE_WIDGET = 'report/UPDATE_WIDGET';
export const UPDATE_WIDGET_SUCCESS = 'report/UPDATE_WIDGET_SUCCESS';
export const UPDATE_WIDGET_ERROR = 'report/UPDATE_WIDGET_ERROR';

export const EXPORT_REPORT_INITIALIZED = 'report/EXPORT_REPORT_INITIALIZED';
export const EXPORT_REPORT_CONFIRMING = 'report/EXPORT_REPORT_CONFIRMING';
export const EXPORT_REPORT_PROCESSING = 'report/EXPORT_REPORT_PROCESSING';
export const EXPORT_REPORT_SUCCESS = 'report/EXPORT_REPORT_SUCCESS';
export const EXPORT_REPORT_ERROR = 'report/EXPORT_REPORT_ERROR';

export const ADD_CUSTOM_SLIDE = 'report/ADD_CUSTOM_SLIDE';
export const ADD_CUSTOM_SLIDE_ERROR = 'report/ADD_CUSTOM_SLIDE_ERROR';
export const ADD_CUSTOM_SLIDE_SUCCESS = 'report/ADD_CUSTOM_SLIDE_SUCCESS';
export const UPDATE_SLIDE_TITLE = 'report/UPDATE_SLIDE_TITLE';

export const ADD_TILE_TO_SLIDE = 'report/ADD_TILE_TO_SLIDE';

export const ADD_TO_SLIDES_BEING_UPDATED = 'Report/ADD_TO_SLIDES_BEING_UPDATED';
export const REMOVE_FROM_SLIDES_BEING_UPDATED =
  'Report/REMOVE_FROM_SLIDES_BEING_UPDATED';

export const LINK_CAMPAIGNS_WITH_REPORT_ERROR =
  'report/LINK_CAMPAIGNS_WITH_REPORT_ERROR';
export const UPDATE_WIDGET_LAYOUT_SLIDE = 'report/UPDATE_WIDGET_LAYOUT_SLIDE';
export const UPDATE_WIDGET_LAYOUT_SLIDE_SUCCESS =
  'report/UPDATE_WIDGET_LAYOUT_SLIDE_SUCCESS';
export const UPDATE_WIDGET_LAYOUT_SLIDE_ERROR =
  'report/UPDATE_WIDGET_LAYOUT_SLIDE_ERROR';

// @TODO: this is becoming a bucket for interfaces and ActionCreators: split up.
//
export interface RouteMatch {
  params: {
    reportUuid: string;
  };
}
export const initialState: ReportHub = {
  duplicatingSuccess: false,
  reportExport: {},
  loading: false,
  slidesBeingUpdated: [],
  error: '',
  report: {},
};

const reportHubReducer = (
  state = { ...initialState },
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case CREATE_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        error: null,
        loading: false,
        slides: action.payload.report?.slides,
      };
    case DUPLICATE_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
        duplicatingSuccess: false,
      };
    case DUPLICATE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        duplicatingSuccess: false,
        error: action.payload,
      };
    case DUPLICATE_REPORT_SUCCESS:
      return {
        ...state,
        duplicatingSuccess: true,
        report: action.payload.report,
        error: null,
        loading: false,
        slides: action.payload.report?.slides,
      };
    case GET_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.payload.report,
      };
    case SWITCH_CURRENT_SLIDE:
      return {
        ...state,
        report: {
          ...state.report,
          slides: action.payload.slides,
        },
      };
    case REORDER_SLIDES:
      return {
        ...state,
        error: null,
      };
    case REORDER_SLIDES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case REORDER_SLIDES_SUCCESS:
      return {
        ...state,
        slides: action.payload.report.slides,
      };
    case DELETE_SLIDE:
      return {
        ...state,
      };
    case DELETE_SLIDE_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          slides: action.payload.lessenedSlides,
        },
      };
    case DELETE_SLIDE_ERROR:
      return {
        ...state,
      };
    case UPDATE_REPORT_STATUS:
      return {
        ...state,
        error: null,
      };
    case UPDATE_REPORT_SLIDES:
      return {
        ...state,
        report: {
          ...state.report,
          slides: action.payload.slides,
        },
      };
    case UPDATE_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          reportStatus: action.payload,
        },
      };
    case UPDATE_REPORT_STATUS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_TEXT_WIDGET_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          slides: action.payload.newSlides,
        },
      };
    case UPDATE_IMAGE_WIDGET_SUCCESS:
    case REMOVE_IMAGE_WIDGET_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          slides: action.payload.newSlides,
        },
      };
    case UPDATE_TEXT_WIDGET_ERROR:
    case UPDATE_IMAGE_WIDGET_ERROR:
    case REMOVE_IMAGE_WIDGET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CHART_DATA: {
      const widgetId = action.payload.id;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (
            slideTile.contentType === WIDGET_TYPES.widgetV3 &&
            slideTile.content.id === widgetId
          ) {
            return {
              ...slideTile,
              content: {
                ...slideTile.content,
                loadingWidget: true,
                widgetError: false,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });
      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case GET_CHART_DATA_SUCCESS: {
      const widgetId = action.payload.id;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (
            slideTile.contentType === WIDGET_TYPES.widgetV3 &&
            slideTile.content.id === widgetId
          ) {
            return {
              ...slideTile,
              content: {
                ...slideTile.content,
                loadingWidget: false,
                widgetError: false,
                chartData: action.payload.data,
                isoCode: action.payload.isoCode ?? slideTile.content.isoCode,
                widgetType:
                  action.payload.widgetType ?? slideTile.content.widgetType,
                chartType:
                  action.payload.chartType ?? slideTile.content.chartType,
                title: action.payload.title ?? slideTile.content.title,
                metrics: action.payload.metrics ?? slideTile.content.metrics,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case GET_CHART_DATA_ERROR: {
      const widgetId = action.payload.id;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (
            slideTile.contentType === WIDGET_TYPES.widgetV3 &&
            slideTile.content.id === widgetId
          ) {
            return {
              ...slideTile,
              content: {
                ...slideTile.content,
                loadingWidget: false,
                widgetError: true,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case UPDATE_WIDGET: {
      const widgetId = action.payload.id;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (
            slideTile.contentType === WIDGET_TYPES.widgetV3 &&
            slideTile.content.id === widgetId
          ) {
            return {
              ...slideTile,
              content: {
                ...slideTile.content,
                updatingWidget: true,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case UPDATE_WIDGET_SUCCESS: {
      const widgetId = action.payload.id;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (
            slideTile.contentType === WIDGET_TYPES.widgetV3 &&
            slideTile.content.id === widgetId
          ) {
            return {
              ...slideTile,
              content: {
                ...slideTile.content,
                updatingWidget: false,
                ...action.payload.content,
                chartData: null,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case UPDATE_WIDGET_ERROR: {
      const widgetId = action.payload.id;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (
            slideTile.contentType === WIDGET_TYPES.widgetV3 &&
            slideTile.content.id === widgetId
          ) {
            return {
              ...slideTile,
              content: {
                ...slideTile.content,
                updatingWidget: false,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case UPDATE_WIDGET_LAYOUT_SLIDE: {
      const slideTileId = action.payload;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (slideTile.id === slideTileId) {
            return {
              ...slideTile,
              content: {
                ...slideTile.content,
                updatingWidget: true,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case UPDATE_WIDGET_LAYOUT_SLIDE_SUCCESS: {
      const slideTileId = action.payload.updatedSlideTile.id;
      const slides = state.report.slides.map(slide => {
        const newLayout = slide.layout.map(slideTile => {
          if (slideTile.id === slideTileId) {
            return {
              ...slideTile,
              legendShown: action.payload.updatedSlideTile.legendShown,
              chartShown: action.payload.updatedSlideTile.chartShown,
              legendPosition: action.payload.updatedSlideTile.legendPosition,
              legendSizeRatio: action.payload.updatedSlideTile.legendSizeRatio,
              content: {
                ...slideTile.content,
                updatingWidget: false,
              },
            };
          }
          return slideTile;
        });
        return { ...slide, layout: newLayout };
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides,
        },
      };
    }
    case UPDATE_WIDGET_LAYOUT_SLIDE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_WIDGET_FROM_SLIDE:
      return {
        ...state,
        error: null,
      };
    case DELETE_WIDGET_FROM_SLIDE_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          slides: action.payload.newSlides,
        },
      };
    case DELETE_WIDGET_FROM_SLIDE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EXPORT_REPORT_INITIALIZED:
      return {
        ...state,
        reportExport: {
          status: REPORT_EXPORT_STATUSES.INITIALIZED,
        } as ReportExport,
      };
    case EXPORT_REPORT_CONFIRMING:
      return {
        ...state,
        reportExport: {
          status: REPORT_EXPORT_STATUSES.CONFIRMING,
        } as ReportExport,
      };
    case EXPORT_REPORT_PROCESSING:
      return {
        ...state,
        reportExport: {
          status: REPORT_EXPORT_STATUSES.PROCESSING,
          error: null,
        } as ReportExport,
      };
    case EXPORT_REPORT_SUCCESS:
      return {
        ...state,
        reportExport: {
          status: REPORT_EXPORT_STATUSES.SUCCESS,
          error: null,
        } as ReportExport,
      };
    case EXPORT_REPORT_ERROR:
      return {
        ...state,
        reportExport: {
          status: REPORT_EXPORT_STATUSES.ERROR,
          error: action.payload,
        } as ReportExport,
      };
    case ADD_CUSTOM_SLIDE:
      return {
        ...state,
        error: null,
      };
    case ADD_CUSTOM_SLIDE_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          slides: action.payload.newSlides,
        },
      };
    case ADD_CUSTOM_SLIDE_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case UPDATE_SLIDE_TITLE: {
      const { slideIndex, newTitle } = action.payload;
      return {
        ...state,
        report: {
          ...state.report,
          slides: [
            ...state.report.slides.slice(0, slideIndex),
            { ...state.report.slides[slideIndex], title: newTitle },
            ...state.report.slides.slice(slideIndex + 1),
          ],
        },
      };
    }

    case ADD_TILE_TO_SLIDE: {
      const { slideId, tile } = action.payload;
      const slides = state.report?.slides?.map(slide => {
        if (slide.id === slideId) {
          return {
            ...slide,
            layout: [...slide.layout, { ...tile }],
          };
        }
        return slide;
      });

      return {
        ...state,
        report: {
          ...state.report,
          slides: slides || [],
        },
      };
    }

    case ADD_TO_SLIDES_BEING_UPDATED:
      return {
        ...state,
        slidesBeingUpdated: [...state.slidesBeingUpdated, action.payload],
      };

    case REMOVE_FROM_SLIDES_BEING_UPDATED:
      return {
        ...state,
        slidesBeingUpdated: [
          ...state.slidesBeingUpdated.filter(id => id !== action.payload),
        ],
      };

    case LINK_CAMPAIGNS_WITH_REPORT_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

const setFirstSlideCurrent = slides => {
  return slides.map((slide, i) => ({ ...slide, isCurrent: i === 0 }));
};

export const updateReportStatusActionCreator = (reportUuid, status) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_REPORT_STATUS, payload: status });
  const state = getState();
  const report = state.report?.reportHub?.report;
  try {
    const { data } = await performPatch(
      `${NEW_REPORT_ENDPOINT}/${reportUuid}`,
      {
        ...report,
        reportStatus: status,
      },
    );
    dispatch({
      type: UPDATE_REPORT_STATUS_SUCCESS,
      payload: data.reportStatus,
    });
  } catch (error) {
    dispatch({ type: UPDATE_REPORT_STATUS_ERROR, payload: error.message });
  }
};

export const switchCurrentSlideActionCreator = slide => (
  dispatch,
  getState,
) => {
  const state = getState();
  const slides = state.report.reportHub.report.slides.map(slide => ({
    ...slide,
    isCurrent: false,
  }));
  const currentSlideIndex = slides.findIndex(x => x.id === slide.id);
  if (currentSlideIndex >= 0) {
    slides[currentSlideIndex].isCurrent = true;
    dispatch({ type: SWITCH_CURRENT_SLIDE, payload: { slides } });
  }
};

export const reorderSlidesActionCreator = (oldIndex, newIndex) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: REORDER_SLIDES });
  const state = getState();
  const slides = state.report.reportHub.report.slides;

  const slide = slides[oldIndex];
  slides.splice(oldIndex, 1);
  slides.splice(newIndex, 0, slide);
  const orderedSlides = slides.map((slide, i) => ({ ...slide, pageIndex: i }));

  try {
    const result = await performPatch(
      `${NEW_REPORT_ENDPOINT}/${state.report.reportHub.report.uuid}/slides`,
      { ...state.report.reportHub.report, slides: orderedSlides },
    );

    dispatch({
      type: REORDER_SLIDES_SUCCESS,
      payload: { report: { slides: result.data.slides } },
    });
    dispatch(switchCurrentSlideActionCreator(slides[newIndex]));
  } catch (e) {
    dispatch({ type: REORDER_SLIDES_ERROR, payload: e.message });
  }
};

export const updateReportSlidesActionCreator = layout => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const slides = [...state.report?.reportHub?.report.slides];
  const indexSlide = slides.findIndex(slide => slide.isCurrent === true);
  slides[indexSlide] = { ...slides[indexSlide], layout };
  dispatch({ type: UPDATE_REPORT_SLIDES, payload: { slides } });
};

export const deleteSlideActionCreator = slideId => async (
  dispatch,
  getState,
) => {
  dispatch({ type: DELETE_SLIDE });
  const state = getState();
  const slides = state.report.reportHub.report.slides;

  try {
    await performDelete(`${NEW_REPORT_SLIDE_ENDPOINT}/${slideId}`);
    let deletedIndex = 0;
    const lessenedSlides = slides
      .filter((slide, i) => {
        if (slideId === slide.id) {
          deletedIndex = i;
        }
        return slideId !== slide.id;
      })
      .map((slide, i) => ({ ...slide, pageIndex: i }));

    dispatch({ type: DELETE_SLIDE_SUCCESS, payload: { lessenedSlides } });

    if (lessenedSlides.length) {
      dispatch(
        switchCurrentSlideActionCreator(
          lessenedSlides[
            lessenedSlides.length - 1 < deletedIndex
              ? deletedIndex - 1
              : deletedIndex
          ],
        ),
      );
    }
  } catch (e) {
    dispatch({
      type: DELETE_SLIDE_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const getReportActionCreator = reportUuid => async dispatch => {
  dispatch({ type: GET_REPORT });
  try {
    const result = await performGet(
      `${NEW_REPORT_ENDPOINT}/full/${reportUuid}`,
      {},
    );

    const promisesArray: Array<Promise<unknown>> = [];

    result.data.slides.forEach(slide => {
      slide.layout.forEach(async layout => {
        if (layout.contentType === 'WIDGET_V3') {
          if (JSON.stringify(layout.content.chartData) === '[]') {
            const x = performGet(
              `${NEW_REPORT_ENDPOINT}/full/${reportUuid}?widget_id=${layout.content.id}`,
              {},
              null,
              null,
              false, //useCache  TODO, when we light that up in dashboards and find it is OK then do it here too
              10,
            ).then(
              results => (layout.content.chartData = results.data.chartData),
            );
            promisesArray.push(x);
          }
        }
      });
    });

    await Promise.all(promisesArray);

    const report = result.data;
    report.slides.sort((a, b) => a.pageIndex - b.pageIndex);
    report.slides.forEach(slide => {
      slide.layout.forEach(layout => {
        const hydratedChartData = hydrateWidgetData(
          layout.content,
          layout.content.chartData,
        );
        layout.content.widgetType = getWidgetTypeFromMetrics(
          layout.content.metrics,
        );
        layout.content.chartType = getChartTypeFromMetrics(
          layout.content.metrics,
        );
        layout.content.chartData = hydratedChartData;
        layout.content.chartData = sortDataByDataSources(
          layout.content.chartData,
        );
      });
    });
    report.slides = setFirstSlideCurrent(report.slides);

    dispatch({
      type: GET_REPORT_SUCCESS,
      payload: {
        report: report,
      },
    });

    dispatch(
      setLastViewedAnalyticActionCreator(report.id, NEW_REPORT_ENDPOINT),
    );
  } catch (e) {
    dispatch({ type: CREATE_REPORT_ERROR, payload: e.message });
  }
};

export const linkCampaignsWithReports = (
  reportId: string,
  newlySelectedCampaigns: any[],
  intl: any,
  isDetailReportPage?: boolean,
  uuId?: string,
) => async dispatch => {
  const updatedUrl = ADD_CAMPAIGNS_TO_REPORT.replace('{reportId}', reportId);
  const bulkUpdate = newlySelectedCampaigns.map(({ id: campaignId }) =>
    performPost(updatedUrl, { campaignIds: [campaignId] }).catch(error => ({
      error,
    })),
  );

  const responses = await Promise.all(bulkUpdate);
  const [successCampaigns, failedCampaigns] = responses.reduce(
    ([success, failed], { data, error }: any, index) => {
      const campaignTitle = newlySelectedCampaigns[index].title;

      if (error) {
        return [success, failed.concat(campaignTitle)];
      }

      return [success.concat(campaignTitle), failed];
    },

    [[], []],
  );

  if (successCampaigns.length) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          messages.linkReportWithCampaignSuccessMessage,
          { campaignTitles: successCampaigns.join(', ') },
        ),
        status: 'success',
      }),
    );
    isDetailReportPage
      ? dispatch(getReportActionCreator(uuId))
      : dispatch(
          allAnalyticActionCreator({ endpointBase: NEW_REPORT_ENDPOINT }),
        );
  }

  if (failedCampaigns.length) {
    dispatch({
      type: LINK_CAMPAIGNS_WITH_REPORT_ERROR,
      payload: { error: failedCampaigns.join(', ') },
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.linkReportWithCampaignErrorMessage, {
          campaignTitles: failedCampaigns.join(', '),
        }),
        status: 'danger',
      }),
    );
  }
};

const buildTextSlideTile = ({
  gridX,
  gridY,
  gridWidth,
  gridHeight,
  content,
}) => {
  const widget: TextWidget = {
    gridX,
    gridY,
    gridWidth,
    gridHeight,
    contentType: WIDGET_TYPES.textWidget,
    content,
  };
  return widget;
};

const buildImageSlideTile = ({ gridX, gridY, gridWidth, gridHeight }) => {
  const widget: TextWidget = {
    gridX,
    gridY,
    gridWidth,
    gridHeight,
    contentType: WIDGET_TYPES.imageWidget,
  };
  return widget;
};

const buildWidgetSlideTile = ({
  gridX,
  gridY,
  gridWidth,
  gridHeight,
  contentId,
}) => {
  const widget: WidgetSlideTile = {
    gridX,
    gridY,
    gridWidth,
    gridHeight,
    contentType: WIDGET_TYPES.widgetV3,
    contentId,
  };
  return widget;
};

const buildCustomSlide = (pageIndex, formatMessage) => ({
  pageIndex,
  title: formatMessage(sidebarMessages.newSlideCaption),
  slideTiles: [
    buildImageSlideTile({
      gridX: 0,
      gridY: 0,
      gridWidth: 1,
      gridHeight: 1,
    }),
    buildTextSlideTile({
      gridX: 1,
      gridY: 0,
      gridWidth: 3,
      gridHeight: 1,
      content: {
        header: formatMessage(messages.reportDefaultHeaderText),
        body: formatMessage(messages.reportDefaultBodyText),
      },
    }),
    buildImageSlideTile({
      gridX: 0,
      gridY: 1,
      gridWidth: 4,
      gridHeight: 2,
    }),
  ],
});

export const buildSlides = ({ intl, widgets, addTextWidgetToSlides }) => {
  const slides = [buildCustomSlide(0, intl.formatMessage)];
  widgets.forEach((widget, i: number) => {
    const content = {
      header:
        widget.title || intl.formatMessage(messages.reportDefaultHeaderText),
      body: intl.formatMessage(messages.reportDefaultBodyText),
    };

    const newSlide: any = {
      pageIndex: i + 1, // +1 because title slide will be first, before widget slides
      slideTiles: [
        buildWidgetSlideTile({
          gridX: 0,
          gridY: 0,
          gridWidth: addTextWidgetToSlides ? 3 : 4,
          gridHeight: 3,
          contentId: widget.id,
        }),
        // For every widget, create a text widget slide tile, if selected during
        // report generation.
        //
        ...(addTextWidgetToSlides
          ? [
              buildTextSlideTile({
                gridX: 3,
                gridY: 0,
                gridWidth: 1,
                gridHeight: 3,
                content,
              }),
            ]
          : []),
      ],
    };

    newSlide.title = widget.title;

    slides.push(newSlide);
  });

  return slides;
};

export const buildMetrics = (widget, widgetForReport = true) => {
  const {
    dateRangeType,
    startDate: nonCompareStartDate,
    endDate: nonCompareEndDate,
  } =
    widget.metrics.filter(metric => metric.isDateRangeCompare === false)[0] ||
    {};
  const newMetrics = widget.metrics?.map(metric => {
    const { startDate, endDate } = metric || {};
    const { startTime, endTime } =
      getTimestampsForAnyRange(
        metric?.dateRangeType,
        moment.utc(startDate).valueOf(),
        moment.utc(endDate).valueOf(),
      ) || {};

    const {
      compareStartDate,
      compareEndDate,
    } = buildDateRangeCompareTimestamps(
      metric?.dateRangeCompareType,
      {
        startDate: nonCompareStartDate,
        endDate: nonCompareEndDate,
        type: dateRangeType,
      },
      startTime,
      endTime,
    );
    return {
      ...(widgetForReport ? metric : {}),
      id: metric.id,
      startDate: compareStartDate || startTime,
      endDate: compareEndDate || endTime,
      dateRangeType: widgetForReport
        ? DATE_RANGE_KEYS.CUSTOM
        : metric?.dateRangeType,
      dateRange: {
        ...(metric?.dateRange || {}),
        startDate: startTime,
        endDate: endTime,
      },
    };
  });
  return newMetrics;
};

export const buildMetricsByWidgets = widgets => {
  return widgets.map(widget => {
    const newMetrics = buildMetrics(widget, false);
    return {
      id: widget.id,
      metrics: newMetrics,
    };
  });
};

export const createReportActionCreator = ({
  titleReport = null,
  widgets,
  intl,
  addTextWidgetToSlides = true,
}) => async dispatch => {
  dispatch({ type: CREATE_REPORT });
  const title = titleReport || 'Untitled Report';
  const slides = buildSlides({
    intl,
    widgets,
    addTextWidgetToSlides,
  });
  const widgetsTimeStamps = buildMetricsByWidgets(widgets);
  const reportContent = {
    // @TODO: This needs to be internationalized in a component.
    //
    title,
    slides,
  };
  try {
    const result = await performPost(`${NEW_REPORT_ENDPOINT}`, {
      report: reportContent,
      metrics: widgetsTimeStamps,
    });
    const report = result.data;
    report.slides = setFirstSlideCurrent(report.slides);
    dispatch({ type: CREATE_REPORT_SUCCESS, payload: { report: report } });
  } catch (e) {
    dispatch({ type: CREATE_REPORT_ERROR, payload: e.message });
  }
};

const updateSlidesBasedOnType = ({ slideTileId, slides, type, content }) => {
  const newSlides = slides.map(slide => {
    const newLayout = slide.layout.map(layout => {
      if (slideTileId === `${layout.id}` && layout.contentType === type) {
        return {
          ...layout,
          content: {
            ...layout.content,
            ...content,
          },
        };
      }
      return layout;
    });
    return {
      ...slide,
      layout: newLayout,
    };
  });
  return newSlides;
};

export const updateTextWidgetActionCreator = (
  slideTileId,
  id,
  header,
  body,
) => async (dispatch, getState) => {
  try {
    const result = await performPut(`${REPORT_UPDATE_TEXT_WIDGET}/${id}`, {
      header,
      body,
    });
    const { body: newBody, header: newHeader } = result.data;
    const state = getState();
    const slides = reportSlides(state);
    const newSlides = updateSlidesBasedOnType({
      slideTileId,
      slides,
      type: 'TEXT_WIDGET',
      content: { body: newBody, header: newHeader },
    });
    dispatch({ type: UPDATE_TEXT_WIDGET_SUCCESS, payload: { newSlides } });
  } catch (e) {
    dispatch({ type: UPDATE_TEXT_WIDGET_ERROR, payload: e.message });
  }
};

export const updateImageWidgetActionCreator = (slideTileId, id, data) => async (
  dispatch,
  getState,
) => {
  try {
    const result = await performPost(
      `${REPORT_IMAGE_WIDGET}/${id}/upload`,
      data,
    );
    const { link } = result.data;
    const state = getState();
    const slides = reportSlides(state);
    const newSlides = updateSlidesBasedOnType({
      slideTileId,
      slides,
      type: 'IMAGE',
      content: { link },
    });
    dispatch({
      type: UPDATE_IMAGE_WIDGET_SUCCESS,
      payload: { newSlides },
    });
  } catch (e) {
    dispatch({ type: UPDATE_IMAGE_WIDGET_ERROR, payload: e.message });
  }
};

export const updateReportDateRange = (
  reportUuid,
  dateRange,
) => async dispatch => {
  try {
    await performPatch(`${NEW_REPORT_ENDPOINT}/${reportUuid}/dateRange`, {
      ...dateRange,
    });
    dispatch(getReportActionCreator(reportUuid));
  } catch (e) {
    dispatch(
      addPageMessage({
        text: e.response.data.error,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};

export const removeImageWidgetActionCreator = (slideTileId, id) => async (
  dispatch,
  getState,
) => {
  try {
    const result = await performPatch(`${REPORT_IMAGE_WIDGET}/${id}/remove`);
    const { link } = result.data;
    const state = getState();
    const slides = reportSlides(state);
    const newSlides = updateSlidesBasedOnType({
      slideTileId,
      slides,
      type: 'IMAGE',
      content: { link },
    });
    dispatch({
      type: REMOVE_IMAGE_WIDGET_SUCCESS,
      payload: { newSlides },
    });
  } catch (e) {
    dispatch({ type: REMOVE_IMAGE_WIDGET_ERROR, payload: e.message });
  }
};

export const duplicateReportActionCreator = dashboardId => async dispatch => {
  try {
    dispatch({ type: DUPLICATE_REPORT });
    const result = await performPost(
      `${NEW_REPORT_ENDPOINT}/${dashboardId}/duplicate`,
      {},
    );
    const report = result.data;
    report.slides = setFirstSlideCurrent(report.slides);
    dispatch({
      type: DUPLICATE_REPORT_SUCCESS,
      payload: { report: report },
    });
    dispatch(
      addPageMessage({
        text: `Report ${result.data.title} has been successfully duplicated.`,
        status: 'success',
        ttl: 6000,
      }),
    );
  } catch (e) {
    dispatch({ type: DUPLICATE_REPORT_ERROR, payload: e.message });
    dispatch(
      addPageMessage({
        text: e.response?.data?.error,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};

const removeWidgetFromSlide = (slides, slideTileId) => {
  const newSlides = slides.map(slide => {
    const newLayout = slide.layout.filter(layout => {
      if (`${slideTileId}` === `${layout.id}`) {
        return false;
      }
      return true;
    });
    return {
      ...slide,
      layout: newLayout,
    };
  });
  return newSlides;
};

export const deleteWidgetFromReportSlideActionCreator = slideTileId => async (
  dispatch,
  getState,
) => {
  if (slideTileId) {
    dispatch({ type: DELETE_WIDGET_FROM_SLIDE });
    try {
      await performPatch(`${REMOVE_WIDGET_FROM_SLIDE}/${slideTileId}`);
      const state = getState();
      const slides = reportSlides(state);
      const newSlides = removeWidgetFromSlide(slides, slideTileId);
      dispatch({
        type: DELETE_WIDGET_FROM_SLIDE_SUCCESS,
        payload: { newSlides },
      });
    } catch (e) {
      dispatch({ type: DELETE_WIDGET_FROM_SLIDE_ERROR, payload: e.message });
    }
  }
};

export const updateWidgetLayoutFromReportSlideActionCreator = (
  slideTileId,
  legendShown,
  chartShown,
  legendPosition,
  legendSizeRatio,
) => async dispatch => {
  if (slideTileId) {
    dispatch({ type: UPDATE_WIDGET_LAYOUT_SLIDE, payload: slideTileId });
    try {
      const response = await performPatch(
        REPORT_WIDGET_LAYOUT_SLIDE.replace('{slideTileId}', slideTileId),
        {
          legendShown,
          chartShown,
          legendPosition,
          legendSizeRatio,
        },
      );
      const updatedSlideTile = response.data;
      dispatch({
        type: UPDATE_WIDGET_LAYOUT_SLIDE_SUCCESS,
        payload: { updatedSlideTile },
      });
    } catch (e) {
      dispatch({ type: UPDATE_WIDGET_LAYOUT_SLIDE_ERROR, payload: e.message });
    }
  }
};

const getChartDataWithWidgetId = (
  id,
  startDate,
  endDate,
  mediaTypeFilters,
  compareStartDate = null,
  compareEndDate = null,
  granularityType = null,
) => {
  const dateRangeParams = buildDateRangeParams({
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
  });

  const useCache = userHasDevFeatureFlag(DEV_FEATURES.cacheWidgetData);

  return performGet(
    `${WIDGET_V3_ENDPOINT}/${id}/data`,
    {
      ...dateRangeParams,
      viewFilters: mediaTypeFilters,
      granularityType,
    },
    null,
    null,
    useCache,
  );
};

export const getChartDataFromWidgetIdActionCreator = ({
  widget,
  startDate = null,
  endDate = null,
  mediaTypeFilters = [],
  compareStartDate = null,
  compareEndDate = null,
  granularityType = null,
}) => async dispatch => {
  const id = widget.id;
  dispatch({ type: GET_CHART_DATA, payload: { id } });
  let response;

  try {
    response = await getChartDataWithWidgetId(
      id,
      startDate,
      endDate,
      mediaTypeFilters,
      compareStartDate,
      compareEndDate,
      granularityType,
    );
  } catch (e) {
    dispatch({ type: GET_CHART_DATA_ERROR, payload: { id } });
    throw e;
  }

  dispatch({
    type: GET_CHART_DATA_SUCCESS,
    payload: {
      data: getProcessedWidgetData(widget, response.data.data),
      id,
    },
  });
};

export const updateWidgetActionCreator = (
  widgetForm,
  convertWidget = true,
) => async dispatch => {
  const widget = convertWidget
    ? convertWidgetFormToV3Widget(widgetForm)
    : widgetForm;

  dispatch({
    type: UPDATE_WIDGET,
    payload: { id: widget.id },
  });

  dispatch({ type: UPDATE_V3_WIDGET, payload: { id: widget.id } });

  bustWidgetDataCache(widget.id);

  try {
    const { data } = await performPut(
      `${WIDGET_V3_ENDPOINT}/${widget.id}`,
      widget,
    );
    const dateRange = getDateRange(widget.metrics);
    const { type, startDate, endDate } = dateRange || {};
    const times = getTimestampsForAnyRange(type, startDate, endDate);
    const dateRangeCompareMetric = widget.metrics.find(
      metric => metric.isDateRangeCompare,
    );
    const dateRangeCompareType =
      dateRangeCompareMetric?.dateRangeCompareType || null;
    let compareStartDate = null;
    let compareEndDate = null;

    if (dateRangeCompareType) {
      const compareTimes = buildDateRangeCompareTimestamps(
        dateRangeCompareType,
        dateRange,
        moment.utc(dateRangeCompareMetric.startDate).valueOf(),
        moment.utc(dateRangeCompareMetric.endDate).valueOf(),
      );
      compareStartDate = compareTimes.compareStartDate;
      compareEndDate = compareTimes.compareEndDate;
    }
    data.widgetType = getWidgetTypeFromMetrics(data.metrics);
    data.chartType = getChartTypeFromMetrics(data.metrics);
    dispatch({
      type: UPDATE_WIDGET_SUCCESS,
      payload: { id: data.id, content: data },
    });
    dispatch({
      type: UPDATE_V3_WIDGET_SUCCESS,
      payload: {
        id: data.id,
        content: data,
        widgetType: widget.widgetType,
        chartType: widget.chartType,
      },
    });
    dispatch(
      getChartDataFromWidgetIdActionCreator({
        widget: data,
        startDate: times?.startTime,
        endDate: times?.endTime,
        compareStartDate,
        compareEndDate,
        granularityType: widgetForm?.granularityType,
      }),
    );
  } catch (e) {
    dispatch({
      type: UPDATE_WIDGET_ERROR,
      payload: { error: e.message, id: widget.id },
    });
    dispatch({
      type: UPDATE_V3_WIDGET_ERROR,
      payload: { error: e.message, id: widget.id },
    });
  }
};

export const requestWidgetData = ({
  widgetId,
  reportUuid,
}) => async dispatch => {
  try {
    dispatch({ type: GET_CHART_DATA, payload: { id: widgetId } });
    const response = await performGet(
      `${NEW_REPORT_ENDPOINT}/full/${reportUuid}?widget_id=${widgetId}`,
      {},
    );
    const { title, metrics, chartData, widgetError, isoCode } = response.data;
    if (!widgetError) {
      dispatch({
        type: GET_CHART_DATA_SUCCESS,
        payload: {
          id: widgetId,
          title,
          data: getProcessedWidgetData({ metrics }, chartData),
          metrics,
          widgetType: getWidgetTypeFromMetrics(metrics),
          chartType: getChartTypeFromMetrics(metrics),
          isoCode: isoCode,
        },
      });
    } else {
      dispatch({ type: GET_CHART_DATA_ERROR, payload: { id: widgetId } });
    }
  } catch (e) {
    dispatch({ type: GET_CHART_DATA_ERROR, payload: { id: widgetId } });
  }
};

export const requestChartData = widget => async dispatch => {
  try {
    const dateRange = getDateRange(widget.metrics);
    const dateRangeCompareMetric = widget.metrics.find(
      metric => metric.isDateRangeCompare,
    );
    const dateRangeCompareType =
      dateRangeCompareMetric?.dateRangeCompareType || null;
    let compareStartDate = null;
    let compareEndDate = null;
    if (dateRangeCompareType) {
      const {
        startDate: compareCustomStartDate,
        endDate: compareCustomEndDate,
      } = getWidgetV3CompareDateRange(widget.metrics) || {};
      const compareTimes = buildDateRangeCompareTimestamps(
        dateRangeCompareType,
        dateRange,
        compareCustomStartDate,
        compareCustomEndDate,
      );
      compareStartDate = compareTimes.compareStartDate;
      compareEndDate = compareTimes.compareEndDate;
    }
    dispatch(
      getChartDataFromWidgetIdActionCreator({
        widget,
        startDate: (dateRange as any)?.startDate,
        endDate: (dateRange as any)?.endDate,
        compareStartDate,
        compareEndDate,
      }),
    );
  } catch (error) {
    dispatch({
      type: UPDATE_WIDGET_ERROR,
      payload: { error: error.message, id: widget.id },
    });
  }
};

export const updateWidgetDateRange = widget => async dispatch => {
  dispatch({
    type: UPDATE_WIDGET,
    payload: { id: widget.id },
  });
  try {
    const { data } = await performPut(`${WIDGET_V3_ENDPOINT}/${widget.id}`, {
      ...widget,
    });

    dispatch({
      type: UPDATE_WIDGET_SUCCESS,
      payload: { id: widget.id, content: data },
    });
    dispatch(requestChartData(widget));
  } catch (e) {
    dispatch({
      type: UPDATE_WIDGET_ERROR,
      payload: { error: e.message, id: widget.id },
    });
  }
};

export const updateWidgetDateRangeCompare = ({
  widget,
  compareType,
  customDate = null,
  dateRange,
  compareDateRange,
}) => async dispatch => {
  dispatch({
    type: UPDATE_WIDGET,
    payload: { id: widget.id },
  });
  try {
    const compareStartDate = customDate
      ? (customDate as any)?.startDate
      : compareDateRange?.compareStartDate;
    const compareEndDate = customDate
      ? (customDate as any)?.endDate
      : compareDateRange?.compareEndDate;
    const mainMetric = widget.metrics.find(m => !m.isDateRangeCompare);
    let dateRangeCompareMetric = widget.metrics.find(m => m.isDateRangeCompare);

    if (!compareType && dateRangeCompareMetric) {
      widget.metrics.splice(widget.metrics.indexOf(dateRangeCompareMetric), 1);
    } else {
      if (!dateRangeCompareMetric) {
        dateRangeCompareMetric = createDateRangeCompareMetric(
          mainMetric,
          compareType,
          customDate,
        );
        widget.metrics.push(dateRangeCompareMetric);
      }

      dateRangeCompareMetric.dateRangeCompareType = compareType;
      dateRangeCompareMetric.dateRangeType =
        compareType === DATE_RANGE_COMPARE_TYPES.customStartDate
          ? DATE_RANGE_KEYS.CUSTOM
          : DATE_RANGE_KEYS.TRAILING_90;
      dateRangeCompareMetric.startDate = (customDate as any)?.startDate;
      dateRangeCompareMetric.endDate = (customDate as any)?.endDate;
    }

    const { data } = await performPut(`${WIDGET_V3_ENDPOINT}/${widget.id}`, {
      ...widget,
    });

    dispatch({
      type: UPDATE_WIDGET_SUCCESS,
      payload: { id: widget.id, content: data },
    });
    dispatch(
      getChartDataFromWidgetIdActionCreator({
        widget: data,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        compareStartDate,
        compareEndDate,
      }),
    );
  } catch (e) {
    dispatch({
      type: UPDATE_WIDGET_ERROR,
      payload: { error: e.message, id: widget.id },
    });
  }
};

export const initializeExportingReport = () => dispatch => {
  dispatch({ type: EXPORT_REPORT_INITIALIZED });
};

export const startExportingReport = () => dispatch => {
  dispatch({ type: EXPORT_REPORT_CONFIRMING });
};

export const addCustomSlideActionCreator = (reportId, intl) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: ADD_CUSTOM_SLIDE });
  try {
    const { formatMessage } = intl;
    const state = getState();
    const currentSlides = reportSlides(state).map(s => ({
      ...s,
      isCurrent: false,
    }));
    const customSlide = buildCustomSlide(currentSlides?.length, formatMessage);
    const report = { slides: [customSlide] };
    const response = await performPost(
      `${NEW_REPORT_ENDPOINT}/${reportId}/add-custom-slide`,
      { report },
    );
    const addedSlide = response.data.slides.pop();
    const newSlides = [...currentSlides, { ...addedSlide, isCurrent: true }];
    dispatch({ type: ADD_CUSTOM_SLIDE_SUCCESS, payload: { newSlides } });
  } catch (e) {
    dispatch({ type: ADD_CUSTOM_SLIDE_ERROR, payload: { error: e } });
  }
};

export const updateSlideTitleActionCreator = (slideIndex, newTitle) => {
  return {
    type: UPDATE_SLIDE_TITLE,
    payload: { slideIndex, newTitle },
  };
};

export const addTileToSlideActionCreator = (slideId, tile) => {
  return {
    type: ADD_TILE_TO_SLIDE,
    payload: {
      slideId,
      tile,
    },
  };
};

export const addToSlidesBeingUpdatedActionCreator = slideId => {
  return {
    type: ADD_TO_SLIDES_BEING_UPDATED,
    payload: slideId,
  };
};

export const removeFromSlidesBeingUpdatedActionCreator = slideId => {
  return {
    type: REMOVE_FROM_SLIDES_BEING_UPDATED,
    payload: slideId,
  };
};

export const addTextWidgetToSlide = ({
  slideId,
  gridX,
  gridY,
  header,
  body,
  intl,
}) => async dispatch => {
  try {
    dispatch(addToSlidesBeingUpdatedActionCreator(slideId));
    dispatch(
      addPageMessage({
        text: header
          ? intl.formatMessage(messages.addingHeadlineToSlide)
          : intl.formatMessage(messages.addingBodyToSlide),
        isLoading: true,
        ttl: 4000,
      }),
    );
    let response = await performPost(REPORT_CREATE_TEXT_WIDGET, {
      header,
      body,
    });
    const textWidgetId = response.data.id;
    response = await performPost(
      REPORT_CREATE_SLIDE_TILE.replace('{id}', slideId),
      buildSlideTilePayload(
        gridX,
        gridY,
        DEFAULT_GRID_SIZE,
        DEFAULT_GRID_SIZE,
        WIDGET_TYPES.textWidget,
        textWidgetId,
      ),
    );
    const { id } = response.data;
    dispatch(
      addTileToSlideActionCreator(
        slideId,
        buildSlideTile(
          id,
          gridX,
          gridY,
          DEFAULT_GRID_SIZE,
          DEFAULT_GRID_SIZE,
          WIDGET_TYPES.textWidget,
          {
            id: textWidgetId,
            body,
            header,
          },
        ),
      ),
    );
    dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
  } catch (error) {
    dispatch(
      addPageMessage({
        text: intl.formatMessage(
          header
            ? messages.errorAddingHeadlineToSlide
            : messages.errorAddingBodyToSlide,
        ),
        status: 'danger',
        ttl: 5000,
      }),
    );
    dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
  }
};

export const addImageWidgetToSlide = ({
  slideId,
  gridX,
  gridY,
  intl,
}) => async dispatch => {
  dispatch(addToSlidesBeingUpdatedActionCreator(slideId));
  dispatch(
    addPageMessage({
      text: intl.formatMessage(messages.addingImageToSlide),
      isLoading: true,
      ttl: 4000,
    }),
  );
  try {
    let response = await performPost(REPORT_IMAGE_WIDGET);
    const imageWidgetId = response.data.id;
    response = await performPost(
      REPORT_CREATE_SLIDE_TILE.replace('{id}', slideId),
      buildSlideTilePayload(
        gridX,
        gridY,
        DEFAULT_GRID_SIZE,
        DEFAULT_GRID_SIZE,
        WIDGET_TYPES.imageWidget,
        imageWidgetId,
      ),
    );
    const { id } = response.data;
    dispatch(
      addTileToSlideActionCreator(
        slideId,
        buildSlideTile(
          id,
          gridX,
          gridY,
          DEFAULT_GRID_SIZE,
          DEFAULT_GRID_SIZE,
          WIDGET_TYPES.imageWidget,
          {
            id: imageWidgetId,
            link: null,
          },
        ),
      ),
    );
    dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
  } catch (error) {
    dispatch(
      addPageMessage({
        text: intl.formatMessage(messages.errorAddingImageToSlide),
        status: 'danger',
        ttl: 5000,
      }),
    );
    dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
  }
};

export const addExistingWidgetToSlide = ({
  reportUuid,
  slideId,
  gridX,
  gridY,
  widgetId,
  intl,
}) => async dispatch => {
  try {
    dispatch(addToSlidesBeingUpdatedActionCreator(slideId));
    dispatch(
      addPageMessage({
        text: intl.formatMessage(messages.addingWidgetToSlide),
        isLoading: true,
        ttl: 4000,
      }),
    );
    const response = await performPost(
      REPORT_CREATE_SLIDE_TILE.replace('{id}', slideId),
      buildSlideTilePayload(
        gridX,
        gridY,
        1,
        1,
        WIDGET_TYPES.widgetV3,
        widgetId,
      ),
    );
    dispatch(
      addTileToSlideActionCreator(
        slideId,
        buildSlideTile(
          response.data.id,
          gridX,
          gridY,
          DEFAULT_GRID_SIZE,
          DEFAULT_GRID_SIZE,
          WIDGET_TYPES.widgetV3,
          {
            id: widgetId,
          },
        ),
      ),
    );
    dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
    dispatch(requestWidgetData({ widgetId, reportUuid }));
  } catch (error) {
    dispatch(
      addPageMessage({
        text: intl.formatMessage(messages.errorAddingWidgetToSlide),
        status: 'danger',
        ttl: 5000,
      }),
    );
    dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
  }
};

export const createWidget = widgetForm => {
  let metrics = createMetricsForWidget(widgetForm);
  metrics = buildMetrics({ metrics }, true);
  return performPost(
    WIDGET_V3_ENDPOINT,
    createWidgetPayload(widgetForm.title, widgetForm.dashboardId, metrics),
  );
};

export const addWidgetToSlide = ({
  reportUuid,
  widgetForm,
  slideId,
  gridX,
  gridY,
  intl,
}) => async dispatch => {
  dispatch(addToSlidesBeingUpdatedActionCreator(slideId));
  dispatch(
    addPageMessage({
      text: intl.formatMessage(messages.addingWidgetToSlide),
      isLoading: true,
      ttl: 4000,
    }),
  );
  let widget;
  let createTileResponse;

  try {
    const widgetResponse = await createWidget(widgetForm);
    widget = widgetResponse.data;
    createTileResponse = await performPost(
      REPORT_CREATE_SLIDE_TILE.replace('{id}', slideId),
      buildSlideTilePayload(
        gridX,
        gridY,
        DEFAULT_GRID_SIZE,
        DEFAULT_GRID_SIZE,
        WIDGET_TYPES.widgetV3,
        widget.id,
      ),
    );
  } catch (error) {
    dispatch(
      addPageMessage({
        text: intl.formatMessage(messages.errorAddingWidgetToSlide),
        status: 'danger',
        ttl: 5000,
      }),
    );
    dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
    return;
  }

  dispatch(
    addTileToSlideActionCreator(
      slideId,
      buildSlideTile(
        createTileResponse.data.id,
        gridX,
        gridY,
        DEFAULT_GRID_SIZE,
        DEFAULT_GRID_SIZE,
        WIDGET_TYPES.widgetV3,
        {
          id: widget.id,
          title: widget.title,
          metrics: widget.metrics,
        },
      ),
    ),
  );
  dispatch(removeFromSlidesBeingUpdatedActionCreator(slideId));
  dispatch(requestWidgetData({ widgetId: widget.id, reportUuid }));
};

export const updateSlideTitle = (
  slideIndex,
  slide,
  newTitle,
) => async dispatch => {
  dispatch(updateSlideTitleActionCreator(slideIndex, newTitle));
  try {
    await performPatch(`${NEW_REPORT_SLIDE_ENDPOINT}/${slide.id}`, {
      title: newTitle,
    });
  } catch (e) {
    dispatch(updateSlideTitleActionCreator(slideIndex, slide.title));
  }
};

export default reportHubReducer;
