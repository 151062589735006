export const TOGGLE_SCHEDULE_MODAL = 'outreach/TOGGLE_SCHEDULE_MODAL';
export const CLOSE_SCHEDULE_MODAL = 'outreach/CLOSE_SCHEDULE_MODAL';

export const TOGGLE_SCHEDULE_MODAL_ERROR =
  'outreach/TOGGLE_SCHEDULE_MODAL_ERROR';
export const CLOSE_SCHEDULE_MODAL_ERROR = 'outreach/CLOSE_SCHEDULE_MODAL_ERROR';

const initialState = {
  isScheduleModalOpen: false,
  isScheduleModalErrorOpen: false,
};

const outreachScheduleModalReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CLOSE_SCHEDULE_MODAL:
      return {
        ...state,
        isScheduleModalOpen: false,
      };
    case TOGGLE_SCHEDULE_MODAL: {
      return {
        ...state,
        isScheduleModalOpen: !state.isScheduleModalOpen,
      };
    }
    case TOGGLE_SCHEDULE_MODAL_ERROR: {
      return {
        ...state,
        isScheduleModalErrorOpen: !state.isScheduleModalErrorOpen,
      };
    }
    case CLOSE_SCHEDULE_MODAL_ERROR: {
      return {
        ...state,
        isScheduleModalErrorOpen: false,
      };
    }
    default:
      return state;
  }
};

export const closeScheduleModalActionCreator = () => ({
  type: CLOSE_SCHEDULE_MODAL,
});
export const toggleScheduleModalActionCreator = () => ({
  type: TOGGLE_SCHEDULE_MODAL,
});

export const closeScheduleModalErrorActionCreator = () => ({
  type: CLOSE_SCHEDULE_MODAL_ERROR,
});
export const toggleScheduleModalErrorActionCreator = () => ({
  type: TOGGLE_SCHEDULE_MODAL_ERROR,
});

export default outreachScheduleModalReducer;
