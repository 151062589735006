import * as constants from 'constants/constants';

const contactListInfo = (
  state = {
    id: '',
    name: '',
    favorite: false,
    loading: true,
    sort: constants.CONTACTS_SORT_DEFAULT_ID,
  },
  action,
) => {
  switch (action.type) {
    case 'ADD_LIST_INFO':
      return {
        favorite: action.list.favorite,
        id: action.list.id,
        name: action.list.name,
        loading: false,
        shared: action.list.shared,
        sort: action.list.sort || constants.CONTACTS_SORT_DEFAULT_ID,
      };
    default:
      return state;
  }
};

export default contactListInfo;
