import React, { Component } from 'react';

import PropTypes from 'prop-types';

import EditStoryCompanyBackground from './EditStoryCompanyBackground';

class EditStoryCompanyBackgroundContainer extends Component {
  onEndEditing = () => {
    this.props.onEndEditing();
  };

  render() {
    const { focus, inputModelName, updateOn, value } = this.props;

    return (
      <EditStoryCompanyBackground
        focus={focus}
        inputModelName={inputModelName}
        onEndEditing={this.onEndEditing}
        updateOn={updateOn}
        value={value}
      />
    );
  }
}

EditStoryCompanyBackgroundContainer.propTypes = {
  focus: PropTypes.bool,
  inputModelName: PropTypes.string,
  onEndEditing: PropTypes.func.isRequired,
  updateOn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.string,
};

EditStoryCompanyBackgroundContainer.defaultProps = {
  focus: false,
  inputModelName: 'background',
  updateOn: 'change',
  value: '',
};

export default EditStoryCompanyBackgroundContainer;
