import React, { useState, useEffect } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  DateRangePicker,
  ExpandableDropdown,
  PopperDropdown,
} from '@trendkite/ui';

import { DEFAULT_DATE_FORMAT, UTC_DATE_FORMAT_INTL } from 'constants/constants';
import usePreviousValue from 'hooks/use-previous-value';
import {
  DATE_RANGE_KEYS,
  getStartOfDay,
  getOneSecondBeforeMidnight,
  getTimestampsForAnyRange,
} from 'utils/date/date-util';

import messages from 'utils/date/date-util.messages';

import { truncateEndDateTimestamp } from './utils';

import customMessages from './widget-v3-date-range.messages';

import styles from './widget-v3-footer.module.css';

const WidgetV3DateRange = ({
  buttonModifiers,
  dateRange,
  editable,
  onDateRangeUpdate,
  overrideLabel,
  leftIcon,
  showTrailing,
  dataQA,
  dateRangeModifiers,
  popperDropdownConfig,
  dateRangeLimitOneYear,
  dateRangeLimitMessage,
}) => {
  const intl = useIntl();
  const DATE_RANGE_OPTIONS = {
    [DATE_RANGE_KEYS.CUSTOM]: {
      label: intl.formatMessage(customMessages.widgetCustomDateRange),
      showOption: true,
      callback: () => {
        handleCustomDateSelected();
      },
    },
    [DATE_RANGE_KEYS.SINCE_YESTERDAY]: {
      label: intl.formatMessage(messages.sinceYesterdayLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.TRAILING_7]: {
      label: intl.formatMessage(messages.trailing7Label),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.TRAILING_14]: {
      label: intl.formatMessage(messages.trailing14Label),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.TRAILING_30]: {
      label: intl.formatMessage(messages.trailing30Label),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.TRAILING_90]: {
      label: intl.formatMessage(messages.trailing90Label),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.WEEK_TO_DATE]: {
      label: intl.formatMessage(messages.weekToDateLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.MONTH_TO_DATE]: {
      label: intl.formatMessage(messages.monthToDateLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.QUARTER_TO_DATE]: {
      label: intl.formatMessage(messages.quarterToDateLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.YEAR_TO_DATE]: {
      label: intl.formatMessage(messages.yearToDateLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.LAST_WEEK]: {
      label: intl.formatMessage(messages.lastWeekLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.LAST_MONTH]: {
      label: intl.formatMessage(messages.lastMonthLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.LAST_QUARTER]: {
      label: intl.formatMessage(messages.lastQuarterLabel),
      showOption: showTrailing,
    },
    [DATE_RANGE_KEYS.LAST_YEAR]: {
      label: intl.formatMessage(messages.lastYearLabel),
      showOption: showTrailing,
    },
  };

  const { type: dateRangeInitVal, startDate, endDate } = dateRange || {};
  let startTimeInitVal = moment.utc(startDate);
  let endTimeInitVal = moment.utc(endDate);

  if (dateRangeInitVal !== DATE_RANGE_KEYS.CUSTOM) {
    const times = getTimestampsForAnyRange(dateRangeInitVal);
    startTimeInitVal = times.startTime;
    // This truncates the timestamp to 59 seconds (1 full second from midnight).
    // It is required because Grails rounds up the time to the 'stroke of midnight'
    // if the seconds are set to one millisecond before midnight.
    endTimeInitVal = truncateEndDateTimestamp(times.endTime).valueOf();
  }

  const [startTime, setStartTime] = useState(startTimeInitVal);
  const [endTime, setEndTime] = useState(endTimeInitVal);
  const [dateRangeVal, setDateRangeVal] = useState(dateRangeInitVal);
  const [customCalendarOpen, setCustomCalendarOpen] = useState(false);
  const previousDateRangeInitVal = usePreviousValue(
    dateRangeInitVal,
    dateRangeInitVal,
  );

  useEffect(() => {
    if (previousDateRangeInitVal !== dateRangeInitVal) {
      setDateRangeVal(dateRangeInitVal);
    }
  }, [previousDateRangeInitVal, dateRangeInitVal, setDateRangeVal]);

  const handleDateRangeChange = (newDateRange, start, end) => {
    setCustomCalendarOpen(newDateRange === DATE_RANGE_KEYS.CUSTOM);
    setDateRangeVal(newDateRange);
    const { startTime, endTime } = getTimestampsForAnyRange(
      newDateRange,
      start,
      end,
    );

    const processedDates =
      newDateRange === DATE_RANGE_KEYS.CUSTOM
        ? {
            startDate: getStartOfDay(startTime),
            endDate: getOneSecondBeforeMidnight(endTime),
          }
        : {
            startDate: startTime,
            endDate: endTime,
          };

    setStartTime(processedDates.startDate);
    setEndTime(processedDates.endDate);

    onDateRangeUpdate({
      dateRange: {
        ...processedDates,
        type: newDateRange,
      },
    });
  };

  const handleCustomDateSelected = () => {
    setCustomCalendarOpen(true);
  };

  const handleCustomDateSave = (start, end) => {
    handleDateRangeChange(DATE_RANGE_KEYS.CUSTOM, start, end);
    setCustomCalendarOpen(false);
  };

  const handleCustomDateCancel = () => {
    setCustomCalendarOpen(false);
  };

  const buildButtonText = () => {
    if (overrideLabel) {
      return overrideLabel;
    }
    let label;
    if (startTime && endTime && dateRangeVal === DATE_RANGE_KEYS.CUSTOM) {
      const startDate = intl.formatDate(
        moment.utc(startTime),
        UTC_DATE_FORMAT_INTL,
      );
      const endDate = intl.formatDate(
        moment.utc(endTime),
        UTC_DATE_FORMAT_INTL,
      );
      label = `${startDate} ${intl.formatMessage(
        customMessages.customDateRangeStringConnector,
      )} ${endDate}`;
    } else if (dateRangeVal) {
      label = customCalendarOpen
        ? DATE_RANGE_OPTIONS.CUSTOM.label
        : DATE_RANGE_OPTIONS[dateRangeVal].label;
    } else {
      label = intl.formatMessage(customMessages.widgetDateRangeChoose);
    }
    return label;
  };

  return (
    <PopperDropdown isOpen={customCalendarOpen} {...popperDropdownConfig}>
      <PopperDropdown.Trigger>
        <ExpandableDropdown
          dropdownModifiers={['inline-block']}
          dropdownOptions={DATE_RANGE_OPTIONS}
          buttonModifiers={buttonModifiers}
          buttonText={buildButtonText()}
          showSelected={false}
          leftIcon={leftIcon}
          disabled={!editable}
          dropdownOptionModifiers={DATE_RANGE_OPTIONS}
          onItemClick={handleDateRangeChange}
          includeMore={false}
          dataQA={dataQA}
        />
      </PopperDropdown.Trigger>
      <PopperDropdown.Content className={styles.popperDropdown}>
        <DateRangePicker
          modifiers={dateRangeModifiers}
          dateFormat={DEFAULT_DATE_FORMAT}
          startDate={startTime}
          endDate={endTime}
          onCancel={handleCustomDateCancel}
          onSave={handleCustomDateSave}
          dateRangeLimitOneYear={dateRangeLimitOneYear}
          dateRangeLimitMessage={dateRangeLimitMessage}
        />
      </PopperDropdown.Content>
    </PopperDropdown>
  );
};

WidgetV3DateRange.propTypes = {
  buttonModifiers: PropTypes.array,
  dateRange: PropTypes.shape({
    type: PropTypes.string,
    startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  editable: PropTypes.bool,
  onDateRangeUpdate: PropTypes.func,
  leftIcon: PropTypes.shape({
    icon: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    modifiers: PropTypes.array,
  }),
  overrideLabel: PropTypes.string,
  showTrailing: PropTypes.bool,
  dataQA: PropTypes.string,
  dateRangeModifiers: PropTypes.array,
  popperDropdownConfig: PropTypes.object,
  dateRangeLimitOneYear: PropTypes.bool,
  dateRangeLimitMessage: PropTypes.string,
};

WidgetV3DateRange.defaultProps = {
  buttonModifiers: ['flex', 'dark-text'],
  dateRange: {
    type: DATE_RANGE_KEYS.TRAILING_90,
    startDate: null,
    endDate: null,
  },
  editable: true,
  onDateRangeUpdate: () => {},
  leftIcon: {
    icon: 'calendar',
    width: 20,
    height: 20,
    modifiers: ['block'],
  },
  overrideLabel: null,
  showTrailing: true,
  dataQA: null,
  dateRangeModifiers: [],
  popperDropdownConfig: {},
  dateRangeLimitOneYear: false,
  dateRangeLimitMessage: '',
};

export default WidgetV3DateRange;
