import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import {
  Actions,
  OpportunityListActionTypes,
  OpportunityListState,
} from './types';

export const defaultSearchCriteria = {
  and: {},
  or: {},
  andNot: {},
  dataSourceIds: [],
  description: '',
  topicIds: [],
  sort: 'deadlineDate',
  deadlineDateRange: {},
  pagenumber: 1,
};

const initialState: OpportunityListState = {
  forcedSearchCriteria: {
    dataSourceIds: [...defaultSearchCriteria.dataSourceIds],
  },
  searchCriteria: { ...defaultSearchCriteria },
  allSelected: false,
  selectedIds: [],
  filterPanelOpen: true,
  chosenTopics: [],
};

const opportunityListReducer = (
  state = { ...initialState },
  action: OpportunityListActionTypes,
): OpportunityListState => {
  switch (action.type) {
    case Actions.OPPORTUNITY_BULK_PAGE_SELECT_ALL_OPPORTUNITIES: {
      return {
        ...state,
        allSelected: true,
        selectedIds: uniq([...state.selectedIds, ...action.payload]),
      };
    }

    case Actions.OPPORTUNITY_BULK_PAGE_UNSELECT_ALL_OPPORTUNITIES: {
      return {
        ...state,
        allSelected: false,
        selectedIds: [],
      };
    }

    case Actions.OPPORTUNITY_SELECTED_ID: {
      return {
        ...state,
        selectedIds: uniq([...state.selectedIds, action.payload]),
      };
    }

    case Actions.OPPORTUNITY_UNSELECTED_ID: {
      return {
        ...state,
        allSelected: false,
        selectedIds: state.selectedIds.filter(
          id => !isEqual(id, action.payload),
        ),
      };
    }

    case Actions.OPPORTUNITY_SET_SEARCH_CRITERIA: {
      return {
        ...state,
        searchCriteria: action.payload,
      };
    }

    case Actions.OPPORTUNITY_SET_DEADLINE_DATE_RANGE: {
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          deadlineDateRange: action.payload,
          pagenumber: 1,
        },
      };
    }

    case Actions.OPPORTUNITY_CLEAR_SEARCH: {
      return {
        ...initialState,
        forcedSearchCriteria: state.forcedSearchCriteria,
      };
    }

    case Actions.OPPORTUNITY_TOGGLE_FILTER_PANEL: {
      return {
        ...state,
        filterPanelOpen: !state.filterPanelOpen,
      };
    }

    case Actions.OPPORTUNITY_CHOSEN_TOPICS: {
      return {
        ...state,
        chosenTopics: action.payload,
      };
    }

    case Actions.OPPORTUNITY_SET_FORCED_SEARCH_CRITERIA: {
      return {
        ...state,
        forcedSearchCriteria: action.payload,
      };
    }

    default:
      return state;
  }
};

export default opportunityListReducer;
