import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Media } from '../../';

const toggleSelection = props => () => {
  const { onChange, itemIds, selectedIds } = props;

  if (itemIds.length > selectedIds.length) {
    onChange(itemIds);
  } else {
    onChange([]);
  }
};

const BulkActions = props => {
  const { children, onChange, itemIds, itemLabel, selectedIds } = props;

  const selectedCount = selectedIds.length;
  const totalCount = itemIds.length;

  if (totalCount < 1) {
    return (
      <div className="tk-bulk-actions">
        <Media style={{ alignItems: 'center' }}>
          <Media.Item>
            <Checkbox disabled onClick={() => {}} selected={false} />
          </Media.Item>
        </Media>
      </div>
    );
  }

  return (
    <div className="tk-bulk-actions">
      <Media style={{ alignItems: 'center' }}>
        <Media.Item style={{ marginRight: '10px' }}>
          <Checkbox
            onClick={toggleSelection({ onChange, itemIds, selectedIds })}
            selected={selectedCount === totalCount}
            indeterminate={selectedCount > 0 && selectedCount < totalCount}
          />
        </Media.Item>
        <Media.Body>
          {selectedCount === 0 ? (
            <span>Select all {itemLabel.plural}</span>
          ) : (
            children
          )}
        </Media.Body>
      </Media>
    </div>
  );
};

BulkActions.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  itemIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  itemLabel: PropTypes.shape({
    plural: PropTypes.string,
    singular: PropTypes.string,
  }),
  selectedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

BulkActions.defaultProps = {
  children: null,
  onChange: () => {},
  itemIds: [],
  itemLabel: { singular: 'item', plural: 'items' },
  selectedIds: [],
};

export default BulkActions;
