export const ADD_CONTACT_IMAGE_PLACEHOLDERS =
  'contact-images/ADD_CONTACT_IMAGE_PLACEHOLDERS';
export const CONTACTS_IMAGE_DATA_RECEIVED =
  'contact-images/CONTACTS_IMAGE_DATA_RECEIVED';

const initialState = {
  images: {},
};

const baseImage = {
  loading: true,
  url: '',
};

const contactImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTACT_IMAGE_PLACEHOLDERS: {
      const images = { ...state.images };
      const newContactImages = action.payload.contactIds.reduce(
        (result, contactId) => {
          result[contactId] = { ...baseImage };
          return result;
        },
        {},
      );
      Object.keys(newContactImages).forEach(newImageContactId => {
        if (images[newImageContactId] === undefined) {
          images[newImageContactId] = newContactImages[newImageContactId];
        }
      });

      return {
        ...state,
        images,
      };
    }

    case CONTACTS_IMAGE_DATA_RECEIVED: {
      const returnedImages = action.payload.contactImageResults.reduce(
        (imageResults, author) => {
          imageResults[author.id] = author.imageUrl;
          return imageResults;
        },
        {},
      );

      const contactIdsImagesFetchedFor = action.payload.contactsImagesFetchedFor.map(
        contact => contact.id,
      );

      const images = Object.keys(state.images).reduce(
        (result, contactImageId) => {
          if (returnedImages[contactImageId] !== undefined) {
            result[contactImageId] = {
              ...state.images[contactImageId],
              url: returnedImages[contactImageId],
              loading: false,
            };

            return result;
          }

          if (contactIdsImagesFetchedFor.indexOf(contactImageId) !== -1) {
            // an attempt to fetch the image for the contact was made, but there was no result
            result[contactImageId] = {
              ...state.images[contactImageId],
              loading: false, // mark image not in response as not loading,
            };

            return result;
          }

          result[contactImageId] = {
            ...state.images[contactImageId],
          };

          return result;
        },
        { ...state.images },
      );

      return {
        ...state,
        images,
      };
    }

    default:
      return state;
  }
};

export const addContactImagePlaceholders = contactIds => ({
  type: ADD_CONTACT_IMAGE_PLACEHOLDERS,
  payload: { contactIds },
});

export const addImagesToContacts = (
  contactImageResults,
  contactsImagesFetchedFor,
) => ({
  type: CONTACTS_IMAGE_DATA_RECEIVED,
  payload: { contactImageResults, contactsImagesFetchedFor },
});

export default contactImagesReducer;
