import includes from 'lodash/includes';

import { API_BASE_URL, ADMIN_FEATURE_ENDPOINT } from 'constants/apis';
import messages from 'pages/Admin/AccountManagement/FeaturesModal/FeatureModal.message';

import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { performGet, performPost } from 'services/rest-service/rest-service';

export const GET_ACCOUNT_DEV_FEATURES_INFO =
  'adminFeatureManagement/GET_ACCOUNT_FEATURES_INFO';
export const GET_ACCOUNT_DEV_FEATURES_SUCCESS =
  'adminFeatureManagement/GET_ACCOUNT_DEV_FEATURES_SUCCESS';
export const GET_ACCOUNT_DEV_FEATURES_ERROR =
  'adminFeatureManagement/GET_ACCOUNT_DEV_FEATURES_ERROR';

export const UPDATE_ACCOUNT_DEV_FEATURES_INFO =
  'adminFeatureManagement/UPDATE_ACCOUNT_FEATURES_INFO';
export const UPDATE_ACCOUNT_DEV_FEATURES_SUCCESS =
  'adminFeatureManagement/UPDATE_ACCOUNT_DEV_FEATURES_SUCCESS';
export const UPDATE_ACCOUNT_DEV_FEATURES_ERROR =
  'adminFeatureManagement/UPDATE_ACCOUNT_DEV_FEATURES_ERROR';

export const DELETE_ACCOUNT_DEV_FEATURES_INFO =
  'adminFeatureManagement/DELETE_ACCOUNT_FEATURES_INFO';
export const DELETE_ACCOUNT_DEV_FEATURES_SUCCESS =
  'adminFeatureManagement/DELETE_ACCOUNT_DEV_FEATURES_SUCCESS';
export const DELETE_ACCOUNT_DEV_FEATURES_ERROR =
  'adminFeatureManagement/DELETE_ACCOUNT_DEV_FEATURES_ERROR';

export const ADD_ACCOUNT_DEV_FEATURES_INFO =
  'adminFeatureManagement/ADD_ACCOUNT_FEATURES_INFO';
export const ADD_ACCOUNT_DEV_FEATURES_SUCCESS =
  'adminFeatureManagement/ADD_ACCOUNT_DEV_FEATURES_SUCCESS';
export const ADD_ACCOUNT_DEV_FEATURES_ERROR =
  'adminFeatureManagement/ADD_ACCOUNT_DEV_FEATURES_ERROR';

export const initialState = {
  accountFeatures: [],
  accountDevFeatures: [],
  loading: false,
  error: null,
};

const adminFeatureManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_DEV_FEATURES_INFO:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_DEV_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        accountDevFeatures: action.payload,
      };
    case GET_ACCOUNT_DEV_FEATURES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACCOUNT_DEV_FEATURES_INFO:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACCOUNT_DEV_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACCOUNT_DEV_FEATURES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ACCOUNT_DEV_FEATURES_INFO:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACCOUNT_DEV_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ACCOUNT_DEV_FEATURES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_ACCOUNT_DEV_FEATURES_INFO:
      return {
        ...state,
        loading: true,
      };
    case ADD_ACCOUNT_DEV_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ACCOUNT_DEV_FEATURES_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export const getAccountDevFeatures = accountId => async dispatch => {
  dispatch({ type: GET_ACCOUNT_DEV_FEATURES_INFO });
  try {
    const response = performGet(
      `${API_BASE_URL}/account/${accountId}/feature-flag`,
    );
    const buildFeatureMap = (all, enabled) => {
      return all.reduce(
        (acc, feature) => ({
          ...acc,
          [feature]: includes(enabled, feature),
        }),
        {},
      );
    };
    const featureMap = buildFeatureMap(
      Object.values(response.featureFlags),
      response.data.devFeatureFlags,
    );

    dispatch({
      type: GET_ACCOUNT_DEV_FEATURES_SUCCESS,
      payload: featureMap,
    });
  } catch (e) {
    dispatch({ type: GET_ACCOUNT_DEV_FEATURES_ERROR });
  }
};

export const updateFeature = (accountId, features, intl) => async dispatch => {
  dispatch({ type: GET_ACCOUNT_DEV_FEATURES_INFO });
  try {
    return performPost(
      `${API_BASE_URL}/account/${accountId}/feature-flag`,
      features,
    ).then(response => {
      if (response.status === 200 && response.data) {
        dispatch({ type: UPDATE_ACCOUNT_DEV_FEATURES_SUCCESS });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(messages.editFeaturesSuccessMsg, {
              ACCOUNT_ID: accountId,
            }),
            status: 'success',
          }),
        );
      }
    });
  } catch (e) {
    dispatch({ type: UPDATE_ACCOUNT_DEV_FEATURES_ERROR });
    throw e;
  }
};

export const deleteFeature = featureId => async dispatch => {
  dispatch({ type: DELETE_ACCOUNT_DEV_FEATURES_INFO });
  try {
    performPost(`${ADMIN_FEATURE_ENDPOINT}/delete`, featureId).then(
      response => {
        if (response.status === 200 && response.data) {
          dispatch({ type: DELETE_ACCOUNT_DEV_FEATURES_SUCCESS });
        }
      },
    );
  } catch (e) {
    dispatch({ type: DELETE_ACCOUNT_DEV_FEATURES_ERROR });
  }
};

export const addFeature = feature => async dispatch => {
  dispatch({ type: ADD_ACCOUNT_DEV_FEATURES_INFO });
  try {
    performPost(`${ADMIN_FEATURE_ENDPOINT}/save`, feature).then(response => {
      if (response.status === 200 && response.data) {
        dispatch({ type: ADD_ACCOUNT_DEV_FEATURES_SUCCESS });
      }
    });
  } catch (e) {
    dispatch({ type: ADD_ACCOUNT_DEV_FEATURES_ERROR });
  }
};

export default adminFeatureManagementReducer;
