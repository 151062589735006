import React from 'react';

import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import {
  Body,
  Button,
  EmbeddedVideoTile,
  ResponsiveContainer,
  ResponsiveContext,
  SvgIcon,
} from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';
import { videosPropTypes } from '../story/templates/default-template';

// i18n
import messages from './StoryVideoDisplay.messages';

const StoryVideosDisplay = ({ videos, isEditable }) => {
  const intl = useIntl();
  const validVideos = videos.filter(v => !!v.url);
  const customBreakpoints = [
    {
      name: '2up',
      minWidth: 520,
    },
  ];
  return (
    <StorySection>
      <StorySectionHeader label={intl.formatMessage(messages.title)} />
      <ResponsiveContainer customBreakpoints={customBreakpoints}>
        <ResponsiveContext.Consumer>
          {responsiveModifiers => {
            const twoUp = responsiveModifiers.includes('container-2up-only');
            const twoUpClass = twoUp ? ' flex-container-twoUp' : '';
            return (
              <div className={`tk-videos${twoUpClass}`}>
                {validVideos.map(video => (
                  <EmbeddedVideoTile
                    key={video.id}
                    title={video.title}
                    url={video.url}
                    hasHoverState={isEditable}
                  />
                ))}
                {validVideos.length === 0 && isEditable && (
                  <div>
                    <Body color="disabled">
                      <TranslatedMessage {...messages.placeholder} />
                    </Body>
                    <Button modifiers={['calltoaction', 'inline-flex']} active>
                      <SvgIcon icon="plus" width={20} height={20} />
                      <span
                        className={`${StoryVideosDisplay.baseClass}__button-text`}
                      >
                        <TranslatedMessage {...messages.addVideo} />
                      </span>
                    </Button>
                  </div>
                )}
              </div>
            );
          }}
        </ResponsiveContext.Consumer>
      </ResponsiveContainer>
    </StorySection>
  );
};

StoryVideosDisplay.baseClass = 'tk-videos-display';

StoryVideosDisplay.propTypes = {
  isEditable: PropTypes.bool,
  videos: videosPropTypes,
};

StoryVideosDisplay.defaultProps = {
  isEditable: false,
  videos: [],
};

export default StoryVideosDisplay;
