import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AUTH_REDIRECT_URL, BASE_URL } from 'constants/constants';
import {
  createHasRolesSelector,
  createHasFeaturesSelector,
  createNotHasFeaturesSelector,
  createHasDevFeaturesSelector,
  createNotHasDevFeaturesSelector,
  createHasImpersonatorRolesSelector,
} from 'selectors/account';

import { getCurrentUserAccountId } from 'services/user-service/user-service';

/*
 * HOC factory that can protect a component with roles, ff, AND dev ffs
 * withAuthorization takes one parameter: options
 * options is an object with these properties:
 *    allRoles: Array of roles that are all required to render component
 *    someRoles: Array of roles that only one is required to render component
 *    allDevFeatures: Array of dev feature flags that are all required
 *    someDevFeatures: Array of dev feature flags that only one is require
 *    allFeatures: Array of features that are all required
 *    someFeatures: Array of featurers that only one is required
 *
 * returns: HOC that creates a component that is protected by the options, if
 * authz fails, the browser is redirected to dashboards. in the future I really
 * hope we do something else than that redirect....
 *
 * example:
 *
 * const ProtectedNewFeatureComp = withAuthorization({
 *   allRoles: [ADMIN, SUPERUSER],
 *   someDevFeatures: [NEW_FEATURE_A, NEW_FEATURE_B],
 * })(NewFeatureComponent);
 *
 *
 * Why a HOC for authz?
 *   this makes it so the protected component will not be mounted if the user
 *   does not have permissions. say protected component is Stories, and Stories
 *   component in `componentDidMount` wanted to hit a story api, we need to
 *   first make sure the user can hit that endpoint before we even mount it.
 *   a HOC makes it so we can shortcut that and only mount the component once we
 *   verify authorization
 */

const withAuthorization = (
  /** @type {import('selectors/canUse/types').WithAuthorizationConfig}} */
  {
    allRoles = [],
    someRoles = [],
    allDevFeatures = [],
    someDevFeatures = [],
    notDevFeatures = [],
    notFeatures = [],
    allFeatures = [],
    someFeatures = [],
    someRolesImpersonator = [],
    canUseFn,
  },
  redirectUrl = BASE_URL,
) => {
  const protectors = [];
  const protectorsImpersonator = [];
  if (allRoles.length > 0) {
    protectors.push(createHasRolesSelector(allRoles, true));
  }
  if (someRoles.length > 0) {
    protectors.push(createHasRolesSelector(someRoles, false));
  }
  if (allDevFeatures.length > 0) {
    protectors.push(createHasDevFeaturesSelector(allDevFeatures, true));
  }
  if (someDevFeatures.length > 0) {
    protectors.push(createHasDevFeaturesSelector(someDevFeatures, false));
  }
  if (notDevFeatures.length > 0) {
    protectors.push(createNotHasDevFeaturesSelector(notDevFeatures));
  }
  if (notFeatures.length > 0) {
    protectors.push(createNotHasFeaturesSelector(notFeatures));
  }
  if (allFeatures.length > 0) {
    protectors.push(createHasFeaturesSelector(allFeatures, true));
  }
  if (someFeatures.length > 0) {
    protectors.push(createHasFeaturesSelector(someFeatures, false));
  }
  if (someRolesImpersonator.length > 0) {
    protectorsImpersonator.push(
      createHasImpersonatorRolesSelector(someRolesImpersonator, false),
    );
  }

  const mapStateToProps = state => {
    const protectorsFlag =
      !!protectors.length && protectors.every(f => f(state));
    const impersonatorsFlag =
      !!protectorsImpersonator.length &&
      protectorsImpersonator.every(f => f(state));
    return {
      isAuthzed:
        protectorsFlag || impersonatorsFlag || (canUseFn && canUseFn()),
      isLoggedIn: !!getCurrentUserAccountId(),
    };
  };

  return Comp => {
    const AuthzedComponent = React.forwardRef((props, ref) => {
      const { isAuthzed, isLoggedIn, ...rest } = props;

      const loggedInRedirect = isLoggedIn
        ? redirectUrl
        : AUTH_REDIRECT_URL.replace(
            '$redirectUrl',
            encodeURIComponent(window.location.hash),
          );

      const authzRedirect = isAuthzed ? null : loggedInRedirect;

      if (authzRedirect) {
        window.location.href = authzRedirect;
        return null;
      }

      return <Comp ref={ref} {...rest} />;
    });

    AuthzedComponent.propTypes = {
      isAuthzed: PropTypes.bool.isRequired,
      isLoggedIn: PropTypes.bool.isRequired,
    };

    const name = Comp.displayName || Comp.name || 'Unknown';
    AuthzedComponent.displayName = `ForwardedRef(withAuthorization(${name}))`;

    return connect(mapStateToProps)(AuthzedComponent);
  };
};

export default withAuthorization;
