import HeaderTitle from './HeaderTitle';
import Logo from './Logo';
import PageHeader from './PageHeader';
import SecondaryMenu from './SecondaryMenu';

PageHeader.SecondaryMenu = SecondaryMenu;
PageHeader.Logo = Logo;
PageHeader.HeaderTitle = HeaderTitle;

export default PageHeader;
