import {
  ActionTypes,
  Actions,
  ProfileStreamState,
} from 'pages/SocialSearch/reducers/ProfileStreamSearchBuilder/types';

export const initialState: ProfileStreamState = {
  searchTerm: '',
  results: {
    data: [],
    loading: false,
    error: false,
  },
  creationResult: {
    loading: false,
    error: false,
  },
};

function profileStreamReducer(
  state: ProfileStreamState = initialState,
  action: ActionTypes,
): ProfileStreamState {
  const { payload, type } = action;
  switch (type) {
    case Actions.SEARCH_UPDATE:
      return {
        ...state,
        searchTerm: payload,
      };

    case Actions.SELECT_PROFILE:
      return {
        ...initialState,
        selectedProfile: payload,
      };

    case Actions.CLEAR_SEARCH:
      return {
        ...initialState,
      };

    case Actions.GET_PROFILES_START:
      return {
        ...state,
        results: {
          data: [],
          loading: true,
          error: false,
        },
      };

    case Actions.GET_PROFILES_SUCCESS:
      return {
        ...state,
        results: {
          data: payload,
          loading: false,
          error: false,
        },
      };

    case Actions.GET_PROFILES_FAIL:
      return {
        ...state,
        results: {
          data: [],
          loading: false,
          error: true,
        },
      };

    case Actions.CREATE_PROFILE_QUERY_START:
      return {
        ...state,
        creationResult: {
          loading: true,
          error: false,
        },
      };

    case Actions.CREATE_PROFILE_QUERY_SUCCESS:
      return {
        ...state,
        creationResult: {
          loading: false,
          createdQueryId: payload,
          error: false,
        },
      };

    case Actions.CREATE_PROFILE_QUERY_FAIL:
      return {
        ...state,
        creationResult: {
          loading: true,
          error: false,
        },
      };

    default:
      return state;
  }
}

export default profileStreamReducer;
