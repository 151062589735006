import messsages from './analytics-integration.messages';

export const ADOBE_ANALYTICS_TYPE = 'ADOBE_ANALYTICS';
export const GOOGLE_ANALYTICS_TYPE = 'GOOGLE_ANALYTICS';

export const integrationsMessagesMap = {
  [ADOBE_ANALYTICS_TYPE]: messsages.adobeIntegration,
  [GOOGLE_ANALYTICS_TYPE]: messsages.gaIntegration,
};

export const ADOBE_ANALYTICS_PHASES = {
  preIntegration: 'preIntegration',
  accessGrant: 'accessGrant',
  integrateAccounts: 'integrateAccounts',
  accountsIntegrated: 'accountsIntegrated',
};

export const GOOGLE_ANALYTICS_PHASES = {
  preIntegration: 'preIntegration',
  grantAccess: 'grantAccess',
  selectViews: 'selectViews',
  viewsIntegrated: 'viewsIntegrated',
  manualIntegration: 'manualIntegration',
};

export const GOOGLE_CLIENT_ID =
  '911820117682-53g5o12dh1o33i4eoe35dsgfkkhjmqog.apps.googleusercontent.com';
export const GOOGLE_BASE_URL =
  'https://www.googleapis.com/analytics/v3/management/accounts';
