import React from 'react';

import { Pill, Button } from '@cision/rover-ui';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { SvgIcon } from '@trendkite/ui';
import { DATA_ITEMS_PROPERTIES_MAP_V3 } from 'components/widgetWizard/constants';

import styles from 'components/widgetWizard/steps/steps.module.css';
import { V3_METRIC_FIELDS } from 'constants/constants';

import Header from './components/header';
import messages from './section-wizard.messages.js';

const Data = ({
  nextStepCallback,
  dataLimit,
  sourceType,
  onRemoveItem,
  primaryMetric,
  secondaryMetric,
}) => {
  const intl = useIntl();
  const dataItemsProperties = DATA_ITEMS_PROPERTIES_MAP_V3[sourceType];
  const selectedItems = useSelector(dataItemsProperties.selector);
  const iconName = selectedItems.length === dataLimit ? 'mainEdit' : 'plus';

  const createPill = (id, title) => {
    return (
      <Pill key={id} checked id={id} style={{ margin: '5px 0 0 2px' }}>
        {title}
        <Pill.Addon
          onClick={() => onRemoveItem(id)}
          data-qa={`create-${id}-pill`}
        >
          <div className={styles.pillCross}>
            <SvgIcon icon="cross" height={15} width={15} />
          </div>
        </Pill.Addon>
      </Pill>
    );
  };

  const metricRequiresDataSources = metric => {
    return metric && metric.measure?.field !== V3_METRIC_FIELDS.sessions;
  };

  const hasDataCompatibleMetric = () => {
    return (
      metricRequiresDataSources(primaryMetric) ||
      metricRequiresDataSources(secondaryMetric)
    );
  };

  const canAddData = hasDataCompatibleMetric(primaryMetric, secondaryMetric);

  return (
    <div className={styles.sectionWrapper}>
      <Header
        title={intl.formatMessage(messages.titleDataSection)}
        subTitle={intl.formatMessage(dataItemsProperties.subtitleMessage, {
          DATA_LIMIT: intl.formatNumber(dataLimit),
        })}
        showIconButton
        icon={iconName}
        onIconButtonClick={() =>
          nextStepCallback(dataItemsProperties.addOrEditWizardStep, {}, true)
        }
        iconButtonDisabled={!canAddData}
      />
      {selectedItems.length === 0 && (
        <div>
          <Button
            disabled={!canAddData}
            level="secondary"
            size="lg"
            onClick={() =>
              nextStepCallback(
                dataItemsProperties.addOrEditWizardStep,
                {},
                true,
              )
            }
            data-qa="widget-custom-add-search"
          >
            {intl.formatMessage(dataItemsProperties.noItemsMessage)}
          </Button>
        </div>
      )}
      {selectedItems.length > 0 && (
        <div>
          {selectedItems.map(item =>
            createPill(item.id, item[dataItemsProperties.titleKey]),
          )}
        </div>
      )}
    </div>
  );
};

Data.propTypes = {
  nextStepCallback: PropTypes.func.isRequired,
  dataLimit: PropTypes.number.isRequired,
  sourceType: PropTypes.string.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  primaryMetric: PropTypes.object,
  secondaryMetric: PropTypes.object,
};

Data.defaultProps = {
  primaryMetric: null,
  secondaryMetric: null,
};

export default Data;
