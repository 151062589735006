import React from 'react';

import { Button, Icon } from '@cision/rover-ui';
import PropTypes from 'prop-types';

import { SvgIcon } from '@trendkite/ui';
import T from 'i18n/TranslatedMessage';

import FeatureStatusBadge from './feature-status-badge';
import messages from './menu-props.messages';
import Spacer from './Spacer';

export const SocialListeningNavLabel = () => (
  <span>
    <T {...messages.menuPropsSocialListening} />
  </span>
);

export const SocialListeningNavTooltip = () => (
  <>
    <T {...messages.menuPropsSocialListeningTooltip} />
    <SvgIcon
      className="brandwatch-logo"
      icon="brandwatchLogo"
      height={30}
      width={200}
    />
  </>
);

export const SocialListeningUpsellLabel = () => (
  <span>
    <T {...messages.menuPropsSocialListening} />
    <Spacer />
    <FeatureStatusBadge />
  </span>
);

export const SocialListeningUpsellTooltip = ({ brandwatchUpsellUrl = '' }) => {
  return (
    <>
      <T {...messages.menuPropsSocialListeningUpsellTooltip} />
      <br />
      <Button
        href={brandwatchUpsellUrl}
        level="link"
        rel="noopener noreferrer"
        style={{ paddingLeft: '0', paddingRight: '0' }}
        target="_blank"
      >
        <span>
          <T {...messages.menuPropsSocialListeningUpsellLink} />{' '}
          <Icon height={14} width={14} name="new-tab" />
        </span>
      </Button>
      <SvgIcon
        className="brandwatch-logo"
        icon="brandwatchLogo"
        height={30}
        width={200}
      />
    </>
  );
};

SocialListeningUpsellTooltip.propTypes = {
  brandwatchUpsellUrl: PropTypes.string,
};
