import React, { useContext } from 'react';

import classNames from 'classnames';
import ReactDom from 'react-dom';

import PopperDropdownContext from './context';

const BASE_CLASS = 'tk_popper_dropdown_content';

interface PopperTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  children;
  className: string;
}

const PopperContent = ({
  children,
  className,
  ...passedProps
}: PopperTriggerProps) => {
  const {
    isOpen,
    usePortal,
    setPopperRef,
    popperData,
    portalNode,
  } = useContext(PopperDropdownContext);

  if (!isOpen) {
    return null;
  }

  const classes = classNames(BASE_CLASS, className);

  const componentToRender = (
    <div
      ref={setPopperRef}
      className={classes}
      {...passedProps}
      style={{ ...popperData.styles }}
      {...popperData.attributes}
    >
      {children}
    </div>
  );

  return usePortal
    ? ReactDom.createPortal(componentToRender, portalNode || document.body)
    : componentToRender;
};

export default PopperContent;
