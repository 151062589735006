export const ACTIVITY_TYPES = {
  EMAIL_SENT: 'EMAIL_SENT',
  EMAIL_RECEIVED: 'EMAIL_RECEIVED',
  EMAIL_REPLIED: 'EMAIL_REPLIED',
  CONTACT_LIST_ADD: 'CONTACT_LIST_ADD',
  NOTE: 'NOTE',
  NOTE_EDIT: 'EDIT_NOTE',
  NOTE_DELETE: 'DELETE_NOTE',
  COFFEE: 'COFFEE',
  DRINKS: 'DRINKS',
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
  PHONE_CALL: 'PHONE_CALL',
  MEETING: 'MEETING',
  CONTACT_LIST_REMOVE: 'CONTACT_LIST_REMOVE',
};

export const INFLUENCER_ACTIVITY_TYPES = {
  COFFEE: 'Coffee',
  DINNER: 'Dinner',
  DRINKS: 'Drinks',
  LUNCH: 'Lunch',
  MEETING: 'Meeting',
  PHONE_CALL: 'Phone Call',
};

export const GROUPED_ACTIVITY_TYPES = {
  CONTACT_LISTS: 'CONTACT_LISTS',
  CUSTOM: 'CUSTOM',
  EMAILS: 'EMAILS',
  NOTES: 'NOTES',
};

export const GROUPED_ACTIVITY_TYPES_MAP = {
  [GROUPED_ACTIVITY_TYPES.CONTACT_LISTS]: [
    ACTIVITY_TYPES.CONTACT_LIST_ADD,
    ACTIVITY_TYPES.CONTACT_LIST_REMOVE,
  ],
  [GROUPED_ACTIVITY_TYPES.CUSTOM]: [
    ACTIVITY_TYPES.COFFEE,
    ACTIVITY_TYPES.DRINKS,
    ACTIVITY_TYPES.LUNCH,
    ACTIVITY_TYPES.DINNER,
    ACTIVITY_TYPES.PHONE_CALL,
    ACTIVITY_TYPES.MEETING,
  ],
  [GROUPED_ACTIVITY_TYPES.EMAILS]: [
    ACTIVITY_TYPES.EMAIL_RECEIVED,
    ACTIVITY_TYPES.EMAIL_REPLIED,
    ACTIVITY_TYPES.EMAIL_SENT,
  ],
  [GROUPED_ACTIVITY_TYPES.NOTES]: [
    ACTIVITY_TYPES.NOTE,
    ACTIVITY_TYPES.NOTE_DELETE,
    ACTIVITY_TYPES.NOTE_EDIT,
  ],
};

export const ACTIVITY_TYPE_FILTERS = [
  {
    label: 'List Management',
    id: GROUPED_ACTIVITY_TYPES.CONTACT_LISTS,
  },
  {
    label: 'Manually Added',
    id: GROUPED_ACTIVITY_TYPES.CUSTOM,
  },
  {
    label: 'Email',
    id: GROUPED_ACTIVITY_TYPES.EMAILS,
  },
  {
    label: 'Notes',
    id: GROUPED_ACTIVITY_TYPES.NOTES,
  },
];

export const ACTIVITY_TYPE_OPTIONS = [
  {
    label: 'Coffee',
    id: ACTIVITY_TYPES.COFFEE,
  },
  {
    label: 'Dinner',
    id: ACTIVITY_TYPES.DINNER,
  },
  {
    label: 'Drinks',
    id: ACTIVITY_TYPES.DRINKS,
  },
  {
    label: 'Lunch',
    id: ACTIVITY_TYPES.LUNCH,
  },
  {
    label: 'Meeting',
    id: ACTIVITY_TYPES.MEETING,
  },
  {
    label: 'Phone Call',
    id: ACTIVITY_TYPES.PHONE_CALL,
  },
];

export const ACTOR_TYPE = 'USER';

export const AUDIENCE_TYPES = {
  author: 'AUTHOR',
  influencer: 'INFLUENCER',
};

export const MANUALLY_GENERATED = 'manually';
export const SYSTEM_GENERATED = 'system';
