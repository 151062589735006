import './button.scss';
import ButtonAddon from './addon';
import OriginalButton from './Button';
import IconButton from './IconButton';

export const Addon = ButtonAddon;

class Button extends OriginalButton {
  static Addon = ButtonAddon;
}

export { IconButton };
export default Button;
