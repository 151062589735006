import messages from 'components/admin/importContactList/components/ListPreferencesStep/ListPreferences.messages';

import { Permissions } from './enums';

const permissionOptions = {
  [Permissions.NONE]: {
    listPermission: Permissions.NONE,
    dataQA: 'sOBPcbS-z8IpaCWF0DQPp',
    label: messages.importContactListPreferencesPrivateOptionLabel,
  },
  [Permissions.VIEW_ONLY]: {
    listPermission: Permissions.VIEW_ONLY,
    dataQA: 'CGQoTCwXdmKtSKrSq-7ZT',
    label: messages.importContactListPreferencesViewOnlyOptionLabel,
  },
  [Permissions.FULL_ACCESS]: {
    listPermission: Permissions.FULL_ACCESS,
    dataQA: 'taYiaj_3RMYXHwZG8_LGu',
    label: messages.importContactListPreferencesFullAccessOption,
  },
};

export { permissionOptions };
