export default {
  addArticleTitlelabel: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_TITLE_LABEL',
    defaultMessage: 'Force Add an Article',
    description:
      'Admin Page > Article Management > Add Arcticle: Add article title label',
  },
  urlLabel: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_URL_LABEL',
    defaultMessage: 'URL',
    description: 'Admin Page > Article Management > Add Arcticle: URL label',
  },
  contentLabel: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_CONTENT_LABEL',
    defaultMessage: 'Content',
    description: 'Admin Page > Article Management > Add Article: Content label',
  },
  publishDateLabel: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_PUBLISH_DATE_LABEL',
    defaultMessage: 'Publish Date (and Harvest Date) UTC',
    description:
      'Admin Page > Article Management > Add Article: Publish date label',
  },
  publisherLabel: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_PUBLISHER_LABEL',
    defaultMessage: 'Publisher',
    description:
      'Admin Page > Article Management > Add Article: Publisher label',
  },
  defaultLanguageValue: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_DEFAULT_LANGUAGE_VALUE',
    defaultMessage: 'English',
    description:
      'Admin Page > Article Management > Add Article: Default language value',
  },
  addArticleSuccessMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_SUCCESS_MESSAGE',
    defaultMessage: 'Success! You have added article {ARTICLE_TITLE}',
    description:
      'Admin Page > Article Management > Add Article: Add article success message',
  },
  addArticleErrorMsg: {
    id: 'ADMIN_PAGE_ARTICLE_MANAGEMENT_ADD_ARTICLE_ERROR_MESSAGE',
    defaultMessage: 'Could not add article {ARTICLE_TITLE}',
    description:
      'Admin Page > Article Management > Add Article: Add article error message',
  },
};
