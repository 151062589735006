export default {
  editSpokespeopleheader: {
    id: 'EDIT-STORY-SPOKESPEOPLE_HEADER',
    defaultMessage: 'Spokespeople For This Story',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, header',
  },
  spokespersonIndex: {
    id: 'EDIT-STORY-SPOKESPEOPLE_SPOKESPERSON_INDEX',
    defaultMessage: 'Spokesperson {NUMBER}',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, spokesperson indicator number',
  },
  firstNamePlaceholder: {
    id: 'EDIT-STORY-SPOKESPEOPLE_FIRST_NAME_PLACEHOLDER',
    defaultMessage: 'First Name',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, first name placeholder',
  },
  lastNamePlaceholder: {
    id: 'EDIT-STORY-SPOKESPEOPLE_LAST_NAME_PLACEHOLDER',
    defaultMessage: 'Last Name',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, last name placeholder',
  },
  jobPlaceholder: {
    id: 'EDIT-STORY-SPOKESPEOPLE_JOB_PLACEHOLDER',
    defaultMessage: 'Job title (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, job placeholder',
  },
  aboutPlaceholder: {
    id: 'EDIT-STORY-SPOKESPEOPLE_ABOUT_PLACEHOLDER',
    defaultMessage: 'About (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, about placeholder',
  },
  twitterPlaceholder: {
    id: 'EDIT-STORY-SPOKESPEOPLE_TWITTER_PLACEHOLDER',
    defaultMessage: 'Twitter handle (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, witter handle placeholder',
  },
  linkedInPlaceholder: {
    id: 'EDIT-STORY-SPOKESPEOPLE_LINKEDIN_PLACEHOLDER',
    defaultMessage: 'LinkedIn profile link (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, linkedin profile placeholder',
  },
  approvedQuotesPlaceholder: {
    id: 'EDIT-STORY-SPOKESPEOPLE_APPROVED_QUOTES_PLACEHOLDER',
    defaultMessage: 'Approved quotes (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, approved quotes placeholder',
  },
  addSpokesperson: {
    id: 'EDIT-STORY-SPOKESPEOPLE_ADD_SPOKESPERSON',
    defaultMessage: 'Add another spokesperson',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit spokespeople, add spokesperson link',
  },
  requiredLinkedinValidationMessage: {
    id: 'EDIT-STORY-SPOKESPEOPLE_LINKEDIN_VALIDATION_MESSAGE',
    defaultMessage: 'A valid profile URL or username is required.',
    description:
      'Message indicating the Story Spokespeople linkedin field is required',
  },
};
