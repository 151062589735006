import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';

export const BOOLEAN_CONDITIONS = {
  FILTER: 'FILTER',
  NOT: 'NOT',
  OR: 'OR',
};

export const SEARCH_SECTION_NAMES = {
  topics: 'topics',
  locations: 'locations',
  talkingAbout: 'talkingAbout',
  outlets: 'outlets',
  jobRoles: 'jobRoles',
  influencers: 'influencers',
  privateInfluencerMatches: 'privateInfluencerMatches',
  recentOutlets: 'recentOutlets',
  filterTopics: 'filterTopics',
  filterLanguages: 'filterLanguages',
  filterLocations: 'filterLocations',
  filterOutlets: 'filterOutlets',
  filterJobRoles: 'filterJobRoles',
};

export const SEARCH_CRITERIA_NAMES = {
  topic: 'topic',
  talkingAbout: 'talkingAbout',
  language: 'language',
  location: 'location',
  outlet: 'outlet',
  contact: 'contact',
  jobRole: 'jobRole',
  influencerType: 'influencerType',
  all: 'all',
};

export const SEARCH_INFO_TYPE = {
  aggregations: 'aggregations',
};

export const AUDIENCE_TYPE = {
  consumer: 'Consumer',
  trade: 'Trade',
  technicalScientificAcademic: 'Technical/Scientific/Academic',
};

const flipKeysAndValues = (obj: Record<string, string>) =>
  Object.entries(obj).reduce(
    (result, [key, value]) => ({
      ...result,
      [value]: key,
    }),
    {},
  );

export const FILTER_SECTION_CONFIG = {
  LOCATION: {
    /** string values for `aggsToInclude` to request data for these filters from the API */
    AGG_KEY: 'locations',
    /** bucket keys for parsing API aggregations response for these filters */
    BUCKET_KEYS: {
      country: 'countries',
      county: 'adm2s',
      state: 'adm1s',
      city: 'cities',
      dma: 'dmas',
      targetArea: 'targetArea',
    },
    /** i18n message keys to translate these filters' pill types */
    PILL_TYPE_I18N_KEYS: {},
    /** Internal types list to identify these filters' pill types */
    PILL_TYPES: {
      country: 'Country',
      county: 'County',
      state: 'State',
      city: 'City',
      dma: 'DMA',
      targetArea: 'TargetArea',
    },
    /** Look up pill types by bucket when parsing API aggregations response */
    PILL_TYPES_BY_BUCKET_KEY: {},
    SUGGESTIONS_URL: `${INFLUENCER_HUB_ENDPOINT}/suggestions/locations`,
  },
  OUTLET: {
    AGG_KEY: 'contactOutletNames',
    BUCKET_KEYS: { outlet: 'contactOutletNames' },
    PILL_TYPE_I18N_KEYS: {},
    PILL_TYPES: null,
    PILL_TYPES_BY_BUCKET_KEY: {},
    SUGGESTIONS_URL: `${INFLUENCER_HUB_ENDPOINT}/suggestions/outlets`,
  },
  OUTLETFULL: {
    AGG_KEY: 'contactOutletNames',
    BUCKET_KEYS: { outlet: 'contactOutletNames' },
    PILL_TYPE_I18N_KEYS: {},
    PILL_TYPES: null,
    PILL_TYPES_BY_BUCKET_KEY: {},
    SUGGESTIONS_URL: `${INFLUENCER_HUB_ENDPOINT}/suggestions/outlets-full`,
  },
  TOPIC: {
    AGG_KEY: 'topics',
    BUCKET_KEYS: { topic: 'topics' },
    PILL_TYPE_I18N_KEYS: {
      keyword: 'keyword',
      topic: 'topic',
      talkingAbout: 'talking-about',
      city: 'city',
      country: 'country',
      county: 'county',
      state: 'state',
      dma: 'dma',
      targetArea: 'targetArea',
    },
    PILL_TYPES: {
      keyword: 'Keyword',
      topic: 'Topic',
      talkingAbout: 'Talking About',
    },
    PILL_TYPES_BY_BUCKET_KEY: {},
    SUGGESTIONS_URL: `${INFLUENCER_HUB_ENDPOINT}/suggestions/topics`,
  },
  MEDIA_TYPE: {
    AGG_KEY: 'mediaTypes',
    BUCKET_KEYS: { outlet: 'mediaTypes' },
    SUGGESTIONS_URL: `${INFLUENCER_HUB_ENDPOINT}/suggestions/media-types`,
  },
  JOB_ROLE: {
    AGG_KEY: 'jobTitles',
    BUCKET_KEYS: { outlet: 'jobTitles' },
    SUGGESTIONS_URL: `${INFLUENCER_HUB_ENDPOINT}/suggestions/job-roles`,
  },
};

FILTER_SECTION_CONFIG.LOCATION.PILL_TYPES_BY_BUCKET_KEY = flipKeysAndValues(
  FILTER_SECTION_CONFIG.LOCATION.BUCKET_KEYS,
);

FILTER_SECTION_CONFIG.OUTLET.PILL_TYPES_BY_BUCKET_KEY = flipKeysAndValues(
  FILTER_SECTION_CONFIG.OUTLET.BUCKET_KEYS,
);

FILTER_SECTION_CONFIG.TOPIC.PILL_TYPES_BY_BUCKET_KEY = flipKeysAndValues(
  FILTER_SECTION_CONFIG.TOPIC.BUCKET_KEYS,
);
