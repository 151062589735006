import { useEffect } from 'react';

/**
 * Handle click action outside of a component or element
 */
function useHandleMousedownOutsideRef(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useHandleMousedownOutsideRef;
