import { INDIVIDUAL_TAB } from 'constants/earned-impact-audience';

export const GET_EARNED_IMPACT_AUDIENCE_LIST =
  'earned-impact/GET_EARNED_IMPACT_AUDIENCE_LIST';
export const GET_EARNED_IMPACT_AUDIENCE_LIST_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_AUDIENCE_LIST_SUCCESS';
export const GET_EARNED_IMPACT_AUDIENCE_LIST_ERROR =
  'earned-impact/GET_EARNED_IMPACT_AUDIENCE_LIST_ERROR';

export const GET_EARNED_IMPACT_AUDIENCE =
  'earned-impact/GET_EARNED_IMPACT_AUDIENCE';
export const GET_EARNED_IMPACT_AUDIENCE_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_AUDIENCE_SUCCESS';
export const GET_EARNED_IMPACT_AUDIENCE_ERROR =
  'earned-impact/GET_EARNED_IMPACT_AUDIENCE_ERROR';
export const SET_EARNED_IMPACT_AUDIENCE_TAB =
  'earned-impact/SET_EARNED_IMPACT_AUDIENCE_TAB';
export const SET_EARNED_IMPACT_AUDIENCE_SIDEBAR =
  'earned-impact/SET_EARNED_IMPACT_AUDIENCE_SIDEBAR';
export const GET_EARNED_IMPACT_VIEWS = 'earned-impact/GET_EARNED_IMPACT_VIEWS';
export const GET_EARNED_IMPACT_VIEWS_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_VIEWS_SUCCESS';
export const GET_EARNED_IMPACT_VIEWS_ERROR =
  'earned-impact/GET_EARNED_IMPACT_VIEWS_ERROR';
export const GET_EARNED_IMPACT_CONVERSIONS =
  'earned-impact/GET_EARNED_IMPACT_CONVERSIONS';
export const GET_EARNED_IMPACT_CONVERSIONS_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_CONVERSIONS_SUCCESS';
export const GET_EARNED_IMPACT_CONVERSIONS_ERROR =
  'earned-impact/GET_EARNED_IMPACT_CONVERSIONS_ERROR';
export const GET_EARNED_IMPACT_DAILY_PERFORMANCE =
  'earned-impact/GET_EARNED_IMPACT_DAILY_PERFORMANCE';
export const GET_EARNED_IMPACT_DAILY_PERFORMANCE_ERROR =
  'earned-impact/GET_EARNED_IMPACT_DAILY_PERFORMANCE_ERROR';
export const GET_EARNED_IMPACT_DAILY_PERFORMANCE_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_DAILY_PERFORMANCE_SUCCESS';
export const GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT =
  'earned-impact/GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT';
export const GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT_SUCCESS';
export const GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT_ERROR =
  'earned-impact/GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT_ERROR';
export const GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS =
  'earned-impact/GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS';
export const GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS_ERROR =
  'earned-impact/GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS_ERROR';
export const GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS_SUCCESS';
export const GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS =
  'earned-impact/GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS';
export const GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_SUCCESS';
export const GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_ERROR =
  'earned-impact/GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_ERROR';
export const GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_GET_MORE =
  'earned-impact/GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_GET_MORE';
export const GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_GET_MORE_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_GET_MORE_SUCCESS';
export const GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_GET_MORE_ERROR =
  'earned-impact/GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_GET_MORE_ERROR';
export const GET_EARNED_IMPACT_LOCATION_PERFORMANCE =
  'earned-impact/GET_EARNED_IMPACT_LOCATION_PERFORMANCE';
export const GET_EARNED_IMPACT_LOCATION_PERFORMANCE_ERROR =
  'earned-impact/GET_EARNED_IMPACT_LOCATION_PERFORMANCE_ERROR';
export const GET_EARNED_IMPACT_LOCATION_PERFORMANCE_SUCCESS =
  'earned-impact/GET_EARNED_IMPACT_LOCATION_PERFORMANCE_SUCCESS';

export class NoCidSearchesFoundError extends Error {
  constructor() {
    super('No CID Searches were found');
    this.name = 'NoCidSearchesFoundError';
  }
}
export const noCidSearchesFoundError = new NoCidSearchesFoundError();

export class AccountSetupIncompleteError extends Error {
  constructor() {
    super('Account setup incomplete');
    this.name = 'AccountSetupIncompleteError';
  }
}
export const accountSetupIncompleteError = new AccountSetupIncompleteError();

export class NoAccessToSearchError extends Error {
  constructor() {
    super('User do not have access to the search');
    this.name = 'NoAccessToSearchError';
  }
}
// TODO: replace this with NoAccessToSearchError class
export const noAccessToSearch = new NoAccessToSearchError();

export const noDailyPerformanceData = new Error(
  'Unable to retrieve impact audience daily performance data',
);

export const noAudiencePerformanceSnapshotData = new Error(
  'Unable to retrieve impact audience performance snapshot data',
);

export const initialState = {
  audience: {
    loading: false,
    error: false,
    data: {
      tabSelected: INDIVIDUAL_TAB,
      sidebarSelected: 0,
      tabs: {
        individual: [],
        household: [],
        firmographics: [],
      },
    },
  },
  views: {
    loading: false,
    error: false,
    total: 0,
    data: null,
  },
  conversions: {
    loading: false,
    error: false,
    data: null,
  },
  savedSearches: {
    loading: false,
    paginationLoading: false,
    error: false,
    data: {
      searches: [],
      percentageUrlUsed: 0,
      searchesUsed: 0,
      totalUrlLimit: 0,
      isConversionsEnabled: false,
      totalSearches: 0,
      cursor: '',
      hasNextPage: false,
    },
  },
  audienceList: {
    loading: null,
    error: false,
    data: null,
  },
  dailyPerformance: {
    loading: null,
    error: false,
    data: null,
  },
  performanceSnapshot: {
    loading: null,
    error: false,
    data: null,
  },
  domainContributions: {
    loading: null,
    error: false,
    data: null,
  },
  topContributingUrls: {
    loading: null,
    loadingMore: null,
    error: false,
    data: null,
  },
  locationPerformance: {
    loading: null,
    error: false,
    data: null,
  },
};

const earnedImpactReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case GET_EARNED_IMPACT_AUDIENCE_LIST:
      return {
        ...state,
        audienceList: {
          ...state.audienceList,
          loading: true,
          error: false,
        },
      };
    case GET_EARNED_IMPACT_AUDIENCE_LIST_SUCCESS:
      return {
        ...state,
        audienceList: {
          ...state.audienceList,
          loading: false,
          data: action.payload.data,
        },
      };
    case GET_EARNED_IMPACT_AUDIENCE_LIST_ERROR:
      return {
        ...state,
        audienceList: {
          ...state.audienceList,
          loading: false,
          error: action.payload,
        },
      };

    case GET_EARNED_IMPACT_AUDIENCE:
      return {
        ...state,
        audience: {
          ...state.audience,
          loading: true,
          error: false,
        },
      };

    case GET_EARNED_IMPACT_AUDIENCE_SUCCESS: {
      const { characteristicsData } = action.payload;

      return {
        ...state,
        audience: {
          ...state.audience,
          data: {
            ...state.audience.data,
            tabSelected: state.audience.data.tabSelected || INDIVIDUAL_TAB,
            sidebarSelected: state.audience.data.sidebarSelected || 0,
            tabs: {
              ...characteristicsData,
            },
          },
          loading: false,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_AUDIENCE_ERROR:
      return {
        ...state,
        audience: {
          ...state.audience,
          loading: false,
          error: true,
        },
      };
    case SET_EARNED_IMPACT_AUDIENCE_TAB: {
      return {
        ...state,
        audience: {
          ...state.audience,
          data: {
            ...state.audience.data,
            tabSelected: action.payload.tab,
          },
        },
      };
    }

    case SET_EARNED_IMPACT_AUDIENCE_SIDEBAR: {
      return {
        ...state,
        audience: {
          ...state.audience,
          data: {
            ...state.audience.data,
            sidebarSelected: action.payload.index,
          },
        },
      };
    }
    case GET_EARNED_IMPACT_VIEWS:
      return {
        ...state,
        views: {
          ...state.views,
          loading: true,
          error: false,
        },
      };
    case GET_EARNED_IMPACT_VIEWS_SUCCESS: {
      const {
        timeSeriesData,
        totalViews,
        uniqueCount,
        conversionCount,
        conversionRate,
      } = action.payload;

      return {
        ...state,
        views: {
          ...state.views,
          loading: false,
          data: {
            timeSeriesData,
            conversionCount,
            conversionRate,
            totalViews,
            uniqueCount,
          },
        },
      };
    }
    case GET_EARNED_IMPACT_VIEWS_ERROR:
      return {
        ...state,
        views: {
          ...state.views,
          loading: false,
          error: true,
        },
      };
    case GET_EARNED_IMPACT_CONVERSIONS:
      return {
        ...state,
        conversions: {
          ...state.conversions,
          loading: true,
          error: false,
        },
      };
    case GET_EARNED_IMPACT_CONVERSIONS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        conversions: {
          ...state.conversions,
          data,
          loading: false,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_CONVERSIONS_ERROR:
      return {
        ...state,
        conversions: {
          ...state.conversions,
          loading: false,
          error: true,
        },
      };
    case GET_EARNED_IMPACT_DAILY_PERFORMANCE: {
      return {
        ...state,
        dailyPerformance: {
          ...state.dailyPerformance,
          loading: true,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_DAILY_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        dailyPerformance: {
          ...state.dailyPerformance,
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_DAILY_PERFORMANCE_ERROR: {
      return {
        ...state,
        dailyPerformance: {
          ...state.dailyPerformance,
          loading: false,
          error: action.payload,
        },
      };
    }
    case GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT: {
      return {
        ...state,
        performanceSnapshot: {
          ...state.performanceSnapshot,
          loading: true,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT_SUCCESS: {
      return {
        ...state,
        performanceSnapshot: {
          ...state.performanceSnapshot,
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_PERFORMANCE_SNAPSHOT_ERROR: {
      return {
        ...state,
        performanceSnapshot: {
          ...state.performanceSnapshot,
          loading: false,
          error: action.payload,
        },
      };
    }
    case GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS: {
      return {
        ...state,
        domainContributions: {
          ...state.domainContributions,
          loading: true,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS_SUCCESS: {
      return {
        ...state,
        domainContributions: {
          ...state.domainContributions,
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_DOMAIN_CONTRIBUTIONS_ERROR: {
      return {
        ...state,
        domainContributions: {
          ...state.domainContributions,
          loading: false,
          error: true,
        },
      };
    }
    case GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS: {
      return {
        ...state,
        topContributingUrls: {
          ...state.topContributingUrls,
          loading: true,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_SUCCESS: {
      return {
        ...state,
        topContributingUrls: {
          ...state.topContributingUrls,
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_TOP_CONTRIBUTING_URLS_ERROR: {
      return {
        ...state,
        topContributingUrls: {
          ...state.topContributingUrls,
          loading: false,
          error: action.payload,
        },
      };
    }
    case GET_EARNED_IMPACT_LOCATION_PERFORMANCE: {
      return {
        ...state,
        locationPerformance: {
          ...state.locationPerformance,
          loading: true,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_LOCATION_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        locationPerformance: {
          ...state.locationPerformance,
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    }
    case GET_EARNED_IMPACT_LOCATION_PERFORMANCE_ERROR: {
      return {
        ...state,
        locationPerformance: {
          ...state.locationPerformance,
          loading: false,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default earnedImpactReducer;
