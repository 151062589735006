import { createSelector } from 'reselect';

import { MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION } from 'constants/constants';
import { getCanUseBulkTagging } from 'selectors/canUse/canUse';
import { pendingExportsByIdsSelector } from 'selectors/exports';

export const dashboardObj = state => state.dashboard || {};

export const articleListObjSelector = createSelector(
  dashboardObj,
  dashboard => (dashboard && dashboard.articleList) || {},
);

export const articleListSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.articles,
);
const getAllArticlesAndDuplicatesIds = (ids, article) =>
  ids.concat(
    article.duplicatePublications && article.duplicatePublications.length
      ? article.duplicatePublications.map(article => article.id)
      : article.id,
  );
export const uniqueArticleListSelector = createSelector(
  articleListSelector,
  articleList => {
    return articleList.reduce(getAllArticlesAndDuplicatesIds, []);
  },
);

export const articleListKeywordFilterTextSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.keywordFilterText || '',
);

export const mediaTypeFiltersSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.mediaTypeFilters,
);

export const dateRangeSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.dateRange,
);

export const articleListLoadingSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.articlesLoading,
);

export const sortDirectionSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.sortAscending,
);

export const sortFieldColumnSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.sortColumnTitle,
);

export const articleListExportIdsSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.exportIds,
);

export const articleListPendingExportsSelector = createSelector(
  articleListExportIdsSelector,
  pendingExportsByIdsSelector,
  (exportIds, getPendingExportsByIds) => getPendingExportsByIds(exportIds),
);

export const articlePreviewSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.articlePreview,
);

export const articlePreviewLoadingSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.articlePreviewLoading,
);

export const tagModalVisibilitySelector = createSelector(
  articleListObjSelector,
  articleList => articleList.tagModalVisible,
);

export const addArticleModalVisibilitySelector = createSelector(
  articleListObjSelector,
  articleList => articleList.addArticleModalVisible,
);

export const campaignTagModalVisibilitySelector = createSelector(
  articleListObjSelector,
  articleList => articleList.campaignTagModalVisible,
);

export const selectedArticlesSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.selectedIds,
);

export const hoverArticleIdSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.listRowOnHoverId,
);

export const queryParamsSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.queryParams,
);

const allArticlesSelectedSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.allArticlesSelected,
);

export const articleListTotalSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.total || 0,
);

export const maxPageNumSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.maxPageNum || 0,
);

export const articlesCountSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.articlesCount,
);

export const articlesSelectionDataSelector = createSelector(
  articleListTotalSelector,
  articlesCountSelector,
  allArticlesSelectedSelector,
  selectedArticlesSelector,
  (totalCountResult, pageCountResults, allArticlesSelected, selectedIds) => {
    const userCanUseBulkTagging = getCanUseBulkTagging();
    const bulkLimit =
      totalCountResult >= MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION
        ? MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION
        : totalCountResult;
    return {
      totalResults: userCanUseBulkTagging ? bulkLimit : pageCountResults,
      allArticlesSelected,
      selectedIds,
    };
  },
);

export const analyticsIntegrationSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.analyticsIntegration,
);

export const currentPageSizeSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.queryParams.pageSize,
);

export const currentPageSelector = createSelector(
  articleListObjSelector,
  articleList => articleList.queryParams.pageNum,
);
