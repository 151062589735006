enum FiltersActions {
  SET_MEDIA_TYPE_FILTER = 'SET_MEDIA_TYPE_FILTER',
  SET_CONTENT_TYPE_FILTER = 'SET_CONTENT_TYPE_FILTER',
  SET_REGION_FILTER = 'SET_REGION_FILTER',
  SET_LOCATION_FILTER = 'SET_LOCATION_FILTER',
  SET_LANGUAGE_FILTER = 'SET_LANGUAGE_FILTER',
  RESET_FILTERS = 'RESET_FILTERS',
  RESET_MEDIA_TYPE_FILTER = 'RESET_MEDIA_TYPE_FILTER',
  RESET_CONTENT_TYPE_FILTER = 'RESET_CONTENT_TYPE_FILTER',
  RESET_REGION_FILTER = 'RESET_REGION_FILTER',
  RESET_LOCATION_FILTER = 'RESET_LOCATION_FILTER',
  RESET_LANGUAGE_FILTER = 'RESET_LANGUAGE_FILTER',
  SET_FILTERS = 'SET_FILTERS',
  SET_MODAL_LOADING_STATUS = 'SET_MODAL_LOADING_STATUS',
  SET_MODAL_ERROR = 'SET_MODAL_ERROR',
  SET_FILTER_VALUES = 'SET_FILTER_VALUES',
  RESET_MODAL_ERROR = 'RESET_MODAL_ERROR',
  SET_LANGUAGE_V2_FILTER = 'SET_LANGUAGE_V2_FILTER',
  SET_LOCATION_V2_FILTER = 'SET_LOCATION_V2_FILTER',
}

export default FiltersActions;
