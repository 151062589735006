import React from 'react';

import PropTypes from 'prop-types';

import { DisplaySM, IconButton, Tooltip } from '@trendkite/ui';

import styles from 'components/widgetWizard/steps/steps.module.css';

const Header = ({
  onIconButtonClick,
  iconButtonDisabled,
  showIconButton,
  iconButtonDisabledMessage,
  icon,
  subTitle,
  title,
}) => {
  return (
    <div className={styles.sectionHeader}>
      <div className={styles.sectionHeaderTitles}>
        <DisplaySM
          color="dark"
          style={{ fontSize: '12px', letterSpacing: '1px' }}
        >
          {title.toUpperCase()}
        </DisplaySM>
        {subTitle && (
          <DisplaySM style={{ fontSize: '12px' }}>{subTitle}</DisplaySM>
        )}
      </div>
      {showIconButton && (
        <div className={styles.sectionHeaderIconButton}>
          <Tooltip
            content={iconButtonDisabledMessage}
            showOnHover
            position="left"
            contentWidth={220}
          >
            <IconButton
              data-qa="T_axT3Nfkv2Nt23jGfhJb"
              icon={icon}
              onClick={onIconButtonClick}
              disabled={iconButtonDisabled}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  onIconButtonClick: PropTypes.func,
  iconButtonDisabled: PropTypes.bool,
  icon: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  showIconButton: PropTypes.bool,
  iconButtonDisabledMessage: PropTypes.string,
};

Header.defaultProps = {
  onIconButtonClick: () => {},
  iconButtonDisabled: false,
  icon: 'plus',
  subTitle: null,
  showIconButton: false,
  iconButtonDisabledMessage: '',
};

export default Header;
