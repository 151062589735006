import { TRANSLATION_ENDPOINT } from '../../constants/apis.js';
import { performPost } from '../../services/rest-service/rest-service';

export default class SharedIntlWrapper {
  getTranslations(languageCode, regionCode) {
    return performPost(`${TRANSLATION_ENDPOINT}/translations-dictionary`, {
      language: languageCode,
      region: regionCode,
      categories: ['UI_REACT'],
    });
  }

  getTranslatedMessages(languageTag) {
    const [languageCode, regionCode] = languageTag?.split('-') ?? ['en', 'us'];
    return this.getTranslations(languageCode, regionCode);
  }

  getLocaleByLanguageTag(languageTag) {
    // Hack to get correct number/date/etc formatting for non-official
    // culture identifiers
    if (languageTag === 'en-se') return 'sv-se';

    return languageTag;
  }
}
