export const OPEN_NYLAS_REDIRECT_MODAL = 'outreach/OPEN_NYLAS_REDIRECT_MODAL';

const initialState = {
  additionalState: {},
  isModalOpen: false,
  redirect: '',
};

const nylasRedirectModalReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case OPEN_NYLAS_REDIRECT_MODAL: {
      return {
        ...state,
        additionalState: action.payload.additionalState,
        isModalOpen: true,
        redirect: action.payload.redirect,
      };
    }
    default:
      return state;
  }
};

export const openNylasRedirectModalActionCreator = (
  redirect,
  additionalState,
) => ({
  type: OPEN_NYLAS_REDIRECT_MODAL,
  payload: {
    redirect,
    additionalState,
  },
});
export default nylasRedirectModalReducer;
