import React, { Component } from 'react';

import classNames from 'classnames';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { IntlPropType } from 'trendkite-front-end-app/src/pages/Influencers/common-prop-types';

import Button from '../button';
import SvgIcon from '../svg-icon';

import CalendarDatePicker from './CalendarDatePicker';
import { getDateFormatFromMoment } from './dateUtils';
import InputDate from './InputDate';

export class DateRangePicker extends Component {
  static baseClass = 'tk-date-range-picker';
  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    hasError: moment(this.props.startDate).isAfter(moment(this.props.endDate)),
    hasStartInputError: false,
    hasEndInputError: false,
    hasRangeError: moment(this.props.startDate)
      .add(365, 'day')
      .isBefore(moment(this.props.endDate)),
    hasEndDateMoreThanCurrent: false,
    hasEndDateBeforeStart: false,
  };

  wrapperRef = React.createRef();

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.endDateMoreThanCurrent) {
      this.setState({ hasEndDateMoreThanCurrent: true });
    }
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.allowChangeProps &&
      prevProps.startDate !== this.props.startDate
    ) {
      this.setState({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  onSave = () => {
    const { onSave } = this.props;
    const { startDate, endDate } = this.state;
    onSave(startDate.valueOf(), endDate.valueOf());
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.onCancel();
    }
  };

  handleStartDateChange = start => {
    const { onChange, disableApplyFilterButton } = this.props;
    const { endDate, hasEndInputError, hasStartInputError } = this.state;
    this.handleStartDateInputError(false);
    if (!hasEndInputError && hasStartInputError) {
      disableApplyFilterButton(false);
    }

    const { startDateUpdated, endDateUpdated } = getDateFormatFromMoment({
      startDate: start,
      endDate,
    });
    onChange(startDateUpdated, endDateUpdated);
    const hasError = moment(start).isAfter(moment(endDate));
    const hasRangeError = moment(start)
      .add(365, 'day')
      .isBefore(moment(endDate));
    this.setState({
      startDate: start,
      hasError,
      hasRangeError,
    });
  };

  handleEndDateChange = end => {
    const { onChange, disableApplyFilterButton } = this.props;
    const { startDate, hasStartInputError, hasEndInputError } = this.state;
    this.handleEndDateInputError(false);
    if (!hasStartInputError && hasEndInputError) {
      disableApplyFilterButton(false);
    }
    const { startDateUpdated, endDateUpdated } = getDateFormatFromMoment({
      startDate,
      endDate: end,
    });
    const hasEndDateBeforeStart = moment(end).isBefore(moment(startDate));
    onChange(startDateUpdated, endDateUpdated);

    const hasRangeError = moment(startDate)
      .add(365, 'day')
      .isBefore(moment(end));
    this.setState({
      endDate: end,
      hasRangeError,
      hasEndDateBeforeStart,
    });
  };

  handleStartDateInputError = err => {
    this.setState({
      hasStartInputError: err,
    });
  };

  handleEndDateInputError = err => {
    this.setState({
      hasEndInputError: err,
    });
  };

  render() {
    const {
      startDate,
      endDate,
      hasError,
      hasRangeError,
      hasEndDateMoreThanCurrent,
      hasEndDateBeforeStart,
    } = this.state;
    const {
      modifiers,
      minDate,
      maxDate,
      disableStartCalendar,
      disableEndCalendar,
      showWarningMessage,
      textMessageWarning,
      hasStartDateError,
      hasEndDateError,
      startDateErrorMessage,
      endDateErrorMessage,
      intl,
      utcOffset,
      hideFooter,
      dateRangeLimitOneYear,
      dateRangeLimitMessage,
      disableApplyFilterButton,
    } = this.props;
    const showRangeError = hasRangeError && dateRangeLimitOneYear;
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${DateRangePicker.baseClass}--${modifier}`)
      : [];

    const className = classNames(DateRangePicker.baseClass, prefixedModifiers);

    const warningMessage = () => (
      <div className={`${DateRangePicker.baseClass}__warningMessage`}>
        <div className={`${DateRangePicker.baseClass}__warningContent`}>
          <SvgIcon icon="info-filled" height={20} width={20} color="info" />
          <span className={`${DateRangePicker.baseClass}__warningText`}>
            {textMessageWarning}
          </span>
        </div>
      </div>
    );

    const rangeMessage = () => (
      <div className={`${DateRangePicker.baseClass}__warningMessage`}>
        <div className={`${DateRangePicker.baseClass}__warningContent`}>
          <SvgIcon icon="info-filled" height={20} width={20} color="info" />
          <span className={`${DateRangePicker.baseClass}__warningText`}>
            {dateRangeLimitMessage}
          </span>
        </div>
      </div>
    );

    return (
      <div className={`${className}`} ref={this.wrapperRef}>
        {showWarningMessage && warningMessage()}
        {showRangeError && rangeMessage()}
        <div className={`${DateRangePicker.baseClass}__container`}>
          <div className={`${DateRangePicker.baseClass}__inputs`}>
            <div className={`${DateRangePicker.baseClass}__input`}>
              <InputDate
                id="startDate"
                label={intl.formatMessage(globalMessages.startRange)}
                date={startDate}
                onDateChange={this.handleStartDateChange}
                error={
                  hasStartDateError || hasError ? startDateErrorMessage : ''
                }
                isDisable={disableStartCalendar}
                utcOffset={utcOffset}
                errorCallBackFn={this.handleStartDateInputError}
                disableApplyFilterButton={disableApplyFilterButton}
              />
            </div>
            <CalendarDatePicker
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onDateChange={this.handleStartDateChange}
              selectedMoment={startDate}
              onSelect={this.handleStartDateChange}
              minDate={minDate}
              maxDate={maxDate}
              className={disableStartCalendar ? 'isDisabled' : ''}
              focusOnSelected
              utcOffset={utcOffset}
              disableFutureDates={hasEndDateMoreThanCurrent}
            />
          </div>
          <div className={`${DateRangePicker.baseClass}__inputs`}>
            <div className={`${DateRangePicker.baseClass}__input`}>
              <InputDate
                id="endDate"
                label={intl.formatMessage(globalMessages.endRange)}
                date={endDate}
                onDateChange={this.handleEndDateChange}
                error={
                  hasEndDateError || hasEndDateBeforeStart
                    ? endDateErrorMessage
                    : ''
                }
                isDisable={disableEndCalendar}
                utcOffset={utcOffset}
                errorCallBackFn={this.handleEndDateInputError}
                disableApplyFilterButton={disableApplyFilterButton}
              />
            </div>
            <CalendarDatePicker
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onDateChange={this.handleEndDateChange}
              selectedMoment={endDate}
              onSelect={this.handleEndDateChange}
              minDate={minDate}
              maxDate={maxDate}
              className={disableEndCalendar ? 'isDisabled' : ''}
              focusOnSelected
              utcOffset={utcOffset}
              disableFutureDates={hasEndDateMoreThanCurrent}
            />
          </div>
        </div>
        {!hideFooter && (
          <div className={`${DateRangePicker.baseClass}__footer`}>
            <div className={`${DateRangePicker.baseClass}__action`}>
              <Button
                data-qa="7svJ1J24I1H6EeNQfmWQG"
                modifiers={['tertiary', 'round']}
                onClick={this.props.onCancel}
              >
                <TranslatedMessage {...globalMessages.cancel} />
              </Button>
            </div>
            <div className={`${DateRangePicker.baseClass}__action`}>
              <Button
                data-qa="YHqwDte8IFHUua6uLunj6"
                modifiers={['primary', 'round']}
                onClick={this.onSave}
                disabled={
                  hasStartDateError ||
                  hasEndDateError ||
                  hasError ||
                  this.state.hasStartInputError ||
                  this.state.hasEndInputError ||
                  (this.state.hasRangeError && dateRangeLimitOneYear)
                }
              >
                <TranslatedMessage {...globalMessages.apply} />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

DateRangePicker.propTypes = {
  modifiers: PropTypes.array,
  /**this prop is not used anymore since we've been using intl formatting for a while*/
  // eslint-disable-next-line react/no-unused-prop-types
  dateFormat: PropTypes.string,
  /** time in millis since epoch */
  startDate: PropTypes.number.isRequired,
  /** time in millis since epoch */
  endDate: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  disableStartCalendar: PropTypes.bool,
  disableEndCalendar: PropTypes.bool,
  showWarningMessage: PropTypes.bool,
  textMessageWarning: PropTypes.string,
  allowChangeProps: PropTypes.bool,
  intl: IntlPropType.isRequired,
  hasStartDateError: PropTypes.bool,
  hasEndDateError: PropTypes.bool,
  startDateErrorMessage: PropTypes.string,
  endDateErrorMessage: PropTypes.string,
  utcOffset: PropTypes.number,
  hideFooter: PropTypes.bool,
  dateRangeLimitOneYear: PropTypes.bool,
  dateRangeLimitMessage: PropTypes.string,
  endDateMoreThanCurrent: PropTypes.boolean,
  disableApplyFilterButton: PropTypes.func,
  filterEndDate: PropTypes.number,
};

DateRangePicker.defaultProps = {
  modifiers: [],
  dateFormat: 'MMM D, YYYY',
  minDate: null,
  maxDate: null,
  disableStartCalendar: false,
  disableEndCalendar: false,
  showWarningMessage: false,
  textMessageWarning: '',
  onChange: () => {},
  onCancel: () => {},
  allowChangeProps: false,
  hasStartDateError: false,
  hasEndDateError: false,
  startDateErrorMessage: 'Invalid start date',
  endDateErrorMessage: 'Invalid end date',
  hideFooter: false,
  dateRangeLimitOneYear: false,
  dateRangeLimitMessage: 'Queries are limited to a one year date range.',
  endDateMoreThanCurrent: false,
  disableApplyFilterButton: () => {},
  filterEndDate: null,
};

export default injectIntl(DateRangePicker);
