export default {
  overview: {
    id: 'DEFAULT_TEMPLATE_OVERVIEW',
    defaultMessage: 'Overview',
    description:
      'Campaigns > Story Kit > Story Edit Page: content tab for overview',
  },
  downloadableAssets: {
    id: 'DEFAULT_TEMPLATE_DOWNLOADABLE_ASSETS',
    defaultMessage: `Downloadable Assets { ASSET_COUNT, plural,
      =0 {}
      other { ({ASSET_COUNT})}
      }`,
    description:
      'Campaigns > Story Kit > Story Edit Page: content tab for downloadable assets',
  },
  requiredContactValidationMessage: {
    id: 'DEFAULT_TEMPLATE_OVERVIEW_CONTACT_VALIDATION_MESSAGE',
    defaultMessage: 'A contact is required to publish',
    description: 'Message indicating the Story Contact field is required.',
  },
};
