import {
  ARTICLE_TYPES_LABELS_MESSAGES_KEYS,
  ARTICLE_TYPE_LABELS_I18N_MESSAGES,
} from 'constants/constants';

export const getTranslatedArticleType = (articleType, messageKey, intl) => {
  const articleTypeMessage =
    ARTICLE_TYPE_LABELS_I18N_MESSAGES[articleType]?.[messageKey];
  return articleTypeMessage
    ? intl.formatMessage(articleTypeMessage)
    : articleType;
};

export const getTranslatedSingularArticleType = (articleType, intl) =>
  getTranslatedArticleType(
    articleType,
    ARTICLE_TYPES_LABELS_MESSAGES_KEYS.singular,
    intl,
  );

export const getTranslatedPluralArticleType = (articleType, intl) =>
  getTranslatedArticleType(
    articleType,
    ARTICLE_TYPES_LABELS_MESSAGES_KEYS.plural,
    intl,
  );
