import React, { Component } from 'react';

import axios from 'axios';
import T from 'i18n/TranslatedMessage';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import {
  SvgIcon,
  DisplaySM,
  Kite,
  withResponsiveModifiers,
} from '@trendkite/ui';

import { ASSET_POPUP_GET_STORY_ENDPOINT } from '../../../app/src/constants/apis';

import BrowseModalUpload from '../browse-asset-library/BrowseModalUpload';

import FileInput from '../story/fields/FileInput';

import ImageAsset from './ImageAsset';

// i18n

import messages from './StoryDownloadableAssets.messages';

const massageStoryAssets = (assets, additions = {}) =>
  mapValues(assets, asset => ({
    imageURL: asset.imageURL,
    thumbnailURL: asset.thumbnailURL,
    size: asset.size,
    caption: asset.caption,
    id: asset.id,
    deleted: asset.deleted,
    width: asset.width,
    height: asset.height,
    position: asset.position,
    ...additions,
  }));

class StoryDownloadableAssets extends Component {
  static baseClass = 'story-downloadable-assets';

  static getDerivedStateFromProps(props, state) {
    if (props.assets !== state.prevAssets) {
      return {
        assets: {
          ...merge(massageStoryAssets(props.assets), state.assets),
        },
        prevAssets: props.assets,
      };
    }

    return null;
  }

  state = {
    assets: {},
    errors: [],
    prevAssets: [],
    isFromDownloadable: true,
    showOtherUploadOptions: false,
  };

  onDrop = (acceptedFiles, text) => {
    const getNextPosition = position => {
      if (!this.state.assets || this.state.assets === undefined)
        return position + 1;
      if (Object.keys(this.state.assets).length === 0) return position + 1;

      const lastPosition = Math.max(
        ...Object.values(this.state.assets).map(asset => asset.position),
      );

      return lastPosition + position + 1;
    };

    const assets = {};

    acceptedFiles.forEach((file, index) => {
      if (file.type === 'application/pdf' || file.type === 'image/svg+xml') {
        file.preview = null;
      }
      const asset = {
        id: text.length === 0 ? shortid.generate() : file.id,
        uploading: true,
        error: false,
        uploadProgress: 0,
        preview: file.preview,
        caption:
          text.length === 0 ? file.name : file.name + '.' + file.extension,
        size:
          text.length === 0
            ? file.size
            : file.sizeInKb.replace(/,/g, '') * 1024,
        deleted: false,
        position: getNextPosition(index),
        file,
      };

      assets[asset.id] = asset;
      this.uploadAsset(asset, text);
    });

    this.setState({ assets: { ...this.state.assets, ...assets } });
  };

  onCaptionEdit = (assetId, caption) => {
    const { dispatchMergeFormAction } = this.props;

    const assets = this.state.assets;
    assets[assetId] = { ...assets[assetId], caption, preview: null };

    this.setState({ assets }, () =>
      dispatchMergeFormAction('storyContentForm', { assets }),
    );
  };

  onAssetDelete = assetId => {
    const { dispatchMergeFormAction } = this.props;

    const assets = this.state.assets;
    assets[assetId].deleted = true;

    this.setState({ assets }, () =>
      dispatchMergeFormAction('storyContentForm', { assets }),
    );
  };

  uploadAsset = async (asset, text) => {
    const { uploadURL, dispatchMergeFormAction } = this.props;

    const file = asset.file;
    const data = new FormData();
    if (text.length === 0) {
      data.append('image', file);
      data.append('type', 'story-asset');
      data.append('maxSize', '10000');
    } else {
      data.append('id', asset.id);
      data.append('type', 'ms-story-asset');
      data.append('isTK', asset.file.isTK);
      data.append('size', asset.size);
      data.append('height', asset.file.height);
      data.append('width', asset.file.width);
    }

    const uploadConfig = {
      onUploadProgress: event => {
        const uploadProgress = Math.round((event.loaded * 100) / event.total);

        const uploadedAssets = this.state.assets;
        uploadedAssets[asset.id] = {
          ...asset,
          uploadProgress,
        };

        this.setState({ assets: uploadedAssets });
      },
    };

    try {
      let response;
      if (text.length === 0) {
        response = await axios.post(uploadURL, data, uploadConfig);
      } else if (text.length > 0) {
        response = await axios.post(
          `${ASSET_POPUP_GET_STORY_ENDPOINT}`,
          data,
          uploadConfig,
        );
      }

      const {
        full: imageURL,
        thumbnail: thumbnailURL,
        full_width: width,
        full_height: height,
      } = response.data;

      const size = parseInt(response.data.full_size, 10);
      const updatedAsset = {
        ...asset,
        uploading: false,
        imageURL,
        thumbnailURL,
        width,
        height,
        size,
      };

      const updatedAssets = this.state.assets;
      updatedAssets[asset.id] = updatedAsset;

      this.setState({ assets: updatedAssets }, () =>
        dispatchMergeFormAction('storyContentForm', { assets: updatedAssets }),
      );
    } catch (e) {
      this.state.assets[asset.id].error = true;
      this.addNewError('There was an error uploading your asset.');
    }
  };

  filterCleanAssets = () => {
    return Object.keys(this.state.assets)
      .map(id => this.state.assets[id])
      .filter(asset => !!asset.position)
      .filter(asset => !asset.deleted)
      .filter(asset => asset.imageURL || asset.preview);
  };

  addNewError = error => {
    this.setState({
      errors: [...this.state.errors, { error, id: shortid.generate() }],
    });
  };

  removeError = id => {
    const errors = this.state.errors.filter(error => error.id !== id);
    this.setState({ errors });
  };

  renderErrors = () =>
    this.state.errors.map(error => (
      <Kite onCancel={() => this.removeError(error.id)} key={error.id}>
        {error.error}
      </Kite>
    ));

  renderImageAssets = () => {
    const { isEditable } = this.props;

    return this.filterCleanAssets()
      .sort((a, b) => {
        if (a.position === b.position) return 0;
        return a.position < b.position ? -1 : 1;
      })
      .map(asset => {
        return (
          <div
            className={`${StoryDownloadableAssets.baseClass}__asset`}
            key={asset.position}
          >
            <ImageAsset
              asset={asset}
              isEditable={isEditable}
              onAssetDelete={this.onAssetDelete}
            />
          </div>
        );
      });
  };

  render() {
    const { className, isEditable, browseLink } = this.props;
    const { isFromDownloadable, showOtherUploadOptions } = this.state;

    const hasAssets = this.filterCleanAssets().length > 0;

    return (
      <div className={`${StoryDownloadableAssets.baseClass} ${className}`}>
        {this.renderErrors()}
        {isEditable && (
          <div className={`${StoryDownloadableAssets.baseClass}__upload`}>
            {!hasAssets && (
              <DisplaySM
                style={{ fontStyle: 'italic', marginBottom: '5px' }}
                color="light"
              >
                <T {...messages.addAssetsToStoryMessage} />
              </DisplaySM>
            )}
            {browseLink && (
              <div className="browseLinkText">
                <BrowseModalUpload
                  isFromDownloadable={isFromDownloadable}
                  showOtherUploadOptions={showOtherUploadOptions}
                  handleSelectedFilesfromPopUp={this.onDrop}
                  makeBrowseAssetComponentFalse={
                    this.makeBrowseAssetComponentFalse
                  }
                />
              </div>
            )}
            {!browseLink && (
              <FileInput
                onDrop={this.onDrop}
                dropzoneOptions={{ accept: 'image/*,application/pdf' }}
              >
                <SvgIcon icon="upload" style={{ marginRight: '16px' }} />
                <T {...messages.uploadAssets} />
              </FileInput>
            )}
          </div>
        )}
        {hasAssets && (
          <div className={`${StoryDownloadableAssets.baseClass}__assets`}>
            {this.renderImageAssets()}
          </div>
        )}
      </div>
    );
  }
}

StoryDownloadableAssets.propTypes = {
  assets: PropTypes.object,
  className: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  browseLink: PropTypes.bool,
  uploadURL: PropTypes.string.isRequired,
  dispatchMergeFormAction: PropTypes.func.isRequired,
};

StoryDownloadableAssets.defaultProps = {
  className: '',
  browseLink: false,
};

export default withResponsiveModifiers(StoryDownloadableAssets);
