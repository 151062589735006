import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const ViewGrid = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M4 11h5V5H4v6zm0 7h5v-6H4v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5V5h-5v6zm6-6v6h5V5h-5z" />
  </svg>
);
ViewGrid.propTypes = { fill: PropTypes.string };
ViewGrid.defaultProps = { fill: iconColorsMap.mainicon };

export default ViewGrid;
