import flatten from 'lodash/flatten';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';

import moment from 'moment';
import qs from 'qs';

import {
  ARTICLE_CUSTOM_BROADCAST_CLIP,
  ARTICLE_DELETE_ENDPOINT,
  ARTICLE_DETAILS_ENDPOINT,
  ARTICLE_STAR_ENDPOINT,
  ARTICLES_ENDPOINT,
  ARTICLES_INTL_ENDPOINT,
  ARTICLES_US_ENDPOINT,
  ARTICLES_UPDATE_SENTIMENT_ENDPOINT,
  ARTICLE_UNSTAR_ENDPOINT,
  CONTACT_BASE_ENDPOINT,
  CONTACT_IMAGES_ENDPOINT,
  SEARCH_LIST_ENDPOINT_V2,
  STORY_BASE_ENDPOINT,
} from 'constants/apis';

import {
  ANALYTICS_INTEGRATION_TYPES,
  ARTICLE_LIST_TYPES,
  REASONS_TO_DELETE_ARTICLES,
  DEV_FEATURES,
  DEFAULT_DATE_FORMAT_INTL,
} from 'constants/constants';

import globalMessages from 'i18n/Global.messages';
import articleMessages from 'pages/Articles/articles.messages';
import {
  SET_ARTICLE_LIST,
  SET_ARTICLE_LIST_ARTICLES,
  SET_ARTICLE_LIST_LOADING,
  SHOW_KEYWORD_FILTER_ERROR,
} from 'reducers/article-lists';
import {
  UPDATE_CAMPAIGN_ARTICLES,
  REMOVE_CAMPAIGN_ARTICLES,
} from 'reducers/campaigns/campaign';
import {
  addContactImagePlaceholders,
  addImagesToContacts,
} from 'reducers/contacts/contact-images';
import {
  getContactById,
  UNSET_CURRENT_CONTACT,
  UPDATE_CONTACT_ARTICLES,
} from 'reducers/contacts/contacts';
import {
  REMOVE_DISCOVERY_ARTICLES,
  SET_TOP_CONTENT,
} from 'reducers/discovery/discovery';
import {
  REMOVE_DRILLDOWN_ARTICLES,
  UPDATE_DRILLDOWN_ARTICLES,
} from 'reducers/drilldowns';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { GET_SEARCH_DATA_ERROR, UNSET_SEARCH_DATA } from 'reducers/searches';
import { setSocialAmpDataFromArticle } from 'reducers/social-amp';
import {
  UPDATE_STORY_ARTICLES,
  REMOVE_STORY_ARTICLES,
} from 'reducers/stories/story-coverage';
import { loggedInSelector, hasDevFeatureFlagSelector } from 'selectors/account';
import { defaultAnalyticsIntegrationIdSelector } from 'selectors/app-info';
import {
  articleByIdSelector,
  articlesInGroupById,
  currentArticleIdSelector,
  currentArticleSelector,
  currentArticleTypeLabelSelector,
} from 'selectors/article';
import { currentArticleListSelector } from 'selectors/article-lists';
import { currentDrilldownIdSelector } from 'selectors/drilldown';
import { currentSearchIdSelector } from 'selectors/search';
import {
  getApiQueryParamsFromList,
  getApiQueryParamsFromUrlParams,
} from 'services/article-lists/article-lists';
import { isCedrom } from 'services/article-source-service/article-source-service';
import {
  performDelete,
  performGet,
  performPost,
} from 'services/rest-service/rest-service';
import { getCacheBustedUrlForCM } from 'utils/links/links';

import { getTranslatedSingularArticleType } from 'utils/translations/translations-utils';

import messages from '../pages/WidgetDrilldown/DrilldownArticleList/form.messages';

export const GET_ARTICLE = 'articles/GET_ARTICLE';
export const GET_ARTICLE_RECEIVED = 'articles/GET_ARTICLE_RECEIVED';
export const GET_ARTICLE_ERROR = 'articles/GET_ARTICLE_ERROR';
export const UNSET_CURRENT_ARTICLE = 'articles/UNSET_CURRENT_ARTICLE';

export const GET_CEDROM_TOKEN = 'articles/GET_CEDROM_TOKEN';
export const GET_CEDROM_TOKEN_SUCCESS = 'articles/GET_CEDROM_TOKEN_SUCCESS';
export const GET_CEDROM_TOKEN_ERROR = 'articles/GET_CEDROM_TOKEN_ERROR';
export const GET_CEDROM_MEDIA_URLS = 'articles/GET_CEDROM_MEDIA_URLS';
export const GET_CEDROM_MEDIA_URLS_SUCCESS =
  'articles/GET_CEDROM_MEDIA_URLS_SUCCESS';
export const GET_CEDROM_MEDIA_URLS_ERROR =
  'articles/GET_CEDROM_MEDIA_URLS_ERROR';

export const KEYWORD_FILTER_SEARCH = 'articles/KEYWORD_FILTER_SEARCH';
export const KEYWORD_FILTER_SEARCH_SUCCESS =
  'articles/KEYWORD_FILTER_SEARCH_SUCCESS';
export const GET_ARTICLES = 'articles/GET_ARTICLES';
export const GET_ARTICLES_RECEIVED = 'articles/GET_ARTICLES_RECEIVED';
export const GET_ARTICLES_ERROR = 'articles/GET_ARTICLES_ERROR';
export const GET_ARTICLES_ERROR_BAD_REQUEST =
  'articles/GET_ARTICLES_ERROR_BAD_REQUEST';
export const REMOVE_ARTICLES_FROM_SEARCH =
  'articles/REMOVE_ARTICLES_FROM_SEARCH';
export const REMOVE_ARTICLES_FROM_SEARCH_SUCCESS =
  'articles/REMOVE_ARTICLES_FROM_SEARCH_SUCCESS';
export const REMOVE_ARTICLES_FROM_SEARCH_ERROR =
  'articles/REMOVE_ARTICLES_FROM_SEARCH_ERROR';
export const SET_ALL_ARTICLES_SELECTION = 'articles/SET_ALL_ARTICLES_SELECTION';
export const SET_ARTICLE_CUSTOM_CLIP = 'articles/SET_ARTICLE_CUSTOM_CLIP';
export const SET_ARTICLE_CUSTOM_CLIP_SUCCESS =
  'articles/SET_ARTICLE_CUSTOM_CLIP_SUCCESS';
export const SET_ARTICLE_CUSTOM_CLIP_ERROR =
  'articles/SET_ARTICLE_CUSTOM_CLIP_ERROR';
export const SET_ARTICLE_EXPANDED_METRICS =
  'articles/SET_ARTICLE_EXPANDED_METRICS';
export const SET_ARTICLE_DUPLICATE_EXPANDED_METRICS =
  'articles/SET_ARTICLE_DUPLICATE_EXPANDED_METRICS';
export const SET_ARTICLE_SELECTION = 'articles/SET_ARTICLE_SELECTION';
export const SET_ARTICLES_SELECTION = 'articles/SET_ARTICLES_SELECTION';
export const SET_DUPLICATE_ARTICLE_SELECTION =
  'articles/SET_DUPLICATE_ARTICLE_SELECTION';
export const SET_ARTICLES_SENTIMENT = 'articles/SET_ARTICLES_SENTIMENT';
export const SET_ARTICLES_SENTIMENT_RECEIVED =
  'articles/SET_ARTICLES_SENTIMENT_RECEIVED';
export const SET_ARTICLES_SENTIMENT_ERROR =
  'articles/SET_ARTICLES_SENTIMENT_ERROR';
export const STAR_ARTICLE = 'articles/STAR_ARTICLE';
export const STAR_ARTICLE_SUCCESS = 'articles/STAR_ARTICLE_SUCCESS';
export const STAR_ARTICLE_ERROR = 'articles/STAR_ARTICLE_ERROR';
export const UNSTAR_ARTICLE = 'articles/UNSTAR_ARTICLE';
export const UNSTAR_ARTICLE_SUCCESS = 'articles/UNSTAR_ARTICLE_SUCCESS';
export const UNSTAR_ARTICLE_ERROR = 'articles/UNSTAR_ARTICLE_ERROR';
export const ADD_TAGS_TO_ARTICLES_BY_ID = 'articles/ADD_TAGS_TO_ARTICLES_BY_ID';
export const REMOVE_TAGS_FROM_ARTICLES_BY_ID =
  'articles/REMOVE_TAGS_FROM_ARTICLES_BY_ID';
export const REMOVE_DISCOVERY_ARTICLES_ALL_MENTIONS =
  'articles/REMOVE_DISCOVERY_ARTICLES_ALL_MENTIONS';

const initialState = {
  loading: true,
  current: null,
  error: false,
  articles: {},
};

export const initialArticle = {
  author: '',
  broadcastMediaType: 0,
  broadcastUrlReadOnly: '',
  data_source: '',
  id: '',
  link: '',
  mediaType: '',
  publishDateAsDate: '',
  publisher: '',
  publisherUrl: '',
  sentiment: '',
  socialSum: 0,
  topArticle: false,
  title: '',
  url: '',
  cedromToken: null,
  cedromMedia: {
    url: null,
    player: null,
    fileLowRes: null,
    fileHighRes: null,
    vtt: null,
    clipEditor: null,
    loading: true,
    error: false,
  },
};

export const flattenArticles = articleClips => {
  let articleClipsAsMap = {};
  const articleIds = [];
  if (Array.isArray(articleClips)) {
    articleClipsAsMap = articleClips.reduce((result, article) => {
      article.expandedMetrics = false;
      article.selected = false;
      // Convert the duplicates into a list of ids only, they will then be looked up
      // using a selector
      article.duplicatePublications = article.duplicatePublications.map(
        duplicate => {
          if (typeof duplicate === 'string') {
            // This article has already been flattened
            return duplicate;
          }

          duplicate.selected = false;
          // Set the parentId
          duplicate.parentId = article.id;
          result[duplicate.id] = duplicate;
          return duplicate.id;
        },
      );

      if (result[article.id]) {
        result[article.id] = {
          ...result[article.id],
          ...article,
        };
      } else {
        result[article.id] = article;
      }

      articleIds.push(article.id);
      return result;
    }, articleClipsAsMap);
  }

  return {
    articleClipsAsMap,
    articleIds,
  };
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE:
      return {
        ...state,
        loading: true,
      };
    case GET_ARTICLE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_ARTICLE_RECEIVED:
      return {
        ...state,
        loading: false,
        articles: {
          ...state.articles,
          [action.payload.article.id]: {
            ...action.payload.article,
            mediaError: false,
            mediaSaving: false,
            removeFromSearchError: false,
            removeFromSearchSaving: false,
            searchId: action.payload.searchId,
            topArticleLoading: false,
          },
        },
        current: action.payload.article.id,
      };
    case GET_CEDROM_TOKEN:
      return {
        ...state,
        articles: {
          ...state.articles,
          [state.current]: {
            ...state.articles[state.current],
            cedromMedia: {
              ...initialState.cedromMedia,
              loading: true,
            },
          },
        },
      };
    case GET_CEDROM_TOKEN_SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [state.current]: {
            ...state.articles[state.current],
            cedromToken: action.payload.token,
          },
        },
      };
    case GET_CEDROM_TOKEN_ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          [state.current]: {
            ...state.articles[state.current],
            cedromMedia: {
              ...initialState.cedromMedia,
              error: true,
              loading: false,
            },
          },
        },
      };

    case GET_CEDROM_MEDIA_URLS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [state.current]: {
            ...state.articles[state.current],
            cedromMedia: {
              ...initialState.cedromMedia,
              loading: true,
            },
          },
        },
      };
    case GET_CEDROM_MEDIA_URLS_SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [state.current]: {
            ...state.articles[state.current],
            cedromMedia: {
              ...initialState.cedromMedia,
              url: action.payload.url,
              player: action.payload.player,
              fileLowRes: action.payload.fileLowRes,
              fileHighRes: action.payload.fileHighRes,
              vtt: action.payload.vtt,
              clipEditor: action.payload.clipEditor,
              loading: false,
              error: false,
            },
          },
        },
      };
    case GET_CEDROM_MEDIA_URLS_ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          [state.current]: {
            ...state.articles[state.current],
            cedromMedia: {
              ...initialState.cedromMedia,
              error: true,
              loading: false,
            },
          },
        },
      };
    case GET_ARTICLES:
      return {
        ...state,
        loading: true,
      };
    case GET_ARTICLES_RECEIVED:
      return {
        ...state,
        articles: {
          ...state.articles,
          ...action.payload.articles,
        },
        articlesQueryParams: action.payload.articlesQueryParams,
        loading: false,
        error: false,
      };
    case GET_ARTICLES_ERROR_BAD_REQUEST:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case GET_ARTICLES_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case REMOVE_ARTICLES_FROM_SEARCH: {
      const modifiedArticles = {};
      action.payload.articleIds.forEach(id => {
        modifiedArticles[id] = {
          ...state.articles[id],
          removeFromSearchError: false,
          removeFromSearchSaving: true,
        };
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          ...modifiedArticles,
        },
      };
    }
    case REMOVE_ARTICLES_FROM_SEARCH_SUCCESS: {
      const modifiedArticles = {};
      action.payload.articleIds.forEach(id => {
        modifiedArticles[id] = {
          ...state.articles[id],
          removeFromSearchError: false,
          removeFromSearchSaving: false,
        };
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          ...modifiedArticles,
        },
      };
    }
    case REMOVE_ARTICLES_FROM_SEARCH_ERROR: {
      const modifiedArticles = {};
      action.payload.articleIds.forEach(id => {
        modifiedArticles[id] = {
          ...state.articles[id],
          removeFromSearchError: true,
          removeFromSearchSaving: false,
        };
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          ...modifiedArticles,
        },
      };
    }
    case REMOVE_DISCOVERY_ARTICLES_ALL_MENTIONS: {
      const modifiedArticles = state.articles;
      action.payload.removeArticleIds.forEach(item => {
        delete modifiedArticles[item];
      });
      return {
        ...state,
        articles: modifiedArticles,
      };
    }
    case SET_ARTICLE_CUSTOM_CLIP:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            mediaError: false,
            mediaSaving: true,
          },
        },
      };
    case SET_ARTICLE_CUSTOM_CLIP_SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            customBroadcastId: action.payload.customBroadcastId,
            customBroadcastUrl: action.payload.customBroadcastUrl,
            customBroadcastStatus: action.payload.customBroadcastStatus,
            mediaError: false,
            mediaSaving: false,
          },
        },
        loading: false,
      };
    case SET_ARTICLE_CUSTOM_CLIP_ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            mediaError: true,
            mediaSaving: false,
          },
        },
      };
    case SET_ARTICLES_SENTIMENT: {
      const modifiedArticles = {};

      action.payload.ids.forEach(id => {
        if (state.articles[id]) {
          modifiedArticles[id] = {
            ...state.articles[id],
            sentimentError: false,
            sentimentLoading: true,
            sentiment: action.payload.sentiment,
          };
        }
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          ...modifiedArticles,
        },
      };
    }
    case SET_ARTICLES_SENTIMENT_RECEIVED: {
      const modifiedArticles = {};

      action.payload.ids.forEach(id => {
        if (state.articles[id]) {
          modifiedArticles[id] = {
            ...state.articles[id],
            sentimentError: false,
            sentimentLoading: false,
            sentiment: action.payload.sentiment,
          };
        }
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          ...modifiedArticles,
        },
      };
    }
    case SET_ARTICLES_SENTIMENT_ERROR: {
      const modifiedArticles = {};

      action.payload.ids.forEach(id => {
        if (state.articles[id]) {
          modifiedArticles[id] = {
            ...state.articles[id],
            sentimentError: action.payload.error,
            sentimentLoading: false,
          };
        }
      });

      return {
        ...state,
        error: true,
        articles: {
          ...state.articles,
          ...modifiedArticles,
        },
      };
    }
    case STAR_ARTICLE:
    case UNSTAR_ARTICLE:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            topArticleLoading: true,
          },
        },
      };
    case STAR_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            topArticle: true,
            topArticleLoading: false,
          },
        },
      };
    case UNSTAR_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            topArticle: false,
            topArticleLoading: false,
          },
        },
      };
    case STAR_ARTICLE_ERROR:
    case UNSTAR_ARTICLE_ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            topArticleLoading: false,
          },
        },
      };
    case UNSET_CURRENT_ARTICLE:
      return {
        ...state,
        current: null,
      };
    case ADD_TAGS_TO_ARTICLES_BY_ID: {
      return {
        ...state,
        articles: {
          ...state.articles,
          ...action.payload.updatedArticles,
        },
      };
    }
    case REMOVE_TAGS_FROM_ARTICLES_BY_ID: {
      return {
        ...state,
        articles: {
          ...state.articles,
          ...action.payload.updatedArticles,
        },
      };
    }
    case SET_ALL_ARTICLES_SELECTION: {
      const { selected } = action.payload;

      const updatedArticles = {};
      Object.values(state.articles).forEach(article => {
        updatedArticles[article.id] = {
          ...article,
          selected,
        };
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          ...updatedArticles,
        },
      };
    }
    case SET_ARTICLE_SELECTION:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            selected: action.payload.selected,
          },
        },
      };
    case SET_ARTICLES_SELECTION: {
      const updatedArticles = {};
      action.payload.articleIds.forEach(articleId => {
        updatedArticles[articleId] = {
          ...state.articles[articleId],
          selected: action.payload.selected,
        };
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          ...updatedArticles,
        },
      };
    }
    case SET_DUPLICATE_ARTICLE_SELECTION:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            duplicatePublications: action.payload.duplicatePublications,
          },
        },
      };
    case SET_ARTICLE_EXPANDED_METRICS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            expandedMetrics: action.payload.expanded,
          },
        },
      };
    case SET_ARTICLE_DUPLICATE_EXPANDED_METRICS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.articleId]: {
            ...state.articles[action.payload.articleId],
            duplicatePublications: action.payload.duplicatePublications,
          },
        },
      };
    default:
      return state;
  }
};

const getCedromMediaUrls = (params, dispatch) => {
  const endpoint = params.url
    .replace('token=xxx', `token=${params.token}`)
    .replace('http://', 'https://');
  // we will need to update this to use prod version.. not RC.
  dispatch({ type: GET_CEDROM_MEDIA_URLS });
  performGet(`${endpoint}`)
    .then(response => {
      dispatch({
        type: GET_CEDROM_MEDIA_URLS_SUCCESS,
        payload: {
          url: response.data.url,
          player: response.data.player,
          fileLowRes: response.data.fileLowRes,
          fileHighRes: response.data.fileHighRes,
          vtt: response.data.vtt,
          clipEditor: response.data.clipEditor,
        },
      });
    })
    .catch(error => {
      dispatch({ type: GET_CEDROM_MEDIA_URLS_ERROR, payload: error });
      throw error;
    });
};

export const getCedromToken = () => (dispatch, getState) => {
  const url = currentArticleSelector(getState()).url;
  if (getState().cedromToken) {
    getCedromMediaUrls({ token: getState().cedromToken, url }, dispatch);
  } else {
    const endpoint =
      'https://media.cedrom-sni.com/auth/kl30dalczmi9d12d245mmcd';
    // we will need to update this to use prod version.. not RC.
    dispatch({ type: GET_CEDROM_TOKEN });
    performGet(`${endpoint}`)
      .then(response => {
        const status = response.status;
        if (status === 200) {
          dispatch({
            type: GET_CEDROM_TOKEN_SUCCESS,
            payload: { token: response.data.token },
          });
          getCedromMediaUrls({ token: response.data.token, url }, dispatch);
        } else {
          dispatch({ type: GET_CEDROM_TOKEN_ERROR });
        }
      })
      .catch(error => {
        dispatch({ type: GET_CEDROM_TOKEN_ERROR, payload: error });
        throw error;
      });
  }
};

export const getArticleById = ({
  articleId,
  searchId,
  dashboardId,
  analyticsIntegrationId,
  userId,
  startDate,
  endDate,
  accessToken,
  intl,
}) => (dispatch, getState) => {
  const state = getState();
  const postData = searchId ? { searchId } : {};
  Object.assign(postData, { startDate, endDate, accessToken });
  const isLoggedIn = loggedInSelector(state);

  if (isLoggedIn && dashboardId) {
    postData.dashboardId = dashboardId;
  }

  if (isLoggedIn && analyticsIntegrationId) {
    postData.analyticsId = analyticsIntegrationId;
  }

  if (userId) {
    postData.userId = userId;
  }

  dispatch({ type: GET_ARTICLE });

  performPost(`${ARTICLE_DETAILS_ENDPOINT}${articleId}`, postData)
    .then(response => {
      if (searchId && !response.data.id) {
        // Invalid search ID is known to cause empty article payload, so try again without it
        dispatch(getArticleById({ articleId, intl }));
        // Setting search error state allows containers to remove searchId from the URL, among other things
        dispatch({ type: GET_SEARCH_DATA_ERROR });
        return;
      }

      const article = response.data;
      if (
        intl &&
        window.activeUser.language === 'fr-ca' &&
        article.data_source?.toLowerCase() === 'tveyes'
      ) {
        const title = intl.formatMessage(
          messages.tvEyesRadioClipFromPublisherTitle,
          {
            PUBLISHER: article.publisher,
            DATE: intl.formatDate(
              moment(article.publishDate).valueOf(),
              DEFAULT_DATE_FORMAT_INTL,
            ),
          },
        );
        article.title = title;
      }

      dispatch({
        type: GET_ARTICLE_RECEIVED,
        payload: {
          article,
          searchId,
        },
      });

      if (
        isCedrom(response.data.data_source) &&
        (hasDevFeatureFlagSelector(getState())(
          DEV_FEATURES.restrictedContent,
        ) ||
          !isLoggedIn)
      ) {
        dispatch(getCedromToken());
      }

      if (isLoggedIn && response.data && response.data.authorId) {
        dispatch(getContactById(response.data.authorId, response.data.author));
      }

      if (response.data && response.data.socialRefreshDate) {
        dispatch(setSocialAmpDataFromArticle(response.data));
      }
    })
    .catch(error => {
      dispatch({ type: GET_ARTICLE_ERROR, payload: error });
      throw error;
    });
};

export const setArticlesSentiment = (articleIds, sentimentString) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const ids = flatten(
    articleIds.map(id => {
      return articlesInGroupById(state)(id);
    }),
  ).map(article => article.id);

  dispatch({ type: SET_ARTICLES_SENTIMENT, payload: { ids } });

  performPost(`${ARTICLES_UPDATE_SENTIMENT_ENDPOINT}`, {
    articleIds: ids,
    sentiment: sentimentString.toUpperCase(),
  })
    .then(() => {
      dispatch({
        type: SET_ARTICLES_SENTIMENT_RECEIVED,
        payload: { ids, sentiment: sentimentString.toLowerCase() },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Success',
          text: `The sentiment has been changed to ${sentimentString}.`,
          status: 'success',
        }),
      );
    })
    .catch(() => {
      dispatch({
        type: SET_ARTICLES_SENTIMENT_ERROR,
        payload: { ids, error: true },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: `The system had a problem setting the sentiment to ${sentimentString}.`,
          status: 'danger',
        }),
      );
    });
};

function encodeAndStringifyQueryParams(queryParams) {
  let urlParams = {
    ...queryParams,
    data_point:
      queryParams && queryParams.dataPoint
        ? encodeURIComponent(queryParams.dataPoint)
        : '',
    pageNum: queryParams && queryParams.pageNum ? queryParams.pageNum : 1,
  };

  delete urlParams.dataPoint;
  urlParams = pickBy(urlParams); // Prunes props with falsey values

  return qs.stringify(urlParams, { indices: false, addQueryPrefix: true });
}

const doGetArticlesByWidgetId = (
  dispatch,
  widgetId,
  newQueryParams,
  widgetTypeOverride,
) => {
  let endpoint = ARTICLES_ENDPOINT;
  if (widgetTypeOverride === 'US_MENTIONS') {
    endpoint = `${ARTICLES_US_ENDPOINT}${widgetId}`;
  } else if (widgetTypeOverride === 'INTL_MENTIONS') {
    endpoint = `${ARTICLES_INTL_ENDPOINT}${widgetId}`;
  }

  performGet(`${endpoint}${encodeAndStringifyQueryParams(newQueryParams)}`)
    .then(response => {
      const resultArticles = response.data || {};
      const articleClips = Array.isArray(resultArticles.articleClips)
        ? resultArticles.articleClips
        : [];
      const articlesTotalCount =
        resultArticles.paging &&
        typeof resultArticles.paging.totalNumResults === 'number'
          ? resultArticles.paging.totalNumResults
          : 0;
      const authorsCount =
        resultArticles.numAuthors === 'number' ? resultArticles.numAuthors : 0;
      const pageMax =
        resultArticles.paging &&
        typeof resultArticles.paging.maxPage === 'string'
          ? resultArticles.paging.maxPage
          : '';
      const pageMaxNum =
        resultArticles.paging &&
        typeof resultArticles.paging.maxPageNum === 'number'
          ? resultArticles.paging.maxPageNum
          : 0;
      const pageNext =
        resultArticles.paging && typeof resultArticles.paging.next === 'string'
          ? resultArticles.paging.next
          : '';
      const pagePrevious =
        resultArticles.paging &&
        typeof resultArticles.paging.previous === 'string'
          ? resultArticles.paging.previous
          : '';

      const { articleClipsAsMap, articleIds } = flattenArticles(articleClips);

      dispatch({
        type: GET_ARTICLES_RECEIVED,
        payload: {
          articles: articleClipsAsMap,
          articlesQueryParams: newQueryParams,
        },
      });

      dispatch({
        type: UPDATE_DRILLDOWN_ARTICLES,
        payload: {
          analyticsIntegrationId:
            (newQueryParams && newQueryParams.analyticsIntegrationId) || '',
          articleIds,
          articlesTotalCount,
          authorsCount,
          pageMax,
          pageMaxNum,
          pageNext,
          pagePrevious,
          id: widgetId,
        },
      });
    })
    .catch(error => {
      if (!String(error).includes('400')) {
        dispatch({ type: GET_ARTICLES_ERROR });
        throw error;
      } else {
        // Shoe Kite message
        dispatch({ type: SHOW_KEYWORD_FILTER_ERROR });
        dispatch({ type: GET_ARTICLES_ERROR_BAD_REQUEST });

        // Showing 'No articles found.' in the list, and removing loader animation.
        dispatch({
          type: UPDATE_DRILLDOWN_ARTICLES,
          payload: {
            analyticsIntegrationId:
              (newQueryParams && newQueryParams.analyticsIntegrationId) || '',
            articleIds: [],
            articlesTotalCount: 0,
            authorsCount: 0,
            pageMax: 1,
            pageMaxNum: 1,
            pageNext: 1,
            pagePrevious: null,
            id: widgetId,
          },
        });
      }
    });
};

export const getArticlesByWidgetId = (
  widgetId,
  urlParams,
  skipLoadingState,
  widgetTypeOverride,
) => (dispatch, getState = () => ({})) => {
  const state = getState();

  if (!widgetId) {
    return;
  }

  let newQueryParams = {
    ...getApiQueryParamsFromList(currentArticleListSelector(state)),
    widgetId,
  };

  if (urlParams) {
    newQueryParams = {
      ...newQueryParams,
      ...getApiQueryParamsFromUrlParams(urlParams),
    };
  }

  if (!skipLoadingState) {
    dispatch({ type: GET_ARTICLES });
  }

  if (newQueryParams.domainId || newQueryParams.analyticsIntegrationId) {
    doGetArticlesByWidgetId(
      dispatch,
      widgetId,
      newQueryParams,
      widgetTypeOverride,
    );
  } else {
    let analyticsForeignId;
    let validAnalytics;
    let analyticsIntegrationType;
    let analyticsIntegration;
    let analyticsIntegrationId = get(
      state,
      'account.account.defaultAnalyticsIntegration.id',
    );

    if (!analyticsIntegrationId && window.appInfo) {
      if (
        window.appInfo.adobeAccounts &&
        window.appInfo.adobeAccounts.length > 0
      ) {
        validAnalytics = window.appInfo.adobeAccounts.find(acc => acc.enabled);
        analyticsIntegrationType = ANALYTICS_INTEGRATION_TYPES.adobeAnalytics;
      }

      if (
        !validAnalytics &&
        window.appInfo.gaAccounts &&
        window.appInfo.gaAccounts.length > 0
      ) {
        validAnalytics = window.appInfo.gaAccounts.find(acc => acc.enabled);
        analyticsIntegrationType = ANALYTICS_INTEGRATION_TYPES.googleAnalytics;
      }

      analyticsForeignId = validAnalytics ? validAnalytics.id : null;

      if (analyticsForeignId && window.appInfo.analyticsIntegrations) {
        analyticsIntegration = window.appInfo.analyticsIntegrations.find(
          ai =>
            ai.analyticsForeignId === analyticsForeignId &&
            ai.analyticsIntegrationType === analyticsIntegrationType.id,
        );
        if (analyticsIntegration) {
          analyticsIntegrationId = analyticsIntegration.analyticsId;
        }
      }
    }

    doGetArticlesByWidgetId(
      dispatch,
      widgetId,
      newQueryParams,
      widgetTypeOverride,
    );
  }
};

export const removeArticlesFromSearch = (
  articleIds,
  refreshArticles,
  search,
  reasonId,
  intl,
) => (dispatch, getState) => {
  const state = getState();
  const searchId = search || currentSearchIdSelector(state);
  const widgetId = currentDrilldownIdSelector(state);
  reasonId =
    reasonId ||
    REASONS_TO_DELETE_ARTICLES.find(r => r.value === 'unspecified').id;

  if (articleIds && articleIds.length && searchId) {
    dispatch({ type: REMOVE_ARTICLES_FROM_SEARCH, payload: { articleIds } });

    return performPost(ARTICLE_DELETE_ENDPOINT, {
      dashboardId: null,
      ids: articleIds,
      searchId,
      reasonId,
    })
      .then(() => {
        dispatch({
          type: REMOVE_ARTICLES_FROM_SEARCH_SUCCESS,
          payload: { articleIds },
        });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(globalMessages.deleteArticlesSuccess, {
              TOTAL_ARTICLES_COUNT: articleIds.length,
            }),
            status: 'success',
          }),
        );

        if (state.drilldowns && state.drilldowns.current) {
          dispatch({
            type: REMOVE_DRILLDOWN_ARTICLES,
            payload: { removeArticleIds: articleIds, id: widgetId },
          });
          if (refreshArticles) {
            dispatch(getArticlesByWidgetId(widgetId, {}, true));
          }
        }

        if (state.campaign.campaign && state.campaign.campaign.id) {
          dispatch({
            type: REMOVE_CAMPAIGN_ARTICLES,
            payload: { removeArticleIds: articleIds },
          });
        }

        if (
          state.stories &&
          state.stories.storyCoverage &&
          state.stories.storyCoverage.articleIds
        ) {
          dispatch({
            type: REMOVE_STORY_ARTICLES,
            payload: { removeArticleIds: articleIds },
          });
        }

        if (
          intersection(state.discovery.topContentArticleIds, articleIds).length
        ) {
          dispatch({
            type: REMOVE_DISCOVERY_ARTICLES,
            payload: { removeArticleIds: articleIds },
          });
        }

        if (state.articles.articles && state.articles.articles[articleIds]) {
          dispatch({
            type: REMOVE_DISCOVERY_ARTICLES_ALL_MENTIONS,
            payload: { removeArticleIds: articleIds },
          });
        }
      })
      .catch(err => {
        dispatch({
          type: REMOVE_ARTICLES_FROM_SEARCH_ERROR,
          payload: { articleIds },
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(globalMessages.deleteArticlesError, {
              TOTAL_ARTICLES_COUNT: articleIds.length,
            }),
            status: 'danger',
          }),
        );
        throw err;
      });
  }

  return null;
};

export const removeCurrentArticleFromSearch = intl => (dispatch, getState) => {
  const state = getState();
  const articleId = currentArticleIdSelector(state);
  const searchId = currentSearchIdSelector(state);

  if (articleId && searchId) {
    const articleIds = [articleId];
    dispatch({ type: REMOVE_ARTICLES_FROM_SEARCH, payload: { articleIds } });

    return performPost(ARTICLE_DELETE_ENDPOINT, {
      dashboardId: null,
      ids: articleIds,
      searchId,
    })
      .then(() => {
        dispatch({
          type: REMOVE_ARTICLES_FROM_SEARCH_SUCCESS,
          payload: { articleIds },
        });
        dispatch({ type: UNSET_SEARCH_DATA });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(
              articleMessages.removeArticleFromSearchSuccess,
            ),
            status: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch({
          type: REMOVE_ARTICLES_FROM_SEARCH_ERROR,
          payload: { articleIds },
        });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(
              articleMessages.removeArticleFromSearchError,
            ),
            status: 'danger',
          }),
        );
      });
  }

  return null;
};

export const revertCurrentArticleCustomClip = intl => (dispatch, getState) => {
  const state = getState();
  const articleId = currentArticleIdSelector(state);
  const article = currentArticleSelector(state);

  if (article) {
    dispatch({ type: SET_ARTICLE_CUSTOM_CLIP, payload: { articleId } });

    return performDelete(
      ARTICLE_CUSTOM_BROADCAST_CLIP,
      {},
      { id: article.customBroadcastId },
    )
      .then(() => {
        dispatch({
          type: SET_ARTICLE_CUSTOM_CLIP_SUCCESS,
          payload: {
            articleId,
            customBroadcastId: null,
            customBroadcastUrl: '',
            customBroadcastStatus: '',
          },
        });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.resetCustomClipSuccess),
            status: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch({ type: SET_ARTICLE_CUSTOM_CLIP_ERROR });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.resetCustomClipError),
            status: 'danger',
          }),
        );

        throw error;
      });
  }

  return null;
};

export const setCurrentArticleCustomClip = (clipUrl, intl) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const articleId = currentArticleIdSelector(state);
  const searchId = currentSearchIdSelector(state);

  if (articleId && searchId) {
    const postData = {
      articleId,
      clipUrl: getCacheBustedUrlForCM(clipUrl),
      searchId,
    };

    dispatch({ type: SET_ARTICLE_CUSTOM_CLIP, payload: { articleId } });
    dispatch({ type: GET_ARTICLES });
    return performPost(ARTICLE_CUSTOM_BROADCAST_CLIP, postData)
      .then(response => {
        const {
          articleId: respArticleId,
          clipUrl: respClipUrl,
          id,
        } = response.data;

        dispatch({
          type: SET_ARTICLE_CUSTOM_CLIP_SUCCESS,
          payload: {
            articleId: respArticleId,
            customBroadcastUrl: respClipUrl,
            customBroadcastId: id,
          },
        });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.createCustomClipSuccess),
            status: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch({ type: SET_ARTICLE_CUSTOM_CLIP_ERROR, payload: error });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.createCustomClipError),
            status: 'danger',
          }),
        );

        throw error;
      });
  }

  return null;
};

export const starArticle = (dashboardId, articleId, intl) => (
  dispatch,
  getState,
) => {
  const state = getState();

  if (articleId && dashboardId) {
    const postData = { articleId, dashboardId };
    const articleTypeLabel = currentArticleTypeLabelSelector(state);

    const intlArticleTypeLabel = getTranslatedSingularArticleType(
      articleTypeLabel,
      intl,
    ).toLowerCase();

    dispatch({ type: STAR_ARTICLE, payload: { articleId } });

    performPost(ARTICLE_STAR_ENDPOINT, postData)
      .then(() => {
        dispatch({ type: STAR_ARTICLE_SUCCESS, payload: { articleId } });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.starArticleSuccess, {
              ARTICLE_TYPE_LABEL: intlArticleTypeLabel,
            }),
            status: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch({ type: STAR_ARTICLE_ERROR, payload: { articleId } });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.starArticleError, {
              ARTICLE_TYPE_LABEL: intlArticleTypeLabel,
            }),
            status: 'danger',
          }),
        );
      });
  }
};

export const unstarArticle = (dashboardId, articleId, intl) => (
  dispatch,
  getState,
) => {
  const state = getState();

  if (articleId && dashboardId) {
    const postData = { articleId, dashboardId };
    const articleTypeLabel = currentArticleTypeLabelSelector(state);
    const intlArticleTypeLabel = getTranslatedSingularArticleType(
      articleTypeLabel,
      intl,
    ).toLowerCase();
    dispatch({ type: UNSTAR_ARTICLE, payload: { articleId } });

    performPost(ARTICLE_UNSTAR_ENDPOINT, postData)
      .then(() => {
        dispatch({ type: UNSTAR_ARTICLE_SUCCESS, payload: { articleId } });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.unStarArticleSuccess, {
              ARTICLE_TYPE_LABEL: intlArticleTypeLabel,
            }),
            status: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch({ type: UNSTAR_ARTICLE_ERROR, payload: { articleId } });

        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(articleMessages.unStarArticleError, {
              ARTICLE_TYPE_LABEL: intlArticleTypeLabel,
            }),
            status: 'danger',
          }),
        );
      });
  }
};

export const getArticlesByContactId = (
  aliases,
  contactId,
  endDate,
  publicationUrls,
  searchId,
  searchQuery,
  sort,
  startDate,
) => dispatch => {
  if (!contactId || !Array.isArray(aliases) || !publicationUrls) {
    return;
  }

  const urlParams = {
    startDate: moment.utc(startDate).startOf('day').valueOf(),
    endDate: moment.utc(endDate).endOf('day').valueOf(),
    searchId,
    sort,
  };

  const urlParamsString = encodeAndStringifyQueryParams(urlParams, {
    indices: false,
    addQueryPrefix: true,
  });

  const postParams = {
    publicationUrls,
    aliases,
    searchTerms: searchQuery,
  };

  dispatch({ type: GET_ARTICLES });

  // TODO: TA-13266 make this hit the same articles endpoint as getArticlesByWidgetId

  performPost(
    `${CONTACT_BASE_ENDPOINT}/${contactId}/articles/${urlParamsString}`,
    postParams,
  )
    .then(response => {
      const resultArticles = response.data || [];
      const { articleClipsAsMap, articleIds } = flattenArticles(resultArticles);

      dispatch({
        type: GET_ARTICLES_RECEIVED,
        payload: {
          articles: articleClipsAsMap,
        },
      });

      dispatch({
        type: UPDATE_CONTACT_ARTICLES,
        payload: {
          articleIds,
          contactId,
        },
      });
    })
    .catch(error => {
      dispatch({ type: GET_ARTICLES_ERROR });
      throw error;
    });
};

export const getArticlesBySearchId = (
  searchId,
  startDate,
  endDate,
  context,
) => (dispatch, getState) => {
  if (!searchId) {
    return;
  }
  const state = getState();
  const articleList = currentArticleListSelector(state);
  const articleListQueryParams = getApiQueryParamsFromList(articleList);

  // TODO: Once the article list is verified to be working with the new API,
  // articleListQueryParams.dataPoint should be removed entirely
  const data = {
    ...articleListQueryParams,
    dataPoint: undefined,
    startDate: startDate || moment.utc(articleList.startDate).valueOf(),
    endDate: endDate || moment.utc(articleList.endDate).valueOf(),
    detailed: context === ARTICLE_LIST_TYPES.DISCOVERY.TOP_CONTENT,
    removeDuplicates: context === ARTICLE_LIST_TYPES.DISCOVERY.TOP_CONTENT,
  };

  const urlParams = qs.stringify(data, {
    indices: false,
    addQueryPrefix: true,
  });

  dispatch({ type: GET_ARTICLES });
  dispatch({ type: SET_ARTICLE_LIST_LOADING, payload: { id: articleList.id } });

  performGet(`${SEARCH_LIST_ENDPOINT_V2}/${searchId}/article${urlParams}`)
    .then(response => {
      const responseData = response.data || {};
      const resultArticles = responseData.articleClips || [];
      const paging = responseData.paging || {};
      const totalArticleCount = parseInt(paging.totalNumResults, 10) || 0;
      const { articleClipsAsMap, articleIds } = flattenArticles(resultArticles);

      dispatch({
        type: GET_ARTICLES_RECEIVED,
        payload: {
          articles: articleClipsAsMap,
        },
      });

      switch (context) {
        case ARTICLE_LIST_TYPES.DEFAULT: {
          dispatch({
            type: SET_ARTICLE_LIST_ARTICLES,
            payload: {
              id: articleList.id,
              articleIds,
              totalArticleCount,
            },
          });
          break;
        }
        case ARTICLE_LIST_TYPES.CAMPAIGNS.DEFAULT: {
          dispatch({
            type: UPDATE_CAMPAIGN_ARTICLES,
            payload: {
              articleIds,
              totalArticleCount,
            },
          });
          break;
        }
        case ARTICLE_LIST_TYPES.DISCOVERY.TOP_CONTENT: {
          dispatch({
            type: SET_TOP_CONTENT,
            payload: {
              articleIds,
              totalArticleCount,
            },
          });

          const authorIds = resultArticles
            .map(article => article.authorId)
            .filter(a => !!a);
          dispatch(addContactImagePlaceholders(authorIds));

          const authors = authorIds.map(id => {
            return { id };
          });

          if (authors.length) {
            performPost(CONTACT_IMAGES_ENDPOINT, { authors, skipErrors: true })
              .then(imagesResponse => {
                dispatch(
                  addImagesToContacts(
                    imagesResponse.data ? imagesResponse.data : [],
                    authors,
                  ),
                );
              })
              .catch(() => {
                dispatch(addImagesToContacts([], authors));
              });
          } else {
            dispatch(addImagesToContacts([], authors));
          }
          break;
        }
        default:
      }
    })
    .catch(error => {
      if (!String(error).includes('400')) {
        dispatch({ type: GET_ARTICLES_ERROR });
        throw new Error(error);
      } else {
        dispatch({ type: SHOW_KEYWORD_FILTER_ERROR });
        dispatch({ type: GET_ARTICLES_ERROR_BAD_REQUEST });
        // Showing 'No articles found.' in the list, and removing loader animation.
        dispatch({
          type: SET_ARTICLE_LIST_ARTICLES,
          payload: {
            id: articleList.id,
            articleIds: [],
            totalArticleCount: 0,
          },
        });
      }
    });
};

export const getArticlesByStoryId = (storyId, customParams, listId) => (
  dispatch,
  getState,
) => {
  if (!storyId) {
    return;
  }

  const state = getState();
  const articleList = currentArticleListSelector(state);
  const queryParams = getApiQueryParamsFromList(articleList);
  const defaultAnalyticsIntegrationId = defaultAnalyticsIntegrationIdSelector(
    state,
  );
  const analyticsIntegrationId = !isNaN(
    parseInt(queryParams.analyticsIntegrationId, 10),
  )
    ? parseInt(queryParams.analyticsIntegrationId, 10)
    : defaultAnalyticsIntegrationId;

  let params = {
    analyticsIntegrationId,
    dateStart: moment.utc(articleList.startDate).startOf('day').valueOf(),
    dateEnd: moment.utc(articleList.endDate).endOf('day').valueOf(),
    pageNumber: queryParams.pageNum,
    queryFilter: queryParams.newSearchText,
    sort: queryParams.sort,
  };

  if (customParams) {
    params = {
      ...params,
      ...customParams,
    };
  }

  const queryParamsString = qs.stringify(
    {
      ...params,
    },
    {
      indices: false,
      addQueryPrefix: true,
    },
  );

  dispatch({ type: GET_ARTICLES });

  performGet(
    `${STORY_BASE_ENDPOINT}/${storyId}/coverage/articles${queryParamsString}`,
  )
    .then(response => {
      const responseData = response.data || {};
      const resultArticles = responseData.articleClips || [];
      const paging = responseData.paging || {};
      const totalArticleCount = parseInt(paging.totalNumResults, 10) || 0;

      const { articleClipsAsMap, articleIds } = flattenArticles(resultArticles);

      dispatch({
        type: GET_ARTICLES_RECEIVED,
        payload: {
          articles: articleClipsAsMap,
        },
      });

      dispatch({
        type: UPDATE_STORY_ARTICLES,
        payload: {
          articleIds,
          totalArticleCount,
        },
      });

      dispatch({
        type: SET_ARTICLE_LIST,
        payload: {
          id: listId || `story.${storyId}`,
          articleCount: totalArticleCount,
        },
      });
    })
    .catch(error => {
      dispatch({ type: GET_ARTICLES_ERROR });
      throw new Error(error);
    });
};

export const getTopArticlesByStoryId = storyId => dispatch => {
  if (!storyId) {
    return;
  }

  dispatch(
    getArticlesByStoryId(
      storyId,
      {
        sort: 'sort-impact-score-desc',
        pageSize: 4,
      },
      `story.${storyId}.top`,
    ),
  );
};

export const unsetCurrentArticle = () => dispatch => {
  dispatch({ type: UNSET_CURRENT_ARTICLE });
};

export const unsetCurrentArticleAndContact = () => dispatch => {
  dispatch({ type: UNSET_CURRENT_CONTACT });
  dispatch({ type: UNSET_CURRENT_ARTICLE });
};

export const removeTagsFromArticlesInListById = (tags, articleIds) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const updatedArticles = {};

  articleIds.forEach(articleId => {
    const article = articleByIdSelector(state)(articleId);

    if (article) {
      const newTags = article.tags.filter(tag => {
        return !some(tags, { id: tag.id });
      });

      updatedArticles[articleId] = {
        ...article,
        tags: newTags,
      };
    }
  });

  dispatch({
    type: REMOVE_TAGS_FROM_ARTICLES_BY_ID,
    payload: { updatedArticles },
  });
};

export const addTagsToArticlesInListById = (tags, articleIds) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const updatedArticles = {};
  const isSingleArticleTagging = articleIds.length === 1;

  articleIds.forEach(articleId => {
    const article = articleByIdSelector(state)(articleId);

    if (article && article.tags && !isSingleArticleTagging) {
      updatedArticles[articleId] = {
        ...article,
        tags: uniqBy(
          [
            ...(article.tags || []),
            ...tags
              .filter(t => t.articleId.toString() === articleId)
              .map(tag => ({
                articleId,
                id: tag.id,
                tag: tag.tag.tag,
                tagId: tag.tag.id,
                tagType: tag.type,
              })),
          ],
          'tag',
        ),
      };
    } else {
      updatedArticles[articleId] = {
        ...article,
        tags: uniqBy(
          [
            ...tags
              .filter(t => t.articleId.toString() === articleId)
              .map(tag => ({
                articleId,
                id: tag.id,
                tag: tag.tag.tag,
                tagId: tag.tag.id,
                tagType: tag.type,
              })),
          ],
          'tag',
        ),
      };
    }
  });

  dispatch({ type: ADD_TAGS_TO_ARTICLES_BY_ID, payload: { updatedArticles } });
};

export const setTagsOnArticlesInListById = (tags, articleIds) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const updatedArticles = {};

  articleIds.forEach(articleId => {
    const article = articleByIdSelector(state)(articleId);

    if (article) {
      updatedArticles[articleId] = {
        ...article,
        tags: uniqBy(
          [
            ...tags.map(tag => ({
              articleId,
              id: tag.id,
              tag: tag.tag.tag,
              tagId: tag.tag.id,
            })),
          ],
          'tag',
        ),
      };
    }
  });

  dispatch({ type: ADD_TAGS_TO_ARTICLES_BY_ID, payload: { updatedArticles } });
};

export const setAllArticlesSelection = selected => dispatch => {
  dispatch({ type: SET_ALL_ARTICLES_SELECTION, payload: { selected } });
};

export const setArticleSelection = (articleId, selected) => dispatch => {
  dispatch({ type: SET_ARTICLE_SELECTION, payload: { articleId, selected } });
};

export const setArticlesSelection = (articleIds, selected) => ({
  type: SET_ARTICLES_SELECTION,
  payload: {
    selected,
    articleIds,
  },
});

export const setDuplicateArticleGroupSelection = (articleId, selected) => (
  dispatch,
  getState,
) => {
  const state = getState();
  dispatch({
    type: SET_ARTICLES_SELECTION,
    payload: {
      selected,
      articleIds: state.articles.articles[articleId].duplicatePublications,
    },
  });
};

export const setDuplicateArticleItemSelection = (
  duplicateId,
  articleId,
  selected,
) => dispatch => {
  dispatch({
    type: SET_ARTICLE_SELECTION,
    payload: { selected, articleId: duplicateId },
  });
};

export const setArticleExpandedMetrics = (articleId, expanded) => dispatch => {
  dispatch({
    type: SET_ARTICLE_EXPANDED_METRICS,
    payload: { articleId, expanded },
  });
};

export const setArticleDuplicateExpandedMetrics = (
  duplicateId,
  articleId,
  expanded,
) => (dispatch, getState) => {
  const state = getState();
  const duplicatePublications = state.articles.articles[
    articleId
  ].duplicatePublications.map(duplicate => {
    if (duplicate.id === duplicateId) {
      duplicate.expandedMetrics = expanded;
    }
    return duplicate;
  });

  dispatch({
    type: SET_ARTICLE_DUPLICATE_EXPANDED_METRICS,
    payload: { articleId, duplicatePublications },
  });
};

export const bulkExpandArticles = (
  expanded,
  articles,
  ignoreSelection,
) => dispatch => {
  articles.forEach(article => {
    if (article.selected || ignoreSelection) {
      dispatch(setArticleExpandedMetrics(article.id, expanded));
    }
    if (
      article.duplicatePublications &&
      article.duplicatePublications.length > 0
    ) {
      article.duplicatePublications.forEach(dupe => {
        if (dupe.selected || ignoreSelection) {
          dispatch(
            setArticleDuplicateExpandedMetrics(dupe.id, article.id, expanded),
          );
        }
      });
    }
  });
};

export default articlesReducer;
