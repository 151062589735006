import { createSelector } from 'reselect';

import { CAMPAIGN_WIZARD_STEPS, FEATURES } from 'constants/constants';
import {
  hasDevFeatureFlagSelector,
  hasFeatureFlagSelector,
} from 'selectors/account';

const stepsSelector = state => state.campaignWizard.steps;
const currentStepIndex = state => state.campaignWizard.currentStepIndex;
const selectedSelector = state => state.campaignWizard.selected;
const primarySearchSelector = state => state.campaignWizard.primarySearch;

export const selectedIdsSelector = createSelector(
  primarySearchSelector,
  selectedSelector,
  (primarySearch, selected) => {
    if (!primarySearch) {
      return [];
    }

    return Object.keys(selected).filter(
      id => selected[id].type !== 'primarySearch' && id !== primarySearch.id,
    );
  },
);

export const selectedEntriesSelector = createSelector(
  selectedIdsSelector,
  selectedSelector,
  (ids, selected) => {
    const results = {};
    ids.forEach(id => {
      if (id in selected) results[id] = selected[id];
    });

    return results;
  },
);

export const campaignWizardStepsSelector = createSelector(
  [hasDevFeatureFlagSelector, hasFeatureFlagSelector],
  (hasDevFeatureFlag, hasFeatureFlag) => {
    let featureEnabled;
    let devFeatureEnabled;
    let excludedFeatureEnabled;

    return CAMPAIGN_WIZARD_STEPS.filter(step => {
      excludedFeatureEnabled = false;
      featureEnabled = true;
      devFeatureEnabled = true;

      if (step.excludedFeatures) {
        excludedFeatureEnabled = step.excludedFeatures.some(featureFeature =>
          hasFeatureFlag(featureFeature),
        );
      }
      if (step.requiredFeatures) {
        featureEnabled = step.requiredFeatures.every(featureFeature =>
          hasFeatureFlag(featureFeature),
        );
      }
      if (step.requiredDevFeatures) {
        devFeatureEnabled = step.requiredDevFeatures.every(devFeature =>
          hasDevFeatureFlag(devFeature),
        );
      }
      return !excludedFeatureEnabled && featureEnabled && devFeatureEnabled;
    });
  },
);

export const hasNextStepSelector = createSelector(
  [stepsSelector, currentStepIndex],
  (steps, stepIndex) => {
    return stepIndex < steps.length - 1;
  },
);

export const currentStepSelector = createSelector(
  [stepsSelector, currentStepIndex],
  (steps, stepIndex) =>
    stepIndex >= steps.length || stepIndex < 0 ? steps[0] : steps[stepIndex],
);

export const previousStepSelector = state =>
  createSelector(
    [stepsSelector, currentStepIndex, hasFeatureFlagSelector],
    (steps, stepIndex, hasFeature) => {
      const hasContacts = hasFeature(FEATURES.pinpointContacts);
      let prevStep;
      if (stepIndex === 0) {
        prevStep = steps[0];
      } else if (!hasContacts && steps[stepIndex - 1].id === 'addContacts') {
        prevStep = steps[2];
      } else {
        prevStep = steps[stepIndex - 1];
      }
      return prevStep;
    },
  )(state);
