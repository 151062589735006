import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import {
  hasDevFeatureFlagSelector,
  hasAccountDevFeatureFlagSelector,
} from 'selectors/account';

import styles from './DevFeatureLocalOverrides.module.css';
import {
  getDevFeatureLocalOverridesActivated,
  getDevFeatureLocalOverrides,
  addDevFeatureLocalOverride,
  removeDevFeatureLocalOverride,
  deinitDevFeatureLocalOverrides,
} from './service';

const Status = ({ emoji, label }) => (
  <>
    {emoji} {label}
  </>
);

const DevFeatureLocalOverrides = () => {
  const activated = getDevFeatureLocalOverridesActivated();

  const [listOpen, setListOpen] = useState(false);
  const [newFlagToAdd, setNewFlagToAdd] = useState('');
  const hasDevFeatureFlag = useSelector(hasDevFeatureFlagSelector);
  const hasAccountDevFeatureFlag = useSelector(
    hasAccountDevFeatureFlagSelector,
  );

  if (!activated) {
    return null;
  }

  const devFeatureLocalOverrides = getDevFeatureLocalOverrides();
  const numOverrides = devFeatureLocalOverrides?.length || 0;

  const reload = () => {
    window.location.reload();
  };

  const toggleList = () => {
    setListOpen(prevState => !prevState);
  };
  const handleToggleClick = () => {
    toggleList();
  };
  const handleToggleKeypress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Space') {
      toggleList();
    }
  };

  const handleDeinitClick = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Dismantle local overrides?')) {
      deinitDevFeatureLocalOverrides();
      reload();
    }
  };

  const submit = event => {
    if (newFlagToAdd) {
      addDevFeatureLocalOverride(newFlagToAdd);
      setNewFlagToAdd('');
    }
    event.preventDefault();
    reload();
  };

  return (
    <div className={styles.devFeatureLocalOverrides}>
      <div
        data-qa="jNGLwNmLCKvUkWmsUsbTx"
        className={styles.toggle}
        onClick={handleToggleClick}
        onKeyDown={handleToggleKeypress}
        role="button"
        tabIndex={-1}
      >
        {numOverrides} BROWSER DEV-FF OVERRIDE{numOverrides !== 1 && 'S'} 🤠
      </div>
      {listOpen && (
        <div className={styles.list}>
          {devFeatureLocalOverrides?.map(name => (
            <div className={styles.item} key={name}>
              <div className={styles.name}>{name}</div>
              <div className="t-flex">
                <div className="t-mr-4 t-flex-1">
                  {hasDevFeatureFlag(name) ? (
                    hasAccountDevFeatureFlag(name) ? (
                      <Status emoji="🌎" label="account-wide" />
                    ) : (
                      <Status emoji="💻" label="this browser only" />
                    )
                  ) : (
                    <Status emoji="⏳" label="reloading" />
                  )}
                </div>
                <button
                  data-qa="rDyBwXPiFKoAG4M2Ut8_c"
                  className="t-ml-2"
                  onClick={() => {
                    removeDevFeatureLocalOverride(name);
                    reload();
                  }}
                  type="button"
                >
                  Delete Local Override & Refresh
                </button>
              </div>
            </div>
          ))}
          <div>
            <form onSubmit={submit} className="t-mt-2">
              <input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                className={styles.input}
                onChange={event => {
                  setNewFlagToAdd(event.target.value);
                }}
                placeholder="Enter exact dev-ff to enable"
                type="text"
                value={newFlagToAdd}
              />
              <button type="submit">Add Browser Override</button>
            </form>
          </div>
          <div>
            <button
              className={styles.deinit}
              data-qa="4L-zjuhLpzzl8X98sLwUc"
              onClick={handleDeinitClick}
              type="button"
            >
              Dismantle Local Overrides
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DevFeatureLocalOverrides;
