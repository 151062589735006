import {
  any,
  compact,
  complement,
  compose,
  differenceBy,
  filter,
  first,
  get,
  isEmpty,
  isNil,
  map,
  reject,
  size,
  some,
  unapply,
} from 'lodash/fp';
import { RootStateOrAny } from 'react-redux';
import { createSelector } from 'reselect';

import { DEV_FEATURES, IHUB_ADVANCED_SEARCH_TYPES } from 'constants/constants';
import { hasDevFeatureFlagSelector } from 'selectors/account';
import { getCanUseInfluencersSearchHomeBeta } from 'selectors/canUse/canUse';

import { UNSUBSCRIBED } from '../InfluencerList/components/SearchResultsFilters/ContactAttributesPanel/constants';
import mockInfluencers from '../mockData/influencers';

import { SEARCH_CRITERIA_NAMES } from './constants';

const notEmpty = complement(isEmpty);

const someNotEmpty = path => some(compose(notEmpty, get(path)));
const someLoading = any(get('loading'));

const availableSuggestedPills = (searchFilter, pillSectionFilter) => ({
  ...pillSectionFilter,
  data: compose(
    filter(({ data }) => notEmpty(data)),
    map(({ title, data }) => ({
      title,
      data: differenceBy('id', data, searchFilter.chosen),
    })),
  )(pillSectionFilter.data),
});

// Generically add new filters to the correct searchCriteria section by type
const getNextSearchCriteria = ({
  prevSearchCriteria,
  sectionKey,
  sectionFilters,
}) => {
  const prevSelectedCount = prevSearchCriteria?.[sectionKey]?.length ?? 0;
  const sectionFiltersData = sectionFilters?.data ?? [];

  const selectedFilters = sectionFiltersData
    .filter(filter => filter.selected)
    .map((filter, index) => ({
      booleanCondition: index === 0 ? 'FILTER' : 'OR',
      criteriaOrder: prevSelectedCount + index + 1,
      /*
        TODO:
        Remove `locationType` requirement from ihub API, then stop setting it here.
        The prop is required because of an old version of the ihub API contract,
        but it has no effect (on location or other filter types).
      */
      locationType: 'city',
      searchId: filter.id.toString(),
      searchString: filter.name,
    }));

  return selectedFilters.length
    ? {
        ...prevSearchCriteria,
        [sectionKey]: [...prevSearchCriteria[sectionKey], ...selectedFilters],
      }
    : prevSearchCriteria;
};

export const isFreeTextInputModeSelector = state =>
  state.influencerSearch.pageState.freeTextInputMode;
export const topicsSelector = state => state.influencerSearch.pageState.topics;
export const locationsSelector = state =>
  state.influencerSearch.pageState.locations;
export const outletsSelector = state =>
  state.influencerSearch.pageState.outlets;
export const searchCriteriaSelector = state =>
  state.influencerSearch.pageState.searchCriteria;
export const currentPageNumberSelector = state =>
  state.influencerSearch.pageState.searchCriteria.pagenumber;
export const chosenTopicsSelector = state =>
  state.influencerSearch.pageState.topics.chosen;
export const chosenLocationsSelector = state =>
  state.influencerSearch.pageState.locations.chosen;
export const chosenOutletsSelector = state =>
  state.influencerSearch.pageState.outlets.chosen;
export const contactSelector = state =>
  state.influencerSearch.pageState.searchCriteria.contact;
export const hasFreeTextSearchMinLengthSelector = state =>
  state.influencerSearch.pageState.freeTextSearch.length > 2;
export const selectedListIdSelector = state =>
  state.influencerSearch.pageState.selectedListId;
export const selectedListSelector = state => state.influencerLists.selectedList;
export const isRenamingList = state => state.influencerLists.isRenaming;
export const unsubscribedInfluencersSelector = state =>
  state.influencerSearch.pageState.searchCriteria.unsubscribed;
export const selectedContactAttributesIdsSelector = state =>
  state.influencerSearch.pageState.contactAttributesIds;

export const topicsSearchTextSelector = createSelector(
  topicsSelector,
  topics => topics.searchText,
);

export const isSearchDisabledSelector = state => {
  const selectedListId = selectedListIdSelector(state);
  const canUseSearchHomeBeta = getCanUseInfluencersSearchHomeBeta();
  const shouldIgnoreSearchDisabled = !(selectedListId === null);

  if (shouldIgnoreSearchDisabled) {
    return false;
  }

  const hasLocationCriteria =
    state.influencerSearch.pageState.locations.chosen.length > 0;

  const hasOutletCriteria =
    state.influencerSearch.pageState.outlets.chosen.length > 0;

  const hasFreeTextSearchMinLength = hasFreeTextSearchMinLengthSelector(state);

  const hasTopicCriteria =
    state.influencerSearch.pageState.topics.chosen.length > 0;

  const hasMediaTypeCriteria =
    canUseSearchHomeBeta &&
    state.influencerSearch.pageState.searchCriteria.mediaType.length > 0;

  const hasJobRoleCriteria =
    canUseSearchHomeBeta &&
    state.influencerSearch.searchResults.pillSectionFilterJobRoles.data.length >
      0;

  return (
    !hasLocationCriteria &&
    !hasOutletCriteria &&
    !hasTopicCriteria &&
    !hasMediaTypeCriteria &&
    !hasJobRoleCriteria &&
    !hasFreeTextSearchMinLength
  );
};

const hasBaseChosenFiltersSelector = createSelector(
  topicsSelector,
  locationsSelector,
  outletsSelector,
  unapply(someNotEmpty('chosen')),
);

const hasBetaChosenFiltersSelector = state => {
  const canUseSearchHomeBeta = getCanUseInfluencersSearchHomeBeta();
  if (!canUseSearchHomeBeta) return false;

  const hasMediaTypeCriteria =
    state.influencerSearch.pageState.searchCriteria.mediaType.length > 0;
  const jobRoleCriteria = state.influencerSearch.searchResults.pillSectionFilterJobRoles.data[0]?.data?.filter(
    d => d.selected,
  );

  const hasJobRoleCriteria = jobRoleCriteria?.length > 0 ?? false;

  return hasMediaTypeCriteria || hasJobRoleCriteria;
};

export const hasChosenFiltersSelector = createSelector(
  hasBaseChosenFiltersSelector,
  hasBetaChosenFiltersSelector,
  (base, beta) => base || beta,
);

export const topicSuggestionsSelector = state =>
  state.influencerSearch.searchResults.topics;
export const locationSuggestionsSelector = state =>
  state.influencerSearch.searchResults.locations;
export const influencerSuggestionsSelector = state =>
  state.influencerSearch.searchResults.influencers;
export const privateInfluencerMatchesSelector = state =>
  state.influencerSearch.searchResults.privateInfluencerMatches;
export const outletSuggestionsSelector = state =>
  state.influencerSearch.searchResults.outlets;
export const searchTotalResultsSelector = state =>
  state.influencerSearch.searchResults.totalResults;
export const influencerSearchSelector = state => state.influencerSearch || {};
export const pillSectionFilterLocationsSelector = state =>
  state.influencerSearch.searchResults.pillSectionFilterLocations;
export const pillSectionFilterOutletsSelector = state =>
  state.influencerSearch.searchResults.pillSectionFilterOutlets;
export const pillSectionFilterTopicsSelector = state =>
  state.influencerSearch.searchResults.pillSectionFilterTopics;
export const pillSectionFilterLanguagesSelector = state =>
  state.influencerSearch.searchResults.pillSectionFilterLanguages;
export const locationSelectorfromSearchCriteria = state =>
  state.influencerSearch.pageState.searchCriteria.language;

export const influencerSearchResultsSelector = createSelector(
  influencerSearchSelector,
  search => search.searchResults || {},
);

export const getSearchPageSelector = sectionName =>
  createSelector(
    influencerSearchResultsSelector,
    searchResults => searchResults?.[sectionName]?.searchPage,
  );

export const influencerSearchInfluencersLoadingSelector = createSelector(
  influencerSearchResultsSelector,
  searchResults => !!searchResults.influencers?.loading,
);

export const influencerSearchInfluencersSelector = createSelector(
  influencerSearchResultsSelector,
  hasDevFeatureFlagSelector,
  (results, hasDevFeatureFlag) => {
    try {
      return (
        [
          ...(hasDevFeatureFlag(DEV_FEATURES.influencerMockData)
            ? mockInfluencers
            : []),
          ...results.influencers.data,
        ] || []
      );
    } catch (e) {
      return [];
    }
  },
);

const influencerSearchPagination = createSelector(
  influencerSearchResultsSelector,
  searchResults =>
    (searchResults.influencers && searchResults.influencers.pagination) || {},
);

export const influencerSearchCurrentPageNumber = createSelector(
  influencerSearchPagination,
  pagination => pagination.currentPageNumber || 1,
);

export const influencerSearchTotalHits = createSelector(
  influencerSearchPagination,
  pagination => pagination.totalHits || 0,
);

export const topicSuggestedPillsSelector = createSelector(
  topicsSelector,
  state => state.influencerSearch.searchResults.pillSectionTopics,
  availableSuggestedPills,
);

export const topicSearchResultFilterPillsSelector = createSelector(
  topicsSelector,
  pillSectionFilterTopicsSelector,
  availableSuggestedPills,
);
export const locationSuggestedPillsSelector = createSelector(
  locationsSelector,
  state => state.influencerSearch.searchResults.pillSectionLocations,
  availableSuggestedPills,
);
export const locationSearchResultFilterPillsSelector = createSelector(
  locationsSelector,
  pillSectionFilterLocationsSelector,
  availableSuggestedPills,
);
export const outletSuggestedPillsSelector = createSelector(
  outletsSelector,
  state => state.influencerSearch.searchResults.pillSectionOutlets,
  availableSuggestedPills,
);
export const outletSearchResultFilterPillsSelector = createSelector(
  outletsSelector,
  pillSectionFilterOutletsSelector,
  availableSuggestedPills,
);
export const jobRoleSearchResultFilterPillsSelector = createSelector(
  () => ({
    chosen: [],
  }),
  (state: RootStateOrAny) =>
    state.influencerSearch.searchResults.pillSectionFilterJobRoles,
  availableSuggestedPills,
);

export const languageSearchResultFilterPillsSelector = createSelector(
  () => ({
    chosen: [],
  }),
  (state: RootStateOrAny) => pillSectionFilterLanguagesSelector(state),
  availableSuggestedPills,
);

export const hasSuggestedPillsSelector = createSelector(
  topicSuggestedPillsSelector,
  locationSuggestedPillsSelector,
  outletSuggestedPillsSelector,
  unapply(someNotEmpty('data')),
);

export const searchIsLoadingSelector = createSelector(
  topicSuggestionsSelector,
  locationSuggestionsSelector,
  influencerSuggestionsSelector,
  outletSuggestionsSelector,
  unapply(someLoading),
);

export const getSelectedReferenceIdsSelector = createSelector(
  searchCriteriaSelector,
  searchCriteria => referenceType =>
    map(
      'searchId',
      searchCriteria[
        IHUB_ADVANCED_SEARCH_TYPES[referenceType].searchCriteriaPropertyName
      ],
    ),
);

export const searchResultsAggSelector = get(
  'influencerSearch.searchResults.aggregations',
);

const getAggBucketName = aggName => state =>
  compose(map('key'), get(`${aggName}.buckets`))(state);

export const availableMediaTypesSelector = createSelector(
  searchResultsAggSelector,
  getAggBucketName('mediaTypes'),
);

export const availableNewsFocusesSelector = createSelector(
  searchResultsAggSelector,
  getAggBucketName('newsFocuses'),
);

export const availableOutletFrequenciesSelector = createSelector(
  searchResultsAggSelector,
  getAggBucketName('frequencies'),
);

export const availableAudienceTypesSelector = createSelector(
  searchResultsAggSelector,
  getAggBucketName('audienceTypes'),
);

export const availableInfluencersCountSelector = createSelector(
  (state: RootStateOrAny) => state.influencerSearch.searchResults,
  ({ totalJournalists, totalOutlets, totalSocials }) => ({
    journalist: totalJournalists,
    outlet: totalOutlets,
    social: totalSocials,
  }),
);

export const fullSearchCriteriaSelector = createSelector(
  searchCriteriaSelector,
  influencerSearchResultsSelector,
  (criteria, searchResults) => {
    let searchCriteria = criteria;

    const topicFilters = first(searchResults.pillSectionFilterTopics.data);
    const languageFilters =
      first(searchResults?.pillSectionFilterLanguages?.data) || [];
    const locationFilters = first(
      searchResults.pillSectionFilterLocations.data,
    );
    const outletFilters = first(searchResults.pillSectionFilterOutlets.data);
    const jobRoleFilters = first(searchResults.pillSectionFilterJobRoles.data);

    searchCriteria = getNextSearchCriteria({
      prevSearchCriteria: searchCriteria,
      sectionKey: SEARCH_CRITERIA_NAMES.topic,
      sectionFilters: topicFilters,
    });

    searchCriteria = getNextSearchCriteria({
      prevSearchCriteria: searchCriteria,
      sectionFilters: languageFilters,
      sectionKey: SEARCH_CRITERIA_NAMES.language,
    });

    searchCriteria = getNextSearchCriteria({
      prevSearchCriteria: searchCriteria,
      sectionKey: SEARCH_CRITERIA_NAMES.location,
      sectionFilters: locationFilters,
    });

    searchCriteria = getNextSearchCriteria({
      prevSearchCriteria: searchCriteria,
      sectionKey: SEARCH_CRITERIA_NAMES.outlet,
      sectionFilters: outletFilters,
    });

    searchCriteria = getNextSearchCriteria({
      prevSearchCriteria: searchCriteria,
      sectionKey: SEARCH_CRITERIA_NAMES.jobRole,
      sectionFilters: jobRoleFilters,
    });

    return searchCriteria;
  },
);

export const getCriteriaByTypeSelector = createSelector(
  searchCriteriaSelector,
  searchCriteria => type => searchCriteria?.[type] ?? [],
);

export const getSearchCriteriaByFilter = (filter, propertyToFind) =>
  createSelector(
    searchCriteriaSelector,
    searchCriteria =>
      searchCriteria?.[filter].map(item => item[propertyToFind])[0] || '',
  );

const SEARCH_STRING = 'searchString';
export const getTalkingAboutSearch =
  getSearchCriteriaByFilter(
    SEARCH_CRITERIA_NAMES.talkingAbout,
    SEARCH_STRING,
  ) || '';

export const getTopicSearch =
  getSearchCriteriaByFilter(SEARCH_CRITERIA_NAMES.topic, SEARCH_STRING) || '';

export const getCriteriaIdsByTypeSelector = createSelector(
  getCriteriaByTypeSelector,
  getCriteriaByType => {
    const getCriteriaIdsByType = type => {
      const locationCriteria = getCriteriaByType(type);
      return locationCriteria.map(criterion => criterion.searchId);
    };

    return getCriteriaIdsByType;
  },
);

export const filterAdvancedFilters = createSelector(
  searchCriteriaSelector,
  ({ influencerType, mediaType, frequency, newsFocus }) => ({
    influencerType,
    mediaType,
    frequency,
    newsFocus,
  }),
);

export const countAdvancedFilters = ({
  influencerType,
  mediaType,
  frequency,
  newsFocus,
}) =>
  compose(
    size,
    reject(criteria => isNil(criteria) || isEmpty(criteria)),
  )([influencerType, mediaType, frequency, newsFocus]);

export const advancedFiltersSelector = createSelector(
  filterAdvancedFilters,
  countAdvancedFilters,
);

export const countFilters = ({
  articleReach,
  audienceType,
  frequency,
  influencerType,
  jobRole,
  language,
  location,
  mediaType,
  newsFocus,
  outlet,
  socialReach,
  topic,
  talkingAbout,
  unsubscribed,
}) => {
  const filters = [
    audienceType,
    frequency,
    influencerType,
    jobRole,
    language,
    location,
    mediaType,
    newsFocus,
    outlet,
    topic,
    talkingAbout,
    compact([articleReach, socialReach]),
  ];

  if (isEmpty(unsubscribed)) {
    filters.push(UNSUBSCRIBED);
  }

  const nonEmptyFilters = reject(
    criteria => isNil(criteria) || isEmpty(criteria),
    filters,
  );

  return size(nonEmptyFilters);
};

export const filtersCountSelector = createSelector(
  fullSearchCriteriaSelector,
  countFilters,
);

export const influencerCriteriaSelector = createSelector(
  searchCriteriaSelector,
  ({ influencerType }) => influencerType,
);

export const isInfluencerOnlySearchSelector = createSelector(
  influencerCriteriaSelector,
  influencerType => {
    if (!influencerType.length) return false;
    return !influencerType.some(
      ({ searchString }) => searchString === 'Outlet',
    );
  },
);

export const isOutletOnlySearchSelector = createSelector(
  influencerCriteriaSelector,
  influencerType => {
    if (!influencerType.length) return false;
    return !influencerType.some(
      ({ searchString }) =>
        searchString === 'Journalist' || searchString === 'Social',
    );
  },
);
