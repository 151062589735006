/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { withPadding, ComponentWithStyle } from '../withPadding';
import { ClassValue } from 'classnames/types';

interface FooterProps extends ComponentWithStyle {
  className?: ClassValue;
  tag?: React.ElementType;
  style?: React.CSSProperties;
  justifyActions?: 'right' | 'left' | 'spread';
}

export type FooterType = React.FC<FooterProps>;

const ModalFooter: FooterType = ({
  className = null,
  tag: Tag = 'div',
  justifyActions = 'right',
  ...attributes
}) => {
  const classes = classNames(className, 'tk-modal__footer', {
    'tk-modal__footer--justify-left': justifyActions === 'left',
    'tk-modal__footer--justify-spread': justifyActions === 'spread',
  });

  return <Tag {...attributes} className={classes} />;
};

export default withPadding(ModalFooter, { padding: '10px' });
