export default {
  adminAccountTitle: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_TITLE',
    defaultMessage: 'Add Account',
    description:
      'Admin > Account Management > Add Account: Admin Add Account Title',
  },
  adminAccountNameLabel: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_NAME_LABEL',
    defaultMessage: 'New Account Name',
    description:
      'Admin > Account Management > Add Account: Admin Account Name Label',
  },
  adminAccountGlobalCustomerIdLabel: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_GLOBAL_CUSTOMER_ID_LABEL',
    defaultMessage: 'Global Customer ID',
    description:
      'Admin > Account Management > Add Account: Admin Global Customer ID Label',
  },
  adminAccountTypeLabel: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_TYPE_LABEL',
    defaultMessage: 'Account Type',
    description:
      'Admin > Account Management > Add Account: Admin Account Type Label',
  },
  adminAccountSalesforceInstanceLabel: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_SALESFORCE_INSTANCE_LABEL',
    defaultMessage: 'Salesforce Instance',
    description:
      'Admin > Account Management > Add Account: Admin Salesforce Instance Label',
  },
  adminAccountAddButton: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_ADD_BUTTON',
    defaultMessage: 'Add Button',
    description: 'Admin > Account Management > Add Account: Add Button',
  },
  adminAccountErrorMsg: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_ERROR_MESSAGE_START',
    defaultMessage: 'Please Select One',
    description: 'Admin > Account Management > Add Account: Error Mesage start',
  },
  adminAccountSuccessCreatedMessage: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_SUCCESS_MESSAGE_CREATING',
    defaultMessage: 'Success! You have added account {ACCOUNT_NAME}.',
    description:
      'Admin > Account Management > Add Account: Account Was Created Message',
  },
  adminAccountErrorCreatedMessage: {
    id: 'ADMIN_ACCOUNT_MANAGEMENT_ADD_ACCOUNT_ERROR_MESSAGE_CREATING',
    defaultMessage: 'An error occurred creating the account.',
    description:
      'Admin > Account Management > Add Account: Error Occuer while Creating Account Message',
  },
};
