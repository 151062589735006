import { actions } from 'react-redux-form';

import { defaultTemplate } from '@trendkite/stories';

const initialState = {
  ...defaultTemplate.defaultProps.defaultContent.pressContact,
};

const storyPressContactFormReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const loadStoryPressContactFormActionCreator = story => dispatch => {
  const pressContact =
    story && story.content && story.content.pressContact
      ? story.content.pressContact
      : {};
  dispatch(actions.load('storyPressContactForm', { ...pressContact }));
};

export const resetStoryPressContactFormActionCreator = () => dispatch => {
  dispatch(actions.reset('storyPressContactForm'));
};

export default storyPressContactFormReducer;
