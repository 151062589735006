import capitalize from 'lodash/capitalize';
import forEach from 'lodash/forEach';

const processDemographicsToArray = (data, omitKeys = []) => {
  if (!data) return [];
  const newArray = [];
  forEach(data, (value, key) => {
    if (omitKeys.indexOf(key) >= 0) return;
    newArray.push({
      label: capitalize(key),
      percent: value * 100,
      value,
      key,
    });
  });
  return newArray;
};

export default processDemographicsToArray;
