export default {
  // IHUB_ADVANCED_SEARCH_TYPES
  constantsIhubAdvancedSearchTypesInfluencerTypes: {
    id: 'CONSTANTS_IHUB_ADVANCED_SEARCH_TYPES_INFLUENCER_TYPE',
    description:
      'Contacts Beta > Search Results: Left side Filter Contact types',
    defaultMessage: 'Contact type',
  },
  constantsIhubAdvancedSearchTypesMediaTypes: {
    id: 'CONSTANTS_IHUB_ADVANCED_SEARCH_TYPES_MEDIA_TYPES',
    description: 'Contacts Beta > Search Results: Left side Filter Media types',
    defaultMessage: 'Media type',
  },
  constantsIhubAdvancedSearchTypesFrequencies: {
    id: 'CONSTANTS_IHUB_ADVANCED_SEARCH_TYPES_FREQUENCIES',
    description:
      'Contacts Beta > Search Results: Left side Filter Outlet frequency',
    defaultMessage: 'Outlet frequency',
  },
  constantsIhubAdvancedSearchTypesNewsFocus: {
    id: 'CONSTANTS_IHUB_ADVANCED_SEARCH_TYPES_NEWS_FOCUS',
    description: 'Contacts Beta > Search Results: Left side Filter News focus',
    defaultMessage: 'News focus',
  },
  constantsIhubAdvancedSearchTypesAudienceTypes: {
    id: 'CONSTANTS_IHUB_ADVANCED_SEARCH_TYPES_AUDIENCE_TYPES',
    description:
      'Contacts Beta > Search Results: Left side Filter Audience types',
    defaultMessage: 'Audience type',
  },
  constantsIhubAdvancedSearchTypesContactAttributes: {
    id: 'CONSTANTS_IHUB_ADVANCED_SEARCH_TYPES_CONTACT_ATTRIBUTES',
    description:
      'Contacts Beta > Search Results: Left side Filter Audience types',
    defaultMessage: 'Contact Attributes',
  },

  // IHUB_SORT_BY_TYPES
  constantsIhubSortByTypesRelevance: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_RELEVANCE',
    description: 'Contacts Beta > Search Results: Sort by Option Relevance',
    defaultMessage: 'Relevance',
  },
  constantsIhubSortByTypesLastUpdateDate: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_LAST_UPDATE_DATE',
    description:
      'Contacts Beta > Search Results: Sort by Option Last Update Date',
    defaultMessage: 'Last Update Date',
  },
  constantsIhubSortByTypesDateCreated: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_DATE_CREATED',
    description: 'Contacts Beta > Search Results: Sort by Option Date Created',
    defaultMessage: 'Date Created',
  },
  constantsIhubSortByTypesName: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_NAME',
    description: 'Contacts Beta > Search Results: Sort by Option Name',
    defaultMessage: 'Name',
  },
  constantsIhubSortByTypesArticleReach: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_ARTICLE_REACH',
    description: 'Contacts Beta > Search Results: Sort by Option Article Reach',
    defaultMessage: 'Article Reach',
  },
  constantsIhubSortByTypesSocialReach: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_SOCIAL_REACH',
    description: 'Contacts Beta > Search Results: Sort by Option Social Reach',
    defaultMessage: 'Social Reach',
  },
  constantsIhubSortByTypesEngagement: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_ENGAGEMENT',
    description:
      'Contacts Beta > Search Results: Sort by Option Engagement Score',
    defaultMessage: 'Engagement Score',
  },
  constantsIhubSortByTypesActivity: {
    id: 'CONSTANTS_IHUB_SEARCH_SORT_BY_TYPES_ACTIVITY',
    description:
      'Contacts Beta > Search Results: Sort by Option Weekly Activity',
    defaultMessage: 'Weekly Activity',
  },

  //IHUB_SAVED_LIST_SHARE_PERMISSIONS
  constantsIhubListSharePermissionPrivate: {
    id: 'CONSTANTS_IHUB_SHARE_LIST_PERMISSION_PRIVATE',
    description:
      'Contacts Beta > Saved Lists: Share List Permission Option Private',
    defaultMessage: 'Private (only visible to you)',
  },
  constantsIhubListSharePermissionViewOnly: {
    id: 'CONSTANTS_IHUB_SHARE_LIST_PERMISSION_VIEW_ONLY',
    description:
      'Contacts Beta > Saved Lists: Share List Permission Option View Only',
    defaultMessage: 'View Only (can only view list, no editing allowed)',
  },
  constantsIhubListSharePermissionFull: {
    id: 'CONSTANTS_IHUB_SHARE_LIST_PERMISSION_FULL_ACCESS',
    description:
      'Contacts Beta > Saved Lists: Share List Permission Option Full Access',
    defaultMessage: 'Full Access (can add, edit and delete)',
  },

  //STORY_KIT_SHARE_PERMISSIONS
  constantsStoryKitSharePermissionPrivate: {
    id: 'CONSTANTS_STORY_KIT_PERMISSION_PRIVATE',
    description:
      'Campaigns > Story kit: Share Story Kit Permission Option Private',
    defaultMessage: 'Private (Only visible to you)',
  },
  constantsStoryKitSharePermissionViewOnly: {
    id: 'CONSTANTS_STORY_KIT_PERMISSION_VIEW_ONLY',
    description:
      'Campaigns > Story kit: Share Story Kit Permission Option View Only',
    defaultMessage: 'View Only (Can only view, no editing allowed)',
  },
  constantsStoryKitSharePermissionFull: {
    id: 'CONSTANTS_STORY_KIT_PERMISSION_FULL_ACCESS',
    description:
      'Contacts Beta > Saved Lists: Share List Permission Option Full Access',
    defaultMessage: 'Full Access (Can add, edit and delete)',
  },

  //CAMPAIGN WIZARD STEPS
  constantsCampaignWizardAddListStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_LIST_STEP_TITLE',
    description: 'Campaigns > New Campaign > Add Lists Step > Title',
    defaultMessage: 'Add Lists',
  },
  constantsCampaignWizardAddDetailsStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_DETAILS_STEP_TITLE',
    description: 'Campaigns > New Campaign > Campaign Details Step > Title',
    defaultMessage: 'Campaign Details',
  },
  constantsCampaignWizardAddPrimarySearchStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_PRIMARY_SEARCH_STEP_TITLE',
    description:
      'Campaigns > New Campaign > Select a Primary Campaign Search Step > Title',
    defaultMessage: 'Select a Primary Campaign Search',
  },
  constantsCampaignWizardAddSearchesStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_SEARCHES_STEP_TITLE',
    description:
      'Campaigns > New Campaign > Add Additional Searches Step > Title',
    defaultMessage: 'Add Additional Searches',
  },
  constantsCampaignWizardAddContactsStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_CONTACTS_STEP_TITLE',
    description: 'Campaigns > New Campaign > Add Contacts Step > Title',
    defaultMessage: 'Add Contacts',
  },
  constantsCampaignWizardAddDashboardsStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_DASHBOARD_STEP_TITLE',
    description: 'Campaigns > New Campaign > Add Dashboards Step > Title',
    defaultMessage: 'Add Dashboards',
  },
  constantsCampaignWizardAddReportsStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_REPORTS_STEP_TITLE',
    description: 'Campaigns > New Campaign > Add Reports Step > Title',
    defaultMessage: 'Add Reports',
  },
  constantsCampaignWizardAddNewsLettersStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_NEWSLETTERS_STEP_TITLE',
    description: 'Campaigns > New Campaign > Add Newsletters Step > Title',
    defaultMessage: 'Add Newsletters',
  },
  constantsCampaignWizardAddAlertsStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_ALERTS_STEP_TITLE',
    description: 'Campaigns > New Campaign > Add Alerts Step > Title',
    defaultMessage: 'Add Alerts',
  },
  constantsCampaignWizardAddStoriesStep: {
    id: 'CONSTANTS_CAMPAIGN_WIZARD_STORIES_STEP_TITLE',
    description: 'Campaigns > New Campaign > Add Story Step > Title',
    defaultMessage: 'Add Stories',
  },

  // ARTICLES SORT OPTIONS
  constantsArticleSortOptionsSortDate: {
    id: 'CONSTANTS_ARTICLE_SORT_OPTIONS_SORT_DATE',
    description: 'Article sort option label',
    defaultMessage: 'Date',
  },
  constantsArticleSortOptionsSortImpactScore: {
    id: 'CONSTANTS_ARTICLE_SORT_OPTIONS_SORT_IMPACT_SCORE',
    description: 'Article sort impact score label',
    defaultMessage: 'Impact Score',
  },
  constantsArticleSortOptionsSortReadership: {
    id: 'CONSTANTS_ARTICLE_SORT_OPTIONS_SORT_READERSHIP',
    description: 'Article sort readership label',
    defaultMessage: 'Readership',
  },
  constantsArticleSortOptionsSortCirculation: {
    id: 'CONSTANTS_ARTICLE_SORT_OPTIONS_SORT_CIRCULATION',
    description: 'Article sort circulation label',
    defaultMessage: 'Circulation',
  },
  constantsArticleSortOptionsSortSeoImpact: {
    id: 'CONSTANTS_ARTICLE_SORT_OPTIONS_SORT_SEO_IMPACT',
    description: 'Article sort seo impact label',
    defaultMessage: 'SEO Impact',
  },
  constantsArticleSortOptionsSortShares: {
    id: 'CONSTANTS_ARTICLE_SORT_OPTIONS_SORT_SHARES',
    description: 'Article sort shares label',
    defaultMessage: 'Shares',
  },

  // MEDIA TYPES
  constantsMediaTypesNews: {
    id: 'CONSTANT_MEDIA_TYPES_NEWS',
    description: 'news media type',
    defaultMessage: 'Online News',
  },

  constantsMediaTypesNewsLicensed: {
    id: 'CONSTANT_MEDIA_TYPES_NEWS_LICENSED',
    description: 'news licensed media type',
    defaultMessage: 'Licenced Content',
  },

  constantsMediaTypesBlog: {
    id: 'CONSTANT_MEDIA_TYPES_BLOG',
    description: 'blog media type',
    defaultMessage: 'Blog',
  },

  constantsMediaTypesBlogLicensed: {
    id: 'CONSTANT_MEDIA_TYPES_BLOG_LICENSED',
    description: 'blog licensed media type',
    defaultMessage: 'Licensed Content',
  },

  constantsMediaTypesTv: {
    id: 'CONSTANT_MEDIA_TYPES_TV',
    description: 'tv media type',
    defaultMessage: 'TV',
  },

  constantsMediaTypesTvDisplayLabel: {
    id: 'CONSTANT_MEDIA_TYPES_TV_DISPLAY_LABEL',
    description: 'tv media type display label',
    defaultMessage: 'Television',
  },

  constantsMediaTypesRadio: {
    id: 'CONSTANT_MEDIA_TYPES_RADIO',
    description: 'radio media type',
    defaultMessage: 'Radio',
  },

  constantsMediaTypesBroadcast: {
    id: 'CONSTANT_MEDIA_TYPES_BROADCAST',
    description: 'broadcast media type',
    defaultMessage: 'Broadcast',
  },

  constantsMediaTypesPodCast: {
    id: 'CONSTANT_MEDIA_TYPES_PODCAST',
    description: 'podcast media type',
    defaultMessage: 'Podcast',
  },

  constantsMediaTypesPrint: {
    id: 'CONSTANT_MEDIA_TYPES_PRINT',
    description: 'print media type',
    defaultMessage: 'Print',
  },

  constantsMediaTypesNewsEdge: {
    id: 'CONSTANT_MEDIA_TYPES_NEWS_EDGE',
    description: 'news edge media type',
    defaultMessage: 'Licensed Content',
  },
  constantsMediaTypesFactiva: {
    id: 'CONSTANT_MEDIA_TYPES_FACTIVA',
    description: 'factiva  media type',
    defaultMessage: 'Factiva',
  },
  constantsMediaTypesFinancialTimes: {
    id: 'CONSTANT_MEDIA_TYPES_FINANCIAL_TIMES',
    description: 'financial times media type',
    defaultMessage: 'Financial Times',
  },
  constantsMediaTypesManuallyAdded: {
    id: 'CONSTANT_MEDIA_TYPES_MANUALLY_ADDED',
    description: 'manually added media type',
    defaultMessage: 'Manually Added',
  },
  // SENTIMENT_TYPES
  constantsSentimentTypesPositive: {
    id: 'CONSTANT_SENTIMENT_TYPES_POSITIVE',
    description: 'positive sentiment type',
    defaultMessage: 'Positive',
  },
  constantsSentimentTypesNegative: {
    id: 'CONSTANT_SENTIMENT_TYPES_NEGATIVE',
    description: 'negative sentiment type',
    defaultMessage: 'Negative',
  },
  constantsSentimentTypesNeutral: {
    id: 'CONSTANT_SENTIMENT_TYPES_NEUTRAL',
    description: 'neutral sentiment type',
    defaultMessage: 'Neutral',
  },

  // STATUS INDICATORS
  constantsStatusIndicatorDraft: {
    id: 'CONSTANT_STATUS_INDICATOR_DRAFT',
    description:
      'Translation for the draft status for the status indicator component',
    defaultMessage: 'Draft',
  },
  constantsStatusIndicatorPaused: {
    id: 'CONSTANT_STATUS_INDICATOR_PAUSED',
    description:
      'Translation for the paused status for the status indicator component',
    defaultMessage: 'Paused',
  },
  constantsStatusIndicatorActive: {
    id: 'CONSTANT_STATUS_INDICATOR_ACTIVE',
    description:
      'Translation for the active status for the status indicator component',
    defaultMessage: 'Live',
  },
  constantsCampaignWizardContactsEmptyState: {
    id: 'CONSTANT_CAMPAIGN_WIZARD_CONTACTS_EMPTY_STATE',
    description:
      'Empty state text for the contacts section in the campaign wizard',
    defaultMessage:
      'You haven’t saved or favorited any contacts. Contacts that are saved to a contact list or added to your favorites can be added to any campaign.',
  },
  constantsCampaignWizardDashboardsEmptyState: {
    id: 'CONSTANT_CAMPAIGN_WIZARD_DASHBOARDS_EMPTY_STATE',
    description:
      'Empty state text for the dashboards section in the campaign wizard',
    defaultMessage:
      'You don’t have any saved or shared dashboards. Once you’ve created a dashboard you’ll be able to add it to any campaign. Dashboards that are shared with can also be added to a campaign.',
  },
  constantsCampaignWizardNewslettersEmptyState: {
    id: 'CONSTANT_CAMPAIGN_WIZARD_NEWSLETTERS_EMPTY_STATE',
    description:
      'Empty state text for the newsletters section in the campaign wizard',
    defaultMessage:
      'You don’t have any saved newsletters. Once you’ve created a newsletter you’ll be able to add it to any campaign.',
  },
  constantsCampaignWizardAlertsEmptyState: {
    id: 'CONSTANT_CAMPAIGN_WIZARD_ALERTS_EMPTY_STATE',
    description:
      'Empty state text for the alerts section in the campaign wizard',
    defaultMessage:
      'You don’t have any saved alerts. Once you’ve created an alert you’ll be able to add it to any campaign.',
  },
  constantsCampaignWizardReportsEmptyState: {
    id: 'CONSTANT_CAMPAIGN_WIZARD_REPORTS_EMPTY_STATE',
    description:
      'Empty state text for the reports section in the campaign wizard',
    defaultMessage:
      'You don’t have any saved reports. Once you’ve created a report you’ll be able to add it to any campaign. Reports are created from dashboards.',
  },
  constantsCampaignWizardSearchesEmptyState: {
    id: 'CONSTANT_CAMPAIGN_WIZARD_SEARCHES_EMPTY_STATE',
    description:
      'Empty state text for the searches section in the campaign wizard',
    defaultMessage:
      'You don’t have any saved or shared searches. Once you’ve created a search you’ll be able to add it to any campaign. Searches that are shared with you can also be added to a campaign.',
  },
  constantsCampaignWizardStoriesEmptyState: {
    id: 'CONSTANT_CAMPAIGN_WIZARD_STORIES_EMPTY_STATE',
    description:
      'Empty state text for the stories section in the campaign wizard',
    defaultMessage:
      'You don’t have any saved story kits. Once you’ve created a story kit you’ll be able to add it to any campaign',
  },
  constantsReadershipArticleMetric: {
    id: 'ARTICLE_METRIC_READERSHIP',
    description: 'Text for the Readership Article Metric',
    defaultMessage: 'Readership',
  },
  constantsLocalViewershipArticleMetric: {
    id: 'ARTICLE_METRIC_LOCAL_AUDIENCE',
    description: 'Text for the Local Audience Article Metric',
    defaultMessage: 'Local Audience',
  },
  constantsNationalViewershipArticleMetric: {
    id: 'ARTICLE_METRIC_NATIONAL_AUDIENCE',
    description: 'Text for the National Audience Article Metric',
    defaultMessage: 'National Audience',
  },
  constantsAdEquivalencyArticleMetric: {
    id: 'ARTICLE_METRIC_AD_EQUIVALENCY',
    description: 'Text for the Ad Equivalency Article Metric',
    defaultMessage: 'Ad Equivalency',
  },
  constantsViewershipAdValueArticleMetric: {
    id: 'ARTICLE_METRIC_COST_PER_MINUTE',
    description: 'Text for the Cost Per Minute Article Metric',
    defaultMessage: 'Cost Per Minute',
  },
  constantsViewershipAdValueArticleMetricTVEyes: {
    id: 'ARTICLE_METRIC_COST_PER_30_SECONDS',
    description: 'Text for the Cost Per 30 Seconds Article Metric',
    defaultMessage: 'Cost Per 30 Seconds',
  },
  constantsSentimentArticleMetric: {
    id: 'ARTICLE_METRIC_SENTIMENT',
    description: 'Text for the Sentiment Article Metric',
    defaultMessage: 'Sentiment',
  },
  constantsSocialSumArticleMetric: {
    id: 'ARTICLE_METRIC_TOTAL_SHARES',
    description: 'Text for the Total Shares Article Metric',
    defaultMessage: 'Total Shares',
  },
  constantsGaPageviewsArticleMetric: {
    id: 'ARTICLE_GA_METRIC_PAGE_VIEWS',
    description: 'Text for the Ga Page Views Article Metric',
    defaultMessage: 'Page Views',
  },
  constantsGaSessionsArticleMetric: {
    id: 'ARTICLE_GA_METRIC_SESSIONS',
    description: 'Text for the Sessions Article Metric',
    defaultMessage: 'Sessions',
  },
  constantsGaAvgSessionDurationArticleMetric: {
    id: 'ARTICLE_METRIC_AVG_DURATION',
    description: 'Text for the Avg. Duration Article Metric',
    defaultMessage: 'Avg. Duration',
  },
  constantsGaPercentNewSessionsArticleMetric: {
    id: 'ARTICLE_METRIC_%_NEW_SESSIONS',
    description: 'Text for the % New Sessions Article Metric',
    defaultMessage: '% New Sessions',
  },
  constantsGaNewUsersArticleMetric: {
    id: 'ARTICLE_METRIC_NEW_USERS',
    description: 'Text for the New Users Article Metric',
    defaultMessage: 'New Users',
  },
  constantsGaBounceRateArticleMetric: {
    id: 'ARTICLE_METRIC_GA_BOUNCE_RATE',
    description: 'Text for the Ga Bounce Rate Article Metric',
    defaultMessage: 'Bounce Rate',
  },
  constantsGaPageviewsPerSessionArticleMetric: {
    id: 'ARTICLE_METRIC_PAGES/SESSIONS',
    description: 'Text for the Pages/Sessions Article Metric',
    defaultMessage: 'Pages/Sessions',
  },
  constantsGaGoalConversionRateAllArticleMetric: {
    id: 'ARTICLE_METRIC_RATE',
    description: 'Text for the Rate Article Metric',
    defaultMessage: 'Rate',
  },
  constantsGaGoalCompletionsAllArticleMetric: {
    id: 'ARTICLE_METRIC_COMPLETIONS',
    description: 'Text for the Completions Article Metric',
    defaultMessage: 'Completions',
  },
  constantsGaGoalValueAllArticleMetric: {
    id: 'ARTICLE_METRIC_VALUE',
    description: 'Text for the Value Article Metric',
    defaultMessage: 'Value',
  },
  constantsGaTransactionRevenueArticleMetric: {
    id: 'ARTICLE_METRIC_TRANSACTION_REVENUE',
    description: 'Text for the Transaction Revenue Article Metric',
    defaultMessage: 'Transaction Revenue',
  },
  constantsAdobeBounceRateArticleMetric: {
    id: 'ARTICLE_METRIC_ADOBE_BOUNCE_RATE',
    description: 'Text for the Adobe Bounce Rate Article Metric',
    defaultMessage: 'Bounce Rate',
  },
  constantsAdobeCheckoutsArticleMetric: {
    id: 'ARTICLE_METRIC_CHECKOUTS',
    description: 'Text for the Checkouts Article Metric',
    defaultMessage: 'Checkouts',
  },
  constantsAdobeOrdersArticleMetric: {
    id: 'ARTICLE_METRIC_ORDERS',
    description: 'Text for the Orders Article Metric',
    defaultMessage: 'Orders',
  },
  constantsAdobePageviewsArticleMetric: {
    id: 'ARTICLE_METRIC_ADOBE_PAGE_VIEWS',
    description: 'Text for the Adobe Page Views Article Metric',
    defaultMessage: 'Page Views',
  },
  constantsAdobeRevenueArticleMetric: {
    id: 'ARTICLE_METRIC_REVENUE',
    description: 'Text for the Revenue Article Metric',
    defaultMessage: 'Revenue',
  },
  constantsAdobeTotalTimeSpentArticleMetric: {
    id: 'ARTICLE_METRIC_AVG_TIME_ON_WEBSITE',
    description: 'Text for the Avg Time on Website Article Metric',
    defaultMessage: 'Avg Time on Website',
  },
  constantsAdobeVisitorsArticleMetric: {
    id: 'ARTICLE_METRIC_VISITORS',
    description: 'Text for the Visitors Article Metric',
    defaultMessage: 'Visitors',
  },
  constantsAdobeVisitsArticleMetric: {
    id: 'ARTICLE_METRIC_WEBSITE_VISITS',
    description: 'Text for the Website Visits Article Metric',
    defaultMessage: 'Website Visits',
  },
  constantsDesktopReadershipArticleMetric: {
    id: 'ARTICLE_METRIC_DESKTOP_READERSHIP',
    description: 'Text for the Desktop Readership Article Metric',
    defaultMessage: 'Desktop Readership',
  },
  constantsMobileReadershipArticleMetric: {
    id: 'ARTICLE_METRIC_MOBILE_READERSHIP',
    description: 'Text for the Mobile Readership Article Metric',
    defaultMessage: 'Mobile Readership',
  },
  constantsTotalReadershipArticleMetric: {
    id: 'ARTICLE_METRIC_TOTAL_READERSHIP',
    description: 'Text for the Total Readership Article Metric',
    defaultMessage: 'Total Readership',
  },
  constantsPressReleaseSearchContentType: {
    id: 'SEARCH_PRESS_RELEASE_CONTENT_TYPE',
    description: 'Text for the Press Release content type',
    defaultMessage: 'Press Releases',
  },
  constantsEarningsAndStockNewsSearchContentType: {
    id: 'SEARCH_EARNINGS_AND_STOCK_NEWS_CONTENT_TYPE',
    description: 'Text for the Earnings & Stock News content type',
    defaultMessage: 'Earnings & Stock News',
  },
  constantsJobPostingsSearchContentType: {
    id: 'SEARCH_JOB_POSTINGS_CONTENT_TYPE',
    description: 'Text for the Job Postings content type',
    defaultMessage: 'Job Postings',
  },
  constantsMarketResearchReportsSearchContentType: {
    id: 'SEARCH_MARKET_RESEARCH_REPORTS_CONTENT_TYPE',
    description: 'Text for the Market Research Reports content type',
    defaultMessage: 'Market Research Reports',
  },
  constantsSportsNewsSearchContentType: {
    id: 'SEARCH_SPORTS_NEWS_CONTENT_TYPE',
    description: 'Text for the Sports News content type',
    defaultMessage: 'Sports News',
  },
  constantsAfricaSearchRegion: {
    id: 'SEARCH_AFRICA_REGION',
    description: 'Text for the Africa region',
    defaultMessage: 'Africa',
  },
  constantsAMEASearchRegion: {
    id: 'SEARCH_AMEA_REGION',
    description: 'Text for the AMEA region',
    defaultMessage: 'AMEA',
  },
  constantsAPACSearchRegion: {
    id: 'SEARCH_APAC_REGION',
    description: 'Text for the APAC region',
    defaultMessage: 'APAC',
  },
  constantsEMEASearchRegion: {
    id: 'SEARCH_EMEA_REGION',
    description: 'Text for the EMEA region',
    defaultMessage: 'EMEA',
  },
  constantsEuropeSearchRegion: {
    id: 'SEARCH_EUROPE_REGION',
    description: 'Text for the Europe region',
    defaultMessage: 'Europe',
  },
  constantsEuropeanUnionSearchRegion: {
    id: 'SEARCH_EUROPEAN_UNION_REGION',
    description: 'Text for the European Union region',
    defaultMessage: 'European Union',
  },
  constantsLATAMSearchRegion: {
    id: 'SEARCH_LATAM_REGION',
    description: 'Text for the LATAM region',
    defaultMessage: 'LATAM',
  },
  constantsMiddleEastSearchRegion: {
    id: 'SEARCH_MIDDLE_EAST_REGION',
    description: 'Text for the Middle East region',
    defaultMessage: 'Middle East',
  },
  constantsNordicsSearchRegion: {
    id: 'SEARCH_NORDICS_REGION',
    description: 'Text for the Nordics region',
    defaultMessage: 'Nordics',
  },
  constantsNorthAmericaSearchRegion: {
    id: 'SEARCH_NORTH_AMERICA_REGION',
    description: 'Text for the North America region',
    defaultMessage: 'North America',
  },
  constantsSEASearchRegion: {
    id: 'SEARCH_SEA_REGION',
    description: 'Text for the SEA region',
    defaultMessage: 'SEA',
  },
  constantsSouthAmericaSearchRegion: {
    id: 'SEARCH_SOUTH_AMERICA_REGION',
    description: 'Text for the South America region',
    defaultMessage: 'South America',
  },
  constantsSetSentimentArticleSuccess: {
    id: 'CONSTANT_SET_SENTIMENT_ARTICLES_SUCCESS',
    description: 'Set sentiment on a list of article success message',
    defaultMessage:
      'Successfully added {SENTIMENT} Sentiment to {TOTAL_ARTICLES_COUNT} {TOTAL_ARTICLES_COUNT, plural, =1 {article} other {articles}}',
  },
  constantsSetSentimentArticleError: {
    id: 'CONSTANT_SET_SENTIMENT_ARTICLES_ERROR',
    description: 'Set sentiment on a list of article error message',
    defaultMessage:
      'There was an error adding {SENTIMENT} Sentiment to {TOTAL_ARTICLES_COUNT} {TOTAL_ARTICLES_COUNT, plural, =1 {article} other {articles}}',
  },
  //IHUB saved lists filter columns
  constantsSortByName: {
    id: 'LISTS_SORT_BY_NAME',
    description:
      'Contacts Beta > Saved Lists: option to sort saved contact lists by contact name',
    defaultMessage: 'Sort by: Name',
  },
  constantsSortByOwner: {
    id: 'LISTS_SORT_BY_OWNER',
    description:
      'Contacts Beta > Saved Lists: option to sort saved contact lists by list owner',
    defaultMessage: 'Sort by: Owner',
  },
  constantsSortByLastUpdated: {
    id: 'LISTS_SORT_BY_LAST_UPDATED',
    description:
      'Contacts Beta > Saved Lists: option to sort saved contact lists by last updated',
    defaultMessage: 'Sort by: Last Updated',
  },
  constantsSortByContactsCount: {
    id: 'LISTS_SORT_BY_CONTACTS_COUNT',
    description:
      'Contacts Beta > Saved Lists: option to sort saved contact lists by number of contacts in a list',
    defaultMessage: 'Sort by: Contacts Count',
  },
  constantsTaggedArticlePendingStatus: {
    id: 'TAGGED_ARTICLE_PENDING_STATUS',
    description:
      'Message to display when the article being tagged has not been processed',
    defaultMessage: 'Pending',
  },
  constantsTaggedArticleProcessingStatus: {
    id: 'TAGGED_ARTICLE_PROCESSING_STATUS',
    description:
      'Message to display when the article being tagged is being processed',
    defaultMessage: 'Processing...',
  },
  constantsTaggedArticleFailedStatus: {
    id: 'TAGGED_ARTICLE_FAILED_STATUS',
    description:
      'Message to display when the system was unable to tagged the article correctly',
    defaultMessage: 'Failed',
  },
  constantsTaggedArticleTaggedStatus: {
    id: 'TAGGED_ARTICLE_TAGGED_STATUS',
    description:
      'Message to display when the system was able to tagged the article correctly',
    defaultMessage: 'Tagged',
  },
  // Exports status
  constantsExportStatusDownloading: {
    id: 'EXPORT_STATUS_DOWNLOADING',
    description: 'Text for status downloading of an export',
    defaultMessage: 'The export is downloading.',
  },
  constantsExportStatusSent: {
    id: 'EXPORT_STATUS_SENT',
    description: 'Text for status sent of an export',
    defaultMessage: 'The export has been sent to {EMAIL}',
  },
  constantsExportStatusFailure: {
    id: 'EXPORT_STATUS_FAILURE',
    description: 'Text for failure of an export',
    defaultMessage: 'There was a problem preparing your export.',
  },
  constantsExpiredMediaLabel: {
    id: 'CONSTANTS_ARTICLE_DETAIL_EXPIRED_MEDIA_LABEL',
    description: 'Message to display when a media is expired',
    defaultMessage: 'Sorry, this {MEDIA_TYPE_LABEL} is expired.',
  },
  constantsExpiredVideoLabel: {
    id: 'CONSTANTS_ARTICLE_DETAIL_EXPIRED_VIDEO_LABEL',
    description: 'Message to display when a video is expired',
    defaultMessage: 'Sorry, this {MEDIA_TYPE_LABEL} is expired.',
  },
  constantsExpiredAudioLabel: {
    id: 'CONSTANTS_ARTICLE_DETAIL_EXPIRED_AUDIO_LABEL',
    description: 'Message to display when an audio is expired',
    defaultMessage: 'Sorry, this {MEDIA_TYPE_LABEL} is expired.',
  },
  constantsMedia: {
    id: 'CONSTANTS_MEDIA',
    description: 'Term to refer to media',
    defaultMessage: 'media',
  },
  constantsVideo: {
    id: 'CONSTANTS_VIDEO',
    description: 'Term to refer to a video',
    defaultMessage: 'video',
  },
  constantsAudio: {
    id: 'CONSTANTS_AUDIO',
    description: 'Term to refer to an audio',
    defaultMessage: 'audio',
  },
  constantsPodcast: {
    id: 'CONSTANTS_PODCAST',
    description: 'Term to refer to a podcast',
    defaultMessage: 'podcast',
  },
  constantsImage: {
    id: 'CONSTANTS_IMAGE',
    description: 'Term to refer to a image',
    defaultMessage: 'image',
  },
  typeArticle: {
    id: 'ARTICLE_TYPE_ARTICLE',
    defaultMessage: 'Article',
    descritpion: 'The post is a written article',
  },
  typeBroadcast: {
    id: 'ARTICLE_TYPE_BROADCAST',
    defaultMessage: 'Broadcast',
    description: 'The post is a broadcast',
  },
};
