import qs from 'qs';

import { SLACK_OAUTH_BASE, SLACK_OAUTH_V2_BASE } from 'constants/apis';
import {
  DEV_FEATURES,
  SLACK_OAUTH_PARAMS,
  SLACK_OAUTH_V2_PARAMS,
} from 'constants/constants';
import { userHasDevFeatureFlag } from 'trendkite-front-end-app/src/services/feature-service/feature-service';
import { removeSensitiveParamsFromUrl } from 'utils/links/links';

export const getSlackAuthUrl = (redirectUri, callbackState) => {
  const params = userHasDevFeatureFlag(DEV_FEATURES.slackV2Auth)
    ? SLACK_OAUTH_V2_PARAMS
    : SLACK_OAUTH_PARAMS;

  const urlParams = qs.stringify(
    {
      ...params,
      /*
     We need to remove the # character from the url that we are passing to
     the redirect_uri param in the slack integration url.
     We have to do it due to the slack api update on April 2020 where slack forbid the
     use of the # character in the url passed to the redirect_uri param. Now grails
     is in charge of redirect users to the correct url adding the # character
     Slack update link: https://api.slack.com/changelog
    */
      redirect_uri: redirectUri.replace('#/', ''), // Make sure shareUrl includes relevant url params for searchId, etc.
      state: callbackState
        ? btoa(JSON.stringify(callbackState))
        : btoa(JSON.stringify({})),
    },
    { addQueryPrefix: true },
  );

  const url = userHasDevFeatureFlag(DEV_FEATURES.slackV2Auth)
    ? SLACK_OAUTH_V2_BASE
    : SLACK_OAUTH_BASE;

  return `${url}${urlParams}`;
};

export const handleSlackRedirect = (
  location,
  slackAuth,
  toggleSlackModal,
  getSlackIntegrations,
  history,
) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const slackCode = queryParams.code;

  getSlackIntegrations();

  if (queryParams.error && queryParams.error === 'access_denied') {
    removeSensitiveParamsFromUrl({
      history,
      location,
      params: ['error', 'state'],
    });
  }

  if (slackCode) {
    const callbackState = queryParams.state
      ? JSON.parse(atob(decodeURIComponent(queryParams.state)))
      : {};
    /*
     We need to remove the # character from the url that we are passing to
     the redirect_uri param in the slack integration url.
     We have to do it due to the slack api update on April 2020 where slack forbid the
     use of the # character in the url passed to the redirect_uri param. Now grails
     is in charge of redirect users to the correct url adding the # character
     Slack update link: https://api.slack.com/changelog
    */
    const redirectUri = callbackState.payload
      ? callbackState.payload.url.replace('#/', '')
      : null;
    let nextAction = () => {};

    if (callbackState.action === 'share') {
      nextAction = () => {
        toggleSlackModal(callbackState.payload);
      };
    }

    removeSensitiveParamsFromUrl({
      history,
      location,
      params: ['code', 'state'],
    });

    slackAuth(slackCode, redirectUri).then(nextAction);
  }
};

export default {
  getSlackAuthUrl,
  handleSlackRedirect,
};
