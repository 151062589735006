import moment from 'moment-timezone';

export const getTimezones = (isEaScheduling = false) => {
  const timezones = moment.tz.names();
  const options = [];
  for (let i = 0; i < timezones.length; i += 1) {
    if (isEaScheduling) {
      options.push({
        id: i,
        offset: moment.tz(timezones[i]).format('Z'),
        label: `${timezones[i].replace('/', ' - ').replace('_', ' ')}`,
        zone: timezones[i],
      });
    } else {
      options.push({
        label: `${timezones[i].replace('_', ' ')} UTC${moment
          .tz(timezones[i])
          .format('Z')}`,
        zone: timezones[i],
      });
    }
  }

  return options;
};

export const getTimezoneLabel = timezone => {
  const timezones = getTimezones(true);
  const currentTimezone = moment.tz.guess();
  let timezoneData = timezones.find(t => t.zone === timezone);
  if (!timezoneData) {
    timezoneData = timezones.find(t => t.zone === currentTimezone);
  }

  return timezoneData.label;
};

export const detectUsersUtcOffset = () => {
  const ianaTimezone = moment.tz.guess();
  return moment.tz(Date.now(), ianaTimezone).format('Z');
};

export const getTimezoneObjectForName = timezoneName => {
  if (timezoneName) {
    // will return 0:00 if invalid timezone supplied
    const offset = moment.tz(timezoneName.replace(' ', '_')).format('Z');
    return {
      label: `${timezoneName.replace('_', ' ')} UTC${offset}`,
      zone: timezoneName,
    };
  }
  return {};
};

export const detectUsersTimezone = () => {
  return moment.tz.guess();
};
