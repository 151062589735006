import shortid from 'shortid';

import {
  PAGE_MESSAGE_DEFAULT_TTL,
  PAGE_MESSAGE_EXTRA_TTL,
} from '../constants/constants';

export const REMOVE_PAGE_MESSAGE = 'REMOVE_PAGE_MESSAGE';
export const ADD_PAGE_MESSAGE = 'ADD_PAGE_MESSAGE';

const removeOld = (id, timeout) => ({
  type: 'REMOVE_PAGE_MESSAGE_OLD',
  id,
  timeout,
});

export const removePageMessageActionCreator = (id, timeout) => {
  if (timeout) {
    return removeOld(id, timeout);
  }

  return {
    type: REMOVE_PAGE_MESSAGE,
    payload: { id },
  };
};

export class Message {
  constructor(config) {
    Object.keys(config).forEach(k => (this[k] = config[k]));
    this.id = shortid.generate();
    this.isNewUI = true;
  }
}

export const addPageMessageWithDefaultTimeout = message => {
  message.ttl = PAGE_MESSAGE_DEFAULT_TTL;

  return dispatch => {
    message.timeout =
      message.ttl &&
      setTimeout(() => {
        dispatch(removePageMessageActionCreator(message.id));
      }, message.ttl);

    dispatch({
      type: ADD_PAGE_MESSAGE,
      payload: message,
    });
  };
};

export const addPageMessageWithExtraTimeout = message => {
  message.ttl = PAGE_MESSAGE_EXTRA_TTL;

  return dispatch => {
    message.timeout =
      message.ttl &&
      setTimeout(() => {
        dispatch(removePageMessageActionCreator(message.id));
      }, message.ttl);

    dispatch({
      type: ADD_PAGE_MESSAGE,
      payload: message,
    });
  };
};

export const addPageMessage = message => {
  return dispatch => {
    message.timeout =
      message.ttl &&
      setTimeout(() => {
        dispatch(removePageMessageActionCreator(message.id));
      }, message.ttl);

    dispatch({
      type: ADD_PAGE_MESSAGE,
      payload: message,
    });
  };
};

export const performMessageActionActionCreator = message => dispatch => {
  dispatch(removePageMessageActionCreator(message.id));
  message.action();
};

const pageMessages = (state = [], action) => {
  switch (action.type) {
    case ADD_PAGE_MESSAGE: {
      const message = action.payload || action;
      message.dismissed = false;
      message.isCancelable =
        message.isCancelable != null ? message.isCancelable : true;
      message.id = message.id || shortid.generate();
      message.ttl = message.ttl || PAGE_MESSAGE_DEFAULT_TTL;
      return [...state, message];
    }
    case REMOVE_PAGE_MESSAGE: {
      const message = state.find(m => m.id === action.payload.id);
      if (!message) {
        return state;
      }

      if (message.timeout) {
        clearTimeout(message.timeout);
      }
      return state.filter(m => m.id !== action.payload.id);
    }
    case 'REMOVE_PAGE_MESSAGE_OLD': {
      if (action.timeout !== undefined) {
        clearTimeout(action.timeout);
      }

      return state.filter(m => m.id !== action.id);
    }
    default:
      return state;
  }
};

export default pageMessages;
