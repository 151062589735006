import React, { Component } from 'react';

import createDOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';

import { Input, MultiLineInput } from '../forms';

class EditableInput extends Component {
  state = {
    focus: this.props.focus,
  };

  componentDidUpdate(prevProps) {
    const { focus } = this.props;
    const { focus: prevFocus } = prevProps;
    if (focus !== prevFocus) {
      this.setFocus(focus);
    }
  }

  purifyText = text => {
    const DOMPurify = createDOMPurify(window);
    return DOMPurify.sanitize(text, { USE_PROFILES: { html: true } });
  };

  onBlur = () => {
    this.setFocus(false);
    this.props.onEndEditing();
  };

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.onBlur();
    } else if (event.key === '\\') {
      event.preventDefault();
    }
  };

  handleChange = event => {
    if (
      event.clipboardData.getData('text') === '\\' ||
      event.clipboardData.getData('text').includes('\\')
    ) {
      event.preventDefault();
    }
  };

  setFocus = focus => {
    this.setState(() => ({ focus }));
  };

  renderMultiLineHeader = () => {
    const { inputModelName, updateOn, debounce } = this.props;
    const { focus } = this.state;
    const modifiers = ['story-headline'];
    const safeDebounce =
      updateOn.indexOf('blur') < 0 && updateOn.indexOf('focus') < 0
        ? debounce
        : null;

    return (
      <Control.custom
        id="editableInput"
        model={`.${inputModelName}`}
        className="tk-editable-input"
        component={MultiLineInput}
        debounce={safeDebounce}
        modifiers={modifiers}
        mapProps={{
          value: props => this.purifyText(props.viewValue),
          onBlur: props => props.onBlur,
          isFocused: focus,
        }}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        updateOn={updateOn}
        validateOn={updateOn}
        validators={{
          required: val => !!val,
        }}
      />
    );
  };

  render() {
    const {
      debounce,
      inputModelName,
      isEditable,
      size,
      updateOn,
      multiLine,
    } = this.props;
    const { focus } = this.state;
    const modifiers = ['color-dark'];

    const safeDebounce =
      updateOn.indexOf('blur') < 0 && updateOn.indexOf('focus') < 0
        ? debounce
        : null; // Debounce makes change events act weird on blur and focus

    if (size !== 'normal') {
      modifiers.push(size);
    }

    if (!isEditable) {
      return null;
    }

    if (multiLine) {
      return this.renderMultiLineHeader();
    }

    return (
      <Control.text
        id="editableInput"
        model={`.${inputModelName}`}
        className="tk-editable-input"
        component={Input}
        controlProps={{
          className: 'tk-editable-input',
          focus,
          modifiers,
          padding: '',
        }}
        debounce={safeDebounce}
        onBlur={this.onBlur}
        onKeyPress={this.onKeyPress}
        updateOn={updateOn}
        validateOn={updateOn}
        onPaste={this.handleChange}
      />
    );
  }
}

EditableInput.propTypes = {
  debounce: PropTypes.number,
  focus: PropTypes.bool,
  inputModelName: PropTypes.string,
  isEditable: PropTypes.bool,
  onEndEditing: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'lg', 'xxxl', 'normal']),
  updateOn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  multiLine: PropTypes.bool,
};

EditableInput.defaultProps = {
  debounce: 500,
  focus: false,
  inputModelName: '',
  isEditable: false,
  size: 'normal',
  updateOn: 'change',
  multiLine: false,
};

export default EditableInput;
