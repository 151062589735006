import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { Loader } from '@trendkite/ui';
import { activeLanguageSelector } from 'selectors/user-settings';

import { getCurrentUserAccountId } from 'services/user-service/user-service';

import TranslationService from '../Translate/translation-service';

import SharedIntlWrapper from './SharedIntlWrapper';

const translationService = new TranslationService();
const sharedIntlWrapper = new SharedIntlWrapper();

function IntlWrapper({ children }) {
  const [translatedMessages, setTranslatedMessages] = useState(null);
  const defaultLanguage = translationService.getDefaultLanguageTag();
  const languageTag = useSelector(activeLanguageSelector);
  const [locale, setLocale] = useState(languageTag);

  const getLanguage = lang => {
    return sharedIntlWrapper
      .getTranslatedMessages(lang)
      .then(response => {
        const messages = response && response.data;
        setTranslatedMessages(messages);
      })
      .catch(err => {
        /* eslint-disable-next-line no-console */
        if (window.console && console.error) {
          /* eslint-disable-next-line no-console */
          console.error('IntlWrapper: Translations failed to load.', err);
        }
        setTranslatedMessages({});
      });
  };

  useEffect(() => {
    if (getCurrentUserAccountId() === null) {
      // Set browser language as default if user is not connected
      getLanguage(navigator.language.toLowerCase());
    } else {
      getLanguage(languageTag);
    }
  }, [languageTag]);

  useEffect(() => {
    const locale = sharedIntlWrapper.getLocaleByLanguageTag(languageTag);
    setLocale(locale);
  }, [languageTag]);

  if (translatedMessages === null) {
    return <Loader />;
  }

  return (
    <IntlProvider
      locale={locale}
      messages={translatedMessages}
      defaultLocale={defaultLanguage}
    >
      {children}
    </IntlProvider>
  );
}

IntlWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntlWrapper;
