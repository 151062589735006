export default {
  duplicateListModalTitle: {
    id: 'DUPLICATE_SAVED_LIST_MODAL_TITLE',
    defaultMessage: 'Duplicate Contact List',
    description: 'Contacts Beta > Saved Lists: duplicate list modal title',
  },
  modalTitle: {
    id: 'BULK-SAVED-LIST-MODAL_MODAL_TITLE',
    defaultMessage: 'New Contact List',
    description: 'Contacts Beta > Saved Lists: new list modal title',
  },
  listNameLabel: {
    id: 'BULK-SAVED-LIST-MODAL_LIST_NAME_LABEL',
    defaultMessage: 'List Name',
    description: 'Contacts Beta > Saved Lists: input label',
  },
  listNamePlaceholder: {
    id: 'BULK-SAVED-LIST-MODAL_INPUT_PLACEHOLDER',
    defaultMessage: 'Enter list name',
    description: 'Contacts Beta > Saved Lists: input placegolder',
  },
};
