import { TK_EVENT_PENDO_GUIDE_LOADED } from '../constants/constants';

// file deepcode ignore HardcodedNonCryptoSecret: <False positive: https://cision.atlassian.net/browse/NGC3CD-911>
// Vendor code
/* eslint-disable */
// prettier-ignore
(function(p, e, n, d, o) {
  var v, w, x, y, z;
  o = p[d] = p[d] || {};
  o._q = [];
  v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
  for (w = 0, x = v.length; w < x; ++w)
    (function(m) {
      o[m] =
        o[m] ||
        function() {
          o._q[m === v[0] ? 'unshift' : 'push'](
            [m].concat([].slice.call(arguments, 0)),
          );
        };
    })(v[w]);
  y = e.createElement(n);
  y.async = !0;
  // y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
  z = e.getElementsByTagName(n)[0];
  z.parentNode.insertBefore(y, z);
})(window, document, 'script', 'pendo');

const getEnvironment = () => window.PAGE_DATA.environment.toLowerCase();

const appendEnvironment = str => {
  const env = getEnvironment();
  // We do not append anything when we are in production
  return !str || env === 'production' ? str : str + '.' + env;
};

let options = {
  visitor: {},
  account: {},
};

const getLanguageForPendo = () => {
  const activeUserLanguage = window.activeUser?.language;
  if (activeUserLanguage) {
    const [lang, region] = activeUserLanguage.split('-');
    return `${lang}_${region.toUpperCase()}`;
  } else {
    return 'en_US';
  }
};

options.visitor.userLanguage = getLanguageForPendo();

if (typeof window.activeUser.impersonatorUserName !== 'undefined') {
  options.visitor.id = appendEnvironment(
    window.activeUser.impersonatorUserName,
  );
  options.visitor.impersonating = true;
  options.visitor.impersonating_user = appendEnvironment(
    window.activeUser.username,
  );
  options.account.id = appendEnvironment(
    window.activeUser.impersonatorAccountgcId,
  );
} else if (typeof window.activeUser.username === 'undefined') {
  options.visitor.id = appendEnvironment(
    'Anonymous.' + window.activeUser.account.id,
  );
  options.account.id = appendEnvironment(window.activeUser.account.id);
} else {
  options.visitor.id = appendEnvironment(window.activeUser.username);
  options.account.id = appendEnvironment(
    window.activeUser.account ? window.activeUser.account.gcId : undefined,
  );
}

if (
  getEnvironment() === 'production' &&
  !window.activeUser.impersonatorUserName &&
  window.activeUser.sfdc_id
) {
  options.account.sfdc_id = window.activeUser.sfdc_id; //only set sfdc id in prod, if not impersonating
}

options.events = {
  guidesLoaded: () => {
    window.postMessage(TK_EVENT_PENDO_GUIDE_LOADED, window.location.origin);
  },
};

pendo.initialize(options);
