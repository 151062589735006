import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Meatballs = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);
Meatballs.propTypes = { fill: PropTypes.string };
Meatballs.defaultProps = { fill: iconColorsMap.mainicon };

export default Meatballs;
