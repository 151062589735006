import React, { Component } from 'react';

import { Button } from '@cision/rover-ui';
import PropTypes from 'prop-types';

import {
  Body,
  Button as TkUiButton,
  Modal,
  InputDatePicker,
  Dropdown,
  DropdownMenu,
  SvgIcon,
  Addon,
  Input,
  List,
  ListEntrySmall,
} from '@trendkite/ui';

import { timeOptionsByMinInterval } from 'utils/times/times';
import {
  getTimezones,
  getTimezoneObjectForName,
  detectUsersTimezone,
} from 'utils/times/timezones';

import './schedule-modal.scss';

const baseClass = 'tk-schedule-modal';
class ScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: '',
      date: this.props.date,
      timeLabel: this.props.timeLabel,
      time: this.props.time,
      isOpenTimeDropdown: false,
      isOpenTimezoneDropdown: false,
      selectedTimezone: getTimezoneObjectForName(
        this.props.selectedTimezoneName,
      ),
      timezones: getTimezones(),
    };
    // preserve the initial state in a new object
    this.baseState = { ...this.state };
  }

  onCancel = () => {
    this.setState(this.baseState);
    this.props.onCancel();
  };

  onSubmit = e => {
    e.stopPropagation();
    const { date, time, selectedTimezone } = this.state;
    this.props.onConfirm({ date, time, selectedTimezone });
  };

  onRemove = e => {
    e.stopPropagation();
    this.props.onRemove();
  };

  hasRequiredFields = () => {
    const { date, time, selectedTimezone } = this.state;
    return !!date && !!time && !!Object.keys(selectedTimezone).length;
  };

  selectedTimeIsValid = () => {
    const { date, time, selectedTimezone } = this.state;
    const timezone = selectedTimezone.zone;
    return this.props.selectionsAreValid({ date, time, timezone });
  };

  canSubmit = () => {
    return this.hasRequiredFields() && this.selectedTimeIsValid();
  };

  // Time Handlers
  handleToggleTimeDropdown = open => {
    setTimeout(() => {
      this.setState({
        isOpenTimeDropdown: open,
        isOpenTimezoneDropdown: false,
      });
    }, 100);
  };

  handleTimeSelect = childSelections => {
    const selections = childSelections[0];
    this.setState({
      timeLabel: selections.title,
      time: selections.id,
    });
    this.handleToggleTimeDropdown(false);
  };

  // Date Handlers
  handleDateChange = newDate => {
    this.setState({ date: newDate });
  };
  handleToggleDateDropdown = () => {
    this.setState({
      isOpenTimezoneDropdown: false,
      isOpenTimeDropdown: false,
    });
  };

  // Timezones Handlers
  handleToggleTimezoneDropdown = open => {
    setTimeout(() => {
      this.setState({
        isOpenTimezoneDropdown: open,
        isOpenTimeDropdown: false,
      });
    }, 100);
  };
  handleTimezoneInputChange = (input, value) => {
    this.setState({
      [input]: value,
    });
  };
  handleTimezoneChange = newTimezone => {
    this.setState({ selectedTimezone: newTimezone });
  };
  handleSearchInputChange = value => {
    const timezones = getTimezones();
    const filteredTimezones = timezones.filter(tz =>
      tz.label.toLowerCase().includes(value.toLowerCase()),
    );

    this.setState({
      searchFilter: value,
      timezones: filteredTimezones,
    });
  };
  renderTimezones = () => {
    return this.state.timezones.map(timezone => {
      const zoneName = timezone.label;
      return (
        <div key={`tz-${zoneName}`}>
          <div>
            <TkUiButton
              data-qa="04xxeY-PhakoVZditt2m0"
              key={`tz-detail-${zoneName.split(' ').join('_')}`}
              onClick={() => {
                this.handleTimezoneChange(timezone);
                this.handleToggleTimezoneDropdown(false);
              }}
              modifiers={['listEntry']}
            >
              {zoneName}
            </TkUiButton>
          </div>
        </div>
      );
    });
  };

  render() {
    const {
      header,
      isOpen,
      cancelButtonText,
      confirmButtonText,
      showRemoveSchedule,
      size,
      status,
    } = this.props;
    const {
      timeLabel,
      isOpenTimeDropdown,
      isOpenTimezoneDropdown,
      selectedTimezone,
      time,
    } = this.state;
    const timeOptions = timeOptionsByMinInterval();

    return (
      <Modal
        className={baseClass}
        toggle={this.onCancel}
        isOpen={isOpen}
        size={size}
      >
        <Modal.Header status={status} toggle={this.onCancel}>
          {header}
        </Modal.Header>

        <Modal.Body>
          <Body>
            <p>When would you like to send these messages?</p>
            <form className="shedule-message-form">
              <div className="form-group">
                <InputDatePicker
                  id="schedule-date-input"
                  onBlur={this.handleDateChange}
                  label=""
                  date={this.state.date}
                  minDate={Date.now()}
                  onFocus={this.handleToggleDateDropdown}
                  openCalendarDatepickerOnFocus
                />
              </div>

              <div className="form-group">
                <Input
                  onFocus={() => this.handleToggleTimeDropdown(true)}
                  onBlur={() => this.handleToggleTimeDropdown(false)}
                  placeholder="Time"
                  value={timeLabel}
                  type="input"
                />
                <Dropdown
                  isOpen={isOpenTimeDropdown}
                  modifiers={['filterbar', 'full-width']}
                  position="bottom"
                  toggle={() => {}}
                >
                  <DropdownMenu
                    isOpen={isOpenTimeDropdown}
                    width="100%"
                    toggle={() => {}}
                  >
                    <div className={`${baseClass}__dropdown-wrapper`}>
                      <div className={`${baseClass}__time-dropdown`}>
                        <List
                          entries={timeOptions}
                          onSelect={this.handleTimeSelect}
                          EntryComponent={ListEntrySmall}
                          selected={[time]}
                        />
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="form-group">
                <Input
                  onFocus={() => this.handleToggleTimezoneDropdown(true)}
                  onBlur={() => this.handleToggleTimezoneDropdown(false)}
                  placeholder="Time zone"
                  value={selectedTimezone.label}
                  type="input"
                  onChange={e =>
                    this.handleTimezoneInputChange('time', e.target.value)
                  }
                />
                <Dropdown
                  isOpen={isOpenTimezoneDropdown}
                  modifiers={['filterbar', 'full-width']}
                  position="bottom"
                  toggle={() => {}}
                >
                  <DropdownMenu
                    isOpen={isOpenTimezoneDropdown}
                    width="100%"
                    toggle={() => {}}
                  >
                    <div className={`${baseClass}__dropdown-wrapper`}>
                      <div
                        className={`${baseClass}__search-filter input-group`}
                      >
                        <div className="input-group-addon">
                          <button
                            data-qa="7OgZBQXpH2oBUt-7OH3HB"
                            type="button"
                            className="btn contacts-search-form-query-search-button"
                            onClick={() => {}}
                          >
                            <SvgIcon icon="search" />
                          </button>
                        </div>
                        <Input
                          onFocus={() =>
                            this.handleToggleTimezoneDropdown(true)
                          }
                          className="default-input"
                          placeholder="Search"
                          value={this.state.searchFilter}
                          type="input"
                          onChange={e =>
                            this.handleSearchInputChange(e.target.value)
                          }
                        />
                      </div>
                      <div className={`${baseClass}__timezones-dropdown`}>
                        {this.renderTimezones()}
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </form>
            {this.hasRequiredFields() && !this.selectedTimeIsValid() && (
              <div className={`${baseClass}__errors`}>
                <Body color="danger">
                  Please select a valid date and time in the future
                </Body>
              </div>
            )}
          </Body>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Actions>
            {showRemoveSchedule && (
              <Modal.Action>
                <TkUiButton
                  data-qa="3ggSrKF1hPqiaf7L27r7I"
                  modifiers={['link', 'inline-flex']}
                  onClick={this.onRemove}
                  style={{ padding: '10px 20px' }}
                >
                  <Addon>
                    <SvgIcon
                      icon="timerOff"
                      width={20}
                      height={20}
                      modifiers={['block']}
                    />
                  </Addon>
                  <span>Remove</span>
                </TkUiButton>
              </Modal.Action>
            )}
            <Modal.Action>
              <TkUiButton
                data-qa="GWtWycdrESEJP6rdPte8m"
                modifiers={['tertiary', 'round', 'margin-right']}
                onClick={this.onCancel}
              >
                {cancelButtonText}
              </TkUiButton>
            </Modal.Action>
            <Modal.Action>
              <Button
                data-qa="Dc57ZRnoK9hhJLB4fga_o"
                level="primary"
                size="lg"
                onClick={this.onSubmit}
                disabled={!this.canSubmit()}
              >
                {confirmButtonText}
              </Button>
            </Modal.Action>
          </Modal.Actions>
        </Modal.Footer>
      </Modal>
    );
  }
}

ScheduleModal.propTypes = {
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  selectionsAreValid: PropTypes.func.isRequired,
  showRemoveSchedule: PropTypes.bool,
  size: PropTypes.string,
  status: PropTypes.oneOf(['danger', 'warning', 'success', 'info', 'none']),
  time: PropTypes.string,
  timeLabel: PropTypes.string,
  date: PropTypes.number,
  selectedTimezoneName: PropTypes.string,
};

ScheduleModal.defaultProps = {
  header: '',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Schedule',
  message: null,
  selectionsAreValid: true,
  showRemoveSchedule: true,
  size: 'sm',
  status: 'none',
  onCancel: () => {},
  onSubmit: () => {},
  onRemove: () => {},
  time: '',
  timeLabel: '',
  date: Date.now(),
  selectedTimezoneName: detectUsersTimezone(),
};

export default ScheduleModal;
