import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { EmailListControl } from '@trendkite/ui';

import { OUTREACH_CONTACT_TYPES } from 'constants/outreach-message';
import { propTypes as outreachContactPropTypes } from 'types/outreach-contact';

import EmailRecipientsDisplay from '../email-recipients-display';

const baseClass = 'email-recipients-control';

/**
  This component wraps EmailListControl with some logic to transform
  the returned values into valid email recipient objects for outreach
*/

class WrappedEmailListControl extends Component {
  onChange = values => {
    const safeValues = values.map(value => {
      if (value.contactType) {
        return value;
      }

      // Patch custom values with metadata
      return {
        ...value,
        contactType: OUTREACH_CONTACT_TYPES.custom,
        id: null,
      };
    });
    this.props.onChange(safeValues);
  };

  render() {
    const { isFocused, onFocus, values, name } = this.props;

    const isFromOutreachComposeForm =
      name === 'outreachComposeForm.to' ||
      name === 'outreachComposeForm.cc' ||
      name === 'outreachComposeForm.bcc';

    if (isFocused || isFromOutreachComposeForm) {
      return (
        <div className={`${baseClass}__control`}>
          <EmailListControl
            {...this.props}
            isFromOutreachComposeForm={isFromOutreachComposeForm}
            modifiers={['seethrough']}
            onChange={this.onChange}
          />
        </div>
      );
    }

    const onFocusEvent = () => {
      if (name !== 'outreachComposeForm.to') return;

      return onFocus;
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        data-qa="NZThGHZNfppIjienYEdE9"
        className={`${baseClass}__values`}
        onClick={onFocusEvent}
        onFocus={onFocus}
        role="button"
        tabIndex="0"
      >
        <EmailRecipientsDisplay values={values} />
      </div>
    );
  }
}

WrappedEmailListControl.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(outreachContactPropTypes).isRequired,
  name: PropTypes.string,
};

export default WrappedEmailListControl;
