import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';

import { performGet, performDelete } from 'services/rest-service/rest-service';

import messages from '../../pages/Campaigns/Campaigns.messages';

import { UPDATE_CAMPAIGN_PROPERTY } from './campaign';

export const GET_CAMPAIGN_STORIES = 'campaigns/stories/GET_CAMPAIGN_STORIES';
export const GET_CAMPAIGN_STORIES_RECEIVED =
  'campaigns/stories/GET_CAMPAIGN_STORIES_RECEIVED';
export const GET_CAMPAIGN_STORIES_ERROR =
  'campaigns/stories/GET_CAMPAIGN_STORIES_ERROR';
export const RESET_CAMPAIGN_STORIES =
  'campaigns/stories/RESET_CAMPAIGN_STORIES';
export const DELETE_CAMPAIGN_STORIES =
  'campaigns/stories/DELETE_CAMPAIGN_STORIES';
export const DELETE_CAMPAIGN_STORIES_SUCCESS =
  'campaigns/stories/DELETE_CAMPAIGN_STORIES_SUCCESS';
export const DELETE_CAMPAIGN_STORIES_ERROR =
  'campaigns/stories/DELETE_CAMPAIGN_STORIES_ERROR';

export const initialState = {
  stories: [],
  loading: false,
  error: false,
};

const campaignStoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_STORIES: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case GET_CAMPAIGN_STORIES_RECEIVED: {
      const { stories } = action.payload;

      return {
        loading: false,
        error: false,
        stories,
      };
    }

    case GET_CAMPAIGN_STORIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case DELETE_CAMPAIGN_STORIES: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_CAMPAIGN_STORIES_SUCCESS: {
      const { stories } = action.payload;

      return {
        stories,
        loading: false,
        error: false,
      };
    }

    case DELETE_CAMPAIGN_STORIES_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export const getCampaignStoriesByCampaignId = campaignId => async dispatch => {
  // eslint-disable-line
  dispatch({ type: GET_CAMPAIGN_STORIES });

  try {
    const storiesRequest = await performGet(
      `${CAMPAIGN_ENDPOINT}/${campaignId}/story`,
    );
    const hasNoStories =
      storiesRequest.status === 204 || storiesRequest.data === [];

    if (hasNoStories) {
      dispatch({
        type: GET_CAMPAIGN_STORIES_RECEIVED,
        payload: { stories: [] },
      });
      return;
    }

    const stories = storiesRequest.data.results.map(story => ({
      ...story,
      svgIcon: 'story',
      type: 'stories',
    }));

    dispatch({ type: GET_CAMPAIGN_STORIES_RECEIVED, payload: { stories } });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_STORIES_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Failed to fetch stories for your campaign.',
        status: 'danger',
      }),
    );

    throw e;
  }
};

export const deleteCampaignStoryByIds = (campaignId, storyIds, intl) => async (
  dispatch,
  getState,
) => {
  const campaignStories = getState().campaignStoriesList.stories;
  const updatedList = campaignStories.filter(
    story => storyIds.indexOf(story.id) === -1,
  );

  dispatch({ type: DELETE_CAMPAIGN_STORIES });

  const url = `${CAMPAIGN_ENDPOINT}/${campaignId}/story`;

  try {
    await performDelete(url, { storyIds });
    dispatch({
      type: DELETE_CAMPAIGN_STORIES_SUCCESS,
      payload: { stories: updatedList },
    });
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: { stories: updatedList.map(story => story.id) },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.storiesDeletedSuccess),
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: DELETE_CAMPAIGN_STORIES_ERROR, payload: { storyIds } });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.storiesDeletedFailed),
        status: 'danger',
      }),
    );
    throw e;
  }
};

export default campaignStoriesReducer;
