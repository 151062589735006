import React from 'react';

import PropTypes from 'prop-types';

/** factory to create a HOC to add sematic color prop to a component
 * takes in a color prop and passes down a modifiers prop to component
 *  in your sass file, include styles/colors and use with-color mixin
 *  to add color modifiers to your component sass
 *
 * @param {string} [defaultColor=undefined] Default Color
 * @returns {function} HOC to wrap component with color
 * @example
 *  const Comp = withColors('danger')(ComponentWithModifiers);
 *  <Comp color="danger" modifiers={['other']} />
 *
 */
const withColorsFactory = defaultColor => Component => {
  const withColorsHOC = props => {
    const { color } = props;
    let modifiers = props.modifiers;
    if (color) {
      modifiers = [...props.modifiers, `color-${color}`];
    }

    return (
      <Component {...props} modifiers={modifiers}>
        {props.children}
      </Component>
    );
  };

  withColorsHOC.propTypes = {
    color: PropTypes.oneOf([
      'danger',
      'warning',
      'info',
      'success',
      'neutral',
      '',
    ]),
    modifiers: PropTypes.array,
    children: PropTypes.node,
  };

  withColorsHOC.defaultProps = {
    color: defaultColor || '',
    modifiers: [],
    children: null,
  };

  withColorsHOC.displayName =
    Component.displayName || Component.name || 'Component';

  return withColorsHOC;
};

export default withColorsFactory;
