import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const AddDocument = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M14 2H4v20h16V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z" />
  </svg>
);
AddDocument.propTypes = { fill: PropTypes.string };
AddDocument.defaultProps = { fill: iconColorsMap.mainicon };

export default AddDocument;
