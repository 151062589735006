import React, { useState } from 'react';

import { Dropdown } from '@cision/rover-ui';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { SvgIcon, Addon, Button as TkUiButton } from '@trendkite/ui';

import {
  checkForMetricMeasure,
  DIMENSION_GROUP_BY_MAP,
  getWidgetByMetricMeasure,
  GROUP_BY_TYPES,
  METRICS,
} from 'components/widgetWizard/constants';

import messages from 'components/widgetWizard/steps/form.messages';
import styles from 'components/widgetWizard/steps/steps.module.css';
import {
  getDimensionAdditionalFields,
  getDimensionGranularity,
  getDimensionLabel,
  getDimensionMaxBuckets,
  getMetricLabel,
  getValidGroupByOptions,
  isHighestReadership,
  METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION,
} from 'components/widgetWizard/utils';
import {
  DATA_SOURCES,
  DATA_SOURCE_TYPES,
  V3_METRIC_FIELDS,
  VISUALIZATION_TYPES,
} from 'constants/constants';

import TranslatedMessage from 'i18n/TranslatedMessage';
import {
  removeAllDataSourcesByTypeActionCreator,
  removePrimaryMetricActionCreator,
  removeSecondaryMetricActionCreator,
  updatePrimaryMetricDimensionActionCreator,
  updateSecondaryMetricDimensionActionCreator,
} from 'reducers/widget-form';

import AnalyticsDropdown from './analytics-dropdown';

interface Props {
  // TODO: Fully typing Metric is a big job.
  //
  metric: Record<string, any>;
  metricKey: string;
  selectedAnalyticId: number;
  selectedItemsCount: number;
  haveSecondaryMetric: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleSelectAnalytic: Function;
}

const MetricField = (props: Props) => {
  const {
    metric,
    metricKey,
    selectedAnalyticId,
    selectedItemsCount,
    haveSecondaryMetric,
    handleSelectAnalytic,
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  const [isGroupByOpen, setIsGroupByOpen] = useState({
    [METRICS.primaryMetric]: false,
    ...(haveSecondaryMetric ? { [METRICS.secondaryMetric]: false } : {}),
  });

  const onRemoveMetric = e => {
    e.stopPropagation();
    if (metricKey === METRICS.primaryMetric) {
      dispatch(removePrimaryMetricActionCreator());
      dispatch(
        removeAllDataSourcesByTypeActionCreator(
          DATA_SOURCES.trendkiteElasticSearch,
          DATA_SOURCE_TYPES.tag,
        ),
      );
    } else if (metricKey === METRICS.secondaryMetric) {
      dispatch(removeSecondaryMetricActionCreator());
    }
  };

  const onGroupBySelect = groupBy => {
    let dimensions = [];
    if (
      METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION.includes(metric.measure) && // weird hack to handle KM and SOV
      metric.visualizationType === VISUALIZATION_TYPES.pie &&
      groupBy.id === GROUP_BY_TYPES.dataSource
    ) {
      dimensions = [];
    } else {
      dimensions = groupBy.dimensionFields.map(field => {
        return {
          granularity: getDimensionGranularity(field, metric),
          additionalFields: getDimensionAdditionalFields(field),
          label: getDimensionLabel(field, metric),
          maxBuckets: getDimensionMaxBuckets(field, metric),
          field,
        };
      });
    }
    if (metricKey === METRICS.primaryMetric) {
      dispatch(updatePrimaryMetricDimensionActionCreator(dimensions));
    } else if (metricKey === METRICS.secondaryMetric) {
      dispatch(updateSecondaryMetricDimensionActionCreator(dimensions));
    }
    onToggle();
  };

  const onGaSelect = ga => {
    handleSelectAnalytic(ga.analyticsId);
  };

  const onToggle = () => {
    setIsGroupByOpen({
      ...isGroupByOpen,
      [metricKey]: !isGroupByOpen[metricKey],
    });
  };

  const getGroupByLabel = () => {
    const metricDimensionFields = metric.dimensions?.map(x => x.field).sort();
    const groupBy =
      metric.measure?.field === V3_METRIC_FIELDS.userTags ||
      isHighestReadership(metric)
        ? DIMENSION_GROUP_BY_MAP(intl).find(
            g => g.id === GROUP_BY_TYPES.publishDate,
          )
        : DIMENSION_GROUP_BY_MAP(intl).find(g =>
            isEqual(g.dimensionFields.sort(), metricDimensionFields),
          );

    if (metric.measure?.field === V3_METRIC_FIELDS.totalPosts) {
      return (DIMENSION_GROUP_BY_MAP(intl).find(
        g => g.id === GROUP_BY_TYPES.posts,
      ) as any).groupByLabel;
    } else if (groupBy) {
      return groupBy.groupByLabel;
    } else if (
      METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION.includes(metric.measure.field) &&
      !metric.dimensions?.length
    ) {
      return (DIMENSION_GROUP_BY_MAP(intl).find(
        g => g.id === GROUP_BY_TYPES.dataSource,
      ) as any).groupByLabel;
    }
  };

  const getGroupByOptions = () => {
    const validGroupByOptions = getValidGroupByOptions({
      metric,
      selectedItemsCount,
    });

    const isValidOption = groupByOption => {
      return validGroupByOptions.indexOf(groupByOption) > -1;
    };

    return DIMENSION_GROUP_BY_MAP(intl).map(gb => (
      <Dropdown.Menu.Item
        disabled={!isValidOption(gb.id)}
        onClick={() => onGroupBySelect(gb)}
        key={gb.id}
        data-qa={`${metricKey}-group-by-dropdown`}
      >
        <span
          className={
            isValidOption(gb.id)
              ? styles.itemContainer
              : styles.disabledItemContainer
          }
        >
          <TranslatedMessage {...messages.groupBySelectionDropdown} />
          &nbsp;-&nbsp;
          {gb.groupByLabel}
        </span>
      </Dropdown.Menu.Item>
    ));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && onRemoveMetric) {
      onRemoveMetric(e);
    }
  };

  const renderMetricPill = () => {
    return (
      <TkUiButton
        modifiers={['clearable-pill', 'flex', 'round', 'large', 'auto-width']}
        style={{ marginBottom: '10px' }}
        disabled={getWidgetByMetricMeasure(metric).metricsUneditable}
      >
        <span>{getMetricLabel(metric.measure, intl)}</span>
        <div
          className={
            getWidgetByMetricMeasure(metric).metricsUneditable
              ? styles.pillCrossDisabled
              : styles.pillCross
          }
          onClick={e => onRemoveMetric(e)}
          onKeyDown={e => handleKeyDown(e)}
          data-qa={`${metricKey}-remove-metric-pill`}
          role="button"
          tabIndex={0}
        >
          <SvgIcon icon="cross" width={15} height={15} modifiers={['flex']} />
        </div>
      </TkUiButton>
    );
  };

  return (
    <div
      className={styles.metricsContainer}
      key={metric.id || metric.measure?.field}
    >
      {' '}
      {renderMetricPill()}
      {(checkForMetricMeasure(metric, V3_METRIC_FIELDS.sessions) ||
        checkForMetricMeasure(metric, V3_METRIC_FIELDS.prTraffic)) && (
        <AnalyticsDropdown
          buttonStyles={['round']}
          onSelect={analytic => onGaSelect(analytic)}
          selectedAnalyticId={selectedAnalyticId}
        />
      )}
      {!checkForMetricMeasure(metric, V3_METRIC_FIELDS.sessions) &&
        !checkForMetricMeasure(metric, V3_METRIC_FIELDS.prTraffic) && (
          <Dropdown
            isOpen={isGroupByOpen[metricKey]}
            onToggle={() => onToggle()}
          >
            <TkUiButton
              onClick={() => onToggle()}
              modifiers={['round', 'tertiary', 'inline-flex']}
              style={{ width: 'initial' }}
              data-qa={`${metricKey}-toggle-group-by-dropdown`}
            >
              <span>
                <TranslatedMessage {...messages.groupBySelectionDropdown} />
                &nbsp;-&nbsp;
                {getGroupByLabel()}
              </span>
              <Addon>
                <SvgIcon
                  icon={isGroupByOpen ? 'arrowDropUp' : 'arrowDropDown'}
                  width={24}
                  height={24}
                  modifiers={['block']}
                />
              </Addon>
            </TkUiButton>
            <Dropdown.Menu style={{ background: 'white', padding: '5px' }}>
              {getGroupByOptions()}
            </Dropdown.Menu>
          </Dropdown>
        )}
    </div>
  );
};

export default MetricField;
