import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Avatar from '../avatar';
import IconButton from '../button/IconButton';
import ShimmerText from '../shimmer-text';

import Name from './Name';
import Publications from './Publications';
import Title from './Title';
import Tooltip from '../tooltip';

const Person = ({
  avatarProps,
  className,
  disabled,
  detailsComponent,
  imgLoading,
  imgUrl,
  isFavorite,
  linkUrl,
  loading,
  name,
  nameSuffix,
  onFavoritePerson,
  onToggleListMenu,
  onUnfavoritePerson,
  publications,
  showActions,
  size,
  style,
  title,
  truncate,
  externalLink,
}) => {
  const mainClass = classnames(Person.baseClass, className, {
    [`${Person.baseClass}--truncate`]: truncate,
    [`${Person.baseClass}--${size}`]: size,
  });

  const favoriteClass = classnames([`${Person.baseClass}__icon-menu-item`], {
    [`${Person.baseClass}__icon-menu-item--favorited`]: isFavorite,
  });

  const listClass = classnames([`${Person.baseClass}__icon-menu-item`]);

  const avatarSize = {
    sm: 'small',
    md: 'normal',
    lg: 'large',
  }[size];

  const safePublications =
    (publications && publications.filter(pub => !!pub)) || [];

  const toggleFavorite = () => {
    if (isFavorite) {
      onUnfavoritePerson();
    } else {
      onFavoritePerson();
    }
  };

  const renderActions = () => {
    const showAddToListMenu = !!onToggleListMenu;
    return (
      <React.Fragment>
        {showActions && (
          <div className={`${Person.baseClass}__icon-menu`}>
            <div className={favoriteClass}>
              <Tooltip
                content={
                  isFavorite ? 'Remove from favorites' : 'Add to favorites'
                }
              >
                <IconButton
                  onClick={toggleFavorite}
                  icon="star-filled"
                  compact
                  isLoading={false}
                  highlight={isFavorite}
                  size="small"
                />
              </Tooltip>
            </div>
            {showAddToListMenu && (
              <div className={listClass}>
                <Tooltip content="Add to list">
                  <IconButton
                    onClick={onToggleListMenu}
                    icon="userStackAdd"
                    compact
                    isLoading={false}
                    size="small"
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={mainClass} style={style}>
      <div className={`${Person.baseClass}__avatar`}>
        <Avatar
          linkUrl={linkUrl}
          loading={loading || imgLoading || false}
          name={name}
          naturalSize
          size={avatarSize}
          url={imgUrl}
          disabled={disabled}
          externalLink={externalLink}
          {...avatarProps}
        />
      </div>
      <div className={`${Person.baseClass}__content`}>
        <div className={`${Person.baseClass}__name-container`}>
          <Name
            name={name}
            linkUrl={linkUrl}
            loading={loading}
            size={size}
            truncate={truncate}
            externalLink={externalLink}
          />
          {nameSuffix || null}
          {renderActions()}
        </div>
        <Title
          loading={loading}
          size={size}
          title={title}
          truncate={truncate}
        />
        <Publications loading={loading} size={size} truncate={truncate}>
          {safePublications}
        </Publications>
        {detailsComponent && (
          <div className={`${Person.baseClass}__details`}>
            {loading && <ShimmerText style={{ width: '7em' }} />}
            {!loading && detailsComponent}
          </div>
        )}
      </div>
    </div>
  );
};

Person.baseClass = 'tk-person';

Person.propTypes = {
  /** Props passed down to the child Avatar component. These override Person-generated Avatar props in conflicts. */
  avatarProps: PropTypes.shape({
    ...Avatar.propTypes,
    // `loading` and `name` are required by Avatar, but can use derived values in this component, so they don't need to be passed in.
    loading: PropTypes.bool,
    name: PropTypes.string,
  }),
  /** Optional custom class names, applied to the outer wrapper of the component (in addition to the normal classes). */
  className: PropTypes.string,
  /** Optional node to render additional details */
  detailsComponent: PropTypes.node,
  /** disabled flag */
  disabled: PropTypes.bool,
  /** Show a loading state for the avatar image when true */
  imgLoading: PropTypes.bool,
  /** The avatar image source */
  imgUrl: PropTypes.string,
  /** Specifies if the person is already favorited or not **/
  isFavorite: PropTypes.bool,
  /** A nav link for for the avatar image and name. Only works when it has access to an ancestor <Router/> component */
  linkUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Show a loading state for the whole person block */
  loading: PropTypes.bool,
  /** Full name */
  name: PropTypes.string.isRequired,
  /** Optional node to render after of name  */
  nameSuffix: PropTypes.node,
  /** favorite action **/
  onFavoritePerson: PropTypes.func,
  /** Toggle list menu **/
  onToggleListMenu: PropTypes.func,
  /** unfavorite action **/
  onUnfavoritePerson: PropTypes.func,
  /** Publication names */
  publications: PropTypes.arrayOf(PropTypes.string),
  /** Show favorite / list actions **/
  showActions: PropTypes.bool,
  /** Size variants */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /** Optional custom style object, applied to the outer wrapper of the component. */
  style: PropTypes.object,
  /** Optional job title */
  title: PropTypes.string,
  /** Limit each author details section to 1 line */
  truncate: PropTypes.bool,
  /** External link access, use an <a> for it */
  externalLink: PropTypes.string,
};

Person.defaultProps = {
  avatarProps: {},
  disabled: false,
  className: '',
  detailsComponent: null,
  isFavorite: false,
  imgLoading: false,
  imgUrl: '',
  linkUrl: '',
  loading: false,
  onFavoritePerson: () => {},
  onToggleListMenu: null,
  onUnfavoritePerson: () => {},
  nameSuffix: undefined,
  publications: [],
  showActions: false,
  size: 'md',
  style: {},
  title: '',
  truncate: false,
  externalLink: '',
};

export default Person;
