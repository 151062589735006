import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  node?: Element | null;
}

type PortalType = React.FC<PortalProps>;

const Portal: PortalType = ({
  children,
  node = null,
}): React.ReactPortal | null => {
  const [defaultNode, setDefaultNode] = useState<Element | null>(null);

  useEffect(() => {
    let n: Element;

    if (!node && !defaultNode) {
      n = document.createElement('div');
      setDefaultNode(n);
    }

    return () => {
      if (defaultNode) {
        document.body.removeChild(defaultNode);
      }
    };
  }, [node, defaultNode, setDefaultNode]);

  if (!node && defaultNode) {
    return ReactDOM.createPortal(children, defaultNode);
  }

  if (node) {
    return ReactDOM.createPortal(children, node);
  }

  return null;
};

export default Portal;
