import React from 'react';

// i18n
import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { Button, Body, DisplaySM, SvgIcon } from '@trendkite/ui';

import BrowseModalUpload from '../browse-asset-library/BrowseModalUpload';
import FileInput from '../story/fields/FileInput';

import messages from './StoryHeroPlaceholder.messages';

const StoryHeroPlaceholder = ({
  onSelectFiles,
  browseLink,
  handleSelectedFilesfromPopUp,
  isFromStoryKit,
  showRestUploadOptions,
}) => (
  <div className={StoryHeroPlaceholder.baseClass}>
    <div className={`${StoryHeroPlaceholder.baseClass}__img`}>
      <SvgIcon icon="camera" width={54} height={44} />
    </div>
    <div className={`${StoryHeroPlaceholder.baseClass}__title`}>
      <DisplaySM color="light">
        <TranslatedMessage {...messages.heroImage} />
      </DisplaySM>
    </div>
    <div className={`${StoryHeroPlaceholder.baseClass}__info`}>
      <Body color="light">
        <TranslatedMessage {...messages.bestResultImageMessage} />
      </Body>
    </div>
    {!browseLink && (
      <div className={`${StoryHeroPlaceholder.baseClass}__action`}>
        <FileInput onDrop={onSelectFiles}>
          <Button modifiers={['link']}>
            <SvgIcon icon="upload" width={14} height={14} />
            &nbsp;&nbsp;
            <TranslatedMessage {...messages.uploadImage} />
          </Button>
        </FileInput>
      </div>
    )}
    {browseLink && (
      <div className={`${StoryHeroPlaceholder.baseClass}__action`}>
        <BrowseModalUpload
          handleSelectedFilesfromPopUp={handleSelectedFilesfromPopUp}
          onSelectFiles={onSelectFiles}
          isFromStoryKit={isFromStoryKit}
          showRestUploadOptions={showRestUploadOptions}
        />
      </div>
    )}
  </div>
);

StoryHeroPlaceholder.baseClass = 'tk-story-hero-placeholder';

StoryHeroPlaceholder.propTypes = {
  onSelectFiles: PropTypes.func.isRequired,
  handleSelectedFilesfromPopUp: PropTypes.func.isRequired,
  browseLink: PropTypes.bool,
  isFromStoryKit: PropTypes.bool,
  showRestUploadOptions: PropTypes.bool,
};

export default StoryHeroPlaceholder;
