import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Mouse = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M17.339 13.943l-2.767-4.001a4.13 4.13 0 00-1.203-1.157 4.89 4.89 0 00-1.555-.648 5.375 5.375 0 00-1.726-.114 5.035 5.035 0 00-1.698.465c-.115-.182-.22-.36-.316-.535a4.505 4.505 0 01-.243-.512 2.785 2.785 0 01-.162-.488 2.066 2.066 0 01-.054-.457c0-.136.01-.28.031-.43a1.71 1.71 0 01.122-.438 1.24 1.24 0 01.234-.383c.102-.114.234-.206.397-.273a.645.645 0 00.09-.047.177.177 0 00.054-.028.78.78 0 00.054-.043.419.419 0 00.13-.164.47.47 0 000-.379.494.494 0 00-.13-.168.83.83 0 00-.158-.094.531.531 0 00-.176-.046.757.757 0 00-.189.007.647.647 0 00-.171.055 2.375 2.375 0 00-.622.38 2.06 2.06 0 00-.45.542 2.54 2.54 0 00-.275.688 3.412 3.412 0 00-.095.82c0 .204.024.41.072.622.048.21.114.423.198.637.09.219.196.439.316.66.12.222.255.444.405.668-.39.318-.704.678-.942 1.079a3.582 3.582 0 00-.428 2.602c.097.454.289.883.577 1.29l2.767 4.001c.216.313.47.59.762.832.29.243.608.447.95.614.349.161.712.285 1.09.371a5.192 5.192 0 001.763.094 5.16 5.16 0 001.194-.281c.192-.07.381-.156.568-.254a4.411 4.411 0 001.356-1.071c.358-.427.608-.891.753-1.391a3.489 3.489 0 00.094-1.528 3.548 3.548 0 00-.617-1.497zm-5.831-.64a.71.71 0 01-.126.062 1.17 1.17 0 01-.266.066.816.816 0 01-.14.012 1.087 1.087 0 01-.469-.11.921.921 0 01-.369-.288l-1.325-1.813a.777.777 0 01-.14-.317.693.693 0 01.014-.324.736.736 0 01.149-.305.932.932 0 01.284-.235.976.976 0 01.365-.128c.129-.019.256-.015.383.011.126.026.243.07.351.133a.867.867 0 01.27.242l1.325 1.806a.84.84 0 01.14.324c.021.112.02.22-.005.324a.84.84 0 01-.157.305.932.932 0 01-.284.235z" />
  </svg>
);
Mouse.propTypes = { fill: PropTypes.string };
Mouse.defaultProps = { fill: iconColorsMap.mainicon };

export default Mouse;
