import {
  addContactImagePlaceholders,
  addImagesToContacts,
} from 'reducers/contacts/contact-images';

import { CONTACT_IMAGES_ENDPOINT } from '../constants/apis';
import * as restService from '../services/rest-service/rest-service';

export const addContactToList = contact => ({
  type: 'ADD_CONTACT_TO_LIST',
  id: contact.id,
});

const buildRequestBodyForImages = authors => {
  const containsRequiredFieldsForImage = author =>
    author.twitterHandles !== undefined && author.twitterHandles.length;

  return {
    authors: authors.filter(containsRequiredFieldsForImage).map(author => ({
      id: author.id,
      twitterUsername: author.twitterHandles[0], // there may be multiple twitter handles, so use the first
    })),
  };
};

const fetchImagesForContacts = (
  contactsToFetchImagesFor,
  listName,
) => dispatch => {
  if (!contactsToFetchImagesFor.length) {
    dispatch({
      type: `ADD_IMAGES_TO_CONTACTS_IN_LIST_${listName}`,
      images: [],
    });

    return;
  }

  const requestbody = buildRequestBodyForImages(contactsToFetchImagesFor);
  if (!requestbody.authors.length) {
    //no twitter information in the list of contacts.
    dispatch({
      type: `ADD_IMAGES_TO_CONTACTS_IN_LIST_${listName}`,
      images: [],
    });

    return;
  }

  restService
    .performPost(CONTACT_IMAGES_ENDPOINT, requestbody)
    .then(response => {
      if (response.data !== undefined) {
        dispatch({
          type: `ADD_IMAGES_TO_CONTACTS_IN_LIST_${listName}`,
          images: response.data,
        });

        dispatch(addImagesToContacts(response.data, contactsToFetchImagesFor));
      }
    })
    .catch(() => {
      dispatch({
        type: `ADD_IMAGES_TO_CONTACTS_IN_LIST_${listName}`,
        images: [],
      });
    });
};

export const addContactsToList = (contacts, listName) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: `ADD_CONTACTS_TO_LIST_${listName}`,
    contacts,
  });

  const formattedContacts = getState()[listName];
  // get images that are loading and do not have url
  const contactsToFetchImagesFor = formattedContacts.filter(
    contact => contact.image.loading && contact.image.url === '',
  );

  dispatch(fetchImagesForContacts(contactsToFetchImagesFor, listName));
};

export const removeAllContactsFromList = listName => ({
  type: `REMOVE_ALL_CONTACTS_FROM_LIST_${listName}`,
});

export const removeContactsFromList = (contactsToRemove, listName) => ({
  type: `REMOVE_CONTACTS_FROM_LIST_${listName}`,
  contacts: contactsToRemove,
});

export const replaceContactsInList = (contacts, listName) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: `REPLACE_CONTACTS_IN_LIST_${listName}`,
    contacts,
  });

  dispatch(addContactImagePlaceholders(contacts.map(contact => contact.id)));

  const formattedContacts = getState()[listName];
  // get images that are loading and do not have url
  const contactsToFetchImagesFor = formattedContacts.filter(
    contact => contact.image.loading && contact.image.url === '',
  );

  dispatch(fetchImagesForContacts(contactsToFetchImagesFor, listName));
};

export const addContactListInfo = list => ({
  type: 'ADD_LIST_INFO',
  list,
});

export const showContactListDrawer = author => ({
  type: 'SHOW_CONTACT_LISTS_DRAWER',
  authorId: author.id,
  authorName: author.name,
  hasContactMethods: author.hasContactMethods,
  listIsRequested: author.listIsRequested,
});

export const hideContactListDrawer = () => ({
  type: 'HIDE_CONTACT_LISTS_DRAWER',
});
