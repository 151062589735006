import { SEARCH_DETAIL_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

export const GET_SEARCH_HISTORY = 'search-history/GET_SEARCH_HISTORY';
export const GET_SEARCH_HISTORY_SUCCESS =
  'search-history/GET_SEARCH_HISTORY_SUCCESS';
export const GET_SEARCH_HISTORY_ERROR =
  'search-history/GET_SEARCH_HISTORY_ERROR';

export const initialState = {
  history: [],
  loading: false,
  error: '',
};

const searchHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_HISTORY:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_SEARCH_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        history: action.payload,
        error: '',
      };
    }
    case GET_SEARCH_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export const getSearchHistoryById = (searchId, date) => async dispatch => {
  dispatch({ type: GET_SEARCH_HISTORY });

  try {
    const results = await performGet(
      `${SEARCH_DETAIL_ENDPOINT}/${searchId}/history`,
      { date },
    );
    dispatch({ type: GET_SEARCH_HISTORY_SUCCESS, payload: results.data });
  } catch (err) {
    dispatch({
      type: GET_SEARCH_HISTORY_ERROR,
      payload: err.response.data.error,
    });
    throw err;
  }
};

export default searchHistoryReducer;
