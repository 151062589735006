import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from './../button-group/ButtonGroup';

class ButtonSwitch extends Component {
  static baseClass = 'tk-button-switch';

  buttonModifiers = ['round'];

  renderButtons = () => {
    const { children } = this.props;
    return React.Children.map(children, button => {
      const { selected } = button.props;
      return React.cloneElement(button, {
        modifiers: [
          ...this.buttonModifiers,
          `${selected ? 'primaryTeal' : 'tertiary'}`,
        ],
      });
    });
  };

  render() {
    const { className } = this.props;
    return (
      <ButtonGroup className={className}>{this.renderButtons()}</ButtonGroup>
    );
  }
}

ButtonSwitch.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ButtonSwitch.defaultProps = {
  className: '',
};

export default ButtonSwitch;
