export default {
  title: {
    id: 'STORY-CONTACT-DISPLAY_TITLE',
    defaultMessage: 'Contact',
    description:
      'Campaigns > Story Kit > Story Edit Page: content contact title',
  },
  addContact: {
    id: 'STORY-CONTACT-DISPLAY_ADD_CONTACT',
    defaultMessage: 'Add Contact',
    description:
      'Campaigns > Story Kit > Story Edit Page: content contact add link',
  },
  primaryContactRequired: {
    id: 'STORY-CONTACT-DISPLAY_PRIMARY_CONTACT_REQUIRED',
    defaultMessage: 'A primary contact is required to publish',
    description:
      'Campaigns > Story Kit > Story Edit Page: content contact primary contact required message',
  },
  placeholder: {
    id: 'STORY-CONTACT-DISPLAY_PLACEHOLDER',
    defaultMessage: 'Add a primary contact for this story',
    description:
      'Campaigns > Story Kit > Story Edit Page: content contact placeholder text',
  },
};
