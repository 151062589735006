export const MAX_AMOUNT_OF_URLS_IN_BULK_ADD = 25;
export const TAGGED_ARTICLE_STATUS = {
  pending: {
    label: 'Pending',
    labelI18n: 'constantsTaggedArticlePendingStatus',
    id: 'pending',
    priority: 3,
    statusIcon: 'hyperlink',
    statusIconColor: 'neutral',
  },
  processing: {
    label: 'Processing...',
    labelI18n: 'constantsTaggedArticleProcessingStatus',
    id: 'processing',
    priority: 3,
    statusIcon: 'hyperlink',
    statusIconColor: 'neutral',
  },
  failed: {
    label: 'Failed',
    labelI18n: 'constantsTaggedArticleFailedStatus',
    id: 'failed',
    priority: 2,
    statusIcon: 'notice',
    statusIconColor: 'danger',
  },
  tagged: {
    label: 'Tagged',
    labelI18n: 'constantsTaggedArticleTaggedStatus',
    id: 'tagged',
    priority: 1,
    statusIcon: 'check',
    statusIconColor: 'success',
  },
  toBeTagged: {
    label: 'Valid',
    labelI18n: 'constantsTaggedArticleToBeAddedStatus',
    id: 'toBeTagged',
    priority: 2,
    statusIcon: 'check',
    statusIconColor: 'neutral',
  },
  exists: {
    label: 'Valid',
    labelI18n: 'constantsTaggedArticleExistsStatus',
    id: 'exists',
    priority: 4,
    statusIcon: 'check',
    statusIconColor: 'neutral',
  },
};
