import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const ListVisor = ({ className = '', children, ...props }) => {
  const wrappingClassName = classNames(ListVisor.baseClass, className);

  const newProps = {
    ...props,
    title: null,
    className: wrappingClassName,
  };

  return <div {...newProps}>{children}</div>;
};

ListVisor.baseClass = 'tk-list-visor';

ListVisor.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ListVisor.defaultProps = {
  className: '',
};

export default ListVisor;
