import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';

import {
  OUTREACH_DKIM_STATUS_ENDPOINT,
  OUTREACH_ENDPOINT,
  OUTREACH_INTEGRATION_VERIFICATION_ENDPOINT,
  OUTREACH_EMAIL_DELETE_ENDPOINT,
  OUTREACH_EMAILS_GET_MESSAGES_STATS,
  CAMPAIGNS_TITLES_ENDPOINT,
} from 'constants/apis';
import emailSettingsMessages from 'pages/EmailSettings/EmailSettings.messages';

import {
  performGet as mockPerformGet,
  performPost as mockPerformPost,
} from 'reducers/outreach/mockEndpoints';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';
import { defaultOutreachIntegrationIdSelector } from 'selectors/outreach';

import {
  performGet,
  performPost,
  performDelete,
  performPatch,
} from 'services/rest-service/rest-service';
import { getDisplayNamesByIds } from 'services/user-service/user-service';

import { hasMockOutreachDataSelector } from './mockData';
export const GET_SELECTED_EMAIL_TYPES = 'outreach/GET_SELECTED_EMAIL_TYPES';
export const GET_OUTREACH_INTEGRATIONS = 'outreach/GET_OUTREACH_INTEGRATIONS';
export const GET_OUTREACH_INTEGRATIONS_SUCCESS =
  'outreach/GET_OUTREACH_INTEGRATIONS_SUCCESS';
export const GET_OUTREACH_INTEGRATIONS_VERIFICATIONS_SUCCESS =
  'outreact/GET_OUTREACH_INTEGRATIONS_VERIFICATIONS_SUCCESS';
export const GET_OUTREACH_INTEGRATIONS_ERROR =
  'outreach/GET_OUTREACH_INTEGRATIONS_ERROR';
export const SEND_DOMAIN_VERIFICATION = 'outreach/SEND_DOMAIN_VERIFICATION';
export const SEND_DOMAIN_VERIFICATION_SUCCESS =
  'outreach/SEND_DOMAIN_VERIFICATION_SUCCESS';
export const SEND_DOMAIN_VERIFICATION_ERROR =
  'outreach/SEND_DOMAIN_VERIFICATION_ERROR';
export const GET_DEFAULT_OUTREACH_INTEGRATION =
  'outreach/GET_DEFAULT_OUTREACH_INTEGRATION';
export const GET_DEFAULT_OUTREACH_INTEGRATION_SUCCESS =
  'outreach/GET_DEFAULT_OUTREACH_INTEGRATION_SUCCESS';
export const GET_DEFAULT_OUTREACH_INTEGRATION_ERROR =
  'outreach/GET_DEFAULT_OUTREACH_INTEGRATION_ERROR';
export const SAVE_OUTREACH_INTEGRATION = 'outreach/SAVE_OUTREACH_INTEGRATION';
export const SAVE_OUTREACH_INTEGRATION_SUCCESS =
  'outreach/SAVE_OUTREACH_INTEGRATION_SUCCESS';
export const SAVE_OUTREACH_INTEGRATION_ERROR =
  'outreach/SAVE_OUTREACH_INTEGRATION_ERROR';
export const GET_INTEGRATION_OWNERS = 'outreach/GET_INTEGRATION_OWNERS';
export const GET_INTEGRATION_OWNERS_SUCCESS =
  'outreach/GET_INTEGRATION_OWNERS_SUCCESS';
export const GET_INTEGRATION_OWNERS_ERROR =
  'outreach/GET_INTEGRATION_OWNERS_ERROR';
export const DELETE_OUTREACH_INTEGRATION =
  'outreach/DELETE_OUTREACH_INTEGRATION';
export const DELETE_OUTREACH_INTEGRATION_SUCCESS =
  'outreach/DELETE_OUTREACH_INTEGRATION_SUCCESS';
export const DELETE_OUTREACH_INTEGRATION_ERROR =
  'outreach/DELETE_OUTREACH_INTEGRATION_ERROR';
export const EDIT_OUTREACH_INTEGRATION = 'outreach/EDIT_OUTREACH_INTEGRATION';
export const EDIT_OUTREACH_INTEGRATION_SUCCESS =
  'outreach/EDIT_OUTREACH_INTEGRATION_SUCCESS';
export const EDIT_OUTREACH_INTEGRATION_ERROR =
  'outreach/EDIT_OUTREACH_INTEGRATION_ERROR';
export const GET_OUTREACH_EMAIL_MESSAGES_STATS =
  'outreach/GET_OUTREACH_EMAIL_MESSAGES_STATS';
export const GET_OUTREACH_EMAIL_MESSAGES_STATS_SUCCESS =
  'outreach/GET_OUTREACH_EMAIL_MESSAGES_STATS_SUCCESS';
export const GET_OUTREACH_EMAIL_MESSAGES_STATS_ERROR =
  'outreach/GET_OUTREACH_EMAIL_MESSAGES_STATS_ERROR';
export const GET_OUTREACH_INTEGRATION_DKIM_STATUS_START =
  'outreach/GET_OUTREACH_INTEGRATION_DKIM_STATUS';
export const GET_OUTREACH_INTEGRATION_DKIM_STATUS_SUCCESS =
  'outreach/GET_OUTREACH_INTEGRATION_DKIM_STATUS_SUCCESS';
export const GET_OUTREACH_INTEGRATION_DKIM_STATUS_ERROR =
  'outreach/GET_OUTREACH_INTEGRATION_DKIM_STATUS_ERROR';
export const GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_START =
  'outreach/GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_START';
export const GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_SUCCESS =
  'outreach/GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_SUCCESS';
export const GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_ERROR =
  'outreach/GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_ERROR';
export const SET_SELECTED_SIDEBAR_FILTER =
  'outreach/SET_SELECTED_SIDEBAR_FILTER';
export const SET_MODIFIED_SIDEBAR_COUNT = 'outreach/SET_MODIFIED_SIDEBAR_COUNT';
export const SET_HAS_SELECTED_ANOTHER_CATEGORY =
  'outreach/HAS_SELECTED_ANOTHER_CATEGORY';
export const SET_EMAIL_DELETED_AND_REDIRECT =
  'outreach/SET_EMAIL_DELETED_AND_REDIRECT';
export const SET_USERS_FILTERED_DATA = 'outreach/SET_USERS_FILTERED_DATA';
export const SET_CAMPAIGNS_FOR_FILTERING =
  'outreach/SET_CAMPAIGNS_FOR_FILTERING';
export const SET_CAMPAIGN_BACK_BUTTON = 'outreach/SET_CAMPAIGN_BACK_BUTTON';

const initialState = {
  domains: [],
  dkimStatus: {},
  saveLoading: false,
  loading: false,
  sendDomainVerificationLoading: false,
  error: false,
  integrations: [],
  campaignsForFilter: [],
  userDefaultIntegrationId: null,
  userOutreachMessageStats: {},
  selectedSidebarFilter: 'all',
  modifiedFromSidebarCount: false,
  emailDeletedAndRedirect: false,
  usersFilteredData: {},
  scheduledEmails: {
    loading: false,
    results: {},
  },
  selectedEmailTypes: [],
  campaignBackButon: true,
};

const outreachIntegrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTREACH_INTEGRATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_SELECTED_EMAIL_TYPES:
      return {
        ...state,
        selectedEmailTypes: action.payload,
      };
    case GET_OUTREACH_INTEGRATIONS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        integrations: [],
        userDefaultIntegrationId: null,
      };
    case GET_OUTREACH_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        integrations: action.payload.integrations,
        userDefaultIntegrationId: action.payload.userDefaultIntegrationId,
      };
    case SEND_DOMAIN_VERIFICATION:
      return {
        ...state,
        sendDomainVerificationLoading: true,
      };
    case SEND_DOMAIN_VERIFICATION_SUCCESS:
    case SEND_DOMAIN_VERIFICATION_ERROR:
      return {
        ...state,
        sendDomainVerificationLoading: false,
      };
    case GET_OUTREACH_INTEGRATIONS_VERIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        domains: action.payload.domains,
        integrations: action.payload.emailAccounts,
        userDefaultIntegrationId: action.payload.userDefaultIntegrationId,
      };
    case SAVE_OUTREACH_INTEGRATION:
      return {
        ...state,
        saveLoading: true,
      };
    case SAVE_OUTREACH_INTEGRATION_ERROR:
      return {
        ...state,
        saveLoading: false,
      };
    case SAVE_OUTREACH_INTEGRATION_SUCCESS: {
      const integrations = [...state.integrations];
      integrations.push(action.payload);
      return {
        ...state,
        saveLoading: false,
        integrations,
      };
    }
    case GET_INTEGRATION_OWNERS_SUCCESS: {
      const displayNames = action.payload;
      const integrations = [...state.integrations].map(integration => ({
        ...integration,
        ownedBy: displayNames[integration.user.id] || '',
      }));

      return {
        ...state,
        integrations,
      };
    }
    case SET_CAMPAIGN_BACK_BUTTON: {
      return {
        ...state,
        campaignBackButon: action.payload,
      };
    }
    case DELETE_OUTREACH_INTEGRATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_OUTREACH_INTEGRATION_SUCCESS: {
      const integrations = [...state.integrations];
      remove(integrations, { id: action.payload.id });
      return {
        ...state,
        loading: false,
        error: false,
        integrations,
        userDefaultIntegrationId:
          state.userDefaultIntegrationId === action.payload.id
            ? null
            : state.userDefaultIntegrationId,
      };
    }
    case DELETE_OUTREACH_INTEGRATION_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case EDIT_OUTREACH_INTEGRATION_SUCCESS: {
      const integrations = [...state.integrations];
      const index = findIndex(integrations, i => i.id === action.payload.id);
      const ownedBy = integrations[index]?.ownedBy || '';

      integrations[index] = action.payload;
      integrations[index].ownedBy = ownedBy;

      return {
        ...state,
        integrations,
      };
    }
    case GET_DEFAULT_OUTREACH_INTEGRATION_SUCCESS:
      return {
        ...state,
        userDefaultIntegrationId: action.payload.defaultOutreachIntegration,
      };
    case GET_OUTREACH_EMAIL_MESSAGES_STATS_SUCCESS:
      return {
        ...state,
        userOutreachMessageStats: action.payload,
      };
    case GET_OUTREACH_EMAIL_MESSAGES_STATS_ERROR:
      return {
        ...state,
        error: true,
      };
    case SET_SELECTED_SIDEBAR_FILTER:
      return {
        ...state,
        selectedSidebarFilter: action.payload,
      };
    case SET_MODIFIED_SIDEBAR_COUNT:
      return {
        ...state,
        modifiedFromSidebarCount: action.payload,
      };
    case SET_EMAIL_DELETED_AND_REDIRECT:
      return {
        ...state,
        emailDeletedAndRedirect: action.payload,
      };
    case SET_USERS_FILTERED_DATA: {
      return {
        ...state,
        usersFilteredData: action.payload,
      };
    }
    case GET_OUTREACH_INTEGRATION_DKIM_STATUS_START:
      return {
        ...state,
        dkimStatus: {
          loading: true,
        },
      };
    case GET_OUTREACH_INTEGRATION_DKIM_STATUS_SUCCESS:
      return {
        ...state,
        dkimStatus: {
          loading: false,
          data: action.payload,
        },
      };
    case GET_OUTREACH_INTEGRATION_DKIM_STATUS_ERROR:
      return {
        ...state,
        dkimStatus: {
          loading: false,
        },
      };
    case GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_START:
      return {
        ...state,
        scheduledEmails: {
          loading: true,
        },
      };
    case GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_SUCCESS:
      return {
        ...state,
        scheduledEmails: {
          loading: false,
          results: action.payload,
        },
      };
    case GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_ERROR:
      return {
        ...state,
        scheduledEmails: {
          loading: false,
        },
      };
    case SET_CAMPAIGNS_FOR_FILTERING: {
      return {
        ...state,
        campaignsForFilter: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export const setCampaignsFilterData = () => async dispatch => {
  const { data } = await performGet(`${CAMPAIGNS_TITLES_ENDPOINT}`);

  if (data) {
    dispatch({ type: SET_CAMPAIGNS_FOR_FILTERING, payload: data.results });
  }
};

export const setSelectedEmailTypes = selectedEmailTypes => ({
  type: GET_SELECTED_EMAIL_TYPES,
  payload: selectedEmailTypes,
});

export const setUsersFilteredData = filters => ({
  type: SET_USERS_FILTERED_DATA,
  payload: filters,
});

export const setSelectedSidebarFilter = filter => ({
  type: SET_SELECTED_SIDEBAR_FILTER,
  payload: filter,
});

export const setModifiedFromSidebarCount = removed => ({
  type: SET_MODIFIED_SIDEBAR_COUNT,
  payload: removed,
});

export const setEmailDeletedAndRedirect = status => ({
  type: SET_EMAIL_DELETED_AND_REDIRECT,
  payload: status,
});

export const getMessageCenterEmailStats = ({
  ownedIntegrationUuids,
  isContacts,
  emailTypes,
  influencerIds,
}) => async (dispatch, getState) => {
  let response;

  const state = getState();
  if (state.outreachIntegrations.selectedEmailTypes?.length) {
    emailTypes = state.outreachIntegrations.selectedEmailTypes;
  }
  const useMockData = hasMockOutreachDataSelector(state);
  let previousEmailTypes = [];

  if (emailTypes) {
    if (emailTypes.length > 0) {
      previousEmailTypes = emailTypes;
    }
  }

  try {
    if (useMockData) {
      response = await mockPerformPost(OUTREACH_EMAILS_GET_MESSAGES_STATS);
    } else {
      response = await performPost(OUTREACH_EMAILS_GET_MESSAGES_STATS, {
        integrationUuids: ownedIntegrationUuids,
        influencerIds: influencerIds ?? [],
        emailTypes: previousEmailTypes ?? [],
        ...(isContacts && { requiredFields: ['authors'] }),
      });
    }

    dispatch({
      type: GET_OUTREACH_EMAIL_MESSAGES_STATS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: GET_OUTREACH_EMAIL_MESSAGES_STATS_ERROR });
  }
};

const getIntegrationOwnerNames = ownerIds => async dispatch => {
  dispatch({ type: GET_INTEGRATION_OWNERS });
  try {
    const displayNames = await getDisplayNamesByIds(ownerIds);
    dispatch({ type: GET_INTEGRATION_OWNERS_SUCCESS, payload: displayNames });
    dispatch({ type: SET_CAMPAIGN_BACK_BUTTON, payload: false });
  } catch (e) {
    dispatch({ type: GET_INTEGRATION_OWNERS_ERROR });
    throw e;
  }
};

export const getDefaultOutreachIntegration = () => async dispatch => {
  let response;

  dispatch({ type: GET_DEFAULT_OUTREACH_INTEGRATION });

  try {
    response = await performGet(`${OUTREACH_ENDPOINT}/integration/default`);
  } catch (e) {
    dispatch({ type: GET_DEFAULT_OUTREACH_INTEGRATION_ERROR });
    throw e;
  }

  return dispatch({
    type: GET_DEFAULT_OUTREACH_INTEGRATION_SUCCESS,
    payload: response.data,
  });
};

export const getOutreachIntegrationsAndVerifications = intl => async (
  dispatch,
  getState,
) => {
  let response;
  let error = null;
  const state = getState();
  const useMockData = hasMockOutreachDataSelector(state);

  dispatch({ type: GET_OUTREACH_INTEGRATIONS });

  try {
    if (useMockData) {
      response = await mockPerformGet(`${OUTREACH_ENDPOINT}/integration`);
    } else {
      response = await performGet(OUTREACH_INTEGRATION_VERIFICATION_ENDPOINT);
    }

    dispatch({
      type: GET_OUTREACH_INTEGRATIONS_VERIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    error = e;
    throw e;
  }

  if (error) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          emailSettingsMessages.outreactIntegrationAndVerificationError,
        ),
        status: 'danger',
      }),
    );
    return dispatch({ type: GET_OUTREACH_INTEGRATIONS_ERROR });
  }

  const integrationsOwnerIds = response.data.emailAccounts?.map(
    emailAccount => emailAccount.user.id,
  );

  const ownerIds = uniq(integrationsOwnerIds);

  if (!ownerIds.length) {
    return [];
  }

  return dispatch(getIntegrationOwnerNames(ownerIds));
};

export const sendDomainAuthenticationSupportEmail = ({
  dataRequest,
  intl,
}) => async dispatch => {
  let status;
  let text;

  dispatch({
    type: SEND_DOMAIN_VERIFICATION,
  });

  try {
    await performPost(`${OUTREACH_ENDPOINT}/domain/request`, dataRequest);

    dispatch(getOutreachIntegrationsAndVerifications(intl));

    dispatch({
      type: SEND_DOMAIN_VERIFICATION_SUCCESS,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Support Email sent!',
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({
      type: SEND_DOMAIN_VERIFICATION_ERROR,
    });
    if (
      e.response?.data?.errors.includes('no new domains to request') &&
      e.response.status === 400
    ) {
      text = intl.formatMessage(
        emailSettingsMessages.noNewDomainsKiteErrorMessage,
      );
      status = 'danger';
    } else {
      text = intl.formatMessage(emailSettingsMessages.defaultKiteErrorMessage);
      status = 'danger';
    }
    dispatch(
      addPageMessageWithDefaultTimeout({
        text,
        status,
      }),
    );
  }
};

export const getOutreachIntegrations = () => async (dispatch, getState) => {
  let response;
  let error = null;
  const state = getState();
  const useMockData = hasMockOutreachDataSelector(state);

  dispatch({ type: GET_OUTREACH_INTEGRATIONS });

  try {
    if (useMockData) {
      response = await mockPerformGet(`${OUTREACH_ENDPOINT}/integration`);
    } else {
      response = await performGet(`${OUTREACH_ENDPOINT}/integration`);
    }
  } catch (e) {
    error = e;
    throw e;
  }

  if (error) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Hmm, something went wrong. Please try again later.',
        status: 'danger',
      }),
    );
    return dispatch({ type: GET_OUTREACH_INTEGRATIONS_ERROR });
  }

  dispatch({
    type: GET_OUTREACH_INTEGRATIONS_SUCCESS,
    payload: response.data,
  });

  const ownerIds = uniq(response.data.integrations.map(p => p.user.id));

  if (!ownerIds.length) {
    return [];
  }

  return dispatch(getIntegrationOwnerNames(ownerIds));
};

export const resendVerificationEmail = ({
  email,
  verificationUrl,
  supportUrl,
  intl,
  messages,
  senderName = null,
  fromRow = false,
}) => async dispatch => {
  try {
    const requestBody = {
      email,
      verificationUrl,
      supportUrl,
    };

    if (senderName) {
      requestBody.senderName = senderName;
    }

    await performPost(
      `${OUTREACH_ENDPOINT}/email-announcement/verification/`,
      requestBody,
    );

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.resendVerificationEmailSuccess, {
          EMAIL_ADDRESS: email,
        }),
        status: 'success',
      }),
    );

    if (!fromRow) {
      dispatch(getOutreachIntegrationsAndVerifications(intl));
    }
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.resendVerificationEmailError),
        status: 'danger',
      }),
    );
  }
};

export const saveOutreachIntegration = ({ intl, integration }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const currentDefault = defaultOutreachIntegrationIdSelector(state);
  let response;
  let error;
  let status;
  let text;

  dispatch({ type: SAVE_OUTREACH_INTEGRATION });

  try {
    response = await performPost(`${OUTREACH_ENDPOINT}/integration/`, {
      authCode: integration,
      default: !currentDefault,
    });
  } catch (e) {
    error = e;
    text = 'Hmm, something went wrong. Please try again later.';
    status = 'danger';

    text = e && e.response.status === 405 ? e.response.data.error : text;

    if (
      e &&
      e.response.status === 400 &&
      e.response.data.error === 'Account already exists'
    ) {
      text = e.response.data.error;
      status = 'info';
    }
  }

  if (text) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text,
        status,
      }),
    );
  }

  if (error && status === 'danger') {
    dispatch({ type: SAVE_OUTREACH_INTEGRATION_ERROR });
    throw error;
  } else if (error && status === 'info') {
    return dispatch({ type: SAVE_OUTREACH_INTEGRATION_ERROR });
  }

  if (!currentDefault) dispatch(getDefaultOutreachIntegration());

  dispatch({
    type: SAVE_OUTREACH_INTEGRATION_SUCCESS,
    payload: response.data,
  });

  return dispatch(getOutreachIntegrationsAndVerifications(intl));
};

export const deleteOutreachIntegrationById = ({
  intl,
  integrationId,
  integrationUuid,
  outreachMessages,
  removeSent,
  userData,
}) => async dispatch => {
  let response;
  const params = {
    remove_sent: true,
  };
  const headers = {
    'X-Account-Id': userData.accountId,
    'X-User-Id': userData.userId,
  };
  const url = `${OUTREACH_ENDPOINT}/integration/${integrationId}`;
  const removeSentUrl = `${OUTREACH_EMAIL_DELETE_ENDPOINT}/${integrationUuid}`;

  dispatch({ type: DELETE_OUTREACH_INTEGRATION });

  try {
    response = await performDelete(url);
    if (removeSent) {
      //submitting data delete request to remove all user pitch data associtated with integrationUuid
      await performPost(removeSentUrl, null, params, headers);
    }
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          outreachMessages[
            removeSent
              ? 'deleteOutreachIntegrationSuccessAlt'
              : 'deleteOutreachIntegrationSuccess'
          ],
        ),
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({ type: DELETE_OUTREACH_INTEGRATION_ERROR });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text:
          'Unable to delete that account at this time. Please try again later.',
        status: 'danger',
      }),
    );
    throw e;
  }

  return dispatch({
    type: DELETE_OUTREACH_INTEGRATION_SUCCESS,
    payload: response.data,
  });
};

export const editOutreachIntegration = ({
  intl,
  integrationId,
  patchObject,
  outreachMessages,
}) => async dispatch => {
  let response;

  dispatch({ type: EDIT_OUTREACH_INTEGRATION });

  try {
    response = await performPatch(
      `${OUTREACH_ENDPOINT}/integration/${integrationId}`,
      patchObject,
    );
  } catch (e) {
    dispatch({ type: EDIT_OUTREACH_INTEGRATION_ERROR });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(outreachMessages.editOutreachIntegrationError),
        status: 'danger',
      }),
    );
    throw e;
  }

  dispatch(getDefaultOutreachIntegration());

  return dispatch({
    type: EDIT_OUTREACH_INTEGRATION_SUCCESS,
    payload: response.data,
  });
};

export const getDkimStatus = ({ email, messages }) => async dispatch => {
  let response;

  dispatch({ type: GET_OUTREACH_INTEGRATION_DKIM_STATUS_START });

  try {
    response = await performGet(
      `${OUTREACH_DKIM_STATUS_ENDPOINT}?email=${email}`,
    );
    dispatch(
      addPageMessage({
        title: messages.title,
        text: response.data.valid ? messages.valid : messages.notValid,
        status: response.data.valid ? 'success' : 'danger',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: GET_OUTREACH_INTEGRATION_DKIM_STATUS_ERROR });
  }

  return dispatch({
    type: GET_OUTREACH_INTEGRATION_DKIM_STATUS_SUCCESS,
    payload: response.data,
  });
};

export const getOutreachScheduledEmailsFromAccount = ({
  account,
  email,
  limit = 200,
}) => async dispatch => {
  dispatch({ type: GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_START });
  const query = {
    pagination: {
      offset: 0,
      limit,
    },
    search: '',
    filters: {
      accountId: `${account}`,
      status: ['SCHEDULED'],
      from: [email],
      emailTypes: ['BulkCampaign'],
    },
    view: 'all',
  };
  try {
    const { data } = await performPost(
      `${OUTREACH_ENDPOINT}/threads/query`,
      query,
    );
    dispatch({
      type: GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({ type: GET_OUTREACH_SCHEDULED_EMAILS_FROM_ACCOUNT_ERROR });
  }
};

export default outreachIntegrationsReducer;
