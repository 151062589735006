import axios from 'axios';
import qs from 'qs';
import useSWR from 'swr';

import { externalApiDomains, SECURITY_CSRF_TOKEN } from '../../constants/apis';
import { CSRF_TOKEN_HEADER_PROPERTY } from '../../constants/csrf-token';
// We're importing this using relative import because this file is being used
// In other workspaces that can't resolve webpack aliases

import axiosClient, {
  axiosWithCacheAndSerial,
  axiosWithCacheAndLimit,
  axiosWithNumbersAsStrings,
} from './axios-client';
import restServiceCache from './cache';

export const paramsSerializer = params =>
  qs.stringify(params, { indices: false });

export const request = async ({
  useLocalCache = false,
  isRateLimited,
  localCacheTtl,
  ...requestObj
}) => {
  const cachedResponse = restServiceCache.get(requestObj);

  if (useLocalCache && cachedResponse !== null) {
    // eslint-disable-next-line no-console
    console.log('cache hit', requestObj.url, requestObj.params);
    return cachedResponse;
  }

  const headers = {};

  let avoidActiveUserToken = false;

  externalApiDomains.forEach(path => {
    avoidActiveUserToken = !!(
      avoidActiveUserToken || requestObj.url.includes(path)
    );
  });

  if (window.activeUser.token && !avoidActiveUserToken) {
    headers['X-Auth-Token'] = window.activeUser.token;
  }

  if (requestObj.headers) {
    headers.authorization = `Bearer ${requestObj.headers}`;
    if (requestObj.headers.cache) {
      headers['Cache-Control'] = requestObj.headers.cache;
      headers.Pragma = 'no-cache'; // IE 11 compatibility
    }
  }

  // Looks like header is being used by something else that gets injected directly into the authorization???
  if (requestObj.additionalHeaders) {
    Object.keys(requestObj.additionalHeaders).forEach(key => {
      headers[key] = requestObj.additionalHeaders[key];
    });
  }

  const requestData = {
    method: requestObj.method,
    url: requestObj.url,
    data: requestObj.data,
    params: requestObj.params,
    headers,
    paramsSerializer,
    ...requestObj.config,
  };

  let response;
  if (isRateLimited && isRateLimited > 1) {
    response = await axiosWithCacheAndLimit(requestData);
  } else if (requestObj.convertResponseNumbersAsStrings) {
    response = await axiosWithNumbersAsStrings(requestData);
  } else if (isRateLimited && isRateLimited === 1) {
    response = await axiosWithCacheAndSerial(requestData);
  } else {
    response = await axiosClient(requestData);
  }

  if (useLocalCache) {
    try {
      restServiceCache.put(requestObj, response, localCacheTtl);
    } catch {}
  }

  return response;
};

export const requestMultiple = requests =>
  new Promise((resolve, reject) => {
    const allRequests = requests.map(multiRequest => request(multiRequest));

    Promise.all(allRequests)
      .then(responses => {
        resolve(responses);
      })
      .catch(errors => {
        reject(errors);
      });
  });

// convenience methods
// prepend with 'perform' b/c delete is reserved keyword
export const performGet = (
  url,
  params,
  headers,
  config,
  useLocalCache,
  isRateLimited,
  localCacheTtl,
) =>
  request({
    params: params || {},
    method: 'get',
    url,
    additionalHeaders: headers,
    config,
    useLocalCache,
    localCacheTtl,
    isRateLimited,
  });

export const performGetMultiple = urls => {
  const requestObjs = urls.map(url => ({
    data: {},
    params: {},
    method: 'get',
    url,
  }));

  return requestMultiple(requestObjs);
};

export const performPost = (
  url,
  data,
  params,
  headers,
  config,
  useLocalCache,
  localCacheTtl,
) =>
  request({
    additionalHeaders: headers,
    data,
    params: params || {},
    method: 'post',
    url,
    config,
    useLocalCache,
    localCacheTtl,
  });

export const getCsfrToken = () =>
  request({
    method: 'get',
    url: SECURITY_CSRF_TOKEN,
  });

export const csrfPerformPost = async (
  url,
  data,
  params,
  headers,
  config,
  useLocalCache,
  localCacheTtl,
) => {
  try {
    const {
      data: { token },
    } = await getCsfrToken();
    return request({
      additionalHeaders: {
        ...headers,
        ...{ [CSRF_TOKEN_HEADER_PROPERTY]: token },
      },
      data,
      params: params || {},
      method: 'post',
      url,
      config,
      useLocalCache,
      localCacheTtl,
    });
  } catch (error) {
    throw Error(`Error trying to get csrf token ${error}`);
  }
};

export const performPut = (
  url,
  data,
  params,
  headers,
  config,
  useLocalCache,
  localCacheTtl,
) =>
  request({
    additionalHeaders: headers,
    data,
    params: params || {},
    method: 'put',
    url,
    config,
    useLocalCache,
    localCacheTtl,
  });

export const csrfPerformPut = async (
  url,
  data,
  params,
  headers,
  config,
  useLocalCache,
  localCacheTtl,
) => {
  try {
    const {
      data: { token },
    } = await getCsfrToken();
    return request({
      additionalHeaders: {
        ...headers,
        ...{ [CSRF_TOKEN_HEADER_PROPERTY]: token },
      },
      data,
      params: params || {},
      method: 'put',
      url,
      config,
      useLocalCache,
      localCacheTtl,
    });
  } catch (error) {
    throw Error(`Error trying to get csrf token ${error}`);
  }
};

export const performDelete = (
  url,
  data,
  params,
  headers,
  config,
  useLocalCache,
  localCacheTtl,
) =>
  request({
    additionalHeaders: headers,
    data,
    params: params || {},
    method: 'delete',
    url,
    config,
    useLocalCache,
    localCacheTtl,
  });

export const csrfPerformDelete = async (
  url,
  data,
  params,
  headers,
  config,
  useLocalCache,
  localCacheTtl,
) => {
  try {
    const {
      data: { token },
    } = await getCsfrToken();
    return request({
      additionalHeaders: {
        ...headers,
        ...{ [CSRF_TOKEN_HEADER_PROPERTY]: token },
      },
      data,
      params: params || {},
      method: 'delete',
      url,
      config,
      useLocalCache,
      localCacheTtl,
    });
  } catch (error) {
    throw Error(`Error trying to get csrf token ${error}`);
  }
};

export const performPatch = (
  url,
  data,
  params,
  headers,
  config,
  useLocalCache,
  localCacheTtl,
) =>
  request({
    additionalHeaders: headers,
    data,
    params: params || {},
    method: 'patch',
    url,
    config,
    useLocalCache,
    localCacheTtl,
  });

const fetchData = async encodedRequestObject =>
  request(JSON.parse(encodedRequestObject));

export const SWRStatus = {
  Error: 'error',
  InitialLoading: 'initial-loading',
  Success: 'success',
};

const getSWRStatus = ({ data, error }) => {
  if (error) return SWRStatus.Error;

  return typeof data === 'undefined'
    ? SWRStatus.InitialLoading
    : SWRStatus.Success;
};

const injectStatusProps = obj => {
  const status = getSWRStatus(obj);
  obj.status = status;
  obj.isInitialLoading = status === SWRStatus.InitialLoading;
  obj.isSuccess = status === SWRStatus.Success;
  obj.isError = status === SWRStatus.Error;
  return obj;
};

// SWR hook helper method for generating consistent cache keys
/*
  Sorts `{requestObj}` keys and stringifies them for you,
  so SWR can use it as a key.
*/
export const getStringifiedRequestObject = requestObj => {
  const { config, data, headers, method, params, url } = requestObj;

  return JSON.stringify({
    config,
    data,
    headers,
    method,
    params,
    url,
    cache: { ignoreCache: true },
  });
};

// Hook for wrapping SWR
/*
  Sorts object's top level keys and stringifies the args for you,
  so SWR can use it as a key.
  Supports the same request object shape as `request()`
*/
export const useFetchDataSWR = (requestObj, options = {}) => {
  if (!options.fetcher) {
    options.fetcher = fetchData;
  }

  const swr = useSWR(() => {
    if (!requestObj) {
      return null;
    }

    /*
      This serves the purpose of sorting the keys, and improving visibility
      into the params.
    */
    return getStringifiedRequestObject(requestObj);
  }, options);

  return injectStatusProps(swr);
};

export const getCancelTokenSource = () => {
  const { CancelToken } = axios;
  return CancelToken.source();
};

export const isRequestCancel = err => axios.isCancel(err);
