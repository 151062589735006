import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import {
  CAMPAIGN_ENDPOINT,
  CONTACT_IMAGES_ENDPOINT,
  CONTACT_LISTS_ENDPOINT,
  CONTACT_LISTS_ALL_OVERVIEW_ENDPOINT,
  FAVORITE_CONTACTS_ENDPOINT,
} from 'constants/apis';

import { UPDATE_CAMPAIGN_PROPERTY } from 'reducers/campaigns/campaign';
import { addPageMessage } from 'reducers/page-messages';
import {
  performGet,
  performDelete,
  performPost,
  performPut,
} from 'services/rest-service/rest-service';

export const RESET_CAMPAIGN_CONTACTS =
  'campaigns/contacts/RESET_CAMPAIGN_CONTACTS';
export const GET_CAMPAIGN_CONTACTS = 'campaigns/contacts/GET_CAMPAIGN_CONTACTS';
export const GET_CAMPAIGN_CONTACTS_RECEIVED =
  'campaigns/contacts/GET_CAMPAIGN_CONTACTS_RECEIVED';
export const GET_CAMPAIGN_CONTACTS_ERROR =
  'campaigns/contacts/GET_CAMPAIGN_CONTACTS_ERROR';
export const DELETE_CAMPAIGN_CONTACT =
  'campaigns/contacts/DELETE_CAMPAIGN_CONTACT';
export const DELETE_CAMPAIGN_CONTACT_SUCCESS =
  'campaigns/contacts/DELETE_CAMPAIGN_CONTACT_SUCCESS';
export const DELETE_CAMPAIGN_CONTACT_ERROR =
  'campaigns/contacts/DELETE_CAMPAIGN_CONTACT_ERROR';
export const SELECT_CAMPAIGN_CONTACTS =
  'campaigns/contacts/SELECT_CAMPAIGN_CONTACTS';
export const DESELECT_CAMPAIGN_CONTACTS =
  'campaigns/contacts/DESELECT_CAMPAIGN_CONTACTS';
export const FAVORITE_CAMPAIGN_CONTACTS =
  'campaigns/contacts/FAVORITE_CAMPAIGN_CONTACTS';
export const UNFAVORITE_CAMPAIGN_CONTACTS =
  'campaigns/contacts/UNFAVORITE_CAMPAIGN_CONTACTS';
export const FAVORITE_CAMPAIGN_CONTACTS_ERROR =
  'campaigns/contacts/FAVORITE_CAMPAIGN_CONTACTS_ERROR';
export const UNFAVORITE_CAMPAIGN_CONTACTS_ERROR =
  'campaigns/contacts/UNFAVORITE_CAMPAIGN_CONTACTS_ERROR';

export const initialState = {
  contacts: [],
  favorited: [],
  images: {},
  selected: [],
  error: false,
  loading: true,
};

const campaignContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CAMPAIGN_CONTACTS:
      return initialState;

    case GET_CAMPAIGN_CONTACTS: {
      return {
        ...state,
        error: false,
        loading: true,
        contacts: [],
      };
    }

    case GET_CAMPAIGN_CONTACTS_RECEIVED: {
      const { contacts } = action.payload;

      return {
        ...state,
        loading: false,
        error: false,
        selected: [],
        contacts,
      };
    }

    case GET_CAMPAIGN_CONTACTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case DELETE_CAMPAIGN_CONTACT: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }

    case DELETE_CAMPAIGN_CONTACT_SUCCESS: {
      const { contacts } = action.payload;

      return {
        ...state,
        loading: false,
        error: false,
        contacts,
      };
    }

    case DELETE_CAMPAIGN_CONTACT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case SELECT_CAMPAIGN_CONTACTS: {
      return {
        ...state,
        selected: flatten([...state.selected, ...action.payload.contactIds]),
      };
    }

    case DESELECT_CAMPAIGN_CONTACTS: {
      const { contactIds } = action.payload;

      let selected = [];
      if (contactIds && Array.isArray(contactIds)) {
        selected = state.selected.filter(id => !contactIds.includes(id));
      } else if (contactIds && typeof contactIds === 'string') {
        selected = state.selected.filter(id => contactIds !== id);
      }

      return {
        ...state,
        selected,
      };
    }

    case FAVORITE_CAMPAIGN_CONTACTS: {
      const { contactIds } = action.payload;

      return {
        ...state,
        contacts: state.contacts.map(contact => {
          if (contactIds.includes(contact.id)) {
            return { ...contact, favorited: true };
          }

          return contact;
        }),
      };
    }

    case UNFAVORITE_CAMPAIGN_CONTACTS: {
      const { contactIds } = action.payload;

      return {
        ...state,
        contacts: state.contacts.map(contact => {
          if (contactIds.includes(contact.id)) {
            return { ...contact, favorited: false };
          }

          return contact;
        }),
      };
    }

    default: {
      return state;
    }
  }
};

export const getCampaignContactsByCampaignId = campaignId => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_CONTACTS });

  try {
    const contactsRequest = await performGet(
      `${CAMPAIGN_ENDPOINT}/${campaignId}/contact`,
    );
    const hasContacts =
      contactsRequest.status === 204 || contactsRequest.data === [];

    if (hasContacts) {
      dispatch({
        type: GET_CAMPAIGN_CONTACTS_RECEIVED,
        payload: { contacts: [] },
      });
      return;
    }

    const filteredContacts = contactsRequest.data.filter(
      contact => contact.twitterHandles.length > 0 && contact.id,
    );

    const contactFavorites = [];
    const contactImages = {};
    if (filteredContacts.length > 0) {
      const contactImagesRequest = await performPost(CONTACT_IMAGES_ENDPOINT, {
        authors: filteredContacts,
        skipErrors: true,
      });
      contactImagesRequest.data.forEach(
        image => (contactImages[image.id] = image),
      );

      const favoritesRequest = await performGet(FAVORITE_CONTACTS_ENDPOINT);
      favoritesRequest.data.forEach(favorite =>
        contactFavorites.push(favorite.id),
      );
    }

    const lists = {};
    const overviewResponse = await performGet(
      CONTACT_LISTS_ALL_OVERVIEW_ENDPOINT,
    );
    overviewResponse.data.forEach(contact => {
      if (!isEmpty(contact.authorListReferences)) {
        lists[contact.id] = {};
        contact.authorListReferences.forEach(
          list => (lists[contact.id][list.id] = list),
        );
      }
    });

    const contacts = contactsRequest.data.map(contact => ({
      ...contact,
      image: contact.id in contactImages ? contactImages[contact.id] : '',
      lists: contact.id in lists ? lists[contact.id] : {},
      favorited: contactFavorites.includes(contact.id),
      svgIcon: 'contact',
      type: 'contacts',
    }));

    dispatch({ type: GET_CAMPAIGN_CONTACTS_RECEIVED, payload: { contacts } });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_CONTACTS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Failed to fetch contacts for your campaign.',
        status: 'danger',
      }),
    );

    throw e;
  }
};

export const deleteCampaignContactsById = (campaignId, contactIds) => async (
  dispatch,
  getState,
) => {
  const campaignContacts = getState().campaignContactsList.contacts;
  const updatedList = campaignContacts.filter(
    contact => contactIds.indexOf(contact.id) === -1,
  );

  dispatch({ type: DELETE_CAMPAIGN_CONTACT });

  try {
    await performDelete(`${CAMPAIGN_ENDPOINT}/${campaignId}/contact`, {
      contactIds: contactIds.map(id => parseInt(id, 10)),
    });
    dispatch({
      type: DELETE_CAMPAIGN_CONTACT_SUCCESS,
      payload: { contacts: updatedList },
    });
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: {
        contacts: updatedList.map(contact => parseInt(contact.id, 10)),
      },
    });
  } catch (e) {
    dispatch({ type: DELETE_CAMPAIGN_CONTACT_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Failed to delete campaign contact.',
        status: 'danger',
      }),
    );
  }
};

export const selectCampaignEntries = contactIds => ({
  type: SELECT_CAMPAIGN_CONTACTS,
  payload: { contactIds: [contactIds] },
});

export const deselectCampaignEntries = contactIds => ({
  type: DESELECT_CAMPAIGN_CONTACTS,
  payload: { contactIds },
});

export const favoriteCampaignContacts = contactIds => async dispatch => {
  try {
    performPut(`${CONTACT_LISTS_ENDPOINT}/favorites/authors`, {
      authorIds: contactIds,
    });
    dispatch({ type: FAVORITE_CAMPAIGN_CONTACTS, payload: { contactIds } });
    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Success!',
        text: 'Contact has been added to your favorites.',
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: FAVORITE_CAMPAIGN_CONTACTS_ERROR });
  }
};

export const unFavoriteCampaignContacts = contactIds => async dispatch => {
  try {
    performDelete(`${CONTACT_LISTS_ENDPOINT}/favorites/authors`, {
      authorIds: contactIds,
    });
    dispatch({ type: UNFAVORITE_CAMPAIGN_CONTACTS, payload: { contactIds } });
    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Success!',
        text: 'Contact has been removed from your favorites.',
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: UNFAVORITE_CAMPAIGN_CONTACTS_ERROR });
  }
};

export default campaignContactsReducer;
