import find from 'lodash/find';
import { createSelector } from 'reselect';

import { DATA_SOURCES, DATA_SOURCE_TYPES } from 'constants/constants';
import { enabledAnalyticsIntegrationsSelector } from 'selectors/app-info';
import {
  allSearchesMapSelector,
  allSocialSearchesMapSelector,
} from 'selectors/search';
import { uniqueTagsByIdSelector } from 'selectors/user-tags';

import { WIZARD_STEPS } from './constants';
import { isValidV3SearchType, hasUnsharedDataSources } from './utils';

// this needs cleanup
export const sidebarSelector = state => state.widgetForm;
export const selectedItemIdsSelector = state => {
  return sidebarSelector(state).selectedItemIds;
};
const allDataSourcesSelector = state => sidebarSelector(state).dataSourcesV3;
export const searchDataSourcesV3 = state =>
  sidebarSelector(state).dataSourcesV3.filter(
    x =>
      isValidV3SearchType(x.sourceType) &&
      x.source === DATA_SOURCES.trendkiteElasticSearch,
  );

export const selectedSearchesSelector = createSelector(
  selectedItemIdsSelector,
  allSearchesMapSelector,
  (selectedIds, searches) =>
    selectedIds.filter(id => searches[id]).map(id => searches[id]),
);

export const selectedSocialSearchesSelector = createSelector(
  selectedItemIdsSelector,
  allSocialSearchesMapSelector,
  (selectedIds, searches) =>
    selectedIds
      .filter(id => find(searches, { id }))
      .map(id => find(searches, { id })),
);

const tagDataSourcesV3 = state =>
  sidebarSelector(state).dataSourcesV3.filter(
    dataSourceElement =>
      dataSourceElement.sourceType === DATA_SOURCE_TYPES.tag &&
      dataSourceElement.source === DATA_SOURCES.trendkiteElasticSearch,
  );

export const selectedTagsSelector = createSelector(
  tagDataSourcesV3,
  tagDataSources =>
    tagDataSources.map(dataSource => {
      return {
        id: dataSource.sourceId,
        tag: dataSource.metadata?.sourceModel.tag,
      };
    }),
);

export const fullSelectedTagsSelector = createSelector(
  selectedTagsSelector,
  uniqueTagsByIdSelector,
  (selectedTags, tags) => {
    return selectedTags.filter(({ id }) => tags[id]).map(({ id }) => tags[id]);
  },
);

export const v2OrV3SelectedItemsIds = createSelector(
  selectedItemIdsSelector,
  allDataSourcesSelector,
  (itemIds, dataSourcesV3) => dataSourcesV3.map(x => x.sourceId),
);

export const analyticByIdSelector = createSelector(
  enabledAnalyticsIntegrationsSelector,
  analyticOptions =>
    analyticOptions.reduce((acc, cur) => {
      acc[cur.analyticsId] = cur;
      return acc;
    }, {}),
);

export const adobeAnalyticsSelector = createSelector(
  enabledAnalyticsIntegrationsSelector,
  analyticOptions =>
    analyticOptions.filter(
      x => x.analyticsIntegrationType === 'ADOBE_ANALYTICS',
    ),
);

export const googleAnalyticsSelector = createSelector(
  enabledAnalyticsIntegrationsSelector,
  analyticOptions =>
    analyticOptions.filter(
      x => x.analyticsIntegrationType === 'GOOGLE_ANALYTICS',
    ),
);

export const mustShareDataSourcesSelector = createSelector(
  sidebarSelector,
  selectedSearchesSelector,
  fullSelectedTagsSelector,
  (form, selectedSearches, selectedTags) => isSharedAnalytic => {
    const { step, dataSourcesShared } = form;
    if (!isSharedAnalytic || dataSourcesShared) return false;

    if (
      (step === WIZARD_STEPS.selectSearch ||
        step === WIZARD_STEPS.customChartSearchSelection) &&
      hasUnsharedDataSources(selectedSearches)
    )
      return true;

    if (step === WIZARD_STEPS.selectTag && hasUnsharedDataSources(selectedTags))
      return true;

    return false;
  },
);

export const translatedKeyMessagesSelector = createSelector(
  sidebarSelector,
  state => state.translatedKeyMessages?.messages,
);

export const translatedKeyMessagesLoadingSelector = createSelector(
  sidebarSelector,
  state => state.translatedKeyMessages?.loading,
);

export const translatedKeyMessagesErrorSelector = createSelector(
  sidebarSelector,
  state => state.translatedKeyMessages?.error,
);

export const widgetWizardTabSelector = createSelector(
  sidebarSelector,
  state => state.tab,
);
