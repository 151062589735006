export const environments = {
  local: 'local',
  dev: 'dev',
  staging: 'staging',
  prod: 'prod',
};

const environmentsMap = {
  localhost: environments.local,
  'dev.trendkite.com': environments.dev,
  'staging.trendkite.com': environments.staging,
  'app.trendkite.com': environments.prod,
  'app2.cision.com': environments.prod,
  'app2.trendkite.com': environments.prod,
};

export const currentEnvironment = environmentsMap[window.location.hostname];
