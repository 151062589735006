import { createSelector } from 'reselect';

export const allPageMessages = state => state.pageMessages;

export const pageMessagesSelector = createSelector(allPageMessages, messages =>
  messages.filter(m => m.isNewUI),
);

export const oldPageMessagesSelector = createSelector(
  allPageMessages,
  messages => messages.filter(m => !m.isNewUI),
);
