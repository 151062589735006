import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip';
import SvgIcon from '../svg-icon';

const tooltipContent = (icon, color, message) => (
  <Fragment>
    <SvgIcon icon={icon} color={color} width={12} height={12} />
    <span style={{ marginLeft: '5px' }}>{message}</span>
  </Fragment>
);

const TabMenuItemBadge = ({ icon, color, message }) => {
  let content = <SvgIcon icon={icon} color={color} />;
  if (message) {
    content = (
      <Tooltip content={tooltipContent(icon, color, message)}>
        {content}
      </Tooltip>
    );
  }
  return <div className="tk-tab-menu__item__badge">{content}</div>;
};

TabMenuItemBadge.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  message: PropTypes.string,
};

TabMenuItemBadge.defaultProps = {
  color: SvgIcon.defaultProps.color,
  message: '',
};

export default TabMenuItemBadge;
