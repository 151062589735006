import React from 'react';

import PropTypes from 'prop-types';

const StoryHeadlineWrapper = props => (
  <div className="tk-story-headline">{props.children}</div>
);

StoryHeadlineWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StoryHeadlineWrapper;
