import React, { Component } from 'react';

import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';
import { Control, Errors, LocalForm, actions, track } from 'react-redux-form';

import shortid from 'shortid';

import { Body, Button, ErrorWithIntl, Input, SvgIcon } from '@trendkite/ui';

// i18n

import globalMessages from '../../../app/src/Global.messages';

import StorySection, { StorySectionHeader } from '../story-section';
import {
  validators,
  videosPropTypes,
  errorMessageI18nKeys,
} from '../story/templates/default-template';

import messages from './EditStoryVideos.messages';

const getNewVideoModel = () => {
  return {
    id: shortid.generate(),
    title: '',
    url: '',
  };
};

const EditStoryVideosError = props => (
  <ErrorWithIntl messages={messages} block {...props} />
);

const formModel = 'storyVideosForm';
class EditStoryVideos extends Component {
  static baseClass = 'tk-videos';

  static getDerivedStateFromProps(props, state) {
    if (props.videos !== state.propVideos) {
      let videos = props.videos;
      if (props.videos.length === 0) {
        videos = [getNewVideoModel()];
      }
      return {
        videos,
        propVideos: props.videos,
      };
    }
    return null;
  }

  static hasOneVideoThatsEmpty = videos =>
    videos.length === 1 && !videos[0].title && !videos[0].url;

  state = {
    isValid: false,
    isEditing: false,
  };

  onDiscard = () => {
    this.setState({ isEditing: false });
    this.props.onEndEditing();
  };

  onSubmit = form => {
    const videos = form.videos
      .filter(b => !!b.url)
      .map(b => {
        let url = b.url;
        if (!/^http(s)?/.test(b.url)) {
          url = `http://${b.url}`;
        }

        return {
          ...b,
          url,
        };
      });
    this.props.dispatchMergeFormAction('storyContentForm', { videos });
    this.setState({ isEditing: false });
    this.props.onEndEditing();
  };

  onClickSubmitButton = () => {
    const { videos } = this.state;

    if (EditStoryVideos.hasOneVideoThatsEmpty(videos)) {
      this.removeVideo(videos[0], 0);
    }

    this.dispatch(actions.submit(formModel));
  };

  startEditing() {
    this.setState({ isEditing: true });
  }

  addVideo = () => {
    this.dispatch(actions.push(`${formModel}.videos`, getNewVideoModel()));
  };

  clearVideo = video => {
    this.dispatch(
      actions.change(track(`${formModel}.videos[].url`, { id: video.id }), ''),
    );
    this.dispatch(
      actions.change(
        track(`${formModel}.videos[].title`, { id: video.id }),
        '',
      ),
    );
  };

  removeVideo = (video, index) => {
    this.dispatch(
      actions.setValidity(
        track(`${formModel}.videos[].url`, { id: video.id }),
        true,
      ),
    );
    this.dispatch(actions.remove(`${formModel}.videos`, index));
  };

  videoFormChanged = newModel => {
    this.setState({ videos: [...newModel.videos] });
  };

  videoFormUpdated = newValue => {
    this.setState({ isValid: newValue.$form.valid });
  };

  render() {
    const { videos, isValid, isEditing } = this.state;
    const { intl } = this.props;

    return (
      <StorySection>
        <StorySectionHeader
          label={intl.formatMessage(messages.editVideosHeader)}
        />
        <div className={EditStoryVideos.baseClass}>
          {isEditing && (
            <LocalForm
              component="div"
              model={formModel}
              initialState={{ videos }}
              onUpdate={this.videoFormUpdated}
              onChange={this.videoFormChanged}
              onSubmit={this.onSubmit}
              getDispatch={d => (this.dispatch = d)}
            >
              {videos &&
                videos.map((v, i) => (
                  <div
                    key={v.id}
                    className={`${EditStoryVideos.baseClass}__row`}
                  >
                    <div className={`${EditStoryVideos.baseClass}__header`}>
                      <div className={`${EditStoryVideos.baseClass}__title`}>
                        <Body color="light">
                          <TranslatedMessage
                            {...messages.videoIndex}
                            values={{ NUMBER: i + 1 }}
                          />
                        </Body>
                      </div>
                      <div className={`${EditStoryVideos.baseClass}__actions`}>
                        {(v.url || v.title) && (
                          <span
                            className={`${EditStoryVideos.baseClass}__clear`}
                          >
                            <Button
                              data-qa="MtRz3Drsbs-G5VWYT6THY"
                              modifiers={['seethrough']}
                              onClick={() => this.clearVideo(v)}
                            >
                              <TranslatedMessage {...globalMessages.clear} />
                            </Button>
                          </span>
                        )}
                        {videos.length > 1 && (
                          <span
                            className={`${EditStoryVideos.baseClass}__remove`}
                          >
                            <Button
                              data-qa="NaBOOonMltduZfMy-hwEY"
                              modifiers={['seethrough']}
                              onClick={() => this.removeVideo(v, i)}
                            >
                              <TranslatedMessage {...globalMessages.remove} />
                            </Button>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={`${EditStoryVideos.baseClass}__fields`}>
                      <div className={`${EditStoryVideos.baseClass}__link`}>
                        <Control.text
                          component={Input}
                          placeholder={intl.formatMessage(
                            messages.urlPlaceholder,
                          )}
                          id={`tkStoryVideosLink${i}`}
                          model={track('.videos[].url', { id: v.id })}
                          validators={validators.videos.url}
                          persist
                        />
                        <Errors
                          className="errors"
                          component={EditStoryVideosError}
                          model={track('.videos[].url', { id: v.id })}
                          messages={errorMessageI18nKeys.videos.url}
                          show="touched"
                        />
                      </div>
                      <div className={`${EditStoryVideos.baseClass}__text`}>
                        <Control.text
                          id={`tkStoryVideosText${i}`}
                          placeholder={intl.formatMessage(
                            messages.titlePlaceholder,
                          )}
                          component={Input}
                          model={track('.videos[].title', { id: v.id })}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </LocalForm>
          )}
          <div className={`${EditStoryVideos.baseClass}__add`}>
            <Button
              data-qa="6cfP97L3LNwnRFmKbdldq"
              modifiers={['calltoaction', 'inline-flex']}
              onClick={this.addVideo}
              active
            >
              <SvgIcon icon="plus" width={20} height={20} />
              <span className={`${EditStoryVideos.baseClass}__button-text`}>
                <TranslatedMessage {...messages.addAnotherLink} />
              </span>
            </Button>
          </div>
          <div className={`${EditStoryVideos.baseClass}__submit-buttons`}>
            <div className={`${EditStoryVideos.baseClass}__cancel`}>
              <Button
                data-qa="VM2nmsJpk6HSy4T4yOQ28"
                modifiers={['round', 'tertiary', 'block']}
                onClick={this.onDiscard}
              >
                <TranslatedMessage {...globalMessages.discard} />
              </Button>
            </div>
            <div className={`${EditStoryVideos.baseClass}__submit`}>
              <Button
                data-qa="hfpeO2V4-F6nEaXB3CyEQ"
                disabled={
                  !(isValid || EditStoryVideos.hasOneVideoThatsEmpty(videos))
                }
                modifiers={['round', 'primary', 'block']}
                onClick={() => {
                  this.onClickSubmitButton();
                }}
              >
                <TranslatedMessage {...globalMessages.save} />
              </Button>
            </div>
          </div>
        </div>
      </StorySection>
    );
  }
}

EditStoryVideos.propTypes = {
  // eslint doesn't recognize the special proptypes
  videos: videosPropTypes, // eslint-disable-line react/no-unused-prop-types
  onEndEditing: PropTypes.func,
  dispatchMergeFormAction: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

EditStoryVideos.defaultProps = {
  videos: [],
  onEndEditing: () => {},
  dispatchMergeFormAction: () => {},
};

export default EditStoryVideos;
