import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * InputFile
 * generic form input for text
 */
export class InputFile extends Component {
  onChange = event => {
    this.props.onChange(event);
  };

  clickInputElement = () => {
    this.InputElement.click();
  };

  render() {
    const {
      accept,
      className,
      disabled,
      error,
      id,
      modifiers,
      onBlur,
      onClick,
      onFocus,
    } = this.props;

    const baseClass = 'tk-form-control';
    const prefixedModifiers = Array.isArray(modifiers)
      ? [...modifiers, 'file'].map(modifier => `${baseClass}--${modifier}`)
      : ['file'];

    const mainClass = classnames(baseClass, className, prefixedModifiers, {
      [`${baseClass}--error`]: error,
      [`${baseClass}--disabled`]: disabled,
    });

    return (
      <input
        accept={accept}
        className={mainClass}
        disabled={disabled}
        id={id}
        onBlur={onBlur}
        onChange={this.onChange}
        onClick={onClick}
        onFocus={onFocus}
        ref={InputElement => {
          this.InputElement = InputElement;
        }}
        type="file"
      />
    );
  }
}

InputFile.defaultProps = {
  accept: 'image/*',
  disabled: false,
  error: false,
  id: '',
  onBlur: () => {},
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  files: [],
  className: '',
  modifiers: [],
};

InputFile.propTypes = {
  accept: PropTypes.string,
  /** Is this input disabled? */
  disabled: PropTypes.bool,
  /** Is this input currently invalid? */
  error: PropTypes.bool,
  /** Pass an id for linking html labels */
  id: PropTypes.string,
  /** Callback on blur */
  onBlur: PropTypes.func,
  /** Callback on file change/input */
  onChange: PropTypes.func,
  /** Callback on click */
  onClick: PropTypes.func,
  /** Callback on focus */
  onFocus: PropTypes.func,
  /** Custom class names */
  className: PropTypes.string,
  /** Custom BEM modifier class suffixes */
  modifiers: PropTypes.array,
};

export default InputFile;
