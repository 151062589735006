export enum REPORT_STATUSES {
  LIVE = 'LIVE',
  OFFLINE = 'OFFLINE',
}

export type ReportStatus = keyof typeof REPORT_STATUSES;

export enum REPORT_EXPORT_STATUSES {
  INITIALIZED,
  STARTED,
  CONFIRMING,
  PROCESSING,
  SUCCESS,
  ERROR,
}

export const S3_REPORTS_V2_STATIC_ASSET_ROOT = 'report-v2.s3.amazonaws.com';

export const S3_STOCK_IMAGES_ASSET_ROOT =
  's3.amazonaws.com/tk-assets/stock-images';

export const SLIDE_LAYOUT = {
  maxRows: 3,
  maxColumns: 4,
};

export const DEFAULT_GRID_SIZE = 1;
