import isDate from 'lodash/isDate';
import moment from 'moment';

import { SOCIAL_REFRESH } from 'constants/apis';
import { performPost } from 'services/rest-service/rest-service';

export const GET_SOCIAL_AMP_DATA = 'social-amp/GET_SOCIAL_AMP_DATA';
export const GET_SOCIAL_AMP_DATA_RECEIVED =
  'social-amp/GET_SOCIAL_AMP_DATA_RECEIVED';
export const GET_SOCIAL_AMP_DATA_ERROR = 'social-amp/GET_SOCIAL_AMP_DATA_ERROR';
export const UNSET_SOCIAL_AMP_DATA = 'social-amp/RESET_SOCIAL_AMP_DATA';

const initialState = {
  loading: true,
  data: {
    default: {
      facebook_total_count: 0,
      googleplus: 0,
      id: '',
      linkedin: 0,
      loading: false,
      pinterest: 0,
      twitter: 0,
      reddit: 0,
      url: '',
      refreshDate: null,
    },
  },
};

const socialAmpReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOCIAL_AMP_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_SOCIAL_AMP_DATA_RECEIVED: {
      const newState = {
        ...state,
        loading: false,
        data: {
          ...state.data,
        },
      };

      Object.keys(action.payload).forEach(key => {
        /* If that key doesn't exist, just jam the data in there */
        if (!newState.data[key]) {
          newState.data[key] = {
            ...action.payload[key],
            loading: false,
          };

          return;
        }

        /* If key already exists, make only put the data in if it's newer */
        const newRefreshDate = action.payload[key].refreshDate;
        const oldRefreshDate = newState.data[key].refreshDate;

        if (
          isDate(newRefreshDate) &&
          isDate(oldRefreshDate) &&
          newRefreshDate > oldRefreshDate
        ) {
          newState.data[key] = {
            ...action.payload[key],
            loading: false,
          };

          return;
        }

        /* If we got down here, then there wasn't an upate to the contents */
        newState.data[key] = {
          ...newState.data[key],
          loading: false,
        };
      });

      return newState;
    }
    case GET_SOCIAL_AMP_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case UNSET_SOCIAL_AMP_DATA:
      return initialState;
    default:
      return state;
  }
};

export const setSocialAmpDataFromArticle = article => dispatch => {
  const socialAmpMap = {
    [article.id]: {
      facebook_total_count: article.socialFacebookTotal,
      googleplus: article.socialGoogleplus,
      id: article.id,
      linkedin: article.socialLinkedin,
      pinterest: article.socialPinterest,
      twitter: article.socialTwitter,
      reddit: article.socialReddit,
      url: article.url,
      refreshDate: moment(article.socialRefreshDate, 'X').toDate(),
    },
  };

  dispatch({ type: GET_SOCIAL_AMP_DATA_RECEIVED, payload: socialAmpMap || {} });
};

export const getSocialAmpDataByArticleId = (
  articleId,
  url,
  publishDate,
) => dispatch => {
  let publishDateMillisString = publishDate;

  if (isDate(publishDate)) {
    publishDateMillisString = publishDate.getTime().toString();
  }

  dispatch({ type: GET_SOCIAL_AMP_DATA, payload: { articleId } });

  performPost(SOCIAL_REFRESH, {
    id: articleId,
    url,
    publishDate: publishDateMillisString,
  })
    .then(response => {
      const now = new Date();
      const data = (response.data || {}).results || {};
      const socialAmpMap = {};

      Object.keys(data).forEach(key => {
        socialAmpMap[articleId] = {
          ...data[key],
          refreshDate: now,
        };
      });

      dispatch({
        type: GET_SOCIAL_AMP_DATA_RECEIVED,
        payload: socialAmpMap || {},
      });
    })
    .catch(error => {
      dispatch({ type: GET_SOCIAL_AMP_DATA_ERROR });
      throw new Error(error);
    });
};

export default socialAmpReducer;
