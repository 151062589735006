import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

class Toggle extends Component {
  render() {
    const {
      checked,
      className,
      disabled,
      modifiers,
      label,
      onToggle,
      ...passedProps
    } = this.props;

    const baseClass = 'tk-toggle';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseClass}--${modifier}`)
      : [];

    const mainClass = classnames(baseClass, className, prefixedModifiers, {
      [`${baseClass}--checked`]: checked,
      [`${baseClass}--disabled`]: disabled,
    });

    const handleKeyUp = event => {
      if (event.key === 'Enter' || event.key === ' ') {
        onToggle();
        event.stopPropagation();
      }
    };

    return (
      <div
        aria-checked={checked}
        className={mainClass}
        data-qa="jzDK1_SrABf7PfgBX14iJ"
        onClick={onToggle}
        role="checkbox"
        tabIndex={0}
        onKeyUp={handleKeyUp}
        {...passedProps}
      >
        <div className={`${baseClass}__button`}>
          <span className={`${baseClass}__feature`}>{label}</span>
        </div>
      </div>
    );
  }
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  modifiers: PropTypes.arrayOf(
    PropTypes.oneOf([
      'xsmall',
      'medium',
      'large',
      'left',
      'right',
      'primaryBlue',
    ]),
  ),
  onToggle: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  className: '',
  disabled: false,
  label: '',
  modifiers: [],
};

export default Toggle;
