import messages from './dashboard-create-wizard.messages';
import templateOptions from './steps/template-options.messages';
import camelCase from 'lodash/camelCase';
export const WIZARD_STEPS = {
  templateSelection: 'TEMPLATE_SELECTION',
  baseSettings: 'BASE_SETTINGS',
  additionalSearches: 'ADDITIONAL_SEARCHES',
  keyMessages: 'KEY_MESSAGES',
  tags: 'TAGS',
};

export const WIZARD_TITLES = {
  [WIZARD_STEPS.templateSelection]: messages.templateSelectionHeaderTitle,
  [WIZARD_STEPS.baseSettings]: messages.baseSettingsHeaderTitle,
  [WIZARD_STEPS.additionalSearches]: messages.additionalSearchesHeaderTitle,
  [WIZARD_STEPS.keyMessages]: messages.keyMessagesHeaderTitle,
  [WIZARD_STEPS.tags]: messages.tagsTitle,
};

export const TEMPLATE_DATA_SOURCE_TYPES = {
  primarySearch: 'PRIMARY_SEARCH',
  secondarySearches: 'SECONDARY_SEARCHES',
  tag: 'TAG',
}

export const BLANK_TEMPLATE_ID = 'blank-template';

const TEMPLATE_CONFIG = {
  brandTemplate: {
    description: templateOptions.brandTemplateDescription,
    title: templateOptions.brandTemplateTitle,
  },
  overviewTemplate: {
    description: templateOptions.overviewTemplateDescription,
    title: templateOptions.overviewTemplateTitle,
  },
  competitiveTemplate: {
    description: templateOptions.competitiveTemplateDescription,
    title: templateOptions.competitiveTemplateTitle,
  },
  blankTemplate: {
    description: templateOptions.blankTemplateDescription,
    title: templateOptions.blankTemplateTitle,
  },
}

export const DEFAULT_TEMPLATE_OPTIONS = title => ({
  key: camelCase(title),
  description: TEMPLATE_CONFIG[camelCase(title)]?.description,
  title: TEMPLATE_CONFIG[camelCase(title)]?.title,
  nextStep: WIZARD_STEPS.baseSettings,
  steps: [WIZARD_STEPS.templateSelection, WIZARD_STEPS.baseSettings, WIZARD_STEPS.additionalSearches, WIZARD_STEPS.keyMessages, WIZARD_STEPS.tags],
});

export const TEMPLATE_OPTIONS = [
  {
    id: BLANK_TEMPLATE_ID,
    title: TEMPLATE_CONFIG.blankTemplate.title,
    description: TEMPLATE_CONFIG.blankTemplate.description,
    key: camelCase(BLANK_TEMPLATE_ID),
  },
];

export const TEMPLATE_DATA_QA = {
  'Blank Template': 'blank-dashboard-template-v3',
  'Brand Template': 'brand-dashboard-template-v3',
  'Competitive Template': 'competitive-dashboard-template-v3',
  'Overview Template': 'overview-dashboard-template-v3',
};
