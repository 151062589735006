import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import messages from './ScoreMeter.messages';

const calculateScore = function (value, max) {
  return value !== '-' ? Math.round((value / max) * 100) : 0;
};

const ScoreMeter = ({ mediumMin, highMin, label, max, modifiers, value }) => {
  const intl = useIntl();
  let adjustedValue = Math.min(parseFloat(value, 10), max);
  adjustedValue = Math.max(adjustedValue, 0);
  adjustedValue = Math.round(adjustedValue);
  const fillWidth = calculateScore(adjustedValue, max); // Turns to a percentage
  const gradientWidth = Math.ceil(10000 / fillWidth); // If the fill is 50% wide, the gradient should be 200% of the fill width
  const bucketLabel = ScoreMeter.getBucketLabel(
    mediumMin,
    highMin,
    adjustedValue,
    intl,
  );
  const small = modifiers.indexOf('small') >= 0;

  const baseClass = 'tk-score-meter';
  const prefixedModifiers = Array.isArray(modifiers)
    ? modifiers.map(modifier => `${baseClass}--${modifier}`)
    : [];

  const mainClass = classNames(baseClass, prefixedModifiers);

  return (
    <div className={mainClass}>
      {(small || label) && (
        <div className={`${baseClass}__heading`}>
          {label && <div className={`${baseClass}__label`}>{label}</div>}
          {small && (
            <div className={`${baseClass}__bucket-label`}>{bucketLabel}</div>
          )}
        </div>
      )}
      <div className={`${baseClass}__fill-container`}>
        <div
          className={`${baseClass}__fill-bar`}
          style={{
            backgroundSize: `${gradientWidth}% 100%`,
            width: `${fillWidth}%`,
          }}
        />
      </div>
      {!small && (
        <div className={`${baseClass}__bucket-label`}>{bucketLabel}</div>
      )}
    </div>
  );
};

ScoreMeter.getBucketLabel = function (mediumMin, highMin, value, intl) {
  if (value !== 0 && !value) {
    return '-';
  }

  if (value < mediumMin) {
    return intl.formatMessage(messages.lowScoreMeter);
  }

  if (value < highMin) {
    return intl.formatMessage(messages.mediumScoreMeter);
  }

  return intl.formatMessage(messages.highScoreMeter);
};

ScoreMeter.propTypes = {
  highMin: PropTypes.number,
  mediumMin: PropTypes.number,
  label: PropTypes.node,
  max: PropTypes.number,
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['large', 'small', 'xlarge'])),
  value: PropTypes.number,
};

ScoreMeter.defaultProps = {
  highMin: 400,
  mediumMin: 150,
  label: '',
  max: 600,
  modifiers: [],
  value: 0,
};

export default ScoreMeter;
