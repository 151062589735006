import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from '../svg-icon';

class DEPRECATED_SideTray extends Component {
  render() {
    const { visible, closeCallback, title, children, modifiers } = this.props;
    const clickOffBackdrop = visible ? (
      <button
        data-qa="bMF6pJgHlM3ZZ40py0vz6"
        className="tk-side-tray-backdrop"
        onClick={() => closeCallback('backdrop')}
      />
    ) : null;
    const baseClass = 'tk-side-tray';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseClass}--${modifier}`)
      : [];
    const mainClass = classnames(baseClass, prefixedModifiers, {
      [`${baseClass}--show`]: visible,
      [`${baseClass}--hide`]: !visible,
    });

    return (
      <div>
        <div className={mainClass}>
          <div className={`${baseClass}--main`}>
            <div className="header">
              <h2 className="title">{title}</h2>
              <button
                data-qa="XjdkqVQ_l9mwihEV_I5Nb"
                type="button"
                className="close-button"
                aria-label="Close"
                onClick={() => closeCallback('close-button')}
              >
                <SvgIcon width={16} height={16} icon="cross" />
              </button>
            </div>
            <div className="content">{children}</div>
          </div>
        </div>
        {clickOffBackdrop}
      </div>
    );
  }
}

DEPRECATED_SideTray.defaultProps = {
  visible: false,
  title: 'New SideTray',
  modifiers: [],
};

DEPRECATED_SideTray.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['flex'])),
};

export default DEPRECATED_SideTray;
