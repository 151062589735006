import {
  DASHBOARD_LIST_ENDPOINT,
  NEW_DASHBOARD_ENDPOINT,
} from 'constants/apis';
import { DEV_FEATURES } from 'constants/constants';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import { performGet } from 'services/rest-service/rest-service';

export const GET_DASHBOARD_LIST = 'dashboards/GET_DASHBOARD_LIST';
export const GET_DASHBOARD_LIST_RECEIVED =
  'dashboards/GET_DASHBOARD_LIST_RECEIVED';
export const GET_DASHBOARD_LIST_ERROR = 'dashboards/GET_DASHBOARD_LIST_ERROR';

export const initialState = {
  error: false,
  loading: true,
  dashboards: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_LIST_RECEIVED: {
      const dashboards = Object.keys(action.payload).map(
        key => action.payload[key],
      );

      return {
        ...state,
        error: false,
        loading: false,
        dashboards,
      };
    }
    case GET_DASHBOARD_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

const prepareData = (dashboard, isV1) => {
  if (dashboard.shared === true) dashboard.infoIcon = 'users';
  dashboard.svgIcon = isV1 ? 'dashboard' : 'dashboardTiles';
  dashboard.version = isV1 ? '1.0' : '2.0';
  dashboard.type = 'dashboards';
};

const filterUntitleds = dash => typeof dash.title !== 'undefined' && dash.title;

export const getDashboards = () => async dispatch => {
  dispatch({ type: GET_DASHBOARD_LIST });
  try {
    const resultV1 = await performGet(`${DASHBOARD_LIST_ENDPOINT}`);
    let resultV2 = null;
    let filteredDashesV2 = [];

    const data = {};
    resultV1.data.forEach(dashboard => {
      prepareData(dashboard, true);
      data[dashboard.id] = dashboard;
    });

    const filteredDashesV1 = resultV1.data.filter(filterUntitleds);
    if (userHasDevFeatureFlag(DEV_FEATURES.newDashboards)) {
      resultV2 = await performGet(`${NEW_DASHBOARD_ENDPOINT}`);
      resultV2.data.results.forEach(dashboard => {
        prepareData(dashboard, false);
        data[dashboard.id] = dashboard;
      });
      filteredDashesV2 = resultV2.data.results.filter(filterUntitleds);
    }
    const filteredDashes = filteredDashesV1.concat(filteredDashesV2);

    dispatch({
      type: GET_DASHBOARD_LIST_RECEIVED,
      payload: filteredDashes,
    });
  } catch (e) {
    dispatch({ type: GET_DASHBOARD_LIST_ERROR });
  }
};

export default dashboardReducer;
