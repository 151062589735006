import React from 'react';

import { Button } from '@cision/rover-ui';
import PropTypes from 'prop-types';

import { Loader, Modal, Type } from '@trendkite/ui';
import {
  OUTREACH_INTEGRATION_ERROR_MESSAGES,
  OUTREACH_INTEGRATION_ERROR_STATE_TITLES,
  OUTREACH_INTEGRATION_ERROR_TYPES,
} from 'constants/outreach-integration';

const OutreachSendingModal = ({
  error,
  from,
  isOpen,
  isSending,
  isTest,
  onCancel,
  subject,
  to,
}) => {
  const recipients = (isTest ? [from] : to)
    .map(t => t.name || t.email)
    .join(', ');

  let errorMessage = '';
  let errorTitle = '';
  let size = 'sm';

  if (error) {
    errorTitle = OUTREACH_INTEGRATION_ERROR_STATE_TITLES[error.type] || 'Error';
    if (error.type === OUTREACH_INTEGRATION_ERROR_TYPES.rejected_send) {
      errorMessage = OUTREACH_INTEGRATION_ERROR_MESSAGES[error.type](
        subject,
        recipients,
      );
    } else {
      errorMessage = OUTREACH_INTEGRATION_ERROR_MESSAGES[error.type];
    }
  }

  if (errorMessage.length >= 200) {
    size = 'md';
  }

  return (
    <Modal isOpen={isOpen} size={size}>
      {error && (
        <Modal.Header status="danger" toggle={onCancel}>
          {errorTitle}
        </Modal.Header>
      )}
      <Modal.Body
        style={{ textAlign: 'center', padding: error ? '20px' : '70px' }}
      >
        {!error && (
          <React.Fragment>
            <Loader size="small" />
            <br />
            {!isSending && <Type size="lg">Preparing to send your email</Type>}
            {isSending && (
              <Type size="lg">
                Your {isTest ? 'test' : 'email'} is being sent…
              </Type>
            )}
            <br />
            <br />
            {!isSending && (
              <Button
                data-qa="IvxSMe79Hxz5PCCX6jgtC"
                level="tertiary"
                size="lg"
                onClick={onCancel}
              >
                Cancel Email
              </Button>
            )}
            {isSending && (
              <Type>
                Sending &quot;{subject}&quot; to {recipients}…
              </Type>
            )}
          </React.Fragment>
        )}

        {error && <Type>{errorMessage}</Type>}
      </Modal.Body>
      {error && (
        <Modal.Footer>
          <Button
            data-qa="sWOcyuYpLxulLOo9D0xCz"
            level="primary"
            size="lg"
            onClick={onCancel}
          >
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

OutreachSendingModal.propTypes = {
  error: PropTypes.object,
  from: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  isSending: PropTypes.bool.isRequired,
  isTest: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  subject: PropTypes.string,
  to: PropTypes.array,
};

OutreachSendingModal.defaultProps = {
  error: null,
  from: null,
  subject: '',
  to: [],
};

export default OutreachSendingModal;
