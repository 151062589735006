import {
  CONTACT_SOCIAL_TIMELINE_ENDPOINT,
  INFLUENCER_HUB_ENDPOINT,
} from 'constants/apis';

import { performGet as realPerformGet } from 'services/rest-service/rest-service';

import influencers from './mockData/influencers';
import socialTimeline from './mockData/social-timeline';

export const performGet = async path => {
  // Return influencer by id
  if (path.indexOf('/ihub/influencer/') === 0) {
    const influencerId = path.substr('/ihub/influencer/'.length);
    const influencer = influencers.find(inf => `${inf.id}` === influencerId);
    return { data: influencer };
  }

  switch (path) {
    case `${CONTACT_SOCIAL_TIMELINE_ENDPOINT}?site=twitter&user=@ByEliott`:
      return { data: socialTimeline };
    default:
      throw new Error('nope');
  }
};

export const performPost = async path => {
  switch (path) {
    case '/ihub/search': {
      /*
        Add some real data to the list in a hacky way,
        by hitting the single profile endpoint
      */
      const profileIds = ['76186741', '2859018580', '69320758'];
      const profilePromises = profileIds.map(id =>
        realPerformGet(`${INFLUENCER_HUB_ENDPOINT}/influencer/${id}`),
      );
      const profileResponses = await Promise.all(profilePromises);
      const profiles = profileResponses.map(response => response.data.data);
      return { data: [...influencers, ...profiles] };
    }
    default:
      throw new Error('nope');
  }
};

export default {
  performGet,
  performPost,
};
