import PropTypes from 'prop-types';

import {
  THREAD_SORT_FIELD,
  THREAD_SORT_ORDER,
} from 'components/outreach/constants';

import {
  defaultProps as outreachAggregationDefaultProps,
  propTypes as outreachAggregationPropTypes,
} from './outreach-aggregations';
import {
  defaultProps as outreachFilterDefaultProps,
  propTypes as outreachFilterPropTypes,
} from './outreach-filters';
import {
  defaultProps as outreachPaginationDefaultProps,
  propTypes as outreachPaginationPropTypes,
} from './outreach-pagination';

/** An outreach filtered view, used to power message center, contact history, and ...? */
export const propTypes = PropTypes.shape({
  /**
    This is the sum unread, replied, sent, etc. for the current filtered view.
    It indicates how many results are in your current set.
  */
  aggregations: outreachAggregationPropTypes,
  /**
    This is the sum unread, replied, sent, etc. for the current view,
    ignoring user filters and navigation filters.
    It indicates how many results you would see _if_ you clicked on
    a nav item.
  */
  aggregationsWithOnlyHiddenFilters: outreachAggregationPropTypes,
  draftAggregations: PropTypes.object,
  draftIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
    Hidden filters are unchangeable by the user, and don't appear in the UI.
    For example, if you're viewing a contact's email history, there's nothing
    you can do in the filter bar to change to view a different contact's messages.
  */
  hiddenFilters: outreachFilterPropTypes,
  loadingTypeaheadThreads: PropTypes.boolean,
  loadingTypeaheadContacts: PropTypes.boolean,
  /**
    Locked filters are unchangeable by the user, but they appear in the UI.
    For example, if you're viewing 'unread' emails because you clicked 'Unread' in the nav bar,
    we show you the filter, but you can't change it unless you use the nav bar to go to a different page.
  */
  lockedFilters: outreachFilterPropTypes,
  pagination: outreachPaginationPropTypes,
  /** Text queries go here, and are handled differently from filters. */
  search: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.oneOf([
      THREAD_SORT_FIELD.DATE,
      THREAD_SORT_FIELD.UPDATED,
      THREAD_SORT_FIELD.SCHEDULED,
    ]),
    order: PropTypes.oneOf([THREAD_SORT_ORDER.ASC, THREAD_SORT_ORDER.DESC]),
  }),
  threadIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  typeaheadThreadIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  typeaheadContactIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
    User filters are the standard, changeable by users, and represented in the filter bar in the UI.
  */
  userFilters: outreachFilterPropTypes,
});

export const defaultProps = {
  aggregations: { ...outreachAggregationDefaultProps },
  aggregationsWithOnlyHiddenFilters: { ...outreachAggregationDefaultProps },
  draftAggregations: {},
  draftIds: [],
  loadingTypeaheadThreads: false,
  loadingTypeaheadContacts: false,
  lockedFilters: { ...outreachFilterDefaultProps },
  userFilters: { ...outreachFilterDefaultProps },
  hiddenFilters: { ...outreachFilterDefaultProps },
  pagination: { ...outreachPaginationDefaultProps },
  sort: {
    field: THREAD_SORT_FIELD.SENT,
    order: THREAD_SORT_ORDER.DESC,
  },
  search: null,
  threadIds: [],
  typeaheadThreadIds: [],
  typeaheadContactIds: [],
};

export default {
  propTypes,
  defaultProps,
};
