/*

Run bookmarklet to enable:

javascript:localStorage.setItem('DEV_FEATURES_LOCAL_OVERRIDES', '[]'); window.location.reload();

*/
const LOCALSTORAGE_KEY = 'DEV_FEATURES_LOCAL_OVERRIDES';

export const getDevFeatureLocalOverridesActivated = () =>
  window.localStorage && LOCALSTORAGE_KEY in window.localStorage;

export const getDevFeatureLocalOverrides = () => {
  const activated = getDevFeatureLocalOverridesActivated();
  const defaultValue = [];
  let parsedData = null;

  if (activated) {
    const data = window.localStorage.getItem(LOCALSTORAGE_KEY);
    try {
      if (data != null) {
        parsedData = JSON.parse(data);
      }
    } catch (e) {
      return defaultValue;
    }
  }

  if (parsedData != null && Array.isArray(parsedData)) {
    return parsedData as string[];
  } else {
    return defaultValue;
  }
};

export const updateDevFeatureLocalOverrides = (newVal: string[]) => {
  window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newVal));
};

export const addDevFeatureLocalOverride = (name: string) => {
  const current = getDevFeatureLocalOverrides();
  updateDevFeatureLocalOverrides([...current, name]);
};

export const removeDevFeatureLocalOverride = (name: string) => {
  const current = getDevFeatureLocalOverrides();
  const newVal = current.filter(v => v !== name);
  updateDevFeatureLocalOverrides(newVal);
};

export const deinitDevFeatureLocalOverrides = () => {
  window.localStorage.removeItem(LOCALSTORAGE_KEY);
};
