import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Twitter = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M22 5.924a9.58 9.58 0 01-.562.22 12.11 12.11 0 01-.58.19 8.804 8.804 0 01-1.212.234 4.84 4.84 0 00.591-.434 3.97 3.97 0 00.918-1.148c.12-.221.217-.449.288-.683a7.701 7.701 0 01-1.914.82c-.228.065-.455.12-.683.166a4.418 4.418 0 00-.62-.532 3.99 3.99 0 00-.718-.405 3.725 3.725 0 00-.801-.264 4.184 4.184 0 00-.86-.088c-.566 0-1.098.107-1.596.322a4.147 4.147 0 00-1.304.88 4.147 4.147 0 00-.879 1.303 3.988 3.988 0 00-.322 1.597 5.987 5.987 0 00.02.478l.039.23c.013.074.029.15.049.23a11.73 11.73 0 01-4.756-1.28 11.767 11.767 0 01-2.012-1.32 11.513 11.513 0 01-1.69-1.689 3.527 3.527 0 00-.41.977c-.052.169-.09.345-.112.527a4.484 4.484 0 00-.034.556c0 .359.042.702.127 1.03.084.33.208.64.37.933.157.293.348.562.572.806.225.244.477.46.757.65a4.162 4.162 0 01-.977-.157 3.096 3.096 0 01-.454-.151 4.834 4.834 0 01-.434-.21v.049c0 .501.084.975.254 1.42.169.447.4.845.693 1.197.293.358.64.66 1.04.903.4.244.835.412 1.304.503a3.646 3.646 0 01-.259.069 1.84 1.84 0 01-.269.039 3.895 3.895 0 01-1.132-.005 4.906 4.906 0 00-.186-.025c.13.404.32.778.566 1.123.248.345.538.645.87.899a4.017 4.017 0 002.393.82 7.78 7.78 0 01-1.119.742c-.394.215-.806.4-1.235.557a8.25 8.25 0 01-2.744.459h-.245c-.084 0-.166-.003-.244-.01a81.704 81.704 0 01-.249-.02A2.825 2.825 0 012 18.405a11.628 11.628 0 002.959 1.358c.527.162 1.07.285 1.626.366.557.081 1.125.122 1.704.122 1.888 0 3.558-.353 5.01-1.06 1.452-.706 2.672-1.619 3.662-2.739a11.882 11.882 0 002.241-3.76c.511-1.386.767-2.757.767-4.11a2.12 2.12 0 00-.005-.138 1.918 1.918 0 01-.005-.136V8.18a.966.966 0 00-.01-.137c.202-.143.396-.296.581-.459.186-.163.366-.332.542-.508.17-.176.332-.361.489-.556A9.69 9.69 0 0022 5.924z"
    />
  </svg>
);

Twitter.propTypes = { fill: PropTypes.string };
Twitter.defaultProps = { fill: iconColorsMap.twitter };

export default Twitter;
