import React from 'react';

import classNames from 'classnames';
import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import Button from '../../button';
import Media from '../../media';

// i18n

import messages from './ListVisorDrawer.messages';

const ListVisorDrawer = ({
  onClear,
  children,
  filterText,
  clearAllText,
  isOpen,
  hideClear,
  hideFilters,
  className,
}) => {
  const url = window.location.href;
  const lastPart = url.substr(url.lastIndexOf('/') + 1);
  const isInMessages =
    ['all', 'mine', 'unread', 'drafts', 'scheduled'].some(filter => {
      return lastPart === filter;
    }) ||
    (url.includes('/influencers/messages') &&
      url.includes('paginationLimit=25&paginationOffset=0'));
  const inMessageCenterHome = isInMessages;

  const drawerClasses = classNames(ListVisorDrawer.baseClass, className, {
    [`${ListVisorDrawer.baseClass}--closed`]: !isOpen,
    [`${ListVisorDrawer.baseClass}--new-message-center`]:
      isOpen && inMessageCenterHome,
  });

  return (
    <div className={drawerClasses}>
      <Media style={{ alignItems: 'center' }}>
        <Media.Item
          style={hideFilters ? { opacity: 0, mouseevents: 'none' } : {}}
        >
          <span className={`${ListVisorDrawer.baseClass}__filter-text`}>
            {filterText}
          </span>
        </Media.Item>
        <Media.Body>{children}</Media.Body>
        <Media.Item>
          <Button
            data-qa="ListVisorDrawer clear all"
            onClick={onClear}
            style={
              hideClear
                ? { opacity: 0, mouseevents: 'none', cursor: 'auto' }
                : {}
            }
          >
            {clearAllText}
          </Button>
        </Media.Item>
      </Media>
    </div>
  );
};

ListVisorDrawer.baseClass = 'tk-list-visor-drawer';

ListVisorDrawer.propTypes = {
  children: PropTypes.node,
  /** Toggles the Drawer visibility */
  isOpen: PropTypes.bool,
  /** Changes the text that is displayed for the Clear All link */
  clearAllText: PropTypes.node,
  /** Changes the text to the left of (what is typically) the Filter Pills */
  filterText: PropTypes.node,
  hideClear: PropTypes.bool,
  /**
   * Called when the usere clicks on the "Clear All" link
   */
  onClear: PropTypes.func,
  hideFilters: PropTypes.bool,
  className: PropTypes.string,
};

ListVisorDrawer.defaultProps = {
  children: null,
  isOpen: false,
  clearAllText: <TranslatedMessage {...messages.clearAll} />,
  filterText: <TranslatedMessage {...messages.filters} />,
  hideClear: false,
  onClear: () => {},
  hideFilters: false,
  className: '',
};

export default ListVisorDrawer;
