import React, { Component } from 'react';

import T from 'i18n/TranslatedMessage';

import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

import { SvgIcon, Button, Addon, Type } from '@trendkite/ui';

import lightHouseIcon from '../../../app/src/assets/lighthouse.svg';

import messages from './assets-library-modals.messages';
import styles from './browseModuleUpload.module.css';
const dragTextStyle = { position: 'absolute', left: '44%', top: '60%' };

class MediaRightPanel extends Component {
  render() {
    const { disclaimerFlag } = this.props.data;
    return (
      <div className={styles.rightPane}>
        <div className={styles.assetFileUploadWrapper}>
          <div>
            <Dropzone className={styles.dropzoneRightPanel}>
              <svg
                className={styles.lighthouseIcon}
                onDrop={e => this.props.onChangeHandler(e)}
              >
                <use xlinkHref={`#${lightHouseIcon.id}`} />
              </svg>
            </Dropzone>
          </div>
          <div className={styles.addTextSection}>
            <Type weight="bold" color="dark">
              <T {...messages.mediaStudioAddFileText} />
            </Type>
            <br />
            <Type style={dragTextStyle}>
              <T {...messages.mediaStudioFileDragAndDropText} />
            </Type>
          </div>
          <div>
            <Button
              modifiers={['round', 'primary']}
              className={styles.uploadMediaButton}
              disabled={disclaimerFlag}
            >
              <Addon>
                <input
                  type="file"
                  className={styles.leftPanelUploadBtn}
                  name="file"
                  onChange={e => this.props.onChange(e)}
                  multiple="multiple"
                />
                <SvgIcon
                  icon="upload"
                  className={styles.uploadIconForRightPane}
                  width={14}
                  height={14}
                />
                <Type style={{ marginLeft: '22px', color: '#fff' }}>
                  <T {...messages.mediaStudioUploadFiles} />
                </Type>
              </Addon>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

MediaRightPanel.propTypes = {
  data: PropTypes.shape({
    disclaimerFlag: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  onChangeHandler: PropTypes.func,
};
MediaRightPanel.defaultProps = {
  data: {
    disclaimerFlag: false,
  },
  onChange: () => {},
  onChangeHandler: () => {},
};

export default MediaRightPanel;
