import React, { Component } from 'react';

import classNames from 'classnames';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';
import difference from 'lodash/difference';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import { Loader } from '@trendkite/ui';

import Button from '../button';

import ComponentType from '../component-type';

import { Checkbox } from '../forms';
import Select from '../forms/Select';
import SearchInput from '../search-input';
import SelectionTray from '../selection-tray';

import ListEntry from './ListEntry';

class List extends Component {
  state = {
    selected: [],
  };

  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    if (this.props.selected) {
      // let parent manage selected entries if given
      this.setState({
        selected: [...this.props.selected],
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    if (
      nextProps.selected &&
      !isEqual(this.props.selected, nextProps.selected)
    ) {
      this.setState({
        selected: [...nextProps.selected],
      });
    }
  }

  onSelect = entry => {
    const {
      entries,
      multiselect,
      onDelete,
      onSelect,
      onSingleSelect,
      selectAfterDelete,
    } = this.props;
    const { selected } = this.state;
    const previouslySelectedIds = selected;
    let triggerSelect = true;
    let newlySelectedIds = selected;
    // eslint-disable-next-line eqeqeq
    const isSelected = !!previouslySelectedIds.filter(id => id == entry.id)
      .length;

    if (!multiselect) {
      // eslint-disable-next-line eqeqeq
      if (previouslySelectedIds[0] != entry.id) {
        newlySelectedIds = [entry.id];
        onSingleSelect(entry);
      }
    } else if (multiselect && isSelected) {
      newlySelectedIds = previouslySelectedIds.filter(id => entry.id !== id);
      onDelete(entry.id);
      if (!selectAfterDelete) {
        triggerSelect = false;
      }
    } else if (!entry.alreadyAdded) {
      newlySelectedIds = [...previouslySelectedIds, entry.id];

      if (Array.isArray(entry)) {
        onSelect(entry);
      } else {
        onSingleSelect(entry);
      }
    }

    this.setState({ selected: newlySelectedIds }, () => {
      if (triggerSelect) {
        onSelect(newlySelectedIds.map(id => find(entries, { id })));
      }
    });
  };

  selectAll = () => {
    const {
      max,
      entries,
      onExceedSelection,
      onSelectAll: customOnSelectAll,
      selectAllContacts,
      selectAllContactsinPinPointContacts,
    } = this.props;

    if (entries.length > max) {
      onExceedSelection(entries.length, max);
    } else {
      const availableEntries = entries
        .filter(c => !c.alreadyAdded)
        .map(it => it.id);
      this.setState({ selected: [...availableEntries] });
      const availableEntryIds = entries.map(entry => entry.id);
      this.props.onSelectAllChange(availableEntryIds);
      selectAllContacts(entries);
      selectAllContactsinPinPointContacts(entries);
    }
    if (customOnSelectAll) {
      customOnSelectAll();
      //TODO: refine in virtual selection sibling subtask
    }
  };

  onTrayDelete = entry => {
    const selected = this.state.selected.filter(id => entry !== id);
    this.setState({ selected });
    this.props.onDelete(this.props.entries[entry].id);
  };

  deleteAll = () => {
    const { onDeleteAll: customOnDeleteAll, selectAllContacts } = this.props;
    this.setState({ selected: [] });
    this.props.onDeleteAllChange([]);
    if (customOnDeleteAll) {
      customOnDeleteAll();
    }
    selectAllContacts([]);
  };

  render() {
    const {
      className,
      clearable,
      containerClassName,
      entries,
      entriesClassName,
      EntryComponent,
      entryProps,
      filterable,
      filterLabel,
      filterOptions,
      isListSelected,
      isSearchBarSticky,
      max,
      maxHeight,
      modifiers,
      multiselect,
      onFilterChange,
      onScroll,
      onSearchChange,
      query,
      scrollIntoView,
      searchable,
      searchPlaceholder,
      showTray,
      removeIcon,
      showSelectAllButton,
      hasCheckBoxSelection,
      currentView,
    } = this.props;
    const { selected } = this.state;

    const baseListClass = 'tk-list';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseListClass}--${modifier}`)
      : [];

    const listClasses = classNames(baseListClass, prefixedModifiers, className);
    const containerClasses = classNames(
      'tk-list-container',
      containerClassName,
    );

    const listStyles = {};
    if (maxHeight) {
      listStyles.maxHeight = maxHeight;
    }

    const selectedId = selected[0];

    let disabled;

    if (multiselect && selected.length >= max) {
      // Keep track of disabled reason, in case we add `min` validation later
      disabled = {
        ...disabled,
        max: true,
      };
    }

    const listMembers = entries.map((entry, idx) => {
      let isSelected = false;

      if (this.props.disableContactsWithoutEmail) {
        if (!entry.hasEmails) {
          disabled = {
            ...disabled,
            noEmail: true,
          };
        } else {
          disabled = {
            ...disabled,
            noEmail: false,
          };
        }
      }

      // Using `==` to match across strings and numbers for campaigns
      // eslint-disable-next-line eqeqeq
      if (!multiselect && entry.id == selectedId) {
        isSelected = true;
      }

      if (multiselect) {
        isSelected = !!selected.filter(id => id == entry.id).length; // eslint-disable-line eqeqeq
      }

      if (isListSelected) {
        disabled = {
          ...disabled,
          isListSelected: true,
        };
        isSelected = true;
      }

      return (
        <div className={entry.style} key={entry.id}>
          <EntryComponent
            entry={entry}
            disabled={disabled}
            multiselect={multiselect}
            scrollIntoView={scrollIntoView}
            selected={isSelected}
            onSelect={this.onSelect}
            removeIcon={removeIcon}
            idx={idx}
            {...entryProps}
          />
        </div>
      );
    });

    let bulkSelector;
    let bulkSelectorBtn;

    if (clearable || filterable) {
      let selectAllDisabled;
      const availableEntries = entries.filter(c => !c.alreadyAdded);
      const availableEntryIds = availableEntries.map(c => c.id);

      const allSelected =
        intersection(selected, availableEntryIds).length ===
        availableEntries.length;

      const remainingUnselected = difference(availableEntryIds, selected);

      if (
        !allSelected &&
        multiselect &&
        remainingUnselected.length + selected.length > max
      ) {
        selectAllDisabled = true;
      }

      bulkSelector = allSelected ? (
        <span
          data-qa="pYDRP1ynoat9WgG875vkQ"
          onKeyUp={this.deleteAll}
          onClick={this.deleteAll}
          className={`${baseListClass}__filter-bar-bulk-select`}
          role="button"
          tabIndex={0}
        >
          <TranslatedMessage {...globalMessages.deselectAll} />
        </span>
      ) : (
        <span
          data-qa="CTNwtTOAdbUg-7EjViNec"
          className={classNames(`${baseListClass}__filter-bar-bulk-select`, {
            [`${baseListClass}__filter-bar-bulk-select--disabled`]: selectAllDisabled,
          })}
          onKeyUp={this.selectAll}
          onClick={this.selectAll}
          role="button"
          tabIndex={0}
        >
          <TranslatedMessage {...globalMessages.selectAll} />
        </span>
      );

      const deselectAll = hasCheckBoxSelection ? (
        <Checkbox
          data-qa="sPaB-k_DRAowF_ZwyVsIB"
          onClick={this.deleteAll}
          selected={allSelected}
        >
          <div className="selectOptions">
            <TranslatedMessage {...globalMessages.deselectAll} />
          </div>
        </Checkbox>
      ) : (
        <Button
          data-qa="sKW3zW_Di1zklNjJd0IfL"
          modifiers={['small', 'link']}
          onClick={this.deleteAll}
        >
          <TranslatedMessage {...globalMessages.deselectAll} />
        </Button>
      );

      const selectAll = hasCheckBoxSelection ? (
        <Checkbox
          data-qa="qfmSECoVl4e0-xxhiDK6z"
          onClick={this.selectAll}
          selected={allSelected}
        >
          <div className="selectOptions">
            <TranslatedMessage {...globalMessages.selectAll} />
          </div>
        </Checkbox>
      ) : (
        <Button
          data-qa="xwdZXjct2RzIJ8f62NZ8t"
          modifiers={['small', 'link']}
          onClick={this.selectAll}
        >
          <TranslatedMessage {...globalMessages.selectAll} />
        </Button>
      );

      bulkSelectorBtn =
        availableEntries.length > 0 && (allSelected ? deselectAll : selectAll);
    }

    const onHandleTypeAheadSearch = ({ target }) => {
      const { onTypingAhead } = this.props;
      const { value } = target;

      if (onTypingAhead) {
        return onTypingAhead(value);
      }
    };

    return (
      <div className={containerClasses} style={listStyles} onScroll={onScroll}>
        {searchable && (
          <div
            className={classNames(`${baseListClass}__search-bar`, {
              [`${baseListClass}__sticky-search-bar`]: isSearchBarSticky,
            })}
          >
            <SearchInput
              placeholder={
                searchPlaceholder ||
                this.props.intl.formatMessage(globalMessages.search)
              }
              performSearchCallback={onSearchChange}
              modifiers={['slim']}
              handleChange={onHandleTypeAheadSearch}
              searchQuery={query}
            />
          </div>
        )}
        {currentView === 'loading' && (
          <Loader size="small" style={{ padding: '10px' }} />
        )}
        {filterable && (
          <div className={`${baseListClass}__filter-bar`}>
            <div className={`${baseListClass}__filter-select`}>
              <Select
                label={filterLabel}
                modifiers={['seethrough', 'inline-block']}
                multiple
                onChange={onFilterChange}
                options={filterOptions}
                style={{ visibility: filterable ? 'hidden' : 'visible' }}
              />
            </div>
            {bulkSelector}
          </div>
        )}
        {showSelectAllButton && clearable && !filterable && multiselect && (
          <div className={`${baseListClass}__bulk-select`}>
            {showSelectAllButton && bulkSelectorBtn}
          </div>
        )}
        <div className={listClasses}>
          <div className={classNames('tk-list-entries', entriesClassName)}>
            {listMembers}
          </div>
        </div>
        {showTray && multiselect && (
          <SelectionTray
            className="tk-list__tray"
            entries={entries}
            selected={selected}
            onEntryDelete={this.onTrayDelete}
            onDeleteAll={this.deleteAll}
          />
        )}
      </div>
    );
  }
}

List.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  containerClassName: PropTypes.string,
  disableContactsWithoutEmail: PropTypes.bool,
  EntryComponent: ComponentType,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    }),
  ).isRequired,
  entryProps: PropTypes.object,
  entriesClassName: PropTypes.string,
  filterable: PropTypes.bool,
  filterLabel: PropTypes.string,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
  isListSelected: PropTypes.bool,
  isSearchBarSticky: PropTypes.bool,
  max: PropTypes.number,
  maxHeight: PropTypes.string,
  modifiers: PropTypes.arrayOf(
    PropTypes.oneOf(['horizontal', 'round', 'vertical']),
  ),
  multiselect: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectAllChange: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteAll: PropTypes.func,
  onDeleteAllChange: PropTypes.func,
  onScroll: PropTypes.func,
  onSingleSelect: PropTypes.func,
  onExceedSelection: PropTypes.func,
  query: PropTypes.string,
  scrollIntoView: PropTypes.bool,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  selected: PropTypes.array,
  selectAfterDelete: PropTypes.bool,
  showTray: PropTypes.bool,
  removeIcon: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  showSelectAllButton: PropTypes.bool,
  onTypingAhead: PropTypes.func,
  hasCheckBoxSelection: PropTypes.bool,
  currentView: PropTypes.string,
  selectAllContacts: PropTypes.func,
  selectAllContactsinPinPointContacts: PropTypes.func,
};

List.defaultProps = {
  className: '',
  clearable: false,
  containerClassName: '',
  disableContactsWithoutEmail: false,
  EntryComponent: ListEntry,
  entries: [],
  entryProps: {},
  filterable: false,
  filterLabel: 'Filter By',
  filterOptions: [],
  isListSelected: false,
  isSearchBarSticky: false,
  max: Infinity,
  maxHeight: '',
  modifiers: [],
  multiselect: false,
  onSelectAllChange: () => {},
  onDelete: () => {},
  onDeleteAll: () => {},
  onDeleteAllChange: () => {},
  onFilterChange: () => {},
  onScroll: () => {},
  onSearchChange: () => {},
  onSelect: () => {},
  onSingleSelect: () => {},
  onExceedSelection: () => {},
  onTypingAhead: () => {},
  query: '',
  scrollIntoView: false,
  searchable: false,
  selected: [],
  selectAfterDelete: true,
  showTray: true,
  removeIcon: false,
  showSelectAllButton: true,
  hasCheckBoxSelection: false,
  currentView: '',
  selectAllContacts: () => {},
  selectAllContactsinPinPointContacts: () => {},
};

export default injectIntl(List);
