/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

interface ActionProps {
  justify?: 'right' | 'left' | null;
}

export type ActionType = React.FC<ActionProps>;

const Action: ActionType = ({ children, justify = null }) => {
  const classes = classNames('tk-modal-action', {
    'tk-modal-action--justify-left': justify === 'left',
    'tk-modal-action--justify-right': justify === 'right',
  });

  return <div className={classes}>{children}</div>;
};

export default Action;
