export const CLOSE_COMPOSE_MODAL = 'outreach/CLOSE_COMPOSE_MODAL';
export const OPEN_COMPOSE_MODAL = 'outreach/OPEN_COMPOSE_MODAL';
export const TOGGLE_COMPOSE_MODAL = 'outreach/TOGGLE_COMPOSE_MODAL';

const initialState = {
  isComposeModalOpen: false,
};

const outreachComposeModalReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CLOSE_COMPOSE_MODAL:
      return {
        ...state,
        isComposeModalOpen: false,
      };
    case OPEN_COMPOSE_MODAL:
      return {
        ...state,
        isComposeModalOpen: true,
      };
    case TOGGLE_COMPOSE_MODAL: {
      return {
        ...state,
        isComposeModalOpen: !state.isComposeModalOpen,
      };
    }
    default:
      return state;
  }
};

export const closeComposeModalActionCreator = () => ({
  type: CLOSE_COMPOSE_MODAL,
});
export const openComposeModalActionCreator = () => ({
  type: OPEN_COMPOSE_MODAL,
});
export const toggleComposeModalActionCreator = () => ({
  type: TOGGLE_COMPOSE_MODAL,
});

export default outreachComposeModalReducer;
