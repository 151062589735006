import {
  SocialMonitoringSearchRequest,
  SocialSearch,
} from '../types/socialSearchTypes';

export const SOCIAL_STREAM_TOGGLE_SEARCH_OPTION =
  'SOCIAL_STREAM_TOGGLE_SEARCH_OPTION';
export const SOCIAL_STREAM_SET_SELECTED_SEARCH_ID =
  'SOCIAL_STREAM_SET_SELECTED_SEARCH_ID';
export const SOCIAL_STREAM_UPDATE_SEARCH_CRITERIA =
  'SOCIAL_STREAM_UPDATE_SEARCH_CRITERIA';
export const SOCIAL_STREAM_CLEAR_SEARCH_CRITERIA =
  'SOCIAL_STREAM_CLEAR_SEARCH_CRITERIA';
export const SOCIAL_STREAM_SET_SELECTED_DATE_OPTION =
  'SOCIAL_STREAM_SET_SELECTED_DATE_OPTION';

interface ToggleSocialStreamSearchOptionAction {
  type: typeof SOCIAL_STREAM_TOGGLE_SEARCH_OPTION;
  payload: string;
}

interface SetSelectedSocialSearchIdAction {
  type: typeof SOCIAL_STREAM_SET_SELECTED_SEARCH_ID;
  payload: SocialSearch;
}

interface UpdateSocialStreamSearchCriteria {
  type: typeof SOCIAL_STREAM_UPDATE_SEARCH_CRITERIA;
  payload: SocialMonitoringSearchRequest;
}

interface ClearSocialStreamSearchCriteriaAction {
  type: typeof SOCIAL_STREAM_CLEAR_SEARCH_CRITERIA;
}

interface SetSelectedDateOption {
  type: typeof SOCIAL_STREAM_SET_SELECTED_DATE_OPTION;
  payload: { id: any; label: string };
}

export interface SocialSearchState {
  socialMonitoringSearchRequest: SocialMonitoringSearchRequest;
  openedSocialSearchOptionIds: string[];
  selectedSearch: SocialSearch;
  socialProfileSearches: SocialSearch[];
  keywordSearches: SocialSearch[];
  selectedDateOption: { id: string; label: string };
}

export type SocialSearchActionTypes =
  | ToggleSocialStreamSearchOptionAction
  | SetSelectedSocialSearchIdAction
  | UpdateSocialStreamSearchCriteria
  | ClearSocialStreamSearchCriteriaAction
  | SetSelectedDateOption;
