import moment from 'moment';

import { STORY_BASE_ENDPOINT } from 'constants/apis';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';

import processDemographicsToArray from 'services/demographics-service/demographics-service';
import { performPost } from 'services/rest-service/rest-service';

export const GET_AUDIENCE_DEMOGRAPHICS = 'stories/GET_AUDIENCE_DEMOGRAPHICS';
export const GET_AUDIENCE_DEMOGRAPHICS_SUCCESS =
  'stories/GET_AUDIENCE_DEMOGRAPHICS_SUCCESS';
export const GET_AUDIENCE_DEMOGRAPHICS_ERROR =
  'stories/GET_AUDIENCE_DEMOGRAPHICS_ERROR';

const initialState = {
  loading: false,
  error: false,
  data: {},
};

const storyAudienceDemographicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIENCE_DEMOGRAPHICS:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_AUDIENCE_DEMOGRAPHICS_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
        data: action.payload,
      };
    }
    case GET_AUDIENCE_DEMOGRAPHICS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const getStoryAudienceDemographicsActionDispatcher = (
  storyId,
  storyLaunchDate,
) => dispatch => {
  if (!storyLaunchDate) return;

  dispatch({ type: GET_AUDIENCE_DEMOGRAPHICS });

  performPost(`${STORY_BASE_ENDPOINT}/${storyId}/coverage/demographics`, {
    endDate: moment.utc().valueOf(),
    startDate: moment.utc(storyLaunchDate).valueOf(),
  })
    .then(response => {
      const r = response.data;

      const ages = processDemographicsToArray(r.ages, ['0-11', '12-17']);
      const genders = processDemographicsToArray(r.genders);

      dispatch({
        type: GET_AUDIENCE_DEMOGRAPHICS_SUCCESS,
        payload: {
          ages,
          genders,
        },
      });
    })
    .catch(error => {
      dispatch({ type: GET_AUDIENCE_DEMOGRAPHICS_ERROR });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'There was a problem retrieving audience demographics.',
          status: 'danger',
        }),
      );
      throw error;
    });
};

export default storyAudienceDemographicsReducer;
