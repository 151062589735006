import React, { Component } from 'react';

import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import script from 'scriptjs';

import {
  Body,
  Button,
  SvgIcon,
  DisplaySM,
  withResponsiveModifiers,
  Loader,
} from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';
import {
  spokespeoplePropTypes,
  isValidURL,
} from '../story/templates/default-template';

// i18n
import messages from './StorySpokespeopleDisplay.messages';

script('//platform.twitter.com/widgets.js', 'twitter-embed');

class StorySpokespeopleDisplay extends Component {
  static baseClass = 'tk-spokespeople-display';

  componentDidMount() {
    script.ready('twitter-embed', () => {
      if (!window.twttr) return;

      window.twttr.widgets.load();
    });
  }

  componentDidUpdate() {
    if (!window.twttr) return;

    window.twttr.widgets.load();
  }

  render() {
    const { className, spokespeople, isEditable, isSaving, intl } = this.props;

    return (
      <div className={`${StorySpokespeopleDisplay.baseClass} ${className}`}>
        <StorySection>
          <StorySectionHeader label={intl.formatMessage(messages.title)} />
          <div className={`${StorySpokespeopleDisplay.baseClass}__people`}>
            {spokespeople &&
              spokespeople.map(person => {
                const imageStyle = person.image
                  ? { backgroundImage: `url("${person.image}")` }
                  : {};
                const linkedinURL = isValidURL(person.linkedin)
                  ? person.linkedin
                  : `https://linkedin.com/in/${person.linkedin}`;

                return (
                  <div
                    className={`${StorySpokespeopleDisplay.baseClass}__person`}
                    key={`spokespeople-display-${person.id}`}
                  >
                    <div
                      className={`${StorySpokespeopleDisplay.baseClass}__header`}
                    >
                      <div
                        className={`${StorySpokespeopleDisplay.baseClass}__header-info`}
                      >
                        <DisplaySM>{`${person.firstName} ${person.lastName}`}</DisplaySM>
                        {person.jobTitle && (
                          <span
                            className={`${StorySpokespeopleDisplay.baseClass}__title`}
                          >
                            {person.jobTitle}
                          </span>
                        )}
                        {(person.twitter || person.linkedin) && (
                          <div
                            className={`${StorySpokespeopleDisplay.baseClass}__social`}
                          >
                            {person.twitter && (
                              <a
                                className="twitter-follow-button"
                                href={`https://twitter.com/${person.twitter}`}
                                data-show-screen-name="false"
                                data-show-count="false"
                              >
                                <TranslatedMessage
                                  {...messages.followTwitterPerson}
                                  values={{ TWITTER_PERSON: person.twitter }}
                                />
                              </a>
                            )}
                            {person.linkedin && (
                              <a
                                href={linkedinURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <SvgIcon
                                  icon="linkedin"
                                  width={20}
                                  height={20}
                                />
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                      {person.image && (
                        <div
                          className={`${StorySpokespeopleDisplay.baseClass}__image`}
                          style={imageStyle}
                        />
                      )}
                    </div>
                    <div
                      className={`${StorySpokespeopleDisplay.baseClass}__content`}
                    >
                      {person.about && (
                        <div
                          className={`${StorySpokespeopleDisplay.baseClass}__about`}
                        >
                          <Body color="light">{person.about}</Body>
                        </div>
                      )}
                      {person.quotes && (
                        <div
                          className={`${StorySpokespeopleDisplay.baseClass}__quotes`}
                        >
                          <StorySectionHeader
                            label={intl.formatMessage(messages.fullName, {
                              FULL_NAME: `${person.firstName} ${person.lastName}`,
                            })}
                          />
                          <Body color="light">{person.quotes}</Body>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          {isEditable && !isSaving && (
            <div className={`${StorySpokespeopleDisplay.baseClass}__footer`}>
              <Button modifiers={['calltoaction', 'inline-flex']} active>
                <SvgIcon icon="plus" width={20} height={20} />
                <span className={`${StorySpokespeopleDisplay.baseClass}__add`}>
                  <TranslatedMessage {...messages.addSpokespeople} />
                </span>
              </Button>
            </div>
          )}
          {isSaving && (
            <div className={`${StorySpokespeopleDisplay.baseClass}__footer`}>
              <Loader />
            </div>
          )}
        </StorySection>
      </div>
    );
  }
}

StorySpokespeopleDisplay.propTypes = {
  className: PropTypes.string,
  spokespeople: spokespeoplePropTypes,
  isEditable: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

StorySpokespeopleDisplay.defaultProps = {
  className: '',
  spokespeople: [],
};

export default withResponsiveModifiers(injectIntl(StorySpokespeopleDisplay));
