import React, { useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

const DynamicSticky = props => {
  const windowScrollListener = useCallback(() => {
    // The moving element (on scroll) which will stick to `anchor`.
    //
    const sticky = props.sticky.current;

    // An element to which `sticky` will attach its vertical position (via
    // position: fixed CSS.
    //
    const anchor = props.anchor.current;

    // Standard way of figuring out where a target element is in relation to
    // concerned viewports.
    //
    const scrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;

    const anchorBottom = anchor.offsetTop + anchor.offsetHeight;
    const scrollPosition = props.stickyAt - scrollTop;

    sticky.style.top = Math.max(scrollPosition, anchorBottom) + 'px';
  }, [props.anchor, props.sticky, props.stickyAt]);

  useEffect(() => {
    window.addEventListener('scroll', windowScrollListener);

    return () => {
      window.removeEventListener('scroll', windowScrollListener);
    };
  }, [windowScrollListener]);

  return <div>{props.children}</div>;
};

DynamicSticky.propTypes = {
  children: PropTypes.node.isRequired,
  sticky: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  anchor: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  stickyAt: PropTypes.number,
};

DynamicSticky.defaultProps = {
  stickyAt: null,
  anchor: { current: null },
  sticky: { current: null },
};

export default DynamicSticky;
