import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from '../loader';

class StatusIndicator extends Component {
  renderIndicator = (text, status) => {
    const statusClass = `status-indicator status-indicator--${status}`;

    return (
      <div className={statusClass}>
        {status === 'loading' && (
          <Loader
            size="tiny"
            style={{
              display: 'inline-block',
              marginLeft: '-5px',
              marginRight: '5px',
            }}
          />
        )}
        <span>{text}</span>
      </div>
    );
  };

  render() {
    const { text, status } = this.props;

    return this.renderIndicator(text, status);
  }
}

StatusIndicator.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
};

StatusIndicator.defaultProps = {
  status: 'none',
};

export default StatusIndicator;
