import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { INFLUENCERS_HUB_LIST_RESULTS_URL } from 'constants/constants';
import { addPageMessage } from 'reducers/page-messages';
import { performGet, performDelete } from 'services/rest-service/rest-service';
import {
  getCurrentUserId,
  getDisplayNamesByIds,
} from 'services/user-service/user-service';

import { UPDATE_CAMPAIGN_PROPERTY } from './campaign';

export const GET_CAMPAIGN_LISTS = 'campaigns/lists/GET_CAMPAIGN_LISTS';
export const GET_CAMPAIGN_LISTS_RECEIVED =
  'campaigns/lists/GET_CAMPAIGN_LISTS_RECEIVED';
export const GET_CAMPAIGN_LISTS_ERROR =
  'campaigns/lists/GET_CAMPAIGN_LISTS_ERROR';
export const DELETE_CAMPAIGN_LIST = 'campaigns/lists/DELETE_CAMPAIGN_LIST';
export const DELETE_CAMPAIGN_LIST_SUCCESS =
  'campaigns/lists/DELETE_CAMPAIGN_LIST_SUCCESS';
export const DELETE_CAMPAIGN_LIST_ERROR =
  'campaigns/lists/DELETE_CAMPAIGN_LIST_ERROR';
export const GET_CAMPAIGN_LIST_OWNERS =
  'campaigns/lists/GET_CAMPAIGN_LIST_OWNERS';
export const GET_CAMPAIGN_LIST_OWNERS_SUCCESS =
  'campaigns/lists/GET_CAMPAIGN_LIST_OWNERS_SUCCESS';
export const GET_CAMPAIGN_LIST_OWNERS_ERROR =
  'campaigns/lists/GET_CAMPAIGN_LIST_OWNERS_ERROR';
export const GET_CAMPAIGN_RENDER_HEADER_ITEMS =
  'campaigns/lists/GET_CAMPAIGN_RENDER_HEADER_ITEMS';

export const initialState = {
  lists: [],
  loading: false,
  error: false,
  renderHeaderItems: false,
};

const campaignListsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LISTS: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case GET_CAMPAIGN_LISTS_RECEIVED: {
      const { lists } = action.payload;

      return {
        loading: false,
        error: false,
        lists,
      };
    }

    case GET_CAMPAIGN_LISTS_ERROR: {
      const { lists } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        lists,
      };
    }

    case GET_CAMPAIGN_LIST_OWNERS_SUCCESS: {
      const displayNames = action.payload;
      const listsWithNames = [...state.lists];
      listsWithNames.forEach(
        nl => (nl.ownedBy = displayNames[nl.userId] || ''),
      );
      return {
        ...state,
        lists: [...listsWithNames],
      };
    }

    case DELETE_CAMPAIGN_LIST: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }

    case DELETE_CAMPAIGN_LIST_SUCCESS: {
      const { lists } = action.payload;

      return {
        ...state,
        loading: false,
        lists,
      };
    }

    case DELETE_CAMPAIGN_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case GET_CAMPAIGN_RENDER_HEADER_ITEMS: {
      return {
        ...state,
        renderHeaderItems: action.payload,
      };
    }

    default:
      return state;
  }
};

export const setCampaignRenderHeaderItems = isRenderHeaderItems => async dispatch => {
  dispatch({
    type: GET_CAMPAIGN_RENDER_HEADER_ITEMS,
    payload: isRenderHeaderItems,
  });
};

const getListOwnerNames = ownerIds => async dispatch => {
  dispatch({
    type: GET_CAMPAIGN_LIST_OWNERS,
  });

  try {
    const displayNames = await getDisplayNamesByIds(ownerIds);
    dispatch({
      type: GET_CAMPAIGN_LIST_OWNERS_SUCCESS,
      payload: displayNames,
    });
  } catch (e) {
    dispatch({
      type: GET_CAMPAIGN_LIST_OWNERS_ERROR,
    });
    throw e;
  }
};

export const getCampaignListsByCampaignId = (
  campaignId,
  failureMessage,
) => async dispatch => {
  dispatch({
    type: GET_CAMPAIGN_LISTS,
  });

  try {
    const currentUserId = getCurrentUserId();
    const listsRequest = await performGet(
      `${CAMPAIGN_ENDPOINT}/${campaignId}/lists`,
    );

    const lists = isEmpty(listsRequest.data.hits)
      ? []
      : listsRequest.data.hits.map(list => ({
          ...list,
          icon: 'contact-list',
          title: list.name,
          titleMaxLength: Number.MAX_SAFE_INTEGER,
          isLegacyLink: false,
          url: `#${INFLUENCERS_HUB_LIST_RESULTS_URL}/${list.id}`,
          owned: list.userId === currentUserId.toString(),
          shared: list.isShared,
          lastModified: list.lastUpdated,
        }));

    dispatch({
      type: GET_CAMPAIGN_LISTS_RECEIVED,
      payload: {
        lists,
      },
    });
    const ownerIds = uniq(lists.map(nl => nl.userId));
    if (!isEmpty(ownerIds)) {
      dispatch(getListOwnerNames(ownerIds));
    }
  } catch (e) {
    dispatch({
      type: GET_CAMPAIGN_LISTS_ERROR,
      payload: {
        lists: [],
      },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: failureMessage,
        status: 'danger',
      }),
    );
  }
};

export const deleteCampaignListsById = (
  campaignId,
  listIds,
  intl,
  messages,
) => async (dispatch, getState) => {
  const campaignLists = getState().campaignLists.lists;
  const updatedList = campaignLists.filter(
    list => listIds.indexOf(list.id) === -1,
  );

  dispatch({
    type: DELETE_CAMPAIGN_LIST,
  });

  try {
    await performDelete(`${CAMPAIGN_ENDPOINT}/${campaignId}/lists`, {
      listIds,
    });
    dispatch({
      type: DELETE_CAMPAIGN_LIST_SUCCESS,
      payload: {
        lists: updatedList,
      },
    });
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: {
        influencerListIds: updatedList.map(list => list.id),
      },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.deleteCampaignListSuccess),
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({
      type: DELETE_CAMPAIGN_LIST_ERROR,
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.deleteCampaignListFailure),
        status: 'danger',
      }),
    );
  }
};

export default campaignListsReducer;
