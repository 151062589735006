import React, { Component } from 'react';

import PropTypes from 'prop-types';

import EditStorySummary from './EditStorySummary';

class EditStorySummaryContainer extends Component {
  onEndEditing = () => {
    this.props.onEndEditing();
  };

  render() {
    const { focus, inputModelName, updateOn, value } = this.props;

    return (
      <EditStorySummary
        focus={focus}
        inputModelName={inputModelName}
        onEndEditing={this.onEndEditing}
        updateOn={updateOn}
        value={value}
      />
    );
  }
}

EditStorySummaryContainer.propTypes = {
  focus: PropTypes.bool,
  inputModelName: PropTypes.string,
  onEndEditing: PropTypes.func.isRequired,
  updateOn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.string,
};

EditStorySummaryContainer.defaultProps = {
  focus: false,
  inputModelName: 'summary',
  updateOn: 'change',
  value: '',
};

export default EditStorySummaryContainer;
