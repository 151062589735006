import React, { Component } from 'react';

import { Button } from '@cision/rover-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Avatar from '../avatar';
import Media from '../media';
import SvgIcon from '../svg-icon';
import Tooltip from '../tooltip';

import { Headline, Body } from '../type';

// Ellipsis is controlled by CSS, so it's difficult to pinpoint consistently
// when to enable tooltips, but after experimentation 21 characters seems to cover
// all of the cases with a little bit of possible overlap into non-cutoff items.
const TOOLTIP_CUTOFF = 21;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class SideBarListItem extends Component {
  baseClass = 'tk-side-bar-list-item';

  wrapTooltip = children => {
    const { title, tooltip, tooltipClassName } = this.props;

    let result = children;

    if (tooltip && title.length > TOOLTIP_CUTOFF) {
      result = (
        <Tooltip
          className={tooltipClassName}
          content={title}
          contentWidth={190}
          position="topright"
        >
          {children}
        </Tooltip>
      );
    }

    return result;
  };

  renderItem = () => {
    const {
      arrow,
      disabled,
      icon,
      opened,
      sub,
      title,
      profileItem,
      children,
      selected,
      titleWeight,
      rightContent,
    } = this.props;

    const color = disabled ? 'light' : 'dark';

    if (children) {
      return this.wrapTooltip(children);
    } else if (sub) {
      const subItemClasses = classNames([`${this.baseClass}__sub-item`], {
        [`${this.baseClass}__sub-item--with-icon`]: !!icon,
      });
      const subContainerClasses = classNames(
        [`${this.baseClass}__sub-container`],
        {
          [`${this.baseClass}__sub-container--with-right-content`]: !!rightContent,
        },
      );

      const itemContainer = (
        <div className={subContainerClasses}>
          {icon && (
            <div className={`${this.baseClass}__sub-icon`}>
              <SvgIcon icon={icon} />
            </div>
          )}
          <div className={subItemClasses}>
            {children || (
              <ConditionalWrapper
                condition={profileItem}
                wrapper={children => (
                  <div
                    className={classNames(`${this.baseClass}__profile-item`)}
                  >
                    {!icon && (
                      <Media.Item>
                        <Avatar
                          size="small"
                          url={profileItem.imageUrl}
                          name={profileItem.name}
                          loading={false}
                        />
                      </Media.Item>
                    )}
                    <div className={`${this.baseClass}__profile-body`}>
                      <Media.Body>
                        <div>
                          <Body
                            color="dark"
                            size="md"
                            weight={selected ? 'bold' : 'normal'}
                          >
                            <div className={`${this.baseClass}__profile-name`}>
                              {profileItem.name}
                              {!icon && profileItem.isVerified && (
                                <SvgIcon
                                  className={`${this.baseClass}__profile-verified`}
                                  icon="verified"
                                  width={16}
                                  height={16}
                                />
                              )}
                            </div>
                          </Body>
                        </div>
                      </Media.Body>
                      {!icon && children}
                    </div>
                  </div>
                )}
              >
                <Body color={color} weight={selected ? 'bold' : 'normal'}>
                  {title}
                </Body>
              </ConditionalWrapper>
            )}
          </div>
          {rightContent}
        </div>
      );

      return this.wrapTooltip(itemContainer);
    }

    if (arrow || opened) {
      return (
        <div className={`${this.baseClass}__arrow-item`}>
          <Headline
            color={color}
            weight={titleWeight || disabled ? 'light' : 'bold'}
          >
            <SvgIcon icon="arrowDown" height={7} width={7} />
            {title}
          </Headline>
        </div>
      );
    }
    return (
      <div className={`${this.baseClass}__headline-item`}>
        <Headline
          color={color}
          weight={titleWeight || disabled ? 'light' : 'bold'}
        >
          {title}
        </Headline>
      </div>
    );
  };

  render() {
    const { selected, opened, path, onClick } = this.props;

    const handleClick = e => onClick && onClick(e);

    const contentBaseClass = `${this.baseClass}__content`;
    const contentClass = classNames(contentBaseClass, {
      [`${contentBaseClass}--selected`]: selected,
      [`${contentBaseClass}--opened`]: opened,
    });

    const item = this.renderItem();
    const content = <div className={contentClass}>{item}</div>;

    const clickableProps = {
      onClick: handleClick,
    };

    const linkProps = {
      tag: Link,
      to: { pathname: path },
    };

    return (
      <Button
        data-qa="eaxkhxYf05sHpeodBNlKi"
        className={this.baseClass}
        level="text"
        {...(onClick ? clickableProps : undefined)}
        {...(path ? linkProps : undefined)}
      >
        {content}
      </Button>
    );
  }
}

SideBarListItem.propTypes = {
  arrow: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  sub: PropTypes.bool,
  profileItem: PropTypes.object,
  titleWeight: PropTypes.string,
  children: PropTypes.node,
  opened: PropTypes.bool,
  path: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipClassName: PropTypes.string,
  onClick: PropTypes.func,
  rightContent: PropTypes.node,
};

SideBarListItem.defaultProps = {
  arrow: false,
  disabled: false,
  icon: null,
  opened: false,
  path: '',
  selected: false,
  sub: false,
  title: 'Item',
  tooltip: false,
  tooltipClassName: '',
  rightContent: null,
};

export default SideBarListItem;
