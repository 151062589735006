export default {
  header: {
    id: 'EDIT-STORY-BACKLINKS_HEADER',
    defaultMessage: 'Preferred Story Backlinks',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit preferred backlinks, displays header',
  },
  backlinkIndex: {
    id: 'EDIT-STORY-BACKLINKS_BACKLINK_INDEX',
    defaultMessage: 'Backlink {NUMBER}',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit preferred backlinks, backlink number indicator',
  },
  anchorTextPlaceholder: {
    id: 'EDIT-STORY-BACKLINKS_ANCHOR_TEXT_PLACEHOLDER',
    defaultMessage: 'Anchor text (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit preferred backlinks, anchor text placeholder',
  },
  addAnotherLink: {
    id: 'EDIT-STORY-BACKLINKS_ADD_ANOTHER_LINK',
    defaultMessage: 'Add another link',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit preferred backlinks, add another link',
  },
};
