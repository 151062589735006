import React from 'react';

import PropTypes from 'prop-types';

import { Body } from '@trendkite/ui';

import {
  userHasDevFeatureFlag,
  userHasFeatureFlag,
  userHasSomeFeatureFlags,
} from 'trendkite-front-end-app/src/services/feature-service/feature-service';

import getMenuProps, { getSecondaryMenuProps } from './menu-props';
import MenuSm from './menu-sm';
import MenuLg from './MenuLg';

const Menu = ({
  cisionCompanyId,
  currentNav,
  endImpersonation,
  firstName,
  isAuthed,
  isCompact,
  loginNavEnabled,
  logoutUrl,
  menuLgRef,
  userIsImpersonating,
  username,
}) => {
  const menuProps = getMenuProps({
    getMenuProps,
    cisionCompanyId,
    currentNav,
    userHasDevFeatureFlag,
    userHasFeatureFlag,
    userHasSomeFeatureFlags,
  });

  const secondaryMenuProps = getSecondaryMenuProps({
    isAuthed,
    userHasDevFeatureFlag,
    userIsImpersonating,
  });

  if (isCompact) {
    return (
      <div className={Menu.baseClass}>
        <Body color="inverse" block>
          <MenuSm
            endImpersonation={endImpersonation}
            firstName={firstName}
            isAuthed={isAuthed}
            loginNavEnabled={loginNavEnabled}
            logoutUrl={logoutUrl}
            menuProps={menuProps}
            secondaryMenuProps={secondaryMenuProps}
            userIsImpersonating={userIsImpersonating}
            username={username}
          />
        </Body>
      </div>
    );
  } else if (isAuthed) {
    return (
      <div className={Menu.baseClass}>
        <Body color="inverse" block>
          <MenuLg menuProps={menuProps} ref={menuLgRef} />
        </Body>
      </div>
    );
  }

  return null;
};

Menu.baseClass = 'universal-nav-header-menu';

Menu.propTypes = {
  cisionCompanyId: PropTypes.number,
  currentNav: PropTypes.string,
  endImpersonation: PropTypes.func,
  firstName: PropTypes.string,
  isAuthed: PropTypes.bool,
  isCompact: PropTypes.bool,
  loginNavEnabled: PropTypes.bool,
  logoutUrl: PropTypes.string,
  menuLgRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  userIsImpersonating: PropTypes.bool,
  username: PropTypes.string,
};

Menu.defaultProps = {
  cisionCompanyId: null,
  currentNav: '',
  endImpersonation: () => {},
  firstName: '',
  isAuthed: false,
  isCompact: false,
  loginNavEnabled: false,
  logoutUrl: '',
  userIsImpersonating: false,
  username: '',
};

export default Menu;
