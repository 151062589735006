import * as constants from '../constants/constants';

export const APP_KEY = {
  AMERICAN_KEY: '1!3aRiF4SCJiVp3NJBoenlhkqo-B8Tsw6j3RELHAxKj6Yt8AD',
  CANADIAN_KEY: '1!SteJDbATaqtqUxcB5xtHaCoxkM26e48F2V6OFeK6w3Et893',
  EMEIA_KEY: '1!bFIjfasWAlel2xjQa1zlGpV2cIrs8O2AoxkMZe0jgQItDE1',
  FRENCH_KEY: '1!YiOvofs045bLR4Cqm8W2L4gCOUKnxBT5QsPfValndNgtDE4',
  GLOBAL_KEY: '1!3aRiF4SCJiVp3NJBoenlhkqo-B8Tsw6j3RELHAxKj6Yt8AD',
};

export const METRICS = {
  REACT_SEARCH: 'React Search',
  DISCOVERY: 'Discovery',
  REACT_TAG: 'React Tag',
  SOCIAL_SEARCH: 'Social Search',
  ARTICLES: 'Articles',
  SEARCH_HISTORY: 'Search History',
  TRANSLATE: 'Translate',
  EARNED_SEARCHES: 'Earned Searches',
  CONTACTS: 'Contacs',
  EMAIL: 'Email',
  BULK_SEND: 'Bulk Send',
  RECIPIENT_UNSUBSCRIPTION: 'Recipient Unsubscription',
  INFLUENCERS_HUB: 'Influencers Hub',
  CAMPAIGNS: 'Campaigns',
  STORIES: 'Stories',
  DASHBOARD: 'Dashboard',
  NEW_DASHBOARD: 'New Dashboard',
  IMPACT: 'Impact',
  WIDGET_DRILLDOWN: 'Widget Drilldown',
  REPORTS: 'Reports',
  NEW_REPORT: 'New Report',
  DRILLDOWN: 'Drilldown',
  RECIPIENT_ALERTS: 'Recipient Alerts',
  ADMIN: 'Admin',
  UNIFIED_SETTINGS: 'Unified Settings',
  UNKNOWN: 'Unknown',
};

export const routeToMetricMap = {
  //coverage routes
  [constants.REACT_SEARCH_BASE_URL]: METRICS.REACT_SEARCH,
  [constants.DISCOVERY_BASE_URL]: METRICS.DISCOVERY,
  [constants.REACT_TAG_BASE_URL]: METRICS.REACT_TAG,
  [constants.SOCIAL_SEARCH_BASE_URL]: METRICS.SOCIAL_SEARCH,
  [constants.ARTICLES_BASE_URL]: METRICS.ARTICLES,
  [constants.SEARCH_HISTORY_BASE_URL]: METRICS.SEARCH_HISTORY,
  [constants.TRANSLATE_BASE_URL]: METRICS.TRANSLATE,
  [constants.EARNED_SEARCHES_BASE_URL]: METRICS.EARNED_SEARCHES,

  //influencers routes
  [constants.CONTACTS_BASE_URL]: METRICS.CONTACTS,
  [constants.EMAIL_BASE_URL]: METRICS.EMAIL,
  [constants.BULK_SEND_BASE_URL]: METRICS.BULK_SEND,
  [constants.RECIPIENT_UNSUBSCRIPTION_BASE_URL]:
    METRICS.RECIPIENT_UNSUBSCRIPTION,

  //contacts routes
  [constants.INFLUENCERS_HUB_BASE_URL]: METRICS.INFLUENCERS_HUB,

  //campaigns routes
  [constants.CAMPAIGNS_BASE_URL]: METRICS.CAMPAIGNS,
  [constants.STORIES_BASE_URL]: METRICS.STORIES,

  //analytics routes
  [constants.DASHBOARD_BASE_URL]: METRICS.DASHBOARD,
  [constants.NEW_DASHBOARD_BASE_URL]: METRICS.NEW_DASHBOARD,
  [constants.IMPACT_BASE_URL]: METRICS.IMPACT,
  [constants.WIDGET_DRILLDOWN_URL]: METRICS.WIDGET_DRILLDOWN,

  //reports routes
  [constants.REPORTS_BASE_URL]: METRICS.REPORTS,
  [constants.NEW_REPORT_BASE_URL]: METRICS.NEW_REPORT,
  [constants.DRILLDOWN_BASE_URL]: METRICS.DRILLDOWN,

  //alerts routes
  [constants.RECIPIENT_ALERTS_BASE_URL]: METRICS.RECIPIENT_ALERTS,

  //admin routes
  [constants.ADMIN_BASE_URL]: METRICS.ADMIN,

  //settings routes
  [constants.UNIFIED_SETTINGS_BASE_URL]: METRICS.UNIFIED_SETTINGS,
};
