import { createSelector } from 'reselect';

interface PaginationCount {
  paginationCount: number;
}

interface Counts {
  all: PaginationCount;
  allForFeed: PaginationCount;
  owned: PaginationCount;
  shared: PaginationCount;
  starred: PaginationCount;
  archived: PaginationCount;
  legacy: PaginationCount;
  deleted: PaginationCount;
}

interface RootState {
  dashboard: number;
}

export const dashboardObj = (state: RootState) => state.dashboard || {};

const getPaginationCount = (
  paginationResponse: Counts,
  listType: keyof Counts,
): number | null => paginationResponse[listType].paginationCount || null;

export const allAnalyticsPaginationCountSelector = createSelector(
  dashboardObj,
  (paginationResponse: Counts): number | null =>
    getPaginationCount(paginationResponse, 'all'),
);

export const ownedAnalyticsPaginationCountSelector = createSelector(
  dashboardObj,
  (paginationResponse: Counts): number | null =>
    getPaginationCount(paginationResponse, 'owned'),
);

export const sharedAnalyticsPaginationCountSelector = createSelector(
  dashboardObj,
  (paginationResponse: Counts): number | null =>
    getPaginationCount(paginationResponse, 'shared'),
);

export const starredAnalyticsPaginationCountSelector = createSelector(
  dashboardObj,
  (paginationResponse: Counts): number | null =>
    getPaginationCount(paginationResponse, 'starred'),
);

export const archivedAnalyticsPaginationCountSelector = createSelector(
  dashboardObj,
  (paginationResponse: Counts): number | null =>
    getPaginationCount(paginationResponse, 'archived'),
);

export const deletedAnalyticsPaginationCountSelector = createSelector(
  dashboardObj,
  (paginationResponse: Counts): number | null =>
    getPaginationCount(paginationResponse, 'deleted'),
);

export const feedAnalyticsPaginationCountSelector = createSelector(
  dashboardObj,
  (paginationResponse: Counts): number | null =>
    getPaginationCount(paginationResponse, 'allForFeed'),
);
