import React from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  VictoryContainer,
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryClipContainer,
} from 'victory';

import { Body, DisplayMD, DisplaySM } from '../type';

import messages from './performance.messages';
import PerformanceMetricDelta from './PerformanceMetricDelta';

const LINE_CHART_HEIGHT = 60;
const LINE_CHART_WIDTH = 220;
const LINE_CHART_STROKE_WIDTH = 1;
const LINE_CHART_DASH = '3,2';

const PerformanceMetric = ({
  chartData,
  secondaryChartData,
  chartLineColor,
  secondaryChartLineColor,
  deltaLabel,
  deltaNumber,
  deltaPositive,
  isPercentage,
  number,
  secondaryNumber,
  secondaryLabel,
  title,
}) => {
  const intl = useIntl();
  const chartStyle = {
    data: {
      stroke: chartLineColor,
      strokeWidth: LINE_CHART_STROKE_WIDTH,
    },
  };

  const secondaryChartStyle = {
    data: {
      stroke: secondaryChartLineColor,
      strokeWidth: LINE_CHART_STROKE_WIDTH,
      strokeDasharray: LINE_CHART_DASH,
    },
  };

  return (
    <div className="performance-metric">
      <div className="performance-metric__title">
        <Body color="light">{title}</Body>
      </div>
      {number && (
        <div className="performance-metric__container">
          <DisplayMD>{number}</DisplayMD>
          {isPercentage && <DisplaySM>/100</DisplaySM>}
          {(deltaLabel || deltaNumber) && (
            <PerformanceMetricDelta
              delta={deltaNumber}
              label={deltaLabel}
              positive={deltaPositive}
            />
          )}
          {(secondaryNumber || secondaryLabel) && (
            <div className="performance-metric__secondary">
              <Body color="light" size="sm" block>
                {secondaryNumber} {secondaryLabel}
              </Body>
            </div>
          )}
          {chartData && chartData.length > 0 && (
            <div className="performance-metric__chart">
              <VictoryChart
                containerComponent={<VictoryContainer responsive={false} />}
                height={LINE_CHART_HEIGHT}
                width={LINE_CHART_WIDTH}
                scale={{ x: 'time', y: 'linear' }}
                padding={1}
              >
                <VictoryLine
                  groupComponent={
                    <VictoryClipContainer clipPadding={{ bottom: 1, top: 1 }} />
                  }
                  data={chartData}
                  height={LINE_CHART_HEIGHT}
                  width={LINE_CHART_WIDTH}
                  scale={{ x: 'time', y: 'linear' }}
                  padding={1}
                  style={chartStyle}
                />
                {secondaryChartData && secondaryChartData.length > 0 && (
                  <VictoryLine
                    groupComponent={
                      <VictoryClipContainer
                        clipPadding={{ bottom: 1, top: 1 }}
                      />
                    }
                    data={secondaryChartData}
                    height={LINE_CHART_HEIGHT}
                    width={LINE_CHART_WIDTH}
                    scale={{ x: 'time', y: 'linear' }}
                    padding={1}
                    style={secondaryChartStyle}
                  />
                )}
                <VictoryAxis
                  style={{
                    axis: { stroke: 'none' },
                    axisLabel: { fill: 'transparent' },
                    grid: { stroke: 'none' },
                    ticks: { stroke: 'none' },
                    tickLabels: { fill: 'transparent' },
                  }}
                />
              </VictoryChart>
            </div>
          )}
        </div>
      )}
      {!number && number !== 0 && (
        <div className="performance-metric__no-data">
          {intl.formatMessage(messages.performanceMetricNoDataAvailable)}
        </div>
      )}
    </div>
  );
};

PerformanceMetric.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
  secondaryChartData: PropTypes.arrayOf(PropTypes.object),
  chartLineColor: PropTypes.string,
  deltaLabel: PropTypes.string,
  deltaNumber: PropTypes.string,
  deltaPositive: PropTypes.bool,
  isPercentage: PropTypes.bool,
  number: PropTypes.string,
  secondaryChartLineColor: PropTypes.string,
  secondaryNumber: PropTypes.string,
  secondaryLabel: PropTypes.string,
  title: PropTypes.node.isRequired,
};

PerformanceMetric.defaultProps = {
  chartData: [],
  secondaryChartData: [],
  chartLineColor: '#0092C2',
  deltaLabel: '',
  deltaNumber: undefined,
  deltaPositive: true,
  isPercentage: false,
  number: '0',
  secondaryNumber: '',
  secondaryLabel: '',
  secondaryChartLineColor: '#65C1EA',
};

export default PerformanceMetric;
