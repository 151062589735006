import React from 'react';

import PropTypes from 'prop-types';

import Pill from '../../pill';
import withModifiers from '../../withModifiers';

const ListControlDefaultItem = ({
  onRemoveListItem,
  onClick,
  value: { id, label },
  removable,
}) => (
  <div className={ListControlDefaultItem.baseClass}>
    <Pill
      data-qa="listControlPill"
      onClick={onClick}
      deletePillHandler={onRemoveListItem}
      id={id}
      includeMargin={false}
      name={label || id}
      removable={removable}
    />
  </div>
);

ListControlDefaultItem.baseClass = 'tk-list-control-default-item';

ListControlDefaultItem.propTypes = {
  onRemoveListItem: PropTypes.func,
  onClick: PropTypes.func,
  removable: PropTypes.bool,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
};

ListControlDefaultItem.defaultProps = {
  onRemoveListItem: () => {},
  onClick: () => {},
  removable: true,
};

export default withModifiers(ListControlDefaultItem);
