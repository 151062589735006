import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Chat = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
  </svg>
);
Chat.propTypes = { fill: PropTypes.string };
Chat.defaultProps = { fill: iconColorsMap.mainicon };

export default Chat;
