import PropTypes from 'prop-types';

const lastModifiedPropTypes = PropTypes.shape({
  beforeDate: PropTypes.number,
  afterDate: PropTypes.number,
});

export const paginationPropTypes = PropTypes.shape({
  offset: PropTypes.number,
  limit: PropTypes.number,
});

export const paginationDefaultProps = {
  offset: 0,
  limit: 50,
};

export const sortPropTypes = PropTypes.shape({
  field: PropTypes.oneOf([
    'name',
    'owner',
    'shared',
    'category',
    'last_modified',
  ]),
  order: PropTypes.oneOf(['asc', 'desc']),
});

export const campaignsFilteredViewPropTypes = PropTypes.shape({
  categories: PropTypes.array,
  lastModified: lastModifiedPropTypes,
  owners: PropTypes.array,
  pagination: paginationPropTypes.isRequired,
  searchText: PropTypes.string,
  shareSetting: PropTypes.array,
  sort: sortPropTypes.isRequired,
});

export const campaignsFilteredDefaultProps = {
  categories: [],
  lastModified: {
    beforeDate: undefined,
    afterDate: undefined,
  },
  owners: [],
  pagination: { ...paginationDefaultProps },
  searchText: '',
  shareSetting: [],
  sort: {
    field: 'last_modified',
    order: 'desc',
  },
};
