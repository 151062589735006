import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader, Button, Addon, SvgIcon, ConfirmModal } from '@trendkite/ui';
import messages from './assets-library-modals.messages';
import T from 'i18n/TranslatedMessage';
import styles from './browseModuleUpload.module.css';
import { injectIntl } from 'react-intl';

class MediaScreenForEditAsset extends Component {
  renderImageAssets = data => {
    const { mediaUrl, mediaToken } = this.props;
    return (
      <div className={styles.assetImageContainer}>
        <img
          src={`${mediaUrl}${data.url}${mediaToken}&p=large`}
          className={styles.imageEditContainer}
        />
      </div>
    );
  };
  renderVideoAssets = data => {
    const { mediaUrl, mediaToken } = this.props;
    return (
      <div className={styles.assetImageContainer}>
        <video width="500" height="275" controls>
          <source src={`${mediaUrl}${data.url}${mediaToken}&p=videoProfile`} />
        </video>
      </div>
    );
  };
  render() {
    const {
      editAssetDetails,
      imgGroup,
      videoGroup,
      docGroup,
      isEditScreenForAsset,
      showDeleteConfirmationModalForSingleFile,
    } = this.props.data;
    const { isLoading, intl } = this.props;
    return (
      <div className={styles.rightPaneEdit}>
        <ConfirmModal
          confirmButtonText={intl.formatMessage(
            messages.confirmationDeleteTextForSingleFile,
          )}
          header={intl.formatMessage(
            messages.confirmationModalHeaderForSingelFile,
          )}
          isOpen={showDeleteConfirmationModalForSingleFile}
          message={intl.formatMessage(
            messages.confirmationModalTextForSingleFile,
          )}
          onCancel={() => this.props.onCancelConfirmationModalForSingleFile()}
          onConfirm={() =>
            this.props.onConfirmConfirmationModalForSingleFile(true)
          }
        />
        <div className={styles.buttonRow}>
          <div>
            <Button
              modifiers={['seethrough']}
              onClick={this.props.clickBackBtn}
            >
              <Addon>
                <SvgIcon icon="arrowLeft2" modifiers={['right-small']} />
              </Addon>
              <span>
                <T {...messages.backButtonFromEditSection} />
              </span>
            </Button>
          </div>
          <div className={styles.deleteSaveContainer}>
            <Button
              modifiers={['tertiary', 'round']}
              onClick={() => this.props.handleDeleteClick(editAssetDetails)}
              style={{ marginRight: '10px' }}
            >
              <T {...messages.deleteFromEditScreen} />
            </Button>
            <Button
              modifiers={['primary', 'round']}
              onClick={() => this.props.saveAssetFromEdit(editAssetDetails)}
            >
              <T {...messages.saveChangesButtonFromEditScreen} />
            </Button>
          </div>
        </div>
        {(imgGroup.includes(editAssetDetails.extension) ||
          docGroup.includes(editAssetDetails.extension)) && (
          <div>{this.renderImageAssets(editAssetDetails)}</div>
        )}
        {videoGroup.includes(editAssetDetails.extension) && (
          <div>{this.renderVideoAssets(editAssetDetails)}</div>
        )}
        {isLoading && isEditScreenForAsset && (
          <div className={styles.modalLoader}>
            <Loader />
          </div>
        )}
        <div className={styles.row}>
          <div>
            <input
              type="text"
              className={styles.imageNameDetails}
              onChange={e => this.props.editAssetName(e.target)}
              value={editAssetDetails.name}
            />
          </div>
          <div className={styles.imageSizeDetails}>
            <span className={styles.sizeDetails}>
              {editAssetDetails.sizeInKb} <T {...messages.sizeInKB} />
            </span>
            <span className={styles.sizeDetails}>
              {editAssetDetails.height} x {editAssetDetails.width}
            </span>
          </div>
        </div>
        <div>
          <p className={styles.editCaption}>
            <T {...messages.editCaptionFromEditScreen} />
          </p>
          <textarea
            className={styles.editCaptionTextArea}
            onChange={e => this.props.editAssetCaption(e.target)}
            value={editAssetDetails.caption}
          />
        </div>
      </div>
    );
  }
}
MediaScreenForEditAsset.propTypes = {
  data: PropTypes.shape({
    editAssetDetails: PropTypes.object,
    isEditScreenForAsset: PropTypes.bool,
    imgGroup: PropTypes.array,
    videoGroup: PropTypes.array,
    docGroup: PropTypes.array,
    showDeleteConfirmationModalForSingleFile: PropTypes.bool,
  }),
  mediaUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    formatNumber: PropTypes.func.isRequired,
  }).isRequired,
  mediaToken: PropTypes.string,
  handleDeleteClick: PropTypes.func,
  onCancelConfirmationModalForSingleFile: PropTypes.func,
  onConfirmConfirmationModalForSingleFile: PropTypes.func,
  saveAssetFromEdit: PropTypes.func,
  clickBackBtn: PropTypes.func,
  editAssetName: PropTypes.func,
  editAssetCaption: PropTypes.func,
};
export default injectIntl(MediaScreenForEditAsset);
