import React, { useState, useEffect, useRef } from 'react';

import trim from 'lodash/trim';
import { useIntl } from 'react-intl';

import { Button as TkUiButton, Modal, Type } from '@trendkite/ui';
import { DEV_FEATURES } from 'constants/constants';
import useHandleMousedownOutsideRef from 'hooks/use-handle-mousedown-outside-ref';
import globalMessages from 'i18n/Global.messages';
import T from 'i18n/TranslatedMessage';

import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';

import messages from './BulkSavedListModal.messages';
import styles from './BulkSavedListsModal.module.css';

import { Permissions } from './enums';
import { PermissionOptionSelection } from './PermissionOptionSelection';

type BulkSavedListsModalProps = {
  duplicateListName?: string;
  showDuplicateListModal?: boolean;
  toggleModal: (...args: any) => void;
  listPermission: Permissions;
  setPermission: React.Dispatch<React.SetStateAction<Permissions>>;
  onCreateSavedList: (...args: any) => void;
  toggleConfirmationModal: (...args: any) => void;
  isOpen: boolean;
};

const BulkSavedListsModal: React.FC<BulkSavedListsModalProps> = ({
  onCreateSavedList,
  listPermission,
  setPermission,
  toggleModal,
  toggleConfirmationModal,
  isOpen,
  showDuplicateListModal,
  duplicateListName,
}) => {
  const intl = useIntl();
  const dropdownRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [listName, setListName] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [listNameChanged, setListNameChanged] = useState(false);

  useEffect(() => {
    const showCreateBtn =
      duplicateListName && !listNameChanged ? true : !!trim(listName);
    setSaveDisabled(showCreateBtn);
  }, [listName, duplicateListName, listNameChanged]);

  const showDuplicateListTitle = showDuplicateListModal
    ? { ...messages.duplicateListModalTitle }
    : { ...messages.listNameLabel };
  const listNameValue =
    showDuplicateListModal && !listNameChanged
      ? duplicateListName + ' ' + 'Copy'
      : listName;
  const actionBtn = showDuplicateListModal
    ? { ...globalMessages.save }
    : { ...globalMessages.create };

  const handleOnCreateNewList = () => {
    if (!showDuplicateListModal) {
      toggleModal();
      toggleConfirmationModal(listNameValue.replace(/\s+/g, ' ').trim());
    }
    onCreateSavedList(listNameValue.replace(/\s+/g, ' ').trim());
  };

  const handleOnListNameInputChange = e => {
    const inputValue = e.target.value;
    if (inputValue.includes('\\')) {
      return;
    }
    setListNameChanged(true);
    setListName(inputValue);
  };

  const handleOnToggleModal = () => {
    toggleModal();
    setListName('');
  };

  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);

  const selectOption = permissionOption => {
    setPermission(permissionOption);
    closeDropdown();
  };

  useHandleMousedownOutsideRef(dropdownRef, closeDropdown);

  const hasConnectUpdateSharePermissionsEnabled = userHasDevFeatureFlag(
    DEV_FEATURES.connectUpdateSharePermissions,
  );

  return (
    <Modal isOpen={isOpen} toggle={handleOnToggleModal} size="md">
      <Modal.Header toggle={handleOnToggleModal}>
        <T {...showDuplicateListTitle} />
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className="bulk-saved-list-modal-name-input">
          <p>
            <Type>
              <T {...messages.listNameLabel} />
            </Type>
          </p>
          <input
            value={listNameValue}
            type="text"
            placeholder={intl.formatMessage(messages.listNamePlaceholder)}
            className="tk-form-control"
            onChange={handleOnListNameInputChange}
            maxLength={150}
          />
        </div>
        {hasConnectUpdateSharePermissionsEnabled && (
          <PermissionOptionSelection
            dropdownRef={dropdownRef}
            isDropdownOpen={isDropdownOpen}
            listPermission={listPermission}
            openDropdown={openDropdown}
            selectOption={selectOption}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Actions>
          <Modal.Action>
            <TkUiButton
              data-qa="LeJm1X6tWhU_jLt-i9_vh"
              modifiers={['round', 'tertiary', 'justify-right']}
              onClick={handleOnToggleModal}
            >
              <T {...globalMessages.cancel} />
            </TkUiButton>
          </Modal.Action>
          <Modal.Action>
            <TkUiButton
              data-qa="VdlWbCvf1lX9LoXbd5HAP"
              modifiers={['round', 'primary', 'justify-right']}
              onClick={handleOnCreateNewList}
              disabled={!saveDisabled}
            >
              <T {...actionBtn} />
            </TkUiButton>
          </Modal.Action>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkSavedListsModal;
