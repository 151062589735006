/* eslint react/no-multi-comp: 0 */

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import thumbnailIcon from '../assets/thumbnail.svg';

export class GridTile extends Component {
  render() {
    const {
      children,
      className,
      height,
      modifiers,
      onClick,
      role,
      width,
    } = this.props;

    const baseClass = 'tk-grid-tile';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseClass}--${modifier}`)
      : [];

    const mainClass = classnames(baseClass, className, prefixedModifiers);

    const props = {
      className: mainClass,
    };

    const styles = {};
    if (height) {
      styles.height = `${height}px`;
    }
    if (width) {
      styles.width = `${width}px`;
    }

    if (role) {
      props.role = role;
      props.tabIndex = 0;
      props.onClick = onClick;
    }

    return (
      <div {...props} style={styles}>
        {children}
      </div>
    );
  }
}

export class Hero extends Component {
  render() {
    const { children, imageSrc, showDefaultThumbnail } = this.props;

    const baseClass = 'tk-grid-tile__hero-image';

    const mainClass = classnames(baseClass, {
      'no-image': !imageSrc,
    });

    const heroImageStyles = {
      backgroundImage: imageSrc ? `url("${imageSrc}")` : '',
    };

    return (
      <div className={mainClass} style={heroImageStyles}>
        {!imageSrc && showDefaultThumbnail && (
          <svg>
            <use xlinkHref={`#${thumbnailIcon.id}`} />
          </svg>
        )}
        <div className={`${baseClass}__children`}>{children}</div>
      </div>
    );
  }
}

GridTile.Hero = Hero;

export class Body extends Component {
  render() {
    const { children } = this.props;

    return <div className="tk-grid-tile__body">{children}</div>;
  }
}

GridTile.Body = Body;

GridTile.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  height: PropTypes.number,
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['fluid', 'hero', 'nopadding'])),
  onClick: PropTypes.func,
  role: PropTypes.string,
  width: PropTypes.number,
};

GridTile.defaultProps = {
  className: '',
  height: 0,
  modifiers: [],
  onClick: () => {},
  role: '',
  width: 0,
};

Hero.propTypes = {
  children: PropTypes.node,
  imageSrc: PropTypes.string,
  showDefaultThumbnail: PropTypes.bool,
};

Hero.defaultProps = {
  children: null,
  imageSrc: '',
  showDefaultThumbnail: false,
};

Body.propTypes = {
  children: PropTypes.node,
};

Body.defaultProps = {
  children: null,
};

export default GridTile;
