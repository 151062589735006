export default {
  devFeature: {
    id: 'ADMIN_USER_MANAGEMENT_MODAL_FEATURES_DEV_FEATURE_TITLE',
    defaultMessage: 'Dev Features to update',
    description: 'Admin > User Management > Modal Feature : Dev Feature Title',
  },
  feature: {
    id: 'ADMIN_USER_MANAGEMENT_MODAL_FEATURES_FEATURE_TITLE',
    defaultMessage: 'Features to update',
    description: 'Admin > User Management > Modal Feature : Feature Title',
  },
  apply: {
    id: 'ADMIN_USER_MANAGEMENT_MODAL_FEATURES_APPLY_BUTTON',
    defaultMessage: 'Apply',
    description: 'Admin > User Management > Modal Feature : Apply Button',
  },
  cancel: {
    id: 'ADMIN_USER_MANAGEMENT_MODAL_FEATURES_CANCEL_BUTTON',
    defaultMessage: 'Cancel',
    description: 'Admin > User Management > Modal Feature : Cancel Button',
  },
  editFeaturesSuccessMsg: {
    id: 'ADMIN_PAGE_EDIT_FEATURE_SUCCESS_EDIT_FEATURE_MESSAGE',
    defaultMessage:
      'Success! You have edited the features for account {ACCOUNT_ID}',
    description: 'Admin Page > Edit Features: Edit features success message',
  },
};
