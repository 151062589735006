import React, { Component } from 'react';

import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { WysiwygToolbar } from '@trendkite/ui';

import { DEV_FEATURES } from 'constants/constants';
import { addAttachmentsActionCreator } from 'reducers/outreach/compose-form';
import { hasDevFeatureFlagSelector } from 'selectors/account';

const mapStateToProps = state => ({
  attachmentsActive: hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.outreachAttachments,
  ),
});

const mapDispatchToProps = dispatch => ({
  addAttachments: attachments =>
    dispatch(addAttachmentsActionCreator(attachments)),
});

const toolbarOptions = [
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'color',
  'align',
  { list: 'bullet' },
  { list: 'ordered' },
  'link',
  { optionName: 'attachment' },
  {
    optionName: 'template-string',
  },
];

const buildOptions = memoize(
  (templatesActive, attachmentsActive, templatesToUse) => {
    const filteredOptions = toolbarOptions.filter(
      o =>
        (attachmentsActive || o.optionName !== 'attachment') &&
        (templatesActive || o.optionName !== 'template-string'),
    );

    return filteredOptions.map(o => {
      if (o.optionName === 'template-string') {
        return {
          ...o,
          props: {
            templates: templatesToUse,
          },
        };
      }

      return o;
    });
  },
);

class EmailEditorToolbar extends Component {
  render() {
    const {
      addAttachments,
      templatesActive,
      templatesToUse,
      attachmentsActive,
      onAddedAttachment,
      toolbarId,
    } = this.props;

    const options = buildOptions(
      templatesActive,
      attachmentsActive,
      templatesToUse,
    );
    return (
      <WysiwygToolbar
        id={toolbarId}
        options={options}
        onAddedAttachments={attachments => {
          onAddedAttachment();
          addAttachments(attachments);
        }}
      />
    );
  }
}

EmailEditorToolbar.propTypes = {
  toolbarId: PropTypes.string.isRequired,
  templatesActive: PropTypes.bool,
  attachmentsActive: PropTypes.bool,
  addAttachments: PropTypes.func.isRequired,
  onAddedAttachment: PropTypes.func,
  templatesToUse: PropTypes.arrayOf(PropTypes.string),
};

EmailEditorToolbar.defaultProps = {
  templatesActive: false,
  attachmentsActive: false,
  onAddedAttachment: () => null,
  templatesToUse: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailEditorToolbar);
