import { CONTACT_SOCIAL_TIMELINE_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';
import { DATE_RANGES, getDatesForRange } from 'utils/date/date-util';

import { getPrimaryProfileForNetwork } from '../social-utils';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const tweetsReducerFactory = namespace => {
  const GET_TWEETS_START = `ihub/${namespace}/GET_TWEETS_START`;
  const GET_TWEETS_SUCCESS = `ihub/${namespace}/GET_TWEETS_SUCCESS`;
  const GET_TWEETS_ERROR = `ihub/${namespace}/GET_TWEETS_ERROR`;

  const getRecentTweetsForInfluencer = (
    influencer,
    tweetLimit = 20,
  ) => async dispatch => {
    // TODO: Get recent tweets by influencer ID (getRecentTweetsByInfluencerId)
    dispatch({ type: GET_TWEETS_START });
    let payload = [];
    const twitterProfile = getPrimaryProfileForNetwork(
      influencer.socialProfiles,
      'twitter',
    );
    if (twitterProfile && twitterProfile.handle) {
      const { startDate, endDate } = getDatesForRange(DATE_RANGES.TRAILING_30);
      try {
        const result = await performGet(
          `${CONTACT_SOCIAL_TIMELINE_ENDPOINT}?site=twitter&user=@${
            twitterProfile.handle
          }&startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}&limit=${tweetLimit}`,
        );
        // Hacky way to get limits since the limit param
        // on the endpoint doesn't seem to be working
        payload = result.data.data.tweets.slice(0, tweetLimit - 1);
      } catch (e) {
        dispatch({ type: GET_TWEETS_ERROR, payload: e });
      }
    }
    dispatch({ type: GET_TWEETS_SUCCESS, payload });
  };

  const getRecentTweetsByInfluencerId = () => async dispatch => {
    dispatch({ type: GET_TWEETS_START });
    let payload;

    try {
      // TODO: Use real endpoint with influencer ID
      payload = await performGet(
        `${CONTACT_SOCIAL_TIMELINE_ENDPOINT}?site=twitter&user=@ByEliott&limit=20`,
      );
    } catch (e) {
      dispatch({ type: GET_TWEETS_ERROR, payload: e });
    }
    dispatch({
      type: GET_TWEETS_SUCCESS,
      payload: payload.data.data.tweets.slice(0, 19),
    });
  };

  const tweetsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_TWEETS_START:
        return {
          ...state,
          loading: true,
          error: false,
          data: [],
        };

      case GET_TWEETS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: [...action.payload],
        };

      case GET_TWEETS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  return {
    GET_TWEETS_SUCCESS,
    GET_TWEETS_START,
    GET_TWEETS_ERROR,
    getRecentTweetsByInfluencerId,
    getRecentTweetsForInfluencer,
    tweetsReducer,
  };
};

export default tweetsReducerFactory;
