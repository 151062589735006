export default {
  showAccountLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_SHOW_ACCOUNT_LABEL',
    defaultMessage: 'Show Account',
    description: 'Admin Page > Edit Account: Show account label',
  },
  editAccountLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_LABEL',
    defaultMessage: 'Edit Account',
    description: 'Admin Page > Edit Account: Edit account label',
  },
  accountNameLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ACCOUNT_NAME_LABEL',
    defaultMessage: 'Name',
    description: 'Admin Page > Edit Account: Account name label',
  },
  accountStatusLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ACCOUNT_STATUS_LABEL',
    defaultMessage: 'Status',
    description: 'Admin Page > Edit Account: Account status label',
  },
  accountTypeLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_TYPE_LABEL',
    defaultMessage: 'Account Type',
    description: 'Admin Page > Edit Account: Account type label',
  },
  nextGenC3AccountIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_NEXT_GEN_C3_ACCOUNT_ID_LABEL',
    defaultMessage: 'NGC3 ID',
    description: 'Admin Page > Edit Account: Next gen c3 account id label',
  },
  gcIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_GC_ID_LABEL',
    defaultMessage: 'GC ID',
    description: 'Admin Page > Edit Account: GC ID label',
  },
  sfdcAccountIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_SFDC_ACCOUNT_ID_LABEL',
    defaultMessage: 'SFDC ID',
    description: 'Admin Page > Edit Account: Sfdc account id label',
  },
  sfdcInstanceLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_SFDC_INSTANCE_LABEL',
    defaultMessage: 'SFDC Instance',
    description: 'Admin Page > Edit Account: Sfdc instance label',
  },
  cisionCompanyIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_CISION_COMPANY_ID_LABEL',
    defaultMessage: 'Cision Company ID',
    description: 'Admin Page > Edit Account: Cision company id label',
  },
  vocusOrgIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_VOCUS_ORG_ID_LABEL',
    defaultMessage: 'Vocus Org ID',
    description: 'Admin Page > Edit Account: Vocus org id label',
  },
  omcAccountIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_OMC_ACCOUNT_ID_LABEL',
    defaultMessage: 'OMC ID',
    description: 'Admin Page > Edit Account: OMC account id label',
  },
  dateCreatedLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_DATE_CREATED_LABEL',
    defaultMessage: 'Date Created',
    description: 'Admin Page > Edit Account: Date created label',
  },
  dateLastUpdatedLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_DATE_LAST_UPDATED_LABEL',
    defaultMessage: 'Last Updated',
    description: 'Admin Page > Edit Account: Date last updated label',
  },
  accessControlGroupLimitLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ACCESS_CONTROL_GROUP_LIMIT_LABEL',
    defaultMessage: 'Access Control Group Limit',
    description: 'Admin Page > Edit Account: Access control group limit label',
  },
  maxUsersLimitLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_MAX_USERS_LIMIT_LABEL',
    defaultMessage: 'Max Users Limit',
    description: 'Admin Page > Edit Account: Max users limit label',
  },
  burrellesAccountIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_BURRELLES_ACCOUNT_ID_LABEL',
    defaultMessage: 'Burrelles Account ID',
    description: 'Admin Page > Edit Account: Burrelles account id label',
  },
  newsedgeAccountIdLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_NEWSEDGE_ACCOUNT_ID_LABEL',
    defaultMessage: 'Newsedge Account ID',
    description: 'Admin Page > Edit Account: Newsedge account id label',
  },
  ukPrintClickLimitLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_UK_PRINT_CLICK_LIMIT_LABEL',
    defaultMessage: 'Uk Print Click Limit',
    description: 'Admin Page > Edit Account: Uk print click limit label',
  },
  activeFeatureFlagsLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ACTIVE_FEATURE_FLAGS_LABEL',
    defaultMessage: 'Active Feature Flags',
    description: 'Admin Page > Edit Account: Active feature flags label',
  },
  activeDeveloperFeaturesLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ACTIVE_DEVELOPER_FEATURES_LABEL',
    defaultMessage: 'Active Developer Features',
    description: 'Admin Page > Edit Account: Active developer features label',
  },
  customSentimentsLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_CUSTOM_SENTIMENTS_LABEL',
    defaultMessage: 'Custom Sentiments',
    description: 'Admin Page > Edit Account: Custom sentiments label',
  },
  googleAnalyticsAccountsLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_GOOGLE_ANALITICS_ACCOUNTS_LABEL',
    defaultMessage: 'Google Analytics Accounts',
    description: 'Admin Page > Edit Account: Google analytics accounts label',
  },
  usersLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_USERS_LABEL',
    defaultMessage: 'Users',
    description: 'Admin Page > Edit Account: Users label',
  },
  enabledValue: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ENABLED_VALUE',
    defaultMessage: 'Enabled',
    description: 'Admin Page > Edit Account: Active value',
  },
  disabledValue: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_DISABLED_VALUE',
    defaultMessage: 'Disabled',
    description: 'Admin Page > Edit Account: Disabled value',
  },
  notAvailableValue: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_NOT_AVAILABLE_VALUE',
    defaultMessage: 'Not available',
    description: 'Admin Page > Edit Account: Not available value',
  },
  enterErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ENTER_ERROR_MESSAGE',
    defaultMessage: 'Please enter a',
    description: 'Admin Page > Edit Account: Enter error message',
  },
  getAccountErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_GET_ACCOUNT_ID_ERROR_MESSAGE',
    defaultMessage: 'Could not get Account Id {ACCOUNT_ID}',
    description: 'Admin Page > Edit Account: Get account error message',
  },
  editAccountSuccessMsg: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_SUCCESS_EDIT_ACCOUNT_MESSAGE',
    defaultMessage: 'Success! You have edited account {ACCOUNT_NAME}',
    description: 'Admin Page > Edit Account: Edit account success message',
  },
  alreadyExistsErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ALREADY_EXISTS_ERROR_MESSAGE',
    defaultMessage: 'Account already exists.',
    description:
      'Admin Page > Edit Account: Edit account that already exists error message',
  },
  editAccountDefaultErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_DEFAULT_EDIT_ERROR_MESSAGE',
    defaultMessage:
      'Could not edit account {ACCOUNT_NAME}, please review the data.',
    description:
      'Admin Page > Edit Account: Default text for editing a account error message',
  },
  clearExistingClicksLabel: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_CLEAR_EXISTING_CLICKS_LABEL',
    defaultMessage: 'Clear Existing Clicks',
    description: 'Admin Page > Edit Account: Clear existing clicks label',
  },
  clearExistingClicksSuccessMsg: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_CLEAR_EXISTING_CLICKS_SUCCESS_MESSAGE',
    defaultMessage: 'Success! Cleared existing clicks',
    description:
      'Admin Page > Edit Account: Clear existing clicks success message',
  },
  clearExistingClicksDefaultErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_CLEAR_EXISTING_CLICKS_DEFAULT_ERROR_MESSAGE',
    defaultMessage: 'Could not clear existing clicks.',
    description:
      'Admin Page > Edit Account: Clear existing clicks error message',
  },
  selectPlaceholder: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_SELECT_PLACEHOLDER',
    defaultMessage: 'Please Select One',
    description: 'Admin Page > Edit Account: Select placeholder',
  },
  changingNameTooltip: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_CHANGING_NAME_TOOLTIP',
    defaultMessage:
      'Changing the name will break the integration with Datahub.',
    description: 'Admin Page > Edit Account: Changing the name tooltip',
  },
  changingGcidTooltip: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_CHANGING_GCID_TOOLTIP',
    defaultMessage:
      'Changing GCID will break Auto-provisioning. Double check you have the correct GCID.',
    description: 'Admin Page > Edit Account: Changing GCID tooltip',
  },
  accessControlGroupLimitTooltip: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_ACCESS_CONTROL_GROUP_LIMIT_TOOLTIP',
    defaultMessage:
      'Adding Access Control Groups could carry an additional cost for the customer. Check with the proper sales associate or account manager prior to changing.',
    description:
      'Admin Page > Edit Account: Adding access control groups tooltip',
  },
  maxUsersLimitTooltip: {
    id: 'ADMIN_PAGE_EDIT_ACCOUNT_MAX_USER_LIMIT_TOOLTIP',
    defaultMessage:
      'Adding Users could carry an additional cost for the customer. Check with the proper sales associate or account manager prior to changing.',
    description: 'Admin Page > Edit Account: Max users limit tooltip',
  },
};
