import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const TwitterComment = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M16.1558462,14.2023077 C15.3513846,14.8829231 12.7086154,16.6156923 11.3549231,17.4933846 L11.3549231,15.234 C11.3549231,14.9410769 11.1169231,14.702 10.824,14.702 L10.5418462,14.702 C7.94646154,14.702 6.06292308,12.9476923 6.06292308,10.53 C6.06292308,8.02507692 8.02507692,6.06292308 10.5289231,6.06292308 L13.4678462,6.06938462 L13.4689231,6.06938462 C15.9738462,6.06938462 17.9338462,8.03046154 17.936,10.5332308 C17.936,11.8869231 17.2693846,13.2578462 16.1558462,14.2023077 M13.4710769,5.00646154 L13.4710769,5.00646154 L10.5310769,5 L10.53,5 L10.5289231,5 C7.42846154,5 5,7.42846154 5,10.53 C5,13.4344615 7.25830769,15.6378462 10.292,15.7541538 L10.292,18.468 C10.292,18.5444615 10.3221538,18.6704615 10.376,18.7544615 C10.4772308,18.9127692 10.6484615,19 10.824,19 C10.922,19 11.0210769,18.9730769 11.1093846,18.916 C11.2967692,18.7975385 15.6981538,15.9813846 16.8429231,15.0132308 C18.1912308,13.8716923 18.9967692,12.2003077 19,10.5396923 L19,10.5364615 L19,10.5332308 L19,10.5278462 C18.9956923,7.43169231 16.5683077,5.00753846 13.4710769,5.00646154" />
  </svg>
);

TwitterComment.propTypes = { fill: PropTypes.string };
TwitterComment.defaultProps = { fill: iconColorsMap.twittercomment };
export default TwitterComment;
