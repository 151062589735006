import WebFont from 'webfontloader';

import './container.scss';
import ContainerComp from './Container';

if (process.env.NODE_ENV !== 'test') {
  WebFont.load({
    google: {
      families: ['Source+Sans+Pro:300,300i,400,400i,600,600i'],
    },
  });
}

export default ContainerComp;
