export default {
  archiveDashboardSuccessfully: {
    id: 'DASHBOARDS_ARCHIVE_ACTION_SUCCESS',
    defaultMessage: 'Dashboard Successfully Archived.',
    description:
      'Message to show when the archive dashboard action is successful',
  },
  unarchivedDashboardSuccessfully: {
    id: 'DASHBOARDS_UNARCHIVED_ACTION_SUCCESS',
    defaultMessage: 'Dashboard Successfully Unarchived.',
    description:
      'Message to show when the unarchived dashboard action is successful',
  },
  dashboardSuccessfullyDeleted: {
    id: 'DASHBOARD_SUCCESSFULLY_DELETED',
    description: 'Dashboard has successfully deleted',
    defaultMessage: 'Dashboard successfully deleted.',
  },
  dashboardDeleteError: {
    id: 'DASHBOARD_FAILED_TO_DELETE_ERROR',
    defaultMessage: 'Failed to delete dashboard.',
    description:
      'Dashboard error message to show when the delete dashboard action is not successful.',
  },
  dashboardsSuccessfullyRestored: {
    id: 'DASHBOARDS_SUCCESSFULLY_RESTORED',
    description: 'Dashboard has successfully restored',
    defaultMessage:
      '{DASHBOARD_COUNT, plural, =1{Dashboard} other{Dashboards}} restored.',
  },
  dashboardsRestoredError: {
    id: 'DASHBOARDS_RESTORED_ERROR',
    defaultMessage:
      'Failed to restore {DASHBOARD_COUNT, plural, =1{dashboard} other{dashboards}}.',
    description:
      'Dashboard error message to show when the restore dashboard action is not successful.',
  },
  pageTitle: {
    id: 'DASHBOARDS_PAGE_TITLE',
    description: 'Dashboards 2.0: page title',
    defaultMessage: '(New) Dashboards',
  },
  untitledAnalytic: {
    id: 'DASHBOARD_UNTITLED_DASHBOARD_TITLE',
    description: 'Dashboard 2.0: untitled dashboard title',
    defaultMessage: 'Untitled dashboard',
  },
  dashboardErrorText: {
    id: 'DASHBOARD_ERROR_TEXT',
    description: 'Dashboard 2.0: text displayed if dashboard errors on load',
    defaultMessage:
      'Something went wrong when I was retrieving your dashboard data. Please try again.',
  },
  dashboardNotFoundText: {
    id: 'DASHBOARD_NOT_FOUND_TEXT',
    description: 'Dashboard 2.0: text displayed if dashboard does not exist',
    defaultMessage: 'Dashboard not found.',
  },
  dashboardErrorButtonText: {
    id: 'DASHBOARD_ERROR_BUTTON_TEXT',
    description:
      'Dashboard 2.0: text displayed within button to refresh dashboard page.',
    defaultMessage: 'Refresh Dashboard',
  },
  dashboardLoadingText: {
    id: 'DASHBOARD_LOADING_TEXT',
    description: 'Dashboard 2.0: text displayed if dashboard is loading',
    defaultMessage: 'Preparing your dashboard...',
  },
};
