import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Body, Media } from '@trendkite/ui';

import { EmailRecipientsPlaceholder } from '../email-recipients-control';
import EmailRecipientsDisplay from '../email-recipients-display';

class EmailHeaderDisplay extends Component {
  static baseClass = 'email-header-display';

  render() {
    const { className, formValues } = this.props;
    const areCcsVisible = formValues.cc.length > 0;
    const areBccsVisible = formValues.bcc.length > 0;

    return (
      <div className={`${EmailHeaderDisplay.baseClass} ${className}`}>
        <div
          className={`${EmailHeaderDisplay.baseClass}__control ${EmailHeaderDisplay.baseClass}__control--to`}
        >
          <Media>
            <Media.Body>
              <EmailRecipientsPlaceholder label="To">
                <EmailRecipientsDisplay values={formValues.to} />
              </EmailRecipientsPlaceholder>
            </Media.Body>
          </Media>
        </div>
        {areCcsVisible && (
          <div
            className={`${EmailHeaderDisplay.baseClass}__control ${EmailHeaderDisplay.baseClass}__control--ccs`}
          >
            <Media>
              <Media.Body>
                <EmailRecipientsPlaceholder label="Cc">
                  <EmailRecipientsDisplay values={formValues.cc} />
                </EmailRecipientsPlaceholder>
              </Media.Body>
            </Media>
          </div>
        )}
        {areBccsVisible && (
          <div
            className={`${EmailHeaderDisplay.baseClass}__control ${EmailHeaderDisplay.baseClass}__control--bccs`}
          >
            <Media>
              <Media.Body>
                <EmailRecipientsPlaceholder label="Bcc">
                  <EmailRecipientsDisplay values={formValues.bcc} />
                </EmailRecipientsPlaceholder>
              </Media.Body>
            </Media>
          </div>
        )}
        <div
          className={`${EmailHeaderDisplay.baseClass}__control ${EmailHeaderDisplay.baseClass}__control--subject`}
        >
          <Media>
            <Media.Body>
              <Body weight="bold">{formValues.subject}</Body>
            </Media.Body>
          </Media>
        </div>
      </div>
    );
  }
}

EmailHeaderDisplay.propTypes = {
  className: PropTypes.string,
  formValues: PropTypes.object,
};

EmailHeaderDisplay.defaultProps = {
  className: '',
  formValues: {},
};

export default EmailHeaderDisplay;
