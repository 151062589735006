import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ComponentType from '../component-type';

/*
  This is avoiding some React dev tool errors when you pass unsupported props
  like `inNewTab={undefined}` on bare DOM elements. Undefined props aren't
  passed down to children, but they still throw console warnings. :P
*/
const pruneUndefinedObjectValues = object =>
  Object.keys(object).reduce(
    (result, key) => {
      if (result[key] === undefined) {
        delete result[key];
      }

      return result;
    },
    { ...object },
  );

class Button extends Component {
  renderButton = ({ a11yLabel, ...passedProps }) => {
    return (
      <button
        type="button"
        aria-label={a11yLabel || undefined}
        ref={button => (this.ref = button)}
        {...pruneUndefinedObjectValues(passedProps)}
      />
    );
  };

  renderLink = ({
    a11yLabel,
    children,
    download,
    inNewTab,
    location,
    ...passedProps
  }) => {
    const newTabProps = {};

    if (inNewTab) {
      newTabProps.target = '_blank';
      newTabProps.rel = 'noopener noreferrer';
    }

    return (
      <a
        {...newTabProps}
        aria-label={a11yLabel || undefined}
        download={download || undefined}
        href={location}
        ref={link => (this.ref = link)}
        {...pruneUndefinedObjectValues(passedProps)}
      >
        {children}
      </a>
    );
  };

  renderComponent = ({ a11yLabel, Comp, mainClass, ...passedProps }) => {
    return (
      <Comp
        className={classnames(passedProps.className, mainClass)}
        aria-label={a11yLabel || undefined}
        {...pruneUndefinedObjectValues(passedProps)}
      />
    );
  };

  render() {
    const {
      active,
      disabled,
      modifiers,
      component,
      componentProps,
      location,
      className: customClassName,
      ...passedProps
    } = this.props;

    const baseClass = 'tk-button';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseClass}--${modifier}`)
      : [];

    const className = classnames(
      customClassName,
      baseClass,
      prefixedModifiers,
      {
        [`${baseClass}--disabled`]: disabled,
        [`${baseClass}--active`]: active,
      },
    );

    if (component) {
      return this.renderComponent({
        ...passedProps,
        ...componentProps,
        Comp: component,
        mainClass: className,
      });
    }

    if (location) {
      return this.renderLink({
        ...passedProps,
        location,
        className,
      });
    }

    return this.renderButton({
      ...passedProps,
      disabled,
      className,
    });
  }
}

Button.propTypes = {
  a11yLabel: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Render this instead of button, accepts a React Comp or tag name */
  component: ComponentType,
  /** Props to be passed to rendered component (only if component prop is specified) */
  componentProps: PropTypes.object,
  disabled: PropTypes.bool,
  download: PropTypes.string,
  /** if location provided, will open link in new tab */
  inNewTab: PropTypes.bool,
  /** Will render an anchor tag with this location as href */
  location: PropTypes.string,
  modifiers: PropTypes.arrayOf(
    PropTypes.oneOf([
      'auto-width',
      'bold-text',
      'block',
      'calltoaction',
      'circle',
      'clearable-pill',
      'danger',
      'dark-text',
      'fancy-pill',
      'flex-space-between',
      'flex',
      'highlight',
      'grayHover',
      'iconOnly',
      'inline-flex',
      'inverse',
      'justify-right',
      'large',
      'link',
      'listEntry',
      'margin-left',
      'margin-left-small',
      'margin-right',
      'margin-top',
      'medium',
      'no-padding-left',
      'primary',
      'primaryGreen',
      'primaryTeal',
      'round',
      'secondary',
      'seethrough-inverse',
      'seethrough-inverse-rebrand',
      'seethrough-media',
      'seethrough',
      'selectable',
      'small',
      'tertiary',
      'with-icon',
    ]),
  ),
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  style: PropTypes.object,
};

Button.defaultProps = {
  a11yLabel: undefined,
  active: undefined,
  className: '',
  component: null,
  componentProps: {},
  disabled: false,
  download: '',
  inNewTab: undefined,
  location: '',
  modifiers: [],
  onBlur: () => {},
  onClick: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyPress: () => {},
  style: {},
};

export default Button;
