import React, { Component } from 'react';

import filesize from 'filesize';
import PropTypes from 'prop-types';

import {
  Caption,
  IconButton,
  ImageCanvasProcessor,
  Media,
  Paper,
  Progress,
  TextTruncate,
  Body,
} from '@trendkite/ui';

import FileThumbnail from './FileThumbnail';

const ClickWrapper = ({ clickToDownload, filename, children, url }) => {
  return clickToDownload ? (
    <a download={filename} href={url} className="attachment__download">
      {children}
    </a>
  ) : (
    children
  );
};

ClickWrapper.propTypes = {
  clickToDownload: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  filename: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

class Attachment extends Component {
  state = {
    blobUrl: '',
  };

  componentDidMount() {
    const { clickToDownload, filetype, getSignedUrl, id } = this.props;
    if (
      id &&
      clickToDownload &&
      ImageCanvasProcessor.supportsCanvasExport(filetype)
    ) {
      getSignedUrl(id);
    }
  }

  onBlobUrlCreated = blobUrl => this.setState({ blobUrl });

  render() {
    const {
      removable,
      filename,
      filetype,
      size,
      isLoading,
      loadingPercent,
      onRemove,
      signedUrl,
      clickToDownload,
      id,
    } = this.props;
    const { blobUrl } = this.state;
    return (
      <Paper padding="10px">
        <Media>
          <Media.Item>
            <div className="attachment__thumbnail">
              <FileThumbnail
                filename={filename}
                thumbnailUrl=""
                filetype={filetype}
              />
            </div>
          </Media.Item>
          <Media.Body
            style={{
              textAlign: 'left',
              marginLeft: '10px',
              alignContent: 'center',
            }}
          >
            <ClickWrapper
              clickToDownload={clickToDownload}
              url={blobUrl || `/api/outreach/file/${id}/download`}
              filename={filename}
            >
              <div className="attachment__filename">
                <TextTruncate block>
                  <Body>{filename}</Body>
                </TextTruncate>
                {signedUrl && (
                  <ImageCanvasProcessor
                    imageSrc={signedUrl}
                    key={signedUrl}
                    filetype={filetype}
                    onCreateBlobUrl={this.onBlobUrlCreated}
                  />
                )}
              </div>
              <div className="attachment__file-size">
                {' '}
                <Caption color="light">{filesize(size, { round: 1 })}</Caption>
              </div>
              {isLoading && (
                <React.Fragment>
                  <div className="attachment__loading-bar">
                    <Progress
                      modifiers={['small']}
                      value={loadingPercent / 100}
                    />
                  </div>
                  <div className="attachment__loading-text">
                    <Caption color="light">
                      uploading {loadingPercent}% complete
                    </Caption>
                  </div>
                </React.Fragment>
              )}
            </ClickWrapper>
          </Media.Body>
          {removable && (
            <Media.Item>
              <div className="attachment__remove-button">
                <IconButton
                  data-qa="uKBQ356DLzPNZDgEMY1eR"
                  icon="bin"
                  compact
                  onClick={onRemove}
                />
              </div>
            </Media.Item>
          )}
        </Media>
      </Paper>
    );
  }
}

Attachment.propTypes = {
  id: PropTypes.number,
  removable: PropTypes.bool,
  filename: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  filetype: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  loadingPercent: PropTypes.number,
  onRemove: PropTypes.func,
  clickToDownload: PropTypes.bool,
  getSignedUrl: PropTypes.func,
  signedUrl: PropTypes.string,
};

Attachment.defaultProps = {
  id: NaN,
  removable: false,
  isLoading: false,
  loadingPercent: 0,
  onRemove: () => {},
  clickToDownload: false,
  getSignedUrl: () => {},
  signedUrl: '',
};

export default Attachment;
