import React from 'react';

import { Button, Media } from '@cision/rover-ui';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SvgIcon } from '@trendkite/ui';

import MenuItemConditionalTooltip from '../../menu-item-conditional-tooltip';

import './menu-item.scss';

const baseClass = 'header-menu-sm-menu-item';

const defaultButtonProps = {
  level: 'primary',
  size: 'lg',
};

const MenuItemButton = ({
  className,
  iconLeft,
  iconRight,
  label,
  tooltip,
  ...passedProps
}) => (
  <Button
    {...defaultButtonProps}
    {...passedProps}
    className={classNames(className, `${baseClass}__button`)}
  >
    <Media>
      {iconLeft && (
        <Media.Item style={{ marginRight: '20px' }}>{iconLeft}</Media.Item>
      )}
      <Media.Body>
        <span>
          <MenuItemConditionalTooltip
            tooltip={tooltip}
            direction="bottom-right"
          >
            {label}
          </MenuItemConditionalTooltip>
        </span>
      </Media.Body>
      {iconRight && (
        <Media.Item style={{ marginLeft: '20px' }}>{iconRight}</Media.Item>
      )}
    </Media>
  </Button>
);

MenuItemButton.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  label: PropTypes.node,
  tooltip: PropTypes.node,
};

MenuItemButton.defaultProps = {
  className: '',
  iconLeft: null,
  iconRight: null,
  label: null,
  tooltip: undefined,
};

const MenuItem = ({
  className: customClassName,
  iconLeft,
  iconRight,
  id,
  inNewTab,
  isCurrentNav,
  isSubOptionHeader,
  label,
  location,
  onClick,
  tooltip,
  ...passedProps
}) => {
  const urlBase = window.appUrlBase || '';

  const className = classNames(
    customClassName,
    MenuItem.baseClass,
    `${MenuItem.baseClass}__item`,
    {
      [`${MenuItem.baseClass}__item--header`]: isSubOptionHeader,
    },
  );

  if (isSubOptionHeader) {
    return (
      <div className={className} key={id}>
        {label}
      </div>
    );
  }

  const shouldUseNewTab = location?.includes('http') || inNewTab;

  const href = shouldUseNewTab
    ? location || undefined
    : (location && `${urlBase}${location}`) || undefined;

  const bespokeButtonProps = shouldUseNewTab
    ? {
        iconRight: iconRight || <SvgIcon icon="new-tab" />,
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {};

  return (
    <MenuItemButton
      active={isCurrentNav.toString()}
      className={className}
      data-qa="qcMNCxhkHJ5fslmeX45RO"
      href={href}
      iconLeft={iconLeft}
      iconRight={iconRight}
      id={id}
      key={id}
      label={label}
      onClick={onClick}
      tooltip={tooltip}
      {...bespokeButtonProps}
      {...passedProps}
    />
  );
};

MenuItem.baseClass = baseClass;

MenuItem.propTypes = {
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  id: PropTypes.string.isRequired,
  inNewTab: PropTypes.bool,
  isCurrentNav: PropTypes.bool,
  isSubOptionHeader: PropTypes.bool,
  label: PropTypes.node.isRequired,
  location: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.node,
};

MenuItem.defaultProps = {
  children: null,
  className: '',
  iconLeft: null,
  iconRight: null,
  inNewTab: false,
  isCurrentNav: false,
  isSubOptionHeader: false,
  location: '',
  onClick: null,
  tooltip: undefined,
};

export default MenuItem;
