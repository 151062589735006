export default {
  campaigns: {
    id: 'WORKSPACES_APP_SRC_COMPONENTS_OMNI_BAR_ENTITIES_LIST_CAMPAIGNS',
    description: 'Campaigns section label',
    defaultMessage: 'Campaigns',
  },
  dashboards: {
    id: 'WORKSPACES_APP_SRC_COMPONENTS_OMNI_BAR_ENTITIES_LIST_DASHBOARDS',
    description: 'Dashboards section label',
    defaultMessage: 'Dashboards',
  },
  influencers: {
    id: 'WORKSPACES_APP_SRC_COMPONENTS_OMNI_BAR_ENTITIES_LIST_INFLUENCERS',
    description: 'Contacts section label',
    defaultMessage: 'Contacts',
  },
  influencerLists: {
    id: 'WORKSPACES_APP_SRC_COMPONENTS_OMNI_BAR_ENTITIES_LIST_INFLUENCER_LISTS',
    description: 'Contact lists section label',
    defaultMessage: 'Saved Contact Lists',
  },
  searches: {
    id: 'WORKSPACES_APP_SRC_COMPONENTS_OMNI_BAR_ENTITIES_LIST_SEARCHES', // The ID needs to be globally unique
    description: 'Searches section label',
    defaultMessage: 'Searches',
  },
};
