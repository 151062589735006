export default {
  title: {
    id: 'STORY-SPOKESPEOPLE-DISPLAY_TITLE',
    defaultMessage: 'Spokespeople For This Story',
    description:
      'Campaigns > Story Kit > Story Edit Page: content spokespeople ',
  },
  addSpokespeople: {
    id: 'STORY-SPOKESPEOPLE-DISPLAY_',
    defaultMessage: 'Add Spokespeople',
    description:
      'Campaigns > Story Kit > Story Edit Page: content spokespeople add link',
  },
  fullName: {
    id: 'STORY-SPOKESPEOPLE-DISPLAY_FULL_NAME',
    defaultMessage: 'Approved quotes from {FULL_NAME}',
    description:
      'Campaigns > Story Kit > Story Edit Page: content spokespeople approved quotes message',
  },
  followTwitterPerson: {
    id: 'STORY-SPOKESPEOPLE-DISPLAY_FOLLOW_TWITTER_PERSON',
    defaultMessage: 'Follow {TWITTER_PERSON} on Twitter',
    description:
      'Campaigns > Story Kit > Story Edit Page: content spokespeople follow on twitter link',
  },
};
