import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UniversalNavHeader from 'header/src/UniversalNavHeader';
import { firstNameSelector } from 'selectors/user-settings';
import {
  getCurrentUserAccountId,
  getCurrentUserImpersonating,
} from 'services/user-service/user-service';

const mapStateToProps = state => ({
  currentNav: state && state.currentNav ? state.currentNav.page : null,
  firstName: firstNameSelector(state),
});

class NewHeader extends Component {
  render() {
    const currentAccountId = getCurrentUserAccountId();
    const isAuthed = currentAccountId && currentAccountId !== -1;

    const impersonatingId = getCurrentUserImpersonating();
    const userIsImpersonating = !!impersonatingId;
    const cisionCompanyId =
      window.activeUser &&
      window.activeUser.account &&
      window.activeUser.account.cisionCompanyId;

    let username = '';
    if (window.activeUser) {
      username = window.activeUser.username;
    }

    return (
      <UniversalNavHeader
        currentNav={this.props.currentNav}
        isAuthed={isAuthed}
        userIsImpersonating={userIsImpersonating}
        username={username}
        firstName={this.props.firstName}
        logoutUrl="/logout"
        cisionCompanyId={cisionCompanyId}
        isUnfixed={this.props.isUnfixed}
      />
    );
  }
}

NewHeader.propTypes = {
  currentNav: PropTypes.string,
  firstName: PropTypes.string,
  isUnfixed: PropTypes.bool,
};

NewHeader.defaultProps = {
  currentNav: '',
  firstName: '',
  isUnfixed: false,
};

const ConnectedNewHeader = connect(mapStateToProps)(NewHeader);

const Header = props => <ConnectedNewHeader {...props} />;

Header.propTypes = { isUnfixed: PropTypes.bool };
Header.defaultProps = { isUnfixed: false };

export default Header;
