import { isValidEmail } from '@trendkite/ui';

const isRequiredContactObjectList = val => {
  return val && val.length > 0;
};

const isValidContactObjectList = val => {
  let valid = false;
  if (!val || val.length === 0) return true;
  val.forEach(obj => {
    valid = isValidEmail(obj.email);
  });
  return valid;
};

const isRequiredContactObject = val => {
  return val && val.email && val.email.length > 0;
};

const isRequiredSubjectObject = val => {
  return val && val.length > 0;
};

const isValidContactObject = val => {
  return val && val.email && val.email && isValidEmail(val.email);
};

const fileSize = attachments => {
  if (!attachments || attachments.length === 0) return true;

  return attachments.reduce((a, f) => a + f.size, 0) < 25000000;
};

const attachmentsLoaded = attachments => {
  if (!attachments || attachments.length === 0) return true;

  return !attachments.some(f => f.loading);
};

const attachmentsErrored = attachments => {
  if (!attachments || attachments.length === 0) return true;

  return !attachments.some(f => f.error);
};

export const validators = {
  to: {
    required: isRequiredContactObjectList,
    validEmail: isValidContactObjectList,
  },
  from: {
    required: isRequiredContactObject,
    validEmail: isValidContactObject,
  },
  cc: {
    validEmail: isValidContactObjectList,
  },
  bcc: {
    validEmail: isValidContactObjectList,
  },
  attachments: {
    fileSize,
    attachmentsLoaded,
    attachmentsErrored,
  },
  subject: {
    required: isRequiredSubjectObject,
  },
};

export const errorMessages = {
  to: {
    required: 'Required',
    validEmail: 'Invalid email address',
  },
  from: {
    required: 'Required',
    validEmail: 'Invalid email address',
  },
  cc: { validEmailAddress: 'Invalid email address' },
  bcc: { validEmailAddress: 'Invalid email address' },
  attachments: {
    filesSize:
      'Your message has exceeded the 25 MB limit. Please reduce your number of attachments.',
    attachmentsLoaded:
      'Some files have not completely uploaded yet. Please wait or remove the files.',
    attachmentsErrored:
      'Some files did not upload successfully. Please remove those files.',
  },
  subject: {
    required: 'Required',
  },
};

export default { validators, errorMessages };
