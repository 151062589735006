import moment from 'moment';

export const getDateString = ({
  intl,
  date,
  monthFormat,
  dayFormat,
  utcOffset,
}) => {
  if (!moment(date, 'x').isValid()) {
    return '';
  }
  let utcDate = date;
  //if the date is not in UTC we need to convert it to UTC so that intl shows the proper date
  if (utcOffset !== '+00:00' || utcOffset !== 0) {
    const yearMonthDay = moment
      .utc(date)
      .utcOffset(utcOffset)
      .format('YYYY-MM-DD');
    utcDate = moment.parseZone(yearMonthDay + 'T00:00:00+00:00').valueOf();
  }
  const dateOptions = {
    month: monthFormat,
    day: dayFormat,
    year: 'numeric',
    timeZone: 'UTC',
  };

  return intl.formatDate(moment.utc(utcDate), dateOptions);
};

export const getDateFormatFromIntl = intl =>
  moment.localeData(intl.locale).longDateFormat('L');

export const getDateFormatFromMoment = ({ startDate, endDate }) => {
  const startDateUpdated = moment.utc(startDate).startOf('day').valueOf();
  const endDateUpdated = moment.utc(endDate).endOf('day').valueOf();
  return {
    startDateUpdated,
    endDateUpdated,
  };
};
