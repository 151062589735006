import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { performPost } from 'services/rest-service/rest-service';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const desksReducerFactory = namespace => {
  const GET_DESKS_START = `ihub/${namespace}/GET_DESKS_START`;
  const GET_DESKS_SUCCESS = `ihub/${namespace}/GET_DESKS_SUCCESS`;
  const GET_DESKS_ERROR = `ihub/${namespace}/GET_DESKS_ERROR`;

  const getDesksForInfluencer = outletId => async dispatch => {
    dispatch({ type: GET_DESKS_START });
    let payload = [];
    const id = parseInt(`${outletId}`.replace(/[^\d]/g, ''), 10);

    try {
      const response = await performPost(`${INFLUENCER_HUB_ENDPOINT}/desks`, {
        id,
      });
      payload = (response && response.data && response.data.desks) || [];
    } catch (e) {
      dispatch({ type: GET_DESKS_ERROR, payload: e });
    }

    dispatch({ type: GET_DESKS_SUCCESS, payload });
  };

  const desksReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_DESKS_START:
        return {
          ...state,
          loading: true,
          error: false,
          data: [],
        };

      case GET_DESKS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: [...action.payload],
        };

      case GET_DESKS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  return {
    GET_DESKS_SUCCESS,
    GET_DESKS_START,
    GET_DESKS_ERROR,
    getDesksForInfluencer,
    desksReducer,
  };
};

export default desksReducerFactory;
