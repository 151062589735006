import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button, { IconButton } from '../button';
import Loader from '../loader';
import SvgIcon from '../svg-icon';
import { Body } from '../type';

const statusIconMap = {
  success: 'checkmark',
  danger: 'notice',
  warning: 'notice',
  info: 'info',
};

const baseClass = 'tk-kite-area';
const KiteArea = ({
  hideDismiss,
  status,
  isLoading,
  title,
  children,
  actionText,
  action,
  actionRightAlign,
  onCancel,
}) => {
  const cancelButton = onCancel ? (
    <div className={`${baseClass}__cancel`}>
      <IconButton
        data-qa="DCvENGzbxjd6toWw0Z9ys"
        icon="cross"
        onClick={onCancel}
        compact
        size="small"
      />
    </div>
  ) : null;

  const dismissButton = onCancel ? (
    <div className={`${baseClass}__action`}>
      <Button
        data-qa="3lVpm90PAsH_6f1wV7Cme"
        modifiers={['small', 'seethrough']}
        onClick={onCancel}
      >
        Dismiss
      </Button>
    </div>
  ) : null;

  const statusIcon = isLoading ? (
    <Loader size="small" />
  ) : (
    <SvgIcon
      icon={statusIconMap[status] || status}
      modifiers={['block']}
      color={status}
    />
  );

  const statusIconBaseClass = `${baseClass}__icon`;
  const statusIconClasses = classNames(statusIconBaseClass, {
    [`${statusIconBaseClass}--svg-icon`]: !isLoading,
  });

  const statusIconArea = <div className={statusIconClasses}>{statusIcon}</div>;

  const contentArea = <div className={`${baseClass}__content`}>{children}</div>;

  const titleArea = (
    <div className={`${baseClass}__title`}>
      <Body weight="bold">{title}</Body>
    </div>
  );

  const actionButton = (
    <Button
      data-qa="pXZ24OPTAjm6rrSJF0lSh"
      modifiers={['calltoaction', 'small']}
      onClick={action}
      active
    >
      {actionText}
    </Button>
  );

  const showContent =
    (title && contentArea) || (action && actionText && !actionRightAlign);
  return (
    <div className={baseClass} data-qa="KiteArea">
      <div className={`${baseClass}__header`}>
        {statusIconArea}
        {title && titleArea}
        {!title && contentArea}
        {actionRightAlign && actionButton}
        {!actionRightAlign && cancelButton}
      </div>
      {showContent && (
        <div className={`${baseClass}__main`}>
          {title && contentArea}
          {action && actionText && !actionRightAlign && (
            <div className={`${baseClass}__actions`}>
              <div className={`${baseClass}__action`}>{actionButton}</div>
              {!hideDismiss && dismissButton}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

KiteArea.propTypes = {
  hideDismiss: PropTypes.bool,
  /** status (matches semantic colors) */
  status: PropTypes.oneOf(['success', 'danger', 'warning', 'info']),
  /** show loading */
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  /** text/node to show */
  children: PropTypes.node,
  /** Text to show in button NOTE: button only shows if both action and actionText
   * provided
   */
  actionText: PropTypes.string,
  /** action func to call NOTE: button only shows if both action and actionText
   * provided
   */
  action: PropTypes.func,
  /** if true align action button to right of kite text, otherwise align below */
  actionRightAlign: PropTypes.bool,
  /** cancel func to call NOTE: cancel button only shows if this is provided */
  onCancel: PropTypes.func,
};

KiteArea.defaultProps = {
  hideDismiss: false,
  status: 'info',
  isLoading: false,
  title: '',
  actionText: '',
  children: null,
  action: null,
  actionRightAlign: false,
  onCancel: null,
};

export default KiteArea;
