import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const FormatBulletNumbered = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z" />
  </svg>
);
FormatBulletNumbered.propTypes = { fill: PropTypes.string };
FormatBulletNumbered.defaultProps = { fill: iconColorsMap.mainicon };

export default FormatBulletNumbered;
