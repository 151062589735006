import uniq from 'lodash/uniq';
import moment from 'moment';

import {
  ARTICLE_V3_BY_URL_ENDPOINT,
  SEARCH_DETAIL_ENDPOINT,
  SEARCH_LIST_ENDPOINT_V2,
  SOCIAL_SEARCH_ENDPOINT,
  SPIKE_ALERTS_ENDPOINT,
  SYSTEM_SEARCHES_PUB_STATUS_ENDPOINT,
} from 'constants/apis';
import {
  ADDED_ARTICLE_STATUS,
  DESC_SORT_DIRECTION,
  DEV_FEATURES,
  LAST_MODIFIED_SORT_OPTION,
  SAVED_SEARCHES_OPTIONS,
} from 'constants/constants';
import globalMessages from 'i18n/Global.messages';
import searchMessages from 'pages/Search/Searches.messages';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import {
  performDelete,
  performGet,
  performPost,
  performPut,
  csrfPerformPut,
  csrfPerformPost,
} from 'services/rest-service/rest-service';

import socialTrackerMessages from '../pages/EarnedSearch/Components/SocialTrackersModal/SocialTrackersModal.messages';

export const ADD_CREATED_SEARCH_TO_LIST = 'searches/ADD_CREATED_SEARCH_TO_LIST';
export const ADD_SEARCH_TO_CAMPAIGN_RECEIVED =
  'searches/ADD_SEARCH_TO_CAMPAIGN_RECEIVED';
export const DELETE_SEARCH_RECEIVED = 'searches/DELETE_SEARCH_RECEIVED';
export const DELETE_SEARCH_ERROR_RECEIVED =
  'searches/DELETE_SEARCH_ERROR_RECEIVED';
export const DELETE_SEARCH_ERROR_CLOSED = 'searches/DELETE_SEARCH_ERROR_CLOSED';
export const GET_SEARCH_COUNT_RECEIVED = 'searches/GET_SEARCH_COUNT_RECEIVED';
export const GET_SEARCH_DATA = 'searches/GET_SEARCH_DATA';
export const GET_SEARCH_DATA_RECEIVED = 'searches/GET_SEARCH_DATA_RECEIVED';
export const GET_SEARCH_DATA_ERROR = 'searches/GET_SEARCH_DATA_ERROR';
export const GET_SEARCH_LIST = 'searches/GET_SEARCH_LIST';
export const GET_SEARCH_LIST_RECEIVED = 'searches/GET_SEARCH_LIST_RECEIVED';
export const GET_SEARCH_LIST_ERROR = 'searches/GET_SEARCH_LIST_ERROR';
export const EMPTY_SEARCH_LIST = 'searches/EMPTY_SEARCH_LIST';
export const GET_SHARED_SEARCH_LIST = 'searches/GET_SHARED_SEARCH_LIST';
export const GET_SHARED_SEARCH_LIST_RECEIVED =
  'searches/GET_SHARED_SEARCH_LIST_RECEIVED';
export const GET_SHARED_SEARCH_LIST_ERROR =
  'searches/GET_SHARED_SEARCH_LIST_ERROR';
export const EMPTY_SHARED_SEARCH_LIST = 'searches/EMPTY_SHARED_SEARCH_LIST';
export const GET_SYSTEM_SEARCH_LIST = 'searches/GET_SYSTEM_SEARCH_LIST';
export const GET_SYSTEM_SEARCH_LIST_RECEIVED =
  'searches/GET_SYSTEM_SEARCH_LIST_RECEIVED';
export const GET_SYSTEM_SEARCH_LIST_COUNT_RECEIVED =
  'searches/GET_SYSTEM_SEARCH_LIST_COUNT_RECEIVED';
export const SET_SHARED_SEARCH_RECEIVED = 'searches/SET_SHARED_SEARCH_RECEIVED';
export const SET_SEARCH_COLOR_RECEIVED = 'searches/SET_SEARCH_COLOR_RECEIVED';
export const TOGGLE_SPIKE_ALERTS_RECEIVED =
  'searches/TOGGLE_SPIKE_ALERTS_RECEIVED';
export const UNSET_SEARCH_DATA = 'searches/RESET_SEARCH_DATA';
export const UPDATE_SEARCH_FACET_RECEIVED =
  'searches/UPDATE_SEARCH_FACET_RECEIVED';
export const GET_LATEST_SEARCH_DATA = 'searches/GET_LATEST_SEARCH_DATA';
export const GET_LATEST_SEARCH_DATA_RECEIVED =
  'searches/GET_LATEST_SEARCH_DATA_RECEIVED';
export const GET_LATEST_SEARCH_DATA_ERROR =
  'searches/GET_LATEST_SEARCH_DATA_ERROR';
export const GET_SYSTEMS_SEARCHES_PUB_STATUS_RECEIVED =
  'searches/GET_SYSTEMS_SEARCHES_PUB_STATUS_RECEIVED';
export const LOADING_SOCIAL_TRACKERS = 'searches/LOADING_SOCIAL_TRACKERS';
export const GETTING_SOCIAL_TRACKERS_ERROR =
  'searches/GETTING_SOCIAL_TRACKERS_ERROR';
export const GETTING_SOCIAL_TRACKERS_SUCCESS =
  'searches/GETTING_SOCIAL_TRACKERS_SUCCESS';
export const GET_SOCIAL_SEARCH_LIST_RECEIVED =
  'searches/GET_SOCIAL_SEARCH_LIST_RECEIVED';
export const SET_SEARCHES_LIST_SORT = 'searches/SET_SEARCH_LIST_SORT';
export const SET_SEARCHES_LIST_FILTER = 'searches/SET_SEARCH_LIST_FILTER';
export const SET_SEARCHES_LIST_CATEGORY_OR_CAMPAIGN_ID =
  'searches/SET_SEARCHES_LIST_CATEGORY_OR_CAMPAIGN_ID';
export const RESET_SEARCHES_LIST_FILTERS_AND_SORT =
  'searches/RESET_SEARCHES_LIST_FILTERS_AND_SORT';

export const TOGGLE_BULK_ADD_TO_SEARCH_MODAL =
  'searches/TOGGLE_BULK_ADD_TO_SEARCH_MODAL';
export const BULK_ADD_ARTICLE_TO_SEARCH_BY_URL =
  'searches/BULK_ADD_ARTICLE_TO_SEARCH_BY_URL';
export const BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_NEW_ARTICLE =
  'searches/BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_NEW_ARTICLE ';
export const BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_DUPLICATED_IN_SEARCH =
  'searches/BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_DUPLICATED_IN_SEARCH';
export const BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_ERROR =
  'searches/BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_ERROR';
export const BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_EXISTING_ARTICLE =
  'searches/BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_EXISTING_ARTICLE';
export const SET_ADDED_ARTICLES = 'searches/SET_ADDED_ARTICLES';
export const EMPTY_BULK_ADD_MODAL_STATE = 'searches/EMPTY_BULK_ADD_MODAL_STATE';
export const BULK_ADD_ARTICLE_REMOVE_ARTICLE =
  'searches/BULK_ADD_ARTICLE_REMOVE_ARTICLE';
export const BULK_ADD_ARTICLE_UPDATE_ARTICLE =
  'searches/BULK_ADD_ARTICLE_UPDATE_ARTICLE';

export const SET_IS_ADD_URL_SCREEN_HIDDEN =
  'searches/SET_IS_ADD_URL_SCREEN_HIDDEN';

export const DEFAULT_FILTERS_AND_SORT = {
  sort: {
    id: LAST_MODIFIED_SORT_OPTION,
    direction: DESC_SORT_DIRECTION,
  },
  filter: SAVED_SEARCHES_OPTIONS.allSearches,
  campaignOrCategoryId: '',
};

export const initialState = {
  current: null,
  error: false,
  bulkAddError: false,
  loading: true,
  loadingShared: true,
  loadingSystemSearches: true,
  searchCount: 0,
  searchCountLoading: true,
  searches: {},
  sharedSearches: {},
  systemSearches: {},
  systemSearchesCount: 0,
  socialSearches: {
    owned: [],
    shared: [],
  },
  systemSearchPubStatus: [],
  socialTrackers: {},
  userAddedArticles: [],
  isAddUrlScreenHidden: false,
  loadingSocialTrackers: false,
  gettingSocialTrackersError: false,
  gettingSocialTrackersSuccess: false,
  trashCanSearchErrorModalIsOpen: false,
  trashCanSearchErrorSearches: [],
  ...DEFAULT_FILTERS_AND_SORT,
};

const findAndSetArticleStatus = (status, priority, url, data) => article => {
  if (article.url === url) {
    return { ...article, status, priority, data };
  }
  return article;
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CREATED_SEARCH_TO_LIST:
      return {
        ...state,
        searches: {
          ...state.searches,
          ...action.payload,
        },
      };
    case ADD_SEARCH_TO_CAMPAIGN_RECEIVED: {
      const { searchId } = action.payload;
      const searches = { ...state.searches };
      const sharedSearches = { ...state.sharedSearches };
      if (state.searches[searchId]) {
        searches[searchId] = {
          ...searches[searchId],
          campaignTitles: uniq(
            searches[searchId].campaignTitles
              .slice()
              .concat(action.payload.campaignTitles),
          ),
        };
      } else {
        sharedSearches[searchId] = {
          ...sharedSearches[searchId],
          campaignTitles: uniq(
            sharedSearches[searchId].campaignTitles
              .slice()
              .concat(action.payload.campaignTitles),
          ),
        };
      }

      return {
        ...state,
        searches,
        sharedSearches,
      };
    }
    case DELETE_SEARCH_ERROR_RECEIVED: {
      return {
        ...state,
        trashCanSearchErrorSearches: action.payload.searches,
        trashCanSearchErrorModalIsOpen: true,
      };
    }
    case DELETE_SEARCH_ERROR_CLOSED: {
      return {
        ...state,
        trashCanSearchErrorSearches: [],
        trashCanSearchErrorModalIsOpen: false,
      };
    }
    case DELETE_SEARCH_RECEIVED: {
      const searches = { ...state.searches };
      delete searches[action.payload.searchId];
      return {
        ...state,
        searches,
      };
    }
    case GET_SEARCH_COUNT_RECEIVED: {
      return {
        ...state,
        searchCount: action.payload.count,
        searchCountLoading: false,
      };
    }
    case GET_SEARCH_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_DATA_RECEIVED:
      return {
        ...state,
        current: action.payload.id,
        error: false,
        loading: false,
        searches: {
          ...state.searches,
          [action.payload.id]: action.payload,
        },
      };
    case GET_SEARCH_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_LATEST_SEARCH_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_LATEST_SEARCH_DATA_RECEIVED:
      return {
        ...state,
        latest: action.payload.id,
        error: false,
        loading: false,
      };
    case GET_LATEST_SEARCH_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_SEARCH_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_LIST_RECEIVED:
      return {
        ...state,
        error: false,
        loading: false,
        searches: {
          ...state.searches,
          ...action.payload,
        },
      };
    case GET_SEARCH_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case EMPTY_SEARCH_LIST: {
      return {
        ...state,
        searches: {},
        current: null,
      };
    }
    case GET_SHARED_SEARCH_LIST:
      return {
        ...state,
        loadingShared: true,
      };
    case GET_SHARED_SEARCH_LIST_RECEIVED:
      return {
        ...state,
        loadingShared: false,
        sharedSearches: {
          ...state.sharedSearches,
          ...action.payload,
        },
      };
    case GET_SHARED_SEARCH_LIST_ERROR:
      return {
        ...state,
        loadingShared: false,
        error: true,
      };
    case EMPTY_SHARED_SEARCH_LIST: {
      return {
        ...state,
        sharedSearches: {},
        current: null,
      };
    }
    case GET_SYSTEM_SEARCH_LIST:
      return {
        ...state,
        loadingSystemSearches: true,
      };
    case GET_SYSTEM_SEARCH_LIST_RECEIVED:
      return {
        ...state,
        loadingSystemSearches: false,
        systemSearches: action.payload,
      };
    case GET_SYSTEM_SEARCH_LIST_COUNT_RECEIVED:
      return {
        ...state,
        systemSearchesCount: action.payload,
      };
    case SET_SHARED_SEARCH_RECEIVED:
      return {
        ...state,
        searches: {
          ...state.searches,
          [action.payload.searchId]: {
            ...state.searches[action.payload.searchId],
            shared: true,
            viewOnly: action.payload.viewOnly,
          },
        },
      };
    case SET_SEARCH_COLOR_RECEIVED:
      return {
        ...state,
        searches: {
          ...state.searches,
          [action.payload.searchId]: {
            ...state.searches[action.payload.searchId],
            colorTag: action.payload.colorTag,
          },
        },
      };
    case TOGGLE_SPIKE_ALERTS_RECEIVED: {
      const { searchId } = action.payload;
      const searches = { ...state.searches };
      const sharedSearches = { ...state.sharedSearches };
      if (state.searches[searchId]) {
        searches[searchId] = {
          ...searches[searchId],
          subscribed: !state.searches[searchId].subscribed,
        };
      } else {
        sharedSearches[searchId] = {
          ...sharedSearches[searchId],
          subscribed: !state.sharedSearches[searchId].subscribed,
        };
      }

      return {
        ...state,
        searches,
        sharedSearches,
      };
    }
    case UNSET_SEARCH_DATA:
      return {
        ...state,
        current: null,
      };
    case UPDATE_SEARCH_FACET_RECEIVED: {
      const { searchId, facet } = action.payload;
      const searches = { ...state.searches };
      const sharedSearches = { ...state.sharedSearches };
      if (state.searches[searchId]) {
        searches[searchId] = {
          ...searches[searchId],
          facet: {
            ...facet,
          },
        };
      } else {
        sharedSearches[searchId] = {
          ...sharedSearches[searchId],
          facet: {
            ...facet,
          },
        };
      }

      return {
        ...state,
        searches,
        sharedSearches,
      };
    }
    case GET_SYSTEMS_SEARCHES_PUB_STATUS_RECEIVED:
      return {
        ...state,
        systemSearchPubStatus: action.payload,
      };

    case LOADING_SOCIAL_TRACKERS:
      return {
        ...state,
        loadingSocialTrackers: true,
        gettingSocialTrackersError: false,
        gettingSocialTrackersSuccess: false,
      };

    case GETTING_SOCIAL_TRACKERS_ERROR:
      return {
        ...state,
        loadingSocialTrackers: false,
        gettingSocialTrackersError: true,
      };

    case GETTING_SOCIAL_TRACKERS_SUCCESS:
      return {
        ...state,
        socialTrackers: action.payload,
        loadingSocialTrackers: false,
        gettingSocialTrackersSuccess: true,
      };

    case GET_SOCIAL_SEARCH_LIST_RECEIVED:
      return {
        ...state,
        socialSearches: {
          ...state.socialSearches,
          ...action.payload,
        },
      };

    case SET_SEARCHES_LIST_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case SET_SEARCHES_LIST_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_SEARCHES_LIST_CATEGORY_OR_CAMPAIGN_ID:
      return {
        ...state,
        campaignOrCategoryId: action.payload,
      };

    case RESET_SEARCHES_LIST_FILTERS_AND_SORT:
      return {
        ...state,
        ...action.payload,
      };

    case TOGGLE_BULK_ADD_TO_SEARCH_MODAL:
      return {
        ...state,
        bulkAddModalOpen: !state.bulkAddModalOpen,
      };
    case BULK_ADD_ARTICLE_TO_SEARCH_BY_URL: {
      const { url, responseData } = action.payload;
      const userAddedArticles = state.userAddedArticles.map(
        findAndSetArticleStatus(
          ADDED_ARTICLE_STATUS.processing.id,
          ADDED_ARTICLE_STATUS.processing.priority,
          url,
          responseData,
        ),
      );
      return {
        ...state,
        userAddedArticles,
      };
    }
    case BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_ERROR: {
      const { bulkAddError, url } = action.payload;
      const userAddedArticles = state.userAddedArticles.map(
        findAndSetArticleStatus(
          ADDED_ARTICLE_STATUS.failed.id,
          ADDED_ARTICLE_STATUS.failed.priority,
          url,
        ),
      );
      return {
        ...state,
        bulkAddError,
        userAddedArticles,
      };
    }
    case BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_NEW_ARTICLE: {
      const { url, responseData } = action.payload;
      const userAddedArticles = state.userAddedArticles.map(
        findAndSetArticleStatus(
          ADDED_ARTICLE_STATUS.toBeAdded.id,
          ADDED_ARTICLE_STATUS.toBeAdded.priority,
          url,
          responseData,
        ),
      );
      return {
        ...state,
        userAddedArticles,
      };
    }

    case BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_DUPLICATED_IN_SEARCH: {
      const { url, responseData } = action.payload;
      const userAddedArticles = state.userAddedArticles.map(
        findAndSetArticleStatus(
          ADDED_ARTICLE_STATUS.duplicatedInSearch.id,
          ADDED_ARTICLE_STATUS.duplicatedInSearch.priority,
          url,
          responseData,
        ),
      );
      return {
        ...state,
        userAddedArticles,
      };
    }

    case BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_EXISTING_ARTICLE: {
      const { url, responseData } = action.payload;
      const userAddedArticles = state.userAddedArticles.map(
        findAndSetArticleStatus(
          ADDED_ARTICLE_STATUS.exists.id,
          ADDED_ARTICLE_STATUS.exists.priority,
          url,
          responseData,
        ),
      );
      return {
        ...state,
        userAddedArticles,
      };
    }

    case BULK_ADD_ARTICLE_UPDATE_ARTICLE: {
      const { url, data: newData } = action.payload;
      const articles = state.userAddedArticles.map(article => {
        if (article.url === url) {
          return {
            ...article,
            data: {
              ...article.data,
              ...newData,
            },
          };
        }
        return article;
      });
      return {
        ...state,
        userAddedArticles: articles,
      };
    }

    case BULK_ADD_ARTICLE_REMOVE_ARTICLE: {
      const articles = state.userAddedArticles.filter(
        article => article.url !== action.payload,
      );
      return {
        ...state,
        userAddedArticles: articles,
      };
    }

    case EMPTY_BULK_ADD_MODAL_STATE: {
      return {
        ...state,
        userAddedArticles: [],
        isAddUrlScreenHidden: false,
      };
    }

    case SET_ADDED_ARTICLES: {
      return { ...state, userAddedArticles: action.payload };
    }

    case SET_IS_ADD_URL_SCREEN_HIDDEN: {
      return { ...state, isAddUrlScreenHidden: action.payload };
    }

    default:
      return state;
  }
};

export const getSearchCount = () => dispatch => {
  const promises = [];
  promises.push(
    performGet(`${SEARCH_LIST_ENDPOINT_V2}`, {
      getResultsCount: true,
      ignoreKeyMessages: true,
    }),
  );
  if (!userHasDevFeatureFlag(DEV_FEATURES.newSearchApi)) {
    promises.push(
      performGet(`${SEARCH_LIST_ENDPOINT_V2}`, {
        getResultsCount: true,
        ignoreKeyMessages: true,
        shared: true,
      }),
    );
  }

  Promise.all(promises).then(results => {
    let count = 0;
    results.forEach(result => (count += result.data.total));

    dispatch({
      type: GET_SEARCH_COUNT_RECEIVED,
      payload: {
        count,
      },
    });
  });
};

export const getSearchBySearchId = searchId => dispatch => {
  if (searchId) {
    dispatch({ type: GET_SEARCH_DATA });

    performGet(`${SEARCH_DETAIL_ENDPOINT}/${searchId}`)
      .then(response => {
        const search = response.data;
        search.svgIcon = 'search';
        search.type = 'searches';
        dispatch({ type: GET_SEARCH_DATA_RECEIVED, payload: response.data });
      })
      .catch(err => {
        dispatch({ type: GET_SEARCH_DATA_ERROR });
        throw err;
      });
  }
};

export const getLatestSearch = () => dispatch => {
  dispatch({ type: GET_LATEST_SEARCH_DATA });
  performGet(`${SEARCH_LIST_ENDPOINT_V2}/metadata/latest`)
    .then(response => {
      dispatch({
        type: GET_LATEST_SEARCH_DATA_RECEIVED,
        payload: response.data,
      });
    })
    .catch(err => {
      dispatch({ type: GET_LATEST_SEARCH_DATA_ERROR });
      throw err;
    });
};

export const getOwnedAndSharedSearchesForCurrentUser = (
  currentUserId,
  campaignId,
  searchTerm,
  replace,
) => dispatch => {
  dispatch({ type: GET_SEARCH_LIST });

  const params = { ignoreKeyMessages: true, skipTranslate: true };

  if (campaignId) {
    params.includeCampaign = campaignId;
  }
  if (searchTerm) {
    params.searchTerm = searchTerm;
  }

  const get = performGet(`${SEARCH_LIST_ENDPOINT_V2}`, params);

  get
    .then(response => {
      const ownedSearchData = {};
      const sharedSearchData = {};
      response.data.forEach(search => {
        search.svgIcon = 'search';
        search.type = 'searches';

        if (search.userId !== currentUserId) {
          sharedSearchData[search.id] = search;
        } else {
          ownedSearchData[search.id] = search;
        }
      });

      if (replace) {
        dispatch({ type: EMPTY_SEARCH_LIST });
        dispatch({ type: EMPTY_SHARED_SEARCH_LIST });
      }

      dispatch({
        type: GET_SEARCH_LIST_RECEIVED,
        payload: ownedSearchData,
      });

      dispatch({
        type: GET_SHARED_SEARCH_LIST_RECEIVED,
        payload: sharedSearchData,
      });
    })
    .catch(err => {
      dispatch({ type: GET_SEARCH_LIST_ERROR });
      throw err;
    });
};

export const getOwnedAndSharedSocialSearchesForCurrentUser = userId => async dispatch => {
  try {
    const params = {
      page: 0,
      pageSize: 25,
    };
    const result = await performGet(SOCIAL_SEARCH_ENDPOINT, params);
    const socialSearchesMap = result.data?.searches?.reduce(
      (acc, search) => {
        if (userId === search.userId) {
          acc.owned = [...acc.owned, search];
        } else {
          acc.shared = [...acc.shared, search];
        }
        return acc;
      },
      {
        owned: [],
        shared: [],
      },
    );

    dispatch({
      type: GET_SOCIAL_SEARCH_LIST_RECEIVED,
      payload: socialSearchesMap,
    });
  } catch (e) {}
};

export const getSearchesForCurrentUser = (searchTerm, replace) => dispatch => {
  dispatch({ type: GET_SEARCH_LIST });

  const params = {
    ignoreKeyMessages: true,
    skipTranslate: true,
  };

  if (searchTerm) {
    params.searchTerm = searchTerm;
  }

  if (userHasDevFeatureFlag(DEV_FEATURES.newSearchApi)) {
    params.excludedShared = true;
  }

  performGet(`${SEARCH_LIST_ENDPOINT_V2}`, params)
    .then(response => {
      const data = {};
      response.data.forEach(search => {
        search.svgIcon = 'search';
        search.type = 'searches';
        data[search.id] = search;
      });

      if (replace) {
        dispatch({ type: EMPTY_SEARCH_LIST });
      }

      dispatch({
        type: GET_SEARCH_LIST_RECEIVED,
        payload: data,
      });
    })
    .catch(err => {
      dispatch({ type: GET_SEARCH_LIST_ERROR });
      throw err;
    });
};

export const addCreatedSearchToList = search => dispatch => {
  const newSearchData = {};
  newSearchData[search.id] = search;
  dispatch({
    type: ADD_CREATED_SEARCH_TO_LIST,
    payload: newSearchData,
  });
};

export const getSharedSearchesByUser = (searchTerm, replace) => dispatch => {
  dispatch({ type: GET_SHARED_SEARCH_LIST });

  const params = {
    ignoreKeyMessages: true,
    skipTranslate: true,
  };

  if (searchTerm) {
    params.searchTerm = searchTerm;
  }

  if (userHasDevFeatureFlag(DEV_FEATURES.newSearchApi)) {
    params.excludeOwned = true;
  } else {
    params.shared = true;
  }

  performGet(`${SEARCH_LIST_ENDPOINT_V2}`, params)
    .then(response => {
      const data = {};
      response.data.forEach(search => {
        search.svgIcon = 'search';
        search.type = 'searches';
        data[search.id] = search;
      });

      if (replace) {
        dispatch({ type: EMPTY_SHARED_SEARCH_LIST });
      }

      dispatch({
        type: GET_SHARED_SEARCH_LIST_RECEIVED,
        payload: data,
      });
    })
    .catch(err => {
      dispatch({ type: GET_SHARED_SEARCH_LIST_ERROR });
      throw err;
    });
};

export const unsetCurrentSearch = () => dispatch => {
  dispatch({ type: UNSET_SEARCH_DATA });
};

export const duplicateSearch = (search, intl) => dispatch => {
  csrfPerformPost(SEARCH_LIST_ENDPOINT_V2, {
    ...search,
    isCopy: true,
  })
    .then(() => {
      getSearchesForCurrentUser()(dispatch);

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(searchMessages.searchDuplicatedSuccess),
          status: 'success',
        }),
      );
    })
    .catch(() => {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(searchMessages.searchDuplicatedError),
          status: 'danger',
        }),
      );
    });
};

const getShareSearchMessages = (viewOnly, hasChangedPermissions, intl) => {
  const searchPermissionStatus = intl.formatMessage(
    viewOnly
      ? searchMessages.viewOnlyPermissionStatus
      : searchMessages.fullAccessPermissionStatus,
  );
  const successText = !hasChangedPermissions
    ? intl.formatMessage(searchMessages.shareSearchSuccess)
    : intl.formatMessage(searchMessages.updateSearchChangePermissionsSuccess, {
        searchPermission: searchPermissionStatus,
      });
  const dangerText = intl.formatMessage(
    !hasChangedPermissions
      ? searchMessages.shareSearchError
      : searchMessages.updateSearchChangePermissionsError,
  );

  return {
    successText,
    dangerText,
  };
};

const getEditSearchColorMessages = intl => {
  const successText = intl.formatMessage(searchMessages.colorUpdateSuccess);
  const dangerText = intl.formatMessage(searchMessages.colorUpdateError);

  return {
    successText,
    dangerText,
  };
};

export const shareSearch = (
  search,
  viewOnly,
  hasChangedPermissions,
  intl,
) => dispatch => {
  const { successText, dangerText } = getShareSearchMessages(
    viewOnly,
    hasChangedPermissions,
    intl,
  );
  performPut(`${SEARCH_DETAIL_ENDPOINT}/share/${search.id}`, {
    viewOnly,
  })
    .then(() => {
      dispatch({
        type: SET_SHARED_SEARCH_RECEIVED,
        payload: {
          searchId: search.id,
          viewOnly,
        },
      });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: successText,
          status: 'success',
        }),
      );
    })
    .catch(() => {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: dangerText,
          status: 'danger',
        }),
      );
    });
};

export const editSearchColor = (search, colorTag, intl) => dispatch => {
  const updatedSearch = {
    ...search,
    colorTag,
  };

  const { successText, dangerText } = getEditSearchColorMessages(intl);
  csrfPerformPut(`${SEARCH_LIST_ENDPOINT_V2}/${search.id}`, updatedSearch)
    .then(() => {
      dispatch({
        type: SET_SEARCH_COLOR_RECEIVED,
        payload: {
          searchId: search.id,
          colorTag,
        },
      });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: successText,
          status: 'success',
        }),
      );
    })
    .catch(() => {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: dangerText,
          status: 'danger',
        }),
      );
    });
};

export const toggleSpikeAlerts = (search, intl) => async dispatch => {
  try {
    if (search.subscribed) {
      const response = await performGet(SPIKE_ALERTS_ENDPOINT, {
        searchId: search.id,
      });
      await performDelete(`${SPIKE_ALERTS_ENDPOINT}/${response.data.id}`);
    } else {
      await performPost(SPIKE_ALERTS_ENDPOINT, { searchId: search.id });
    }

    dispatch({
      type: TOGGLE_SPIKE_ALERTS_RECEIVED,
      payload: {
        searchId: search.id,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(searchMessages.spikeAlertTurnedOnSuccess, {
          status: search.subscribed
            ? intl.formatMessage(globalMessages.off)
            : intl.formatMessage(globalMessages.on),
        }),
        status: 'success',
      }),
    );
  } catch (err) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(searchMessages.spikeAlertTurnedOnError, {
          status: search.subscribed
            ? intl.formatMessage(globalMessages.off)
            : intl.formatMessage(globalMessages.on),
        }),
        status: 'danger',
      }),
    );
  }
};

export const deleteSearch = (search, intl) => async dispatch => {
  try {
    await performDelete(`${SEARCH_DETAIL_ENDPOINT}/delete/${search.id}`);

    dispatch({
      type: DELETE_SEARCH_RECEIVED,
      payload: {
        searchId: search.id,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(searchMessages.searchDeleteSuccess),
        status: 'success',
      }),
    );
  } catch (err) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(searchMessages.searchDeleteError),
        status: 'danger',
      }),
    );
  }
};

export const addSearchToCampaign = (
  search,
  campaigns,
  intl,
) => async dispatch => {
  try {
    await performPost(
      `${SEARCH_DETAIL_ENDPOINT}/${search.id}/campaign`,
      campaigns,
    );

    dispatch({
      type: ADD_SEARCH_TO_CAMPAIGN_RECEIVED,
      payload: {
        searchId: search.id,
        campaignTitles: campaigns.map(c => c.title),
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          searchMessages.successfulSearchAddedToCampaign,
        ),
        status: 'success',
      }),
    );
  } catch (err) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          searchMessages.unsuccessfulSearchAddedToCampaign,
        ),
        status: 'danger',
      }),
    );
  }
};

export const getSystemSearches = (
  pageNum,
  pageSize,
  query,
  intl,
) => async dispatch => {
  dispatch({ type: GET_SYSTEM_SEARCH_LIST });
  try {
    const params = {
      pageNum,
      pageSize,
    };
    if (query) {
      params.isLike = query;
    }

    const results = await performGet(
      `${SEARCH_DETAIL_ENDPOINT}/systemSearchList`,
      params,
    );

    const data = {};
    results.data.forEach(search => {
      search.svgIcon = 'search';
      search.type = 'searches';
      search.campaignTitles = [];
      search.dashboardTitles = search.dashboards.map(d => d.title);
      data[search.id] = search;
    });

    dispatch({
      type: GET_SYSTEM_SEARCH_LIST_RECEIVED,
      payload: data,
    });
  } catch (err) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(searchMessages.errorGettingSystemSearches),
        status: 'danger',
      }),
    );
  }
};

export const getSystemSearchesCount = query => async dispatch => {
  try {
    const params = {};
    if (query) {
      params.isLike = query;
    }
    const results = await performGet(
      `${SEARCH_DETAIL_ENDPOINT}/systemSearchListCount`,
      params,
    );

    dispatch({
      type: GET_SYSTEM_SEARCH_LIST_COUNT_RECEIVED,
      payload: results.data.count,
    });
  } catch (err) {
    // ignore
  }
};

export const setSearchFacet = (search, facet, intl) => async dispatch => {
  try {
    const updatedSearch = {
      ...search,
      facet: {
        uuid: facet.uuid,
      },
    };

    await csrfPerformPut(
      `${SEARCH_LIST_ENDPOINT_V2}/${search.id}`,
      updatedSearch,
    );

    dispatch({
      type: UPDATE_SEARCH_FACET_RECEIVED,
      payload: {
        searchId: search.id,
        facet,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(searchMessages.successfulCategoryUpdate),
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(searchMessages.unsuccessfulCategoryUpdate),
        status: 'danger',
      }),
    );
  }
};

export const getSystemSearchesPubStatus = intl => async dispatch => {
  try {
    const results = await performGet(SYSTEM_SEARCHES_PUB_STATUS_ENDPOINT);

    dispatch({
      type: GET_SYSTEMS_SEARCHES_PUB_STATUS_RECEIVED,
      payload: results.data,
    });
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          searchMessages.errorGettingSystemSearchesPubStatus,
        ),
        status: 'danger',
      }),
    );
  }
};

export const getSocialTrackerslist = (searchId, intl) => async dispatch => {
  try {
    const results = await performGet(
      `${SEARCH_LIST_ENDPOINT_V2}/${searchId}/tks_tracker_list`,
      { channels: 'twitter', types: 'topic' },
    );

    const socialTrackersData = results.data.results.reduce(
      (acc, socialTracker) => {
        acc[socialTracker.id] = socialTracker;
        return acc;
      },
      {},
    );

    dispatch({
      type: GETTING_SOCIAL_TRACKERS_SUCCESS,
      payload: socialTrackersData,
    });
  } catch (e) {
    dispatch({ type: GETTING_SOCIAL_TRACKERS_ERROR });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(socialTrackerMessages.getSocialTrackersError),
        status: 'danger',
      }),
    );
  }
};

export const setSearchesListSort = sort => ({
  type: SET_SEARCHES_LIST_SORT,
  payload: sort,
});

export const setSearchesListFilter = filter => ({
  type: SET_SEARCHES_LIST_FILTER,
  payload: filter,
});

export const setSearchesListCampaignOrCategory = campaignOrCategoryId => ({
  type: SET_SEARCHES_LIST_CATEGORY_OR_CAMPAIGN_ID,
  payload: campaignOrCategoryId,
});

export const resetSearchesListFiltersAndSort = () => ({
  type: RESET_SEARCHES_LIST_FILTERS_AND_SORT,
  payload: { ...DEFAULT_FILTERS_AND_SORT },
});

export const setAddedArticles = articles => ({
  type: SET_ADDED_ARTICLES,
  payload: articles,
});

export const emptyBulkAddModalState = () => ({
  type: EMPTY_BULK_ADD_MODAL_STATE,
});

export const removeArticleFromList = url => ({
  type: BULK_ADD_ARTICLE_REMOVE_ARTICLE,
  payload: url,
});

export const updateArticle = (url, data) => ({
  type: BULK_ADD_ARTICLE_UPDATE_ARTICLE,
  payload: { url, data },
});

const getValidArticles = articles => {
  const articlesToBeLinked = [];
  const articlesToBeSaved = [];
  articles.forEach(articleMap => {
    if (articleMap.status === ADDED_ARTICLE_STATUS.exists.id) {
      articlesToBeLinked.push(articleMap.data);
    } else if (articleMap.status === ADDED_ARTICLE_STATUS.toBeAdded.id) {
      articlesToBeSaved.push(articleMap.data);
    }
  });
  return { articlesToBeLinked, articlesToBeSaved };
};

export const addArticlesToSearch = (
  articles,
  searchId,
  widgetId,
  messages,
) => async dispatch => {
  try {
    const { articlesToBeLinked, articlesToBeSaved } = getValidArticles(
      articles,
    );
    if (articlesToBeLinked.length === 0 && articlesToBeSaved.length === 0) {
      return;
    }
    const requestPromises = [];
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: messages.inProgress,
        isLoading: true,
      }),
    );
    const queryParams = {
      searchId,
      widgetId,
    };
    if (articlesToBeLinked.length > 0) {
      requestPromises.push(
        performPost(
          `${ARTICLE_V3_BY_URL_ENDPOINT}/bulkLinkArticlesToSearch`,
          { articles: articlesToBeLinked },
          queryParams,
        ),
      );
    }
    if (articlesToBeSaved.length > 0) {
      requestPromises.push(
        performPost(
          `${ARTICLE_V3_BY_URL_ENDPOINT}/bulkSave`,
          { articles: articlesToBeSaved },
          queryParams,
        ),
      );
    }

    const responses = await Promise.all(requestPromises);
    const showError = responses?.some(response =>
      response?.data?.some(result => result.type === 'error'),
    );
    const kiteContent = showError
      ? {
          text: messages.error,
          status: 'danger',
        }
      : {
          text: messages.success,
          status: 'success',
        };
    dispatch(
      addPageMessageWithDefaultTimeout({
        ...kiteContent,
      }),
    );
  } catch (err) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: messages.error,
        status: 'danger',
      }),
    );
  }
};

export const extractArticlesByUrl = (
  url,
  searchId,
  widgetId,
) => async dispatch => {
  dispatch({ type: BULK_ADD_ARTICLE_TO_SEARCH_BY_URL, payload: { url } });
  try {
    const fetchedArticle = await performGet(
      `${ARTICLE_V3_BY_URL_ENDPOINT}/get`,
      {
        url,
        searchId,
        widgetId,
      },
    );

    if (fetchedArticle.data.type === 'error') {
      dispatch({
        type: BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_ERROR,
        payload: { error: fetchedArticle.data, url },
      });
    } else if (!fetchedArticle.data.duplicateTrendKiteArticleId) {
      if (!fetchedArticle.data.published) {
        dispatch({
          type: BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_ERROR,
          payload: { bulkAddError: fetchedArticle.data, url },
        });
        return;
      }
      fetchedArticle.data.published = moment(
        fetchedArticle.data.published,
      ).valueOf();
      dispatch({
        type: BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_NEW_ARTICLE,
        payload: { responseData: fetchedArticle.data, url },
      });
    } else if (
      fetchedArticle.data.type.toLowerCase() === 'duplicate_in_search'
    ) {
      dispatch({
        type: BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_DUPLICATED_IN_SEARCH,
        payload: { responseData: fetchedArticle.data, url },
      });
    } else {
      dispatch({
        type: BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_EXISTING_ARTICLE,
        payload: { responseData: fetchedArticle.data, url },
      });
    }
  } catch (err) {
    dispatch({
      type: BULK_ADD_ARTICLE_TO_SEARCH_BY_URL_ERROR,
      payload: { error: err.message, url },
    });
  }
};

export default searchReducer;
