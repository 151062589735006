import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useIntl } from 'react-intl';

import { Input, FormGroup, FormLabel } from '../forms';
import { Body } from '../type';

import { getDateString, getDateFormatFromIntl } from './dateUtils';
import messages from './InputDate.messages';

interface InputDateType {
  id;
  date;
  onDateChange;
  error;
  isDisabled?;
  className?;
  label?;
  utcOffset?;
  errorCallBackFn;
  disableApplyFilterButton;
}

const BASE_CLASS = 'tk-input-datepicker';

const InputDate: React.FC<InputDateType> = ({
  id,
  date,
  onDateChange,
  label,
  error,
  isDisabled,
  className,
  utcOffset,
  errorCallBackFn,
  disableApplyFilterButton,
}) => {
  const intl = useIntl();
  const [formatError, setFormatError] = useState('');
  const [formattedDate, setFormattedDate] = useState('');

  const intlDateFormat = getDateFormatFromIntl(intl);

  useEffect(() => {
    const dateAsString = getDateString({
      intl,
      date,
      monthFormat: '2-digit',
      dayFormat: '2-digit',
      utcOffset,
    });
    setFormatError('');
    setFormattedDate(dateAsString);
  }, [date, utcOffset, intl]);

  const validateDate = ({ target: { value: dateString } }) => {
    const parsedDate = moment(dateString, intlDateFormat, true);
    setFormattedDate(dateString);
    const errorMsg = intl.formatMessage(messages.invalidDateMessage);
    if (!parsedDate.isValid()) {
      setFormatError(errorMsg);
      if (typeof errorCallBackFn === 'function') {
        errorCallBackFn(true);
      }
      disableApplyFilterButton(true);
      return;
    }
    setFormatError('');
    disableApplyFilterButton(false);
    if (typeof errorCallBackFn === 'function') {
      errorCallBackFn(false);
    }
    onDateChange(parsedDate);
  };

  return (
    <div className={`${BASE_CLASS} ${className}`}>
      <FormGroup modifiers={['position-relative', 'no-margin']}>
        {label && (
          <FormLabel
            htmlFor={id}
            modifiers={['within-input']}
            className="tk-input-date__input-label"
          >
            <Body color={isDisabled ? 'disabled' : 'light'}>{label}</Body>
          </FormLabel>
        )}
        <Input
          id={id}
          onChange={validateDate}
          value={formattedDate}
          paddingLeft={!label ? undefined : '50px'}
          error={!!(formatError || error)}
          disabled={isDisabled}
        />
        {(formatError || error) && (
          <Body block color="danger">
            <span data-qa="DatePicker-input-error">{formatError || error}</span>
          </Body>
        )}
      </FormGroup>
    </div>
  );
};

InputDate.defaultProps = {
  isDisabled: false,
  className: '',
  label: '',
  error: '',
  utcOffset: '+00:00',
  errorCallBackFn: null,
  disableApplyFilterButton: () => {},
};

export default InputDate;
