import EmailBodyDisplay from './email-body-display';
import EmailContentGroup from './email-content-group';
import EmailFooterGroup from './email-footer-group';

import EmailHeaderDisplay from './email-header-display';
import EmailHeaderGroup from './email-header-group';

export default {
  EmailContentGroup,
  EmailHeaderGroup,
  EmailFooterGroup,
  EmailBodyDisplay,
  EmailHeaderDisplay,
};
