import React from 'react';

import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import {
  Button,
  IconButton,
  PageHeader,
  SvgIcon,
  Tooltip,
} from '@trendkite/ui';

import { LEARN_MORE_MARKETING_URL } from 'trendkite-front-end-app/src/constants/constants';

import helpURL from './helpme';
import loadSalesforce from './loadCorrectSalesforce';
import UserDropdown from './UserDropdown';

import messages from './userDropDown.messages.js';

const SecondaryMenu = ({
  usSalesforceChatEnabled,
  ukSalesforceChatEnabled,
  endImpersonation,
  firstName,
  forwardedRef,
  isAuthed,
  loginNavEnabled,
  logoutUrl,
  userIsImpersonating,
  username,
  browseLinkEnabled,
}) => {
  let secondaryNav = null;
  let chatIcon = null;
  let helpIcon = null;

  const intl = useIntl();
  const helpTooltipLabel = intl.formatMessage(messages.helpTooltipLabel);
  helpIcon = (
    <div className="secondary-menu-item__help" data-qa="HelpIcon">
      <Tooltip key="helptt" content={helpTooltipLabel} position="bottom">
        <IconButton
          data-qa="30TdItcaB9DE4fFpQ9Qppp"
          icon="info-question"
          color="primaryTeal"
          id="context-help"
          onClick={() =>
            window.open(
              intl.formatMessage(messages.newKnowledgeGuideURL) + helpURL(),
            )
          }
          a11yLabel="Help"
        />
      </Tooltip>
    </div>
  );

  // Load the correct salesforce live agent here.  we have differing requirements based on the US/ or UK instances
  loadSalesforce(usSalesforceChatEnabled, ukSalesforceChatEnabled);
  const chatTooltipLabel = intl.formatMessage(messages.chatTooltipLabel);

  if (ukSalesforceChatEnabled) {
    chatIcon = (
      <div className="secondary-menu-item__chat" data-qa="UkSalesforceChatIcon">
        <Tooltip key="chattt" content={chatTooltipLabel} position="bottom">
          <IconButton
            data-qa="30TdItcaB9DE4fFpQ9QJd"
            icon="chat"
            color="primaryTeal"
            id="liveagent_button_online_5734J000000gIns"
            onClick={() => window.liveagent.startChat('5734J000000gIns')}
            a11yLabel="Chat"
          />
        </Tooltip>
      </div>
    );
  } else {
    chatIcon = usSalesforceChatEnabled && (
      <div className="secondary-menu-item__chat" data-qa="UsSalesforceChatIcon">
        <Tooltip key="chattt" content={chatTooltipLabel} position="bottom">
          <IconButton
            data-qa="mmtqJDWFIXBe2Ej_3tLRO"
            icon="chat"
            color="primaryTeal"
            id="liveagent_button_online_573d0000000GxI1"
            onClick={() => window.liveagent.startChat('573d0000000GxI1')}
            a11yLabel="Chat"
          />
          <IconButton
            data-qa="mmtqJDWFIXBe2Ej_3tLROnot"
            icon="chat"
            color="primaryTeal"
            id="email_support_off_hours"
            onClick={() =>
              window.open(
                'https://cision.atlassian.net/servicedesk/customer/portal/1019',
              )
            }
            a11yLabel="Chat"
            style={{ display: 'none' }}
          />
        </Tooltip>
      </div>
    );
  }

  if (!isAuthed && loginNavEnabled) {
    secondaryNav = (
      <div className="secondary-menu" ref={forwardedRef}>
        <div className="secondary-menu-item__marketing">
          <Button
            modifiers={['round', 'primaryTeal']}
            location={LEARN_MORE_MARKETING_URL}
          >
            Want to learn more about Cision?
          </Button>
        </div>
        <div className="secondary-menu-item__user">
          <Button
            modifiers={['seethrough-inverse-rebrand', 'inline-flex']}
            location={`/login/auth?page=${encodeURIComponent(window.location)}`}
          >
            <SvgIcon icon="user" modifiers={['right']} width={24} height={24} />
            Login
          </Button>
        </div>
      </div>
    );
  } else if (!isAuthed) {
    secondaryNav = '';
  } else if (userIsImpersonating) {
    secondaryNav = (
      <Button
        data-qa="PThrGXdFlfTBNtOh8GqiF"
        modifiers={['primaryTeal']}
        onClick={endImpersonation}
        tabIndex="-1"
      >
        You are using Cision as <strong>{username}</strong>. Click to switch
        back to self.
      </Button>
    );
  } else {
    secondaryNav = (
      <div
        className="secondary-menu secondary-menu--loggedIn"
        ref={forwardedRef}
      >
        {helpIcon}
        {chatIcon}
        <UserDropdown
          logoutUrl={logoutUrl}
          firstName={firstName}
          browseLinkEnabled={browseLinkEnabled}
        />
      </div>
    );
  }

  return <PageHeader.SecondaryMenu>{secondaryNav}</PageHeader.SecondaryMenu>;
};

SecondaryMenu.propTypes = {
  browseLinkEnabled: PropTypes.bool,
  usSalesforceChatEnabled: PropTypes.bool,
  ukSalesforceChatEnabled: PropTypes.bool,
  endImpersonation: PropTypes.func,
  firstName: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  isAuthed: PropTypes.bool,
  loginNavEnabled: PropTypes.bool.isRequired,
  logoutUrl: PropTypes.string,
  userIsImpersonating: PropTypes.bool,
  username: PropTypes.string,
};

SecondaryMenu.defaultProps = {
  browseLinkEnabled: false,
  usSalesforceChatEnabled: false,
  ukSalesforceChatEnabled: false,
  endImpersonation: () => {},
  firstName: '',
  isAuthed: false,
  logoutUrl: '',
  userIsImpersonating: false,
  username: '',
};

export default React.forwardRef((props, ref) => (
  <SecondaryMenu {...props} forwardedRef={ref} />
));
