import React, { useEffect } from 'react';

import { SideTray as RoverUISideTray } from '@cision/rover-ui';

import { SvgIcon } from '@trendkite/ui';

import styles from './SideTray.module.css';

type RoverUISideTrayProps = Parameters<typeof RoverUISideTray>[0] & {
  title?: string;
  direction?: string;
};

const SideTray: React.FC<RoverUISideTrayProps> & typeof RoverUISideTray = (
  props: RoverUISideTrayProps,
) => {
  const DEFAULT_WIDTH = 400;
  const {
    children,
    visible,
    title = '',
    onClose,
    direction,
    width = DEFAULT_WIDTH,
  } = props;

  // Move ZenDesk chat button/window out of the way when we open a SideTray
  useEffect(() => {
    const $b = window.$zopim?.livechat?.button; // ZenDesk chat button/window

    if (!$b) return;

    let parsedWidth: string | number; // RoverUI handles strings and numbers for width
    const initialOffsetHorizontal = 0;

    if (typeof width === 'string') {
      parsedWidth = parseInt(width);
    } else {
      parsedWidth = width;
    }

    if (visible) {
      // Moving ZenDesk chat button/window to the left
      $b.setOffsetHorizontal(parsedWidth);
    }

    return () => {
      // Returning ZenDesk chat button/window to its starting position
      $b.setOffsetHorizontal(initialOffsetHorizontal);
    };
  }, [visible, width]);

  if (title) {
    return (
      <RoverUISideTray direction={direction} {...props}>
        <RoverUISideTray.Header>
          <div className={styles.header}>
            <h2 className={styles.title}>{title}</h2>
            <button
              data-qa="XjdkqVQ_l9mwihEV_I5Nb"
              type="button"
              className={styles.closeButton}
              aria-label="Close"
              onClick={e => onClose(e)}
            >
              <SvgIcon width={16} height={16} icon="cross" />
            </button>
          </div>
        </RoverUISideTray.Header>
        {children}
      </RoverUISideTray>
    );
  }

  return <RoverUISideTray {...props} />;
};

SideTray.propTypes = RoverUISideTray.propTypes;

SideTray.Header = RoverUISideTray.Header;
SideTray.Body = RoverUISideTray.Body;
SideTray.Footer = RoverUISideTray.Footer;

export default SideTray;
