import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import './checkbox.scss';
import LargeCheckbox from './LargeCheckbox';
import SmallCheckbox from './SmallCheckbox';

const Checkbox = props => {
  const { size } = props;
  const CheckBoxComponent = useMemo(() => {
    return size !== 'small' ? LargeCheckbox : SmallCheckbox;
  }, [size]);
  return <CheckBoxComponent {...props} />;
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['light'])),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  dataQA: PropTypes.string,
};

Checkbox.defaultProps = {
  name: 'checkbox',
  selected: false,
  indeterminate: false,
  children: null,
  disabled: false,
  modifiers: [],
  onClick: () => {},
  size: 'small',
  dataQA: null,
};

export default Checkbox;
