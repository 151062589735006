import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { createSelector } from 'reselect';

import {
  DashboardsResponse,
  DashboardResponse,
} from 'pages/Dashboard/dashboard-interface';

interface RootState {
  dashboard: number;
}

// This won't nail down types anywhere else until all Dashboard files are typescriptified.
//
const MAX_NUMBER_DASHBOARDS_DISPLAYED = 3;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const dashboardObj = (state: RootState) => state.dashboard || {};

export const listAnalyticSelector = createSelector(
  dashboardObj,
  (dashboard: DashboardsResponse): DashboardResponse =>
    (dashboard && dashboard.all) || {},
);

export const ownedDashboardSelector = createSelector(
  dashboardObj,
  (dashboardsResponse: DashboardsResponse): DashboardResponse =>
    (dashboardsResponse && dashboardsResponse.owned) || {},
);

export const sharedDashboardSelector = createSelector(
  dashboardObj,
  (dashboardsResponse: DashboardsResponse): DashboardResponse =>
    (dashboardsResponse && dashboardsResponse.shared) || {},
);

export const allDashboardsSharedSelector = createSelector(
  dashboardObj,
  (dashboardsResponse: DashboardsResponse): DashboardResponse =>
    (dashboardsResponse.all?.items || []).reduce(
      (sharedDashboards, dashboard) => {
        sharedDashboards[dashboard.id] = dashboard.shared;
        return sharedDashboards;
      },
      {},
    ),
);

export const starredDashboardSelector = createSelector(
  dashboardObj,
  (dashboardsResponse: DashboardsResponse): DashboardResponse =>
    (dashboardsResponse && dashboardsResponse.starred) || {},
);

export const legacyDashboardSelector = createSelector(
  dashboardObj,
  (dashboardsResponse: DashboardsResponse): DashboardResponse =>
    (dashboardsResponse && dashboardsResponse.legacy) || {},
);

export const listAnalyticForFeed = createSelector(
  dashboardObj,
  (dashboardsResponse: DashboardsResponse): DashboardResponse =>
    (dashboardsResponse && dashboardsResponse.allForFeed) || {},
);

export const allAnalyticsLoadingSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): boolean => dashboardResponse.loading,
);

export const ownedAnalyticsLoadingSelector = createSelector(
  ownedDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean => dashboardResponse.loading,
);

export const sharedAnalyticsLoadingSelector = createSelector(
  sharedDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean => dashboardResponse.loading,
);

export const starredAnalyticsLoadingSelector = createSelector(
  starredDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean => dashboardResponse.loading,
);

export const legacyAnalyticsLoadingSelector = createSelector(
  legacyDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean => dashboardResponse.loading,
);

export const feedAnalyticLoadingSelector = createSelector(
  listAnalyticForFeed,
  (dashboardResponse: DashboardResponse): boolean => dashboardResponse.loading,
);

export const allAnalyticsFilteringSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.listFilters?.filtering,
);

export const ownedAnalyticsFilteringSelector = createSelector(
  ownedDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.listFilters?.filtering,
);

export const sharedAnalyticsFilteringSelector = createSelector(
  sharedDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.listFilters?.filtering,
);

export const starredAnalyticsFilteringSelector = createSelector(
  starredDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.listFilters?.filtering,
);

export const legacyAnalyticsFilteringSelector = createSelector(
  legacyDashboardSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.listFilters?.filtering,
);

export const allAnalyticsSearchSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): string =>
    dashboardResponse.listFilters?.search,
);

export const ownedAnalyticsSearchSelector = createSelector(
  ownedDashboardSelector,
  (dashboardResponse: DashboardResponse): string =>
    dashboardResponse.listFilters?.search,
);

export const sharedAnalyticsSearchSelector = createSelector(
  sharedDashboardSelector,
  (dashboardResponse: DashboardResponse): string =>
    dashboardResponse.listFilters?.search,
);

export const starredAnalyticsSearchSelector = createSelector(
  starredDashboardSelector,
  (dashboardResponse: DashboardResponse): string =>
    dashboardResponse.listFilters?.search,
);

export const legacyAnalyticsSearchSelector = createSelector(
  legacyDashboardSelector,
  (dashboardResponse: DashboardResponse): string =>
    dashboardResponse.listFilters?.search,
);

export const allDashboardSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.items || [],
);

export const ownedAnalyticsSelector = createSelector(
  ownedDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.items || [],
);

export const sharedAnalyticsSelector = createSelector(
  sharedDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.items || [],
);

export const starredAnalyticsSelector = createSelector(
  starredDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.items || [],
);

export const legacyAnalyticsSelector = createSelector(
  legacyDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.items || [],
);

export const getAnalyticUpdatingSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.updatingAnalytic,
);

export const getAnalyticDeletingSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.deletingAnalytic,
);

export const allAnalyticsForOwnerSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.ownerFilterAnalytics || [],
);

export const ownedAnalyticsForOwnerSelector = createSelector(
  ownedDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.ownerFilterAnalytics || [],
);

export const sharedAnalyticsForOwnerSelector = createSelector(
  sharedDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.ownerFilterAnalytics || [],
);

export const starredAnalyticsForOwnerSelector = createSelector(
  starredDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.ownerFilterAnalytics || [],
);

export const legacyAnalyticsForOwnerSelector = createSelector(
  legacyDashboardSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.ownerFilterAnalytics || [],
);

export const dashboardSystemTemplatesSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): any[] =>
    dashboardResponse.systemTemplates || [],
);

export const dashboardSystemTemplatesLoadingSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): boolean =>
    dashboardResponse.isLoadingTemplateMetrics,
);

export const getDashboardsByIndexSelector = dashboardIndex =>
  createSelector(
    listAnalyticSelector,
    (dashboardResponse: DashboardResponse): any[] =>
      dashboardResponse.items?.length > MAX_NUMBER_DASHBOARDS_DISPLAYED
        ? dashboardResponse.items?.slice(dashboardIndex).map(item => item.id)
        : [],
  );

export const allAnalyticsResultsCollationSelector = createSelector(
  listAnalyticSelector,
  (dashboardResponse: DashboardResponse): Record<string, any> =>
    dashboardResponse.resultsCollation || {},
);

export const ownedAnalyticsResultsCollationSelector = createSelector(
  ownedDashboardSelector,
  (dashboardResponse: DashboardResponse): Record<string, any> =>
    dashboardResponse.resultsCollation || {},
);

export const sharedAnalyticsResultsCollationSelector = createSelector(
  sharedDashboardSelector,
  (dashboardResponse: DashboardResponse): Record<string, any> =>
    dashboardResponse.resultsCollation || {},
);

export const starredAnalyticsResultsCollationSelector = createSelector(
  starredDashboardSelector,
  (dashboardResponse: DashboardResponse): Record<string, any> =>
    dashboardResponse.resultsCollation || {},
);

export const legacyAnalyticsResultsCollationSelector = createSelector(
  legacyDashboardSelector,
  (dashboardResponse: DashboardResponse): Record<string, any> =>
    dashboardResponse.resultsCollation || {},
);
