import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import { createSelector } from 'reselect';

const LIKES_LABEL = 'likes';

const secondaryMetricSum = metricLabel => (acc, { secondaryMetrics }) => {
  const st = secondaryMetrics
    .filter(({ label }) => label === metricLabel)
    .reduce((secAcc, { value }) => value + secAcc, 0);

  return st + acc;
};

export const influencerProfileSelector = state => state.influencerProfile || {};

export const influencerProfileLoadingSelector = createSelector(
  influencerProfileSelector,
  influencer => influencer.loading,
);

export const influencerProfileInfoSelector = createSelector(
  influencerProfileSelector,
  influencer => influencer.info || {},
);

export const influencerProfileSocialSelector = createSelector(
  influencerProfileInfoSelector,
  info => info.socialProfiles || [],
);

export const influencerProfileSocialLikesSelector = createSelector(
  influencerProfileSocialSelector,
  profiles => profiles.reduce(secondaryMetricSum(LIKES_LABEL), 0),
);

export const influencerProfileSocialEngagementScoreSelector = createSelector(
  influencerProfileInfoSelector,
  influencer => {
    if (influencer.engagementScore) {
      return influencer.engagementScore;
    }

    const arr = [influencer.engagementTier, influencer.resonanceTier].filter(
      isNumber,
    );
    if (isEmpty(arr)) {
      return null;
    }
    return round(arr.reduce((a, b) => a + b, 0) / arr.length, 1);
  },
);

export const influencerProfileArticlesSelector = createSelector(
  influencerProfileSelector,
  influencer => influencer.articles || [],
);

export const influencerProfileRecentArticlesSelector = createSelector(
  influencerProfileSelector,
  influencer => influencer.recentArticles || [],
);

export const influencerPitchingProfileSelector = createSelector(
  influencerProfileInfoSelector,
  influencerInfo => influencerInfo.pitchingProfile,
);

export const influencerTypesSelector = createSelector(
  influencerProfileInfoSelector,
  influencerInfo => influencerInfo.influencerTypes || [],
);

export const influencerIsOutletSelector = createSelector(
  influencerTypesSelector,
  influencerTypes => influencerTypes.indexOf('Outlet') >= 0,
);

export const influencerSocialProfilesSelector = createSelector(
  influencerProfileInfoSelector,
  influencerInfo => influencerInfo.socialProfiles || [],
);
