export default {
  featureName: {
    id: 'ADMIN_ANALYSIS_FEATURE_NAME',
    defaultMessage: 'Feature Name',
    description: 'Admin > Analysis > Table: Admin Feature List',
  },
  accounts: {
    id: 'ADMIN_ANALYSIS_FEATURE_ACCOUNTS',
    defaultMessage: 'Accounts',
    description: 'Admin > Analysis > Table: Admin Feature List',
  },
  filterPlaceholder: {
    id: 'ADMIN_ANALYSIS_FEATURE_FILTER_PLACEHOLDER',
    defaultMessage: 'Filter by Feature Name',
    description: 'Admin > Analysis > Filter: Placeholder',
  },
  devFilterPlaceholder: {
    id: 'ADMIN_ANALYSIS_DEV_FEATURE_FILTER_PLACEHOLDER',
    defaultMessage: 'Filter by Dev Feature Name',
    description: 'Admin > Analysis > Filter: Placeholder',
  },
  noFeatureFoundMsg: {
    id: 'ADMIN_ANALYSIS_FEATURE_NOT_FOUND',
    defaultMessage: 'Feature Not Found ',
    description: 'Admin > Analysis > Table: Feature Not Found',
  },
  getAccountsErrorMsg: {
    id: 'ADMIN_ANALYSIS_GET_ACCOUNTS_ERROR_MESSAGE',
    defaultMessage: 'Could not get accounts',
    description: 'Admin > Analysis > Table: Get accounts error message',
  },
};
