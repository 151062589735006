import { IntlShape } from 'react-intl';

import messages from './translate-service.messages';

const EMPTY_CODE = null;

export default class TranslationService {
  // Our user-facing default language is English/United States
  DEFAULT_USER_LANG = 'en';
  DEFAULT_USER_REGION = 'us';

  getLanguageTag({ languageCode, regionCode }) {
    if (languageCode && regionCode) {
      return `${languageCode}-${regionCode}`;
    }
    return languageCode;
  }

  getDefaultLanguageTag() {
    return this.getLanguageTag({
      languageCode: this.DEFAULT_USER_LANG,
      regionCode: this.DEFAULT_USER_REGION,
    });
  }

  isDefaultUserLanguageRegion({
    languageCode = EMPTY_CODE,
    regionCode = EMPTY_CODE,
  }: {
    languageCode: string | null;
    regionCode: string | null;
  }) {
    return (
      languageCode === this.DEFAULT_USER_LANG &&
      regionCode === this.DEFAULT_USER_REGION
    );
  }

  showUsSalesforceChatForLocale(intl: IntlShape) {
    return intl.formatMessage(messages.usSalesforceChat) === 'true';
  }

  showUkSalesforceChatForLocale(intl: IntlShape) {
    return intl.formatMessage(messages.ukSalesforceChat) === 'true';
  }
}
