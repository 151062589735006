import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Filter = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
  </svg>
);
Filter.propTypes = { fill: PropTypes.string };
Filter.defaultProps = { fill: iconColorsMap.mainicon };

export default Filter;
