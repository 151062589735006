import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import KiteArea from './KiteArea';

const containerNodeId = 'kitesContainer';
class Kite extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.shouldShow !== prevState.shouldShow) {
      return {
        shouldShow: nextProps.shouldShow,
        isOpen: nextProps.shouldShow,
      };
    }

    return null;
  }

  state = {
    isOpen: this.props.shouldShow,
    shouldShow: this.props.shouldShow,
  };

  componentDidUpdate() {
    if (!this.state.isOpen && this.node) {
      this.containerNode.removeChild(this.node);
      this.node = null;
    }

    if (!this.state.isOpen && this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = NaN;
    }

    if (this.props.ttl && this.state.isOpen && !this.timeout) {
      this.timeout = setTimeout(() => {
        this.setState({ isOpen: false });
        this.timeout = NaN;
      }, this.props.ttl);
    }
  }

  componentWillUnmount() {
    if (this.node) {
      this.containerNode.removeChild(this.node);
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onCancel = () => {
    this.setState({ isOpen: false });
    if (this.props.onCancel) this.props.onCancel();
  };

  timeout = NaN;

  containerNode =
    document.getElementById(containerNodeId) || this.createContainerNode();

  createContainerNode() {
    const containerNode = document.createElement('div');
    containerNode.className =
      'tk-kite' +
      `${this.props.classModifier ? ` ${this.props.classModifier}` : ''}`;
    containerNode.setAttribute('id', containerNodeId);
    document.body.appendChild(containerNode);
    return containerNode;
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    if (!this.node) {
      this.node = document.createElement('div');
      this.containerNode.insertBefore(this.node, this.containerNode.firstChild);
    }

    const kite = (
      <KiteArea
        {...this.props}
        onCancel={this.props.onCancel && this.onCancel}
      />
    );

    return createPortal(kite, this.node);
  }
}

Kite.propTypes = {
  ...KiteArea.propTypes,
  classModifier: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  shouldShow: PropTypes.bool,
  ttl: PropTypes.number,
};

Kite.defaultProps = {
  ...KiteArea.defaultProps,
  classModifier: '',
  shouldShow: true,
  ttl: NaN,
};

export default Kite;
