import React from 'react';

import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';

import { Tooltip } from '@trendkite/ui';

import { propTypes as outreachContactPropTypes } from 'types/outreach-contact';

const EmailRecipientsDisplay = props => {
  const { values } = props;
  /**
    Takes an array of recipients with names and emails,
    de-dupes by name, and produces a string with counts and
    popovers for names with multiple email addresses in the list.

    ([
      {email: 'a@example.com'},
      {email: 'b@example.com', name: 'bob'},
      {email: 'b2@example.com', name: 'bob'}
    ]) => 'a@example.com, <Tooltip>bob(2)</Tooltip>'
  */

  const collapsedEmailsMap = groupBy(values, value =>
    value.name ? `NAME-${value.name}` : `EMAIL-${value.email}`,
  );
  const uniqueValueKeys = Object.keys(collapsedEmailsMap);

  const dedupedChildren = uniqueValueKeys.map((key, i) => {
    const contactValues = collapsedEmailsMap[key];
    const delimiter = i < uniqueValueKeys.length - 1 ? ', ' : '';
    let contents;

    if (contactValues.length === 1) {
      contents = contactValues[0].name || contactValues[0].email;
    }

    if (contactValues.length > 1) {
      contents = (
        <span className={`${EmailRecipientsDisplay.baseClass}__value-tooltip`}>
          <Tooltip
            content={contactValues
              .map(contactValue => contactValue.email)
              .join(', ')}
          >
            {contactValues[0].name} ({contactValues.length})
          </Tooltip>
        </span>
      );
    }

    return (
      <span key={key}>
        {contents}
        {delimiter}
      </span>
    );
  });

  return (
    <div className={`${EmailRecipientsDisplay.baseClass}`}>
      {dedupedChildren}
    </div>
  );
};

EmailRecipientsDisplay.baseClass = 'email-recipients-display';

EmailRecipientsDisplay.propTypes = {
  values: PropTypes.arrayOf(outreachContactPropTypes),
};

EmailRecipientsDisplay.defaultProps = {
  values: [],
};

export default EmailRecipientsDisplay;
