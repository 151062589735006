import JsPdf from 'jspdf';

import { performGet } from 'services/rest-service/rest-service';

import { MIGRATION_ENDPOINT } from '../../components/admin/import-customer-v2/config';

export const CREATE_REPORT = 'adminMigrationManagementReducer/CREATE_REPORT';
export const CREATE_REPORT_SUCCESS =
  'adminMigrationManagementReducer/CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR =
  'adminMigrationManagementReducer/CREATE_REPORT_ERROR';

export const GET_MIGRATION = 'adminMigrationManagementReducer/GET_MIGRATION';
export const GET_MIGRATION_SUCCESS =
  'adminMigrationManagementReducer/GET_MIGRATION_SUCCESS';
export const GET_MIGRATION_ERROR =
  'adminMigrationManagementReducer/GET_MIGRATION_ERROR';

export const GET_PHASE = 'adminMigrationManagementReducer/GET_PHASE';
export const GET_PHASE_SUCCESS =
  'adminMigrationManagementReducer/GET_PHASE_SUCCESS';
export const GET_PHASE_ERROR =
  'adminMigrationManagementReducer/GET_PHASE_ERROR';

export const initialState = {
  migrations: [],
  loading: false,
  error: null,
};

const adminMigrationManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REPORT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CREATE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_MIGRATION:
      return {
        ...state,
        loading: true,
      };
    case GET_MIGRATION_SUCCESS:
      return {
        ...state,
        migrations: action.payload.migrations,
        loading: false,
        error: null,
      };
    case GET_MIGRATION_ERROR:
      return {
        ...state,
        migrations: [],
        loading: false,
        error: action.payload.error,
      };
    case GET_PHASE:
      return {
        ...state,
        loading: false,
      };
    case GET_PHASE_SUCCESS: {
      const fetchedMigration = action.payload.phase;
      const newMigrations = state.migrations.map(migration =>
        migration.id === fetchedMigration.id ? fetchedMigration : migration,
      );
      return {
        ...state,
        migrations: newMigrations,
        loading: false,
        error: null,
      };
    }
    case GET_PHASE_ERROR:
      return {
        ...state,
        phase: null,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getMigrations = params => dispatch => {
  dispatch({ type: GET_MIGRATION });

  return performGet(MIGRATION_ENDPOINT, params)
    .then(response => {
      if (response.status === 200 && response.data?.length > 0) {
        dispatch({
          type: GET_MIGRATION_SUCCESS,
          payload: { migrations: response.data },
        });
      } else {
        dispatch({
          type: GET_MIGRATION_ERROR,
          payload: { error: response.data },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_MIGRATION_ERROR, payload: { error: e.response } });
    });
};

export const getPhase = migrationId => dispatch => {
  dispatch({ type: GET_PHASE });
  performGet(`${MIGRATION_ENDPOINT}/${migrationId}`)
    .then(response => {
      if (response.status === 200) {
        const phase = response.data;
        dispatch({
          type: GET_PHASE_SUCCESS,
          payload: { phase },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_PHASE_ERROR, payload: { error: e.response } });
    });
};

function formatDate(dateString) {
  if (!dateString) {
    return 'Pending';
  }

  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

const generateHtmlReport = migrations => {
  return `
    <hmtl>
      <head>
        <style>
          tr {
            border-bottom: 1px solid #ddd;
          }
          tr:nth-child(even) {
            background-color: #D6EEEE;
          }
          th, td {
            padding: 5px;
            text-align: center;
          }
        </style>
      </head>
      <body>
        <center>
          <h1 style="padding-bottom: 15px">Migrations Report</h1>
          <table style="width: 100%">
            <thead>
              <tr>
                <th>Legacy System</th>
                <th>Legacy ID</th>
                <th>Legacy Name</th>
                <th>Cision ID</th>
                <th>Account Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Owner</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              ${migrations.map(
                migration => `
                <tr>
                  <td>${migration.legacySystem}</td>
                  <td>${migration.legacyAccountId}</td>
                  <td>${migration.legacyAccountName}</td>
                  <td>${migration.cisionAccountGcId}</td>
                  <td>${migration.cisionAccountName}</td>
                  <td>${formatDate(migration.creationDate)}</td>
                  <td>${formatDate(migration.finishDate)}</td>
                  <td>${migration.ownerAccountId}</td>
                  <td>${migration.status}</td>
                </tr>
              `,
              )}
            </tbody>
          </table>
        </center>
      </body>
    </hmtl>
  `;
};

export const createReport = migrations => dispatch => {
  const pdf = new JsPdf('l', 'px', 'a4');
  pdf.setFont('helvetica', 'normal');
  pdf.setTextColor('black');
  pdf.html(generateHtmlReport(migrations), {
    callback: function (pdf) {
      pdf.save('Migrations_Report.pdf');
      dispatch({ type: CREATE_REPORT_SUCCESS });
    },
    autoPaging: 'text',
    margin: [10, 10, 10, 10],
    windowWidth: window.innerWidth,
    width: 610,
  });
};

export const setLoading = () => dispatch => {
  dispatch({ type: CREATE_REPORT });
};

export default adminMigrationManagementReducer;
