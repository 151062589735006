// doing fully qualified import to support usage across workspaces
import { getDevFeatureLocalOverrides } from 'trendkite-front-end-app/src/components/DevFeatureLocalOverrides/service';

import { AuthorizationProtectors, CanUseFn } from './types';

export type AuthorizationProps = {
  features?: string[];
  devFeatures?: string[];
  roles?: string[];
};

type CanUseRuleImplementedProtectors = Pick<
  AuthorizationProtectors,
  | 'allDevFeatures'
  | 'someDevFeatures'
  | 'notDevFeatures'
  | 'allFeatures'
  | 'someFeatures'
  | 'notFeatures'
  | 'allRoles'
  | 'someRoles'
>;

export const createCanUseRule = (
  protectors: CanUseRuleImplementedProtectors,
): CanUseFn => () => {
  const {
    features = [],
    devFeatures: accountDevFeatures = [],
    roles = [],
  } = ((window.activeUser || {}) as unknown) as AuthorizationProps;

  let devFeatures = getDevFeatureLocalOverrides();
  if (Array.isArray(accountDevFeatures)) {
    devFeatures = devFeatures.concat(accountDevFeatures);
  }

  // TODO: add Roles, if we ever add CanUse rules that utilize Roles (until then... YAGNI)

  return (
    (protectors.allDevFeatures === undefined ||
      protectors.allDevFeatures.every(i => devFeatures?.includes(i))) &&
    (protectors.someDevFeatures === undefined ||
      protectors.someDevFeatures.length === 0 ||
      protectors.someDevFeatures.some(i => devFeatures?.includes(i))) &&
    (protectors.notDevFeatures === undefined ||
      !protectors.notDevFeatures.some(i => devFeatures?.includes(i))) &&
    (protectors.allFeatures === undefined ||
      protectors.allFeatures.every(i => features?.includes(i))) &&
    (protectors.someFeatures === undefined ||
      protectors.someFeatures.length === 0 ||
      protectors.someFeatures.some(i => features?.includes(i))) &&
    (protectors.notFeatures === undefined ||
      !protectors.notFeatures.some(i => features?.includes(i))) &&
    (protectors.allRoles === undefined ||
      protectors.allRoles.every(i => roles?.includes(i))) &&
    (protectors.someRoles === undefined ||
      protectors.someRoles.length === 0 ||
      protectors.someRoles.some(i => roles?.includes(i)))
  );
};
