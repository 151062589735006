import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import moment from 'moment';

import shortid from 'shortid';

import { THREAD_STATUSES } from 'components/outreach/constants';
import { buildPreviewThreadFromSentMessage } from 'components/outreach/utils';
import {
  OUTREACH_ENDPOINT,
  OUTREACH_THREAD_UPDATE,
  OUTREACH_PITCH_UPDATE,
} from 'constants/apis';
import { OUTREACH_INTEGRATION_ERROR_TYPES } from 'constants/outreach-integration';

import {
  emailCampaignsSelector,
  emailIdSelector,
} from 'pages/Email/Email-selector';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { accountSelector } from 'selectors/account';
import { campaignsForUserSelector } from 'selectors/campaign';
import {
  threadsByIdSelector,
  outreachMessagesByIdSelector,
} from 'selectors/outreach';
import {
  performPatch,
  performPost,
  performPut,
} from 'services/rest-service/rest-service';

import { defaultProps as outreachFilteredViewDefaultProps } from 'types/outreach-filtered-view';

import { hasMockOutreachDataSelector } from './mockData';
import { performPost as performMockPost } from './mockEndpoints';

export const GET_THREADS = 'outreach/GET_THREADS';
export const GET_THREADS_ERROR = 'outreach/GET_THREADS_ERROR';
export const GET_THREADS_SUCCESS = 'outreach/GET_THREADS_SUCCESS';
export const UPDATE_THREAD = 'outreach/UPDATE_THREAD';
export const UPDATE_THREAD_SUCCESS = 'outreach/UPDATE_THREAD_SUCCESS';
export const UPDATE_THREAD_ERROR = 'outreach/UPDATE_THREAD_ERROR';
export const PREVIEW_THREAD_FROM_SENT_MESSAGE =
  'outreach/PREVIEW_THREAD_FROM_SENT_MESSAGE';
export const SET_CURRENT_THREAD_ID = 'outreach/SET_CURRENT_THREAD_ID';
export const UNSET_CURRENT_THREAD_ID = 'outreach/UNSET_CURRENT_THREAD_ID';
export const ADD_MESSAGES_TO_THREAD = 'outreach/ADD_MESSAGES_TO_THREAD';
export const UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD =
  'outreach/UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD';
export const TRACK_IDS_IN_DELTA = 'outreach/TRACK_IDS_IN_DELTA';
export const UNTRACK_IDS_IN_DELTA = 'outreach/UNTRACK_IDS_IN_DELTA';
export const SET_ALL_RECIPIENTS_SELECTED =
  'outreach/SET_ALL_RECIPIENTS_SELECTED';
export const RESET_RECIPIENTS_SELECTED = 'outreach/RESET_RECIPIENTS_SELECTED';
export const UPDATE_PITCH_THREAD_LIST = 'outreach/UPDATE_PITCH_THREAD_LIST';
export const UPDATE_PITCH_THREAD_LIST_SUCCESSFUL =
  'outreach/UPDATE_PITCH_THREAD_LIST_SUCCESSFUL';
export const UPDATE_PITCH_THREAD_LIST_ERROR =
  'outreach/UPDATE_PITCH_THREAD_LIST_ERROR';

export const UPDATE_PITCH_MESSAGE = 'outreach/UPDATE_PITCH_MESSAGE';
export const UPDATE_PITCH_MESSAGE_SUCCESSFUL =
  'outreach/UPDATE_PITCH_MESSAGE_SUCCESSFUL';
export const UPDATE_PITCH_MESSAGE_ERROR = 'outreach/UPDATE_PITCH_MESSAGE_ERROR';

const initialState = {
  aggregations: {},
  threadsById: {},
  threadsError: null,
  threadsLoading: false,
  currentThreadId: null,
  currentThreadIds: [],
  currentPage: 1,
  pageSize: 25,
  threadsUpdating: {},
  selectedRecipientsIds: [],
  isSelectAllChecked: false,
  pitchThreadUpdating: false,
  pitchMessageUpdating: false,
  linkTrackingEnabled: true,
};

const outreachThreadReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case GET_THREADS: {
      return {
        ...state,
        threadsLoading:
          action.updateCurrentThreads === false ? state.threadsLoading : true,
        currentPage: action.payload.pageNumber,
        currentThreadIds:
          action.updateCurrentThreads === false ? state.currentThreadIds : [],
      };
    }
    case GET_THREADS_ERROR: {
      return {
        ...state,
        threadsError: action.payload.error,
        threadsLoading: false,
      };
    }
    case GET_THREADS_SUCCESS: {
      return {
        ...state,
        aggregations: action.payload.aggregations,
        threadsById: {
          ...state.threadsById,
          ...action.payload.threadsById,
        },
        currentThreadIds:
          action.updateCurrentThreads === false
            ? state.currentThreadIds
            : [...action.payload.threadIds],
        threadsError:
          action.updateCurrentThreads === false ? state.threadsError : null,
        threadsLoading:
          action.updateCurrentThreads === false ? state.threadsLoading : false,
        linkTrackingEnabled: action.payload.linkTrackingEnabled,
      };
    }
    case PREVIEW_THREAD_FROM_SENT_MESSAGE: {
      const previewThread = buildPreviewThreadFromSentMessage({
        ...action.payload.message,
        dateCreated: moment().toISOString(),
        dateDelivered: moment().valueOf(),
      });

      return {
        ...state,
        threadsById: {
          ...state.threadsById,
          [previewThread.id]: previewThread,
        },
        currentThreadIds: uniq([previewThread.id, ...state.currentThreadIds]),
      };
    }
    case SET_CURRENT_THREAD_ID:
      return {
        ...state,
        currentThreadId: action.payload,
      };
    case UNSET_CURRENT_THREAD_ID:
      return {
        ...state,
        currentThreadId: null,
      };
    case UPDATE_THREAD:
      return {
        ...state,
        threadsUpdating: {
          ...state.threadsUpdating,
          [action.payload.threadId]: true,
        },
      };
    case UPDATE_THREAD_ERROR:
      return {
        ...state,
        threadsUpdating: {
          ...state.threadsUpdating,
          [action.payload.threadId]: false,
        },
      };
    case UPDATE_THREAD_SUCCESS:
      return {
        ...state,
        threadsUpdating: {
          ...state.threadsUpdating,
          [action.payload.threadId]: false,
        },
        threadsById: {
          ...state.threadsById,
          [action.payload.threadId]: { ...action.payload.threadContent },
        },
      };
    case ADD_MESSAGES_TO_THREAD: {
      const selectedThreadId = action.payload.threadId;
      const threadToUpdate = { ...state.threadsById[selectedThreadId] };
      const messageIds = [
        ...threadToUpdate.messageIds,
        ...action.payload.messageIds,
      ];
      return {
        ...state,
        threadsById: {
          ...state.threadsById,
          [selectedThreadId]: {
            ...state.threadsById[selectedThreadId],
            messageIds,
          },
        },
      };
    }
    case UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD: {
      const { campaigns, threadId } = action.payload;
      return {
        ...state,
        threadsById: {
          ...state.threadsById,
          [threadId]: {
            ...state.threadsById[threadId],
            campaigns,
          },
        },
      };
    }
    case TRACK_IDS_IN_DELTA: {
      return {
        ...state,
        selectedRecipientsIds: uniq([
          ...state.selectedRecipientsIds,
          ...action.payload,
        ]),
      };
    }
    case UNTRACK_IDS_IN_DELTA: {
      return {
        ...state,
        selectedRecipientsIds:
          action.payload.length === 0
            ? []
            : state.selectedRecipientsIds.filter(
                sid => !isEqual(sid, ...action.payload),
              ),
      };
    }
    case SET_ALL_RECIPIENTS_SELECTED:
      return {
        ...state,
        isSelectAllChecked: action.payload,
      };
    case RESET_RECIPIENTS_SELECTED:
      return {
        ...state,
        selectedRecipientsIds: initialState.selectedRecipientsIds,
        isSelectAllChecked: initialState.isSelectAllChecked,
      };
    case UPDATE_PITCH_THREAD_LIST:
      return {
        ...state,
        pitchThreadUpdating: true,
      };
    case UPDATE_PITCH_THREAD_LIST_SUCCESSFUL:
      return {
        ...state,
        pitchThreadUpdating: false,
      };
    case UPDATE_PITCH_THREAD_LIST_ERROR:
      return {
        ...state,
        pitchThreadUpdating: false,
      };

    case UPDATE_PITCH_MESSAGE:
      return {
        ...state,
        pitchMessageUpdating: true,
      };
    case UPDATE_PITCH_MESSAGE_SUCCESSFUL:
      return {
        ...state,
        pitchMessageUpdating: false,
      };
    case UPDATE_PITCH_MESSAGE_ERROR:
      return {
        ...state,
        pitchMessageUpdating: false,
      };
    default:
      return state;
  }
};

export const updatePitchThread = ({
  threadId,
  readOrUnread,
}) => async dispatch => {
  dispatch({ type: UPDATE_PITCH_THREAD_LIST });

  try {
    performPatch(`${OUTREACH_THREAD_UPDATE}/${threadId}`, {
      unread: readOrUnread,
    });

    dispatch({ type: UPDATE_PITCH_THREAD_LIST_SUCCESSFUL });
  } catch (e) {
    dispatch({
      type: UPDATE_PITCH_THREAD_LIST_ERROR,
    });
  }
};

export const updatePitchMessage = ({
  messageId,
  readOrUnread,
}) => async dispatch => {
  dispatch({ type: UPDATE_PITCH_MESSAGE });

  try {
    performPatch(`${OUTREACH_PITCH_UPDATE}/${messageId}`, {
      unread: readOrUnread,
    });

    dispatch({ type: UPDATE_PITCH_MESSAGE_SUCCESSFUL });
  } catch (e) {
    dispatch({
      type: UPDATE_PITCH_MESSAGE_ERROR,
    });
  }
};

const getThreadsByIdFromQueryResponse = results =>
  results.reduce(
    (result, thread) => {
      if (thread.threadId && result.threadIds.indexOf(thread.threadId) < 0) {
        result.threadsById[thread.threadId] = thread;
        result.threadIds.push(thread.threadId);
      }
      return result;
    },
    { threadIds: [], threadsById: {} },
  );

export const resetRecipientsSelectedActionCreator = () => ({
  type: RESET_RECIPIENTS_SELECTED,
});

export const trackIdsInDeltaActionCreator = (
  ids,
  totalFilteredRecipients,
) => async (dispatch, getState) => {
  dispatch({
    type: TRACK_IDS_IN_DELTA,
    payload: ids,
  });

  const state = getState();
  const currentlyTrackedIds = state.outreachThreads.selectedRecipientsIds;
  const selectAllIsChecked = state.outreachThreads.isSelectAllChecked;

  if (
    selectAllIsChecked &&
    currentlyTrackedIds.length === totalFilteredRecipients
  ) {
    dispatch(resetRecipientsSelectedActionCreator());
  }
};

export const unTrackIdsInDeltaActionActionCreator = ids => ({
  type: UNTRACK_IDS_IN_DELTA,
  payload: ids,
});

export const setIsSelectAllCheckedActionCreator = isChecked => ({
  type: SET_ALL_RECIPIENTS_SELECTED,
  payload: isChecked,
});

export const setCurrentThreadActionCreator = threadId => ({
  type: SET_CURRENT_THREAD_ID,
  payload: threadId,
});

export const unsetCurrentThreadActionCreator = () => ({
  type: UNSET_CURRENT_THREAD_ID,
});

export const updateOutreachThreadActionCreator = (threadId, updates) => async (
  dispatch,
  getState,
) => {
  let response;
  let error;
  dispatch({ type: UPDATE_THREAD, payload: { threadId } });

  const state = getState();
  const originalThread = threadsByIdSelector(state)[threadId];
  if (!originalThread) return;

  const updatedThreadObject = {
    ...originalThread,
    ...updates,
  };

  try {
    response = await performPut(
      `${OUTREACH_ENDPOINT}/threads/${threadId}`,
      updatedThreadObject,
    );
  } catch (e) {
    dispatch({ type: UPDATE_THREAD_ERROR, payload: { threadId } });
    error = e;
  }

  if (!error) {
    dispatch({
      type: UPDATE_THREAD_SUCCESS,
      payload: {
        threadId,
        threadContent: response.data,
      },
    });
  }
};

const getMockOutreachThreads = async params => {
  const { data } = await performMockPost(
    `${OUTREACH_ENDPOINT}/threads/query`,
    params,
  );
  return data;
};

const getThreadishDataFromEmailAnnouncementDrafts = draftData => {
  const results = draftData.results.map(draft => {
    const id =
      draft.draftIds && draft.draftIds.length
        ? draft.draftIds.join('-')
        : shortid.generate();

    return {
      ...draft,
      messageIds: [id],
      threadId: id,
      status: THREAD_STATUSES.DRAFT,
    };
  });

  return {
    ...draftData,
    aggregations: draftData.aggregations || {},
    results,
  };
};

export const getOutreachThreadsWithQuery = async ({
  account,
  query,
  useMockData = false,
  getEmailAnnouncementDrafts = false,
  getEmailAnnouncementScheduled = false,
}) => {
  if (useMockData) {
    return getMockOutreachThreads(query);
  }

  /*
    TODO: Remove this condition if/when we merge post threads query and post bulk drafts query
    Just do the `else` part.
  */
  if (getEmailAnnouncementDrafts) {
    const { data } = await performPost(
      `${OUTREACH_ENDPOINT}/bulk/drafts/query`,
      {
        ...query,
        filters: {
          status: query.filters.status[0],
          accountId: account.id,
          campaignIds: query.filters.campaignIds,
        },
      },
    );

    // Make email draft data compatible with thread display.
    return getThreadishDataFromEmailAnnouncementDrafts(data);
  } else {
    const { data } = await performPost(
      `${OUTREACH_ENDPOINT}/threads/query`,
      query,
    );

    if (getEmailAnnouncementScheduled) {
      const results = data.results.map(draft => {
        return {
          ...draft,
          status: THREAD_STATUSES.SCHEDULED,
        };
      });
      return {
        ...data,
        results,
      };
    } else {
      return data;
    }
  }
};

export const getOutreachThreadsWithQueryActionCreator = (
  { query },
  {
    updateCurrentThreads,
    getEmailAnnouncementDrafts,
    getEmailAnnouncementScheduled,
  } = {},
) => async (dispatch, getState) => {
  const state = getState();
  const { pagination } = query;
  const account = accountSelector(state);
  const useMockData = hasMockOutreachDataSelector(state);

  dispatch({
    type: GET_THREADS,
    payload: {
      pageNumber: Math.floor(pagination.offset / pagination.limit) + 1,
      updateCurrentThreads,
    },
  });

  let response;

  try {
    response = await getOutreachThreadsWithQuery({
      account,
      query,
      useMockData,
      getEmailAnnouncementDrafts,
      getEmailAnnouncementScheduled,
    });
  } catch (e) {
    dispatch({
      type: GET_THREADS_ERROR,
      payload: {
        error: {
          type: OUTREACH_INTEGRATION_ERROR_TYPES.generic,
        },
      },
    });
    throw e;
  }

  const { aggregations, pagination: paginationResponse, results } = response;

  let threadIds = [];
  let threadsById = {};

  const linkTrackingEnabled =
    results.length > 0 && results[0].hasOwnProperty('linkTrackingEnabled')
      ? results[0].linkTrackingEnabled
      : true;

  /*
    TODO: Remove old filters.drafts and filters.scheduled references?
    afaik, JORTS_DRAFTS isn't on the roadmap any time soon, and this code
    causes confusion with email announcement drafts.
    - Chris Garcia, 5/1/2020
  */
  // only set threadIds and threadsById if not filtering for draft or scheduled only
  if (!query.filters.draft && !query.filters.scheduled) {
    const threads = getThreadsByIdFromQueryResponse(results);
    threadIds = threads.threadIds;
    threadsById = threads.threadsById;
  }

  dispatch({
    type: GET_THREADS_SUCCESS,
    payload: {
      threadsById,
      threadIds,
      updateCurrentThreads,
      aggregations,
      linkTrackingEnabled,
    },
  });

  return {
    aggregations,
    pagination: paginationResponse,
    threadIds,
    threadsById,
    linkTrackingEnabled,
  };
};

export const getOutreachThreadByIdWithQueryActionCreator = ({
  threadId,
}) => async dispatch => {
  const filteredView = { ...outreachFilteredViewDefaultProps };

  const query = {
    pagination: {
      limit: filteredView.pagination.limit,
      offset: filteredView.pagination.offset,
    },
    sort: filteredView.sort,
    search: filteredView.search,
    filters: {
      threadId,
    },
    view: 'results',
  };

  return dispatch(getOutreachThreadsWithQueryActionCreator({ query }));
};

export const addPreviewThreadFromMessageActionCreator = ({ message }) => ({
  type: PREVIEW_THREAD_FROM_SENT_MESSAGE,
  payload: { message },
});

const patchThreadsCampaigns = patchInstructions => {
  return performPatch(
    `${OUTREACH_ENDPOINT}/threads/campaigns`,
    patchInstructions,
  );
};

// fallback for /campaigns list API not providing "isMine" property
const getIsMine = (state, campaign) => {
  const currentUserId = state.account?.id;
  return campaign.userId === currentUserId;
};

const getUpdatedCampaigns = ({
  initialCampaigns,
  selectedCampaignIds,
  state,
}) => {
  const initialCampaignIds = initialCampaigns.map(c => c.id);

  const campaignsForUser = campaignsForUserSelector(state);
  const unownedOrDeletedCampaigns = initialCampaigns.filter(
    c => !(c.isMine || c.shared) || c.isDeleted,
  );
  const unownedOrDeletedCampaignIds = unownedOrDeletedCampaigns.map(c => c.id);

  const exclude = (array, element) => !array.includes(element);

  const campaignIdsToAdd = selectedCampaignIds.filter(i =>
    exclude(initialCampaignIds, i),
  );

  const campaignsIdsToRemove = initialCampaignIds
    .filter(i => exclude(selectedCampaignIds, i))
    .filter(i => exclude(unownedOrDeletedCampaignIds, i));

  const campaignsToAdd = campaignsForUser
    .filter(campaign => campaignIdsToAdd.includes(campaign.id))
    .map(campaign => ({
      ...campaign,
      isMine: getIsMine(state, campaign),
    }));

  const updatedCampaigns = initialCampaigns
    .concat(campaignsToAdd)
    .filter(c => exclude(campaignsIdsToRemove, c.id));

  return { campaignIdsToAdd, campaignsIdsToRemove, updatedCampaigns };
};

const updateCampaignAssignmentsActionCreator = ({
  threadId,
  initialCampaigns,
  selectedCampaignIds,
  errorMessage,
  successMessage,
}) => async (dispatch, getState) => {
  const state = getState();

  const {
    campaignIdsToAdd,
    campaignsIdsToRemove,
    updatedCampaigns,
  } = getUpdatedCampaigns({
    state,
    initialCampaigns,
    selectedCampaignIds,
  });

  const patchInstructions = [];
  if (campaignIdsToAdd.length > 0) {
    patchInstructions.push({
      op: 'add',
      value: {
        threadId,
        campaignIds: campaignIdsToAdd,
      },
    });
  }
  if (campaignsIdsToRemove.length > 0) {
    patchInstructions.push({
      op: 'remove',
      value: {
        threadId,
        campaignIds: campaignsIdsToRemove,
      },
    });
  }

  try {
    dispatch({
      type: UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD,
      payload: {
        threadId,
        campaigns: updatedCampaigns,
      },
    });
    await patchThreadsCampaigns(patchInstructions);
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: successMessage,
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: errorMessage,
        status: 'danger',
      }),
    );
    dispatch({
      type: UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD,
      payload: {
        threadId,
        campaigns: initialCampaigns,
      },
    });
  }
};

const getCampaignsForThreadId = (state, threadId) => {
  const threadsById = threadsByIdSelector(state);
  return threadsById[threadId].campaigns;
};

const getCampaignsForMessageId = (state, messageId) => {
  const messageById = outreachMessagesByIdSelector(state);
  return messageById[messageId].campaigns;
};

const getThreadId = (state, threadId) => {
  const threadsById = outreachMessagesByIdSelector(state);
  return threadsById[threadId].threadId;
};

export const updateCampaignAssignmentsForThreadActionCreator = ({
  threadId,
  selectedCampaignIds,
  errorMessage,
  successMessage,
  isTrash = false,
}) => async (dispatch, getState) => {
  const state = getState();
  const checkThreadId = isTrash ? getThreadId(state, threadId) : threadId;
  dispatch(
    updateCampaignAssignmentsActionCreator({
      threadId: checkThreadId,
      initialCampaigns: isTrash
        ? getCampaignsForMessageId(state, threadId)
        : getCampaignsForThreadId(state, threadId),
      selectedCampaignIds,
      errorMessage,
      successMessage,
    }),
  );
};

export const updateCampaignAssignmentsForDraftActionCreator = ({
  selectedCampaignIds,
  errorMessage,
}) => async (dispatch, getState) => {
  const state = getState();

  dispatch(
    updateCampaignAssignmentsActionCreator({
      threadId: emailIdSelector(state),
      initialCampaigns: emailCampaignsSelector(state),
      selectedCampaignIds,
      errorMessage,
    }),
  );
};

export const removeCampaignAssignmentActionCreator = ({
  threadId,
  campaignId,
  initialCampaigns,
  errorMessage,
}) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD,
      payload: {
        threadId,
        campaigns: initialCampaigns.filter(c => c.id !== campaignId),
      },
    });
    await patchThreadsCampaigns([
      { op: 'remove', value: { threadId, campaignIds: [campaignId] } },
    ]);
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: errorMessage,
        status: 'danger',
      }),
    );
    dispatch({
      type: UPDATE_CAMPAIGN_ASSIGNMENTS_FOR_THREAD,
      payload: {
        threadId,
        campaigns: initialCampaigns,
      },
    });
  }
};

export const removeCampaignAssignmentForThreadActionCreator = ({
  threadId,
  campaignId,
  errorMessage,
}) => async (dispatch, getState) => {
  const state = getState();

  dispatch(
    removeCampaignAssignmentActionCreator({
      threadId,
      campaignId,
      initialCampaigns: getCampaignsForThreadId(state, threadId),
      errorMessage,
    }),
  );
};

export default outreachThreadReducer;
