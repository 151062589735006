import { actions } from 'react-redux-form';

import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import {
  CAMPAIGN_WIZARD_STEPS,
  CAMPAIGN_WIZARD_STEPS_BY_ID,
  FEATURES,
} from 'constants/constants';
import messages from 'pages/Campaigns/Campaigns.messages';
import { toggleSidebar } from 'reducers/campaigns/campaign-sidebar';
import { addPageMessage } from 'reducers/page-messages';
import { hasFeatureFlagSelector } from 'selectors/account';
import { campaignWizardStepsSelector } from 'selectors/campaign-wizard';
import { performPost } from 'services/rest-service/rest-service';

export const CAMPAIGN_WIZARD_SET_NAME = 'campaign-wizard/SET_NAME';
export const CAMPAIGN_WIZARD_SET_CURRENT_STEP =
  'campaign-wizard/SET_CURRENT_STEP';
export const CAMPAIGN_WIZARD_NEXT_STEP = 'campaign-wizard/NEXT_STEP';
export const CAMPAIGN_WIZARD_PREVIOUS_STEP = 'campaign-wizard/PREVIOUS_STEP';
export const CAMPAIGN_WIZARD_RESET = 'campaign-wizard/RESET';
export const CAMPAIGN_WIZARD_VALIDATE_STEP = 'campaign-wizard/VALIDATE_STEP';
export const CAMPAIGN_WIZARD_TOGGLE = 'campaign-wizard/TOGGLE';
export const CAMPAIGN_WIZARD_SAVE = 'campaign-wizard/SAVE';
export const CAMPAIGN_WIZARD_SAVE_COMPLETED = 'campaign-wizard/SAVE_COMPLETED';
export const CAMPAIGN_WIZARD_SAVE_ERROR = 'campaign-wizard/SAVE_ERROR';
export const CAMPAIGN_WIZARD_ADD_SELECTION = 'campaign-wizard/ADD_SELECTION';
export const CAMPAIGN_WIZARD_REMOVE_SELECTION =
  'campaign-wizard/REMOVE_SELECTION';
export const CAMPAIGN_WIZARD_REMOVE_ALL_SELECTIONS =
  'campaign-wizard/REMOVE_ALL_SELECTIONS';
export const CAMPAIGN_WIZARD_ADD_PRIMARY_SEARCH =
  'campaign-wizard/ADD_PRIMARY_SEARCH';
export const CAMPAIGN_WIZARD_ADD_SEARCH = 'campaign-wizard/ADD_SEARCH';
export const CAMPAIGN_WIZARD_REMOVE_SEARCH = 'campaign-wizard/REMOVE_SEARCH';
export const CAMPAIGN_WIZARD_ADD_DASHBOARD = 'campaign-wizard/ADD_DASHBOARD';
export const CAMPAIGN_WIZARD_REMOVE_DASHBOARD =
  'campaign-wizard/REMOVE_DASHBOARD';
export const CAMPAIGN_WIZARD_ADD_REPORT = 'campaign-wizard/ADD_REPORT';
export const CAMPAIGN_WIZARD_REMOVE_REPORT = 'campaign-wizard/REMOVE_REPORT';
export const CAMPAIGN_WIZARD_ADD_NEWSLETTER = 'campaign-wizard/ADD_NEWSLETTER';
export const CAMPAIGN_WIZARD_REMOVE_NEWSLETTER =
  'campaign-wizard/REMOVE_NEWSLETTER';
export const CAMPAIGN_WIZARD_ADD_ALERT = 'campaign-wizard/ADD_ALERT';
export const CAMPAIGN_WIZARD_REMOVE_ALERT = 'campaign-wizard/REMOVE_ALERT';
export const CAMPAIGN_WIZARD_ADD_STORY = 'campaign-wizard/ADD_STORY';
export const CAMPAIGN_WIZARD_ADD_SAVEDLIST = 'campaign-wizard/ADD_SAVEDLIST';
export const CAMPAIGN_WIZARD_REMOVE_STORY = 'campaign-wizard/REMOVE_STORY';
export const CAMPAIGN_WIZARD_ADD_CONTACT =
  'campaign-wizard/CAMPAIGN_WIZARD_ADD_CONTACT';
export const CAMPAIGN_WIZARD_REMOVE_CONTACT =
  'campaign-wizard/CAMPAIGN_WIZARD_REMOVE_CONTACT';
export const CAMPAIGN_WIZARD_UPDATE_CONTACT_FILTERS =
  'campaing-wizard/CAMPAIGN_WIZARD_UPDATE_CONTACT_FILTERS';
export const CAMPAIGN_WIZARD_CREATE_WIDGET =
  'campaign-wizard/CAMPAIGN_WIZARD_CREATE_WIDGET';
export const CAMPAIGN_WIZARD_CREATE_WIDGET_ERROR =
  'campaign-wizard/CAMPAIGN_WIZARD_CREATE_WIDGET_ERROR';

const initialState = {
  campaignName: '',
  campaignId: null,
  currentStepIndex: 0,
  campaignDateRange: 'TRAILING_30',
  start: null,
  end: null,
  open: true,
  steps: CAMPAIGN_WIZARD_STEPS,
  campaign: {},
  selected: {},
  loading: false,
  error: false,
  primarySearch: null,
};

const campaignWizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_WIZARD_TOGGLE: {
      return { ...state, open: !state.open };
    }

    case CAMPAIGN_WIZARD_RESET: {
      return initialState;
    }

    case CAMPAIGN_WIZARD_SET_NAME: {
      return { ...state, campaignName: action.payload.name };
    }

    case CAMPAIGN_WIZARD_ADD_CONTACT: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          contacts: [...state.steps[step].data.contacts, selected],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_REMOVE_CONTACT: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          contacts: [
            ...state.steps[step].data.contacts.filter(
              contact => contact.id !== selected,
            ),
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_UPDATE_CONTACT_FILTERS: {
      const { stepId, query, filters } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          query,
          filters,
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_SEARCH: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          searches: [...state.steps[step].data.searches, selected],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_PRIMARY_SEARCH: {
      const { primarySearch } = action.payload;
      const newState = {
        ...state,
        primarySearch,
        selected: { ...state.selected, [primarySearch.id]: primarySearch },
      };

      if (state.primarySearch) {
        delete newState.selected[state.primarySearch.id];
      }

      return newState;
    }

    case CAMPAIGN_WIZARD_REMOVE_SEARCH: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          searches: [
            ...state.steps[step].data.searches.filter(
              search => search.id !== selected,
            ),
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_DASHBOARD: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          dashboards: [...state.steps[step].data.dashboards, selected],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_REMOVE_DASHBOARD: {
      const { stepId, selected } = action.payload;
      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          dashboards: [
            ...state.steps[step].data.dashboards.filter(
              dashboard => dashboard.id !== selected,
            ),
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_REPORT: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          reports: [...state.steps[step].data.reports, selected],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_REMOVE_REPORT: {
      const { stepId, selected } = action.payload;
      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          reports: [
            ...state.steps[step].data.reports.filter(
              report => report.id !== selected,
            ),
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_NEWSLETTER: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          newsletters: [...state.steps[step].data.newsletters, selected],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_REMOVE_NEWSLETTER: {
      const { stepId, selected } = action.payload;
      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          newsletters: [
            ...state.steps[step].data.newsletters.filter(
              newsletter => newsletter.id !== selected,
            ),
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_ALERT: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          alerts: [...state.steps[step].data.alerts, selected],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_REMOVE_ALERT: {
      const { stepId, selected } = action.payload;
      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          alerts: [
            ...state.steps[step].data.alerts.filter(
              alert => alert.id !== selected,
            ),
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_STORY: {
      const { stepId, selected } = action.payload;

      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          stories: [...state.steps[step].data.stories, selected],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_ADD_SAVEDLIST: {
      const { stepId, selected } = action.payload;
      const steps = [...state.steps];
      const step = steps.findIndex(step => stepId === step.id);

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          influencerLists: [
            ...state.steps[step].data.influencerLists,
            selected,
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_REMOVE_STORY: {
      const { stepId, selected } = action.payload;
      const step = CAMPAIGN_WIZARD_STEPS_BY_ID[stepId];
      const steps = [...state.steps];

      steps[step] = {
        ...state.steps[step],
        data: {
          ...state.steps[step].data,
          stories: [
            ...state.steps[step].data.stories.filter(
              story => story.id !== selected,
            ),
          ],
        },
      };

      return { ...state, steps };
    }

    case CAMPAIGN_WIZARD_VALIDATE_STEP: {
      const { id, valid } = action.payload;

      return {
        ...state,
        ...(state.steps[id].valid = valid),
      };
    }

    case CAMPAIGN_WIZARD_PREVIOUS_STEP: {
      let nextStepIndex =
        state.currentStepIndex - 1 < 0 ? 0 : state.currentStepIndex - 1;
      if (
        !action.payload.hasContacts &&
        state.steps[nextStepIndex].id === 'addContacts'
      ) {
        nextStepIndex = nextStepIndex === 3 ? 2 : nextStepIndex;
      }
      const nextStep = state.steps[nextStepIndex];
      return {
        ...state,
        currentStepIndex: nextStepIndex,
        currentStep: nextStep,
      };
    }

    case CAMPAIGN_WIZARD_NEXT_STEP: {
      let nextStepIndex =
        state.steps.length < state.currentStepIndex + 1
          ? 0
          : state.currentStepIndex + 1;
      if (
        !action.payload.hasContacts &&
        state.steps[nextStepIndex].id === 'addContacts'
      ) {
        nextStepIndex = nextStepIndex === 3 ? 4 : nextStepIndex;
      }
      const nextStep = state.steps[nextStepIndex];
      return {
        ...state,
        currentStepIndex: nextStepIndex,
        currentStep: nextStep,
      };
    }

    case CAMPAIGN_WIZARD_SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload.currentStepName,
        currentStepIndex:
          CAMPAIGN_WIZARD_STEPS_BY_ID[action.payload.currentStepName],
      };
    }

    case CAMPAIGN_WIZARD_SAVE: {
      return {
        ...state,
        loading: true,
      };
    }

    case CAMPAIGN_WIZARD_SAVE_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case CAMPAIGN_WIZARD_SAVE_COMPLETED: {
      return {
        ...state,
        loading: false,
        error: false,
        campaign: action.payload.campaign,
        campaignId: action.payload.campaign.id,
      };
    }

    case CAMPAIGN_WIZARD_ADD_SELECTION: {
      const { selection } = action.payload;
      return {
        ...state,
        selected: { ...state.selected, [selection.id]: selection },
      };
    }

    case CAMPAIGN_WIZARD_REMOVE_SELECTION: {
      const { selection } = action.payload;

      const selected = { ...state.selected };
      delete selected[selection];

      return {
        ...state,
        selected,
      };
    }

    case CAMPAIGN_WIZARD_REMOVE_ALL_SELECTIONS: {
      const selected = {};
      let primarySearch;
      Object.keys(state.selected).forEach(k => {
        if (state.selected[k].type === 'primarySearch') {
          primarySearch = state.selected[k];
        }
      });

      if (typeof primarySearch !== 'undefined') {
        selected[primarySearch.id] = primarySearch;
      }

      return {
        ...state,
        selected,
      };
    }
    case CAMPAIGN_WIZARD_CREATE_WIDGET: {
      return {
        ...state,
        loading: true,
      };
    }
    case CAMPAIGN_WIZARD_CREATE_WIDGET_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const addToContactList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_CONTACT,
  payload: { stepId, selected },
});

export const addToDashboardList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_DASHBOARD,
  payload: { stepId, selected },
});

export const addToReportList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_REPORT,
  payload: { stepId, selected },
});

export const addToNewsletterList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_NEWSLETTER,
  payload: { stepId, selected },
});

export const addToAlertList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_ALERT,
  payload: { stepId, selected },
});

export const addToSearchList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_SEARCH,
  payload: { selected, stepId },
});

export const addToStoryList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_STORY,
  payload: { selected, stepId },
});

export const addToInfluencerList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_ADD_SAVEDLIST,
  payload: { selected, stepId },
});

export const removeFromContactList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_REMOVE_CONTACT,
  payload: { selected, stepId },
});

export const removeFromDashboardList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_REMOVE_DASHBOARD,
  payload: { selected, stepId },
});

export const removeFromReportList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_REMOVE_REPORT,
  payload: { selected, stepId },
});

export const removeFromNewsletterList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_REMOVE_NEWSLETTER,
  payload: { selected, stepId },
});

export const removeFromAlertList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_REMOVE_ALERT,
  payload: { selected, stepId },
});

export const removeFromSearchList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_REMOVE_SEARCH,
  payload: { selected, stepId },
});

export const removeFromStoryList = (stepId, selected) => ({
  type: CAMPAIGN_WIZARD_REMOVE_STORY,
  payload: { selected, stepId },
});

export const addPrimarySearch = primarySearch => ({
  type: CAMPAIGN_WIZARD_ADD_PRIMARY_SEARCH,
  payload: { primarySearch },
});

export const addSelection = selection => {
  return { type: CAMPAIGN_WIZARD_ADD_SELECTION, payload: { selection } };
};

export const removeSelection = selection => ({
  type: CAMPAIGN_WIZARD_REMOVE_SELECTION,
  payload: { selection },
});

export const removeAllSelections = () => ({
  type: CAMPAIGN_WIZARD_REMOVE_ALL_SELECTIONS,
});

export const updateContactFilters = (stepId, query, filters) => ({
  type: CAMPAIGN_WIZARD_UPDATE_CONTACT_FILTERS,
  payload: { stepId, query, filters },
});

export const toggleStepValidation = (id, isValid) => ({
  type: CAMPAIGN_WIZARD_VALIDATE_STEP,
  payload: { id, isValid },
});

export const goToNextStep = () => (dispatch, getState) => {
  const state = getState();
  const hasContacts = hasFeatureFlagSelector(state)(FEATURES.pinpointContacts);
  dispatch({ type: CAMPAIGN_WIZARD_NEXT_STEP, payload: { hasContacts } });
};

export const goToPreviousStep = () => (dispatch, getState) => {
  const state = getState();
  const hasContacts = hasFeatureFlagSelector(state)(FEATURES.pinpointContacts);
  dispatch({ type: CAMPAIGN_WIZARD_PREVIOUS_STEP, payload: { hasContacts } });
};

export const toggleWizard = () => ({
  type: CAMPAIGN_WIZARD_TOGGLE,
});

export const resetWizard = () => ({
  type: CAMPAIGN_WIZARD_RESET,
});

export const resetWizardForm = () => dispatch => {
  dispatch(actions.reset('campaignWizard'));
  dispatch(actions.resetValidation('campaignWizard'));
};

export const setCurrentStep = stepName => ({
  type: CAMPAIGN_WIZARD_SET_CURRENT_STEP,
  payload: {
    currentStepName: stepName,
  },
});

export const setCampaignWizardSteps = () => (dispatch, getState) => {
  const state = getState();
  const newStepsList = campaignWizardStepsSelector(state);
  initialState.steps = newStepsList;
  state.campaignWizard.steps = newStepsList;
};

export const saveCampaign = intl => async (dispatch, getState) => {
  const state = getState();
  const data = {};
  const selected = state.campaignWizard.selected;

  Object.keys(selected).forEach(k => {
    const type = selected[k].type;
    const id = selected[k].id;
    const version = selected[k].version === '1.0' ? 1 : 2;
    let element;
    if (type === 'dashboards') {
      element = { id, version };
    } else if (type === 'reports') {
      element = { id, version: selected[k].version };
    } else {
      element = id;
    }
    if (type === 'primarySearch') {
      data[type] = selected[k];
    } else if (typeof data[type] === 'undefined') {
      data[type] = [element];
    } else {
      data[type].push(element);
    }
  });

  data.category = state.campaignWizard.steps[0].data.category[0];
  data.description = state.campaignWizard.steps[0].data.description;
  data.title = state.campaignWizard.steps[0].data.title;
  data.rangeType = state.campaignWizard.steps[0].data.dateRange;
  data.startDate = state.campaignWizard.steps[0].data.start;
  data.endDate = state.campaignWizard.steps[0].data.end;
  data.widgetV3s = null;

  dispatch({ type: CAMPAIGN_WIZARD_SAVE });
  performPost(`${CAMPAIGN_ENDPOINT}`, data)
    .then(response => {
      dispatch({
        type: CAMPAIGN_WIZARD_SAVE_COMPLETED,
        payload: {
          campaign: response.data,
        },
      });
      dispatch(toggleSidebar());
      dispatch(resetWizard());
      dispatch(resetWizardForm);
      dispatch(actions.reset('campaignWizard.steps[]'));
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.campaignsCreationSucceeded),
          status: 'success',
          ttl: 3000,
        }),
      );
    })
    .catch(error => {
      dispatch({ type: CAMPAIGN_WIZARD_SAVE_ERROR, payload: error });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.campaignsCreationFailed),
          status: 'danger',
        }),
      );
    });
};

export default campaignWizardReducer;
