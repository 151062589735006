export const OUTREACH_CONTACT_TYPES = {
  author: 'Author',
  custom: 'Custom',
  user: 'User',
  influencer: 'Influencer',
};

// TODO: Get grails to change its ways and use all caps enums
export const OUTREACH_MESSAGE_STATUSES = {
  draft: 'Draft',
  scheduled: 'Scheduled',
  sent: 'Sent',
  failed: 'Failed',
  received: 'Received',
};

export const OUTREACH_MOCK_MESSAGES = {
  emailSubject: 'Product launch follow-up',
  emailBody:
    // eslint-disable-next-line max-len
    "Hi,<br/><br/>How are you today?<br /><br />I just wanted to reach out to follow up on your coverage of our forthcoming product launch. Is there anything else you need in order for you to write your story? Please don't hesitate to let me know.<br /><br />Thanks!",
  bulkEmailListBody:
    // eslint-disable-next-line max-len
    'Hi <span class="tk-ql-template-string" data-template-key="First name"></span>,<br/><br/>How are you today?<br /><br />I just wanted to reach out to follow up on your coverage of our forthcoming product launch. Is there anything else you need in order for you to write your story? Please don\'t hesitate to let me know.<br /><br />Thanks!',
  bulkEmailStoryKitBody:
    // eslint-disable-next-line max-len
    'Hi <span class="tk-ql-template-string" data-template-key="First name"></span>,<br /><br />How are you today?<br /><br />I just wanted to reach out to follow up on your coverage of our forthcoming product launch. Is there anything else you need in order for you to write your story? Please do <a href="http://www.trendkite.com" target="_blank">check out this link</a> for supporting info and assets to assist with your story.<br /><br />Thanks!',
};

export const TK_TEST_EMAIL = 'tk.outreach.test@gmail.com';

export default {
  OUTREACH_CONTACT_TYPES,
};
