import React from 'react';
import PropTypes from 'prop-types';

const TabMenu = ({ children, style }) => (
  <ul className={TabMenu.baseClass} style={style}>
    {children}
  </ul>
);

TabMenu.baseClass = 'tk-tab-menu';

TabMenu.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

TabMenu.defaultProps = {
  style: {},
};

export default TabMenu;
