// outreach.js
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { getBodyAsSafeHtml, getBodyAsText } from 'components/outreach/utils';
import {
  DOMAIN_DKIM_STATUS,
  OUTREACH_EMAIL_ACCOUNT_TYPE,
} from 'constants/constants';
import { OUTREACH_INTEGRATION_STATUS } from 'constants/outreach-integration';
import { userIdSelector } from 'selectors/account';

// Messages
export const outreachMessagesErrorSelector = state =>
  state.outreach.messagesError;
export const outreachMessagesLoadingSelector = state =>
  !!state.outreach.messagesLoading;
export const outreachMessagesByIdSelector = state =>
  state.outreach.messagesById || {};
export const outreachMessageAggregationsSelector = state =>
  state.outreach.aggregations;
export const currentMessageIdsSelector = state =>
  state.outreach.currentMessageIds || [];

export const getProcessedMessageBody = body => {
  if (!body) {
    return null;
  }

  const bodySafeHtml = getBodyAsSafeHtml(body);
  const bodyText = getBodyAsText(body);

  return {
    bodySafeHtml,
    bodyText,
  };
};

export const memoizedGetProcessedMessageBody = memoize(getProcessedMessageBody);

export const getOutreachMessageByIdSelector = createSelector(
  // Make all message selectors use this - it memoizes html sanitization
  outreachMessagesByIdSelector,
  outreachMessagesById =>
    memoize(messageId => {
      const message = outreachMessagesById[messageId];
      return {
        ...message,
        ...getProcessedMessageBody(message?.body),
      };
    }),
);

export const currentMessagesAsArraySelector = createSelector(
  currentMessageIdsSelector,
  getOutreachMessageByIdSelector,
  (currentMessageIds, getOutreachMessageById) =>
    currentMessageIds.map(messageId => getOutreachMessageById(messageId)) || [],
);

// Integrations
export const outreachIntegrationsLoadingSelector = state =>
  !!state.outreachIntegrations.loading;
export const hasOutreachIntegrationsSelector = state =>
  !state.outreachIntegrations.loading &&
  state.outreachIntegrations.integrations.length > 0;
export const outreachIntegrationsArraySelector = state =>
  (state.outreachIntegrations?.integrations &&
    sortBy(state.outreachIntegrations.integrations, 'emailAddress')) ||
  [];
export const defaultOutreachIntegrationIdSelector = state =>
  state.outreachIntegrations.userDefaultIntegrationId || null;

export const messageCenterEmailStatsSelector = state =>
  state.outreachIntegrations.userOutreachMessageStats;

export const outreachDomainsSelector = state =>
  state.outreachIntegrations.domains;

export const outreachCampaignsSelector = state =>
  state.outreachIntegrations.campaignsForFilter || [];

export const sendDomainVerificationLoadingSelector = state =>
  state.outreachIntegrations.sendDomainVerificationLoading;

export const outreachSaveLoadingSelector = state =>
  state.outreachIntegrations.saveLoading;
export const messge = state => state.outreachIntegrations.messageId;

export const campaignBackButton = state =>
  state.outreachIntegrations.campaignBackButon;

export const outreachErrorSelector = state => state.outreachIntegrations.error;

export const hasUnrequestedPrivateDomainsSelector = createSelector(
  outreachDomainsSelector,
  domains =>
    !isEmpty(
      domains.filter(
        d =>
          d.public === false &&
          d.verified === true &&
          d.requested === false &&
          d.status !== DOMAIN_DKIM_STATUS.valid,
      ),
    ),
);

export const hasUnauthenticatedPrivateDomainsSelector = createSelector(
  outreachDomainsSelector,
  domains =>
    domains
      .filter(
        d => d.public === false && d.verified === true && d.requested === true,
      )
      .some(
        d =>
          d.status === DOMAIN_DKIM_STATUS.invalid ||
          d.status === DOMAIN_DKIM_STATUS.notCreated,
      ),
);

export const defaultOutreachIntegrationSelector = createSelector(
  outreachIntegrationsArraySelector,
  defaultOutreachIntegrationIdSelector,
  (integrations, id) => integrations.find(i => id === i.id),
);

export const ownedIntegrationsSelector = createSelector(
  outreachIntegrationsArraySelector,
  userIdSelector,
  (integrations, userId) =>
    integrations.filter(
      i =>
        i.user.id === userId &&
        i.type === OUTREACH_EMAIL_ACCOUNT_TYPE.integration,
    ),
);

export const validOwnedIntegrationsSelector = createSelector(
  ownedIntegrationsSelector,
  integrations =>
    integrations.filter(i => i.status === OUTREACH_INTEGRATION_STATUS.running),
);

export const defaultOrFirstOutreachIntegrationSelector = createSelector(
  ownedIntegrationsSelector,
  defaultOutreachIntegrationSelector,
  (integrations, defaultIntegration) =>
    defaultIntegration || (integrations.length && integrations[0]) || null,
);

export const ownedIntegrationUuidsSelector = createSelector(
  ownedIntegrationsSelector,
  ownedIntegrations => ownedIntegrations.map(i => i.uuid),
);

export const hasOwnedOutreachIntegrationsSelector = createSelector(
  ownedIntegrationsSelector,
  ownedIntegrations => ownedIntegrations.length > 0,
);

export const invalidOwnedIntegrationsSelector = createSelector(
  ownedIntegrationsSelector,
  ownedIntegrations =>
    ownedIntegrations.filter(
      i => i.status === OUTREACH_INTEGRATION_STATUS.reauth,
    ),
);

export const hasInvalidOwnedIntegrationsSelector = createSelector(
  invalidOwnedIntegrationsSelector,
  invalidIntegrations => invalidIntegrations.length > 0,
);

// Threads
export const aggregationsTotalSelector = state =>
  state.outreachThreads.aggregations.total || 0;
export const threadsLoadingSelector = state =>
  state.outreachThreads.threadsLoading;
export const currentThreadIdSelector = state =>
  state.outreachThreads.currentThreadId || null;
export const threadsByIdSelector = state =>
  state.outreachThreads.threadsById || {};
export const currentThreadIdsSelector = state =>
  state.outreachThreads.currentThreadIds || [];
export const updatingThreadsSelector = state =>
  state.outreachThreads.threadsUpdating;
export const selectedRecipientsIdsSelector = state =>
  state.outreachThreads.selectedRecipientsIds;
export const isSelectAllCheckedSelector = state =>
  state.outreachThreads.isSelectAllChecked;
export const linkTrackingEnabledSelector = state =>
  state.outreachThreads.linkTrackingEnabled;

const getOutreachMessageByIdSelectors = createSelector(
  outreachMessagesByIdSelector,
  messagesById => memoize(messagesId => messagesById[messagesId] || null),
);

const getOutreachThreadByIdSelector = createSelector(
  threadsByIdSelector,
  threadsById => memoize(threadId => threadsById[threadId] || null),
);

export const currentThreadIsUpdatingSelector = createSelector(
  currentThreadIdSelector,
  updatingThreadsSelector,
  (threadId, updatingThreads) => !!updatingThreads[threadId],
);

export const currentPageThreadsSelector = createSelector(
  currentThreadIdsSelector,
  threadsByIdSelector,
  (currentThreadIds, threadsById) =>
    currentThreadIds.map(id => threadsById[id]),
);

export const currentThreadSelector = createSelector(
  currentThreadIdSelector,
  threadsByIdSelector,
  (currentThreadId, threadsById) => threadsById[currentThreadId] || null,
);

// Compose Modal
export const isComposeModalOpenSelector = state =>
  state.outreachComposeModal.isComposeModalOpen;

// Schedule Modal
export const isScheduleModalOpenSelector = state =>
  state.outreachScheduleModal.isScheduleModalOpen;
export const isScheduleModalErrorOpenSelector = state =>
  state.outreachScheduleModal.isScheduleModalErrorOpen;

// Compose message form (Deconstructing RRF state back to something we can use)
export const outreachComposeFormSelector = state =>
  state.forms.outreachComposeForm || {};

export const isOutreachComposeFormPristineSelector = createSelector(
  outreachComposeFormSelector,
  outreachComposeForm => outreachComposeForm.$form.pristine,
);

export const isOutreachComposeFormValidSelector = createSelector(
  outreachComposeFormSelector,
  outreachComposeForm => outreachComposeForm.$form.valid,
);

export const isOutreachComposeFormUnmodifiedReplySelector = createSelector(
  outreachComposeFormSelector,
  outreachComposeForm =>
    !!outreachComposeForm.replyToMessageId.value &&
    outreachComposeForm.body.pristine,
);

export const hasOutreachComposeFormRequirementsSelector = createSelector(
  outreachComposeFormSelector,
  outreachComposeForm =>
    !!outreachComposeForm.subject.value &&
    !!outreachComposeForm.body.value &&
    outreachComposeForm.body.value !== '<div><br></div>',
);

export const toFieldIdSelector = createSelector(
  outreachComposeFormSelector,
  outreachComposeForm => outreachComposeForm.to[0]?.id?.value,
);

export const isOutreachComposeFormSubjectFieldValidSelector = createSelector(
  outreachComposeFormSelector,
  outreachComposeForm => outreachComposeForm.subject.value.length > 0,
);

export const isOutreachComposeFormSubjectFieldTouchedSelector = createSelector(
  outreachComposeFormSelector,
  outreachComposeForm => outreachComposeForm.subject.touched,
);

export const outreachComposeFormValuesSelector = state =>
  state.outreachComposeForm;

// Generic aggregations selectors

const outreachAggregationsSelector = state =>
  state.outreachFilteredViews.aggregations || {};

export const outreachAggregationsByIdSelector = memoize(id =>
  createSelector(
    outreachAggregationsSelector,
    outreachAggregations => outreachAggregations[id] || null,
  ),
);

export const selectedSidebarFilter = state =>
  state.outreachIntegrations.selectedSidebarFilter;

export const modifiedSidebarCount = state =>
  state.outreachIntegrations.modifiedFromSidebarCount;

// Generic filtered view selectors
// Filtered views
export const outreachFilteredViewsByIdSelector = state =>
  state.outreachFilteredViews.filteredViewsById || {};

export const getOutreachFilteredViewSelectorById = memoize(id =>
  createSelector(
    outreachFilteredViewsByIdSelector,
    outreachFilteredViewsById => outreachFilteredViewsById[id] || null,
  ),
);

export const outreachFilteredViewUnfilteredAggregationsSelectorById = memoize(
  id =>
    createSelector(
      getOutreachFilteredViewSelectorById(id),
      filteredView =>
        (filteredView && filteredView.aggregationsWithOnlyHiddenFilters) || {},
    ),
);

export const outreachFilteredViewThreadIdsSelectorById = memoize(id =>
  createSelector(
    getOutreachFilteredViewSelectorById(id),
    filteredView => (filteredView && filteredView.threadIds) || [],
  ),
);

export const outreachFilteredViewThreadsSelectorById = memoize(filteredViewId =>
  createSelector(
    outreachFilteredViewThreadIdsSelectorById(filteredViewId),
    threadsByIdSelector,
    (threadIds, threadsById) =>
      threadIds.map(threadId => threadsById[threadId]),
  ),
);

export const outreachFilteredViewCurrentPageMessageCount = memoize(id =>
  createSelector(
    outreachFilteredViewThreadIdsSelectorById(id),
    threadsByIdSelector,
    (threadIds, threadsById) =>
      threadIds
        .map(threadId => threadsById[threadId])
        .map(thread => thread.messageIds)
        .reduce((acc, messageIds) => {
          return acc + messageIds.length;
        }, 0),
  ),
);

export const outreachFilteredViewLoadingSelectorById = memoize(id =>
  createSelector(
    getOutreachFilteredViewSelectorById(id),
    filteredView => !!(filteredView && filteredView.loading),
  ),
);

const outreachFilteredViewTypeaheadThreadIdsSelectorById = memoize(id =>
  createSelector(
    getOutreachFilteredViewSelectorById(id),
    filteredView => (filteredView && filteredView.typeaheadThreadIds) || [],
  ),
);

export const outreachFilteredViewTypeaheadThreadsLoadingSelectorById = memoize(
  id =>
    createSelector(
      getOutreachFilteredViewSelectorById(id),
      filteredView => filteredView && !!filteredView.loadingTypeaheadThreads,
    ),
);

export const outreachFilteredViewTypeaheadThreadsSelectorById = memoize(
  filteredViewId =>
    createSelector(
      outreachFilteredViewTypeaheadThreadIdsSelectorById(filteredViewId),
      getOutreachThreadByIdSelector,
      (outreachMessageCenterTypeaheadThreadIds, getOutreachThreadById) =>
        outreachMessageCenterTypeaheadThreadIds.map(threadId =>
          getOutreachThreadById(threadId),
        ),
    ),
);

export const outreachFilteredViewTypeaheadMessagesSelectorById = memoize(
  filteredViewId =>
    createSelector(
      outreachFilteredViewTypeaheadThreadIdsSelectorById(filteredViewId),
      getOutreachMessageByIdSelectors,
      (outreachMessageCenterTypeaheadThreadIds, getOutreachThreadById) =>
        outreachMessageCenterTypeaheadThreadIds.map(messageId =>
          getOutreachThreadById(messageId),
        ),
    ),
);

export const attachmentUrlSelector = (state, id) =>
  state.outreachAttachments.imageSignedUrls[id];

export const attachmentSelector = (state, id) =>
  state.outreach.messageAttachmentsById[id];

export const usersFilteredData = state =>
  state.outreachIntegrations.usersFilteredData || {};

export const emailDeletedAndRedirect = state =>
  state.outreachIntegrations.emailDeletedAndRedirect;

// DKIM status
export const dkimStatusLoadingSelector = state =>
  state.outreachIntegrations.dkimStatus.loading;

export const dkimStatusDataSelector = state =>
  state.outreachIntegrations.dkimStatus.data;

// SCHEDULED EMAILS FROM ACCOUNT
export const scheduledEmailsLoadingSelector = state =>
  state.outreachIntegrations.scheduledEmails.loading;
export const scheduledEmailsResultsSelector = state =>
  state.outreachIntegrations.scheduledEmails.results || {};
