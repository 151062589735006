import { NEW_DASHBOARD_ENDPOINT } from 'constants/apis';
import { DEV_FEATURES } from 'constants/constants';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import { performGet } from 'services/rest-service/rest-service';

import { getCurrentUserImpersonating } from 'services/user-service/user-service';

import { AnalyticsListActionCreatorArgs } from '../../analytics-config';

export const LIST_SIDEBAR_ANALYTICS = 'analytics/LIST_SIDEBAR_ANALYTICS';
export const LIST_SIDEBAR_ANALYTICS_SUCCESS =
  'analytics/LIST_SIDEBAR_ANALYTICS_SUCCESS';
export const LIST_SIDEBAR_ANALYTICS_ERROR =
  'analytics/LIST_SIDEBAR_ANALYTICS_ERROR';

export const initialState = {
  error: false,
  loading: false,
  counts: {},
};

const sidebarAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SIDEBAR_ANALYTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LIST_SIDEBAR_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        counts: action.payload,
      };
    case LIST_SIDEBAR_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

const getTrashcanDaysParam = () => {
  const impersonatingId = getCurrentUserImpersonating();
  const userIsImpersonating = !!impersonatingId;

  return { trashcanDays: userIsImpersonating ? 731 : 30 };
};

export const sidebarAnalyticsActionCreator = ({
  endpointBase = NEW_DASHBOARD_ENDPOINT,
  doCountReload = false,
}: AnalyticsListActionCreatorArgs = {}) => async (dispatch, getState) => {
  dispatch({ type: LIST_SIDEBAR_ANALYTICS });

  try {
    const userHasArchiveDeleteDashFf = userHasDevFeatureFlag(
      DEV_FEATURES.archiveDeleteDashboards,
    );
    const params = userHasArchiveDeleteDashFf ? getTrashcanDaysParam() : {};
    const response = await performGet(`${endpointBase}/counts`, params);
    dispatch({
      type: LIST_SIDEBAR_ANALYTICS_SUCCESS,
      payload: response.data.counts,
    });
  } catch (e) {
    dispatch({
      type: LIST_SIDEBAR_ANALYTICS_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export default sidebarAnalyticReducer;
