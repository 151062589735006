import { actions } from 'react-redux-form';

import { defaultTemplate } from '@trendkite/stories';

const initialState = {
  ...defaultTemplate.defaultProps.defaultContent,
};

const storyContentFormReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const loadStoryContentFormActionCreator = story => dispatch => {
  const content = story && (story.draftContent || story.content || {});
  dispatch(actions.load('storyContentForm', content));
};

export const resetStoryContentFormActionCreator = () => dispatch => {
  dispatch(actions.reset('storyContentForm'));
};

export default storyContentFormReducer;
