import React, { Component } from 'react';

import { Button } from '@cision/rover-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ConfirmModal, SvgIcon } from '@trendkite/ui';

import { openNylasRedirectModalActionCreator } from 'reducers/outreach/nylas-redirect-modal';
import { openComposeModalWithValuesActionCreator } from 'reducers/outreach/outreach-drafts';
import {
  closeReconnectModalActionCreator,
  CONTINUE_PATH_COMPOSE_MODAL,
} from 'reducers/outreach/outreach-reconnect-modal';
import {
  invalidOwnedIntegrationsSelector,
  validOwnedIntegrationsSelector,
} from 'selectors/outreach';

import './outreach-reconnect-modal.scss';

const mapStateToProps = state => ({
  continuePath: state.outreachReconnectModal.continuePath,
  continueState: state.outreachReconnectModal.continueState,
  isOpen: state.outreachReconnectModal.isOpen,
  invalidIntegrations: invalidOwnedIntegrationsSelector(state),
  ownedIntegrations: validOwnedIntegrationsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  closeReconnectModal: () => dispatch(closeReconnectModalActionCreator()),
  openComposeModal: (vals, saveDraft, filteredViewId) =>
    dispatch(
      openComposeModalWithValuesActionCreator(
        vals,
        { saveDraft, filteredViewId },
        true,
      ),
    ),
  openNylasRedirectModal: (redirect, state) =>
    dispatch(openNylasRedirectModalActionCreator(redirect, state)),
});

const someInvalidProps = {
  cancelButtonText: 'Continue',
  header: "Some Email Accounts Couldn't Sync",
  status: 'warning',
};

const allInvalidProps = {
  header: "Your Email Accounts Couldn't Sync",
  status: 'danger',
};

class OutreachReconnectModal extends Component {
  onContinue = () => {
    const {
      closeReconnectModal,
      continuePath,
      continueState,
      history,
      openComposeModal,
      ownedIntegrations,
    } = this.props;

    closeReconnectModal();
    if (ownedIntegrations.length) {
      if (continuePath === CONTINUE_PATH_COMPOSE_MODAL) {
        openComposeModal(
          continueState.vals,
          continueState.saveDraft,
          continueState.filteredViewId,
        );
      } else {
        history.push('/send/compose');
      }
    }
  };

  onManageAccounts = () => {
    this.props.closeReconnectModal();
    this.props.history.push('/settings/account/mail');
  };

  reconnectOutreach = () => {
    this.props.openNylasRedirectModal(this.props.location.pathname);
  };

  renderInvalidIntegrations = () => {
    const { invalidIntegrations } = this.props;

    return (
      <React.Fragment>
        <span>
          You won&apos;t be able to send emails from the accounts listed below:
        </span>
        <div
          className={`${OutreachReconnectModal.baseClass}__invalid-integrations-list`}
        >
          {invalidIntegrations.map(({ emailAddress }) => {
            return (
              <div
                key={emailAddress}
                className={`${OutreachReconnectModal.baseClass}__item`}
              >
                <SvgIcon
                  icon="notice"
                  color="danger"
                  modifiers={['right-small']}
                />
                <div className={`${OutreachReconnectModal.baseClass}__email`}>
                  {emailAddress}
                </div>
                <div className={`${OutreachReconnectModal.baseClass}__reason`}>
                  <Button
                    data-qa="hJO0ObqrWqd_HGY_Sr2_r"
                    level="link"
                    size="sm"
                    onClick={this.reconnectOutreach}
                    style={{
                      paddingLeft: '0',
                      paddingRight: '0',
                    }}
                  >
                    Reconnect
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { isOpen, ownedIntegrations } = this.props;

    let modalProps = someInvalidProps;
    if (!ownedIntegrations.length) {
      modalProps = allInvalidProps;
    }

    return (
      <ConfirmModal
        confirmButtonText="Manage Email Accounts"
        isOpen={isOpen}
        message={this.renderInvalidIntegrations()}
        onCancel={this.onContinue}
        onConfirm={this.onManageAccounts}
        size="md"
        {...modalProps}
      />
    );
  }
}

OutreachReconnectModal.baseClass = 'outreach-reconnect-modal';

OutreachReconnectModal.propTypes = {
  closeReconnectModal: PropTypes.func.isRequired,
  continuePath: PropTypes.string,
  continueState: PropTypes.object,
  history: PropTypes.object.isRequired,
  invalidIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  openComposeModal: PropTypes.func.isRequired,
  openNylasRedirectModal: PropTypes.func.isRequired,
  ownedIntegrations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

OutreachReconnectModal.defaultProps = {
  continuePath: '',
  continueState: {},
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutreachReconnectModal),
);
