import { ALERT_LIST_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

export const GET_ALERT_LIST = 'alerts/GET_ALERT_LIST';
export const GET_ALERT_LIST_RECEIVED = 'alerts/GET_ALERT_LIST_RECEIVED';
export const GET_ALERT_LIST_ERROR = 'alerts/GET_ALERT_LIST_ERROR';

export const initialState = {
  error: false,
  loading: true,
  alerts: {},
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALERT_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALERT_LIST_RECEIVED:
      return {
        ...state,
        error: false,
        loading: false,
        alerts: {
          ...state.alerts,
          ...action.payload,
        },
      };
    case GET_ALERT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export const getAlerts = () => dispatch => {
  dispatch({ type: GET_ALERT_LIST });

  performGet(`${ALERT_LIST_ENDPOINT}`)
    .then(response => {
      const data = {};
      response.data.forEach(alert => {
        alert = { ...alert, svgIcon: 'alerts', type: 'alerts' };
        data[alert.id] = alert;
      });

      dispatch({
        type: GET_ALERT_LIST_RECEIVED,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_ALERT_LIST_ERROR });
    });
};

export default alertReducer;
