/* eslint-disable import/order */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Errors } from 'react-redux-form';
import filesize from 'filesize';
import { connect } from 'react-redux';

import {
  Addon,
  Button,
  ErrorWithIntl,
  Loader,
  Paper,
  ResponsiveContainer,
  ResponsiveContext,
  SvgIcon,
  TabMenu,
  TabMenuItem,
} from '@trendkite/ui';

import StorySummary from '../../story-summary';
import StoryVideos from '../../story-videos';
import StorySpokespeople from '../../story-spokespeople';
import { contentPropTypes, errorMessageI18nKeys } from './default-template';
import { hasDevFeatureFlagSelector } from 'selectors/account';
import { DEV_FEATURES } from 'constants/constants';

// i18n
import T from 'i18n/TranslatedMessage';
import messages from './DefaultTemplate.messages';

import StoryHero from '../../story-hero';
import StoryLogo from '../../story-logo';
import StoryHeadline from '../../story-headline';
import StoryContact from '../../story-contact';

import StoryCompanyBackground from '../../story-company-background';
import StoryBacklinks from '../../story-backlinks';
import StoryDownloadableAssets from '../../story-downloadable-assets';

import './default-template.scss';

const DefaultTemplateError = props => (
  <ErrorWithIntl messages={messages} block paddingTop="5px" {...props} />
);

const baseClass = 'tk-story';

const mapStateToProps = state => ({
  hasDevFeatureFlag: hasDevFeatureFlagSelector(state),
});

class DefaultTemplate extends Component {
  state = {
    activeTab: 'content',
    assetsDownloading: false,
  };

  onClickDownloadAllAssets = () => {
    this.setState({ assetsDownloading: true });
    this.props
      .onClickDownloadAllAssets()
      .then(() => {
        this.setState({ assetsDownloading: false });
      })
      .catch(() => {
        this.setState({ assetsDownloading: false });
      });
  };

  getAssetArray() {
    const {
      content: { assets = {} },
    } = this.props;
    return Object.values(assets).filter(a => !a.deleted && a.imageURL);
  }

  renderNav() {
    const { activeTab } = this.state;
    const { isEditable } = this.props;

    const assetCount = this.getAssetArray().length;

    const showDownloadableAssets = isEditable || !!assetCount;

    return (
      <div className={`${baseClass}__nav`}>
        <TabMenu>
          <TabMenuItem>
            <button
              data-qa="kFel1MoqKybKhJL_AYJSr"
              onClick={() => this.setState({ activeTab: 'content' })}
              className={activeTab === 'content' ? 'active' : null}
            >
              <T {...messages.overview} />
            </button>
          </TabMenuItem>
          {showDownloadableAssets && (
            <TabMenuItem>
              <button
                data-qa="B6h5c5pZI8kNWjBdOC619"
                onClick={() =>
                  this.setState({ activeTab: 'downloadable-assets' })
                }
                className={
                  activeTab === 'downloadable-assets' ? 'active' : null
                }
              >
                <T
                  {...messages.downloadableAssets}
                  values={{ ASSET_COUNT: assetCount }}
                />
              </button>
            </TabMenuItem>
          )}
        </TabMenu>
      </div>
    );
  }

  renderSidebar() {
    const { content, isEditable } = this.props;

    const { pressContact, spokespeople } = content;

    const contactComp = this.renderComponent(
      pressContact,
      isEditable,
      this.renderContact,
    );
    const spokespeopleComp = this.renderComponent(
      spokespeople,
      isEditable,
      this.renderSpokespeople,
    );

    const fields = [
      <div key="story-contacts-key">{contactComp}</div>,
      <div key="story-spokespeople-key">{spokespeopleComp}</div>,
    ];

    if (!isEditable && fields.filter(field => field !== null).length === 0) {
      return null;
    }

    return (
      <div className={`${baseClass}__sidebar`}>
        <ResponsiveContainer>{fields}</ResponsiveContainer>
      </div>
    );
  }

  renderErrors = () => {
    const { isEditable } = this.props;

    if (!isEditable) {
      return null;
    }

    return (
      <Errors
        className="errors"
        component={DefaultTemplateError}
        messages={errorMessageI18nKeys.pressContact['']}
        model="storyPressContactForm"
        show="touched"
      />
    );
  };

  renderHero = (heroSrc, isEditable) => {
    const { dispatchMergeFormAction, logoUploadPath } = this.props;

    return (
      <StoryHero
        isEditable={isEditable}
        heroSrc={heroSrc}
        dispatchMergeFormAction={dispatchMergeFormAction}
        logoUploadPath={logoUploadPath}
        browseLink={this.props.hasDevFeatureFlag(
          DEV_FEATURES.browseAssetLibrary,
        )}
      />
    );
  };

  renderContact = (pressContact, isEditable) => {
    const {
      dispatchSubmitFormAction,
      dispatchMergeFormAction,
      dispatchTouchFormAction,
      isStoryPressContactFormValid,
      isStoryPressContactValid,
    } = this.props;

    const onStopEditingPressContact = () => {
      dispatchTouchFormAction('storyPressContactForm'); // This doesn't seem to work
    };

    return (
      <StoryContact
        customErrors={this.renderErrors()}
        dispatchSubmitFormAction={dispatchSubmitFormAction}
        isEditable={isEditable}
        isStoryPressContactFormValid={isStoryPressContactFormValid}
        isStoryPressContactValid={isStoryPressContactValid}
        dispatchMergeFormAction={dispatchMergeFormAction}
        onStopEditing={onStopEditingPressContact}
        pressContact={pressContact}
      />
    );
  };

  renderLogo = (logo, isEditable) => {
    const { logoUploadPath, dispatchMergeFormAction } = this.props;
    const { logoSize, logoSrc } = logo;

    return (
      <StoryLogo
        isEditable={isEditable}
        logoSize={logoSize}
        logoSrc={logoSrc}
        logoUploadPath={logoUploadPath}
        dispatchMergeFormAction={dispatchMergeFormAction}
        browseLink={this.props.hasDevFeatureFlag(
          DEV_FEATURES.browseAssetLibrary,
        )}
      />
    );
  };

  renderHeadline = (headline, isEditable) => {
    return (
      <div className={`${baseClass}__headline`}>
        <StoryHeadline
          color="dark"
          errorMessages={errorMessageI18nKeys.headline}
          inputModelName="headline"
          isEditable={isEditable}
          size="xxxl"
          updateOn={['blur']}
          value={headline}
          multiLine
        />
      </div>
    );
  };

  renderSummary = (summary, isEditable) => {
    return (
      <StorySummary
        errorMessages={errorMessageI18nKeys.summary}
        inputModelName="summary"
        isEditable={isEditable}
        updateOn={['change']}
        value={summary}
      />
    );
  };

  renderCompanyBackground = (background, isEditable) => {
    return (
      <StoryCompanyBackground
        errorMessages={errorMessageI18nKeys.summary}
        inputModelName="companyBackground"
        isEditable={isEditable}
        value={background}
      />
    );
  };

  renderBacklinks = (backlinks, isEditable) => {
    return (
      <StoryBacklinks
        isEditable={isEditable}
        backlinks={backlinks}
        dispatchMergeFormAction={this.props.dispatchMergeFormAction}
      />
    );
  };

  renderVideos = (videos, isEditable) => {
    return (
      <StoryVideos
        isEditable={isEditable}
        videos={videos}
        dispatchMergeFormAction={this.props.dispatchMergeFormAction}
      />
    );
  };

  renderSpokespeople = (spokespeople, isEditable) => {
    return (
      <StorySpokespeople
        isSaving={this.props.isSaving}
        isEditable={isEditable}
        spokespeople={spokespeople}
        logoUploadPath={this.props.logoUploadPath}
        dispatchMergeFormAction={this.props.dispatchMergeFormAction}
        browseLink={this.props.hasDevFeatureFlag(
          DEV_FEATURES.browseAssetLibrary,
        )}
      />
    );
  };

  renderComponent(value, isEditable, renderFunc) {
    if (
      !isEditable &&
      (!value || (Array.isArray(value) && value.length === 0))
    ) {
      return null;
    }

    return renderFunc(value, isEditable);
  }

  renderDownloadableAssets = () => {
    const {
      content: { assets },
      isEditable,
      dispatchMergeFormAction,
      logoUploadPath,
    } = this.props;

    return (
      <div className={`${baseClass}__content`}>
        <div className={`${baseClass}__main`}>
          <StoryDownloadableAssets
            isEditable={isEditable}
            assets={assets}
            uploadURL={logoUploadPath}
            dispatchMergeFormAction={dispatchMergeFormAction}
            browseLink={this.props.hasDevFeatureFlag(
              DEV_FEATURES.browseAssetLibrary,
            )}
          />
        </div>
      </div>
    );
  };

  renderContent = contentClassNames => {
    const {
      content: { backlinks, summary, companyBackground, videos },
      isEditable,
    } = this.props;
    const summaryComp = this.renderComponent(
      summary,
      isEditable,
      this.renderSummary,
    );
    const backgroundComp = this.renderComponent(
      companyBackground,
      isEditable,
      this.renderCompanyBackground,
    );
    const backlinksComp = this.renderComponent(
      backlinks,
      isEditable,
      this.renderBacklinks,
    );
    const videosComp = this.renderComponent(
      videos,
      isEditable,
      this.renderVideos,
    );
    const sideBar = this.renderSidebar();

    return (
      <div className={contentClassNames}>
        <div className={`${baseClass}__main`}>
          {summaryComp}
          {backgroundComp}
          {backlinksComp}
          {videosComp}
        </div>
        {sideBar}
      </div>
    );
  };

  renderContentTab = () => {
    const { isEditable } = this.props;

    if (isEditable) {
      return this.renderContent(`${baseClass}__content`);
    }

    // Make the main area responsive in story pages
    return (
      <ResponsiveContext.Consumer>
        {responsiveModifiers => {
          const responsiveClasses = responsiveModifiers
            ? responsiveModifiers.map(
                modifier => `${baseClass}__content--${modifier}`,
              )
            : [];

          const contentClassNames = classNames(
            `${baseClass}__content`,
            responsiveClasses,
          );

          return this.renderContent(contentClassNames);
        }}
      </ResponsiveContext.Consumer>
    );
  };

  renderHeader() {
    const { isEditable } = this.props;

    const assetsArray = this.getAssetArray();

    // why have a zip of just one asset right?
    if (isEditable || assetsArray.length <= 1) {
      return null;
    }

    const totalSize = filesize(
      assetsArray.reduce((a, v) => a + v.size, 0),
      { round: 1 },
    );

    return (
      <div className={`${baseClass}__header`}>
        <Button
          data-qa="gxLV4g0JTOXQDR8_oRMr1"
          modifiers={['inline-flex', 'link', 'calltoaction']}
          onClick={this.onClickDownloadAllAssets}
          style={{ width: 'auto' }}
        >
          <Addon>
            {this.state.assetsDownloading && <Loader size="tiny" />}
            {!this.state.assetsDownloading && <SvgIcon icon="download" />}
          </Addon>
          <span>
            Download All {assetsArray.length} Story Assets ({totalSize})
          </span>
        </Button>
      </div>
    );
  }

  render() {
    const { content, isEditable } = this.props;

    const { activeTab } = this.state;

    const { headline, logoSize, logoSrc, heroSrc } = content;

    const containerClasses = classNames(baseClass, 'default-template');

    const nav = this.renderNav();
    const header = this.renderHeader();
    const logo = this.renderComponent(
      { logoSize, logoSrc },
      isEditable,
      this.renderLogo,
    );
    const headlineText = this.renderComponent(
      headline,
      isEditable,
      this.renderHeadline,
    );
    const hero = this.renderComponent(heroSrc, isEditable, this.renderHero);

    const activeContent =
      activeTab === 'content'
        ? this.renderContentTab()
        : this.renderDownloadableAssets();

    return (
      <div className={containerClasses}>
        {hero}
        <ResponsiveContext.Consumer>
          {responsiveModifiers => {
            const boxClassName = `${baseClass}__box`;
            const responsiveClasses = responsiveModifiers
              ? responsiveModifiers.map(
                  modifier => `${boxClassName}--${modifier}`,
                )
              : [];
            const boxClasses = classNames(boxClassName, responsiveClasses, {
              [`${boxClassName}--has-hero`]: isEditable || !!heroSrc,
            });
            return (
              <div className={boxClasses}>
                <Paper>
                  {header}
                  {logo}
                  {headlineText}
                  {nav}
                  <div className={`${baseClass}__area`}>
                    {!isEditable && (
                      <ResponsiveContainer>{activeContent}</ResponsiveContainer>
                    )}
                    {isEditable && activeContent}
                  </div>
                </Paper>
              </div>
            );
          }}
        </ResponsiveContext.Consumer>
      </div>
    );
  }
}

DefaultTemplate.propTypes = {
  content: contentPropTypes.isRequired,
  dispatchMergeFormAction: PropTypes.func,
  dispatchSubmitFormAction: PropTypes.func,
  dispatchTouchFormAction: PropTypes.func,
  isEditable: PropTypes.bool,
  isStoryPressContactFormValid: PropTypes.bool,
  isStoryPressContactValid: PropTypes.bool,
  logoUploadPath: PropTypes.string,
  onClickDownloadAllAssets: PropTypes.func,
  isSaving: PropTypes.bool,
  hasDevFeatureFlag: PropTypes.func,
};

DefaultTemplate.defaultProps = {
  contentFormValues: {},
  isEditable: false,
  isSaving: false,
  logoUploadPath: '',
  dispatchMergeFormAction: () => {},
  dispatchSubmitFormAction: () => {},
  dispatchTouchFormAction: () => {},
  dispatchXorFormAction: () => {},
  isStoryPressContactFormValid: true,
  isStoryPressContactValid: true,
  onClickDownloadAllAssets: () => {},
  hasDevFeatureFlag: () => {},
};

export default connect(mapStateToProps)(DefaultTemplate);
