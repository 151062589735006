export default {
  modalTitle: {
    id: 'SHARE-SAVED-LIST-MODAL-TITLE',
    defaultMessage: 'Share Settings',
    description: 'Contacts Beta > Saved Lists: share list modal title',
  },
  shareList: {
    id: 'SHARE-SAVED-LIST-MODAL-SHARE-BUTTON_TEXT',
    defaultMessage: 'Share List',
    description:
      'Contacts Beta > Saved Lists: share list modal share button text',
  },
  permissionLabel: {
    id: 'SHARE-SAVED-LIST-MODAL_PERMISSIONS_LABEL',
    defaultMessage: 'Share Permissions',
    description: 'Contacts Beta > Saved Lists: selection label',
  },
};
