export enum AUDIENCE_TABS_LABELS {
  individual = 'audienceSectionIndDemoTabTitle',
  household = 'audienceSectionHouseDemoTabTitle',
  firmographics = 'audienceSectionFirmTabTitle',
}

export enum AUDIENCE_TABS {
  individual = 'individual',
  household = 'household',
  firmographics = 'firmographics',
}

export type AudienceTabs = keyof typeof AUDIENCE_TABS;

export enum CHART_TYPE {
  DONUT = 'DONUT',
  VERTICAL_BAR = 'VERTICAL_BAR',
  HORIZONTAL_BAR = 'HORIZONTAL_BAR',
}

export type ChartTypes = keyof typeof CHART_TYPE;

export type AudienceCategories = {
  [key: string]: {
    tab: AudienceTabs;
    chartType: ChartTypes;
    groupOrder: number;
  };
};

export type AudienceTabLabels = {
  [AUDIENCE_TABS.individual]: AUDIENCE_TABS_LABELS.individual;
  [AUDIENCE_TABS.household]: AUDIENCE_TABS_LABELS.household;
  [AUDIENCE_TABS.firmographics]: AUDIENCE_TABS_LABELS.firmographics;
};

export const INDIVIDUAL_TAB = 'individual';
export const HOUSEHOLD_TAB = 'household';
export const FIRMOGRAPHICS_TAB = 'firmographics';

export const AUDIENCE_TAB_LABELS: AudienceTabLabels = {
  [INDIVIDUAL_TAB]: AUDIENCE_TABS_LABELS.individual,
  [HOUSEHOLD_TAB]: AUDIENCE_TABS_LABELS.household,
  [FIRMOGRAPHICS_TAB]: AUDIENCE_TABS_LABELS.firmographics,
};

export const AUDIENCE_CATEGORIES: AudienceCategories = {
  Gender: {
    tab: INDIVIDUAL_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 1,
  },
  Age: {
    tab: INDIVIDUAL_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 2,
  },
  'Life Stage': {
    tab: INDIVIDUAL_TAB,
    chartType: CHART_TYPE.HORIZONTAL_BAR,
    groupOrder: 3,
  },
  'Marital Status': {
    tab: INDIVIDUAL_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 4,
  },
  Education: {
    tab: INDIVIDUAL_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 5,
  },
  Employment: {
    tab: INDIVIDUAL_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 6,
  },
  'Estimated Net Worth': {
    tab: INDIVIDUAL_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 7,
  },
  'Household Size': {
    tab: HOUSEHOLD_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 8,
  },
  'Gender of Children': {
    tab: HOUSEHOLD_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 10,
  },
  'Age of Children': {
    tab: HOUSEHOLD_TAB,
    chartType: CHART_TYPE.HORIZONTAL_BAR,
    groupOrder: 11,
  },
  'Number of Children': {
    tab: HOUSEHOLD_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 9,
  },
  'Family Position': {
    tab: HOUSEHOLD_TAB,
    chartType: CHART_TYPE.DONUT,
    groupOrder: 12,
  },
  'Household Income': {
    tab: HOUSEHOLD_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 13,
  },
  'Wealth Decile': {
    tab: HOUSEHOLD_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 14,
  },
  'Management Level': {
    tab: FIRMOGRAPHICS_TAB,
    chartType: CHART_TYPE.HORIZONTAL_BAR,
    groupOrder: 15,
  },
  'Decision Makers': {
    tab: FIRMOGRAPHICS_TAB,
    chartType: CHART_TYPE.HORIZONTAL_BAR,
    groupOrder: 18,
  },
  'Company Age': {
    tab: FIRMOGRAPHICS_TAB,
    chartType: CHART_TYPE.HORIZONTAL_BAR,
    groupOrder: 17,
  },
  'Company Size': {
    tab: FIRMOGRAPHICS_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 16,
  },
  'Company Sales': {
    tab: FIRMOGRAPHICS_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 19,
  },
  'Industry and Occupations': {
    tab: FIRMOGRAPHICS_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 20,
  },
  'Past Technology Purchases': {
    tab: FIRMOGRAPHICS_TAB,
    chartType: CHART_TYPE.VERTICAL_BAR,
    groupOrder: 21,
  },
};

export default {
  AUDIENCE_CATEGORIES,
  INDIVIDUAL_TAB,
  HOUSEHOLD_TAB,
  FIRMOGRAPHICS_TAB,
  AUDIENCE_TAB_LABELS,
};
