import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { createSelector } from 'reselect';

import { ExportListFormats } from 'components/influencers/saved-lists-table/export-list-modal/types';
import { isWidgetPoweredBySearch } from 'components/widgetV3/utils';
import {
  V3_METRIC_FIELDS,
  DATA_SOURCE_TYPES,
  WIDGET_TYPES,
} from 'constants/constants';
import {
  AnalyticItem,
  AnalyticItemObject,
} from 'pages/Analytics/analytics-config';
import {
  BulkSearchDetail,
  BulkSearchObj,
} from 'pages/Dashboard/DashboardsBulkSearchModal/Types';
import { AnalyticSlideLayout } from 'pages/Report/Slide/SlideLayout';
import { DATE_RANGE_KEYS } from 'utils/date/date-util';

export interface State {
  dashboard: DashboardState;
}

export interface DashboardState {
  dashboardHub: DashboardHub;
}

export interface DashboardHub {
  loading: boolean;
  error: string;
  dashboard: Dashboard | Record<string, any>;
  isDashboardsListsSideTrayOpen: boolean;
  widgetV3ById: Record<number, Widget>;
  widgetsToReload: number[];
  shouldWidgetUseBackendCache: boolean;
  // These should probably not go at the top level of this interface.
  //
  duplicatingSuccess: boolean;
  duplicateDashboardId: number;

  export: Export;
}

export interface Dashboard extends AnalyticItem {
  category: string;
  dateRange: Record<string, any>;
  lastViewed: string;
  layout: AnalyticSlideLayout[];
  ownerId: number;
  shared: boolean;
  username: string;
  searchesCount: number;
  deletedSearches: boolean;
}

export interface DashboardObject extends AnalyticItemObject {
  category: string;
  dateRange: Record<string, any>;
  lastViewed: string;
  layout: any[];
  ownerId: number;
  shared: boolean;
  username: string;
  searchesCount: number;
  deletedSearches: boolean | null;
}

interface TextWidgetContent {
  header: string;
  body: string;
}

export interface TextWidget {
  gridX: number;
  gridY: number;
  gridWidth: number;
  gridHeight: number;
  contentType: string;
  content?: TextWidgetContent;
}

export interface WidgetSlideTile {
  id?: number;
  type?: string;
  gridX: number;
  gridY: number;
  gridWidth: number;
  gridHeight: number;
  contentType: string;
  contentId: number;
}

export interface Widget {
  id: number;
  type?: string;
  gridX: number;
  gridY: number;
  gridWidth: number;
  gridHeight: number;
  contentType: string;
  contentId: number;
  metrics?: Metric[] | undefined;
  loadingChartData?: boolean | undefined;
  widgetError?: boolean | undefined;
  widgetErrorMessage?: string | undefined;
}

interface Metric {
  dataSources: DataSource[];
}

interface DataSource {
  metadata: MetaData;
  sourceType: string;
}

interface MetaData {
  sourceModel: SourceModel;
  type: string;
  dateCreated: string;
}

interface SourceModel {
  colorTag?: string;
  id: number;
  search: string;
  simple: boolean;
  title: string;
}

export interface Export {
  initiateExport: boolean;
  exportedWidgets: Widget[];
  csvExportType: ExportListFormats;
}

export const dashboardState = (state: State): DashboardState =>
  state.dashboard || {};

export const dashboardHubState = state => state?.dashboard?.dashboardHub || {};

export const widgetsV3State = createSelector(
  dashboardHubState,
  dashboardHub => dashboardHub.widgetV3ById || {},
);

export const isDashboardCreatedSelector = createSelector(
  dashboardHubState,
  dashboardHub => dashboardHub.isDashboardCreated,
);

export const errorCreatingDashboardSelector = createSelector(
  dashboardHubState,
  dashboardHub => dashboardHub.errorCreatingDashboard,
);

export const bulkSearchState = createSelector(
  dashboardHubState,
  hubState => hubState.bulkSearch,
);

export const dashboardSearchesSelector = createSelector(
  dashboardHubState,
  hubState => hubState.dashboardSearches,
);

export const isDashboardViewAndHasArchiveDeleteDashboardsFFSelector = createSelector(
  dashboardHubState,
  hubState => hubState.isDashboardViewAndHasArchiveDeleteDashboardsFF || false,
);

const mapWidgetToSearch = (widget): BulkSearchDetail[] =>
  widget.metrics?.reduce((acc, currentMetric) => {
    if (currentMetric.measure.field === V3_METRIC_FIELDS.impactSearch) {
      return acc;
    }
    const dataSources = currentMetric.dataSources;
    const filteredDataSources = dataSources.filter(
      dataSource =>
        dataSource.sourceType === DATA_SOURCE_TYPES.search ||
        dataSource.sourceType === DATA_SOURCE_TYPES.parentSearch,
    );

    return acc.concat(
      filteredDataSources.map(dataSource => {
        const sourceModel = dataSource.metadata?.sourceModel;
        return {
          searchName: sourceModel?.title,
          searchId: sourceModel?.id,
          widgetIds: new Set(),
          searchOwnerId: sourceModel?.searchOwnerId,
          searchOwnerEmail: sourceModel?.searchOwnerUsername,
          searchSharedStatus: sourceModel?.searchSharedStatus,
        };
      }),
    );
  }, []);

export const buildBulkSearchPayloadSelector = createSelector(
  bulkSearchState,
  dashboardHubState,
  (bulkSearch, dashboardHub) => {
    const widgets = dashboardHub.widgetV3ById;
    return dashboardHub?.dashboard?.layout?.reduce((acc, layout) => {
      const widgetId = layout.contentId;
      const widget = { ...widgets[widgetId] };
      if (!widget || widget.loadingChartData) {
        return acc;
      }
      let hasSearchesToUpdate = false;
      widget.metrics = widget?.metrics?.map(metric => {
        if (!metric || metric.measure.field === V3_METRIC_FIELDS.impactSearch) {
          return metric;
        }
        const safeMetric = { ...metric };
        safeMetric.dataSources = metric.dataSources.map(dataSource => {
          const { sourceType, sourceId } = dataSource;
          const isSearch =
            sourceType === DATA_SOURCE_TYPES.search ||
            sourceType === DATA_SOURCE_TYPES.parentSearch;
          if (!isSearch) {
            return dataSource;
          }
          if (bulkSearch.newSearches[sourceId]) {
            hasSearchesToUpdate = true;
            return {
              ...bulkSearch.newSearches[sourceId],
              sourceType: sourceType,
              metadata: {
                ...bulkSearch.newSearches[sourceId].metadata,
                type: sourceType,
              },
            };
          }
          return dataSource;
        });
        return safeMetric;
      });
      const { id, metrics, title } = widget;
      return hasSearchesToUpdate ? acc.concat({ id, metrics, title }) : acc;
    }, []);
  },
);

export const recentSearchesFromDashboardWidgetSelector = createSelector(
  dashboardHubState,
  (dashboardHub): any[] => {
    const widgetV3s: Widget[] = Object.values(dashboardHub.widgetV3ById);

    const dashboard: Dashboard = dashboardHub?.dashboard;
    if (!dashboard) {
      return [];
    }
    const layoutsIds: number[] = dashboard?.layout?.map(
      layout => layout.contentId,
    );
    const dashboardWidgets = widgetV3s.filter(
      widget => layoutsIds.indexOf(widget.id) !== -1,
    );
    const areWidgetsLoading = dashboardWidgets.some(
      widget => widget.loadingChartData,
    );

    if (areWidgetsLoading) {
      return [];
    }

    const searches: DataSource[] = [];
    dashboardWidgets.forEach(widget => {
      if (!widget.widgetError) {
        /* eslint-disable-next-line no-unused-expressions */
        widget.metrics?.forEach(metric => {
          metric.dataSources.forEach(ds => {
            if (
              ds.sourceType === 'SEARCH' ||
              ds.sourceType === 'PARENT_SEARCH'
            ) {
              searches.push(ds);
            }
          });
        });
      }
    });

    const uniqueSearches = uniqBy(searches, 'sourceId');
    const sortedSearches = sortBy(uniqueSearches, 'dateCreated').reverse();
    return sortedSearches
      .slice(0, 5)
      .map(search => search.metadata.sourceModel);
  },
);

export const searchDataFromDashboardWidgetSelector = createSelector(
  dashboardHubState,
  (dashboardHub): BulkSearchObj | undefined => {
    const widgetsV3 = dashboardHub.widgetV3ById;
    const layoutsIds: number[] = dashboardHub?.dashboard?.layout?.map(
      layout => layout.contentId,
    );
    const areWidgetsLoading = layoutsIds?.some(
      layoutId => !widgetsV3[layoutId] || widgetsV3[layoutId].loadingChartData,
    );

    if (areWidgetsLoading) {
      return undefined;
    }

    return layoutsIds?.reduce((acc: BulkSearchObj, layoutId: number) => {
      const searchDetails = mapWidgetToSearch(widgetsV3[layoutId]);
      if (!searchDetails || !searchDetails.length) return acc;

      return searchDetails.reduce((searchDetailsAcc, currentSearch) => {
        const searchExistsInAcc: BulkSearchDetail =
          searchDetailsAcc[currentSearch.searchId];
        if (searchExistsInAcc) {
          searchExistsInAcc.widgetIds.add(layoutId);
          return searchDetailsAcc;
        } else {
          currentSearch.widgetIds.add(layoutId);
          return {
            ...searchDetailsAcc,
            [currentSearch.searchId]: currentSearch,
          };
        }
      }, acc);
    }, {});
  },
);

export const saveBulkSearchEnabledSelector = createSelector(
  searchDataFromDashboardWidgetSelector,
  bulkSearchState,
  (searchWidgetObj, bulkSearchObj) => {
    if (!searchWidgetObj) {
      return false;
    }

    return Object.keys(searchWidgetObj).some(searchId => {
      return !!bulkSearchObj.newSearches[searchId];
    });
  },
);

export const bulkSearchUpdateInProgressSelector = createSelector(
  bulkSearchState,
  ({ updateInProgress }) => updateInProgress,
);

export const analyticTitleSelector = createSelector(
  dashboardState,
  (dashboard: DashboardState): Dashboard['title'] =>
    dashboard.dashboardHub.dashboard?.title || '',
);

export const analyticHubSelector = createSelector(
  dashboardHubState,
  dashboardHub => {
    if (!dashboardHub) return null;

    return dashboardHub.dashboard || {};
  },
);

export const dashboardDateRangeSelector = createSelector(
  analyticHubSelector,
  dashboard => ({
    type: dashboard.dateRangeType,
    startDate: dashboard.startDate && moment.utc(dashboard.startDate).valueOf(),
    endDate: dashboard.endDate && moment.utc(dashboard.endDate).valueOf(),
  }),
);

export const analyticLayoutSelector = createSelector(
  dashboardHubState,
  dashboardHub => {
    if (!dashboardHub) return [];
    else if (dashboardHub.dashboard && dashboardHub.dashboard.layout) {
      return dashboardHub.dashboard.layout;
    }
    return [];
  },
);

export const dashboardWidgetsById = createSelector(
  dashboardHubState,
  dashboardHub => {
    if (!dashboardHub) return {};
    else if (dashboardHub && dashboardHub.widgetsById) {
      return dashboardHub.widgetsById;
    }
    return {};
  },
);

export const dashboardWidgetsV3ById = createSelector(
  dashboardHubState,
  dashboardHub => dashboardHub?.widgetV3ById || {},
);

export const dashboardWidgetsV3Ids = createSelector(
  dashboardHubState,
  dashboardHub => dashboardHub?.dashboard?.layout?.map(l => l.contentId) || [],
);

export const dashboardExportWidgetsV3InstantiateExport = createSelector(
  dashboardHubState,
  dashboardHub => dashboardHub?.export?.initiateExport || false,
);

export const dashboardWidgetsInReadingOrder = createSelector(
  dashboardWidgetsV3ById,
  analyticLayoutSelector,
  (widgets, layout) =>
    layout
      .sort((a, b) =>
        a.gridY === b.gridY ? a.gridX - b.gridX : a.gridY - b.gridY,
      )
      .map(({ contentId }) => widgets[contentId] || {}),
);

export const dashboardWidgetsLoading = createSelector(
  dashboardHubState,
  dashboardHub => dashboardHub?.loading,
);

export const analyticErrorSelector = createSelector(
  dashboardHubState,
  dashboard => dashboard.error,
);

export const isDateRangeConsistentSelector = createSelector(
  dashboardDateRangeSelector,
  dashboardWidgetsInReadingOrder,
  (dashboardDateRange, widgets) => {
    if (Object.keys(widgets).length === 0) {
      return true;
    }

    if (!dashboardDateRange?.type) {
      return false;
    }

    return widgets.every(w => {
      const primaryMetric = w.metrics?.find(
        metric => !metric.isDateRangeCompare,
      );

      if (primaryMetric) {
        if (dashboardDateRange.type === DATE_RANGE_KEYS.CUSTOM) {
          return (
            primaryMetric.dateRangeType === DATE_RANGE_KEYS.CUSTOM &&
            moment
              .utc(primaryMetric.startDate)
              .isSame(dashboardDateRange.startDate) &&
            moment.utc(primaryMetric.endDate).isSame(dashboardDateRange.endDate)
          );
        } else {
          return primaryMetric.dateRangeType === dashboardDateRange.type;
        }
      }
      return false;
    });
  },
);

export const timeSeriesDateGrouping = createSelector(
  dashboardWidgetsV3ById,
  (dashboardV3Widgets: any[]) => {
    const granularityTypesById = Object.values(dashboardV3Widgets).map(
      widget => ({
        type: widget?.metrics?.[0]?.dimensions?.[0]?.granularity?.type,
        id: widget?.id,
      }),
    );

    return granularityTypesById.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.type }),
      {},
    );
  },
);

export const getWidgetErrorSelector = id =>
  createSelector(
    dashboardHubState,
    (dashboardHub: DashboardHub) =>
      dashboardHub.widgetV3ById[id]?.widgetError || false,
  );

export const getWidgetErrorMessageSelector = id =>
  createSelector(
    dashboardHubState,
    (dashboardHub: DashboardHub) =>
      dashboardHub.widgetV3ById[id]?.widgetErrorMessage || '',
  );

export const widgetsToReload = createSelector(
  dashboardHubState,
  (dashboardHub: DashboardHub) => dashboardHub.widgetsToReload,
);

export const isDashboardsListsSideTrayOpen = createSelector(
  dashboardHubState,
  (dashboardHub: DashboardHub) => dashboardHub.isDashboardsListsSideTrayOpen,
);

export const getWidgetsPoweredBySearch = searchId =>
  createSelector(analyticLayoutSelector, widgetsV3State, (layout, widgets) => {
    const dashboardTiles = layout?.filter(
      tile => tile.type === WIDGET_TYPES.widgetV3,
    );
    return dashboardTiles?.reduce((acc, tile: any) => {
      const widget = widgets[tile?.contentId];
      if (widget && isWidgetPoweredBySearch(widget.metrics, searchId)) {
        return [...acc, tile.contentId];
      }
      return acc;
    }, []);
  });

export const getDashboardWidgetsIds = () =>
  createSelector(analyticLayoutSelector, layout => {
    const dashboardTiles = layout?.filter(
      tile => tile.type === WIDGET_TYPES.widgetV3,
    );
    return dashboardTiles?.map(tile => tile?.contentId);
  });

export const shouldWidgetUseBackendCacheSelector = createSelector(
  dashboardHubState,
  (dashboardHub: DashboardHub) => dashboardHub.shouldWidgetUseBackendCache,
);

export const getAnalyticsSetToShare = ({
  analytics,
  isShared,
  idsToCheckForSharing,
}) =>
  analytics.map((i: Dashboard) => {
    return {
      ...i,
      shared: idsToCheckForSharing.includes(i.id) ? isShared : i.shared,
    };
  });
