import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { WysiwygDisplay } from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';

// i18n

import messages from './StoryCompanyBackgroundDisplay.messages';

const StoryCompanyBackgroundDisplay = ({ value, isEditable }) => {
  const intl = useIntl();
  const baseClass = 'tk-story-company-background__text';
  const classes = classNames(baseClass, {
    [`${baseClass}--placeholder`]: !value,
  });

  return (
    <StorySection>
      <StorySectionHeader label={intl.formatMessage(messages.title)} />
      <div className="tk-story-summary">
        <div className={classes}>
          {value && (
            <WysiwygDisplay content={value} disableLinks={isEditable} />
          )}
          {!value && isEditable && intl.formatMessage(messages.placeholder)}
        </div>
      </div>
    </StorySection>
  );
};

StoryCompanyBackgroundDisplay.propTypes = {
  value: PropTypes.string,
  isEditable: PropTypes.bool,
};

StoryCompanyBackgroundDisplay.defaultProps = {
  value: null,
  isEditable: PropTypes.bool,
};

export default StoryCompanyBackgroundDisplay;
