import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';

import { THREAD_STATUSES, THREAD_TYPES } from 'components/outreach/constants';
import { DEV_FEATURES } from 'constants/constants';

import { getOutreachDraftsActionCreator } from 'reducers/outreach/outreach-drafts';

import { getOutreachMessagesWithQueryActionCreator } from 'reducers/outreach/outreach-messages';
import {
  // getOutreachThreadsWithQuery,
  getOutreachThreadsWithQueryActionCreator, // Also updates corresponding threads in redux
} from 'reducers/outreach/outreach-threads';

import { getOutreachFilteredViewSelectorById } from 'selectors/outreach';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';

import { defaultProps as outreachFilteredViewDefaultProps } from 'types/outreach-filtered-view';

// import { accountSelector } from 'selectors/account';
import { getDatesForRange } from 'utils/date/date-util';

export const CLEAR_FILTERED_VIEW_TYPEAHEAD =
  'outreach/CLEAR_FILTERED_VIEW_TYPEAHEAD';
export const CREATE_FILTERED_VIEW = 'outreach/CREATE_FILTERED_VIEW';
export const GET_FILTERED_VIEW_TYPEAHEAD =
  'outreach/GET_FILTERED_VIEW_TYPEAHEAD';
export const GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS =
  'outreach/GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS';
export const SET_AGGREGATIONS_BY_ID = 'outreach/SET_AGGREGATIONS_BY_ID';
export const UPDATE_FILTERED_VIEW = 'outreach/UPDATE_FILTERED_VIEW';
export const UPDATE_FILTERED_VIEW_AGGREGATIONS_HIDDEN_FILTERS_ONLY =
  'outreach/UPDATE_FILTERED_VIEW_AGGREGATIONS_HIDDEN_FILTERS_ONLY';
export const UPDATE_FILTERED_VIEW_WITH_RESULTS =
  'outreach/UPDATE_FILTERED_VIEW_WITH_RESULTS';
export const SET_USERS_FILTERED_DATA = 'outreach/SET_USERS_FILTERED_DATA';
export const SET_MESSAGE_ID = 'outreach/SET_MESSAGE_ID';

/**
  Here's a reference for filtered view object propTypes from types/outreach-filtered-view.
  TODO: Remove this after dev
  {
    lockedFilters: PropTypes.shape(outreachFilterPropTypes),
    userFilters: PropTypes.shape(outreachFilterPropTypes),
    hiddenFilters: PropTypes.shape(outreachFilterPropTypes),
    pagination: PropTypes.shape(outreachPaginationPropTypes),
    sort: PropTypes.shape({
      field: PropTypes.oneOf(['Date']),
      order: PropTypes.oneOf(['Asc', 'Desc']),
    }),
    search: PropTypes.string,
    threadIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }
*/

const initialState = {
  filteredViewsById: {},
  aggregations: {},
  usersFilteredData: {},
};

const outreachFilteredViewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_FILTERED_VIEW: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...action.payload.filteredView,
            loading: true,
          },
        },
      };
    }
    case CLEAR_FILTERED_VIEW_TYPEAHEAD: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            loadingTypeaheadThreads: false,
            typeaheadThreadIds: [],
          },
        },
      };
    }
    case GET_FILTERED_VIEW_TYPEAHEAD: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            loadingTypeaheadThreads: true,
          },
        },
      };
    }
    case GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            loadingTypeaheadThreads: false,
            typeaheadThreadIds: [...action.payload.folderIds],
          },
        },
      };
    }
    case SET_AGGREGATIONS_BY_ID: {
      return {
        ...state,
        aggregations: {
          ...state.aggregations,
          [action.payload.aggregationId]: {
            ...action.payload.aggregations,
          },
        },
      };
    }
    case UPDATE_FILTERED_VIEW: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            ...action.payload.filteredView,
            aggregationsWithOnlyHiddenFilters:
              state.filteredViewsById[action.payload.id]
                .aggregationsWithOnlyHiddenFilters,
            loading: true,
          },
        },
      };
    }
    case UPDATE_FILTERED_VIEW_AGGREGATIONS_HIDDEN_FILTERS_ONLY: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            aggregationsWithOnlyHiddenFilters: action.payload.aggregations,
          },
        },
      };
    }
    case UPDATE_FILTERED_VIEW_WITH_RESULTS: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            ...action.payload.filteredView,
            aggregationsWithOnlyHiddenFilters:
              state.filteredViewsById[action.payload.id]
                .aggregationsWithOnlyHiddenFilters,
            loading: false,
          },
        },
      };
    }

    default:
      return state;
  }
};

/*
  Hidden filters have the last word over everything else.
  Locked filters come from navigation choices.
  User filters come from user interaction in a filter bar.
  There _shouldn't_ be any overlap between them, but just in case...
*/
const dates = {};

const getCombinedFiltersForQuery = filteredView => {
  if (
    filteredView.userFilters.dateRangeType !== 'ALL_TIME' &&
    filteredView.userFilters.dateRangeType !== 'CUSTOM'
  ) {
    const datesForRange = getDatesForRange(
      filteredView.userFilters.dateRangeType,
    );
    dates.recievedAfter = datesForRange.startDate;
    dates.receivedBefore = datesForRange.endDate;
  }

  const composeHiddenFilters = key => [
    ...filteredView.userFilters[key],
    ...filteredView.lockedFilters[key],
    ...filteredView.hiddenFilters[key],
  ];

  const filters = {
    ...filteredView.userFilters,
    ...dates,
    ...pickBy(filteredView.lockedFilters, f => f !== null && f !== undefined),
    ...pickBy(filteredView.hiddenFilters, f => f !== null && f !== undefined),
    authorLists: composeHiddenFilters('authorLists'),
    authors: composeHiddenFilters('authors'),
    campaigns: composeHiddenFilters('campaigns'),
    campaignIds: composeHiddenFilters('campaignIds'),
    emailTypes: filteredView.userFilters.emailTypes.length
      ? filteredView.userFilters.emailTypes
      : filteredView.lockedFilters.emailTypes.length
      ? filteredView.lockedFilters.emailTypes
      : composeHiddenFilters('emailTypes'),
    influencerLists: composeHiddenFilters('influencerLists'),
    influencers: composeHiddenFilters('influencers'),
    integrationUuids: composeHiddenFilters('integrationUuids'),
    requiredFields: composeHiddenFilters('requiredFields'),
    status: composeHiddenFilters('status'),
    stories: composeHiddenFilters('stories'),
    dateOption: filteredView.userFilters.dateOption,
  };

  delete filters.dateRangeType;
  return filters;
};

export const setAggregationsFromFiltersActionCreator = () => () => {};

export const setUsersFilteredData = filters => ({
  type: SET_USERS_FILTERED_DATA,
  payload: filters,
});

export const setMessageid = messageId => ({
  type: SET_MESSAGE_ID,
  payload: messageId,
});

// TODO: Turn back on as soon as outreach service can handle them again.
/*
export const setAggregationsFromFiltersActionCreator = ({ aggregationId, filters }) => async (dispatch, getState) => {
  const account = accountSelector(getState());

  const query = {
    pagination: {
      limit: 1,
      offset: 0,
    },
    filters,
    view: 'aggregations',
  };

  const { aggregations } = await getOutreachThreadsWithQuery({ account, query });

  dispatch({
    type: SET_AGGREGATIONS_BY_ID,
    payload: { aggregationId, aggregations },
  });
};
*/

export const updateFilteredViewActionCreator = ({
  id,
  nextFilteredViewProps = { ...outreachFilteredViewDefaultProps },
}) => async dispatch => {
  let filteredView = {
    ...nextFilteredViewProps,
  };

  // TODO: Figure out if we need a filtered view loading state before we get to the thread loading part
  dispatch({
    type: UPDATE_FILTERED_VIEW,
    payload: { id, filteredView },
  });

  const query = {
    pagination: {
      limit: filteredView.pagination.limit,
      offset: filteredView.pagination.offset,
    },
    sort: filteredView.sort,
    search: filteredView.search,
    filters: getCombinedFiltersForQuery(filteredView),
    view: 'results',
    draftsLimit: filteredView.draftsLimit,
  };

  /*
    Conditional logic to use v1 drafts query endpoint.
    TODO: Remove this if/when we combine it with the threads query endpoint.
  */
  const getEmailAnnouncementDrafts =
    query.filters.emailTypes.includes(THREAD_TYPES.EMAIL_ANNOUNCEMENT) &&
    query.filters.status.includes(THREAD_STATUSES.DRAFT);
  const getEmailAnnouncementScheduled =
    query.filters.emailTypes.includes(THREAD_TYPES.EMAIL_ANNOUNCEMENT) &&
    query.filters.status.includes(THREAD_STATUSES.SCHEDULED);
  // END of block to remove

  const getTrashMessages = query.filters.trash;
  let threadsResponse, aggregations;

  if (
    !userHasDevFeatureFlag(DEV_FEATURES.connectTrashFolder) ||
    !getTrashMessages
  ) {
    try {
      threadsResponse = await dispatch(
        getOutreachThreadsWithQueryActionCreator(
          { query },
          { getEmailAnnouncementDrafts, getEmailAnnouncementScheduled },
        ),
      );
    } catch (e) {
      return null;
    }

    const { threadIds } = threadsResponse;
    aggregations = threadsResponse.aggregations;

    filteredView = {
      ...filteredView,
      aggregations,
      threadIds,
    };
  }

  if (
    userHasDevFeatureFlag(DEV_FEATURES.connectTrashFolder) &&
    getTrashMessages
  ) {
    let trashMessagesReponse;
    try {
      trashMessagesReponse = await dispatch(
        getOutreachMessagesWithQueryActionCreator({ query }),
      );
    } catch (e) {
      return null;
    }
    aggregations = trashMessagesReponse.aggregations;
    const { messagesById, messageIds } = trashMessagesReponse;

    filteredView = {
      ...filteredView,
      aggregations,
      messagesById,
      messageIds,
    };
  }

  if (userHasDevFeatureFlag(DEV_FEATURES.jortsDrafts)) {
    let draftThreadsReponse;
    try {
      draftThreadsReponse = await dispatch(
        getOutreachDraftsActionCreator({ query }),
      );
    } catch (e) {
      return null;
    }

    const { draftAggregations, draftIds } = draftThreadsReponse;

    filteredView = {
      ...filteredView,
      draftAggregations,
      draftIds,
    };
  }

  // If hidden filters are the only non-default filters, then update.
  const hiddenFiltersOnly = getCombinedFiltersForQuery({
    ...outreachFilteredViewDefaultProps,
    hiddenFilters: { ...filteredView.hiddenFilters },
  });

  const updateAggregationsWithHiddenFilters =
    isEqual(query.filters, hiddenFiltersOnly) && !query.search;

  if (updateAggregationsWithHiddenFilters) {
    dispatch({
      type: UPDATE_FILTERED_VIEW_AGGREGATIONS_HIDDEN_FILTERS_ONLY,
      payload: { id, aggregations },
    });
  }

  // TODO: Add error state

  return dispatch({
    type: UPDATE_FILTERED_VIEW_WITH_RESULTS,
    payload: { id, filteredView },
  });
};

export const refreshFilteredViewActionCreator = id => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const filteredViewProps = getOutreachFilteredViewSelectorById(id)(state);

  if (!filteredViewProps) {
    return 'No such filtered view';
  }

  return dispatch(
    updateFilteredViewActionCreator({
      id,
      nextFilteredViewProps: filteredViewProps,
    }),
  );
};

export const getFilteredViewTypeaheadActionCreator = ({
  id,
  typeaheadFilteredViewProps,
}) => async dispatch => {
  dispatch({
    type: GET_FILTERED_VIEW_TYPEAHEAD,
    payload: { id },
  });
  let folderIds;

  const query = {
    pagination: typeaheadFilteredViewProps.pagination,
    sort: typeaheadFilteredViewProps.sort,
    search: typeaheadFilteredViewProps.search,
    filters: getCombinedFiltersForQuery(typeaheadFilteredViewProps),
    view: 'typeAhead',
  };

  const getTrashMessages = query.filters.trash;

  if (
    userHasDevFeatureFlag(DEV_FEATURES.connectTrashFolder) &&
    getTrashMessages
  ) {
    const { messageIds } = await dispatch(
      getOutreachMessagesWithQueryActionCreator(
        { query },
        { updateCurrentMessages: false },
      ),
    );
    folderIds = messageIds;
  } else {
    // TODO: When we add contacts, stop awaiting in favor of separate loading states
    const { threadIds } = await dispatch(
      getOutreachThreadsWithQueryActionCreator(
        { query },
        { updateCurrentThreads: false },
      ),
    );
    folderIds = threadIds;
  }

  // TODO: Add error states

  return dispatch({
    type: GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS,
    payload: { id, folderIds },
  });
};

export const clearFilteredViewTypeaheadActionCreator = ({ id }) => dispatch =>
  dispatch({
    type: CLEAR_FILTERED_VIEW_TYPEAHEAD,
    payload: { id },
  });

export const createFilteredViewActionCreator = ({
  id,
  nextFilteredViewProps,
}) => async dispatch => {
  const filteredView = {
    ...outreachFilteredViewDefaultProps,
    ...nextFilteredViewProps,
  };

  // TODO: Figure out if we need a filtered view loading state before we get to the thread loading part
  await dispatch({
    type: CREATE_FILTERED_VIEW,
    payload: { id, filteredView },
  });

  return dispatch(
    updateFilteredViewActionCreator({ id, nextFilteredViewProps }),
  );
};

export default outreachFilteredViewsReducer;
