import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

class Dropdown extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    modifiers: PropTypes.arrayOf(
      PropTypes.oneOf([
        'bottomLeft',
        'filterbar',
        'full-width',
        'inline-block',
        'seethrough',
        'small',
      ]),
    ),
    toggle: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    modifiers: [],
    className: '',
  };

  componentDidMount() {
    this.handleProps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.handleProps();
    }
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  handleProps() {
    if (this.props.isOpen) {
      this.addEvents();
    } else {
      this.removeEvents();
    }
  }

  addEvents() {
    document.addEventListener('click', this.handleDocumentClick, true);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  removeEvents() {
    document.removeEventListener('click', this.handleDocumentClick, true);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleDocumentClick = event => {
    if (this.dropdown) {
      if (
        this.dropdown.contains(event.target) &&
        this.dropdown !== event.target
      ) {
        return;
      }

      this.toggle();
    }
  };

  handleKeyUp = event => {
    if (this.dropdown && this.dropdown.contains(document.activeElement)) {
      if (event.key === 'Escape') {
        this.close();
      }
    }
  };

  toggle(event) {
    const { disabled, toggle } = this.props;

    if (disabled) {
      return event && event.preventDefault();
    }

    return toggle();
  }

  close() {
    const { isOpen, toggle } = this.props;

    if (isOpen) {
      return toggle();
    }

    return null;
  }

  render() {
    /*
      Toggle is included in the following spread operator in order to omit it
      from the `props` passed down to the top-level `div` element
    */
    const {
      children,
      isOpen,
      modifiers,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      toggle,
      className,
      ...props
    } = this.props;
    const baseClass = 'tk-dropdown';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseClass}--${modifier}`)
      : [];

    const mainClass = classnames(baseClass, prefixedModifiers, className, {
      [`${baseClass}--open`]: isOpen,
    });

    return (
      <div
        className={mainClass}
        ref={dropdown => {
          this.dropdown = dropdown;
        }}
        {...props}
      >
        {children}
      </div>
    );
  }
}

export default Dropdown;
