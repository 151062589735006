import messages from 'components/article/ArticleTagSelectorModal.messages';

import {
  BULK_ADD_TAGS_TO_ARTICLE_ENDPOINT,
  BULK_SET_TAGS_FOR_ARTICLES_ENDPOINT,
  ARTICLE_TAG_ENDPOINT,
  BULK_DELETE_TAGS_TO_ARTICLES_ENDPOINT,
} from 'constants/apis';
import globalMessageDescriptors from 'i18n/Global.messages';
import TagMessages from 'pages/Tag/Tag.messages';
import { removeTagsForArticles } from 'pages/WidgetDrilldown/drilldown-reducer';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { fetchUserTags } from 'reducers/user-tags';
import {
  performGet,
  performDelete,
  performPost,
  request,
} from 'services/rest-service/rest-service';
import {
  buildSelectedTags,
  filterForTagsToCreate,
  filterForTagsToAdd,
} from 'services/tag-service/tag-service';
import { formatTags, updateTagsOnArticleList } from 'utils/tag/tag-utils';

export const ADD_TAGS_TO_ARTICLES_BY_ID = 'article/ADD_TAGS_TO_ARTICLES_BY_ID';
export const GET_TAGS = 'tags/GET_TAGS';
export const GET_TAGS_RECEIVED = 'tags/GET_TAGS_RECEIVED';
export const GET_TAGS_ERROR = 'tags/GET_TAGS_ERROR';
export const DELETE_TAG = 'tags/DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'tags/DELETE_TAG_SUCCESS';
export const DELETE_TAG_ERROR = 'tags/DELETE_TAG_ERROR';
export const CREATE_TAG_SUCCESS = 'tags/CREATE_TAG_SUCCESS';
export const CLEAR_NEW_TAGS = 'tags/CLEAR_NEW_TAGS';
export const BULK_DELETE_TAG_ERROR = 'tags/BULK_DELETE_TAG_ERROR';

export const initialState = {
  loadingTags: true,
  deletingTag: false,
  current: null,
  error: false,
  tags: {},
  newTags: [],
  overrideArticleTags: false,
};

const tagListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TAGS_TO_ARTICLES_BY_ID: {
      const { articleIds, tags } = action.payload;
      const existingTags = state.tags;

      articleIds.forEach(articleId => {
        existingTags[articleId] = (existingTags[articleId] || []).concat(tags);
      });

      return {
        ...state,
        tags: existingTags,
      };
    }
    case GET_TAGS:
      return { ...state, loadingTags: true };
    case GET_TAGS_ERROR:
      return { ...state, error: action.payload, loadingTags: false };
    case GET_TAGS_RECEIVED:
      return {
        ...state,
        loadingTags: false,
        tags: {
          ...state.tags,
          [action.payload.articleId]: action.payload.tags,
        },
        current: action.payload.articleId,
      };
    case DELETE_TAG:
      return { ...state, deletingTag: true };
    case DELETE_TAG_ERROR:
      return { ...state, error: action.payload, deletingTag: false };
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        deletingTag: false,
        tags: action.payload,
      };
    case CREATE_TAG_SUCCESS: {
      const { newTags, overrideArticleTags } = action.payload;
      return {
        ...state,
        newTags: newTags,
        overrideArticleTags: overrideArticleTags,
      };
    }
    case CLEAR_NEW_TAGS: {
      return {
        ...state,
        newTags: [],
        overrideArticleTags: false,
      };
    }
    case BULK_DELETE_TAG_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export const addTagsToArticlesById = (tags, articleIds) => ({
  type: ADD_TAGS_TO_ARTICLES_BY_ID,
  payload: { articleIds, tags },
});

export const getTagsByArticleId = articleId => dispatch => {
  dispatch({ type: GET_TAGS });
  performGet(`${ARTICLE_TAG_ENDPOINT}/${articleId}/`)
    .then(response => {
      dispatch({
        type: GET_TAGS_RECEIVED,
        payload: { tags: response.data.tags, articleId },
      });
    })
    .catch(err => {
      dispatch({ type: GET_TAGS_ERROR, payload: err });
    });
};

export const deleteTagById = id => dispatch => {
  dispatch({ type: DELETE_TAG });
  performDelete(`${ARTICLE_TAG_ENDPOINT}/${id}/`)
    .then(response => {
      dispatch({ type: DELETE_TAG_SUCCESS, payload: response.data.tags });
    })
    .catch(err => dispatch({ type: DELETE_TAG_ERROR, payload: err }));
};

export const createTags = (
  existingTags,
  query,
  selectedTags,
  tags,
  articleIds,
  intl,
  asyncStrategy,
) => dispatch => {
  existingTags = existingTags || [];
  const { endpoint, overrideArticleTags } = existingTags.length
    ? {
        endpoint: BULK_SET_TAGS_FOR_ARTICLES_ENDPOINT,
        overrideArticleTags: true,
      }
    : {
        endpoint: BULK_ADD_TAGS_TO_ARTICLE_ENDPOINT,
        overrideArticleTags: false,
      };
  const existingTagIds = formatTags(tags.userTags)
    .concat(formatTags(tags.sharedTags))
    .map(tag => tag.id);

  const userSelectedTags = buildSelectedTags(query, selectedTags);

  const payload = {
    newTags: filterForTagsToCreate(existingTagIds, userSelectedTags),
    tagIds: filterForTagsToAdd(existingTagIds, userSelectedTags),
    selectedArticles: articleIds,
  };
  const params = {
    asyncStrategy: asyncStrategy,
  };

  request({
    url: endpoint,
    data: payload,
    params,
    method: 'post',
    convertResponseNumbersAsStrings: true,
  })
    .then(response => {
      const { appliedTags, tags, proccessingAsAsync } = response.data;

      const affectedTagList = tags || appliedTags;

      if (proccessingAsAsync) {
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(TagMessages.addBulkTagInProgress),
            isLoading: true,
          }),
        );
        return;
      }

      // Fetch the tags again in case any new tags were created
      dispatch(fetchUserTags());

      if (affectedTagList.length > 0) {
        updateTagsOnArticleList(
          intl,
          dispatch,
          affectedTagList,
          articleIds,
          existingTags,
          overrideArticleTags,
        );
      }
    })
    .catch(() => {
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: intl.formatMessage(globalMessageDescriptors.error),
          text: intl.formatMessage(messages.tagArticlesErrorMessage),
          status: 'danger',
        }),
      );

      dispatch({
        type: CLEAR_NEW_TAGS,
      });
    });
};

export const deleteTags = (tags, articleIds, intl) => dispatch => {
  if (tags && tags.length > 0 && articleIds && articleIds.length > 0) {
    const payload = {
      articlesIds: articleIds,
      tagsIds: tags.map(tag => tag.id),
    };
    performPost(BULK_DELETE_TAGS_TO_ARTICLES_ENDPOINT, payload)
      .then(response => {
        if (response.status === 200 && response.data) {
          const { data } = response;
          const { data: resultData } = data;
          const getTagsNoSuccessResult = Object.values(resultData)
            .map(value => value.filter(tag => !tag.success))
            .filter(result => result.length > 0);
          if (getTagsNoSuccessResult.length === 0) {
            dispatch(
              addPageMessageWithDefaultTimeout({
                title: intl.formatMessage(globalMessageDescriptors.success),
                text: intl.formatMessage(messages.bulkUnTagArticlesSuccess),
                status: 'success',
              }),
            );
          } else {
            dispatch(
              addPageMessageWithDefaultTimeout({
                title: intl.formatMessage(globalMessageDescriptors.error),
                text: intl.formatMessage(messages.bulkUnTagArticlesError),
                status: 'danger',
              }),
            );
          }

          const getSuccessTagsToRemove = [
            ...new Set(
              Object.values(resultData)
                .map(val =>
                  val.map(tag => tag).filter(result => result.success),
                )
                .flat()
                .map(tag => tag.tagId),
            ),
          ];
          dispatch(removeTagsForArticles(articleIds, getSuccessTagsToRemove));
        }
      })
      .catch(e => {
        dispatch({
          type: BULK_DELETE_TAG_ERROR,
          payload: { error: e.response },
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: intl.formatMessage(globalMessageDescriptors.error),
            text: intl.formatMessage(messages.bulkUnTagArticlesError),
            status: 'danger',
          }),
        );
      });
  }
};

export default tagListReducer;
