import { CONTACT_TWITCH_HISTORY_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

import { getPrimaryProfileForNetwork } from '../social-utils';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const twitchReducerFactory = namespace => {
  const GET_TWITCH_VIDEOS_START = `ihub/${namespace}/GET_TWITCH_VIDEOS_START`;
  const GET_TWITCH_VIDEOS_SUCCESS = `ihub/${namespace}/GET_TWITCH_VIDEOS_SUCCESS`;
  const GET_TWITCH_VIDEOS_ERROR = `ihub/${namespace}/GET_TWITCH_VIDEOS_ERROR`;

  const getRecentTwitchVideosForInfluencer = influencer => async dispatch => {
    dispatch({ type: GET_TWITCH_VIDEOS_START });
    let payload = [];
    const twitchProfile = getPrimaryProfileForNetwork(
      influencer.socialProfiles,
      'twitch',
    );
    if (twitchProfile && twitchProfile.id) {
      let result;
      try {
        result = await performGet(
          `${CONTACT_TWITCH_HISTORY_ENDPOINT}?userId=${twitchProfile.id}`,
        );
      } catch (e) {
        dispatch({ type: GET_TWITCH_VIDEOS_ERROR, payload: e });
      }
      payload = result.data.data.data;
    }
    dispatch({ type: GET_TWITCH_VIDEOS_SUCCESS, payload });
  };

  const twitchReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_TWITCH_VIDEOS_START:
        return {
          ...state,
          loading: true,
          error: false,
          data: [],
        };

      case GET_TWITCH_VIDEOS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: [...action.payload],
        };

      case GET_TWITCH_VIDEOS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  return {
    GET_TWITCH_VIDEOS_START,
    GET_TWITCH_VIDEOS_SUCCESS,
    GET_TWITCH_VIDEOS_ERROR,
    getRecentTwitchVideosForInfluencer,
    twitchReducer,
  };
};

export default twitchReducerFactory;
