const regionalUrls = {
  brandwatchUpsell: {
    ca: {
      en:
        'https://www.cision.ca/brandwatch/?utm_medium=product&utm_source=c3popup&utm_content=demo&utm_campaign=brandwatch-launch-ngc3',
      fr:
        'https://www.cision.ca/fr/brandwatch/?utm_medium=product&utm_source=c3popup&utm_content=demo&utm_campaign=brandwatch-launch-ngc3',
    },
  },
};

const getRegionalUrl = ({ page, region = 'us', language = 'en' }) =>
  regionalUrls[page]?.[region.toLowerCase()]?.[language.toLowerCase()] ||
  undefined;

export default getRegionalUrl;
