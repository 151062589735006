export default {
  sendMeTestEmail: {
    id: 'EMAIL_FOOTER_GROUP_SEND_ME_TEST_EMAIL',
    defaultMessage: 'Send me a test email',
    description:
      'Text to be displayed on the email footer group > send me a test email button',
  },
  discardChanges: {
    id: 'EMAIL_FOOTER_GROUP_DISCARD_CHANGES',
    defaultMessage: 'Discard Changes',
    description:
      'Text to be displayed on the email footer group > discard changes button',
  },
  sendMessageLater: {
    id: 'EMAIL_FOOTER_GROUP_SEND_MESSAGE_LATER',
    defaultMessage: 'Send this message later',
    description:
      'Text to be displayed on the email footer group > save this message later button',
  },
  messageWillNotBeSendWithBatch: {
    id: 'EMAIL_FOOTER_GROUP_MESSAGE_WILL_NOT_BE_SEND_WITH_BATCH',
    defaultMessage: 'This message will not be sent with this batch',
    description:
      'Text to be displayed on the email footer group indicating that the message will not be sent on this batch',
  },
  unskip: {
    id: 'EMAIL_FOOTER_GROUP_UNSKIP',
    defaultMessage: 'Unskip',
    description:
      'Text to be displayed on the email footer group > unskip button',
  },
};
