import { combineReducers } from 'redux';
import reportHubReducer from './report-hub-reducer';
import reportWrapperReducer from './report-wrapper-reducer';

const reportReducer = combineReducers({
  reportHub: reportHubReducer,
  reportWrapper: reportWrapperReducer,
});

export default reportReducer;
