import React, { Component } from 'react';

import { Icon } from '@cision/rover-ui';
import classNames from 'classnames';
import TranslatedMessage from 'i18n/TranslatedMessage';
import pullAt from 'lodash/pullAt';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';

import ComponentType from '../../component-type';
import withModifiers from '../../withModifiers';
import ListControlDefaultItem from '../list-control-default-item';

import messages from './ListControl.messages';

const safeGT = (len, max) => {
  return typeof max === 'number' && len > max;
};

const safeGTE = (len, max) => {
  return typeof max === 'number' && len >= max;
};

class ListControl extends Component {
  static baseClass = 'tk-form-control';

  state = {
    focus: this.props.isFocused,
    visiblePlaceholder: true,
    resetListSize: false,
  };

  componentDidMount = () => {
    const { isFocused } = this.props;

    document.addEventListener('keyup', this.handleKeyUp, false);

    if (isFocused) {
      this.safeFocusInput();
    }
  };

  componentDidUpdate = prevProps => {
    const { isFocused, verifiedEmailAddress, values } = this.props;

    const unsetVerifiedEmailAddressState =
      this.props.unsetVerifiedEmailAddressState ?? undefined;

    if (isFocused && !prevProps.isFocused) {
      this.safeFocusInput();
    }
    if (verifiedEmailAddress.length > 0 && unsetVerifiedEmailAddressState) {
      if (values.length > 0) {
        this.handleClearEmailValues();
      }
      this.setInputValue(verifiedEmailAddress);
      this.processInputValue({ isUserTyping: false });
      unsetVerifiedEmailAddressState();
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('keyup', this.handleKeyUp, false);
  };

  setResetListSize = () => {
    const {
      emailNotCompleteDetails = { emailNotComplete: false, email: '' },
    } = this.props;
    const { emailNotComplete } = emailNotCompleteDetails;
    emailNotComplete
      ? this.setState({ resetListSize: false })
      : this.setState({ resetListSize: true });
  };

  setInputValue = nextInputValue => {
    if (!this.Input.current) {
      return;
    }

    this.setState({ visiblePlaceholder: !nextInputValue });
    this.Input.current.value = nextInputValue;
  };

  Input = React.createRef();

  handleFocusInput = event => {
    const { onFocus } = this.props;
    this.setState({ focus: true });
    onFocus(event);
  };

  handleBlurControl = event => {
    const { onBlur } = this.props;
    this.setState({ focus: false });
    onBlur(event);
  };

  handleBlurInput = () => {
    this.setResetListSize();
    this.processInputValue({ isUserTyping: false });
  };

  handleClickOutside = event => {
    this.setResetListSize();

    if (this.state.focus) {
      this.handleBlurControl(event);
    }
  };

  handleKeyUp = event => {
    this.setResetListSize();

    if (!this.Input.current) {
      return;
    }

    const keyCodes = {
      Escape: 27,
      Enter: 13,
      Tab: 9,
    };

    const keys =
      event.key !== undefined
        ? keyName => keyName
        : keyName => keyCodes[keyName];

    const eventKey = event.key || event.keyCode;

    if (!this.state.focus && eventKey !== keys('Tab')) {
      return;
    }

    switch (eventKey) {
      case keys('Escape'): {
        this.Input.current.blur();
        this.handleBlurControl();
        break;
      }
      case keys('Enter'):
      case keys('Tab'): {
        this.processInputValue({ isUserTyping: false });
        break;
      }
      default: {
        break;
      }
    }
  };

  handleChangeInput = () => {
    this.processInputValue({ isUserTyping: true });
  };

  handleRemoveListItem = listItemId => {
    const {
      onChange,
      values,
      field,
      fromValues,
      setFromWithSenderName,
    } = this.props;

    const nextValues = values.filter(value => value.id !== listItemId);

    this.setResetListSize();

    if (field === 'senderName' && fromValues.length > 0) {
      const fromEmail = fromValues[0].email;
      const newFrom = [
        {
          email: fromEmail,
          name: '',
        },
      ];

      setFromWithSenderName(newFrom);
    }

    if (values.length !== nextValues.length) {
      return onChange(nextValues);
    }

    return null;
  };

  handleClearEmailValues = () => {
    const { values } = this.props;
    values.length = 0;
  };

  defaultParseListItem = listItemString => {
    if (!listItemString) {
      return null;
    }

    return {
      id: listItemString,
      label: listItemString,
    };
  };

  handleClearInput = () => {
    this.setInputValue('');
    const { handleClearEmail = () => {} } = this.props;
    handleClearEmail();
    this.setState({ resetListSize: true });
  };

  getMaxLength = () => {
    const { field } = this.props;
    const MAX_LENGTH = 50;

    if (field !== 'senderName') {
      return null;
    }

    return MAX_LENGTH;
  };

  processInputValue = ({ isUserTyping }) => {
    if (!this.Input.current) {
      return;
    }

    const {
      customParseListItem,
      onChange,
      onChangeInputText,
      values: prevValues,
      field,
      fromValues,
      setFromWithSenderName,
      senderNameValues,
    } = this.props;
    const itemDelimiter = ',';
    const prevInputValue = this.Input.current.value;
    const parseListItem = customParseListItem || this.defaultParseListItem;
    const parsedInputValueIndices = [];
    const splitInputValue = prevInputValue.split(itemDelimiter);
    const parsedValues = splitInputValue.reduce((result, valueString, i) => {
      const trimmedValueString = valueString.trim();
      // Don't try to parse empty strings ('bob, , sam,')

      if (!trimmedValueString) {
        this.setState({ resetListSize: true });
        return result;
      }

      // Don't try the last item if the user is still typing
      if (isUserTyping && i === splitInputValue.length - 1) {
        return result;
      }
      const parsedInputValue = parseListItem(trimmedValueString);

      if (!parsedInputValue) {
        this.setState({ resetListSize: false });
        return result;
      }

      parsedInputValueIndices.push(i); // Parsed values will be removed from in-progress string

      result.push(parsedInputValue);
      this.setState({ resetListSize: true });

      return result;
    }, []);

    // Only add unique new values
    const nextValues = uniqBy(
      [...prevValues, ...parsedValues],
      valueObject => valueObject.id,
    );

    if (field === 'from' && senderNameValues.length > 0) {
      const senderName = senderNameValues[0].email;
      nextValues[0].data.name = senderName;
    }

    // Only fire onChange if values have changed
    if (nextValues.length !== prevValues.length) {
      onChange(nextValues);
      if (field === 'senderName' && fromValues.length > 0) {
        const fromEmail = fromValues[0].email;
        const senderName = nextValues[0].id;
        const newFrom = [
          {
            email: fromEmail,
            name: senderName,
          },
        ];

        setFromWithSenderName(newFrom);
      }
    }

    // Set remaining (unparseable) user input string as the new input text
    pullAt(splitInputValue, parsedInputValueIndices); // Mutates splitInputValue
    const nextInputValue = splitInputValue.join(itemDelimiter);

    if (prevInputValue !== nextInputValue) {
      onChangeInputText(nextInputValue);
      this.setInputValue(nextInputValue);
    }
  };

  safeFocusInput = () => {
    if (
      this.Input &&
      this.Input.current &&
      this.Input.current !== document.activeElement
    ) {
      this.Input.current.focus();
    }
  };

  renderListItems = () => {
    const { disabled, CustomListItemComponent, min, values } = this.props;
    const ListControlItem = CustomListItemComponent || ListControlDefaultItem;

    const listItems = values.map(itemValue => {
      const listControlItemProps = {
        onRemoveListItem: this.handleRemoveListItem,
        removable: !disabled && values.length > min,
        value: itemValue,
      };

      return (
        <span
          className={`${ListControl.baseClass}__item`}
          key={`li-${itemValue.id}`}
        >
          <ListControlItem {...listControlItemProps} />
        </span>
      );
    });

    return listItems;
  };

  renderCustomAddListItem = () => {
    const { CustomAddListItemComponent, disabled, max, values } = this.props;

    if (!CustomAddListItemComponent) {
      return null;
    }

    const adderClass = classNames(`${ListControl.baseClass}__adder`, {
      [`${ListControl.baseClass}__adder--disabled`]:
        disabled || safeGT(values.length, max),
    });

    return (
      <span className={adderClass}>
        <CustomAddListItemComponent {...disabled} />
      </span>
    );
  };

  render() {
    const {
      autoComplete,
      className,
      disabled,
      inputTextDisabled,
      max,
      min,
      placeholder,
      style,
      values,
      field,
      handleDropdownToggle,
    } = this.props;

    const { focus } = this.state;

    const isFrom = field === 'from';

    const onClickFunctionality = () => {
      if (isFrom) {
        handleDropdownToggle();
      } else {
        this.safeFocusInput();
      }
    };

    const {
      emailNotCompleteDetails = { emailNotComplete: false, email: '' },
    } = this.props;
    const { emailNotComplete, email } = emailNotCompleteDetails;

    const mainClass = classNames(
      ListControl.baseClass,
      `${ListControl.baseClass}--list`,
      className,
      {
        [`${ListControl.baseClass}--focus`]: focus,
        [`${ListControl.baseClass}--error`]:
          values.length < min || safeGT(values.length, max),
        [`${ListControl.baseClass}--disabled`]: disabled,
      },
    );

    const inputClass = classNames(`${ListControl.baseClass}__input`, {
      [`${ListControl.baseClass}__input--hidden`]: safeGTE(values.length, max),
    });

    const inputSizeProps = {
      disabled,
    };

    const inputClassName = emailNotComplete
      ? 'tk-form-control--input-pill'
      : inputClass;

    if (email.length > 0 && !this.state.resetListSize) {
      inputSizeProps.size = email.length + 1;
    } else if (this.state.visiblePlaceholder || this.state.resetListSize) {
      /*
        This purely sets a default visible width for the input,
        to stop flex from compressing it when there's no content.
      */
      inputSizeProps.size = placeholder.length;
    }

    return (
      <div
        data-qa="QIvZS4yf_e7iddbwWglwk"
        className={mainClass}
        onClick={onClickFunctionality}
        onKeyDown={onClickFunctionality}
        role="button"
        style={style}
        tabIndex="-1"
      >
        <div data-qa="XLarc6ojVg9ATSrRKd5cU" role="button">
          {isFrom && values.length === 0 && (
            <p className={`${ListControl.baseClass}__from-placeholder`}>
              <TranslatedMessage {...messages.fromPlaceholderMessageSender} />
            </p>
          )}
          {this.renderListItems()}
          {this.renderCustomAddListItem()}
        </div>
        {!inputTextDisabled && (
          <div
            className={emailNotComplete ? 'tk-form-control__pill--error' : ''}
          >
            {isFrom ? (
              <div ref={this.Input} />
            ) : (
              <input
                {...inputSizeProps}
                autoComplete={autoComplete ? 'on' : 'off'}
                className={inputClassName}
                onBlur={this.handleBlurInput}
                onChange={this.handleChangeInput}
                onFocus={this.handleFocusInput}
                placeholder={placeholder}
                ref={this.Input}
                disabled={emailNotComplete}
                type="text"
                maxLength={this.getMaxLength()}
              />
            )}
            {emailNotComplete ? (
              <Icon
                data-qa="aD5Wk3_XcmcLcpe1QaEPH"
                name="clear"
                type="submit"
                onClick={this.handleClearInput}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

ListControl.propTypes = {
  autoComplete: PropTypes.bool,

  /** Additional class/es to add to the control */
  className: PropTypes.string,
  /**
    If you'll add structured data for a list (like address /
    name pairs), put in a button to add them.
  */
  CustomAddListItemComponent: ComponentType,
  /** For custom display of items in the list */
  CustomListItemComponent: ComponentType,
  /**
    Process each item (between delimiters) and return
    an object with a `.valid` boolean and a `.result`
    object that represents the parsed value
  */
  customParseListItem: PropTypes.func,
  disabled: PropTypes.bool,
  /** Set if you don't want users to be able to type their own entries */
  inputTextDisabled: PropTypes.bool,
  /** Force focus state */
  isFocused: PropTypes.bool,
  /** Max number of items in the list */
  max: PropTypes.number,
  /** Min number of items in the list */
  min: PropTypes.number,
  onBlur: PropTypes.func,
  /** The top-level onChange only fires when a list item is added / removed */
  onChange: PropTypes.func,
  /** The input-level onChange fires whenever the (parsed) text input's value changes */
  onChangeInputText: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.node,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  style: PropTypes.object,
  emailNotCompleteDetails: PropTypes.shape({
    emailNotComplete: PropTypes.bool,
    email: PropTypes.string,
  }),
  handleClearEmail: PropTypes.func.isRequired,
  verifiedEmailAddress: PropTypes.string,
  unsetVerifiedEmailAddressState: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.undefined,
  ]),
  field: PropTypes.string,
  handleDropdownToggle: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.undefined,
  ]),
  fromValues: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  setFromWithSenderName: PropTypes.func,
  senderNameValues: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
};

ListControl.defaultProps = {
  autoComplete: true,
  className: '',
  CustomAddListItemComponent: null,
  CustomListItemComponent: null,
  customParseListItem: null,
  disabled: false,
  inputTextDisabled: false,
  isFocused: false,
  max: null,
  min: 0,
  onBlur: () => {},
  onChange: () => {},
  onChangeInputText: () => {},
  onFocus: () => {},
  placeholder: 'Add items separated by commas',
  style: {},
  field: '',
  values: [],
  fromValues: [],
  setFromWithSenderName: () => {},
  senderNameValues: [],
};

export default withModifiers(enhanceWithClickOutside(ListControl));
