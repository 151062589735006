import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import moment from 'moment';

import columnMessages from 'components/widget/column.messages.js';
import {
  WIDGET_V3_SOURCE,
  WIDGET_V3_SOURCE_TYPES,
  TIME_SERIES_GRANULARITY_TYPES,
  DATE_RANGE_OPTIONS_WITH_UNDEFINED_DAYS,
  GRANULARITY_TYPES,
} from 'components/widget/constants';
import {
  checkForMetricMeasure,
  checkForMetricSingleDimension,
  checkForMetricSocialDimensions,
} from 'components/widgetWizard/constants';
import formMessages from 'components/widgetWizard/steps/form.messages';
import {
  isValidV3SearchType,
  isHighestReadership,
} from 'components/widgetWizard/utils';
import {
  BASE_SENTIMENT_COLORS,
  BASE_SEO_IMPACT_COLORS,
  getColorArrayFromCount,
  LEFT_AXIS_COLOR,
  RIGHT_AXIS_COLOR,
} from 'constants/charts';
import {
  CHART_TYPE,
  DATA_SOURCES,
  VISUALIZATION_TYPES,
  DATA_SOURCE_TYPES,
  V3_MEASURE_OPERATIONS,
  V3_METRIC_FIELDS,
  V3_METRIC_LABELS,
  WIDGET_DIMENSION_FIELDS,
  WIDGET_TYPE,
  WIDGET_ANALYTICS_INTEGRATION_TYPES,
} from 'constants/constants';
import globalMessages from 'i18n/Global.messages';

import { DASHBOARD_GRID_LAYOUT } from 'pages/Dashboard/DashboardTable/constants';
import {
  buildDateRangeFromPayload,
  buildWidgetV3DrilldownPath,
} from 'pages/Dashboard/DashboardTable/utils';
import {
  getTimestampsForAnyRange,
  DATE_RANGE_KEYS,
  DATE_RANGES,
  buildDateRangeCompareTimestamps,
} from 'utils/date/date-util';

export const DATE_RANGE_COMPARE_KEY_PREFIX = 'COMPARE-';
const NO_DATA_COLOR = ['#CCCFD2'];
const BAR_CHART_METRICS_WITHOUT_DRILLDOWN = [
  'AD_EQUIVALENCY',
  'AGGREGATE_READERSHIP',
];
const SIZE_TO_HIDE_SCROLLBAR = ['small', 'medium', 'large'];
const MAX_NUMBER_METRICS_TO_HIDE_SCROLLBAR = 5;
export const TAG_ERROR_RESPONSE = 'Missing tag detected. Tag: ';
export const UNSUPPORTED_FILTER_ERROR_RESPONSE = 'unsupported filters applied';
//export const ARTICLE_MENTION = 'ARTICLE_MENTION';
export const ANALYTICS_INTEGRATION_METRICS_WITH_DRILLDOWN = [
  'ARTICLE_MENTION',
  'UNIQUE_PR_ARTICLES',
];

export const WIDTH_BREAKPOINT_LARGE = 1680;
export const WIDTH_BREAKPOINT_WIDE = 2000;

// DIMENSION FUNCTIONS
export const isSentiment = metric =>
  checkForMetricSingleDimension(metric, WIDGET_DIMENSION_FIELDS.sentiment);

export const isTag = metrics =>
  checkForMetricMeasure((metrics || [])[0], V3_METRIC_FIELDS.userTags);

export const isImpactSearch = metrics =>
  checkForMetricMeasure((metrics || [])[0], V3_METRIC_FIELDS.impactSearch);

export const isSocialSearch = metrics =>
  checkForMetricMeasure((metrics || [])[0], V3_METRIC_FIELDS.totalPosts);

export const isStateProvince = metric =>
  checkForMetricSingleDimension(metric, WIDGET_DIMENSION_FIELDS.state);

export const isCity = metric =>
  checkForMetricSingleDimension(metric, WIDGET_DIMENSION_FIELDS.city);

export const isPrTraffic = metrics =>
  metrics.some(m => m.measure.field === V3_METRIC_FIELDS.uniquePrArticles) &&
  metrics.some(m => m.measure.field === V3_METRIC_FIELDS.prTraffic);

export const isTotalMentionsVsTotalSessions = metrics =>
  metrics &&
  metrics.length === 2 &&
  metrics.some(m => m.measure.field === V3_METRIC_FIELDS.articleMention) &&
  metrics.some(m => m.measure.field === V3_METRIC_FIELDS.sessions);

export const isAdEquivalency = metrics =>
  metrics &&
  metrics.length === 1 &&
  metrics.some(m => m.measure.field === V3_METRIC_FIELDS.adEquivalency);

export const isSocialAmplification = metrics =>
  metrics && metrics.length === 1 && checkForMetricSocialDimensions(metrics[0]);

export const isDomainAuthority = metric =>
  checkForMetricSingleDimension(
    metric,
    WIDGET_DIMENSION_FIELDS.domainAuthority,
  );

export const isCountry = metric =>
  checkForMetricSingleDimension(metric, WIDGET_DIMENSION_FIELDS.country);

export const isSingleDataSource = data => {
  return data?.length === 1 && data?.[0]?.data?.length === 1;
};

export const isSingleDataSourceWithDateRangeCompare = data => {
  return (
    data?.length === 2 &&
    data?.[0]?.data?.length === 1 &&
    isDateRangeCompare(data)
  );
};

export const isImpactTotalConversionValuesPresent = metrics => {
  return metrics?.[0]?.data?.[0]?.data.length === 0 || false;
};

// DATE RANGE FUNCTIONS
//both data and metrics are being passed to this function (not ideal) AND we
// use isDateRangeCompare within metrics, but dateRangeCompare within data (not ideal)
export const isDateRangeCompare = data => {
  return (
    data?.some(x => x.isDateRangeCompare) || data?.some(x => x.dateRangeCompare)
  );
};

// DISPLAY FUNCTIONS
export const getWidgetChartMetricsDetailsStyle = (
  data,
  gridWidth,
  gridHeight,
  widgetType,
  isReportV3Slide,
  legendPosition,
  legendSizeRatio,
) => {
  const hasSingleGridDisplay =
    (isSingleDataSource(data) ||
      isSingleDataSourceWithDateRangeCompare(data)) &&
    gridWidth === 1 &&
    gridHeight === 1 &&
    widgetType !== WIDGET_TYPE.sentiment;

  const percent = 100 / Math.max(gridWidth, gridHeight);
  const style = {
    order: hasSingleGridDisplay ? -1 : 0,
    height: '100%',
  };
  if (hasSingleGridDisplay) {
    style.width = `${percent}%`;
  }
  if (isReportV3Slide) {
    style.height = '';
    if (legendPosition === 'LEFT' || legendPosition === 'TOP') {
      style.order = -1;
    }
    if (legendPosition === 'LEFT' || legendPosition === 'RIGHT') {
      switch (legendSizeRatio) {
        case 0.5:
          style.width = '50%';
          break;
        case 0.33:
          style.width = '33%';
          break;
        case 0.25:
          style.width = '25%';
          break;
      }
    }
    if (legendPosition === 'TOP' || legendPosition === 'BOTTOM') {
      switch (legendSizeRatio) {
        case 0.5:
          style.height = '50%';
          break;
        case 0.33:
          style.height = '33%';
          break;
        case 0.25:
          style.height = '25%';
          break;
      }
    }
  }
  return style;
};

export const setWidgetChartSlideStyle = (
  chartStyle,
  legendPosition,
  legendSizeRatio,
) => {
  if (legendPosition === 'TOP' || legendPosition === 'BOTTOM') {
    chartStyle.overflowY = 'scroll';

    switch (legendSizeRatio) {
      case 0.5:
        chartStyle.height = '50%';
        break;
      case 0.33:
        chartStyle.height = '67%';
        break;
      case 0.25:
        chartStyle.height = '75%';
        break;
    }
  }

  return chartStyle;
};

export const getWidgetChartMetricsHandlerScrollbar = (tableData, size) => {
  return (
    tableData?.length <= MAX_NUMBER_METRICS_TO_HIDE_SCROLLBAR &&
    SIZE_TO_HIDE_SCROLLBAR.includes(size)
  );
};

export const isTimeSeriesChart = granularityType => {
  return TIME_SERIES_GRANULARITY_TYPES.indexOf(granularityType) > -1;
};

export const isPieChart = visualizationTypes => {
  return (
    visualizationTypes?.every(type => type === VISUALIZATION_TYPES.pie) ?? false
  );
};

export const hasAnaltyicIntegrationDataSource = data => {
  const dataSources = data?.map(d => d.dataSources).flat() || [];
  return dataSources.some(
    ds => ds.sourceType === DATA_SOURCE_TYPES.analyticsIntegration,
  );
};

export const getAnalyticIntegrationDataSource = data => {
  const dataSources = data.map(d => d.dataSources).flat() || [];
  const selectedAnalyticId = dataSources.find(
    ds => ds.sourceType === DATA_SOURCE_TYPES.analyticsIntegration,
  )?.sourceId;
  return selectedAnalyticId;
};

export const sortBySourcesId = data => {
  if (!data) {
    return data;
  }
  return sortBy(data, d => d.dataSource.sourceId);
};

export const sortDataByDataSources = chartData => {
  if (!chartData) {
    return chartData;
  }
  return chartData.map(data => {
    data.data = sortBySourcesId(data.data);
    return data;
  });
};

export const getColors = ({ data, widgetType, chartType }) => {
  if (!data || !data.length) {
    return NO_DATA_COLOR;
  }
  const colors = [];
  const granularityType = data?.[0]?.dimensions?.[0]?.granularity?.type;
  const dataLength = data?.[0]?.data?.length || 0;
  const baseColors = getColorArrayFromCount(dataLength);
  if (isTimeSeriesChart(granularityType)) {
    let baseColorIndex = 0;
    data[0].data.forEach((d, index) => {
      if (d.dataSource?.metadata?.sourceModel?.colorTag) {
        colors.push(d.dataSource.metadata.sourceModel.colorTag);
      } else {
        colors.push(baseColors[baseColorIndex]);
        baseColorIndex += 1;
      }
    });
  } else if (chartType === CHART_TYPE.pie) {
    if (widgetType === WIDGET_TYPE.international) {
      return getColorArrayFromCount(data?.[0]?.data?.[0]?.data?.length);
    } else if (widgetType === WIDGET_TYPE.sentiment) {
      return BASE_SENTIMENT_COLORS;
    } else if (widgetType === WIDGET_TYPE.domainAuthority) {
      return BASE_SEO_IMPACT_COLORS;
    } else {
      // Data is not returned in a consistent sorted order for data and data compare
      // Sort by data source id since it should be unique for a pie chart
      let baseColorIndex = 0;
      data[0].data.forEach(({ dataSource, data }) => {
        if (dataSource?.metadata?.sourceModel?.colorTag) {
          colors.push(dataSource.metadata.sourceModel.colorTag);
        } else {
          colors.push(baseColors[baseColorIndex]);
          baseColorIndex += 1;
        }
      });
    }
  }
  return colors;
};

export const assignMultiMetricColorsAndYAxis = ({
  areaKey,
  lineKey,
  verticalBarKey,
}) => {
  let areaColor;
  let areaYAxisId;
  let lineColor;
  let lineYAxisId;
  let verticalBarColor;
  let verticalBarYAxisId;

  if (areaKey) {
    areaYAxisId = 'left';
    areaColor = LEFT_AXIS_COLOR;
    if (lineKey) {
      lineYAxisId = 'right';
      lineColor = RIGHT_AXIS_COLOR;
    } else {
      verticalBarYAxisId = 'right';
      verticalBarColor = RIGHT_AXIS_COLOR;
    }
  } else if (lineKey) {
    lineYAxisId = 'left';
    lineColor = LEFT_AXIS_COLOR;
    verticalBarYAxisId = 'right';
    verticalBarColor = RIGHT_AXIS_COLOR;
  }

  return {
    areaColor,
    areaYAxisId,
    lineColor,
    lineYAxisId,
    verticalBarColor,
    verticalBarYAxisId,
  };
};

const shouldCountryChangeToPie = (data, size) => {
  return data && size && isCountry(data[0]) && size === 'small';
};

function getUniqueMetrics(metrics) {
  const measureFields = uniq(metrics?.map(m => m.measure?.field));
  const measureLabels = uniq(metrics?.map(m => m.measure?.label));
  const dimensionFields = uniq(
    metrics?.map(m => m.dimensions?.map(d => d.field)).flat(),
  );

  return {
    measureFields,
    measureLabels,
    dimensionFields,
  };
}

export const getVisualizationTypes = (data, size, previewData) => {
  if (!data && !previewData) return null;
  const visualizationTypes = (data || previewData).map(
    x => x.visualizationType,
  );
  if (shouldCountryChangeToPie(data, size)) {
    return [VISUALIZATION_TYPES.pie];
  }
  return visualizationTypes;
};

export const isTrendingConversions = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, [V3_METRIC_FIELDS.impactSearch]) &&
    isEqual(measureLabels, [V3_METRIC_LABELS.conversions]) &&
    isEqual(dimensionFields, [WIDGET_DIMENSION_FIELDS.publishDate])
  );
};

export const isImpactSnapshot = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, [V3_METRIC_FIELDS.impactSearch]) &&
    isEqual(measureLabels, [V3_METRIC_LABELS.impactSnapshot]) &&
    isEqual(dimensionFields, [WIDGET_DIMENSION_FIELDS.publishDate])
  );
};

export const isTopJournalists = metrics => {
  const visualizationTypes = metrics.map(m => m.visualizationType);
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.impactTopJournalistList
  );
};

export const isCountryWithSmallSize = (size, widgetType) => {
  return widgetType === WIDGET_TYPE.international && size === 'small';
};

export const isEarnedMediaAttribution = metrics => {
  const measure = metrics.map(m => m.measure);
  return measure[0]?.label === V3_METRIC_LABELS.earnedMediaAttribution;
};

export const isParentOrChildSearchDataSource = sourceType =>
  sourceType === DATA_SOURCE_TYPES.parentSearch ||
  sourceType === DATA_SOURCE_TYPES.childSearch;

export const isSearchDataSource = sourceType =>
  sourceType === DATA_SOURCE_TYPES.search;

export const isSocialSearchDataSource = sourceType =>
  sourceType === DATA_SOURCE_TYPES.socialSearch;

export const isTagDataSource = sourceType =>
  sourceType === DATA_SOURCE_TYPES.tag;

export const isKeyMessageWidget = data =>
  data?.[0]?.measure?.label === 'SUB_SEARCH';

export const getMetricFieldFromKeyMessageWidget = data => {
  if (data?.length !== 1) {
    return null;
  }

  const foundField = [
    V3_METRIC_FIELDS.totalReadership,
    V3_METRIC_FIELDS.mobileReadership,
    V3_METRIC_FIELDS.desktopReadership,
  ].find(field => field === data?.[0]?.measure?.field);

  return foundField || null;
};

export const isMobileReaderShipWidget = data =>
  data?.[0]?.measure?.label === 'MOBILE_READERSHIP';

export const isImpactSearchDataSource = sourceType =>
  sourceType === DATA_SOURCE_TYPES.impactSearch;

export const isAreaChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.area
  );
};

export const isAggregateReadership = data =>
  data?.[0]?.measure?.field === 'AGGREGATE_READERSHIP';

export const isReadershipMeasureFieldsAndLabels = (
  measureFields,
  measureLabels,
) => {
  return (
    (isEqual(measureFields, [V3_METRIC_FIELDS.mobileReadership]) &&
      isEqual(measureLabels, ['MOBILE_READERSHIP'])) ||
    (isEqual(measureFields, [V3_METRIC_FIELDS.desktopReadership]) &&
      isEqual(measureLabels, ['DESKTOP_READERSHIP'])) ||
    (isEqual(measureFields, [V3_METRIC_FIELDS.totalReadership]) &&
      isEqual(measureLabels, ['TOTAL_READERSHIP']))
  );
};

export const isVerifiedViews = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, [V3_METRIC_FIELDS.impactSearch]) &&
    isEqual(measureLabels, [V3_METRIC_LABELS.views]) &&
    isEqual(dimensionFields, [WIDGET_DIMENSION_FIELDS.publishDate])
  );
};

export const isVerifiedUniqueViewers = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, [V3_METRIC_FIELDS.impactSearch]) &&
    isEqual(measureLabels, [V3_METRIC_LABELS.dailyUniqueViews]) &&
    isEqual(dimensionFields, [WIDGET_DIMENSION_FIELDS.publishDate])
  );
};

export const isTotalMentionsOverTime = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, [V3_METRIC_FIELDS.articleMention]) &&
    isEqual(measureLabels, ['MENTIONS']) &&
    isEqual(dimensionFields, [WIDGET_DIMENSION_FIELDS.publishDate])
  );
};

export const isWordCloud = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, [V3_METRIC_FIELDS.word]) &&
    isEqual(measureLabels, [V3_METRIC_LABELS.word]) &&
    isEqual(dimensionFields, [])
  );
};

export const isTopPublishers = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, [V3_METRIC_FIELDS.impactScore]) &&
    isEqual(measureLabels, [V3_METRIC_LABELS.impactScore]) &&
    isEqual(dimensionFields, [WIDGET_DIMENSION_FIELDS.publisherUrl])
  );
};

export const isReadershipOverTime = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isReadershipMeasureFieldsAndLabels(measureFields, measureLabels) &&
    isEqual(dimensionFields, [WIDGET_DIMENSION_FIELDS.publishDate])
  );
};

export const isShareOfVoice = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isEqual(measureFields, ['ARTICLE_MENTION']) &&
    isEqual(measureLabels, ['MENTIONS']) &&
    isEqual(dimensionFields, [])
  );
};

export const isReadershipShareOfVoice = metrics => {
  const { measureFields, measureLabels, dimensionFields } = getUniqueMetrics(
    metrics,
  );
  return (
    isReadershipMeasureFieldsAndLabels(measureFields, measureLabels) &&
    isEqual(dimensionFields, [])
  );
};

export const isArticleList = metrics => {
  const visualizationTypes = metrics.map(m => m.visualizationType);
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.articleList
  );
};

export const isTotalPostsMeasure = data =>
  data?.[0]?.measure?.field === V3_METRIC_FIELDS.totalPosts;

export const isAve = data => data?.[0]?.measure?.field === 'AD_EQUIVALENCY';

export const availableToDrilldown = data => {
  return (
    !BAR_CHART_METRICS_WITHOUT_DRILLDOWN.includes(data?.[0]?.measure?.field) &&
    !hasAnaltyicIntegrationDataSource(data)
  );
};

export const getChartTypeFromMetrics = metrics => {
  const visualizationTypes = metrics?.map(m => m.visualizationType);
  if (!visualizationTypes?.length) {
    return CHART_TYPE.unknown;
  } else if (isAreaChart(visualizationTypes)) {
    return CHART_TYPE.area;
  } else if (isLineChart(visualizationTypes)) {
    return CHART_TYPE.line;
  } else if (
    isLineAndAreaChart(visualizationTypes) &&
    isDateRangeCompare(metrics)
  ) {
    return CHART_TYPE.areaDateRangeCompare;
  } else if (isPieChart(visualizationTypes)) {
    return CHART_TYPE.pie;
  } else if (isMapChart(visualizationTypes)) {
    return CHART_TYPE.worldMap;
  } else if (isHorizontalBarChart(visualizationTypes)) {
    return CHART_TYPE.horizontalBar;
    // check definition of this function
  } else if (isMultiVisualizationTypesOverTime(visualizationTypes, metrics)) {
    return CHART_TYPE.multiVisOverTime;
  } else if (isWordCloudChart(visualizationTypes)) {
    return CHART_TYPE.wordCloud;
  } else {
    return CHART_TYPE.unknown;
  }
};

export const transformWordCloudData = data => {
  data = data.filter(entry => {
    // Check if entry is not null and entry[4] exists and is a string
    if (entry && entry[4] && typeof entry[4] === 'string') {
      return entry[4].split(' ').length - 1 < 10;
    }

    // Exclude entries that don't meet the criteria
    return false;
  }); // 10 words and you are out...

  const [min, average] = getMinAndAverage(data);
  if (typeof min === 'undefined') {
    return data;
  }
  const max = Math.max(...data.map(el => el[3]));
  const normalization = 100 / max;

  const transformedData = data.map(entry => {
    const mapData = {
      text: entry[4].replace(/^["”“](.+(?=["”“]$))["”“]$/, '$1'),
      value: entry[3] * normalization,
      count: entry[3],
      avg: average,
    };

    return transformWordCloudItem(mapData, min, average);
  });
  return sortWordCloudData(transformedData);
};

const transformWordCloudItem = (item, min, average) => {
  let customSize = +2;
  const count = item.count;
  const minDiff = Math.abs(count - min);
  const averageDiff = Math.abs(count - average);
  if (minDiff < average) customSize = -2;
  else if (averageDiff < average) customSize = 0;
  return { ...item, customSize };
};

const getMinAndAverage = data => {
  const [sum, min] = data.reduce(
    ([total, min], next) => {
      const newTotal = total + next[3];
      const newMin = next[3] < min ? next[3] : min;
      return [newTotal, newMin];
    },
    [0, data[0]],
  );
  const average = sum / data.length;

  return [min, average];
};

const sortWordCloudData = data => {
  return data.sort((a, b) => a.count - b.count);
};

export const getWidgetTypeFromMetrics = metrics => {
  if (!metrics?.length) {
    return WIDGET_TYPE.unknown;
  } else if (isTotalMentionsOverTime(metrics)) {
    return WIDGET_TYPE.totalMentionsOverTime;
  } else if (isShareOfVoice(metrics)) {
    return WIDGET_TYPE.shareOfVoice;
  } else if (isSocialAmplification(metrics)) {
    return WIDGET_TYPE.socialAmplification;
  } else if (isKeyMessageWidget(metrics)) {
    return WIDGET_TYPE.keyMessages;
  } else if (isSentiment(metrics[0])) {
    return WIDGET_TYPE.sentiment;
  } else if (isArticleList(metrics)) {
    return WIDGET_TYPE.articleList;
  } else if (isDomainAuthority(metrics[0])) {
    return WIDGET_TYPE.domainAuthority;
  } else if (isCountry(metrics[0])) {
    return WIDGET_TYPE.international;
  } else if (isStateProvince(metrics[0])) {
    return WIDGET_TYPE.stateProvince;
  } else if (isCity(metrics[0])) {
    return WIDGET_TYPE.city;
  } else if (isPrTraffic(metrics)) {
    return WIDGET_TYPE.prReferralTraffic;
  } else if (isTotalMentionsVsTotalSessions(metrics)) {
    return WIDGET_TYPE.totalMentionsVsTotalSessions;
  } else if (isHighestReadership(metrics[0])) {
    return WIDGET_TYPE.highestReaderShip;
  } else if (isAggregateReadership(metrics)) {
    return WIDGET_TYPE.aggregateReaderShip;
  } else if (isAve(metrics)) {
    return WIDGET_TYPE.adEquivalency;
  } else if (isVerifiedViews(metrics)) {
    return WIDGET_TYPE.verifiedViews;
  } else if (isVerifiedUniqueViewers(metrics)) {
    return WIDGET_TYPE.verifiedUniqueViewers;
  } else if (isTrendingConversions(metrics)) {
    return WIDGET_TYPE.impactTrendingConversions;
  } else if (isTopPublishers(metrics)) {
    return WIDGET_TYPE.topPublishers;
  } else if (isTopJournalists(metrics)) {
    return WIDGET_TYPE.topJournalists;
  } else if (isSocialSearch(metrics)) {
    return WIDGET_TYPE.socialSearch;
  } else if (isImpactSnapshot(metrics)) {
    return WIDGET_TYPE.impactSnapshot;
  } else if (isEarnedMediaAttribution(metrics)) {
    return WIDGET_TYPE.earnedMediaAttribution;
  } else if (isWordCloud(metrics)) {
    return WIDGET_TYPE.wordCloud;
  } else {
    return WIDGET_TYPE.unknown;
  }
};

const getUniqueMeasuresFields = metrics =>
  uniq(metrics?.map(m => m.measure?.field));

export const isTotalPosts = metrics => {
  const measureFields = getUniqueMeasuresFields(metrics);
  return isEqual(measureFields, [V3_METRIC_FIELDS.totalPosts]);
};

export const isLineChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.line
  );
};

export const isLineAndAreaChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 2 &&
    visualizationTypes.indexOf(VISUALIZATION_TYPES.area) > -1 &&
    visualizationTypes.indexOf(VISUALIZATION_TYPES.line) > -1
  );
};

export const isHorizontalBarChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.horizontalBar
  );
};

export const isWordCloudChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.wordCloud
  );
};

export const isMultiVisualizationTypesOverTime = (
  visualizationTypes,
  metrics,
) => {
  const granularityType = metrics?.[0]?.dimensions?.[0]?.granularity?.type;
  return (
    visualizationTypes?.length > 1 &&
    !isDateRangeCompare(metrics) &&
    isTimeSeriesChart(granularityType)
  );
};

export const isImpactTopJournalistListChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.impactTopJournalistList
  );
};

export const isImpactSnapshotChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.impactSnapshot
  );
};

export const isArticleTable = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.table
  );
};

export const isMapChart = visualizationTypes => {
  return (
    visualizationTypes?.length === 1 &&
    visualizationTypes[0] === VISUALIZATION_TYPES.map
  );
};

export const getSearchTypeDataSources = data => {
  return data
    ?.map(element =>
      element.dataSources
        .filter(
          source =>
            (source.sourceType === DATA_SOURCE_TYPES.search &&
              source.source === DATA_SOURCES.trendkiteElasticSearch) ||
            (source.sourceType === DATA_SOURCE_TYPES.socialSearch &&
              source.source === DATA_SOURCES.trendkiteSocial) ||
            (source.sourceType === DATA_SOURCE_TYPES.impactSearch &&
              source.source === DATA_SOURCES.cidImpact) ||
            (source.source === DATA_SOURCES.cidImpact &&
              source.sourceType === DATA_SOURCE_TYPES.search),
        )
        .map(source => source),
    )
    .flat();
};

export const isUniqueSearchForAllMetrics = dataMetrics => {
  let isUnique = dataMetrics?.length > 0;
  (dataMetrics || []).forEach(element => {
    if (element.sourceId !== dataMetrics[0].sourceId) {
      isUnique = false;
    }
  });
  return isUnique;
};

export const isSingleSearch = data => {
  const searchIds = data
    ?.filter(d => isSingleDataSource([d]))
    .map(d => d.dataSources[0]?.metadata?.sourceModel?.id);
  const uniqueIds = new Set(searchIds);
  return uniqueIds.size === 1;
};

export const getWidgetMetaData = (
  widgetType,
  searchTypeDataSources,
  chartData,
  searchId,
) => {
  const widgetSourceModelData = {
    id: null,
    title: null,
    simple: null,
  };
  let widgetSourceType =
    chartData?.[0]?.dataSources?.[0]?.sourceType === undefined
      ? null
      : chartData?.[0]?.dataSources?.[0]?.sourceType;
  if (isUniqueSearchForAllMetrics(searchTypeDataSources)) {
    if (!isSocialSearchDataSource(widgetSourceType)) {
      const { id, title, simple } =
        searchTypeDataSources?.[0]?.metadata.sourceModel ?? {};
      widgetSourceModelData.id = id;
      widgetSourceModelData.title = title;
      widgetSourceModelData.simple = simple;
    } else {
      /* The type of the social search will be needed in the future
         to redirect users to the correct social search editor
      */
      const { id, title } =
        searchTypeDataSources?.[0]?.metadata.sourceModel ?? {};
      widgetSourceModelData.id = id;
      widgetSourceModelData.title = title;
    }

    widgetSourceType = searchTypeDataSources?.[0]?.metadata?.type;
  } else if (widgetType === WIDGET_TYPE.keyMessages && chartData?.length) {
    const parentDataSource = chartData?.[0]?.dataSources?.find(
      ({ sourceType }) => sourceType === DATA_SOURCE_TYPES.parentSearch,
    );
    const { id, title, simple } = parentDataSource?.metadata?.sourceModel || {};
    widgetSourceModelData.id = id === undefined ? null : id;
    widgetSourceModelData.title = title === undefined ? null : title;
    widgetSourceModelData.simple = simple === undefined ? null : simple;
    widgetSourceType = parentDataSource?.metadata?.type;
  } else if (isTagDataSource(widgetSourceType)) {
    const { id, tag } = chartData?.[0]?.dataSources?.[0]?.metadata.sourceModel;
    widgetSourceModelData.id = id;
    widgetSourceModelData.title = tag;
  } else if (searchId) {
    const searchTypeDataSourcesByIdSearch = searchTypeDataSources?.find(
      searchType => searchType.sourceId === searchId,
    );
    const { id, title, simple } =
      searchTypeDataSourcesByIdSearch?.metadata.sourceModel ?? {};
    widgetSourceModelData.id = id;
    widgetSourceModelData.title = title;
    widgetSourceModelData.simple = simple;
  }
  return { widgetSourceModelData, widgetSourceType };
};

export const getDateRangeFromChartData = chartData => {
  if (!chartData) {
    return null;
  }
  const { dateRange } =
    chartData.find(e => !!e.dateRange && !e.dateRangeCompare) || {};

  if (!dateRange) {
    return {};
  } else {
    return {
      endDate: dateRange.endDate
        ? moment.utc(dateRange.endDate).valueOf()
        : null,
      startDate: dateRange.startDate
        ? moment.utc(dateRange.startDate).valueOf()
        : null,
      type: dateRange?.dateRangeType,
    };
  }
};

export const getDateRange = metrics => {
  if (!metrics) {
    return null;
  }
  const metric = metrics.find(metric => !metric.isDateRangeCompare);
  if (!metric) {
    return {};
  } else {
    return {
      endDate: metric.endDate ? moment.utc(metric.endDate).valueOf() : null,
      startDate: metric.startDate
        ? moment.utc(metric.startDate).valueOf()
        : null,
      type: metric?.dateRangeType,
    };
  }
};

export const getAnalyticsIntegrations = metrics => {
  if (!metrics) {
    return null;
  }
  const analitycDataSource = metrics.map(metric =>
    metric.dataSources.find(
      dt => dt.sourceType === DATA_SOURCE_TYPES.analyticsIntegration,
    ),
  )[0];
  const analitycsIntegrationSource = analitycDataSource?.metadata?.sourceModel;
  return {
    analyticsId: analitycDataSource?.sourceId,
    analyticsForeignId:
      analitycsIntegrationSource?.analyticsIntegrationForeignId,
    analyticsIntegrationType:
      WIDGET_ANALYTICS_INTEGRATION_TYPES[
        analitycsIntegrationSource?.analyticsIntegrationType.id
      ],
    isEnabled: true,
  };
};

export const getDateRangeDropdownConfig = ({
  layoutBreakpoint,
  gridX,
  gridY,
  gridHeight,
  isReportView,
}) => {
  const options = {
    placement: 'auto',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['bottom-start', 'bottom-end', 'bottom'],
        },
      },
    ],
  };
  if (
    layoutBreakpoint !== DASHBOARD_GRID_LAYOUT.breakpoints.lg ||
    isReportView ||
    gridX === undefined ||
    gridY === undefined
  ) {
    return options;
  }

  // Widget in the last column for lg layout
  const isWidgetInLastColumn = gridX + 1 === DASHBOARD_GRID_LAYOUT.columns.lg;
  // Widget in small size within the first grid row
  if (gridY === 0 && gridHeight <= 1) {
    if (isWidgetInLastColumn) {
      options.placement = 'bottom-end';
      options.modifiers[0].options = { fallbackPlacements: ['bottom-end'] };
    }
  } else if (isWidgetInLastColumn) {
    options.placement = 'bottom-end';
    options.modifiers[0].options = {
      fallbackPlacements: ['bottom-end', 'top'],
    };
  } else {
    options.placement = 'bottom-start';
    delete options.modifiers;
  }
  return options;
};

export const getDateRangeCompareType = data => {
  if (!data) {
    return null;
  }
  const metric = data.find(metric => metric.isDateRangeCompare);
  return metric ? metric.dateRangeCompareType : '';
};

export const getMetricDetailHeaderLabel = (intl, data) => {
  if (data.length < 1) {
    return 'undefined';
  }
  const labels = uniq(data.map(d => d.measure.label));
  if (labels.length > 1) {
    return intl.formatMessage(columnMessages.columnLeftMultiMetric);
  }
  switch (labels[0]) {
    case 'SENTIMENT':
      return intl.formatMessage(columnMessages.columnLeftSentiment);
    case 'PUBLICATION':
      return intl.formatMessage(columnMessages.columnLeftPublication);
    case 'SUB_SEARCH':
      return intl.formatMessage(columnMessages.columnLeftKeyMessage);
    case 'SOCIAL_PLATFORM':
      return intl.formatMessage(columnMessages.columnLeftSocialPlatform);
    case 'AGGREGATE_READERSHIP':
    case 'AD_EQUIVALENCY':
      return intl.formatMessage(columnMessages.columnLeftAggregateReadership);
    case 'SEO_IMPACT_VALUE_BUCKET':
      return intl.formatMessage(columnMessages.columnLeftSEOImpact);
    case 'EARNED_MEDIA_ATTRIBUTION':
      return intl.formatMessage(globalMessages.metric);
    case 'MENTIONS': {
      const dimension = uniq(
        data.map(d => (d.dimensions.length > 0 ? d.dimensions[0] : [])),
      )[0];
      const field = dimension?.field;
      if (field === WIDGET_DIMENSION_FIELDS.state) {
        return intl.formatMessage(columnMessages.columnLeftState);
      } else if (field === WIDGET_DIMENSION_FIELDS.province) {
        return intl.formatMessage(columnMessages.columnLeftProvince);
      } else if (field === WIDGET_DIMENSION_FIELDS.city) {
        return intl.formatMessage(columnMessages.columnLeftCity);
      } else if (field === WIDGET_DIMENSION_FIELDS.country) {
        return intl.formatMessage(columnMessages.columnLeftInternational);
      }
      return intl.formatMessage(columnMessages.columnLeftDefault);
    }
    default:
      return intl.formatMessage(columnMessages.columnLeftDefault);
  }
};

export const getMultiMetricDetailRowLabel = (intl, measureField) => {
  switch (measureField) {
    case V3_METRIC_FIELDS.sessions:
      return intl.formatMessage(columnMessages.columnTotalSessionsLong);
    case V3_METRIC_FIELDS.articleMention:
      return intl.formatMessage(columnMessages.columnArticleMentionLong);
    case V3_METRIC_FIELDS.mobileReadership:
      return intl.formatMessage(columnMessages.columnMobileReadershipLong);
    case V3_METRIC_FIELDS.desktopReadership:
      return intl.formatMessage(columnMessages.columnDesktopReadershipLong);
    case V3_METRIC_FIELDS.totalReadership:
      return intl.formatMessage(columnMessages.columnTotalReadershipLong);
    case V3_METRIC_FIELDS.uniquePrArticles:
      return intl.formatMessage(columnMessages.columnUniquePrArticlesLong);
    case V3_METRIC_FIELDS.prTraffic:
      return intl.formatMessage(formMessages.prTrafficDefaultTitle);
  }
};

export const getSocialSearchLabel = intl => {
  return intl.formatMessage(globalMessages.tweets);
};

export const getMeasureLabel = data => {
  const labels = uniq(data.map(x => x.measure.label));
  if (labels.length > 1) {
    return null;
  }
  return labels[0];
};

export const getMetricName = data => {
  const labels = uniq(data.map(x => x.measure.label));
  return labels.length === 1 ? labels[0] : null;
};

export const getUnits = (intl, data) => {
  const label = getMetricName(data);
  if (!label) {
    return {
      long: intl.formatMessage(columnMessages.columnMultiMetric),
      short: intl.formatMessage(columnMessages.columnMultiMetric),
    };
  }
  switch (label) {
    case 'SENTIMENT':
      return {
        long: intl.formatMessage(columnMessages.columnSentimentLong),
        short: intl.formatMessage(columnMessages.columnSentimentShort),
      };
    case V3_METRIC_LABELS.subSearch: {
      const metricFieldFromKeyMessageWidget = getMetricFieldFromKeyMessageWidget(
        data,
      );

      switch (metricFieldFromKeyMessageWidget) {
        case V3_METRIC_FIELDS.totalReadership:
          return {
            long: intl.formatMessage(columnMessages.columnTotalReadershipLong),
            short: intl.formatMessage(
              columnMessages.columnTotalReadershipShort,
            ),
          };
        case V3_METRIC_FIELDS.mobileReadership:
          return {
            long: intl.formatMessage(columnMessages.columnMobileReadershipLong),
            short: intl.formatMessage(
              columnMessages.columnMobileReadershipShort,
            ),
          };
        case V3_METRIC_FIELDS.desktopReadership:
          return {
            long: intl.formatMessage(
              columnMessages.columnDesktopReadershipLong,
            ),
            short: intl.formatMessage(
              columnMessages.columnDesktopReadershipShort,
            ),
          };
        default:
          return {
            long: intl.formatMessage(columnMessages.columnArticleMentionLong),
            short: intl.formatMessage(columnMessages.columnArticleMentionShort),
          };
      }
    }
    case V3_METRIC_LABELS.seoImpactBucket:
    case V3_METRIC_LABELS.mentions:
      return {
        long: intl.formatMessage(columnMessages.columnArticleMentionLong),
        short: intl.formatMessage(columnMessages.columnArticleMentionShort),
      };
    case V3_METRIC_LABELS.aggregateReadership:
    case V3_METRIC_LABELS.totalReadership:
      return {
        long: intl.formatMessage(columnMessages.columnTotalReadershipLong),
        short: intl.formatMessage(columnMessages.columnTotalReadershipShort),
      };
    case V3_METRIC_LABELS.mobileReadership:
      return {
        long: intl.formatMessage(columnMessages.columnMobileReadershipLong),
        short: intl.formatMessage(columnMessages.columnMobileReadershipShort),
      };
    case V3_METRIC_LABELS.desktopReadership:
      return {
        long: intl.formatMessage(columnMessages.columnDesktopReadershipLong),
        short: intl.formatMessage(columnMessages.columnDesktopReadershipShort),
      };
    case 'SITE_SESSION':
      return {
        long: intl.formatMessage(columnMessages.columnTotalSessionsLong),
        short: intl.formatMessage(columnMessages.columnTotalSessionsLong),
      };
    case V3_METRIC_LABELS.socialPlatform:
      return {
        long: intl.formatMessage(columnMessages.columnSocialAmpLong),
        short: intl.formatMessage(columnMessages.columnSocialAmpShort),
      };
    case V3_METRIC_LABELS.adEquivalency:
      return {
        long: intl.formatMessage(columnMessages.columnAdEquivalencyLong),
        short: intl.formatMessage(columnMessages.columnAdEquivalencyShort),
      };
    case 'AVG_ARTICLE_IMPACT':
      return {
        long: intl.formatMessage(columnMessages.columnAvgArticleImpactLong),
        short: intl.formatMessage(columnMessages.columnAvgArticleImpactShort),
      };
    case WIDGET_TYPE.earnedMediaAttribution:
      return {
        long: intl.formatMessage(globalMessages.performance),
        short: intl.formatMessage(globalMessages.performance),
      };
    case V3_METRIC_LABELS.views:
      return {
        long: intl.formatMessage(columnMessages.columnViews),
        short: intl.formatMessage(columnMessages.columnViews),
      };
    case V3_METRIC_LABELS.dailyUniqueViews:
      return {
        long: intl.formatMessage(columnMessages.columnDailyUniqueViews),
        short: intl.formatMessage(columnMessages.columnDailyUniqueViews),
      };
    case V3_METRIC_LABELS.conversions:
      return {
        long: intl.formatMessage(columnMessages.columnConversions),
        short: intl.formatMessage(columnMessages.columnConversions),
      };
    case 'UNIQUE_PR_ARTICLES':
      return {
        long: intl.formatMessage(columnMessages.columnUniquePrArticlesLong),
        short: intl.formatMessage(columnMessages.columnUniquePrArticlesShort),
      };
    case 'PR_TRAFFIC':
      return {
        long: intl.formatMessage(formMessages.prTrafficDefaultTitle),
        short: intl.formatMessage(columnMessages.columnPrReferralTrafficShort),
      };
    case V3_METRIC_LABELS.posts:
      return {
        long: intl.formatMessage(columnMessages.columnPostsLong),
        short: intl.formatMessage(columnMessages.columnPostsShort),
      };
    default:
      return { long: null, short: null };
  }
};

export const getWidgetIcon = (data, widgetType) => {
  if (data?.length === 1) {
    const metric = data[0];
    const vizType = metric.visualizationType;
    if (
      vizType === VISUALIZATION_TYPES.area ||
      vizType === VISUALIZATION_TYPES.line
    ) {
      return 'sparklineChartIcon';
    } else if (vizType === VISUALIZATION_TYPES.pie) {
      if (widgetType === WIDGET_TYPE.sentiment) {
        return 'sentiment';
      } else if (widgetType === WIDGET_TYPE.international) {
        return 'location';
      }
      return 'donutChartIcon';
    } else if (vizType === VISUALIZATION_TYPES.horizontalBar) {
      // BAR_CHART_METRIC_MAP will need to be recreated here
      return 'horizontalBarChartIcon';
    } else if (vizType === VISUALIZATION_TYPES.impactTopJournalistList) {
      return 'list';
    }
  } // does not handle multiple metrics at the moment
};

export const dateString = date => {
  return `${moment.utc(date).format('MMM DD, YYYY')}`;
};

export const getKeyLabelMapping = (data, intl, chartType) => {
  if (!data) return null;
  if (data.some(d => !d.data.length)) return [null, null];
  const mapping = {};
  const searchMapping = {};
  const isMultiVisualizationType = chartType === CHART_TYPE.multiVisOverTime;
  data.forEach(d => {
    if (isMultiVisualizationType) {
      mapping[
        `${d.visualizationType}-${d.data[0].dataSource.sourceId}`
      ] = getMultiMetricDetailRowLabel(intl, d.measure.field);
    } else {
      d.data.forEach(x => {
        const dataSource = x.dataSource;
        if (
          dataSource.source === DATA_SOURCES.trendkiteElasticSearch &&
          isValidV3SearchType(dataSource.sourceType)
        ) {
          const sourceId = dataSource.sourceId;
          mapping[sourceId] = dataSource?.metadata?.sourceModel?.title;
          searchMapping[sourceId] = dataSource?.metadata?.sourceModel?.search;
        } else if (
          dataSource.source === DATA_SOURCES.trendkiteSocial &&
          dataSource.sourceType === DATA_SOURCE_TYPES.socialSearch
        ) {
          const sourceId = dataSource.sourceId;
          mapping[sourceId] = getSocialSearchLabel(intl);
          searchMapping[sourceId] = dataSource?.metadata?.sourceModel?.search;
        } else if (
          dataSource.source === DATA_SOURCES.cidImpact &&
          isValidV3SearchType(dataSource.sourceType)
        ) {
          const sourceId = dataSource.sourceId;
          mapping[sourceId] = dataSource?.metadata?.sourceModel?.title;
          searchMapping[sourceId] = dataSource?.metadata?.sourceModel?.search;
        }
        // TBD
      });
    }
  });
  return [mapping, searchMapping];
};

/**
 * Returns the sentiment buckets in sorted order
 */
export const getSentimentBuckets = (dataEntry, bucketIndex) => {
  const positive = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex].toLowerCase() === 'positive',
  );
  const neutral = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex].toLowerCase() === 'neutral',
  );
  const negative = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex].toLowerCase() === 'negative',
  );

  return [positive, neutral, negative];
};

export const buildDataIndicesFromHeaders = headers => {
  const indices = {};

  if (!headers) return indices;

  headers.forEach((header, index) => {
    indices[header] = index;
  });
  return indices;
};
export const buildSocialTransientWidgetData = searchId => ({
  searchId,
  visualizationType: VISUALIZATION_TYPES.area,
  dateRangeType: DATE_RANGE_KEYS.TRAILING_30,
  measureField: V3_METRIC_FIELDS.articleMention,
  measureLabel: V3_METRIC_LABELS.mentions,
  measureOperation: V3_MEASURE_OPERATIONS.count,
  metadataSourceModelTitle: 'TWEETS',
  dimensionsField: WIDGET_DIMENSION_FIELDS.publishDate,
  dimensionsLabel: 'TWITTER',
  dimensionsGranularity: { type: GRANULARITY_TYPES.DAY },
  dataSourcesSource: DATA_SOURCES.trendkiteSocial,
  dataSourcesSourceId: searchId,
  dataSourcesSourceType: DATA_SOURCE_TYPES.socialSearch,
});

export const buildTransientWidgetRequest = widgetData => ({
  metrics: [
    {
      visualizationType: widgetData.visualizationType,
      dateRangeType: widgetData.dateRangeType,
      isDateRangeCompare: false,
      measure: {
        field: widgetData.measureField,
        label: widgetData.measureLabel,
        operation: widgetData.measureOperation,
      },
      dimensions: [
        {
          field: widgetData.dimensionsField,
          label: widgetData.dimensionsLabel,
          granularity: widgetData.dimensionsGranularity,
        },
      ],
      dataSources: [
        {
          source: widgetData.dataSourcesSource,
          sourceId: widgetData.searchId,
          sourceType: widgetData.dataSourcesSourceType,
          metadata: {
            sourceModel: {
              id: widgetData.searchId,
              title: widgetData.metadataSourceModelTitle,
            },
            title: widgetData.metadataSourceModelTitle,
            type: widgetData.dataSourcesSourceType,
          },
        },
      ],
    },
  ],
});

export const getDomainAuthorityBuckets = (dataEntry, bucketIndex) => {
  const firstRange = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex] === 'firstRange',
  );
  const secondRange = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex] === 'secondRange',
  );
  const thirdRange = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex] === 'thirdRange',
  );
  const fourthRange = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex] === 'fourthRange',
  );
  const fifthRange = dataEntry?.data?.[0]?.data?.find(
    data => data[bucketIndex] === 'fifthRange',
  );

  return [firstRange, secondRange, thirdRange, fourthRange, fifthRange];
};

export const calcPercentage = (total, value) => (value / total) * 100;

export const calcTotal = (data, valueIndex) => {
  let total = 0;
  if (data) {
    data.forEach(row => (total += row[valueIndex]));
  }
  return total;
};

const validateWidgetV3DataSource = dataSource => {
  return (
    dataSource.source === WIDGET_V3_SOURCE &&
    WIDGET_V3_SOURCE_TYPES.includes(dataSource.sourceType)
  );
};

const getDataSourcesForDrilldownLink = metrics =>
  metrics.reduce(
    (acc, metric) => [
      ...acc,
      ...metric.dataSources.filter(validateWidgetV3DataSource),
    ],
    [],
  );

const getDataFromDataSources = (ds, params, metrics) => {
  const { dataKey, payload } = params || {};
  const validDateKey = !dataKey || dataKey === 'value';
  if (validDateKey && payload) {
    return { title: payload?.label, searchId: payload?.searchId };
  }
  const mDS = metrics[0]?.dataSources;
  const dataSource =
    ds.find(ds => ds.sourceId === dataKey) ||
    ds.find(ds => `${dataKey}`.includes(`${ds.sourceId}`)) ||
    mDS.find(ds => ds.metadata?.sourceModel?.search === dataKey);

  if (!dataSource && ds.length >= 1) {
    return {
      title: ds[0].metadata?.sourceModel?.title || '',
      searchId: ds[0].sourceId,
    };
  }
  const { sourceModel, type } = dataSource?.metadata;
  const title = sourceModel?.title || '';
  const searchId = sourceModel?.id;
  const dataSourceId = dataSource?.sourceId;
  return { title, searchId, dataSourceId, type };
};

export const getWidgetV3CompareDateRange = metricsV3 => {
  const compareMetric = metricsV3?.find(m => m.isDateRangeCompare);

  if (!compareMetric) {
    return null;
  }

  return {
    type: compareMetric.dateRangeCompareType,
    startDate: moment.utc(compareMetric?.startDate).valueOf(),
    endDate: moment.utc(compareMetric?.endDate).valueOf(),
  };
};

export const buildChartDrilldownLink = ({
  params,
  metrics,
  chartData,
  widgetId,
  granularityType,
  reportUuid = '',
  minDate = null,
  maxDate = null,
  protectedOwnerId = '',
}) => {
  let compareDateRange;
  const useDateRangeCompare = params[0]?.isCompareClick;

  let dateRange = getDateRange(metrics) || {};
  if (useDateRangeCompare) {
    compareDateRange = getWidgetV3CompareDateRange(metrics);
  }

  if (!dateRange || (!dateRange.startDate && !dateRange.endDate)) {
    dateRange = getDateRangeFromChartData(chartData);
  }

  const { type, startDate, endDate } = dateRange;

  let { startTime, endTime } = getTimestampsForAnyRange(
    type,
    startDate,
    endDate,
  );

  if (useDateRangeCompare) {
    const {
      compareStartDate,
      compareEndDate,
    } = buildDateRangeCompareTimestamps(
      compareDateRange.type,
      { type: dateRange.dateRangeType, ...dateRange },
      compareDateRange.startDate,
      compareDateRange.endDate,
    );
    startTime = compareStartDate;
    endTime = compareEndDate;
  }

  if (params.length === 2) {
    //When user clicks on country in map chart it receives two params
    const [searchId, payload] = params;
    const { widgetType, keyword } = payload;

    return {
      pathname: buildWidgetV3DrilldownPath(widgetId),
      search: `searchId=${searchId}&startDate=${startTime}&endDate=${endTime}&searchTitle=${keyword}&dataPoint=${keyword}&reportUuid=${reportUuid}&widgetType=${widgetType}`,
    };
  }
  const dataSources = getDataSourcesForDrilldownLink(metrics);
  const { payload } = params[0] || {};

  let formattedDay = '';
  let builtDateRange = null;

  if (payload?.date) {
    builtDateRange = buildDateRangeFromPayload(
      payload,
      granularityType,
      minDate,
      maxDate,
      params[0].isCompareClick,
    );

    // Don't allow bucketized date range to override any custom dates set at either end, e.g. if a custom date range
    // of 2/9/21 to 2/24/21, is bucketized by week, the week buckets will be:
    //   o 2/9 to 2/14
    //   o 2/15 to 2/21
    //   o 2/22 to 2/24
    // i.e. the first and last weeks are truncated to fit the custom range.  A monthly bucket would just encompass
    // the entire 2/19 to 2/24 range.
    //

    const isCustomDateRange = getDateRange(metrics)?.dateRangeType === 'CUSTOM';
    if (isCustomDateRange) {
      builtDateRange.startDate = Math.max(
        builtDateRange.startDate,
        dateRange.startDate,
      );
      builtDateRange.endDate = Math.min(
        builtDateRange.endDate,
        dateRange.endDate,
      );
    }

    formattedDay = moment(builtDateRange.day, 'MMM-DD-YYYY').format('YYYYMMDD');
  }
  const { startDate: buildStartDate, endDate: buildEndDate } =
    builtDateRange || {};
  const {
    searchId,
    title,
    dataSourceId,
    type: sourceType,
  } = getDataFromDataSources(dataSources, params[0], metrics);
  const id =
    sourceType === DATA_SOURCE_TYPES.tag
      ? `dataSourceId=${dataSourceId}`
      : `searchId=${searchId}`;

  const searchTitle = isSentiment(metrics?.[0])
    ? dataSources[0]?.metadata?.sourceModel?.title
    : title;

  const searchParams = [
    `${id}`,
    `startDate=${
      builtDateRange && buildStartDate ? buildStartDate : startTime
    }`,
    `endDate=${builtDateRange && buildEndDate ? buildEndDate : endTime}`,
    `searchTitle=${encodeURIComponent(searchTitle)}`,
    `reportUuid=${reportUuid}`,
  ];
  if (formattedDay) {
    searchParams.push(`activeDay=${formattedDay}`);
  }
  if (payload?.dataPoint) {
    searchParams.push(`dataPoint=${payload.dataPoint}`);
  } else if (payload?.label) {
    searchParams.push(`dataPoint=${payload.label}`);
  } else if (reportUuid && protectedOwnerId) {
    searchParams.push(`userId=${protectedOwnerId}`);
  }

  return {
    pathname: buildWidgetV3DrilldownPath(widgetId),
    search: searchParams.join('&'),
  };
};

export const buildMetricDrilldownLink = (
  params,
  dateRange,
  widgetId,
  reportUuid = '',
  protectedOwnerId = '',
) => {
  const { searchId, label, searchQuery } = params || {};
  const { type, startDate, endDate } = dateRange || {};
  const { startTime, endTime } = getTimestampsForAnyRange(
    type,
    startDate,
    endDate,
  );
  const dataPoint = searchQuery || label;

  const userParam =
    reportUuid && protectedOwnerId ? `&userId=${protectedOwnerId}` : '';

  const searchTitle = params?.searchTitle || label;

  return {
    pathname: buildWidgetV3DrilldownPath(widgetId),
    search: `searchId=${searchId}&startDate=${startTime}&endDate=${endTime}&searchTitle=${encodeURIComponent(
      searchTitle,
    )}&dataPoint=${encodeURIComponent(
      dataPoint,
    )}&reportUuid=${reportUuid}${userParam}`,
  };
};

export const getDateRangeCompareDayDifference = dateRange => {
  let dayDifference;
  if (dateRange.type === DATE_RANGE_KEYS.CUSTOM) {
    dayDifference = moment
      .utc(dateRange.endDate)
      .diff(moment.utc(dateRange.startDate), 'days');
  } else if (DATE_RANGE_OPTIONS_WITH_UNDEFINED_DAYS.includes(dateRange.type)) {
    const { startTime, endTime } = getTimestampsForAnyRange(dateRange.type);
    dayDifference = moment.utc(endTime).diff(startTime, 'days');
  } else {
    dayDifference = DATE_RANGES[dateRange.type]?.trailing_days;
  }

  return dayDifference;
};

export const getWidgetV3DateRangeCompareDateRange = widgetV3 => {
  const compareMetric = widgetV3.metrics.find(m => m.isDateRangeCompare);
  return compareMetric?.dateRange;
};

/**
 * Builds the tooltip strings for a pie chart date compare
 */
export const buildPieCompareDataMapping = (data, intl) => {
  const format = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };

  if (data?.length < 2) {
    return {};
  }
  const { dateRange } = data.find(d => !d.dateRangeCompare);
  const { dateRange: compareDateRange, dateRangeCompareType } = data.find(
    d => d.dateRangeCompare,
  );
  const { startTime, endTime } = getTimestampsForAnyRange(
    dateRange.dateRangeType,
    dateRange.startDate,
    dateRange.endDate,
  );
  const { compareStartDate, compareEndDate } = buildDateRangeCompareTimestamps(
    dateRangeCompareType,
    { type: dateRange.dateRangeType, ...dateRange },
    compareDateRange.startDate,
    compareDateRange.endDate,
  );

  const formattedDateRange = `${intl.formatDate(
    new Date(startTime),
    format,
  )} - ${intl.formatDate(new Date(endTime), format)}`;
  const formattedCompareDateRange = `${intl.formatDate(
    new Date(compareStartDate),
    format,
  )} - ${intl.formatDate(new Date(compareEndDate), format)}`;

  return {
    comparedDateRange: formattedCompareDateRange,
    dateRange: formattedDateRange,
  };
};

export const getKeysForWidget = data => {
  return data
    .filter(d => d.visualizationType === VISUALIZATION_TYPES.area)
    .map(d => {
      const searchTitles = uniq(
        d.data
          .map(x => ({
            sourceId: x.dataSource.sourceId,
            title: x.dataSource.metadata.sourceModel.title,
          }))
          .flat(),
      );
      return searchTitles;
    })
    .flat();
};

export const buildDateRangeParams = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
}) => {
  let dateRangeParams = startDate && endDate ? { startDate, endDate } : {};
  if (compareStartDate && compareEndDate) {
    dateRangeParams = {
      ...dateRangeParams,
      compareStartDate,
      compareEndDate,
    };
  }
  return dateRangeParams;
};

// This truncates the timestamp to 59 seconds (1 full second from midnight).
// It is required because Grails rounds up the time to the 'stroke of midnight'
// if the seconds are set to one millisecond before midnight.
export const truncateEndDateTimestamp = timestamp => {
  const timestampSeconds = Math.trunc(timestamp / 1000);
  return timestampSeconds * 1000;
};

export const getMapDimensions = size => {
  if (size === 'drilldown') {
    return { offsetX: -50, width: 650, height: 216 };
  }
  if (size === 'large') {
    return { offsetX: -120, width: 770, height: 216 };
  }
  return { offsetX: 0, width: 488, height: 216 };
};

export const isTagOwnedByUser = message =>
  !message?.includes(TAG_ERROR_RESPONSE);

export const isUnsupportedFilter = message => {
  const isError = message
    ?.toLowerCase()
    .includes(UNSUPPORTED_FILTER_ERROR_RESPONSE.toLocaleLowerCase());
  const listIndex = message?.indexOf('[');
  const list = message?.substring(listIndex);
  return [isError, isError ? list : ''];
};

export const getWidgetGranularityType = data =>
  data?.[0]?.dimensions?.[0]?.granularity?.type;

export const MAX_NUMBER_OF_OPERATORS = 1023;

export const shouldRenderTooltip = (widgetType, metricType) => {
  if (
    widgetType === WIDGET_TYPE.totalMentionsOverTime ||
    widgetType === WIDGET_TYPE.shareOfVoice
  ) {
    return true;
  }
  return (
    widgetType === WIDGET_TYPE.unknown &&
    (metricType === V3_METRIC_LABELS.mobileReadership ||
      metricType === V3_METRIC_LABELS.desktopReadership ||
      metricType === V3_METRIC_LABELS.totalReadership)
  );
};

const isEmptyChartData = chartData => {
  return chartData?.[0]?.data?.[0]?.data.length === 0;
};

export const isEmptyArticleListWidget = chartData => {
  return chartData?.[0]?.data?.[0]?.data?.[0]?.articles?.length === 0;
};

const isEmptyTopJournalists = chartData => {
  //[2] is the index of the value in the data response
  // a bit magical but true fix is to have top journalists
  // data response more closely match the structure of article list
  return chartData?.[0]?.data?.[0]?.data?.[2]?.length === 0;
};

export const isEmptyImpactWidget = (chartData, widgetType) => {
  return (
    (isEmptyChartData(chartData) &&
      (widgetType === WIDGET_TYPE.verifiedUniqueViewers ||
        widgetType === WIDGET_TYPE.impactTrendingConversions ||
        widgetType === WIDGET_TYPE.verifiedViews)) ||
    isEmptyTopJournalists(chartData)
  );
};

export const sendEmailTo = (target, subject, body, signature) => {
  const message = body + signature;
  return `mailto:${target}?subject=${subject}&body=${message}`;
};

export const getAccountServiceEmail = () => {
  return 'innovations.js@cision.com';
};

export const getAccountServiceSignature = activeUser => {
  return activeUser.account
    ? `%0A ${activeUser.username} %0A [${activeUser.account.name} ${activeUser.account.id}]`
    : '';
};

const SUPPORTED_WIDGETS_FOR_CREATE_SEARCH_OPTIONS = [
  WIDGET_TYPE.totalMentionsOverTime,
  WIDGET_TYPE.shareOfVoice,
];

export const shouldShowCreateSearchOption = widgetType => {
  return SUPPORTED_WIDGETS_FOR_CREATE_SEARCH_OPTIONS.includes(widgetType);
};

export const hasVerifiedViewsData = chartData => {
  const verifiedViewsData = chartData?.[0]?.data?.[0]?.data[0];
  return !!verifiedViewsData && verifiedViewsData?.length > 0;
};

export const hydrateWidgetData = (widget, widgetData) => {
  const metrics = widget.metrics;
  if (!metrics?.length) {
    return null;
  }
  const allWidgetDataSources = metrics.map(m => m.dataSources).flat();

  return widgetData?.map(metricData => {
    const matchingWidgetMetric = widget.metrics.find(
      x => x.id === metricData.metricId,
    );
    if (matchingWidgetMetric) {
      metricData.measure = matchingWidgetMetric.measure;
      metricData.dimensions = matchingWidgetMetric.dimensions;
      metricData.dataSources = matchingWidgetMetric.dataSources;
      metricData.visualizationType = matchingWidgetMetric.visualizationType;
      metricData.dateRangeCompare = matchingWidgetMetric.isDateRangeCompare;
      metricData.startDate = matchingWidgetMetric.startDate;
      metricData.endDate = matchingWidgetMetric.endDate;
      metricData.dateRangeCompareType =
        matchingWidgetMetric.dateRangeCompareType;
      metricData.data.forEach(d => {
        const matchingDataSource =
          allWidgetDataSources.find(
            widgetDataSources =>
              widgetDataSources.sourceId === d.data[0]?.[0] ||
              widgetDataSources.sourceId === d.data[0],
          ) || {};
        d.dataSource = matchingDataSource;
      });
    }
    return metricData;
  });
};

const findSearchInDataSources = (dataSources, searchId) => {
  return dataSources?.some(dataSource => {
    const isValidDataSource =
      dataSource.sourceType === DATA_SOURCE_TYPES.search ||
      dataSource.sourceType === DATA_SOURCE_TYPES.parentSearch ||
      dataSource.sourceType === DATA_SOURCE_TYPES.impactSearch;
    return isValidDataSource && dataSource.sourceId === searchId;
  });
};

export const isWidgetPoweredBySearch = (metrics, searchId) => {
  return metrics?.some(metric =>
    findSearchInDataSources(metric.dataSources, searchId),
  );
};

const SUPPORTED_WIDGETS_FOR_REPLACE_SEARCH_OPTIONS = [
  WIDGET_TYPE.socialAmplification,
  WIDGET_TYPE.keyMessages,
  WIDGET_TYPE.sentiment,
  WIDGET_TYPE.domainAuthority,
  WIDGET_TYPE.international,
  WIDGET_TYPE.stateProvince,
  WIDGET_TYPE.city,
  WIDGET_TYPE.prReferralTraffic,
  WIDGET_TYPE.totalMentionsVsTotalSessions,
  WIDGET_TYPE.highestReaderShip,
  WIDGET_TYPE.aggregateReaderShip,
  WIDGET_TYPE.articleList,
  WIDGET_TYPE.adEquivalency,
  WIDGET_TYPE.topPublishers,
];

export const isMultipleDataSourceWidget = widgetType =>
  SUPPORTED_WIDGETS_FOR_CREATE_SEARCH_OPTIONS.includes(widgetType);
export const isSingleDataSourceWidget = widgetType =>
  SUPPORTED_WIDGETS_FOR_REPLACE_SEARCH_OPTIONS.includes(widgetType);

export const isDesktopReadership = metrics =>
  metrics[0]?.measure?.label === V3_METRIC_LABELS.desktopReadership;
export const isEarnedMediaMentions = metrics =>
  metrics[0]?.measure?.label === V3_METRIC_LABELS.mentions;

export const isArticleListWithAllowedMetric = (widgetType, metrics) =>
  widgetType === WIDGET_TYPE.unknown &&
  (isMobileReaderShipWidget(metrics) || isDesktopReadership(metrics));

export const isMultipleDataSourceWithDateRangeCompare = data => {
  return (
    data?.length === 2 &&
    data?.[0]?.data?.length > 1 &&
    isDateRangeCompare(data)
  );
};

export const getShowChartV3 = (
  chartData,
  isPreview,
  gridHeight,
  gridWidth,
  widgetType,
  chartType,
) => {
  if (
    widgetType === WIDGET_TYPE.totalMentionsOverTime &&
    gridHeight === 1 &&
    gridWidth === 1 &&
    isMultipleDataSourceWithDateRangeCompare(chartData)
  ) {
    return false;
  }

  const isSingleDS = isSingleDataSource(chartData);
  const isSingleDSWithDataRangeCompare = isSingleDataSourceWithDateRangeCompare(
    chartData,
  );
  return (
    ((chartData && chartData.length) || isPreview) &&
    (gridHeight > 1 ||
      gridWidth > 1 ||
      ((isSingleDS || isSingleDSWithDataRangeCompare) &&
        chartType === CHART_TYPE.area) ||
      widgetType === WIDGET_TYPE.highestReaderShip ||
      chartType === CHART_TYPE.areaDateRangeCompare ||
      chartType === CHART_TYPE.wordCloud ||
      widgetType === WIDGET_TYPE.articleList ||
      widgetType === WIDGET_TYPE.topJournalists ||
      widgetType === WIDGET_TYPE.impactSnapshot ||
      (chartType === CHART_TYPE.pie && widgetType === WIDGET_TYPE.sentiment))
  );
};

export const showSimilarWebLogo = metricLabel => {
  return [
    V3_METRIC_LABELS.totalReadership,
    V3_METRIC_LABELS.aggregateReadership,
    V3_METRIC_LABELS.desktopReadership,
    V3_METRIC_LABELS.mobileReadership,
  ].includes(metricLabel);
};

export const isPieWidget = widgetType => {
  return [WIDGET_TYPE.sentiment, WIDGET_TYPE.shareOfVoice].includes(widgetType);
};
