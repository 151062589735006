import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body, Button, EmptyState, Paper } from '@trendkite/ui';

import lighthouse from '../assets/icons/lighthouse.svg';

const baseClass = 'tk-story';

const StoryError = ({ tkUrl }) => {
  const containerClasses = classNames(baseClass, 'story-error');

  return (
    <div className={containerClasses}>
      <div className={`${baseClass}__area`}>
        <div className={`${baseClass}__box`}>
          <Paper padding="40px 40px 60px">
            <EmptyState
              imageUrl={`#${lighthouse.id}`}
              title="Sorry."
              text="This page is not what you're looking for."
            />
          </Paper>
        </div>
        <div className={`${baseClass}__footnote`}>
          <Body color="light">
            This landing page was built using the Cision platform.
            <Button modifiers={['link']} location={tkUrl}>
              {' '}
              Learn more about Cision
            </Button>
          </Body>
        </div>
      </div>
    </div>
  );
};

StoryError.propTypes = {
  tkUrl: PropTypes.string.isRequired,
};

export default StoryError;
