import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  AnalyticItem,
  AnalyticItemObject,
} from 'pages/Analytics/analytics-config';

import { REPORT_STATUSES, REPORT_EXPORT_STATUSES } from './constants';
import { ReportWrapper } from './report-wrapper-reducer';
import { ReportSlide } from './Slide';

export const reportSlides = (state: State): ReportSlide[] =>
  state.report.reportHub.report?.slides || [];

export interface State {
  report: ReportState;
}

export interface ReportState {
  reportHub: ReportHub;
  reportWrapper: ReportWrapper;
}

export interface ReportHub {
  duplicatingSuccess: boolean;
  reportExport: ReportExport | Record<string, any>;
  loading: boolean;
  slidesBeingUpdated: any[];
  error: string;
  report: Report | Record<string, any>;
}

export interface campaignInfo {
  id: number;
  title: string;
}

export interface Report extends AnalyticItem {
  dateRangeType: string;
  endDate: string;
  owner: Record<string, string>;
  password: string;
  reportStatus: REPORT_STATUSES.LIVE | REPORT_STATUSES.OFFLINE;
  slides: ReportSlide[];
  startDate: string;
  shared: boolean;
  uuid: string;
  campaigns: campaignInfo[];
}

export class ReportObject extends AnalyticItemObject {
  endDate: '';
  owner: Record<string | number, any>;
  password: '';
  reportStatus: REPORT_STATUSES.OFFLINE;
  slides: [];
  startDate: '';
  uuid: '';
}

export interface ReportExport {
  status: REPORT_EXPORT_STATUSES;
  error?: string | null;
}

export const useTypedSelector: TypedUseSelectorHook<State> = useSelector;

export const reportState = (state: State): ReportState => state.report || {};

export const analyticTitleSelector = createSelector(
  reportState,
  (report: ReportState): Report['title'] =>
    report.reportHub.report?.title || '',
);
