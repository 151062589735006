import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withModifiers from '../withModifiers';
import withPadding from '../withPadding';

class Paper extends Component {
  static baseClass = 'tk-paper';

  render() {
    const { children, className, style, ...passedProps } = this.props;

    const mainClass = classNames(Paper.baseClass, className);

    return (
      <div className={mainClass} style={style} {...passedProps}>
        {children}
      </div>
    );
  }
}

Paper.defaultProps = {
  children: null,
  className: '',
  style: {},
};

Paper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default withPadding(withModifiers(Paper));
