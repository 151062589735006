export const EMPTY_CODE = null;
// Translations API uses empty languages/regions to imply hierarchy.
// <empty lang>/<empty region>
// -- English/<empty region>
// ---- English/Canada
//
// Our current preferred inherited language is <empty lang>/<empty region>
// WARNING: if this is changed, all hell kinda breaks loose since there will be
// consequences to the translation tool, as well as potential issues with
// translations inheriting from the wrong parent
export const ROOT_LANGUAGE_ID: number | null = EMPTY_CODE;
export const ROOT_REGION_ID: number | null = EMPTY_CODE;
