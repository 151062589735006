import React, { useContext } from 'react';

import PopperDropdownContext from './context';

const PopperTrigger = ({
  children,
  ...passedProps
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { setTriggerRef } = useContext(PopperDropdownContext);
  return (
    <div ref={setTriggerRef} {...passedProps}>
      {children}
    </div>
  );
};

export default PopperTrigger;
