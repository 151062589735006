import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import {
  csrfPerformPost,
  performGet,
  performPost,
  performPut,
} from 'services/rest-service/rest-service';

export const GET_USERS = 'customerAdmin/GET_USERS';
export const GET_USERS_SUCCESS = 'customerAdmin/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'customerAdmin/GET_USERS_ERROR';

export const CREATE_USER = 'customerAdmin/CREATE_USER';
export const CREATE_USER_SUCCESS = 'customerAdmin/CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'customerAdmin/CREATE_USER_ERROR';

export const UPDATE_USER_STATUSES = 'customerAdmin/UPDATE_USER_STATUSES';
export const UPDATE_USER_STATUSES_SUCCESS =
  'customerAdmin/UPDATE_USER_STATUSES_SUCCESS';
export const UPDATE_USER_STATUSES_ERROR =
  'customerAdmin/UPDATE_USER_STATUSES_ERROR';

export const RESET_PASSWORD_USER = 'customerAdmin/RESET_PASSWORD_USER';
export const RESET_PASSWORD_USER_SUCCESS =
  'customerAdmin/RESET_PASSWORD_USER_SUCCESS';
export const RESET_PASSWORD_USER_ERROR =
  'customerAdmin/RESET_PASSWORD_USER_ERROR';

export const UPDATE_USER = 'customerAdmin/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'customerAdmin/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'customerAdmin/UPDATE_USER_ERROR';

export const initialState = {
  users: [],
  loading: false,
  error: null,
  count: 0,
  activeUsers: 0,
  maxUsers: null,
  pageNumber: 1,
};

const customerAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        maxUsers: action.payload.maxUsers,
        count: action.payload.total,
        activeUsers: action.payload.activeUsers,
        pageNumber: action.payload.pageNumber,
        loading: false,
        error: null,
      };
    case GET_USERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case CREATE_USER:
      return {
        ...state,
        savingUser: true,
        savingUserError: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        savingUser: false,
        savingUserError: null,
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        savingUser: false,
        savingUserError: action.payload.error,
      };
    case UPDATE_USER_STATUSES:
      return {
        ...state,
        updatingUserStatuses: true,
        updatingUserStatusesError: null,
      };
    case UPDATE_USER_STATUSES_SUCCESS:
      return {
        ...state,
        updatingUserStatuses: false,
        updatingUserStatusesError: null,
      };
    case UPDATE_USER_STATUSES_ERROR:
      return {
        ...state,
        updatingUserStatuses: false,
        updatingUserStatusesError: action.payload.error,
      };
    case RESET_PASSWORD_USER:
      return {
        ...state,
        sendingPasswordReset: true,
        passwordResetError: null,
      };
    case RESET_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        sendingPasswordReset: false,
        passwordResetError: null,
      };
    case RESET_PASSWORD_USER_ERROR:
      return {
        ...state,
        sendingPasswordReset: false,
        passwordResetError: action.payload.error,
      };
    case UPDATE_USER:
      return {
        ...state,
        updatingUser: true,
        userUpdateError: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatingUser: false,
        userUpdateError: null,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        updatingUser: true,
        userUpdateError: action.payload.error,
      };
    default:
      return state;
  }
};

export const getUsersForAccountActionCreator = ({
  pageNumber = 1,
  sort = 'username',
  sortDirection = 'asc',
  pageSize = 25,
  activeUsers = false,
}) => async dispatch => {
  dispatch({ type: GET_USERS });
  try {
    const { data } = await performGet('api/account/users', {
      pageNumber,
      sort,
      sortDirection,
      pageSize,
      activeUsers,
    });
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: {
        users: data.results,
        maxUsers: data.maxUsers,
        total: data.total,
        activeUsers: data.activeUsers,
        pageNumber,
      },
    });
  } catch (e) {
    dispatch({ type: GET_USERS_ERROR, payload: { error: e.message } });
  }
};

export const createUserActionCreator = body => async (dispatch, getState) => {
  dispatch({ type: CREATE_USER });
  const currentPageNumber = getState().customerAdmin.pageNumber;
  try {
    const { data } = await performPost('api/account/users', body);
    const { username } = data || {};

    if (username) {
      await performPost('api/account/users/welcome-email', {
        username,
        isNewUser: true,
      });
    }

    dispatch({ type: CREATE_USER_SUCCESS });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Success! Invitation Sent.',
        status: 'success',
      }),
    );
    dispatch(
      getUsersForAccountActionCreator({ pageNumber: currentPageNumber }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There has been an error sending invite.',
        status: 'danger',
      }),
    );
    dispatch({ type: CREATE_USER_ERROR, payload: { error: e.message } });
  }
};

export const updateUserStatusesActionCreator = users => async (
  dispatch,
  getState,
) => {
  const currentPageNumber = getState().customerAdmin.pageNumber;
  dispatch({ type: UPDATE_USER_STATUSES });
  try {
    await performPut('api/account/users', { users });

    dispatch({ type: UPDATE_USER_STATUSES_SUCCESS });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Success! User Statuses Updated.',
        status: 'success',
      }),
    );
    dispatch(
      getUsersForAccountActionCreator({ pageNumber: currentPageNumber }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There has been an error updating user statuses.',
        status: 'danger',
      }),
    );
    dispatch({
      type: UPDATE_USER_STATUSES_ERROR,
      payload: { error: e.message },
    });
  }
};

export const resetUserPasswordActionCreator = username => async dispatch => {
  dispatch({ type: RESET_PASSWORD_USER });
  try {
    await performPost('api/account/users/welcome-email', {
      username,
      isNewUser: false,
    });

    dispatch({ type: RESET_PASSWORD_USER_SUCCESS });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Success! User emailed with password reset.',
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There has been an error with password reset.',
        status: 'danger',
      }),
    );
    dispatch({
      type: RESET_PASSWORD_USER_ERROR,
      payload: { error: e.message },
    });
  }
};

export const updateUserActionCreator = user => async (dispatch, getState) => {
  dispatch({ type: UPDATE_USER });
  const currentPageNumber = getState().customerAdmin.pageNumber;
  try {
    const { id, isCustomerAdmin } = user;
    await csrfPerformPost('api/account/users/customerAdmin', {
      userId: id,
      isCustomerAdmin,
    });

    dispatch({ type: UPDATE_USER_SUCCESS });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Success! User updated.',
        status: 'success',
      }),
    );
    dispatch(
      getUsersForAccountActionCreator({ pageNumber: currentPageNumber }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There has been an error updating user.',
        status: 'danger',
      }),
    );
    dispatch({ type: UPDATE_USER_ERROR, payload: { error: e.message } });
  }
};

export default customerAdminReducer;
