import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import {
  SocialMetric,
  SocialNetworkInfo,
  SocialNetworkName,
  SocialProfile,
} from './types/social-profiles.types';

export const socialInfo: Record<SocialNetworkName, SocialNetworkInfo> = {
  twitter: {
    label: 'Twitter',
    prefix: '@',
    mainMetricName: 'followers',
    urlBase: 'https://twitter.com',
  },
  twitch: {
    label: 'Twitch',
    prefix: '/',
    mainMetricName: 'followers',
    urlBase: 'https://twitch.tv',
  },
  youtube: {
    label: 'YouTube',
    prefix: '/',
    mainMetricName: 'subscribers',
    urlBase: 'https://youtube.com',
  },
  linkedin: {
    label: 'LinkedIn',
    prefix: '',
    mainMetricName: '',
    urlBase: 'https://linkedin.com/in',
  },
  facebook: {
    label: 'Facebook',
    prefix: '',
    mainMetricName: 'friends',
    urlBase: 'https://facebook.com',
  },
  instagram: {
    label: 'Instagram',
    prefix: '@',
    mainMetricName: '',
    hideMetrics: true,
    urlBase: 'https://instagram.com',
  },
  pinterest: {
    label: 'Pinterest',
    prefix: '',
    mainMetricName: '',
    urlBase: 'https://www.pinterest.com',
  },
};

export const humanReadableLabelsIntlKeys = {
  followers: 'socialActivityLabelsFollowers',
  following: 'socialActivityLabelsFollowing',
  friends: 'socialActivityLabelsFriends',
  subscribers: 'socialActivityLabelsSubscribers',
  likes: 'socialActivityLabelsLikes',
  tweets: 'socialActivityLabelsTweets',
  views: 'socialActivityLabelsViews',
  videos: 'socialActivityLabelsVideos',
  engagementScore: 'socialActivityLabelsEngagementScore',
  avgTweetsPerWeek: 'socialActivityLabelsAvgTweetsPerWeek',
  media: 'socialActivityLabelsMedia',
};

export const socialProfileLabelsIntlKeys = {
  followers: 'sidebarSocialFollowers',
  friends: 'sidebarSocialFriends',
  subscribers: 'sidebarSocialSubscribers',
};

export const orderedMainSocialNetworks: SocialNetworkName[] = [
  'twitter',
  'twitch',
  'youtube',
];

export const orderedSocialNetworks: SocialNetworkName[] = [
  'twitter',
  'youtube',
  'twitch',
  'facebook',
  'instagram',
];

export const getHideMetrics = (socialProfile: SocialProfile): boolean =>
  !!socialInfo[socialProfile.networkName]?.hideMetrics;

export const getMainMetric = (
  socialProfile: SocialProfile,
): SocialMetric | null => {
  const metrics = socialProfile.primaryMetrics || [];
  const mainLabel = socialInfo[socialProfile.networkName]?.mainMetricName;
  return metrics.find(metric => metric.label === mainLabel) || metrics[0];
};

export const getSocialProfileUrl = (
  socialProfile: SocialProfile,
): string | null => {
  const { networkName, handle } = socialProfile;
  const urlBase = socialInfo[networkName]?.urlBase;
  if (!urlBase) return null;
  return `${urlBase}/${handle}`;
};

export const getSocialProfileHandle = (socialProfile: SocialProfile) => {
  const { networkName } = socialProfile;
  const prefix = socialInfo[networkName]?.prefix || '';
  return `${prefix}${socialProfile.handle}`;
};

export const orderSocialProfiles = (profiles: SocialProfile[]) => {
  const orderedSocialNetworksReversed = [...orderedSocialNetworks].reverse();
  return orderBy(
    profiles,
    profile => [
      orderedSocialNetworksReversed.indexOf(profile.networkName),
      profile.networkName,
    ],
    ['desc'],
  );
};

export const orderMainSocialProfiles = (profiles: SocialProfile[]) =>
  orderSocialProfiles(profiles).filter(
    profile => orderedMainSocialNetworks.indexOf(profile.networkName) >= 0,
  );

export const getPrimaryProfileForNetwork = (
  profiles: SocialProfile[],
  networkName: SocialNetworkName,
) => {
  if (!profiles) return null;

  const networkProfiles = profiles.filter(
    profile => profile.networkName === networkName,
  );

  return (
    networkProfiles.find(
      profile => (profile.badges || []).indexOf('primary') >= 0,
    ) ||
    networkProfiles.find(profile => !isEmpty(profile.primaryMetrics)) ||
    networkProfiles.find(
      profile => (profile.badges || []).indexOf('verified') >= 0,
    ) ||
    networkProfiles[0]
  );
};

export const dedupedSocialProfiles = (socialProfiles: SocialProfile[]) => {
  if (isEmpty(socialProfiles)) return [];
  return uniqBy(
    socialProfiles.filter(profile => !!profile.handle),
    profile => `${profile.networkName}-${profile.handle.toLowerCase()}`,
  );
};

export const getPrimaryProfileForMainNetworks = (
  profiles: SocialProfile[],
): SocialProfile[] => {
  if (isEmpty(profiles)) return [];

  return orderedMainSocialNetworks.reduce<SocialProfile[]>(
    (accProfiles, network): SocialProfile[] => {
      const profile = getPrimaryProfileForNetwork(profiles, network);

      if (profile) {
        accProfiles.push(profile);
      }

      return accProfiles;
    },
    [],
  );
};

export const getPrimaryProfileForAllNetworks = (profiles: SocialProfile[]) => {
  if (isEmpty(profiles)) return [];
  return orderedSocialNetworks
    .map(network => getPrimaryProfileForNetwork(profiles, network))
    .filter(profile => !!profile);
};
