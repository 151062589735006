/* global liveagent:readonly */

function ukSalesforceChatInit() {
  liveagent.init(
    'https://d.la1-c2-lo3.salesforceliveagent.com/chat',
    '5724J000000gHQJ',
    '00D20000000MLq7',
  );

  //Set subject in the format: <Account name> + <Contact name OR email> + 'Chat'
  const caseSubjectTemplate = (accountName, userName) =>
    `${accountName} + ${userName} + Chat`;

  //custom details are stored in object ConversationContextEntry
  //The alternative for Salesforce pre-chat window, comment code below if you set prechat window for the button
  liveagent.addCustomDetail(
    'Account Name',
    window.activeUser?.account?.name,
    true,
  );
  liveagent.addCustomDetail('Email', window.activeUser?.username, true);
  liveagent.addCustomDetail('Last Name', window.activeUser?.username, true);

  // Do not modify
  liveagent.addCustomDetail(
    'ContactRecordTypeId',
    /*UK Sales*/ '012w0000000KBxXAAW',
    false,
  );
  // Do not modify
  liveagent.addCustomDetail(
    'CaseRecordTypeId',
    /*Go Case*/ '0122X000000oqaCQAQ',
    false,
  );

  liveagent.addCustomDetail(
    'Case Subject',
    caseSubjectTemplate(
      window.activeUser?.account?.name,
      window.activeUser?.username,
    ),
    false,
  );
  liveagent.addCustomDetail(
    'Global_Customer_Id__c',
    window.activeUser?.account?.gcId,
    false,
  );

  liveagent
    .findOrCreate('Case')
    .map('Subject', 'Case Subject', false, false, true) //false,false,true = do not search, not exact search, add subject to new record
    .map('RecordTypeId', 'CaseRecordTypeId', false, false, true)
    .saveToTranscript('CaseId')
    .showOnCreate();

  liveagent
    .findOrCreate('Contact')
    .map('Email', 'Email', true, true, true) //true,true,true = search by email, exact search, add email to new record
    .map('FirstName', 'First Name', false, false, true)
    .map('LastName', 'Last Name', false, false, true)
    .map('RecordTypeId', 'ContactRecordTypeId', false, false, true);

  liveagent
    .findOrCreate('Account')
    .map('Global_Customer_Id__c', 'Global_Customer_Id__c', true, true, false);

  liveagent
    .findOrCreate('Contact')
    .saveToTranscript('ContactId')
    .showOnCreate()
    .linkToEntity('Case', 'ContactId');

  liveagent
    .findOrCreate('Account')
    .saveToTranscript('AccountId')
    .showOnCreate()
    .linkToEntity('Case', 'AccountId')
    .linkToEntity('Contact', 'AccountId');

  // format: <Account Name>, <user name>
  const chatUserNameTemplate = (accountName, userName) =>
    `${accountName}, ${userName}`;

  liveagent.setName(
    chatUserNameTemplate(
      window.activeUser?.account?.name,
      window.activeUser?.username,
    ),
  );
  liveagent.setChatWindowWidth(500);
  liveagent.setChatWindowHeight(500);
  //<<< writes logs to developer console, @comment this line in production
  //liveagent.enableLogging();

  if (!window._laq) {
    window._laq = [];
  }
  window._laq.push(function () {
    liveagent.showWhenOnline(
      '5734J000000gIns',
      document.getElementById('liveagent_button_online_5734J000000gIns'),
    );
  });
}

function usSalesforceChatInit() {
  liveagent.init(
    'https://d.la1w1.salesforceliveagent.com/chat',
    '572d0000000Gwon',
    '00Dd0000000iEuw',
  );

  //Set subject in the format: <Account name> + <Contact name OR email> + 'Chat'
  const caseSubjectTemplate = (accountName, userName) =>
    `${window.activeUser?.account?.name} + ${userName} + Chat`;

  //custom details are stored in object ConversationContextEntry
  //The alternative for Salesforce pre-chat window, comment code below if you set prechat window for the button
  liveagent.addCustomDetail(
    'Account Name',
    window.activeUser?.account?.name,
    true,
  );
  liveagent.addCustomDetail('Email', window.activeUser?.username, true);
  liveagent.addCustomDetail('Last Name', window.activeUser?.username, true);
  liveagent.addCustomDetail(
    'First Name',
    window.activeUser?.firstname === null ? '' : window.activeUser?.firstname,
    true,
  );

  // Do not modify
  liveagent.addCustomDetail(
    'ContactRecordTypeId',
    /*UK Sales*/ '012w0000000KBxXAAW',
    false,
  );
  // Do not modify
  liveagent.addCustomDetail(
    'CaseRecordTypeId',
    /*Go Case*/ '0122X000000oqaCQAQ',
    false,
  );

  liveagent.addCustomDetail(
    'Case Subject',
    caseSubjectTemplate(
      window.activeUser?.account?.name,
      window.activeUser?.username,
    ),
    false,
  );
  liveagent.addCustomDetail(
    'Global_Customer_Id__c',
    window.activeUser?.account?.gcId,
    false,
  );

  liveagent
    .findOrCreate('Case')
    .map('Subject', 'Case Subject', false, false, true) //false,false,true = do not search, not exact search, add subject to new record
    .map('RecordTypeId', 'CaseRecordTypeId', false, false, true)
    .saveToTranscript('CaseId')
    .showOnCreate();

  liveagent
    .findOrCreate('Contact')
    .map('Email', 'Email', true, true, true) //true,true,true = search by email, exact search, add email to new record
    .map('FirstName', 'First Name', false, false, true)
    .map('LastName', 'Last Name', false, false, true)
    .map('RecordTypeId', 'ContactRecordTypeId', false, false, true);

  liveagent
    .findOrCreate('Account')
    .map('Global_Customer_Id__c', 'Global_Customer_Id__c', true, true, false);

  liveagent
    .findOrCreate('Contact')
    .saveToTranscript('ContactId')
    .showOnCreate()
    .linkToEntity('Case', 'ContactId');

  liveagent
    .findOrCreate('Account')
    .saveToTranscript('AccountId')
    .showOnCreate()
    .linkToEntity('Case', 'AccountId')
    .linkToEntity('Contact', 'AccountId');

  // format: <Account Name>, <user name>
  const chatUserNameTemplate = (accountName, userName) =>
    `${accountName}, ${userName}`;

  liveagent.setName(
    chatUserNameTemplate(
      window.activeUser?.account?.name,
      window.activeUser?.username,
    ),
  );
  liveagent.setChatWindowWidth(500);
  liveagent.setChatWindowHeight(500);
  //<<< writes logs to developer console, @comment this line in production
  //liveagent.enableLogging();

  if (!window._laq) {
    window._laq = [];
  }
  window._laq.push(function () {
    liveagent.showWhenOnline(
      '573d0000000GxI1',
      document.getElementById('liveagent_button_online_573d0000000GxI1'),
    );
  });
}

export default function (usSalesforceChatEnabled, ukSalesforceChatEnabled) {
  //unload any script loaded from the grails side
  delete window.liveagent;
  delete window.liveAgentDeployment;
  window.liveagent = {
    _: {
      handlePing: () => {
        /*do nothing supress*/
      },
    },
  };

  if (!usSalesforceChatEnabled && !ukSalesforceChatEnabled) {
    return;
  }

  const salesforceFile = usSalesforceChatEnabled
    ? 'https://c.la1w1.salesforceliveagent.com/content/g/js/33.0/deployment.js'
    : 'https://c.la1-c2-lo3.salesforceliveagent.com/content/g/js/53.0/deployment.js';
  const callbackFn = usSalesforceChatEnabled
    ? usSalesforceChatInit
    : ukSalesforceChatInit;

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = salesforceFile;
  s.onload = callbackFn;
  document.getElementsByTagName('body')[0].appendChild(s);

  if (usSalesforceChatEnabled) {
    setTimeout(() => {
      const mutationObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'style'
          ) {
            if (document.getElementById('email_support_off_hours')) {
              document.getElementById('email_support_off_hours').style.display =
                document.getElementById(
                  'liveagent_button_online_573d0000000GxI1',
                ).style.display === 'none'
                  ? 'flex'
                  : 'none';
            }
          }
        });
      });
      const targetNode = document.getElementById(
        'liveagent_button_online_573d0000000GxI1',
      );
      mutationObserver.disconnect();
      if (targetNode) {
        mutationObserver.observe(targetNode, {
          attributes: true,
          attributeOldValue: true,
        });
      }
    }, 3000);
  }
}
