export const ADD_TERM = 'ADD_TERM';
export const DELETE_TERM = 'DELETE_TERM';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const CLEAR_ERROR_FROM_ALL = 'CLEAR_ERROR_FROM_ALL';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SET_SEARCH_BUILDER_DATA = 'SET_SEARCH_BUILDER_DATA';
export const SET_ADVANCED_SEARCH_QUERY = 'SET_UPDATE_SEARCH_QUERY';
export const CLEAN_UP = 'CLEAN_UP';
export const TOGGLE_ADULT_CONTENT = 'TOGGLE_ADULT_CONTENT';
export const TOGGLE_QUALITY_FILTER = 'TOGGLE_QUALITY_FILTER';
export const PICK_CHART_COLOR = 'PICK_CHART_COLOR';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_SUBSCRIPTION_UPDATE_IN_PROGRESS =
  'SET_SUBSCRIPTION_UPDATE_IN_PROGRESS';
export const SET_SEARCH_DATA_LOADING = 'SET_SEARCH_DATA_LOADING';
export const TOGGLE_SYSTEM_SEARCH = 'TOGGLE_SYSTEM_SEARCH';
export const SET_SYSTEM_SEARCH_PUB_STATUS = 'SET_SYSTEM_SEARCH_PUB_STATUS';
export const SET_ACTIVE_IN = 'SET_ACTIVE_IN';
export const SET_AUDIT_LOG = 'SET_AUDIT_LOG';
export const UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA';
export const SET_SEARCH_TITLE = 'SET_SEARCH_TITLE';
export const SET_SEARCH_UPDATE_IN_PROGRESS = 'SET_SEARCH_UPDATE_IN_PROGRESS';
export const UPDATE_SHARE_PERMISSIONS = 'UPDATE_SHARE_PERMISSIONS';
export const SET_SEARCH_SHARED_AND_ACCESS_CONTROL_GROUPS =
  'SET_SEARCH_SHARED_AND_ACCESS_CONTROL_GROUPS';
export const CHANGE_CONVERT_TO_ADVANCED_SEARCH_STATUS =
  'CHANGE_CONVERT_TO_ADVANCED_SEARCH_STATUS';
export const UPDATE_PROCESS_SUCCESSFUL = 'UPDATE_PROCESS_SUCCESSFUL';
