export default {
  showUserLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_SHOW_USER_LABEL',
    defaultMessage: 'Show User',
    description: 'Admin Page > Edit User: Show user label',
  },
  editUserLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_LABEL',
    defaultMessage: 'Edit User',
    description: 'Admin Page > Edit User: Edit user label',
  },
  userIdLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_ID_LABEL',
    defaultMessage: 'User Id',
    description: 'Admin Page > Edit User: User id label',
  },
  userNameLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_USER_NAME_LABEL',
    defaultMessage: 'User Name',
    description: 'Admin Page > Edit User: User name label',
  },
  sfdcIdLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_SFDC_ID_LABEL',
    defaultMessage: 'SFDC ID',
    description: 'Admin Page > Edit User: sfdcId label',
  },
  accountNameLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_ACCOUNT_NAME_LABEL',
    defaultMessage: 'Account Name',
    description: 'Admin Page > Edit User: Account name label',
  },
  accountStatusLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_ACCOUNT_STATUS_LABEL',
    defaultMessage: 'Account Status',
    description: 'Admin Page > Edit User: Account status label',
  },
  currentAccountLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_CURRENT_ACCOUNT_LABEL',
    defaultMessage: 'Account',
    description: 'Admin Page > Edit User: Account label',
  },
  userStatusLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_STATUS_LABEL',
    defaultMessage: 'User Status',
    description: 'Admin Page > Edit User: User status label',
  },
  passwordLockedLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_PASSWORD_LOCKED_LABEL',
    defaultMessage: 'Password Locked',
    description: 'Admin Page > Edit User: Password locked label',
  },
  passwordExpiredLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_PASSWORD_EXPIRED_LABEL',
    defaultMessage: 'Password Expired',
    description: 'Admin Page > Edit User: Password expired label',
  },
  dateCreatedLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_DATE_CREATED_LABEL',
    defaultMessage: 'Date Created',
    description: 'Admin Page > Edit User: Date created label',
  },
  dateLastUpdatedLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_DATE_LAST_UPDATED_LABEL',
    defaultMessage: 'Date Last Updated',
    description: 'Admin Page > Edit User: Date last updated label',
  },
  userRolesLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_ROLES_LABEL',
    defaultMessage: 'User Roles',
    description: 'Admin Page > Edit User: User roles label',
  },
  userSearchesLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_SEARCHES_LABEL',
    defaultMessage: 'Searches Created by the User',
    description: 'Admin Page > Edit User: User searches label',
  },
  userFeaturesLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_FEATURES_LABEL',
    defaultMessage: 'User Features',
    description: 'Admin Page > Edit User: User features label',
  },
  userDevFeaturesLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_DEV_FEATURES_LABEL',
    defaultMessage: 'User Dev Features',
    description: 'Admin Page > Edit User: User dev features label',
  },
  userEmailsLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_EMAILS_LABEL',
    defaultMessage: 'User Emails',
    description: 'Admin Page > Edit User: User emails label',
  },
  userCompaniesLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_COMPANIES_LABEL',
    defaultMessage: 'User Companies',
    description: 'Admin Page > Edit User: User companies label',
  },
  userMixPanelsLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_MIX_PANELS_LABEL',
    defaultMessage: 'User Mix Panels',
    description: 'Admin Page > Edit User: User mix panels label',
  },
  userSettingsLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_SETTINGS_LABEL',
    defaultMessage: 'User Settings',
    description: 'Admin Page > Edit User: User settings label',
  },
  activeValue: {
    id: 'ADMIN_PAGE_EDIT_USER_ACTIVE_VALUE',
    defaultMessage: 'Active',
    description: 'Admin Page > Edit User: Active value',
  },
  deactivatedValue: {
    id: 'ADMIN_PAGE_EDIT_USER_DEACTIVATED_VALUE',
    defaultMessage: 'Deactivated',
    description: 'Admin Page > Edit User: Deactivated value',
  },
  lockedValue: {
    id: 'ADMIN_PAGE_EDIT_USER_LOCKED_VALUE',
    defaultMessage: 'Locked',
    description: 'Admin Page > Edit User: Locked value',
  },
  notAvailableValue: {
    id: 'ADMIN_PAGE_EDIT_USER_NOT_AVAILABLE_VALUE',
    defaultMessage: 'Not available',
    description: 'Admin Page > Edit User: Not available value',
  },
  enterErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_ENTER_ERROR_MESSAGE',
    defaultMessage: 'Please enter a',
    description: 'Admin Page > Edit User: Enter error message',
  },
  invalidEmailErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_INVALID_EMAIL_ERROR_MESSAGE',
    defaultMessage: 'Please enter a valid email',
    description: 'Admin Page > Edit User: Invalid email message',
  },
  getUserErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_GET_USER_ERROR_MESSAGE',
    defaultMessage: 'Could not get User Id {USER_ID}',
    description: 'Admin Page > Edit User: Get user error message',
  },
  getUsersErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_GET_USERS_ERROR_MESSAGE',
    defaultMessage: 'Could not get Users',
    description: 'Admin Page > Edit User: Get users error message',
  },
  editUserSuccessMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_SUCCESS_MESSAGE',
    defaultMessage: 'Success! You have edited {USER_NAME}',
    description: 'Admin Page > Edit User: Edit user success message',
  },
  alreadyExistsErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_ALREADY_EXISTS_ERROR_MESSAGE',
    defaultMessage: 'User already exists.',
    description:
      'Admin Page > Edit User: Edit user that already exists error message',
  },
  editUserDefaultErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_DEFAULT_ERROR_MESSAGE',
    defaultMessage: 'Could not edit user {USER_NAME}, please review the data.',
    description:
      'Admin Page > Edit User: Default text for editing a user error message',
  },
  resetPasswordLabel: {
    id: 'ADMIN_PAGE_EDIT_USER_RESET_PASSWORD_LABEL',
    defaultMessage: 'Reset Password',
    description: 'Admin Page > Edit User: Reset password label',
  },
  resetPasswordSuccessMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_RESET_PASSWORD_SUCCESS_MESSAGE',
    defaultMessage: 'Success! Temporary password is: {USER_PASSWORD}',
    description: 'Admin Page > Edit User: Reset password success message',
  },
  resetPasswordDefaultErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_USER_RESET_PASSWORD_DEFAULT_ERROR_MESSAGE',
    defaultMessage: 'Could not reset user password.',
    description: 'Admin Page > Edit User: Reset password error message',
  },
};
