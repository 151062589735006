import React, { Component } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button, EmptyState, Headline, Body, Paper } from '@trendkite/ui';

import safe from '../assets/icons/safe.svg';

const baseClass = 'tk-story';

class StoryPaused extends Component {
  renderHeader = () => {
    const { content, publishDate } = this.props;
    const { pressContact } = content;

    return (
      <div className={`${baseClass}__byline`}>
        <Headline>Story Kit</Headline>
        <Body color="light">
          Published
          {publishDate ? moment(publishDate).format(' MMM D, YYYY ') : ' '}
          by {pressContact.firstName} {pressContact.lastName}
        </Body>
      </div>
    );
  };

  renderMessage = () => {
    const contact = this.props.content.pressContact;

    return (
      <Body>
        This story&#39;s content might be getting updated or it has been
        archived. If you think you should have access to this story please reach
        out to {contact.firstName} {contact.lastName} at
        <Button modifiers={['link']} location={`mailTo:${contact.email}`}>
          {contact.email || ''}
        </Button>
        {contact.phone ? ` or ${contact.phone}` : ''}
      </Body>
    );
  };

  render() {
    const containerClasses = classNames(baseClass, 'story-revoked');
    const message = this.renderMessage();
    const { content, tkUrl } = this.props;
    const { pressContact } = content;

    return (
      <div className={containerClasses}>
        <div className={`${baseClass}__area`}>
          <div className={`${baseClass}__box`}>
            <Paper padding="40px">
              {this.renderHeader()}
              <div className={`${baseClass}__content`}>
                <EmptyState
                  imageUrl={`#${safe.id}`}
                  title={`Bummer, it looks ${pressContact.firstName} has revoked access to this story.`}
                  text={message}
                />
              </div>
            </Paper>
          </div>
          <div className={`${baseClass}__footnote`}>
            <Body color="light">
              This landing page was built using the Cision platform.
              <Button modifiers={['link']} location={tkUrl}>
                {' '}
                Learn more about Cision
              </Button>
            </Body>
          </div>
        </div>
      </div>
    );
  }
}

StoryPaused.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string,
    pressContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  publishDate: PropTypes.string,
  tkUrl: PropTypes.string.isRequired,
};

StoryPaused.defaultProps = {
  publishDate: '',
};

export default StoryPaused;
