import React, { Component } from 'react';
import PropTypes from 'prop-types';

const imageMimeTypes = ['image/bmp', 'image/jpeg', 'image/png'];

class ImageCanvasProcessor extends Component {
  static supportsCanvasExport(mimeType) {
    return (
      'toBlob' in window.HTMLCanvasElement.prototype &&
      !!imageMimeTypes.includes(mimeType)
    );
  }

  state = {
    canvasTop: 999,
    canvasRight: 999,
  };

  componentDidMount() {
    const { imageSrc } = this.props;
    if (imageSrc) {
      this.image = new Image();
      this.image.crossOrigin = 'anonymous';
      this.image.onload = this.imageLoaded;
      this.image.src = imageSrc;
    }
  }

  componentWillUnmount() {
    this.image = null;
    URL.revokeObjectURL(this.state.blob);
  }

  imageLoaded = event => {
    if (this.canvas.current) {
      this.canvas.current.width = event.target.naturalWidth;
      this.canvas.current.height = event.target.naturalHeight;
      if (
        event.target.naturalWidth > this.state.canvasRight &&
        event.target.naturalHeight > this.state.canvasTop
      ) {
        this.setState({
          canvasTop: event.target.naturalHeight + 20,
          canvasRight: event.target.naturalWidth + 20,
        });
      }
      this.canvas.current.getContext('2d').drawImage(event.target, 0, 0);
      this.canvas.current.toBlob(blob => {
        const blobUrl = URL.createObjectURL(blob);
        this.props.onCreateBlobUrl(blobUrl);
        this.setState({ blob: blobUrl });
      }, this.props.filetype);
    }
  };

  canvas = React.createRef();
  render() {
    const { canvasTop, canvasRight } = this.state;
    return (
      <canvas
        style={{
          position: 'absolute',
          top: `-${canvasTop}em`,
          right: `-${canvasRight}em`,
          opacity: 0,
        }}
        ref={this.canvas}
      />
    );
  }
}

ImageCanvasProcessor.propTypes = {
  imageSrc: PropTypes.string,
  filetype: PropTypes.string,
  onCreateBlobUrl: PropTypes.func.isRequired,
};

ImageCanvasProcessor.defaultProps = {
  imageSrc: '',
  filetype: 'image/png',
};

export default ImageCanvasProcessor;
