export default {
  footerBack: {
    id: 'DASHBOARD_CREATION_WIZARD_FOOTER_BACK',
    description: 'Dashboards v2.0: dashboard creation wizard footer button to peform previous step',
    defaultMessage: 'Back',
  },
  footerNext: {
    id: 'DASHBOARD_CREATION_WIZARD_FOOTER_NEXT_STEP',
    description: 'Dashboards v2.0: dashboard creation wizard footer button to peform next step',
    defaultMessage: 'Next Step',
  },
  footerCreateDashboard: {
    id: 'DASHBOARD_CREATION_WIZARD_FOOTER_CREATE',
    description: 'Dashboards v2.0: dashboard creation wizard footer button to create a dashboard',
    defaultMessage: 'Create Dashboard',
  },
  templateSelectionHeaderTitle: {
    id: 'DASHBOARD_CREATION_WIZARD_HEADER_TEMPLATE_SELECTION_TITLE',
    description: 'Dashboards v2.0: dashboard creation wizard header title for selecting a template',
    defaultMessage: 'New Dashboard - 1 - Select a Template',
  },
  baseSettingsHeaderTitle: {
    id: 'DASHBOARD_CREATION_WIZARD_HEADER_BASE_SETTINGS_TITLE',
    description: 'Dashboards v2.0: dashboard creation wizard header title for base settings',
    defaultMessage: 'New Dashboard - 2 - Base Settings',
  },
  additionalSearchesHeaderTitle: {
    id: 'DASHBOARD_CREATION_WIZARD_HEADER_SOV_TITLE',
    description: 'Dashboards v2.0: dashboard creation wizard header title for share of voice settings',
    defaultMessage: 'New Dashboard - 3.1 - Additional Searches',
  },
  keyMessagesHeaderTitle: {
    id: 'DASHBOARD_CREATION_WIZARD_HEADER_KEY_MESSAGES_SETTINGS_TITLE',
    description: 'Dashboards v2.0: dashboard creation wizard header title for key message settings',
    defaultMessage: 'New Dashboard - 3.2 - Key Messages',
  },
  tagsTitle: {
    id: 'DASHBOARD_CREATION_WIZARD_HEADER_TAGS_TITLE',
    description: 'Dashboards v2.0: dashboard creation wizard header title for tags',
    defaultMessage: 'New Dashboard - 3.3 - Tags',
  },
  dashboardCreationSuccessMessage: {
    id: 'DASHBOARD_CREATION_WIZARD_SUCCESS_MESSAGE',
    description: 'Dashboards v2.0: Kite message indicating that a dashboard was succesfully generated from template',
    defaultMessage: 'Dashboard created.',
  },
};
