import {
  OUTREACH_DKIM_DOMAIN_ENDPOINT,
  OUTREACH_DKIM_STATUS_ENDPOINT,
} from 'constants/apis';
import {
  addPageMessage,
  removePageMessageActionCreator,
} from 'reducers/page-messages';
import { performGet, performPost } from 'services/rest-service/rest-service';

export const CREATE_RECORD = 'adminDkimManagementReducer/CREATE_RECORD';
export const CREATE_RECORD_SUCCESS =
  'adminDkimManagementReducer/CREATE_RECORD_SUCCESS';
export const CREATE_RECORD_ERROR =
  'adminDkimManagementReducer/CREATE_RECORD_ERROR';
export const LIST_RECORD = 'adminDkimManagementReducer/LIST_RECORD';
export const LIST_RECORD_SUCCESS =
  'adminDkimManagementReducer/LIST_RECORD_SUCCESS';
export const LIST_RECORD_ERROR = 'adminDkimManagementReducer/LIST_RECORD_ERROR';
export const VALIDATE_RECORD = 'adminDkimManagementReducer/VALIDATE_RECORD';
export const VALIDATE_RECORD_SUCCESS =
  'adminDkimManagementReducer/VALIDATE_RECORD_SUCCESS';
export const VALIDATE_RECORD_ERROR =
  'adminDkimManagementReducer/VALIDATE_RECORD_ERROR';

export const initialState = {
  createDkimRecord: {
    loading: false,
    error: null,
  },
  accountRecords: {
    records: {},
    loading: false,
    error: null,
  },
  validateRecord: {
    loading: false,
    error: null,
  },
};

const adminDkimManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_RECORD:
      return {
        ...state,
        createDkimRecord: {
          loading: true,
          error: false,
        },
      };
    case CREATE_RECORD_SUCCESS:
      return {
        ...state,
        createDkimRecord: {
          loading: false,
        },
      };
    case CREATE_RECORD_ERROR:
      return {
        ...state,
        createDkimRecord: {
          loading: false,
          error: true,
        },
      };
    case LIST_RECORD:
      return {
        ...state,
        accountRecords: {
          ...state.accountRecords,
          records: {},
          loading: true,
          error: false,
        },
      };
    case LIST_RECORD_SUCCESS:
      return {
        ...state,
        accountRecords: {
          ...state.accountRecords,
          records: { ...action.payload },
          loading: false,
        },
      };
    case LIST_RECORD_ERROR:
      return {
        ...state,
        accountRecords: {
          ...state.accountRecords,
          loading: false,
          error: action.payload.error,
        },
      };
    case VALIDATE_RECORD:
      return {
        ...state,
        validateRecord: {
          loading: true,
          error: false,
        },
      };
    case VALIDATE_RECORD_SUCCESS:
      return {
        ...state,
        validateRecord: {
          loading: false,
        },
      };
    case VALIDATE_RECORD_ERROR:
      return {
        ...state,
        validateRecord: {
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

const gcIdHeader = 'X-Gc-Id';

export const getDkimDomainByGcId = (gcId, intl) => async dispatch => {
  dispatch({ type: LIST_RECORD });

  try {
    const response = await performGet(
      `${OUTREACH_DKIM_DOMAIN_ENDPOINT}`,
      null,
      gcId ? { [gcIdHeader]: gcId } : {},
    );

    dispatch({
      type: LIST_RECORD_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: LIST_RECORD_ERROR,
      payload: {
        error: true,
      },
    });

    let additionalActions = {};
    if (e.response?.status !== 404) {
      additionalActions = {
        actionText: 'Retry',
        action: () => {
          dispatch(getDkimDomainByGcId(gcId, intl));
          dispatch(removePageMessageActionCreator());
        },
      };
    }

    dispatch(
      addPageMessage({
        isNewUI: true,
        text: `Failed to fetch DKIM records: ${e.response?.data?.error || ''}`,
        status: 'danger',
        ...additionalActions,
      }),
    );
  }
};

export const createDkimRecordByGcId = (
  gcId,
  domain,
  customDkimSelector,
  intl,
) => async dispatch => {
  dispatch({ type: CREATE_RECORD });

  try {
    await performPost(
      `${OUTREACH_DKIM_DOMAIN_ENDPOINT}`,
      { domain, customDkimSelector },
      null,
      gcId ? { [gcIdHeader]: gcId } : {},
    );

    dispatch({ type: CREATE_RECORD_SUCCESS });
    dispatch(getDkimDomainByGcId(gcId, intl));

    dispatch(
      addPageMessage({
        text: `Successfully created DKIM record for ${domain}`,
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({ type: CREATE_RECORD_ERROR });

    dispatch(
      addPageMessage({
        text: `Failed to create DKIM record: ${
          e.response?.data?.[0]?.error || ''
        }`,
        status: 'danger',
        ttl: 10000,
      }),
    );
  }
};

export const validateDkimRecordByGcId = (
  gcId,
  domain,
  intl,
) => async dispatch => {
  dispatch({ type: VALIDATE_RECORD });

  try {
    await performGet(
      `${OUTREACH_DKIM_STATUS_ENDPOINT}`,
      { domain },
      gcId ? { [gcIdHeader]: gcId } : {},
    );

    dispatch({ type: VALIDATE_RECORD_SUCCESS });
    dispatch(getDkimDomainByGcId(gcId, intl));
  } catch (e) {
    dispatch({ type: VALIDATE_RECORD_ERROR });

    dispatch(
      addPageMessage({
        text: 'Failed to validate DKIM record',
        status: 'danger',
        actionText: 'Retry',
        action: () => {
          dispatch(validateDkimRecordByGcId(gcId, domain, intl));
          dispatch(removePageMessageActionCreator());
        },
      }),
    );
  }
};

export default adminDkimManagementReducer;
