// Default descending order by recency ( EVER-12581 ) by simply knowing that newest phrases have highest IDs
// since they were created in order

import { PhraseTranslationViewModel } from '../types/PhraseTranslationViewModel';
import { PhraseViewModel } from '../types/PhraseViewModel';

// used as safeguard against client created phrases with no ID.
const FALLBACK_ID = Number.MAX_SAFE_INTEGER;
export function defaultPhraseViewModelComparer(
  a: PhraseViewModel,
  b: PhraseViewModel,
) {
  const v = (b.phraseId ?? FALLBACK_ID) - (a.phraseId ?? FALLBACK_ID);
  return v !== 0 ? v : a.phraseKey.localeCompare(b.phraseKey); // if equal ID (no idea how but), do key compare
}

/**
 * Sorts translations by logic:
 *  1. Default language
 *  2. Is Default Region In Default Language
 *  3. Name in Default Language
 *  4. Language Name
 *  5. Region Name fro each Language
 * @param a
 * @param b
 */
export function defaultPhraseTranslationComparer(
  a: PhraseTranslationViewModel,
  b: PhraseTranslationViewModel,
) {
  if (a.isRootLanguage !== b.isRootLanguage) {
    return a.isRootLanguage ? -1 : 1;
  }

  if (a.isDefaultUserLanguage !== b.isDefaultUserLanguage) {
    return a.isDefaultUserLanguage ? -1 : 1;
  }

  if (a.languageName !== b.languageName) {
    return (a.languageName ?? '').localeCompare(b.languageName);
  }

  if (a.isRootRegion !== b.isRootRegion) {
    return a.isRootRegion ? -1 : 1;
  }

  if (a.isDefaultUserRegion !== b.isDefaultUserRegion) {
    // we trust that noone is silly enough to set different languages
    // to default, so only need to check a
    return a.isDefaultUserRegion ? -1 : 1;
  }

  return (a.regionName ?? '').localeCompare(b.regionName ?? '');
}
