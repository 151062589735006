import qs from 'qs';

import { replaceContactsInList } from 'actions';
import { CONTACT_BASE_ENDPOINT } from 'constants/apis';
import { CONTACTS_RESULTS_LIST } from 'constants/constants';
import { addContacts } from 'reducers/contacts/contacts';
import { performGet } from 'services/rest-service/rest-service';

export const CLEAR_CONTACTS_SEARCH_FILTERS =
  'contacts-search/CLEAR_CONTACTS_SEARCH_FILTERS';
export const CLEAR_CONTACTS_SEARCH_RESULTS =
  'contacts-search/CLEAR_CONTACTS_SEARCH_RESULTS';
export const UPDATE_CONTACTS_SEARCH_FILTERS =
  'contacts-search/UPDATE_CONTACTS_SEARCH_FILTERS';
export const LOAD_CONTACTS_SEARCH_REQUEST =
  'contacts-search/LOAD_CONTACTS_SEARCH_REQUEST';
export const LOAD_CONTACTS_SEARCH_SUCCESS =
  'contacts-search/LOAD_CONTACTS_SEARCH_SUCCESS';
export const LOAD_CONTACTS_SEARCH_ERROR =
  'contacts-search/LOAD_CONTACTS_SEARCH_ERROR';

const initialState = {
  error: false,
  searchFilters: {},
  resultsLoaded: false,
  resultsLoading: false,
};

const contactsSearch = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CONTACTS_SEARCH_RESULTS: {
      return {
        ...state,
        resultsLoaded: false,
        resultsLoading: false,
      };
    }
    case UPDATE_CONTACTS_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload,
        },
      };
    }
    case LOAD_CONTACTS_SEARCH_REQUEST: {
      return {
        ...state,
        error: false,
        resultsLoaded: false,
        resultsLoading: true,
      };
    }
    case LOAD_CONTACTS_SEARCH_SUCCESS: {
      return {
        ...state,
        error: false,
        resultsLoaded: true,
        resultsLoading: false,
      };
    }
    case LOAD_CONTACTS_SEARCH_ERROR: {
      return {
        ...state,
        error: true,
        resultsLoaded: false,
        resultsLoading: false,
      };
    }
    case CLEAR_CONTACTS_SEARCH_FILTERS: {
      return {
        ...state,
        searchFilters: {},
      };
    }
    default:
      return state;
  }
};

export const clearContactsSearchResults = () => ({
  type: CLEAR_CONTACTS_SEARCH_RESULTS,
});

export const updateContactSearchFilters = searchFilters => ({
  type: UPDATE_CONTACTS_SEARCH_FILTERS,
  payload: searchFilters,
});

export const clearContactSearchFilters = () => ({
  type: CLEAR_CONTACTS_SEARCH_FILTERS,
});

export const loadingContactsSearch = () => ({
  type: LOAD_CONTACTS_SEARCH_REQUEST,
});

export const performContactsSearch = searchParams => dispatch => {
  dispatch(loadingContactsSearch());

  performGet(`${CONTACT_BASE_ENDPOINT}/search`, searchParams, null, {
    paramsSerializer: p => qs.stringify(p, { indices: false }),
  })
    .then(response => {
      dispatch({ type: LOAD_CONTACTS_SEARCH_SUCCESS, payload: response.data });
      dispatch(replaceContactsInList(response.data, CONTACTS_RESULTS_LIST));
      dispatch(addContacts(response.data));
    })
    .catch(error => {
      dispatch({ type: LOAD_CONTACTS_SEARCH_ERROR, payload: error });
    });
};

export default contactsSearch;
