import { ARTICLE_METRICS } from 'constants/constants';
import { WIDGET_DESCRIPTIONS } from 'constants/widgets';

export const getDescriptionForDrilldownType = type => {
  return WIDGET_DESCRIPTIONS[type] || '';
};

export const correctMissingDataPoints = article => {
  if (!article) {
    return false;
  }
  const metrics = Object.keys(ARTICLE_METRICS);

  metrics.forEach(metric => {
    if (!article[metric] || article[metric] === -1) {
      article[metric] = '-';
    }
  });
  return article;
};
