import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Button from '../button';
import Input from '../forms/Input';
import globalMessages from '../Global.messages';
import SvgIcon from '../svg-icon';

// i18n

import messages from './SearchInput.messages';

export class SearchInput extends Component {
  state = {
    clearButtonVisible: this.props.searchQuery !== '',
    focus: this.props.focus,
    query: this.props.searchQuery,
    queryDirty: false,
  };

  componentDidUpdate(prevProps) {
    const { searchQuery } = this.props;
    if (prevProps.searchQuery !== searchQuery) {
      this.setState({
        clearButtonVisible: searchQuery !== '',
        query: searchQuery,
      });
    }
  }

  clear = (performCallback = true) => {
    this.setState({
      clearButtonVisible: false,
      query: '',
      queryDirty: false,
    });

    this.props.onClear();

    if (performCallback) {
      this.props.performSearchCallback('');
    }
  };

  handleClearClick = () => {
    this.clear(true);
  };

  handleKeyDown = event => {
    if (event.key === 'ArrowDown') {
      this.props.onDownArrow(event);
    }
  };

  handleKeyUp = event => {
    if (this.props.searchAsYouType) {
      this.performSearch();
    }
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.performSearch();
    }
  };

  handleQueryBlur = event => {
    // clear results if user deletes query and clicks out
    if (this.state.queryDirty && event.target.value === '') {
      this.props.performSearchCallback('');
    }

    this.setState({
      focus: false,
    });
  };

  handleQueryChange = event => {
    this.setState({
      clearButtonVisible: event.target.value !== '',
      query: event.target.value,
    });

    this.props.handleChange(event);
  };

  handleQueryFocus = () => {
    this.setState({
      focus: true,
    });
  };

  performSearch = () => {
    this.props.performSearchCallback(this.state.query?.trim());

    this.setState({
      queryDirty: true,
      focus: true,
    });
  };

  focus = () => {
    if (this.inputElement) {
      this.inputElement.focus();
    }
  };

  render() {
    const {
      closeCallback,
      extraButton,
      inputStyle,
      maxlength,
      modifiers,
      placeholder,
      style,
      alwaysShowClear,
      intl,
      inputDataQa,
    } = this.props;
    const { focus, query } = this.state;
    const baseClass = 'tk-searchInput';
    const safeModifiers = Array.isArray(modifiers) ? modifiers : [];
    const prefixedModifiers = safeModifiers.map(
      modifier => `${baseClass}--${modifier}`,
    );
    const slim = safeModifiers.indexOf('slim') >= 0;
    const filterbar = safeModifiers.indexOf('filterbar') >= 0;
    const leftbutton = slim || safeModifiers.indexOf('leftbutton') >= 0;
    const rightbutton = safeModifiers.indexOf('rightbutton') >= 0;
    const autoComplete =
      safeModifiers.indexOf('autocomplete-off') >= 0 ? 'off' : 'on'; // non-camelcased modifier matches html attribute
    const searchInputAddonWidth = 14;
    const searchInputSpacerWidth = 10;
    const addonClasses = ['seethrough'];
    const inputClasses = [];
    const finalInputDataQa = inputDataQa || 'SearchInput query';

    if (filterbar) {
      inputClasses.push('seethrough');
    }

    if (slim) {
      inputClasses.push('seethrough');
      inputClasses.push('small');
    }

    const mainClass = classnames(baseClass, prefixedModifiers, {
      [`${baseClass}--active`]: query || alwaysShowClear,
      [`${baseClass}--focus`]: focus,
    });

    const inputClass = `${baseClass}__input`;
    const searchAddonClass = `${baseClass}__button`;
    const clearAddonClass = `${baseClass}__cancel`;
    const iconClearAddonClass = `${baseClass}__cancelIcon`;
    const inputRightPadding =
      filterbar || slim || leftbutton
        ? 0
        : 3 * searchInputSpacerWidth + 2 * searchInputAddonWidth;
    const inputRightPaddingString = `${inputRightPadding}px`;
    let inputLeftPadding = leftbutton && !slim ? 3 * searchInputSpacerWidth : 0;
    if (rightbutton) {
      inputLeftPadding = searchInputAddonWidth;
    }

    const inputLeftPaddingString = `${inputLeftPadding}px`;

    return (
      <React.Fragment>
        <div className={mainClass} style={style}>
          {leftbutton && (
            <div className={searchAddonClass}>
              <Button
                data-qa="kdHIWLtGda6RtMlG14Yyq"
                onClick={this.performSearch}
                modifiers={addonClasses}
              >
                <SvgIcon width={20} height={20} icon="search" />
              </Button>
            </div>
          )}
          <div className={inputClass}>
            <Input
              autoComplete={autoComplete}
              id={`${baseClass}-query`}
              data-qa={finalInputDataQa}
              focus={focus}
              maxlength={maxlength}
              modifiers={inputClasses}
              onBlur={this.handleQueryBlur}
              onChange={this.handleQueryChange}
              onFocus={this.handleQueryFocus}
              onKeyDown={this.handleKeyDown}
              onKeyPress={this.handleKeyPress}
              onKeyUp={this.handleKeyUp}
              paddingTop={null}
              paddingBottom={null}
              paddingRight={inputRightPaddingString}
              paddingLeft={inputLeftPaddingString}
              placeholder={`${
                placeholder || intl.formatMessage(messages.searchPlaceholder)
              }`}
              ref={ref => (this.inputElement = ref)}
              style={inputStyle}
              type="text"
              value={this.state.query}
            />
          </div>
          {!closeCallback && (
            <div className={clearAddonClass}>
              <Button
                data-qa="IUzdSp-zcrJgOCtjSWdOm"
                onClick={this.clear}
                disabled={!query && !alwaysShowClear}
                modifiers={addonClasses}
              >
                <div className={iconClearAddonClass}>
                  <SvgIcon icon="cross" />
                </div>
              </Button>
            </div>
          )}
          {closeCallback && (
            <div className={clearAddonClass}>
              <Button
                data-qa="tMCUVi_FMe_ADoM__2HHE"
                onClick={() => closeCallback('close-button')}
                disabled={!query && !alwaysShowClear}
                modifiers={addonClasses}
              >
                <div className={iconClearAddonClass}>
                  <SvgIcon icon="cross" />
                </div>
              </Button>
            </div>
          )}
          {!leftbutton && (
            <div className={searchAddonClass}>
              <Button
                data-qa="pIhcIt7r9-nIbmvnaNkuu"
                onClick={this.performSearch}
                modifiers={addonClasses}
              >
                <SvgIcon icon="search" />
              </Button>
            </div>
          )}
        </div>
        {extraButton && (
          <Button
            data-qa="6ocDFMn0CKzWb5GNJAv3v"
            onClick={this.performSearch}
            modifiers={['margin-left', 'round', 'tertiary']}
          >
            {intl.formatMessage(globalMessages.search)}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

SearchInput.propTypes = {
  alwaysShowClear: PropTypes.bool,
  closeCallback: PropTypes.func,
  extraButton: PropTypes.bool,
  inputStyle: PropTypes.object,
  focus: PropTypes.bool,
  handleChange: PropTypes.func,
  maxlength: PropTypes.number,
  modifiers: PropTypes.arrayOf(
    PropTypes.oneOf([
      'autocomplete-off',
      'dark',
      'filterbar',
      'leftbutton',
      'rightbutton',
      'slim',
    ]),
  ),
  onClear: PropTypes.func,
  onDownArrow: PropTypes.func,
  onMouseLeave: PropTypes.func,
  performSearchCallback: PropTypes.func,
  placeholder: PropTypes.string,
  inputDataQa: PropTypes.string,
  searchQuery: PropTypes.string,
  searchAsYouType: PropTypes.bool,
  style: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

SearchInput.defaultProps = {
  alwaysShowClear: false,
  extraButton: false,
  focus: false,
  inputStyle: {},
  handleChange: () => {},
  maxlength: null,
  modifiers: [],
  onClear: () => {},
  onDownArrow: () => {},
  placeholder: '',
  inputDataQa: '',
  searchQuery: '',
  searchAsYouType: false,
  style: {},
  performSearchCallback: () => {},
};

export default injectIntl(SearchInput, { forwardRef: true });
