import React, { useEffect } from 'react';

import { Button } from '@cision/rover-ui';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';

import {
  isTotalMentionsOverTime,
  isShareOfVoice,
  isReadershipOverTime,
  isReadershipShareOfVoice,
} from 'components/widgetV3/utils';
import {
  METRICS,
  checkForMetricMeasure,
} from 'components/widgetWizard/constants';
import styles from 'components/widgetWizard/steps/steps.module.css';
import { analyticByIdSelector } from 'components/widgetWizard/widget-wizard-selectors';
import { V3_METRIC_FIELDS } from 'constants/constants';

import {
  setCurrentlyEditedMetricActionCreator,
  updateAnalyticsActionCreator,
} from 'reducers/widget-form';

import Header from './components/header';

import MetricField from './components/metric-field';
import messages from './section-wizard.messages';

const MetricV3 = ({
  selectedAnalyticId,
  onAddMetric,
  selectedItemsCount,
  primaryMetric,
  secondaryMetric,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const analyticOptionsById = useSelector(analyticByIdSelector);

  let gaMetric;

  if (
    checkForMetricMeasure(primaryMetric, V3_METRIC_FIELDS.sessions) ||
    checkForMetricMeasure(primaryMetric, V3_METRIC_FIELDS.prTraffic)
  ) {
    gaMetric = primaryMetric;
  } else if (
    checkForMetricMeasure(secondaryMetric, V3_METRIC_FIELDS.sessions) ||
    checkForMetricMeasure(secondaryMetric, V3_METRIC_FIELDS.prTraffic)
  ) {
    gaMetric = secondaryMetric;
  }

  const handleSelectAnalytic = selectedId =>
    dispatch(updateAnalyticsActionCreator(selectedId));

  useEffect(() => {
    const analyticOptions = Object.values(analyticOptionsById);
    if (gaMetric && analyticOptions.length && !selectedAnalyticId) {
      const defaultAnalytic = analyticOptions.find(x => x.isDefault);
      handleSelectAnalytic(defaultAnalytic.analyticsId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddOrEditMetric = metricKey => {
    dispatch(setCurrentlyEditedMetricActionCreator(metricKey));
    onAddMetric();
  };

  let canAddAdditionalMetric = false;
  const metrics = compact([primaryMetric, secondaryMetric]);
  const visualizationTypes = uniq(metrics.map(x => x.visualizationType));
  if (!primaryMetric && !secondaryMetric) {
    canAddAdditionalMetric = true;
  } else if (primaryMetric && secondaryMetric) {
    canAddAdditionalMetric = false;
  } else if (gaMetric) {
    canAddAdditionalMetric = true;
  } else if (
    isTotalMentionsOverTime(metrics) ||
    isReadershipOverTime(metrics) ||
    ((isShareOfVoice(metrics) || isReadershipShareOfVoice(metrics)) &&
      isEqual(visualizationTypes, [undefined]))
  ) {
    canAddAdditionalMetric = true;
  }

  const renderMetricField = (metric, metricKey) => {
    return (
      <MetricField
        metric={metric}
        metricKey={metricKey}
        selectedAnalyticId={selectedAnalyticId}
        selectedItemsCount={selectedItemsCount}
        haveSecondaryMetric={!!secondaryMetric}
        handleSelectAnalytic={handleSelectAnalytic}
      />
    );
  };

  return (
    <div className={styles.sectionWrapper}>
      <Header
        title={intl.formatMessage(messages.titleMetricSection)}
        subTitle={
          !primaryMetric && !secondaryMetric
            ? intl.formatMessage(messages.subtitleEmptyMetricSection)
            : intl.formatMessage(messages.subtitleMetricSection)
        }
        showIconButton
        onIconButtonClick={() =>
          onAddOrEditMetric(
            !primaryMetric ? METRICS.primaryMetric : METRICS.secondaryMetric,
          )
        }
        iconButtonDisabled={!canAddAdditionalMetric}
      />
      {!primaryMetric && !secondaryMetric && (
        <div className={styles.metricsContainer}>
          <Button
            level="secondary"
            size="lg"
            onClick={() => onAddOrEditMetric(METRICS.primaryMetric)}
            data-qa="add-metric-button"
          >
            {intl.formatMessage(messages.subtitleMetricSection)}
          </Button>
        </div>
      )}
      {primaryMetric && renderMetricField(primaryMetric, METRICS.primaryMetric)}
      {secondaryMetric &&
        renderMetricField(secondaryMetric, METRICS.secondaryMetric)}
    </div>
  );
};

MetricV3.propTypes = {
  primaryMetric: PropTypes.object,
  secondaryMetric: PropTypes.object,
  selectedAnalyticId: PropTypes.number,
  onAddMetric: PropTypes.func.isRequired,
  selectedItemsCount: PropTypes.number.isRequired,
};

MetricV3.defaultProps = {
  primaryMetric: null,
  selectedAnalyticId: null,
  secondaryMetric: null,
};

export default MetricV3;
