import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {
  ARTICLE_LIST_EXPORT_ENDPOINT,
  SEARCH_DETAIL_ENDPOINT,
  API_BASE_URL_V2,
  API_BASE_URL_V3,
} from 'constants/apis';
import constantsMessages from 'constants/constants.messages';
import {
  advancedSearchQuerySelector,
  searchLastSavedSelector,
  searchEditInvalidSelector,
  searchPreviewPayloadSelector,
  simpleSearchQuerySelector,
} from 'pages/EarnedSearch/state/selectors/searchBuilderSelector';
import columnConfig, {
  DESKTOP_READERSHIP_COLUMN,
  DESKTOP_READERSHIP_NON_MOBILE_UVM_COLUMN,
} from 'pages/WidgetDrilldown/DrilldownArticleList/columnConfig';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { primarySearchSelector } from 'selectors/campaign';
import { exportsByIdsSelector } from 'selectors/exports';
import { currentStorySearchId } from 'selectors/stories/story-hub';
import { earnedSearchArrangeableColumnsSelector } from 'selectors/user-preferences';
import { getExportParamsFromList } from 'services/article-lists/article-lists';
import { performGet, performPost } from 'services/rest-service/rest-service';
import timeoutPromise from 'utils/timeout-promise';

export const CREATE_EXPORT = 'tk/exports/CREATE_EXPORT';
export const UPDATE_EXPORT = 'tk/exports/UPDATE_EXPORT';
export const UPDATE_EXPORT_ERROR = 'tk/exports/UPDATE_EXPORT_ERROR';
export const UPDATE_EXPORT_EXTERNAL_ID = 'tk/exports/UPDATE_EXPORT_EXTERNAL_ID';
export const UPDATE_EXPORT_SUCCEEDED = 'tk/exports/UPDATE_EXPORT_SUCCEEDED';

const initialState = {
  exports: {},
};

const nowUtcMoment = moment().utc();
const defaultStartDate = nowUtcMoment
  .subtract(90, 'days')
  .startOf('day')
  .toISOString();

const initialExportState = {
  attempts: 0,
  deliveryOption: 'DOWNLOAD',
  deliveryEmail: '',
  error: null,
  externalId: NaN,
  externalUrl: '',
  id: '',
  pending: true,
  started: defaultStartDate,
  succeeded: false,
};

const exportsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CREATE_EXPORT:
      return {
        ...state,
        exports: {
          ...state.exports,
          [action.payload.id]: {
            ...initialExportState,
            ...state[action.payload.id],
            ...action.payload,
          },
        },
      };
    case UPDATE_EXPORT:
      return {
        ...state,
        exports: {
          ...state.exports,
          [action.payload.id]: {
            ...state.exports[action.payload.id],
            ...action.payload,
          },
        },
      };
    case UPDATE_EXPORT_ERROR:
      return {
        ...state,
        exports: {
          ...state.exports,
          [action.payload.id]: {
            ...state.exports[action.payload.id],
            ...action.payload,
            pending: false,
            error: action.payload.error || true,
          },
        },
      };
    case UPDATE_EXPORT_EXTERNAL_ID:
      return {
        ...state,
        exports: {
          ...state.exports,
          [action.payload.id]: {
            ...state.exports[action.payload.id],
            externalId: action.payload.externalId,
          },
        },
      };
    case UPDATE_EXPORT_SUCCEEDED:
      return {
        ...state,
        exports: {
          ...state.exports,
          [action.payload.id]: {
            ...state.exports[action.payload.id],
            ...action.payload,
            succeeded: true,
            pending: false,
          },
        },
      };
    default:
      return state;
  }
};

export const createExport = (
  id,
  method,
  dashboardId,
  storyId,
  list,
  userMetrics,
  email,
  format,
  mobileUvmEnabled,
) => async (dispatch, getState) => {
  const state = getState();
  let exportApiPostParams = {
    ...getExportParamsFromList(dashboardId, storyId, list, userMetrics),
    deliveryEmail: email,
    deliveryOption: method,
    format,
  };
  const isDashboardV2Export = list.id.startsWith('dashboardV2');

  if (isDashboardV2Export) {
    const { startDate, endDate, queryParams } = list || {};
    const {
      sort,
      selectedSearchId,
      dataSourceId,
      keywordFilterText,
      dataPoint,
      viewFilters,
      columns,
    } = queryParams || {};

    exportApiPostParams = {
      sort,
      startDate,
      endDate,
      searchId: selectedSearchId,
      dataSourceId,
      keywordFilterText,
      deliveryEmail: email,
      deliveryOption: method,
      format,
      data_point: dataPoint,
      viewFilters,
      columns,
      analyticsIntegrationId: null,
    };
  } else if (list.id.startsWith('campaign-widget-')) {
    const {
      endDate,
      startDate,
      queryParams: { selectedSearchId },
    } = list;

    const dateEnd = moment.utc(parseInt(endDate, 10));
    const dateEndFormatted = dateEnd.clone().endOf('d').format('MM/DD/YYYY');
    const dateStart = moment.utc(parseInt(startDate, 10));
    const dateStartFormatted = dateStart
      .clone()
      .startOf('d')
      .format('MM/DD/YYYY');

    const search = await performGet(
      `${SEARCH_DETAIL_ENDPOINT}/${selectedSearchId}`,
    );

    exportApiPostParams = {
      ...exportApiPostParams,
      dateEnd: dateEnd.valueOf(),
      dateStart: dateStart.valueOf(),
      data_point: `range.${dateStartFormatted}-${dateEndFormatted}`,
      search: search.data || {},
    };
  } else if (list.id.startsWith('campaign.')) {
    const campaignPrimarySearch = primarySearchSelector(state);
    if (!isEmpty(campaignPrimarySearch)) {
      const search = await performGet(
        `${SEARCH_DETAIL_ENDPOINT}/${campaignPrimarySearch}`,
      );
      exportApiPostParams = {
        ...exportApiPostParams,
        search: search.data || {},
      };
    }
  } else if (list.id.startsWith('story.')) {
    const storySearch = currentStorySearchId(state);
    if (storySearch) {
      const search = await performGet(
        `${SEARCH_DETAIL_ENDPOINT}/${storySearch}`,
      );
      exportApiPostParams = {
        ...exportApiPostParams,
        search: search.data || {},
      };
    }
  } else if (list.id.startsWith('search.')) {
    const loadedSearch = searchLastSavedSelector(state);
    let search = null;

    if (!loadedSearch && exportApiPostParams.articleListId) {
      search = await performGet(
        `${SEARCH_DETAIL_ENDPOINT}/${exportApiPostParams.articleListId}`,
      );
      exportApiPostParams = {
        ...exportApiPostParams,
        search: search.data || {},
      };
    } else {
      if (!searchEditInvalidSelector(state)) {
        const advancedQuery = advancedSearchQuerySelector(state);
        const basicQuery = simpleSearchQuerySelector(state);
        search = searchPreviewPayloadSelector(state);
        search.id = exportApiPostParams.articleListId;
        if (advancedQuery) {
          search.search = advancedQuery;
        } else {
          search.query = basicQuery;
        }
      }
      exportApiPostParams = {
        ...exportApiPostParams,
        search: search,
      };
    }

    const { analyticsIntegrationId, sort } = list.queryParams || {};

    const EXPORT_UVM_COLUMNS = [
      'MOBILE_READERSHIP',
      'TOTAL_READERSHIP',
      'DESKTOP_READERSHIP',
    ];

    const selectedArrangeableColumns = earnedSearchArrangeableColumnsSelector(
      state,
    ).reduce((acc, item) => {
      if (item.checked) {
        acc.push(item.id);
      }
      return acc;
    }, []);

    selectedArrangeableColumns.push(...EXPORT_UVM_COLUMNS);

    const userMetrics = selectedArrangeableColumns.reduce((acc, column) => {
      if (!columnConfig[column] || !columnConfig[column].exportLabel) {
        return acc;
      } else if (!mobileUvmEnabled) {
        if (column === DESKTOP_READERSHIP_COLUMN) {
          return acc.concat(
            columnConfig[DESKTOP_READERSHIP_NON_MOBILE_UVM_COLUMN].exportLabel,
          );
        }
      }

      return acc.concat(columnConfig[column].exportLabel);
    }, []);

    const { columns } = getExportParamsFromList(
      dashboardId,
      storyId,
      list,
      userMetrics,
    );

    exportApiPostParams = {
      ...exportApiPostParams,
      columns,
      analyticsIntegrationId,
      sort,
    };
  } else if (list.id.startsWith('tag.')) {
    const tagId = list.id.split('.')[1];
    const { analyticsIntegrationId } = list.queryParams || {};

    exportApiPostParams = {
      ...exportApiPostParams,
      isEditingTag: true,
      analyticsIntegrationId,
      tagId,
    };
  } else if (!exportApiPostParams.widgetId) {
    const search = await performGet(
      `${SEARCH_DETAIL_ENDPOINT}/${exportApiPostParams.articleListId}`,
    );
    exportApiPostParams = {
      ...exportApiPostParams,
      search: search.data || {},
    };
  }

  dispatch({
    type: CREATE_EXPORT,
    payload: {
      deliveryEmail: email,
      deliveryOption: method,
      format,
      id,
      started: moment().utc().toISOString(),
      status: 'STARTED',
    },
  });

  try {
    const endpoint = isDashboardV2Export
      ? `${API_BASE_URL_V3}/widget/${list.widgetId}/articleExport/create`
      : `${ARTICLE_LIST_EXPORT_ENDPOINT}/create?useNewEarnedSearch=true`;

    const result = await performPost(endpoint, exportApiPostParams);

    const resultData = result.data || {};
    const { id: externalId, status } = resultData;

    dispatch({
      type: UPDATE_EXPORT_EXTERNAL_ID,
      payload: {
        externalId,
        id,
        status,
      },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_EXPORT_ERROR,
      payload: {
        error: err,
        id,
        pending: false,
        status: 'ERROR',
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There was a problem preparing your export.',
        status: 'error',
      }),
    );

    throw err;
  }
};

export const checkExportStatus = (id, isDashboardV2 = false, intl) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const matchingExports = exportsByIdsSelector(state)([id]);
  const thisExport = matchingExports.length ? matchingExports[0] : {};
  const { deliveryEmail: email, externalId } = thisExport;
  let { attempts, externalUrl, deliveryOption: method, status } = thisExport;
  let message = '';
  let maxAttempts = 5;
  if (!isDashboardV2) {
    // Article exports now return almost immediately with the job number for polling
    // and then does the long process of retrieval and export of articles
    maxAttempts = 5 * 20; // should check for 5 minutes, each check waits 3 seconds
  }

  try {
    if (attempts > maxAttempts) {
      throw new Error('Error getting export');
    }

    if (!externalId) {
      return;
    }

    let result;
    if (isDashboardV2) {
      result = await performGet(
        `${API_BASE_URL_V2}/articleExport/get/${externalId}`,
      );
    } else {
      result = await performGet(
        `${ARTICLE_LIST_EXPORT_ENDPOINT}/get/${externalId}`,
      );
    }
    const resultData = result.data || {};
    status = resultData.jobStatus ? resultData.jobStatus.name : status;
    externalUrl = resultData.externalUrl || externalUrl;
    attempts = externalId ? (attempts += 1) : attempts;
    method = resultData.method || method;

    dispatch({
      type: UPDATE_EXPORT,
      payload: {
        attempts,
        externalUrl,
        id,
        status,
      },
    });

    if (status === 'ERROR' || attempts > maxAttempts) {
      throw new Error('Error getting export');
    }

    if (status === 'COMPLETED') {
      if (method === 'DOWNLOAD' && externalUrl) {
        window.open(externalUrl, '_parent');
        message = intl.formatMessage(
          constantsMessages.constantsExportStatusDownloading,
        );
      }

      if (method === 'EMAIL') {
        message = intl.formatMessage(
          constantsMessages.constantsExportStatusSent,
          {
            EMAIL: email,
          },
        );
      }

      dispatch({
        type: UPDATE_EXPORT_SUCCEEDED,
        payload: {
          id,
        },
      });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: message,
          status: 'success',
        }),
      );
    }

    if (status === 'STARTED') {
      await timeoutPromise(3000);
      dispatch(checkExportStatus(id, false, intl));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_EXPORT_ERROR,
      payload: {
        error: err,
        id,
        pending: false,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          constantsMessages.constantsExportStatusFailure,
        ),
        status: 'error',
      }),
    );

    throw err;
  }
};

export default exportsReducer;
