import {
  ACCESS_CONTROL_GROUP_SET_SELECTED_GROUP_ID,
  AccessControlGroupActionTypes,
  AccessControlGroupState,
} from './types';

const initialState: AccessControlGroupState = {
  selectedGroupId: 0,
};

const accessControlGroupReducer = (
  state = { ...initialState },
  action: AccessControlGroupActionTypes,
): AccessControlGroupState => {
  switch (action.type) {
    case ACCESS_CONTROL_GROUP_SET_SELECTED_GROUP_ID: {
      return {
        ...state,
        selectedGroupId: action.payload,
      };
    }

    default:
      return state;
  }
};

export default accessControlGroupReducer;
