import React from 'react';
import PropTypes from 'prop-types';

const Overlay = ({ children }) => <div className="tk-overlay">{children}</div>;

Overlay.propTypes = {
  children: PropTypes.node,
};

Overlay.defaultProps = {
  children: null,
};

export default Overlay;
