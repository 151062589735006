export const ADD_CONTACT_LIST_CONTACTS_BY_LIST_ID = 'contact-lists-contacts';

const initialState = {
  listIds: {},
};

const contactListsContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTACT_LIST_CONTACTS_BY_LIST_ID:
      return {
        ...state,
        listIds: {
          ...state.listIds,
          [action.payload.listId]: action.payload.contacts.map(
            contact => contact.id,
          ),
        },
      };
    default:
      return state;
  }
};

export const addContactListContactsByListId = (listId, contacts) => ({
  type: ADD_CONTACT_LIST_CONTACTS_BY_LIST_ID,
  payload: { listId, contacts },
});

export default contactListsContactsReducer;
