import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

const loaderSizes = {
  tiny: 'tk-loader--tiny',
  small: 'tk-loader--small',
};

const Loader = ({ className, modifiers, size, style }) => {
  let sizeClass = null;
  if (size !== '' && loaderSizes[size] !== undefined) {
    sizeClass = loaderSizes[size];
  }

  const baseClass = 'tk-loader';
  const prefixedModifiers = Array.isArray(modifiers)
    ? modifiers.map(modifier => `${baseClass}--${modifier}`)
    : [];

  const mainClass = classnames(
    className,
    baseClass,
    prefixedModifiers,
    sizeClass,
  );

  return (
    <div id="loader" className={mainClass} style={style}>
      <div className={`${baseClass}__icon`} />
    </div>
  );
};

Loader.defaultProps = {
  className: '',
  modifiers: [],
  size: '',
  style: {},
};

Loader.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.array,
  size: PropTypes.string,
  style: PropTypes.object,
};

export default Loader;
