export default {
  modalTitle: {
    id: 'SOCIAL_TRACKERS_MODAL_TITLE',
    defaultMessage: 'Link Social Tracker',
    description: 'Social Trackers Modal: Title for the modal',
  },
  modalDescription: {
    id: 'SOCIAL_TRACKERS_MODAL_DESCRIPTION',
    defaultMessage:
      'Select a social search (Tracker) to associate with this earned media search. Linking a Tracker will make more social data available anytime you use this search.',
    description: 'Social Trackers Modal: Description for the modal',
  },
  gettingTrackers: {
    id: 'SOCIAL_TRACKERS_TRACKERS_DROPDOWN_GETTING_TRACKERS_LABEL',
    defaultMessage: 'Getting trackers...',
    description:
      'Social Trackers Modal: Message for the option to display in the Trackers dropdown when the trackers list is being loaded',
  },
  chooseATracker: {
    id: 'SOCIAL_TRACKERS_TRACKERS_DROPDOWN_CHOOSE_A_TRACKER_LABEL',
    defaultMessage: 'Choose a Tracker',
    description:
      'Social Trackers Modal: Message for the option to display in the Trackers dropdown when there is not a tracker selected',
  },
  trackersDropdownLabel: {
    id: 'SOCIAL_TRACKERS_MODAL_TRACKERS_DROPDOWN_LABEL',
    defaultMessage: 'Available Trackers',
    description:
      'Social Trackers Modal: Message for the label above the Trackers dropdown',
  },
  trackersListEmpty: {
    id: 'SOCIAL_TRACKERS_MODAL_TRACKERS_LIST_EMPTY',
    defaultMessage:
      "You don't have any Trackers set up. All Trackers you create in TrendKite Social will be available here.",
    description:
      'Social Trackers Modal: Message for the text to use when there are not trackers in the trackers list',
  },
  setUpATracker: {
    id: 'SOCIAL_TRACKERS_MODAL_SET_UP_A_TRACKER',
    defaultMessage: 'Set up a Tracker now',
    description:
      'Social Trackers Modal: Message for the set up a tracker now link',
  },
  removeTracker: {
    id: 'SOCIAL_TRACKERS_MODAL_REMOVE_OPTION',
    defaultMessage: 'Remove',
    description: 'Social Trackers Modal: Message for the remove tracker button',
  },
  submitButtonText: {
    id: 'SOCIAL_TRACKERS_MODAL_SUBMIT_BUTTON_TEXT',
    defaultMessage: 'Select Tracker',
    description:
      'Social Trackers Modal: Message for the text of the submit button',
  },
  getSocialTrackersError: {
    id: 'EARNED_SEARCHES_GET_SOCIAL_TRACKERS_ERROR',
    defaultMessage: 'Failed to retrieve the trackers list',
    description: 'Text showed when occurs an error getting social trackers',
  },
};
