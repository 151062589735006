import pullAllWith from 'lodash/pullAllWith';

import {
  SLACK_ACTION_ENDPOINT,
  SLACK_INTEGRATION_ENDPOINT,
} from 'constants/apis';
import globalMessages from 'i18n/Global.messages';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import {
  performDelete,
  performGet,
  performPost,
} from 'services/rest-service/rest-service';

import messages from '../components/slack/slack.messages';

export const AUTHENTICATE = 'tk/slack/AUTHENTICATE';
export const AUTHENTICATE_ERROR = 'tk/slack/AUTHENTICATE_ERROR';
export const AUTHENTICATE_SUCCESS = 'tk/slack/AUTHENTICATE_SUCCESS';
export const GET_SLACK_INTEGRATIONS = 'tk/slack/GET_SLACK_INTEGRATIONS';
export const GET_SLACK_INTEGRATIONS_ERROR =
  'tk/slack/GET_SLACK_INTEGRATIONS_ERROR';
export const GET_SLACK_INTEGRATIONS_SUCCESS =
  'tk/slack/GET_SLACK_INTEGRATIONS_SUCCESS';
export const DELETE_SLACK_INTEGRATIONS = 'tk/slack/DELETE_SLACK_INTEGRATIONS';
export const DELETE_SLACK_INTEGRATIONS_SUCCESS =
  'tk/slack/DELETE_SLACK_INTEGRATIONS_SUCCESS';
export const DELETE_SLACK_INTEGRATIONS_ERROR =
  'tk/slack/DELETE_SLACK_INTEGRATIONS_ERROR';
export const SHARE_ARTICLE_TO_SLACK = 'tk/slack/SHARE_ARTICLE_TO_SLACK';
export const SHARE_ARTICLE_TO_SLACK_ERROR =
  'tk/slack/SHARE_ARTICLE_TO_SLACK_ERROR';
export const SHARE_ARTICLE_TO_SLACK_SUCCESS =
  'tk/slack/SHARE_ARTICLE_TO_SLACK_SUCCESS';
export const TOGGLE_SLACK_MODAL = 'tk/slack/TOGGLE_SLACK_MODAL';

const initialState = {
  authenticated: false,
  authenticationError: false,
  authenticationPending: false,
  integrations: [],
  integrationsError: false,
  integrationsLoading: false,
  integrationsCached: false,
  sharingError: false,
  sharingPending: false,
  slackModalOpen: false,
  shareUrl: '',
  articleId: '',
  articleTypeLabel: '',
};

const slackReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        authenticated: false,
        authenticationPending: true,
        authenticationError: false,
      };
    case AUTHENTICATE_ERROR:
      return {
        ...state,
        authenticated: false,
        authenticationPending: false,
        authenticationError: true,
      };
    case AUTHENTICATE_SUCCESS: {
      const integrations = [...state.integrations];

      pullAllWith(integrations, action.payload.integrations, (a, b) => {
        return (
          a.teamId === b.teamId && a.webhookChannelId === b.webhookChannelId
        );
      });

      return {
        ...state,
        integrations: [...integrations, ...action.payload.integrations],
        authenticated: true,
        authenticationPending: false,
        authenticationError: false,
      };
    }
    case DELETE_SLACK_INTEGRATIONS:
      return {
        ...state,
        deletionPending: true,
        deletionError: false,
      };
    case DELETE_SLACK_INTEGRATIONS_SUCCESS: {
      const integrations = [...state.integrations];

      pullAllWith(integrations, action.payload.integrations, (a, b) => {
        return (
          a.teamId === b.teamId && a.webhookChannelId === b.webhookChannelId
        );
      });

      // This doesn't get updated in the picker yet (look at the UNSAFE_componentWillReceiveProps method in the modal?)

      return {
        ...state,
        integrations,
        authenticated: false,
        deletionPending: false,
        deletionError: true,
      };
    }
    case DELETE_SLACK_INTEGRATIONS_ERROR:
      return {
        ...state,
        deletionPending: false,
        deletionError: false,
      };
    case GET_SLACK_INTEGRATIONS:
      return {
        ...state,
        integrationsPending: true,
        integrationsError: false,
        integrationsCached: false,
      };
    case GET_SLACK_INTEGRATIONS_ERROR:
      return {
        ...state,
        integrationsPending: false,
        integrationsError: true,
        integrationsCached: false,
      };
    case GET_SLACK_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        integrations: [...action.payload.integrations],
        integrationsPending: false,
        integrationsError: false,
        integrationsCached: true,
      };
    case SHARE_ARTICLE_TO_SLACK:
      return {
        ...state,
        sharingError: false,
        sharePending: true,
      };
    case SHARE_ARTICLE_TO_SLACK_SUCCESS:
      return {
        ...state,
        sharingError: false,
        sharePending: false,
        slackModalOpen: false,
      };
    case SHARE_ARTICLE_TO_SLACK_ERROR:
      return {
        ...state,
        sharingError: true,
        sharePending: false,
      };
    case TOGGLE_SLACK_MODAL:
      return {
        ...state,
        slackModalOpen: !state.slackModalOpen,
        shareUrl: action.payload ? action.payload.shareUrl : '',
        articleId: action.payload ? action.payload.articleId : '',
        articleTypeLabel: action.payload ? action.payload.articleTypeLabel : '',
      };
    default:
      return state;
  }
};

export const getSlackIntegrationsActionCreator = () => async (
  dispatch,
  getState,
) => {
  if (window?.activeUser?.id === -1 || getState()?.slack?.integrationsCached) {
    return;
  }

  dispatch({
    type: GET_SLACK_INTEGRATIONS,
  });

  try {
    const result = await performGet(`${SLACK_INTEGRATION_ENDPOINT}`);

    dispatch({
      type: GET_SLACK_INTEGRATIONS_SUCCESS,
      payload: {
        integrations: result.data,
      },
    });
  } catch (e) {
    dispatch({
      type: GET_SLACK_INTEGRATIONS_ERROR,
      payload: {},
    });

    throw new Error(e);
  }
};

export const deleteSlackIntegrationsActionCreator = () => async dispatch => {
  dispatch({
    type: DELETE_SLACK_INTEGRATIONS,
  });

  try {
    const result = await performDelete(`${SLACK_INTEGRATION_ENDPOINT}`);

    dispatch({
      type: DELETE_SLACK_INTEGRATIONS_SUCCESS,
      payload: {
        integrations: result.data,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        title: 'Success',
        text: 'Your Slack webhooks have been deleted',
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({
      type: DELETE_SLACK_INTEGRATIONS_ERROR,
      payload: {},
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        title: 'Error',
        text: 'There was a problem deleting your Slack webhooks',
        status: 'danger',
      }),
    );

    throw new Error(e);
  }
};

export const slackAuthActionCreator = (code, redirectUri) => async dispatch => {
  const postParams = {
    code,
    redirectUri,
  };

  dispatch({
    type: AUTHENTICATE,
    payload: {},
  });

  try {
    const result = await performPost(
      `${SLACK_INTEGRATION_ENDPOINT}`,
      postParams,
    );

    dispatch({
      type: AUTHENTICATE_SUCCESS,
      payload: {
        integrations: result.data,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        title: 'Success',
        text: 'Authenticated with Slack',
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({
      type: AUTHENTICATE_ERROR,
      payload: {},
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        title: 'Error',
        text: 'There was a problem authenticating with Slack',
        status: 'danger',
      }),
    );

    throw new Error(e);
  }
};

export const slackShareActionCreator = (
  articleId,
  comment,
  shareUrl,
  webhookChannelId,
  intl,
) => async dispatch => {
  const postParams = {
    articleId,
    comment,
    shareUrl,
    webhookChannelId,
  };

  dispatch({
    type: SHARE_ARTICLE_TO_SLACK,
  });

  try {
    await performPost(`${SLACK_ACTION_ENDPOINT}`, postParams);

    dispatch({
      type: SHARE_ARTICLE_TO_SLACK_SUCCESS,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        title: intl.formatMessage(globalMessages.success),
        text: intl.formatMessage(messages.articleSharedToSlack),
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({
      type: SHARE_ARTICLE_TO_SLACK_ERROR,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        title: intl.formatMessage(globalMessages.error),
        text: intl.formatMessage(messages.articleSharedToSlackError),
        status: 'danger',
      }),
    );

    const errorMessage =
      (e && e.response && e.response.data && e.response.data.error) || '';

    if (errorMessage === 'Not Found') {
      dispatch(getSlackIntegrationsActionCreator());
    }

    throw new Error(e);
  }
};

export const toggleSlackModalActionCreator = payload => dispatch => {
  dispatch({
    type: TOGGLE_SLACK_MODAL,
    payload,
  });
};

export default slackReducer;
