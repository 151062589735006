import { createSelector } from 'reselect';

import { TAGGED_ARTICLE_STATUS } from 'constants/tags';

export const userTagsSelector = state => state.userTags.userTags || {};
export const sharedTagsSelector = state => state.userTags.sharedTags || {};
export const deleteModalSelector = state => state.userTags.deleteModal || false;
export const selectedTagSelector = state => state.userTags.selectedTag || {};
export const shareModalOpenSelector = state =>
  state.userTags.shareModalOpen || false;
export const tagsLoadingSelector = state => state.userTags.loading || false;
export const bulkTagModalOpenSelector = state =>
  state.userTags.bulkTagModalOpen || false;
export const articlesSelector = state => state.userTags.articles || [];
export const isAddUrlScreenHiddenSelector = state =>
  state.userTags.isAddUrlScreenHidden || false;

export const isCreatingTagSelector = createSelector(
  selectedTagSelector,
  selectedTag => Object.keys(selectedTag).length === 0,
);

export const isBulkTagModalInProcessingScreenSelector = createSelector(
  articlesSelector,
  articles =>
    articles.some(
      article => article.status !== TAGGED_ARTICLE_STATUS.pending.id,
    ),
);

export const isModalTaggingSelector = createSelector(
  articlesSelector,
  articles =>
    articles.some(
      article =>
        article.status === TAGGED_ARTICLE_STATUS.processing.id ||
        article.status === TAGGED_ARTICLE_STATUS.pending.id,
    ),
);

export const sortedArticlesSelector = createSelector(
  articlesSelector,
  articles => articles.sort((a, b) => a.priority - b.priority),
);

export const statusCountSelector = createSelector(
  articlesSelector,
  articles => statusId =>
    articles.reduce(
      (acc, article) => (article.status === statusId ? acc + 1 : acc),
      0,
    ),
);

export const areAllArticlesProcessedSelector = createSelector(
  articlesSelector,
  statusCountSelector,
  (articles, statusCount) =>
    statusCount(TAGGED_ARTICLE_STATUS.failed.id) +
      statusCount(TAGGED_ARTICLE_STATUS.tagged.id) ===
    articles.length,
);

export const uniqueTagsSelector = createSelector(
  userTagsSelector,
  sharedTagsSelector,
  (userTags, sharedTags) => {
    const tags = {};
    Object.assign(tags, userTags, sharedTags);
    return Object.values(tags).sort((a, b) => a.tag.localeCompare(b.tag));
  },
);

export const uniqueTagsByIdSelector = createSelector(
  userTagsSelector,
  sharedTagsSelector,
  (userTags, sharedTags) => {
    const tags = {};
    return Object.assign(tags, userTags, sharedTags);
  },
);
