import React from 'react';
import PropTypes from 'prop-types';
import './title.scss';

import { DisplaySM } from '../type';

const HeaderTitle = ({ children }) => (
  <div className="page-header__title">
    <DisplaySM color="inverseLight" size="lg">
      {children}
    </DisplaySM>
  </div>
);

HeaderTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderTitle;
