import React, { Component } from 'react';

import filesize from 'filesize';
import PropTypes from 'prop-types';

import {
  Addon,
  Button,
  Loader,
  Progress,
  SvgIcon,
  ImageCanvasProcessor,
} from '@trendkite/ui';

import brokenDocument from '../assets/icons/broken_document.svg';

const baseClass = 'story-image-asset';
const failedBaseClass = 'story-failed-image-asset';

const getFullSourceUrl = (asset = {}) => {
  let { imageURL, thumbnailURL } = asset;
  imageURL = imageURL ? imageURL.replace('+', '%2b') : '';
  thumbnailURL = thumbnailURL ? thumbnailURL.replace('+', '%2b') : '';
  return imageURL || thumbnailURL || '';
};

const getFileName = (asset = {}) => {
  const fullSourceUrl = getFullSourceUrl(asset);
  return fullSourceUrl.split('/').pop();
};

const getImageUrl = (asset = {}) => {
  const preview = asset.preview;
  const thumbnailURL = asset.thumbnailURL
    ? asset.thumbnailURL.replace('+', '%2b')
    : '';
  return thumbnailURL || preview || '';
};

const getImageMimeType = filename => {
  const allowedImageExtensions = ['bmp', 'jpeg', 'png'];
  const extensionWithDot =
    filename.lastIndexOf('.') >= 0
      ? filename.substring(filename.lastIndexOf('.') + 1)
      : '';
  let extension = extensionWithDot.toLowerCase();

  extension = extension === 'jpg' ? 'jpeg' : extension;

  const imageMimeType =
    allowedImageExtensions.indexOf(extension) >= 0 ? `image/${extension}` : '';

  return imageMimeType;
};

class ImageAsset extends Component {
  state = {
    fullSourceUrlBlob: '',
    hovered: false,
    loading: true,
  };

  componentDidMount() {
    const { asset = {} } = this.props;

    const imageUrl = getImageUrl(asset);
    this.smallImage = new Image();
    this.smallImage.onload = this.onLoad(this);
    this.smallImage.src = imageUrl;
  }

  componentWillUnmount() {
    this.smallImage = null;
  }

  onLoad = self => () => {
    self.setState({
      loading: false,
    });
  };

  onImageHover = () =>
    !this.state.loading ? this.setState({ hovered: true }) : null;

  onImageHoverEnded = () => this.setState({ hovered: false });

  onAssetDelete = () => this.props.onAssetDelete(this.props.asset.id);

  onCreatedBlobUrl = url => this.setState({ fullSourceUrlBlob: url });

  displaySizes = (size, height, width) => {
    let sizes;
    if (size && !height && !width) {
      sizes = filesize(size, { round: 1 });
    } else if (size && height && width) {
      sizes = `${filesize(size, { round: 1 })} ${height}x${width}`;
    }
    return <div className={`${baseClass}__asset__info__size`}>{sizes}</div>;
  };

  renderStatusDisplay = () => {
    const { uploadProgress, uploading } = this.props.asset;

    const progressBar = (
      <div>
        <Progress modifiers={['small']} value={uploadProgress} />
        {`Uploading ${uploadProgress}% complete`}
      </div>
    );

    const loader = <Loader />;

    const statusDisplay =
      uploading && uploadProgress !== 100 ? progressBar : loader;

    return (
      <div className={`${baseClass}__overlay`}>
        <div className={`${baseClass}__overlay__content`}>{statusDisplay}</div>
      </div>
    );
  };

  renderEditHoverDisplay = () => {
    const { isEditable, asset } = this.props;
    const { fullSourceUrlBlob } = this.state;
    const filename = getFileName(asset);
    const fullSourceUrl = getFullSourceUrl(asset);
    const canCanvasExport = ImageCanvasProcessor.supportsCanvasExport(
      getImageMimeType(filename),
    );

    return (
      <div className={`${baseClass}__overlay-image`}>
        <div className={`${baseClass}__overlay-image-content`}>
          {isEditable ? (
            <div className={`${baseClass}__overlay-image-action`}>
              <Button
                data-qa="JlCb_RAKzfaxlLqUzlacY"
                modifiers={['danger', 'round']}
                onClick={this.onAssetDelete}
              >
                Remove
              </Button>
            </div>
          ) : (
            <div className={`${baseClass}__overlay-image-action`}>
              <Button
                disabled={canCanvasExport && !fullSourceUrlBlob}
                download={filename}
                inNewTab
                location={canCanvasExport ? fullSourceUrlBlob : fullSourceUrl}
                modifiers={['primary', 'round']}
              >
                <Addon>
                  <SvgIcon icon="download" />
                </Addon>
                <span>
                  {canCanvasExport && !fullSourceUrlBlob
                    ? 'Preparing Download'
                    : 'Download Asset'}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderError = () => {
    return (
      <div className={failedBaseClass}>
        <div className={`${failedBaseClass}__content`}>
          <div className={`${failedBaseClass}__content__image`}>
            <svg>
              <use xlinkHref={`#${brokenDocument.id}`} />
            </svg>
          </div>
          <p className={`${failedBaseClass}__content__text`}>
            Sorry, we couldn&apos;t load this image. Please try refreshing this
            page.
          </p>
          <div className={`${failedBaseClass}__content__button`}>
            <Button
              data-qa="i9EfwbeOKWqaXVop_ghMd"
              modifiers={['danger', 'round']}
              onClick={this.onAssetDelete}
            >
              Remove This Image
            </Button>
          </div>
        </div>
      </div>
    );
  };
  onError = () => this.setState({ loading: false });

  renderSuccess = () => {
    const { height, size, uploading, width, caption = '' } = this.props.asset;
    const { hovered, loading } = this.state;

    const shouldRenderStatus = uploading || loading;
    const imageUrl = getImageUrl(this.props.asset);
    const fullSourceUrl = getFullSourceUrl(this.props.asset);
    const filename = getFileName(this.props.asset);
    const mimeType = getImageMimeType(filename);

    return (
      <div
        className={baseClass}
        onMouseEnter={this.onImageHover}
        onMouseLeave={this.onImageHoverEnded}
      >
        <div className={`${baseClass}__asset`}>
          <div
            className={`${baseClass}__asset__image`}
            style={{ backgroundImage: `url("${imageUrl}")` }}
          >
            <img
              alt={caption}
              className={`${baseClass}__img`}
              crossOrigin="anonymous"
              src={fullSourceUrl}
              onError={this.onError}
            />
            {shouldRenderStatus && this.renderStatusDisplay()}
            {hovered && !shouldRenderStatus && this.renderEditHoverDisplay()}
          </div>
          <div className={`${baseClass}__asset__info`}>
            <div className={`${baseClass}__asset__info__caption`}>
              {caption}
            </div>
            {this.displaySizes(size, height, width)}
          </div>
        </div>
        {fullSourceUrl && mimeType && !this.props.isEditable && (
          <ImageCanvasProcessor
            imageSrc={fullSourceUrl}
            filename={filename}
            filetype={mimeType}
            onCreateBlobUrl={this.onCreatedBlobUrl}
          />
        )}
      </div>
    );
  };

  render() {
    return this.props.asset.error ? this.renderError() : this.renderSuccess();
  }
}

ImageAsset.propTypes = {
  asset: PropTypes.shape({
    imageURL: PropTypes.string,
    thumbnailURL: PropTypes.string,
    preview: PropTypes.string,
    caption: PropTypes.string,
    uploading: PropTypes.bool,
    error: PropTypes.bool,
    uploadProgress: PropTypes.number,
    size: PropTypes.number,
    id: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  isEditable: PropTypes.bool,
  onAssetDelete: PropTypes.func,
};

ImageAsset.defaultProps = {
  asset: {
    imageURL: null,
    thumbnailURL: null,
    preview: null,
    caption: null,
    uploading: false,
    error: false,
    uploadProgress: 0,
    size: null,
    width: 0,
    height: 0,
  },
  isEditable: false,
  onAssetDelete: () => {},
};

export default ImageAsset;
