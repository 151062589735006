import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Body as Text } from '../type';
import ShimmerText from '../shimmer-text';
import TextTruncate from '../text-truncate';

import './title.scss';

const Title = ({ className, loading, title, size, style, truncate }) => {
  if (!title) {
    return null;
  }

  const titleSize = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  }[size];

  let renderedTitle = title;

  const mainClass = classnames(Title.baseClass, className, {
    [`${Title.baseClass}--truncate`]: truncate,
    [`${Title.baseClass}--${size}`]: size,
  });

  renderedTitle = (
    <Text color="light" size={titleSize}>
      {renderedTitle}
    </Text>
  );
  renderedTitle = truncate ? (
    <TextTruncate block>{renderedTitle}</TextTruncate>
  ) : (
    renderedTitle
  );

  return (
    <div className={mainClass} style={style}>
      {loading && <ShimmerText style={{ width: '7em' }} />}
      {!loading && renderedTitle}
    </div>
  );
};

Title.baseClass = 'tk-person-title';

Title.propTypes = {
  /** Optional custom class names, applied to the outer wrapper of the component (in addition to the normal classes). */
  className: PropTypes.string,
  /** Show a loading state for the whole person block */
  loading: PropTypes.bool,
  /** Size variants */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /** Optional custom style object, applied to the outer wrapper of the component. */
  style: PropTypes.object,
  /** Optional job title */
  title: PropTypes.string,
  /** Limit each author details section to 1 line */
  truncate: PropTypes.bool,
};

Title.defaultProps = {
  className: '',
  loading: false,
  size: 'md',
  style: {},
  title: '',
  truncate: false,
};

export default Title;
