import React, { useLayoutEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DEV_FEATURES } from 'trendkite-front-end-app/src/constants/constants';
import { userHasDevFeatureFlag } from 'trendkite-front-end-app/src/services/feature-service/feature-service';

import './responsive-nav.scss';

const ResponsiveNav = ({
  logo,
  menu,
  responsiveContext,
  secondaryNav,
  updateLgMinWidth,
}) => {
  const isCompact =
    userHasDevFeatureFlag(DEV_FEATURES.responsive) &&
    responsiveContext.indexOf('container-md-down') >= 0;

  useLayoutEffect(() => {
    if (!isCompact) {
      if (typeof window.requestIdleCallback !== 'function') {
        updateLgMinWidth();
        return;
      }

      const idleCallbackId = window.requestIdleCallback(updateLgMinWidth);
      return () => window.cancelIdleCallback(idleCallbackId);
    }
  }, [isCompact, updateLgMinWidth]);

  const mainClass = classNames(ResponsiveNav.baseClass, {
    [`${ResponsiveNav.baseClass}--compact`]: isCompact,
  });

  return (
    <div className={mainClass}>
      <div className={`${ResponsiveNav.baseClass}__menu`}>
        {React.cloneElement(menu, { isCompact })}
      </div>
      <div className={`${ResponsiveNav.baseClass}__logo`}>{logo}</div>
      <div className={`${ResponsiveNav.baseClass}__secondary-nav`}>
        {isCompact ? null : secondaryNav}
      </div>
    </div>
  );
};

ResponsiveNav.baseClass = 'responsive-nav';

ResponsiveNav.propTypes = {
  logo: PropTypes.node,
  menu: PropTypes.node,
  responsiveContext: PropTypes.arrayOf(PropTypes.string),
  secondaryNav: PropTypes.node,
  updateLgMinWidth: PropTypes.func,
};

ResponsiveNav.defaultProps = {
  logo: null,
  menu: null,
  responsiveContext: [],
  secondaryNav: null,
  updateLgMinWidth: () => {},
};

export default ResponsiveNav;
