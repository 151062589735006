import {
  FEEDS_ENDPOINT,
  FEED_ENDPOINT,
  FEED_COUNT_LIMIT_ENDPOINT,
} from 'constants/apis';
import editMessages from 'pages/Admin/FeedManagement/EditFeed/EditFeed.messages';
import { FEED_ACTION_STATUSES } from 'pages/Admin/FeedManagement/FeedManagement';

import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import {
  performDelete,
  performGet,
  performPatch,
  performPost,
} from 'services/rest-service/rest-service';

export const GET_FEEDS = 'GET_FEEDS';
export const GET_FEEDS_SUCCESS = 'GET_FEEDS_SUCCESS';
export const GET_FEEDS_ERROR = 'GET_FEEDS_ERROR';

export const GET_FEED = 'GET_FEED';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_FEED_ERROR = 'GET_FEED_ERROR';

export const CREATE_FEED = 'CREATE_FEED';
export const CREATE_FEED_SUCCESS = 'CREATE_FEED_SUCCESS';
export const CREATE_FEED_ERROR = 'CREATE_FEED_ERROR';

export const UPDATE_FEED = 'UPDATE_FEED';
export const UPDATE_FEED_SUCCESS = 'UPDATE_FEED_SUCCESS';
export const UPDATE_FEED_ERROR = 'UPDATE_FEED_ERROR';
export const UPDATE_FEED_WIDGET_ID_TITLE = 'UPDATE_FEED_WIDGET_ID_TITLE';

export const DELETE_FEED = 'DELETE_FEED';
export const DELETE_FEED_SUCCESS = 'DELETE_FEED_SUCCESS';
export const DELETE_FEED_ERROR = 'DELETE_FEED_ERROR';

export const GET_FEED_COUNT_LIMIT = 'GET_FEED_COUNT_LIMIT';
export const GET_FEED_COUNT_LIMIT_SUCCESS = 'GET_FEED_COUNT_LIMIT_SUCCESS';
export const GET_FEED_COUNT_LIMIT_ERROR = 'GET_FEED_COUNT_LIMIT_ERROR';

export const CLEAR_FEED_COUNT_LIMIT = 'CLEAR_FEED_COUNT_LIMIT';

export const CLEAR_ALL_FEED_DATA = 'CLEAR_ALL_FEED_DATA';

const initialState = {
  loading: false,
  error: false,
  feeds: [],
  feed: {},
  feedCounts: {},
  feedDashboardWidgets: [],
  feedSelectedWidgetTitle: '',
  actionStatus: FEED_ACTION_STATUSES.INITIALIZED,
  paginator: {},
};

const additionalHeaders = {
  'X-API-UI': true,
};

const adminFeedManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEEDS:
      return { ...state, loading: true };
    case GET_FEEDS_SUCCESS:
      return {
        ...state,
        loading: false,
        feeds: action.payload.feeds,
        paginator: action.payload.paginator,
      };
    case GET_FEEDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        feeds: [],
        paginator: {},
      };
    case GET_FEED:
      return { ...state, loading: true };
    case GET_FEED_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        feed: null,
      };
    case GET_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        feed: action.payload.feed,
        feedDashboardWidgets: action.payload.feedDashboardWidgets,
        feedSelectedWidgetTitle: action.payload.feedSelectedWidgetTitle,
        feedDashboardTitle: action.payload.feedDashboardTitle,
      };
    case CREATE_FEED:
      return { ...state, loading: true, actionStatus: null };
    case CREATE_FEED_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        feeds: [],
        actionStatus: FEED_ACTION_STATUSES.CREATE_FAILURE,
      };
    case CREATE_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        actionStatus: FEED_ACTION_STATUSES.CREATE_SUCCESS,
      };
    case UPDATE_FEED:
      return {
        ...state,
        error: null,
        loading: true,
        actionStatus: null,
      };
    case UPDATE_FEED_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        actionStatus: FEED_ACTION_STATUSES.UPDATE_SUCCESS,
      };
    case UPDATE_FEED_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        actionStatus: FEED_ACTION_STATUSES.UPDATE_FAILURE,
      };
    case UPDATE_FEED_WIDGET_ID_TITLE:
      return {
        ...state,
        feed: {
          ...state.feed,
          widgetV3: {
            id: action.payload.widgetV3.id,
          },
        },
        feedSelectedWidgetTitle: action.payload.widgetV3.title,
      };
    case CLEAR_ALL_FEED_DATA:
      return {
        ...state,
        actionStatus: FEED_ACTION_STATUSES.INITIALIZED,
      };
    case GET_FEED_COUNT_LIMIT:
      return { ...state, loading: true };
    case GET_FEED_COUNT_LIMIT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        feedCounts: {
          count: action.payload.count,
          limit: action.payload.limit,
        },
      };
    case GET_FEED_COUNT_LIMIT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        feedCounts: {},
      };
    case CLEAR_FEED_COUNT_LIMIT:
      return {
        ...state,
        feedCounts: {},
      };
    case DELETE_FEED:
      return {
        ...state,
        error: null,
        loading: true,
        actionStatus: null,
      };
    case DELETE_FEED_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        actionStatus: FEED_ACTION_STATUSES.DELETE_SUCCESS,
      };
    case DELETE_FEED_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        actionStatus: FEED_ACTION_STATUSES.DELETE_FAILURE,
      };
    default:
      return state;
  }
};

export const getFeedsActionCreator = (params, intl) => dispatch => {
  dispatch({ type: GET_FEEDS });
  performGet(FEEDS_ENDPOINT, params, additionalHeaders)
    .then(response => {
      if (response.status === 200 && response.data) {
        const { paginator, feeds } = response.data;
        dispatch({
          type: GET_FEEDS_SUCCESS,
          payload: {
            paginator,
            feeds,
          },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_FEEDS_ERROR, payload: { error: e.response } });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.getFeedsErrorMsg),
          status: 'danger',
        }),
      );
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(clearAllFeedDataActionCreator());
      }, 2000);
    });
};

export const getFeedActionCreator = (feedId, intl) => async dispatch => {
  dispatch({ type: GET_FEED });
  performGet(FEED_ENDPOINT + '/' + feedId, null, additionalHeaders)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({
          type: GET_FEED_SUCCESS,
          payload: {
            feed: response.data.feed,
            feedDashboardWidgets: response.data.feedDashboardWidgets,
            feedSelectedWidgetTitle: response.data.feedSelectedWidgetTitle,
            feedDashboardTitle: response.data.feedDashboardTitle,
          },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_FEED_ERROR, payload: { error: e.response } });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.getFeedErrorMsg, {
            FEED_ID: feedId,
          }),
          status: 'danger',
        }),
      );
    });
};

export const createFeedActionCreator = widgetV3Id => async dispatch => {
  dispatch({ type: CREATE_FEED });
  try {
    const response = await performPost(FEEDS_ENDPOINT, { widgetV3Id });
    if (response.status === 200 && response.data) {
      dispatch({ type: CREATE_FEED_SUCCESS, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: CREATE_FEED_ERROR, payload: e });
  }
};

export const deleteFeedActionCreator = (feedId, intl) => async dispatch => {
  dispatch({ type: DELETE_FEED });
  try {
    await performDelete(`${FEED_ENDPOINT}/${feedId}`);
    dispatch({ type: DELETE_FEED_SUCCESS });
    dispatch(getFeedsActionCreator(intl));
  } catch (e) {
    dispatch({
      type: DELETE_FEED_ERROR,
      payload: { error: e.response?.data },
    });
  }
};

export const getFeedCountLimitActionCreator = accountId => async dispatch => {
  dispatch({ type: GET_FEED_COUNT_LIMIT });
  try {
    const response = await performGet(FEED_COUNT_LIMIT_ENDPOINT, { accountId });
    if (response.status === 200 && response) {
      dispatch({ type: GET_FEED_COUNT_LIMIT_SUCCESS, payload: response.data });
    }
  } catch (e) {
    dispatch({ type: GET_FEED_COUNT_LIMIT_ERROR, payload: e });
  }
};

export const clearFeedCountLimitActionCreator = () => dispatch =>
  dispatch({ type: CLEAR_FEED_COUNT_LIMIT });

export const updateFeedActionCreator = (feedId, widgetV3) => async dispatch => {
  dispatch({ type: UPDATE_FEED });
  try {
    await performPatch(`${FEED_ENDPOINT}/${feedId}`, {
      widgetV3Id: widgetV3.id,
    });
    dispatch({ type: UPDATE_FEED_SUCCESS });
    dispatch(updateFeedWidgetIdActionCreator(widgetV3));
  } catch (e) {
    dispatch({
      type: UPDATE_FEED_ERROR,
      payload: { error: e.response?.data },
    });
  }
};

export const updateFeedWidgetIdActionCreator = widgetV3 => dispatch => {
  dispatch({ type: UPDATE_FEED_WIDGET_ID_TITLE, payload: { widgetV3 } });
};

export const clearAllFeedDataActionCreator = () => dispatch =>
  dispatch({ type: CLEAR_ALL_FEED_DATA });

export default adminFeedManagementReducer;
