import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const FormatParagraphRight = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M3 21h18v-2H3v2zm6-4h12v-2H9v2zm-6-4h18v-2H3v2zm6-4h12V7H9v2zM3 3v2h18V3H3z" />
  </svg>
);
FormatParagraphRight.propTypes = { fill: PropTypes.string };
FormatParagraphRight.defaultProps = { fill: iconColorsMap.mainicon };

export default FormatParagraphRight;
