import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import SideBarListItem from '../side-bar-list-item/SideBarListItem';

class SideBarCollapse extends Component {
  render() {
    const { opened, toggle, title, titleWeight, dataQA } = this.props;

    const classes = classNames('tk-side-bar-collapse__children', {
      'tk-side-bar-collapse__children--closed': !opened,
    });

    const disabled = this.props.children.length === 0;

    return (
      <div>
        <div>
          <SideBarListItem
            opened={opened}
            title={title}
            arrow
            titleWeight={titleWeight}
            disabled={disabled}
            data-qa={dataQA || '4JguoU2qJUx3uL2u4JxQs'}
            className="tk-side-bar-collapse"
            onClick={!disabled ? toggle : undefined}
            role="button"
            tabIndex={0}
          />
        </div>
        <div className="tk-side-bar-collapse">
          <div className={classes}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

SideBarCollapse.propTypes = {
  opened: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  titleWeight: PropTypes.string,
  dataQA: PropTypes.string,
};

SideBarCollapse.defaultProps = {
  title: 'Title',
  opened: false,
};

export default SideBarCollapse;
