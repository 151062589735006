import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const SyncError = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M3 12c0 2.21.91 4.2 2.36 5.64L3 20h6v-6l-2.24 2.24A6.003 6.003 0 015 12a5.99 5.99 0 014-5.65V4.26C5.55 5.15 3 8.27 3 12zm8 5h2v-2h-2v2zM21 4h-6v6l2.24-2.24A6.003 6.003 0 0119 12a5.99 5.99 0 01-4 5.65v2.09c3.45-.89 6-4.01 6-7.74 0-2.21-.91-4.2-2.36-5.64L21 4zm-10 9h2V7h-2v6z" />
  </svg>
);
SyncError.propTypes = { fill: PropTypes.string };
SyncError.defaultProps = { fill: iconColorsMap.mainicon };

export default SyncError;
