import { CONTACT_IMAGES_ENDPOINT } from 'constants/apis';
import { performPost } from 'services/rest-service/rest-service';

const fetchContactImages = contactsToRequestImagesFor => {
  const containsRequiredFieldsForImage = contact =>
    contact.twitterUsername !== undefined &&
    contact.twitterUsername &&
    contact.id;
  const requestBody = contactsToRequestImagesFor.filter(
    containsRequiredFieldsForImage,
  );

  if (!requestBody.length) {
    return [];
  }

  return performPost(CONTACT_IMAGES_ENDPOINT, { authors: requestBody });
};

export default fetchContactImages;
