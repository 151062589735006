const addImageFieldToContacts = contacts => {
  const result = [];

  contacts.forEach(contact => {
    const formattedContact = { ...contact };
    formattedContact.image = {
      loading: true,
      url: '',
    };

    result.push(formattedContact);
  });

  return result;
};

const createContactListWithNamedType = function createContactListWithNamedType(
  name,
) {
  return function contactList(state = [], action) {
    switch (action.type) {
      case `ADD_CONTACTS_TO_LIST_${name}`: {
        let result = state.slice();
        result = result.concat(addImageFieldToContacts(action.contacts));
        return result;
      }

      case `ADD_IMAGES_TO_CONTACTS_IN_LIST_${name}`: {
        return state.map(contact => {
          const image = action.images.find(img => img.id === contact.id) || {};

          if (!contact.image.url && contact.image.loading && image.imageUrl) {
            contact.image.url = image.imageUrl;
          }

          contact.image.loading = false;

          return contact;
        });
      }

      case `REMOVE_ALL_CONTACTS_FROM_LIST_${name}`: {
        return [];
      }

      case `REMOVE_CONTACTS_FROM_LIST_${name}`: {
        return state.filter(
          contact =>
            !action.contacts.some(
              secondContact => contact.id === secondContact.id,
            ),
        );
      }

      case `REPLACE_CONTACTS_IN_LIST_${name}`: {
        return addImageFieldToContacts(action.contacts);
      }

      default:
        return state;
    }
  };
};

export default createContactListWithNamedType;
