import React from 'react';
import PropTypes from 'prop-types';

import withModifiers from '../withModifiers';

const Badge = props => (
  <div {...props} className={`${Badge.baseClass} ${props.className}`} />
);

Badge.baseClass = 'tk-badge';

Badge.defaultProps = {
  children: null,
  className: '',
  style: {},
};

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default withModifiers(Badge);
