import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Loader } from '@trendkite/ui';

import { setNylasCallbackStateActionCreator } from 'reducers/outreach/nylas-state';
import { saveOutreachIntegration } from 'reducers/outreach/outreach-integrations';
import { addPageMessage } from 'reducers/page-messages';
import { hasDevFeatureFlagSelector } from 'selectors/account';
import { handleNylasRedirect } from 'utils/nylas';

import messages from './OutreachNotConnected.messages';

const mapStateToProps = state => ({
  hasDevFeatureFlag: hasDevFeatureFlagSelector(state),
});

const mapDispatchToProps = dispatch => ({
  saveEmailIntegration: ({ intl, authCode: integration }) =>
    dispatch(saveOutreachIntegration({ intl, integration })),
  saveNylasState: nylasState =>
    dispatch(setNylasCallbackStateActionCreator(nylasState)),
  showErrorKite: intl =>
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.outreachIntegrationError),
        status: 'danger',
        ttl: 5000,
      }),
    ),
});

class OutreachRedirect extends Component {
  componentDidMount() {
    const {
      location,
      history,
      saveEmailIntegration,
      saveNylasState,
      showErrorKite,
      hasDevFeatureFlag,
      intl,
    } = this.props;

    handleNylasRedirect({
      location,
      hasDevFeatureFlag,
      history,
      saveIntegrationCallback: saveEmailIntegration,
      saveNylasStateCallback: saveNylasState,
      showErrorKiteCallback: showErrorKite,
      intl,
    });
  }

  render() {
    return <Loader />;
  }
}

OutreachRedirect.propTypes = {
  hasDevFeatureFlag: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  saveEmailIntegration: PropTypes.func.isRequired,
  saveNylasState: PropTypes.func.isRequired,
  showErrorKite: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OutreachRedirect),
);
