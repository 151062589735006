import {
  ADMIN_ARTICLE_SEARCH_ENDPOINT,
  ADMIN_ARTICLE_FIELDS_ENDPOINT,
  ADMIN_ARTICLE_UPDATE_ENDPOINT,
  ADMIN_ARTICLE_SOCIAL_REFRESH_ENDPOINT,
  ADMIN_ARTICLE_ADD_ENDPOINT,
} from 'constants/apis';
import addMessages from 'pages/Admin/ArticleManagement/AddArticle/AddArticle.messages';
import articleSearchMessages from 'pages/Admin/ArticleManagement/ArticleSearch/ArticleSearch.message';
import editMessages from 'pages/Admin/ArticleManagement/EditArticle/EditArticle.messages';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';

import { performGet, performPost } from 'services/rest-service/rest-service';

export const CLEAR_ARTICLE = 'adminArticleManagement/CLEAR_ARTICLE';

export const SEARCH_ARTICLE = 'adminArticleManagement/SEARCH_ARTICLE';
export const SEARCH_ARTICLE_SUCCESS =
  'adminArticleManagement/SEARCH_ARTICLE_SUCCESS';
export const SEARCH_ARTICLE_ERROR =
  'adminArticleManagement/SEARCH_ARTICLE_ERROR';

export const GET_ARTICLE_FIELDS = 'adminArticleManagement/GET_ARTICLE_FIELDS';
export const GET_ARTICLE_FIELDS_SUCCESS =
  'adminArticleManagement/GET_ARTICLE_FIELDS_SUCCESS';
export const GET_ARTICLE_FIELDS_ERROR =
  'adminArticleManagement/GET_ARTICLE_FIELDS_ERROR';

export const ADD_ARTICLE = 'adminArticleManagement/ADD_ARTICLE';
export const ADD_ARTICLE_SUCCESS = 'adminArticleManagement/ADD_ARTICLE_SUCCESS';
export const ADD_ARTICLE_ERROR = 'adminArticleManagement/ADD_ARTICLE_ERROR';

export const UPDATE_ARTICLE = 'adminArticleManagement/UPDATE_ARTICLE';
export const UPDATE_ARTICLE_SUCCESS =
  'adminArticleManagement/UPDATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_ERROR =
  'adminArticleManagement/UPDATE_ARTICLE_ERROR';

export const GET_ARTICLE_SOCIAL_DATA_INFO =
  'adminArticleManagement/GET_ARTICLE_SOCIAL_DATA_INFO';
export const GET_ARTICLE_SOCIAL_DATA_SUCCESS =
  'adminArticleManagement/GET_ARTICLE_SOCIAL_DATA_SUCCESS';
export const GET_ARTICLE_SOCIAL_DATA_ERROR =
  'adminArticleManagement/GET_ARTICLE_SOCIAL_DATA_ERROR';

export const initialState = {
  loading: false,
  error: null,
  articles: [],
  fields: [],
  socialRefresh: {},
};

const adminArticletManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ARTICLE:
      return {
        ...state,
        articles: [],
        socialRefresh: {},
      };
    case SEARCH_ARTICLE:
      return {
        ...state,
        articles: [],
        loading: true,
      };
    case SEARCH_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        loading: false,
        error: null,
      };
    case SEARCH_ARTICLE_ERROR:
      return {
        ...state,
        articles: [],
        loading: false,
        error: action.payload.error,
      };
    case GET_ARTICLE_FIELDS:
      return {
        ...state,
        loading: true,
      };
    case GET_ARTICLE_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.payload.fields,
        loading: false,
        error: null,
      };
    case GET_ARTICLE_FIELDS_ERROR:
      return {
        ...state,
        fields: [],
        loading: false,
        error: action.payload.error,
      };
    case ADD_ARTICLE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_ARTICLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_ARTICLE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: [],
        loading: false,
        error: null,
      };
    case UPDATE_ARTICLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_ARTICLE_SOCIAL_DATA_INFO:
      return {
        ...state,
        loading: true,
      };
    case GET_ARTICLE_SOCIAL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        socialRefresh: action.payload,
        error: null,
      };
    case GET_ARTICLE_SOCIAL_DATA_ERROR:
      return {
        ...state,
        loading: false,
        socialRefresh: action.payload.data,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const clearArticle = () => dispatch => {
  dispatch({ type: CLEAR_ARTICLE });
};

export const searchArticle = (param, intl) => async dispatch => {
  dispatch({ type: SEARCH_ARTICLE });
  performPost(ADMIN_ARTICLE_SEARCH_ENDPOINT, param)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({
          type: SEARCH_ARTICLE_SUCCESS,
          payload: { articles: response.data },
        });
        if (response.data.length === 0) {
          dispatch(
            addPageMessageWithDefaultTimeout({
              text: intl.formatMessage(editMessages.noArticleFoundMsg, {
                SEQUENCE_ID: param.value,
              }),
              status: 'success',
            }),
          );
        }
      }
    })
    .catch(e => {
      dispatch({ type: SEARCH_ARTICLE_ERROR, payload: { error: e.response } });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.getArticleErrorMsg, {
            SEQUENCE_ID: param.value,
          }),
          status: 'danger',
        }),
      );
    });
};

export const getArticleFields = () => async dispatch => {
  dispatch({ type: GET_ARTICLE_FIELDS });
  performGet(ADMIN_ARTICLE_FIELDS_ENDPOINT)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({
          type: GET_ARTICLE_FIELDS_SUCCESS,
          payload: { fields: response.data },
        });
      }
    })
    .catch(e => {
      dispatch({
        type: GET_ARTICLE_FIELDS_ERROR,
        payload: { error: e.response },
      });
    });
};

export const addArticleAction = (param, intl) => async dispatch => {
  dispatch({ type: ADD_ARTICLE });
  return performPost(ADMIN_ARTICLE_ADD_ENDPOINT, param)
    .then(response => {
      if (response.status === 200) {
        dispatch({
          type: ADD_ARTICLE_SUCCESS,
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(addMessages.addArticleSuccessMsg, {
              ARTICLE_TITLE: param.article.title,
            }),
            status: 'success',
          }),
        );
      }
    })
    .catch(e => {
      dispatch({
        type: ADD_ARTICLE_ERROR,
        payload: { error: e.response },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(addMessages.addArticleErrorMsg, {
            ARTICLE_TITLE: param.article.title,
          }),
          status: 'danger',
        }),
      );
      throw e.response;
    });
};

export const editArticleAction = (article, intl) => async dispatch => {
  dispatch({ type: UPDATE_ARTICLE });
  return performPost(ADMIN_ARTICLE_UPDATE_ENDPOINT, article)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({
          type: UPDATE_ARTICLE_SUCCESS,
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(editMessages.updateArticleSuccessMsg, {
              SEQUENCE_ID: article.id,
            }),
            status: 'success',
          }),
        );
      }
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ARTICLE_ERROR,
        payload: { error: e.response },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.updateArticleErrorMsg, {
            SEQUENCE_ID: article.id,
          }),
          status: 'danger',
        }),
      );
      throw e.response;
    });
};

export const getSocialRefresh = (params, intl) => async dispatch => {
  dispatch({ type: GET_ARTICLE_SOCIAL_DATA_INFO });
  performPost(ADMIN_ARTICLE_SOCIAL_REFRESH_ENDPOINT, params)
    .then(resp => {
      if (resp.status === 200 && resp.data) {
        const data = resp.data;
        dispatch({
          type: GET_ARTICLE_SOCIAL_DATA_SUCCESS,
          payload: data?.results,
        });
      }
    })
    .catch(e => {
      dispatch({
        type: GET_ARTICLE_SOCIAL_DATA_ERROR,
        payload: { error: e.response, data: [] },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(
            articleSearchMessages.adminArticleSocialRefreshErrorMessage,
          ),
          status: 'danger',
        }),
      );
    });
};

export default adminArticletManagementReducer;
