import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import { ACTIVITY_TYPES } from 'constants/activities';
import { DATE_RANGE_KEYS, DATE_RANGE_OPTIONS } from 'utils/date/date-util';

import activityType from './activity';

export const ALL_TIME = {
  label: 'All Time',
  shortLabel: 'All Time',
  labelTranslationKey: 'allTimeShortLabel',
};

export const dateRangeOptions = {
  ALL_TIME,
  ...pick(DATE_RANGE_OPTIONS, [
    DATE_RANGE_KEYS.TRAILING_7,
    DATE_RANGE_KEYS.TRAILING_30,
    DATE_RANGE_KEYS.TRAILING_90,
    DATE_RANGE_KEYS.CUSTOM,
  ]),
};

export const sortPropTypes = PropTypes.shape({
  field: PropTypes.oneOf(['date_happened']),
  order: PropTypes.oneOf(['Asc', 'Desc']),
});

export const defaultPaginationProps = {
  offset: 0,
  limit: 25,
};

export const propTypes = PropTypes.shape({
  activities: PropTypes.arrayOf(activityType),
  count: PropTypes.number,
  error: PropTypes.bool,
  filters: PropTypes.shape({
    activityType: PropTypes.array,
    actorIds: PropTypes.arrayOf(PropTypes.string),
    actorType: PropTypes.string,
    audienceIds: PropTypes.arrayOf(PropTypes.string),
    audienceType: PropTypes.string,
    dateRangeType: PropTypes.oneOf(Object.keys(dateRangeOptions)),
    endDate: PropTypes.number,
    startDate: PropTypes.number,
  }),
  loading: PropTypes.bool,
  loadingTypeaheadActivity: PropTypes.bool,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
  }),
  search: PropTypes.string,
  sort: sortPropTypes,
  typeaheadActivities: PropTypes.arrayOf(activityType),
});

export const defaultProps = {
  activities: [],
  count: 0,
  error: false,
  filters: {
    activityType: Object.keys(ACTIVITY_TYPES),
    actorIds: [],
    actorType: '',
    audienceIds: [],
    audienceType: '',
    dateRangeType: 'ALL_TIME',
    endDate: 0,
    startDate: 0,
  },
  loading: true,
  loadingTypeaheadActivity: false,
  pagination: {
    ...defaultPaginationProps,
  },
  search: '',
  sort: {
    field: 'date_happened',
    order: 'Desc',
  },
  typeaheadActivities: [],
};

export default {
  dateRangeOptions,
  propTypes,
  defaultProps,
  defaultPaginationProps,
  sortPropTypes,
};
