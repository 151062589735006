import React from 'react';

import PropTypes from 'prop-types';

const StorySection = ({ children }) => (
  <div className="tk-story-section">{children}</div>
);

StorySection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StorySection;
