import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import { DATE_RANGE_KEYS, DATE_RANGE_OPTIONS } from 'utils/date/date-util';

export const ALL_TIME = {
  label: 'All Time',
  shortLabel: 'All Time',
};

export const dateRangeOptions = {
  ALL_TIME,
  ...pick(DATE_RANGE_OPTIONS, [
    DATE_RANGE_KEYS.TRAILING_7,
    DATE_RANGE_KEYS.TRAILING_30,
    DATE_RANGE_KEYS.TRAILING_90,
    DATE_RANGE_KEYS.CUSTOM,
  ]),
};

export const propTypes = PropTypes.shape({
  /** Our internal ID of record */
  id: PropTypes.string,
  /** Nylas's ID of record */
  messageId: PropTypes.string,
  /** Nylas's ID of record */
  threadId: PropTypes.string,
  /** ISO with timezone */
  receivedBefore: PropTypes.string,
  /** ISO with timezone */
  receivedAfter: PropTypes.string,
  /** Date range type */
  dateRangeType: PropTypes.oneOf(Object.keys(dateRangeOptions)),

  dateOption: PropTypes.arrayOf(PropTypes.string),
  toDate: PropTypes.string,
  fromDate: PropTypes.string,
  /**
    Any email in the thread has been replied to by the recipient,
    or this message has been replied to by the recipient.
  */
  emailTypes: PropTypes.arrayOf(PropTypes.string),
  hasReply: PropTypes.bool,
  unread: PropTypes.bool,
  starred: PropTypes.bool,
  /** A list of Outreach integrations from which to include results, or omit to include all */
  integrationUuids: PropTypes.arrayOf(PropTypes.string),
  campaigns: PropTypes.arrayOf(PropTypes.number),
  campaignIds: PropTypes.arrayOf(PropTypes.number),
  stories: PropTypes.arrayOf(PropTypes.number),
  authors: PropTypes.arrayOf(PropTypes.number),
  authorLists: PropTypes.arrayOf(PropTypes.number),
  influencers: PropTypes.arrayOf(PropTypes.string),
  influencerLists: PropTypes.arrayOf(PropTypes.string),
  draft: PropTypes.bool,
  scheduled: PropTypes.bool,
  status: PropTypes.arrayOf(PropTypes.string),
  /**
    Field names that should have values,
    for now used to request either influencer emails or author emails
  */
  requiredFields: PropTypes.arrayOf(PropTypes.string),
});

export const defaultProps = {
  id: null,
  messageId: null,
  threadId: null,
  receivedBefore: undefined,
  receivedAfter: undefined,
  dateRangeType: 'ALL_TIME',
  emailTypes: [],
  hasReply: undefined,
  unread: undefined,
  starred: null,
  integrationUuids: [],
  campaigns: [],
  campaignIds: [],
  stories: [],
  authors: [],
  authorLists: [],
  influencers: [],
  influencerLists: [],
  draft: undefined,
  scheduled: undefined,
  status: [],
  requiredFields: [],
  toDate: null,
  fromDate: null,
  dateOption: [],
};

export default {
  propTypes,
  defaultProps,
};
