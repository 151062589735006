import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Kite } from '@trendkite/ui';
import {
  OUTREACH_INTEGRATION_ERROR_TYPES,
  OUTREACH_INTEGRATION_ERROR_MESSAGES,
} from 'constants/outreach-integration';
import { openNylasRedirectModalActionCreator } from 'reducers/outreach/nylas-redirect-modal';
import { outreachMessagesErrorSelector } from 'selectors/outreach';

const mapStateToProps = state => ({
  messagesError: outreachMessagesErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  openNylasRedirectModal: redirect =>
    dispatch(openNylasRedirectModalActionCreator(redirect)),
});

class OutreachErrorPageKites extends Component {
  getErrorAction = error => {
    if (!error) {
      return null;
    }

    if (error.type === OUTREACH_INTEGRATION_ERROR_TYPES.disconnected) {
      return {
        text: 'Reconnect Now',
        onClick: this.reconnectOutreach,
        modifiers: ['link', 'block'],
      };
    } else if (error.type === OUTREACH_INTEGRATION_ERROR_TYPES.syncing) {
      return {
        text: 'Refresh Now',
        onClick: this.refreshOutreach,
        modifiers: ['link', 'block'],
      };
    }
    return null;
  };

  getErrorDescription = error => {
    if (!error) {
      return null;
    }

    if (error.type === OUTREACH_INTEGRATION_ERROR_TYPES.rejected_send) {
      const subject = error.sendableMessages[0].subject;
      const to = error.sendableMessages[0].to
        .map(recipient => recipient.email)
        .join(',');

      return OUTREACH_INTEGRATION_ERROR_MESSAGES[error.type](subject, to);
    }
    return OUTREACH_INTEGRATION_ERROR_MESSAGES[error.type];
  };

  getKiteForError = error => {
    const action = this.getErrorAction(error);
    return (
      <Kite
        shouldShow={!!error}
        status="danger"
        actionText={action && action.text}
        action={action && action.onClick}
      >
        {this.getErrorDescription(error)}
      </Kite>
    );
  };

  reconnectOutreach = () => {
    this.props.openNylasRedirectModal(this.props.location.pathname);
  };

  refreshOutreach = () => {
    window.location.reload();
  };

  render() {
    const { messagesError } = this.props;

    return (
      <React.Fragment>{this.getKiteForError(messagesError)}</React.Fragment>
    );
  }
}

OutreachErrorPageKites.propTypes = {
  location: PropTypes.object.isRequired,
  messagesError: PropTypes.object,
  openNylasRedirectModal: PropTypes.func.isRequired,
};

OutreachErrorPageKites.defaultProps = {
  messagesError: null,
  threadsError: null,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutreachErrorPageKites),
);
