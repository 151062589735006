import moment from 'moment';

import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { DEV_FEATURES, FEATURES } from 'constants/constants';

import {
  userHasDevFeatureFlag,
  userHasFeatureFlag,
} from 'services/feature-service/feature-service';

import { performGet } from 'services/rest-service/rest-service';

import { performGet as mockPerformGet } from '../mockEndpoints';
import { influencerIsOutlet } from '../utils';

import affiliateMediaReducerFactory from './affiliateMedia';
import alsoFollowsReducerFactory from './alsoFollows';
import articleReducerFactory from './articles';
import contributorsReducerFactory from './contributors';
import desksReducerFactory from './desks';
import { addRecentInfluencer } from './recent';
import topLocationsReducerFactory from './toplocations';
import topTopicsReducerFactory from './toptopics';
import tweetsReducerFactory from './tweets';
import twitchReducerFactory from './twitch';
import youtubeReducerFactory from './youtube';

const {
  contributorsReducer,
  GET_CONTRIBUTORS_START,
  GET_CONTRIBUTORS_SUCCESS,
  GET_CONTRIBUTORS_ERROR,
  CLEAR_SELECTED_CONTRIBUTORS,
  UNSELECT_CONTRIBUTORS,
  SELECT_CONTRIBUTORS,
} = contributorsReducerFactory('PROFILE');

const {
  contributorsReducer: topContributorsReducer,
  GET_CONTRIBUTORS_START: GET_TOP_CONTRIBUTORS_START,
  GET_CONTRIBUTORS_SUCCESS: GET_TOP_CONTRIBUTORS_SUCCESS,
  GET_CONTRIBUTORS_ERROR: GET_TOP_CONTRIBUTORS_ERROR,
  CLEAR_SELECTED_CONTRIBUTORS: CLEAR_SELECTED_TOP_CONTRIBUTORS,
  UNSELECT_CONTRIBUTORS: UNSELECT_TOP_CONTRIBUTORS,
  SELECT_CONTRIBUTORS: SELECT_TOP_CONTRIBUTORS,
  getContributorsForOutlet: getTopContributorsForOutlet,
} = contributorsReducerFactory('PROFILE_MODULE');

const {
  affiliateMediaReducer,
  GET_AFFILIATE_MEDIA_START,
  GET_AFFILIATE_MEDIA_SUCCESS,
  GET_AFFILIATE_MEDIA_ERROR,
} = affiliateMediaReducerFactory('PROFILE');

const {
  affiliateMediaReducer: topAffiliateMediaReducer,
  GET_AFFILIATE_MEDIA_START: GET_TOP_AFFILIATE_MEDIA_START,
  GET_AFFILIATE_MEDIA_SUCCESS: GET_TOP_AFFILIATE_MEDIA_SUCCESS,
  GET_AFFILIATE_MEDIA_ERROR: GET_TOP_AFFILIATE_MEDIA_ERROR,
  CLEAR_SELECTED_AFFILIATE_MEDIA: CLEAR_SELECTED_TOP_AFFILIATE_MEDIA,
  UNSELECT_AFFILIATE_MEDIA: UNSELECT_TOP_AFFILIATE_MEDIA,
  SELECT_AFFILIATE_MEDIA: SELECT_TOP_AFFILIATE_MEDIA,
  getAffiliateMediaForInfluencer: getTopAffiliateMediaForInfluencer,
} = affiliateMediaReducerFactory('PROFILE_MODULE');

const {
  desksReducer,
  GET_DESKS_START,
  GET_DESKS_SUCCESS,
  GET_DESKS_ERROR,
  getDesksForInfluencer,
} = desksReducerFactory('PROFILE');

const {
  articlesReducer,
  GET_ARTICLES_START,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_ERROR,
} = articleReducerFactory('PROFILE');

const {
  articlesReducer: recentArticlesReducer,
  GET_ARTICLES_START: GET_RECENT_ARTICLES_START,
  GET_ARTICLES_SUCCESS: GET_RECENT_ARTICLES_SUCCESS,
  GET_ARTICLES_ERROR: GET_RECENT_ARTICLES_ERROR,
  getRecentArticlesByInfluencerId: getRecentArticlesByInfluencerIdForModule,
} = articleReducerFactory('PROFILE_MODULE');

const {
  tweetsReducer,
  GET_TWEETS_START,
  GET_TWEETS_SUCCESS,
  GET_TWEETS_ERROR,
  getRecentTweetsForInfluencer,
} = tweetsReducerFactory('PROFILE');

const {
  youtubeReducer,
  GET_YOUTUBE_VIDEOS_START,
  GET_YOUTUBE_VIDEOS_SUCCESS,
  GET_YOUTUBE_VIDEOS_ERROR,
  getRecentYoutubeVideosForInfluencer,
} = youtubeReducerFactory('PROFILE');

const {
  twitchReducer,
  GET_TWITCH_VIDEOS_START,
  GET_TWITCH_VIDEOS_SUCCESS,
  GET_TWITCH_VIDEOS_ERROR,
  getRecentTwitchVideosForInfluencer,
} = twitchReducerFactory('PROFILE');

const {
  topTopicsReducer,
  GET_TOPTOPICS_START,
  GET_TOPTOPICS_SUCCESS,
  GET_TOPTOPICS_ERROR,
  getTopTopicsForInfluencer,
} = topTopicsReducerFactory('PROFILE');

const {
  topLocationsReducer,
  GET_TOPLOCATIONS_START,
  GET_TOPLOCATIONS_SUCCESS,
  GET_TOPLOCATIONS_ERROR,
  getTopLocationsForInfluencer,
} = topLocationsReducerFactory('PROFILE');

const {
  alsoFollowsReducer,
  GET_ALSOFOLLOWS_START,
  GET_ALSOFOLLOWS_SUCCESS,
  GET_ALSOFOLLOWS_ERROR,
  getAlsoFollowsForInfluencer,
} = alsoFollowsReducerFactory('PROFILE');

const INFLUENCER_PROFILE_START = 'ihub/INFLUENCER_PROFILE_START';
export const INFLUENCER_PROFILE_SUCCESS = 'ihub/INFLUENCER_PROFILE_SUCCESS';
const INFLUENCER_PROFILE_ERROR = 'ihub/INFLUENCER_PROFILE_ERROR';

const SET_OUTLET_OVERVIEW_TAB = 'ihub/SET_OUTLET_OVERVIEW_TAB';

export const TOP_CONTRIBUTORS = 'TOP_CONTRIBUTORS';
export const AFFILIATED_MEDIA = 'AFFILIATED_MEDIA';
export const DESKS = 'DESKS';

const initialState = {
  loading: false,
  error: false,
  activeId: '',
  info: {},
  tweets: {},
  youtube: {},
  twitch: {},
  affiliateMedia: {},
  topAffiliateMedia: {},
  articles: {},
  recentArticles: {},
  contributors: {},
  topContributors: {},
  desks: {},
  notes: {},
  outletTab: TOP_CONTRIBUTORS,
  toptopics: {},
  toplocations: {},
  alsoFollows: {},
};

const influencerProfileReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case INFLUENCER_PROFILE_START: {
      return {
        ...state,
        loading: true,
        error: false,
        activeId: action.payload,
        outletTab: TOP_CONTRIBUTORS,
        info: {},
      };
    }

    case INFLUENCER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        info: {
          ...action.payload,
        },
      };
    }

    case INFLUENCER_PROFILE_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case GET_TWEETS_START:
    case GET_TWEETS_SUCCESS:
    case GET_TWEETS_ERROR:
      return {
        ...state,
        tweets: tweetsReducer(state.tweets, action),
      };

    case GET_YOUTUBE_VIDEOS_START:
    case GET_YOUTUBE_VIDEOS_SUCCESS:
    case GET_YOUTUBE_VIDEOS_ERROR:
      return {
        ...state,
        youtube: youtubeReducer(state.youtube, action),
      };

    case GET_TWITCH_VIDEOS_START:
    case GET_TWITCH_VIDEOS_SUCCESS:
    case GET_TWITCH_VIDEOS_ERROR:
      return {
        ...state,
        twitch: twitchReducer(state.twitch, action),
      };

    case GET_DESKS_START:
    case GET_DESKS_SUCCESS:
    case GET_DESKS_ERROR:
      return {
        ...state,
        desks: desksReducer(state.desks, action),
      };

    case GET_AFFILIATE_MEDIA_START:
    case GET_AFFILIATE_MEDIA_SUCCESS:
    case GET_AFFILIATE_MEDIA_ERROR:
      return {
        ...state,
        affiliateMedia: affiliateMediaReducer(state.affiliateMedia, action),
      };

    case GET_CONTRIBUTORS_START:
    case GET_CONTRIBUTORS_SUCCESS:
    case GET_CONTRIBUTORS_ERROR:
    case CLEAR_SELECTED_CONTRIBUTORS:
    case UNSELECT_CONTRIBUTORS:
    case SELECT_CONTRIBUTORS:
      return {
        ...state,
        contributors: contributorsReducer(state.contributors, action),
      };

    case GET_TOP_CONTRIBUTORS_START:
    case GET_TOP_CONTRIBUTORS_SUCCESS:
    case GET_TOP_CONTRIBUTORS_ERROR:
    case CLEAR_SELECTED_TOP_CONTRIBUTORS:
    case UNSELECT_TOP_CONTRIBUTORS:
    case SELECT_TOP_CONTRIBUTORS:
      return {
        ...state,
        topContributors: topContributorsReducer(state.topContributors, action),
      };

    case GET_TOP_AFFILIATE_MEDIA_START:
    case GET_TOP_AFFILIATE_MEDIA_SUCCESS:
    case GET_TOP_AFFILIATE_MEDIA_ERROR:
    case CLEAR_SELECTED_TOP_AFFILIATE_MEDIA:
    case UNSELECT_TOP_AFFILIATE_MEDIA:
    case SELECT_TOP_AFFILIATE_MEDIA:
      return {
        ...state,
        topAffiliateMedia: topAffiliateMediaReducer(
          state.topAffiliateMedia,
          action,
        ),
      };

    case GET_RECENT_ARTICLES_START:
    case GET_RECENT_ARTICLES_SUCCESS:
    case GET_RECENT_ARTICLES_ERROR:
      return {
        ...state,
        recentArticles: recentArticlesReducer(state.recentArticles, action),
      };

    case GET_ARTICLES_START:
    case GET_ARTICLES_SUCCESS:
    case GET_ARTICLES_ERROR:
      return {
        ...state,
        articles: articlesReducer(state.articles, action),
      };

    case SET_OUTLET_OVERVIEW_TAB:
      return {
        ...state,
        outletTab: action.payload,
      };

    case GET_TOPTOPICS_START:
    case GET_TOPTOPICS_SUCCESS:
    case GET_TOPTOPICS_ERROR:
      return {
        ...state,
        toptopics: topTopicsReducer(state.toptopics, action),
      };

    case GET_TOPLOCATIONS_START:
    case GET_TOPLOCATIONS_SUCCESS:
    case GET_TOPLOCATIONS_ERROR:
      return {
        ...state,
        toplocations: topLocationsReducer(state.toplocations, action),
      };

    case GET_ALSOFOLLOWS_START:
    case GET_ALSOFOLLOWS_SUCCESS:
    case GET_ALSOFOLLOWS_ERROR:
      return {
        ...state,
        alsoFollows: alsoFollowsReducer(state.alsoFollows, action),
      };

    default:
      return state;
  }
};

export const setOverviewOutletTab = value => ({
  type: SET_OUTLET_OVERVIEW_TAB,
  payload: value,
});

export const getProfileActionDispatcher = id => async dispatch => {
  dispatch({ type: INFLUENCER_PROFILE_START, payload: id });

  try {
    let response;

    if (id.indexOf('mock-') === 0) {
      // For mock influencer IDs, use local data
      response = await mockPerformGet(`/ihub/influencer/${id}`);
    } else {
      response = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/influencer/${id}`,
      );
      response = response.data;
    }

    const payload = response && response.data;
    const isOutlet = influencerIsOutlet(payload);

    dispatch({ type: INFLUENCER_PROFILE_SUCCESS, payload });
    dispatch(addRecentInfluencer(payload));

    if (!userHasFeatureFlag(FEATURES.connectBasic)) {
      const nowMoment = moment();
      const endDate = nowMoment.endOf('day').valueOf();

      const startDate = nowMoment.subtract(90, 'days').startOf('day').valueOf();

      const params = { isOutlet, pageSize: 5, startDate, endDate };
      dispatch(getRecentTweetsForInfluencer(payload));
      dispatch(getRecentYoutubeVideosForInfluencer(payload));
      dispatch(getRecentTwitchVideosForInfluencer(payload));
      dispatch(getRecentArticlesByInfluencerIdForModule(id, params));
    }

    if (isOutlet) {
      const { rdsOneOutletId } = payload;

      if (rdsOneOutletId) {
        dispatch(getTopAffiliateMediaForInfluencer(rdsOneOutletId, 5));
        dispatch(
          getTopContributorsForOutlet({ outletId: rdsOneOutletId, size: 5 }),
        );
        dispatch(getDesksForInfluencer(rdsOneOutletId));
      }
    }

    if (userHasDevFeatureFlag(DEV_FEATURES.twitterAudienceInsights)) {
      dispatch(getTopTopicsForInfluencer(payload));
      dispatch(getTopLocationsForInfluencer(payload));
      dispatch(getAlsoFollowsForInfluencer(payload));
    }
  } catch (err) {
    dispatch({
      type: INFLUENCER_PROFILE_ERROR,
      error: { message: 'something went wrong' },
    });
  }
};

export default influencerProfileReducer;
