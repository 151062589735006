import uniq from 'lodash/uniq';

import { CAMPAIGNS_ENDPOINT } from 'constants/apis';
import { campaignsFilteredDefaultProps } from 'pages/Campaigns/Home/View/campaigns-filtered-view';
import { performGet } from 'services/rest-service/rest-service';
import {
  getCurrentUserId,
  getDisplayNamesByIds,
} from 'services/user-service/user-service';

export const GET_CAMPAIGNS = 'campaign-list/GET_CAMPAIGNS';
export const GET_CAMPAIGNS_ERROR = 'campaign-list/GET_CAMPAIGNS_ERROR';
export const GET_CAMPAIGNS_RECEIVED = 'campaign-list/GET_CAMPAIGNS_RECEIVED';
export const GET_CAMPAIGN_OWNERS = 'campaign-list/GET_CAMPAIGN_OWNERS';
export const GET_CAMPAIGN_OWNERS_SUCCESS =
  'campaign-list/GET_CAMPAIGN_OWNERS_SUCCESS';
export const GET_CAMPAIGN_OWNERS_ERROR =
  'campaign-list/GET_CAMPAIGN_OWNERS_ERROR';
export const SET_CAMPAIGNS_SHARING = 'campaign-list/SET_CAMPAIGNS_SHARING';

export const initialState = {
  campaigns: [],
  error: false,
  loadingCampaigns: true,
  loadedCampaigns: false,
  total: 0,
  offset: 0,
  limit: -1,
  campaignsOwnedByList: [],
  campaignsCategoriesList: [],
};

const campaignListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS:
    case GET_CAMPAIGN_OWNERS:
      return { ...state, loadingCampaigns: true };
    case GET_CAMPAIGNS_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingCampaigns: false,
      };
    case GET_CAMPAIGNS_RECEIVED:
      return {
        ...state,
        loadingCampaigns: false,
        loadedCampaigns: true,
        campaigns: action.payload.campaigns,
        pagination: action.payload.pagination || null,
        campaignsCategoriesList:
          action.payload.filters?.category?.sort((a, b) =>
            a.id === -1 || b.id === -1 ? 1 : a.name.localeCompare(b.name),
          ) || [],
        campaignsOwnedByList: [],
      };
    case GET_CAMPAIGN_OWNERS_SUCCESS: {
      const displayNames = action.payload;
      const campaignsWithNames = [...state.campaigns];

      const currentUserId = getCurrentUserId().toString();
      if (displayNames.hasOwnProperty(currentUserId)) {
        displayNames[currentUserId] += ' (You)';
      }

      campaignsWithNames.forEach(
        campaign => (campaign.ownedBy = displayNames[campaign.userId] || ''),
      );
      return {
        ...state,
        loadingCampaigns: false,
        campaigns: [...campaignsWithNames],
        campaignsOwnedByList: Object.entries(displayNames).sort((a, b) =>
          currentUserId === a[0] || currentUserId === b[0]
            ? -1
            : a[1].localeCompare(b[1]),
        ),
      };
    }
    case SET_CAMPAIGNS_SHARING: {
      const { shared, campaignIds } = action.payload;
      const updatedCampaigns = state.campaigns.map(campaign => {
        if (campaignIds.indexOf(campaign.id) >= 0) {
          return {
            ...campaign,
            shared,
          };
        }
        return campaign;
      });
      return {
        ...state,
        campaigns: updatedCampaigns,
      };
    }
    default:
      return state;
  }
};

const getCampaignOwnerNames = ownerIds => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_OWNERS });

  try {
    const displayNames = await getDisplayNamesByIds(ownerIds);
    dispatch({ type: GET_CAMPAIGN_OWNERS_SUCCESS, payload: displayNames });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_OWNERS_ERROR });
    throw e;
  }
};

export const getCampaignsForUser = ({
  filteredView,
} = {}) => async dispatch => {
  let queryParams;
  if (filteredView != null) {
    const {
      categories,
      lastModified: {
        beforeDate: lastModifiedLte,
        afterDate: lastModifiedGte,
      } = {},
      owners,
      pagination: { offset, limit },
      searchText: query,
      shareSetting,
      sort: { field: sortField, order: sortOrder },
    } = filteredView;

    queryParams = {
      category: categories?.length > 0 ? categories.join(',') : undefined,
      lastModifiedGte: lastModifiedGte || undefined,
      lastModifiedLte: lastModifiedLte || undefined,
      limit,
      offset,
      owner: owners?.length > 0 ? owners.join(',') : undefined,
      query: query || undefined,
      shared: shareSetting?.length === 1 ? shareSetting[0] : undefined,
      sortField,
      sortOrder,
    };
  }

  dispatch({ type: GET_CAMPAIGNS });
  await performGet(`${CAMPAIGNS_ENDPOINT}`, queryParams)
    .then(response => {
      const { results, filters, ...rest } = response.data;
      const ownerIds = uniq(
        response.data.filters.owner?.map(owner => owner.id),
      );
      const responseData = {
        campaigns: results,
        filters,
        pagination: rest,
      };
      dispatch({
        type: GET_CAMPAIGNS_RECEIVED,
        payload: responseData,
      });
      if (ownerIds.length > 0) {
        dispatch(getCampaignOwnerNames(ownerIds));
      }
    })
    .catch(err => dispatch({ type: GET_CAMPAIGNS_ERROR, payload: err }));
};

export const updateCampaignsFilteredViewList = ({
  nextFilteredViewProps = { ...campaignsFilteredDefaultProps },
}) => async dispatch => {
  const filteredView = {
    ...nextFilteredViewProps,
  };

  return dispatch(getCampaignsForUser({ filteredView }));
};

export const createCampaignsFilteredViewList = ({
  nextFilteredViewProps,
}) => async dispatch => {
  const filteredView = {
    ...campaignsFilteredDefaultProps,
    ...nextFilteredViewProps,
  };

  return dispatch(updateCampaignsFilteredViewList({ filteredView }));
};

export default campaignListReducer;
