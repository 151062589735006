import isEqual from 'lodash/isEqual';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import { createSelector } from 'reselect';

import {
  ANALYTICS_INTEGRATION_TYPES,
  ARTICLE_METRICS,
  ARTICLE_METRICS_AA_FIELDS,
  ARTICLE_METRICS_BASE_FIELDS,
  ARTICLE_METRICS_BROADCAST_FIELDS,
  ARTICLE_METRICS_GA_FIELDS,
  ARTICLE_METRICS_UVM_FIELDS,
  ARTICLE_STATS,
  FEATURES,
  DEV_FEATURES,
  MEDIA_TYPES,
  ORDERED_METRICS,
  SOCIAL_ARTICLE_STATS,
  ARTICLE_TYPES_LABELS,
} from 'constants/constants';
import { initialArticle } from 'reducers/articles';
import { currentArticleListAnalyticsIntegrationTypeSelector } from 'selectors/article-lists';

import * as featureService from 'services/feature-service/feature-service';

//BEGIN helper methods
const correctMissingDataPoints = articles => {
  const metrics = Object.keys(ARTICLE_METRICS);

  Object.keys(articles).forEach(articleId => {
    const article = articles[articleId];
    metrics.forEach(metric => {
      if (!article[metric] || article[metric] < 0) {
        article[metric] = '-';
      }
    });

    articles[articleId] = article;
  });

  return articles;
};

const averageImpactScore = memoize((articleIds, articles) => {
  const impactScore = articleIds.reduce((result, articleId) => {
    const article = articles[articleId];
    if (!article) {
      return result;
    }
    return result + articles[articleId].impactScore;
  }, 0);

  return impactScore / articleIds.length;
});

export const rollupMetrics = memoize(
  (articleIds, articles, metricConfigs) => {
    metricConfigs = metricConfigs || {
      ...ARTICLE_METRICS,
      ...ARTICLE_STATS,
      ...SOCIAL_ARTICLE_STATS,
    };
    const metricKeys = Object.keys(metricConfigs).filter(
      metric => metricConfigs[metric].duplicateAggregationMethod !== 'none',
    );

    let authors = [];

    const metrics = articleIds.reduce((result, articleId) => {
      const article = articles[articleId];
      if (!article) {
        return result;
      }

      if (article.authorId || article.author) {
        const { author, authorId } = article;
        authors.push({ author, authorId });
      }

      metricKeys.forEach(metric => {
        const value =
          article[metric] && article[metric] !== '-' && article[metric] !== -1
            ? article[metric]
            : 0;
        const rollupValue = result[metric];

        if (!result[metric]) {
          // initialize the accumulator
          result[metric] = value;
        } else {
          result[metric] = rollupValue + value;
        }
      });

      return result;
    }, {});

    metricKeys.forEach(metric => {
      if (!metrics[metric] || metrics[metric] < 0) {
        metrics[metric] = '-';
      }
    });

    authors = uniqWith(authors, isEqual);

    const result = {
      ...metrics,
      authorsCount: authors.length,
    };

    // for metrics that need to be averages, divide by number of dupes
    metricKeys
      .filter(
        metric => metricConfigs[metric].duplicateAggregationMethod === 'avg',
      )
      .forEach(metric => {
        result[metric] /= articleIds.length;
      });
    return result;
  },
  (articleIds, ...params) => {
    let cacheKey = articleIds.join(',');
    if (params.length > 1) {
      const lastIndex = params.length - 1;
      const analyticsIntegrationId = params[lastIndex] || '';
      cacheKey += analyticsIntegrationId.toString();
    }
    return cacheKey;
  },
);
//END helper methods

export function articlesSelector(state) {
  return state.articles && state.articles.articles
    ? correctMissingDataPoints(state.articles.articles)
    : {};
}

export const articlesErrorSelector = state =>
  state.articles ? state.articles.error : false;

export const articlesLoadingSelector = state =>
  state.articles ? state.articles.loading : false;

export const currentArticleIdSelector = state =>
  state.articles ? state.articles.current : null;

export const currentArticleSelector = createSelector(
  currentArticleIdSelector,
  articlesSelector,
  (articleId, articles) => (articleId ? articles[articleId] : initialArticle),
);

export const articleByIdSelector = createSelector(articlesSelector, articles =>
  memoize(articleId => (articleId ? articles[articleId] : null)),
);

export const articlesByIdsSelector = createSelector(
  articlesSelector,
  articles => articleIds => articleIds.map(id => articles[id]),
);

/**
 * Returns all articles in a group.
 * 1) Article is not part of a group
 *    return an array with only that article
 * 2) Article is part of a group and is the parent
 *    return array of articles contained in duplicatePublications
 * 3) Article is part of a group and is a child
 *    return array of articles contained in the parent's duplicatePublications
 */
export const articlesInGroupById = createSelector(
  articlesSelector,
  articles => articleId => {
    const article = articles[articleId];
    if (!article) {
      return [];
    }
    if (article.parentId) {
      return articles[article.parentId].duplicatePublications.map(
        id => articles[id],
      );
    } else if (
      article.duplicatePublications &&
      article.duplicatePublications.length
    ) {
      return article.duplicatePublications.map(id => articles[id]);
    }
    return [article];
  },
);

const getArticleTypeLabel = article =>
  (article ? article.mediaType === MEDIA_TYPES.broadcast.apiKey : '')
    ? ARTICLE_TYPES_LABELS.broadcast
    : ARTICLE_TYPES_LABELS.article;

export const articleByIdTypeLabelSelector = createSelector(
  articlesSelector,
  articles =>
    memoize(articleId => {
      if (!articleId || !articles[articleId]) {
        return '';
      }

      return getArticleTypeLabel(articles[articleId]);
    }),
);

export const currentArticleTypeLabelSelector = createSelector(
  currentArticleSelector,
  getArticleTypeLabel,
);

export const currentContactSelector = state =>
  state.contacts && state.contacts.current
    ? state.contacts.contacts[state.contacts.current]
    : null;

export const currentTagsSelector = state =>
  state.tags.current ? state.tags.tags[state.tags.current] : [];
export const audienceDemographicsSelector = state =>
  state.domainAudienceDemographics.data || {};

// GET LIST OF METRICS THE USER IS ALLOWED TO SEE
const getBaseMetrics = () => ARTICLE_METRICS_BASE_FIELDS;
const getBroadcastMetrics = () =>
  featureService.userHasFeatureFlag(FEATURES.broadcastData)
    ? ARTICLE_METRICS_BROADCAST_FIELDS
    : [];
const getGAMetrics = () =>
  featureService.userHasFeatureFlag(FEATURES.googleAnalytics)
    ? ARTICLE_METRICS_GA_FIELDS
    : [];
const getAAMetrics = () =>
  featureService.userHasFeatureFlag(FEATURES.adobeAnalytics)
    ? ARTICLE_METRICS_AA_FIELDS
    : [];
const getUVMMetrics = () =>
  !featureService.userHasDevFeatureFlag(DEV_FEATURES.desktopUvm)
    ? ARTICLE_METRICS_UVM_FIELDS
    : [];

export const userMetricsSelector = createSelector(
  [
    currentArticleListAnalyticsIntegrationTypeSelector,
    getBaseMetrics,
    getBroadcastMetrics,
    getGAMetrics,
    getAAMetrics,
    getUVMMetrics,
  ],
  (
    currentAnalyticsIntegrationType,
    baseMetrics,
    broadcastMetrics,
    gaMetrics,
    aaMetrics,
    uvmMetrics,
  ) => [
    ...baseMetrics,
    ...broadcastMetrics,
    ...gaMetrics,
    ...aaMetrics,
    ...uvmMetrics,
  ],
);

export const currentAvailableMetrics = createSelector(
  getAAMetrics,
  currentArticleListAnalyticsIntegrationTypeSelector,
  getGAMetrics,
  userMetricsSelector,
  (aaMetrics, analyticsIntegrationType, gaMetrics, userMetrics) => {
    let metrics = ORDERED_METRICS.filter(m => userMetrics.indexOf(m) > -1);

    switch (analyticsIntegrationType) {
      case ANALYTICS_INTEGRATION_TYPES.googleAnalytics.id:
        metrics = metrics.filter(m => aaMetrics.indexOf(m) < 0);
        break;
      case ANALYTICS_INTEGRATION_TYPES.adobeAnalytics.id:
        metrics = metrics.filter(m => gaMetrics.indexOf(m) < 0);
        break;
      default:
        break;
    }

    return metrics;
  },
);

export const getRolledUpMetrics = createSelector(
  articlesSelector,
  articles => articleIds => rollupMetrics(articleIds, articles),
);

export const getAverageImpactScore = createSelector(
  articlesSelector,
  articles => articleIds => averageImpactScore(articleIds, articles),
);

const mapArticleWithDuplicates = articles => articleId => {
  if (!articles[articleId]) {
    return {};
  }
  let articleDuplicates = [];
  if (articles[articleId].duplicatePublications) {
    articleDuplicates = articles[articleId].duplicatePublications;
  }
  return {
    ...articles[articleId],
    duplicatePublications: articleDuplicates.map(id => articles[id]),
  };
};

export const articlesWithDuplicatesSelector = createSelector(
  articlesSelector,
  articles => articleIds => articleIds.map(mapArticleWithDuplicates(articles)),
);

export const articlesWithDupesCountSelector = createSelector(
  articlesSelector,
  articles => articleIds => {
    const articleCount = articleIds.reduce((count, articleId) => {
      const article = articles[articleId];
      if (!article) {
        return count;
      }
      if (
        article.duplicatePublications &&
        article.duplicatePublications.length
      ) {
        return count + article.duplicatePublications.length;
      }
      return count + 1;
    }, 0);

    return articleCount;
  },
);

export const articleIdsSelectedSelector = createSelector(
  articlesSelector,
  articles => articleIds => {
    const selectedArticleIds = articleIds.reduce((ids, articleId) => {
      const article = articles[articleId];

      if (!article) {
        return ids;
      }

      if (article.duplicatePublications) {
        article.duplicatePublications.forEach(id => {
          if (articles[id] && articles[id].selected) {
            ids.push(id);
          }
        });
      }

      if (article.selected) {
        ids.push(article.id);
      }

      return ids;
    }, []);

    return uniq(selectedArticleIds);
  },
);

export const allSelectedArticlesAreExpanded = createSelector(
  articlesSelector,
  articles => articleIds => {
    const selectedArticles = articleIds.map(mapArticleWithDuplicates(articles));
    return selectedArticles.reduce((allExpanded, article) => {
      if (article.selected && !article.expandedMetrics) {
        allExpanded = false;
      }
      return allExpanded;
    }, true);
  },
);
