import React, { Component } from 'react';

import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';

import { Body, DisplaySM, Loader, BarStat } from '@trendkite/ui';

class StoryAudienceDemographics extends Component {
  renderMetrics(data) {
    if (!data || !data.length) return null;

    return data.map(dp => (
      <div
        className={`${StoryAudienceDemographics.baseClass}__metric`}
        key={dp.key}
      >
        <DisplaySM block>{dp.label}</DisplaySM>
        <BarStat percent={dp.percent} showLabel />
      </div>
    ));
  }

  renderNoDataView() {
    return (
      <div className={`${StoryAudienceDemographics.baseClass}__empty-state`}>
        <div
          className={`${StoryAudienceDemographics.baseClass}__empty-state__text`}
        >
          <Body>
            No demographic data available. Demographic data is related to the
            publications covering your Story Kit.
          </Body>
        </div>
      </div>
    );
  }

  render() {
    const { ageDemographics, genderDemographics, loading, title } = this.props;

    const genderMetrics = this.renderMetrics(genderDemographics);
    const ageMetrics = this.renderMetrics(ageDemographics);
    const noDataView = this.renderNoDataView();
    const hasNoData =
      sumBy(genderDemographics, dp => dp.value) <= 0 &&
      sumBy(ageDemographics, dp => dp.value) <= 0;

    return (
      <div className={`${StoryAudienceDemographics.baseClass}`}>
        {title && (
          <div className={`${StoryAudienceDemographics.baseClass}__title`}>
            <DisplaySM weight="bold">{title}</DisplaySM>
          </div>
        )}
        {loading && <Loader />}
        {!loading && hasNoData && noDataView}
        {!loading && !hasNoData && (
          <React.Fragment>
            <div className={`${StoryAudienceDemographics.baseClass}__headers`}>
              <Body color="light" block>
                Gender breakdown
              </Body>
              <Body color="light" block>
                Age breakdown
              </Body>
            </div>
            <div className={`${StoryAudienceDemographics.baseClass}__metrics`}>
              <div
                className={`${StoryAudienceDemographics.baseClass}__gender-metrics`}
              >
                {genderMetrics}
              </div>
              <div
                className={`${StoryAudienceDemographics.baseClass}__age-metrics`}
              >
                {ageMetrics}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

StoryAudienceDemographics.baseClass = 'story-audience-demographics';

StoryAudienceDemographics.propTypes = {
  ageDemographics: PropTypes.array,
  genderDemographics: PropTypes.array,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

StoryAudienceDemographics.defaultProps = {
  ageDemographics: [],
  genderDemographics: [],
  loading: false,
  title: '',
};

export default StoryAudienceDemographics;
