import React, { Component } from 'react';

import classNames from 'classnames';

import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import {
  Button,
  FloatingPanel,
  SvgIcon,
  Toolbar,
  Tooltip,
  Addon,
} from '@trendkite/ui';

import { logoPropTypes } from '../story/templates/default-template';

import messages from './story-logo.messages';

const baseClass = 'tk-story-logo__toolbar';

class StoryLogoToolbarContent extends Component {
  onClickUploadButton = () => {
    if (this.uploadLabel && typeof this.uploadLabel.click === 'function') {
      this.uploadLabel.click();
    }
  };

  render() {
    const {
      logoSize,
      removeLogo,
      setSizeLg,
      setSizeMd,
      setSizeSm,
      toolbarOpen,
      browseLink,
      replaceWithAssetLibrary,
    } = this.props;

    const toolbarClass = classNames(`${baseClass}`, {
      [`${baseClass}--open`]: toolbarOpen,
    });

    return (
      <div key="tk-story-logo-toolbar" className={toolbarClass}>
        <div className={`${baseClass}-center`}>
          <FloatingPanel modifiers={['small']}>
            <Toolbar>
              {[
                <Tooltip content="Small" key="sizeSm">
                  <Button
                    data-qa="rTI5Aep-UfjyzLcvjumll"
                    active={logoSize === 'sm'}
                    modifiers={['seethrough', 'small']}
                    onClick={setSizeSm}
                  >
                    <SvgIcon icon="square" height={16} width={8} />
                    <span className="sr-only">Small</span>
                  </Button>
                </Tooltip>,
                <Tooltip content="Medium" key="sizeMd">
                  <Button
                    data-qa="_xPIQLhhaB50BgynmDnDp"
                    active={logoSize === 'md'}
                    modifiers={['seethrough', 'small']}
                    onClick={setSizeMd}
                  >
                    <SvgIcon icon="square" height={16} width={12} />
                    <span className="sr-only">Medium</span>
                  </Button>
                </Tooltip>,
                <Tooltip content="Large" key="sizeLg">
                  <Button
                    data-qa="7vqbFRMFIT-bJXJK2JLG-"
                    active={logoSize === 'lg'}
                    modifiers={['seethrough', 'small']}
                    onClick={setSizeLg}
                  >
                    <SvgIcon icon="square" height={16} width={16} />
                    <span className="sr-only">Large</span>
                  </Button>
                </Tooltip>,
                <Tooltip content="Upload logo" key="upload">
                  <label
                    className={`${baseClass}__input-label`}
                    htmlFor="logo-input-file"
                    ref={uploadLabel => {
                      this.uploadLabel = uploadLabel;
                    }}
                  >
                    {!browseLink && (
                      <Button
                        data-qa="6Y-ypTiCbLVBfn2CKu1I2"
                        modifiers={['seethrough', 'small']}
                        onClick={this.onClickUploadButton}
                      >
                        <Addon>
                          <SvgIcon icon="upload" />
                          <span className="sr-only">
                            <T {...messages.uploadImageLink} />
                          </span>
                        </Addon>
                      </Button>
                    )}
                    {browseLink && (
                      <Button
                        data-qa="TXUnp6pAuXFBr43lKEg6n"
                        modifiers={['seethrough', 'small']}
                        onClick={replaceWithAssetLibrary}
                      >
                        <Addon>
                          <SvgIcon icon="upload" />
                          <span className="sr-only">
                            <T {...messages.uploadImageLink} />
                          </span>
                        </Addon>
                      </Button>
                    )}
                  </label>
                </Tooltip>,
                <Tooltip content="Remove logo" key="remove">
                  <Button
                    data-qa="NnpxWgsMmQAgn3p-5kiua"
                    modifiers={['seethrough', 'small']}
                    onClick={removeLogo}
                  >
                    <SvgIcon icon="bin" />
                    <span className="sr-only">Remove logo</span>
                  </Button>
                </Tooltip>,
              ]}
            </Toolbar>
          </FloatingPanel>
        </div>
      </div>
    );
  }
}

StoryLogoToolbarContent.propTypes = {
  logoSize: logoPropTypes.logoSize.isRequired,
  removeLogo: PropTypes.func.isRequired,
  setSizeSm: PropTypes.func.isRequired,
  setSizeMd: PropTypes.func.isRequired,
  setSizeLg: PropTypes.func.isRequired,
  toolbarOpen: PropTypes.bool.isRequired,
  browseLink: PropTypes.bool,
  replaceWithAssetLibrary: PropTypes.func,
};

const StoryLogoToolbar = props => {
  const { toolbarOpen } = props;

  return (
    <CSSTransition
      in={toolbarOpen}
      classNames="tk-story-logo__toolbar-transition"
      timeout={200}
      unmountOnExit
    >
      <StoryLogoToolbarContent {...props} />
    </CSSTransition>
  );
};

StoryLogoToolbar.propTypes = {
  logoSize: logoPropTypes.logoSize.isRequired,
  removeLogo: PropTypes.func.isRequired,
  setSizeSm: PropTypes.func.isRequired,
  setSizeMd: PropTypes.func.isRequired,
  setSizeLg: PropTypes.func.isRequired,
  toolbarOpen: PropTypes.bool.isRequired,
};

export default StoryLogoToolbar;
