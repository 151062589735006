import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from '../svg-icon';

class AccordionListEntry extends Component {
  onSelect = () => {
    const { disabled, entry, onSelect } = this.props;
    if (disabled) {
      return;
    }
    onSelect(entry);
  };

  render() {
    const { disabled, entry, selected } = this.props;

    const classes = classNames('tk-accordion-list-entry', {
      'tk-accordion-list-entry--disabled': disabled,
    });
    const title = entry.titleString ? entry.titleString : entry.title;
    const { subtitle } = entry;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        data-qa="J2V5oo7pbA3P1YZBLnml8"
        className={classes}
        onClick={this.onSelect}
        role="button"
        tabIndex="0"
      >
        <div className="tk-accordion-list-entry__data-title">
          {selected && <SvgIcon icon="checkmark" />}
          {typeof title === 'string' ? <span>{title}</span> : title}
        </div>
        {subtitle && (
          <div className="tk-accordion-list-entry__data-subtitle">
            {subtitle}
          </div>
        )}
      </div>
    );
  }
}

AccordionListEntry.defaultProps = {
  disabled: false,
  onSelect: () => {},
  selected: false,
};

AccordionListEntry.propTypes = {
  disabled: PropTypes.bool,
  entry: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleString: PropTypes.string,
    subtitle: PropTypes.string,
    svgIcon: PropTypes.string,
    infoIcon: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default AccordionListEntry;
