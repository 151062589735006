export const THREAD_TYPES = {
  PITCH: 'Pitch',
  EMAIL_ANNOUNCEMENT: 'BulkCampaign',
};

export const THREAD_STATUSES = {
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  SENT: 'SENT',
};

export const THREAD_TABS = {
  PREVIEW: 'preview',
  SNAPSHOT: 'snapshot',
};

export const THREAD_SORT_FIELD = {
  DATE: 'Date',
  UPDATED: 'Updated',
  SCHEDULED: 'Scheduled',
  SENT: 'Sent',
};

export const THREAD_SORT_ORDER = {
  ASC: 'Asc',
  DESC: 'Desc',
};
