import { truncate } from 'lodash';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import unionBy from 'lodash/unionBy';
import qs from 'qs';

import { Permissions } from 'components/influencers/saved-lists-table/bulk-saved-lists-modal';
import {
  INFLUENCER_SAVED_LISTS_ENDPOINT,
  INFLUENCER_SAVED_LISTS_WITH_VIRTUAL_SELECTION_ENDPOINT,
  INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT,
  INFLUENCERS_GET_INFLUENCERS_COLLECTION_URL,
  API_BASE_URL,
  INFLUENCER_SAVED_LISTS_COPY_ENDPOINT,
  INFLUENCER_DUPLICATE_SAVED_LISTS_ENDPOINT,
  MERGE_SAVED_LISTS_ENDPOINT,
} from 'constants/apis';

import { getCampaignsForUser } from 'reducers/campaigns/campaign-list';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import restServiceCache from 'services/rest-service/cache';

import {
  performGet,
  performPost,
  performDelete,
  performPut,
} from 'services/rest-service/rest-service';

import {
  getCurrentUserId,
  getCurrentUserAccountId,
  getCurrentUserName,
} from 'services/user-service/user-service';

import { resetSearchResultSortSelection } from '../../Influencers/Search/reducer';
import { loadInfluencerListTitleForm } from '../reducers/influencer-list-title-form';

import {
  SELECT_INFLUENCER_SAVED_LIST,
  addInfluencerToListActionDispatcher,
} from '../reducers/saved-list';

export const CREATE_LIST_START = 'lists/CREATE_LIST_START';
export const CREATE_LIST_SUCCESS = 'lists/CREATE_LIST_SUCCESS';
export const CREATE_LIST_ERROR = 'lists/CREATE_LIST_ERROR';
export const SET_SELECTED_MERGE_LIST_OBJ = 'lists/SET_SELECTED_MERGE_LIST_NAME';
export const DUPLICATE_LIST_START = 'lists/DUPLICATE_LIST_START';
export const DUPLICATE_LIST_SUCCESS = 'lists/DUPLICATE_LIST_SUCCESS';
export const DUPLICATE_LIST_ERROR = 'lists/DUPLICATE_LIST_ERROR';
export const DELETE_LIST_SUCCESS = 'lists/DELETE_LIST_SUCCESS';
export const DELETE_LIST_SUCCESS_DUPLICATE =
  'lists/DELETE_LIST_SUCCESS_DUPLICATE';
export const MERGE_LIST_START = 'lists/MERGE_LIST_START';
export const MERGE_LIST_SUCCESS = 'lists/MERGE_LISTS_SUCCESS';
export const MERGE_LIST_ERROR = 'lists/MERGE_LISTS_ERROR';
export const TOGGLE_MERGE_LIST_MODAL = 'lists/TOGGLE_MERGE_LIST_MODAL_OPEN';
export const MERGE_CONFIRMATION_MODAL = 'lists/MERGE_CONFIRMATION_MODAL';
export const SELECTED_TO_LIST_NAME = 'lists/SELECTED_TO_LIST_NAME';
export const SHOW_MERGE_LOADER = 'lists/SHOW_MERGE_LOADER';
export const BULK_DELETE_LIST_SUCCESS = 'lists/BULK_DELETE_LIST_SUCCESS';
export const RESET_REDIRECT_AFTER_DELETE_LIST =
  'lists/RESET_REDIRECT_AFTER_DELETE_LIST';
export const RESET_DUPLICATE_LIST_MODAL = 'lists/RESET_DUPLICATE_LIST_MODAL';
export const TOGGLE_BULK_SAVED_LIST_MODAL =
  'lists/TOGGLE_BULK_SAVED_LIST_MODAL';
export const TOGGLE_BULK_CONFIRMATION_MODAL =
  'lists/TOGGLE_BULK_CONFIRMATION_MODAL';
export const TOGGLE_DELETE_MODAL = 'lists/TOGGLE_DELETE_MODAL';
export const TOGGLE_DUPLICATE_LIST_MODAL = 'lists/TOGGLE_DUPLICATE_LIST_MODAL';
export const SAVE_SELECTED_LIST_ID = 'lists/SELECTED_SAVED_LIST_ID';
export const SET_DUPLICATE_LIST_ID = 'lists/DUPLICATE_LIST_ID';
export const SET_PERMISSIONS = 'Lists/SET_PERMISSIONS';
export const TOGGLE_BULK_DELETE_MODAL = 'lists/TOGGLE_BULK_DELETE_MODAL';
export const TOGGLE_EXPORT_LIST_MODAL = 'lists/TOGGLE_EXPORT_LIST_MODAL';
export const SET_SELECTED_SAVED_LISTS = 'lists/SET_SELECTED_SAVED_LISTS';
export const CLEAR_ALL_SELECTED_SAVED_LISTS =
  'lists/CLEAR_ALL_SELECTED_SAVED_LISTS';
export const SET_SELECTED_SAVED_LIST = 'lists/SET_SELECTED_SAVED_LIST';
export const SET_BULK_SELECTED_SAVED_LISTS =
  'lists/SET_BULK_SELECTED_SAVED_LISTS';
export const INFLUENCERS_SAVED_LIST_START =
  'ihub-search/INFLUENCERS_SAVED_LIST_START';
export const INFLUENCERS_CUSTOM_LIST_START =
  'ihub-search/INFLUENCERS_CUSTOM_LIST_START';
export const INFLUENCERS_ACTION_LIST_START =
  'ihub-search/INFLUENCERS_ACTION_LIST_START';
export const INFLUENCERS_SAVED_LIST_SUCCESS =
  'ihub-search/INFLUENCERS_SAVED_LIST_SUCCESS';
export const INFLUENCERS_SAVED_LIST_SUCCESS_MODAL =
  'ihub-search/INFLUENCERS_SAVED_LIST_SUCCESS_MODAL';
export const INFLUENCERS_CUSTOM_LIST_SUCCESS =
  'ihub-search/INFLUENCERS_CUSTOM_LIST_SUCCESS';
export const INFLUENCERS_CUSTOM_LIST_FINISH_LOADING =
  'ihub-search/INFLUENCERS_CUSTOM_LIST_FINISH_LOADING';
export const INFLUENCERS_SAVED_LIST_ERROR =
  'ihub-search/INFLUENCERS_SAVED_LIST_ERROR';
export const INFLUENCERS_SAVED_ACTION_LIST_ERROR =
  'ihub-search/INFLUENCERS_SAVED_ACTION_LIST_ERROR';
export const INFLUENCERS_SAVED_LIST_CURRENT_SEARCH =
  'ihub-search/INFLUENCERS_SAVED_LIST_CURRENT_SEARCH';
export const INFLUENCERS_SAVED_LIST_CURRENT_SEARCH_RESET =
  'ihub-search/INFLUENCERS_SAVED_LIST_CURRENT_SEARCH_RESET';
export const INFLUENCERS_SELECT_INFLUENCER_ID =
  'ihub-search/INFLUENCERS_SELECT_INFLUENCER_ID';
export const INFLUENCERS_UNSELECT_INFLUENCER_ID =
  'ihub-search/INFLUENCERS_UNSELECT_INFLUENCER_ID';
export const GET_INFLUENCERS_AVATARS = 'ihub/GET_INFLUENCERS_AVATARS';
export const GET_INFLUENCERS_AVATARS_SUCCESS =
  'ihub/GET_INFLUENCERS_AVATARS_SUCCESS';
export const GET_INFLUENCERS_AVATARS_ERROR =
  'ihub/GET_INFLUENCERS_AVATARS_ERROR';
export const GET_INFLUENCERS_SAVED_LISTS = 'ihub/GET_INFLUENCERS_SAVED_LISTS';
export const GET_INFLUENCERS_SAVED_LISTS_SUCCESS =
  'ihub/GET_INFLUENCERS_SAVED_LISTS_SUCCESS';
export const GET_INFLUENCERS_SAVED_LISTS_ERROR =
  'ihub/GET_INFLUENCERS_SAVED_LISTS_ERROR';
export const SET_SAVED_LISTS_SEARCH_TERM = 'lists/SET_SAVED_LISTS_SEARCH_TERM';
export const SET_SAVED_LISTS_SORT_DIRECTION =
  'lists/SET_SAVED_LISTS_SORT_DIRECTION';
export const SET_SAVED_LISTS_SORT_FIELD = 'lists/SET_SAVED_LISTS_SORT_FIELD';
export const SET_CAMPAIGN_FILTER_FIELD = 'lists/SET_CAMPAIGN_FILTER_FIELD';
export const TOGGLE_SHARE_MODAL = 'lists/TOGGLE_SHARE_MODAL';
export const TOGGLE_SHARE_SETTINGS_CONFIRMATION_MODAL =
  'lists/TOGGLE_SHARE_SETTINGS_CONFIRMATION_MODAL';
export const CHECK_SHARED_NAME_ERROR = 'lists/CHECK_SHARED_NAME_ERROR';
export const REMOVE_ADDED_LIST = 'lists/REMOVE_ADDED_LIST';
export const TOGGLE_RENAME_SAVED_LIST_MODAL =
  'lists/TOGGLE_RENAME_SAVED_LIST_MODAL';
export const SET_SHARE_DATA = 'lists/SET_SHARE_DATA';
export const TOGGLE_ADD_LIST_TO_CAMPAIGNS_MODAL_OPEN =
  'lists/TOGGLE_ADD_LIST_TO_CAMPAIGNS_MODAL_OPEN';
export const SET_LIST_TO_ADD_TO_CAMPAIGNS =
  'lists/SET_LIST_TO_ADD_TO_CAMPAIGNS';
export const UPDATE_SELECTED_LIST_SHARE_PERMISSIONS =
  'lists/UPDATE_SELECTED_LIST_SHARE_PERMISSIONS';
export const TOGGLE_ACTION_LIST_MODAL_OPEN =
  'lists/TOGGLE_ACTION_LIST_MODAL_OPEN';
export const TOGGLE_ACTION_LIST_MODAL_OPEN_IS_COPY =
  'lists/TOGGLE_ACTION_LIST_MODAL_OPEN_IS_COPY';
export const INFLUENCERS_SAVED_LIST_COPY_START =
  'lists/INFLUENCERS_SAVED_LIST_COPY_START';
export const INFLUENCERS_SAVED_LIST_COPY_SUCCESS =
  'lists/INFLUENCERS_SAVED_LIST_COPY_SUCCESS';
export const INFLUENCERS_SAVED_LIST_COPY_ERROR =
  'lists/INFLUENCERS_SAVED_LIST_COPY_ERROR';
export const GET_SAVED_SEARCHES_START =
  'influencer-hub/GET_SAVED_SEARCHES_START';
export const GET_SAVED_SEARCHES_SUCCESS =
  'influencer-hub/GET_SAVED_SEARCHES_SUCCESS';
export const GET_SAVED_SEARCHES_ERROR =
  'influencer-hub/GET_SAVED_SEARCHES_ERROR';
export const SAVED_SEARCH_LIST_ENTERY_MODAL_OPEN =
  'influencer-hub/SAVED_SEARCH_LIST_ENTERY_MODAL_OPEN';
export const SET_CAMPAIGN_ID = 'lists/SET_CAMPAIGN_ID';

export const initialState = {
  selectedNewMergeListObj: {
    newListName: '',
    selectedPermission: '',
  },
  duplicateListLoader: false,
  loading: false,
  customLoading: false,
  actionListLoading: false,
  areAllSidebarListsVisible: false,
  error: false,
  customError: false,
  actionListError: false,
  selectedSavedList: {} as any,
  selectedIds: [],
  isMultiSelect: false,
  selectedSavedLists: [],
  list: {
    data: [] as any[], // TODO: get a real type for this
    totalLists: 0,
    currentPageNumber: 0,
  },
  customList: {
    data: [] as any[],
    totalLists: 0,
    currentPageNumber: 0,
  },
  actionList: {
    data: [] as any[],
    totalLists: 0,
    currentPageNumber: 0,
  },
  addedList: null,
  bulkSavedListModalOpen: false,
  bulkConfirmationModalOpen: false,
  deleteSavedListModalOpen: false,
  actionListCopyListModalOpen: false,
  isCopyList: false,
  duplicateSavedListModalOpen: false,
  duplicateListName: '',
  duplicateListId: '',
  actionMergeListModalOpen: false,
  mergeConfirmationModalOpen: false,
  showSelectedToName: '',
  bulkDeleteSavedListModalOpen: false,
  selectedSavedListsFullAccess: 0,
  selectedSavedListsOnlyView: 0,
  selectedSavedListsAvailable: [],
  shouldRedirectAfterDelete: false,
  shareListModalOpen: false,
  shareSettingsConfirmationOpen: false,
  renameSavedListModalOpen: false,
  searchTerm: '',
  sort: {
    direction: 'desc',
    field: 'last_updated',
  },
  suggestedName: '',
  listId: '',
  addListToCampaignsModalOpen: false,
  selectedCampaignFilter: 'all',
  currentSearch: '',
  hasSaveSearchListEnteryModalOpen: false,
  campaignId: null,
};

const influencerSavedListsReducer = (
  state = {
    ...initialState,
  },
  action,
) => {
  switch (action.type) {
    case DELETE_LIST_SUCCESS: {
      const { data } = state.list;

      const newSelectedSavedList =
        state.selectedSavedList &&
        state.selectedSavedList.id === action.payload.listId
          ? {}
          : state.selectedSavedList;

      return {
        ...state,
        shouldRedirectAfterDelete: action.payload.redirect,
        selectedSavedList: newSelectedSavedList,
        list: {
          ...state.list,
          totalLists: state.list.totalLists - 1,
          data: data.filter(d => d.id !== action.payload.listId),
        },
      };
    }

    case DELETE_LIST_SUCCESS_DUPLICATE: {
      const { data } = state.list;

      const newSelectedSavedList =
        state.selectedSavedList &&
        state.selectedSavedList.id === action.payload.listId
          ? {}
          : state.selectedSavedList;

      return {
        ...state,
        shouldRedirectAfterDelete: action.payload.redirect,
        selectedSavedList: newSelectedSavedList,
        list: {
          ...state.list,
          totalLists: state.list.totalLists,
          data: data.filter(d => d.id !== action.payload.listId),
        },
      };
    }

    case BULK_DELETE_LIST_SUCCESS: {
      return {
        ...state,
        shouldRedirectAfterDelete: action.payload.redirect,
        list: {
          ...state.list,
          totalLists: action.payload.totalLists,
          data: action.payload.data,
        },
      };
    }

    case RESET_REDIRECT_AFTER_DELETE_LIST: {
      return {
        ...state,
        shouldRedirectAfterDelete: false,
      };
    }

    case INFLUENCERS_SAVED_LIST_START: {
      const { payload = {} } = action;
      const { append = false } = payload;

      const nextList = !append
        ? {
            data: [],
            totalLists: 0,
            currentPageNumber: 0,
          }
        : {};

      return {
        ...state,
        loading: true,
        error: false,
        list: {
          ...state.list,
          ...nextList,
        },
      };
    }

    case INFLUENCERS_SAVED_LIST_SUCCESS: {
      const {
        append,
        currentPageNumber,
        data,
        totalLists,
        currentSearch,
        overrideList,
      } = action.payload;

      if (currentSearch === state.currentSearch || overrideList) {
        const nextData = append ? [...state.list.data, ...data] : data;

        return {
          ...state,
          loading: false,
          error: false,
          list: {
            ...state.list,
            data: nextData,
            totalLists,
            currentPageNumber,
          },
        };
      }

      return state;
    }

    case INFLUENCERS_SAVED_LIST_SUCCESS_MODAL: {
      const { append, currentPageNumber, data, totalLists } = action.payload;

      const nextData = append ? [...state.list.data, ...data] : data;

      return {
        ...state,
        actionListLoading: false,
        actionListError: false,
        actionList: {
          ...state.actionList,
          data: nextData,
          totalLists,
          currentPageNumber,
        },
      };
    }

    case INFLUENCERS_SAVED_LIST_CURRENT_SEARCH: {
      return {
        ...state,
        currentSearch: action.payload,
      };
    }

    case INFLUENCERS_SAVED_LIST_CURRENT_SEARCH_RESET: {
      return {
        ...state,
        currentSearch: '',
      };
    }

    case INFLUENCERS_CUSTOM_LIST_START: {
      return {
        ...state,
        customLoading: true,
        customError: false,
        customList: {
          ...state.customList,
        },
      };
    }

    case INFLUENCERS_ACTION_LIST_START: {
      return {
        ...state,
        actionListLoading: true,
        actionListError: false,
        actionList: {
          ...state.actionList,
        },
      };
    }

    case INFLUENCERS_CUSTOM_LIST_SUCCESS: {
      const {
        payload: { currentPageNumber, data, totalLists, append },
      } = action;

      const nextData = append ? [...state.customList.data, ...data] : data;

      return {
        ...state,
        customLoading: false,
        customError: false,
        customList: {
          data: nextData,
          totalLists,
          currentPageNumber,
        },
      };
    }

    case INFLUENCERS_CUSTOM_LIST_FINISH_LOADING: {
      const { areAllSidebarListsVisible } = action.payload;
      return {
        ...state,
        customLoading: false,
        areAllSidebarListsVisible,
      };
    }

    case INFLUENCERS_SAVED_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        list: {
          ...state.list,
          data: [],
          totalLists: 0,
          currentPageNumber: 0,
        },
      };
    }

    case INFLUENCERS_SAVED_ACTION_LIST_ERROR: {
      return {
        ...state,
        actionListLoading: false,
        actionListError: true,
        actionList: {
          ...state.actionList,
          data: [],
          totalLists: 0,
          currentPageNumber: 0,
        },
      };
    }

    case CREATE_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case CREATE_LIST_SUCCESS: {
      const totalLists = state.list.totalLists;
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          data: [...state.list.data],
          totalLists,
        },
        addedList: {
          ...action.payload,
        },
      };
    }

    case SET_SELECTED_MERGE_LIST_OBJ:
      return {
        ...state,
        selectedNewMergeListObj: {
          newListName: action.payload.name,
          selectedPermission: action.payload.permissions,
        },
      };

    case CREATE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DUPLICATE_LIST_START: {
      return {
        ...state,
        duplicateListLoader: true,
        error: false,
      };
    }
    case DUPLICATE_LIST_SUCCESS: {
      return {
        ...state,
        duplicateListLoader: false,
        error: false,
      };
    }
    case DUPLICATE_LIST_ERROR: {
      return {
        ...state,
        duplicateListLoader: false,
        error: action.payload,
      };
    }
    case TOGGLE_MERGE_LIST_MODAL:
      return {
        ...state,
        actionMergeListModalOpen: !state.actionMergeListModalOpen,
      };
    case MERGE_CONFIRMATION_MODAL:
      return {
        ...state,
        mergeConfirmationModalOpen: !state.mergeConfirmationModalOpen,
      };
    case SELECTED_TO_LIST_NAME:
      return {
        ...state,
        showSelectedToName: action.payload,
      };
    case MERGE_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case MERGE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case MERGE_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case TOGGLE_BULK_SAVED_LIST_MODAL:
      return {
        ...state,
        bulkSavedListModalOpen: !state.bulkSavedListModalOpen,
      };

    case TOGGLE_BULK_CONFIRMATION_MODAL:
      return {
        ...state,
        name: action.payload,
        bulkConfirmationModalOpen: !state.bulkConfirmationModalOpen,
      };

    case TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteSavedListModalOpen: !state.deleteSavedListModalOpen,
      };

    case RESET_DUPLICATE_LIST_MODAL:
      return {
        ...state,
        duplicateSavedListModalOpen: false,
        duplicateListLoader: false,
      };

    case TOGGLE_DUPLICATE_LIST_MODAL:
      return {
        ...state,
        duplicateListName: action.payload ? action.payload : '',
        duplicateSavedListModalOpen: !state.duplicateSavedListModalOpen,
      };
    case SET_DUPLICATE_LIST_ID:
      return {
        ...state,
        duplicateListId: action.payload,
      };

    case SET_PERMISSIONS:
      return {
        ...state,
        selectedListPermissions: action.payload,
      };
    case TOGGLE_BULK_DELETE_MODAL:
      return {
        ...state,
        bulkDeleteSavedListModalOpen: !state.bulkDeleteSavedListModalOpen,
      };

    case TOGGLE_SHARE_MODAL: {
      return {
        ...state,
        shareListModalOpen: !state.shareListModalOpen,
      };
    }

    case TOGGLE_SHARE_SETTINGS_CONFIRMATION_MODAL: {
      return {
        ...state,
        shareSettingsConfirmationOpen: !state.shareSettingsConfirmationOpen,
      };
    }

    case TOGGLE_RENAME_SAVED_LIST_MODAL: {
      return {
        ...state,
        renameSavedListModalOpen: !state.renameSavedListModalOpen,
        suggestedName: action.payload,
      };
    }

    case TOGGLE_ADD_LIST_TO_CAMPAIGNS_MODAL_OPEN: {
      return {
        ...state,
        addListToCampaignsModalOpen: !state.addListToCampaignsModalOpen,
      };
    }

    case SET_LIST_TO_ADD_TO_CAMPAIGNS: {
      return {
        ...state,
        listId: action.payload,
      };
    }

    case SET_SELECTED_SAVED_LIST:
      return {
        ...state,
        selectedSavedList: action.payload,
      };

    case SET_SELECTED_SAVED_LISTS:
      return {
        ...state,
        selectedSavedLists: action.payload,
      };

    case CLEAR_ALL_SELECTED_SAVED_LISTS:
      return {
        ...state,
        selectedSavedLists: [],
        selectedSavedListsFullAccess: 0,
        selectedSavedListsOnlyView: 0,
        selectedSavedListsAvailable: [],
      };

    case SET_BULK_SELECTED_SAVED_LISTS:
      return {
        ...state,
        selectedSavedListsFullAccess: action.payload.fullAccess.length,
        selectedSavedListsOnlyView: action.payload.viewOnly.length,
        selectedSavedListsAvailable: action.payload.available,
      };

    case GET_INFLUENCERS_AVATARS: {
      return {
        ...state,
        loading: action.payload,
        error: false,
      };
    }

    case GET_INFLUENCERS_AVATARS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        list: {
          ...state.list,
          data: action.payload,
        },
      };
    }

    case GET_INFLUENCERS_AVATARS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case GET_INFLUENCERS_SAVED_LISTS: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case GET_INFLUENCERS_SAVED_LISTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }

    case GET_INFLUENCERS_SAVED_LISTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case SET_SAVED_LISTS_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };

    case SET_SAVED_LISTS_SORT_DIRECTION:
      return {
        ...state,
        sort: {
          ...state.sort,
          direction: action.payload,
        },
      };

    case SET_SAVED_LISTS_SORT_FIELD:
      return {
        ...state,
        sort: {
          ...state.sort,
          field: action.payload,
        },
      };

    case SET_CAMPAIGN_FILTER_FIELD:
      return {
        ...state,
        selectedCampaignFilter: action.payload,
      };

    case CHECK_SHARED_NAME_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case REMOVE_ADDED_LIST:
      return {
        ...state,
        addedList: null,
      };
    case UPDATE_SELECTED_LIST_SHARE_PERMISSIONS:
      return {
        ...state,
        selectedSavedList: action.payload,
        list: {
          ...state.list,
          data: state.list.data.map(list => {
            return list.id === action.payload.id ? action.payload : list;
          }),
        },
      };
    case TOGGLE_ACTION_LIST_MODAL_OPEN:
      return {
        ...state,
        actionListCopyListModalOpen: !state.actionListCopyListModalOpen,
      };
    case TOGGLE_ACTION_LIST_MODAL_OPEN_IS_COPY:
      return {
        ...state,
        isCopyList: !state.isCopyList,
        isMultiSelect: !state.isMultiSelect,
      };
    case INFLUENCERS_SAVED_LIST_COPY_START:
      return {
        ...state,
        actionListLoading: true,
        actionListError: false,
      };
    case INFLUENCERS_SAVED_LIST_COPY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case INFLUENCERS_SAVED_LIST_COPY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SET_CAMPAIGN_ID:
      return {
        ...state,
        campaignId: action.payload,
      };
    default:
      return state;
  }
};

export const bustSavedListsCache = () => {
  const urlMatcherForCacheBusting = new RegExp(INFLUENCER_SAVED_LISTS_ENDPOINT);
  restServiceCache.deleteKeysByUrl(urlMatcherForCacheBusting);
};

// TODO: Remove after actual requests are implemented and used
export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const addListToCampaigns = (listId, campaignsIds) => async dispatch => {
  try {
    await performPost(`${API_BASE_URL}/lists/${listId}/campaigns`, {
      campaignIds: campaignsIds,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Successfully added list to campaign.',
        status: 'success',
      }),
    );
  } catch (err) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Failed to add list to campaign',
        status: 'danger',
      }),
    );
  }
};

export const getInfluencersAvatarsInfo = (paginationLoading = false) => async (
  dispatch,
  getState,
) => {
  const currentListsState = getState().savedLists.list.data;
  const uniqueInfluencerIds = new Set();

  filter(currentListsState, list => {
    return !isEmpty(list.influencerIds);
  }).forEach(list => {
    take(list.influencerIds, 5).forEach(id => uniqueInfluencerIds.add(id));
  });

  if (uniqueInfluencerIds.size === 0) {
    return;
  }

  dispatch({
    type: GET_INFLUENCERS_AVATARS,
    payload: paginationLoading,
  });

  performPost(INFLUENCERS_GET_INFLUENCERS_COLLECTION_URL, {
    display: 'Full',
    influencerids: Array.from(uniqueInfluencerIds),
    from: 0,
    size: uniqueInfluencerIds.size,
  })
    .then(result => {
      const returnedInfluencers = result.data.data;

      // Find the entries in the lists that need these avatars and do the replacement
      currentListsState.forEach((list, index) => {
        if (!isEmpty(list.influencerIds)) {
          currentListsState[index].influencersCount = [];

          take(list.influencerIds, 5).forEach(influencerId => {
            const match = find(returnedInfluencers, i => i.id === influencerId);

            if (match) {
              currentListsState[index].influencersCount.push(match);
            }
          });
        }
      });

      dispatch({
        type: GET_INFLUENCERS_AVATARS_SUCCESS,
        payload: [...currentListsState],
      });
    })
    .catch(e => {
      dispatch({
        type: GET_INFLUENCERS_AVATARS_ERROR,
        payload: e.message,
      });
    });
};

export const resetSavedListsActionCreator = () => ({
  type: INFLUENCERS_SAVED_LIST_START,
});

export const resetAddedListActionCreator = () => ({
  type: REMOVE_ADDED_LIST,
});

export const getStartingRecordNumber = (pageNumber, pageSize) => {
  return pageNumber <= 1 ? 0 : (pageNumber - 1) * pageSize;
};

export const getSavedListsActionCreator = props => async (
  dispatch,
  getState,
) => {
  bustSavedListsCache();
  const sort = getState().savedLists.sort || {};
  const {
    name = '',
    pageNumber = 1,
    size = 50,
    sortBy: forceSortBy,
    sortOrder: forceSortOrder,
    reset = true,
    appendData = false,
    filterByCampaign = '',
    paginationLoading = false,
  } = props;
  const sortBy = forceSortBy || sort.field;
  const sortOrder = forceSortOrder || sort.direction;

  dispatch({ type: INFLUENCERS_SAVED_LIST_CURRENT_SEARCH, payload: name });
  dispatch({
    type: INFLUENCERS_SAVED_LIST_START,
    payload: { append: appendData },
  });

  if (reset) {
    dispatch(resetSearchResultSortSelection());
  }

  try {
    const useLocalCache = true;
    const localCacheTtl = 10 * 60; // 10 min in seconds

    // the Whitepages api which is called to get the lists is a thin layer over the ES api
    // it uses 0 based offset for the from parameter, therefore we have to calculate the offset/record number
    // so send in the from query parameter.
    const recordOffSet = getStartingRecordNumber(pageNumber, size);

    const response = name
      ? await performPut(INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT, {
          name,
          from: recordOffSet,
          size,
          sortBy,
          sortOrder,
          campaignIds: filterByCampaign,
          matchType: 'contains',
          includeDeleted: false,
          visibility: 'user',
        })
      : await performGet(
          INFLUENCER_SAVED_LISTS_ENDPOINT,
          {
            from: recordOffSet,
            size,
            sortBy,
            sortOrder,
            campaignIds: filterByCampaign,
          },
          undefined,
          undefined,
          useLocalCache,
          0,
          localCacheTtl,
        );

    const totalLists = response?.data?.total || 0;
    const hits = response?.data?.hits;

    const payload = {
      append: appendData,
      data: hits,
      totalLists,
      currentPageNumber: pageNumber,
      currentSearch: name,
    };

    dispatch({
      type: INFLUENCERS_SAVED_LIST_SUCCESS,
      payload,
    });

    if (reset) {
      dispatch(getInfluencersAvatarsInfo(paginationLoading));
    }

    if (!filterByCampaign) {
      dispatch({
        type: SET_CAMPAIGN_FILTER_FIELD,
        payload: 'all',
      });
    }
  } catch (err) {
    dispatch({
      type: INFLUENCERS_SAVED_LIST_ERROR,
      error: {
        message: 'something went wrong',
      },
    });
  }
};

export const getSavedListsActionCreatorModal = props => async (
  dispatch,
  getState,
) => {
  const sort = getState().savedLists.sort || {};
  const {
    name = '',
    pageNumber = 1,
    size = 50,
    sortBy: forceSortBy,
    sortOrder: forceSortOrder,
    reset = true,
    appendData = false,
    filterByCampaign = '',
  } = props;
  const sortBy = forceSortBy || sort.field;
  const sortOrder = forceSortOrder || sort.direction;

  dispatch({
    type: INFLUENCERS_ACTION_LIST_START,
    payload: { append: appendData },
  });

  if (reset) {
    dispatch(resetSearchResultSortSelection());
  }

  try {
    const useLocalCache = true;
    const localCacheTtl = 10 * 60; // 10 min in seconds

    // the Whitepages api which is called to get the lists is a thin layer over the ES api
    // it uses 0 based offset for the from parameter, therefore we have to calculate the offset/record number
    // so send in the from query parameter.
    const recordOffSet = getStartingRecordNumber(pageNumber, size);

    const response = name
      ? await performPut(INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT, {
          name,
          from: recordOffSet,
          size,
          sortBy,
          sortOrder,
          campaignIds: filterByCampaign,
          matchType: 'contains',
          includeDeleted: false,
          visibility: 'user',
        })
      : await performGet(
          INFLUENCER_SAVED_LISTS_ENDPOINT,
          {
            from: recordOffSet,
            size,
            sortBy,
            sortOrder,
            campaignIds: filterByCampaign,
          },
          undefined,
          undefined,
          useLocalCache,
          0,
          localCacheTtl,
        );

    const totalLists = response?.data?.total || 0;
    const hits = response?.data?.hits;

    const payload = {
      append: appendData,
      data: hits,
      totalLists,
      currentPageNumber: pageNumber,
    };

    dispatch({
      type: INFLUENCERS_SAVED_LIST_SUCCESS_MODAL,
      payload,
    });
  } catch (err) {
    dispatch({
      type: INFLUENCERS_SAVED_ACTION_LIST_ERROR,
      error: {
        message: 'something went wrong',
      },
    });
  }
};

export const getSavedListByIdActionDispatcher = (
  savedListId,
  campaignId = null,
) => async dispatch => {
  dispatch({
    type: INFLUENCERS_SAVED_LIST_START,
    payload: { append: false },
  });

  try {
    //Clear locally stored list when fetching a new one
    localStorage.setItem(SET_SELECTED_SAVED_LIST, JSON.stringify({}));

    const response = await performPut(
      `${INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT}`,
      {
        listIds: [savedListId],
      },
    );
    const list = isEmpty(response.data.hits) ? [] : response.data.hits;
    const payload = {
      data: list,
      totalLists: response.total || 0,
      currentPageNumber: 1,
      campaignId,
      overrideList: true,
    };
    dispatch({
      type: INFLUENCERS_SAVED_LIST_SUCCESS,
      payload,
    });
    dispatch({
      type: SELECT_INFLUENCER_SAVED_LIST,
      payload: list[0] || {},
    });
    localStorage.setItem(
      SET_SELECTED_SAVED_LIST,
      JSON.stringify(list[0] || {}),
    );
  } catch (e) {
    dispatch({
      type: INFLUENCERS_SAVED_LIST_ERROR,
    });
  }
};

export const postSavedListSearchActionCreator = ({
  name = '',
}) => async dispatch => {
  const params = {
    name,
    matchType: 'contains',
    sortBy: 'name',
    sortOrder: 'asc',
  };

  dispatch({ type: INFLUENCERS_SAVED_LIST_START });
  dispatch({ type: INFLUENCERS_SAVED_LIST_CURRENT_SEARCH, payload: name });

  try {
    const { data } = await performPost(
      `${INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT}?${qs.stringify(params)}`,
    );
    const hits = data && data.hits && [...data.hits];

    const payload = {
      data: hits,
      totalLists: data.total || 0,
      currentPageNumber: 1,
      currentSearch: name,
    };

    dispatch({
      type: INFLUENCERS_SAVED_LIST_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: INFLUENCERS_SAVED_LIST_ERROR,
      error: {
        message: 'something went wrong',
      },
    });
  }
};

export const resetCurrentSearchActionCreator = () => ({
  type: INFLUENCERS_SAVED_LIST_CURRENT_SEARCH_RESET,
});

export const resetShouldRedirectAfterDeleteList = () => ({
  type: RESET_REDIRECT_AFTER_DELETE_LIST,
});

export const toggleBulkSavedListModal = () => ({
  type: TOGGLE_BULK_SAVED_LIST_MODAL,
});

export const toggleBulkConfirmationModal = (name = '') => ({
  type: TOGGLE_BULK_CONFIRMATION_MODAL,
  payload: name,
});

export const toggleDeleteSavedListModal = () => ({
  type: TOGGLE_DELETE_MODAL,
});

export const toggleActionListModal = () => ({
  type: TOGGLE_ACTION_LIST_MODAL_OPEN,
});

export const toggleActionListCopyModal = () => ({
  type: TOGGLE_ACTION_LIST_MODAL_OPEN_IS_COPY,
});
export const toggleDuplicateListModal = () => ({
  type: TOGGLE_DUPLICATE_LIST_MODAL,
});

export const toggleMergeListModal = () => ({
  type: TOGGLE_MERGE_LIST_MODAL,
});

export const mergeConfirmationModal = () => ({
  type: MERGE_CONFIRMATION_MODAL,
});

export const selectedToListName = name => ({
  type: SELECTED_TO_LIST_NAME,
  payload: name,
});

export const toggleBulkDeleteSavedListModal = () => ({
  type: TOGGLE_BULK_DELETE_MODAL,
});

export const toggleShareSettingsConfirmationModal = () => ({
  type: TOGGLE_SHARE_SETTINGS_CONFIRMATION_MODAL,
});

export const toggleShareListModal = () => ({
  type: TOGGLE_SHARE_MODAL,
});

export const toggleRenameSavedListModal = (suggestedName = '') => ({
  type: TOGGLE_RENAME_SAVED_LIST_MODAL,
  payload: suggestedName,
});

export const toggleAddListToCampaignsModal = () => ({
  type: TOGGLE_ADD_LIST_TO_CAMPAIGNS_MODAL_OPEN,
});

export const setListToAddToCampaigns = (listId = '') => ({
  type: SET_LIST_TO_ADD_TO_CAMPAIGNS,
  payload: listId,
});

export const checkSharedNameDispatcher = savedListName => async dispatch => {
  const encodedSavedListName = encodeURIComponent(savedListName);
  try {
    const { data } = await performGet(
      `${INFLUENCER_SAVED_LISTS_ENDPOINT}/checksharedname?name=${encodedSavedListName}`,
    );

    if (data && data.nameInUse) {
      dispatch(toggleRenameSavedListModal(data.suggestedName));
    } else {
      dispatch(toggleShareListModal());
    }
  } catch (e) {
    dispatch({
      type: CHECK_SHARED_NAME_ERROR,
    });
  }
};

const grantedPermission = permission => {
  switch (permission) {
    case 'NONE':
      return 'none';
    case 'VIEWONLY':
      return 'r';
    case 'FULL':
      return 'rw';
  }
};

export const updateSelectedListSharePermissions = (
  selectedList,
  sharePermission,
) => dispatch => {
  let permissions;

  if (typeof sharePermission !== 'string') {
    permissions = grantedPermission(sharePermission[0].sharingLevel);
  } else {
    permissions = sharePermission;
  }

  const sharedWith = selectedList.sharedWith.map(shared => {
    if (shared.userId === '__PUBLIC__') {
      shared.permissions = permissions;
    }
    return shared;
  });

  const updatedList = {
    ...selectedList,
    isShared:
      typeof sharePermission === 'string'
        ? sharePermission !== 'none'
        : sharePermission[0].sharingLevel !== 'NONE',
    sharedWith,
  };

  if (typeof sharePermission !== 'string') {
    updatedList.accessControlGroups = sharePermission.filter(
      group =>
        group.sharingLevel === 'FULL' || group.sharingLevel === 'VIEWONLY',
    );
  }

  dispatch({
    type: UPDATE_SELECTED_LIST_SHARE_PERMISSIONS,
    payload: updatedList,
  });
};

export const shareSavedListDispatcher = (
  savedListId,
  sharePermission,
  intl,
  messages,
) => async (dispatch, getState) => {
  bustSavedListsCache();

  if (!sharePermission) return;

  const state = getState();
  const selectedSavedList = state.savedLists.selectedSavedList;
  const { isShared } = selectedSavedList;
  let permissions;

  if (typeof sharePermission !== 'string') {
    permissions = grantedPermission(sharePermission[0].sharingLevel);
  } else {
    permissions = sharePermission;
  }

  try {
    const isPublic =
      typeof sharePermission === 'string'
        ? sharePermission !== 'none'
        : sharePermission[0].sharingLevel !== 'NONE';
    await performPut(
      `${INFLUENCER_SAVED_LISTS_ENDPOINT}/${savedListId}/share`,
      {
        permissions: permissions,
        userId: '__PUBLIC__',
        public: isPublic,
      },
    );

    dispatch(
      updateSelectedListSharePermissions(selectedSavedList, sharePermission),
    );
    if (isShared) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.successUpdatingSharedList),
          status: 'success',
        }),
      );
    } else {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.successSharingList),
          status: 'success',
        }),
      );
    }
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.errorSharingList),
        status: 'danger',
      }),
    );
  }
};

export const shareListWithAccessControlGroups = (
  savedList,
  groupsToShareWith,
  intl,
  messages,
) => async dispatch => {
  bustSavedListsCache();
  const sharingEndpoint = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/${savedList.id}/share/{ACGId}?sharing={SHARE_LEVEL}`;

  try {
    const promises = groupsToShareWith.map(g => {
      return performPut(
        sharingEndpoint
          .replace('{ACGId}', g.id.toString())
          .replace('{SHARE_LEVEL}', g.sharingLevel),
      );
    });

    await Promise.all(promises);

    const updatedList = {
      ...savedList,
      accessControlGroups: groupsToShareWith.filter(
        group =>
          group.sharingLevel === 'FULL' || group.sharingLevel === 'VIEWONLY',
      ),
      isShared: groupsToShareWith.some(
        g => g.sharingLevel === 'FULL' || g.sharingLevel === 'VIEWONLY',
      ),
    };

    dispatch({
      type: UPDATE_SELECTED_LIST_SHARE_PERMISSIONS,
      payload: updatedList,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.successSharingList),
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.errorSharingList),
        status: 'danger',
      }),
    );
  }
};

export const setSelectedListsActionDispatcher = (savedLists, isSelected) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const payload = isSelected
    ? unionBy(state.savedLists.selectedSavedLists, savedLists, 'id')
    : state.savedLists.selectedSavedLists.filter(
        list => !savedLists.find(sl => sl.id === list.id),
      );

  dispatch({
    type: SET_SELECTED_SAVED_LISTS,
    payload,
  });
};

export const clearAllSelectedLists = () => dispatch => {
  dispatch({
    type: CLEAR_ALL_SELECTED_SAVED_LISTS,
  });
};

export const resetDuplicateModal = () => dispatch => {
  dispatch({
    type: RESET_DUPLICATE_LIST_MODAL,
  });
};

export const setSelectedListActionDispatcher = savedList => dispatch => {
  dispatch(loadInfluencerListTitleForm(savedList.name));
  dispatch({ type: SET_SELECTED_SAVED_LIST, payload: savedList });
};

export const setBulkSelectedListsActionDispatcher = (
  hasCustomerAdminRole = false,
) => (dispatch, getState) => {
  const state = getState();
  const accLists = state.savedLists.selectedSavedLists.reduce(
    (acc, list) => {
      let accList;

      if (list.isMyList) {
        accList = { available: [...acc.available, list.id] };
      } else if (list.isReadOnly) {
        accList = { viewOnly: [...acc.viewOnly, list] };
      } else {
        const availableListsAccumulatedToDelete = [...acc.available, list.id];
        const fullAccessListsAccumulated = [...acc.fullAccess, list];

        hasCustomerAdminRole
          ? (accList = { available: availableListsAccumulatedToDelete })
          : (accList = { fullAccess: fullAccessListsAccumulated });
      }

      return {
        ...acc,
        ...accList,
      };
    },
    { viewOnly: [], fullAccess: [], available: [] },
  );

  dispatch({ type: SET_BULK_SELECTED_SAVED_LISTS, payload: accLists });
};

export const duplicateListDispatcher = savedList => async dispatch => {
  try {
    dispatch({
      type: DUPLICATE_LIST_START,
    });

    const response = await performPost(
      `${INFLUENCER_DUPLICATE_SAVED_LISTS_ENDPOINT}`.replace(
        '<listId>',
        savedList.id,
      ),
    );

    const duplicateListId = response?.data?.data?.id;

    dispatch({
      type: DUPLICATE_LIST_SUCCESS,
    });

    dispatch({
      type: SET_DUPLICATE_LIST_ID,
      payload: duplicateListId,
    });

    dispatch({
      type: TOGGLE_DUPLICATE_LIST_MODAL,
      payload: savedList.name,
    });
  } catch (e) {
    dispatch({
      type: DUPLICATE_LIST_ERROR,
      payload: e.message,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text:
          'We were unable to process your list duplication. Please try again.',
        status: 'danger',
      }),
    );
  }
};

export const deleteSavedListDispatcher = ({
  listId,
  successMessage,
  failureMessage,
  resetCampaignsList = false,
  redirectAfterDelete = false,
  isDuplicateList = false,
}) => async (dispatch, getState) => {
  bustSavedListsCache();

  !isDuplicateList &&
    dispatch({
      type: TOGGLE_DELETE_MODAL,
    });

  try {
    await performDelete(`${INFLUENCER_SAVED_LISTS_ENDPOINT}/delete`, {
      listIds: [listId],
    });

    isDuplicateList &&
      dispatch({
        type: DELETE_LIST_SUCCESS_DUPLICATE,
        payload: {
          listId: listId,
          redirect: redirectAfterDelete,
        },
      });

    !isDuplicateList &&
      dispatch({
        type: DELETE_LIST_SUCCESS,
        payload: {
          listId: listId,
          redirect: redirectAfterDelete,
        },
      });

    isDuplicateList &&
      dispatch({
        type: TOGGLE_DUPLICATE_LIST_MODAL,
        payload: '',
      });

    const state = getState();

    if (
      state.savedLists.addedList &&
      state.savedLists.addedList.id === listId
    ) {
      dispatch({
        type: REMOVE_ADDED_LIST,
      });
    }

    if (
      state.influencerLists.selectedList &&
      state.influencerLists.selectedList.id === listId
    ) {
      dispatch({
        type: SELECT_INFLUENCER_SAVED_LIST,
        payload: null,
      });
    }

    if (resetCampaignsList) {
      dispatch(getCampaignsForUser());
    }

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: successMessage,
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: failureMessage,
        status: 'danger',
      }),
    );
  }
};

export const bulkDeleteSavedListDispatcher = (
  { listIds, successMessage, failureMessage, redirectAfterDelete = false },
  successCallback,
) => async (dispatch, getState) => {
  bustSavedListsCache();

  try {
    const response = await performDelete(
      `${INFLUENCER_SAVED_LISTS_ENDPOINT}/delete`,
      {
        listIds,
      },
    );

    const ids = response?.data?.data.map(list => list.id);

    dispatch({
      type: TOGGLE_BULK_DELETE_MODAL,
    });

    const {
      savedLists: {
        list: { data, totalLists },
        selectedSavedLists,
      },
    } = getState();
    const newTotalLists = totalLists - ids.length;
    const newData = data.filter(d => !ids.includes(d.id));
    const newselectedSavedLists = selectedSavedLists.filter(
      element => !listIds.includes(element.id),
    );

    dispatch({
      type: SET_SELECTED_SAVED_LISTS,
      payload: newselectedSavedLists,
    });

    dispatch({
      type: BULK_DELETE_LIST_SUCCESS,
      payload: {
        totalLists: newTotalLists,
        data: newData,
        redirect: redirectAfterDelete,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: successMessage,
        status: 'success',
      }),
    );

    successCallback(ids);
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: failureMessage,
        status: 'danger',
      }),
    );
  }
};

export const addInfluencerToSavedList = (influencerId, savedListId) => {
  return {
    influencerId,
    savedListId,
  };
};

export const createSavedListDispatcher = (
  name,
  intl,
  messages,
  showModal = false,
  influencerIds: string[] = [],
  closeCallback?: () => void,
) => async dispatch => {
  bustSavedListsCache();

  dispatch({
    type: CREATE_LIST_START,
  });

  try {
    const userId = getCurrentUserId();
    const accountId = getCurrentUserAccountId();
    const username = getCurrentUserName();
    const { data } = await performPost(
      `${INFLUENCER_SAVED_LISTS_ENDPOINT}/create`,
      {
        userId,
        accountId,
        name,
        influencerIds,
      },
    );
    const savedList = {
      ...(data.data || {}),
      owner: username,
    };

    dispatch({
      type: CREATE_LIST_SUCCESS,
      payload: savedList,
    });

    if (showModal) {
      dispatch(toggleBulkConfirmationModal(name));
    }

    if (!influencerIds.length) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.createListSuccess, {
            LIST_NAME: truncate(savedList.name),
          }),
          status: 'success',
        }),
      );
    } else {
      dispatch(
        addInfluencerToListActionDispatcher(
          influencerIds,
          Array.from([savedList]),
          intl,
          messages,
        ),
      );

      if (closeCallback) closeCallback();
    }
  } catch (err) {
    dispatch({
      type: CREATE_LIST_ERROR,
      payload: err.message,
    });
    const errText = intl.formatMessage(
      err.message.includes('code 409')
        ? messages.duplicateListError
        : messages.listCreationError,
    );
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: errText,
        status: 'danger',
      }),
    );
  }
  if (showModal) {
    dispatch(toggleBulkSavedListModal());
  }
};

export const updateSharingPermissions = ({
  intl,
  listPermission,
  addInfluencerTrayMessages,
  savedListId,
}) => async dispatch => {
  try {
    const userId = '__PUBLIC__';

    if (listPermission !== Permissions.NONE) {
      await performPut(
        `${INFLUENCER_SAVED_LISTS_ENDPOINT}/${savedListId}/share`,
        {
          permissions: listPermission,
          public: true,
          userId,
        },
      );
    }

    let successMessage = '';
    switch (listPermission) {
      case Permissions.FULL_ACCESS: {
        successMessage = addInfluencerTrayMessages.successSharingFullAccessList;
        break;
      }
      case Permissions.VIEW_ONLY: {
        successMessage = addInfluencerTrayMessages.successSharingViewOnlyList;
        break;
      }
      case Permissions.NONE: {
        successMessage = addInfluencerTrayMessages.successSharingPrivateList;
        break;
      }
    }

    dispatch(
      addPageMessageWithDefaultTimeout({
        status: 'success',
        text: intl.formatMessage(successMessage),
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        status: 'danger',
        text: intl.formatMessage(addInfluencerTrayMessages.errorSharingList),
      }),
    );
  }
};

export const mergeListActionCreator = (
  name,
  listPermission,
  addInfluencerTrayMessages,
  messages,
  intl,
  selectedIds,
) => async dispatch => {
  bustSavedListsCache();

  dispatch({ type: MERGE_LIST_START });

  const mergePayload = {
    listIds: selectedIds,
    name: name,
  };

  try {
    const response = await performPost(
      `${MERGE_SAVED_LISTS_ENDPOINT}`,
      mergePayload,
    );

    const showExceedsErr = response?.data?.data?.status === 500;

    const savedListId = response?.data?.data?.id;

    dispatch({ type: MERGE_LIST_SUCCESS });

    if (showExceedsErr) {
      dispatch(toggleActionListModal());
      dispatch(toggleMergeListModal());
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.contactsExceedsError),
          status: 'danger',
        }),
      );
      return;
    } else {
      if (savedListId) {
        await dispatch(
          updateSharingPermissions({
            intl,
            listPermission,
            addInfluencerTrayMessages,
            savedListId,
          }),
        );
      }
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.mergeListSuccess),
          status: 'success',
        }),
      );
      dispatch(getSavedListsActionCreator({}));
    }
  } catch (err) {
    dispatch(toggleActionListModal());
    dispatch(toggleMergeListModal());
    dispatch({
      type: MERGE_LIST_ERROR,
      payload: err.message,
    });
    const errText = intl.formatMessage(
      err.message.includes('code 409')
        ? addInfluencerTrayMessages.duplicateListError
        : messages.mergeListError,
    );

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: errText,
        status: 'danger',
      }),
    );
  }
};

export const createSavedListWithSelectedPermission = ({
  intl,
  influencerIds,
  listPermission,
  addInfluencerTrayMessages,
  name,
  reloadList = false,
}) => async dispatch => {
  bustSavedListsCache();

  dispatch(toggleBulkSavedListModal());
  dispatch({
    type: CREATE_LIST_START,
  });

  try {
    const userId = getCurrentUserId();
    const accountId = getCurrentUserAccountId();
    const { data } = await performPost(
      `${INFLUENCER_SAVED_LISTS_ENDPOINT}/create`,
      {
        accountId,
        influencerIds,
        name,
        userId,
      },
    );

    const savedList = data?.data || {};

    dispatch({
      type: CREATE_LIST_SUCCESS,
      payload: savedList,
    });

    dispatch(toggleBulkConfirmationModal(name));

    if (data?.data) {
      const savedListId = savedList?.id || '';
      await dispatch(
        updateSharingPermissions({
          intl,
          listPermission,
          addInfluencerTrayMessages,
          savedListId,
        }),
      );
    }
    if (reloadList) {
      dispatch(getSavedListsActionCreator({}));
    }
  } catch (error) {
    dispatch({
      type: CREATE_LIST_ERROR,
      payload: error.message,
    });

    const DUPLICATE_LIST_ERROR_CODE = 'code 409';
    const ERROR_TYPE = 'danger';
    const errorMessage = intl.formatMessage(
      error.message.includes(DUPLICATE_LIST_ERROR_CODE)
        ? addInfluencerTrayMessages.duplicateListError
        : addInfluencerTrayMessages.listCreationError,
    );

    dispatch(
      addPageMessageWithDefaultTimeout({
        status: ERROR_TYPE,
        text: errorMessage,
      }),
    );
  }
};

export const createListWithVirtualSelectionActionCreator = ({
  intl,
  messages,
  influencerCount,
  listCreationRequest,
  close,
}) => async dispatch => {
  bustSavedListsCache();

  dispatch({
    type: CREATE_LIST_START,
  });
  let response;
  try {
    response = await performPost(
      `${INFLUENCER_SAVED_LISTS_WITH_VIRTUAL_SELECTION_ENDPOINT}`,
      listCreationRequest,
    );

    const savedList = response.data ? response.data.data || {} : {};
    dispatch({
      type: CREATE_LIST_SUCCESS,
      payload: savedList,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.addInfluencerToListSuccess, {
          ADDED_NAMES: listCreationRequest?.name,
          INFLUENCER_COUNT: influencerCount,
        }),
        status: 'success',
      }),
    );
    close();
  } catch (err) {
    dispatch({
      type: CREATE_LIST_ERROR,
      payload: err.message,
    });
    const errText = intl.formatMessage(
      err.message.includes('code 409')
        ? messages.duplicateListError
        : messages.listCreationError,
    );
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: errText,
        status: 'danger',
      }),
    );
  }
};

export const setSearchTermActionCreator = searchTerm => ({
  type: SET_SAVED_LISTS_SEARCH_TERM,
  payload: searchTerm,
});

export const setSortFieldActionCreator = sortField => ({
  type: SET_SAVED_LISTS_SORT_FIELD,
  payload: sortField,
});

export const setSortDirectionActionCreator = sortDirection => ({
  type: SET_SAVED_LISTS_SORT_DIRECTION,
  payload: sortDirection,
});

export const setListsCampaignFilterField = filterField => async dispatch => {
  dispatch({
    type: SET_CAMPAIGN_FILTER_FIELD,
    payload: filterField,
  });

  dispatch(getSavedListsActionCreator({ filterByCampaign: filterField }));
};

export const copySavedListDispatcher = (
  intl,
  fromListIds,
  toListIds,
  messages,
  selectedListData,
) => async dispatch => {
  bustSavedListsCache();
  try {
    dispatch({ type: INFLUENCERS_SAVED_LIST_COPY_START });

    const response = await performPost(INFLUENCER_SAVED_LISTS_COPY_ENDPOINT, {
      fromListIds,
      toListIds,
    });

    dispatch({
      type: INFLUENCERS_SAVED_LIST_COPY_SUCCESS,
    });

    let successCount = 0;
    const partialFailedList: any[] = [];
    response.data.listsStatus.forEach(result => {
      if (result.status === 200 || result.status === 304)
        successCount = successCount + 1;
      else if (result.status === 500)
        selectedListData.map(list => {
          if (list.id === result.id) {
            partialFailedList.push(list.name);
          }
        });
    });

    if (response.data.listsStatus.length === successCount) {
      dispatch(getSavedListsActionCreator({}));
      dispatch(setSearchTermActionCreator(''));
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.successCopyMessage),
          status: 'success',
        }),
      );
    } else if (
      response.data.listsStatus.length !== successCount &&
      successCount !== 0 &&
      partialFailedList.length
    ) {
      dispatch(getSavedListsActionCreator({}));
      dispatch(setSearchTermActionCreator(''));
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.partialFailedCopyMessage, {
            LIST_NAMES: partialFailedList.join(', '),
          }),
          status: 'danger',
        }),
      );
    } else {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.failedCopyMessage),
          status: 'danger',
        }),
      );
    }
    dispatch(toggleActionListModal());
    dispatch(toggleActionListCopyModal());
  } catch (err) {
    dispatch({
      type: INFLUENCERS_SAVED_LIST_COPY_ERROR,
      payload: err.message,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Something went wrong, Please try again later',
        status: 'danger',
      }),
    );
    dispatch(toggleActionListModal());
    dispatch(toggleActionListCopyModal());
  }
};
export const setCampaginId = campaignId => ({
  type: SET_CAMPAIGN_ID,
  payload: campaignId,
});

export default influencerSavedListsReducer;
