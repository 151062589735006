export const LOCK_ANALYTICS = 'appInfo/LOCK_ANALYTICS';
export const CLEAR_LOCK_ANALYTICS = 'appInfo/CLEAR_LOCK_ANALYTICS';

const appInfoReducer = (state = { ...window.appInfo }, action) => {
  switch (action.type) {
    case LOCK_ANALYTICS:
      return {
        ...state,
        lockedAnalyticIntegration: action.payload,
      };
    case CLEAR_LOCK_ANALYTICS:
      return {
        ...state,
        lockedAnalyticIntegration: undefined,
      };
    default:
      return state;
  }
};

export const clearAnalytics = () => dispatch => {
  dispatch({ type: CLEAR_LOCK_ANALYTICS });
};

export const lockAnalytics = analytic => dispatch => {
  dispatch({
    type: LOCK_ANALYTICS,
    payload: analytic,
  });
};

export default appInfoReducer;
