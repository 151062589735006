import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';
import { performGet, performPut } from 'services/rest-service/rest-service';

import messages from '../../pages/Campaigns/Campaigns.messages';

import { UPDATE_CAMPAIGN_PROPERTY } from './campaign';

export const UPDATE_CAMPAIGN_ALERTS = 'campaigns/alerts/UPDATE_CAMPAIGN_ALERTS';
export const UPDATE_CAMPAIGN_ALERTS_SUCCESS =
  'campaigns/alerts/UPDATE_CAMPAIGNS_ALERTS_SUCCESS';
export const UPDATE_CAMPAIGN_ALERTS_ERROR =
  'campaigns/alerts/UPDATE_CAMPAIGN_ALERTS_ERROR';

export const initialState = {
  alerts: [],
  loading: false,
  error: false,
};

const campaignAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAMPAIGN_ALERTS: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case UPDATE_CAMPAIGN_ALERTS_SUCCESS: {
      const { alerts } = action.payload;
      return {
        loading: false,
        error: false,
        alerts,
      };
    }

    case UPDATE_CAMPAIGN_ALERTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

export const getCampaignAlertsByCampaignId = campaignId => async dispatch => {
  dispatch({ type: UPDATE_CAMPAIGN_ALERTS });

  try {
    const alerts = await performGet(`${CAMPAIGN_ENDPOINT}/${campaignId}/alert`);
    alerts.data.forEach(alert => {
      alert.svgIcon = 'alerts';
      alert.url = '';
    });
    dispatch({
      type: UPDATE_CAMPAIGN_ALERTS_SUCCESS,
      payload: { alerts: alerts.data },
    });
  } catch (e) {
    dispatch({ type: UPDATE_CAMPAIGN_ALERTS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Failed to fetch alerts for your campaign.',
        status: 'danger',
      }),
    );
  }
};

export const deleteCampaignAlertById = (campaignId, alertIds, intl) => async (
  dispatch,
  getState,
) => {
  const campaignAlerts = getState().campaignAlertList.alerts;
  const updatedList = campaignAlerts.filter(
    alert => alertIds.indexOf(alert.id) === -1,
  );

  dispatch({ type: UPDATE_CAMPAIGN_ALERTS });

  try {
    await performPut(`${CAMPAIGN_ENDPOINT}/${campaignId}`, {
      alerts: updatedList.map(alert => alert.id),
    });
    dispatch({
      type: UPDATE_CAMPAIGN_ALERTS_SUCCESS,
      payload: { alerts: updatedList },
    });
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: { alerts: updatedList.map(alert => alert.id) },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.alertsDeletedSuccess),
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: UPDATE_CAMPAIGN_ALERTS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.alertsDeletedFailed),
        status: 'danger',
      }),
    );
  }
};

export default campaignAlertsReducer;
