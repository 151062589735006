import React, { useState } from 'react';

import { Button } from '@cision/rover-ui';

import { useIntl } from 'react-intl';

import { Link } from 'react-router-dom';

import {
  Button as TkUiButton,
  Dropdown,
  DropdownMenu,
  FloatingPanel,
  GridTableRow,
  ResponsiveContainer,
  ResponsiveContext,
  SvgIcon,
} from '@trendkite/ui';

import { ADMIN_FEED_URL, DEFAULT_DATE_FORMAT_INTL } from 'constants/constants';

import styles from 'pages/Admin/UserManagement/UserTable/UserRow/UserRow.module.css';

import { getCanUseAdminManagement } from 'selectors/canUse/canUse';

import messages from '../../Feeds.messages';

export interface Feed {
  id: number;
  modifiedOn: string;
  widget: BusinessObjectLite | null;
  uuid: string;
  account: BusinessObjectLite | null;
  accountId: number;
  link: string;
  widgetV3: BusinessObjectLite | null;
  widgetId: number;
  createdOn: string;
  widgetTitle: string;
}

interface BusinessObjectLite {
  id: number | null;
}

interface Props {
  feed: Feed;
  columnStyle: string;
  onDeleteFeed: (id) => void;
}

const FeedRow = ({ feed, columnStyle, onDeleteFeed }: Props) => {
  const baseClass = 'feed-table';
  const [menuOpen, setMenuOpen] = useState(false);
  const [, toggleDropdownMenu] = useState(false);
  const intl = useIntl();

  const canUseAdminManagement = getCanUseAdminManagement();

  const handleToggleDropdownMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDeleteFeed = id => {
    onDeleteFeed(id);
  };

  return (
    <ResponsiveContainer>
      <ResponsiveContext.Consumer>
        {responsiveModifiers => {
          const useMeatballMenu = true;
          return (
            <GridTableRow
              columnStyle={columnStyle}
              modifiers={['clickable', 'hoverable']}
              rowData={[
                {
                  key: 'col_0',
                  content: (
                    <div
                      className={`${baseClass}__column ${baseClass}__checkbox`}
                    />
                  ),
                },
                {
                  key: 'col_1',
                  modifiers: ['clickable', 'responsive'],
                  content: (
                    <div
                      className={`${baseClass}__column ${baseClass}__column--button`}
                    >
                      <p
                        title={(feed.id as unknown) as string}
                        className={`${baseClass}__title`}
                      >
                        {feed.uuid}
                      </p>
                    </div>
                  ),
                },
                {
                  key: 'col_2',
                  content: (
                    <a
                      href={feed.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={`${baseClass}__column`}>{feed.link}</div>
                    </a>
                  ),
                },
                {
                  key: 'col_3',
                  content: (
                    <div className={`${baseClass}__column`}>
                      {intl.formatDate(
                        feed.createdOn,
                        DEFAULT_DATE_FORMAT_INTL,
                      )}
                    </div>
                  ),
                },
                {
                  key: 'col_4',
                  content: (
                    <div className={`${baseClass}__column`}>
                      {intl.formatDate(
                        feed.modifiedOn,
                        DEFAULT_DATE_FORMAT_INTL,
                      )}
                    </div>
                  ),
                },
                {
                  key: 'col_5',
                  content: (
                    <Link
                      to={
                        canUseAdminManagement
                          ? ADMIN_FEED_URL + '/' + feed.id
                          : '#'
                      }
                      className={styles.linkClick}
                    >
                      <div className={`${baseClass}__column`}>
                        {`${feed.widgetTitle}
                        ${
                          feed.widgetTitle !== ''
                            ? '(' + (feed.widgetId || feed.widgetV3?.id) + ')'
                            : feed.widgetId || feed.widgetV3?.id
                        }`}
                      </div>
                    </Link>
                  ),
                },
                {
                  key: 'col_6',
                  content: (
                    <div className={`${baseClass}__column`}>
                      {feed.accountId || feed.account?.id}
                    </div>
                  ),
                },
                {
                  key: 'col_7',
                  content: (
                    <div>
                      <Dropdown isOpen={menuOpen} toggle={toggleDropdownMenu}>
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            handleToggleDropdownMenu();
                          }}
                          level="text"
                          size="sm"
                          data-qa="2KRswNdFnU4Yk6llE6OGG"
                          style={{
                            paddingLeft: '0',
                            paddingRight: '0',
                          }}
                        >
                          {useMeatballMenu && (
                            <SvgIcon icon="ellipsis" width={18} height={18} />
                          )}
                        </Button>
                        <DropdownMenu isOpen={menuOpen} position="bottomLeft">
                          <FloatingPanel>
                            <TkUiButton
                              modifiers={['listEntry']}
                              onClick={e => {
                                setMenuOpen(false);
                                handleDeleteFeed(feed.id);
                              }}
                              data-qa="NWG3cqKOxbgVEZWoC0EOP"
                            >
                              {intl.formatMessage(messages.feedDeleteOption)}
                            </TkUiButton>
                          </FloatingPanel>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  ),
                },
              ]}
            />
          );
        }}
      </ResponsiveContext.Consumer>
    </ResponsiveContainer>
  );
};

export default FeedRow;
