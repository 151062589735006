import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { ACTIVITY_TYPES, AUDIENCE_TYPES } from 'constants/activities';

import {
  ActivityTypes,
  EmailSubTypes,
  SubjectTypes,
  SourceTypes,
} from '../../components/activity-feed/activity-types';

const youActors = [
  {
    name: 'You',
  },
];

const actorList = [
  {
    name: 'John Smith',
  },
];

const getActivity = (type, actors, audiences, text) => {
  const metadata = JSON.stringify({
    actors,
    audiences,
  });

  const contentJson = {
    text,
    metadata,
  };

  return {
    id: uuidv4(),
    contentJson,
    activityType: type,
    dateCreated: moment.utc(new Date()).valueOf(),
    dateHappened: moment.utc(new Date()).valueOf(),
  };
};

const getInfluencerActivity = (
  activityTypeId,
  activitySubtypeId,
  sourceExternalInfoData = null,
  subjectData = null,
) => {
  const automated =
    activityTypeId === ActivityTypes.EMAIL_ANNOUNCEMENT ||
    activityTypeId === ActivityTypes.EMAIL_PITCH;
  const sourceExternalInfo = sourceExternalInfoData || youActors[0].name;
  const subject = subjectData || [
    {
      subjectTypeId: SubjectTypes.INFLUENCER,
      subjectExternalInfo: actorList[0].name,
    },
  ];
  return {
    id: uuidv4(),
    activityTypeId,
    activitySubtypeId,
    source: {
      sourceTypeId: SourceTypes.INFLUENCER,
      sourceExternalInfo,
    },
    subject,
    automated,
    occurredAt: moment.utc(new Date()).valueOf(),
    createdAt: moment.utc(new Date()).valueOf(),
  };
};

export const performPost = async (path, params = {}) => {
  // eslint-disable-line no-unused-vars
  switch (path) {
    case '/api/activity/query': {
      if (params.audienceType === AUDIENCE_TYPES.influencer) {
        return {
          data: {
            count: 9,
            activities: [
              getInfluencerActivity(
                ActivityTypes.EMAIL_ANNOUNCEMENT,
                EmailSubTypes.REPLY,
                actorList[0].name,
                [
                  {
                    subjectTypeId: SubjectTypes.EMAIL,
                    subjectExternalInfo: 'Product Launch Question',
                  },
                ],
              ),
              getInfluencerActivity(
                ActivityTypes.EMAIL_ANNOUNCEMENT,
                EmailSubTypes.OPEN,
                actorList[0].name,
                [
                  {
                    subjectTypeId: SubjectTypes.EMAIL,
                    subjectExternalInfo: 'Product Launch Question',
                  },
                ],
              ),
              getInfluencerActivity(
                ActivityTypes.EMAIL_ANNOUNCEMENT,
                EmailSubTypes.CLICK,
                actorList[0].name,
                [
                  {
                    subjectTypeId: SubjectTypes.EMAIL,
                    subjectExternalInfo: 'Product Launch Question',
                  },
                ],
              ),
              getInfluencerActivity(
                ActivityTypes.EMAIL_ANNOUNCEMENT,
                EmailSubTypes.SEND,
                youActors[0].name,
                [
                  {
                    subjectTypeId: SubjectTypes.INFLUENCER,
                    subjectExternalInfo: actorList[0].name,
                  },
                  {
                    subjectTypeId: SubjectTypes.EMAIL,
                  },
                ],
              ),
              getInfluencerActivity(ActivityTypes.COFFEE),
              getInfluencerActivity(ActivityTypes.DINNER),
              getInfluencerActivity(ActivityTypes.DRINKS),
              getInfluencerActivity(ActivityTypes.PHONE_CALL),
              getInfluencerActivity(ActivityTypes.MEETING),
            ],
          },
        };
      }
      return {
        data: {
          count: 7,
          activities: [
            getActivity(
              ACTIVITY_TYPES.EMAIL_REPLIED,
              actorList,
              [],
              'Product Launch Question',
            ),
            getActivity(
              ACTIVITY_TYPES.EMAIL_SENT,
              youActors,
              actorList,
              'Product Launch Question',
            ),
            getActivity(
              ACTIVITY_TYPES.STORY_VIEWED,
              actorList,
              [],
              'Product at CES this year',
            ),
            getActivity(
              ACTIVITY_TYPES.COFFEE,
              youActors,
              actorList,
              'Spoke about coverage on launch day',
            ),
            getActivity(
              ACTIVITY_TYPES.EMAIL_SENT,
              youActors,
              actorList,
              'Meet for Coffee?',
            ),
            getActivity(
              ACTIVITY_TYPES.NOTE,
              actorList,
              [],
              'Make sure to meet with Jane Doe to secure coverage',
            ),
            getActivity(
              ACTIVITY_TYPES.CONTACT_LIST_ADD,
              youActors,
              actorList,
              'Product Coverage List',
            ),
          ],
        },
      };
    }
    default: {
      return {
        data: {
          error: {
            message: "Couldn't get activity",
          },
          aggregations: null,
          pagination: null,
          results: [],
        },
        status: 400,
      };
    }
  }
};

export default {
  performPost,
};
