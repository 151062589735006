import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';

import { SvgIcon } from '@trendkite/ui';
import { TK_EVENT_LOGOUT } from 'trendkite-front-end-app/src/constants/constants';

import MenuItem from './menu-item';

const logoutAction = e => {
  if (!e || !e.detail || !e.detail.logoutUrl) {
    return;
  }

  window.localStorage.removeItem('searchId');
  window.location = e.detail.logoutUrl;
};

const Footer = ({
  endImpersonation,
  isAuthed,
  loginNavEnabled,
  logoutUrl,
  secondaryMenuProps,
  setSubMenuKey,
  userIsImpersonating,
  username,
}) => {
  useEffect(() => {
    window.addEventListener(TK_EVENT_LOGOUT, logoutAction);
    return () => window.removeEventListener(TK_EVENT_LOGOUT, logoutAction);
  }, []);

  return (
    <Fragment>
      {userIsImpersonating && (
        <MenuItem
          data-qa="uALW0-UOK9JxUFduF3P33"
          id="impersonating-nav-button"
          key="impersonating-nav-button"
          label={
            <span>
              You are using Cision as <strong>{username}</strong>. Click to
              switch back to self.
            </span>
          }
          level="primary-alt"
          onClick={endImpersonation}
        />
      )}
      {secondaryMenuProps.map(menuItemProps => {
        if (!menuItemProps.subOptions) {
          return (
            <MenuItem
              id={menuItemProps.id}
              key={menuItemProps.id}
              label={menuItemProps.label}
              location={`${window.appUrlBase || ''}${menuItemProps.location}`}
            />
          );
        }

        return (
          <MenuItem
            data-qa="aYDWq6IzwNXKxV3EWHSQt"
            iconRight={
              <SvgIcon
                icon="chevronDown"
                style={{
                  transformOrigin: 'center center',
                  transform: 'rotate(-90deg)',
                }}
              />
            }
            id={menuItemProps.id}
            key={menuItemProps.id}
            label={menuItemProps.label}
            onClick={() => setSubMenuKey(menuItemProps.id)}
          />
        );
      })}
      {!isAuthed && loginNavEnabled && (
        <MenuItem
          iconLeft={<SvgIcon icon="arrowRight" height={16} width={16} />}
          iconRight=" "
          id="login-nav-button"
          key="login-nav-button"
          label="Login"
          location={`/login/auth?page=${encodeURIComponent(window.location)}`}
        />
      )}
      {isAuthed && (
        <MenuItem
          data-qa="rKXwvuTvdgW-fFLUxBmKR"
          id="logout-nav-button"
          iconLeft={<SvgIcon icon="square" />}
          key="logout-nav-button"
          label="Logout"
          onClick={() =>
            window.dispatchEvent(
              new CustomEvent(TK_EVENT_LOGOUT, { detail: { logoutUrl } }),
            )
          }
        />
      )}
    </Fragment>
  );
};

Footer.propTypes = {
  endImpersonation: PropTypes.func.isRequired,
  isAuthed: PropTypes.bool,
  loginNavEnabled: PropTypes.bool,
  logoutUrl: PropTypes.string,
  secondaryMenuProps: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSubMenuKey: PropTypes.func.isRequired,
  userIsImpersonating: PropTypes.bool,
  username: PropTypes.string,
};

Footer.defaultProps = {
  endImpersonation: () => {},
  isAuthed: false,
  loginNavEnabled: false,
  logoutUrl: '',
  userIsImpersonating: false,
  username: '',
};

export default Footer;
