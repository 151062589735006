export default {
  header: {
    id: 'EDIT-STORY-CONTACT_HEADER',
    defaultMessage: 'Contact',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit contact, section header',
  },
  firstNamePlaceholder: {
    id: 'EDIT-STORY-CONTACT_FIRST_NAME_PLACEHOLDER',
    defaultMessage: 'First name',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit contact, first name placeholder',
  },
  lastNamePlaceholder: {
    id: 'EDIT-STORY-CONTACT_LAST_NAME',
    defaultMessage: 'Last name',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit contact, last name placeholder',
  },
  organizationPlaceholder: {
    id: 'EDIT-STORY-CONTACT_ORGANIZATION_PACEHOLDER',
    defaultMessage: 'Organization',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit contact, organization placeholder',
  },
  emailPlaceholder: {
    id: 'EDIT-STORY-CONTACT_EMAIL_PLACEHOLDER',
    defaultMessage: 'Email',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit contact, email placeholder',
  },
  phonePlaceholder: {
    id: 'EDIT-STORY-CONTACT_PHONE_PLACEHOLDER',
    defaultMessage: 'Phone (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit contact, phone placeholder',
  },
  mailingAddressPlaceholder: {
    id: 'EDIT-STORY-CONTACT_MAILING_ADDRESS_PLACEHOLDER',
    defaultMessage: 'Mailing Address (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click to edit contact, mailing address placeholder',
  },
};
