import React from 'react';

import PropTypes from 'prop-types';

import withModifiers from '../withModifiers';

const FormLabel = ({ children = null, htmlFor, className = '' }) => (
  <label className={`${FormLabel.baseClass} ${className}`} htmlFor={htmlFor}>
    {children}
  </label>
);

FormLabel.baseClass = 'tk-label';

FormLabel.propTypes = {
  children: PropTypes.node,
  htmlFor: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default withModifiers(FormLabel);
