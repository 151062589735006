export default {
  addToList: {
    id: 'ADD_INFLUENCER_TRAY_ADD_TO_LIST',
    defaultMessage: 'Add to List',
    description:
      'The title on a side tray that opens and allows the user to add an influencer to a saved list',
  },
  createNewList: {
    id: 'ADD_INFLUENCER_TRAY_CREATE_NEW_LIST',
    defaultMessage: 'Create New List',
    description:
      'A button on a side tray that allows the user to create a new saved list',
  },
  createListSuccess: {
    id: 'ADD_INFLUENCER_TRAY_SUCCESS_MESSAGE',
    defaultMessage: 'Successfully created list "{LIST_NAME}".',
    description:
      'Influencers Beta > Add to List sidetray: This is the message that is shown to the user if their saved list has been successfully created',
  },
  duplicateListError: {
    id: 'ADD_INFLUENCER_TRAY_DUPLICATE_LIST_ERROR',
    defaultMessage: 'A list with the same name already exists in this account',
    description:
      'This is an error message that is shown to the user if their saved list has a name that has already been used',
  },
  errorSharingList: {
    id: 'INFLUENCER_LIST_SHARE_LIST_ERROR_SHARING_LIST',
    defaultMessage: "Failed to update your list's share settings.",
    description:
      'A message that displays in a popup window indicating that there was an error trying to share a saved list',
  },
  listCreationError: {
    id: 'ADD_INFLUENCER_TRAY_LIST_CREATION_ERROR',
    defaultMessage: 'Failed to create list.',
    description:
      'This is the message that is shown to the user if an error occurred creating their new saved list',
  },
  addInfluencerToListSuccess: {
    id: 'ADD_INFLUENCER_TRAY_LIST_ADD_INFLUENCER_TO_LIST_SUCCESS',
    defaultMessage: `All set! {INFLUENCER_COUNT} { INFLUENCER_COUNT, plural,
      =1 {contact has}
      other {contacts have}
    } been added to {ADDED_NAMES}`,
    description:
      'This is the message that is shown to the user if adding an influencer was successful',
  },
  addInfluencerToListError: {
    id: 'ADD_INFLUENCER_TRAY_LIST_ADD_INFLUENCER_TO_LIST_ERROR',
    defaultMessage: `Adding {INFLUENCER_COUNT} influencer{ INFLUENCER_COUNT, plural,
      =1 {}
      other {s}
    } to {ITEM_NAME} will exceed the limit of {LIST_LIMIT}`,
    description:
      'This is the message that is shown to the user if adding an influencer was not successful',
  },
  addInfluencerToListGenericError: {
    id: 'ADD_INFLUENCER_TRAY_LIST_ADD_INFLUENCER_TO_LIST_GENERIC_ERROR',
    defaultMessage: 'Failed to add influencer.',
    description:
      'This is the generic message that is shown to the user if adding an influencer was not successful',
  },
  removeInfluencerFromList: {
    id: 'REMOVE_INFLUENCER_FROM_LIST_MESSAGE',
    defaultMessage: 'Influencer successfully removed.',
    description:
      'This is the message that is shown to the user when removing an influencer is successful',
  },
  bulkRemoveInfluencerFromList: {
    id: 'BULK_REMOVE_INFLUENCER_FROM_LIST_MESSAGE',
    defaultMessage:
      'You have removed {AMOUNT_INFLUENCERS} contacts from this list.',
    description:
      'This is the message that is shown to the user when bulk removing influencers is successful.',
  },
  removeInfluencerFromListError: {
    id: 'REMOVE_INFLUENCER_FROM_LIST_ERROR_MESSAGE',
    defaultMessage: 'Failed to remove influencer from the current list.',
    description:
      'This is the  message that is shown to the user when removing an influencer is successful',
  },
  bulkRemoveInfluencerFromListError: {
    id: 'BULK_REMOVE_INFLUENCER_FROM_LIST_ERROR_MESSAGE',
    defaultMessage: 'Failed to remove influencers from the current list.',
    description:
      'This is the  message that is shown to the user when bulk removing influencers is successful.',
  },
  searchInputPlaceholder: {
    id: 'ADD_INFLUENCER_TRAY_LIST_SEARCH_PLACEHOLDER',
    defaultMessage: 'Search Lists',
    description:
      'When a user tries to add contacts to a story, the saved contact list search box shows this placeholder text.',
  },
  successSharingViewOnlyList: {
    id: 'INFLUENCER_LIST_SHARE_VIEW_ONLY_LIST_SUCCESS',
    defaultMessage: 'Success! Your list is now set to view only.',
    description:
      'A message that displays in a popup window indicating that the list was successfully shared as view only',
  },
  successSharingFullAccessList: {
    id: 'INFLUENCER_LIST_SHARE_FULL_ACCESS_LIST_SUCCESS',
    defaultMessage: 'Success! Your list is now set to full access.',
    description:
      'A message that displays in a popup window indicating that the list was successfully shared as full access',
  },
  successSharingPrivateList: {
    id: 'INFLUENCER_LIST_SHARE_PRIVATE_LIST_SUCCESS',
    defaultMessage: 'Success! Your list is now set to private.',
    description:
      'A message that displays in a popup window indicating that the list was successfully shared as private',
  },
};
