import isDate from 'lodash/isDate';

import { SOCIAL_REFRESH } from 'constants/apis';
import messages from 'pages/WidgetDrilldown/DrilldownListActionBar/DrilldownListActionBar.messages';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';
import { performPost } from 'services/rest-service/rest-service';

export const GET_SOCIAL_AMP_DATA = 'social-amp/GET_SOCIAL_AMP_DATA';
export const SUBMIT_REQUEST = 'social-amp/SUBMIT_REQUEST';
export const REQUEST_SUBMITTED = 'social-amp/REQUEST_SUBMITTED';
export const REQUEST_ERROR = 'social-amp/REQUEST_ERROR';
export const GET_SOCIAL_AMP_DATA_RECEIVED =
  'social-amp/GET_SOCIAL_AMP_DATA_RECEIVED';
export const GET_SOCIAL_AMP_DATA_ERROR = 'social-amp/GET_SOCIAL_AMP_DATA_ERROR';
export const UNSET_SOCIAL_AMP_DATA = 'social-amp/RESET_SOCIAL_AMP_DATA';

const initialState = {
  loading: false,
  error: false,
  success: false,
};

const articleListSocialAmp = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case REQUEST_SUBMITTED:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
    case REQUEST_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
      };
    case UNSET_SOCIAL_AMP_DATA:
      return initialState;
    default:
      return state;
  }
};

const createMilliSecondsDate = date =>
  isDate(date) ? date.getTime() : new Date(date).getTime();

const createMultipleArticlesSocialRefreshPayload = articleList => ({
  articles: articleList.map(article => ({
    id: article.id,
    publishDate: createMilliSecondsDate(article.publishDate),
    url: article.urlDirect,
  })),
});

export const refreshSocialDataForArticles = (articleList, intl) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: SUBMIT_REQUEST });
  try {
    await performPost(
      SOCIAL_REFRESH,
      createMultipleArticlesSocialRefreshPayload(articleList),
    );
    dispatch({ type: REQUEST_SUBMITTED });
    dispatch(
      addPageMessage({
        text: intl.formatMessage(messages.socialDataUpdateSuccess),
        status: 'success',
        ttl: 6000,
      }),
    );
  } catch (error) {
    dispatch({ type: REQUEST_ERROR });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.socialDataUpdateError),
        status: 'warning',
      }),
    );
    throw new Error(error);
  }
};

export const resetSocialAmpState = () => dispatch => {
  dispatch({ type: UNSET_SOCIAL_AMP_DATA });
};

export default articleListSocialAmp;
