import { activeUserWithOverride } from 'services/feature-service/feature-service';
export const UPDATE_USER_CURRENCY_CONVERSION =
  'account/UPDATE_USER_CURRENCY_CONVERSION';

const accountReducer = (
  state = activeUserWithOverride(window.activeUser),
  action,
) => {
  switch (action.type) {
    case UPDATE_USER_CURRENCY_CONVERSION:
      return {
        ...state,
        currency: action.payload,
      };
    default:
      return state;
  }
};

export const updateUserCurrency = currency => ({
  type: UPDATE_USER_CURRENCY_CONVERSION,
  payload: currency,
});

export default accountReducer;
