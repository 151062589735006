import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Type = ({
  block,
  children,
  className,
  'data-qa': dataQa,
  color,
  italic,
  pre,
  breakword,
  modifiers: customModifiers,
  size,
  style,
  weight,
}) => {
  const baseClass = 'tk-type';
  let prefixedModifiers = [];

  const modifiers = [
    ...(customModifiers || []),
    `color-${color}`,
    `size-${size}`,
    `weight-${weight}`,
  ];

  if (italic) {
    modifiers.push('italic');
  }

  if (pre) {
    modifiers.push('pre');
  }

  if (breakword) {
    modifiers.push('break-word');
  }

  if (Array.isArray(modifiers)) {
    prefixedModifiers = modifiers.map(modifier => `${baseClass}--${modifier}`);
  }

  const mainClass = classNames(baseClass, prefixedModifiers, className);

  if (block) {
    return (
      <div className={mainClass} data-qa={dataQa} style={style}>
        {children}
      </div>
    );
  }

  return (
    <span className={mainClass} data-qa={dataQa} style={style}>
      {children}
    </span>
  );
};

export const Caption = props => {
  const typographyProps = {
    size: 'sm',
    ...props,
  };
  return <Type {...typographyProps} />;
};

export const Body = props => {
  const typographyProps = {
    ...props,
  };
  return <Type {...typographyProps} />;
};

export const Subhead = props => {
  const typographyProps = {
    ...props,
    modifiers: ['uppercase'],
    size: 'sm',
    style: {
      textTransform: 'uppercase',
    },
  };
  return <Type {...typographyProps} />;
};

export const Headline = props => {
  const typographyProps = {
    ...props,
    modifiers: ['uppercase'],
    weight: props.weight || 'bold',
    style: {
      textTransform: 'uppercase',
      ...props.style
    },
  };
  return <Type {...typographyProps} />;
};

export const DisplaySM = props => {
  const typographyProps = {
    ...props,
    size: 'lg',
  };
  return <Type {...typographyProps} />;
};

export const DisplayMD = props => {
  const typographyProps = {
    ...props,
    size: 'xl',
  };
  return <Type {...typographyProps} />;
};

export const DisplayLG = props => {
  const typographyProps = {
    ...props,
    size: 'xxl',
  };
  return <Type {...typographyProps} />;
};

export const DisplayXL = props => {
  const typographyProps = {
    ...props,
    size: 'xxxl',
  };
  return <Type {...typographyProps} />;
};

Type.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'danger',
    'dark',
    'disabled',
    'inverse',
    'inverseLight',
    'light',
    'link',
    'normal',
    'normalWithHover',
    'inherit',
    'success',
  ]),
  'data-qa': PropTypes.string,
  italic: PropTypes.bool,
  pre: PropTypes.bool,
  breakword: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(['sm', 'md', 'ml', 'lg', 'xl', 'xxl', 'xxxl']),
  style: PropTypes.object,
  weight: PropTypes.oneOf(['light', 'normal', 'bold']),
};

Type.defaultProps = {
  block: false,
  children: '',
  className: '',
  color: 'normal',
  'data-qa': undefined,
  italic: false,
  pre: false,
  breakword: false,
  modifiers: [],
  size: 'md',
  style: {},
  weight: 'normal',
};

Headline.propTypes = {
  ...Type.propTypes,
};

Headline.defaultProps = {
  weight: 'bold',
};

export default Type;
