import { NEW_DASHBOARD_ENDPOINT } from 'constants/apis';

import {
  AnalyticsListActionCreatorArgs,
  AnalyticsListResultsCollation,
} from '../../analytics-config';

import { campaignAnalyticsActionCreator } from './analytics-campaign-reducer';
import { recentlyViewedAnalyticsActionCreator } from './analytics-last-viewed-reducer';
import { processAnalyticResults } from './analytics-list-reducer';
import { sidebarAnalyticsActionCreator } from './analytics-sidebar-reducer';

export const LIST_LEGACY_ANALYTICS = 'analytics/LIST_LEGACY_ANALYTICS';
export const LIST_LEGACY_ANALYTICS_SUCCESS =
  'analytics/LIST_LEGACY_ANALYTICS_SUCCESS';
export const LIST_LEGACY_ANALYTICS_ERROR =
  'analytics/LIST_LEGACY_ANALYTICS_ERROR';

export const initialState = {
  error: false,
  loading: true,
  listFilters: {
    filtering: false,
    search: '',
  },
  items: [],
  paginationCount: null,
  ownerFilterAnalytics: [],
  resultsCollation: {
    pageNumber: 1,
    sort: 'lastUpdated',
    sortDirection: 'desc',
    ownerFilter: 'all',
  } as AnalyticsListResultsCollation,
};

const legacyAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_LEGACY_ANALYTICS:
      return {
        ...state,
        loading: true,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: null,
      };
    case LIST_LEGACY_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        listFilters: {
          filtering: action.payload.filtering,
          search: action.payload.search,
        },
        error: null,
        items: action.payload.items,
        paginationCount: action.payload.paginationCount,
        ownerFilterAnalytics: action.payload.isFilter
          ? state.ownerFilterAnalytics
          : action.payload.items,
        resultsCollation: action.payload.resultsCollation,
      };
    case LIST_LEGACY_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const legacyAnalyticsActionCreator = ({
  endpointBase = NEW_DASHBOARD_ENDPOINT,
  search = null,
  ownerId = null,
  isAlreadyFiltered = false,
  resultsCollation = initialState.resultsCollation,
  campaigns,
  isInitialLoad = false,
  doCountReload = false,
}: AnalyticsListActionCreatorArgs = {}) => async dispatch => {
  const params = {
    ...resultsCollation,
    legacy: true,
    search,
    ownerId,
    campaigns,
  };
  const isFilter = !!search || !!ownerId;
  dispatch({ type: LIST_LEGACY_ANALYTICS });

  try {
    const actionCreatorArgs = {
      endpointBase,
      isAlreadyFiltered: isFilter,
    };
    const { items, itemCount } = await processAnalyticResults({
      params,
      endpointBase,
    });
    dispatch({
      type: LIST_LEGACY_ANALYTICS_SUCCESS,
      payload: {
        items: items,
        paginationCount: itemCount,
        filtering: isAlreadyFiltered || isFilter,
        search,
        isFilter,
        resultsCollation,
      },
    });
    dispatch(
      sidebarAnalyticsActionCreator({
        endpointBase,
        doCountReload,
      }),
    );
    if (isInitialLoad) {
      dispatch(recentlyViewedAnalyticsActionCreator(actionCreatorArgs));
      dispatch(campaignAnalyticsActionCreator(actionCreatorArgs));
    }
  } catch (e) {
    dispatch({
      type: LIST_LEGACY_ANALYTICS_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export default legacyAnalyticReducer;
