import React from 'react';

import DefaultTemplate from './templates/DefaultTemplate';

// just use default template for now, in future might have other templates
// a story could use
const Story = props => {
  return <DefaultTemplate {...props} />;
};

export default Story;
