import qs from 'qs';

import {
  TVEYES_ENDPOINT,
  TVEYES_ORDER_CALLBACK_ENDPOINT,
} from 'constants/apis';

import { performGet } from 'services/rest-service/rest-service';

export const GET_CLIP_EDITOR_URL = 'tveyesClips/GET_CLIP_EDITOR_URL';
export const GET_CLIP_EDITOR_URL_ERROR =
  'tveyesClips/GET_CLIP_EDITOR_URL_ERROR';
export const GET_CLIP_EDITOR_URL_SUCCESS =
  'tveyesClips/GET_CLIP_EDITOR_URL_SUCCESS';

export const initialState = {
  clipEditorUrlsByArticleId: {},
};

export const initialClipState = {
  editorUrl: null,
  loading: false,
  error: null,
};

export const defaultError = 'Something went wrong.';

const tvEyesClipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIP_EDITOR_URL: {
      return {
        ...state,
        clipEditorUrlsByArticleId: {
          ...state.clipEditorUrlsByArticleId,
          [action.payload.articleId]: {
            ...initialClipState,
            ...state.clipEditorUrlsByArticleId[action.payload.articleId],
            loading: true,
          },
        },
      };
    }
    case GET_CLIP_EDITOR_URL_ERROR: {
      return {
        ...state,
        clipEditorUrlsByArticleId: {
          ...state.clipEditorUrlsByArticleId,
          [action.payload.articleId]: {
            ...state.clipEditorUrlsByArticleId[action.payload.articleId],
            error: action.payload.error || defaultError,
            loading: false,
          },
        },
      };
    }
    case GET_CLIP_EDITOR_URL_SUCCESS: {
      return {
        ...state,
        clipEditorUrlsByArticleId: {
          ...state.clipEditorUrlsByArticleId,
          [action.payload.articleId]: {
            editorUrl: action.payload.editorUrl,
            error: null,
            loading: false,
          },
        },
      };
    }
    default:
      return state;
  }
};

export const getClipEditorUrlActionCreator = ({
  articleId,
}) => async dispatch => {
  let error = null;
  let editorUrl = null;

  dispatch({
    type: GET_CLIP_EDITOR_URL,
    payload: {
      articleId,
    },
  });

  let host = window.location.origin;

  /*
    In order to test TVEyes clip editing locally, you can provide a host
    name to use instead of localhost. If you run
    ```
    ngrok http 8080
    ```
    You can then set the host (e.g. 3d76sd.ngrok.io) in a `.env` file or via command line.

    See `workspaces/app/TVEYES_CLIPS.md`
  */
  if (process.env.NODE_ENV !== 'production') {
    host = process.env.TVEYES_CALLBACK_HOST || host;
  }

  const unencodedParams = {
    editor: true,
    orderCompleteCallback: `${host}${TVEYES_ORDER_CALLBACK_ENDPOINT}/complete`,
    orderPlacedCallback: `${host}${TVEYES_ORDER_CALLBACK_ENDPOINT}/placed`,
    articleId,
  };

  try {
    editorUrl = await performGet(
      `${TVEYES_ENDPOINT}?${qs.stringify(unencodedParams)}`,
    );
    editorUrl = editorUrl.data.url;
  } catch (err) {
    error = err.message;
  }

  if (error) {
    return dispatch({
      type: GET_CLIP_EDITOR_URL_ERROR,
      payload: {
        articleId,
        error,
      },
    });
  }

  return dispatch({
    type: GET_CLIP_EDITOR_URL_SUCCESS,
    payload: {
      articleId,
      editorUrl,
    },
  });
};

export default tvEyesClipsReducer;
