import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@trendkite/ui';

import { USER_ROLES } from 'constants/constants';
import withAuthorization from 'hocs/withAuthorization';
import { getFeedsActionCreator } from 'reducers/admin/feed-management';
import {
  adminFeedIsLoadingSelector,
  adminFeedsPaginatorSelector,
  adminFeedsSelector,
} from 'selectors/admin/feed-management';

//commented variable lines because of linter error
//Will Circle back to this commented line in future tickets

import FeedTable from './FeedTable/FeedTable';

export enum FEED_ACTION_STATUSES {
  INITIALIZED = 1,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
}

const FeedManagement = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const feeds = useSelector(adminFeedsSelector);
  const isLoading = useSelector(adminFeedIsLoadingSelector);
  const paginator = useSelector(adminFeedsPaginatorSelector);
  const [initialLoading, setInitialLoading] = useState(true);
  //const feedsLoadingError = useSelector(feedsLoadingErrorSelector);

  useEffect(() => {
    setInitialLoading(false);
  }, []);

  const handleGetFeeds = queryParams => {
    dispatch(getFeedsActionCreator(queryParams, intl));
  };

  return (
    <div>
      <div>
        <div>
          <div className="tk-story-recipients-table">
            <FeedTable
              feeds={feeds}
              paginator={paginator}
              initialLoading={initialLoading || isLoading}
              onGetFeeds={handleGetFeeds}
            />
          </div>
        </div>
      </div>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

const AdminFeedManagement = withAuthorization({
  allRoles: [USER_ROLES.adminRole],
})(() => <FeedManagement />);

export default AdminFeedManagement;
