import unionBy from 'lodash/unionBy';

import { addContactsToList, removeAllContactsFromList } from 'actions';
import {
  CONTACT_LISTS_ENDPOINT,
  CONTACT_LISTS_ALL_OVERVIEW_ENDPOINT,
} from 'constants/apis';
import { CONTACTS_RESULTS_LIST } from 'constants/constants';
import { addPageMessage } from 'reducers/page-messages';
import * as restService from 'services/rest-service/rest-service';

const ADD_LISTS_TO_CONTACT_LISTS = 'contact-lists/ADD_LISTS_TO_CONTACT_LISTS';
const SET_CONTACT_LISTS = 'contact-lists/SET_CONTACT_LISTS';
const LOAD_CONTACT_LISTS = 'contact-lists/LOAD_CONTACT_LISTS';
const REMOVE_LISTS_BY_ID_FROM_CONTACT_LISTS =
  'contact-lists/REMOVE_LISTS_BY_ID_FROM_CONTACT_LISTS';
const SET_CURRENT_LIST_ID = 'contact-lists/SET_CURRENT_LIST_ID';

export default function reducer(
  state = {
    currentListId: undefined,
    loaded: false,
    loading: false,
    lists: [],
  },
  action,
) {
  switch (action.type) {
    case ADD_LISTS_TO_CONTACT_LISTS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        lists: unionBy(state.lists, action.payload.lists, 'id').sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };
    }
    case SET_CONTACT_LISTS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        lists: action.payload.lists.sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };
    }
    case LOAD_CONTACT_LISTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case REMOVE_LISTS_BY_ID_FROM_CONTACT_LISTS: {
      const lists = state.lists.map(l => {
        if (l.id === action.payload.deletedId) {
          l.dateDeleted = new Date();
        }
        return l;
      });

      return {
        ...state,
        lists,
      };
    }
    case SET_CURRENT_LIST_ID: {
      return {
        ...state,
        currentListId: action.payload.listId,
      };
    }
    default:
      return state;
  }
}

const fetchContactLists = () => dispatch => {
  restService
    .performGet(CONTACT_LISTS_ENDPOINT)
    .then(response => {
      dispatch({
        type: ADD_LISTS_TO_CONTACT_LISTS,
        payload: {
          lists: response.data,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: ADD_LISTS_TO_CONTACT_LISTS,
        payload: {
          lists: [],
        },
      });
    });
};

const fetchContactListsOverview = () => dispatch => {
  restService
    .performGet(CONTACT_LISTS_ALL_OVERVIEW_ENDPOINT)
    .then(response => {
      const lists = {};
      response.data.forEach(contact => {
        contact.type = 'contacts';
        contact.authorListReferences.forEach(list => {
          if (!lists[list.id]) {
            lists[list.id] = list;
          }
        });
        contact.authorListReferences = contact.authorListReferences.map(
          list => list.id,
        );
      });
      dispatch(removeAllContactsFromList(CONTACTS_RESULTS_LIST));
      dispatch(addContactsToList(response.data, CONTACTS_RESULTS_LIST));

      dispatch({
        type: SET_CONTACT_LISTS,
        payload: {
          lists: Object.values(lists),
        },
      });
    })
    .catch(() => {
      dispatch({
        type: SET_CONTACT_LISTS,
        payload: {
          lists: [],
        },
      });
    });
};

export function addListsToContactLists(payload) {
  return {
    type: ADD_LISTS_TO_CONTACT_LISTS,
    payload,
  };
}

export const loadContactLists = () => dispatch => {
  dispatch({
    type: LOAD_CONTACT_LISTS,
  });

  dispatch(fetchContactLists());
};

export const removeListsByIdFromContactLists = (id, name) => async dispatch => {
  await restService.performDelete(`${CONTACT_LISTS_ENDPOINT}/${id}`);
  dispatch({
    type: REMOVE_LISTS_BY_ID_FROM_CONTACT_LISTS,
    payload: { deletedId: id },
  });
  dispatch(
    addPageMessage({
      isNewUI: true,
      text: `List ${name} has been deleted`,
      status: 'success',
      ttl: 3000,
    }),
  );
};

export const setCurrentListId = listId => ({
  type: SET_CURRENT_LIST_ID,
  payload: { listId },
});

export const loadAllContactListsOverview = () => async dispatch => {
  dispatch({
    type: LOAD_CONTACT_LISTS,
  });

  return dispatch(fetchContactListsOverview());
};

export const updateContactListName = (name, id) => async (
  dispatch,
  getState,
) => {
  const url = `${CONTACT_LISTS_ENDPOINT}/${id}`;
  const state = getState();

  let list = null;
  const lists = state.contactLists.lists.map(l => {
    if (l.id === id) {
      l.name = name;
      list = l;
    }

    return l;
  });

  await restService.performPut(url, { ...list });
  dispatch({ type: SET_CONTACT_LISTS, payload: { lists } });
};

export const updateContactListSharing = (isShared, id) => async (
  dispatch,
  getState,
) => {
  const url = `${CONTACT_LISTS_ENDPOINT}/${id}`;
  const state = getState();

  let list = null;
  const lists = state.contactLists.lists.map(l => {
    if (l.id === id) {
      l.shared = isShared;
      list = l;
    }

    return l;
  });

  await restService.performPut(url, { ...list });
  dispatch({ type: SET_CONTACT_LISTS, payload: { lists } });
  dispatch(
    addPageMessage({
      isNewUI: true,
      text: `Successfully ${isShared ? ' shared' : ' unshared'} list.`,
      status: 'success',
      ttl: 3000,
    }),
  );
};

export const restoreDeletedList = id => async (dispatch, getState) => {
  const url = `${CONTACT_LISTS_ENDPOINT}/${id}`;
  const state = getState();

  const lists = state.contactLists.lists.map(l => {
    if (l.id === id) {
      l.dateDeleted = null;
    }

    return l;
  });

  await restService.performPut(url, { restore: true });
  dispatch({ type: SET_CONTACT_LISTS, payload: { lists } });
};
