import assign from 'lodash/assign';

import difference from 'lodash/difference';

import find from 'lodash/find';
import omit from 'lodash/omit';

import uniq from 'lodash/uniq';

import {
  buildFauxEmail,
  templatizeAndSanitizeHtml,
} from 'components/outreach/utils';
import { OUTREACH_ENDPOINT } from 'constants/apis';

import { DEV_FEATURES } from 'constants/constants';
import { OUTREACH_INTEGRATION_ERROR_TYPES } from 'constants/outreach-integration';
import { OUTREACH_MESSAGE_STATUSES } from 'constants/outreach-message';
import { deleteOutreachDraftMessageById } from 'reducers/outreach/outreach-drafts';
import { refreshFilteredViewActionCreator } from 'reducers/outreach/outreach-filtered-views';
import { setModifiedFromSidebarCount } from 'reducers/outreach/outreach-integrations';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { handleStoryKitMessageSuccess } from 'reducers/stories/story-recipients';
import { hasDevFeatureFlagSelector, userIdSelector } from 'selectors/account';
import {
  outreachComposeFormValuesSelector,
  threadsByIdSelector,
} from 'selectors/outreach';
import { performPost } from 'services/rest-service/rest-service';
import { defaultProps as outreachFilteredViewDefaultProps } from 'types/outreach-filtered-view';
import timeoutPromise from 'utils/timeout-promise';

import { hasMockOutreachDataSelector } from './mockData';
import { performPost as performMockPost } from './mockEndpoints';
import { addPreviewThreadFromMessageActionCreator } from './outreach-threads';

export const CLEAR_ERROR_MESSAGES = 'outreach/CLEAR_ERROR_MESSAGES';
export const CLEAR_MESSAGE_SAVE_ERROR = 'outreach/CLEAR_MESSAGE_SAVE_ERROR';

export const DELETE_MESSAGES = 'outreach/DELETE_MESSAGES';
export const DELETE_MESSAGES_SUCCESS = 'outreach/DELETE_MESSAGES_SUCCESS';
export const DELETE_MESSAGES_ERROR = 'outreach/DELETE_MESSAGES_ERROR';

export const GET_MESSAGES = 'outreach/GET_MESSAGES';
export const GET_MESSAGES_ERROR = 'outreach/GET_MESSAGES_ERROR';
export const GET_MESSAGES_SUCCESS = 'outreach/GET_MESSAGES_SUCCESS';

export const MESSAGES_ERROR = 'outreach/MESSAGES_ERROR';

export const SEND_NEW_MESSAGE = 'outreach/SEND_NEW_MESSAGE';
export const SEND_NEW_MESSAGE_ERROR = 'outreach/SEND_NEW_MESSAGE_ERROR';
export const SEND_NEW_MESSAGE_SUCCESS = 'outreach/SEND_NEW_MESSAGE_SUCCESS';

export const SAVE_MESSAGES = 'outreach/SAVE_MESSAGES';
export const SAVE_MESSAGES_ERROR = 'outreach/SAVE_MESSAGES_ERROR';
export const SAVE_MESSAGES_SUCCESS = 'outreach/SAVE_MESSAGES_SUCCESS';

export const SET_CURRENT_EDITOR_MESSAGE_ID =
  'outreach/SET_CURRENT_EDITOR_MESSAGE_ID';
export const UNSET_CURRENT_EDITOR_MESSAGE_ID =
  'outreach/UNSET_CURRENT_EDITOR_MESSAGE_ID';

const initialState = {
  aggregations: {},
  messagesById: {},
  messagesError: null,
  messagesLoading: false,
  messageDeletePendingIds: [],
  messageDeleteErrorIds: [],
  messageSendPendingIds: [],
  messageSendErrorIds: [],
  messageSavePendingIds: [],
  messageSaveErrorIds: [],
  currentMessageIds: [],
  currentEditorMessageId: null,
  newMessageSaving: false,
  newMessageSaveError: null,
  newMessageIsTest: false,
  successfulMessages: [],
  erroredMessages: [],
  messageAttachmentsById: {},
};

const unnestMessageAttachments = messagesById => {
  const attachments = {};
  Object.values(messagesById).forEach(message => {
    let { attachments: messageAttachments } = message;
    messageAttachments = messageAttachments || [];
    const viewableAttachments = messageAttachments.filter(a => !!a.filename);
    viewableAttachments.forEach(a => (attachments[a.id] = a));
    message.attachments = viewableAttachments.map(a => a.id);
  });

  return attachments;
};

const outreachReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CLEAR_ERROR_MESSAGES: {
      return {
        ...state,
        erroredMessages: [],
      };
    }
    case CLEAR_MESSAGE_SAVE_ERROR:
      return {
        ...state,
        newMessageSaveError: null,
      };
    case GET_MESSAGES: {
      return {
        ...state,
        messagesLoading:
          action.payload.updateCurrentMessages === false
            ? state.messagesLoading
            : true,
        currentMessageIds:
          action.payload.updateCurrentMessages === false
            ? state.currentMessageIds
            : [],
        messagesError: null,
      };
    }
    case GET_MESSAGES_ERROR: {
      return {
        ...state,
        messagesError: action.payload,
        messagesLoading: false,
      };
    }
    case GET_MESSAGES_SUCCESS: {
      const attachments = unnestMessageAttachments(action.payload.messagesById);
      return {
        ...state,
        currentMessageIds:
          action.payload.updateCurrentMessages === false
            ? state.currentMessageIds
            : [...action.payload.messageIds],
        messagesById: {
          ...state.messagesById,
          ...action.payload.messagesById,
        },
        aggregations: action.payload.aggregations,
        messagesError: null,
        messagesLoading: false,
        messageAttachmentsById: {
          ...state.messageAttachmentsById,
          ...attachments,
        },
      };
    }
    case MESSAGES_ERROR: {
      return {
        ...state,
        ...action.payload.updateState,
        messagesError: action.payload,
      };
    }
    case SAVE_MESSAGES: {
      /**
        Receives an array of ids for messages about to be saved
      */
      const nextMessageSavePendingIds = uniq([
        ...state.messageSavePendingIds,
        ...action.payload.messageIds,
      ]);
      return {
        ...state,
        messageSavePendingIds: nextMessageSavePendingIds,
      };
    }
    case SAVE_MESSAGES_ERROR: {
      /**
        Receives an array of ids for messages that errored
      */
      const nextMessageSavePendingIds = difference(
        state.messageSavePendingIds,
        action.payload.messageIds,
      );
      const nextMessageSaveErrorIds = uniq([
        ...state.messageSaveErrorIds,
        ...action.payload.messageIds,
      ]);

      return {
        ...state,
        erroredMessages: action.payload.errors,
        successfulMessages: action.payload.successes,
        messageSavePendingIds: nextMessageSavePendingIds,
        messageSaveErrorIds: nextMessageSaveErrorIds,
      };
    }
    case SAVE_MESSAGES_SUCCESS: {
      /**
        Receives an object of successfully saved messages
      */
      const attachments = unnestMessageAttachments(action.payload.messagesById);
      const messageIds = Object.keys(action.payload.messagesById);
      const nextMessageSavePendingIds = difference(
        state.messageSavePendingIds,
        messageIds,
      );
      const nextMessageSaveErrorIds = difference(
        state.messageSaveErrorIds,
        ...messageIds,
      );

      return {
        ...state,
        messagesById: {
          ...state.messagesById,
          ...action.payload.messagesById,
        },
        messageSavePendingIds: nextMessageSavePendingIds,
        messageSaveErrorIds: nextMessageSaveErrorIds,
        messageAttachmentsById: {
          ...state.messageAttachmentsById,
          ...attachments,
        },
      };
    }
    case SEND_NEW_MESSAGE:
      return {
        ...state,
        newMessageSaving: true,
        newMessageIsTest: action.payload && action.payload.isTest,
        newMessageSaveError: null,
      };
    case SEND_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        newMessageSaving: false,
        newMessageIsTest: false,
      };
    case SEND_NEW_MESSAGE_ERROR:
      return {
        ...state,
        newMessageSaving: false,
        newMessageIsTest: false,
        newMessageSaveError: action.payload,
      };
    case DELETE_MESSAGES: {
      /**
        Receives an array of ids for messages about to be deleted
      */
      const nextMessageDeletePendingIds = uniq([
        ...state.messageDeletePendingIds,
        ...action.payload.messageIds,
      ]);
      return {
        ...state,
        messageDeletePendingIds: nextMessageDeletePendingIds,
      };
    }
    case DELETE_MESSAGES_ERROR: {
      /**
        Receives an array of ids for messages that errored on deletion
      */
      const nextMessageDeletePendingIds = difference(
        state.messageDeletePendingIds,
        action.payload.messageIds,
      );
      const nextMessageDeleteErrorIds = uniq([
        ...state.messageDeleteErrorIds,
        ...action.payload.messageIds,
      ]);

      return {
        ...state,
        messageDeletePendingIds: nextMessageDeletePendingIds,
        messageDeleteErrorIds: nextMessageDeleteErrorIds,
      };
    }
    case DELETE_MESSAGES_SUCCESS: {
      /**
        Receives an object of successfully deleted messages
      */
      const messageIds = Object.keys(action.payload.messagesById);
      const nextMessageDeletePendingIds = difference(
        state.messageDeletePendingIds,
        messageIds,
      );
      const nextMessageDeleteErrorIds = difference(
        state.messageDeleteErrorIds,
        ...messageIds,
      );
      const nextMessagesById = omit(state.messagesById, messageIds);

      return {
        ...state,
        messagesById: nextMessagesById,
        messageDeletePendingIds: nextMessageDeletePendingIds,
        messageDeleteErrorIds: nextMessageDeleteErrorIds,
      };
    }
    case SET_CURRENT_EDITOR_MESSAGE_ID:
      return {
        ...state,
        currentEditorMessageId: action.payload,
      };
    case UNSET_CURRENT_EDITOR_MESSAGE_ID:
      return {
        ...state,
        currentEditorMessageId: null,
      };
    default:
      return state;
  }
};

const outreachErrorActionDispatcher = (
  response,
  messageIds = [],
  sendableMessages = [],
) => dispatch => {
  let errorType = OUTREACH_INTEGRATION_ERROR_TYPES.unknown;
  let errorActionType = SEND_NEW_MESSAGE_ERROR;
  if (response) {
    switch (response.status) {
      case 400:
        errorType = OUTREACH_INTEGRATION_ERROR_TYPES.generic_send;
        break;
      case 402:
        errorType = OUTREACH_INTEGRATION_ERROR_TYPES.rejected_send;
        break;
      case 401:
      case 403:
        errorType = OUTREACH_INTEGRATION_ERROR_TYPES.disconnected;
        errorActionType = MESSAGES_ERROR;
        break;
      case 429:
        errorType = OUTREACH_INTEGRATION_ERROR_TYPES.throttled;
        break;
      case 422:
        errorType = OUTREACH_INTEGRATION_ERROR_TYPES.outlookConfig;
        break;
      case 500:
      case 502:
      case 503:
      case 504:
      default:
        errorType = OUTREACH_INTEGRATION_ERROR_TYPES.unknown_send;
    }
  }

  return dispatch({
    type: errorActionType,
    payload: {
      messageIds: messageIds || [],
      sendableMessages,
      type: errorType,
      updateState: {
        newMessageSaving: false,
        newMessageIsTest: false,
      },
    },
  });
};

const getMessagesByIdFromResponse = responseData => {
  const messageIds = [];
  let messagesById = {};

  if (!responseData) {
    return { messageIds, messagesById };
  }

  // Wrap single message payloads in an array for consistency
  // TODO: Make API responses always return an array of messages as the 'messages' node, and this code can go away.
  let messagesArray =
    (Array.isArray(responseData.results) && responseData.results) ||
    (Array.isArray(responseData.messages) && responseData.messages) ||
    [];
  messagesArray =
    !Array.isArray(messagesArray) && responseData.id
      ? [responseData]
      : messagesArray;
  messagesArray = messagesArray.map(message => {
    return {
      ...message,
      from: Array.isArray(message.from) ? message.from : [message.from],
    };
  });

  if (!messagesArray) {
    return { messageIds, messagesById };
  }

  messagesById = messagesArray.reduce((result, message) => {
    if (message.id) {
      result[message.id] = message;
      messageIds.push(message.id);
    }
    return result;
  }, {});

  return { messageIds, messagesById };
};

const getMessagesByIdFromQueryResponse = results =>
  results.reduce(
    (result, message) => {
      if (message.messageId) {
        result.messagesById[message.messageId] = message;
        result.messageIds.push(message.messageId);
      }
      return result;
    },
    { messageIds: [], messagesById: {} },
  );

const useMockOutreachMessages = params => async dispatch => {
  const { data } = await performMockPost(
    `${OUTREACH_ENDPOINT}/messages/query`,
    params,
  );
  const { aggregations, pagination: paginationResponse, results } = data;
  const { messageIds, messagesById } = getMessagesByIdFromQueryResponse(
    results,
  );

  dispatch({
    type: GET_MESSAGES_SUCCESS,
    payload: { messageIds, messagesById },
  });

  return {
    aggregations,
    pagination: paginationResponse,
    messageIds,
    messagesById,
  };
};

export const getOutreachMessagesWithQueryActionCreator = (
  { query },
  { updateCurrentMessages } = {},
) => async (dispatch, getState) => {
  const state = getState();
  const useMockData = hasMockOutreachDataSelector(state);

  dispatch({
    type: GET_MESSAGES,
    payload: { updateCurrentMessages },
  });

  if (useMockData) return dispatch(useMockOutreachMessages(query));

  // TODO: Add error handling by the time we're hitting a real API
  const { data } = await performPost(
    `${OUTREACH_ENDPOINT}/messages/query`,
    query,
  );
  const { aggregations, pagination: paginationResponse, results } = data;
  const { messageIds, messagesById } = getMessagesByIdFromQueryResponse(
    results,
  );

  dispatch({
    type: GET_MESSAGES_SUCCESS,
    payload: { messageIds, messagesById, aggregations, updateCurrentMessages },
  });

  return {
    aggregations,
    pagination: paginationResponse,
    messageIds,
    messagesById,
  };
};

export const getOutreachMessagesByThreadIdWithQueryActionCreator = ({
  pagination,
  threadId,
  trash = false,
}) => async dispatch => {
  const filteredView = { ...outreachFilteredViewDefaultProps };

  const query = {
    pagination: {
      ...filteredView.pagination,
      ...pagination,
    },
    sort: filteredView.sort,
    search: filteredView.search,
    filters: {
      threadId,
      trash,
    },
  };

  return dispatch(getOutreachMessagesWithQueryActionCreator({ query }));
};

const updateThreadWithSentMessages = ({ messagesById, messageIds }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const threadsById = threadsByIdSelector(state);

  messageIds.forEach(messageId => {
    const message = messagesById[messageId];
    const threadId = message.threadId;
    const threadDetails = threadsById[threadId];

    if (threadDetails) {
      dispatch(
        getOutreachMessagesByThreadIdWithQueryActionCreator({ threadId }),
      );
    } else {
      dispatch(addPreviewThreadFromMessageActionCreator({ message }));
    }
  });
};

/** Private function for all actions that update messages */
const postOutreachMessagesActionCreator = nextMessages => async (
  dispatch,
  getState,
) => {
  const errors = [];
  const state = getState();
  const useMockData = hasMockOutreachDataSelector(state);

  const { messageIds: nextMessageIds } = getMessagesByIdFromResponse(
    nextMessages,
  );

  const isTest = state.outreach?.newMessageIsTest;

  dispatch({
    type: SAVE_MESSAGES,
    payload: { messageIds: nextMessageIds },
  });

  if (useMockData) {
    dispatch({
      type: SAVE_MESSAGES_SUCCESS,
      payload: {
        messagesById: nextMessageIds,
      },
    });

    return { messageIds: [], messagesById: nextMessageIds };
  }

  // TODO: Remove this logic in favor of all string IDs when service-outreach can handle it.
  const cleanupParticipantStringIds = participant => {
    if (typeof participant.id === 'string') {
      const cleanedParticipant = {
        ...participant,
        idString: participant.id,
      };

      delete cleanedParticipant.id;
      return cleanedParticipant;
    }

    return participant;
  };

  const requests = nextMessages.map(message => {
    const messageWithoutContactIdsAsString = {
      ...message,
      to: message.to.map(p => cleanupParticipantStringIds(p)),
      cc: isTest ? [] : message.cc.map(p => cleanupParticipantStringIds(p)),
      bcc: isTest ? [] : message.bcc.map(p => cleanupParticipantStringIds(p)),
    };

    return new Promise(resolve => {
      performPost(`${OUTREACH_ENDPOINT}/messages`, {
        messages: [messageWithoutContactIdsAsString],
      })
        .then(response => {
          if (!response || !response.data) {
            throw new Error('Missing response data');
          }

          resolve({ message, response: response.data });
        })
        .catch(error => {
          resolve({
            message,
            error: {
              message: error.message,
              response: error.response,
            },
          });
        });
    });
  });

  const results = await Promise.all(requests);

  results.forEach(result => {
    if (result.error) {
      errors.push(result.error);
    }
  });

  const responseMessageIds = results.map(result =>
    getMessagesByIdFromResponse(result.response),
  );

  const messageIds = [];
  const messagesById = {};
  responseMessageIds.forEach(rmi => {
    messageIds.push(rmi.messageIds[0]);
    assign(messagesById, rmi.messagesById);
  });

  if (errors.length || !messageIds || !messageIds.length) {
    const error = errors.length
      ? errors[0]
      : new Error('There was a problem saving messages.');

    dispatch({
      type: SAVE_MESSAGES_ERROR,
      payload: {
        messageIds,
        successes: results.filter(result => !result.error),
        errors: results.filter(result => !!result.error),
      },
    });

    error.messageIds = messageIds;
    throw error;
  }

  dispatch({
    type: SAVE_MESSAGES_SUCCESS,
    payload: { messagesById },
  });

  return { messageIds, messagesById };
};

/** Sending messages by POSTing them with status: SCHEDULED */
export const sendOutreachMessagesActionCreator = messages => async (
  dispatch,
  getState,
) => {
  const state = getState();

  let sendableMessages = messages.map(message => ({
    ...message,
    status: OUTREACH_MESSAGE_STATUSES.scheduled,
  }));

  if (hasDevFeatureFlagSelector(state)(DEV_FEATURES.jortsEmailBlocker)) {
    sendableMessages = messages.map(message => ({
      ...message,
      status: OUTREACH_MESSAGE_STATUSES.scheduled,
      to: message.to.map(outreachContact => ({
        ...outreachContact,
        email: buildFauxEmail(outreachContact.email),
      })),
    }));
  }

  const { messagesById: nextMessageIds } = getMessagesByIdFromResponse(
    sendableMessages,
  );

  dispatch({
    type: SEND_NEW_MESSAGE,
    payload: { messageIds: nextMessageIds },
  });

  return dispatch(postOutreachMessagesActionCreator(sendableMessages))
    .then(({ messagesById, messageIds }) => {
      sendableMessages.forEach(message => {
        if (message.id) {
          dispatch(deleteOutreachDraftMessageById(message.id));
        }
      });

      dispatch({
        type: SEND_NEW_MESSAGE_SUCCESS,
        payload: Object.keys(messagesById)[0],
      });

      dispatch({ type: UNSET_CURRENT_EDITOR_MESSAGE_ID });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Message sent',
          status: 'success',
        }),
      );

      // Refresh messages or threads
      dispatch(updateThreadWithSentMessages({ messagesById, messageIds }));

      const messageWithStory = find(
        messagesById,
        message => !!message.stories && message.stories.length > 0,
      );
      if (messageWithStory) {
        dispatch(
          handleStoryKitMessageSuccess(
            messagesById,
            messageWithStory.stories[0],
          ),
        );
      }

      dispatch(setModifiedFromSidebarCount(true));
    })
    .catch(error => {
      const { messageIds, response } = error;
      dispatch(
        outreachErrorActionDispatcher(response, messageIds, sendableMessages),
      );
      throw error;
    });
};

// Convenience method for sending straight from the redux form values
export const sendOutreachMessageFormActionCreator = () => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const ownerId = userIdSelector(state);
  const {
    filteredViewId,
    ...messageFormValues
  } = outreachComposeFormValuesSelector(state);

  const message = {
    ...messageFormValues,
    ownerId,
    body: templatizeAndSanitizeHtml(
      messageFormValues.body,
      messageFormValues.substitutions,
    ),
  };

  const result = await dispatch(sendOutreachMessagesActionCreator([message]));

  if (filteredViewId) {
    await timeoutPromise(1000);
    dispatch(refreshFilteredViewActionCreator(filteredViewId));
  }

  return result;
};

/**
 * This method hooks into postOutreachMessagesActionCreator directly,
 * avoiding the other side affects such as adding to threads and replacing the
 * "to" fields with faux emails (already replaced with from value)
 */
export const sendOutreachMessageFormTestActionCreator = messageToUse => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const ownerId = userIdSelector(state);
  const messageFormValues = outreachComposeFormValuesSelector(state);
  const jortsEmailBlocker = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsEmailBlocker,
  );

  const revisedFromData = jortsEmailBlocker
    ? {
        ...messageFormValues.from,
        email: buildFauxEmail(messageFormValues.from.email),
      }
    : messageFormValues.from;

  const newMessage = {
    ...messageFormValues,
    status: OUTREACH_MESSAGE_STATUSES.scheduled,
    to: [revisedFromData],
    ownerId,
    stories: [],
    body: templatizeAndSanitizeHtml(messageFormValues.body, {
      ...messageFormValues.substitutions,
      'First name': '{{First name}}',
      'Last name': '{{Last name}}',
      'Full name': '{{Full name}}',
      'German greeting': '{{German greeting}}',
    }),
  };

  const message =
    messageToUse && !jortsEmailBlocker ? messageToUse : newMessage;

  dispatch({ type: SEND_NEW_MESSAGE, payload: { isTest: true } });

  return dispatch(postOutreachMessagesActionCreator([message]))
    .then(() => {
      dispatch({ type: SEND_NEW_MESSAGE_SUCCESS });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: `Test message sent to ${messageFormValues.from.email}`,
          status: 'success',
        }),
      );
    })
    .catch(error => {
      const { messageIds, response } = error;
      return dispatch(
        outreachErrorActionDispatcher(response, messageIds, [message]),
      );
    });
};

export const clearMessageSaveErrorActionDispatcher = () => ({
  type: CLEAR_MESSAGE_SAVE_ERROR,
});

export default outreachReducer;
