import React, { Suspense, useEffect } from 'react';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import axios from 'axios';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { __RouterContext as RouterContext } from 'react-router';
import {
  HashRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { RouterToUrlQuery, configureUrlQuery } from 'react-url-query';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { Container, Loader } from '@trendkite/ui';
import DevFeatureLocalOverrides from 'components/DevFeatureLocalOverrides';
import FeatureMenu from 'components/FeatureMenu';
import OmniBar from 'components/OmniBar';

import {
  AUTH_REDIRECT_URL,
  DASHBOARD_BASE_URL,
  DEV_FEATURES,
  FEATURES,
  INFLUENCERS_HUB_URL,
  DASHBOARD_URL,
} from 'constants/constants';

import usePageTransitionTracker from 'hooks/usePageTransitionTracker';

import {
  userHasFeatureFlag,
  userHasDevFeatureFlag,
} from 'services/feature-service/feature-service';

import { getCurrentUserAccountId } from 'services/user-service/user-service';
import { getCanUseBrandwatchEmbedded } from 'trendkite-front-end-app/src/selectors/canUse/canUse';

import ScrollToTop from '../components/global/ScrollToTop';
import OutreachComposeModal from '../components/outreach/OutreachComposeModal';
import OutreachReconnectModal from '../components/outreach/OutreachReconnectModal';
import OutreachRedirect from '../components/outreach/OutreachRedirect';
import OutreachRedirectModal from '../components/outreach/OutreachRedirectModal';
import PageKites from '../components/page-kites';
import SlackShareArticleModal from '../components/slack/SlackShareArticleModal';
import churnZeroTrackEvent from '../config/churnzero';
import * as constants from '../constants/constants';
import app from '../reducers';

import Header from './header';
import IntlWrapper from './IntlWrapper/IntlWrapper';

import {
  Admin,
  Announcements,
  ArticleContainer,
  BulkSendContainer,
  BrowseAssetLibraryStandAlone,
  Campaigns,
  Contacts,
  ContactsBulkCreate,
  ContactsMarketingView,
  Dashboard,
  DashboardTemplates,
  DevArea,
  Discovery,
  DrilldownContainer,
  DrilldownReportContainer,
  Email,
  EmailVerification,
  Impact,
  ManageData,
  RecipientAlerts,
  RecipientUnsubscribe,
  Report,
  Search,
  SearchHistory,
  SlackContainer,
  Stories,
  Tags,
  TranslationsEditor,
  UnifiedSettings,
  Influencers,
  SocialSearch,
  WidgetDrilldown,
  EarnedSearch,
  ReportPresentation,
  Alerts,
  SocialListening,
  WelcomeHub,
} from './index.js';

const userOnlyHasOldDashboard = () =>
  userHasDevFeatureFlag(DEV_FEATURES.oldDashboards) &&
  !userHasDevFeatureFlag(DEV_FEATURES.newDashboards);

const shouldRedirectToOldDashboards = () =>
  userOnlyHasOldDashboard() ||
  (userHasDevFeatureFlag(DEV_FEATURES.oldDashboards) &&
    userHasDevFeatureFlag(DEV_FEATURES.forceHomeOldDashboards));

const getDashboardNavigation = () => {
  if (shouldRedirectToOldDashboards()) {
    return DASHBOARD_BASE_URL;
  } else {
    return DASHBOARD_URL;
  }
};

function homePageRedirect() {
  if (!getCurrentUserAccountId()) {
    return AUTH_REDIRECT_URL.replace(
      '$redirectUrl',
      encodeURIComponent(window.location.hash),
    );
  }

  let redirectUrl = DASHBOARD_BASE_URL;

  if (!userHasFeatureFlag(FEATURES.monitoring)) {
    redirectUrl = INFLUENCERS_HUB_URL;
  } else if (userHasFeatureFlag(FEATURES.monitoring)) {
    redirectUrl = getDashboardNavigation();
  }
  return redirectUrl;
}

const RouteChangeLogger = () => {
  const location = useLocation();

  usePageTransitionTracker(location.pathname);

  useEffect(() => {
    churnZeroTrackEvent(location.pathname);
  }, [location]);

  return null;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

export const createAppStore = (initialState = {}) => {
  const store = createStore(
    app,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(app);
    });
  }

  return store;
};

const store = createAppStore();
configureUrlQuery({ entrySeparator: ',' });

const httpLink = createHttpLink({
  uri: '/api/earned-impact/graphql',
  fetch: buildAxiosFetch(axios),
});

const authLink = setContext((_, { headers }) => {
  const tkAccountId = window.activeUser?.account?.id;
  return {
    headers: {
      ...headers,
      tkAccountId,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const isAppEmbeddedInIframe = window !== window.parent;
const shouldShowHeader = !isAppEmbeddedInIframe;

if (!shouldShowHeader) {
  // apply class to the body element so we can apply styling to all elements, including React Portals
  document.querySelector('body').classList.add('app--no-header');
}

export const Routes = () => (
  <>
    <RouterToUrlQuery routerContext={RouterContext}>
      <ScrollToTop>
        <div>
          <FeatureMenu />
          <DevFeatureLocalOverrides />
          <PageKites />
          <Switch>
            <Route
              path={[
                `${constants.INTEGRATIONS_BASE_URL}/slack`,
                `${constants.INFLUENCERS_HUB_BASE_URL}`,
                `${constants.RECIPIENT_UNSUBSCRIPTION_BASE_URL}`,
                `${constants.RECIPIENT_ALERTS_BASE_URL}`,
                `${constants.SOCIAL_LISTENING_URL}`,
                `${constants.EMAIL_ANNOUNCEMENTS_UNSUBSCRIBE_BASE_URL}`,
                `${constants.EMAIL_ANNOUNCEMENTS_UNSUBSCRIBED_BASE_URL}`,
                `${constants.EMAIL_VERIFICATION_BASE_URL}`,
              ]}
              component={null}
            />
            <Route
              exact
              path={`${constants.REPORT_PRESENTATION_URL}/:reportUuid`}
              component={null}
            />
            {shouldShowHeader && <Route component={Header} />}
          </Switch>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path={`${constants.CONTACTS_BULK_CREATE_BASE_URL}`}
                component={ContactsBulkCreate}
              />
              <Route exact path="/dev-area/tailwind" component={DevArea} />
              <Route
                exact
                path={`${constants.CONTACTS_BASE_URL}-overview`}
                component={ContactsMarketingView}
              />
              <Route
                path={`${constants.CONTACTS_BASE_URL}/`}
                component={Contacts}
              />
              <Route
                path={`${constants.ARTICLES_BASE_URL}/:articleId`}
                component={ArticleContainer}
              />
              <Route
                path={`${constants.CAMPAIGNS_BASE_URL}/:id?`}
                component={Campaigns}
              />
              <Route
                path={`${constants.DRILLDOWN_REPORT_BASE_URL}/:widgetId`}
                component={DrilldownReportContainer}
              />
              <Route
                path={`${constants.DRILLDOWN_BASE_URL}/:widgetId`}
                component={DrilldownContainer}
              />
              <Route
                path={`${constants.INTEGRATIONS_BASE_URL}/slack`}
                component={SlackContainer}
              />
              <Route
                path={`${constants.DISCOVERY_BASE_URL}/:section?/:searchId?`}
                component={Discovery}
              />
              <Route
                path={constants.REACT_SEARCH_BASE_URL}
                component={Search}
              />
              <Route
                exact
                path={`${constants.STORIES_BASE_URL}/browse-asset-library`}
                component={BrowseAssetLibraryStandAlone}
              />
              <Route
                path={`${constants.STORIES_BASE_URL}/`}
                component={Stories}
              />
              <Route path={`${constants.EMAIL_BASE_URL}`} component={Email} />
              <Route path={`${constants.IMPACT_BASE_URL}`} component={Impact} />
              <Route
                path={`${constants.UNIFIED_SETTINGS_BASE_URL}/:section?/:subsection?`}
                component={UnifiedSettings}
              />
              <Route
                exact
                path={`${constants.MANAGE_DATA_BASE_URL}/`}
                component={ManageData}
              />
              <Route path={constants.REACT_TAG_BASE_URL} component={Tags} />
              <Route
                path={constants.OUTREACH_INTEGRATION_REDIRECT_URL}
                component={OutreachRedirect}
              />
              <Route
                path={`${constants.SEARCH_HISTORY_BASE_URL}/:searchId`}
                component={SearchHistory}
              />
              <Route
                path={constants.BULK_SEND_BASE_URL}
                component={BulkSendContainer}
              />
              <Route path={constants.ADMIN_BASE_URL} component={Admin} />
              <Route
                path={`${constants.RECIPIENT_UNSUBSCRIPTION_BASE_URL}`}
                component={RecipientUnsubscribe}
              />
              <Route
                path={`${constants.DASHBOARD_TEMPLATES_BASE_URL}/`}
                component={DashboardTemplates}
              />
              <Route
                path={`${constants.RECIPIENT_ALERTS_BASE_URL}`}
                component={RecipientAlerts}
              />
              <Route
                path={`${constants.NEW_DASHBOARD_BASE_URL}`}
                component={Dashboard}
              />
              <Route
                path={`${constants.REPORT_PRESENTATION_URL}/:reportUuid`}
                component={ReportPresentation}
              />
              <Route
                path={`${constants.NEW_REPORT_BASE_URL}`}
                component={Report}
              />
              <Route
                path={constants.INFLUENCERS_HUB_BASE_URL}
                component={Influencers}
              />
              <Route path={constants.BASE_ALERTS_URL_NEW} component={Alerts} />
              <Route
                path={`${constants.EMAIL_ANNOUNCEMENTS_UNSUBSCRIBE_BASE_URL}`}
                component={Announcements}
              />
              <Route
                path={`${constants.EMAIL_ANNOUNCEMENTS_UNSUBSCRIBED_BASE_URL}`}
                component={Announcements}
              />
              <Route
                path={`${constants.EMAIL_VERIFICATION_BASE_URL}/:uuid`}
                component={EmailVerification}
              />
              <Route
                path={`${constants.TRANSLATE_BASE_URL}`}
                component={TranslationsEditor}
              />
              <Route
                path={`${constants.SOCIAL_SEARCH_BASE_URL}`}
                component={SocialSearch}
              />
              <Route
                path={`${constants.WIDGET_DRILLDOWN_URL}/:widgetId`}
                component={WidgetDrilldown}
              />
              <Route
                path={constants.EARNED_SEARCHES_BASE_URL}
                component={EarnedSearch}
              />
              <Route path={constants.WELCOME_HUB_URL} component={WelcomeHub} />
              {getCanUseBrandwatchEmbedded() && (
                <Route
                  component={SocialListening}
                  path={constants.SOCIAL_LISTENING_BASE_URL}
                />
              )}
              <Route render={() => (window.location = homePageRedirect())} />
            </Switch>
          </Suspense>
          <OutreachComposeModal
            showSaveDraft={userHasDevFeatureFlag(
              constants.DEV_FEATURES.jortsDrafts,
            )}
          />
          <OutreachReconnectModal />
          <OutreachRedirectModal
            companyName={
              userHasDevFeatureFlag(constants.DEV_FEATURES.rebranding)
                ? 'Cision'
                : 'TrendKite'
            }
          />
          {userHasDevFeatureFlag(constants.DEV_FEATURES.influencerHub) && (
            <OmniBar />
          )}
          <SlackShareArticleModal />
        </div>
      </ScrollToTop>
    </RouterToUrlQuery>
    <RouteChangeLogger />
  </>
);

const App = () => (
  <Container>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <IntlWrapper>
          <Router>
            <Routes />
          </Router>
        </IntlWrapper>
      </Provider>
    </ApolloProvider>
  </Container>
);

export default hot(module)(App);
