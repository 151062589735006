import { combineReducers } from 'redux';

import myStoriesReducer from './my-stories';
import storyAudienceDemographicsReducer from './story-audience-demographics';
import storyBrowseModalUploadReducer from './story-browse-modal-upload';
import storyCoverageReducer from './story-coverage';
import storyBuilder from './story-hub';
import storyKeyMessagesReducer from './story-key-messages';
import storyRecipientsReducer from './story-recipients';

export { default as storyContentFormReducer } from './story-content-form';
export { default as storyPressContactFormReducer } from './story-press-contact-form';
export { default as storyTitleFormReducer } from './story-title-form';

export default combineReducers({
  storyBuilder,
  myStories: myStoriesReducer,
  storyCoverage: storyCoverageReducer,
  storyKeyMessages: storyKeyMessagesReducer,
  storyRecipients: storyRecipientsReducer,
  storyBrowseModalUpload: storyBrowseModalUploadReducer,
  storyAudienceDemographics: storyAudienceDemographicsReducer,
});
