import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Edit = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </svg>
);
Edit.propTypes = { fill: PropTypes.string };
Edit.defaultProps = { fill: iconColorsMap.mainicon };

export default Edit;
