import React from 'react';
import PropTypes from 'prop-types';

import { Body, DisplaySM } from '../type';
import Loader from '../loader';
import Modal from '../modal';

const LoadingModal = ({ title, isOpen, body }) => {
  return (
    <Modal isOpen={isOpen} size="sm" modalClassName="tk-loading-modal">
      <Modal.Body style={{ padding: '50px' }}>
        <Loader style={{ marginBottom: '20px' }} />
        {title && <DisplaySM block>{title}</DisplaySM>}
        {body && (
          <Body color="light" block>
            {body}
          </Body>
        )}
      </Modal.Body>
    </Modal>
  );
};

LoadingModal.propTypes = {
  body: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

LoadingModal.defaultProps = {
  title: '',
  body: null,
};

export default LoadingModal;
