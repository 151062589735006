import { getCurrentUserImpersonating } from 'services/user-service/user-service';

import * as churnzeroConstants from '../constants/churnzero-constants';
import {
  getIsAmericanAccount,
  getIsCanadianAccount,
  getIsEmeiaAccount,
  getIsFrenchAccount,
  getCanUseChurnZeroByAccountType,
  getIsUserInCisionDomain,
} from '../selectors/canUse/canUse';

// OK see AN-898 for more :
// 1. The ChurnZero JS should be Off in Dev and On in production.
// 2. The only demo account with the ChurnZero JS in Production should be the Dunder Mifflin account.
// 3. When we built the JS with Ian Strain-Seymour last year, he included logic in it that required a ChurnZero feature flag in Next Gen.
//

const getEnvironment = () => window.PAGE_DATA?.environment?.toLowerCase();
const env = getEnvironment();

const canUseChurnZeroTagging = env === 'production';
const ChurnZero = window.ChurnZero || [];

const user = window.activeUser;
const accountGcId = user.account?.gcId;
const isDemoAccount = getCanUseChurnZeroByAccountType(
  user.account?.accountType?.id,
);
const isCisionDomain = getIsUserInCisionDomain(user.username);
const impersonatingId = getCurrentUserImpersonating();
const userIsImpersonating = !!impersonatingId;

const validChurnZeroConfig =
  !Number.isNaN(accountGcId) &&
  !isDemoAccount &&
  !isCisionDomain &&
  !userIsImpersonating;

const setChurnZeroConfig = () => {
  if (validChurnZeroConfig) {
    let appKey;

    if (getIsAmericanAccount(accountGcId)) {
      appKey = churnzeroConstants.APP_KEY.AMERICAN_KEY;
    } else if (getIsCanadianAccount(accountGcId)) {
      appKey = churnzeroConstants.APP_KEY.CANADIAN_KEY;
    } else if (getIsEmeiaAccount(accountGcId)) {
      appKey = churnzeroConstants.APP_KEY.EMEIA_KEY;
    } else if (getIsFrenchAccount(accountGcId)) {
      appKey = churnzeroConstants.APP_KEY.FRENCH_KEY;
    } else {
      appKey = churnzeroConstants.APP_KEY.GLOBAL_KEY;
    }

    ChurnZero.push(['setAppKey', appKey]);
    ChurnZero.push(['setContact', accountGcId, user.username]);
  }
};

setChurnZeroConfig();

const churnZeroTrackEvent = route => {
  if (canUseChurnZeroTagging && validChurnZeroConfig) {
    let metric = churnzeroConstants.routeToMetricMap[route];
    if (!metric) {
      metric = churnzeroConstants.METRICS.UNKNOWN;
    }
    if (ChurnZero.length > 0) {
      ChurnZero.push(['trackEvent', metric]);
    }
  }
};
export default churnZeroTrackEvent;
