import pullAll from 'lodash/pullAll';

export const SET_TOP_CONTENT = 'discovery/SET_TOP_CONTENT';
export const SET_SEARCH_ID = 'discovery/SET_SEARCH_ID';
export const REMOVE_DISCOVERY_ARTICLES = 'discovery/REMOVE_DISCOVERY';

export const GET_DISCOVERY_FACETS = 'discovery/GET_DISCOVERY_FACETS';
export const GET_DISCOVERY_FACETS_SUCCESS =
  'discovery/GET_DISCOVERY_FACETS_SUCCESS';
export const GET_DISCOVERY_FACETS_ERROR =
  'discovery/GET_DISCOVERY_FACETS_ERROR';

export const OPEN_DISCOVERY_FACET = 'discovery/OPEN_DISCOVERY_FACET';
export const CLOSE_DISCOVERY_FACET = 'discovery/CLOSE_DISCOVERY_FACET';

export const initialState = {
  topContentArticleIds: [],
  totalArticleCount: 0,
  searchId: '',
  facetsLoading: false,
  facetsError: false,
  facetIds: [],
  openFacetIds: [],
  useSelectedSearch: false,
};

const discoveryTopContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOP_CONTENT: {
      return {
        ...state,
        topContentArticleIds: action.payload.articleIds,
        totalArticleCount: action.payload.totalArticleCount,
        error: false,
        loading: true,
      };
    }

    case GET_DISCOVERY_FACETS: {
      return {
        ...state,
        facetsLoading: true,
        facetsError: false,
        facetIds: [],
      };
    }

    case GET_DISCOVERY_FACETS_ERROR: {
      return {
        ...state,
        facetsLoading: false,
        facetsError: true,
        facetIds: [],
      };
    }

    case GET_DISCOVERY_FACETS_SUCCESS: {
      return {
        ...state,
        facetsLoading: false,
        facetsError: false,
        facetIds: action.payload,
      };
    }

    case REMOVE_DISCOVERY_ARTICLES: {
      const topContentArticleIds = state.topContentArticleIds.slice();
      pullAll(topContentArticleIds, action.payload.removeArticleIds);

      return {
        ...state,
        topContentArticleIds,
      };
    }

    case SET_SEARCH_ID: {
      return {
        ...state,
        searchId: `${action.payload}`,
        useSelectedSearch: true,
      };
    }

    case OPEN_DISCOVERY_FACET: {
      const openFacetIds = [...state.openFacetIds];

      if (state.openFacetIds.indexOf(action.payload) < 0) {
        openFacetIds.push(action.payload);
      }

      return {
        ...state,
        openFacetIds,
      };
    }

    case CLOSE_DISCOVERY_FACET: {
      const openFacetIds = [...state.openFacetIds];

      if (state.openFacetIds.indexOf(action.payload) >= 0) {
        openFacetIds.splice(state.openFacetIds.indexOf(action.payload), 1);
      }

      return {
        ...state,
        openFacetIds,
      };
    }

    default:
      return state;
  }
};

export const setSearchId = searchId => dispatch => {
  dispatch({
    type: SET_SEARCH_ID,
    payload: `${searchId || ''}`,
  });
};

export const setDiscoveryFacetOpenActionCreator = facetId => dispatch => {
  dispatch({ type: OPEN_DISCOVERY_FACET, payload: facetId });
};

export const setDiscoveryFacetClosedActionCreator = facetId => dispatch => {
  dispatch({ type: CLOSE_DISCOVERY_FACET, payload: facetId });
};

export default discoveryTopContentReducer;
