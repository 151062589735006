export const ACCESS_CONTROL_GROUP_SET_SELECTED_GROUP_ID =
  'ACCESS_CONTROL_GROUP_SET_SELECTED_GROUP_ID';

export interface AccessControlGroupState {
  selectedGroupId: number;
}

interface SetSelectedGroupIdAction {
  type: typeof ACCESS_CONTROL_GROUP_SET_SELECTED_GROUP_ID;
  payload: number;
}

export type AccessControlGroupActionTypes = SetSelectedGroupIdAction;
