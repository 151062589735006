export default {
  archiveReportSuccessfully: {
    id: 'REPORT_ARCHIVE_ACTION_SUCCESS',
    defaultMessage: 'Report Successfully Archived.',
    description: 'Message to show when the archive report action is successful',
  },
  pageTitle: {
    id: 'REPORTS_PAGE_TITLE',
    description: 'Reports 2.0: page title',
    defaultMessage: '(New) Reports',
  },
  untitledAnalytic: {
    id: 'REPORTS_UNTITLED_REPORT_TITLE',
    description: 'Reports 2.0: untitled report title',
    defaultMessage: 'Untitled Report',
  },
  reportLoadingMessage: {
    id: 'GENERATING_REPORT_PROCESSING_MESSAGE',
    description: 'Reports 2.0: Message while report is being generated',
    defaultMessage:
      'We are processing the report. This may take a few minutes or longer. Once the report is complete, it will be available under Analytics > Reports.',
  },
  reportLoadingModalMessage: {
    id: 'MODAL_REPORT_LOADING_MESSAGE',
    description: 'Modal to generate reports from the dashboard listing page',
    defaultMessage: 'Please wait while we prepare your widgets',
  },
  reportOfflineViewText: {
    id: 'REPORT_OFFLINE_VIEW_TEXT',
    description: 'Reports 2.0: text for offline report view ',
    defaultMessage: 'This report is currently offline',
  },
  reportOfflineViewContactText: {
    id: 'REPORT_OFFLINE_VIEW_CONTACT_TEXT',
    description: 'Reports 2.0: text to contact if there is a problem ',
    defaultMessage:
      'Please contact the owner of this report to get it published.',
  },
  reportOfflineViewPoweredBy: {
    id: 'REPORT_OFFLINE_VIEW_POWERED_BY',
    description: 'Reports 2.0: text to indicate the creators ',
    defaultMessage: 'INTERACTIVE REPORT POWERED BY',
  },
  reportErrorViewText: {
    id: 'REPORT_ERROR_VIEW_TEXT',
    description:
      'Reports 2.0: text to indicate there is a fault in the response ',
    defaultMessage:
      'Something went wrong. This occasionally happens when one or more widgets failed. Try revisiting your dashboard widgets and see if there’s any error.',
  },
  reportDefaultHeaderText: {
    id: 'REPORT_DEFAULT_HEADER_TEXT',
    description: 'Reports 2.0: Default text for a text widget header',
    defaultMessage: 'Please add the report header',
  },
  reportDefaultBodyText: {
    id: 'REPORT_DEFAULT_BODY_TEXT',
    description: 'Reports 2.0: Default text for a text widget body',
    defaultMessage: 'Please add the report summary',
  },
  linkReportWithCampaignSuccessMessage: {
    id: 'LINK_REPORT_WITH_CAMPAIGN_SUCCESS_MESSAGE',
    description:
      'Reports 2.0: Message for the kite component when campaign gets linked to a report successfully',
    defaultMessage: 'Success! You added this report to {campaignTitles}.',
  },
  linkReportWithCampaignErrorMessage: {
    id: 'LINK_REPORT_WITH_CAMPAIGN_ERROR_MESSAGE',
    description:
      'Reports 2.0: Message for the kite component when campaign fails to link to a report',
    defaultMessage:
      'Error! We were not able to add this report to {campaignTitles}. Please try again. If the error persists, please contact support.',
  },
  addingHeadlineToSlide: {
    id: 'REPORTS_ADDING_HEADLINE_TO_SLIDE',
    defaultMessage: 'Adding headline to the slide.',
    description:
      'Reports 2.0: Message to show while adding a headline to the slide.',
  },
  addingBodyToSlide: {
    id: 'REPORTS_ADDING_BODY_TO_SLIDE',
    defaultMessage: 'Adding body to the slide.',
    description:
      'Reports 2.0: Message to show while adding a body to the slide.',
  },
  addingImageToSlide: {
    id: 'REPORTS_ADDING_IMAGE_TO_SLIDE',
    defaultMessage: 'Adding image to the slide.',
    description:
      'Reports 2.0: Message to show while adding an image to the slide.',
  },
  addingWidgetToSlide: {
    id: 'REPORTS_ADDING_WIDGET_TO_SLIDE',
    defaultMessage: 'Adding widget to the slide.',
    description:
      'Reports 2.0: Message to show while adding a widget to the slide.',
  },
  errorAddingHeadlineToSlide: {
    id: 'REPORTS_ERROR_ADDING_HEADLINE_TO_SLIDE',
    defaultMessage:
      'An error ocurred while trying to add a headline to the slide.',
    description:
      'Reports 2.0: Message to show when an error ocurred while adding headline to the slide.',
  },
  errorAddingBodyToSlide: {
    id: 'REPORTS_ERROR_ADDING_BODY_TO_SLIDE',
    defaultMessage: 'An error ocurred while trying to add a body to the slide.',
    description:
      'Reports 2.0: Message to show when an error ocurred while adding body to the slide.',
  },
  errorAddingImageToSlide: {
    id: 'REPORTS_ERROR_ADDING_IMAGE_TO_SLIDE',
    defaultMessage:
      'An error ocurred while trying to add an image to the slide.',
    description:
      'Reports 2.0: Message to show when an error ocurred while adding an image to the slide.',
  },
  errorAddingWidgetToSlide: {
    id: 'REPORTS_ERROR_ADDING_WIDGET_TO_SLIDE',
    defaultMessage:
      'An error ocurred while trying to add a widget to the slide.',
    description:
      'Reports 2.0: Message to show when an error ocurred while adding a widget to the slide.',
  },
};
