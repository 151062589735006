import includes from 'lodash/includes';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';

import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { performPost } from 'services/rest-service/rest-service';

const initialState = {
  loading: false,
  error: false,
  selected: [],
  data: [],
};

const contributorsReducerFactory = namespace => {
  const GET_CONTRIBUTORS_START = `ihub/${namespace}/GET_CONTRIBUTORS_START`;
  const GET_CONTRIBUTORS_SUCCESS = `ihub/${namespace}/GET_CONTRIBUTORS_SUCCESS`;
  const GET_CONTRIBUTORS_ERROR = `ihub/${namespace}/GET_CONTRIBUTORS_ERROR`;
  const SELECT_CONTRIBUTORS = `ihub/${namespace}/SELECT_CONTRIBUTORS`;
  const UNSELECT_CONTRIBUTORS = `ihub/${namespace}/UNSELECT_CONTRIBUTORS`;
  const CLEAR_SELECTED_CONTRIBUTORS = `ihub/${namespace}/CLEAR_SELECTED_CONTRIBUTORS`;

  const contributorsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_CONTRIBUTORS_START:
        return {
          ...state,
          loading: true,
          error: false,
          selected: [],
          data: [],
        };

      case GET_CONTRIBUTORS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: [...(action.payload?.data || [])],
          total: action.payload?.total || 0,
        };

      case GET_CONTRIBUTORS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };

      case SELECT_CONTRIBUTORS:
        return {
          ...state,
          selected: uniq(state.selected.concat(action.payload)),
        };

      case CLEAR_SELECTED_CONTRIBUTORS:
        return {
          ...state,
          selected: [],
        };

      case UNSELECT_CONTRIBUTORS:
        return {
          ...state,
          selected: remove(
            state.selected,
            val => !includes(action.payload, val),
          ),
        };

      default:
        return state;
    }
  };

  const clearContributors = () => ({ type: CLEAR_SELECTED_CONTRIBUTORS });
  const selectContributors = ids => async dispatch => {
    dispatch({ type: SELECT_CONTRIBUTORS, payload: ids });
  };
  const unselectContributors = ids => async dispatch => {
    dispatch({ type: UNSELECT_CONTRIBUTORS, payload: ids });
  };

  const getContributorsForOutlet = ({
    outletId,
    size = 30,
    offset = 0,
    description = '',
  }) => async dispatch => {
    dispatch({ type: GET_CONTRIBUTORS_START });
    let payload = [];
    const id = parseInt(`${outletId}`.replace(/[^\d]/g, ''), 10);

    try {
      const response = await performPost(
        `${INFLUENCER_HUB_ENDPOINT}/top-contributors`,
        {
          id,
          size,
          offset,
          description,
        },
      );
      payload = response?.data || {};
    } catch (e) {
      dispatch({ type: GET_CONTRIBUTORS_ERROR, payload: e });
    }

    dispatch({ type: GET_CONTRIBUTORS_SUCCESS, payload });
  };

  return {
    CLEAR_SELECTED_CONTRIBUTORS,
    GET_CONTRIBUTORS_START,
    GET_CONTRIBUTORS_ERROR,
    GET_CONTRIBUTORS_SUCCESS,
    SELECT_CONTRIBUTORS,
    UNSELECT_CONTRIBUTORS,
    getContributorsForOutlet,
    contributorsReducer,
    clearContributors,
    selectContributors,
    unselectContributors,
  };
};

export default contributorsReducerFactory;
