import pick from 'lodash/pick';

import { ARTICLE_V3_BY_URL_ENDPOINT } from 'constants/apis';

import { DATE_RANGE_KEYS, DATE_RANGE_OPTIONS } from 'utils/date/date-util';

import columnsConfig from './DrilldownArticleList/columnConfig';

export const ASC_SORT = 'asc';
export const DESC_SORT = 'desc';

export const ADD_ARTICLE_FLOW_ENDPOINTS_MAP = {
  get: `${ARTICLE_V3_BY_URL_ENDPOINT}/get`,
  linkToSearch: `${ARTICLE_V3_BY_URL_ENDPOINT}/linkToSearch`,
  save: `${ARTICLE_V3_BY_URL_ENDPOINT}/save`,
};

export const SEARCH_ID_KEY = 'searchId';
export const TAG_ID_KEY = 'tagId';

export const ALLOWED_SORT_OPTIONS = Object.keys(columnsConfig).reduce(
  (acc, columnKey) => {
    const columnConfig = columnsConfig[columnKey];
    if (columnConfig.sort) {
      return { ...acc, [columnConfig.sort]: columnKey };
    }
    return acc;
  },
  {},
);

export const COMMON_DATE_RANGE_CONFIG_DROPDOWN_MODIFIERS = {
  [DATE_RANGE_KEYS.TRAILING_7]: { showOption: true },
  [DATE_RANGE_KEYS.TRAILING_30]: { showOption: true },
  [DATE_RANGE_KEYS.TRAILING_90]: { showOption: true },
  [DATE_RANGE_KEYS.CUSTOM]: {
    showOption: true,
  },
};

export const EARNED_SEARCHES_DATE_RANGE_CONFIG = {
  dropdownModifiers: {
    ...COMMON_DATE_RANGE_CONFIG_DROPDOWN_MODIFIERS,
  },
  options: pick(DATE_RANGE_OPTIONS, [
    DATE_RANGE_KEYS.TRAILING_7,
    DATE_RANGE_KEYS.TRAILING_30,
    DATE_RANGE_KEYS.TRAILING_90,
    DATE_RANGE_KEYS.CUSTOM,
  ]),
  orderedOptionsKeys: [
    DATE_RANGE_KEYS.TRAILING_90,
    DATE_RANGE_KEYS.TRAILING_30,
    DATE_RANGE_KEYS.TRAILING_7,
    DATE_RANGE_KEYS.CUSTOM,
  ],
  includeMore: false,
  showSelected: false,
};

export const DISCOVERY_ALL_MENTIONS_DATE_RANGE_CONFIG = {
  dropdownModifiers: {
    ...COMMON_DATE_RANGE_CONFIG_DROPDOWN_MODIFIERS,
  },
  options: DATE_RANGE_OPTIONS,
  orderedOptionsKeys: Object.keys(DATE_RANGE_OPTIONS),
  includeMore: true,
  moreButtonPosition: 3,
  showSelected: false,
};
