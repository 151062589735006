import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';

import { Icon } from '@cision/rover-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import ReactPaginate from 'react-paginate';

import styles from './SimplePagination.module.css';

const SimplePagination = props => {
  const { pageChange, testId, disableNextPageControl } = props;
  const [activeLink, setActiveLink] = useState(null);
  const ref = useRef(null);

  const saveRef = () => {
    // eslint-disable-next-line react/no-find-dom-node
    setActiveLink(ReactDOM.findDOMNode(ref.current));
  };

  useEffect(() => {
    saveRef();
  }, []);

  const onPageChange = data => {
    pageChange(data);
  };

  useLayoutEffect(() => {
    if (activeLink) {
      const label = activeLink.getElementsByClassName(
        'SavedListTable__active-link',
      );
      if (label.length > 0) {
        label[0].innerHTML = props.label;
      }
    }
  });

  const createPaginationModifiersList = (context = []) => {
    return classNames('tk-pagination', styles.pagination, {
      [styles.paginationXs]: context && context.includes('container-xs-down'),
      [styles.nextPagecontrolDisabled]: disableNextPageControl,
    });
  };

  return (
    <React.Fragment>
      <ReactPaginate
        ref={ref}
        breakClassName="break-me"
        disableInitialCallback
        marginPagesDisplayed={0}
        pageRangeDisplayed={0}
        breakLabel={null}
        previousLabel={
          <Icon
            name="arrow-back"
            width={20}
            height={20}
            data-qa={`${testId}-previous`}
          />
        }
        nextLabel={
          <Icon
            name="arrow-forward"
            width={20}
            height={20}
            data-qa={`${testId}-next`}
          />
        }
        subContainerClassName="pages pagination"
        onPageChange={onPageChange}
        pageClassName={styles.paginationLi}
        previousLinkClassName={styles.previousLinkClassName}
        nextLinkClassName={styles.nextLinkClassName}
        previousClassName={styles.paginationLi}
        nextClassName={styles.paginationLi}
        activeClassName={styles.paginationLi}
        containerClassName={createPaginationModifiersList(props.context)}
        activeLinkClassName={`${props.labelElementClassName} ${styles.paginationLink}`}
        {...props}
      />
    </React.Fragment>
  );
};

SimplePagination.defaultProps = {
  context: [],
  forcePage: 0,
  pageCount: 0,
  testId: 'pagination',
  disableNextPageControl: false,
};

SimplePagination.propTypes = {
  context: PropTypes.array,
  label: PropTypes.string.isRequired,
  labelElementClassName: PropTypes.string.isRequired,
  pageChange: PropTypes.func.isRequired,
  forcePage: PropTypes.number,
  pageCount: PropTypes.number,
  testId: PropTypes.string,
  disableNextPageControl: PropTypes.bool,
};

export default SimplePagination;
