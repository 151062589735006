import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';

import { NYLAS_OAUTH_BASE } from 'constants/apis';

import {
  NYLAS_OAUTH_PARAMS,
  NYLAS_DEV_OAUTH_PARAMS,
  OUTREACH_INTEGRATION_REDIRECT_URL,
} from 'constants/constants';

const environments = {
  'dev.trendkite.com': 'dev',
  'staging.trendkite.com': 'staging',
  'app.trendkite.com': 'prod',
  'app2.trendkite.com': 'app2',
  'app2.cision.com': 'prod',
};

export const getNylasAuthUrl = ({ callbackState, hasDevFeatureFlag }) => {
  const stateKey = uuidv4();
  if (callbackState) {
    window.localStorage.setItem(stateKey, JSON.stringify(callbackState));
  }

  const oauthParams =
    environments[window.location.hostname] === 'prod'
      ? NYLAS_OAUTH_PARAMS
      : NYLAS_DEV_OAUTH_PARAMS;

  const urlParams = qs.stringify(
    {
      ...oauthParams,
      login_hint: '',
      redirect_uri: `${window.location.origin}/#${OUTREACH_INTEGRATION_REDIRECT_URL}`,
      state: stateKey,
    },
    { addQueryPrefix: true },
  );

  return `${NYLAS_OAUTH_BASE}${urlParams}`;
};

export const handleNylasRedirect = ({
  location,
  hasDevFeatureFlag,
  history,
  saveIntegrationCallback,
  saveNylasStateCallback,
  showErrorKiteCallback,
  intl,
}) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const code = queryParams.code;

  let callbackState;
  const stateKey = queryParams.state;
  const storedState = window.localStorage.getItem(stateKey);
  if (storedState) {
    window.localStorage.removeItem(stateKey);
    callbackState = JSON.parse(storedState);
  }

  if (queryParams.error && queryParams.error === 'access_denied') {
    showErrorKiteCallback(intl);
    if (callbackState?.redirect) {
      history.replace(callbackState?.redirect);
    } else {
      history.push('/settings/account/mail');
    }
  }

  if (code) {
    saveIntegrationCallback({ intl, authCode: code });

    if (saveNylasStateCallback) {
      saveNylasStateCallback(callbackState);
    }

    history.replace(callbackState?.redirect);
  }
};

export default {
  getNylasAuthUrl,
};
