import { CAMPAIGN_ENDPOINT, WIDGET_V3_ENDPOINT } from 'constants/apis';
import {
  DATA_SOURCE_TYPES,
  DATA_SOURCES,
  CAMPAIGN_WIDGET_DRILLDOWN_COLUMNS,
  V3_MEASURE_OPERATIONS,
  V3_METRIC_FIELDS,
  VISUALIZATION_TYPES,
  WIDGET_DIMENSION_FIELDS,
} from 'constants/constants';
import {
  performGet,
  performPut,
  performPost,
} from 'services/rest-service/rest-service';
import timeoutPromise from 'utils/timeout-promise';

export const GET_CAMPAIGN_WIDGETS = 'campaigns/widgets/GET_CAMPAIGN_WIDGETS';
export const GET_CAMPAIGN_WIDGETS_SUCCESS =
  'campaigns/widgets/GET_CAMPAIGN_WIDGETS_SUCCESS';
export const GET_CAMPAIGN_WIDGETS_ERROR =
  'campaigns/widgets/GET_CAMPAIGN_WIDGETS_ERROR';
export const SET_TOTAL_MENTIONS_WIDGET =
  'campaign/widgets/SET_TOTAL_MENTIONS_WIDGET';
export const UPDATE_TOTAL_MENTIONS_WIDGET =
  'campaigns/widgets/UPDATE_TOTAL_MENTIONS_WIDGET';
export const UPDATE_TOTAL_MENTIONS_WIDGET_SUCCESS =
  'campaigns/widgets/UPDATE_TOTAL_MENTIONS_WIDGET_SUCCESS';
export const UPDATE_TOTAL_MENTIONS_WIDGET_ERROR =
  'campaigns/widgets/UPDATE_TOTAL_MENTIONS_WIDGET_ERROR';
export const GET_TOTAL_MENTIONS_WIDGET =
  'campaigns/widgets/GET_TOTAL_MENTIONS_WIDGET';
export const GET_TOTAL_MENTIONS_WIDGET_SUCCESS =
  'campaigns/widgets/GET_TOTAL_MENTIONS_WIDGET_SUCCESS';
export const GET_TOTAL_MENTIONS_WIDGET_ERROR =
  'campaigns/widgets/GET_TOTAL_MENTIONS_WIDGET_ERROR';
export const CREATE_TOTAL_MENTIONS_WIDGET =
  'campaigns/widgets/CREATE_TOTAL_MENTIONS_WIDGET';
export const CREATE_TOTAL_MENTIONS_WIDGET_SUCCESS =
  'campaigns/widgets/CREATE_TOTAL_MENTIONS_WIDGET_SUCCESS';
export const CREATE_TOTAL_MENTIONS_WIDGET_ERROR =
  'campaigns/widgets/CREATE_TOTAL_MENTIONS_WIDGET_ERROR';
export const RESET_TO_DEFAULT = 'campaigns/widgets/RESET_TO_DEFAULT';

export const initialState = {
  widgets: [],
  totalMentionsWidget: null,
  widgetDrilldownColumns: [
    CAMPAIGN_WIDGET_DRILLDOWN_COLUMNS.AUTHOR,
    CAMPAIGN_WIDGET_DRILLDOWN_COLUMNS.OUTLET,
    CAMPAIGN_WIDGET_DRILLDOWN_COLUMNS.PUB_DATE,
  ],
  error: false,
  loading: false,
  totalMentionsWidgetUpdated: false,
};

const campaignWidgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_WIDGETS: {
      return {
        ...state,
        loading: true,
        totalMentionsWidget: null,
      };
    }

    case GET_CAMPAIGN_WIDGETS_SUCCESS: {
      return {
        ...state,
        widgets: action.payload,
        error: false,
        loading: false,
      };
    }

    case GET_CAMPAIGN_WIDGETS_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    case CREATE_TOTAL_MENTIONS_WIDGET: {
      return {
        ...state,
        loading: true,
      };
    }

    case CREATE_TOTAL_MENTIONS_WIDGET_SUCCESS: {
      return {
        ...state,
        totalMentionsWidget: action.payload,
        error: false,
        loading: false,
      };
    }

    case CREATE_TOTAL_MENTIONS_WIDGET_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    case UPDATE_TOTAL_MENTIONS_WIDGET: {
      return {
        ...state,
        loading: true,
        totalMentionsWidgetUpdated: false,
      };
    }

    case UPDATE_TOTAL_MENTIONS_WIDGET_SUCCESS: {
      return {
        ...state,
        totalMentionsWidget: action.payload,
        error: false,
        loading: false,
        totalMentionsWidgetUpdated: true,
      };
    }

    case UPDATE_TOTAL_MENTIONS_WIDGET_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        totalMentionsWidgetUpdated: false,
      };
    }
    case GET_TOTAL_MENTIONS_WIDGET_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        totalMentionsWidgetUpdated: false,
      };
    }
    case GET_TOTAL_MENTIONS_WIDGET: {
      return {
        ...state,
        loading: true,
        totalMentionsWidgetUpdated: false,
      };
    }
    case GET_TOTAL_MENTIONS_WIDGET_SUCCESS: {
      return {
        ...state,
        totalMentionsWidget: action.payload,
        error: false,
        loading: false,
        totalMentionsWidgetUpdated: true,
      };
    }
    case SET_TOTAL_MENTIONS_WIDGET: {
      return {
        ...state,
        totalMentionsWidget: action.payload,
      };
    }
    case RESET_TO_DEFAULT: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export const getCampaignWidgets = campaign => dispatch => {
  dispatch({ type: GET_CAMPAIGN_WIDGETS });

  performGet(`${CAMPAIGN_ENDPOINT}/${campaign.id}/widgets`)
    .then(response => {
      dispatch({
        type: GET_CAMPAIGN_WIDGETS_SUCCESS,
        payload: response.data,
      });
      const totalMentionsWidget = response.data.length
        ? response.data.find(x =>
            x.metrics.some(
              y =>
                y.measure.field === V3_METRIC_FIELDS.articleMention &&
                y.measure.operation === V3_MEASURE_OPERATIONS.count &&
                y.visualizationType === VISUALIZATION_TYPES.area,
            ),
          )
        : null;

      dispatch({
        type: SET_TOTAL_MENTIONS_WIDGET,
        payload: totalMentionsWidget,
      });

      if (!totalMentionsWidget) {
        dispatch(createTotalMentionsWidget(campaign));
      }
    })
    .catch(() => {
      dispatch({ type: GET_CAMPAIGN_WIDGETS_ERROR });
    });
};

export const createCampaignTotalMentionsWidgetV3 = campaignData => {
  const allSearchIds = campaignData.searches
    ? Array.from(
        new Set(
          [campaignData.primarySearch.id].concat([...campaignData.searches]),
        ),
      )
    : [campaignData.primarySearch.id];

  const widgetMetrics = [
    {
      visualizationType: VISUALIZATION_TYPES.area,
      measure: {
        field: V3_METRIC_FIELDS.articleMention,
        label: 'MENTIONS',
        operation: V3_MEASURE_OPERATIONS.count,
      },
      isDateRangeCompare: false,
      dimensions: [
        {
          field: WIDGET_DIMENSION_FIELDS.publishDate,
          label: DATA_SOURCE_TYPES.search,
          granularity: { type: 'DAY' },
        },
      ],
      dateRangeType: campaignData.rangeType,
      dataSources: allSearchIds.map(searchId => {
        return {
          source: DATA_SOURCES.trendkiteElasticSearch,
          sourceType: DATA_SOURCE_TYPES.search,
          sourceId: searchId,
        };
      }),
      startDate: campaignData.startDate,
      endDate: campaignData.endDate,
    },
  ];
  const widgetRequest = {
    title: 'Total Mentions',
    metrics: widgetMetrics,
  };

  return performPost(WIDGET_V3_ENDPOINT, widgetRequest);
};

export const createTotalMentionsWidget = campaign => async dispatch => {
  dispatch({ type: CREATE_TOTAL_MENTIONS_WIDGET });
  try {
    const widgetResponse = await createCampaignTotalMentionsWidgetV3(campaign);
    dispatch({
      type: CREATE_TOTAL_MENTIONS_WIDGET_SUCCESS,
      payload: widgetResponse.data,
    });
    campaign.widgetV3s = [widgetResponse.data.id];
    await performPut(`${CAMPAIGN_ENDPOINT}/${campaign.id}`, campaign);
  } catch (error) {
    dispatch({ type: CREATE_TOTAL_MENTIONS_WIDGET_ERROR });
  }
};

export const updateTotalMentionsWidget = () => async (dispatch, getState) => {
  const state = getState();
  const widget = state.campaignWidgets.totalMentionsWidget;
  const campaign = state.campaign.campaign;

  if (widget) {
    await timeoutPromise(3000);
    dispatch({ type: UPDATE_TOTAL_MENTIONS_WIDGET });
    widget.metrics = widget.metrics.map(metric => {
      return {
        id: metric.id,
        dateRangeCompareType: metric.dateRangeCompareType,
        isDateRangeCompare: metric.isDateRangeCompare,
        dataSources: campaign.searches.map(searchId => {
          return {
            source: DATA_SOURCES.trendkiteElasticSearch,
            sourceType: DATA_SOURCE_TYPES.search,
            sourceId: searchId,
          };
        }),
        dateRangeType: campaign.rangeType,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        visualizationType: metric.visualizationType,
        dimensions: metric.dimensions,
        measure: metric.measure,
      };
    });
    performPut(`${WIDGET_V3_ENDPOINT}/${widget.id}`, widget)
      .then(response => {
        dispatch({
          type: UPDATE_TOTAL_MENTIONS_WIDGET_SUCCESS,
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: UPDATE_TOTAL_MENTIONS_WIDGET_ERROR });
      });
  }
};

export const getTotalMentionsWidget = widgetId => dispatch => {
  dispatch({ type: GET_TOTAL_MENTIONS_WIDGET });
  performGet(`${WIDGET_V3_ENDPOINT}/${widgetId}`)
    .then(response => {
      dispatch({
        type: GET_TOTAL_MENTIONS_WIDGET_SUCCESS,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_TOTAL_MENTIONS_WIDGET_ERROR });
    });
};

export const resetCampaignWidgets = () => ({
  type: RESET_TO_DEFAULT,
});

export default campaignWidgetReducer;
