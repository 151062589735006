import React, { Component } from 'react';

import { Button } from '@cision/rover-ui';

import PropTypes from 'prop-types';

import { Addon, Body, Button as TkUiButton, SvgIcon } from '@trendkite/ui';

import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';

import messages from './EmailFooterGroup.messages';

class EmailFooterGroup extends Component {
  static baseClass = 'email-footer-group';

  render() {
    const {
      className,
      onSendTestEmail,
      onDiscardChanges,
      onSaveChanges,
      onSkip,
      onUnskip,
      show,
    } = this.props;

    return (
      <div className={`${EmailFooterGroup.baseClass} ${className}`}>
        <div className={`${EmailFooterGroup.baseClass}__left-group`} />
        <div className={`${EmailFooterGroup.baseClass}__right-group`}>
          {show.sendTest && (
            <div className={`${EmailFooterGroup.baseClass}__item`}>
              <TkUiButton
                data-qa="Opb7WEKwf3WJxqbTnZDVA"
                modifiers={['link', 'bold-text']}
                onClick={onSendTestEmail}
                disabled={show.isSendTestDisabled}
              >
                <TranslatedMessage {...messages.sendMeTestEmail} />
              </TkUiButton>
            </div>
          )}
          {show.discardChanges && (
            <div className={`${EmailFooterGroup.baseClass}__item`}>
              <Button
                data-qa="E5RfTimP8wlY15d63MuHv"
                level="tertiary"
                size="lg"
                onClick={onDiscardChanges}
              >
                <TranslatedMessage {...messages.discardChanges} />
              </Button>
            </div>
          )}
          {show.saveChanges && (
            <div className={`${EmailFooterGroup.baseClass}__item`}>
              <Button
                data-qa="uURhCZosbStJq4imx0PbD"
                level="primary"
                size="lg"
                onClick={onSaveChanges}
              >
                <TranslatedMessage {...globalMessages.save} />
              </Button>
            </div>
          )}
          {show.skip && (
            <div className={`${EmailFooterGroup.baseClass}__item`}>
              <TkUiButton
                data-qa="cOUcPx9IYF0PJa6OLaFgR"
                modifiers={['round', 'tertiary', 'flex']}
                onClick={onSkip}
              >
                <Addon>
                  <SvgIcon icon="no" width={20} height={20} />
                </Addon>
                <TranslatedMessage {...messages.sendMessageLater} />
              </TkUiButton>
            </div>
          )}
          {show.unskip && (
            <React.Fragment>
              <div className={`${EmailFooterGroup.baseClass}__item`}>
                <SvgIcon icon="notice" width={14} height={14} />
              </div>
              <div className={`${EmailFooterGroup.baseClass}__item`}>
                <Body color="light">
                  <TranslatedMessage
                    {...messages.messageWillNotBeSendWithBatch}
                  />
                </Body>
              </div>
              <div className={`${EmailFooterGroup.baseClass}__item`}>
                <TkUiButton
                  data-qa="cdUyICm3bQHpVEQrtyDDy"
                  modifiers={['round', 'primary', 'inline-block']}
                  onClick={onUnskip}
                >
                  <Addon>
                    <SvgIcon color="white" icon="no" width={20} height={20} />
                  </Addon>
                  <TranslatedMessage {...messages.unskip} />
                </TkUiButton>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

EmailFooterGroup.propTypes = {
  className: PropTypes.string,
  onSendTestEmail: PropTypes.func,
  onDiscardChanges: PropTypes.func,
  onSaveChanges: PropTypes.func,
  onSkip: PropTypes.func,
  onUnskip: PropTypes.func,
  show: PropTypes.shape({
    relationship: PropTypes.bool,
    sendTest: PropTypes.bool,
    isSendTestDisabled: PropTypes.bool,
    discardChanges: PropTypes.bool,
    saveChanges: PropTypes.bool,
    skip: PropTypes.bool,
    unskip: PropTypes.bool,
  }).isRequired,
};

EmailFooterGroup.defaultProps = {
  className: '',
  onRelationshipUpdate: () => {},
  onSendTestEmail: () => {},
  onDiscardChanges: () => {},
  onSaveChanges: () => {},
  onSkip: () => {},
  onUnskip: () => {},
  show: {
    relationship: false,
    sendTest: false,
    isSendTestDisabled: false,
    discardChanges: false,
    saveChanges: false,
    skip: false,
    unskip: false,
  },
};

export default EmailFooterGroup;
