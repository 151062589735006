import uniq from 'lodash/uniq';

import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { DEV_FEATURES } from 'constants/constants';

import { addPageMessage } from 'reducers/page-messages';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import { performGet, performPut } from 'services/rest-service/rest-service';
import {
  getDisplayNamesByIds,
  getCurrentUserId,
} from 'services/user-service/user-service';

import messages from '../../pages/Campaigns/Campaigns.messages';
import dashboardMessages from '../../pages/Dashboard/DashboardListContainer/DashboardGrid/dashboard-grid.messages';

import { UPDATE_CAMPAIGN_PROPERTY } from './campaign';

export const GET_CAMPAIGN_DASHBOARD_DATA =
  'campaigns/dashboards/GET_CAMPAIGN_DASHBOARD_DATA';
export const GET_CAMPAIGN_DASHBOARD_DATA_RECEIVED =
  'campaigns/dashboards/GET_CAMPAIGN_DASHBOARD_DATA_RECEIVED';
export const GET_CAMPAIGN_DASHBOARD_DATA_ERROR =
  'campaigns/dashboards/GET_CAMPAIGN_DASHBOARD_DATA_ERROR';
export const GET_CAMPAIGN_DASHBOARD_OWNERS =
  'campaigns/dashboards/GET_CAMPAIGN_DASHBOARD_OWNERS';
export const GET_CAMPAIGN_DASHBOARD_OWNERS_ERROR =
  'campaigns/dashboards/GET_CAMPAIGN_DASHBOARD_OWNERS_ERROR';
export const GET_CAMPAIGN_DASHBOARD_OWNERS_SUCCESS =
  'campaigns/dashboards/GET_CAMPAIGN_DASHBOARD_OWNERS_SUCCESS';
export const DELETE_CAMPAIGN_DASHBOARD =
  'campaigns/dashboards/DELETE_CAMPAIGN_DASHBOARD';
export const DELETE_CAMPAIGN_DASHBOARD_ERROR =
  'campaigns/dashboards/DELETE_CAMPAIGN_DASHBOARD_ERROR';
export const DELETE_CAMPAIGN_DASHBOARD_SUCCESS =
  'campaigns/dashboards/DELETE_CAMPAIGN_DASHBOARD_SUCESS';

export const initialState = {
  campaignDashboards: [],
  archivedDashboards: [],
  deletedDashboards: [],
  error: false,
  loading: true,
};

const campaignDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_DASHBOARD_DATA: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CAMPAIGN_DASHBOARD_DATA_RECEIVED: {
      const {
        campaignDashboards,
        archivedDashboards,
        deletedDashboards,
      } = action.payload;

      return {
        ...state,
        campaignDashboards,
        archivedDashboards,
        deletedDashboards,
        error: false,
        loading: false,
      };
    }

    case GET_CAMPAIGN_DASHBOARD_DATA_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    case GET_CAMPAIGN_DASHBOARD_OWNERS_SUCCESS: {
      const displayNames = action.payload;
      const dashboardsWithNames = [...state.campaignDashboards];
      dashboardsWithNames.forEach(
        dash => (dash.ownedBy = displayNames[dash.userId] || ''),
      );
      return {
        ...state,
        campaignDashboards: [...dashboardsWithNames],
      };
    }

    case DELETE_CAMPAIGN_DASHBOARD: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case DELETE_CAMPAIGN_DASHBOARD_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case DELETE_CAMPAIGN_DASHBOARD_SUCCESS: {
      const { dashboards } = action.payload;
      const { campaignDashboards } = state;

      const updatedDashboards = campaignDashboards.filter(dashboard => {
        return dashboards.some(
          dash =>
            dash.id === dashboard.id && dash.version === dashboard.version,
        );
      });

      return {
        ...state,
        campaignDashboards: updatedDashboards,
        loading: false,
        error: false,
      };
    }

    default:
      return state;
  }
};

const getDashboardOwnerNames = ownerIds => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_DASHBOARD_OWNERS });

  try {
    const displayNames = await getDisplayNamesByIds(ownerIds);
    dispatch({
      type: GET_CAMPAIGN_DASHBOARD_OWNERS_SUCCESS,
      payload: displayNames,
    });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_DASHBOARD_OWNERS_ERROR });
    throw e;
  }
};

export const getCampaignDashboardsByCampaignId = (
  campaignId,
  intl,
) => dispatch => {
  dispatch({ type: GET_CAMPAIGN_DASHBOARD_DATA });

  performGet(`${CAMPAIGN_ENDPOINT}/${campaignId}/dashboard`)
    .then(response => {
      let allCampaignDashboards = [...response.data];
      if (!userHasDevFeatureFlag(DEV_FEATURES.newDashboards)) {
        allCampaignDashboards = response.data.filter(
          dash => dash.version === 1,
        );
      }
      const currentUserId = getCurrentUserId();
      const {
        archivedDashboards,
        deletedDashboards,
        campaignDashboards,
      } = allCampaignDashboards.reduce(
        (result, dash) => {
          const dateArchived =
            userHasDevFeatureFlag(DEV_FEATURES.archiveDeleteDashboards) &&
            dash.dateArchived;
          const dateDeleted =
            userHasDevFeatureFlag(DEV_FEATURES.archiveDeleteDashboards) &&
            dash.dateDeleted;

          const updatedDashboard = {
            ...dash,
            svgIcon: dash.version === 1 ? 'dashboard' : 'dashboardTiles',
            owned: dash.userId === currentUserId,
            title:
              dash.title ||
              intl?.formatMessage(dashboardMessages.untitledAnalytic),
          };

          if (dateArchived) {
            result.archivedDashboards.push(updatedDashboard);
          } else if (dateDeleted) {
            result.deletedDashboards.push(updatedDashboard);
          } else {
            result.campaignDashboards.push(updatedDashboard);
          }

          return result;
        },
        {
          archivedDashboards: [],
          deletedDashboards: [],
          campaignDashboards: [],
        },
      );

      dispatch({
        type: GET_CAMPAIGN_DASHBOARD_DATA_RECEIVED,
        payload: {
          archivedDashboards,
          deletedDashboards,
          campaignDashboards,
        },
      });

      const ownerIds = uniq(campaignDashboards.map(dash => dash.userId));
      if (ownerIds.length > 0) {
        dispatch(getDashboardOwnerNames(ownerIds));
      }
    })
    .catch(() => {
      dispatch({ type: GET_CAMPAIGN_DASHBOARD_DATA_ERROR });
    });
};

export const deleteCampaignDashboardsById = (
  campaignId,
  dashboards,
  intl,
) => async (dispatch, getState) => {
  const allDashboards = getState().campaignDashboardList;
  const campaignDashboards = [
    ...allDashboards.campaignDashboards,
    ...allDashboards.archivedDashboards,
    ...allDashboards.deletedDashboards,
  ];

  const filteredDashboards = campaignDashboards
    .filter(dashboard => !dashboards.map(d => d.id).includes(dashboard.id))
    .map(dashboard => ({ id: dashboard.id, version: dashboard.version }));

  dispatch({
    type: DELETE_CAMPAIGN_DASHBOARD,
    payload: { dashboards: filteredDashboards },
  });

  try {
    await performPut(`${CAMPAIGN_ENDPOINT}/${campaignId}`, {
      dashboards: filteredDashboards,
    });
    dispatch({
      type: DELETE_CAMPAIGN_DASHBOARD_SUCCESS,
      payload: { dashboards: filteredDashboards },
    });
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: { dashboards: filteredDashboards },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.dashboardsDeletedSuccess),
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: DELETE_CAMPAIGN_DASHBOARD_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.dashboardsDeletedFailed),
        status: 'danger',
      }),
    );
  }
};

export default campaignDashboardReducer;
