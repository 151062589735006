import { produce, Draft } from 'immer';
import {
  State,
  MetadataApiResponse,
  Group,
  Persona,
  Preview,
  FIRMOGRAPHICS_KEY,
  HOUSEHOLD_KEY,
  INDIVIDUAL_KEY,
} from './types';

export const MANAGE_IMPACT_PERSONA_METADATA_FETCH =
  'earned-impact/MANAGE_IMPACT_PERSONA_METADATA_FETCH';
export const MANAGE_IMPACT_PERSONA_METADATA_SUCCESS =
  'earned-impact/MANAGE_IMPACT_PERSONA_METADATA_SUCCESS';
export const MANAGE_IMPACT_PERSONA_METADATA_ERROR =
  'earned-impact/MANAGE_IMPACT_PERSONA_METADATA_ERROR';
export const MANAGE_IMPACT_PERSONA_PREVIEW_FETCH =
  'earned-impact/MANAGE_IMPACT_PERSONA_PREVIEW_FETCH';
export const MANAGE_IMPACT_PERSONA_PREVIEW_SUCCESS =
  'earned-impact/MANAGE_IMPACT_PERSONA_PREVIEW_SUCCESS';
export const MANAGE_IMPACT_PERSONA_PREVIEW_ERROR =
  'earned-impact/MANAGE_IMPACT_PERSONA_PREVIEW_ERROR';
export const MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_FETCH =
  'earned-impact/MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_FETCH';
export const MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_SUCCESS =
  'earned-impact/MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_SUCCESS';
export const MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_ERROR =
  'earned-impact/MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_ERROR';
export const IMPACT_PERSONAS_FETCH = 'earned-impact/IMPACT_PERSONAS_FETCH';
export const IMPACT_PERSONAS_SUCCESS = 'earned-impact/IMPACT_PERSONAS_SUCCESS';
export const IMPACT_PERSONAS_ERROR = 'earned-impact/IMPACT_PERSONAS_ERROR';
export const CLEAR_PERSONA_REDUCER = 'earned-impact/CLEAR_PERSONA_REDUCER';

interface FetchMetadataAction {
  type: typeof MANAGE_IMPACT_PERSONA_METADATA_FETCH;
}

interface FetchMetadataErrorAction {
  type: typeof MANAGE_IMPACT_PERSONA_METADATA_ERROR;
  payload: Error;
}

interface FetchMetadataSuccessAction {
  type: typeof MANAGE_IMPACT_PERSONA_METADATA_SUCCESS;
  payload: {
    metadata: MetadataApiResponse;
  };
}

interface FetchPersonaPreviewAction {
  type: typeof MANAGE_IMPACT_PERSONA_PREVIEW_FETCH;
}

interface FetchPersonaPreviewSuccessAction {
  type: typeof MANAGE_IMPACT_PERSONA_PREVIEW_SUCCESS;
  // TODO: update payload type
  payload: Preview;
}

interface FetchPersonaPreviewErrorAction {
  type: typeof MANAGE_IMPACT_PERSONA_PREVIEW_ERROR;
  payload: Error;
}

interface FetchConversionNamesAction {
  type: typeof MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_FETCH;
}

interface FetchConversionNamesSuccessAction {
  type: typeof MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_SUCCESS;
  payload: string[];
}

interface FetchConversionNamesErrorAction {
  type: typeof MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_ERROR;
  payload: Error;
}

interface FetchPersonasAction {
  type: typeof IMPACT_PERSONAS_FETCH;
}

interface FetchPersonasSuccessAction {
  type: typeof IMPACT_PERSONAS_SUCCESS;
  payload: Persona[];
}

interface FetchPersonasErrorAction {
  type: typeof IMPACT_PERSONAS_ERROR;
  payload: Error;
}

interface ClearPersonaReducerAction {
  type: typeof CLEAR_PERSONA_REDUCER;
}

type MetadataAction =
  | FetchMetadataAction
  | FetchMetadataSuccessAction
  | FetchMetadataErrorAction
  | FetchPersonaPreviewAction
  | FetchPersonaPreviewSuccessAction
  | FetchPersonaPreviewErrorAction
  | FetchConversionNamesAction
  | FetchConversionNamesSuccessAction
  | FetchConversionNamesErrorAction
  | FetchPersonasAction
  | FetchPersonasSuccessAction
  | FetchPersonasErrorAction
  | ClearPersonaReducerAction;

export function fetchMetadata(): FetchMetadataAction {
  return {
    type: MANAGE_IMPACT_PERSONA_METADATA_FETCH,
  };
}

export function fetchMetadataSuccess(payload: {
  metadata: MetadataApiResponse;
}): FetchMetadataSuccessAction {
  return {
    type: MANAGE_IMPACT_PERSONA_METADATA_SUCCESS,
    payload,
  };
}

export function fetchMetadataError(payload: Error): FetchMetadataErrorAction {
  return {
    type: MANAGE_IMPACT_PERSONA_METADATA_ERROR,
    payload,
  };
}

export function fetchPersonaPreview(): FetchPersonaPreviewAction {
  return {
    type: MANAGE_IMPACT_PERSONA_PREVIEW_FETCH,
  };
}

export function fetchPersonaPreviewSuccess(
  payload: Preview,
): FetchPersonaPreviewSuccessAction {
  return {
    type: MANAGE_IMPACT_PERSONA_PREVIEW_SUCCESS,
    payload,
  };
}

export function fetchPersonaPreviewError(
  payload: Error,
): FetchPersonaPreviewErrorAction {
  return {
    type: MANAGE_IMPACT_PERSONA_PREVIEW_ERROR,
    payload,
  };
}

export function fetchConversionNames(): FetchConversionNamesAction {
  return {
    type: MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_FETCH,
  };
}

export function fetchConversionNamesSuccess(
  payload: string[],
): FetchConversionNamesSuccessAction {
  return {
    type: MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_SUCCESS,
    payload,
  };
}

export function fetchConversionNamesError(
  payload: Error,
): FetchConversionNamesErrorAction {
  return {
    type: MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_ERROR,
    payload,
  };
}

export function fetchPersonas(): FetchPersonasAction {
  return {
    type: IMPACT_PERSONAS_FETCH,
  };
}

export function fetchPersonasSuccess(
  payload: Persona[],
): FetchPersonasSuccessAction {
  return {
    type: IMPACT_PERSONAS_SUCCESS,
    payload,
  };
}

export function fetchPersonasError(payload: Error): FetchPersonasErrorAction {
  return {
    type: IMPACT_PERSONAS_ERROR,
    payload,
  };
}

export function clearPersona(): ClearPersonaReducerAction {
  return {
    type: CLEAR_PERSONA_REDUCER,
  };
}

const getInitialState = (): State => ({
  personas: {
    data: undefined,
    loading: undefined,
    error: undefined,
  },
  manage: {
    individual: [],
    household: [],
    firmographics: [],
    loading: undefined,
    error: undefined,
  },
  preview: {
    data: undefined,
    loading: false,
    error: false,
  },
  conversionNames: {
    data: undefined,
    loading: undefined,
    error: undefined,
  },
});

const mapResults = (metadata: MetadataApiResponse) => {
  const data = metadata.reduce((obj, { friendlyType, groups }) => {
    obj[friendlyType] = groups;
    return obj;
  }, {});

  const map = (groups: Group[]) =>
    groups
      .map(
        ({
          friendlyGroup,
          categories,
          friendlyGroupOrder,
          ...otherParams
        }) => ({
          ...otherParams,
          friendlyGroup,
          friendlyGroupOrder,
          categories: categories.sort(
            (prev, curr) => prev.friendlyOrder - curr.friendlyOrder,
          ),
        }),
      )
      .sort((prev, curr) => prev.friendlyGroupOrder - curr.friendlyGroupOrder);

  const firmographics = map(data[FIRMOGRAPHICS_KEY]);
  const household = map(data[HOUSEHOLD_KEY]);
  const individual = map(data[INDIVIDUAL_KEY]);

  return { firmographics, household, individual };
};

const personaReducer = produce(
  (draft: Draft<State>, action: MetadataAction) => {
    switch (action.type) {
      case MANAGE_IMPACT_PERSONA_METADATA_FETCH: {
        draft.manage.loading = true;
        draft.manage.error = false;
        break;
      }
      case MANAGE_IMPACT_PERSONA_METADATA_SUCCESS: {
        const { metadata } = action.payload;
        const { firmographics, household, individual } = mapResults(
          metadata as MetadataApiResponse,
        );
        draft.manage.individual = individual;
        draft.manage.household = household;
        draft.manage.firmographics = firmographics;
        draft.manage.loading = false;
        draft.manage.error = false;
        break;
      }
      case MANAGE_IMPACT_PERSONA_METADATA_ERROR: {
        draft.manage.loading = false;
        draft.manage.error = true;
        break;
      }
      case MANAGE_IMPACT_PERSONA_PREVIEW_FETCH: {
        draft.preview.loading = true;
        draft.preview.error = false;
        break;
      }
      case MANAGE_IMPACT_PERSONA_PREVIEW_SUCCESS: {
        draft.preview.loading = false;
        draft.preview.data = action.payload;
        break;
      }
      case MANAGE_IMPACT_PERSONA_PREVIEW_ERROR: {
        draft.preview.loading = false;
        draft.preview.error = Boolean(action.payload);
        break;
      }
      case MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_FETCH: {
        draft.conversionNames.loading = true;
        draft.conversionNames.error = null;
        break;
      }
      case MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_SUCCESS: {
        draft.conversionNames.loading = false;
        draft.conversionNames.data = action.payload;
        break;
      }
      case MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_ERROR: {
        draft.conversionNames.loading = false;
        draft.conversionNames.error = Boolean(action.payload);
        break;
      }
      case IMPACT_PERSONAS_FETCH: {
        draft.personas.loading = true;
        draft.personas.error = undefined;
        break;
      }
      case IMPACT_PERSONAS_SUCCESS: {
        draft.personas.data = action.payload;
        draft.personas.loading = false;
        draft.personas.error = undefined;
        break;
      }
      case IMPACT_PERSONAS_ERROR: {
        draft.personas.loading = false;
        draft.personas.error = true;
        break;
      }
      case CLEAR_PERSONA_REDUCER: {
        // We don't want to mutate the state, we want to replace it instead
        return getInitialState();
      }
    }
  },
  getInitialState(),
);

export default personaReducer;
