import React from 'react';

import { Dropdown, Icon } from '@cision/rover-ui';
import { useIntl } from 'react-intl';

import PermissionOption from 'components/admin/importContactList/components/ListPreferencesStep/PermissionOption';
import TranslatedMessage from 'i18n/TranslatedMessage';

import sharePermissionMessages from '../share-list-modal/ShareSavedListPermissionModal.messages';

import styles from './BulkSavedListsModal.module.css';

import { permissionOptions } from './permissionOptions';

type PermissionOptionSelectionProps = {
  dropdownClassModifier?: string;
  dropdownRef: React.MutableRefObject<HTMLDivElement | null> | null;
  dropdownWrapperClass?: string;
  isDropdownOpen: boolean;
  listPermission: string;
  openDropdown: () => void;
  selectOption: (a: string) => void;
};

const PermissionOptionSelection = ({
  dropdownClassModifier = '',
  dropdownRef = null,
  dropdownWrapperClass = '',
  isDropdownOpen = false,
  listPermission = '',
  openDropdown = () => {},
  selectOption = () => {},
}: PermissionOptionSelectionProps) => {
  const intl = useIntl();

  return (
    <>
      <div>
        <h3 className="tk-type tk-type--color-normal tk-type--size-md tk-type--weight-normal">
          <TranslatedMessage {...sharePermissionMessages.permissionLabel} />
        </h3>
        <div ref={dropdownRef} className={dropdownWrapperClass}>
          <button
            className={styles.dropdownTitle}
            data-qa="mx-0WgRQc9C1twZzA_l1z"
            onClick={openDropdown}
          >
            <TranslatedMessage {...permissionOptions[listPermission].label} />
            <Icon name="chevron-down" className={styles.chevronDown} />
          </button>
          <Dropdown
            className={`${styles.dropdown} ${dropdownClassModifier}`}
            isOpen={isDropdownOpen}
          >
            <Dropdown.Menu className={styles.dropdownMenu}>
              {Object.keys(permissionOptions).map(selectedOption => {
                const permissionOption =
                  permissionOptions[selectedOption].listPermission;
                const label = intl.formatMessage(
                  permissionOptions[selectedOption].label,
                );

                return (
                  <PermissionOption
                    classNameModifier={styles.permissionOption}
                    dataQA={permissionOptions[selectedOption].dataQA}
                    key={permissionOption}
                    label={label}
                    onSelect={() => selectOption(permissionOption)}
                    permission={permissionOption}
                    selected={listPermission === permissionOption}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export { PermissionOptionSelection };
