import React, { Component } from 'react';

import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';
import { Control, Errors, LocalForm, actions, track } from 'react-redux-form';

import shortid from 'shortid';

import {
  Body,
  Button,
  ErrorWithIntl,
  globalMessages as tkUiMessages,
  Input,
  SvgIcon,
} from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';
import {
  validators,
  backlinksPropTypes,
  errorMessageI18nKeys,
} from '../story/templates/default-template';

// i18n
import messages from './EditStoryBacklinks.messages';

const getNewBacklinkModel = () => {
  return {
    id: shortid.generate(),
    text: '',
    url: '',
  };
};

const formModel = 'storyBacklinksForm';

const BacklinksError = props => <ErrorWithIntl block {...props} />;

class EditStoryBacklinks extends Component {
  static baseClass = 'tk-backlinks';

  static getDerivedStateFromProps(props, state) {
    if (props.backlinks !== state.propBacklinks) {
      let backlinks = props.backlinks;
      if (props.backlinks.length === 0) {
        backlinks = [getNewBacklinkModel()];
      }
      return {
        backlinks,
        propBacklinks: props.backlinks,
      };
    }

    return null;
  }

  static hasOneBacklinkThatsEmpty = backlinks =>
    backlinks.length === 1 && !backlinks[0].text && !backlinks[0].url;

  state = {
    isValid: false,
    isEditing: false,
    backlinks: [],
    propBacklinks: [],
  };

  onDiscard = () => {
    this.setState({ isEditing: false });
    this.props.onEndEditing();
  };

  onSubmit = form => {
    const backlinks = form.backlinks
      .filter(b => !!b.url)
      .map(b => {
        let url = b.url;
        if (!/^http(s)?/.test(b.url)) {
          url = `http://${b.url}`;
        }

        return {
          ...b,
          url,
        };
      });
    this.props.dispatchMergeFormAction('storyContentForm', { backlinks });
    this.setState({ isEditing: false });
    this.props.onEndEditing();
  };

  onClickSubmitButton = () => {
    const { backlinks } = this.state;

    if (EditStoryBacklinks.hasOneBacklinkThatsEmpty(backlinks)) {
      this.removeBacklink(backlinks[0], 0);
    }

    this.dispatch(actions.submit(formModel));
  };

  startEditing() {
    this.setState({ isEditing: true });
  }

  addBacklink = () => {
    this.dispatch(
      actions.push(`${formModel}.backlinks`, getNewBacklinkModel()),
    );
  };

  clearBacklink = backlink => {
    this.dispatch(
      actions.change(
        track(`${formModel}.backlinks[].url`, { id: backlink.id }),
        '',
      ),
    );
    this.dispatch(
      actions.change(
        track(`${formModel}.backlinks[].text`, { id: backlink.id }),
        '',
      ),
    );
  };

  removeBacklink = (backlink, index) => {
    this.dispatch(
      actions.setValidity(
        track(`${formModel}.backlinks[].url`, { id: backlink.id }),
        true,
      ),
    );
    this.dispatch(actions.remove(`${formModel}.backlinks`, index));
  };

  backlinkFormChanged = newModel => {
    this.setState({ backlinks: [...newModel.backlinks] });
  };

  backlinkFormUpdated = newValue => {
    this.setState({ isValid: newValue.$form.valid });
  };

  render() {
    const { backlinks, isValid, isEditing } = this.state;
    const { intl } = this.props;

    return (
      <StorySection>
        <StorySectionHeader label={intl.formatMessage(messages.header)} />
        <div className={EditStoryBacklinks.baseClass}>
          {isEditing && (
            <LocalForm
              component="div"
              model={formModel}
              initialState={{ backlinks }}
              onUpdate={this.backlinkFormUpdated}
              onChange={this.backlinkFormChanged}
              onSubmit={this.onSubmit}
              getDispatch={d => (this.dispatch = d)}
            >
              {backlinks &&
                backlinks.map((b, i) => (
                  <div
                    key={b.id}
                    className={`${EditStoryBacklinks.baseClass}__row`}
                  >
                    <div className={`${EditStoryBacklinks.baseClass}__header`}>
                      <div className={`${EditStoryBacklinks.baseClass}__title`}>
                        <Body color="light">
                          <T
                            {...messages.backlinkIndex}
                            values={{ NUMBER: i + 1 }}
                          />
                        </Body>
                      </div>
                      <div
                        className={`${EditStoryBacklinks.baseClass}__actions`}
                      >
                        {(b.url || b.text) && (
                          <span
                            className={`${EditStoryBacklinks.baseClass}__clear`}
                          >
                            <Button
                              data-qa="L5WM7yNRnk5U0Yjy_nrbp"
                              modifiers={['seethrough']}
                              onClick={() => this.clearBacklink(b)}
                            >
                              <T {...tkUiMessages.clear} />
                            </Button>
                          </span>
                        )}
                        {backlinks.length > 1 && (
                          <span
                            className={`${EditStoryBacklinks.baseClass}__remove`}
                          >
                            <Button
                              data-qa="e66n6rwnM8kHhnRuJb9LA"
                              modifiers={['seethrough']}
                              onClick={() => this.removeBacklink(b, i)}
                            >
                              <T {...tkUiMessages.remove} />
                            </Button>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={`${EditStoryBacklinks.baseClass}__fields`}>
                      <div className={`${EditStoryBacklinks.baseClass}__link`}>
                        <Control.text
                          component={Input}
                          placeholder="http://"
                          id={`tkStoryBacklinksLink${i}`}
                          model={track('.backlinks[].url', { id: b.id })}
                          validators={validators.backlinks.url}
                          persist
                        />
                        <Errors
                          className="errors"
                          component={BacklinksError}
                          model={track('.backlinks[].url', { id: b.id })}
                          messages={errorMessageI18nKeys.backlinks.url}
                          show="touched"
                        />
                      </div>
                      <div className={`${EditStoryBacklinks.baseClass}__text`}>
                        <Control.text
                          id={`tkStoryBacklinksText${i}`}
                          placeholder={intl.formatMessage(
                            messages.anchorTextPlaceholder,
                          )}
                          component={Input}
                          model={track('.backlinks[].text', { id: b.id })}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </LocalForm>
          )}
          <div className={`${EditStoryBacklinks.baseClass}__add`}>
            <Button
              data-qa="izt0mW_wDwKEzti6jLC9g"
              modifiers={['calltoaction', 'inline-flex']}
              onClick={this.addBacklink}
              active
            >
              <SvgIcon icon="plus" width={20} height={20} />
              <span className={`${EditStoryBacklinks.baseClass}__button-text`}>
                <T {...messages.addAnotherLink} />
              </span>
            </Button>
          </div>
          <div className={`${EditStoryBacklinks.baseClass}__submit-buttons`}>
            <div className={`${EditStoryBacklinks.baseClass}__cancel`}>
              <Button
                data-qa="Ogfa9ZsqVmZBgkGg0Avm7"
                modifiers={['round', 'tertiary', 'block']}
                onClick={this.onDiscard}
              >
                <T {...tkUiMessages.discard} />
              </Button>
            </div>
            <div className={`${EditStoryBacklinks.baseClass}__submit`}>
              <Button
                data-qa="NSJ_uSfDT1cKmvdJxFp-r"
                disabled={
                  !(
                    isValid ||
                    EditStoryBacklinks.hasOneBacklinkThatsEmpty(backlinks)
                  )
                }
                modifiers={['round', 'primary', 'block']}
                onClick={() => {
                  this.onClickSubmitButton();
                }}
              >
                <T {...tkUiMessages.save} />
              </Button>
            </div>
          </div>
        </div>
      </StorySection>
    );
  }
}

EditStoryBacklinks.propTypes = {
  // Looks like we're consuming backlinks in a way eslint doesn't pick up
  backlinks: backlinksPropTypes, // eslint-disable-line react/no-unused-prop-types
  onEndEditing: PropTypes.func,
  dispatchMergeFormAction: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

EditStoryBacklinks.defaultProps = {
  backlinks: [],
  onEndEditing: () => {},
  dispatchMergeFormAction: () => {},
};

export default EditStoryBacklinks;
