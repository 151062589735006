import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Kite } from '@trendkite/ui';

import OutreachErrorPageKites from 'components/outreach/OutreachErrorPageKites';
import {
  removePageMessageActionCreator,
  performMessageActionActionCreator,
} from 'reducers/page-messages';
import { allPageMessages } from 'selectors/page-messages';

const mapStateToProps = state => ({
  messages: allPageMessages(state),
});

const mapDispatchToProps = dispatch => ({
  removePageMessage: message =>
    dispatch(removePageMessageActionCreator(message)),
  performMessageAction: message =>
    dispatch(performMessageActionActionCreator(message)),
});

const PageKites = ({ messages, performMessageAction, removePageMessage }) => (
  <div className={PageKites.baseClass}>
    {messages.map(m => {
      function handleCancel() {
        if (m.onCancel) {
          m.onCancel();
        }
        removePageMessage(m.id);
      }
      if (m.text) {
        return (
          <Kite
            {...m}
            action={() => performMessageAction(m)}
            onCancel={m.isCancelable ? handleCancel : null}
            key={m.id}
          >
            {m.text}
            {m.ContentComponent && <m.ContentComponent {...m.contentData} />}
          </Kite>
        );
      }
    })}
    <OutreachErrorPageKites />
  </div>
);

PageKites.baseClass = 'kites';

PageKites.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      ...Kite.propTypes,
    }),
  ).isRequired,
  performMessageAction: PropTypes.func.isRequired,
  removePageMessage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageKites);
