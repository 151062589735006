import React from 'react';

import PropTypes from 'prop-types';

import withModifiers from '../withModifiers';

const FormGroup = ({ children, className = '' }) => (
  <div className={`${FormGroup.baseClass} ${className}`}>{children}</div>
);

FormGroup.baseClass = 'tk-form-group';

FormGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withModifiers(FormGroup);
