/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';

import T from 'i18n/TranslatedMessage';
import moment from 'moment';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Button, SvgIcon, Loader, Modal } from '@trendkite/ui';

import warningIcon from '../../../app/src/assets/warning.svg';

import {
  ASSETS_GALLERY_TYPES,
  BROWSE_ASSET_LIBRARY_SORT_TYPES,
  BROWSE_ASSET_LIBRARY_TABS,
  BROWSE_ASSET_LIBRARY_NAME_VALIDATION,
  BROWSE_ASSET_LIBRARY_TAGS,
  BROWSE_DOWNLOADABLE_ASSETS_KEYWORD,
  BROWSE_ASSET_FROM_SPOKESPERSON_KEYWORD,
  DISCLAIMER_LINK,
  DISCLAIMER_LOCAL_SESSION,
  ASSET_INCOMPLETE_UPLOAD_STATUS,
  ASSET_TIME_INTERVAL_TO_TRIGGER_API,
  VALID_ASSET_TYPES_FOR_STORY_KIT_FROM_EMAIL,
} from '../../../app/src/constants/constants';

import { addPageMessageWithDefaultTimeout } from '../../../app/src/reducers/page-messages';
import {
  getMyAssetsListActionDispactcher,
  getAssetsUploadingActionDispactcher,
  getEditAssetActionDispactcher,
  getDeletedAssetsActionDispactcher,
  getToggleAssetFlagActionDispatcher,
  getToggleAssetEditAndInsertActionDispatcher,
  getToggleAssetEditActionDispatcher,
  getToggleFullAssetsDataActionDispatcher,
  getAssetsInfoApiActionDispactcher,
  getToggleAssetInfoApiCaldActionDispactcher,
  getAssetsUploadedFromUploadComputerDispactcher,
  getAssetsUploadedFromUploadUrlDispactcher,
  getBase64FromFileDispatcher,
  toggleAssetFileFromBase64ReceivedDispatcher,
} from '../../../app/src/reducers/stories/story-browse-modal-upload';

import messages from './assets-library-modals.messages';
import styles from './browseModuleUpload.module.css';
import MediaBrowseAssetsFromComputer from './MediaBrowseAssetsFromComputer';
import MediaBrowseAssetsFromUrl from './MediaBrowseAssetsFromUrl';
import MediaBrowseAssetsPanel from './MediaBrowseAssetsPanel';
import MediaPopupLeftPanel from './MediaPopupLeftPanel';
import MediaRightPanel from './MediaRightPanel';
import MediaScreenForEditAsset from './MediaScreenForEditAsset';

const mapStateToProps = state => ({
  fullAssetsData: state.stories.storyBrowseModalUpload.fullAssetsData,
  fullAssetsDataCheck: state.stories.storyBrowseModalUpload.fullAssetsDataCheck,
  mediaToken: state.stories.storyBrowseModalUpload.mediaToken,
  mediaUrl: state.stories.storyBrowseModalUpload.mediaUrl,
  isLoading: state.stories.storyBrowseModalUpload.isLoading,
  successToast: state.stories.storyBrowseModalUpload.successToast,
  warningToast: state.stories.storyBrowseModalUpload.warningToast,
  assetUploaded: state.stories.storyBrowseModalUpload.assetUploaded,
  assetEdited: state.stories.storyBrowseModalUpload.assetEdited,
  assetDeleted: state.stories.storyBrowseModalUpload.assetDeleted,
  editPageFlag: state.stories.storyBrowseModalUpload.editPageFlag,
  assetEditAndInsert: state.stories.storyBrowseModalUpload.assetEditAndInsert,
  doHaveAssetsList: state.stories.storyBrowseModalUpload.doHaveAssetsList,
  assetsInfoApiCalled: state.stories.storyBrowseModalUpload.assetsInfoApiCalled,
  assetInfoDetails: state.stories.storyBrowseModalUpload.assetInfoDetails,
  assetFileDetailsFromBase64:
    state.stories.storyBrowseModalUpload.assetFileDetailsFromBase64,
  assetFileFromBase64Received:
    state.stories.storyBrowseModalUpload.assetFileFromBase64Received,
});

const mapDispatchToProps = dispatch => ({
  getAssetsCollection: isLoader =>
    dispatch(getMyAssetsListActionDispactcher(isLoader)),
  addPageMessageWithDefaultTimeout: message =>
    dispatch(addPageMessageWithDefaultTimeout(message)),
  getAssetsUploaded: data =>
    dispatch(getAssetsUploadingActionDispactcher(data)),
  getAssetsUploadedFromUploadComputer: data =>
    dispatch(getAssetsUploadedFromUploadComputerDispactcher(data)),
  getAssetsUploadedFromUploadUrl: url =>
    dispatch(getAssetsUploadedFromUploadUrlDispactcher(url)),
  editAsset: (data, isInsert) =>
    dispatch(getEditAssetActionDispactcher(data, isInsert)),
  deleteAssetFromLibrary: (data, isEditPageFlag) =>
    dispatch(getDeletedAssetsActionDispactcher(data, isEditPageFlag)),
  toggleAssetDeleteFlag: () => dispatch(getToggleAssetFlagActionDispatcher()),
  toggleAssetEditAndInsertFlag: () =>
    dispatch(getToggleAssetEditAndInsertActionDispatcher()),
  toggleAssetEditFlag: () => dispatch(getToggleAssetEditActionDispatcher()),
  toggleFullAssetsDataCheck: () =>
    dispatch(getToggleFullAssetsDataActionDispatcher()),
  getAssetsInfoApi: data => dispatch(getAssetsInfoApiActionDispactcher(data)),
  toggleAssetInfoApiCald: () =>
    dispatch(getToggleAssetInfoApiCaldActionDispactcher()),
  getBase64FromFile: data => dispatch(getBase64FromFileDispatcher(data)),
  toggleAssetFileFromBase64Received: () =>
    dispatch(toggleAssetFileFromBase64ReceivedDispatcher()),
});

class BrowseModalUpload extends Component {
  state = {
    modalIsOpen: false,
    isScreenForFileUpload: true,
    isScreenForBrowseFilesFromList: false,
    showEditDiv: false,
    isEditScreenForAsset: false,
    activeTab: 1,
    currentMediaSection: [],
    mediaAllJson: [],
    mediaImageJson: [],
    mediaVideoJson: [],
    mediaDocJson: [],
    searchText: '',
    sortStatus: 'recent',
    clickedAssetId: null,
    editAssetDetails: [],
    editAssetName: '',
    editAssetCaption: '',
    files: [],
    selectedAsset: {},
    isLoading: false,
    downloadableAssetFiles: [],
    downloadableAssetIDs: [],
    uploadFromComputerFiles: [],
    count: 0,
    warningText: '',
    imgGroup: ASSETS_GALLERY_TYPES.imgGroup,
    videoGroup: ASSETS_GALLERY_TYPES.videoGroup,
    docGroup: ASSETS_GALLERY_TYPES.docGroup,
    mediaLinkClicked: false,
    mediaLinkUploadFromComputer: false,
    mediaLinkUploafFromUrl: false,
    deletedAsset: {},
    disclaimerFlag: true,
    dragOver: false,
    showDeleteConfirmationModalForSingleFile: false,
    showUploadOptionsFromEmail: false,
    checkboxForUploadFromComputer: true,
    checkboxForUploadFromUrl: true,
    imageUrlsForUploadScreen: [],
    setImageUrl: '',
    disclaimerFlagFromUploadScreens: false,
    showRestUploadOptionsFromLink: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
      showEditDiv: false,
      successToast: false,
      warningToast: false,
      downloadableAssetFiles: [],
      downloadableAssetIDs: [],
      activeTab: 1,
      count: 0,
      isScreenForFileUpload: true,
      isScreenForBrowseFilesFromList: false,
      isEditScreenForAsset: false,
      mediaLinkClicked: true,
      showRestUploadOptionsFromLink: false,
      mediaLinkUploadFromComputer: false,
      mediaLinkUploadFromUrl: false,
    });
    const flag = window.localStorage.getItem(
      DISCLAIMER_LOCAL_SESSION.DISCLAIMER_OPTION,
    );
    if (flag) {
      this.setState({ disclaimerFlag: flag ? JSON.parse(flag) : true });
    } else {
      this.setState({ disclaimerFlag: flag ? JSON.parse(flag) : true });
    }
    this.props.getAssetsCollection(true);
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      mediaLinkClicked: false,
      mediaLinkUploadFromComputer: false,
      mediaLinkUploadFromUrl: false,
    });
    if (this.props.browseAssetComponent) {
      this.props.makeBrowseAssetComponentFalse();
    }
  };

  handleMediaLinkClick = () => {
    this.setState({
      mediaLinkClicked: true,
      mediaLinkUploadFromComputer: false,
      mediaLinkUploadFromUrl: false,
      isScreenForBrowseFilesFromList: true,
    });
    this.props.getAssetsCollection(true);
  };

  handleMediaUploadFromComputer = () => {
    this.setState({
      mediaLinkClicked: false,
      mediaLinkUploadFromComputer: true,
      mediaLinkUploadFromUrl: false,
      isScreenForFileUpload: false,
      isScreenForBrowseFilesFromList: false,
      isEditScreenForAsset: false,
      uploadFilesCollection: [],
    });
  };

  handleMediaUploadFromUrl = () => {
    this.setState({
      mediaLinkClicked: false,
      mediaLinkUploadFromComputer: false,
      mediaLinkUploadFromUrl: true,
      isScreenForFileUpload: false,
      isScreenForBrowseFilesFromList: false,
      isEditScreenForAsset: false,
      imageUrlsForUploadScreen: [],
      setImageUrl: '',
    });
  };

  componentDidUpdate = prevProps => {
    const data = this.props.fullAssetsData;
    const doNotHaveAssetsList = !this.props.doHaveAssetsList;
    if (
      prevProps.fullAssetsData !== this.props.fullAssetsData &&
      this.props.fullAssetsData.loadedData &&
      this.props.fullAssetsDataCheck &&
      this.state.modalIsOpen
    ) {
      this.setState({
        isScreenForBrowseFilesFromList:
          doNotHaveAssetsList && !this.state.isEditScreenForAsset,
        mediaAllJson: data.ALL_ASSETS ? data.ALL_ASSETS : [],
        mediaImageJson: data.IMG ? data.IMG : [],
        mediaVideoJson: data.VID ? data.VID : [],
        mediaDocJson: data.DOC ? data.DOC : [],
        mediaToken: this.props.mediaToken,
        mediaUrl: this.props.mediaUrl,
        currentMediaSection: this.state.showUploadOptionsFromEmail
          ? data.IMG
          : data.ALL_ASSETS,
        isEditScreenForAsset:
          doNotHaveAssetsList && this.state.isEditScreenForAsset,
        isScreenForFileUpload: !doNotHaveAssetsList,
      });
      this.assetStatusCheck(data.ALL_ASSETS);
    }
    if (
      prevProps.assetsInfoApiCalled !== this.props.assetsInfoApiCalled &&
      this.props.assetsInfoApiCalled
    ) {
      const data = this.props.assetInfoDetails
        ? this.props.assetInfoDetails.data
        : [];
      const mainData = data.assets ? data.assets : [];
      const allAssets = [...this.state.mediaAllJson];
      mainData.filter(item => {
        const selectedAsset = allAssets.find(i => i.id === item.id);
        if (selectedAsset) {
          selectedAsset.uploadStatus = item.uploadStatus;
          selectedAsset.url = item.url;
          if (ASSETS_GALLERY_TYPES.imgGroup.includes(selectedAsset.extension)) {
            this.editImagefromCollection(selectedAsset);
          }
          if (
            ASSETS_GALLERY_TYPES.videoGroup.includes(selectedAsset.extension)
          ) {
            this.editVideofromCollection(selectedAsset);
          }
          if (ASSETS_GALLERY_TYPES.docGroup.includes(selectedAsset.extension)) {
            this.editDocsfromCollection(selectedAsset);
          }
        }
      });

      this.setState({
        mediaAllJson: allAssets,
        currentMediaSection: allAssets,
      });
      this.assetStatusCheck(allAssets);
    }

    if (
      prevProps.assetUploaded !== this.props.assetUploaded &&
      this.props.assetUploaded &&
      this.state.modalIsOpen
    ) {
      this.props.getAssetsCollection(true);
    }

    if (prevProps.assetEdited !== this.props.assetEdited) {
      const allAssets = [...this.state.mediaAllJson];
      const { id, name, caption, extension } = this.state.editAssetDetails;
      const assetToUpdate = allAssets.find(a => a.id === id);
      if (assetToUpdate) {
        assetToUpdate.name = name;
        assetToUpdate.caption = caption;
        if (ASSETS_GALLERY_TYPES.imgGroup.includes(extension)) {
          this.editImagefromCollection(this.state.editAssetDetails);
        }
        if (ASSETS_GALLERY_TYPES.videoGroup.includes(extension)) {
          this.editVideofromCollection(this.state.editAssetDetails);
        }
        if (ASSETS_GALLERY_TYPES.docGroup.includes(extension)) {
          this.editDocsfromCollection(this.state.editAssetDetails);
        }
      }
      this.clickBackBtn();
      this.setState({
        mediaAllJson: allAssets,
      });
      this.props.toggleAssetEditFlag();
    }

    if (prevProps.assetDeleted !== this.props.assetDeleted) {
      const { extension, id } = this.state.deletedAsset;
      this.props.toggleAssetDeleteFlag();
      if (ASSETS_GALLERY_TYPES.imgGroup.includes(extension)) {
        this.removeImagefromCollection(id);
      }
      if (ASSETS_GALLERY_TYPES.videoGroup.includes(extension)) {
        this.removeVideofromCollection(id);
      }
      if (ASSETS_GALLERY_TYPES.docGroup.includes(extension)) {
        this.removeDocsfromCollection(id);
      }
    }
    if (prevProps.editPageFlag !== this.props.editPageFlag) {
      this.setState({
        isScreenForFileUpload: false,
        isScreenForBrowseFilesFromList: true,
        isEditScreenForAsset: false,
      });
    }
    if (prevProps.assetEditAndInsert !== this.props.assetEditAndInsert) {
      this.handleSavefromLibraryPage();
      this.props.toggleAssetEditAndInsertFlag();
    }
    if (
      prevProps.assetFileFromBase64Received !==
        this.props.assetFileFromBase64Received &&
      this.props.assetFileFromBase64Received &&
      this.state.modalIsOpen
    ) {
      const selectedAssetList = [this.props.assetFileDetailsFromBase64];
      if (this.props.isFromStoryKit) {
        this.props.onSelectFiles(selectedAssetList);
      } else if (this.props.isFromDownloadable) {
        this.props.handleSelectedFilesfromPopUp(selectedAssetList, '');
      } else if (this.props.isFromSpokePersonWidget) {
        this.props.handleSelectedFilesfromPopUp(
          this.props.personId,
          selectedAssetList,
        );
      } else {
        this.props.onConfirmFromEmail(this.props.assetFileDetailsFromBase64);
      }
      this.closeModal();
      this.props.toggleAssetFileFromBase64Received();
    }
  };

  assetStatusCheck = data => {
    if (data) {
      this.props.toggleFullAssetsDataCheck();
      this.props.toggleAssetInfoApiCald();
      const allAssets = data;
      const assetsWithIncompleteUploadStatus = [];
      const now = moment();
      allAssets.filter(i => {
        const duration = moment.duration(now.diff(i.uploadedDate));
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        if (
          days === 0 &&
          hours === 0 &&
          minutes <= ASSET_TIME_INTERVAL_TO_TRIGGER_API &&
          ASSET_INCOMPLETE_UPLOAD_STATUS.includes(i.uploadStatus)
        ) {
          assetsWithIncompleteUploadStatus.push(i.id);
        }
      });
      if (assetsWithIncompleteUploadStatus.length > 0) {
        this.props.getAssetsInfoApi(assetsWithIncompleteUploadStatus);
      } else {
        this.props.toggleAssetInfoApiCald();
      }
    }
  };

  editImagefromCollection = details => {
    const imageCollection = [...this.state.mediaImageJson];
    const assetToUpdate = imageCollection.find(a => a.id === details.id);
    if (assetToUpdate) {
      assetToUpdate.name = details.name;
      assetToUpdate.caption = details.caption;
      assetToUpdate.url = details.url;
      assetToUpdate.uploadStatus = details.uploadStatus;
    }
  };
  editVideofromCollection = details => {
    const videoCollection = [...this.state.mediaVideoJson];
    const assetToUpdate = videoCollection.find(a => a.id === details.id);
    if (assetToUpdate) {
      assetToUpdate.name = details.name;
      assetToUpdate.caption = details.caption;
      assetToUpdate.url = details.url;
      assetToUpdate.uploadStatus = details.uploadStatus;
    }
  };
  editDocsfromCollection = details => {
    const docsCollection = [...this.state.mediaDocJson];
    const assetToUpdate = docsCollection.find(a => a.id === details.id);
    if (assetToUpdate) {
      assetToUpdate.name = details.name;
      assetToUpdate.caption = details.caption;
      assetToUpdate.url = details.url;
      assetToUpdate.uploadStatus = details.uploadStatus;
    }
  };

  removeImagefromCollection = id => {
    const imageCollection = [...this.state.mediaImageJson];
    const imageCollectionAfterDelete = imageCollection.filter(
      item => item.id !== id,
    );
    const newTotalCollection = imageCollectionAfterDelete
      .concat(this.state.mediaVideoJson)
      .concat(this.state.mediaDocJson);

    this.setState({
      mediaImageJson: imageCollectionAfterDelete,
      mediaAllJson: newTotalCollection,
      currentMediaSection:
        this.state.activeTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_ALL
          ? newTotalCollection
          : imageCollectionAfterDelete,
    });
  };

  removeVideofromCollection = id => {
    const videoCollection = [...this.state.mediaVideoJson];
    const videoCollectionAfterDelete = videoCollection.filter(
      item => item.id !== id,
    );
    const newTotalCollection = videoCollectionAfterDelete
      .concat(this.state.mediaImageJson)
      .concat(this.state.mediaDocJson);

    this.setState({
      mediaVideoJson: videoCollectionAfterDelete,
      mediaAllJson: newTotalCollection,
      currentMediaSection:
        this.state.activeTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_ALL
          ? newTotalCollection
          : videoCollectionAfterDelete,
    });
  };

  removeDocsfromCollection = id => {
    const docsCollection = [...this.state.mediaDocJson];
    const docsCollectionAfterDelete = docsCollection.filter(
      item => item.id !== id,
    );
    const newTotalCollection = docsCollectionAfterDelete
      .concat(this.state.mediaImageJson)
      .concat(this.state.mediaVideoJson);

    this.setState({
      mediaDocJson: docsCollectionAfterDelete,
      mediaAllJson: newTotalCollection,
      currentMediaSection:
        this.state.activeTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_ALL
          ? newTotalCollection
          : docsCollectionAfterDelete,
    });
  };

  tabClicks = val => {
    const currentTab = parseInt(val);
    this.setState({ activeTab: currentTab });
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_ALL) {
      const mediaContent = [...this.state.mediaAllJson];
      this.setState({ currentMediaSection: mediaContent });
    }
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_IMAGES) {
      const mediaContent = [...this.state.mediaImageJson];
      this.setState({ currentMediaSection: mediaContent });
    }
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_VIDEOS) {
      const mediaContent = [...this.state.mediaVideoJson];
      this.setState({ currentMediaSection: mediaContent });
    }
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_DOCS) {
      const mediaContent = [...this.state.mediaDocJson];
      this.setState({ currentMediaSection: mediaContent });
    }
  };

  sort = val => {
    let data;
    if (this.state.showUploadOptionsFromEmail) {
      data = [...this.state.mediaImageJson];
    } else {
      data = [...this.state.currentMediaSection];
    }
    if (val === BROWSE_ASSET_LIBRARY_SORT_TYPES.RECENT) {
      const sorted = data.sort((a, b) => {
        return new Date(b.uploadedDate) - new Date(a.uploadedDate);
      });
      this.setState({
        currentMediaSection: sorted,
        sortStatus: val,
      });
    } else if (val === BROWSE_ASSET_LIBRARY_SORT_TYPES.ALPHABETICAL) {
      const sorted = data.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0;
      });
      this.setState({
        currentMediaSection: sorted,
        sortStatus: val,
      });
    }
  };

  handleChange = event => {
    const val = event.target.value;
    this.onSearchText(val);
  };

  onSearchText = val => {
    const data = this.getData(this.state.activeTab);
    const searchResult = data.filter(item => {
      const searchVal = item.name.toUpperCase();
      const searchCaptionVal = item.caption ? item.caption.toUpperCase() : '';
      const receivedVal = val.toUpperCase();
      if (searchVal !== '') {
        if (searchVal.search(receivedVal) !== -1) {
          return item;
        } else if (
          searchCaptionVal !== '' &&
          searchCaptionVal.search(receivedVal) !== -1
        ) {
          return item;
        } else {
          return null;
        }
      }
    });
    this.setState({ currentMediaSection: searchResult });
    if (val === '' || val === null || val === undefined) {
      if (this.state.showUploadOptionsFromEmail) {
        this.tabClicks(BROWSE_ASSET_LIBRARY_TABS.MEDIA_IMAGES);
      } else {
        this.tabClicks(this.state.activeTab);
      }
    }
  };

  getData = index => {
    let currentTab;
    if (this.state.showUploadOptionsFromEmail) {
      currentTab = BROWSE_ASSET_LIBRARY_TABS.MEDIA_IMAGES;
    } else {
      currentTab = parseInt(index);
    }
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_ALL) {
      const mediaContent = [...this.state.mediaAllJson];
      return mediaContent;
    }
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_IMAGES) {
      const mediaContent = [...this.state.mediaImageJson];
      return mediaContent;
    }
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_VIDEOS) {
      const mediaContent = [...this.state.mediaVideoJson];
      return mediaContent;
    }
    if (currentTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_DOCS) {
      const mediaContent = [...this.state.mediaDocJson];
      return mediaContent;
    }
  };

  assetClick = id => {
    this.setState({
      showEditDiv: true,
      clickedAssetId: id,
    });
  };

  static getDerivedStateFromProps(props, state) {
    const disclaimerFlagReceived = window.localStorage.getItem(
      DISCLAIMER_LOCAL_SESSION.DISCLAIMER_OPTION,
    );
    if (props.browseAssetComponent && state.modalIsOpen === false) {
      return {
        isScreenForBrowseFilesFromList: BrowseModalUpload.getAssetsList(props),
        showUploadOptionsFromEmail: props.isFromEmail,
        mediaLinkClicked: true,
        modalIsOpen: true,
        disclaimerFlag:
          disclaimerFlagReceived !== null
            ? JSON.parse(disclaimerFlagReceived)
            : true,
      };
    }

    return null;
  }

  static getAssetsList(props) {
    props.getAssetsCollection(true);
  }
  handleEditClick = data => {
    const assetsData = [...this.state.currentMediaSection];
    const selectedAsset = assetsData.find(i => i.id === data.id);
    this.setState({
      editAssetDetails: selectedAsset,
      isEditScreenForAsset: true,
      isScreenForBrowseFilesFromList: false,
    });
  };

  clickBackBtn = () => {
    this.setState({
      isEditScreenForAsset: false,
      successToast: false,
      isScreenForBrowseFilesFromList: true,
      isScreenForFileUpload: false,
    });
  };

  toggleDragStyleChange = action => {
    if (action === 'over') {
      this.setState({
        dragOver: true,
      });
    } else {
      this.setState({
        dragOver: false,
      });
    }
  };

  handleChangeForFileUpload = event => {
    let uploadFiles;
    if (event.type === 'drop') {
      uploadFiles = Object.keys(event.dataTransfer.files).map(
        key => event.dataTransfer.files[key],
      );
    } else {
      uploadFiles = Object.keys(event.target.files).map(
        key => event.target.files[key],
      );
    }
    if (uploadFiles) {
      this.props.getAssetsUploaded(uploadFiles);
    }
    this.setState({
      dragOver: false,
    });
  };

  editAssetName = target => {
    const regex = BROWSE_ASSET_LIBRARY_NAME_VALIDATION.NAME_REGEX;
    if (target.value.search(regex) === -1) {
      return false;
    } else {
      this.setState(prevState => ({
        editAssetDetails: {
          ...prevState.editAssetDetails,
          name: target.value,
        },
      }));
    }
  };

  editAssetCaption = target => {
    this.setState(prevState => ({
      editAssetDetails: {
        ...prevState.editAssetDetails,
        caption: target.value,
      },
    }));
  };

  saveAssetFromEdit = data => {
    this.props.editAsset(data, false);
  };

  handleDeleteClick = data => {
    this.setState({
      deletedAsset: data,
      showDeleteConfirmationModalForSingleFile: true,
    });
  };

  onCancelConfirmationModalForSingleFile = () => {
    this.setState({ showDeleteConfirmationModalForSingleFile: false });
  };
  onConfirmConfirmationModalForSingleFile = isFromEdit => {
    this.props.deleteAssetFromLibrary(this.state.deletedAsset, !!isFromEdit);
    this.setState({ showDeleteConfirmationModalForSingleFile: false });
  };

  handleDeleteClickFromEditPage = data => {
    this.setState({
      deletedAsset: data,
      showDeleteConfirmationModalForSingleFile: true,
    });
  };

  handleSelectedAsset = (data, elem) => {
    if (this.props.isFromDownloadable) {
      const assetsCollection = this.state.downloadableAssetFiles;
      const assetsIDCollection = this.state.downloadableAssetIDs;
      const found = assetsCollection.find(el => el.id === data.id);
      if (!found && elem.target.checked === true) {
        assetsCollection.push(data);
        assetsIDCollection.push(data.id);
        this.setState({
          downloadableAssetFiles: assetsCollection,
          count: assetsCollection.length,
          downloadableAssetIDs: assetsIDCollection,
        });
      } else if (found && elem.target.checked === false) {
        const arrayAfterIteration = assetsCollection.filter(
          item => item.id !== data.id,
        );
        const idsAfterIteration = assetsIDCollection.filter(
          id => id !== data.id,
        );
        this.setState({
          downloadableAssetFiles: arrayAfterIteration,
          count: arrayAfterIteration.length,
          downloadableAssetIDs: idsAfterIteration,
        });
      }
    } else {
      this.setState({ selectedAsset: data, count: 1 });
    }
  };

  handleSaveFromLibraryPageInEmailFlow = () => {
    const selectedAssetList = this.state.selectedAsset;
    if (selectedAssetList) {
      this.props.getBase64FromFile(selectedAssetList);
    }
  };

  handleSavefromLibraryPage = () => {
    const downloadableAssetFilesList = [...this.state.downloadableAssetFiles];
    const selectedAssetList = this.state.selectedAsset;
    if (this.props.isFromDownloadable) {
      if (downloadableAssetFilesList.length === 0) {
        return;
      }
      this.props.handleSelectedFilesfromPopUp(
        downloadableAssetFilesList,
        BROWSE_DOWNLOADABLE_ASSETS_KEYWORD.DOWNLOAD_KEYWORD,
      );
      this.closeModal();
    } else if (
      selectedAssetList.fileType === BROWSE_ASSET_LIBRARY_TAGS.FOR_DOCS ||
      selectedAssetList.fileType === BROWSE_ASSET_LIBRARY_TAGS.FOR_VIDEOS
    ) {
      this.props.addPageMessageWithDefaultTimeout({
        title: 'Error',
        text: <T {...messages.toastForShowingImageAssetsForHeroAndLogo} />,
        status: 'danger',
      });
      return false;
    } else if (this.props.isFromSpokePersonWidget) {
      this.props.handleSelectedFilesfromPopUp(
        this.props.personId,
        selectedAssetList,
        BROWSE_ASSET_FROM_SPOKESPERSON_KEYWORD.SPOKE_KEYWORD,
      );
      this.closeModal();
    } else {
      this.props.handleSelectedFilesfromPopUp(selectedAssetList);
      this.closeModal();
    }
  };

  handleSaveAndInsertfromEditPage = () => {
    const data = this.state.editAssetDetails;
    if (
      data.fileType === BROWSE_ASSET_LIBRARY_TAGS.FOR_DOCS ||
      data.fileType === BROWSE_ASSET_LIBRARY_TAGS.FOR_VIDEOS
    ) {
      this.props.addPageMessageWithDefaultTimeout({
        title: 'Error',
        text: <T {...messages.toastForShowingImageAssetsForHeroAndLogo} />,
        status: 'danger',
      });
      return false;
    }
    if (this.props.isFromDownloadable) {
      const downloadList = [];
      downloadList.push(data);
      this.setState({ downloadableAssetFiles: downloadList });
      this.props.editAsset(data, true);
      this.closeModal();
    } else {
      this.setState({ selectedAsset: data });
      this.props.editAsset(data, true);
    }
  };

  closeDisclaimerOption = () => {
    const flag = localStorage.setItem(
      DISCLAIMER_LOCAL_SESSION.DISCLAIMER_OPTION,
      false,
    );
    this.setState({
      disclaimerFlag: flag,
      disclaimerFlagFromUploadScreens: false,
    });
  };

  onBrowseFileUploadFromComputer = event => {
    let uploadFiles;
    if (event.type === 'drop') {
      const fileType = event.dataTransfer.files
        ? event.dataTransfer.files[0].type.split('/')[1]
        : '';
      if (VALID_ASSET_TYPES_FOR_STORY_KIT_FROM_EMAIL.includes(fileType)) {
        uploadFiles = Object.keys(event.dataTransfer.files).map(
          key => event.dataTransfer.files[key],
        );
      } else {
        this.props.addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: (
            <T
              {...messages.toastForShowingValidFileTypeForStoryKitFromEmailForDragAndDrop}
            />
          ),
          status: 'danger',
        });
        return false;
      }
    } else {
      uploadFiles = Object.keys(event.target.files).map(
        key => event.target.files[key],
      );
    }
    const uploadFilesCollection = [...this.state.uploadFilesCollection];
    if (uploadFiles) {
      uploadFiles.forEach((file, index) => {
        const objectUrl = URL.createObjectURL(file);
        const fileObj = {
          id: index,
          file: file,
          name: file.name,
          size: file.size,
          type: file.type.split('/')[1],
          url: objectUrl,
          checked: true,
        };
        uploadFilesCollection.push(fileObj);
      });
    }
    this.setState({ uploadFromComputerFiles: uploadFilesCollection });
  };

  toggleMsCheckboxForUploadFromComputer = () =>
    this.setState({
      checkboxForUploadFromComputer: !this.state.checkboxForUploadFromComputer,
    });

  toggleMsCheckboxForUploadFromUrl = () =>
    this.setState({
      checkboxForUploadFromUrl: !this.state.checkboxForUploadFromUrl,
    });

  handleSaveFromUploadPages = () => {
    if (this.state.mediaLinkUploadFromComputer) {
      const uploadRawFilesCollection = [...this.state.uploadFromComputerFiles];
      if (uploadRawFilesCollection.length > 0) {
        uploadRawFilesCollection.forEach(data => {
          if (data.checked) {
            const selectedAssetList = [data.file];
            if (this.props.isFromStoryKit) {
              this.props.onSelectFiles(selectedAssetList);
              this.closeModal();
            } else if (this.props.isFromDownloadable) {
              this.props.handleSelectedFilesfromPopUp(selectedAssetList, '');
              this.closeModal();
            } else if (this.props.isFromSpokePersonWidget) {
              this.props.handleSelectedFilesfromPopUp(
                this.props.personId,
                selectedAssetList,
              );
              this.closeModal();
            } else {
              this.props.onConfirmFromEmail(data.file);
            }
          }
        });
      } else {
        return false;
      }
      if (this.state.checkboxForUploadFromComputer) {
        this.props.getAssetsUploadedFromUploadComputer(
          uploadRawFilesCollection,
        );
      }
    } else if (this.state.mediaLinkUploadFromUrl) {
      const imageUrlsCollection = [...this.state.imageUrlsForUploadScreen];
      if (imageUrlsCollection.length > 0) {
        if (
          this.props.isFromStoryKit ||
          this.props.isFromDownloadable ||
          this.props.isFromSpokePersonWidget
        ) {
          const assetUrlData = { url: imageUrlsCollection[0].url };
          this.props.getBase64FromFile(assetUrlData);
        } else {
          this.props.onConfirmFromEmail(null, imageUrlsCollection[0].url, '');
        }
        if (this.state.checkboxForUploadFromUrl) {
          this.props.getAssetsUploadedFromUploadUrl(imageUrlsCollection[0].url);
        }
      }
    }
  };

  handleCancelFromUploadPages = () => {
    this.closeModal();
    this.props.onCancelFromEmail();
  };

  assetsCheckBoxClickFromComputer = (id, elem) => {
    const uploadFromComputerFiles = this.state.uploadFromComputerFiles.map(
      file => {
        if (file.id === id) {
          file.checked = elem.target.checked;
        }
        return file;
      },
    );
    this.setState({ uploadFromComputerFiles });
  };

  handleChangeForInput = value => {
    this.setState({ setImageUrl: value, isLoading: true });
  };

  getImageFromUrl = () => {
    const imageUrlsCollection = [...this.state.imageUrlsForUploadScreen];
    if (this.state.setImageUrl) {
      const fileObj = {
        id: new Date().getTime(),
        url: this.state.setImageUrl,
        checked: true,
        deleted: false,
      };
      imageUrlsCollection.push(fileObj);
      this.setState({
        imageUrlsForUploadScreen: imageUrlsCollection,
        setImageUrl: '',
        isLoading: false,
      });
    }
  };

  deleteAssetFromUrlScreen = id => {
    const imageUrlsForUploadScreen = this.state.imageUrlsForUploadScreen.filter(
      item => item.id !== id,
    );
    this.setState({ imageUrlsForUploadScreen });
  };

  toggleDisclaimerDiv = () => {
    this.setState({
      disclaimerFlagFromUploadScreens: true,
    });
  };

  onImageLoadError = () => {
    this.setState({ imageUrlsForUploadScreen: [], isLoading: false });
    this.props.addPageMessageWithDefaultTimeout({
      title: 'Error',
      text: <T {...messages.toastForCorruptedImageForStoryKitFromEmail} />,
      status: 'danger',
    });
  };

  render() {
    const {
      browseAssetComponent,
      isLoading,
      mediaUrl,
      mediaToken,
      successToast,
      warningToast,
      isFromDownloadable,
      isFromSpokePersonWidget,
      isFromEmail,
      showOtherUploadOptions,
    } = this.props;
    const {
      modalIsOpen,
      isScreenForFileUpload,
      isScreenForBrowseFilesFromList,
      isEditScreenForAsset,
      count,
      disclaimerFlag,
      mediaLinkUploadFromComputer,
      mediaLinkUploadFromUrl,
      uploadFromComputerFiles,
      checkboxForUploadFromComputer,
      checkboxForUploadFromUrl,
      imageUrlsForUploadScreen,
      setImageUrl,
      disclaimerFlagFromUploadScreens,
    } = this.state;
    return (
      <div>
        {!browseAssetComponent && (
          <div
            data-qa="4_6j18KtQgz9QxM91nRYN"
            onClick={this.openModal}
            className={styles.browseLinkDiv}
          >
            {isFromSpokePersonWidget && (
              <React.Fragment>
                <SvgIcon
                  icon="image"
                  width={20}
                  height={20}
                  className={styles.browseIcon}
                />
                <span className={styles.browseLibraryText}>
                  <T {...messages.browseAssetLibraryLinkForSpokePerson} />
                </span>
              </React.Fragment>
            )}
            {!isFromSpokePersonWidget && (
              <React.Fragment>
                <SvgIcon
                  icon="image"
                  width={20}
                  height={20}
                  className={styles.browseIcon}
                />
                <span className={styles.browseLibraryText}>
                  <T {...messages.browseAssetLibraryLink} />
                </span>
              </React.Fragment>
            )}
          </div>
        )}
        <Modal toggle={this.closeModal} isOpen={modalIsOpen} size="lg">
          <Modal.Header>
            {' '}
            <T {...messages.browsePopupTitle} />
            <SvgIcon
              data-qa="decRkH2Z1kTK4B6M4mr-C"
              width={20}
              height={20}
              onClick={this.closeModal}
              icon="cross"
              className={styles.closeBtn}
            />
          </Modal.Header>
          <Modal.Body style={{ padding: '0' }}>
            <div
              className={styles.row}
              style={{ display: 'flex', margin: '0' }}
            >
              <MediaPopupLeftPanel
                data={this.state}
                onMediaLinkClick={this.handleMediaLinkClick}
                onMediaFromComputer={this.handleMediaUploadFromComputer}
                onMediaFromUrl={this.handleMediaUploadFromUrl}
                onChange={this.handleChangeForFileUpload}
                showOtherUploadOptions={showOtherUploadOptions}
              />
              {isScreenForFileUpload &&
                !isScreenForBrowseFilesFromList &&
                !mediaLinkUploadFromComputer &&
                !mediaLinkUploadFromUrl &&
                !isEditScreenForAsset && (
                  <React.Fragment>
                    <MediaRightPanel
                      data={this.state}
                      onChange={this.handleChangeForFileUpload}
                      onChangeHandler={this.handleChangeForFileUpload}
                    />
                  </React.Fragment>
                )}

              {isScreenForBrowseFilesFromList &&
                !isScreenForFileUpload &&
                !mediaLinkUploadFromComputer &&
                !mediaLinkUploadFromUrl &&
                !isEditScreenForAsset && (
                  <React.Fragment>
                    <MediaBrowseAssetsPanel
                      data={this.state}
                      mediaUrl={mediaUrl}
                      isLoading={isLoading}
                      mediaToken={mediaToken}
                      onSearchText={this.onSearchText}
                      handleChange={this.handleChange}
                      successToast={successToast}
                      warningToast={warningToast}
                      handleEditClick={this.handleEditClick}
                      handleDeleteClick={this.handleDeleteClick}
                      assetClick={this.assetClick}
                      sort={this.sort}
                      tabClicks={this.tabClicks}
                      handleSelectedAsset={this.handleSelectedAsset}
                      isFromDownloadable={isFromDownloadable}
                      onChangeHandler={this.handleChangeForFileUpload}
                      toggleDragStyleChange={this.toggleDragStyleChange}
                      onCancelConfirmationModalForSingleFile={
                        this.onCancelConfirmationModalForSingleFile
                      }
                      onConfirmConfirmationModalForSingleFile={
                        this.onConfirmConfirmationModalForSingleFile
                      }
                    />
                  </React.Fragment>
                )}
              {isEditScreenForAsset &&
                !isScreenForBrowseFilesFromList &&
                !mediaLinkUploadFromComputer &&
                !mediaLinkUploadFromUrl &&
                !isScreenForFileUpload && (
                  <React.Fragment>
                    <MediaScreenForEditAsset
                      data={this.state}
                      mediaUrl={mediaUrl}
                      mediaToken={mediaToken}
                      isLoading={isLoading}
                      clickBackBtn={this.clickBackBtn}
                      editAssetName={this.editAssetName}
                      editAssetCaption={this.editAssetCaption}
                      saveAssetFromEdit={this.saveAssetFromEdit}
                      handleDeleteClick={this.handleDeleteClickFromEditPage}
                      onCancelConfirmationModalForSingleFile={
                        this.onCancelConfirmationModalForSingleFile
                      }
                      onConfirmConfirmationModalForSingleFile={
                        this.onConfirmConfirmationModalForSingleFile
                      }
                    />
                  </React.Fragment>
                )}
              {mediaLinkUploadFromComputer && (
                <React.Fragment>
                  <MediaBrowseAssetsFromComputer
                    uploadFromComputerFiles={uploadFromComputerFiles}
                    checkboxForUploadFromComputer={
                      checkboxForUploadFromComputer
                    }
                    onBrowseFileUploadFromComputer={
                      this.onBrowseFileUploadFromComputer
                    }
                    onBrowseFileUploadFromComputerDragAndDrop={
                      this.onBrowseFileUploadFromComputer
                    }
                    toggleMsCheckboxForUploadFromComputer={
                      this.toggleMsCheckboxForUploadFromComputer
                    }
                    assetsCheckBoxClickFromComputer={
                      this.assetsCheckBoxClickFromComputer
                    }
                    toggleDisclaimerDiv={this.toggleDisclaimerDiv}
                  />
                </React.Fragment>
              )}
              {mediaLinkUploadFromUrl && (
                <React.Fragment>
                  <MediaBrowseAssetsFromUrl
                    setImageUrl={setImageUrl}
                    imageUrlsForUploadScreen={imageUrlsForUploadScreen}
                    checkboxForUploadFromUrl={checkboxForUploadFromUrl}
                    toggleMsCheckboxForUploadFromUrl={
                      this.toggleMsCheckboxForUploadFromUrl
                    }
                    handleChangeForInput={this.handleChangeForInput}
                    getImageFromUrl={this.getImageFromUrl}
                    deleteAssetFromUrlScreen={this.deleteAssetFromUrlScreen}
                    toggleDisclaimerDiv={this.toggleDisclaimerDiv}
                    onImageLoadError={this.onImageLoadError}
                  />
                </React.Fragment>
              )}
            </div>
          </Modal.Body>
          {isLoading && isScreenForFileUpload && (
            <div className={styles.modalLoader}>
              <Loader />
            </div>
          )}
          <Modal.Footer style={{ padding: '0' }}>
            <div className={styles.screenFileUpload}>
              {(disclaimerFlag || disclaimerFlagFromUploadScreens) && (
                <div className={styles.disclaimerText}>
                  <div className={styles.disclaimerWarningIcon}>
                    <svg className={styles.warningIcon}>
                      <use xlinkHref={`#${warningIcon.id}`} />
                    </svg>
                  </div>
                  <div className={styles.disclaimerTextMessage}>
                    <T {...messages.disclaimerTextForLibrary} />{' '}
                    <a
                      href={DISCLAIMER_LINK}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <T {...messages.disclaimerLink} />
                    </a>
                  </div>
                  <div
                    data-qa="2331_E7cAbDg4g1fjZhOO"
                    className={styles.disclaimerCloseIcon}
                    onClick={this.closeDisclaimerOption}
                  >
                    <SvgIcon icon="cross" width={14} height={14} />{' '}
                    <T {...messages.dismissMessageForLibrary} />
                  </div>
                </div>
              )}
            </div>
            {isScreenForBrowseFilesFromList &&
              !isScreenForFileUpload &&
              !mediaLinkUploadFromComputer &&
              !isFromEmail &&
              !mediaLinkUploadFromUrl &&
              !isEditScreenForAsset && (
                <Button
                  data-qa="aV511SVmWePXUWZDqeC0o"
                  disabled={count === 0}
                  modifiers={['round', 'primary']}
                  style={{ margin: '10px 20px 10px 0' }}
                  onClick={this.handleSavefromLibraryPage}
                >
                  {count > 1 && (
                    <T
                      {...messages.insertButtonForLibraryForDownloadable}
                      values={{ NUM_ASSETS: count }}
                    />
                  )}
                  {(count === 0 || count === 1) && (
                    <T
                      {...messages.insertButtonForLibrary}
                      values={{ NUM_ASSETS: count }}
                    />
                  )}
                </Button>
              )}
            {isEditScreenForAsset &&
              !isScreenForBrowseFilesFromList &&
              !mediaLinkUploadFromComputer &&
              !isFromEmail &&
              !mediaLinkUploadFromUrl &&
              !isScreenForFileUpload && (
                <Button
                  data-qa="9pU00vCzKBZBfm4cv4eUy"
                  modifiers={['round', 'primary']}
                  style={{ margin: '10px 20px 10px 0' }}
                  onClick={this.handleSaveAndInsertfromEditPage}
                >
                  <T {...messages.saveAndInsert} />
                </Button>
              )}
            {(mediaLinkUploadFromComputer || mediaLinkUploadFromUrl) && (
              <span>
                <Button
                  data-qa="9nQPU7iAucXZZWQxwW1lm"
                  modifiers={['round', 'secondary']}
                  style={{ margin: '10px 20px 10px 0' }}
                  onClick={this.handleCancelFromUploadPages}
                >
                  <T {...messages.cancelButtonForUploadScreens} />
                </Button>
                <Button
                  data-qa="cryZmenUsPdoq3ahKdIve"
                  modifiers={['round', 'primary']}
                  style={{ margin: '10px 20px 10px 0' }}
                  onClick={this.handleSaveFromUploadPages}
                >
                  <T {...messages.insertButtonForUploadScreens} />
                </Button>
              </span>
            )}
            {isScreenForBrowseFilesFromList &&
              isFromEmail &&
              !mediaLinkUploadFromComputer &&
              !mediaLinkUploadFromUrl && (
                <span>
                  <Button
                    data-qa="pHFyLvmBr2Y8gM_W8i-Pj"
                    disabled={count === 0}
                    modifiers={['round', 'primary']}
                    style={{ margin: '10px 20px 10px 0' }}
                    onClick={this.handleSaveFromLibraryPageInEmailFlow}
                  >
                    <T {...messages.insertButtonForUploadScreens} />
                  </Button>
                </span>
              )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

BrowseModalUpload.propTypes = {
  browseAssetComponent: PropTypes.bool,
  isFromEmail: PropTypes.bool,
  addPageMessageWithDefaultTimeout: PropTypes.func,
  makeBrowseAssetComponentFalse: PropTypes.func,
  onConfirmFromEmail: PropTypes.func,
  onCancelFromEmail: PropTypes.func,
  getAssetsCollection: PropTypes.func,
  getAssetsInfoApi: PropTypes.func,
  getAssetsUploaded: PropTypes.func,
  getAssetsUploadedFromUploadComputer: PropTypes.func,
  getAssetsUploadedFromUploadUrl: PropTypes.func,
  deleteAssetFromLibrary: PropTypes.func,
  toggleAssetDeleteFlag: PropTypes.func,
  toggleAssetEditFlag: PropTypes.func,
  toggleAssetEditAndInsertFlag: PropTypes.func,
  toggleAssetFileFromBase64Received: PropTypes.func,
  handleSelectedFilesfromPopUp: PropTypes.func,
  onSelectFiles: PropTypes.func,
  toggleFullAssetsDataCheck: PropTypes.func,
  toggleAssetInfoApiCald: PropTypes.func,
  getBase64FromFile: PropTypes.func,
  editAsset: PropTypes.func,
  fullAssetsData: PropTypes.object,
  assetInfoDetails: PropTypes.object,
  assetFileDetailsFromBase64: PropTypes.object,
  mediaToken: PropTypes.string,
  mediaUrl: PropTypes.string,
  doHaveAssetsList: PropTypes.bool,
  successToast: PropTypes.bool,
  warningToast: PropTypes.bool,
  assetUploaded: PropTypes.bool,
  assetEdited: PropTypes.bool,
  fullAssetsDataCheck: PropTypes.bool,
  assetDeleted: PropTypes.bool,
  assetsInfoApiCalled: PropTypes.bool,
  editPageFlag: PropTypes.bool,
  assetEditAndInsert: PropTypes.bool,
  isLoading: PropTypes.bool,
  assetFileFromBase64Received: PropTypes.bool,
  isFromDownloadable: PropTypes.bool,
  showOtherUploadOptions: PropTypes.bool,
  isFromSpokePersonWidget: PropTypes.bool,
  personId: PropTypes.string,
  isFromStoryKit: PropTypes.bool,
};

BrowseModalUpload.defaultProps = {
  browseAssetComponent: false,
  isFromEmail: false,
  addPageMessageWithDefaultTimeout: () => {},
  makeBrowseAssetComponentFalse: () => {},
  onConfirmFromEmail: () => {},
  onCancelFromEmail: () => {},
  getAssetsCollection: () => {},
  getAssetsInfoApi: () => {},
  getAssetsUploaded: () => {},
  getAssetsUploadedFromUploadComputer: () => {},
  getAssetsUploadedFromUploadUrl: () => {},
  deleteAssetFromLibrary: () => {},
  toggleAssetDeleteFlag: () => {},
  toggleAssetEditAndInsertFlag: () => {},
  toggleAssetFileFromBase64Received: () => {},
  toggleAssetEditFlag: () => {},
  handleSelectedFilesfromPopUp: () => {},
  onSelectFiles: () => {},
  toggleFullAssetsDataCheck: () => {},
  toggleAssetInfoApiCald: () => {},
  getBase64FromFile: () => {},
  editAsset: () => {},
  fullAssetsData: {},
  assetInfoDetails: {},
  assetFileDetailsFromBase64: {},
  mediaToken: '',
  mediaUrl: '',
  successToast: false,
  assetUploaded: false,
  assetEdited: false,
  doHaveAssetsList: false,
  fullAssetsDataCheck: false,
  assetDeleted: false,
  assetsInfoApiCalled: false,
  editPageFlag: false,
  assetEditAndInsert: false,
  warningToast: false,
  isLoading: false,
  assetFileFromBase64Received: false,
  isFromDownloadable: false,
  showOtherUploadOptions: false,
  isFromSpokePersonWidget: false,
  personId: '',
  isFromStoryKit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseModalUpload);
