import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SvgIcon from '../svg-icon';
import { Body } from '../type';

const PerformanceMetricDelta = ({ delta, label, positive }) => {
  const isNotZero = number => {
    return number !== '0' && number !== '0%' && number !== 'No change';
  };

  if (!delta) {
    return null;
  }

  if (delta === '-') {
    // empty state for no comparison data yet
    return (
      <Body color="light" size="sm" block>
        <span className="delta-value">{delta} </span>
        <span className="delta-label">{label}</span>
      </Body>
    );
  }

  const comparisonBaseClass = 'performance-metric__comparison';
  const comparisonClassName = classNames({
    [`${comparisonBaseClass}`]: true,
    [`${comparisonBaseClass}--negative`]: !positive && isNotZero(delta),
    [`${comparisonBaseClass}--positive`]: positive && isNotZero(delta),
  });

  return (
    <Body color="light" size="sm" block>
      <span className={comparisonClassName}>
        {isNotZero(delta) && (
          <span className="delta-arrow">
            <SvgIcon
              height={10}
              width={10}
              icon={positive ? 'arrowUp' : 'arrowDown'}
            />
          </span>
        )}
        <span className="delta-value">{delta}</span>
      </span>
      <span className="delta-label">{label}</span>
    </Body>
  );
};

PerformanceMetricDelta.propTypes = {
  delta: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  positive: PropTypes.bool.isRequired,
};

export default PerformanceMetricDelta;
