const isRequired = val => !!val;

const contentValidators = {
  headline: { isRequired },
  pressContact: { isRequired },
  summary: { isRequired },
};

// mimics api for react-redux-forms validation
export default story => {
  const { content } = story;
  const result = { valid: true, errors: null };

  Object.keys(contentValidators).forEach(contentKey => {
    const validators = contentValidators[contentKey];
    Object.keys(validators).forEach(validatorKey => {
      const validator = validators[validatorKey];
      const value = content[contentKey];
      if (!validator(value)) {
        result.valid = false;
        result.errors = result.errors || {};
        result.errors[contentKey] = result.errors[contentKey] || {};
        result.errors[contentKey][validatorKey] = true;
      }
    });
  });

  return result;
};
