export default {
  heroImage: {
    id: 'STORY-HERO-PLACEHOLDER_HERO_IMAGE',
    defaultMessage: 'Hero Image',
    description:
      'Campaigns > Story Kit > Story Edit Page: content image placeholder text',
  },
  bestResultImageMessage: {
    id: 'STORY-HERO-PLACEHOLDER_BEST_IMAGE',
    defaultMessage:
      'For best results use horizontal images that are a minumum of 1440px wide',
    description:
      'Campaigns > Story Kit > Story Edit Page: image best dimensions description',
  },
  uploadImage: {
    id: 'STORY-HERO-PLACEHOLDER_UPLOAD_IMAGE',
    defaultMessage: 'Upload Image',
    description: 'Campaigns > Story Kit > Story Edit Page: upload image link',
  },
};
