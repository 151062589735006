import { useRef } from 'react';

/**
 * Returns the value from the previous render.
 */
function usePreviousValue(currentValue, initialValue) {
  const prevValueRef = useRef(initialValue);

  const prevValue = prevValueRef.current;
  prevValueRef.current = currentValue;

  return prevValue;
}

export default usePreviousValue;
