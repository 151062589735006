import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

export const videoHosts = {
  youtube: {
    key: 'YOUTUBE',
    label: 'YouTube',
    hostname: 'youtube.com',
    embedBaseURL: 'https://www.youtube.com/embed',
  },
  vimeo: {
    key: 'VIMEO',
    label: 'Vimeo',
    hostname: 'vimeo.com',
    embedBaseURL: 'https://player.vimeo.com/video',
  },
};

export const getEmbeddableLinkFromUrl = url => {
  let location;
  try {
    location = document.createElement('a');
    location.href = url;
  } catch (e) {
    return null;
  }

  if (location.hostname.indexOf(videoHosts.youtube.hostname) >= 0) {
    const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    return `${videoHosts.youtube.embedBaseURL}/${urlParams.v}`;
  } else if (location.hostname.indexOf(videoHosts.vimeo.hostname) >= 0) {
    const videoPath = location.pathname.split('/');
    return `${videoHosts.vimeo.embedBaseURL}/${videoPath[1]}`;
  }

  return null;
};

class EmbeddedVideo extends Component {
  static baseClass = 'tk-embedded-video';

  renderEmbed = (url, title) => {
    const embedUrl = getEmbeddableLinkFromUrl(url);
    return embedUrl ? (
      <iframe
        className={`${EmbeddedVideo.baseClass}__iframe`}
        src={embedUrl}
        title={title}
        frameBorder={0}
        showinfo={0}
        controls={0}
        autohide={0}
      />
    ) : null;
  };

  render() {
    const { title, url, hasHoverState } = this.props;
    const iframe = this.renderEmbed(url, title);
    return (
      <div className={`${EmbeddedVideo.baseClass}`}>
        <div className={`${EmbeddedVideo.baseClass}__embed`}>
          {iframe}
          {hasHoverState && (
            <div className={`${EmbeddedVideo.baseClass}__hover`} />
          )}
        </div>
      </div>
    );
  }
}

EmbeddedVideo.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  hasHoverState: PropTypes.bool,
};

EmbeddedVideo.defaultProps = {
  title: '',
  hasHoverState: true,
};

export default EmbeddedVideo;
