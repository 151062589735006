import React from 'react';

import T from 'i18n/TranslatedMessage';

import {
  VALID_ASSET_TYPES_FOR_STORY_KIT,
  MAXIMUM_ASSETS_IN_ONE_UPLOAD_SHOT,
} from '../../../../app/src/constants/constants';
import messages from '../../../../tk-stories/src/browse-asset-library/assets-library-modals.messages';
import {
  ASSET_POPUP_GET_LIST_ASSETS_ENDPOINT,
  ASSET_POPUP_IMAGE_UPLOAD_ENDPOINT,
  ASSET_POPUP_EDIT_SAVE_ASSET_ENDPOINT,
  ASSET_POPUP_DELETE_ASSET_ENDPOINT,
  ASSETS_INFO_ENDPOINT,
  ASSETS_BASE64_ENDPOINT,
} from '../../constants/apis';
import { addPageMessageWithDefaultTimeout } from '../../reducers/page-messages';
import {
  performGet,
  performPost,
  performPut,
  performDelete,
} from '../../services/rest-service/rest-service';

export const GET_ASSETS_LIST = 'stories/GET_ASSETS_LIST';
export const GET_ASSETS_LIST_ERROR = 'stories/GET_ASSETS_LIST_ERROR';
export const GET_ASSET_UPLOADED = 'stories/GETS_ASSET_UPLOADED';
export const GET_ASSET_EDIT_SUCCESSFULLY =
  'stories/GET_ASSET_EDIT_SUCCESSFULLY';
export const GET_ASSET_EDIT_SUCCESSFULLY_AND_INSERT =
  'stories/GET_ASSET_EDIT_SUCCESSFULLY_AND_INSERT';
export const GET_ASSET_DELETE_SUCCESSFULLY =
  'stories/GET_ASSET_DELETE_SUCCESSFULLY';
export const SHOW_LOADER = 'stories/SHOW_LOADER';
export const HIDE_LOADER = 'stories/HIDE_LOADER';
export const UPLOAD_VIA_URL_SUCCESS = 'stories/UPLOAD_VIA_URL_SUCCESS';
export const UPLOAD_VIA_URL_FAILURE = 'stories/UPLOAD_VIA_URL_FAILURE';
export const SHOW_ERROR_TOAST = 'stories/SHOW_ERROR_TOAST';
export const GET_ASSET_DELETE_SUCCESSFULLY_FROM_EDIT_PAGE =
  'stories/GET_ASSET_DELETE_SUCCESSFULLY_FROM_EDIT_PAGE';
export const GET_TOGGLE_ASSET_DELETE_FLAG =
  'stories/GET_TOGGLE_ASSET_DELETE_FLAG';
export const GET_TOGGLE_ASSET_EDIT_AND_SAVE_FLAG =
  'stories/GET_TOGGLE_ASSET_EDIT_AND_SAVE_FLAG';
export const GET_TOGGLE_ASSET_EDIT_FLAG = 'stories/GET_TOGGLE_ASSET_EDIT_FLAG';
export const GET_BULK_ASSET_DELETE_SUCCESSFULLY =
  'stories/GET_BULK_ASSET_DELETE_SUCCESSFULLY';
export const GET_TOGGLE_FULL_ASSETS_DATA_FLAG =
  'stories/GET_TOGGLE_FULL_ASSETS_DATA_FLAG';
export const GET_TOGGLE_FULL_ASSETS_DATA_SET_COMPLETE =
  'stories/GET_TOGGLE_FULL_ASSETS_DATA_SET_COMPLETE';
export const GET_ASSETS_INFO_API_RESPONSE =
  'stories/GET_ASSETS_INFO_API_RESPONSE';
export const GET_TOGGLE_ASSETS_INFO_API_CALD_FLAG =
  'stories/GET_TOGGLE_ASSETS_INFO_API_CALD_FLAG';
export const GET_FILE_FROM_BASE64_SUCCESSFULLY =
  'stories/GET_FILE_FROM_BASE64_SUCCESSFULLY';
export const GET_FILE_FROM_BASE64_TOGGLE =
  'stories/GET_FILE_FROM_BASE64_TOGGLE';

const initialState = {
  fullAssetsData: {
    loadedData: false,
    ALL_ASSETS: [],
    DOC: [],
    IMG: [],
    VID: [],
    largeimage_param: '',
    mediaimage_param: '',
    mediastudio_url: '',
    originalimage_param: '',
    thumbnail_param: '',
    token: '',
    video_thumbnail_url: '',
  },
  mediaToken: '',
  mediaUrl: '',
  doHaveAssetsList: true,
  isLoading: false,
  successToast: false,
  warningToast: false,
  assetUploaded: false,
  assetEdited: false,
  assetDeleted: false,
  editPageFlag: false,
  assetEditAndInsert: false,
  assetBulkDeleted: false,
  fullAssetsDataCheck: false,
  assetsInfoApiCalled: false,
  assetInfoDetails: null,
  assetFileDetailsFromBase64: null,
  assetFileFromBase64Received: false,
  uploadViaUrlSuccess: false,
};

const checkAnyAssets = allAssets => {
  if (allAssets.length > 0) {
    return false;
  }
  return true;
};

const browseModalUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSETS_LIST: {
      return {
        ...state,
        successToast: true,
        isLoading: false,
        fullAssetsDataCheck: true,
        fullAssetsData: {
          ...state.fullAssetsData,
          loadedData: true,
          ALL_ASSETS: action.payload.ALL_ASSETS,
          DOC: action.payload.DOC,
          IMG: action.payload.IMG,
          VID: action.payload.VID,
          largeimage_param: action.payload.largeimage_param,
          mediaimage_param: action.payload.mediaimage_param,
          mediastudio_url: action.payload.mediastudio_url,
          originalimage_param: action.payload.originalimage_param,
          thumbnail_param: action.payload.thumbnail_param,
          token: action.payload.token,
          video_thumbnail_url: action.payload.video_thumbnail_url,
        },
        doHaveAssetsList: checkAnyAssets(action.payload.ALL_ASSETS),
        mediaToken: action.payload.token,
        mediaUrl: action.payload.mediastudio_url,
        assetUploaded: false,
        assetBulkDeleted: false,
      };
    }
    case GET_ASSET_UPLOADED: {
      return {
        ...state,
        successToast: true,
        assetUploaded: true,
      };
    }
    case UPLOAD_VIA_URL_SUCCESS: {
      return {
        ...state,
        successToast: true,
        uploadViaUrlSuccess: true,
      };
    }
    case UPLOAD_VIA_URL_FAILURE: {
      return {
        ...state,
        successToast: true,
        uploadViaUrlSuccess: false,
      };
    }
    case GET_FILE_FROM_BASE64_SUCCESSFULLY: {
      return {
        ...state,
        assetFileFromBase64Received: true,
        assetFileDetailsFromBase64: action.payload,
      };
    }
    case GET_FILE_FROM_BASE64_TOGGLE: {
      return {
        ...state,
        assetFileFromBase64Received: false,
      };
    }
    case GET_TOGGLE_ASSETS_INFO_API_CALD_FLAG: {
      return {
        ...state,
        assetsInfoApiCalled: false,
      };
    }
    case GET_ASSETS_INFO_API_RESPONSE: {
      return {
        ...state,
        assetsInfoApiCalled: true,
        assetInfoDetails: action.payload,
      };
    }
    case GET_TOGGLE_ASSET_EDIT_FLAG: {
      return {
        ...state,
        assetEdited: false,
      };
    }
    case GET_ASSET_EDIT_SUCCESSFULLY: {
      return {
        ...state,
        successToast: true,
        assetEdited: true,
        assetEditAndInsert: false,
      };
    }
    case GET_TOGGLE_ASSET_EDIT_AND_SAVE_FLAG: {
      return {
        ...state,
        editPageFlag: false,
        assetEditAndInsert: false,
      };
    }
    case GET_ASSET_EDIT_SUCCESSFULLY_AND_INSERT: {
      return {
        ...state,
        successToast: true,
        editPageFlag: false,
        assetEditAndInsert: true,
      };
    }
    case GET_ASSET_DELETE_SUCCESSFULLY: {
      return {
        ...state,
        successToast: true,
        assetDeleted: true,
        editPageFlag: false,
      };
    }
    case GET_ASSET_DELETE_SUCCESSFULLY_FROM_EDIT_PAGE: {
      return {
        ...state,
        successToast: true,
        assetDeleted: true,
        editPageFlag: true,
      };
    }
    case GET_BULK_ASSET_DELETE_SUCCESSFULLY: {
      return {
        ...state,
        successToast: true,
        assetBulkDeleted: true,
      };
    }
    case GET_TOGGLE_ASSET_DELETE_FLAG: {
      return {
        ...state,
        assetDeleted: false,
        editPageFlag: false,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SHOW_ERROR_TOAST: {
      return {
        ...state,
        isLoading: false,
        warningToast: true,
      };
    }
    case GET_ASSETS_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        warningToast: true,
      };
    }
    case GET_TOGGLE_FULL_ASSETS_DATA_FLAG: {
      return {
        ...state,
        fullAssetsDataCheck: false,
      };
    }

    default:
      return state;
  }
};

export const getMyAssetsListActionDispactcher = isLoader => dispatch => {
  if (isLoader) {
    dispatch({ type: SHOW_LOADER });
  }
  performGet(ASSET_POPUP_GET_LIST_ASSETS_ENDPOINT)
    .then(response => {
      const payload = response.data;
      dispatch({
        type: GET_ASSETS_LIST,
        payload: {
          ...payload,
          data: payload.results,
        },
      });
      if (response.data.ALL_ASSETS.length === 0) {
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Error',
            text: <T {...messages.toastForNoAssetsFound} />,
            status: 'danger',
          }),
        );
      }
    })
    .catch(err => {
      dispatch({ type: GET_ASSETS_LIST_ERROR, payload: err });
      dispatch({ type: HIDE_LOADER });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: <T {...messages.toastForCommonErrorMessages} />,
          status: 'danger',
        }),
      );
    });
};

export const getAssetsInfoApiActionDispactcher = data => dispatch => {
  const postData = data;
  performPost(ASSETS_INFO_ENDPOINT, postData)
    .then(response => {
      const payload = response.data;
      dispatch({
        type: GET_ASSETS_INFO_API_RESPONSE,
        payload: {
          ...payload,
          data: payload,
        },
      });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR_TOAST, payload: err });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: <T {...messages.toastForCommonErrorMessages} />,
          status: 'danger',
        }),
      );
    });
};

export const getBase64FromFileDispatcher = data => (dispatch, getState) => {
  dispatch({ type: SHOW_LOADER });
  const state = getState();
  const storyData = state.stories.storyBrowseModalUpload;
  const assetUrl = data.name
    ? storyData.mediaUrl + data.url + storyData.mediaToken + '&p=medium'
    : data.url;
  const formData = new FormData();
  formData.append('assetUrl', assetUrl);
  performPost(ASSETS_BASE64_ENDPOINT, formData).then(response => {
    const responseValue = response.data;
    const str = atob(responseValue.base64Data);
    const bin = [...str].map(char => char.charCodeAt(0));
    const arr = new Uint8Array(bin);
    const { buffer } = arr;
    const fileName = data.name ? data.name : responseValue.name;
    const fileInput = new File([buffer], fileName, {
      type: responseValue.fileType,
    });
    dispatch({ type: GET_FILE_FROM_BASE64_SUCCESSFULLY, payload: fileInput });
    dispatch({ type: HIDE_LOADER });
  });
};

export const getToggleAssetInfoApiCaldActionDispactcher = () => dispatch => {
  dispatch({
    type: GET_TOGGLE_ASSETS_INFO_API_CALD_FLAG,
  });
};
export const getAssetsUploadedFromUploadUrlDispactcher = url => dispatch => {
  dispatch({ type: SHOW_LOADER });
  const formData = new FormData();
  formData.append('assetUrl', url);
  return performPost(ASSET_POPUP_IMAGE_UPLOAD_ENDPOINT, formData)
    .then(() => {
      dispatch({ type: HIDE_LOADER });
      dispatch({ type: UPLOAD_VIA_URL_SUCCESS });
    })
    .catch(err => {
      dispatch({ type: HIDE_LOADER });
      dispatch({ type: UPLOAD_VIA_URL_FAILURE });
      throw err;
    });
};

export const getAssetsUploadedFromUploadComputerDispactcher = dataFiles => dispatch => {
  dispatch({ type: SHOW_LOADER });
  const promises = dataFiles.map(data => {
    const formData = new FormData();
    formData.append('file', data.file);
    return performPost(ASSET_POPUP_IMAGE_UPLOAD_ENDPOINT, formData);
  });
  Promise.all(promises)
    .then(response => {
      dispatch({ type: HIDE_LOADER });
      dispatch({
        type: GET_ASSET_UPLOADED,
        payload: response,
      });
    })
    .catch(errors => {
      dispatch({ type: HIDE_LOADER });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: errors,
          status: 'danger',
        }),
      );
    });
};

export const getAssetsUploadingActionDispactcher = data => dispatch => {
  const newValidAssetsUploadArray = [];
  if (data.length <= MAXIMUM_ASSETS_IN_ONE_UPLOAD_SHOT) {
    data.forEach(dataItem => {
      const fileType = dataItem.type ? dataItem.type.split('/')[1] : '';
      if (VALID_ASSET_TYPES_FOR_STORY_KIT.includes(fileType)) {
        newValidAssetsUploadArray.push(dataItem);
      } else {
        const invalidFileName = dataItem ? dataItem.name : '';
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Error',
            text: (
              <T
                {...messages.toastForFailedUploadedAssets}
                values={{ fileName: invalidFileName }}
              />
            ),
            status: 'danger',
          }),
        );
      }
    });
  } else {
    dispatch(
      addPageMessageWithDefaultTimeout({
        title: 'Error',
        text: <T {...messages.toastForAssetsGreaterThanLimit} />,
        status: 'danger',
      }),
    );
  }
  if (newValidAssetsUploadArray.length > 0) {
    dispatch({ type: SHOW_LOADER });
    const promises = newValidAssetsUploadArray.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      return performPost(ASSET_POPUP_IMAGE_UPLOAD_ENDPOINT, formData);
    });
    Promise.all(promises)
      .then(response => {
        dispatch({
          type: GET_ASSET_UPLOADED,
          payload: response,
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Success',
            text: <T {...messages.toastForSuccessfullyUploadedAssets} />,
            status: 'success',
          }),
        );
      })
      .catch(err => {
        dispatch({ type: SHOW_ERROR_TOAST, payload: err });
        dispatch({ type: HIDE_LOADER });
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Error',
            text: <T {...messages.toastForCommonErrorMessages} />,
            status: 'danger',
          }),
        );
      });
  }
};

export const getEditAssetActionDispactcher = (
  data,
  isInsertFlag,
) => dispatch => {
  dispatch({ type: SHOW_LOADER });
  const putData = { name: data.name, caption: data.caption };
  performPut(`${ASSET_POPUP_EDIT_SAVE_ASSET_ENDPOINT}${data.id}`, putData)
    .then(response => {
      const payload = response.data;
      if (isInsertFlag) {
        dispatch({
          type: GET_ASSET_EDIT_SUCCESSFULLY_AND_INSERT,
          payload: {
            ...payload,
            data: payload.results,
          },
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Success',
            text: <T {...messages.toastForSuccessfullyEditedAssets} />,
            status: 'success',
          }),
        );
      } else {
        dispatch({
          type: GET_ASSET_EDIT_SUCCESSFULLY,
          payload: {
            ...payload,
            data: payload.results,
          },
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Success',
            text: <T {...messages.toastForSuccessfullyEditedAssets} />,
            status: 'success',
          }),
        );
      }
      dispatch({ type: HIDE_LOADER });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR_TOAST, payload: err });
      dispatch({ type: HIDE_LOADER });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: <T {...messages.toastForCommonErrorMessages} />,
          status: 'danger',
        }),
      );
    });
};

export const getDeletedAssetsActionDispactcher = (
  data,
  isEditPageFlag,
) => dispatch => {
  dispatch({ type: SHOW_LOADER });
  performDelete(`${ASSET_POPUP_DELETE_ASSET_ENDPOINT}${data.id}`)
    .then(response => {
      const payload = response.data;
      if (isEditPageFlag) {
        dispatch({
          type: GET_ASSET_DELETE_SUCCESSFULLY_FROM_EDIT_PAGE,
          payload: {
            ...payload,
            data: payload.results,
          },
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Success',
            text: <T {...messages.toastForSuccessfullyDeletingAssets} />,
            status: 'success',
          }),
        );
        dispatch({ type: HIDE_LOADER });
      } else {
        dispatch({
          type: GET_ASSET_DELETE_SUCCESSFULLY,
          payload: {
            ...payload,
            data: payload.results,
          },
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            title: 'Success',
            text: <T {...messages.toastForSuccessfullyDeletingAssets} />,
            status: 'success',
          }),
        );
        dispatch({ type: HIDE_LOADER });
      }
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR_TOAST, payload: err });
      dispatch({ type: HIDE_LOADER });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: <T {...messages.toastForCommonErrorMessages} />,
          status: 'danger',
        }),
      );
    });
};

export const getBulkDeletedAssetsActionDispactcher = data => dispatch => {
  dispatch({ type: SHOW_LOADER });
  const promises = data.map(id => {
    return performDelete(`${ASSET_POPUP_DELETE_ASSET_ENDPOINT}${id}`);
  });
  Promise.all(promises)
    .then(response => {
      dispatch({
        type: GET_BULK_ASSET_DELETE_SUCCESSFULLY,
        payload: response,
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Success',
          text: <T {...messages.toastForSuccessfullyDeletingAssets} />,
          status: 'success',
        }),
      );
      dispatch({ type: HIDE_LOADER });
    })
    .catch(err => {
      dispatch({ type: HIDE_LOADER });
      dispatch({ type: SHOW_ERROR_TOAST, payload: err });
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: <T {...messages.toastForCommonErrorMessages} />,
          status: 'danger',
        }),
      );
    });
};

export const getToggleAssetFlagActionDispatcher = () => dispatch => {
  dispatch({
    type: GET_TOGGLE_ASSET_DELETE_FLAG,
  });
};
export const toggleAssetFileFromBase64ReceivedDispatcher = () => dispatch => {
  dispatch({
    type: GET_FILE_FROM_BASE64_TOGGLE,
  });
};
export const getToggleAssetEditAndInsertActionDispatcher = () => dispatch => {
  dispatch({
    type: GET_TOGGLE_ASSET_EDIT_AND_SAVE_FLAG,
  });
};
export const getToggleAssetEditActionDispatcher = () => dispatch => {
  dispatch({
    type: GET_TOGGLE_ASSET_EDIT_FLAG,
  });
};

export const getToggleFullAssetsDataActionDispatcher = () => dispatch => {
  dispatch({
    type: GET_TOGGLE_FULL_ASSETS_DATA_FLAG,
  });
};

export const getToggleUploadViaUrlDispatcher = () => dispatch => {
  dispatch({
    type: UPLOAD_VIA_URL_FAILURE,
  });
};

export default browseModalUploadReducer;
