import moment from 'moment';

const validRanges = ['month', 'year'];

class TemplateValidator {
  constructor(validContentFilters, validWidgetTypes, validSearchTaxonomies) {
    this.validContentFilters = validContentFilters;
    this.validWidgetTypes = validWidgetTypes;
    this.validSearchTaxonomies = validSearchTaxonomies;
    this.errorMessages = [];
  }

  parseJson(json) {
    let parsedJson;
    try {
      parsedJson = JSON.parse(json);
    } catch (e) {
      this.errorMessages.push(`You have malformed json --> ${e.message}`);
      return false;
    }
    return parsedJson;
  }

  checkForDefaultTitleField(json) {
    if (json.defaultTitle) {
      return true;
    }
    this.errorMessages.push(
      'The template must have a default title. Please include a "defaultTitle" field.',
    );
    return false;
  }

  checkDateField(json) {
    if (!json.date) {
      this.errorMessages.push(
        'The template and every widget must have a date object. Please include a "date" field.',
      );
      return false;
    }
    if (json.date.rangeToDate) {
      if (validRanges.indexOf(json.date.rangeToDate) === -1) {
        this.errorMessages.push(
          `"rangeToDate" must be one of the following values: ${validRanges.join(
            ', ',
          )}`,
        );
        return false;
      }
    } else if (json.date.trailing) {
      if (!json.date.trailingDays) {
        this.errorMessages.push(
          'If trailing is true, you  must specify "trailingDays".',
        );
        return false;
      }
    } else if (
      !json.date.start ||
      (json.date.start && !moment(json.date.start).isValid()) ||
      !json.date.end ||
      (json.date.end && !moment(json.date.end).isValid())
    ) {
      this.errorMessages.push(
        'The date object must include a valid "start" and "end" date.',
      );
    }

    return true;
  }

  checkWidgetDateField(json) {
    let result = true;

    if (json.widgets) {
      json.widgets.forEach(widget => {
        if (!this.checkDateField(widget)) {
          result = false;
        }
      });
    }

    return result;
  }

  checkForDashboardSearch(json) {
    if (json.search && Object.keys(json.search).length > 0) {
      return true;
    }
    this.errorMessages.push('The template must include a valid search object.');
    return false;
  }

  checkForValidContentFilters(json) {
    let result = true;

    if (json.contentFilters) {
      json.contentFilters.forEach(filter => {
        if (!this.validContentFilters.includes(filter)) {
          this.errorMessages.push(
            `The content filter "${filter}" is not allowed in dashboard templates.`,
          );
          result = false;
        }
      });
    }

    return result;
  }

  checkForValidWidgetTypes(json) {
    let result = true;

    if (json.widgets) {
      json.widgets.forEach(widget => {
        if (!widget.type) {
          this.errorMessages.push(
            'The template contains a widget definition with no "type" field.',
          );
          result = false;
        } else if (
          widget.type &&
          !this.validWidgetTypes.includes(widget.type)
        ) {
          this.errorMessages.push(
            `The widget type "${widget.type}" is not allowed in dashboard templates. Please adjust the ${widget.type} widget.`,
          );
          result = false;
        }
      });
    }

    return result;
  }

  checkForValidWidgetFilters(json) {
    let result = true;

    if (json.widgets) {
      json.widgets.forEach(widget => {
        if (widget.filters) {
          widget.filters.forEach(filter => {
            if (!this.validContentFilters.includes(filter)) {
              this.errorMessages.push(
                `The widget filter "${filter}" is not allowed in dashboard templates.`,
              );
              result = false;
            }
          });
        }
      });
    }

    return result;
  }

  checkForValidSearchTaxonomies(json) {
    let result = true;

    if (
      json.search &&
      json.search.taxonomy &&
      !this.validSearchTaxonomies.includes(json.search.taxonomy)
    ) {
      this.errorMessages.push(
        `The search taxonomy "${json.search.taxonomy}" is not allowed in this dashboard template.`,
      );
      return false;
    }

    if (json.widgets) {
      json.widgets.forEach(widget => {
        if (widget.searches) {
          widget.searches.forEach(search => {
            if (
              search.taxonomy &&
              !this.validSearchTaxonomies.includes(search.taxonomy)
            ) {
              this.errorMessages.push(
                `The search taxonomy "${search.taxonomy}" is not allowed in this dashboard template.`,
              );
              result = false;
            }
          });
        }
        if (
          widget.primarySearch &&
          widget.primarySearch.taxonomy &&
          !this.validSearchTaxonomies.includes(widget.primarySearch.taxonomy)
        ) {
          this.errorMessages.push(
            `The search taxonomy "${widget.primarySearch.taxonomy}" is not allowed in this dashboard template.`,
          );
          result = false;
        }
      });
    }

    return result;
  }

  checkSearchTemplateValid(json) {
    let result = true;

    if (json.search && json.search.template) {
      if (!json.search.title) {
        result = false;
        this.errorMessages.push(
          'The templated search for json.search.template must have a title.',
        );
      } else {
        result =
          this.validateSearchTaxonomiesInTemplate(
            json.search.title,
            this.errorMessages,
          ) && result;
      }

      result =
        this.areCurlyBracesBalanced(
          json.search.template,
          this.errorMessages,
          'json.search.template',
        ) && result;
      result =
        this.validateSearchTaxonomiesInTemplate(
          json.search.template,
          this.errorMessages,
        ) && result;
    }

    if (json.widgets) {
      json.widgets.forEach((widget, idx) => {
        if (widget.searches) {
          widget.searches.forEach(search => {
            if (search.template) {
              if (!search.title) {
                result = false;
                this.errorMessages.push(
                  `The templated search for widget[${idx}].search.template must have a title.`,
                );
              } else {
                result =
                  this.validateSearchTaxonomiesInTemplate(
                    search.title,
                    this.errorMessages,
                  ) && result;
              }

              result =
                this.areCurlyBracesBalanced(
                  search.template,
                  this.errorMessages,
                  `widget[${idx}].search.template`,
                ) && result;
              result =
                this.validateSearchTaxonomiesInTemplate(
                  search.template,
                  this.errorMessages,
                ) && result;
            }
          });
        }

        if (widget.primarySearch && widget.primarySearch.template) {
          if (!widget.primarySearch.title) {
            result = false;
            this.errorMessages.push(
              'The templated search for widget.primarySearch.template must have a title.',
            );
          } else {
            result =
              this.validateSearchTaxonomiesInTemplate(
                widget.primarySearch.title,
                this.errorMessages,
              ) && result;
          }

          result =
            this.areCurlyBracesBalanced(
              widget.primarySearch.template,
              this.errorMessages,
              'widget.primarySearch.template',
            ) && result;
          result =
            this.validateSearchTaxonomiesInTemplate(
              widget.primarySearch.template,
              this.errorMessages,
            ) && result;
        }
      });
    }

    return result;
  }

  areCurlyBracesBalanced(
    searchTemplateString,
    errorMessageList,
    searchTemplateLocation,
  ) {
    const curlyBraceStack = [];

    searchTemplateString.split('').forEach(char => {
      if (char === '{') {
        curlyBraceStack.push('{');
      }

      if (char === '}') {
        curlyBraceStack.pop();
      }
    });

    if (curlyBraceStack.length > 0) {
      errorMessageList.push(
        `Curly braces in ${searchTemplateLocation} are unbalanced.`,
      );
      return false;
    }

    return true;
  }

  extractSearchTaxonomiesFromTemplate(searchTemplateString) {
    const matches = searchTemplateString.match(/{{[\S]*}}/g);

    if (matches) {
      return matches.map(taxonomy =>
        taxonomy.substring(2, taxonomy.length - 2),
      );
    }

    return [];
  }

  validateSearchTaxonomiesInTemplate(searchTemplateString, errorMessageList) {
    let returnedResult = true;
    const templatedTaxonomies = this.extractSearchTaxonomiesFromTemplate(
      searchTemplateString,
    );

    templatedTaxonomies.forEach(taxonomyInfo => {
      const taxonomy = taxonomyInfo.split(':')[0];

      if (!this.validSearchTaxonomies.includes(taxonomy)) {
        errorMessageList.push(
          `The search taxonomy "${taxonomy}" is not allowed in dashboard templates (template search).`,
        );
        returnedResult = false;
      }

      const position = taxonomyInfo.split(':')[1];

      if (position && isNaN(position)) {
        errorMessageList.push(
          `The position "${position}" for search taxonomy "${taxonomy}" must be a number (template search).`,
        );
        returnedResult = false;
      }
    });

    return returnedResult;
  }

  validate(json) {
    let result = true;
    this.errorMessages = [];

    const parsedJson = this.parseJson(json);
    if (!parsedJson) {
      // short circuit: if the parsing fails, then fail the whole validation
      return false;
    }

    result = this.checkForDefaultTitleField(parsedJson) && result;
    result = this.checkDateField(parsedJson) && result;
    result = this.checkWidgetDateField(parsedJson) && result;
    result = this.checkForDashboardSearch(parsedJson) && result;
    result = this.checkForValidContentFilters(parsedJson) && result;
    result = this.checkForValidWidgetTypes(parsedJson) && result;
    result = this.checkForValidWidgetFilters(parsedJson) && result;
    result = this.checkForValidSearchTaxonomies(parsedJson) && result;
    result = this.checkSearchTemplateValid(parsedJson) && result;
    return result;
  }
}

export default TemplateValidator;
