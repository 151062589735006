export default {
  allOwners: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_ALL_OWNERS',
    description:
      "report list action bar label for all owner option in the report's owners select",
    defaultMessage: 'All Owners',
  },
  lastModified: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_LAST_MODIFIED',
    description: 'report list action bar label for last modified',
    defaultMessage: 'Last Modified',
  },
  owner: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_LAST_OWNER',
    description: 'report list action bar label for owner',
    defaultMessage: 'Owner',
  },
  name: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_NAME',
    description: 'report list action bar label for name',
    defaultMessage: 'Name',
  },
  selectAll: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_SELECT_ALL',
    description: 'report list action bar label for select all',
    defaultMessage: 'Select All',
  },
  analyticsSelected: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_REPORTS_SELECTED',
    description: 'report list action bar label for Reports selected',
    defaultMessage:
      '{TOTAL_ANALYTICS} of {SELECTED_ANALYTICS} Reports selected',
  },
  star: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_STARS',
    description: 'report list action bar label for star',
    defaultMessage: 'Star',
  },
  unstar: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_UNSTAR',
    description: 'report list action bar label for unstar',
    defaultMessage: 'Unstar',
  },
  archive: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_ARCHIVE',
    description: 'report list action bar label for archive',
    defaultMessage: 'Archive',
  },
  share: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_SHARE',
    description: 'report list action bar label for share',
    defaultMessage: 'Share',
  },
  shareSettings: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_SHARE_SETTINGS',
    description: 'report list action bar label for share settings',
    defaultMessage: 'Share Settings',
  },
  restore: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_RESTORE',
    description: 'report list action bar label for restore',
    defaultMessage: 'Restore',
  },
  findAnalytic: {
    id: 'REPORT_LIST_ACTION_BAR_LABEL_FIND_REPORT',
    description: 'report list action bar label for find report',
    defaultMessage: 'Find a Report',
  },
  reportListArchiveActionErrorNotOwner: {
    id: 'REPORT_LIST_ACTION_BAR_ARCHIVE_ACTION_NOT_OWNER_MESSAGE',
    description:
      'report list action bar: error message shown when user tried to archive an report that is not own',
    defaultMessage: 'User can only archive own reports',
  },
};
