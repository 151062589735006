import sortBy from 'lodash/sortBy';

import uniqBy from 'lodash/uniqBy';

import tagMessages from 'components/tags/bulk-tag-articles-modal/BulkTagArticlesModal.messages';
import { TAG_BASE_ENDPOINT } from 'constants/apis';
import { REACT_TAG_BASE_URL } from 'constants/constants';
import globalMessageDescriptors from 'i18n/Global.messages';
import TagMessages from 'pages/Tag/Tag.messages';
import { updateTagsForArticleIds } from 'pages/WidgetDrilldown/drilldown-reducer';
import { addTagsToArticlesInListById } from 'reducers/articles';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';
import { addTagsToArticlesById, CREATE_TAG_SUCCESS } from 'reducers/tag-list';
import { performGet, performPut } from 'services/rest-service/rest-service';
import timeoutPromise from 'utils/timeout-promise';

const formatTag = tagValue => {
  return tagValue.trim().replace(/\s\s+/g, ' ');
};

const POLLING_MILLISECONDS = 30000;
const MAXIMUM_WAITING_MINUTES = 10;

export default formatTag;

export const buildTagURL = tagId => `${REACT_TAG_BASE_URL}/${tagId.toString()}`;

export const executeRequestToShareTag = async (groupsToShareWith, tagId) => {
  const sharingEndpoint = `${TAG_BASE_ENDPOINT}/${tagId}/share/{ACGId}?sharing={SHARE_LEVEL}`;
  const promises = groupsToShareWith.map(g => {
    return performPut(
      sharingEndpoint
        .replace('{ACGId}', g.id.toString())
        .replace('{SHARE_LEVEL}', g.sharingLevel),
    );
  });

  await Promise.all(promises);
};

export const updateTagsOnArticleList = (
  intl,
  dispatch,
  appliedTags,
  articleIds,
  existingTags,
  overrideArticleTags,
) => {
  const newTags = appliedTags.map(newTag => ({
    articleId: newTag.articleId,
    id: newTag.id,
    tag: {
      id: newTag.tagId,
      tag: newTag.tag,
    },
    type: newTag.tagType,
  }));

  if (!existingTags.length) {
    dispatch(addTagsToArticlesById(newTags, articleIds));
  }
  dispatch(addTagsToArticlesInListById(newTags, articleIds));
  const tagNames = uniqBy(newTags, t => t.tag.tag)
    .map(newTag => newTag.tag.tag)
    .join(', ');

  dispatch(
    addPageMessageWithDefaultTimeout({
      title: intl.formatMessage(globalMessageDescriptors.success),
      text: intl.formatMessage(tagMessages.taggedArticles, {
        COUNT: articleIds.length,
        TAG_NAME: tagNames,
      }),
      status: 'success',
    }),
  );

  dispatch({
    type: CREATE_TAG_SUCCESS,
    payload: { newTags, overrideArticleTags },
  });

  dispatch(updateTagsForArticleIds(articleIds, newTags, overrideArticleTags));
};

export const canUserShareTag = (
  isTagOwner,
  isTagShared,
  hasCustomerAdminRole,
  hasAccessControlTags,
) => {
  return hasAccessControlTags
    ? isTagOwner || (isTagShared && hasCustomerAdminRole)
    : isTagOwner && !isTagShared;
};

const findArticleInList = (allArticles, articleId) =>
  allArticles.find(article => article.id === articleId);

const isTagInArticle = (article, tagId) =>
  !!article?.tags?.find(articleTag => articleTag.tagId === tagId);

const isTagInList = (list, tagId) => !!list.find(tag => tag.id === tagId);

// this is for the Article Tag Selector Modal
export const filterByArticleIdWhenRemovingTag = (
  allArticles,
  articleIds,
  tagList,
) => {
  const tempList = [];
  articleIds.forEach(articleId => {
    tagList.forEach(tagItem => {
      const articleFound = findArticleInList(allArticles, articleId);
      if (articleFound) {
        if (isTagInArticle(articleFound, tagItem.id)) {
          if (!isTagInList(tempList, tagItem.id)) {
            tempList.push(tagItem);
          }
        }
      }
    });
  });
  return tempList;
};
export const formatTags = tags =>
  sortBy(
    Object.values(tags).map(tag => {
      return {
        ...tag,
        title: tag.tag,
        subtitle:
          tag.articleCount > 0
            ? `${tag.articleCount} Article${tag.articleCount > 1 ? 's' : ''}`
            : '',
      };
    }),
    ['tag'],
  );
export const verifyTagRemoval = async (
  formatMessage,
  dispatch,
  tagId,
  tagName,
) => {
  let shouldDoPolling = true;
  let numberOfIterations = 0;

  while (shouldDoPolling) {
    await timeoutPromise(POLLING_MILLISECONDS);
    try {
      const response = await performGet(
        `${TAG_BASE_ENDPOINT}/${tagId}?includeVisible=false`,
      );
      if (response.data?.visible) {
        dispatch(
          addPageMessage({
            text: formatMessage(TagMessages.deleteTagErrorWithName, {
              TAG_NAME: tagName,
            }),
            status: 'danger',
            ttl: 6000,
          }),
        );
        break;
      }
    } catch (error) {
      if (error.response?.status === 404) {
        dispatch(
          addPageMessage({
            text: formatMessage(TagMessages.deleteTagSuccessfullyWithName, {
              TAG_NAME: tagName,
            }),
            status: 'success',
            ttl: 6000,
          }),
        );
        break;
      }
    }
    numberOfIterations += 1;
    shouldDoPolling =
      (POLLING_MILLISECONDS * numberOfIterations) / 1000 / 60 <=
      MAXIMUM_WAITING_MINUTES;
  }
};
