import React from 'react';

import globalMessages from 'i18n/Global.messages';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Button from '../button';
import Modal from '../modal';
import { Body } from '../type';

const statuses = {
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  NONE: 'none',
};
const defaultProps = {
  header: '',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Yes',
  message: null,
  size: 'sm',
  status: 'none',
  onCancel: null,
  confirmButtonModifiers: ['primary'],
  onCancelDataQA: 'oP-8YEej7GTpS7FGNN2pa',
  onConfirmDataQA: 'kyyDnqMeeme9ukAmH47DP',
  zIndex: 1050,
};

const ConfirmModal = ({
  header,
  isOpen,
  message,
  onCancel,
  onCancelDataQA,
  onConfirm,
  onConfirmDataQA,
  cancelButtonText,
  confirmButtonText,
  confirmButtonModifiers,
  size,
  status,
  zIndex,
}) => {
  const intl = useIntl();
  const handleOnConfirm = () => {
    const removeSent = false;
    onConfirm(removeSent);
  };

  return (
    <Modal toggle={onCancel} isOpen={isOpen} size={size} zIndex={zIndex}>
      {header && (
        <Modal.Header status={status} toggle={onCancel}>
          {header}
        </Modal.Header>
      )}
      {message && (
        <Modal.Body>
          <Body>{message}</Body>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Modal.Actions>
          {onCancel && (
            <Modal.Action>
              <Button
                data-qa={onCancelDataQA}
                modifiers={['tertiary', 'round', 'margin-right']}
                onClick={onCancel}
              >
                {cancelButtonText === defaultProps.cancelButtonText
                  ? intl.formatMessage(globalMessages.cancel)
                  : cancelButtonText}
              </Button>
            </Modal.Action>
          )}
          <Modal.Action>
            <Button
              data-qa={onConfirmDataQA}
              modifiers={['round', ...confirmButtonModifiers]}
              onClick={handleOnConfirm}
            >
              {confirmButtonText === defaultProps.confirmButtonText
                ? intl.formatMessage(globalMessages.yes)
                : confirmButtonText}
            </Button>
          </Modal.Action>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  header: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.node,
  onCancel: PropTypes.func,
  onCancelDataQA: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onConfirmDataQA: PropTypes.string,
  cancelButtonText: PropTypes.node,
  confirmButtonText: PropTypes.node,
  size: PropTypes.string,
  status: PropTypes.oneOf(Object.values(statuses)),
  confirmButtonModifiers: PropTypes.arrayOf(PropTypes.string),
  zIndex: PropTypes.number,
};

ConfirmModal.defaultProps = defaultProps;

export default ConfirmModal;
