import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment-timezone';

momentDurationFormatSetup(moment);
export const formatDuration = duration => {
  return moment.duration(duration, 'milliseconds').format();
};

export const msPerSec = 1000;
export const secPerMin = 60;
export const minPerHour = 60;
export const hourPerDay = 24; // Usually
export const msPerMinute = msPerSec * secPerMin;
export const msPerHour = msPerMinute * minPerHour;
export const msPerDay = msPerHour * hourPerDay; // Usually

export const convertDateTimeSelectionToUnix = (date, time, timezone) => {
  const day = moment(date).format('YYYY-MM-DD');
  const dayTime = `${day} ${time}`;
  return moment.tz(dayTime, 'YYYY-MM-DD HH:mm', timezone).valueOf();
};

export const roundDown15MinUtc = dateTimeMillis => {
  const roundedMoment = moment.utc(dateTimeMillis);
  const intervals = Math.floor(roundedMoment.minutes() / 15);
  roundedMoment.minutes(intervals * 15);
  roundedMoment.seconds(0);
  return roundedMoment;
};

export const unixTimeIsInFuture = timestamp => {
  const now = moment().valueOf();
  return now < timestamp;
};

export const timeOptionsByMinInterval = (minutes = 15) => {
  const start = moment().startOf('day');
  const times = [];
  times.push({
    title: start.format('h:mm a'),
    id: start.format('kk:mm'),
  });
  for (let i = 1; i < 24 * (60 / minutes); i += 1) {
    const time = start.add(minutes, 'minutes');
    times.push({
      title: time.format('h:mm a'),
      id: time.format('HH:mm'),
    });
  }
  return times;
};

export function subtractDate(
  date,
  subtractDays,
  subtractTime = 'days',
  isUTC = true,
) {
  return isUTC
    ? moment(date).subtract(subtractDays, subtractTime).valueOf()
    : moment(date).subtract(subtractDays, subtractTime);
}

export const convertDateBetweenOffsets = (date, beforeOffset, afterOffset) => {
  //get the year-month-day of the given date in the original offset
  const yearMonthDay = moment
    .utc(date)
    .utcOffset(beforeOffset)
    .format('YYYY-MM-DD');
  //get this date in the new offset
  return moment.parseZone(yearMonthDay + 'T00:00:00' + afterOffset).valueOf();
};

//unixTime is seconds since Unix Epoch
export const convertUnixTimeBetweenOffsets = (
  unixTime,
  beforeOffset,
  afterOffset,
) => {
  const yearMonthDayTime = moment
    .unix(unixTime)
    .utcOffset(beforeOffset)
    .format('YYYY-MM-DDTHH:mm:ss');
  return moment.parseZone(yearMonthDayTime + afterOffset).valueOf();
};

//given a date, a time and an offset, return the unix timestamp
//representing the full date and time in the given offset
export const getUnixTimestamp = (date, time, utcOffset) => {
  //get the year-month-day of the given date in the given offset
  const yearMonthDay = moment
    .utc(date)
    .utcOffset(utcOffset)
    .format('YYYY-MM-DD');
  //put the date, time and offset together into a recognized ISO 8601 formatted string
  const fullDateTime = yearMonthDay + 'T' + time + ':00' + utcOffset;
  const millisecondsSinceUnixEpoch = moment.parseZone(fullDateTime).valueOf();
  //convert to seconds to return unix timestamp
  return millisecondsSinceUnixEpoch / 1000;
};

//given a unix timestamp and offset, return the time in that offset
export const getTimeFromUnixTimestamp = (unixTimestamp, utcOffset) => {
  return moment.unix(unixTimestamp).utcOffset(utcOffset).format('HH:mm');
};

//if the given date is in the past, returns it as the min date;
//otherwise returns today's date
export const getMinDate = (date, utcOffset) => {
  if (isDateInPastDay(date, utcOffset)) {
    return date;
  } else {
    return Date.now();
  }
};

//if the given date is today, returns the time in approximately half an hour as the min time;
//otherwise returns midnight since any time is acceptable
export const getMinTime = (date, utcOffset) => {
  if (isDateToday(date, utcOffset)) {
    return getTimeInHalfAnHourOrSo(Date.now(), utcOffset);
  } else {
    return '00:00'; //any time goes
  }
};

//returns true if the date is in the past or today but near midnight
export const isDateInvalid = (date, utcOffset) => {
  const isInPast = isDateInPastDay(date, utcOffset);
  const isToday = isDateToday(date, utcOffset);
  const isAlmostMidnight = isTimeAlmostMidnight(Date.now(), utcOffset);
  return isInPast || (isToday && isAlmostMidnight);
};

//returns true if the timestamp is in the past or in the next (approx.) half hour
export const isScheduledTimestampInvalid = unixTimestamp => {
  const now = moment.utc(Date.now());
  const remainder = 15 - (now.minute() % 15) + 15;
  const minTimestamp = now.add(remainder, 'minutes').set('seconds', 0).unix();
  return unixTimestamp < minTimestamp;
};

//given a date, apply the offset to find the time in that offset
//and return a time approximately half an hour in the future,
//rounding to the nearest quarter, half or hour mark
// e.g. given 09:00 it will return 09:30, given 09:44 it will return 10:00, given 09:45 it will return 10:15
export const getTimeInHalfAnHourOrSo = (date, utcOffset) => {
  const now = moment.utc(date).utcOffset(utcOffset);
  const remainder = 15 - (now.minute() % 15) + 15;
  return now.add(remainder, 'minutes').format('HH:mm');
};

export const isDateInPastDay = (date, utcOffset) => {
  //get the year-month-day of today in the given offset
  const today = moment
    .utc(Date.now())
    .utcOffset(utcOffset)
    .format('YYYY-MM-DD');
  //get the year-month-day of the given date in the given offset
  const given = moment.utc(date).utcOffset(utcOffset).format('YYYY-MM-DD');
  //convert both to milliseconds since Unix Epoch to compare them
  return moment.utc(given).valueOf() < moment.utc(today).valueOf();
};

export const isDateToday = (date, utcOffset) => {
  //identical to the isDateInPastDay method except that we check the dates are equal
  const today = moment
    .utc(Date.now())
    .utcOffset(utcOffset)
    .format('YYYY-MM-DD');
  const given = moment.utc(date).utcOffset(utcOffset).format('YYYY-MM-DD');
  return moment.utc(given).valueOf() === moment.utc(today).valueOf();
};

export const isTimeAlmostMidnight = (date, utcOffset) => {
  const now = moment.utc(date).utcOffset(utcOffset);
  const hour = parseInt(now.format('HH'));
  const minutes = parseInt(now.format('mm'));
  if (hour === 23 && minutes >= 30) {
    return true;
  }
  return false;
};
