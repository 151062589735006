interface Lists {
  id: string;
  name: string;
}

interface Data {
  influencerId: string;
  lists: Lists[];
}

export const flattenLists = (data: Data[] = []): Lists[] => {
  return data.reduce((accumulator: Lists[], currentItem: Data) => {
    const mergedLists = [...accumulator, ...currentItem.lists];

    return mergedLists;
  }, []);
};
