import isEmpty from 'lodash/isEmpty';

import { Influencer, TInfluencerType } from './types';

export const INFLUENCER_TYPES_MAP: Record<string, TInfluencerType> = {
  OUTLET: 'Outlet',
  SOCIAL: 'Social',
  JOURNALIST: 'Journalist',
};

const storedSearchCriteriaKey = 'influencerActiveSearchCriteria';

export const storeSearchCriteria = searchCriteria =>
  window.sessionStorage.setItem(
    storedSearchCriteriaKey,
    JSON.stringify(searchCriteria),
  );

export const influencerIsOutlet = (influencer: Influencer | null) => {
  return (influencer?.influencerTypes || []).includes(
    INFLUENCER_TYPES_MAP.OUTLET,
  );
};

export const isInfluencerPrivate = (influencer: Influencer) => {
  const influencerId: string = influencer?.id || '';

  return (
    !influencerId.startsWith('contact_') &&
    !influencerId.startsWith('outlet_') &&
    isNaN((influencerId as unknown) as number)
  );
};

export const influencerIsOnlySocial = (influencer: Influencer) => {
  const types = influencer.influencerTypes || [];

  // More than one type. Not just social
  if (types.length > 1) {
    return false;
  }

  return types.includes(INFLUENCER_TYPES_MAP.SOCIAL);
};

export const parseLocation = location => {
  const countryName = location.country ? location.country : null;

  const locationPieces = [location.city, location.state, countryName];
  return locationPieces.filter(l => !!l).join(', ');
};

export const searchWithStoredSearchCriteria = ({
  dispatch,
  getSearchResults,
  setSearchCriteria,
  setChosenTopics,
  setChosenLocations,
  setChosenOutlets,
}) => {
  const storedSearchCriteriaString = window.sessionStorage.getItem(
    storedSearchCriteriaKey,
  );
  let storedSearchCriteria;

  try {
    storedSearchCriteria = JSON.parse(storedSearchCriteriaString as string);
  } catch (e) {
    window.sessionStorage.setItem(storedSearchCriteriaKey, JSON.stringify({}));
  }

  if (storedSearchCriteria) {
    // Set state.
    dispatch(setSearchCriteria(storedSearchCriteria.searchCriteria));
    dispatch({
      type: setChosenTopics,
      payload: storedSearchCriteria.topics.chosen,
    });

    dispatch({
      type: setChosenLocations,
      payload: storedSearchCriteria.locations.chosen,
    });

    dispatch({
      type: setChosenOutlets,
      payload: storedSearchCriteria.outlets.chosen,
    });

    // Do search.
    dispatch(getSearchResults());
  } else {
    throw new Error('No searchCriteria');
  }
};

export const extractInfluencerContactsByType = (
  allContacts,
  typesToExtract: string[] = [],
) => {
  if (isEmpty(allContacts)) return {};

  // Produces { email: ['sam@example.com'], twitter: ['sam'] }
  return allContacts.reduce(
    (result, contact) => {
      if (
        result[contact.type] &&
        result[contact.type].indexOf(contact.text) === -1
      ) {
        result[contact.type].push(contact.text);
      }

      return result;
    },
    isEmpty(typesToExtract)
      ? // By default, only extract email and twitter
        { email: [], twitter: [], phone: [] }
      : // A typesToExtract argument (an array of type names) means extract all contacts of those types
        typesToExtract.reduce((r, t) => ({ ...r, [t]: [] }), {}),
  );
};

export default {
  searchWithStoredSearchCriteria,
  storeSearchCriteria,
};
