import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

const StoryInsightsBody = ({ children, className, style }) => {
  const mainClass = classnames(StoryInsightsBody.baseClass, className);

  return (
    <div className={mainClass} style={style}>
      {children}
    </div>
  );
};

StoryInsightsBody.baseClass = 'story-insights-body';

StoryInsightsBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

StoryInsightsBody.defaultProps = {
  className: '',
  style: {},
};

export default StoryInsightsBody;
