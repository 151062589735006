export const isValidEmail = val => {
  const validEmailRegExp = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
  );
  return val ? validEmailRegExp.test(val) : true;
};

export default {
  isValidEmail,
};
