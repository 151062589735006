import {
  CONTACT_BASE_ENDPOINT,
  CONTACT_IMAGES_ENDPOINT,
  CONTACT_LISTS_ENDPOINT,
} from 'constants/apis';

import { CONTACTS_RANK_SORT_OPTION, DEV_FEATURES } from 'constants/constants';

import { addPageMessage } from 'reducers/page-messages';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import {
  performDelete,
  performGet,
  performPost,
  performPut,
} from 'services/rest-service/rest-service';

export const GET_KEY_AUTHORS = 'discovery/GET_KEY_AUTHORS';
export const GET_KEY_AUTHORS_ERROR = 'discovery/GET_KEY_AUTHORS_ERRORS';
export const GET_KEY_AUTHORS_SUCCESS = 'discovery/GET_KEY_AUTHORS_SUCCESS';
export const FAVORITE_DISCOVERY_CONTACTS =
  'discovery/contacts/FAVORITE_DISCOVERY_CONTACTS';
export const UNFAVORITE_DISCOVERY_CONTACTS =
  'discovery/contacts/UNFAVORITE_DISCOVERY_CONTACTS';
export const FAVORITE_DISCOVERY_CONTACTS_ERROR =
  'discovery/contacts/FAVORITE_DISCOVERY_CONTACTS_ERROR';
export const UNFAVORITE_DISCOVERY_CONTACTS_ERROR =
  'discovery/contacts/UNFAVORITE_DISCOVERY_CONTACTS_ERROR';

export const initialState = {
  keyAuthors: {},
};

const discoveryKeyAuthorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KEY_AUTHORS_SUCCESS: {
      return {
        ...state,
        keyAuthors: {
          ...state.keyAuthors,
          [action.payload.searchId]: {
            authors: action.payload.contacts,
            error: false,
            loading: false,
          },
        },
      };
    }

    case GET_KEY_AUTHORS: {
      return {
        ...state,
        keyAuthors: {
          ...state.keyAuthors,
          [action.payload.searchId]: {
            authors: [],
            error: false,
            loading: true,
          },
        },
      };
    }

    case GET_KEY_AUTHORS_ERROR: {
      return {
        ...state,
        keyAuthors: {
          ...state.keyAuthors,
          [action.payload.searchId]: {
            authors: action.payload.contacts,
            error: true,
            loading: false,
          },
        },
      };
    }

    case FAVORITE_DISCOVERY_CONTACTS: {
      const { contactIds, searchId } = action.payload;
      const updatedKeyAuthors = state.keyAuthors[searchId].authors.map(
        contact => {
          if (contactIds.includes(contact.id)) {
            return { ...contact, favorited: true };
          }
          return contact;
        },
      );
      return {
        ...state,
        keyAuthors: {
          ...state.keyAuthors,
          [searchId]: {
            ...state.keyAuthors[searchId],
            authors: updatedKeyAuthors,
          },
        },
      };
    }

    case UNFAVORITE_DISCOVERY_CONTACTS: {
      const { contactIds, searchId } = action.payload;
      if (!searchId || !(state.keyAuthors || {})[searchId]) {
        return state;
      }
      const updatedKeyAuthors = state.keyAuthors[searchId].authors.map(
        contact => {
          if (contactIds.includes(contact.id)) {
            return { ...contact, favorited: false };
          }
          return contact;
        },
      );
      return {
        ...state,
        keyAuthors: {
          ...state.keyAuthors,
          [searchId]: {
            ...state.keyAuthors[searchId],
            authors: updatedKeyAuthors,
          },
        },
      };
    }

    default:
      return state;
  }
};

export const getKeyAuthors = (
  searchId,
  startDate,
  endDate,
) => async dispatch => {
  dispatch({ type: GET_KEY_AUTHORS, payload: { searchId } });

  const apiParams = {
    searchId,
    startDate,
    endDate,
    searchScoped: true,
    sort: userHasDevFeatureFlag(DEV_FEATURES.authorRank)
      ? CONTACTS_RANK_SORT_OPTION.id
      : undefined,
  };

  try {
    const response = await performGet(`${CONTACT_BASE_ENDPOINT}`, apiParams);

    // TODO: this is becoming an increasingly common function, it should be centralized
    const filteredContacts = response.data.authors.filter(
      contact => contact.twitterHandles.length > 0 && contact.id,
    );

    const contactImages = {};
    if (filteredContacts.length > 0) {
      const contactImagesRequest = await performPost(CONTACT_IMAGES_ENDPOINT, {
        authors: filteredContacts.map(contact => ({ id: contact.id })),
        skipErrors: true,
      });
      contactImagesRequest.data.forEach(
        image => (contactImages[image.id] = image),
      );
    }

    const contacts = response.data.authors.map(contact => ({
      ...contact,
      image: contact.id in contactImages ? contactImages[contact.id] : '',
      svgIcon: 'contact',
      type: 'contacts',
    }));

    dispatch({
      type: GET_KEY_AUTHORS_SUCCESS,
      payload: {
        searchId,
        contacts,
      },
    });
  } catch (e) {
    dispatch({
      type: GET_KEY_AUTHORS_ERROR,
      payload: {
        searchId,
      },
    });
  }
};

export const favoriteDiscoveryContacts = (
  contactIds,
  searchId,
) => async dispatch => {
  try {
    if (typeof searchId !== 'string' && typeof searchId !== 'number') {
      throw new Error("Search ID isn't valid");
    }
    performPut(`${CONTACT_LISTS_ENDPOINT}/favorites/authors`, {
      authorIds: contactIds,
    });
    dispatch({
      type: FAVORITE_DISCOVERY_CONTACTS,
      payload: { contactIds, searchId },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Success!',
        text: 'Contact has been added to your favorites.',
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: FAVORITE_DISCOVERY_CONTACTS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Error!',
        text: e.message,
        status: 'danger',
        ttl: 3000,
      }),
    );
  }
};

export const unfavoriteDiscoveryContacts = (
  contactIds,
  searchId,
) => async dispatch => {
  try {
    if (typeof searchId !== 'string' && typeof searchId !== 'number') {
      throw new Error("Search ID isn't valid");
    }
    performDelete(`${CONTACT_LISTS_ENDPOINT}/favorites/authors`, {
      authorIds: contactIds,
    });
    dispatch({
      type: UNFAVORITE_DISCOVERY_CONTACTS,
      payload: { contactIds, searchId },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Success!',
        text: 'Contact has been removed from your favorites.',
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: UNFAVORITE_DISCOVERY_CONTACTS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        title: 'Error!',
        text: e.message,
        status: 'danger',
        ttl: 3000,
      }),
    );
  }
};

export default discoveryKeyAuthorsReducer;
