export default {
  lowScoreMeter: {
    id: 'SCORE_METER_LOW_SCORE_METER',
    defaultMessage: 'Low',
    description: 'Label for the low score meter',
  },
  mediumScoreMeter: {
    id: 'SCORE_METER_MEDIUM_SCORE_METER',
    defaultMessage: 'Medium',
    description: 'Label for the medium score meter',
  },
  highScoreMeter: {
    id: 'SCORE_METER_HIGH_SCORE_METER',
    defaultMessage: 'High',
    description: 'Label for the high score meter',
  },
};
