import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Proptypes from 'prop-types';

const baseClass = 'tk-keyword-input';

const KeywordInput = props => {
  const {
    className,
    onCreateHandler,
    helperText,
    placeHolder,
    enableAutoFocus,
    prefixedText,
    wrapperWidth,
    ...rest
  } = props;
  const baseMaxWidth = wrapperWidth - 5; //container minus the vertical bar and 3 px for the nbsp
  const containerClasses = classNames(baseClass, className);
  const prefixRef = useRef();
  const inputKeywordRef = useRef();
  const helperTextRef = useRef();
  const [inputKeywordMaxWidth, setInputKeywordMaxWidth] = useState(
    baseMaxWidth,
  );
  useEffect(() => {
    if (enableAutoFocus && inputKeywordRef) {
      inputKeywordRef.current.focus();
    }
  }, [enableAutoFocus, prefixedText]);

  useEffect(() => {
    let updatedInputKeywordMaxWidth = baseMaxWidth;
    if (prefixedText) {
      if (prefixRef) {
        const prefixTextWidth = prefixRef.current.clientWidth;
        updatedInputKeywordMaxWidth -= prefixTextWidth;
      }
    }
    if (helperText) {
      if (helperTextRef) {
        const helperTextWidth = helperTextRef.current.clientWidth;
        updatedInputKeywordMaxWidth -= helperTextWidth;
      }
    }
    setInputKeywordMaxWidth(updatedInputKeywordMaxWidth);
  }, [baseMaxWidth, helperText, prefixedText, wrapperWidth]);

  const onKeyPress = event => {
    const keyPressed = event.key;
    if (keyPressed === 'Enter') {
      const keyword = inputKeywordRef.current.textContent;
      if (keyword.trim().length === 0) {
        event.preventDefault();
        return;
      }
      const withSuccess = onCreateHandler(keyword);
      if (withSuccess) {
        inputKeywordRef.current.textContent = '';
      }
      event.preventDefault();
    }
  };

  return (
    <div className={containerClasses} {...rest}>
      <span ref={prefixRef}>{prefixedText}</span>
      &nbsp;
      <div
        contentEditable
        className="keywordTextBox"
        style={{ maxWidth: inputKeywordMaxWidth }}
        data-placeholder={placeHolder}
        ref={inputKeywordRef}
        onKeyDown={onKeyPress}
        tabIndex="0"
      />
      {helperText && (
        <>
          <div className={`${baseClass}__vertical-bar`} />
          <span className={`${baseClass}__helper-text`} ref={helperTextRef}>
            {helperText}
          </span>
        </>
      )}
    </div>
  );
};

KeywordInput.propTypes = {
  className: Proptypes.string,
  onCreateHandler: Proptypes.func,
  helperText: Proptypes.string,
  placeHolder: Proptypes.string,
  enableAutoFocus: Proptypes.bool,
  prefixedText: Proptypes.string,
  wrapperWidth: Proptypes.number,
};

KeywordInput.defaultProps = {
  className: '',
  onCreateHandler: () => null,
  helperText: '',
  placeHolder: 'Type here',
  enableAutoFocus: false,
  prefixedText: '',
  wrapperWidth: 0,
};

export default KeywordInput;
