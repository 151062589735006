import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const SettingsGear = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M18.885 12.877c.035-.28.058-.573.058-.877 0-.304-.023-.596-.07-.877l1.956-1.486a.456.456 0 00.116-.573l-1.852-3.117a.477.477 0 00-.567-.202l-2.302.9a6.83 6.83 0 00-1.563-.877l-.347-2.385A.458.458 0 0013.851 3H10.15a.446.446 0 00-.452.382L9.35 5.768a7.012 7.012 0 00-1.562.878l-2.303-.9a.464.464 0 00-.567.202L3.067 9.064a.428.428 0 00.115.573l1.956 1.486a5.502 5.502 0 00-.081.877c0 .293.023.596.07.877L3.17 14.364a.456.456 0 00-.116.573l1.852 3.116c.115.203.358.27.567.203l2.302-.9c.486.36.995.652 1.563.878l.347 2.384a.467.467 0 00.463.383h3.702c.232 0 .429-.157.452-.383l.347-2.384a7.012 7.012 0 001.562-.878l2.303.9c.208.079.451 0 .567-.203l1.851-3.116a.428.428 0 00-.115-.573l-1.933-1.486zM12 15.375c-1.91 0-3.472-1.519-3.472-3.375S10.091 8.625 12 8.625c1.91 0 3.472 1.519 3.472 3.375S13.909 15.375 12 15.375z" />
  </svg>
);
SettingsGear.propTypes = { fill: PropTypes.string };
SettingsGear.defaultProps = { fill: iconColorsMap.mainicon };

export default SettingsGear;
