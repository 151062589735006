/* eslint-disable max-len */
import * as types from './types';

type ThreadData = {
  dateDelivered: number;
  from: string;
  messages: any;
  recipients: types.Recipient[];
  recipientsFilteredTotal: number;
  recipientsTotal: number;
  stats: types.AggregateStats;
  subject: string;
  title?: string;
};

const MOCK_ANNOUNCEMENT_BODY = `
<div class="col-sm-10 col-sm-offset-1">
<p><i>As of <span class="xn-chron">February 1st</span>, Cision's comprehensive monitoring solution includes over 25,000 podcasts through a partnership with TVEyes</i></p>
<p><span class="xn-location">CHICAGO</span>, <span class="xn-chron">Feb. 26, 2020</span> /PRNewswire/ -- Today Cision announced an expansion of its partnership with TVEyes as of <span class="xn-chron">February 1, 2020</span>, making it possible for Cision clients to monitor podcast mentions of their brands, competitors, products, and people. Communications professionals can now track earned media mentions in key podcasts and tie this coverage back to business results with fast, detailed earned media analysis.&nbsp;Podcast monitoring powered by TVEyes is available as part of Cision's Broadcast Monitoring in the Next Generation Cision Communications Cloud, which <a href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2731630-1&amp;h=3320755435&amp;u=https%3A%2F%2Fwww.prnewswire.com%2Fnews-releases%2Fcision-unveils-the-next-generation-cision-communications-cloud-designed-to-empower-communications-teams-300880001.html&amp;a=launched+in+the+U.S." rel="nofollow" target="_blank">launched in the U.S.</a> and <span class="xn-location">Canada</span> last year.</p>
<p>"Cision is constantly evolving to bring our clients best-in-class features, and partnering with TVEyes is an exciting way to expand how PR and communications professionals track the entire scope of their media coverage," said <span class="xn-person">Brendon O'Donovan</span>, Cision's Head of Global Product Marketing. </p>
<p>"Podcasts are one of the fastest-growing media platforms today, with 25% of the American population listening to them weekly. The addition of podcasts on <span class="xn-chron">February 1</span> to Cision's global monitoring of online, social and traditional media, will allow communicators to track their brand with more depth."</p>
<p>Through the partnership with TVEyes, Cision customers can now pinpoint brand mentions across 25,000+ top ranking podcasts, including shows from the leading podcast publishers such as NPR, iHeartRadio and ESPN. These podcasts have been added to Cision's industry-leading content network of 7 million+ sources for global online news, blogs, social, print and broadcast. </p>
<p>"We continue to invest significantly in our core broadcast service, and in new product offerings such as our podcast monitoring service.&nbsp; Cision customers will now be able to search and discover new podcast episodes available daily across almost 20 categories and over 15 languages," added <span class="xn-person">David Ives</span>, CEO of TVEyes. "Podcasts are shifting the media landscape, exploding in popularity and giving rise to new sources of information for consumers. We are excited to make this innovative new service available to Cision's customers."</p>
<p>Podcast monitoring has been available for clients in <span class="xn-location">the United States</span> and <span class="xn-location">Canada</span> since early February, and will soon be available globally.&nbsp;To learn more, please visit <span><a href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2731630-1&amp;h=278555906&amp;u=https%3A%2F%2Fc212.net%2Fc%2Flink%2F%3Ft%3D0%26l%3Den%26o%3D2516336-1%26h%3D4241506923%26u%3Dhttp%253A%252F%252Fwww.cision.com%252F%26a%3Dwww.cision.com&amp;a=www.cision.com" rel="nofollow" target="_blank">www.cision.com</a></span>.</p>
<p><b>About Cision</b><br class="dnr">Cision Ltd. is a leading global provider of earned media software and services to public relations and marketing communications professionals. Cision's software allows users to identify key influencers, craft and distribute strategic content, and measure meaningful impact. Cision has over 4,800 employees with offices in 22 countries throughout the Americas, EMEA, and APAC. For more information about its award-winning products and services, including the Cision Communications Cloud®, visit <a href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2731630-1&amp;h=1134626057&amp;u=http%3A%2F%2Fwww.cision.com%2F&amp;a=www.cision.com" rel="nofollow" target="_blank">www.cision.com</a> and follow Cision on Twitter @Cision.</p>
<p><b>About TVEyes</b><br class="dnr">TVEyes Inc., headquartered in <span class="xn-location">Fairfield, Conn.</span>, provides a subscription-based service for online, near real-time search of television and radio broadcasts, and podcasts, as well as infrastructure for search engines, business intelligence and media intelligence platforms. Its Media Monitoring Suite™ is in use by corporations, professional sports teams, political campaigns, elected officials and the military to provide up-to-the-second intelligence on broadcast in multiple languages for US and international markets. More information on TVEyes can be found at <a href="https://c212.net/c/link/?t=0&amp;l=en&amp;o=2731630-1&amp;h=1461097228&amp;u=https%3A%2F%2Fnam03.safelinks.protection.outlook.com%2F%3Furl%3Dhttp%253A%252F%252Ftveyes.com%26data%3D02%257C01%257CRebecca.Dersh%2540cision.com%257Cad7a3bae89ec448a954708d79e902e1b%257C887bf9ee3c824b88bcb280d5e169b99b%257C1%257C0%257C637152214420581642%26sdata%3D2hTKp4aIZ%252BNr5%252BTA99b5TYgR08Kt7YhZoPwh2E0ZNR0%253D%26reserved%3D0&amp;a=tveyes.com" rel="nofollow" target="_blank">tveyes.com</a>.</p>
<p><b>Media Contacts</b></p>
<p>Cision<br class="dnr"><span class="xn-person">Rebecca Dersh</span><br class="dnr"><a href="mailto:cisionpr@cision.com" rel="nofollow" target="_blank">cisionpr@cision.com</a></p>
<p>TVEyes<br class="dnr"><span class="xn-person">Susan Aitkin</span><br class="dnr"><a href="mailto:saitkin@tveyes.com" rel="nofollow" target="_blank">saitkin@tveyes.com</a></p>
<p>SOURCE Cision Ltd.</p>
<img alt="" src="https://rt.prnewswire.com/rt.gif?NewsItemId=CG31211&amp;Transmission_Id=202002261230PR_NEWS_USPR_____CG31211&amp;DateId=20200226" style="border: 0.0px;width: 1.0px;height: 1.0px;"><h4> Related Links</h4><p> <a title="Link to http://www.cision.com" href="http://www.cision.com" class="linkOnClick" rel="nofollow" target="_blank">http://www.cision.com</a><br></p>
</div>
`;
export const MOCK_ANNOUNCEMENT: ThreadData = {
  dateDelivered: 1595530635918,
  from: 'test@test.com',
  messages: [
    {
      // NOTE: including all response fields and commenting out those that have no immediate effect.
      // when we come back here for single-thread API loading, we can make sure to only implement
      // fields we actively use.
      //
      // accountId: null,
      // ownerId: null,
      // id: 'mock-email-announcement-id',
      // messageId: 'mock-email-announcement-id',
      // threadId: 'mock-email-announcement-id',
      // replyToMessageId: null,
      // snippet:
      //   'Today Cision announced an expansion of its partnership with TVEyes',
      // subject:
      //   'Cision Announces Podcast Monitoring in Its Next Generation Communications Cloud',
      // dateCreated: 1595530635918,
      dateDelivered: 1595530635918,
      // dateReceived: null,
      // dateDeleted: null,
      // dateOpened: null,
      // unread: false,
      // starred: false,
      body: MOCK_ANNOUNCEMENT_BODY,
      // normally these two props, bodySafeHtml and bodyText, are created on the client via
      // composed Redux selectors that ultimately call getOutreachMessageByIdSelector while
      // building this messages object.
      // faking them out here instead so we don't have to fake out any Redux state
      bodySafeHtml: MOCK_ANNOUNCEMENT_BODY,
      bodyText: MOCK_ANNOUNCEMENT_BODY,
      // ^
      // status: null,
      // messageType: null,
      from: [
        {
          // contactType: null,
          // id: null,
          // idString: null,
          email: 'cisionpr@cision.com',
          name: 'Rebecca Dersh',
        },
      ],
      replyTo: [
        // {
        //   contactType: null,
        //   id: null,
        //   idString: null,
        //   email: 'hello@cision.com',
        //   name: '',
        // },
      ],
      to: [
        // {
        //   contactType: null,
        //   id: null,
        //   idString: null,
        //   email: 'jorge.spaciuk@cision.com',
        //   name: 'Jorge Spaciuk',
        // },
      ],
      cc: [
        // {
        //   contactType: null,
        //   id: null,
        //   idString: null,
        //   email: 'hello@cision.com',
        //   name: '',
        // },
      ],
      bcc: [],
      // contexts: [],
      // folder: null,
      // labels: [],
      // campaigns: null,
      // stories: null,
      // authors: null,
      // authorLists: [],
      // influencers: null,
      // influencerLists: [],
      // fromTk: false,
      // attachments: null,
    },
  ],
  recipients: [
    {
      influencer_id: '1',
      first_name: 'Amy',
      last_name: 'Mock-Smith',
      salutation: 'Mrs.',
      email: 'a.smith@thesun.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '2',
      full_name: 'Cody Fox',
      email: 'cody.fox@thelocalspain.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '3',
      full_name: 'Caitlin Watson',
      email: 'caitlinw@cnbc.com',
      stats: {
        delivered: 1,
        sent: 1,
        opened: 0,
        clicked: {
          count: 0,
          urls: [],
        },
        bounced: 0,
        unsubscribed: 0,
        account_unsubscribed: 0,
        global_unsubscribed: 0,
      },
    },
    {
      influencer_id: '4',
      full_name: 'Dianne Cooper',
      email: 'diannecooper@bbc.uk.co',
      stats: {
        bounced: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '5',
      full_name: 'Doug Richards',
      stats: {
        account_unsubscribed: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '6',
      full_name: 'Guy Flores',
      email: 'gflores@theindependent.com',
      stats: {
        opened: 1,
        unsubscribed: 1,
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '7',
      full_name: 'Jessica Edwards',
      email: 'J.Edwards@theeconomists.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '8',
      full_name: 'Tim Pitt',
      email: 'Tim@motoringresearch.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '9',
      full_name: 'Paul Clifton',
      email: 'Paul.clifton@bbc.co.uk',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '10',
      full_name: 'Ronan Glon',
      email: 'Ronan@digitaltrends.com',
      stats: {
        delivered: 1,
        sent: 1,
        opened: 0,
        clicked: {
          count: 0,
          urls: [],
        },
        bounced: 0,
        unsubscribed: 0,
        account_unsubscribed: 0,
        global_unsubscribed: 0,
      },
    },
    {
      influencer_id: '11',
      full_name: 'Sam Abuelsamid',
      email: 'Sam.abuelsamid@navigant.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '12',
      full_name: 'Philip Nolan',
      email: 'Philipnolan@nolanmedia.net',
      stats: {
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '13',
      full_name: 'Ray Massey',
      email: 'Ray.massey@dailymail.co.uk ',
      stats: {
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '14',
      full_name: 'Jared Gall',
      email: 'Jgall@hearst.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '15',
      full_name: 'Josh Max',
      email: 'jm@thelocalspain.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '16',
      full_name: 'Tom Payne',
      email: 'Tom.payne@dailymail.co.uk',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '17',
      full_name: 'Liane Yvkoff',
      email: 'Lianeyvkoff@yahoo.com',
      stats: {
        delivered: 1,
        sent: 1,
        opened: 0,
        clicked: {
          count: 0,
          urls: [],
        },
        bounced: 0,
        unsubscribed: 0,
        account_unsubscribed: 0,
        global_unsubscribed: 0,
      },
    },
    {
      influencer_id: '18',
      full_name: 'David Shepardson',
      email: 'David.shepardson@tr.com',
      stats: {
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '19',
      full_name: 'Christian Sylt',
      email: 'Chris@formulamoney.com',
      stats: {
        delivered: 1,
        sent: 1,
        clicked: {
          count: 11,
          urls: [
            {
              url:
                'https://www.trustradius.com/reviews/cision-communications-cloud-2020-09-04-08-28-10',
              count: 5,
            },
            {
              url: 'https://www.cision.com/products/',
              count: 2,
            },
            {
              url: 'https://www.cision.com/',
              count: 2,
            },
            {
              url: 'https://www.trustradius.com/',
              count: 2,
            },
          ],
        },
      },
    },
    {
      influencer_id: '20',
      full_name: 'Andrew Wendler',
      email: 'awendler@thelocalspain.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '21',
      full_name: 'Neil Winton',
      email: 'Neil@wintonsworld.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '22',
      full_name: 'Brooke Crothers',
      email: 'Mbcrothers@gmail.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '23',
      full_name: 'Sean Szymkowski',
      email: 'Sean.szymkowski@cnet.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '24',
      full_name: 'Greg Gardner',
      email: 'greg.gardner@thelocal.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '25',
      full_name: 'Drew Phillips',
      email: 'Drew.phillips@autoblog.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '26',
      full_name: 'Antuan Goodwin',
      email: 'Antuan.goodwin@cbsinteractive.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '27',
      full_name: 'Jonathan Wong',
      email: 'Jon.wong@cbsinteractive.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '28',
      full_name: 'Steven Ewing',
      email: 'Steven.ewing@cnet.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '29',
      full_name: 'Andrew Frankel',
      email: 'Andrewfrankel@alfproductions.co.uk',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '30',
      full_name: 'Andrew Frankel',
      email: 'Andrew.krok@cbsinteractive.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '31',
      full_name: 'Emme Hall',
      email: 'Emme.hall@cbsinteractive.com',
      stats: {
        opened: 1,
        delivered: 1,
      },
    },
    {
      influencer_id: '32',
      full_name: 'Tim Stevens',
      email: 'Tim@theroadshow.com',
      stats: {
        delivered: 1,
      },
    },
    {
      influencer_id: '33',
      full_name: 'Joseph Capparella',
      email: 'Jcapparella@hearst.com ',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '34',
      full_name: 'Gary Gastelu',
      email: 'Gary.gastelu@foxnews.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '35',
      full_name: 'Roadshow - CNET',
      email: 'Cartech@cnet.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '36',
      full_name: 'John Brandon',
      email: 'Johnbrandonbb@gmail.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '37',
      full_name: 'Chuck Tannert',
      email: 'Ctannert@forbes.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '38',
      full_name: 'Jeremy Korzeniewski',
      email: 'Jeremy.korzeniewski@autoblog.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '39',
      full_name: 'Jeff Plungis',
      email: 'Jeff.plungis@consumer.org',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '40',
      full_name: 'Cheat Sheet',
      email: 'Editors@wallstcheatsheet.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '41',
      full_name: 'Zac Palmer',
      email: 'Zac.palmer@autoblog.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '42',
      full_name: 'Joel Stocksdale',
      email: 'Joel.stocksdale@autoblog.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '43',
      full_name: 'Laura Laker',
      email: 'Lahlaker@gmail.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '44',
      full_name: 'Luke Chillingsworth',
      email: 'Luke.chillingsworth@reachplc.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '45',
      full_name: 'John Snyder',
      email: 'John.snyder@autoblog.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '46',
      full_name: 'Jason Fogelson',
      email: 'Jason@fogelson.com',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '47',
      full_name: 'Greg Rasa',
      email: 'Greg.rasa@autoblog.com ',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '48',
      full_name: 'Rob Lewis',
      email: 'Rob.lewis@the-sun.co.uk',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '49',
      full_name: 'James Gratton',
      email: 'James.gratton@the-sun.co.uk',
      stats: {
        delivered: 1,
        sent: 1,
      },
    },
    {
      influencer_id: '50',
      full_name: 'Clifford Atiyeh',
      email: 'Clifford.atiyeh@live.com',
      stats: {
        opened: 1,
        delivered: 1,
        sent: 1,
      },
    },
  ],
  recipientsFilteredTotal: 50,
  recipientsTotal: 50,
  stats: {
    opened: 20,
    notClicked: 3,
    notOpened: 0,
    clicked: 8,
    bounced: 1,
    unsubscribed: 1,
    recipients: 50,
    delivered: 48,
    sent: 50,
    notDelivered: 0,
    notSent: 0,
  },
  subject:
    'Cision Announces Podcast Monitoring in Its Next Generation Communications Cloud',
  title: 'Podcast Monitoring',
};

const resolveViewModel = ({
  useMockData = false,
  messagesData,
  recipientsData,
  statsData,
  threadData,
}): ThreadData =>
  useMockData
    ? MOCK_ANNOUNCEMENT
    : {
        dateDelivered: threadData?.dateDelivered,
        from: threadData?.from,
        messages: messagesData,
        recipients: recipientsData?.recipients,
        recipientsFilteredTotal: recipientsData?.totalFilteredRecipients,
        recipientsTotal: recipientsData?.totalDocumentRecipients,
        stats: statsData,
        subject: threadData?.subject,
        title: threadData?.title,
      };

export default resolveViewModel;
