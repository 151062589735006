import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const alsoFollowsReducerFactory = namespace => {
  const GET_ALSOFOLLOWS_START = `ihub/${namespace}/GET_ALSOFOLLOWS_START`;
  const GET_ALSOFOLLOWS_SUCCESS = `ihub/${namespace}/GET_ALSOFOLLOWS_SUCCESS`;
  const GET_ALSOFOLLOWS_ERROR = `ihub/${namespace}/GET_ALSOFOLLOWS_ERROR`;

  const getTwitterHandle = socialProfiles => {
    return socialProfiles?.find(profile => profile?.networkName === 'twitter')
      ?.handle;
  };

  const getEmail = contacts => {
    return contacts?.find(contact => contact?.type === 'email')?.text;
  };

  const parseResponse = influencers => {
    const followers = influencers.map(influencer => {
      return {
        name: influencer.influencer.name,
        jobRole: influencer.influencer.jobRole,
        email: getEmail(influencer.influencer.contacts),
        id: influencer.influencer.id,
        twitter_handle: getTwitterHandle(influencer.influencer.socialProfiles),
        profile_image_url_https: influencer.influencer.image,
        profile_image_url: influencer.influencer.image,
        city: influencer.influencer.location.city,
        state: influencer.influencer.location.state,
        country: influencer.influencer.location.country,
        toptopics: influencer.influencer.topics,
        twitterFollowers: influencer.influencer.twitterFollowersCount,
        youTubeFollowers: influencer.influencer.youTubeFollowersCount,
        instagramFollowers: influencer.influencer.instagramFollowersCount,
        twitchFollowers: influencer.influencer.twitchFollowersCount,
      };
    });
    return followers;
  };

  const getAlsoFollowsForInfluencer = influencer => async dispatch => {
    dispatch({ type: GET_ALSOFOLLOWS_START });
    const payload = {
      totalCount: 0,
      data: [],
    };

    try {
      const response = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/influencer/${influencer.id}/also-follows`,
      );

      if (response && response.data && response.data.influencers) {
        payload.data = parseResponse(response.data.influencers);
        dispatch({ type: GET_ALSOFOLLOWS_SUCCESS, payload: payload });
      }
    } catch (e) {
      dispatch({ type: GET_ALSOFOLLOWS_ERROR, payload: e });
    }
  };

  const alsoFollowsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_ALSOFOLLOWS_START:
        return {
          ...state,
          loading: true,
          error: false,
          totalCount: 0,
          data: [],
        };

      case GET_ALSOFOLLOWS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          totalCount: action.payload.totalCount,
          data: [...action.payload.data],
        };

      case GET_ALSOFOLLOWS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  return {
    alsoFollowsReducer,
    GET_ALSOFOLLOWS_START,
    GET_ALSOFOLLOWS_SUCCESS,
    GET_ALSOFOLLOWS_ERROR,
    getAlsoFollowsForInfluencer,
  };
};

export default alsoFollowsReducerFactory;
