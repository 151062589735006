import { isString } from 'lodash';
import Cookies from 'universal-cookie';

import {
  ACCOUNT_CREATE_ENDPOINT,
  USER_GET_ENDPOINT,
  USER_SAVE_ENDPOINT,
  USER_SEND_NEW_USER_EMAIL_ENDPOINT,
  USER_PASSWORD_MANAGEMENT_ENDPOINT,
  ADMIN_USER_IMPERSONATE_URL,
  DASHBOARDS_LIST_ENDPOINT,
} from 'constants/apis';
import { DEV_FEATURE_OVERRIDE_COOKIE_KEY } from 'constants/constants';

import addMessages from 'pages/Admin/UserManagement/AddUser/AddUser.messages';
import editMessages from 'pages/Admin/UserManagement/EditUser/EditUser.messages';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';

import {
  performGet,
  performPost,
  performPut,
} from 'services/rest-service/rest-service';

export const GET_USER = 'adminUserManagement/GET_USER';
export const GET_USER_SUCCESS = 'adminUserManagement/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'adminUserManagement/GET_USER_ERROR';

export const GET_USERS = 'adminUserManagement/GET_USERS';
export const GET_USERS_SUCCESS = 'adminUserManagement/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'adminUserManagement/GET_USERS_ERROR';

export const ADD_USER = 'adminUserManagement/ADD_USER';
export const ADD_USER_SUCCESS = 'adminUserManagement/ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'adminUserManagement/ADD_USER_ERROR';

export const ADD_USER_ACCOUNT = 'adminUserManagement/ADD_USER_ACCOUNT';
export const ADD_USER_ACCOUNT_SUCCESS =
  'adminUserManagement/ADD_USER_ACCOUNT_SUCCESS';
export const ADD_USER_ACCOUNT_ERROR =
  'adminUserManagement/ADD_USER_ACCOUNT_ERROR';

export const UPDATE_USER = 'adminUserManagement/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'adminUserManagement/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'adminUserManagement/UPDATE_USER_ERROR';

export const UPDATE_USER_PASSWORD = 'adminUserManagement/UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS =
  'adminUserManagement/UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_ERROR =
  'adminUserManagement/UPDATE_USER_PASSWORD_ERROR';

export const SEND_NEW_USER_EMAIL = 'adminUserManagement/SEND_NEW_USER_EMAIL';
export const SEND_NEW_USER_EMAIL_SUCCESS =
  'adminUserManagement/SEND_NEW_USER_EMAIL_SUCCESS';
export const SEND_NEW_USER_EMAIL_ERROR =
  'adminUserManagement/SEND_NEW_USER_EMAIL_ERROR';

export const BEGIN_IMPERSONATE_USERS_INFO =
  'adminUserManagement/BEGIN_IMPERSONATE_USERS_INFO';
export const BEGIN_IMPERSONATE_USERS_INFO_SUCCESS =
  'adminUserManagement/BEGIN_IMPERSONATE_USERS_INFO_SUCCESS';
export const BEGIN_IMPERSONATE_USERS_INFO_ERROR =
  'adminUserManagement/BEGIN_IMPERSONATE_USERS_INFO_ERROR';
export const SET_SELECTED_USER_TO_IMPERSONATE =
  'adminUserManagement/SET_SELECTED_USER_TO_IMPERSONATE';

export const initialState = {
  accounts: [],
  user: null,
  users: [],
  paginator: {},
  loading: false,
  error: null,
  selectedUserToImpersonate: {},
};

const DEFAULT_PASSWORD_ADD_USER = 'defaultPassword123';
const additionalHeaders = {
  'X-API-UI': true,
};

const adminUserManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: null,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        error: null,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.payload.error,
      };
    case GET_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        paginator: action.payload.paginator,
        loading: false,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        users: [],
        paginator: {},
        loading: false,
      };
    case ADD_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_USER_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_USER_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SEND_NEW_USER_EMAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        error: null,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SEND_NEW_USER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SEND_NEW_USER_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case BEGIN_IMPERSONATE_USERS_INFO: {
      return {
        ...state,
        loading: true,
      };
    }
    case BEGIN_IMPERSONATE_USERS_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case BEGIN_IMPERSONATE_USERS_INFO_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_SELECTED_USER_TO_IMPERSONATE: {
      return {
        ...state,
        selectedUserToImpersonate: action.payload,
      };
    }
    default:
      return state;
  }
};

export const setSeletedUserToImpersonateAdmin = user => async dispatch => {
  return dispatch({ type: SET_SELECTED_USER_TO_IMPERSONATE, payload: user });
};

export const getUser = (userId, intl) => async dispatch => {
  dispatch({ type: GET_USER });
  performGet(USER_GET_ENDPOINT + '/' + userId, null, additionalHeaders)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: { user: response.data },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_USER_ERROR, payload: { error: e.response } });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.getUserErrorMsg, {
            USER_ID: userId,
          }),
          status: 'danger',
        }),
      );
    });
};

export const getUsers = (params, intl) => dispatch => {
  dispatch({ type: GET_USERS });
  performGet(USER_GET_ENDPOINT, params, additionalHeaders)
    .then(resp => {
      if (resp.status === 200 && resp.data) {
        const { paginator, users } = resp.data;
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: {
            paginator,
            users,
          },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_USERS_ERROR, payload: { error: e.response } });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.getUsersErrorMsg),
          status: 'danger',
        }),
      );
    });
};

export const sendNewUserEmailAction = (username, intl) => async dispatch => {
  const userInfo = {
    username: username,
    isNewUser: true,
  };

  dispatch({ type: SEND_NEW_USER_EMAIL });
  return performPost(USER_SEND_NEW_USER_EMAIL_ENDPOINT, userInfo)
    .then(response => {
      if (response.status === 200) {
        dispatch({ type: SEND_NEW_USER_EMAIL_SUCCESS });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(addMessages.sendNewUserEmailSuccessMsg, {
              USER_NAME: username,
            }),
            status: 'success',
          }),
        );
      }
    })
    .catch(e => {
      dispatch({
        type: SEND_NEW_USER_EMAIL_ERROR,
        payload: { error: e.response },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(addMessages.sendNewUserEmailErrorMsg, {
            USER_NAME: username,
          }),
          status: 'danger',
        }),
      );
    });
};

export const addUserActionCreator = (
  body,
  sendNewUserEmail,
  intl,
) => async dispatch => {
  dispatch({ type: ADD_USER });
  return performPut(USER_SAVE_ENDPOINT, body)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({ type: ADD_USER_SUCCESS });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(addMessages.addUserSuccessMsg, {
              USER_NAME: body.username,
            }),
            status: 'success',
          }),
        );
        if (sendNewUserEmail) {
          return dispatch(sendNewUserEmailAction(body.username, intl));
        }
      }
    })
    .catch(e => {
      dispatch({ type: ADD_USER_ERROR, payload: { error: e.response } });
      const { status } = e.response;
      const text =
        status === 409
          ? intl.formatMessage(addMessages.alreadyExistsErrorMsg)
          : intl.formatMessage(addMessages.addUserDefaultErrorMsg, {
              username: body.username,
            });
      dispatch(addPageMessageWithDefaultTimeout({ text, status: 'danger' }));
      throw e.response;
    });
};

export const editUserAction = (user, userId, intl) => async dispatch => {
  dispatch({ type: UPDATE_USER });
  return performPut(
    USER_GET_ENDPOINT + '/' + userId,
    user,
    null,
    additionalHeaders,
  )
    .then(response => {
      const isValidResponse =
        response.status === 200 && response.data && !isString(response.data);

      const currentState = isValidResponse
        ? {
            type: UPDATE_USER_SUCCESS,
            payload: { user: response.data },
          }
        : {
            type: UPDATE_USER_ERROR,
            payload: { user: response.data },
          };
      const message = isValidResponse
        ? {
            text: intl.formatMessage(editMessages.editUserSuccessMsg, {
              USER_NAME: user.username,
            }),
            status: 'success',
          }
        : {
            text: intl.formatMessage(editMessages.editUserDefaultErrorMsg, {
              USER_NAME: user.username,
            }),
            status: 'danger',
          };

      dispatch(currentState);
      dispatch(addPageMessageWithDefaultTimeout(message));
      if (!isValidResponse) {
        throw response.data;
      }
    })
    .catch(e => {
      dispatch({ type: UPDATE_USER_ERROR, payload: { error: e.response } });
      const { status } = e.response;
      const text =
        status === 409
          ? intl.formatMessage(editMessages.alreadyExistsErrorMsg)
          : intl.formatMessage(editMessages.editUserDefaultErrorMsg, {
              USER_NAME: user.username,
            });
      dispatch(addPageMessageWithDefaultTimeout({ text, status: 'danger' }));
      throw e.response;
    });
};

export const resetPasswordAction = (userId, intl) => async dispatch => {
  dispatch({ type: UPDATE_USER_PASSWORD });
  performPut(USER_PASSWORD_MANAGEMENT_ENDPOINT, { userId })
    .then(response => {
      if (response.status === 200 && response.data) {
        const password = response.data.password;
        dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS });
        dispatch(
          addPageMessage({
            text: intl.formatMessage(editMessages.resetPasswordSuccessMsg, {
              USER_PASSWORD: password,
            }),
            status: 'success',
          }),
        );
      }
    })
    .catch(e => {
      dispatch({
        type: UPDATE_USER_PASSWORD_ERROR,
        payload: { error: e.response },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.resetPasswordDefaultErrorMsg),
          status: 'danger',
        }),
      );
    });
};

export const addAccountAndOrUserActionCreator = (
  body,
  intl,
) => async dispatch => {
  const userInfo = {
    username: body.userName,
    first_name: body.firstName,
    last_name: body.lastName,
    pass1: body.sendNewUserEmail ? DEFAULT_PASSWORD_ADD_USER : body.newPassword,
    pass2: body.sendNewUserEmail
      ? DEFAULT_PASSWORD_ADD_USER
      : body.confirmNewPassword,
    account_type: body.accountType?.id,
    account: body.currentAccount?.id,
    addImpersonator: body.addImpersonator,
    addCustomerAdmin: body.addCustomerAdmin,
    addAdminLite: body.addAdminLite,
    addAdminContentSearch: body.addAdminContentSearch,
  };

  if (body.addToExistingAccount) {
    return dispatch(
      addUserActionCreator(userInfo, body.sendNewUserEmail, intl),
    );
  } else {
    const accountInfo = {
      name: body.newAccountName,
      sfdcId: body.newAccountId,
      type: body.accountType?.id,
    };

    dispatch({ type: ADD_USER_ACCOUNT });
    return performPost(ACCOUNT_CREATE_ENDPOINT, accountInfo)
      .then(response => {
        const { id } = response.data || {};
        if (id) {
          dispatch({ type: ADD_USER_ACCOUNT_SUCCESS });
          dispatch(
            addPageMessage({
              text: intl.formatMessage(addMessages.addUserAccountSuccessMsg, {
                ACCOUNT_NAME: accountInfo.name,
              }),
              status: 'success',
            }),
          );
          userInfo.account = id;
          return dispatch(
            addUserActionCreator(userInfo, body.sendNewUserEmail, intl),
          );
        }
      })
      .catch(e => {
        dispatch({
          type: ADD_USER_ACCOUNT_ERROR,
          payload: { error: e.response },
        });
        const { status } = e.response;
        const { error } = e.response?.data;
        const text =
          status === 409 && error === 'Invalid sfdcId'
            ? addMessages.invalidGlobalCustomerIdErrorMsg
            : status === 409 && error === 'Duplicate sfdcId'
            ? addMessages.accountDuplicateIdErrorMsg
            : addMessages.addUserAccountDefaultErrorMsg;
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(text),
            status: 'danger',
          }),
        );
        throw e.response;
      });
  }
};

export const beginImpersonate = (
  originalUser,
  selectedUser,
) => async dispatch => {
  dispatch({
    type: BEGIN_IMPERSONATE_USERS_INFO,
    originalUserInfo: originalUser,
  });

  try {
    await performPost(`${ADMIN_USER_IMPERSONATE_URL}/${selectedUser.id}`).then(
      resp => {
        const cookies = new Cookies();
        cookies.set('IMPERSONATING', `${originalUser.id}_${selectedUser.id}`, {
          path: '/',
        });

        try {
          const devFeaturesOverrides = cookies.get(
            DEV_FEATURE_OVERRIDE_COOKIE_KEY,
          );
          const devFeaturesOverridesJson = devFeaturesOverrides
            ? JSON.parse(devFeaturesOverrides)
            : null;

          Object.keys(devFeaturesOverrides).forEach(key => {
            devFeaturesOverrides[key].e = -1;
          });

          cookies.set(
            DEV_FEATURE_OVERRIDE_COOKIE_KEY,
            JSON.stringify(devFeaturesOverridesJson),
            { path: '/' },
          );
        } catch (err) {
          cookies.remove(DEV_FEATURE_OVERRIDE_COOKIE_KEY);
        }
        performGet(DASHBOARDS_LIST_ENDPOINT).then(resp => {
          dispatch({ type: BEGIN_IMPERSONATE_USERS_INFO_SUCCESS });
          window.location = '/';
        });
      },
    );
  } catch (e) {
    dispatch({
      type: GET_USERS_ERROR,
      payload: { error: e },
    });
  }
};

export default adminUserManagementReducer;
