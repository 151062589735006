export default {
  addContacts: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_ADD_CONTACTS_BUTTON', // The ID needs to be globally unique
    description:
      'Button text for the button that allows users to add recipients to a story kit',
    defaultMessage: 'Add Contacts',
  },
  setRelationshipStatus: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_SET_RELATIONSHIP_STATUS', // The ID needs to be globally unique
    description:
      'Prompt to display to indicate changing the relationship for a story kit recipient',
    defaultMessage: 'Set relationship status',
  },
  setRelationshipStatusSuccess: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_SET_RELATIONSHIP_STATUS_SUCCESS',
    description:
      'Kite message to indicate successfully changing the relationship for a story kit recipient',
    defaultMessage: "Successfully updated contact's relationship status",
  },
  setRelationshipStatusFailed: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_SET_RELATIONSHIP_STATUS_FAILED',
    description:
      'Kite message to indicate error changing the relationship for a story kit recipient',
    defaultMessage: "Error changing contact's relationship status",
  },
  changePitchStage: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_CHANGE_PITCH_STAGE', // The ID needs to be globally unique
    description:
      'Prompt to display to indicate changing the stage a recipient of a story kit should be in',
    defaultMessage: 'Change pitch stage',
  },
  shareStoryKitContactsTitle: {
    id: 'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_TITLE',
    description:
      'When there are no contacts in a Story Kit, this is the title that will display',
    defaultMessage: 'Share Your Story Kit',
  },
  shareStoryKitPinpointContacts: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_MESSAGE_PINPOINT', // The ID needs to be globally unique
    description:
      'When there are no contacts in a Story Kit and the account uses Pinpoint Contacts, this is the blurb that will display',
    defaultMessage: `
          Share your Story Kit with any Pinpoint Contacts you’ve saved by adding
          them here. Once your Story Kit has been published each contact will be
          assigned a unique URL to access this Story Kit. Using that URL will
          allow you to track your Story Kit’s effectiveness.`,
  },
  shareStoryKitInfluencers: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_MESSAGE_INFLUENCERS', // The ID needs to be globally unique
    description:
      'When there are no contacts in a Story Kit and the account uses Influencer Hub, this is the blurb that will display',
    defaultMessage: `
          Share your Story Kit with any Journalists, Outlets, and Social Influencers
          you’ve saved by adding them here. Once your Story Kit has been published
          each contact will be assigned a unique URL to access this Story Kit. Using
          that URL will allow you to track your Story Kit’s effectiveness.`,
  },
  liveStoryKitMessage: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_LIVE_STORY_KIT_MESSAGE', // The ID needs to be globally unique
    description:
      'After a story kit is published, this message will display to help users take action',
    defaultMessage: `
                  Now that this Story Kit is live, you can share it with your
                  contacts. Send the individual tracking links associated with
                  each contact as part of your normal pitching activities to
                  track your outreach and performance.`,
  },
  loadingContactsEmailStatus: {
    id: 'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_LOADING_EMAIL_STATUS_MESSAGE', // The ID needs to be globally unique
    description: 'Loading contacts&apos; email status message',
    defaultMessage: 'Loading contacts&apos; email status',
  },
  storyKitContactTabEmailNotEmailedContacts: {
    id: 'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_NOT_EMAILED_CONTACTS_MESSAGE', // The ID needs to be globally unique
    description: `Some contacts ({uncontactedRecipientIdsWithEmailLength} of
      {recipientsWithEmailCount}) have not been emailed.`,
    defaultMessage: 'Loading contacts&apos; email status',
  },
  invalidMaxKiteText: {
    id:
      'PAGES_STORIES_STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_INVALID_KITE_TEXT',
    defaultMessage:
      'This story already has the maximum number of recipients ({MAXIMUM_RECIPIENTS}).',
    description:
      'Body text of the warning that appears when the user tries to add too many influencers to a story.',
  },
  invalidMaxKiteTitle: {
    id:
      'PAGES_STORIES_STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_INVALID_KITE_TITLE',
    defaultMessage: "You can't add any more recipients",
    description:
      'Title of the warning that appears when the user tries to add too many influencers to a story.',
  },
  invalidMaxRecipientsTitle: {
    id:
      'PAGES_STORIES_STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_INVALID_MAXIMUM_RECIPIENTS_TITLE',
    description:
      'Title of warning that appears when a user tries to add Pinpoint Contacts to a story kit with over 200 contacts.',
    defaultMessage: 'This Story Kit has too many recipients',
  },
  invalidMaxRecipientsText: {
    id:
      'PAGES_STORIES_STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_INVALID_MAXIMUM_RECIPIENTS_TEXT',
    description:
      'Body text of warning that appears when a user tries to add Pinpoint Contacts to a story kit with over 200 contacts.',
    defaultMessage:
      'A Story Kit can have a maximum of 200 recipients, please reduce the number of current recipients and try again',
  },
  storyKitLoadErrorTitle: {
    id:
      'PAGES_STORIES_STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_STORY_KIT_LOAD_ERROR_TITLE',
    description: 'Title of error that appears when data cannot be loaded.',
    defaultMessage: 'Sorry, something went wrong.',
  },
  storyKitLoadErrorText: {
    id:
      'PAGES_STORIES_STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_STORY_KIT_LOAD_ERROR_TEXT',
    defaultMessage:
      "An error has occurred and we couldn't load your data. Please try refreshing this page. Sorry for any inconvenience.",
    description: 'Body text of error that appears when data cannot be loaded.',
  },
  addRecipients: {
    id:
      'PAGES_STORIES_STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_STORY_KIT_CONTACTS_LIST_SIDE_TRAY_TITLE',
    defaultMessage: 'Add Recipients',
    description: 'Title for the contacts list side tray',
  },
  storyKitAddContactInfluencerOption: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_ADD_CONTACTS_INFLUENCERS',
    defaultMessage: 'Influencers',
    description:
      'When there are no contacts in a Story Kit and the account uses Influencer Hub, the add contacts button has an influencers option',
  },
  storyKitAddContactPinpointContactsOption: {
    id:
      'STORY_HUB_STORY_HUB_RECIPIENTS_CONTAINER_EMPTY_CONTACTS_ADD_CONTACTS_PINPOINT',
    defaultMessage: 'Pinpoint Contacts',
    description:
      'When there are no contacts in a Story Kit and the account uses Influencer Hub, the add contacts button has an pinpoint contacts option',
  },
  storyKitContactTabHeaderTitle: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_ADD_CONTACTS_HEADER_TITLE',
    defaultMessage: 'Contacts you have shared this story with',
    description:
      'Header title in Story kit - CONTACTS tab, next to ADD CONTACT button',
  },
  storyKitContactTabEmailThemNow: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_ADD_CONTACTS_EMAIL_THEM_NOW',
    defaultMessage: 'Email Them Now',
    description: 'Story kit - CONTACTS - , next to ADD CONTACT button',
  },
  storyKitContactTabDownloadCSV: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_DOWNLOAD_CSV_MESSAGE',
    defaultMessage: 'Download list as CSV',
    description: 'Story kit - CONTACTS, download csv message',
  },
  storyKitEmailList: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_EMAIL_LIST_MESSAGE',
    defaultMessage: 'Email List',
    description: 'Story kit - CONTACTS, Email List dropdown message',
  },
  searchRecipients: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_EMAIL_LIST_SEARCH_RECIPIENTS_MESSAGE',
    defaultMessage: 'Search Recipients',
    description: 'Story kit - CONTACTS, Email List Search Recipients message',
  },
  storyKitEmailListDropdownOptionAllContacts: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_EMAIL_LIST_ALL_CONTACTS_OPTION',
    defaultMessage: 'All Contacts',
    description:
      'Story kit - CONTACTS, Email List dropdown option ALL CONTACTS text',
  },
  storyKitEmailListDropdownOptionNewContacts: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_EMAIL_LIST_NEW_CONTACTS_OPTION',
    defaultMessage: 'New Contacts',
    description:
      'Story kit - CONTACTS, Email List dropdown option NEW CONTACTS text',
  },
  storyKitEmailListProcessingSending: {
    id:
      'STORY_HUB_STORY_HUB_CONTACTS_EMAIL_LIST_PROCESSING_CONTACTS_FOR_SENDING',
    defaultMessage: 'Processing contacts for sending…',
    description:
      'Story kit - CONTACTS, Email List "Processing contacts for sending…" message',
  },
  storyKitViewStoryKit: {
    id: 'STORY_HUB_STORY_HUB_CONTACTS_EMAIL_LIST_VIEW_STORY_KIT_HERE',
    defaultMessage: 'View the Story Kit here:',
    description: 'Story kit - CONTACTS "View the Story Kit here:" message',
  },
};
