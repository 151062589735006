export default {
  editVideosHeader: {
    id: 'EDIT-STOTY-VIDEOS_HEADER',
    defaultMessage: 'Related Videos',
    description:
      'Campaigns > Story Kit > Story Edit Page: click on related videos, header',
  },
  addAnotherLink: {
    id: 'EDIT-STOTY-VIDEOS_ADD_ANOTHER_LINK',
    defaultMessage: 'Add another link',
    description:
      'Campaigns > Story Kit > Story Edit Page: click on related videos, add another link',
  },
  urlPlaceholder: {
    id: 'EDIT-STOTY-VIDEOS_URL_PLACEHOLDER',
    defaultMessage: 'http://',
    description:
      'Campaigns > Story Kit > Story Edit Page: click on related videos, url placeholder',
  },
  titlePlaceholder: {
    id: 'EDIT-STOTY-VIDEOS_TITLE_PLACEHOLDER',
    defaultMessage: 'Title (optional)',
    description:
      'Campaigns > Story Kit > Story Edit Page: click on related videos, title placeholder',
  },
  videoIndex: {
    id: 'EDIT-STOTY-VIDEOS_VIDEO_INDEX',
    defaultMessage: 'Video {NUMBER}',
    description:
      'Campaigns > Story Kit > Story Edit Page: click on related videos, video index label',
  },
  invalidVideoUrlValidationMessage: {
    id: 'EDIT-STORY-VIDEOS_URL_VALIDATION_MESSAGE',
    defaultMessage: 'Invalid URL. Only YouTube and Vimeo links accepted.',
    description: 'Message indicating the story contains an invalid video URL',
  },
};
