import { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import useValueDidNotChange from 'hooks/use-value-did-not-change';

import { pageTrackerConfigs as defaultPageTrackerConfigs } from './config';
import type { Matcher, TrackerConfig } from './config';

import { maxDuration, detectionStrategies } from './constants';

import getOpenHttpsCount from './getOpenHttpsCount';

declare const window: {
  cancelIdleCallback: any;
  clearInterval: any;
  requestIdleCallback: any;
  setInterval: any;
};

const isMatchingPage = ({
  matcher,
  pathname,
}: {
  matcher: Matcher;
  pathname: string;
}) =>
  matcher instanceof RegExp
    ? matcher.test(pathname)
    : typeof matcher === 'string'
    ? matcher === pathname
    : false;

const getTrackingConfig = (pathname: string, configs: TrackerConfig[]) =>
  configs.find(trackerConfig =>
    isMatchingPage({ matcher: trackerConfig.matcher, pathname }),
  );

interface LogTransitionArgs {
  detectionStrategy?: string;
  exceededMax?: boolean;
  pathname: string;
  startTimeMs: number;
}

const logTransition = (eventProps: LogTransitionArgs) => {};

interface LogTransitionFromSelectorArgs extends LogTransitionArgs {
  setIsTracking: (arg0: boolean) => void;
}

const logTransitionFromSelector = ({
  setIsTracking,
  ...eventProps
}: LogTransitionFromSelectorArgs) => {
  logTransition({
    ...eventProps,
    detectionStrategy: detectionStrategies.waitForSelector,
  });

  setIsTracking(false);
};

const logWhenNoOpenRequests = ({ pathname, startTimeMs, setIsTracking }) => {
  const exceededMax = new Date().valueOf() - startTimeMs > maxDuration;

  if (getOpenHttpsCount() === 0 || exceededMax) {
    logTransition({
      exceededMax,
      pathname,
      startTimeMs,
    });

    setIsTracking(false);
  }
};

const useIdleCallback = ({ callback, shouldCallback }) => {
  const intervalId = useRef();

  useEffect(() => {
    if (
      !window.requestIdleCallback ||
      !shouldCallback ||
      !window.cancelIdleCallback
    )
      return;

    return () => {
      if (intervalId.current) {
        window.clearInterval(intervalId.current);
      }
    };
  }, [callback, shouldCallback]);
};

type UseSelectorCallbackArgs = {
  callback: () => void;
  selector?: (arg0: any) => any;
  shouldCallback: boolean;
};

const useCallbackWhenSelectorChangesToFalse = ({
  callback,
  selector = () => false,
  shouldCallback,
}: UseSelectorCallbackArgs) => {
  const isLoading = useSelector(selector);
  const startedLoadingOnce = useRef(false);

  useEffect(() => {
    if (shouldCallback && isLoading) startedLoadingOnce.current = true;
  }, [shouldCallback, isLoading]);

  useEffect(() => {
    if (shouldCallback && startedLoadingOnce.current && !isLoading) {
      callback();
    }
  }, [callback, isLoading, shouldCallback]);
};

const usePageTransitionTracker = (
  pathname: string,
  configs = defaultPageTrackerConfigs,
): void => {
  // Currently doesn't log transitions if the user navs away before complete.
  const [isAutoTracking, setIsAutoTracking] = useState(false);
  const [isTrackingBySelector, setIsTrackingBySelector] = useState(false);
  const [startTimeMs, setStartTimeMs] = useState(0);

  const pathnameChanged = !useValueDidNotChange(pathname);
  const trackingConfig = getTrackingConfig(pathname, configs);

  const logWhenNoOpenRequestsCallback = useCallback(
    () =>
      logWhenNoOpenRequests({
        pathname,
        startTimeMs,
        setIsTracking: setIsAutoTracking,
      }),
    [pathname, startTimeMs, setIsAutoTracking],
  );

  useIdleCallback({
    callback: logWhenNoOpenRequestsCallback,
    shouldCallback: isAutoTracking,
  });

  const logWhenSelectorLoadedCallback = useCallback(
    () =>
      logTransitionFromSelector({
        pathname,
        startTimeMs,
        setIsTracking: setIsTrackingBySelector,
      }),
    [pathname, startTimeMs, setIsTrackingBySelector],
  );

  useCallbackWhenSelectorChangesToFalse({
    callback: logWhenSelectorLoadedCallback,
    selector: trackingConfig?.selectorForIsLoading,
    shouldCallback: isTrackingBySelector,
  });

  if (!pathnameChanged) return;

  if (!trackingConfig) {
    setIsAutoTracking(false);
    setIsTrackingBySelector(false);
    return;
  }

  setStartTimeMs(new Date().valueOf());

  const shouldStartTrackingBySelector =
    !isTrackingBySelector && trackingConfig.selectorForIsLoading;
  if (shouldStartTrackingBySelector) setIsTrackingBySelector(true);

  const shouldStopTrackingBySelector =
    isTrackingBySelector && !trackingConfig.selectorForIsLoading;
  if (shouldStopTrackingBySelector) setIsTrackingBySelector(false);

  if (!isAutoTracking) setIsAutoTracking(true);
};

export default usePageTransitionTracker;
