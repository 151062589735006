import {
  DASHBOARDS_LIST_PER_ACCOUNT,
  DASHBOARD_FOR_FEED_ENDPOINT,
} from 'constants/apis';

import { Dashboard } from 'selectors/dashboards/dashboard-hub';
import { performGet } from 'services/rest-service/rest-service';

import {
  AnalyticsListActionCreatorArgs,
  AnalyticsListResultsCollation,
} from '../../analytics-config';

import { getItemCount } from './analytics-list-reducer';

export const LIST_ANALYTICS = 'analytics/LIST_ANALYTICS';
export const LIST_ANALYTICS_SUCCESS = 'analytics/LIST_ANALYTICS_SUCCESS';
export const LIST_ANALYTICS_ERROR = 'analytics/LIST_ANALYTICS_ERROR';
export const GET_DASHBOARD_FOR_FEED = 'analytics/GET_DASHBOARD_FOR_FEED';
export const GET_DASHBOARD_FOR_FEED_ERROR =
  ' analytics/GET_DASHBOARD_FOR_FEED_ERROR';
export const GET_DASHBOARD_FOR_FEED_SUCCESS =
  'analytics/GET_DASHBOARD_FOR_FEED_SUCCESS';
export const CLEAR_ALL_FEED_DATA_WIDGETS =
  'analytics/CLEAR_ALL_FEED_DATA_WIDGETS';
export const CLEAR_ALL_FEED_DATA_DASHBOARDS =
  'analytics/CLEAR_ALL_FEED_DATA_DASHBOARDS';

export const initialState = {
  error: false,
  loading: false,
  items: [],
  listFilters: {
    filtering: false,
    search: '',
  },
  updatingAnalytic: false,
  updatingAnalyticError: null,
  paginationCount: null,
  dashboard: null,
  resultsCollation: {
    pageNumber: 1,
    sort: 'lastUpdated',
    sortDirection: 'desc',
    ownerFilter: 'all',
  } as AnalyticsListResultsCollation,
};

export const analyticsListFeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ANALYTICS:
      return {
        ...state,
        loading: true,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: null,
      };
    case LIST_ANALYTICS_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...(state.dashboard as Dashboard | null),
          layout: [],
        },
        loading: false,
        filtering: action.payload.filtering,
        search: action.payload.search,
        listFilters: {
          filtering: action.payload.filtering,
          search: action.payload.search,
        },
        error: null,
        items: action.payload.items,
        paginationCount: action.payload.paginationCount,
        resultsCollation: action.payload.resultsCollation,
      };
    case LIST_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: action.payload.error,
      };
    case GET_DASHBOARD_FOR_FEED:
      return {
        ...state,
        loading: true,
        dashboard: null,
        error: null,
      };
    case GET_DASHBOARD_FOR_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        dashboard: action.payload.dashboard,
      };
    case GET_DASHBOARD_FOR_FEED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_ALL_FEED_DATA_WIDGETS:
      return {
        ...state,
        dashboard: {},
      };
    case CLEAR_ALL_FEED_DATA_DASHBOARDS:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
};

export const allAnalyticPerAccountActionCreator = ({
  search = null,
  ownerId = null,
  resultsCollation = initialState.resultsCollation,
  accountId = null,
  pageNumber = 1,
}: AnalyticsListActionCreatorArgs = {}) => async dispatch => {
  const isFilter = !!search || !!ownerId;
  dispatch({ type: LIST_ANALYTICS });

  try {
    const allAnalytics = await performGet(
      DASHBOARDS_LIST_PER_ACCOUNT.replace(
        '{accountId}',
        accountId ? accountId.toString() : '',
      ).replace('{pageNumber}', pageNumber.toString()),
    );

    dispatch({
      type: LIST_ANALYTICS_SUCCESS,
      payload: {
        items: allAnalytics.data.results,
        paginationCount: getItemCount(allAnalytics),
        filtering: isFilter,
        search,
        isFilter,
        resultsCollation,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_ANALYTICS_ERROR,
      payload: { error: e.response?.data },
    });
  }
};

export const getDashboardFromAnyUserActionCreator = id => async dispatch => {
  let result;
  dispatch({ type: GET_DASHBOARD_FOR_FEED });

  try {
    result = await performGet(
      DASHBOARD_FOR_FEED_ENDPOINT.replace('{id}', id.toString()),
    );

    dispatch({
      type: GET_DASHBOARD_FOR_FEED_SUCCESS,
      payload: { dashboard: result.data },
    });
  } catch (e) {
    dispatch({
      type: GET_DASHBOARD_FOR_FEED_ERROR,
      payload: { error: { status: e.response.status, message: e.message } },
    });
    throw e;
  }
};

export const clearFeedWidgetsActionCreator = () => dispatch => {
  dispatch({ type: CLEAR_ALL_FEED_DATA_WIDGETS });
};

export const clearFeedDashboardsActionCreator = () => dispatch => {
  dispatch({ type: CLEAR_ALL_FEED_DATA_DASHBOARDS });
};

export default analyticsListFeedReducer;
