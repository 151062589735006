import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Icon, Media } from '@cision/rover-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { Modal } from '@trendkite/ui';

import {
  CAMPAIGNS_ENDPOINT,
  DASHBOARDS_LIST_ENDPOINT,
  INFLUENCER_HUB_ENDPOINT,
  // INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT,
  SEARCH_LIST_ENDPOINT_V2,
} from 'constants/apis';
import {
  CAMPAIGNS_BASE_URL,
  DASHBOARD_BASE_URL,
  INFLUENCERS_HUB_BASE_URL,
  // INFLUENCERS_SAVED_LISTS_BASE_URL,
} from 'constants/constants';

import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { useFetchDataSWR } from 'services/rest-service/rest-service';

import { getRedirectSearchDetailUrl } from 'utils/searches';

import EntitiesList from './EntitiesList';
import messages from './OmniBar.messages';
import styles from './OmniBar.module.css';

export const OmniBar = ({ addPageMessage, className, ...passedProps }) => {
  const queryRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 400);
  const intl = useIntl();

  // Campaigns
  const {
    data: campaignsData,
    error: campaignsError,
    isValidating: campaignsIsValidating,
  } = useFetchDataSWR(
    !debouncedQuery
      ? null
      : {
          method: 'GET',
          url: CAMPAIGNS_ENDPOINT,
        },
  );

  const campaigns = useMemo(() => {
    const responseData = campaignsData?.data.results;
    return ((debouncedQuery && responseData) || []).filter(campaign =>
      campaign.title?.toLowerCase().includes(debouncedQuery.toLowerCase()),
    );
  }, [campaignsData?.data.results, debouncedQuery]);

  const campaignsInfo = useMemo(
    () => ({
      error: campaignsError,
      isFetching: campaignsIsValidating,
      isLoading: campaignsIsValidating && !campaigns.length,
    }),
    [campaigns.length, campaignsError, campaignsIsValidating],
  );

  // Dashboards
  const {
    data: dashboardsData,
    error: dashboardsError,
    isValidating: dashboardsIsValidating,
  } = useFetchDataSWR(
    !debouncedQuery
      ? null
      : {
          method: 'GET',
          url: DASHBOARDS_LIST_ENDPOINT,
        },
  );

  const dashboards = useMemo(
    () =>
      ((debouncedQuery && dashboardsData?.data) || []).filter(
        dashboard =>
          dashboard.title
            ?.toLowerCase()
            .indexOf(debouncedQuery.toLowerCase()) >= 0,
      ),
    [dashboardsData, debouncedQuery],
  );

  const dashboardsInfo = useMemo(
    () => ({
      error: dashboardsError,
      isFetching: dashboardsIsValidating,
      isLoading: dashboardsIsValidating && !dashboards.length,
    }),
    [dashboards.length, dashboardsError, dashboardsIsValidating],
  );

  // Influencers
  const {
    data: influencersData,
    error: influencersError,
    isValidating: influencersIsValidating,
  } = useFetchDataSWR(
    !debouncedQuery
      ? null
      : {
          method: 'GET',
          params: { keyword: debouncedQuery },
          url: `${INFLUENCER_HUB_ENDPOINT}/suggestions/influencers`,
        },
  );

  const influencers = useMemo(
    () => (debouncedQuery && influencersData?.data?.data) || [],
    [debouncedQuery, influencersData],
  );

  const influencersInfo = useMemo(
    () => ({
      error: influencersError,
      isFetching: influencersIsValidating,
      isLoading: influencersIsValidating && !influencers.length,
    }),
    [influencers.length, influencersError, influencersIsValidating],
  );

  // Influencer lists
  // TODO: Restore this when deep linking to lists is supported
  // const {
  //   data: influencerListsData,
  //   error: influencerListsError,
  //   isValidating: influencerListsIsValidating,
  // } = useFetchDataSWR(!debouncedQuery ? null : {
  //   method: 'POST',
  //   params: { name: debouncedQuery, matchType: 'contains' },
  //   url: INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT,
  // });

  // TODO: Delete this when deep linking to lists is supported
  const influencerListsData = { data: { hits: [] } };
  const influencerListsError = undefined;
  const influencerListsIsValidating = false;
  // End delete

  const influencerLists = useMemo(
    () => (debouncedQuery && influencerListsData?.data?.hits) || [],
    [debouncedQuery, influencerListsData],
  );

  const influencerListsInfo = useMemo(
    () => ({
      error: influencerListsError,
      isFetching: influencerListsIsValidating,
      isLoading: influencerListsIsValidating && !influencerLists.length,
    }),
    [influencerLists.length, influencerListsError, influencerListsIsValidating],
  );

  // Searches
  const {
    data: searchesData,
    error: searchesError,
    isValidating: searchesIsValidating,
  } = useFetchDataSWR(
    !debouncedQuery
      ? null
      : {
          method: 'GET',
          params: {
            ignoreKeyMessages: true,
            skipTranslate: true,
            includeCampaign: 'all',
            searchTerm: debouncedQuery,
          },
          url: SEARCH_LIST_ENDPOINT_V2,
        },
  );

  const searches = useMemo(() => (debouncedQuery && searchesData?.data) || [], [
    debouncedQuery,
    searchesData,
  ]);

  const searchesInfo = useMemo(
    () => ({
      error: searchesError,
      isFetching: searchesIsValidating,
      isLoading: searchesIsValidating && !searches.length,
    }),
    [searches.length, searchesError, searchesIsValidating],
  );

  // Aggregations
  const hasAnyContents =
    !!campaigns.length ||
    !!dashboards.length ||
    !!influencers.length ||
    !!influencerLists.length ||
    !!searches.length;

  const isFetchingAny =
    campaignsInfo.isFetching ||
    dashboardsInfo.isFetching ||
    influencersInfo.isFetching ||
    influencerListsInfo.isFetching ||
    searchesInfo.isFetching;

  const isLoadingAny =
    campaignsInfo.isLoading ||
    dashboardsInfo.isLoading ||
    influencersInfo.isLoading ||
    influencerListsInfo.isLoading ||
    searchesInfo.isLoading;

  const errorAny = !!(
    campaignsError ||
    dashboardsError ||
    influencersError ||
    influencerListsError ||
    searchesError
  );

  const hasAnything = hasAnyContents || isFetchingAny || isLoadingAny;

  useEffect(() => {
    if (errorAny) {
      addPageMessage({
        text: intl.formatMessage(messages.error),
        status: 'danger',
      });
    }
  }, [addPageMessage, errorAny, intl]);

  useEffect(() => {
    if (isOpen && queryRef.current) {
      queryRef.current.focus();
    }
  }, [isOpen]);

  const handleChangeQuery = event => setQuery(event.target.value);

  const handleToggle = event => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      // 'Esc' is for IE's non-standard implementation
      if (queryRef.current !== document.activeElement) {
        setIsOpen(prevIsOpen => !prevIsOpen);
      } else if (queryRef.current) {
        setQuery('');
        queryRef.current.blur();
      }
    } else {
      setIsOpen(prevIsOpen => !prevIsOpen);
    }
  };

  useEffect(() => {
    const handleKeyUp = event => {
      if (document.activeElement !== document.body) {
        return;
      }

      if (event.key === '.') {
        setIsOpen(prevIsOpen => !prevIsOpen);
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <div
      {...passedProps}
      className={classNames(styles.OmniBar, className)}
      data-qa="omnibar"
    >
      <Modal isOpen={isOpen} size="sm" toggle={handleToggle}>
        <Modal.Body className={styles.modalBody}>
          <Media>
            <Media.Item className={styles.queryIcon}>
              <Icon
                data-qa="o-wMPqgf3265Ev_jdfQnS"
                name="search"
                onClick={() => queryRef?.current?.focus()}
              />
            </Media.Item>
            <Media.Body>
              <input
                className={styles.query}
                onChange={handleChangeQuery}
                placeholder={intl.formatMessage(messages.placeholder)}
                ref={queryRef}
                value={query}
              />
            </Media.Body>
          </Media>
          {hasAnything && (
            <>
              <EntitiesList
                entities={campaigns}
                getUrlFromEntity={entity =>
                  `${CAMPAIGNS_BASE_URL}/${entity.id}`
                }
                headMessageKey="campaigns"
                idKey="id"
                info={campaignsInfo}
                nameKey="title"
                onClickEntity={() => setIsOpen(false)}
              />
              <EntitiesList
                entities={dashboards}
                getExternalUrlFromEntity={entity =>
                  `${DASHBOARD_BASE_URL}${entity.id}`
                }
                headMessageKey="dashboards"
                idKey="id"
                info={dashboardsInfo}
                nameKey="title"
                onClickEntity={() => setIsOpen(false)}
              />
              <EntitiesList
                entities={influencers}
                getUrlFromEntity={entity =>
                  `${INFLUENCERS_HUB_BASE_URL}/${entity.id}`
                }
                headMessageKey="influencers"
                idKey="id"
                info={influencersInfo}
                nameKey="name"
                onClickEntity={() => setIsOpen(false)}
              />
              {/*
                // TODO: when deep linking to lists is supported, restore this
                <EntitiesList
                  entities={influencerLists}
                  headMessageKey="influencerLists"
                  idKey="id"
                  info={influencerListsInfo}
                  nameKey="name"
                  onClickEntity={() => setIsOpen(false)}
                />
              */}
              <EntitiesList
                entities={searches}
                getExternalUrlFromEntity={entity =>
                  getRedirectSearchDetailUrl(entity.id)
                }
                headMessageKey="searches"
                idKey="id"
                info={searchesInfo}
                nameKey="title"
                onClickEntity={() => setIsOpen(false)}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

OmniBar.propTypes = {
  addPageMessage: PropTypes.func.isRequired,
  className: PropTypes.string,
};

OmniBar.defaultProps = {
  className: '',
};

const ConnectedOmniBar = props => {
  const dispatch = useDispatch();
  const addPageMessage = useCallback(
    message => dispatch(addPageMessageWithDefaultTimeout(message)),
    [dispatch],
  );

  return <OmniBar {...props} addPageMessage={addPageMessage} />;
};

export default ConnectedOmniBar;
