import has from 'lodash/has';
import includes from 'lodash/includes';
import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { hasDevFeatureFlagSelector } from 'selectors/account';
import { articlesSelector } from 'selectors/article';

import { CONTACTS_RESULTS_LIST } from '../constants/constants';

const contactsObjectSelector = state => state.contacts;
const contactImagesSelector = state => state.contactImages.images;
const contactsAndSelectedContactsSelector = state =>
  state.selectedContacts.contactIds;
const contactListInfoSelector = state => state.contactListInfo;
const contactListsSelector = state => state.contactLists.lists;

export const contactListSelector = state => state[CONTACTS_RESULTS_LIST];

export const contactsSelector = createSelector(
  contactsObjectSelector,
  contactsObject => (contactsObject ? contactsObject.contacts : {}),
);

export const allContactIdsInContactListSelector = createSelector(
  [contactListSelector],
  contacts => {
    return contacts.map(contact => contact.id);
  },
);

export const allContactsInContactListSelectorFullData = createSelector(
  [contactListSelector, contactImagesSelector],
  (contacts, contactImages) => {
    return contacts.map(contact => {
      const image = contactImages[contact.id]
        ? contactImages[contact.id]
        : { loading: false, url: '' };
      return { ...contact, image };
    });
  },
);

export const selectedContactsSelector = createSelector(
  [
    contactsSelector,
    contactImagesSelector,
    contactsAndSelectedContactsSelector,
  ],
  (contacts, contactImages, selectedContactIds) => {
    if (!selectedContactIds || !selectedContactIds.length) {
      return [];
    }

    return selectedContactIds
      .map(id => {
        const image = contactImages[id]
          ? contactImages[id]
          : { loading: false, url: '' };
        return { ...contacts[id], image };
      })
      .filter(contact => has(contact, 'id'));
  },
);

export const selectedContactIdsSelector = createSelector(
  [selectedContactsSelector],
  selectedContacts => selectedContacts.map(c => c.id),
);

export const allContactsSelectedInContactListSelector = createSelector(
  [selectedContactIdsSelector, allContactIdsInContactListSelector],
  (selectedContactIds, contactListsContacts) => {
    if (!selectedContactIds || !selectedContactIds.length) {
      return false;
    }

    const selected = selectedContactIds.filter(contactId =>
      includes(contactListsContacts, contactId),
    );

    return selected.length === contactListsContacts.length;
  },
);

export const selectedContactIdsInContactListSelector = createSelector(
  [selectedContactIdsSelector, allContactIdsInContactListSelector],
  (selectedContactIds, contactListsContacts) => {
    if (
      !selectedContactIds ||
      !selectedContactIds.length ||
      !contactListsContacts
    ) {
      return [];
    }

    return selectedContactIds.filter(contactId =>
      includes(contactListsContacts, contactId),
    );
  },
);

export const someContactsSelectedInContactListSelector = createSelector(
  [selectedContactIdsSelector, allContactIdsInContactListSelector],
  (selectedContactIds, contactListsContacts) => {
    if (
      !selectedContactIds ||
      !selectedContactIds.length ||
      !contactListsContacts
    ) {
      return false;
    }

    return selectedContactIds.length < contactListsContacts.length;
  },
);

export const contactByIdSelector = createSelector(contactsSelector, contacts =>
  memoize(contactId => (contactId ? contacts[contactId] : null)),
);

export const currentContactIdSelector = state =>
  state.contacts ? state.contacts.current : null;

export const currentContactSelector = createSelector(
  currentContactIdSelector,
  contactByIdSelector,
  (contactId, contactById) => contactById(contactId),
);

export const currentContactArticleIdsSelector = createSelector(
  currentContactSelector,
  contact =>
    contact && Array.isArray(contact.articleIds) ? contact.articleIds : [],
);

export const currentContactArticlesSelector = createSelector(
  currentContactArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) =>
    articleIds.map(articleId => {
      if (!articles[articleId]) {
        return {};
      }
      return {
        ...articles[articleId],
        duplicatePublications: (
          articles[articleId].duplicatePublications || []
        ).map(id => articles[id]),
      };
    }),
);

export const relevantOnlySelector = createSelector(
  contactsObjectSelector,
  contactsObject => {
    if (hasDevFeatureFlagSelector('CONTACTS_BETTER_DEFAULT_FILTER')) {
      return contactsObject.relevantOnly;
    }

    return false;
  },
);

export const contactImageSelector = createSelector(
  contactImagesSelector,
  contactImages => contactId => {
    return contactImages[contactId] || { url: '', loading: false };
  },
);

export const contactNotesSelector = createSelector(
  contactsObjectSelector,
  contactsObject => contactsObject.notes,
);

export const contactNotesHitsSelector = createSelector(
  contactsObjectSelector,
  contactsObject => contactsObject.notesHits,
);

export const areContactNotesLoading = createSelector(
  contactsObjectSelector,
  contactsObject => contactsObject.AreAuthorNotesLoading,
);

export const contactByIdEmailsSelector = createSelector(
  contactByIdSelector,
  contactById => contactId => {
    const contact = contactById(contactId) || {};
    if (contact.dateSuppress) {
      return [];
    }
    return (contact.contacts || [])
      .filter(
        con =>
          !con.dateSuppressed &&
          !con.dateOutdated &&
          con.contactType === 'Email',
      )
      .map(con => con.value);
  },
);

export const currentContactEmailsSelector = createSelector(
  contactByIdEmailsSelector,
  currentContactIdSelector,
  (contactByIdEmails, currentContactId) => contactByIdEmails(currentContactId),
);

export const currentListInfoSelector = createSelector(
  contactListsSelector,
  contactListInfoSelector,
  (lists, info) => {
    const { favorite } = info;
    const currentList = lists.find(l => l.id === info.id);
    const deleted = currentList && !!currentList.dateDeleted;

    return {
      ...info,
      isShareable: !favorite && !deleted && info.name !== 'Requested',
      deleted,
    };
  },
);

export const currentContactSocialSelector = createSelector(
  currentContactSelector,
  contact => {
    const socialData = {};

    if (!contact) return socialData;

    if (contact.contacts && contact.contacts.length) {
      contact.contacts.forEach(c => {
        const contactType = c.contactType.toLowerCase();

        if (socialData[contactType]) {
          socialData[contactType].contacts.push(c);
        } else {
          socialData[contactType] = {
            contactType: c.contactType,
            id: c.id,
            contacts: [c],
          };
        }
      });
    }

    return socialData;
  },
);

export const getContactSocialDataSelector = createSelector(
  contactsObjectSelector,
  contacts => contacts.socialStream,
);

export const nonDeletedContactListsWithFavorite = createSelector(
  contactListsSelector,
  lists => {
    const filteredList = lists.filter(l => !l.dateDeleted);
    const favorite = lists.find(l => l.favorite);
    if (!favorite) {
      filteredList.unshift({
        id: 'favorites',
        favorite: true,
        name: 'Favorites',
      });
    }
    return filteredList;
  },
);
