import React, { useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import withModifiers from '../withModifiers';

/**
 * @argument {React.FC<{
 *  children: React.Element;
 *  className?: string;
 *  content: React.Element;
 *  contentWidth?: number;
 *  position: 'top' | 'topleft' | 'topright' | 'bottom' | 'left' | 'right' | 'bottomleft' | 'bottomright';
 *  style?: object;
 *  tooltipStyle?: object
 *  }>}
 */
const Tooltip = ({
  children,
  className,
  content,
  contentWidth,
  position,
  style,
  tooltipStyle,
  isOpen,
  showOnHover,
  classNameTooltip,
}) => {
  const [showing, setShowing] = useState(false);

  if (!content) {
    return children;
  }

  const contentStyle = {
    ...tooltipStyle,
    width: contentWidth || 'auto',
  };

  const mainClass = classnames(
    Tooltip.baseClass,
    className,
    `${Tooltip.baseClass}--${position}`,
    {
      [`${Tooltip.baseClass}--fixed-width`]: !!contentWidth,
      [`${Tooltip.baseClass}--open`]: isOpen || showing,
    },
  );

  const tooltipClass = classnames(
    `${Tooltip.baseClass}__content`,
    classNameTooltip,
  );

  const handleSetHover = value => () => {
    if (showOnHover) {
      setShowing(value);
    } else if (!showOnHover && showing) {
      setShowing(false);
    }
  };

  return (
    <div
      className={mainClass}
      style={style}
      onMouseEnter={handleSetHover(true)}
      onMouseLeave={handleSetHover(false)}
    >
      <div
        className={tooltipClass}
        style={contentStyle}
        data-qa="tooltip-content"
      >
        {content}
      </div>
      {children}
    </div>
  );
};

Tooltip.baseClass = 'tk-tooltip';

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentWidth: PropTypes.number,
  position: PropTypes.oneOf([
    'top',
    'topleft',
    'topright',
    'bottom',
    'bottomleft',
    'bottomright',
    'left',
    'right',
  ]),
  style: PropTypes.object,
  tooltipStyle: PropTypes.object,
  isOpen: PropTypes.bool,
  showOnHover: PropTypes.bool,
  classNameTooltip: PropTypes.string,
};

Tooltip.defaultProps = {
  className: '',
  contentWidth: null,
  position: 'top',
  style: {},
  tooltipStyle: {},
  isOpen: false,
  showOnHover: true,
  classNameTooltip: '',
};

export default withModifiers(Tooltip);
