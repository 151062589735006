import Set from 'es6-set';
import includes from 'lodash/includes';

import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';

import { performPost } from 'services/rest-service/rest-service';

import { MINI_CARD_VIEW, FULL_PROFILE_VIEW } from './constants';

const INFLUENCER_RECENT_TRIGGER = 'influencer-hub/INFLUENCER_RECENT_TRIGGER';

const initialState = {
  recentInfluencers: [],
};

const influencerRecentReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case INFLUENCER_RECENT_TRIGGER: {
      const set = new Set(state.recentInfluencers);

      if (set.has(action.payload)) {
        return state;
      }

      set.add(action.payload);

      return {
        ...state,
        recentInfluencers: Array.from(set),
      };
    }
    default:
      return state;
  }
};

const triggerRecentInfluencerRequest = influencer => {
  const isOutlet = (influencer.influencerTypes || []).includes('Outlet');

  performPost(`${INFLUENCER_HUB_ENDPOINT}/recent/influencer`, {
    influencerId: influencer.id,
    isOutlet,
  });
};

export const addRecentInfluencer = influencer => (dispatch, getState) => {
  if (!influencer) {
    return;
  }

  const recentInfluencers = getState().influencerRecent.recentInfluencers;

  if (includes(recentInfluencers, influencer.id)) {
    return;
  }

  dispatch({ type: INFLUENCER_RECENT_TRIGGER, payload: influencer.id });
  triggerRecentInfluencerRequest(influencer);
};

export const triggerInfluencerViewRequest = ({
  influencer,
  viewType,
}) => dispatch => {
  const isOutlet = (influencer.influencerTypes || []).includes('Outlet');
  const isSocial = (influencer.influencerTypes || []).includes('Social');
  const isJournalist = (influencer.influencerTypes || []).includes(
    'Journalist',
  );
  const isMiniProfileView = viewType === MINI_CARD_VIEW;
  const isFullProfileView = viewType === FULL_PROFILE_VIEW;

  performPost(`${INFLUENCER_HUB_ENDPOINT}/record-view`, {
    influencerId: influencer.id,
    influencerName: influencer.name,
    isSocial,
    isJournalist,
    isMiniProfileView,
    isFullProfileView,
    isOutlet,
  });
};

export default influencerRecentReducer;
