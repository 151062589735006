import React, { Component, Fragment } from 'react';

import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import values from 'lodash/values';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Button, { Addon } from '../../button';
import FloatingPanel from '../../floating-panel';
import SvgIcon from '../../svg-icon';
import Body from '../../type';
import Dropdown from '../dropdown';
import DropdownMenu from '../menu';

import messages from './expandableDropdown.messages';
import style from './ExpandableDropdown.module.css';

class ExpandableDropdown extends Component {
  state = {
    isOpen: false,
    showMore: false,
  };

  onMenuItemClick = (callback, closeOnClick, data, option) => {
    if (closeOnClick) this.closeDropdown();
    callback(data, option);
  };

  closeDropdown = () => {
    this.setState({ isOpen: false, showMore: false });
  };

  toggleDropdown = () => {
    if (this.props.disabled) return;

    this.setState(prevState => {
      return { isOpen: !prevState.isOpen };
    });
  };

  renderMoreButton = () => {
    return (
      <Button
        data-qa="OXAXTGlnRJbcKXf6ql56x"
        key="show-more-button"
        modifiers={['listEntry', 'inline-flex']}
        onClick={() => {
          this.setState(prevState => {
            return { showMore: !prevState.showMore };
          });
        }}
      >
        <Body color="light">
          {this.props.intl.formatMessage(messages.moreButtonText)}
        </Body>
        <SvgIcon
          width={12}
          height={12}
          icon="chevronDown"
          modifiers={['left-small']}
        />
      </Button>
    );
  };

  renderMenuOptions = () => {
    const {
      includeMore,
      dropdownOptions,
      moreButtonPosition,
      onItemClick,
      dropdownOptionModifiers,
    } = this.props;
    const { showMore } = this.state;
    const moreButton = showMore ? null : this.renderMoreButton();
    const dateMap = {};

    forEach(dropdownOptions, (option, key) => {
      const modifyOption = dropdownOptionModifiers[key] || {};
      dateMap[key] = {
        showOption: showMore,
        closeOnClick: true,
        modifiers: ['listEntry'],
        callback: onItemClick,
        ...modifyOption,
        data: key,
        label: option.label,
        key,
      };
    });

    // TODO: FILTERING HERE

    const menuList = compact(
      values(dateMap).map(option => {
        return option.showOption ? (
          <Button
            data-qa="5ggW-QUqmnzBV4jl_g0Ze"
            {...option.props}
            key={option.key}
            modifiers={option.modifiers}
            onClick={() => {
              this.onMenuItemClick(
                option.callback,
                option.closeOnClick,
                option.data,
                option,
              );
            }}
          >
            <Body>{option.label}</Body>
          </Button>
        ) : null;
      }),
    );

    if (includeMore && moreButtonPosition >= 0) {
      menuList.splice(moreButtonPosition, 0, moreButton);
    }

    return menuList;
  };

  render() {
    const { selectedText } = this.state;
    const {
      buttonModifiers,
      buttonText,
      dropdownModifiers,
      leftIcon,
      menuPosition,
      showSelected,
      dropdownWidth,
      disabled,
      children,
      containerClass,
      dataQA,
    } = this.props;
    const renderMenu = this.renderMenuOptions();

    const dropdownDefaultBody = (
      <Fragment>
        {leftIcon && (
          <Addon>
            <SvgIcon
              icon={leftIcon.icon}
              width={leftIcon.width || undefined}
              height={leftIcon.height || undefined}
              modifiers={leftIcon.modifiers || []}
              style={{ ...leftIcon.style }}
            />
          </Addon>
        )}
        <span className={style.expandableDropdownText}>{buttonText}</span>
        {!disabled && (
          <Addon>
            <SvgIcon icon="arrowDown" width={8} height={8} />
          </Addon>
        )}
      </Fragment>
    );

    const dropdownBody =
      children !== undefined ? children : dropdownDefaultBody;

    return (
      <Dropdown
        isOpen={this.state.isOpen}
        modifiers={dropdownModifiers}
        className={this.props.className}
        toggle={this.toggleDropdown}
      >
        <Button
          data-qa={dataQA || 'expandable-option'}
          modifiers={buttonModifiers}
          onClick={this.toggleDropdown}
          disabled={disabled}
          className={containerClass}
        >
          {dropdownBody}
        </Button>
        <DropdownMenu
          isOpen={this.state.isOpen}
          position={menuPosition}
          width={dropdownWidth}
        >
          <FloatingPanel>
            {showSelected && selectedText && (
              <Button
                data-qa="DV4skR-Bd7VcnlDDQWTCg"
                modifiers={['listEntry']}
                onClick={() => {
                  this.closeDropdown();
                }}
              >
                <Body weight="bold">{selectedText}</Body>
              </Button>
            )}
            {renderMenu}
          </FloatingPanel>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

ExpandableDropdown.propTypes = {
  buttonModifiers: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  buttonText: PropTypes.string,
  dropdownModifiers: PropTypes.arrayOf(PropTypes.string),
  /** Pass in object with date value as key, updated options as value. */
  dropdownOptionModifiers: PropTypes.shape({
    callback: PropTypes.func,
    showOption: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    modifiers: PropTypes.arrayOf(PropTypes.string),
  }),
  /** dropdownOptions object values should have label and data min (for callback) */
  dropdownOptions: PropTypes.shape({
    label: PropTypes.node,
    data: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.func,
    ]),
  }).isRequired,
  /** Useful and re-usable menu should not always require a "More >>" option **/
  includeMore: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  leftIcon: PropTypes.shape({
    height: PropTypes.number,
    icon: PropTypes.string.isRequired,
    width: PropTypes.number,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    style: PropTypes.object,
  }),
  /** Modifier to pass on to the dropdown menu itself **/
  menuPosition: PropTypes.string,
  /** Where to place the "show more" option in the list. Use `-1` to hide it. */
  moreButtonPosition: PropTypes.number,
  /**
   * Default click handler for each option.
   * It returns the data prop as the first argument, and the full option details as the second
   */
  onItemClick: PropTypes.func,
  showSelected: PropTypes.bool,
  dropdownWidth: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  containerClass: PropTypes.string,
  dataQA: PropTypes.string,
};

ExpandableDropdown.defaultProps = {
  buttonModifiers: [],
  buttonText: 'Please select an option',
  dropdownModifiers: [],
  dropdownOptionModifiers: {},
  includeMore: true,
  leftIcon: null,
  menuPosition: 'bottom',
  moreButtonPosition: 4,
  onItemClick: () => {},
  showSelected: true,
  dropdownWidth: 'auto',
  disabled: false,
  children: undefined,
  containerClass: '',
  dataQA: null,
};

export default injectIntl(ExpandableDropdown);
