import PropTypes from 'prop-types';

export const logoDefaultProps = {
  logoSrc: '',
  logoSize: 'md',
};

export const heroDefaultProps = {
  heroSrc: '',
};

export const defaultProps = {
  name: 'default',
  defaultContent: {
    ...heroDefaultProps,
    assets: {},
    backlinks: [],
    companyBackground: '',
    headline: '',
    logoSize: 'md',
    logoSrc: '',
    pressContact: {
      address: '',
      company: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    showInsights: true,
    spokespeople: [],
    summary: '',
    videos: [],
  },
};

const allowedHostnames = {
  linkedin: 'linkedin.com',
  vimeo: 'vimeo.com',
  youtube: 'youtube.com',
};

const isValidEmail = val => {
  // This RegExp is from https://www.w3.org/TR/html5/forms.html#valid-e-mail-address on April 9, 2018
  const validEmailRegExp = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  );
  return val ? validEmailRegExp.test(val) : true;
};

export const isValidURL = val => {
  const validURLRegEx = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  return val ? validURLRegEx.test(val) : true;
};

const isRequired = val => !!val;

const pressContactValidators = {
  company: {
    required: isRequired,
  },
  email: {
    required: isRequired,
    validEmail: isValidEmail,
  },
  firstName: {
    required: isRequired,
  },
  lastName: {
    required: isRequired,
  },
};

const isValidPressContact = pressContact => {
  // Validates the entire pressContact
  if (!pressContact) {
    return false;
  }

  return Object.keys(pressContactValidators).reduce(
    (pressContactResult, pressContactKey) => {
      const fieldValidators = pressContactValidators[pressContactKey];
      pressContactResult =
        pressContactResult &&
        Object.keys(fieldValidators).reduce(
          (fieldValidResult, validatorKey) => {
            const validator = fieldValidators[validatorKey];
            const fieldValue = pressContact[pressContactKey];
            fieldValidResult = fieldValidResult && validator(fieldValue);
            return fieldValidResult;
          },
          true,
        );
      return pressContactResult;
    },
    true,
  );
};

export const isValidLinkedinProfile = value => {
  const invalidLinkedinProfile = RegExp('[^a-zA-Z0-9-]');

  // allow empty
  if (!value || value === '') return true;

  try {
    if (value.includes('.')) {
      //if periods in value, parse as URL
      const url = document.createElement('a');
      url.href = value;
      return url.hostname.includes(allowedHostnames.linkedin);
    }

    //no periods, so parse as username
    return !invalidLinkedinProfile.test(value);
  } catch (e) {
    //if parsing as url throws exception, parse as username
    return !invalidLinkedinProfile.test(value);
  }
};

export const isValidVideoUrl = value => {
  if (!value || value === '') return true;
  try {
    const url = document.createElement('a');
    url.href = value;
    return (
      url.hostname.includes(allowedHostnames.youtube) ||
      url.hostname.includes(allowedHostnames.vimeo)
    );
  } catch (e) {
    return false;
  }
};

export const validators = {
  content: {
    '': {
      hasPressContact: ({ pressContact }) => isValidPressContact(pressContact),
    },
    headline: {
      required: isRequired,
    },
    summary: {
      required: isRequired,
    },
  },
  pressContact: {
    ...pressContactValidators,
    '': {
      isPressContact: isValidPressContact,
    },
  },
  backlinks: {
    url: {
      required: isRequired,
      validURL: isValidURL,
    },
  },
  videos: {
    url: {
      required: isRequired,
      validURL: isValidVideoUrl,
    },
  },
  spokespeople: {
    firstName: {
      required: isRequired,
    },
    lastName: {
      required: isRequired,
    },
    linkedin: {
      isValidProfile: isValidLinkedinProfile,
    },
  },
};

export const requiredValidationMessageI18nKey = 'requiredValidationMessage';
export const emailValidationMessageI18nKey = 'emailValidationMessage';
export const urlRequiredValidationMessageI18nKey =
  'urlRequiredValidationMessage';
export const urlValidationMessageI18nKey = 'urlValidationMessage';

export const errorMessageI18nKeys = {
  headline: {
    required: 'requiredHeadlineValidationMessage',
  },
  pressContact: {
    '': {
      isPressContact: 'requiredContactValidationMessage',
    },
    company: {
      required: requiredValidationMessageI18nKey,
    },
    email: {
      required: requiredValidationMessageI18nKey,
      validEmail: emailValidationMessageI18nKey,
    },
    firstName: {
      required: requiredValidationMessageI18nKey,
    },
    lastName: {
      required: requiredValidationMessageI18nKey,
    },
  },
  summary: {
    required: 'requiredSummaryValidationMessage',
  },
  backlinks: {
    url: {
      required: urlRequiredValidationMessageI18nKey,
      validURL: urlValidationMessageI18nKey,
    },
  },
  spokespeople: {
    firstName: {
      required: requiredValidationMessageI18nKey,
    },
    lastName: {
      required: requiredValidationMessageI18nKey,
    },
    linkedin: {
      isValidProfile: 'requiredLinkedinValidationMessage',
    },
  },
  videos: {
    url: {
      required: urlRequiredValidationMessageI18nKey,
      validURL: 'invalidVideoUrlValidationMessage',
    },
  },
};

export const logoPropTypes = {
  logoSrc: PropTypes.string,
  logoSize: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export const heroPropTypes = {
  heroSrc: PropTypes.string,
};

export const backlinksPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  }),
);

export const videosPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
);

export const spokespeoplePropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    image: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    quotes: PropTypes.string,
  }),
);

export const contentPropTypes = PropTypes.shape({
  headline: PropTypes.string,
  ...logoPropTypes,
  ...heroPropTypes,
  pressContact: PropTypes.shape({
    address: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
  }),
  summary: PropTypes.string,
  companyBackground: PropTypes.string,
  backlinks: backlinksPropTypes,
  spokespeople: spokespeoplePropTypes,
});

export const propTypes = PropTypes.shape({
  name: PropTypes.string,
  content: contentPropTypes.isRequired,
});

export default { contentPropTypes, defaultProps, propTypes, validators };
