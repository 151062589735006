export const API_BASE_URL = '/api';
export const API_BASE_URL_V2 = '/api/v2';
export const API_BASE_URL_V3 = 'api/v3';
export const API_COOKIE_AUTH_HEADER = 'X-Auth-Token';
export const ADMIN_BASE_URL = '/admin';
export const ADMIN_BASE_URL_V2_DEV =
  'https://cision-upgrade-service.dev-usa-gke.ext.cision.com';
export const ADMIN_BASE_URL_V2_PROD =
  'https://cision-upgrade-service.prod-usa-gke.ext.cision.com';

export const ACCESS_CONTROL_BASE_ENDPOINT = `${API_BASE_URL}/accesscontrol`;
export const ACCESS_CONTROL_GROUPS_BASE_ENDPOINT = `${ACCESS_CONTROL_BASE_ENDPOINT}/groups`;
export const ACCESS_CONTROL_ACCOUNT_BASE_ENDPOINT = `${ACCESS_CONTROL_BASE_ENDPOINT}/account/{accountId}`;
export const ACCOUNT_SETTINGS_ENDPOINT = `${API_BASE_URL}/account/settings`;
export const ACCOUNT_USERS_ENDPOINT = `${API_BASE_URL}/account/users`;
export const ACCOUNT_LIST_ENDPOINT = `${ADMIN_BASE_URL}/account/list`;
export const ACCOUNT_CREATE_ENDPOINT = `${ADMIN_BASE_URL}/create_account`;
export const ACCOUNT_GET_ENDPOINT = `${ADMIN_BASE_URL}/account`;
export const ACCOUNT_GET_BY_FEATURE_ENDPOINT = `${API_BASE_URL}/account/byFeature`;
export const ACCOUNT_GET_BY_DEV_FEATURE_ENDPOINT = `${API_BASE_URL}/account/byDevFeature`;
export const ACCOUNT_TYPE_ADMIN_ENDPOINT = `${ACCOUNT_GET_ENDPOINT}/types`;
export const ACCOUNT_CLEAR_EXISTING_CLICKS_ENDPOINT = `${ADMIN_BASE_URL}/ukPrintClick/clearClicks`;
export const ACCOUNT_DEACTIVATE_ENDPOINT = `${ACCOUNT_GET_ENDPOINT}/deactivateAccount`;
export const ACTIVITIES_ENDPOINT = `${API_BASE_URL}/activity`;
export const ALERT_LIST_ENDPOINT = `${API_BASE_URL}/dailyClips/list`;
export const ANALYTICS_INTEGRATION_ENDPOINT = `${API_BASE_URL}/account/integration/analytics/`;
export const ANALYTICS_INTEGRATION_ENDPOINT_BETA = `${API_BASE_URL}/account/integration/ga/`;
export const ARTICLE_BY_URL_ENDPOINT = `${API_BASE_URL_V2}/articleExtraction`;
export const ARTICLE_V3_BY_URL_ENDPOINT = `${API_BASE_URL_V3}/articleExtraction`;
export const ARTICLE_CUSTOM_BROADCAST_CLIP = `${API_BASE_URL}/article/custom-broadcast-clip`;
export const ARTICLE_DELETE_ENDPOINT = `${API_BASE_URL}/article/delete`;
export const DELETE_ARTICLE_FROM_TAG_ENDPOINT = `${API_BASE_URL}/tag/{tagId}/article/delete`;
export const ARTICLE_DETAILS_ENDPOINT = '/article/details/';
export const ARTICLE_LIST_EXPORT_ENDPOINT = `${API_BASE_URL}/articleExport`;
export const ARTICLE_STAR_ENDPOINT = `${API_BASE_URL}/article/star`;
export const ARTICLE_TAG_ENDPOINT = `${API_BASE_URL}/tag/article`;
export const ARTICLE_UNSTAR_ENDPOINT = `${API_BASE_URL}/article/unstar`;
export const ARTICLE_UPDATE_SENTIMENT_ENDPOINT = `${API_BASE_URL}/article/update_sentiment`;
export const ARTICLES_ENDPOINT = '/chartDetails/articles/';
export const ARTICLES_INTL_ENDPOINT = '/chartDetails/intl_mentions/';
export const ARTICLES_US_ENDPOINT = '/chartDetails/us_mentions/';
export const ARTICLES_UPDATE_SENTIMENT_ENDPOINT = `${API_BASE_URL}/article/bulk-update-sentiment`;
export const AUTHOR_ATTRIBUTION = `${API_BASE_URL}/author-attribution`;
export const AUTHOR_ATTRIBUTION_COLLECTION = `${AUTHOR_ATTRIBUTION}/collection`;
export const BRANDWATCH_INSIGHTS_SRC = `${API_BASE_URL}/brandwatch/insights-src`;
export const BULK_ADD_TAGS_TO_ARTICLE_ENDPOINT = `${API_BASE_URL}/tag/bulkAddArticleTags`;
export const BULK_ADD_ARTICLES_TO_SEARCH_ENDPOINT = `${API_BASE_URL}/tag/bulkAddArticlesToSearch`;
export const BULK_DELETE_TAGS_TO_ARTICLES_ENDPOINT = `${API_BASE_URL}/tag/bulkUntag`;
export const BULK_SET_TAGS_FOR_ARTICLES_ENDPOINT = `${API_BASE_URL}/tag/setArticleTags`;
export const ADD_CAMPAIGN_TAGS_TO_ARTICLES_ENDPOINT = `${API_BASE_URL}/tag/setCampaignArticleTag`;
export const AUDIENCE_TRACKING_DOMAINS = `${API_BASE_URL}/audience-tracking-domains`;
export const CAMPAIGN_CATEGORIES_ENDPOINT = `${API_BASE_URL}/campaign-categories`;
export const CAMPAIGN_ENDPOINT = `${API_BASE_URL}/campaign`;
export const CAMPAIGNS_ENDPOINT = `${API_BASE_URL}/campaigns`;
export const CAMPAIGNS_TITLES_ENDPOINT = `${API_BASE_URL}/campaign-titles`;
export const CHANGE_PASSWORD_ENDPOINT = '/passwordmanagement/changepassword';
export const CONTACT_BASE_ENDPOINT = `${API_BASE_URL}/authors`;
export const CONTACT_IMAGES_ENDPOINT = `${CONTACT_BASE_ENDPOINT}/images`;
export const CONTACT_PUBLICATIONS_ENDPOINT = `${CONTACT_BASE_ENDPOINT}/publications`;
export const CONTACT_LISTS_ENDPOINT = `${API_BASE_URL}/author-list-reference`;
export const CONTACT_NOTE_BASE_ENDPOINT = `${API_BASE_URL}/author`;
export const CONTACT_NOTES_ENDPOINT = '/note';
export const CONTACT_SOCIAL_BASE_ENDPOINT = `${API_BASE_URL}/social`;
export const CONTACT_SOCIAL_HISTORY_ENDPOINT = `${CONTACT_SOCIAL_BASE_ENDPOINT}/history`;
export const CONTACT_SOCIAL_TIMELINE_ENDPOINT = `${CONTACT_SOCIAL_BASE_ENDPOINT}/timeline`;
export const CONTACT_TWITCH_HISTORY_ENDPOINT = `${CONTACT_SOCIAL_BASE_ENDPOINT}/history/twitch`;
export const CONTACT_TOPICS_BASE_ENDPOINT = `${API_BASE_URL}/author`;
export const CONTACT_TOPICS_ENDPOINT = '/topics';
export const COUNTRY_ENDPOINT = `${API_BASE_URL}/account/:id/country-code`;
export const CURRENCY_ENDPOINT = `${API_BASE_URL}/currencysettings`;
export const DASHBOARD_ENDPOINT_V2 = `${API_BASE_URL_V2}/dashboard`;
export const USER_SETTINGS_LANGUAGE_ENDPOINT = `${API_BASE_URL}/language`;
export const FAVORITE_CONTACTS_ENDPOINT = `${CONTACT_LISTS_ENDPOINT}/favorites/authors`;
export const CONTACT_LISTS_ALL_OVERVIEW_ENDPOINT = `${API_BASE_URL}/author-list-reference/all-overviews`;
export const DASHBOARD_LIST_ENDPOINT = `${API_BASE_URL}/dashboard`;
export const DASHBOARDS_LIST_ENDPOINT = `${API_BASE_URL}/dashboards/list`;
export const OUTREACH_ENDPOINT = `${API_BASE_URL}/outreach`;
export const OUTREACH_DKIM_DOMAIN_ENDPOINT = `${OUTREACH_ENDPOINT}/domain`;
export const OUTREACH_DKIM_STATUS_ENDPOINT = `${OUTREACH_DKIM_DOMAIN_ENDPOINT}/status`;
export const OUTREACH_INTEGRATION_VERIFICATION_ENDPOINT = `${OUTREACH_ENDPOINT}/email-announcement/verification`;
export const OUTREACH_JETPACK_ENDPOINT = `${API_BASE_URL}/jetpack/outreach`;
export const OUTREACH_EMAIL_EXCLUSION_ENDPOINT = `${OUTREACH_ENDPOINT}/exclusions`;
export const OUTREACH_EMAIL_GET_VERIFIED_EMAILS = `${OUTREACH_ENDPOINT}/email-announcement/verified-emails`;
export const OUTREACH_EMAIL_DELETE_ENDPOINT = `${OUTREACH_JETPACK_ENDPOINT}/entity/dataDeleteRequest/integration`;
export const OUTREACH_EMAILS_GET_MESSAGES_STATS = `${OUTREACH_ENDPOINT}/messages/stats`;
export const OUTREACH_PITCH_UPDATE = `${OUTREACH_ENDPOINT}/messages/pitch`;
export const OUTREACH_THREAD_UPDATE = `${OUTREACH_ENDPOINT}/threads/pitch`;
export const OUTREACH_CSV_EXPORT = `${OUTREACH_ENDPOINT}/bulk/drafts/recipients/report`;
export const IMAGE_BASE_ENDPOINT = `${API_BASE_URL}/image`;
export const IMPORT_CUSTOMER_ENDPOINT = `${ADMIN_BASE_URL}/import-customer`;
export const IMPORT_CUSTOMER_ENDPOINT_V2_DEV = `${ADMIN_BASE_URL_V2_DEV}/import-customer`;
export const IMPORT_CUSTOMER_ENDPOINT_V2_PROD = `${ADMIN_BASE_URL_V2_PROD}/import-customer`;
export const MEDIA_UPDATE_REQUEST_ENDPOINT = `${API_BASE_URL}/influencer-hub/research`;
export const MIGRATION_ENDPOINT_V2_DEV = `${ADMIN_BASE_URL_V2_DEV}/upgrades/migration`;
export const MIGRATION_ENDPOINT_V2_PROD = `${ADMIN_BASE_URL_V2_PROD}/upgrades/migration`;
export const NEWSLETTER_ENDPOINT = `${API_BASE_URL}/newsletter`;
export const NEWSLETTER_LIST_ENDPOINT = `${NEWSLETTER_ENDPOINT}/list`;
export const NEWSLETTER_COPY_ENDPOINT = `${NEWSLETTER_ENDPOINT}/copy`;
export const NEWSLETTER_UNSUBSCRIBED_ENDPOINT = `${NEWSLETTER_ENDPOINT}/unsubscribed-recipients`;
export const NEWSLETTER_TRANSLATIONS_DICTIONARY_ENDPOINT =
  '/newsletter/translations-dictionary';
export const NEWSLETTER_UNSUBSCRIBE_ENDPOINT =
  '/newsletter/unsubscribe-recipient';
export const NYLAS_OAUTH_BASE = 'https://api.nylas.com/oauth/authorize';
export const PR_MODEL_ENDPOINT = `${API_BASE_URL}/prmodel`;
export const PR_MODEL_MY_FACETS_ENDPOINT = `${PR_MODEL_ENDPOINT}/me/facets`;
export const REPORT_LIST_ENDPOINT = `${API_BASE_URL}/reports/list`;
export const REPORT_PRESENTATION_ENDPOINT =
  '/report_presentation/report_object';
export const REPORT_PRESENTATION_DASHBOARD_ENDPOINT =
  '/report_presentation/get_dashboard';
export const SEARCH_DETAIL_ENDPOINT = `${API_BASE_URL}/search`;
export const SYSTEM_SEARCHES_ENDPOINT = `${SEARCH_DETAIL_ENDPOINT}/systemSearchList`;
export const SEARCH_LIST_ENDPOINT_V2 = `${API_BASE_URL_V2}/search`;
export const SEARCH_PREVIEW_SEARCH_ENDPOINT = `${API_BASE_URL}/search/preview_search`;
export const SEARCH_PREVIEW_SEARCH_ENDPOINT_V2 = `${API_BASE_URL}/v2/search/preview_search`;
export const SHARED_SEARCH_LIST_ENDPOINT = `${API_BASE_URL}/search/shared`;
export const SLACK_OAUTH_BASE = 'https://slack.com/oauth/authorize';
export const SLACK_OAUTH_V2_BASE = 'https://slack.com/oauth/v2/authorize';
export const SLACK_INTEGRATION_ENDPOINT = `${API_BASE_URL_V2}/integration/slack`;
export const SLACK_ACTION_ENDPOINT = `${SLACK_INTEGRATION_ENDPOINT}/action`;
export const SOCIAL_REFRESH = `${API_BASE_URL}/article/socialRefresh`;
export const STORY_BASE_ENDPOINT = `${API_BASE_URL}/story`;
export const SPIKE_ALERTS_ENDPOINT = `${API_BASE_URL}/anomaly-notification-subscription`;
export const SUBSCRIBED_ALERTS_ENDPOINT = '/dailyClips/recipients/alerts';
export const TAG_BASE_ENDPOINT = `${API_BASE_URL}/tag`;
export const TAG_BASE_ENDPOINT_V2 = `${API_BASE_URL}/v2/tag`;
export const USER_BASE_ENDPOINT = `${API_BASE_URL_V2}/user`;
export const USER_INFO_ENDPOINT = `${API_BASE_URL}/account/userInfo`;
export const USER_SETTINGS_ENDPOINT = `${API_BASE_URL}/usersettings`;
export const USER_TAGS_ENDPOINT = `${API_BASE_URL}/tag/user`;
export const USER_SAVE_ENDPOINT = `${ADMIN_BASE_URL}/save_user`;
export const USER_GET_ENDPOINT = `${ADMIN_BASE_URL}/user`;
export const USER_PASSWORD_MANAGEMENT_ENDPOINT = '/passwordmanagement';
export const USER_SEND_NEW_USER_EMAIL_ENDPOINT = `${USER_PASSWORD_MANAGEMENT_ENDPOINT}/requestemail`;
export const SHARED_USER_TAGS_ENDPOINT = `${API_BASE_URL}/tag/shared`;
export const NEW_WIDGET_ENDPOINT = `${API_BASE_URL_V2}/widget`;
export const WIDGET_DETAIL_ENDPOINT = `${API_BASE_URL}/widget`;
export const SERVICE_ACCOUNT_ENABLED = `${API_BASE_URL}/appsettings/ga/service-account-address`;
export const GA_URLS_ENDPOINT = `${API_BASE_URL}/appsettings/integrations/ga_urls`;
export const ARTICLE_EXTRACTION_ENDPOINT = `${API_BASE_URL}/embedly/get`;
export const ARTICLE_SAVE_ENDPOINT = `${API_BASE_URL}/embedly/save`;
export const DOMAIN_DEMOGRAPHICS_ENDPOINT = `${API_BASE_URL}/audience/demographics`;
export const TVEYES_ENDPOINT = `${API_BASE_URL}/tveyes`;
export const TVEYES_ORDER_CALLBACK_ENDPOINT = `${TVEYES_ENDPOINT}/order`;
export const CEDROM_AUTH_TOKEN_REQUEST_ENDPOINT =
  'https://media.cedrom-sni.com';
export const CEDROM_AUTH_TOKEN_REQUEST_RC_ENDPOINT =
  'https://mediarc.cedrom-sni.com';
export const CEDROM_VIDE_PLAYER_ENDPOINT = 'https://demo.eureka.cc';
export const externalApiDomains = [
  CEDROM_AUTH_TOKEN_REQUEST_ENDPOINT,
  CEDROM_AUTH_TOKEN_REQUEST_RC_ENDPOINT,
  CEDROM_VIDE_PLAYER_ENDPOINT,
];
export const CEDROM_ROYALTIES_EVENT_ENDPOINT = `${API_BASE_URL}/cedrom/royalties/event`;
export const IMPACT_ENDPOINT = `${API_BASE_URL}/earned-impact`;
export const INFLUENCER_HUB_ENDPOINT = `${API_BASE_URL}/influencer-hub`;
export const ACTIVITY_TYPES_ENDPOINT = `${INFLUENCER_HUB_ENDPOINT}/activity-types`;
export const INFLUENCER_ACTIVITY_SEARCH_ENDPOINT = `${INFLUENCER_HUB_ENDPOINT}/activities/search`;
export const INFLUENCER_HUB_SEARCH_ENDPOINT = `${INFLUENCER_HUB_ENDPOINT}/search`;
export const INFLUENCER_SAVED_LISTS_ENDPOINT = `${API_BASE_URL}/influencer-hub/lists`;
export const INFLUENCER_SAVED_LISTS_WITH_VIRTUAL_SELECTION_ENDPOINT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/recipients`;
export const INFLUENCER_SAVED_LISTS_ADD_MEMBERS_WITH_VIRTUAL_SELECTION_ENDPOINT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/<listId>/recipients`; // Replace <listId>
export const INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/search`;
export const INFLUENCER_SAVED_LISTS_COPY_ENDPOINT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/copy`;
export const INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/<listId>/search`; // Replace <listId>
export const INFLUENCER_SAVED_LISTS_CSV_EXPORT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/<listId>/csv`; // Replace <listId>
export const INFLUENCER_SAVED_LISTS_CSV_EXPORT_BY_ID = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/csv`;
export const INFLUENCER_DUPLICATE_SAVED_LISTS_ENDPOINT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/<listId>/duplicate`;
export const MERGE_SAVED_LISTS_ENDPOINT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/merge`;
export const INFLUENCER_GET_SAVED_LISTS = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/member-search`;
export const INFLUENCERS_GET_INFLUENCERS_COLLECTION_URL = `${API_BASE_URL}/influencer-hub/influencers`;
export const INFLUENCER_GET_PRIVATE_INFLUENCERS_URL = `${API_BASE_URL}/influencer-hub/private/influencers`;
export const INFLUENCER_GET_UNSUBSCRIBED_INFLUENCERS_URL = `${API_BASE_URL}/influencer-hub/unsubscribed/influencers`;
export const INFLUENCER_PRIVATE_INFLUENCERS_URL = `${API_BASE_URL}/influencer-hub/private/influencer`;
export const INFLUENCER_PROFILE_BASE_URL = `${API_BASE_URL}/influencer-hub/influencer`;
export const INFLUENCER_NOTES_ENDPOINT = `${INFLUENCER_PROFILE_BASE_URL}/{id}/notes`;
export const INFLUENCER_NOTES_CREATE_ENDPOINT = `${INFLUENCER_PROFILE_BASE_URL}/{id}/note`;
export const INFLUENCER_NOTES_DELETE_ENDPOINT = `${INFLUENCER_NOTES_ENDPOINT}/{noteId}/delete`;
export const INFLUENCER_LIST_IMPORT_TEMPLATE_DOWNLOAD = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/download-template-file`;
export const INFLUENCER_LIST_IMPORT_TEMPLATE_CHECK = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/check-template-file`;
export const INFLUENCER_LIST_IMPORT_CONTACT_LIST_IMPORT = `${INFLUENCER_SAVED_LISTS_ENDPOINT}/contact-list-import`;
export const TRANSLATION_ENDPOINT = `${API_BASE_URL}/translation`;
export const PHRASES_ENDPOINT = `${TRANSLATION_ENDPOINT}/phrases`;
export const LANGUAGES_ENDPOINT = `${API_BASE_URL}/translation/languages`;
export const NEW_DASHBOARD_ENDPOINT = `${API_BASE_URL_V2}/dashboard`;
export const NEW_REPORT_ENDPOINT = `${API_BASE_URL_V2}/report`;
export const ADD_CAMPAIGNS_TO_REPORT = `${API_BASE_URL_V2}/report/{reportId}/campaign`;
export const PROXY_PDF_IMAGE = `${API_BASE_URL_V2}/report/proxy-pdf-image`;
export const NEW_REPORT_SLIDE_ENDPOINT = `${API_BASE_URL_V2}/slide`;
export const REPORT_CREATE_TEXT_WIDGET = `${API_BASE_URL_V2}/text-widget/create`;
export const REPORT_UPDATE_TEXT_WIDGET = `${API_BASE_URL_V2}/text-widget/`;
export const REPORT_IMAGE_WIDGET = `${API_BASE_URL_V2}/image-widget`;
export const SLIDE_TILE_ENDPOINT = `${API_BASE_URL_V2}/slide-tile`;
export const REPORT_CREATE_SLIDE_TILE = `${SLIDE_TILE_ENDPOINT}/{id}/create`;
export const REMOVE_WIDGET_FROM_SLIDE = `${SLIDE_TILE_ENDPOINT}/delete`;
export const REPORT_WIDGET_LAYOUT_SLIDE = `${API_BASE_URL_V2}/slide-tile/{slideTileId}/widget-layout`;
export const GET_DASHBOARD_SYSTEM_TEMPLATES = `${API_BASE_URL_V2}/dashboard/templates`;
export const TILE_ENDPOINT = `${API_BASE_URL_V2}/tile`;
export const TRANSLATION_DESCRIPTORS_ENDPOINT = `${API_BASE_URL}/translation/translations-editor-definitions`;
export const ASSET_POPUP_IMAGE_UPLOAD_ENDPOINT = `${API_BASE_URL}/assetlibrary/ms/upload`;
export const ASSET_POPUP_GET_LIST_ASSETS_ENDPOINT = `${API_BASE_URL}/assetlibrary/ms/list`;
export const ASSET_POPUP_DELETE_ASSET_ENDPOINT = `${API_BASE_URL}/assetlibrary/ms/delete/`;
export const ASSET_POPUP_EDIT_SAVE_ASSET_ENDPOINT = `${API_BASE_URL}/assetlibrary/ms/edit/`;
export const ASSET_POPUP_GET_STORY_ENDPOINT = `${API_BASE_URL}/assetlibrary/ms/getStoryAssetUrl/`;
export const ASSETS_INFO_ENDPOINT = `${API_BASE_URL}/assetlibrary/ms/assets`;
export const ASSETS_BASE64_ENDPOINT = `${API_BASE_URL}/assetlibrary/ms/fetchAssetDataByUrl`;
export const INFLUENCER_HUB_EMAIL_ENDPOINT = `${INFLUENCER_HUB_ENDPOINT}/email`;
export const WIDGET_V3_ENDPOINT = `${API_BASE_URL}/v3/widget`;
export const SOCIAL_MONITORING_API_BASE_URL = `${API_BASE_URL}/jetpack/socialmonitoring/v1/`;
export const SOCIAL_MONITORING_QUERIES_ENDPOINT = `${SOCIAL_MONITORING_API_BASE_URL}queries`;
export const SOCIAL_MONITORING_QUERY_BY_ID = `${SOCIAL_MONITORING_QUERIES_ENDPOINT}/{queryId}`;
export const SOCIAL_MONITORING_QUERY = `${SOCIAL_MONITORING_API_BASE_URL}/query`;
export const SOCIAL_MONITORING_PREVIEW_ENDPOINT = `${SOCIAL_MONITORING_API_BASE_URL}/query/preview`;
export const SOCIAL_MONITORING_RUN_QUERY_ENDPOINT = `${SOCIAL_MONITORING_QUERY_BY_ID}/run`;
export const SOCIAL_MONITORING_GET_PROJECTS_ENDPOINT = `${SOCIAL_MONITORING_API_BASE_URL}/projects`;
export const SOCIAL_MONITORING_GET_METRICS_ENDPOINT = `${SOCIAL_MONITORING_QUERY_BY_ID}/metrics`;
export const SOCIAL_MONITORING_GET_ORG_PACKAGE = `${SOCIAL_MONITORING_API_BASE_URL}/org/package`;
export const ANOMALY_NOTIFICATION_SUBSCRIPTION_ENDPOINT = `${API_BASE_URL}/anomaly-notification-subscription`;
export const SYSTEM_SEARCHES_PUB_STATUS_ENDPOINT = `${API_BASE_URL}/system-search-pub-status/list`;
export const EARNED_IMPACT_SEARCH_STATUS_ENDPOINT = `${API_BASE_URL}/earned-impact/search/{searchId}/status`;
export const EARNED_IMPACT_SEARCH_IMPACT_STATUS_ENDPOINT = `${API_BASE_URL}/earned-impact/search/{searchId}/impact-status`;
export const EARNED_IMPACT_SEARCH_ENDPOINT = `${API_BASE_URL}/earned-impact/{searchId}`;
export const EARNED_IMPACT_SEARCH_BEIGN_MODIFIED_ENDPOINT = `${API_BASE_URL}/earned-impact/search/{searchId}/being-modified`;
export const EARNED_IMPACT_SEARCH_REFRESH_ENDPOINT = `${API_BASE_URL}/earned-impact/search/{searchId}/refresh`;
export const SECURITY_CSRF_TOKEN = `${API_BASE_URL}/security/csrfToken`;
export const FEED_ENDPOINT = `${API_BASE_URL_V2}/feed`;
export const FEEDS_ENDPOINT = `${API_BASE_URL_V2}/feeds`;
export const FEED_COUNT_LIMIT_ENDPOINT = `${API_BASE_URL_V2}/feed/counts`;
export const SOCIAL_SEARCH_BASE_URL = '/socialsearch';
export const SOCIAL_SEARCH_ENDPOINT = `${API_BASE_URL}${SOCIAL_SEARCH_BASE_URL}`;
export const SOCIAL_SEARCH_POSTS = `${SOCIAL_SEARCH_ENDPOINT}/posts`;
export const SOCIAL_SEARCH_POSTS_COUNT = `${SOCIAL_SEARCH_ENDPOINT}/counts`;
export const SOCIAL_SEARCH_BY_ID_ENDPOINT = `${SOCIAL_SEARCH_ENDPOINT}/{searchId}`;
export const DASHBOARDS_LIST_PER_ACCOUNT = `${IMPORT_CUSTOMER_ENDPOINT}/account/{accountId}/dashboards/page/{pageNumber}`;
export const DASHBOARD_FOR_FEED_ENDPOINT = `${IMPORT_CUSTOMER_ENDPOINT}/dashboard/{id}`;
export const ADMIN_USER_IMPERSONATE_URL = `${ADMIN_BASE_URL}/impersonate`;
export const ADMIN_ARTICLE_FIELDS_ENDPOINT = `${ADMIN_BASE_URL}/article/fields`;
export const ADMIN_ARTICLE_SEARCH_ENDPOINT = `${ADMIN_BASE_URL}/article/search`;
export const ADMIN_ARTICLE_ADD_ENDPOINT = `${ADMIN_BASE_URL}/article/add`;
export const ADMIN_ARTICLE_UPDATE_ENDPOINT = `${ADMIN_BASE_URL}/article/update`;
export const ADMIN_ARTICLE_SOCIAL_REFRESH_ENDPOINT = `${ADMIN_BASE_URL}/article/socialRefresh`;
export const ADMIN_FEATURE_ENDPOINT = `${ADMIN_BASE_URL}/feature`;
export const ADD_CONTENT_ENGAGEMENT_EVENT = `${API_BASE_URL}/v1/content/engagement/event`;
export const DEVFF_ENDPOINT = `${API_BASE_URL}/account/{accountId}/dev-feature-flag`;
export const CONNECTIVELY_PAGE_MASK = 'http://www.connectively.us/';
export const LINK_TRACKING_FLAG_ENDPOINT = `${OUTREACH_ENDPOINT}/email-announcement/update-click-tracking-flag`;
export const GET_USER_ASSETS_COUNT = `${ADMIN_BASE_URL}/user/getAssetsCountByUsername`;
export const TRANSER_USER_ASSETS = `${ADMIN_BASE_URL}/transferUserAssetOwnership`;
export const GET_DEMO_REFERENCE_USERS = `${ADMIN_BASE_URL}/user/referenceUsers`;
export const CREATE_DEMO_USER = `${ADMIN_BASE_URL}/user/createDemoUser`;
