const REFERENCES_PATH_REGEX = new RegExp('/api/influencer-hub/reference');
const SEARCH_PATH_REGEX = new RegExp('/api/influencer-hub/search');
const INFLUENCER_LIST_PATH_REGEX = new RegExp(
  '/api/influencer-hub/lists/.*/search',
);
const PRIVATE_INFLUENCERS_LIST_REGEX = new RegExp(
  'api/influencer-hub/private/influencers',
);
const CREATE_UPDATE_PRIVATE_INFLUENCER_REGEX = new RegExp(
  'api/influencer-hub/private/influencer$',
  'gm',
);
const GET_INFLUENCER_INFO_REGEX = new RegExp(
  'api/influencer-hub/influencer/[a-zA-Z\\d_-]+$',
  'gm',
);

export const audienceTypeRequestInterceptor = request => {
  const { url } = request;
  if (
    SEARCH_PATH_REGEX.test(url) ||
    INFLUENCER_LIST_PATH_REGEX.test(url) ||
    PRIVATE_INFLUENCERS_LIST_REGEX.test(url)
  ) {
    return {
      ...request,
      data: {
        ...request.data,
        contentType: request.data.audienceType,
        contentTypeIds: request.data.audienceTypeIds,
      },
    };
  }

  if (CREATE_UPDATE_PRIVATE_INFLUENCER_REGEX.test(url)) {
    const newData = {
      ...request.data,
      contentTypeIds: request.data?.audienceTypeIds,
    };
    return {
      ...request,
      data: newData,
    };
  }

  return request;
};

export const audienceTypeResponseInterceptor = response => {
  const { url } = response.config;
  if (REFERENCES_PATH_REGEX.test(url)) {
    const newReferences = response.data?.reference?.map(r => {
      if (r.referenceType !== 'content-types') return r;
      return {
        referenceType: 'audience-types',
        data: [...r.data],
      };
    });

    return {
      ...response,
      data: {
        reference: newReferences,
      },
    };
  }

  if (
    SEARCH_PATH_REGEX.test(url) ||
    INFLUENCER_LIST_PATH_REGEX.test(url) ||
    PRIVATE_INFLUENCERS_LIST_REGEX.test(url)
  ) {
    const transformedData = response.data.data.map(
      ({ contentTypes, contentTypeIds, ...data }) => ({
        ...data,
        audienceTypes: contentTypes || data.audienceTypes,
        audienceTypeIds: contentTypeIds || data.audienceTypeIds,
      }),
    );
    return {
      ...response,
      data: {
        ...response.data,
        aggs: {
          ...response.data.aggs,
          audienceTypes: response.data.aggs?.contentTypes
            ? { ...response.data.aggs?.contentTypes }
            : { ...response.data.aggs?.audienceTypes },
          contentTypes: undefined,
        },
        ...response.data.data,
        data: transformedData,
      },
    };
  }

  if (GET_INFLUENCER_INFO_REGEX.test(url)) {
    const { contentTypes, contentTypeIds } = response.data.data;
    const transformedData = {
      ...response.data.data,
      audienceTypes: contentTypes || response.data.data.audienceTypes,
      audienceTypeIds: contentTypeIds || response.data.data.audienceTypeIds,
    };

    return {
      ...response,
      data: {
        ...response.data,
        data: transformedData,
      },
    };
  }

  return response;
};
