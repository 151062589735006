import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Avatar extends Component {
  static baseClass = 'tk-avatar';

  static formatInitials(name) {
    if (!name) return '';
    const nameParts = name.split(' ', 2);
    let initials = '';
    nameParts.forEach(part => {
      initials += part[0];
    });
    return initials.slice(0, 2);
  }

  state = {
    loadError: false,
  };

  getSizedUrlForTwitter(url, size) {
    // skip if not twitter or already sized
    if (!url) return null;

    if (
      !url.includes('twimg') ||
      /_(bigger|normal|mini|400x400)\.[a-zA-Z]+$/.test(url)
    ) {
      return url;
    }

    let sizeUrl = '400x400';
    if (size === 'small') {
      sizeUrl = 'bigger';
    }

    return url.replace(/(\.[a-zA-Z]+)$/, `_${sizeUrl}$1`);
  }

  getImgOrInitialsBlock(initials, loading, loadError, name, url) {
    if (loading) {
      return <span />;
    }

    if (!loadError && url) {
      return (
        <img
          className={`${Avatar.baseClass}__img`}
          src={url}
          alt={name}
          onError={this.handleImageLoadFailure}
        />
      );
    }

    return <span>{initials}</span>;
  }

  getLinkedAuthorBlock(children, classes, linkUrl) {
    return (
      <Link to={linkUrl} className={classes}>
        {children}
      </Link>
    );
  }

  getExternalLinkedAuthorBlock(children, classes, linkUrl) {
    return (
      <a
        target="_blank"
        href={linkUrl}
        rel="noopener noreferrer"
        className={classes}
      >
        {children}
      </a>
    );
  }

  getUnlinkedAuthorBlock(children, classes) {
    return <span className={classes}>{children}</span>;
  }

  handleImageLoadFailure = () => {
    this.setState({
      loadError: true,
    });
  };

  render() {
    const {
      className,
      contentClassName,
      linkUrl,
      loading,
      name,
      naturalSize,
      size,
      url,
      badge,
      badgePosition,
      externalLink,
    } = this.props;
    const { loadError } = this.state;
    const initials = Avatar.formatInitials(name);

    const mainClass = classnames({
      [Avatar.baseClass]: true,
      [className]: true,
      [`${Avatar.baseClass}--naturalSize`]: naturalSize,
      [`${Avatar.baseClass}--${size}`]: size,
    });

    const imgOrInitialsClassNames = classnames(
      {
        [`${Avatar.baseClass}__content`]: true,
        [`${Avatar.baseClass}__content--linked`]: linkUrl,
        [`${Avatar.baseClass}__content--loading`]: loading,
        [`${Avatar.baseClass}__content--placeholder`]: loadError || !url,
        [`${Avatar.baseClass}__content--disabled`]: this.props.disabled,
      },
      contentClassName,
    );

    const badgeBaseClass = `${Avatar.baseClass}__badge`;

    const sizedUrl = this.getSizedUrlForTwitter(url, size);

    const contentBlock = this.getImgOrInitialsBlock(
      initials,
      loading,
      loadError,
      name,
      sizedUrl,
    );

    let contactImageBlock;

    if (linkUrl) {
      contactImageBlock = this.getLinkedAuthorBlock(
        contentBlock,
        imgOrInitialsClassNames,
        linkUrl,
      );
    } else if (externalLink) {
      contactImageBlock = this.getExternalLinkedAuthorBlock(
        contentBlock,
        imgOrInitialsClassNames,
        externalLink,
      );
    } else {
      contactImageBlock = this.getUnlinkedAuthorBlock(
        contentBlock,
        imgOrInitialsClassNames,
      );
    }

    return (
      <div className={mainClass}>
        {contactImageBlock}
        <div
          className={`${badgeBaseClass} ${badgeBaseClass}--${badgePosition}`}
        >
          {badge}
        </div>
      </div>
    );
  }
}

Avatar.propTypes = {
  /** className that gets passed to outer avatar wrapper */
  className: PropTypes.string,
  /** contentClassName that gets passed to outer avatar wrapper */
  contentClassName: PropTypes.string,
  /** A nav link for for a clickable avatar image. Only works when it has access to an ancestor <Router/> component */
  linkUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Show a loading state when true */
  loading: PropTypes.bool.isRequired,
  /** Full name */
  name: PropTypes.string.isRequired,
  /** When false, this component has a fixed size that includes extra space around the image */
  naturalSize: PropTypes.bool,
  /** Size variants */
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  /** The image source */
  url: PropTypes.string,
  /** Badge component */
  badge: PropTypes.node,
  /** corner to put the badge */
  badgePosition: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-right',
    'bottom-left',
  ]),
  /** greyed out or not */
  disabled: PropTypes.bool,
  /** External link access, use an <a> for it */
  externalLink: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
  contentClassName: '',
  linkUrl: '',
  naturalSize: false,
  size: 'normal',
  url: '',
  badge: null,
  badgePosition: 'bottom-left',
  disabled: false,
  externalLink: null,
};

export default Avatar;
