import { lazy } from 'react';

export const Admin = lazy(() => import('./Admin/Admin'));
export const ArticleContainer = lazy(() =>
  import('./Articles/ArticleContainer'),
);
export const BulkSendContainer = lazy(() =>
  import('./BulkSend/BulkSendContainer'),
);
export const BrowseAssetLibraryStandAlone = lazy(() =>
  import('./BrowseAssetLibraryPages/LibraryPage'),
);
export const Campaigns = lazy(() => import('./Campaigns/Campaigns'));
export const Contacts = lazy(() => import('./Contacts/Contacts'));
export const ContactsBulkCreate = lazy(() =>
  import('./Contacts/ContactsBulkCreate'),
);
export const ContactsMarketingView = lazy(() =>
  import('components/contacts/ContactsMarketing'),
);
export const DevArea = lazy(() => import('./DevArea'));
export const Dashboard = lazy(() => import('./Dashboard'));
export const DashboardTemplates = lazy(() =>
  import('./DashboardTemplates/DashboardTemplates'),
);
export const Discovery = lazy(() => import('./Discovery/Discovery'));
export const DrilldownContainer = lazy(() =>
  import('./Drilldown/DrilldownContainer'),
);
export const DrilldownReportContainer = lazy(() =>
  import('./Drilldown/DrilldownReportContainer'),
);
export const Email = lazy(() => import('./Email/Email'));
export const Impact = lazy(() => import('./Impact/impact'));
export const ManageData = lazy(() =>
  import('./AppSettings/ManageDataContainer'),
);
export const RecipientAlerts = lazy(() =>
  import('./Alerts/Unsubscribe/RecipientAlertsContainer'),
);
export const RecipientUnsubscribe = lazy(() =>
  import('./Alerts/Unsubscribe/RecipientUnsubscribeContainer'),
);
export const Report = lazy(() => import('./Report'));
export const Search = lazy(() => import('./Search/Search'));
export const SearchHistory = lazy(() => import('./Search/SearchHistory'));
export const SlackContainer = lazy(() => import('./Slack/SlackContainer'));
export const Stories = lazy(() => import('./Stories/Stories'));
export const Tags = lazy(() => import('./Tag'));
export const TranslationsEditor = lazy(() =>
  import('./Translate/TranslationsEditor'),
);
export const UnifiedSettings = lazy(() =>
  import('./UnifiedSettings/UnifiedSettings'),
);
export const Influencers = lazy(() => import('./Influencers'));
export const SocialSearch = lazy(() => import('./SocialSearch'));
export const WidgetDrilldown = lazy(() => import('./WidgetDrilldown'));
export const EarnedSearch = lazy(() => import('./EarnedSearch'));
export const ReportPresentation = lazy(() => import('./ReportPresentation'));
export const Alerts = lazy(() => import('./Alerts'));
export const SocialListening = lazy(() => import('./SocialListening'));
export const Announcements = lazy(() => import('./Announcements'));
export const EmailVerification = lazy(() => import('./EmailVerification'));
export const WelcomeHub = lazy(() => import('./WelcomeHub'));
