import PropTypes from 'prop-types';

export const propTypes = PropTypes.shape({
  activityType: PropTypes.string.isRequired,
  actorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  contentJson: PropTypes.shape({
    text: PropTypes.string,
    metadata: PropTypes.string.isRequired,
  }),
  dateCreated: PropTypes.number.isRequired,
  dateHappened: PropTypes.number.isRequired,
  externalId: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

export const propTypesOld = PropTypes.shape({
  id: PropTypes.string.isRequired,
  actor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  date_happened: PropTypes.number.isRequired,
  date_updated: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      actors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }),
      ),
      audiences: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }),
      ),
    }),
  }),
});

export default propTypes;
