import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Instagram = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M11.014 3h1.972c1.568.004 1.912.017 2.725.054.958.044 1.612.196 2.184.419.592.23 1.094.537 1.594 1.038.5.5.809 1.002 1.039 1.594.222.572.374 1.226.418 2.184.037.813.05 1.157.053 2.725v1.972c-.003 1.568-.016 1.912-.053 2.725-.044.958-.196 1.612-.418 2.184a4.412 4.412 0 01-1.039 1.594c-.5.5-1.002.809-1.594 1.039-.572.222-1.226.374-2.184.418-.96.044-1.267.054-3.711.054s-2.75-.01-3.71-.054c-.959-.044-1.613-.196-2.185-.418a4.412 4.412 0 01-1.594-1.039c-.5-.5-.809-1.002-1.038-1.594-.223-.572-.375-1.226-.419-2.184-.037-.813-.05-1.157-.053-2.725v-1.972c.003-1.568.016-1.912.053-2.725.044-.958.196-1.612.419-2.184A4.411 4.411 0 014.51 4.51c.5-.5 1.002-.809 1.594-1.038.572-.223 1.226-.375 2.184-.419.813-.037 1.157-.05 2.725-.053h1.972zm1.524 1.622h-1.075c-1.916.001-2.227.012-3.1.052-.877.04-1.354.187-1.67.31a2.79 2.79 0 00-1.036.673c-.315.315-.51.615-.673 1.035-.123.317-.27.794-.31 1.671-.04.873-.05 1.184-.052 3.1v1.075c.001 1.915.012 2.226.052 3.099.04.877.187 1.354.31 1.67.163.421.358.72.673 1.036.315.315.615.51 1.035.673.317.123.794.27 1.671.31.873.04 1.184.05 3.1.052h1.075c1.915-.001 2.226-.012 3.099-.052.877-.04 1.354-.187 1.67-.31a2.79 2.79 0 001.036-.673c.315-.315.51-.615.673-1.035.123-.317.27-.794.31-1.671.04-.873.05-1.184.052-3.1v-1.074c-.001-1.916-.012-2.227-.052-3.1-.04-.877-.187-1.354-.31-1.67a2.788 2.788 0 00-.673-1.036 2.788 2.788 0 00-1.035-.673c-.317-.123-.794-.27-1.671-.31-.873-.04-1.184-.05-3.1-.052zM12 7.378a4.622 4.622 0 110 9.244 4.622 4.622 0 010-9.244zM12 9a3 3 0 100 6 3 3 0 000-6zm4.804-2.884a1.08 1.08 0 110 2.16 1.08 1.08 0 010-2.16z"
    />
  </svg>
);

Instagram.propTypes = { fill: PropTypes.string };
Instagram.defaultProps = { fill: iconColorsMap.instagram };

export default Instagram;
