import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import withModifiers from '../withModifiers';
import { IconButton } from '../button';

class StatusBar extends Component {
  static baseClass = 'tk-status-bar';

  render() {
    const {
      bordered,
      children,
      className,
      onCancel,
      type,
      modifiers,
    } = this.props;

    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${StatusBar.baseClass}--${modifier}`)
      : [];
    const mainClass = classnames(
      StatusBar.baseClass,
      prefixedModifiers,
      `${StatusBar.baseClass}--${type}`,
      {
        [`${StatusBar.baseClass}--border-bottom`]: bordered,
        [`${StatusBar.baseClass}--has-cancel`]: onCancel !== null,
      },
    );

    return (
      <div className={`${mainClass} ${className}`}>
        {children}
        {onCancel && (
          <div className={`${StatusBar.baseClass}__cancel`}>
            <IconButton icon="cross" onClick={onCancel} compact size="small" />
          </div>
        )}
      </div>
    );
  }
}

StatusBar.defaultProps = {
  type: '',
  bordered: true,
  modifiers: [],
  className: '',
  style: {},
  onCancel: null,
};

StatusBar.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  modifiers: PropTypes.array,
  /* Only renders exit button if function is set */
  onCancel: PropTypes.func,
  type: PropTypes.string,
};

export default withModifiers(StatusBar);
