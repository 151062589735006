export default {
  campaignListTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-INFLUENCER-LIST',
    description:
      'Campaigns > My Campaigns > Choose Campaign > List Tab > Add > List Side Tray Title',
    defaultMessage: 'Update Campaign Lists',
  },
  campaignListEmptyStateMessage: {
    id: 'CAMPAIGN-SIDE-TRAY-INFLUENCER-LIST-EMPTY_STATE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > List Tab > Add > List Side Tray Empty State Text',
    defaultMessage:
      'You don’t have any saved or shared lists. Once you’ve created a list, you’ll be able to add it to any campaign. Lists that are shared with you can also be added to a campaign.',
  },
  campaignStoryTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-ADD-STORY',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Story Kits Tab > Add > Story Kits Side Tray Title',
    defaultMessage: 'Add Campaign Story Kits',
  },
  campaignAlertTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-ADD-ALERT',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Alerts Tab > Add > Alerts Side Tray Title',
    defaultMessage: 'Add Campaign Alerts',
  },
  campaignContactsTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-ADD-CONTACTS',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Contacts Tab > Add > Contact Side Tray Title',
    defaultMessage: 'Add Contacts',
  },
  campaignNewslettersTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-ADD-NEWSLETTERS',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Newsletters Tab > Add > Newsletters Side Tray Title',
    defaultMessage: 'Update Campaign Newsletters',
  },
  campaignReportsTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-ADD-REPORTS',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Reports Tab > Add > Reports Side Tray Title',
    defaultMessage: 'Update Campaign Reports',
  },
  campaignDetailsTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-UPDATE-DETAILS',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Action > Edit Campaign > Update Campaign Side Tray Title',
    defaultMessage: 'Update Campaign Details',
  },
  campaignUpdateSearchesTitle: {
    id: 'CAMPAIGN-SIDE-TRAY-UPDATE-SEARCHES',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Overview Tab > Update Searches > Update Searches Side Tray Title',
    defaultMessage: 'Update Searches',
  },
  goToSearches: {
    id: 'CAMPAIGN-SIDE-TRAY-SEARCH-CALL-TO-ACTION',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Searches Tab > Add > Call to action if no searches',
    defaultMessage: 'Go To Searches',
  },
  goToReports: {
    id: 'CAMPAIGN-SIDE-TRAY-REPORTS-CALL-TO-ACTION',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Reports Tab > Add > Call to action if no report',
    defaultMessage: 'Go To Reports',
  },
  goToNewsletters: {
    id: 'CAMPAIGN-SIDE-TRAY-NEWSLETTERS-CALL-TO-ACTION',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Newletters Tab > Add > Call to action if no Newsletters',
    defaultMessage: 'Go To Newsletters',
  },
  goToAlerts: {
    id: 'CAMPAIGN-SIDE-TRAY-ALERTS-CALL-TO-ACTION',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Alerts Tab > Add > Call to action if no Alerts',
    defaultMessage: 'Go To Alerts',
  },
  goToStoryKits: {
    id: 'CAMPAIGN-SIDE-TRAY-STORY-KITS-CALL-TO-ACTION',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Story Kits Tab > Add > Call to action if no Stories',
    defaultMessage: 'Go To Story Kits',
  },
  goToSavedLists: {
    id: 'CAMPAIGN-SIDE-TRAY-LISTS-CALL-TO-ACTION',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Lists Tab > Add > Call to action if no Lists',
    defaultMessage: 'Go To Saved Lists',
  },
  initialCampaignsEmptyStateAddCampaignButtonOriginalText: {
    id:
      'CAMPAIGNS_HOME_INITIAL_CAMPAIGNS_EMPTY_STATE_ADD_CAMPAIGN_BUTTON_ORIGINAL_TEXT',
    description: 'Original text for the empty state add campaign button',
    defaultMessage: 'Add a Campaign',
  },
  initialCampaignsEmptyStateAddCampaignButtonAlternativeText: {
    id:
      'CAMPAIGNS_HOME_INITIAL_CAMPAIGNS_EMPTY_STATE_ADD_CAMPAIGN_BUTTON_ALTERNATIVE_TEXT',
    description: 'Alternative text for the empty state add campaign button',
    defaultMessage: 'New Campaign',
  },
  initialCampaignEmptyStateOriginalTitle: {
    id: 'CAMPAIGNS_HOME_INITIAL_CAMPAIGNS_EMPTY_STATE_ORIGINAL_TITLE',
    description:
      'Original title for the empty state when there are no campaigns created',
    defaultMessage: 'Add Your First Campaign',
  },
  initialCampaignsEmptyStateTitle: {
    id: 'CAMPAIGNS_HOME_INITIAL_CAMPAIGNS_EMPTY_STATE_TITLE',
    description:
      'Title for the empty state when there are no campaigns created',
    defaultMessage:
      'Campaigns let you track your team’s outreach against your earned media.',
  },
  initialCampaignsEmptyStateText: {
    id: 'CAMPAIGNS_HOME_INITIAL_CAMPAIGNS_EMPTY_STATE_TEXT',
    description: 'Text for the empty state when there are no campaigns created',
    defaultMessage: 'Get started by creating your first campaign canvas',
  },
  listUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-LISTS-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Lists Tab > Add > Side Tray > Success message when lists are saved',
    defaultMessage: 'Successfully added campaign Lists.',
  },
  listUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-LISTS-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Lists Tab > Add > Side Tray > Failure message when lists are not saved',
    defaultMessage: 'Failed to add campaign Lists.',
  },
  reportsUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-REPORTS-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Reports Tab > Add > Side Tray > Success message when reports are saved',
    defaultMessage: 'Successfully added campaign reports.',
  },
  reportsUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-REPORTS-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Reports Tab > Add > Side Tray > Failure message when reports are not saved',
    defaultMessage: 'Failed to add campaign reports.',
  },
  newslettersUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-NEWSLETTERS-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Newsletter Tab > Add > Side Tray > Success message when newsletters are saved',
    defaultMessage: 'Successfully added campaign newsletters.',
  },
  newslettersUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-NEWSLETTERS-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Newsletters Tab > Add > Side Tray > Failure message when newsletters are not saved',
    defaultMessage: 'Failed to add campaign newsletters.',
  },
  searchesUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-SEARCHES-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Searches Tab > Add > Side Tray > Success message when searches are saved',
    defaultMessage: 'Successfully added campaign searches.',
  },
  searchesUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-SEARCHES-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Searches Tab > Add > Side Tray > Failure message when searches are not saved',
    defaultMessage: 'Failed to add campaign searches.',
  },
  dashboardsUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-DASHBOARDS-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Dashboards Tab > Add > Side Tray > Success message when dashboards are saved',
    defaultMessage: 'Successfully added campaign dashboards.',
  },
  dashboardsUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-DASHBOARDS-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Dashboards Tab > Add > Side Tray > Failure message when dashboards are not saved',
    defaultMessage: 'Failed to add campaign dashboards.',
  },
  contactsUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-CONTACTS-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Contacts Tab > Add > Side Tray > Success message when contacts are saved',
    defaultMessage: 'Successfully added campaign contacts.',
  },
  contactsUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-CONTACTS-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Contacts Tab > Add > Side Tray > Failure message when contacts are not saved',
    defaultMessage: 'Failed to add campaign contacts.',
  },
  alertsUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-ALERTS-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Alerts Tab > Add > Side Tray > Success message when alerts are saved',
    defaultMessage: 'Successfully added campaign alerts.',
  },
  alertsUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-ALERTS-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Alerts Tab > Add > Side Tray > Failure message when alerts are not saved',
    defaultMessage: 'Failed to add campaign alerts.',
  },
  storiesUpdateSuccess: {
    id: 'CAMPAIGN-UPDATE-STORY-KITS-SAVE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Story Kits Tab > Add > Side Tray > Success message when stories are saved',
    defaultMessage: 'Successfully added campaign stories.',
  },
  storiesUpdateFailed: {
    id: 'CAMPAIGN-UPDATE-STORY-KITS-SAVE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Story Kits Tab > Add > Side Tray > Failure message when stories are not saved',
    defaultMessage: 'Failed to add campaign stories.',
  },
  deleteCampaignLabelTooltip: {
    id: 'CAMPAIGN_DELETE_CAMPAIGN_LABEL_TOOLTIP',
    description: 'Campaigns > My Campaigns > Delete campaign label text',
    defaultMessage: 'Delete Campaign',
  },
  deleteCampaignSuccess: {
    id: 'CAMPAIGN-DELETE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Choose Actions > Delete Campaign > Success message when campaing is deleted',
    defaultMessage: 'Successfully deleted campaign',
  },
  deleteCampaignFailed: {
    id: 'CAMPAIGN-DELETE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Choose Actions > Delete Campaign > Failure message when campaing is not deleted',
    defaultMessage: 'There was a problem deleting campaign.',
  },
  updateCampaignSuccess: {
    id: 'CAMPAIGN-UPDATE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Choose Actions > Edit Campaign > Success message when campaing is edited',
    defaultMessage: 'Successfully updated campaign',
  },
  updateCampaignFailed: {
    id: 'CAMPAIGN-UPDATE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Choose Actions > Edit Campaign > Failure message when campaing is not edited',
    defaultMessage: 'There was a problem updating campaign.',
  },
  campaignBrandingCategory: {
    id: 'CAMPAIGN_BRANDING_CATEGORY',
    description: 'Campaigns > My Campaigns > Campaign branding category',
    defaultMessage: 'Branding',
  },
  shareCampaignSuccess: {
    id: 'CAMPAIGN-SHARE-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Share > Success message when campaing is shared',
    defaultMessage: 'Successfully shared campaign',
  },
  shareCampaignFailed: {
    id: 'CAMPAIGN-SHARE-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Share > Failure message when campaing is not shared',
    defaultMessage: 'Failed to share campaign.',
  },
  campaignAwarenessCategory: {
    id: 'CAMPAIGN_AWARENESS_CATEGORY',
    description: 'Campaigns > My Campaigns > Campaign awareness category',
    defaultMessage: 'Awareness',
  },
  newCampaignText: {
    id: 'CAMPAIGN-NEW-CAMPAIGN-TEXT',
    description: 'Campaigns > My Campaigns: text for new campaign labels',
    defaultMessage: 'New Campaign',
  },
  searchesDeletedSuccess: {
    id: 'CAMPAIGN-DELETE-SEARCHES-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Search Tab > Choose Search >  Remove > Success message when searches are deleted',
    defaultMessage:
      'Successfully deleted campaign {COUNT, plural, =1{search} other {searches}}.',
  },
  searchesDeletedFailed: {
    id: 'CAMPAIGN-DELETE-SEARCHES-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Search Tab > Choose Search >  Remove > Success message when searches are not deleted',
    defaultMessage:
      'Failed to deleted campaign {COUNT, plural, =1{search} other {searches}}',
  },
  storiesDeletedSuccess: {
    id: 'CAMPAIGN-DELETE-STORY-KITS-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Dashboards Tab > Choose Story >  Remove > Success message when dashboards are deleted',
    defaultMessage: 'Successfully deleted campaign stories.',
  },
  storiesDeletedFailed: {
    id: 'CAMPAIGN-DELETE-STORY-KITS-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Dashboards Tab > Choose Story >  Remove > Success message when dashboards are not deleted',
    defaultMessage: 'Failed to deleted campaign stories.',
  },
  dashboardsDeletedSuccess: {
    id: 'CAMPAIGN-DELETE-DASHBOARDS-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Dashboards Tab > Choose Dashboard >  Remove > Success message when dashboards are deleted',
    defaultMessage: 'Successfully deleted campaign dashboard(s).',
  },
  dashboardsDeletedFailed: {
    id: 'CAMPAIGN-DELETE-DASHBOARDS-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Dashboards Tab > Choose Dashboard >  Remove > Success message when dashboards are not deleted',
    defaultMessage: 'Failed to deleted campaign dashboard(s).',
  },
  reportsDeletedSuccess: {
    id: 'CAMPAIGN-DELETE-REPORTS-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Reports Tab > Choose Reports >  Remove > Success message when reports are deleted',
    defaultMessage: 'Successfully deleted campaign report(s).',
  },
  reportsDeletedFailed: {
    id: 'CAMPAIGN-DELETE-REPORTS-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > Reports Tab > Choose Reports >  Remove > Success message when reports are not deleted',
    defaultMessage: 'Failed to deleted campaign report(s).',
  },
  alertsDeletedSuccess: {
    id: 'CAMPAIGN-DELETE-ALERTS-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > alert Tab > Choose alert >  Remove > Success message when alerts are deleted',
    defaultMessage: 'Successfully deleted campaign alert(s).',
  },
  alertsDeletedFailed: {
    id: 'CAMPAIGN-DELETE-ALERTS-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > alert Tab > Choose alert >  Remove > Success message when alerts are not deleted',
    defaultMessage: 'Failed to deleted campaign alert(s).',
  },
  newslettersDeletedSuccess: {
    id: 'CAMPAIGN-DELETE-NEWSLETTERS-SUCCESS-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > newsletter Tab > Choose newsletter >  Remove > Success message when newsletter are deleted',
    defaultMessage: 'Successfully deleted campaign newsletter.',
  },
  newslettersDeletedFailed: {
    id: 'CAMPAIGN-DELETE-NEWSLETTERS-FAILED-KITE-MESSAGE',
    description:
      'Campaigns > My Campaigns > Choose Campaign > newsletter Tab > Choose newsletter >  Remove > Success message when newsletter are not deleted',
    defaultMessage: 'Failed to deleted campaign newsletter.',
  },
  campaignsCreationFailed: {
    id: 'CAMPAIGN-CREATE-NEW-CAMPAIGN-MESSAGE-FAILED',
    description:
      'Campaigns > My Campaigns > New Campaign > Error message when Campaign creation fails',
    defaultMessage: 'There was a problem creating the campaign.',
  },
  campaignsCreationSucceeded: {
    id: 'CAMPAIGN-CREATE-NEW-CAMPAIGN-MESSAGE-SUCCESS',
    description:
      'Campaigns > My Campaigns > New Campaign > Success message when Campaign is created without errors',
    defaultMessage: 'Campaign created successfully.',
  },
  allReportsFilter: {
    id: 'CAMPAIGN-SIDE-TRAY-REPORTS-ALL-REPORTS',
    description:
      'Filter label for displaying all reports, including reports v1 and v2',
    defaultMessage: 'All Reports',
  },
  reportsV1Filter: {
    id: 'CAMPAIGN-SIDE-TRAY-REPORTS-ALL-REPORTS-V1-FILTER',
    description: 'Filter label for displaying only reports from v1',
    defaultMessage: 'Reports 1.0 Only',
  },
  reportsV2Filter: {
    id: 'CAMPAIGN-SIDE-TRAY-REPORTS-ALL-REPORTS-V2-FILTER',
    description: 'Filter label for displaying only reports from v2',
    defaultMessage: 'Reports 2.0 Only',
  },
  campaignType: {
    id: 'CAMPAIGN-TYPE',
    description: 'Filter label for campaigns types',
    defaultMessage: 'Filter By: {CAMPAIGN_TYPE}',
  },
  campaignOwner: {
    id: 'CAMPAIGN-OWNER',
    description: 'Filter label for Campaign Owner',
    defaultMessage: 'Filter By: {CAMPAIGN_OWNER}',
  },
  campaignListView: {
    id: 'CAMPAIGN-LIST-VIEW',
    description: "Display campaigns' list view",
    defaultMessage: 'List View',
  },
  campaignFindAnEntry: {
    id: 'CAMPAIGN-FIND-AN-ENTRY',
    description: "Display campaigns' search placeholder",
    defaultMessage: 'Find an entry',
  },
  campaignHeaderTitle: {
    id: 'CAMPAIGN-HEADER-CAMPAIGN-TITLE',
    description: 'Campaigns table column header > Title',
    defaultMessage: 'Title',
  },
  campaignHeaderOwner: {
    id: 'CAMPAIGN-HEADER-CAMPAIGN-OWNER',
    description: 'Campaigns table column header > Owner',
    defaultMessage: 'Owner',
  },
  campaignHeaderShareSetting: {
    id: 'CAMPAIGN-HEADER-CAMPAIGN-SHARE-SETTING',
    description: 'Campaigns table column header > Share Setting',
    defaultMessage: 'Share Setting',
  },
  campaignHeaderCategory: {
    id: 'CAMPAIGN-HEADER-CAMPAIGN-CATEGORY',
    description: 'Campaigns table column header > Category',
    defaultMessage: 'Category',
  },
  campaignHeaderDescription: {
    id: 'CAMPAIGN-HEADER-CAMPAIGN-DESCRIPTION',
    description: 'Campaigns table column header > Description',
    defaultMessage: 'Description',
  },
  campaignHeaderLastModified: {
    id: 'CAMPAIGN-HEADER-CAMPAIGN-LAST-MODIFIED',
    description: 'Campaigns table column header > Last Modified',
    defaultMessage: 'Last Modified',
  },
  campaignEmptySearchResults: {
    id: 'CAMPAIGN-EMPTY-SEARCH-RESULTS',
    description: 'Campaigns search with 0 results',
    defaultMessage: 'No campaigns to display',
  },
  campaignAdjustEmptySearchResults: {
    id: 'CAMPAIGN-ADJUST-EMPTY-SEARCH-RESULTS',
    description: "Adjust campaigns' search or applied filters",
    defaultMessage: 'Please try adjusting your search or applied filters.',
  },
  campaignCategoryFilterPill: {
    id: 'CAMPAIGN-CATEGORY-FILTER-PILL',
    description: "Filtering pill indicator for the campaign's category",
    defaultMessage: 'Category',
  },
  campaignLastModifiedFilterPill: {
    id: 'CAMPAIGN-LAST-MODIFIED-FILTER-PILL',
    description: "Filtering pill indicator for the campaign's last modified",
    defaultMessage: 'Last Modified',
  },
  campaignLastModifiedFilter7Days: {
    id: 'CAMPAIGN-LAST-MODIFIED-FILTER-7-DAYS',
    description: 'Date range filter option that indicates the last 7 days',
    defaultMessage: 'Last 7 days',
  },
  campaignLastModifiedFilter30Days: {
    id: 'CAMPAIGN-LAST-MODIFIED-FILTER-30-DAYS',
    description: 'Date range filter option that indicates the last 30 days',
    defaultMessage: 'Last 30 days',
  },
  campaignLastModifiedFilter90Days: {
    id: 'CAMPAIGN-LAST-MODIFIED-FILTER-90-DAYS',
    description: 'Date range filter option that indicates the last 90 days',
    defaultMessage: 'Last 90 days',
  },
  campaignLastModifiedFilter365Days: {
    id: 'CAMPAIGN-LAST-MODIFIED-FILTER-365-DAYS',
    description: 'Date range filter option that indicates the last 365 days',
    defaultMessage: 'Last 365 days',
  },
  campaignLastModifiedFilterCustomDays: {
    id: 'CAMPAIGN-LAST-MODIFIED-FILTER-CUSTOM-DAYS',
    description:
      'Date range filter option that indicates the customized days/months/years range',
    defaultMessage: 'Custom',
  },
  campaignOwnerFilterPill: {
    id: 'CAMPAIGN-OWNER-FILTER-PILL',
    description: "Filtering pill indicator for the campaign's owner",
    defaultMessage: 'Owner',
  },
  campaignShareSettingFilterPill: {
    id: 'CAMPAIGN-SHARE-SETTING-FILTER-PILL',
    description: "Filtering pill indicator for the campaign's share setting",
    defaultMessage: 'Share Setting',
  },
  campaignShareSettingTypeShared: {
    id: 'CAMPAIGN-SHARE-SETTING-TYPE-SHARED',
    description: "Indicator for the campaign's share setting: Shared",
    defaultMessage: 'Shared',
  },
  campaignShareSettingTypePrivate: {
    id: 'CAMPAIGN-SHARE-SETTING-TYPE-PRIVATE',
    description: "Indicator for the campaign's share setting: Private",
    defaultMessage: 'Private',
  },
  campaignSortByOwnerLastName: {
    id: 'CAMPAIGN-SORT-BY-OWNER-LAST-NAME',
    description: "Selection to sort by the Owner's last name",
    defaultMessage: 'Owner (Last Name)',
  },
};
