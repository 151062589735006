export enum ActivityTypes {
  COFFEE,
  DINNER,
  DRINKS,
  LUNCH,
  MEETING,
  PHONE_CALL,
  EMAIL_PITCH,
  EMAIL_ANNOUNCEMENT,
}

export enum SourceTypes {
  INFLUENCER,
  USER,
}

export enum SubjectTypes {
  INFLUENCER,
  USER,
  EMAIL,
}

export enum EmailSubTypes {
  SEND,
  OPEN,
  REPLY,
  CLICK,
  UNSUBSCRIBE,
}
