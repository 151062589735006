import React from 'react';

import classnames from 'classnames';
import globalMessages from 'i18n/Global.messages';
import PropTypes from 'prop-types';

import './publications.scss';

import { useIntl } from 'react-intl';

import Button from '../button';
import { CollapsibleList } from '../list';
import ShimmerText from '../shimmer-text';
import { Body as Text } from '../type';

const LargeToggle = ({
  children,
  collapsed,
  collapsedLimit,
  onToggleCollapsed,
}) => {
  const intl = useIntl();
  return (
    <Button
      data-qa="ohD6JLCpj6NazMFAWRSyS"
      key="collapsible-list__toggle"
      modifiers={['link', 'medium']}
      onClick={onToggleCollapsed}
    >
      {collapsed &&
        intl.formatMessage(globalMessages.moreItems, {
          ITEMS_COUNT: intl.formatNumber(children.length - collapsedLimit),
        })}
      {!collapsed && intl.formatMessage(globalMessages.showLess)}
    </Button>
  );
};

LargeToggle.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapsedLimit: PropTypes.number.isRequired,
  onToggleCollapsed: PropTypes.func.isRequired,
};

const Publications = ({
  children,
  className,
  loading,
  size,
  style,
  truncate,
}) => {
  if (!children || !children.length) {
    return null;
  }

  const pubSize = {
    sm: 'sm',
    md: 'sm',
    lg: 'md',
  }[size];

  const mainClass = classnames(Publications.baseClass, className, {
    [`${Publications.baseClass}--${size}`]: size,
    [`${Publications.baseClass}--truncate`]: truncate,
  });

  const publications = children.map((pub, i) => (
    <span className={`${Publications.baseClass}__publication`} key={pub}>
      <Text color="light" size={pubSize}>
        {pub}
        {children.length > 1 && i < children.length - 1 ? ', ' : ' '}
      </Text>
    </span>
  ));

  return (
    <div className={mainClass} style={style}>
      {loading && (
        <div className={`${Publications.baseClass}__publication`}>
          <ShimmerText style={{ width: '8em' }} />
        </div>
      )}
      {!loading && (
        <CollapsibleList
          collapsedLimit={7}
          ToggleComponent={size === 'lg' ? LargeToggle : null}
          toggleComponentWrapper={Publications.renderToggleComponentWrapper}
        >
          {publications}
        </CollapsibleList>
      )}
    </div>
  );
};

Publications.renderToggleComponentWrapper = function renderToggleComponentWrapper(
  props,
) {
  return (
    <div
      {...props}
      className={`${Publications.baseClass}__publication`}
      key={`${Publications.baseClass}__publication-toggle`}
    />
  );
};

Publications.baseClass = 'tk-person-publications';

Publications.propTypes = {
  /** Optional custom class names, applied to the outer wrapper of the component (in addition to the normal classes). */
  className: PropTypes.string,
  /** Show a loading state for the whole person block */
  loading: PropTypes.bool,
  /** Publication names */
  children: PropTypes.arrayOf(PropTypes.string),
  /** Size variants */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /** Optional custom style object, applied to the outer wrapper of the component. */
  style: PropTypes.object,
  /** Limit each author details section to 1 line */
  truncate: PropTypes.bool,
};

Publications.defaultProps = {
  className: '',
  loading: false,
  children: [],
  size: 'md',
  style: {},
  truncate: false,
};

export default Publications;
