import { createSelector } from 'reselect';

import { ANALYTICS_INTEGRATION_TYPES } from 'constants/constants';
import { hasFeatureFlagSelector } from 'selectors/account';

const appInfoSelector = state => (state.appInfo ? state.appInfo : {});

const analyticsIntegrationsSelector = createSelector(
  appInfoSelector,
  appInfo => {
    if (appInfo && Array.isArray(appInfo.analyticsIntegrations)) {
      return appInfo.analyticsIntegrations.map(integration => {
        const analyticsTypeKeys = Object.keys(
          ANALYTICS_INTEGRATION_TYPES,
        ).filter(
          type =>
            ANALYTICS_INTEGRATION_TYPES[type].id ===
            integration.analyticsIntegrationType,
        );
        const thisType = analyticsTypeKeys.length
          ? ANALYTICS_INTEGRATION_TYPES[analyticsTypeKeys[0]]
          : null;
        return thisType
          ? { ...integration, analyticsTypePrefix: thisType.prefix }
          : { ...integration };
      });
    }

    return [];
  },
);

const allowedAnalyticsIntegrationsSelector = createSelector(
  analyticsIntegrationsSelector,
  hasFeatureFlagSelector,
  (integrations, hasFeatureFlag) =>
    integrations.filter(integration =>
      hasFeatureFlag(integration.analyticsIntegrationType),
    ),
);

export const enabledAnalyticsIntegrationsSelector = createSelector(
  allowedAnalyticsIntegrationsSelector,
  integrations => integrations.filter(integration => integration.isEnabled),
);

export const enabledAnalyticsIntegrationsMappedToAccountsSelector = createSelector(
  enabledAnalyticsIntegrationsSelector,
  integrations =>
    integrations.map(integration => ({
      id: integration.analyticsId,
      infoIcon: null,
      subtitle: '',
      svgIcon: '',
      title: integration.analyticsIntegrationName,
      titleString: integration.analyticsIntegrationName,
      type: integration.analyticsIntegrationType,
    })),
);

export const enabledAnalyticsIntegrationsMappedToGaAccountsSelector = createSelector(
  enabledAnalyticsIntegrationsMappedToAccountsSelector,
  accounts =>
    accounts.filter(
      a => a.type === ANALYTICS_INTEGRATION_TYPES.googleAnalytics.id,
    ),
);

export const enabledAnalyticsIntegrationsMappedToAdobeAccountsSelector = createSelector(
  enabledAnalyticsIntegrationsMappedToAccountsSelector,
  accounts =>
    accounts.filter(
      a => a.type === ANALYTICS_INTEGRATION_TYPES.adobeAnalytics.id,
    ),
);

export const mixedAnalyticsIntegrationTypesSelector = createSelector(
  enabledAnalyticsIntegrationsSelector,
  integrations => {
    return integrations.reduce((hasMixedTypes, integration, index) => {
      if (
        index &&
        integrations[index - 1].analyticsIntegrationType !==
          integration.analyticsIntegrationType
      ) {
        hasMixedTypes = true;
      }

      return hasMixedTypes;
    }, false);
  },
);

export const defaultAnalyticsIntegrationSelector = createSelector(
  enabledAnalyticsIntegrationsSelector,
  integrations => {
    const defaultIntegrations = integrations.filter(
      integration => integration.isDefault,
    );
    return defaultIntegrations.length ? defaultIntegrations[0] : null;
  },
);

export const defaultAnalyticsIntegrationIdSelector = createSelector(
  defaultAnalyticsIntegrationSelector,
  integration => {
    return integration ? integration.analyticsId : NaN;
  },
);

export const defaultAnalyticsIntegrationTypeSelector = createSelector(
  defaultAnalyticsIntegrationSelector,
  integration => {
    return integration ? integration.analyticsIntegrationType : '';
  },
);

export const lockedAnalyticIntegrationSelector = state =>
  state.appInfo.lockedAnalyticIntegration;
