import {
  PR_MODEL_MY_FACETS_ENDPOINT,
  SEARCH_LIST_ENDPOINT_V2,
} from 'constants/apis';
import { DEV_FEATURES } from 'constants/constants';
import {
  GET_DISCOVERY_FACETS,
  GET_DISCOVERY_FACETS_ERROR,
  GET_DISCOVERY_FACETS_SUCCESS,
} from 'reducers/discovery/discovery';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';

import {
  GET_SEARCH_LIST_ERROR,
  GET_SEARCH_LIST_RECEIVED,
  GET_SEARCH_LIST,
  GET_SHARED_SEARCH_LIST_ERROR,
  GET_SHARED_SEARCH_LIST_RECEIVED,
  GET_SHARED_SEARCH_LIST,
  GET_LATEST_SEARCH_DATA,
  GET_LATEST_SEARCH_DATA_RECEIVED,
  GET_LATEST_SEARCH_DATA_ERROR,
} from 'reducers/searches';

import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import { performGet } from 'services/rest-service/rest-service';

export const GET_FACETS = 'facets/GET_FACETS';
export const GET_FACETS_SUCCESS = 'facets/GET_FACETS_SUCCESS';
export const GET_FACETS_ERROR = 'facets/GET_FACETS_ERROR';

export const initialState = {
  error: false,
  facetsById: {},
  loading: false,
};

const EXCLUDED_CATEGORIES = [
  'blacklisted publications',
  'location',
  'language',
  'on-brand messaging',
  'seo keyword',
];

const facetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FACETS: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case GET_FACETS_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    case GET_FACETS_SUCCESS: {
      return {
        ...state,
        facetsById: {
          ...state.facetsById,
          ...action.payload,
        },
        error: false,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export const getMyFacetsActionCreator = campaignId => dispatch => {
  dispatch({ type: GET_FACETS });
  dispatch({ type: GET_SEARCH_LIST });

  const facets = performGet(PR_MODEL_MY_FACETS_ENDPOINT);
  const searchParams = {
    ignoreKeyMessages: true,
    skipTranslate: true,
  };

  if (campaignId) {
    searchParams.includeCampaign = campaignId;
  }

  let searches;
  let sharedSearches;

  if (userHasDevFeatureFlag(DEV_FEATURES.newSearchApi)) {
    searches = performGet(SEARCH_LIST_ENDPOINT_V2, searchParams);

    sharedSearches = [];
  } else {
    searches = performGet(`${SEARCH_LIST_ENDPOINT_V2}`, searchParams);

    dispatch({ type: GET_SHARED_SEARCH_LIST });

    sharedSearches = performGet(SEARCH_LIST_ENDPOINT_V2, {
      ...searchParams,
      shared: true,
    });
  }

  dispatch({ type: GET_LATEST_SEARCH_DATA });
  const latestSearch = performGet(`${SEARCH_LIST_ENDPOINT_V2}/metadata/latest`);

  return Promise.all([facets, searches, sharedSearches, latestSearch])
    .then(responses => {
      const searchesData = (responses[1] || {}).data || [];
      const sharedSearchesData = (responses[2] || {}).data || [];
      const allSearches = searchesData.concat(sharedSearchesData);
      const latestSearchData = (responses[3] || {}).data || [];

      const searchesMap = searchesData.reduce((result, search) => {
        result[search.id] = search;

        return result;
      }, {});

      dispatch({
        type: GET_SEARCH_LIST_RECEIVED,
        payload: searchesMap,
      });

      if (!userHasDevFeatureFlag(DEV_FEATURES.newSearchApi)) {
        // Close the loop for GET_SHARED_SEARCH_LIST only if we started it.
        const sharedSearchesMap = sharedSearchesData.reduce(
          (result, search) => {
            result[search.id] = search;

            return result;
          },
          {},
        );

        dispatch({
          type: GET_SHARED_SEARCH_LIST_RECEIVED,
          payload: sharedSearchesMap,
        });
      }

      const allSearchesArray = [...new Set(allSearches)];
      const facetsArray = []; // For sorting
      const currentUserId = window?.activeUser?.id;

      /*
      If the latestSearchData doesn't have any results, we'll retrieve the ID where the user ID matches the
      current user and corresponds to the most recent lastView timestamp. If such an entry is absent,
      we'll obtain the ID associated with the latest lastView timestamp, regardless of the user ID.
      */
      const latestSearchView =
        !latestSearchData?.id &&
        currentUserId &&
        allSearchesArray?.reduce(
          (acc, currentItem) => {
            const lastViewDate = new Date(currentItem.lastView);

            if (
              (currentItem.userId === currentUserId &&
                lastViewDate > acc.date) ||
              lastViewDate > acc.date
            ) {
              return {
                id: currentItem.id,
                date: lastViewDate,
              };
            }

            return acc;
          },
          {
            id: null,
            date: new Date(0),
          },
        );

      let latestSearchDataReceived = latestSearchData?.id
        ? latestSearchData
        : latestSearchView || {};

      if (!latestSearchDataReceived.id && allSearchesArray.length > 0) {
        latestSearchDataReceived = allSearchesArray[0];
      }

      dispatch({
        type: GET_LATEST_SEARCH_DATA_RECEIVED,
        payload: latestSearchDataReceived,
      });

      const facetsById = responses[0].data.facets
        .map(facet => {
          facet.searchIds = allSearchesArray.reduce((result, search) => {
            const uuidMatch =
              search.facet.uuid && search.facet.uuid === facet.uuid;
            const nameMatch =
              search.facet.name.toLowerCase() === facet.name.toLowerCase();

            if (uuidMatch || nameMatch) {
              result.push(search.id);
            }

            return result;
          }, []);

          return facet;
        })
        .reduce((result, facet) => {
          // Exclude filtered
          if (EXCLUDED_CATEGORIES.indexOf(facet.name.toLowerCase()) >= 0) {
            return result;
          }

          // Get searchIds arrays for each facet
          const searchIds = allSearchesArray.reduce(
            (searchIdsResult, search) => {
              const uuidMatch =
                search.facet.uuid && search.facet.uuid === facet.uuid;
              const nameMatch =
                search.facet.name.toLowerCase() === facet.name.toLowerCase();

              if (uuidMatch || nameMatch) {
                searchIdsResult.push(search.id);
              }

              return searchIdsResult;
            },
            [],
          );

          // Add current facet to facets map
          result[facet.uuid] = {
            ...facet,
            searchIds,
          };

          facetsArray.push(facet);

          return result;
        }, {});

      const sortedFacetIds = facetsArray
        .sort((a, b) => {
          // Simultaneously sort by name and sort custom to last
          if (a.name.toLowerCase() === 'custom') {
            return 1;
          } else if (b.name.toLowerCase() === 'custom') {
            return -1;
          }

          return a.name.localeCompare(b.name);
        })
        .map(facet => facet.uuid);

      dispatch({ type: GET_FACETS_SUCCESS, payload: facetsById });

      return {
        facetsById,
        sortedFacetIds,
      };
    })
    .catch(() => {
      dispatch({ type: GET_FACETS_ERROR });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Failed to get facets',
          status: 'danger',
        }),
      );

      dispatch({ type: GET_SEARCH_LIST_ERROR });

      if (!userHasDevFeatureFlag(DEV_FEATURES.newSearchApi)) {
        dispatch({ type: GET_SHARED_SEARCH_LIST_ERROR });
      }

      dispatch({ type: GET_LATEST_SEARCH_DATA_ERROR });
    });
};

export const getFacetsForDiscoveryActionCreator = campaignId => dispatch => {
  dispatch({ type: GET_DISCOVERY_FACETS });

  return dispatch(getMyFacetsActionCreator(campaignId))
    .then(result => {
      dispatch({
        type: GET_DISCOVERY_FACETS_SUCCESS,
        payload: result.sortedFacetIds,
      });
    })
    .catch(() => {
      dispatch({ type: GET_DISCOVERY_FACETS_ERROR });
    });
};

export default facetsReducer;
