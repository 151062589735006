export default {
  browsePopupTitle: {
    id: 'BROWSE_ASSETS_LIBRARY_TITLE',
    defaultMessage: 'Asset Library',
    description:
      'Select Story  > Click Browse Asset library link > Popup Title',
  },
  saveAndInsert: {
    id: 'SAVE_AND_INSERT_BUTTON',
    defaultMessage: 'Save And Insert',
    description:
      'Select Story  > Click Browse Asset library link > Modal popup > Click edit section of any Asset >Edit Page Down Section Save And Insert Button',
  },
  insertButtonForLibraryForDownloadable: {
    id: 'SAVE_BUTTON_FOR_DOWNLOADABLE',
    defaultMessage: 'Insert {NUM_ASSETS} Files',
    description:
      'Select Story  > Click Browse Asset library link > Modal popup > Click  Asset link >Select any asset radio button> click select button bottom',
  },
  insertButtonForLibrary: {
    id: 'SAVE_BUTTON',
    defaultMessage: 'Insert {NUM_ASSETS} File',
    description:
      'Select Story  > Click Browse Asset library link > Modal popup > Click  Asset link >Select any asset radio button> click select button bottom',
  },
  browseAssetLibraryLink: {
    id: 'BROWSE_ASSET_LIBRARY_LINK',
    defaultMessage: 'Browse Asset Library',
    description:
      'Select Story  > Browse Asset library link instead of upload image link',
  },
  mediaStudioLink: {
    id: 'MEDIA_STUDIO_LINK',
    defaultMessage: 'Media Studio',
    description:
      'Select Story  > Click on Browse Asset library Link > Pop up with link Media Studio on left panel',
  },
  mediaUploadFromComputer: {
    id: 'MEDIA_STUDIO_LINK_UPLOAD_FROM_COMPUTER',
    defaultMessage: 'Upload From Computer',
    description:
      'Select Story  > Click on Browse Asset library Link > Pop up with link Media Studio on left panel',
  },
  mediaUploadFromUrl: {
    id: 'MEDIA_STUDIO_LINK_UPLOAD_FROM_URL',
    defaultMessage: 'Upload From URL',
    description:
      'Select Story  > Click on Browse Asset library Link > Pop up with link Media Studio on left panel',
  },
  mediaUploadFromComputerSupportedFormats: {
    id: 'MEDIA_STUDIO_LINK_UPLOAD_FROM_COMPUTER_SUPPORTED_FORMATS',
    defaultMessage: 'supported file formats include: jpg,png,jpeg',
    description:
      'Select Story  > Click on Browse Asset library Link > Pop up with link Media Studio on left panel',
  },
  mediaStudioEnterUrlHeading: {
    id: 'MEDIA_STUDIO_LINK_UPLOAD FROM_URL_ENTER_URL_TEXT',
    defaultMessage: 'Enter a URL in the field below',
    description:
      'Select Story  > Click on Browse Asset library Link > have 3 tabs on left side > Click on upload from Url > can see the text',
  },
  mediaStudioUploadFromUrlGetImage: {
    id: 'MEDIA_STUDIO_LINK_UPLOAD FROM_URL_GET_IMAGE_BUTTON',
    defaultMessage: 'Get Image',
    description:
      'Select Story  > Click on Browse Asset library Link >  have 3 tabs on left side > click on upload from Url > can see the button with label get image',
  },
  insertButtonForUploadScreens: {
    id: 'MEDIA_STUDIO_LINK_UPLOAD_SCREEN_INSERT_BUTTON',
    defaultMessage: 'Insert Image',
    description:
      'Select Story  > Click on Browse Asset library Link > have 3 tabs > click on upload from Computer > can see the button label as Insert images down',
  },
  cancelButtonForUploadScreens: {
    id: 'MEDIA_STUDIO_LINK_UPLOAD_SCREEN_CANCEL_BUTTON',
    defaultMessage: 'Cancel',
    description:
      'Select Story  > Click on Browse Asset library Link > have 3 tabs > click on upload from Computer > can see the button label as Cancel down',
  },
  mediaStudioFileSupport: {
    id: 'MEDIA_STUDIO_FILE_SUPPORT',
    defaultMessage: 'File Support',
    description:
      'Select Story  > Click on Browse Asset library Link > have 3 tabs > click on upload from Computer > can see the text with supported extension being listed',
  },
  mediaStudioUploadFromComputerBrowseFiles: {
    id: 'MEDIA_STUDIO_UPLOAD_FROM_COMPUTER_BROWSE_FILES',
    defaultMessage: 'Browse Files',
    description:
      'Select Story  > Click on Browse Asset library Link > have 3 tabs > click on upload from Computer > can see the button browse files',
  },
  mediaStudioUploadFromComputerAgrementText: {
    id: 'MEDIA_STUDIO_UPLOAD_FROM_COMPUTER_AGREMENT_TEXT',
    defaultMessage:
      'By selecting this option, you agree to store uploaded files on Media Studio',
    description:
      'Select Story  > Click on Browse Asset library Link >  have 3 tabs > click on upload from Computer > you can see the text there down',
  },
  mediaStudioUploadFromComputerCheckboxText: {
    id: 'MEDIA_STUDIO_UPLOAD_FROM_COMPUTER_CHECKBOX_TEXT',
    defaultMessage: 'I want to store the selected files on Media Studio.',
    description:
      'Select Story  > Click on Browse Asset library Link > have 3 tabs > click on upload from Computer > can see this text down',
  },
  mediaStudioUploadFromComputerCheckboxTextLearnMore: {
    id: 'MEDIA_STUDIO_UPLOAD_FROM_COMPUTER_CHECKBOX_TEXT_LEARN_MORE',
    defaultMessage: 'View Disclaimer',
    description:
      'Select Story  > Click on Browse Asset library Link > have 3 tabs > click on upload from Computer > can see this text learn more ',
  },
  mediaStudioUploadFiles: {
    id: 'MEDIA_STUDIO_UPLOAD_FILES',
    defaultMessage: 'Upload Files',
    description:
      'Select Story  > Click on Browse Asset library > Pop up with link Upload file on center of modal',
  },
  mediaStudioFileSupportTypes: {
    id: 'MEDIA_STUDIO_UPLOAD_FILES_TYPES',
    defaultMessage: 'JPG, PNG, PDF, MP4 Less than 20 MB',
    description:
      'Select Story  > Click on Browse Asset library> Pop up with link Upload file on center of modal>below that file types mentioned',
  },
  mediaStudioAddFileText: {
    id: 'MEDIA_STUDIO_ADD_FILE_TEXT',
    defaultMessage: 'Let’s Add Some Files Here',
    description:
      'Select Story  > Click on Browse Asset library> Pop up with link Upload file on center of modal>below that add file text mentioned',
  },
  mediaStudioFileDragAndDropText: {
    id: 'MEDIA_STUDIO_FILE_DRAG_AND_DROP_TEXT',
    defaultMessage: 'Go ahead, drag and drop some files here  OR',
    description:
      'Select Story  > Click on Browse Asset library> Pop up with link Upload file on center of modal>below that drag and  drop text mentioned',
  },
  sortBy: {
    id: 'MEDIA_STUDIO_LIBRARY_SORT_BY',
    defaultMessage: 'Sort by',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with sort option on top right',
  },
  sortByMostRecent: {
    id: 'MEDIA_STUDIO_LIBRARY_SORT_BY_MOST_RECENT',
    defaultMessage: 'Most Recent',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with sort option on top right with option as most recent',
  },
  sortByAlphabetical: {
    id: 'MEDIA_STUDIO_LIBRARY_SORT_BY_ALPHABETICAL',
    defaultMessage: 'Alphabetical',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with sort option on top right with option alphabetical',
  },
  searchTextPlaceholder: {
    id: 'MEDIA_STUDIO_LIBRARY_SEARCH_PLACEHOLDER',
    defaultMessage: 'Value',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with seacrh text field on center',
  },
  tabsHeadingAll: {
    id: 'MEDIA_STUDIO_LIBRARY_TABS_HEADING_ALL',
    defaultMessage: 'All ({NUM_ITEMS})',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with tabs',
  },
  tabsHeadingImages: {
    id: 'MEDIA_STUDIO_LIBRARY_TABS_HEADING_IMAGES',
    defaultMessage: 'Images ({NUM_ITEMS})',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with tabs',
  },
  tabsHeadingVideos: {
    id: 'MEDIA_STUDIO_LIBRARY_TABS_HEADING_VIDEOS',
    defaultMessage: 'Videos ({NUM_ITEMS})',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with tabs',
  },
  tabsHeadingDocs: {
    id: 'MEDIA_STUDIO_LIBRARY_TABS_HEADING_DOCS',
    defaultMessage: 'PDFs ({NUM_ITEMS})',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page comes with tabs',
  },
  showEditDivWithEditOption: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_DIV_WITH_EDIT_LINK',
    defaultMessage: 'Edit',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with edit link',
  },
  showEditDivWithDeleteOption: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_DIV_WITH_DELETE_LINK',
    defaultMessage: 'Delete',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with delete link',
  },
  showEditDivWithDownloadOption: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_DIV_WITH_DOWNLOAD_LINK',
    defaultMessage: 'Download',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with download link',
  },
  showDropFilesText: {
    id: 'MEDIA_STUDIO_LIBRARY_SHOW_DROP_FILES_TEXT_ON_DRAG_OVER',
    defaultMessage: 'Drop your files here to upload to Media Studio',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page > Assets list will be loaded > try to drag some files and see the text',
  },
  showNoAssetsText: {
    id: 'MEDIA_STUDIO_LIBRARY_SHOW_NO_TEXT',
    defaultMessage: "Sorry,  We couldn't find any assets",
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link >Text will enable if you dont have any assets to be listed',
  },
  percentComplete: {
    id: 'MEDIA_STUDIO_LIBRARY_PROGRESS_COMPLETION_PERCENTAGE',
    defaultMessage: '{PERCENT} Complete',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >if upload status not 8 it will show progree bar and value completion based on its upload value',
  },
  backButtonFromEditSection: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_SCREEN_WITH_BACK_LINK',
    defaultMessage: 'Back',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with edit link>Click you can see edit screen page with back link',
  },
  deleteFromEditScreen: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_SCREEN_WITH_DELETE_LINK',
    defaultMessage: 'Delete',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with edit link>Click you can see edit screen page with delete button',
  },
  saveChangesButtonFromEditScreen: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_SCREEN_WITH_SAVE_CHANGES_BUTTON',
    defaultMessage: 'Save Changes',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with edit link>Click you can see edit screen page with save changes button',
  },
  editCaptionFromEditScreen: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_SCREEN_WITH_EDIT_CATION_HEADING',
    defaultMessage: 'Caption (Optional)',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with edit link>Click you can see edit screen page with an option to edit caption ',
  },
  sizeInKB: {
    id: 'MEDIA_STUDIO_LIBRARY_EDIT_SCREEN_WITH_SIZE_IN_KB',
    defaultMessage: 'KB',
    description:
      'Select Story  > Click on Browse Asset library> Click on Media Studio Link > Library page >click and assets 3 dots > you can see another section with edit link>Click you can see edit screen page with asset size in KB ',
  },
  dismissMessageForLibrary: {
    id: 'MEDIA_STUDIO_LIBRARY_DISMISS_MESSAGE',
    defaultMessage: 'Dismiss',
    description:
      'Select Story  > Click on Browse Asset library> see the Disclaimer text > right to it can see the Dismiss text',
  },
  disclaimerTextForLibrary: {
    id: 'MEDIA_STUDIO_LIBRARY_DISCLAIMER_MESSAGE',
    defaultMessage:
      'NOTICE: This Asset Library is not intended to be used as a repository for sensitive or confidential information.  Users upload data at their own discretion and therefore at their own risk.  Data uploaded to this Asset Library may be used by Cision in a manner consistent with its Privacy Policy, available for review at: ',
    description:
      'Select Story  > Click on Browse Asset library> see the Disclaimer text ',
  },
  disclaimerLink: {
    id: 'MEDIA_STUDIO_LIBRARY_DISCLAIMER_LINK',
    defaultMessage: 'https://www.cision.com/us/legal/privacy-policy',
    description:
      'Select Story  > Click on Browse Asset library> see the Disclaimer link ',
  },
  toastForSuccessfullyUploadedAssets: {
    id: 'MEDIA_STUDIO_LIBRARY_SUCCESSFUL_UPLOAD_API',
    defaultMessage: 'Successfully uploaded. Please wait ...',
    description:
      'Select Story  > Click on Browse Asset library> click media link > click on upload ',
  },
  toastForCommonErrorMessages: {
    id: 'MEDIA_STUDIO_LIBRARY_SUCCESSFUL_ERROR_RESPONSE',
    defaultMessage: 'Some error happend. Please try again later.',
    description:
      'Select Story  > Click on Browse Asset library> click media link > click on upload ',
  },
  toastForShowingValidFileTypeForStoryKitFromEmailForDragAndDrop: {
    id: 'MEDIA_STUDIO_LIBRARY_VALIDATION_ON_DRAG_FROM_EMAIL',
    defaultMessage: 'Please try to upload jpeg, png or jpg file formats',
    description:
      'Select inline image icon from Email > click on upload from computer > try to upload a unsupportable file formats ',
  },
  toastForCorruptedImageForStoryKitFromEmail: {
    id: 'MEDIA_STUDIO_LIBRARY_VALIDATION_ON_CORRUPTED_IMAGE_FROM_EMAIL',
    defaultMessage: 'Please try to upload a valid image url!',
    description:
      'Select inline image icon from Email > click on upload from computer > try to upload a unsupportable image url ',
  },
  toastForFailedUploadedAssets: {
    id: 'MEDIA_STUDIO_LIBRARY_FAILED_ERROR_RESPONSE_FOR_FILE_UPLOAD',
    defaultMessage: '{fileName} type is not a valid type !',
    description:
      'Select Story  > Click on Browse Asset library> click media link > click on upload ',
  },
  toastForAssetsGreaterThanLimit: {
    id: 'MEDIA_STUDIO_LIBRARY_FAILED_ERROR_IF_ASSETS_NUMBER_BEYOND_10',
    defaultMessage: 'Please try to upload assets number less than 10 !',
    description:
      'Select Story  > Click on Browse Asset library> click media link > click on upload ',
  },
  toastForNoAssetsFound: {
    id: 'MEDIA_STUDIO_LIBRARY_MESSAGE_NO_ASSETS_FOUND',
    defaultMessage: 'Sorry, no assets found. Please upload some assets',
    description: 'Select Story  > Click on Browse Asset library> ',
  },
  toastForFetchingAssets: {
    id: 'MEDIA_STUDIO_LIBRARY_MESSAGE_FOR_FETCHING_ASSETS',
    defaultMessage: 'Please wait till all assets load !',
    description: 'Select Story  > Click on Browse Asset library> ',
  },
  toastForSuccessfullyDeletingAssets: {
    id: 'MEDIA_STUDIO_LIBRARY_SUCCESSFUL_DELETE_API',
    defaultMessage: 'Successfully deleted. Please wait ...',
    description:
      'Select Story  > Click on Browse Asset library> click media link > click on delete and see ',
  },
  toastForSuccessfullyEditedAssets: {
    id: 'MEDIA_STUDIO_LIBRARY_SUCCESSFUL_EDIT_API',
    defaultMessage: 'Successfully Edited the assets.Please wait ...',
    description:
      'Select Story  > Click on Browse Asset library> click media link > click on edit and see ',
  },
  toastForShowingImageAssetsForHeroAndLogo: {
    id: 'MEDIA_STUDIO_LIBRARY_SHOW_TOAST_FOR_WRONG_ASSETS_TYPE',
    defaultMessage: 'Please select any image assets',
    description:
      'Select Story  > Click on Browse Asset library> click media link > try to upload video or doc for hero or logo ',
  },
  browseAssetLibraryLinkForSpokePerson: {
    id: 'BROWSE_ASSET_LIBRARY_LINK_FOR_SPOKE_PERSON',
    defaultMessage: 'Browse',
    description: 'Select Story  > Click on Spoke Person  ',
  },
  confirmationModalTextForSingleFile: {
    id: 'BROWSE_ASSET_LIBRARY_CONFIRMATION_MODAL_TEXT_FOR_SINGLE_FILE',
    defaultMessage:
      "You are about to permanently delete a saved file. This action can't be undone. Would you like to continue?",
    description:
      'Select Story  > Click on Browse Asset library>  Click on 3 dots of any asset> click on delete text> confirmation modal with header will come',
  },
  confirmationModalHeaderForSingelFile: {
    id: 'BROWSE_ASSET_LIBRARY_CONFIRMATION_MODAL_HEADER_FOR_SINGLE_FILE',
    defaultMessage: 'Delete this Asset ?',
    description:
      'Select Story  > Click on Browse Asset library>  Click on 3 dots of any asset> click on delete text> confirmation modal with header will come',
  },
  confirmationDeleteTextForSingleFile: {
    id: 'BROWSE_ASSET_LIBRARY_CONFIRMATION_MODAL_DELETE_TEXT_FOR_SINGLE_FILE',
    defaultMessage: 'Yes, delete file',
    description:
      'Select Story  > Click on Browse Asset library>  Click on 3 dots of any asset> click on delete text> confirmation modal with header will come',
  },
};
