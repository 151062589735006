import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

const initialState = {
  loading: false,
  error: false,
  otherDocumentCount: 0,
  data: [],
};

const topLocationsReducerFactory = namespace => {
  const GET_TOPLOCATIONS_START = `ihub/${namespace}/GET_TOPLOCATIONS_START`;
  const GET_TOPLOCATIONS_SUCCESS = `ihub/${namespace}/GET_TOPLOCATIONS_SUCCESS`;
  const GET_TOPLOCATIONS_ERROR = `ihub/${namespace}/GET_TOPLOCATIONS_ERROR`;

  const getTopLocationsForInfluencer = influencer => async dispatch => {
    dispatch({ type: GET_TOPLOCATIONS_START });
    const payload = {
      otherDocumentCount: 0,
      data: [],
    };

    try {
      const response = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/influencer/${influencer.id}/followers-locations`,
      );

      if (response && response.data) {
        payload.otherDocumentCount = response.data.otherDocumentCount;
        payload.data = response.data.buckets;
      }
    } catch (e) {
      dispatch({ type: GET_TOPLOCATIONS_ERROR, payload: e });
    }
    dispatch({ type: GET_TOPLOCATIONS_SUCCESS, payload: payload });
  };

  const topLocationsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_TOPLOCATIONS_START:
        return {
          ...state,
          loading: true,
          error: false,
          otherDocumentCount: 0,
          data: [],
        };

      case GET_TOPLOCATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          otherDocumentCount: action.payload.otherDocumentCount,
          data: [...action.payload.data],
        };

      case GET_TOPLOCATIONS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  return {
    topLocationsReducer,
    GET_TOPLOCATIONS_START,
    GET_TOPLOCATIONS_SUCCESS,
    GET_TOPLOCATIONS_ERROR,
    getTopLocationsForInfluencer,
  };
};

export default topLocationsReducerFactory;
