import { createSelector } from 'reselect';

interface Counts {
  all: number;
  owned: number;
  shared: number;
  starred: number;
  archived: number;
  legacy: number;
  deleted: number;
}

interface SidebarCounts {
  counts: Counts;
}

interface SidebarResponse {
  sidebarCounts: SidebarCounts;
}

interface RootState {
  dashboard: number;
}
interface SummaryCampaign {
  title: string;
  id: number;
  count: number;
}

interface ListSummaryCampaign {
  summaryCampaigns: SummaryCampaign[];
  campaignsSelected: number[];
}
interface SummaryCampaignResponse {
  campaigns: ListSummaryCampaign;
}

export const dashboardObj = (state: RootState) => state.dashboard || {};

export const sidebarAnalytcsSelector = createSelector(
  dashboardObj,
  (sidebarResponse: SidebarResponse): Counts =>
    sidebarResponse?.sidebarCounts?.counts || {},
);

export const allAnalyticsSidebarCountSelector = createSelector(
  sidebarAnalytcsSelector,
  (sidebarResponse: Counts): number | null => sidebarResponse.all || null,
);

export const ownedAnalyticsSidebarCountSelector = createSelector(
  sidebarAnalytcsSelector,
  (sidebarResponse: Counts): number | null => sidebarResponse.owned || null,
);

export const sharedAnalyticsSidebarCountSelector = createSelector(
  sidebarAnalytcsSelector,
  (sidebarResponse: Counts): number | null => sidebarResponse.shared || null,
);

export const starredAnalyticsSidebarCountSelector = createSelector(
  sidebarAnalytcsSelector,
  (sidebarResponse: Counts): number | null => sidebarResponse.starred || null,
);

export const legacyAnalyticsSidebarCountSelector = createSelector(
  sidebarAnalytcsSelector,
  (sidebarResponse: Counts): number | null => sidebarResponse.legacy || null,
);

export const archivedAnalyticsSidebarCountSelector = createSelector(
  sidebarAnalytcsSelector,
  (sidebarResponse: Counts): number | null => sidebarResponse.archived || null,
);

export const deletedAnalyticsSidebarCountSelector = createSelector(
  sidebarAnalytcsSelector,
  (sidebarResponse: Counts): number | null => sidebarResponse.deleted || null,
);

export const summaryCampaignAnalyticsSelector = createSelector(
  dashboardObj,
  (summaryCampaignsResponse: SummaryCampaignResponse): SummaryCampaign[] =>
    summaryCampaignsResponse?.campaigns?.summaryCampaigns || {},
);

export const summaryCampaignSelectedAnalyticsSelector = createSelector(
  dashboardObj,
  (summaryCampaignsResponse: SummaryCampaignResponse): number[] =>
    summaryCampaignsResponse?.campaigns?.campaignsSelected || {},
);
