import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Paper = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" />
  </svg>
);
Paper.propTypes = { fill: PropTypes.string };
Paper.defaultProps = { fill: iconColorsMap.mainicon };

export default Paper;
