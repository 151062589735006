import { ASSET_POPUP_GET_STORY_ENDPOINT } from 'constants/apis';

import React, { Component } from 'react';

import axios from 'axios';
import classNames from 'classnames';
import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { Button, Overlay, Progress, SvgIcon, Addon } from '@trendkite/ui';

import BrowseModalUpload from '../browse-asset-library/BrowseModalUpload';

import FileInput from '../story/fields/FileInput';
import {
  heroPropTypes,
  heroDefaultProps,
} from '../story/templates/default-template';

import messages from './story-hero.messages';

import StoryHeroPlaceholder from './StoryHeroPlaceholder';

class StoryHero extends Component {
  state = {
    isUploading: false,
    uploadProgress: NaN,
    browseAssetComponent: false,
    isFromStoryKit: true,
    showOtherUploadOptions: false,
  };

  onClickedArea = () => {
    this.setState({ isEditing: true });
  };

  handleSelectedFilesFromPopUp = info => {
    const data = new FormData();
    data.append('id', info.id);
    data.append('type', 'ms-story-hero');
    data.append('isTK', info.isTK);
    this.setState({ isUploading: true });
    axios
      .post(`${ASSET_POPUP_GET_STORY_ENDPOINT}`, data, {
        onUploadProgress: this.onUploadProgress,
      })
      .then(res => {
        this.setState({ browseAssetComponent: false });
        this.props.dispatchMergeFormAction('storyContentForm', {
          heroSrc: res.data.logo,
        });
      })
      .finally(() => {
        this.setState({ isUploading: false, uploadProgress: NaN });
      });
  };

  onSelectedFile = files => {
    if (!files || files.length <= 0) return;

    const { logoUploadPath } = this.props;
    const file = files[0];
    const data = new FormData();

    data.append('image', file);
    data.append('type', 'story');
    data.append('maxSize', 3000);

    this.setState({ isUploading: true });
    axios
      .post(logoUploadPath, data, { onUploadProgress: this.onUploadProgress })
      .then(res => {
        this.props.dispatchMergeFormAction('storyContentForm', {
          heroSrc: res.data.logo,
        });
      })
      .finally(() => {
        this.setState({ isUploading: false, uploadProgress: NaN });
      });
  };

  replaceWithAssetLibrary = () => {
    this.setState({ browseAssetComponent: true });
  };

  onUploadProgress = progress => {
    const { loaded, total } = progress;
    const uploadProgressPercent =
      typeof loaded === 'number' && typeof total === 'number' && total !== 0
        ? Math.round(100 * (loaded / total))
        : null;
    this.setState(() => ({ uploadProgress: uploadProgressPercent }));
  };

  makeBrowseAssetComponentFalse = () => {
    this.setState({ browseAssetComponent: false });
  };

  removeHero = () => {
    this.props.dispatchMergeFormAction('storyContentForm', { heroSrc: '' });
  };

  render() {
    const { heroSrc, isEditable, browseLink } = this.props;
    const {
      isUploading,
      uploadProgress,
      browseAssetComponent,
      isFromStoryKit,
      showOtherUploadOptions,
    } = this.state;
    const topClassNames = classNames(StoryHero.baseClass);
    const heroStyle = heroSrc ? { backgroundImage: `url("${heroSrc}")` } : {};
    return (
      <div className={topClassNames} style={heroStyle}>
        <div className={`${StoryHero.baseClass}__viewable-area`}>
          {!isUploading && isEditable && !heroSrc && (
            <StoryHeroPlaceholder
              handleSelectedFilesfromPopUp={this.handleSelectedFilesFromPopUp}
              onSelectFiles={this.onSelectedFile}
              browseLink={browseLink}
              isFromStoryKit={isFromStoryKit}
            />
          )}
          {!isUploading && isEditable && !!heroSrc && (
            <div className={`${StoryHero.baseClass}__overlay`}>
              <Overlay>
                <div className={`${StoryHero.baseClass}__overlay-actions`}>
                  <div className={`${StoryHero.baseClass}__overlay-action`}>
                    {!browseLink && (
                      <FileInput onDrop={this.onSelectedFile}>
                        <Button
                          data-qa="wNEYHBUH6b5pBGXtQOP9r"
                          modifiers={['round', 'tertiary']}
                          onClick={this.onClickUpload}
                        >
                          <SvgIcon icon="upload" />
                          &nbsp;&nbsp;Replace
                        </Button>
                      </FileInput>
                    )}
                    {browseLink && (
                      <Button
                        data-qa="28ZdvFb2MIvl8pT6rzJsB"
                        modifiers={['round', 'tertiary']}
                        onClick={this.replaceWithAssetLibrary}
                      >
                        <Addon>
                          <SvgIcon icon="upload" />
                          <T {...messages.replaceTitle} />
                        </Addon>
                      </Button>
                    )}
                  </div>
                  {browseAssetComponent && (
                    <BrowseModalUpload
                      browseAssetComponent={browseAssetComponent}
                      showOtherUploadOptions={showOtherUploadOptions}
                      handleSelectedFilesfromPopUp={
                        this.handleSelectedFilesFromPopUp
                      }
                      makeBrowseAssetComponentFalse={
                        this.makeBrowseAssetComponentFalse
                      }
                      onSelectFiles={this.onSelectedFile}
                      isFromStoryKit={isFromStoryKit}
                    />
                  )}
                  <div className={`${StoryHero.baseClass}__overlay-action`}>
                    <Button
                      data-qa="sMcwDQIhugOSHB9bmoiT6"
                      modifiers={['round', 'danger']}
                      onClick={this.removeHero}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </Overlay>
            </div>
          )}
          {isUploading && (
            <Overlay>
              <div className={`${StoryHero.baseClass}__progress`}>
                <Progress modifiers={['small']} value={uploadProgress / 100} />
                <div className={`${StoryHero.baseClass}__progress-label`}>
                  {typeof uploadProgress === 'number' && (
                    <span>uploading {uploadProgress || 0}% complete</span>
                  )}
                  {typeof uploadProgress !== 'number' && (
                    <span>uploading...</span>
                  )}
                </div>
              </div>
            </Overlay>
          )}
        </div>
      </div>
    );
  }
}

StoryHero.baseClass = 'tk-story-hero';

StoryHero.propTypes = {
  ...heroPropTypes,
  isEditable: PropTypes.bool,
  logoUploadPath: PropTypes.string,
  dispatchMergeFormAction: PropTypes.func,
  browseLink: PropTypes.bool,
};

StoryHero.defaultProps = {
  ...heroDefaultProps,
  isEditable: false,
  logoUploadPath: '',
  dispatchMergeFormAction: () => {},
  browseLink: false,
};

export default StoryHero;
