import difference from 'lodash/difference';

import moment from 'moment';

import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { TK_ACCOUNT_ID } from 'constants/constants';
import {
  UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY,
  UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_ERROR,
  UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_SUCCESS,
} from 'reducers/campaigns/campaign-details-side-tray';
import {
  getCampaignsForUser,
  SET_CAMPAIGNS_SHARING,
} from 'reducers/campaigns/campaign-list';
import { getCampaignSearchesByCampaignId } from 'reducers/campaigns/campaign-searches';
import {
  updateTotalMentionsWidget,
  getCampaignWidgets,
} from 'reducers/campaigns/campaign-widgets';
import { addPageMessage } from 'reducers/page-messages';
import {
  performGet,
  performDelete,
  performPut,
  performPost,
} from 'services/rest-service/rest-service';

import { getCurrentUserAccountId } from 'services/user-service/user-service';

import messages from '../../pages/Campaigns/Campaigns.messages';

export const RESET_CAMPAIGN = 'campaigns/RESET_CAMPAIGN';
export const GET_CAMPAIGN = 'campaigns/GET_CAMPAIGN';
export const GET_CAMPAIGN_ERROR = 'campaigns/GET_CAMPAIGN_ERROR';
export const GET_CAMPAIGN_RECEIVED = 'campaigns/GET_CAMPAIGN_RECEIVED';
export const DELETE_CAMPAIGN = 'campaigns/DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_ERROR = 'campaigns/DELETE_CAMPAIGN_ERROR';
export const DELETE_CAMPAIGN_COMPLETED = 'campaigns/DELETE_CAMPAIGN_COMPLETED';
export const UPDATE_CAMPAIGN_ARTICLES = 'campaigns/UPDATE_CAMPAIGN_ARTICLES';
export const REMOVE_CAMPAIGN_ARTICLES = 'campaigns/REMOVE_CAMPAIGN_ARTICLES';
export const UPDATE_CAMPAIGN_DASHBOARDS =
  'campaigns/UPDATE_CAMPAIGN_DASHBOARDS';
export const UPDATE_CAMPAIGN_DASHBOARDS_ERROR =
  'campaigns/UPDATE_CAMPAIGN_DASHBOARDS_ERROR';
export const UPDATE_CAMPAIGN_DASHBOARDS_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_DASHBOARDS_SUCCESS';
export const UPDATE_CAMPAIGN_DETAILS = 'campaigns/UPDATE_CAMPAIGN_DETAILS';
export const CAMPAIGN_DETAILS_UPDATE_ERROR =
  'campaigns/CAMPAIGN_DETAILS_UPDATE_ERROR';
export const UPDATE_CAMPAIGN_PRIMARY_SEARCH =
  'campaigns/UPDATE_CAMPAIGN_PRIMARY_SEARCH';
export const CAMPAIGN_PRIMARY_SEARCH_UPDATE_ERROR =
  'campaigns/CAMPAIGN_PRIMARY_SEARCH_UPDATE_ERROR';
export const SET_CAMPAIGN_SHARING = 'campaigns/SET_CAMPAIGN_SHARING';
export const SET_CAMPAIGN_SHARING_SUCCESS =
  'campaigns/SET_CAMPAIGN_SHARING_SUCCESS';
export const SET_CAMPAIGN_SHARING_ERROR =
  'campaigns/SET_CAMPAIGN_SHARING_ERROR';
export const UPDATE_CAMPAIGN_SEARCHES = 'campaigns/UPDATE_CAMPAIGN_SEARCHES';
export const UPDATE_CAMPAIGN_SEARCHES_COMPLETE =
  'campaigns/UPDATE_CAMPAIGN_SEARCHES_COMPLETE';
export const UPDATE_CAMPAIGN_SEARCHES_ERROR =
  'campaigns/UPDATE_CAMPAIGN_SEARCHES_ERROR';
export const UPDATE_CAMPAIGN_REPORTS = 'campaigns/UPDATE_CAMPAIGN_REPORTS';
export const UPDATE_CAMPAIGN_REPORTS_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_REPORTS_SUCCESS';
export const UPDATE_CAMPAIGN_REPORTS_ERROR =
  'campaigns/UPDATE_CAMPAIGN_REPORTS_ERROR';
export const UPDATE_CAMPAIGN_NEWSLETTERS =
  'campaigns/UPDATE_CAMPAIGN_NEWSLETTERS';
export const UPDATE_CAMPAIGN_NEWSLETTERS_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_NEWSLETTERS_SUCCESS';
export const UPDATE_CAMPAIGN_NEWSLETTERS_ERROR =
  'campaigns/UPDATE_CAMPAIGN_NEWSLETTERS_ERROR';
export const UPDATE_CAMPAIGN_CONTACTS = 'campaigns/UPDATE_CAMPAIGN_CONTACTS';
export const UPDATE_CAMPAIGN_CONTACTS_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_CONTACTS_SUCCESS';
export const UPDATE_CAMPAIGN_CONTACTS_ERROR =
  'campaigns/UPDATE_CAMPAIGN_CONTACTS_ERROR';
export const UPDATE_CAMPAIGN_PROPERTY = 'campaigns/UPDATE_CAMPAIGN_PROPERTY';
export const UPDATE_CAMPAIGN_ALERTS = 'campaigns/UPDATE_CAMPAIGN_ALERTS';
export const UPDATE_CAMPAIGN_ALERTS_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_ALERTS_SUCCESS';
export const UPDATE_CAMPAIGN_ALERTS_ERROR =
  'campaigns/UPDATE_CAMPAIGN_ALERTS_ERROR';
export const UPDATE_CAMPAIGN_STORIES = 'campaigns/UPDATE_CAMPAIGN_STORIES';
export const UPDATE_CAMPAIGN_STORIES_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_STORIES_SUCCESS';
export const UPDATE_CAMPAIGN_STORIES_ERROR =
  'campaigns/UPDATE_CAMPAIGN_STORIES_ERROR';
export const UPDATE_CAMPAIGN_INFLUENCER_LISTS =
  'campaigns/UPDATE_CAMPAIGN_INFLUENCER_LISTS';
export const UPDATE_CAMPAIGN_INFLUENCER_LISTS_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_INFLUENCER_LISTS_SUCCESS';
export const UPDATE_CAMPAIGN_INFLUENCER_LISTS_ERROR =
  'campaigns/UPDATE_CAMPAIGN_INFLUENCER_LISTS_ERROR';
export const SET_CAMPAIGN_SEARCHES = 'campaigns/SET_CAMPAIGN_SEARCHES';
export const SET_CAMPAIGN_SEARCHES_ERROR =
  'campaigns/SET_CAMPAIGN_SEARCHES_ERROR';
export const SET_CAMPAIGN_SEARCHES_SUCCESS =
  'campaigns/SET_CAMPAIGN_SEARCHES_SUCCESS';

export const initialState = {
  campaign: {},
  error: false,
  loading: true,
  deleting: false,
  sharing: false,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CAMPAIGN:
      return initialState;
    case GET_CAMPAIGN:
      return { ...state, loading: true };
    case GET_CAMPAIGN_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_CAMPAIGN_RECEIVED: {
      const { campaign } = action.payload;
      // future-proofing for updated campaign user API
      if (((campaign || {}).user || {}).id) {
        campaign.userId = campaign.user.id;
      }
      if ((campaign || {}).startDate) {
        campaign.startDate = moment(campaign.startDate).valueOf();
      }
      if ((campaign || {}).endDate) {
        campaign.endDate = moment(campaign.endDate).valueOf();
      }
      return {
        ...state,
        loading: false,
        campaign,
      };
    }
    case SET_CAMPAIGN_SEARCHES:
      return { ...state, loading: false };
    case SET_CAMPAIGN_SEARCHES_ERROR:
      return {
        ...state,
        error: true,
      };
    case SET_CAMPAIGN_SEARCHES_SUCCESS: {
      return {
        ...state,
        campaign: {
          ...state.campaign,
          ...action.payload,
        },
      };
    }
    case UPDATE_CAMPAIGN_ARTICLES:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          articleIds: action.payload.articleIds,
          totalArticleCount: action.payload.totalArticleCount,
        },
      };
    case REMOVE_CAMPAIGN_ARTICLES: {
      const oldArticleIds = [...state.campaign.articleIds];
      const newArticleIds = difference(
        oldArticleIds,
        action.payload.removeArticleIds,
      );
      const removedCount = oldArticleIds.length - newArticleIds.length;
      return {
        ...state,
        campaign: {
          ...state.campaign,
          articleIds: newArticleIds,
          totalArticleCount: state.campaign.totalArticleCount - removedCount,
        },
      };
    }
    case DELETE_CAMPAIGN: {
      return {
        ...state,
        campaign: {
          ...state.campaign,
          deleting: true,
        },
        deleting: true,
      };
    }
    case DELETE_CAMPAIGN_ERROR: {
      return {
        ...state,
        campaign: {
          ...state.campaign,
          deleting: false,
        },
        deleting: false,
        error: true,
      };
    }
    case DELETE_CAMPAIGN_COMPLETED: {
      return {
        ...state,
        campaign: {},
        deleting: false,
        error: false,
      };
    }
    case UPDATE_CAMPAIGN_CONTACTS:
    case UPDATE_CAMPAIGN_REPORTS:
    case UPDATE_CAMPAIGN_NEWSLETTERS:
    case UPDATE_CAMPAIGN_DASHBOARDS:
    case UPDATE_CAMPAIGN_ALERTS:
    case UPDATE_CAMPAIGN_INFLUENCER_LISTS:
    case UPDATE_CAMPAIGN_STORIES: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }
    case UPDATE_CAMPAIGN_CONTACTS_ERROR:
    case UPDATE_CAMPAIGN_REPORTS_ERROR:
    case UPDATE_CAMPAIGN_NEWSLETTERS_ERROR:
    case UPDATE_CAMPAIGN_DASHBOARDS_ERROR:
    case UPDATE_CAMPAIGN_ALERTS_ERROR:
    case UPDATE_CAMPAIGN_INFLUENCER_LISTS_ERROR:
    case UPDATE_CAMPAIGN_STORIES_ERROR: {
      return {
        ...state,
        deleting: false,
        error: true,
        loading: false,
      };
    }
    case UPDATE_CAMPAIGN_DASHBOARDS_SUCCESS:
    case UPDATE_CAMPAIGN_CONTACTS_SUCCESS:
    case UPDATE_CAMPAIGN_REPORTS_SUCCESS:
    case UPDATE_CAMPAIGN_NEWSLETTERS_SUCCESS:
    case UPDATE_CAMPAIGN_ALERTS_SUCCESS:
    case UPDATE_CAMPAIGN_INFLUENCER_LISTS_SUCCESS:
    case UPDATE_CAMPAIGN_STORIES_SUCCESS: {
      const newState = { ...state };
      if (typeof action.payload !== 'undefined') {
        newState.campaign = action.payload;
      }
      return {
        ...newState,
        error: false,
        loading: false,
      };
    }
    case UPDATE_CAMPAIGN_DETAILS: {
      const newState = { ...state };
      if (typeof action.payload !== 'undefined') {
        newState.campaign.category = action.payload.category;
        newState.campaign.description = action.payload.description;
        newState.campaign.title = action.payload.title;
        newState.campaign.rangeType = action.payload.rangeType;
        newState.campaign.startDate = action.payload.startDate
          ? moment.utc(action.payload.startDate).valueOf()
          : null;
        newState.campaign.endDate = action.payload.endDate
          ? moment.utc(action.payload.endDate).valueOf()
          : null;
      }
      return {
        ...newState,
        error: false,
        loading: false,
      };
    }
    case CAMPAIGN_DETAILS_UPDATE_ERROR: {
      return {
        ...state,
        deleting: false,
        error: true,
        loading: false,
      };
    }
    case UPDATE_CAMPAIGN_PRIMARY_SEARCH: {
      const newState = { ...state };
      if (typeof action.payload !== 'undefined') {
        newState.campaign = action.payload;
      }
      return {
        ...newState,
        error: false,
        loading: false,
      };
    }
    case CAMPAIGN_PRIMARY_SEARCH_UPDATE_ERROR: {
      return {
        ...state,
        deleting: false,
        error: true,
        loading: false,
      };
    }
    case UPDATE_CAMPAIGN_PROPERTY: {
      return {
        ...state,
        campaign: {
          ...state.campaign,
          ...action.payload,
        },
      };
    }
    case SET_CAMPAIGN_SHARING:
      return {
        ...state,
        sharing: true,
      };
    case SET_CAMPAIGN_SHARING_SUCCESS:
      return {
        ...state,
        sharing: false,
        campaign: {
          ...state.campaign,
          shared: action.payload,
        },
      };
    case SET_CAMPAIGN_SHARING_ERROR:
      return {
        ...state,
        sharing: false,
      };
    default:
      return state;
  }
};

export const resetCampaign = () => dispatch =>
  dispatch({ type: RESET_CAMPAIGN });

export const getCampaign = campaignId => dispatch => {
  dispatch({ type: GET_CAMPAIGN });
  dispatch({ type: UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY });

  performGet(`${CAMPAIGN_ENDPOINT}/${campaignId}`)
    .then(response => {
      dispatch({
        type: GET_CAMPAIGN_RECEIVED,
        payload: { campaign: response.data },
      });
      dispatch({
        type: UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_SUCCESS,
        payload: response.data,
      });
      dispatch(getCampaignWidgets(response.data));
    })
    .catch(err => {
      dispatch({ type: GET_CAMPAIGN_ERROR, payload: err });
      dispatch({ type: UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_ERROR, payload: err });
      throw err;
    });
};

export const setCampaignSearches = campaignId => dispatch => {
  dispatch({ type: SET_CAMPAIGN_SEARCHES });

  performGet(`${CAMPAIGN_ENDPOINT}/${campaignId}`)
    .then(response => {
      dispatch({
        type: SET_CAMPAIGN_SEARCHES_SUCCESS,
        payload: {
          searches: response.data.searches,
        },
      });
    })
    .catch(err => {
      dispatch({ type: SET_CAMPAIGN_SEARCHES_ERROR, payload: err });
    });
};

export const deleteCampaign = (
  campaignId,
  intl,
  updateCampaigns = true,
) => async dispatch => {
  dispatch({ type: DELETE_CAMPAIGN });
  await performDelete(`${CAMPAIGN_ENDPOINT}/${campaignId}`)
    .then(() => {
      dispatch({ type: DELETE_CAMPAIGN_COMPLETED });

      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.deleteCampaignSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );

      if (updateCampaigns) {
        dispatch(getCampaignsForUser());
      }
    })
    .catch(err => {
      dispatch({ type: DELETE_CAMPAIGN_ERROR, payload: err });

      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.deleteCampaignFailed),
          status: 'danger',
        }),
      );

      throw err;
    });
};

export const updateCampaignReportsActionCreator = (
  campaign,
  reportList,
  intl,
) => async dispatch => {
  if (
    typeof campaign !== 'undefined' &&
    typeof campaign.id !== 'undefined' &&
    Array.isArray(reportList)
  ) {
    dispatch({ type: UPDATE_CAMPAIGN_REPORTS });
    const reportIdList = reportList.map(({ id, version }) => ({
      id,
      version,
    }));
    const updatedCampaign = {
      reports: reportIdList,
    };
    const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
    try {
      const response = await performPut(url, updatedCampaign);
      dispatch({
        type: UPDATE_CAMPAIGN_REPORTS_SUCCESS,
        payload: response.data,
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.reportsUpdateSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_CAMPAIGN_REPORTS_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.reportsUpdateFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  }
  dispatch({ type: UPDATE_CAMPAIGN_REPORTS_ERROR });
};

export const updateCampaignNewslettersActionCreator = (
  campaign,
  newsletterList,
  intl,
) => async dispatch => {
  if (
    typeof campaign !== 'undefined' &&
    typeof campaign.id !== 'undefined' &&
    Array.isArray(newsletterList)
  ) {
    dispatch({ type: UPDATE_CAMPAIGN_NEWSLETTERS });
    const newsletterIdList = newsletterList.map(n => n.id);
    const updatedCampaign = {
      ...campaign,
      newsletters: newsletterIdList,
    };
    const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
    try {
      const response = await performPut(url, updatedCampaign);
      dispatch({
        type: UPDATE_CAMPAIGN_NEWSLETTERS_SUCCESS,
        payload: response.data,
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.newslettersUpdateSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_CAMPAIGN_NEWSLETTERS_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.newslettersUpdateFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  }
  dispatch({ type: UPDATE_CAMPAIGN_NEWSLETTERS_ERROR });
};

export const updateCampaignDashboardsActionCreator = (
  campaign,
  dashboardList,
  intl,
) => async dispatch => {
  if (
    typeof campaign !== 'undefined' &&
    typeof campaign.id !== 'undefined' &&
    Array.isArray(dashboardList)
  ) {
    dispatch({ type: UPDATE_CAMPAIGN_DASHBOARDS });
    const dashboardIdList = dashboardList.map(dash => ({
      id: dash.id,
      version: dash.version,
    }));
    const updatedCampaign = {
      ...campaign,
      dashboards: dashboardIdList,
    };
    const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
    try {
      const response = await performPut(url, updatedCampaign);
      dispatch({
        type: UPDATE_CAMPAIGN_DASHBOARDS_SUCCESS,
        payload: response.data,
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.dashboardsUpdateSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_CAMPAIGN_DASHBOARDS_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.dashboardsUpdateFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  } else {
    dispatch({ type: UPDATE_CAMPAIGN_DASHBOARDS_ERROR });
  }
};

export const updateCampaignContactsActionCreator = (
  campaign,
  contacts,
  intl,
) => async dispatch => {
  if (
    typeof campaign !== 'undefined' &&
    typeof campaign.id !== 'undefined' &&
    Array.isArray(contacts)
  ) {
    dispatch({ type: UPDATE_CAMPAIGN_CONTACTS });
    const contactIdList = contacts.map(contact => contact.id);
    const updatedCampaign = {
      ...campaign,
      contacts: contactIdList,
    };
    const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
    try {
      const response = await performPut(url, updatedCampaign);
      dispatch({
        type: UPDATE_CAMPAIGN_CONTACTS_SUCCESS,
        payload: response.data,
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.contactsUpdateSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_CAMPAIGN_CONTACTS_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.contactsUpdateFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  }
  dispatch({ type: UPDATE_CAMPAIGN_CONTACTS_ERROR });
};

export const updateCampaignAlertsActionCreator = (
  campaign,
  alertList,
  intl,
) => async dispatch => {
  if (campaign && campaign.id && Array.isArray(alertList)) {
    dispatch({ type: UPDATE_CAMPAIGN_ALERTS });
    const alertIdList = alertList.map(alert => alert.id);
    const updatedCampaign = {
      ...campaign,
      alerts: alertIdList,
    };
    const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
    try {
      const response = await performPut(url, updatedCampaign);
      dispatch({
        type: UPDATE_CAMPAIGN_ALERTS_SUCCESS,
        payload: response.data,
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.alertsUpdateSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_CAMPAIGN_ALERTS_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.alertsUpdateFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  }
  dispatch({ type: UPDATE_CAMPAIGN_ALERTS_ERROR });
};

export const updateCampaignStoriesActionCreator = (
  campaign,
  storyList,
  intl,
) => async dispatch => {
  if (campaign && campaign.id && Array.isArray(storyList)) {
    dispatch({ type: UPDATE_CAMPAIGN_STORIES });
    const storyIdList = storyList.map(story => story.id);
    const updatedCampaign = {
      ...campaign,
      stories: storyIdList,
    };
    const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
    try {
      const response = await performPut(url, updatedCampaign);
      dispatch({
        type: UPDATE_CAMPAIGN_STORIES_SUCCESS,
        payload: response.data,
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.storiesUpdateSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_CAMPAIGN_STORIES_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.storiesUpdateFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  }
  dispatch({ type: UPDATE_CAMPAIGN_STORIES_ERROR });
};

export const updateCampaignDetailsActionCreator = (
  campaign,
  campaignDetails,
  intl,
) => {
  if (typeof campaign.id !== 'undefined') {
    const updatedCampaign = {
      ...campaign,
      category: campaignDetails.campaignCategory,
      description: campaignDetails.campaignDescription,
      title: campaignDetails.campaignTitle,
      rangeType: campaignDetails.campaignDateRange,
      startDate: campaignDetails.startDate,
      endDate: campaignDetails.endDate,
    };
    return async dispatch => {
      const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
      dispatch({ type: UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY });
      try {
        const response = await performPut(url, updatedCampaign);
        dispatch({ type: UPDATE_CAMPAIGN_DETAILS, payload: response.data });
        dispatch({
          type: UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_SUCCESS,
          payload: response.data,
        });
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: intl.formatMessage(messages.updateCampaignSuccess),
            status: 'success',
            ttl: 3000,
          }),
        );
        dispatch(updateTotalMentionsWidget());
      } catch (e) {
        dispatch({ type: CAMPAIGN_DETAILS_UPDATE_ERROR });
        dispatch({ type: UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_ERROR });
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: intl.formatMessage(messages.updateCampaignFailed),
            status: 'danger',
          }),
        );
        throw e;
      }
    };
  }
  return dispatch => dispatch({ type: CAMPAIGN_DETAILS_UPDATE_ERROR });
};

export const setPrimarySearchForCampaign = (campaign, searchId) => {
  campaign.primarySearch = campaign.primarySearch || {};
  searchId =
    Array.isArray(searchId) && searchId.length === 1 ? searchId[0] : searchId;
  if (campaign.primarySearch.id !== searchId) {
    const updatedCampaign = campaign;
    campaign.primarySearch.id = searchId;
    return async dispatch => {
      const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}`;
      try {
        const response = await performPut(url, updatedCampaign);
        dispatch({
          type: UPDATE_CAMPAIGN_PRIMARY_SEARCH,
          payload: response.data,
        });
        dispatch(updateTotalMentionsWidget());
      } catch (e) {
        dispatch({ type: CAMPAIGN_PRIMARY_SEARCH_UPDATE_ERROR });
        throw e;
      }
    };
  }
  return dispatch => dispatch({ type: UPDATE_CAMPAIGN_PRIMARY_SEARCH });
};

export const setSearchesForCampaign = (campaign, searchIds, intl) => async (
  dispatch,
  getState,
) => {
  const campaignSearchIds = getState().campaignSearchList.campaignSearches.map(
    search => search.id,
  );
  const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}/search`;
  const searchPayload = { searches: [...searchIds] };

  dispatch({ type: UPDATE_CAMPAIGN_SEARCHES });

  try {
    await performPost(url, searchPayload);
    dispatch({ type: UPDATE_CAMPAIGN_SEARCHES_COMPLETE });
    dispatch(getCampaignSearchesByCampaignId(campaign.id));
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: {
        searches: Array.from(new Set(campaignSearchIds.concat([...searchIds]))),
      },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.searchesUpdateSuccess),
        status: 'success',
        ttl: 3000,
      }),
    );
    dispatch(updateTotalMentionsWidget());
  } catch (e) {
    dispatch({ type: UPDATE_CAMPAIGN_SEARCHES_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.searchesUpdateFailed),
        status: 'danger',
      }),
    );
    throw e;
  }
};

export const setCampaignSharingActionCreator = (
  campaignIds,
  type,
  intl,
) => async dispatch => {
  if (Array.isArray(campaignIds)) {
    dispatch({ type: SET_CAMPAIGN_SHARING });
    const unshareData = {
      ids: campaignIds,
      type,
    };
    const isShared = type === 'share';
    const url = `${CAMPAIGN_ENDPOINT}/action`;

    // Don't actually share campaign if part of Trendkite demo account
    if (getCurrentUserAccountId() === TK_ACCOUNT_ID) {
      dispatch({ type: SET_CAMPAIGN_SHARING_SUCCESS, payload: isShared });
      dispatch({
        type: SET_CAMPAIGNS_SHARING,
        payload: { shared: isShared, campaignIds },
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.shareCampaignSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
      return;
    }

    try {
      await performPost(url, unshareData);
      dispatch({ type: SET_CAMPAIGN_SHARING_SUCCESS, payload: isShared });
      dispatch({
        type: SET_CAMPAIGNS_SHARING,
        payload: { shared: isShared, campaignIds },
      });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.shareCampaignSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: SET_CAMPAIGN_SHARING_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.shareCampaignFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  } else {
    dispatch({ type: SET_CAMPAIGN_SHARING_ERROR });
  }
};

export const updateCampaignInfluencerListsActionCreator = (
  campaign,
  InfluencerList,
  intl,
  messages,
) => async dispatch => {
  if (campaign && campaign.id && Array.isArray(InfluencerList)) {
    dispatch({ type: UPDATE_CAMPAIGN_INFLUENCER_LISTS });
    const InfluenceListIds = InfluencerList.map(iList => iList.id);
    const updatedCampaign = {
      ...campaign,
      influencerListIds: [
        ...campaign.influencerListIds,
        ...new Set(InfluenceListIds),
      ],
    };
    const listPayload = {
      listIds: InfluenceListIds,
    };
    const url = `${CAMPAIGN_ENDPOINT}/${campaign.id}/lists`;
    try {
      await performPost(url, listPayload);
      dispatch({
        type: UPDATE_CAMPAIGN_INFLUENCER_LISTS_SUCCESS,
        payload: updatedCampaign,
      });

      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.listUpdateSuccess),
          status: 'success',
          ttl: 3000,
        }),
      );
    } catch (e) {
      dispatch({ type: UPDATE_CAMPAIGN_INFLUENCER_LISTS_ERROR });
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: intl.formatMessage(messages.listUpdateFailed),
          status: 'danger',
        }),
      );

      throw e;
    }
  }
};

export default campaignReducer;
