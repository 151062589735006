import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

class FileInput extends Component {
  onDrop = (acceptedFiles, rejectedFiles) => {
    this.props.onDrop(acceptedFiles, rejectedFiles);
  };

  render() {
    const { className, dropzoneOptions, children } = this.props;

    return (
      <div className={`${className} tk-form-fileinput`}>
        <Dropzone
          {...dropzoneOptions}
          onDrop={this.onDrop}
          className={`${className}--dropzone tk-form-fileinput--dropzone`}
        >
          {children}
        </Dropzone>
      </div>
    );
  }
}

FileInput.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dropzoneOptions: PropTypes.object,
  onDrop: PropTypes.func,
};

FileInput.defaultProps = {
  dropzoneOptions: {},
  className: '',
  onDrop: () => {},
};

export default FileInput;
