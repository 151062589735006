export default {
  newTag: {
    id: 'TAG_NEW_TAG',
    description: 'Label for the new tag button',
    defaultMessage: 'New Tag',
  },
  emptyStateTitle: {
    id: 'TAG_EMPTY_STATE_TITLE',
    description: "Empty state title when users don't have tags",
    defaultMessage: "You don't have any tags",
  },
  emptyStateText: {
    id: 'TAG_EMPTY_STATE_TEXT',
    description: "Empty state text when users don't have tags",
    defaultMessage:
      "Tag any article you find in {COMPANY_NAME}. Once you've tagged an article, you'll be able to manage them here.",
  },
  numberOfArticlesColumnHeader: {
    id: 'TAG_NUMBER_OF_ARTICLES_COLUMN_HEADER',
    defaultMessage: 'No. of Articles',
    description: 'Column header for the tags table',
  },
  deleteTagSuccessfully: {
    id: 'DELETE_TAG_SUCCESSFULLY_MESSAGE',
    defaultMessage: 'Successfully deleted tag.',
    description: 'Message when tag gets deleted successfully',
  },
  deleteTagError: {
    id: 'DELETE_TAG_ERROR',
    defaultMessage: 'Failed to delete tag.',
    description: 'Message when tag could not get deleted',
  },
  noTagsFoundMessage: {
    id: 'NO_TAGS_FOUND_MESSAGE',
    defaultMessage:
      'No tags match with your search criteria. Please adjust your search terms or filters.',
    description: 'Message when there are no tag results after a search.',
  },
  tagUpdatedSuccessfully: {
    id: 'NEW_TAG_VIEW_TAG_WAS_UPDATED_SUCCESSFULLY',
    defaultMessage: 'The tag was updated successfully!',
    description:
      'Tags List or Edit Tag View: Message to show when the tag was updated successfully',
  },
  errorSharingTag: {
    id: 'NEW_TAG_VIEW_ERROR_WHEN_SHARING_THE_TAG',
    defaultMessage: 'Failed to share tag.',
    description:
      'Tags List or Edit Tag View: Message to show when there was an error when sharing the tag',
  },
  tagSharedDescription: {
    id: 'TAG_TOOLTIP_DESCRIPTION',
    defaultMessage: `Shared with {GROUPS} {GROUPS, plural,
    one {group}
    other {groups}}
    with Full Access`,
    description: 'Message for the tooltip on every hover for each tag',
  },
  successfullySharedTag: {
    id: 'TAG_SHARING_MODAL_SUCCESSFULLY_SHARED_TAG',
    defaultMessage: 'Successfully shared tag.',
    description:
      'Old tag listing page: Message to show when the tag was shared successfully',
  },
  tagErrorMessageTemplate: {
    id: 'TAG_ERROR_MESSAGE_TEMPLATE',
    description: 'Template to show errors using a title and a detail',
    defaultMessage: '{TITLE} {DETAIL}',
  },
  tagUserAccountPrintLicenseError: {
    id: 'TAG_USER_ACCOUNT_HAS_NOT_PRINT_LICENSE_SET_CORRECTLY',
    description:
      'Message to show when the user account has not any valid license attached',
    defaultMessage:
      'This account does not have a correct print license attached to the account.',
  },
  tagSearchNotLoadProperly: {
    id: 'TAG_SEARCH_NOT_LOAD_PROPERLY',
    description:
      'Message to show when there was an error when previewing a search',
    defaultMessage: 'Search did not load properly:',
  },
  failPreviewSearchMessage: {
    id: 'TAG_FAIL_PREVIEW_SEARCH_MESSAGE',
    defaultMessage: 'Could not preview search. Query string is invalid.',
    description: 'text that shows after a search preview has failed',
  },
  deleteTagSuccessfullyWithName: {
    id: 'DELETE_TAG_SUCCESSFULLY_MESSAGE_WITH_TAG_NAME',
    defaultMessage: 'Successfully deleted tag "{TAG_NAME}".',
    description: 'Message when tag gets deleted successfully',
  },
  deleteTagErrorWithName: {
    id: 'DELETE_TAG_ERROR_MESSAGE_WITH_TAG_NAME',
    defaultMessage: 'Failed to delete tag "{TAG_NAME}".',
    description: 'Message to use when an error ocurred while deleting a tag',
  },
  deleteTagInProgress: {
    id: 'TAG_BULK_DELETE_TAG_IN_PROGRESS',
    defaultMessage: 'Sit tight. The tag is being deleted.',
    description:
      'Message to let users know that the system is deleting a tag but it might take some time.',
  },
  addBulkTagInProgress: {
    id: 'TAG_BULK_ADD_TAG_IN_PROGRESS',
    defaultMessage: 'Sit tight. Tags are being added to the articles.',
    description:
      'Message to let users know that the system is adding the articles to the selected tags.',
  },
};
