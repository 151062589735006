import {
  GA_URLS_ENDPOINT,
  ANALYTICS_INTEGRATION_ENDPOINT,
  ARTICLE_EXTRACTION_ENDPOINT,
  ARTICLE_SAVE_ENDPOINT,
} from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';
import { performGet, performPost } from 'services/rest-service/rest-service';

import { MANAGE_DATA_ITEMS_PER_PAGE } from '../../constants/constants';

export const SET_GA_URLS = 'manageData/SET_GA_URLS';
export const SET_IS_MANAGE_DATA_LOADING =
  'manageData/SET_IS_MANAGE_DATA_LOADING';
export const SET_FLAT_ANALYTICS_INTEGRATIONS =
  'manageData/SET_FLAT_ANALYTICS_INTEGRATIONS';
export const SET_ANALYTICS_INTEGRATIONS =
  'manageData/SET_ANALYTICS_INTEGRATIONS';
export const SELECT_INTEGRATION = 'manageData/SELECT_INTEGRATION';
export const SET_SEARCH_URLS_RESULT = 'manageData/SET_SEARCH_URLS_RESULT';
export const SET_MODAL_TYPE = 'manageData/SET_MODAL_TYPE';
export const TOGGLE_ARTICLE_MODAL = 'manageData/TOGGLE_ARTICLE_MODAL';
export const TOGGLE_DATE_MODAL = 'manageData/TOGGLE_DATE_MODAL';
export const SET_ARTICLE_INFO = 'manageData/SET_ARTICLE_INFO';
export const SET_SELECTED_URL = 'manageData/SET_SELECTED_URL';
export const SET_CURRENT_PAGE = 'manageData/SET_CURRENT_PAGE';

const initialState = {
  gaUrls: [],
  moreToLoad: false,
  searchUrlsResult: null,
  isLoading: true,
  flatGaIntegrations: [],
  gaIntegrations: [],
  selectedIntegration: {},
  modalType: '',
  articleModal: false,
  dateModal: false,
  articleInfo: {},
  selectedUrl: {},
  currentPage: 1,
};

const manageDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GA_URLS: {
      let newUrls = [];
      if (payload.loadingMoreUrls) {
        newUrls = [...state.gaUrls, ...payload.urls];
      }
      return {
        ...state,
        gaUrls: payload.loadingMoreUrls ? newUrls : payload.urls,
        moreToLoad: payload.moreToLoad,
      };
    }

    case SET_IS_MANAGE_DATA_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case SET_FLAT_ANALYTICS_INTEGRATIONS: {
      return {
        ...state,
        flatGaIntegrations: payload,
      };
    }

    case SET_ANALYTICS_INTEGRATIONS: {
      return {
        ...state,
        gaIntegrations: payload,
      };
    }

    case SELECT_INTEGRATION: {
      return {
        ...state,
        selectedIntegration: payload,
      };
    }

    case SET_SEARCH_URLS_RESULT: {
      return {
        ...state,
        searchUrlsResult: payload,
      };
    }

    case SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: payload,
      };
    }

    case TOGGLE_ARTICLE_MODAL: {
      return {
        ...state,
        articleModal: !state.articleModal,
      };
    }

    case TOGGLE_DATE_MODAL: {
      return {
        ...state,
        dateModal: !state.dateModal,
      };
    }

    case SET_ARTICLE_INFO: {
      return {
        ...state,
        articleInfo: payload,
      };
    }

    case SET_SELECTED_URL: {
      return {
        ...state,
        selectedUrl: payload,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      };
    }

    default:
      return state;
  }
};

export const setGaUrls = (urls, moreToLoad, loadingMoreUrls) => ({
  type: SET_GA_URLS,
  payload: { urls, moreToLoad, loadingMoreUrls },
});

export const setIsManageDataLoading = isLoading => ({
  type: SET_IS_MANAGE_DATA_LOADING,
  payload: isLoading,
});

export const fetchGaUrls = (config, loadingMoreUrls) => dispatch => {
  dispatch(setIsManageDataLoading(true));
  performGet(GA_URLS_ENDPOINT, config)
    .then(res => {
      dispatch(
        setGaUrls(
          res.data.GAUrls.map(url => ({
            ...url,
            media_outlet: url.outlet,
          })),
          res.data.moreToLoad,
          loadingMoreUrls,
        ),
      );
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => dispatch(setIsManageDataLoading(false)));
};

export const fetchAnalyticsIntegrations = currentPage => dispatch => {
  dispatch(setIsManageDataLoading(true));
  performGet(ANALYTICS_INTEGRATION_ENDPOINT)
    .then(res => {
      const gaIntegrations = res.data.gaAccounts
        .filter(integration => integration.enabled)
        .map(integration => ({
          id: integration.gaId,
          label:
            integration.name !== null ? integration.name : integration.gaId,
          selected:
            integration.isDefault === true ? integration.isDefault : false,
          foreignId: integration.id,
        }));
      if (gaIntegrations.length > 0) {
        let selectedIntegration = gaIntegrations.find(
          integration => integration.isDefault === true,
        );
        if (!selectedIntegration) {
          gaIntegrations[0].selected = true;
          selectedIntegration = gaIntegrations[0];
          dispatch({ type: SELECT_INTEGRATION, payload: gaIntegrations[0] });
        }
        dispatch(
          fetchGaUrls({
            pageNum: currentPage,
            itemsPerPage: MANAGE_DATA_ITEMS_PER_PAGE,
            sort: 'sessions',
            asc: true,
            analytics_foreign_id: selectedIntegration.foreignId,
          }),
        );
      }
      dispatch({
        type: SET_FLAT_ANALYTICS_INTEGRATIONS,
        payload: gaIntegrations,
      });
      dispatch({ type: SET_ANALYTICS_INTEGRATIONS, payload: gaIntegrations });
    })
    .finally(() => dispatch(setIsManageDataLoading(false)));
};

export const selectIntegration = integration => dispatch => {
  dispatch({ type: SELECT_INTEGRATION, payload: integration });
  dispatch(
    fetchGaUrls({
      pageNum: 1,
      itemsPerPage: MANAGE_DATA_ITEMS_PER_PAGE,
      sort: 'sessions',
      asc: true,
      analytics_foreign_id: integration.foreignId,
    }),
  );
};

export const searchUrls = searchQuery => (dispatch, getState) => {
  const gaAccounts = getState().manageData.gaUrls;
  const filteredAccounts = gaAccounts.filter(item => {
    const lowerCaseUrl = item.url.toLowerCase();
    if (lowerCaseUrl.includes(searchQuery)) {
      return item;
    }
    return null;
  });
  dispatch({
    type: SET_SEARCH_URLS_RESULT,
    payload: filteredAccounts,
  });
};

export const sortUrls = (options, selectedIntegration) => dispatch => {
  dispatch(
    fetchGaUrls({
      pageNum: options.pageNum,
      itemsPerPage: options.itemsPerPage,
      sort: options.sort,
      asc: options.asc,
      analytics_foreign_id: selectedIntegration.foreignId,
    }),
  );
};

export const toggleArticleModal = () => ({ type: TOGGLE_ARTICLE_MODAL });

export const toggleDateModal = () => ({ type: TOGGLE_DATE_MODAL });

export const openModalAddUrl = (config, article) => dispatch => {
  const queryStringP = {
    url: config.url,
    _: Date.now(),
  };
  dispatch(setIsManageDataLoading(true));
  dispatch({
    type: SET_SELECTED_URL,
    payload: article,
  });
  performPost(ARTICLE_EXTRACTION_ENDPOINT, config, queryStringP)
    .then(res => {
      dispatch({
        type: SET_ARTICLE_INFO,
        payload: {
          title: res.data.title,
          published: res.data.published,
          authors: res.data.authors
            ? [{ name: res.data.authors[0].name, url: '' }]
            : null,
          provider_name: res.data.provider_name,
          description: res.data.description,
          images: res.data.images ? res.data.images : [],
          language: res.data.language,
          offset: res.data.offset,
          original_url: res.data.original_url,
          provider_url: res.data.provider_url,
          safe: res.data.safe,
          type: res.data.type,
          url: res.data.url,
          content: res.data.content,
        },
      });
      dispatch({
        type: SET_MODAL_TYPE,
        payload: res.data.type,
      });
      dispatch(toggleArticleModal());
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => dispatch(setIsManageDataLoading(false)));
};

export const addUrl = config => (dispatch, getState) => {
  const selectedUrl = getState().manageData.selectedUrl;
  const articleInfo = getState().manageData.articleInfo;
  const articleObject = {
    authors: config.authors || articleInfo.authors,
    content: articleInfo.content,
    description: config.description,
    images: articleInfo.images,
    language: articleInfo.language,
    offset: articleInfo.offset,
    original_url: articleInfo.original_url,
    provider_name: config.provider_name,
    provider_url: articleInfo.provider_url,
    published: config.published, //date chosen in modal
    safe: articleInfo.safe,
    title: config.title,
    type: articleInfo.type,
    url: articleInfo.url,
    gaUrl: {
      //this data comes from the table
      gaAccountId: selectedUrl.gaAccountId,
      outlet: selectedUrl.outlet,
      sessions: selectedUrl.sessions,
      url: selectedUrl.url,
    },
  };

  dispatch(setIsManageDataLoading(true));
  performPost(ARTICLE_SAVE_ENDPOINT, articleObject, { gaAdded: true })
    .then(() => {
      dispatch(toggleArticleModal());
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: 'Article added correctly!',
          status: 'success',
          ttl: 4000,
        }),
      );
    })
    .catch(() => {
      dispatch(toggleArticleModal());
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: 'Failed to add the article!',
          status: 'danger',
          ttl: 4000,
        }),
      );
    })
    .finally(() => dispatch(setIsManageDataLoading(false)));
};

export const loadMoreUrls = (currentPage, selectedIntegration) => dispatch => {
  dispatch(setIsManageDataLoading(true));
  const incrementPage = currentPage + 1;
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: incrementPage,
  });
  dispatch(
    fetchGaUrls(
      {
        pageNum: incrementPage,
        itemsPerPage: MANAGE_DATA_ITEMS_PER_PAGE,
        sort: 'sessions',
        asc: true,
        analytics_foreign_id: selectedIntegration,
      },
      true,
    ),
  );
  dispatch(setIsManageDataLoading(false));
};

export default manageDataReducer;
