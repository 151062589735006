export const OUTREACH_PROVIDERS = {
  gmail: 'GMAIL',
  exchange: 'EAS',
  outlook: 'OUTLOOK',
  custom: 'CUSTOM',
};

export const OUTREACH_INTEGRATION_STATUS = {
  enabled: 'ENABLED',
  disabled: 'DISABLED',
  reauth: 'INVALID-CREDENTIALS',
  running: 'RUNNING',
  stopped: 'STOPPED',
  exception: 'EXCEPTION',
  pendingVerification: 'PENDING-VERIFICATION',
};

export const OUTREACH_INTEGRATION_ERROR_TYPES = {
  disconnected: 'DISCONNECTED',
  generic: 'GENERIC',
  generic_send: 'GENERIC_SEND',
  rejected_send: 'REJECTED_SEND',
  syncing: 'SYNCING',
  throttled: 'THROTTLED',
  unknown: 'UNKNOWN',
  unknown_send: 'UNKNOWN_SEND',
  outlookConfig: 'OUTLOOK_CONFIGURATION',
};

export const OUTREACH_INTEGRATION_ERROR_MESSAGES = {
  [OUTREACH_INTEGRATION_ERROR_TYPES.disconnected]:
    'Sorry, your emails could not be loaded. Cision could not connect to your email provider. Please try reconnecting your account.',
  [OUTREACH_INTEGRATION_ERROR_TYPES.generic_send]:
    'Hmm, something went wrong. We couldn’t send your email. Please try again.',
  [OUTREACH_INTEGRATION_ERROR_TYPES.generic]:
    'Hmm, something went wrong. Please try again later.',
  [OUTREACH_INTEGRATION_ERROR_TYPES.rejected_send]: (subject, to) =>
    `Sorry, your email “${subject}” to ${to} was rejected by the recipients email provider.`,
  [OUTREACH_INTEGRATION_ERROR_TYPES.syncing]:
    'Hmm, something went wrong. We couldn’t sync your email(s)',
  [OUTREACH_INTEGRATION_ERROR_TYPES.throttled]:
    "You are attempting to send too many emails at the same time or are over your email provider's sending quota. Please lower the number of emails you are sending or try to send them again later.",
  [OUTREACH_INTEGRATION_ERROR_TYPES.unknown]:
    'Sorry, Cision’s email service is currently experiencing problems. Please try again later. Sorry for any inconvenience.',
  [OUTREACH_INTEGRATION_ERROR_TYPES.unknown_send]:
    'Sorry, we couldn’t send that email. It looks like your email provider is temporarily unavailable. Please try again later. Sorry for any inconvenience.',
  [OUTREACH_INTEGRATION_ERROR_TYPES.outlookConfig]:
    // eslint-disable-next-line max-len
    "Sorry, we couldn't send your email because of Exchange, Office 365, or Outlook settings. Check with your IT team that you're authorized to use Cision's email integration. Contact Cision support if you or your IT team need more help.",
};

export const OUTREACH_INTEGRATION_ERROR_STATE_TITLES = {
  [OUTREACH_INTEGRATION_ERROR_TYPES.disconnected]: 'Could not load emails',
  [OUTREACH_INTEGRATION_ERROR_TYPES.syncing]: 'Hmm, something went wrong',
  [OUTREACH_INTEGRATION_ERROR_TYPES.generic]: 'Hmm, something went wrong',
  [OUTREACH_INTEGRATION_ERROR_TYPES.unknown]: 'Email service unavailable',
  [OUTREACH_INTEGRATION_ERROR_TYPES.outlookConfig]: 'Contact your IT team',
};

export const OUTREACH_INTEGRATION_BULK_ERROR_MESSAGE_OVERRIDES = {
  'Request failed with status code 422':
    'Microsoft mail settings error. Please contact your IT team.',
};
