import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

const ShimmerText = ({ className, style }) => {
  const mainClass = classnames(ShimmerText.baseClass, className);

  return <div className={mainClass} style={style} />;
};

ShimmerText.baseClass = 'tk-shimmer-text';

ShimmerText.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

ShimmerText.defaultProps = {
  className: '',
  style: {},
};

export default ShimmerText;
