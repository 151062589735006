import {
  CONTACT_PUBLICATIONS_ENDPOINT,
  CONTACT_BASE_ENDPOINT,
} from 'constants/apis';
import { CONTACT_RELATIONSHIP_STATUSES } from 'constants/constants';
import { performGet } from 'services/rest-service/rest-service';
import { loadState, saveState } from 'utils/local-storage-state';

export const DISCOVER_FILTERS = 'discover';
export const SEARCH_FILTERS = 'search';
export const SAVED_FILTERS = 'saved';

export const CLEAR_CONTACTS_FILTERS = 'contacts-filter/CLEAR_CONTACTS_FILTERS';
export const UPDATE_CONTACTS_FILTERS =
  'contacts-filter/UPDATE_CONTACTS_FILTERS';
export const TOGGLE_CONTACT_FILTER_DRAWER =
  'contacts-filter/TOGGLE_CONTACT_FILTER_DRAWER';
export const SET_CONTACT_FILTER_DRAWER =
  'contacts-filter/SET_CONTACT_FILTER_DRAWER';
export const LOAD_PUBLICATIONS_REQUEST =
  'contacts-filter/LOAD_PUBLICATIONS_REQUEST';
export const LOAD_PUBLICATIONS_SUCCESS =
  'contacts-filter/LOAD_PUBLICATIONS_SUCCESS';
export const LOAD_PUBLICATIONS_ERROR =
  'contacts-filter/LOAD_PUBLICATIONS_ERROR';
export const SET_CURRENT_PUBLICATION_IDS =
  'contacts-filter/SET_CURRENT_PUBLICATION_IDS';
export const LOAD_RELATIONSHIP_STATUSES_SUCCESS =
  'contacts-filter/LOAD_RELATIONSHIP_STATUSES_SUCCESS';
export const LOAD_RELATIONSHIP_STATUSES_ERROR =
  'contacts-filter/LOAD_RELATIONSHIP_STATUSES_ERROR';

const LOCAL_STORAGE_KEY = 'contact-filters';
const initialState = {
  drawerOpen: false,
  filterValues: loadState(LOCAL_STORAGE_KEY) || {
    [DISCOVER_FILTERS]: {},
    [SAVED_FILTERS]: {},
    [SEARCH_FILTERS]: {},
  },
  publications: {
    options: {
      [DISCOVER_FILTERS]: [],
      [SAVED_FILTERS]: [],
      [SEARCH_FILTERS]: [],
    },
    data: {},
    error: false,
    loading: false,
  },
  relationshipStatuses: [...CONTACT_RELATIONSHIP_STATUSES],
};

const saveFilterValues = state => {
  saveState(LOCAL_STORAGE_KEY, state.contactsFilter.filterValues);
};

const contactsFilter = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CONTACT_FILTER_DRAWER: {
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    }
    case SET_CONTACT_FILTER_DRAWER: {
      return {
        ...state,
        drawerOpen: !!action.payload.open,
      };
    }
    case CLEAR_CONTACTS_FILTERS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.payload.name]: {},
        },
      };
    }
    case UPDATE_CONTACTS_FILTERS: {
      const { name, filters } = action.payload;
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [name]: {
            ...state.filterValues[name],
            ...filters,
          },
        },
      };
    }
    case SET_CURRENT_PUBLICATION_IDS: {
      const { namespace, ids } = action.payload;

      return {
        ...state,
        publications: {
          ...state.publications,
          options: {
            ...state.publications.options,
            [namespace]: ids,
          },
        },
      };
    }
    case LOAD_PUBLICATIONS_REQUEST: {
      return {
        ...state,
        publications: {
          ...state.publications,
          error: false,
          loading: true,
        },
      };
    }
    case LOAD_PUBLICATIONS_ERROR: {
      return {
        ...state,
        publications: {
          ...state.publications,
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    }
    case LOAD_PUBLICATIONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        publications: {
          ...state.publications,
          data: {
            ...state.publications.data,
            ...data,
          },
          error: false,
          loading: false,
        },
      };
    }
    case LOAD_RELATIONSHIP_STATUSES_SUCCESS: {
      const { relationships } = action.payload;
      const relationshipStatuses = relationships
        ? CONTACT_RELATIONSHIP_STATUSES.filter(r =>
            relationships.includes(r.id),
          )
        : [];

      return {
        ...state,
        relationshipStatuses,
      };
    }
    default:
      return state;
  }
};

export const setContactFilterDrawer = open => ({
  type: SET_CONTACT_FILTER_DRAWER,
  payload: { open },
});

export const toggleContactFilterDrawer = () => ({
  type: TOGGLE_CONTACT_FILTER_DRAWER,
});

export const clearContactFilters = name => (dispatch, getState) => {
  dispatch({
    type: CLEAR_CONTACTS_FILTERS,
    payload: { name },
  });
  saveFilterValues(getState());
};

export const updateContactFilters = (name, filters) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CONTACTS_FILTERS,
    payload: { name, filters },
  });
  saveFilterValues(getState());
};

export const loadPublicationsRequest = () => ({
  type: LOAD_PUBLICATIONS_REQUEST,
});

export const loadPublicationsSuccess = data => ({
  type: LOAD_PUBLICATIONS_SUCCESS,
  payload: { data },
});

export const loadPublicationsError = err => ({
  type: LOAD_PUBLICATIONS_ERROR,
  payload: err,
});

export const setCurrentPublicationsIds = (namespace, ids) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: SET_CURRENT_PUBLICATION_IDS,
    payload: { namespace, ids },
  });
  saveFilterValues(getState());
};

export const getPublications = (namespace, params) => dispatch => {
  dispatch(loadPublicationsRequest());

  return performGet(CONTACT_PUBLICATIONS_ENDPOINT, params)
    .then(response => {
      const publicationOptions = response.data.reduce((opts, datum) => {
        const keys = Object.keys(datum.map);

        keys.forEach(key => {
          opts = {
            ...opts,
            [key]: { id: key, label: datum.map[key] },
          };
        });

        return opts;
      }, {});

      dispatch(loadPublicationsSuccess(publicationOptions));

      const publicationIds = Object.keys(publicationOptions);
      dispatch(setCurrentPublicationsIds(namespace, publicationIds));
    })
    .catch(error => {
      dispatch(loadPublicationsError(error));
    });
};

export const getRelationshipStatusesPerAccount = () => dispatch => {
  return performGet(`${CONTACT_BASE_ENDPOINT}/relationships`, {})
    .then(response => {
      dispatch({
        type: LOAD_RELATIONSHIP_STATUSES_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({ type: LOAD_RELATIONSHIP_STATUSES_ERROR, payload: { error } });
    });
};

export default contactsFilter;
