import React, { Component } from 'react';

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import BrowseModalUpload from '../browse-asset-library/BrowseModalUpload';

import {
  logoDefaultProps,
  logoPropTypes,
} from '../story/templates/default-template';

class StoryLogoDisplay extends Component {
  state = {
    imgHeight: 0,
    imgLoaded: false,
    imgWidth: 0,
  };

  componentDidMount() {
    const { logoSrc } = this.props;

    if (logoSrc) {
      this.getImageDimensions(logoSrc);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    const propsToCheck = ['logoSize', 'logoSrc'];
    if (
      !isEqual(pick(this.props, propsToCheck), pick(nextProps, propsToCheck)) &&
      nextProps.logoSrc
    ) {
      this.getImageDimensions(nextProps.logoSrc);
    }
  }

  componentWillUnmount() {
    this.removeImgEventListeners();
  }

  getImageDimensions = logoSrc => {
    this.preloader.src = logoSrc;
    this.preloader.addEventListener('error', this.unsetImgDimensions);
    this.preloader.addEventListener('load', this.setImgDimensions);
  };

  setImgDimensions = event => {
    const { onSetImgDimensions, logoSize } = this.props;
    const maxLength = this.maxLengthMap[logoSize] || 200;
    let { naturalHeight, naturalWidth } = event.target;
    naturalHeight = typeof naturalHeight === 'number' ? naturalHeight : 0;
    naturalWidth = typeof naturalWidth === 'number' ? naturalWidth : 0;
    const aspectRatio = naturalHeight !== 0 ? naturalWidth / naturalHeight : 0;
    let imgHeight = 0;
    let imgWidth = 0;

    this.removeImgEventListeners();

    if (naturalHeight > naturalWidth) {
      imgHeight = maxLength;
      imgWidth = imgHeight * aspectRatio;
    } else {
      imgWidth = maxLength;
      imgHeight = imgWidth / aspectRatio;
    }

    onSetImgDimensions({ imgHeight, imgWidth });
    this.setState(() => ({ imgHeight, imgLoaded: true, imgWidth }));
  };

  maxLengthMap = { sm: 140, md: 200, lg: 240 };

  unsetImgDimensions = () => {
    this.removeImgEventListeners();

    this.setState({
      imgHeight: 0,
      imgLoaded: false,
      imgWidth: 0,
    });
  };

  removeImgEventListeners = () => {
    this.preloader.removeEventListener('error', this.unsetImgDimensions);
    this.preloader.removeEventListener('load', this.setImgDimensions);
  };

  preloader = new Image();

  render() {
    const { imgHeight, imgLoaded, imgWidth } = this.state;
    const {
      isEditable,
      lastHeight,
      logoSize,
      logoSrc,
      browseAssetComponent,
      showOtherUploadOptions,
      handleSelectedFilesfromPopUp,
      makeBrowseAssetComponentFalse,
      onSelectFiles,
      isFromStoryKit,
    } = this.props;
    const baseClass = 'tk-story-logo-display';
    const height = imgLoaded
      ? 'auto'
      : lastHeight || this.maxLengthMap[logoSize] || 200;

    const imgClasses = classNames(`${baseClass}__img`, {
      [`${baseClass}__img--hidden`]: false,
    });

    if (!logoSrc && !isEditable) {
      return null;
    }

    return (
      <div className={baseClass} style={{ height }}>
        {browseAssetComponent && (
          <div>
            <BrowseModalUpload
              browseAssetComponent={browseAssetComponent}
              showOtherUploadOptions={showOtherUploadOptions}
              handleSelectedFilesfromPopUp={handleSelectedFilesfromPopUp}
              makeBrowseAssetComponentFalse={makeBrowseAssetComponentFalse}
              onSelectFiles={onSelectFiles}
              isFromStoryKit={isFromStoryKit}
            />
          </div>
        )}
        {logoSrc && imgLoaded && (
          <img
            alt="logo"
            className={imgClasses}
            ref={Img => {
              this.Img = Img;
            }}
            src={logoSrc}
            style={{
              height: imgHeight,
              width: imgWidth,
            }}
          />
        )}
        {!logoSrc && <div className={`${baseClass}__placeholder`}>No logo</div>}
      </div>
    );
  }
}

StoryLogoDisplay.propTypes = {
  isEditable: PropTypes.bool,
  ...logoPropTypes,
  onSetImgDimensions: PropTypes.func,
  handleSelectedFilesfromPopUp: PropTypes.func,
  makeBrowseAssetComponentFalse: PropTypes.func,
};

StoryLogoDisplay.defaultProps = {
  isEditable: false,
  makeBrowseAssetComponentFalse: () => {},
  ...logoDefaultProps,
  onSetImgDimensions: () => {},
  handleSelectedFilesfromPopUp: () => {},
};

export default StoryLogoDisplay;
