import { CONTACT_SOCIAL_HISTORY_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

import { getPrimaryProfileForNetwork } from '../social-utils';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const youtubeReducerFactory = namespace => {
  const GET_YOUTUBE_VIDEOS_START = `ihub/${namespace}/GET_YOUTUBE_VIDEOS_START`;
  const GET_YOUTUBE_VIDEOS_SUCCESS = `ihub/${namespace}/GET_YOUTUBE_VIDEOS_SUCCESS`;
  const GET_YOUTUBE_VIDEOS_ERROR = `ihub/${namespace}/GET_YOUTUBE_VIDEOS_ERROR`;

  const getRecentYoutubeVideosForInfluencer = influencer => async dispatch => {
    dispatch({ type: GET_YOUTUBE_VIDEOS_START });
    let payload = [];
    const youtubeProfile = getPrimaryProfileForNetwork(
      influencer.socialProfiles,
      'youtube',
    );
    if (youtubeProfile && youtubeProfile.id) {
      let result;
      try {
        result = await performGet(
          `${CONTACT_SOCIAL_HISTORY_ENDPOINT}/?site=youtube&channelId=${youtubeProfile.id}`,
        );
      } catch (e) {
        dispatch({ type: GET_YOUTUBE_VIDEOS_ERROR, payload: e });
      }
      const d = result.data.data;
      if (d.error) {
        dispatch({ type: GET_YOUTUBE_VIDEOS_ERROR, payload: result });
        return;
      }
      payload = d.items;
    }
    dispatch({ type: GET_YOUTUBE_VIDEOS_SUCCESS, payload });
  };

  const youtubeReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_YOUTUBE_VIDEOS_START:
        return {
          ...state,
          loading: true,
          error: false,
          data: [],
        };

      case GET_YOUTUBE_VIDEOS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: [...action.payload],
        };

      case GET_YOUTUBE_VIDEOS_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };

  return {
    GET_YOUTUBE_VIDEOS_START,
    GET_YOUTUBE_VIDEOS_SUCCESS,
    GET_YOUTUBE_VIDEOS_ERROR,
    getRecentYoutubeVideosForInfluencer,
    youtubeReducer,
  };
};

export default youtubeReducerFactory;
