import React, { useMemo } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from '../svg-icon';

const SmallCheckbox = ({
  smallCheckboxSize,
  modifiers,
  name,
  selected,
  stopPropagation,
  indeterminate,
  onClick,
  children,
  disabled,
  dataQA,
  id,
}) => {
  const iconColor =
    !disabled && (selected || indeterminate) ? 'info' : undefined;
  const icon =
    (selected && 'checkboxChecked') ||
    (indeterminate && 'checkboxIndeterminate') ||
    'checkboxUnchecked';
  const labelBaseClass = 'tk-checkbox';
  const prefixedModifiers = useMemo(() => {
    return Array.isArray(modifiers)
      ? modifiers.map(modifier => `${labelBaseClass}--${modifier}`)
      : [];
  }, [modifiers]);
  const labelClassNames = useMemo(() => {
    return classNames(labelBaseClass, prefixedModifiers, {
      [`${labelBaseClass}--selected`]: selected,
      [`${labelBaseClass}--disabled`]: disabled,
    });
  }, [disabled, prefixedModifiers, selected]);

  const handleOnClick = event => {
    if (stopPropagation) {
      event.stopPropagation();
    }
  };

  return (
    <label className={labelClassNames} htmlFor={name}>
      <SvgIcon
        width={smallCheckboxSize}
        height={smallCheckboxSize}
        color={iconColor}
        icon={icon}
      />
      {children && (
        <span className={`${labelBaseClass}__children`}>{children}</span>
      )}
      <input
        id={id}
        className={`${labelBaseClass}__input`}
        type="checkbox"
        checked={selected}
        onChange={onClick}
        disabled={disabled}
        onClick={handleOnClick}
        name={name}
        data-qa={dataQA}
      />
    </label>
  );
};

SmallCheckbox.propTypes = {
  smallCheckboxSize: PropTypes.number,
  stopPropagation: PropTypes.bool,
  name: PropTypes.string,
  selected: PropTypes.bool,
  indeterminate: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['light'])),
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  dataQA: PropTypes.string,
  id: PropTypes.string,
};

SmallCheckbox.defaultProps = {
  smallCheckboxSize: 16,
  stopPropagation: true,
  name: 'checkbox',
  selected: false,
  indeterminate: false,
  children: null,
  disabled: false,
  modifiers: [],
  onClick: () => {},
  dataQA: null,
  id: undefined,
};

export default SmallCheckbox;
