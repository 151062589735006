import find from 'lodash/find';

import { SEARCH_LIST_ENDPOINT_V2 } from 'constants/apis';
import discoveryMessages from 'pages/Discovery/discovery.messages';
import { addPageMessage } from 'reducers/page-messages';
import { requestMultiple } from 'services/rest-service/rest-service';
import { getDatesForRange, getCustomDatesForRange } from 'utils/date/date-util';

export const GET_DISCOVERY_METRICS_DATA =
  'discovery/GET_DISCOVERY_METRICS_DATA';
export const GET_DISCOVERY_METRICS_DATA_RECEIVED =
  'discovery/GET_DISCOVERY_METRICS_DATA_RECEIVED';
export const GET_DISCOVERY_METRICS_DATA_ERROR =
  'discovery/GET_DISCOVERY_METRICS_DATA_ERROR';

const USER_ACCOUNT_HAS_NOT_PRINT_LICENSE_ATTACHED_CORRECTLY =
  'It has PRINT flag enable without any Print license attached to the account.';

export const initialState = {
  discoveryMetrics: {},
  error: false,
  loading: true,
};

const discoveryMetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISCOVERY_METRICS_DATA:
      return {
        ...state,
        discoveryMetrics: {
          ...state.discoveryMetrics,
          [action.payload.searchId]: {
            loading: true,
          },
        },
      };
    case GET_DISCOVERY_METRICS_DATA_RECEIVED:
      return {
        ...state,
        discoveryMetrics: {
          ...state.discoveryMetrics,
          [action.payload.searchId]: {
            metrics: action.payload.metrics,
            compareMetrics: action.payload.compareMetrics,
            loading: false,
          },
        },
        error: false,
      };
    case GET_DISCOVERY_METRICS_DATA_ERROR:
      return {
        ...state,
        discoveryMetrics: {
          ...state.discoveryMetrics,
          [action.payload.searchId]: {
            loading: false,
          },
        },
        error: true,
      };
    default:
      return state;
  }
};

export const getDiscoveryMetrics = (
  searchId,
  rangeType,
  customDate,
  intl,
) => async dispatch => {
  dispatch({ type: GET_DISCOVERY_METRICS_DATA, payload: { searchId } });

  const metricsTimes = !customDate
    ? getDatesForRange(rangeType)
    : getCustomDatesForRange(customDate);
  const url = `${SEARCH_LIST_ENDPOINT_V2}/${searchId}/metrics`;
  const metricReq = {
    url,
    method: 'get',
    params: {
      startDate: metricsTimes.startDate.valueOf(),
      endDate: metricsTimes.endDate.valueOf(),
      skipCache: true,
    },
  };
  const compareReq = {
    url,
    method: 'get',
    params: {
      startDate: metricsTimes.previousStart.valueOf(),
      endDate: metricsTimes.previousEnd.valueOf(),
      skipCache: true,
    },
  };

  try {
    const result = await requestMultiple([metricReq, compareReq]);
    const metrics = find(result, {
      config: {
        params: {
          endDate: metricsTimes.endDate.valueOf(),
          startDate: metricsTimes.startDate.valueOf(),
        },
      },
    }).data;
    const compareMetrics = find(result, {
      config: {
        params: {
          startDate: metricsTimes.previousStart.valueOf(),
          endDate: metricsTimes.previousEnd.valueOf(),
        },
      },
    }).data;
    dispatch({
      type: GET_DISCOVERY_METRICS_DATA_RECEIVED,
      payload: { metrics, compareMetrics, searchId },
    });
  } catch (e) {
    const errorText =
      e?.response?.data?.error || e?.response?.data?.message || '';
    dispatch({ type: GET_DISCOVERY_METRICS_DATA_ERROR, payload: { searchId } });
    let translatedErrorMessage = intl.formatMessage(
      discoveryMessages.discoveryDefaultErrorMessage,
    );
    if (
      errorText.indexOf(USER_ACCOUNT_HAS_NOT_PRINT_LICENSE_ATTACHED_CORRECTLY) >
      -1
    ) {
      translatedErrorMessage = intl.formatMessage(
        discoveryMessages.discoveryUserAccountPrintLicenseError,
      );
    }
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: translatedErrorMessage,
        status: 'danger',
        ttl: 3000,
      }),
    );
    throw e;
  }
};

export default discoveryMetricsReducer;
