import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import DashboardValidator from 'services/dashboard-templates-service';
import {
  performGet,
  performPost,
  performPut,
} from 'services/rest-service/rest-service';

export const GET_DASHBOARD_TEMPLATES =
  'dashboard-templates/GET_DASHBOARD_TEMPLATES';
export const GET_DASHBOARD_TEMPLATES_SUCCESS =
  'dashboard-templates/GET_DASHBOARD_TEMPLATES_SUCCESS';
export const GET_DASHBOARD_TEMPLATES_ERROR =
  'dashboard-templates/GET_DASHBOARD_TEMPLATES_ERROR';
export const GET_MARKET_SEGMENTS = 'dashboard-templates/GET_MARKET_SEGMENTS';
export const GET_CONTENT_FILTERS = 'dashboard-templates/GET_CONTENT_FILTERS';
export const GET_WIDGET_TYPES = 'dashboard-templates/GET_WIDGET_TYPES';
export const SET_CURRENT_DASHBOARD_TEMPLATE =
  'dashboard-templates/SET_CURRENT_DASHBOARD_TEMPLATE';
export const GET_SEARCH_TAXONOMY = 'dashboard-template/GET_SEARCH_TAXONOMY';
export const GET_SEARCH_TAXONOMY_SUCCESS =
  'dashboard-template/GET_SEARCH_TAXONOMY_SUCCESS';
export const GET_SEARCH_TAXONOMY_ERROR =
  'dashboard-template/GET_SEARCH_TAXONOMY_ERROR';
export const CHANGE_TEMPLATE_FORM = 'dashboard-template/CHANGE_TEMPLATE_FORM';
export const SAVE_TEMPLATE = 'dashboard-template/SAVE_TEMPLATE';
export const SAVE_TEMPLATE_SUCCESS = 'dashboard-template/SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_ERROR = 'dashboard-template/SAVE_TEMPLATE_ERROR';

const initialState = {
  dashboardTemplatesById: {},
  verticals: [],
  contentFilters: [],
  widgetTypes: [],
  isLoading: false,
  loadingError: false,
  taxonomyById: {},
  allTaxonomies: [],
  allTaxonomiesLoading: false,
  validTaxonomyByVerticalId: {},
  loadingTaxonomyForVerticalId: {},
  currentDashboardTemplateId: null,
  templateForm: null,
  savingTemplate: false,
  saveErrors: null,
};

const dashboardTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_TAXONOMY: {
      const { verticalId } = action.payload;
      const loadingTaxonomyForVerticalId = {
        ...state.loadingTaxonomyForVerticalId,
      };
      if (verticalId) {
        loadingTaxonomyForVerticalId[verticalId] = true;
      }

      return {
        ...state,
        allTaxonomiesLoading: !verticalId ? true : state.allTaxonomiesLoading,
        loadingTaxonomyForVerticalId,
      };
    }
    case GET_SEARCH_TAXONOMY_SUCCESS: {
      const { verticalId } = action.payload;
      const validTaxonomyByVerticalId = { ...state.validTaxonomyByVerticalId };
      const loadingTaxonomyForVerticalId = {
        ...state.loadingTaxonomyForVerticalId,
      };
      if (verticalId) {
        validTaxonomyByVerticalId[verticalId] = action.payload.taxonomies;
        loadingTaxonomyForVerticalId[verticalId] = false;
      }

      return {
        ...state,
        allTaxonomies: !verticalId
          ? action.payload.taxonomies
          : state.allTaxonomies,
        allTaxonomiesLoading: !verticalId ? false : state.allTaxonomiesLoading,
        validTaxonomyByVerticalId,
        loadingTaxonomyForVerticalId,
      };
    }
    case GET_SEARCH_TAXONOMY_ERROR: {
      const { verticalId } = action.payload;
      const loadingTaxonomyForVerticalId = {
        ...state.loadingTaxonomyForVerticalId,
      };
      if (verticalId) {
        loadingTaxonomyForVerticalId[verticalId] = false;
      }

      return {
        ...state,
        allTaxonomiesLoading: !verticalId ? false : state.allTaxonomiesLoading,
        loadingTaxonomyForVerticalId,
      };
    }
    case GET_DASHBOARD_TEMPLATES:
      return {
        ...state,
        dashboardTemplatesById: {},
        isLoading: true,
        loadingError: false,
      };
    case GET_DASHBOARD_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardTemplatesById: action.payload.reduce((a, v) => {
          a[v.id] = v;
          return a;
        }, {}),
      };
    case GET_DASHBOARD_TEMPLATES_ERROR:
      return {
        ...state,
        isLoading: false,
        loadingError: true,
      };
    case GET_MARKET_SEGMENTS:
      return {
        ...state,
        verticals: action.payload,
      };
    case GET_CONTENT_FILTERS:
      return {
        ...state,
        contentFilters: action.payload,
      };
    case GET_WIDGET_TYPES:
      return {
        ...state,
        widgetTypes: action.payload,
      };
    case SET_CURRENT_DASHBOARD_TEMPLATE:
      return {
        ...state,
        currentDashboardTemplateId: action.payload,
        templateForm: {
          ...(action.payload
            ? state.dashboardTemplatesById[action.payload]
            : {
                title: '',
                templateJson: '',
                published: false,
                verticalId: null,
                marketSegment: { id: 1 },
              }),
        },
      };
    case CHANGE_TEMPLATE_FORM:
      return {
        ...state,
        templateForm: {
          ...state.templateForm,
          ...action.payload.change,
        },
      };
    case SAVE_TEMPLATE:
      return {
        ...state,
        savingTemplate: true,
        savingErrors: null,
      };
    case SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        savingTemplate: false,
        dashboardTemplatesById: {
          ...state.dashboardTemplatesById,
          [action.payload.template.id]: action.payload.template,
        },
      };
    case SAVE_TEMPLATE_ERROR:
      return {
        ...state,
        savingTemplate: false,
        savingErrors: action.payload.messages,
      };
    default:
      return state;
  }
};

export const setCurrentDashboardTemplateActionCreator = id => ({
  type: SET_CURRENT_DASHBOARD_TEMPLATE,
  payload: id,
});

export const getDashboardTemplatesActionCreator = () => async dispatch => {
  dispatch({ type: GET_DASHBOARD_TEMPLATES });
  let responses;
  try {
    responses = await Promise.all([
      performGet('/api/dashboard-templates'),
      performGet('/api/market-segments'),
      performGet('/api/content-filter'),
      performGet('/api/widgettype/types'),
    ]);
  } catch (e) {
    dispatch({ type: GET_DASHBOARD_TEMPLATES_ERROR });
    throw e;
  }

  dispatch({
    type: GET_DASHBOARD_TEMPLATES_SUCCESS,
    payload: responses[0].data,
  });
  dispatch({ type: GET_MARKET_SEGMENTS, payload: responses[1].data });
  dispatch({ type: GET_CONTENT_FILTERS, payload: responses[2].data });
  dispatch({ type: GET_WIDGET_TYPES, payload: responses[3].data });
};

const getTaxonomiesByVerticalId = verticalId => async dispatch => {
  dispatch({ type: GET_SEARCH_TAXONOMY, payload: { verticalId } });

  const params = {
    validated_only: false,
  };

  if (verticalId && verticalId !== '00000000-0000-0000-0000-000000000000') {
    params.vertical_id = verticalId;
  }

  const response = await performGet('/api/searchtaxonomy', params);
  dispatch({
    type: GET_SEARCH_TAXONOMY_SUCCESS,
    payload: { taxonomies: response.data.map(t => t.type.name), verticalId },
  });
};

export const saveTemplateActionCreator = template => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const {
    dashboardTemplates: {
      allTaxonomies,
      validTaxonomyByVerticalId,
      contentFilters,
      widgetTypes,
    },
  } = state;

  const validTaxonomies = template.verticalId
    ? validTaxonomyByVerticalId[template.verticalId]
    : allTaxonomies;

  const validator = new DashboardValidator(
    contentFilters,
    widgetTypes,
    validTaxonomies,
  );
  if (validator.validate(template.templateJson)) {
    let response;

    try {
      if (template.id) {
        response = await performPut(
          `/api/dashboard-templates/${template.id}`,
          template,
        );
      } else {
        response = await performPost('/api/dashboard-templates/', template);
      }
      dispatch({
        type: SAVE_TEMPLATE_SUCCESS,
        payload: { template: response.data },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Successfully saved template',
          status: 'success',
        }),
      );
    } catch (e) {
      dispatch({ type: SAVE_TEMPLATE_ERROR, payload: { messages: e.message } });
    }
  } else {
    dispatch({
      type: SAVE_TEMPLATE_ERROR,
      payload: { messages: validator.errorMessages },
    });
  }
};

export const changeTemplateFormActionCreator = change => ({
  type: CHANGE_TEMPLATE_FORM,
  payload: { change },
});

export const changeVerticalActionCreator = verticalId => (
  dispatch,
  getState,
) => {
  const currentState = getState();
  const localState = currentState.dashboardTemplates;
  if (
    (verticalId === null &&
      !localState.allTaxonomies.length &&
      !localState.allTaxonomiesLoading) ||
    (verticalId &&
      !localState.validTaxonomyByVerticalId[verticalId] &&
      !localState.loadingTaxonomyForVerticalId[verticalId])
  ) {
    dispatch(getTaxonomiesByVerticalId(verticalId));
  }

  dispatch(changeTemplateFormActionCreator({ verticalId }));
};

export default dashboardTemplatesReducer;
