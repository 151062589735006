import React from 'react';

import ResponsiveContext from './responsive-context';
import withModifiers from './withModifiers';

/**
  Looks for a ResponsiveContext.Provider in the nearest ancestor component.
  If it finds one, it gets a list of responsive modifiers (strings representing current breakpoint queries).
  It uses withModifiers to turn those strings into BEM-style classes.
*/
const withResponsiveModifiers = BaseComponent => {
  const ModifiedComponent = withModifiers(BaseComponent);

  const ResponsiveModifiedComponent = props => {
    const wrappedComponent = (
      <ResponsiveContext.Consumer>
        {responsiveContext => {
          const baseModifiers = props.modifiers || [];
          const responsiveModifiers = responsiveContext || [];
          return (
            <ModifiedComponent
              {...props}
              modifiers={[...baseModifiers, ...responsiveModifiers]}
            />
          );
        }}
      </ResponsiveContext.Consumer>
    );

    return wrappedComponent;
  };

  ResponsiveModifiedComponent.displayName = `withResponsiveModifiers(${
    BaseComponent.displayName || BaseComponent.name || 'BaseComponent'
  })`;

  return ResponsiveModifiedComponent;
};

export default withResponsiveModifiers;
