import { SOCIAL_KEYWORDS_STREAM_FILTERS_OPERATORS } from 'constants/constants';

import {
  ADD_KEYWORD,
  DELETE_KEYWORD,
  SOCIAL_STREAM_CREATE_FILTER_LOCATION,
  SOCIAL_STREAM_CREATE_FILTER_LANGUAGE,
  SOCIAL_STREAM_CREATE_FILTER_SENTIMENT,
  SOCIAL_STREAM_CREATE_FILTER_MEDIA,
  SOCIAL_STREAM_CREATE_FILTER_FOLLOWER_COUNT,
  SET_ACTIVE_KEYWORD_CONTAINER_ID,
  TAKE_QUERY_SNAPSHOT,
  APPLY_QUERY_BUILDER,
  SET_ADVANCED_QUERY,
  ALLOW_SAVE_QUERY,
  SET_IS_VALID_SEARCH,
  SHOW_KEYWORD_ERROR,
  CLEAN_UP_ALL_KEYWORD_ERRORS,
  SET_MAX_MENTIONS,
  SET_MENTION_COUNT,
  SET_SOCIAL_SEARCH_BUILDER_DATA,
} from './actions';

const MUST_CRITERIA_KEYWORDS = 'mustCriteriaKeywords';
const ANY_CRITERIA_KEYWORDS = 'andAnyCriteriaKeywords';
const NOT_CRITERIA_KEYWORDS = 'notCriteriaKeywords';

export const STREAM_KEYWORDS_CRITERIAS_KEYS = {
  mustCriteria: MUST_CRITERIA_KEYWORDS,
  anyCriteria: ANY_CRITERIA_KEYWORDS,
  notCriteria: NOT_CRITERIA_KEYWORDS,
};

export const FOLLOWERS_COUNT_FILTER_INITIAL_VALUE = {
  min: 'none',
  max: 'none',
};

export const TOGGLE_LIST_MODALS_INITIAL_VALUE = {
  operator: SOCIAL_KEYWORDS_STREAM_FILTERS_OPERATORS.is,
  values: [],
};

export const initialState = {
  filters: {
    country: TOGGLE_LIST_MODALS_INITIAL_VALUE,
    language: TOGGLE_LIST_MODALS_INITIAL_VALUE,
    sentiment: TOGGLE_LIST_MODALS_INITIAL_VALUE,
    media: TOGGLE_LIST_MODALS_INITIAL_VALUE,
    twitterFollowers: FOLLOWERS_COUNT_FILTER_INITIAL_VALUE,
  },
  keywords: {
    [STREAM_KEYWORDS_CRITERIAS_KEYS.mustCriteria]: [],
    [STREAM_KEYWORDS_CRITERIAS_KEYS.anyCriteria]: [],
    [STREAM_KEYWORDS_CRITERIAS_KEYS.notCriteria]: [],
    [`${STREAM_KEYWORDS_CRITERIAS_KEYS.mustCriteria}Error`]: '',
    [`${STREAM_KEYWORDS_CRITERIAS_KEYS.anyCriteria}Error`]: '',
    [`${STREAM_KEYWORDS_CRITERIAS_KEYS.notCriteria}Error`]: '',
  },
  activeKeywordContainerId: '',
  advancedQuery: false,
  isValidSearch: false,
  querySnapshot: null,
  allowSaveQuery: false,
  maxMentions: undefined,
  mentionCount: undefined,
  userId: null,
  shared: null,
  viewOnly: null,
};

const keywordStreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCIAL_STREAM_CREATE_FILTER_MEDIA:
      return {
        ...state,
        filters: {
          ...state.filters,
          media: action.filters,
        },
      };
    case SOCIAL_STREAM_CREATE_FILTER_LOCATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          country: action.filters,
        },
      };
    case SOCIAL_STREAM_CREATE_FILTER_LANGUAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          language: action.filters,
        },
      };
    case SOCIAL_STREAM_CREATE_FILTER_SENTIMENT:
      return {
        ...state,
        filters: {
          ...state.filters,
          sentiment: action.filters,
        },
      };
    case SOCIAL_STREAM_CREATE_FILTER_FOLLOWER_COUNT:
      return {
        ...state,
        filters: {
          ...state.filters,
          twitterFollowers: action.filters,
        },
      };

    case ADD_KEYWORD: {
      const { criteriaKey, keywordName } = action.payload;
      const newKeywords = [...state.keywords[criteriaKey], keywordName];
      return {
        ...state,
        keywords: {
          ...state.keywords,
          [criteriaKey]: newKeywords,
        },
      };
    }
    case DELETE_KEYWORD: {
      const { criteriaKey, keywordName } = action.payload;
      const updatedKeywords = state.keywords[criteriaKey].filter(
        keyword => keyword !== keywordName
      );
      return {
        ...state,
        keywords: {
          ...state.keywords,
          [criteriaKey]: updatedKeywords,
        },
      };
    }
    case SHOW_KEYWORD_ERROR: {
      const { criteriaKey, errorMessage } = action.payload;
      return {
        ...state,
        keywords: { ...state.keywords, [`${criteriaKey}Error`]: errorMessage },
      };
    }
    case CLEAN_UP_ALL_KEYWORD_ERRORS: {
      return {
        ...state,
        keywords: {
          ...state.keywords,
          [`${STREAM_KEYWORDS_CRITERIAS_KEYS.mustCriteria}Error`]: '',
          [`${STREAM_KEYWORDS_CRITERIAS_KEYS.anyCriteria}Error`]: '',
          [`${STREAM_KEYWORDS_CRITERIAS_KEYS.notCriteria}Error`]: '',
        },
      };
    }

    case SET_ACTIVE_KEYWORD_CONTAINER_ID: {
      const { activeKeywordContainerId } = action.payload;
      return {
        ...state,
        activeKeywordContainerId: activeKeywordContainerId,
      };
    }

    case APPLY_QUERY_BUILDER: {
      return { ...state, ...action.payload };
    }

    case TAKE_QUERY_SNAPSHOT: {
      return {
        ...state,
        querySnapshot: state,
      };
    }

    case SET_ADVANCED_QUERY: {
      return {
        ...state,
        advancedQuery: action.payload,
      };
    }

    case SET_IS_VALID_SEARCH:
      return {
        ...state,
        isValidSearch: action.payload,
      };

    case ALLOW_SAVE_QUERY: {
      return { ...state, allowSaveQuery: Boolean(action.payload) };
    }

    case SET_MAX_MENTIONS: {
      return {
        ...state,
        maxMentions: action.payload,
      };
    }

    case SET_MENTION_COUNT: {
      return {
        ...state,
        mentionCount: action.payload,
      };
    }

    case SET_SOCIAL_SEARCH_BUILDER_DATA: {
      const {
        userId,
        keywords,
        advancedQuery,
        shared,
        viewOnly,
      } = action.payload;
      return {
        ...state,
        userId,
        advancedQuery,
        shared,
        viewOnly,
        keywords: {
          ...state.keywords,
          ...keywords,
        },
      };
    }

    default:
      return state;
  }
};

export default keywordStreamReducer;
