import React from 'react';

import { Icon, Button } from '@cision/rover-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { DisplaySM } from '@trendkite/ui';

import {
  CHART_TYPES_MAP,
  WIZARD_STEPS,
  METRICS,
} from 'components/widgetWizard/constants';
import styles from 'components/widgetWizard/steps/steps.module.css';
import { setCurrentlyEditedMetricActionCreator } from 'reducers/widget-form';

import {
  getMetricLabel,
  metricIsMissingDimension,
  isTagMetric,
} from '../utils';

import Header from './components/header';
import messages from './section-wizard.messages';

const VisualizationTypeV3 = ({
  intl,
  primaryMetric,
  secondaryMetric,
  nextStepCallback,
}) => {
  const dispatch = useDispatch();

  const getSelectedChartDetails = visualizationType => {
    const { icon, label } = CHART_TYPES_MAP(intl).find(
      chart => chart.visualizationType === visualizationType,
    );
    const metricDetails = { chartIcon: icon, chartName: label };
    return metricDetails;
  };

  const selectMetricButton = (disabled, onClick = () => {}) => {
    return (
      <div className={styles.chartSectionWizard}>
        <DisplaySM style={{ fontSize: '12px', marginBottom: '20px' }}>
          {intl.formatMessage(messages.labelForChartTypeButton, {
            METRIC_ID: 1,
            METRIC_LABEL: intl.formatMessage(messages.subTitleNoChartTypeLabel),
          })}
        </DisplaySM>
        <Button
          data-qa="GC5vH7zU0tuK2L0pecAzU"
          disabled={disabled}
          style={{ display: 'flex', alignItems: 'center' }}
          modifiers={['round', 'secondary']}
          onClick={onClick}
          size="md"
          tag="button"
        >
          <Button.Addon style={{ display: 'flex' }}>
            <Icon
              width={24}
              height={24}
              fill={disabled ? '#cccfd2' : '#0092c2'}
              name="arrow-forward"
              modifiers={['right']}
            />
          </Button.Addon>
          {intl.formatMessage(messages.labelButtonChartTypeSection)}
        </Button>
      </div>
    );
  };

  const visualizationButtonForMetric = (metric, counter, metricKey) => {
    const label = intl.formatMessage(messages.labelForChartTypeButton, {
      METRIC_ID: counter,
      METRIC_LABEL: getMetricLabel(metric.measure, intl),
    });

    const visualizationType = metric.visualizationType;

    const onClick = () => {
      nextStepCallback(
        WIZARD_STEPS.selectChartType,
        { METRIC_ID: counter },
        true,
      );
      dispatch(setCurrentlyEditedMetricActionCreator(metricKey));
    };

    if (visualizationType) {
      const { chartIcon, chartName } = getSelectedChartDetails(
        visualizationType,
      );
      const buttonSize = chartIcon ? 'md' : 'lg';
      return (
        <div key={metric.metric} className={styles.chartSectionWizard}>
          <DisplaySM style={{ fontSize: '12px', marginBottom: '20px' }}>
            {label}
          </DisplaySM>
          <Button
            data-qa="B9JikbNRDkvbZl5O0_1KL"
            style={{ display: 'flex', alignItems: 'center' }}
            modifiers={['round', 'secondary']}
            onClick={() => onClick()}
            size={buttonSize}
            tag="button"
          >
            {chartIcon && (
              <Button.Addon style={{ display: 'flex' }}>
                <Icon
                  width={24}
                  height={24}
                  fill="#43BFB7"
                  name={chartIcon}
                  modifiers={['right']}
                />
              </Button.Addon>
            )}
            <span>
              {chartName ||
                intl.formatMessage(messages.labelButtonChartTypeSection)}
            </span>
          </Button>
        </div>
      );
    } else {
      return selectMetricButton(
        metricIsMissingDimension(metric) && !isTagMetric(metric),
        onClick,
      );
    }
  };

  return (
    <div className={styles.sectionWrapper}>
      <Header title={intl.formatMessage(messages.titleChartSection)} />
      {!primaryMetric && !secondaryMetric && selectMetricButton(true)}
      {primaryMetric && (
        <div>
          {visualizationButtonForMetric(
            primaryMetric,
            1,
            METRICS.primaryMetric,
          )}
        </div>
      )}
      {secondaryMetric && (
        <div>
          {visualizationButtonForMetric(
            secondaryMetric,
            2,
            METRICS.secondaryMetric,
          )}
        </div>
      )}
    </div>
  );
};

VisualizationTypeV3.propTypes = {
  intl: PropTypes.object.isRequired,
  primaryMetric: PropTypes.object,
  secondaryMetric: PropTypes.object,
  nextStepCallback: PropTypes.func.isRequired,
};

VisualizationTypeV3.defaultProps = {
  primaryMetric: null,
  secondaryMetric: null,
};

export default VisualizationTypeV3;
