import omit from 'lodash/omit';
import qs from 'qs';

import {
  BASIC_EARNED_SEARCH_URL,
  ADVANCED_EARNED_SEARCH_URL,
  SIMPLE_KEYWORD_FEED_SEARCH_BUILDER_URL,
  DEV_FEATURES,
} from 'constants/constants';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';

export const getParsedUrl = url => {
  // This is a hack to parse a URL string
  const parser = document.createElement('a');
  parser.href = url;
  return {
    protocol: parser.protocol,
    host: parser.host,
    pathname: parser.pathname,
    search: parser.search,
    hash: parser.hash,
    origin: `${parser.protocol}//${parser.host}`,
  };
};

const getArticleLinkForSeqId = function (seqId) {
  if (seqId === undefined || seqId === '') {
    return undefined;
  }

  return `${window.location.origin}/redir?s=${seqId}`;
};

const getCacheBustedUrl = url => {
  const nowMillis = new Date().getTime();
  const { protocol, host, pathname, search, hash } = getParsedUrl(url);

  const newSearch = search
    ? `${search}&ignore=${nowMillis}`
    : `?ignore=${nowMillis}`;
  return `${protocol}//${host}${pathname}${newSearch}${hash}`;
};

const getCacheBustedUrlForCM = url => {
  //check for a bug Critical Mention had where they were forgetting the slash after their port number.
  //We should remove this method and use getCacheBustedUrl() when CM fixes their bug - Josh (Jan 4, 2019)
  const cleanURL = url.replace(':443app', ':443/app');
  return getCacheBustedUrl(cleanURL);
};

const getFrontEndAssetPath = secure => {
  const { hostname, protocol } = window.location;
  const port = window.location.port ? `:${window.location.port}` : '';
  const uiEndPoint =
    window.PAGE_DATA && window.PAGE_DATA.uiEndPoint
      ? window.PAGE_DATA.uiEndPoint.slice(0, -1)
      : ''; // Remove trailing slash
  const frontEndVersion = process.argv.uiVersion || '';

  if (uiEndPoint && frontEndVersion) {
    return `https:${uiEndPoint}-bundles/${frontEndVersion}/`;
  }

  if (secure) {
    return `https://${hostname}${port}/`;
  }

  return `${protocol}//${hostname}${port}/`;
};

export const getQueryStringWithoutParams = (queryString, paramsArray) => {
  const newQueryObj = omit(
    qs.parse(queryString, { ignoreQueryPrefix: true }),
    paramsArray,
  );
  const hasPrefix = queryString.charAt(0) === '?';

  return qs.stringify(newQueryObj, { addQueryPrefix: hasPrefix });
};

const getUrlWithoutHashParams = (url, paramsArray) => {
  const { protocol, host, pathname, search, hash } = getParsedUrl(url);
  let newHash = hash;

  if (newHash.indexOf('?') >= 0 && Array.isArray(paramsArray)) {
    const newHashBeforeQuery = newHash.substring(0, newHash.indexOf('?'));
    const newHashAfterQuery = getQueryStringWithoutParams(
      newHash.substring(newHash.indexOf('?')),
      paramsArray,
    );
    newHash = `${newHashBeforeQuery}${newHashAfterQuery}`;
  }

  return `${protocol}//${host}${pathname}${search}${newHash}`;
};

export const removeSensitiveParamsFromUrl = ({ history, location, params }) => {
  if (history && location) {
    const myNewSearch = getQueryStringWithoutParams(location.search, params);
    history.replace(`${location.pathname}${myNewSearch}`);
  } else {
    const myNewUrl = getUrlWithoutHashParams(window.location.href, params);
    window.history.replaceState('Clean URL', '', myNewUrl);
  }
};

const normalizeLink = function (url) {
  const URL_SCHEME_REGEXP = /^((?:f|ht)tps?:)?\/\//;
  const DEFAULT_SCHEME = 'https';

  if (!URL_SCHEME_REGEXP.test(url)) {
    url = DEFAULT_SCHEME ? `${DEFAULT_SCHEME}://${url}` : `//${url}`;
  }

  return url;
};

export const getUrlWithPath = path => {
  return `${window.location.protocol}//${window.location.host}/#${path}`;
};

export const getEditSearchUrlForEarnedSearch = (searchId, isSimple) => {
  let editSearchUrl = `${window.location.protocol}//${window.location.host}/search#/edit/${searchId}`;
  if (userHasDevFeatureFlag(DEV_FEATURES.simpleEarnedSearch) && isSimple) {
    editSearchUrl = `${window.location.protocol}//${window.location.host}${BASIC_EARNED_SEARCH_URL}/${searchId}`;
  } else if (
    userHasDevFeatureFlag(DEV_FEATURES.advancedEarnedSearch) &&
    !isSimple
  ) {
    editSearchUrl = `${window.location.protocol}//${window.location.host}${ADVANCED_EARNED_SEARCH_URL}/${searchId}`;
  }
  return editSearchUrl;
};

export const getEditSearchUrl = ({
  searchId,
  isSimpleSearch,
  haveSocialSearchData,
}) => {
  let editSearchUrl;

  if (haveSocialSearchData) {
    /*
    socialSearchData should contain the search type (profile, simple keywords or advanced)
    to determine the correct social search editor and redirect users to it
    */
    editSearchUrl = `${window.location.protocol}//${window.location.host}${SIMPLE_KEYWORD_FEED_SEARCH_BUILDER_URL}/${searchId}`;
  } else {
    editSearchUrl = getEditSearchUrlForEarnedSearch(searchId, isSimpleSearch);
  }
  return editSearchUrl;
};

export {
  getArticleLinkForSeqId,
  getCacheBustedUrl,
  getCacheBustedUrlForCM,
  getFrontEndAssetPath,
  getUrlWithoutHashParams,
  normalizeLink,
};
