export default {
  title: {
    id: 'STORY-VIDEO-DISPLAY_TITLE',
    defaultMessage: 'Related Videos',
    description:
      'Campaigns > Story Kit > Story Edit Page: content related videos title',
  },
  addVideo: {
    id: 'STORY-VIDEO-DISPLAY_ADD_VIDEO',
    defaultMessage: 'Add Videos',
    description:
      'Campaigns > Story Kit > Story Edit Page: content related videos add video link',
  },
  placeholder: {
    id: 'STORY-VIDEO-DISPLAY_INCLUDE_ALL_VIDEOS_PLACEHOLDER',
    defaultMessage: 'Include all of the videos related to this story',
    description:
      'Campaigns > Story Kit > Story Edit Page: content related videos include all videos placeholder',
  },
};
