import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions, Control } from 'react-redux-form';

import { Body, WysiwygEditor } from '@trendkite/ui';

const formats = [
  'bold',
  'italic',
  'underline',
  'align',
  'list',
  'color',
  'size',
  'font',
  'link',
  'blockquote',
  'placeholder',
  'templateString',
  'templateLink',
  'image',
];

const mapDispatchToProps = dispatch => ({
  setPristine: model => dispatch(actions.setPristine(model)),
});

class EmailBodyControl extends Component {
  focus() {
    if (this.editor && this.editor.focus) {
      this.editor.focus();
    }
  }

  render() {
    const {
      model,
      scrollingContainer,
      toolbarId,
      setPristine,
      placeholder,
    } = this.props;

    return (
      <div className="tk-email-body-control">
        <Body block>
          <Control.text
            model={model}
            component={WysiwygEditor}
            toolbarId={toolbarId}
            formats={formats}
            getRef={r => (this.editor = r)}
            controlProps={{
              focus: true,
              focusCursorAtEnd: false,
              scrollingContainer,
              setPristine: () => {
                setPristine(model);
              },
              placeholder,
            }}
          />
        </Body>
      </div>
    );
  }
}

EmailBodyControl.propTypes = {
  model: PropTypes.string.isRequired,
  scrollingContainer: PropTypes.node,
  setPristine: PropTypes.func.isRequired,
  toolbarId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

EmailBodyControl.defaultProps = {
  scrollingContainer: null,
  placeholder: '',
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  EmailBodyControl,
);
