import React from 'react';

import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { DisplayXL } from '@trendkite/ui';

// i18n
import messages from './StoryHeadlineDisplay.messages';

const StoryHeadlineDisplay = ({ isEditable, value, errors }) => {
  const baseClass = 'tk-story-headline-display';

  const textColor = value ? 'dark' : 'disabled';
  const textWeight = value ? 'normal' : 'light';

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__text`}>
        <DisplayXL color={textColor} italic={!value} weight={textWeight}>
          {value ||
            (isEditable && (
              <TranslatedMessage {...messages.contentTitlePlaceholder} />
            ))}
        </DisplayXL>
      </div>
    </div>
  );
};

StoryHeadlineDisplay.propTypes = {
  value: PropTypes.string,
  isEditable: PropTypes.bool,
  errors: PropTypes.shape({
    isRequired: PropTypes.bool,
  }),
};

StoryHeadlineDisplay.defaultProps = {
  value: null,
  isEditable: false,
  errors: null,
};

export default StoryHeadlineDisplay;
