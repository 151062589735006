import { NEW_DASHBOARD_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

import { AnalyticsListActionCreatorArgs } from '../../analytics-config';

export const LIST_SUMMARY_CAMPAIGNS_ANALYTICS =
  'analytics/LIST_SUMMARY_CAMPAIGNS_ANALYTICS';
export const LIST_SUMMARY_CAMPAIGNS_ANALYTICS_SUCCESS =
  'analytics/LIST_SUMMARY_CAMPAIGNS_ANALYTICS_SUCCESS';
export const LIST_SUMMARY_CAMPAIGNS_ANALYTICS_ERROR =
  'analytics/LIST_SUMMARY_CAMPAIGNS_ANALYTICS_ERROR';

export const TOGGLE_SUMMARY_CAMPAIGN_SELECTION =
  'analytics/TOGGLE_SUMMARY_CAMPAIGN_SELECTION';
export const CLEAR_SUMMARY_CAMPAIGN_SELECTION =
  'analytics/CLEAR_SUMMARY_CAMPAIGN_SELECTION';

export const initialState = {
  error: false,
  loading: false,
  summaryCampaigns: [],
  campaignsSelected: [] as number[],
};

const campaignAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SUMMARY_CAMPAIGNS_ANALYTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LIST_SUMMARY_CAMPAIGNS_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        summaryCampaigns: action.payload,
      };
    case LIST_SUMMARY_CAMPAIGNS_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case TOGGLE_SUMMARY_CAMPAIGN_SELECTION: {
      const currentCampaigns = new Set(state.campaignsSelected);
      const id = action.payload.id;
      return {
        ...state,
        campaignsSelected: currentCampaigns.has(id) ? [] : [id],
      };
    }
    case CLEAR_SUMMARY_CAMPAIGN_SELECTION:
      return {
        ...state,
        campaignsSelected: [],
      };
    default:
      return state;
  }
};

export const campaignAnalyticsActionCreator = ({
  endpointBase = NEW_DASHBOARD_ENDPOINT,
}: AnalyticsListActionCreatorArgs = {}) => async dispatch => {
  dispatch({ type: LIST_SUMMARY_CAMPAIGNS_ANALYTICS });

  try {
    const response = await performGet(`${endpointBase}/campaign-count`);
    dispatch({
      type: LIST_SUMMARY_CAMPAIGNS_ANALYTICS_SUCCESS,
      payload: response.data.campaignCount,
    });
  } catch (e) {
    dispatch({
      type: LIST_SUMMARY_CAMPAIGNS_ANALYTICS_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const toggleAnalyticCampaignSelection = id => ({
  type: TOGGLE_SUMMARY_CAMPAIGN_SELECTION,
  payload: { id },
});

export const clearAnalyticsCampaignSelection = () => ({
  type: CLEAR_SUMMARY_CAMPAIGN_SELECTION,
});

export default campaignAnalyticReducer;
