import React from 'react';
import PropTypes from 'prop-types';
import withModifiers from '../withModifiers';

const TemplateString = ({ children, className }) => {
  return (
    <div
      className={`${TemplateString.baseClass} ${className}`}
      contentEditable={false}
    >
      {children}
    </div>
  );
};

TemplateString.baseClass = 'tk-template-string';

TemplateString.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TemplateString.defaultProps = {
  className: '',
};

export default withModifiers(TemplateString);
