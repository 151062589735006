import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const ChartSparkline = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" />
  </svg>
);
ChartSparkline.propTypes = { fill: PropTypes.string };
ChartSparkline.defaultProps = { fill: iconColorsMap.mainicon };

export default ChartSparkline;
