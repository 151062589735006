import React from 'react';
import styles from './browseModuleUpload.module.css';
import messages from './assets-library-modals.messages';
import { Button, Checkbox, Input, SvgIcon } from '@trendkite/ui';
import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';
class MediaBrowseAssetsFromUrl extends React.Component {
  render() {
    const {
      checkboxForUploadFromUrl,
      imageUrlsForUploadScreen,
      setImageUrl,
    } = this.props;
    return (
      <div className={styles.dummyheight}>
        <div className={styles.browseFromUrlHeader}>
          <p className={styles.browseFromUrlHeaderText}>
            <T {...messages.mediaUploadFromUrl} />
          </p>
        </div>
        <div className={styles.uploadFromUrlInsertSection}>
          <p className={styles.enterUrlHeading}>
            <T {...messages.mediaStudioEnterUrlHeading} />
          </p>
          <div className={styles.imageUrlSection}>
            <Input
              placeholder="http://imageUrl.com"
              type="text"
              value={setImageUrl}
              data-qa="input-search-id"
              className={styles.getImageInputtext}
              onChange={e => this.props.handleChangeForInput(e.target.value)}
            />
            <Button
              modifiers={['round', 'primary']}
              className={styles.getImageInputButton}
              onClick={() => this.props.getImageFromUrl()}
            >
              <T {...messages.mediaStudioUploadFromUrlGetImage} />
            </Button>
          </div>
        </div>
        <div
          className={styles.row}
          style={{ paddingLeft: '20px', overflow: 'scroll', height: '245px' }}
        >
          {imageUrlsForUploadScreen &&
            imageUrlsForUploadScreen.length > 0 &&
            imageUrlsForUploadScreen.map(file => {
              return (
                <div
                  className={styles.thumbnailContainerForUploadFromComputer}
                  key={file.id}
                >
                  <div className={styles.hoverMenuForUploadFromComputer}>
                    <SvgIcon
                      icon="deleteIcon"
                      width={24}
                      height={24}
                      modifiers={['block']}
                      className={styles.uploadFromUrlDeleteIcon}
                      onClick={() =>
                        this.props.deleteAssetFromUrlScreen(file.id)
                      }
                    />
                    <label>
                      <Checkbox
                        className={styles.selectedAsset}
                        selected={file.checked}
                      />
                    </label>
                  </div>
                  <div className={styles.imageContainer}>
                    <img
                      className={styles.imgHolderForUploadFromUrl}
                      src={file.url}
                      onError={() => this.props.onImageLoadError()}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={styles.uploadFromComputerMediaStudioSection}>
          <p className={styles.UploadFromComputerCheckboxSection}>
            <label>
              <Checkbox
                onClick={() => this.props.toggleMsCheckboxForUploadFromUrl()}
                selected={checkboxForUploadFromUrl}
              />
              <span className={styles.UploadFromComputerCheckboxText}>
                <T {...messages.mediaStudioUploadFromComputerCheckboxText} />
              </span>
            </label>
            <span
              className={styles.uploadFromComputerCheckboxTextLearnMore}
              onClick={() => this.props.toggleDisclaimerDiv()}
            >
              <T
                {...messages.mediaStudioUploadFromComputerCheckboxTextLearnMore}
              />
            </span>
          </p>
          <p className={styles.uploadFromComputerAgrementText}>
            <T {...messages.mediaStudioUploadFromComputerAgrementText} />
          </p>
        </div>
      </div>
    );
  }
}
MediaBrowseAssetsFromUrl.propTypes = {
  checkboxForUploadFromUrl: PropTypes.bool,
  toggleMsCheckboxForUploadFromUrl: PropTypes.func,
  onImageLoadError: PropTypes.func,
  handleChangeForInput: PropTypes.func,
  getImageFromUrl: PropTypes.func,
  deleteAssetFromUrlScreen: PropTypes.func,
  imageUrlsForUploadScreen: PropTypes.array,
  setImageUrl: PropTypes.string,
  toggleDisclaimerDiv: PropTypes.func,
};

MediaBrowseAssetsFromUrl.defaultProps = {
  checkboxForUploadFromUrl: false,
  toggleMsCheckboxForUploadFromUrl: () => {},
  onImageLoadError: () => {},
  handleChangeForInput: () => {},
  getImageFromUrl: () => {},
  deleteAssetFromUrlScreen: () => {},
  imageUrlsForUploadScreen: [],
  setImageUrl: '',
  toggleDisclaimerDiv: () => {},
};
export default MediaBrowseAssetsFromUrl;
