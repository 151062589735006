import {
  REPORT_LIST_ENDPOINT,
  REPORT_PRESENTATION_ENDPOINT,
  REPORT_PRESENTATION_DASHBOARD_ENDPOINT,
  NEW_REPORT_ENDPOINT,
} from 'constants/apis';
import { DEV_FEATURES } from 'constants/constants';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';

import { performGet } from 'services/rest-service/rest-service';

export const GET_REPORT_LIST = 'reports/GET_REPORT_LIST';
export const GET_REPORT_LIST_RECEIVED = 'reports/GET_REPORT_LIST_RECEIVED';
export const GET_REPORT_LIST_ERROR = 'reports/GET_REPORT_LIST_ERROR';
export const GET_REPORT = 'reports/GET_REPORT';
export const GET_REPORT_ERROR = 'reports/GET_REPORT_ERROR';
export const GET_REPORT_RECEIVED = 'reports/GET_REPORT_RECEIVED';
export const SET_REPORT_DEFAULT_WIDGET_TITLE =
  'reports/SET_REPORT_DEFAULT_WIDGET_TITLE';
export const GET_REPORT_DASHBOARD = 'reports/GET_REPORT_DASHBOARD';
export const GET_REPORT_DASHBOARD_ERROR = 'reports/GET_REPORT_DASHBOARD_ERROR';
export const GET_REPORT_DASHBOARD_RECEIVED =
  'reports/GET_REPORT_DASHBOARD_RECEIVED';

export const initialState = {
  error: false,
  loading: true,
  reports: [],
  currentReport: {
    loading: false,
    error: false,
    report: null,
    defaultWidgetTitle: null,
  },
  currentReportDashboard: {
    loading: false,
    error: false,
    dashboard: null,
  },
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT_LIST_RECEIVED:
      return {
        ...state,
        error: false,
        loading: false,
        reports: action.payload,
      };
    case GET_REPORT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_REPORT:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          report: null,
          loading: true,
          error: false,
        },
      };
    case GET_REPORT_ERROR:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          report: null,
          loading: false,
          error: true,
        },
      };
    case GET_REPORT_RECEIVED:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          report: action.payload,
          loading: false,
          error: false,
        },
      };
    case GET_REPORT_DASHBOARD:
      return {
        ...state,
        currentReportDashboard: {
          dashboard: null,
          loading: true,
          error: false,
        },
      };
    case GET_REPORT_DASHBOARD_ERROR:
      return {
        ...state,
        currentReportDashboard: {
          dashboard: null,
          loading: false,
          error: true,
        },
      };
    case GET_REPORT_DASHBOARD_RECEIVED:
      return {
        ...state,
        currentReportDashboard: {
          dashboard: action.payload,
          loading: false,
          error: false,
        },
      };
    case SET_REPORT_DEFAULT_WIDGET_TITLE:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          defaultWidgetTitle: action.payload,
        },
      };
    default:
      return state;
  }
};

export const getReports = () => async dispatch => {
  dispatch({ type: GET_REPORT_LIST });
  try {
    const reportsV1 = await performGet(`${REPORT_LIST_ENDPOINT}`);

    const datareportV1 = reportsV1.data || [];

    const dataV1 = datareportV1.map(reportV1 => {
      return {
        ...reportV1,
        svgIcon: 'reports',
        type: 'reports',
        version: 1,
      };
    });

    let dataV2 = [];
    if (userHasDevFeatureFlag(DEV_FEATURES.newReports)) {
      const reportV2 = await performGet(`${NEW_REPORT_ENDPOINT}`, {
        notPaginate: true,
      });

      const { results: datareportV2 } = reportV2.data;

      dataV2 = datareportV2.map(reportv2 => {
        return {
          ...reportv2,
          svgIcon: 'reportSlideIcon',
          type: 'reports',
          version: 2,
        };
      });
    }

    const dataReports = [...dataV1, ...dataV2];

    dispatch({
      type: GET_REPORT_LIST_RECEIVED,
      payload: dataReports,
    });
  } catch (e) {
    dispatch({ type: GET_REPORT_LIST_ERROR });
  }
};

export const getReport = uuid => dispatch => {
  if (!uuid) {
    dispatch({
      type: SET_REPORT_DEFAULT_WIDGET_TITLE,
      payload: 'Report Articles',
    });
  } else {
    dispatch({ type: GET_REPORT });
    performGet(`${REPORT_PRESENTATION_ENDPOINT}/${uuid}`)
      .then(response => {
        dispatch({
          type: GET_REPORT_RECEIVED,
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: GET_REPORT_ERROR });
      });
  }
};

export const getDashboardForReport = uuid => dispatch => {
  dispatch({ type: GET_REPORT_DASHBOARD });
  performGet(`${REPORT_PRESENTATION_DASHBOARD_ENDPOINT}/${uuid}`)
    .then(response => {
      dispatch({
        type: GET_REPORT_DASHBOARD_RECEIVED,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_REPORT_DASHBOARD_ERROR });
    });
};

export default reportsReducer;
