import PropTypes from 'prop-types';

export const propTypes = PropTypes.shape({
  offset: PropTypes.number,
  limit: PropTypes.number,
});

export const defaultProps = {
  offset: 0,
  limit: 25,
};

export default {
  propTypes,
  defaultProps,
};
