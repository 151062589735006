import {
  GET_EARNED_SEARCH_DETAIL_RECEIVED,
  GET_EARNED_SEARCH_DETAIL_ERROR,
  CLEAR_EARNED_SEARCHES_TOOLTIP_DATA,
} from 'pages/EarnedSearch/state/actions/searchesActions';

import { ActionType, SearchState } from '../types/searches';

const initialState: SearchState = {};

function searchesReducer(
  state: SearchState = initialState,
  action: ActionType,
): SearchState {
  const { payload, type } = action;

  switch (type) {
    case GET_EARNED_SEARCH_DETAIL_RECEIVED:
      return {
        ...state,
        ...payload,
      };
    case GET_EARNED_SEARCH_DETAIL_ERROR:
      return {
        ...state,
        ...payload,
      };

    case CLEAR_EARNED_SEARCHES_TOOLTIP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default searchesReducer;
