import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '../type';
import './secondary-menu.scss';

const SecondaryMenu = ({ children }) => (
  <div className="page-header__secondary-menu">
    <Body color="inverseLight" block>
      <div className="page-header__secondary-menu-content">{children}</div>
    </Body>
  </div>
);

SecondaryMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SecondaryMenu;
