import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

const StoryInsightsHead = ({ children, className, style }) => {
  const mainClass = classnames(StoryInsightsHead.baseClass, className);

  return (
    <div className={mainClass} style={style}>
      {children}
    </div>
  );
};

StoryInsightsHead.baseClass = 'story-insights-head';

StoryInsightsHead.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

StoryInsightsHead.defaultProps = {
  className: '',
  style: {},
};

export default StoryInsightsHead;
