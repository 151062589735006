import moment from 'moment';

import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { FEATURES } from 'constants/constants';
import { userHasFeatureFlag } from 'services/feature-service/feature-service';
import { performGet } from 'services/rest-service/rest-service';

import { performGet as mockPerformGet } from '../mockEndpoints';

import { influencerIsOnlySocial, influencerIsOutlet } from '../utils';

import articleReducerFactory from './articles';
import { MINI_CARD_VIEW } from './constants';
import { addRecentInfluencer, triggerInfluencerViewRequest } from './recent';
import tweetsReducerFactory from './tweets';

export const {
  articlesReducer,
  GET_ARTICLES_START,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_ERROR,
  getRecentArticlesByInfluencerId,
} = articleReducerFactory('PREVIEW');

export const {
  tweetsReducer,
  GET_TWEETS_START,
  GET_TWEETS_SUCCESS,
  GET_TWEETS_ERROR,
  getRecentTweetsForInfluencer,
} = tweetsReducerFactory('PREVIEW');

const INFLUENCER_PREVIEW_START = 'ihub/INFLUENCER_PREVIEW_START';
const INFLUENCER_PREVIEW_SUCCESS = 'ihub/INFLUENCER_PREVIEW_SUCCESS';
const INFLUENCER_PREVIEW_ERROR = 'ihub/INFLUENCER_PREVIEW_ERROR';
const INFLUENCER_PREVIEW_BY_ID_START = 'ihub/INFLUENCER_PREVIEW_BY_ID_START';
const INFLUENCER_PREVIEW_BY_ID_SUCCESS =
  'ihub/INFLUENCER_PREVIEW_BY_ID_SUCCESS';
const INFLUENCER_PREVIEW_BY_ID_ERROR = 'ihub/INFLUENCER_PREVIEW_BY_ID_ERROR';

const initialState = {
  loading: false,
  error: false,
  activeId: '',
  info: {},
  tweets: {},
  articles: {},
  notes: {},
};

const influencerPreviewReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case INFLUENCER_PREVIEW_START: {
      return {
        ...state,
        loading: true,
        error: false,
        activeId: action.payload,
        info: {},
        tweets: {},
        articles: {},
      };
    }

    case INFLUENCER_PREVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        info: {
          ...action.payload,
        },
      };
    }

    case INFLUENCER_PREVIEW_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case INFLUENCER_PREVIEW_BY_ID_START: {
      return {
        ...state,
        loading: true,
        error: false,
        activeId: action.payload,
        info: {},
        tweets: {},
        articles: {},
      };
    }

    case INFLUENCER_PREVIEW_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        info: {
          ...action.payload,
        },
      };
    }

    case INFLUENCER_PREVIEW_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case GET_TWEETS_START:
    case GET_TWEETS_SUCCESS:
    case GET_TWEETS_ERROR:
      return {
        ...state,
        tweets: tweetsReducer(state.tweets, action),
      };

    case GET_ARTICLES_START:
    case GET_ARTICLES_SUCCESS:
    case GET_ARTICLES_ERROR:
      return {
        ...state,
        articles: articlesReducer(state.articles, action),
      };
    default:
      return state;
  }
};

const getConnectPreview = ({
  influencer,
  searchId,
  startDate,
  endDate,
}) => dispatch => {
  const isOnlySocial = influencerIsOnlySocial(influencer);
  const { id } = influencer;
  if (!isOnlySocial && id) {
    const isOutlet = influencerIsOutlet(influencer);

    const nowMoment = moment();
    const paramEndDate = endDate || nowMoment.endOf('day').valueOf();
    const paramStartDate =
      startDate || nowMoment.subtract(90, 'days').startOf('day').valueOf();
    const params = {
      isOutlet,
      pageSize: 5,
      startDate: paramStartDate,
      endDate: paramEndDate,
      searchId: searchId || undefined,
    };
    dispatch(getRecentArticlesByInfluencerId(id, params));
  }

  dispatch(getRecentTweetsForInfluencer(influencer, 5));
};

export const getPreviewActionDispatcher = ({
  influencer,
  includeConnectForInfluencer = true,
  ...params
}) => dispatch => {
  dispatch({ type: INFLUENCER_PREVIEW_START, payload: influencer.id });

  if (influencer) {
    influencer = {
      ...influencer,
      profileImage: influencer.profileImage || '',
      image: influencer.image || '',
    };
  }

  // TODO: load other areas of the influencer preview
  dispatch({ type: INFLUENCER_PREVIEW_SUCCESS, payload: influencer });
  dispatch(addRecentInfluencer(influencer));
  if (
    !userHasFeatureFlag(FEATURES.connectBasic) &&
    includeConnectForInfluencer
  ) {
    dispatch(getConnectPreview({ influencer, ...params }));
  }
};

export const getPreviewActionByIdDispatcher = ({
  id,
  includeConnectForInfluencer = true,
  ...params
}) => async dispatch => {
  dispatch({ type: INFLUENCER_PREVIEW_BY_ID_START, payload: id });

  try {
    let response;

    if (id.indexOf('mock-') === 0) {
      // For mock influencer IDs, use local data
      response = await mockPerformGet(`/ihub/influencer/${id}`);
    } else {
      response = await performGet(
        `${INFLUENCER_HUB_ENDPOINT}/influencer/${id}`,
      );
      response = response.data;
    }

    let payload = response && response.data;

    if (payload) {
      payload = {
        ...payload,
        profileImage: payload.profileImage || '',
        image: payload.image || '',
      };
    }

    dispatch({ type: INFLUENCER_PREVIEW_BY_ID_SUCCESS, payload });
    dispatch(addRecentInfluencer(payload));
    dispatch(
      triggerInfluencerViewRequest({
        influencer: payload,
        viewType: MINI_CARD_VIEW,
      }),
    );
    if (
      !userHasFeatureFlag(FEATURES.connectBasic) &&
      includeConnectForInfluencer
    ) {
      dispatch(getConnectPreview({ influencer: payload, ...params }));
    }
  } catch (err) {
    dispatch({
      type: INFLUENCER_PREVIEW_BY_ID_ERROR,
      error: { message: err },
    });
  }
};

export default influencerPreviewReducer;
