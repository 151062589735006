import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Button, SvgIcon, Addon, DisplaySM, Dropdown } from '@trendkite/ui';

import MenuItemConditionalTooltip from './menu/menu-item-conditional-tooltip';

class UniversalNavMenuItem extends Component {
  static baseClass = 'universal-nav-menu';
  state = { isOpen: false };

  UNSAFE_componentWillMount() {
    // TODO: Cleanup URL generation for menu items to have correct TLD context - mdespuits (6/3/2019)
    this.urlBase = '';
    if (window.appUrlBase) {
      this.urlBase = window.appUrlBase;
    }
  }

  toggleIsOpen = () => {
    this.setState(previousState => ({ isOpen: !previousState.isOpen }));
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  renderSubOption = option => {
    if (option.isSubOptionHeader) {
      return (
        <div
          id={`${option.id}-sub-option-header`}
          key={option.id}
          className={`${UniversalNavMenuItem.baseClass}__option-header`}
        >
          {option.label}
        </div>
      );
    }

    const inNewTab = option.location.includes('http') || option.inNewTab;

    if (inNewTab) {
      return (
        <div
          key={option.id}
          className={`${UniversalNavMenuItem.baseClass}__option`}
        >
          <span
            className={`${UniversalNavMenuItem.baseClass}__option-text-wrapper`}
          >
            <a
              data-qa="AKHtRJGTUwTGMJLzr6y0T"
              id={option.id}
              href={option.location}
              onClick={this.close}
              target="_blank"
              rel="noopener noreferrer"
            >
              {option.label}
            </a>
          </span>
          <span
            className={`${UniversalNavMenuItem.baseClass}__option-icon-wrapper`}
          >
            <SvgIcon icon="new-tab" />
          </span>
        </div>
      );
    }
    // DO NOT CHANGE THIS CLASS NAME!!!!!!
    // JS magic in grails project - alerts-module.js
    let classes = [`${UniversalNavMenuItem.baseClass}__option`];
    if (option.isCurrentNav) {
      classes.push(`${UniversalNavMenuItem.baseClass}__option--current`);
    }
    classes = classes.join(' ');
    return (
      <div className={classes} key={option.id} id={option.id}>
        <span
          className={`${UniversalNavMenuItem.baseClass}__option-text-wrapper`}
        >
          <a
            data-qa="Xc8IeiT1E0nR6sMrcYfwx"
            href={`${this.urlBase}${option.location}`}
            onClick={this.close}
          >
            {option.label}
          </a>
        </span>
      </div>
    );
  };

  renderSubOptions = options => {
    return options
      .filter(option => !option.hide)
      .map(option => this.renderSubOption(option));
  };

  render() {
    const {
      id,
      inNewTab,
      isCurrentNav,
      label,
      subOptions,
      location,
      tooltip,
    } = this.props;

    const opensInNewTab = location.includes('http') || inNewTab;
    const hasOptions = !!subOptions.length;
    const menuOptions = this.renderSubOptions(subOptions);
    let isCurrentTopNav = false;
    if (subOptions.length) {
      isCurrentTopNav = !!subOptions.filter(option => option.isCurrentNav)
        .length;
    } else {
      isCurrentTopNav = isCurrentNav;
    }
    let listItemClasses = [`${UniversalNavMenuItem.baseClass}__item`];
    let dropdownClasses = [`${UniversalNavMenuItem.baseClass}__dropdown`];
    if (this.state.isOpen) {
      listItemClasses.push(`${UniversalNavMenuItem.baseClass}__item--open`);
      dropdownClasses.push(`${UniversalNavMenuItem.baseClass}__dropdown--open`);
    }
    listItemClasses = listItemClasses.join(' ');
    dropdownClasses = dropdownClasses.join(' ');

    const body = (
      <Button
        data-qa="P-ULsPrUCYIDK55PvMRCJ"
        modifiers={[
          'inline-flex',
          'margin-right',
          'margin-left',
          'seethrough-inverse-rebrand',
        ]}
        style={{ fontSize: '18px', height: '65px' }}
        onClick={this.toggleIsOpen}
        location={
          location && !subOptions.length ? `${this.urlBase}${location}` : null
        }
        active={this.state.isOpen || isCurrentTopNav}
        inNewTab={opensInNewTab}
      >
        <DisplaySM
          color={isCurrentTopNav ? 'inverse' : 'inherit'}
          weight={isCurrentTopNav ? 'bold' : null}
        >
          {label}
        </DisplaySM>
        {hasOptions && (
          <Addon>
            <SvgIcon
              height={10}
              width={10}
              icon={this.state.isOpen ? 'chevronUp' : 'chevronDown'}
            />
          </Addon>
        )}
      </Button>
    );

    return (
      <div className={listItemClasses} onMouseLeave={this.close}>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggleIsOpen}>
          <span
            // DO NOT CHANGE THIS CLASS NAME!!!!!!
            // JS magic in grails project - alerts-module.js
            className={`${UniversalNavMenuItem.baseClass}__button-wrapper`}
            id={`${id}-dropdown`}
          >
            <MenuItemConditionalTooltip tooltip={tooltip}>
              {body}
            </MenuItemConditionalTooltip>
            {hasOptions && <div className={dropdownClasses}>{menuOptions}</div>}
            {opensInNewTab && <SvgIcon icon="new-tab" />}
          </span>
        </Dropdown>
      </div>
    );
  }
}

UniversalNavMenuItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  inNewTab: PropTypes.bool,
  isCurrentNav: PropTypes.bool,
  label: PropTypes.node.isRequired,
  location: PropTypes.string,
  subOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      inNewTab: PropTypes.bool,
      isCurrentNav: PropTypes.bool,
      label: PropTypes.node,
      location: PropTypes.string,
      hide: PropTypes.bool,
    }),
  ),
  tooltip: PropTypes.node,
};

UniversalNavMenuItem.defaultProps = {
  isCurrentNav: false,
  location: '',
  subOptions: [],
};

export default UniversalNavMenuItem;
