export default {
  needsReauth: {
    id: 'SLACK_NEEDS_REAUTH',
    description:
      'Message indicating a slack integration needs to reauthorize itself',
    defaultMessage: 'This channel needs reauthorization',
  },
  deauthorizeAll: {
    id: 'SLACK_DEAUTHORIZE_ALL',
    description: 'Share to slack modal > Deauthorize All option',
    defaultMessage: 'Deauthorize All',
  },
  options: {
    id: 'SLACK_TOOLTIP_OPTIONS_KEBAB',
    description: 'Share to slack modal > Tooltip for the options kebab',
    defaultMessage: 'Options',
  },
  title: {
    id: 'SLACK_TITLE_DESCRIPTION',
    description: 'Share to slack modal > Title',
    defaultMessage: 'Share {ARTICLE_TYPE} to Slack',
  },
  description: {
    id: 'SLACK_MODAL_DESCRIPTION',
    description:
      'Share to slack modal > Description to let users know what they can do regarding to slack integrations',
    defaultMessage:
      'Please select the Slack channel or user you would like to share this article with.',
  },
  addNewChannel: {
    id: 'SLACK_ADD_NEW_CHANNEL',
    description: 'Share to slack modal > Add new channel option',
    defaultMessage: 'Add new channel',
  },
  shareTo: {
    id: 'SLACK_SHARE_TO_LABEL',
    description: 'Share to slack modal > Share to label',
    defaultMessage: 'Share to',
  },
  selectChannel: {
    id: 'SLACK_SELECT_CHANNEL',
    description: 'Share to slack modal > Selct channel option',
    defaultMessage: 'Select channel',
  },
  addComments: {
    id: 'SLACK_ADD_COMMENTS',
    description: 'Share to slack modal > Add comments (optional) label',
    defaultMessage: 'Add comments (optional)',
  },
  enterText: {
    id: 'SLACK_COMMENTS_INPUT_PLACEHOLDER',
    description: 'Share to slack modal > Comments input placeholder',
    defaultMessage: 'Enter Text',
  },
  articleSharedToSlack: {
    id: 'SLACK_ARTICLE_SHARED',
    description:
      'Share to slack modal > Kite message shows when an article was shared to slack succesfull',
    defaultMessage: 'Your article was shared to Slack',
  },
  articleSharedToSlackError: {
    id: 'SLACK_ARTICLE_SHARED_ERROR',
    description:
      'Share to slack modal > Kite message shows when an error ocurred sharing an article to slack',
    defaultMessage: 'There was a problem sharing your article to Slack',
  },
};
