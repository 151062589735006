import uniq from 'lodash/uniq';

import { CONTACT_LISTS_ENDPOINT } from 'constants/apis';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { performPut } from 'services/rest-service/rest-service';

export const FAVORITE_SELECTED_CONTACTS =
  'selected-contacts/FAVORITE_SELECTED_CONTACTS';
export const FAVORITE_SELECTED_CONTACTS_ERROR =
  'selected-contacts/FAVORITE_SELECTED_CONTACTS_ERROR';
export const FAVORITE_SELECTED_CONTACTS_SUCCESS =
  'selected-contacts/FAVORITE_SELECTED_CONTACTS_SUCCESS';
export const DESELECT_ALL_CONTACTS = 'selected-contacts/DESELECT_ALL_CONTACTS';
export const DESELECT_CONTACTS_BY_ID =
  'selected-contacts/DESELECT_CONTACTS_BY_ID';
export const SELECT_CONTACTS_BY_ID = 'selected-contacts/SELECT_CONTACTS_BY_ID';

const initialState = {
  contactIds: [],
  selectionFavoriteSaveInProgress: false,
};

const selectedContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITE_SELECTED_CONTACTS: {
      return {
        ...state,
        selectionFavoriteSaveInProgress: true,
      };
    }

    case FAVORITE_SELECTED_CONTACTS_ERROR: {
      return {
        ...state,
        selectionFavoriteSaveInProgress: false,
      };
    }

    case FAVORITE_SELECTED_CONTACTS_SUCCESS: {
      return {
        ...state,
        contactIds: [],
        selectionFavoriteSaveInProgress: false,
      };
    }

    case DESELECT_ALL_CONTACTS: {
      return {
        ...state,
        contactIds: [],
      };
    }

    case DESELECT_CONTACTS_BY_ID: {
      const newContactIds = state.contactIds.filter(
        contactId =>
          !action.payload.ids.some(
            secondContactId => secondContactId === contactId,
          ),
      );

      return {
        ...state,
        contactIds: newContactIds,
      };
    }

    case SELECT_CONTACTS_BY_ID: {
      const newContactIds = uniq(state.contactIds.concat(action.payload.ids));

      return {
        ...state,
        contactIds: newContactIds,
      };
    }

    default:
      return state;
  }
};

export const selectContactsById = ids => {
  return {
    type: SELECT_CONTACTS_BY_ID,
    payload: { ids },
  };
};

export const favoriteSelectedContacts = ids => dispatch => {
  dispatch({
    type: FAVORITE_SELECTED_CONTACTS,
  });

  performPut(`${CONTACT_LISTS_ENDPOINT}/favorites/authors`, {
    authorIds: ids,
  })
    .then(() => {
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Success',
          text: 'Contacts have been added to your Favorites', // TODO: update this message
          status: 'success',
        }),
      );

      dispatch({
        type: FAVORITE_SELECTED_CONTACTS_SUCCESS,
      });
    })
    .catch(() => {
      dispatch(
        addPageMessageWithDefaultTimeout({
          title: 'Error',
          text: 'There was a problem adding the Contacts to your Favorites.', // TODO: update this message
          status: 'danger',
        }),
      );

      dispatch({
        type: FAVORITE_SELECTED_CONTACTS_ERROR,
      });
    });
};

export const deSelectAllContacts = () => ({
  type: DESELECT_ALL_CONTACTS,
});

export const deSelectContactsByIds = ids => ({
  type: DESELECT_CONTACTS_BY_ID,
  payload: { ids },
});

export default selectedContactsReducer;
