import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { Checkbox } from '../forms';
import Person from '../person';
import RadioButton from '../radio-button';
import withModifiers from '../withModifiers';

import messages from './List.messages';

const ListEntryWithAvatar = ({
  className,
  disabled,
  entry,
  hideSelectionOption,
  multiselect,
  onClickDisabled,
  onSelect,
  selected,
  showEntryBadge,
  ...passedProps
}) => {
  const {
    alreadyAdded,
    badge,
    contacts,
    fullName,
    image,
    publications,
  } = entry;

  const addingDisabled = useMemo(() => disabled?.max, [disabled]);

  const noEmail = useMemo(() => disabled?.noEmail, [disabled]);

  const isListSelected = useMemo(() => disabled?.isListSelected, [disabled]);

  const handleSelect = useCallback(() => {
    if (addingDisabled && !selected) {
      return onClickDisabled(disabled, entry);
    }

    if (noEmail || isListSelected) {
      return;
    }

    return onSelect(entry);
  }, [
    addingDisabled,
    disabled,
    entry,
    isListSelected,
    noEmail,
    onClickDisabled,
    onSelect,
    selected,
  ]);

  const selectionOption = useMemo(() => {
    if (hideSelectionOption) {
      return null;
    }

    let selectionOption;

    if (alreadyAdded) {
      selectionOption = <div>Added</div>;
    } else if (noEmail) {
      selectionOption = (
        <div className="tk-list-entry__no-email">
          <TranslatedMessage {...messages.noEmail} />
        </div>
      );
    } else if (!multiselect) {
      selectionOption = (
        <div className="tk-list-entry__single-select">
          <RadioButton selected={selected} />
        </div>
      );
    } else if (addingDisabled && !selected) {
      selectionOption = (
        <div className="tk-list-entry__checkbox">
          <Checkbox disabled size="medium" />
        </div>
      );
    } else {
      selectionOption = (
        <div className="tk-list-entry__checkbox">
          <Checkbox selected={selected} size="medium" />
        </div>
      );
    }
    return selectionOption;
  }, [
    hideSelectionOption,
    alreadyAdded,
    noEmail,
    multiselect,
    addingDisabled,
    selected,
  ]);

  const subtitle =
    (publications && !!publications.length && publications[0]) || '';

  const classes = classNames(className, {
    'tk-list-entry--no-subtitle': !subtitle,
    'tk-list-entry--unavailable': alreadyAdded || noEmail || isListSelected,
    'tk-list-entry--selected': selected,
  });

  return (
    <div
      data-qa="8-8-JDB4mFYQT9nAz-_xZ"
      className={classes}
      onClick={handleSelect}
      onKeyUp={handleSelect}
      role="button"
      tabIndex="0"
    >
      <div className="tk-list-entry__data">
        <Person
          disabled={contacts && contacts.length === 0}
          imgLoading={image && image.loading}
          imgUrl={image && image.url}
          name={fullName}
          publications={[subtitle]}
          size="sm"
          truncate
        />
      </div>
      {selectionOption}
      {showEntryBadge && <div className="tk-list-entry__badge">{badge}</div>}
    </div>
  );
};

ListEntryWithAvatar.baseClass = 'tk-list-entry';

ListEntryWithAvatar.defaultProps = {
  className: 'tk-list-entry',
  disabled: undefined,
  hideSelectionOption: false,
  modifiers: [],
  multiselect: false,
  onClickDisabled: () => {},
  onSelect: () => {},
  selected: false,
  showEntryBadge: false,
};

ListEntryWithAvatar.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.shape({
    max: PropTypes.bool,
    noEmail: PropTypes.bool,
    isListSelected: PropTypes.bool,
  }),
  entry: PropTypes.shape({
    alreadyAdded: PropTypes.bool,
    badge: PropTypes.node,
    contacts: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.object,
    imageLoading: PropTypes.bool,
    fullName: PropTypes.string,
    publications: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  hideSelectionOption: PropTypes.bool,
  modifiers: PropTypes.arrayOf(
    // eslint-disable-line react/no-unused-prop-types
    PropTypes.oneOf(['bordered', 'highlighted-select']),
  ),
  multiselect: PropTypes.bool.isRequired,
  onClickDisabled: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  showEntryBadge: PropTypes.bool,
};

export default withModifiers(ListEntryWithAvatar);
