import { createSelector } from 'reselect';

import { TK_SYSTEM_USER } from 'constants/constants';
import { facetsByIdSelector } from 'selectors/facets';
import { socialTrackersSelector } from 'selectors/search';

import { getCurrentUserId } from 'services/user-service/user-service';

import { SEARCH_CRITERIA_KEYS } from '../reducers/searchBuilderReducer';

import { allFiltersWithDataSelector } from './filtersSelectors';

export const mustCriteriaSelector = state =>
  state.earnedSearch.searchBuilder[SEARCH_CRITERIA_KEYS.mustCriteria];
export const anyCriteriaSelector = state =>
  state.earnedSearch.searchBuilder[SEARCH_CRITERIA_KEYS.anyCriteria];
export const notCriteriaSelector = state =>
  state.earnedSearch.searchBuilder[SEARCH_CRITERIA_KEYS.notCriteria];

export const mustCriteriaErrorsSelector = state =>
  state.earnedSearch.searchBuilder.errors.mustCriteria;

export const anyCriteriaErrorsSelector = state =>
  state.earnedSearch.searchBuilder.errors.anyCriteria;

export const notCriteriaErrorsSelector = state =>
  state.earnedSearch.searchBuilder.errors.notCriteria;

export const selectedCategorySelector = state =>
  state.earnedSearch.searchBuilder.selectedCategory;

export const advancedSearchQuerySelector = state =>
  state.earnedSearch.searchBuilder.search;

export const adultContentSelector = state =>
  state.earnedSearch.searchBuilder.includeAdultContent;

export const chartColorSelector = state =>
  state.earnedSearch.searchBuilder.colorTag;

export const subscriptionSelector = state =>
  state.earnedSearch.searchBuilder.subscription;

export const subscriptionUpdateInProgressSelector = state =>
  state.earnedSearch.searchBuilder.subscriptionUpdateInProgress;

export const searchTitleSelector = state =>
  state.earnedSearch.searchBuilder.searchTitle;

export const qualitySearchSelector = state =>
  state.earnedSearch.searchBuilder.qualityFilter;

export const searchIsArchivedSelector = state =>
  !!state.earnedSearch.searchBuilder.dateDeleted &&
  !!state.earnedSearch.searchBuilder.dateArchived;

export const searchExistsSelector = state =>
  state.earnedSearch.searchBuilder.id !== undefined;

export const searchIsSimpleSelector = state =>
  state.earnedSearch.searchBuilder.simple;

export const updateSearchSuccessfulSelector = state =>
  state.earnedSearch.searchBuilder.updateProcessSuccessful;

export const simpleSearchQuerySelector = createSelector(
  mustCriteriaSelector,
  anyCriteriaSelector,
  notCriteriaSelector,
  allFiltersWithDataSelector,
  (includeAll, includeAny, excludeAny, filtersData) => ({
    includeAll,
    includeAny,
    excludeAny,
    filters: filtersData,
  }),
);

export const searchDataSnapshotSelector = createSelector(
  searchTitleSelector,
  simpleSearchQuerySelector,
  advancedSearchQuerySelector,
  searchIsSimpleSelector,
  selectedCategorySelector,
  qualitySearchSelector,
  adultContentSelector,
  chartColorSelector,
  (
    searchTitle,
    { includeAll, includeAny, excludeAny, filters },
    query,
    isSimple,
    selectedCategory,
    qualityFilter,
    includeAdultContent,
    chartColor,
  ) => ({
    searchTitle,
    includeAll,
    includeAny,
    excludeAny,
    query,
    isSimple,
    filters,
    selectedCategory,
    qualityFilter,
    includeAdultContent,
    chartColor,
  }),
);

export const convertToAdvancedSearchSelector = state =>
  state.earnedSearch.searchBuilder.canConvertToAdvancedSearch;

export const isLoadingDataSelector = state =>
  state.earnedSearch.searchBuilder.isLoadingData;

export const isSystemSearchSelector = state =>
  state.earnedSearch.searchBuilder.systemSearch;
export const systemSearchPubStatusSelector = state =>
  state.earnedSearch.searchBuilder.systemSearchPubStatus;
export const viewOnlySelector = state =>
  state.earnedSearch.searchBuilder.viewOnly;
export const searchActiveInSelector = state =>
  state.earnedSearch.searchBuilder.activeIn;
export const searchAuditLogSelector = state =>
  state.earnedSearch.searchBuilder.auditLog;
export const searchLastSavedSelector = state =>
  state.earnedSearch.searchBuilder.lastSaved;
export const searchUserSelector = state =>
  state.earnedSearch.searchBuilder.user;
export const searchSharedSelector = state =>
  state.earnedSearch.searchBuilder.shared;
export const socialTrackerSelector = state =>
  state.earnedSearch.searchBuilder.tksTrackerId;

export const searchTrackerNameSelector = createSelector(
  socialTrackerSelector,
  socialTrackersSelector,
  (trackerId, trackers) =>
    trackers[trackerId] ? trackers[trackerId].name : '',
);

export const searchEditInvalidSelector = createSelector(
  advancedSearchQuerySelector,
  mustCriteriaSelector,
  (advancedQuery, mustCriteria) => {
    return !(advancedQuery.trim().length || mustCriteria.length);
  },
);

export const searchDisabledSelector = createSelector(
  searchLastSavedSelector,
  searchUserSelector,
  viewOnlySelector,
  searchSharedSelector,
  searchEditInvalidSelector,
  (lastSaved, searchUser, viewOnly, shared, invalidSearchEdit) => {
    if (lastSaved) {
      const userOwnsSearch =
        searchUser.id === getCurrentUserId() ||
        searchUser.username === TK_SYSTEM_USER;
      return (
        (!userOwnsSearch && viewOnly) ||
        (!userOwnsSearch && !shared) ||
        invalidSearchEdit
      );
    }
    return invalidSearchEdit;
  },
);

export const searchPreviewPayloadSelector = createSelector(
  facetsByIdSelector,
  selectedCategorySelector,
  searchTitleSelector,
  adultContentSelector,
  isSystemSearchSelector,
  systemSearchPubStatusSelector,
  qualitySearchSelector,
  (
    facetsById,
    selectedCategory,
    searchTitle,
    adultContent,
    isSystemSearch,
    systemSearch,
    qualityFilter,
  ) => {
    const facets = Object.values(facetsById);
    const defaultCategory = facets.find(({ name }) => name === 'Custom');
    const category = selectedCategory || defaultCategory;
    return {
      facet: { uuid: category?.uuid },
      title: searchTitle,
      saved: false,
      simple: false,
      systemSearch: isSystemSearch,
      systemSearchPubStatus: isSystemSearch ? systemSearch : null,
      includeAdultContent: adultContent,
      qualityFilter,
    };
  },
);

export const sharedPermissionSelector = createSelector(
  viewOnlySelector,
  searchSharedSelector,
  (viewOnly, shared) => ({ viewOnly, shared }),
);

export const searchUpdateInProgressSelector = state =>
  state.earnedSearch.searchBuilder.searchUpdateInProgress;

export const searchAccessControlGroupsSelector = state =>
  state.earnedSearch.searchBuilder.accessControlGroups;
