export default {
  integrationTitle: {
    id: 'APP_SETTINGS_INTEGRATION_TITLE',
    defaultMessage: 'Integrations',
    description: 'Title of the page as Integrations',
  },
  appTitle: {
    id: 'APP_SETTINGS_APP_TITLE',
    defaultMessage: 'App Settings',
    description: 'Title of the page as App Settings',
  },
  websiteAnalyticsTitle: {
    id: 'APP_SETTINGS_WEBSITE_ANALYTICS_TITLE',
    defaultMessage: 'Website Analytics',
    description: 'Sub-title in the page as Website Analytics',
  },
  websiteAnalyticsBody: {
    id: 'APP_SETTINGS_WEBSITE_ANALYTICS_BODY',
    defaultMessage:
      'Connect {BRAND} to the web services you already use, import content, and see how your news is influencing your business.',
    description:
      'Content goes as part of body of website analytics section in App settings page',
  },
  teamCommunicationTitle: {
    id: 'APP_SETTINGS_TEAM_COMMUNICATION_TITLE',
    defaultMessage: 'Team Communication',
    description: 'Sub-title in the page as Team communication',
  },
  teamCommunicationBody: {
    id: 'APP_SETTINGS_TEAM_COMMUNICATION_BODY',
    defaultMessage:
      'Connect to your team’s Slack channels to easily share mentions you find in {BRAND}.',
    description:
      'Content goes as part of body of team communication section in App settings page',
  },
  broadcastDataTitle: {
    id: 'APP_SETTINGS_BROADCAST_DATA_TITLE',
    defaultMessage: 'Broadcast Data',
    description: 'Sub-title in the page as Broadcast Data',
  },
  gaViewsIntegratedButtonText: {
    id: 'APP_SETTINGS_GA_VIEWS_INTEGRATED_BUTTON_TEXT',
    defaultMessage: 'Manage Data',
    description: 'Displayed text on GAViewsIntegrated button',
  },
  googleLoginErrorMsg: {
    id: 'APP_SETTINGS_GOOGLE_LOGIN_ERROR_MSG',
    defaultMessage:
      'There was an error with the Google integration. Please try again later.',
    description: 'Failure message displayed when google login failed',
  },
  googleLoginSignInText: {
    id: 'APP_SETTINGS_GOOGLE_LOGIN_SIGN_TEXT',
    defaultMessage: 'Sign in with Google',
    description: 'Displayed text for google login',
  },
  googleLoginSetupManuallyText: {
    id: 'APP_SETTINGS_GOOGLE_LOGIN_SETUP_MANUALLY_TEXT',
    defaultMessage: 'Set Up Manually',
    description: 'Displayed text for manual google login',
  },
  cancelButtonText: {
    id: 'APP_SETTINGS_CANCEL_BUTTON_TEXT',
    defaultMessage: 'Cancel',
    description: 'Displayed text on cancel button',
  },
  adobeAnalyticsNoContentButtonText: {
    id: 'APP_SETTINGS_ADOBE_ANALYTICS_NO_CONTENT_BUTTON_TEXT',
    defaultMessage: 'Connect',
    description: 'Displayed text for AdobeAnalytics no content connect button',
  },
  adobeAnalyticsNoContentCardDescription: {
    id: 'APP_SETTINGS_ADOBE_ANALYTICS_NO_CONTENT_CARD_DESCRIPTION',
    defaultMessage:
      'The Adobe Analytics integration provides enterprise teams with website traffic, audience engagement, conversions, and revenue that was driven by PR for all your news coverage that contains backlinks to your website.', // eslint-disable-line max-len
    description:
      'Displayed text for AdobeAnalytics no content card description',
  },
  adobeAnalyticsInstructionsScreenDescription: {
    id: 'APP_SETTINGS_ADOBE_ANALYTICS_INSTRUCTIONS_SCREEN_DESCRIPTION_LABEL',
    defaultMessage:
      'Before we can connect your Adobe Analytics account, you will need to create a new User to grant {BRAND} API access. Click the button below to view the step-by-step instructions.', // eslint-disable-line max-len
    description:
      'Displayed text for AdobeAnalytics instructions screen description',
  },
  adobeAnalyticsEnabledDomainTableCardDescription: {
    id: 'APP_SETTINGS_ADOBE_ANALYTICS_ENABLED_DOMAIN_TABLE_CARD_DESCRIPTION',
    defaultMessage:
      'The Adobe Analytics integration provides enterprise teams with website traffic, audience engagement, conversions, and revenue that was driven by PR for all your news coverage that contains backlinks to your website.', // eslint-disable-line max-len
    description:
      'Displayed text for AdobeAnalytics enabled domain table card description',
  },
  slackCardDeauthorizeAllButtonText: {
    id: 'APP_SETTINGS_SLACK_CARD_DISCONNECT_TEXT',
    defaultMessage: 'Disconnect',
    description: 'Displayed text for Slack card, deauthorize all button',
  },
  slackCardAddNewChannelButtonText: {
    id: 'APP_SETTINGS_SLACK_CARD_ADD_NEW_CHANNEL_BUTTON_TEXT',
    defaultMessage: 'Add New Channel',
    description: 'Displayed text for Slack card, add new channel button',
  },
  gaVersionTitle: {
    id: 'GA_VERSION_TITLE',
    description: 'Google Analytics Title',
    defaultMessage: 'Google Analytics {GA_VERSION}',
  },
  gaVersionLabel: {
    id: 'GA_VERSION_LABEL',
    description: 'GA version label',
    defaultMessage: 'V{GA_VERSION}',
  },
  toggleGaVersionSuccess: {
    id: 'TOGGLE_GA_VERSION_SUCCESS',
    description: 'Successfully changed GA version',
    defaultMessage: 'Successfully changed GA to version {GA_VERSION}',
  },
  toggleGaVersionError: {
    id: 'TOGGLE_GA_VERSION_ERROR',
    description: 'Error trying to toggle GA version',
    defaultMessage:
      'There was an error trying to toggle GA version to {GA_VERSION}. Please try again later.',
  },
  fetchGaVersionError: {
    id: 'FETCH_GA_VERSION_ERROR',
    description: 'Error fetching GA version',
    defaultMessage:
      'There was an error trying to fetch GA version. Please try again later.',
  },
};
