import React from 'react';

import { Badge } from '@cision/rover-ui';
import classNames from 'classnames';

import globalMessages from 'i18n/Global.messages';
import T from 'i18n/TranslatedMessage';

import styles from './FeatureStatusBadge.module.css';

interface FeatureStatusBadgeProps {
  className?: string;
  children?: React.ReactNode;
  style?: any;
}

const FeatureStatusBadge: React.FC<FeatureStatusBadgeProps> = ({
  className = '',
  children = <T {...globalMessages.new} />,
  style = {},
  ...props
}) => (
  <Badge
    className={classNames(styles.FeatureStatusBadge, className)}
    style={{ textTransform: 'uppercase', ...style }}
    {...props}
  >
    {children}
  </Badge>
);

export default FeatureStatusBadge;
