export default {
  authorDefaultLabel: {
    id: 'AUTHOR_DEFAULT_COLUMN_LABEL',
    defaultMessage: 'Author',
    description: 'Label for the author column',
  },
  mediaTypeDefaultLabel: {
    id: 'MEDIA_TYPE_DEFAULT_COLUMN_LABEL',
    defaultMessage: 'Media Type',
    description: 'Label for the media type column',
  },
  mentionDefaultLabel: {
    id: 'DASHBOARD_WIDGET_DRILLDOWN_ARTICLE_LIST_MENTION_COLUMN_LABEL',
    defaultMessage: 'Mention',
    description:
      'When users clicks on the Mention title in the article list, it will be sorted by MENTION in ascending or descending sort.',
  },
  aveDefaultLabel: {
    id: 'AD_EQUIVALENCY_COLUMN_LABEL',
    defaultMessage: 'Ave',
    description: 'Label for the ad equivalency column',
  },
  desktopReadershipDefaultLabel: {
    id: 'DESKTOP_READERSHIP_COLUMN_LABEL',
    defaultMessage: 'Desktop Readership',
    description: 'Label for the desktop readership column',
  },
  desktopReadershipMobileUvmLabel: {
    id: 'READERSHIP_COLUMN_LABEL',
    defaultMessage: 'Readership',
    description: 'Label for the readership column',
  },
  articleImpactDefaultLabel: {
    id: 'IMPACT_SCORE_COLUMN_LABEL',
    defaultMessage: 'Article Impact',
    description: 'Label for the article impact column',
  },
  mobileReadershipDefaultLabel: {
    id: 'MOBILE_READERSHIP_COLUMN_LABEL',
    defaultMessage: 'Mobile Readership',
    description: 'Label for the mobile readership column',
  },
  outletDefaultLabel: {
    id: 'OUTLET_COLUMN_LABEL',
    defaultMessage: 'Outlet',
    description: 'Label for the outlet column',
  },
  pubDateDefaultLabel: {
    id: 'PUB_DATE_COLUMN_LABEL',
    defaultMessage: 'Pub. Date',
    description: 'Label for the published date column',
  },
  sentimentDefaultLabel: {
    id: 'SENTIMENT_COLUMN_LABEL',
    defaultMessage: 'Sentiment',
    description: 'Label for the sentiment column',
  },
  domainAuthorityDefaultLabel: {
    id: 'DOMAIN_AUTHORITY_COLUMN_LABEL',
    defaultMessage: 'Domain Authority',
    description: 'Label for the SEO impact column',
  },
  sharesDefaultLabel: {
    id: 'SHARES_COLUMN_LABEL',
    defaultMessage: 'Shares',
    description: 'Label for the shares column',
  },
  totalReadershipDefaultLabel: {
    id: 'TOTAL_READERSHIP_COLUMN_LABEL',
    defaultMessage: 'Total Readership',
    description: 'Label for the total readership column',
  },
  countryDefaultLabel: {
    id: 'COUNTRY_COLUMN_LABEL',
    defaultMessage: 'Country',
    description: 'Label for the country column',
  },
  stateDefaultLabel: {
    id: 'STATE_COLUMN_LABEL',
    defaultMessage: 'State',
    description: 'Label for the state column',
  },
  cityDefaultLabel: {
    id: 'CITY_COLUMN_LABEL',
    defaultMessage: 'City',
    description: 'Label for the city column',
  },
  localViewershipDefaultLabel: {
    id: 'LOCAL_VIEWERSHIP_COLUMN_LABEL',
    defaultMessage: 'Local Viewership',
    description: 'Label for the local viewership column',
  },
  nationalViewershipDefaultLabel: {
    id: 'NATIONAL_VIEWERSHIP_COLUMN_LABEL',
    defaultMessage: 'National Viewership',
    description: 'Label for the national viewership column',
  },
  circulation: {
    id: 'CIRCULATION_COLUMN_LABEL',
    defaultMessage: 'Circulation',
    description: 'Label for the circulation column',
  },
  noArticlesFoundLabel: {
    id: 'NO_ARTICLES_MESSAGE_LABEL',
    defaultMessage: 'No articles found',
    description:
      'Label to show when no articles are found to load on the article list',
  },
  shareArticleToSlack: {
    id: 'SHARE_ARTICLE_TO_SLACK',
    defaultMessage: 'Share To Slack',
    description: 'Label to share articles by slack',
  },
  totalSharedSocialNetworks: {
    id: 'TOTAL_SHARED_SOCIAL_MEDIA',
    defaultMessage: 'Total Shares',
    description: 'Label for total shared with social networks',
  },
  earnedSearchArticleCount: {
    id: 'EARNED_SEARCHES_ARTICLES_RESULT_COUNT',
    defaultMessage: `Showing {articleCount} {articleCount, plural,
      one {mention}
      other {mentions}
    } for "{searchTitle}" from {startDate} - {endDate}`,
    description:
      'Message to show the amount of articles linked to a specific search',
  },
  tagsArticleCount: {
    id: 'TAGS_DRILLDOWN_ARTICLES_RESULT_COUNT',
    defaultMessage: `Showing {ARTICLES_COUNT} {ARTICLES_COUNT, plural,
      one {article}
      other {articles}
    } for "{TAG_NAME}"`,
    description:
      'Message to show the amount of articles linked to a specific tag',
  },
  earnedSearchGroupPublicationsOutlet: {
    id: 'EARNED_SEARCHES_ARTICLES_GROUP_OUTLET',
    defaultMessage: '({articleCount}) Publications...',
    description: 'Message for outlet in syndicate articles publications count',
  },
  earnedSearchGroupPublicationsAuthors: {
    id: 'EARNED_SEARCHES_ARTICLES_GROUP_AUTHORS',
    defaultMessage: '({articleCount}) Authors...',
    description: 'Message for outlet in syndicate articles authors count',
  },
  markEarnedMediaAction: {
    id: 'MARK_EARNED_MEDIA_ACTION',
    defaultMessage: 'Mark Earned Media',
    description: 'Message for Mark Earned Media action',
  },
  coveragePageEmptyStateTitle: {
    id: 'COVERAGE_PAGE_EMPTY_STATE_TITLE',
    defaultMessage: 'Searching for Coverage',
    description: 'Title for the empty state in the coverage page',
  },
  coveragePageEmptyStateText: {
    id: 'COVERAGE_PAGE_EMPTY_STATE_TEXT',
    defaultMessage:
      'We’re scanning the internet for coverage related to your linked search! Check again soon.',
    description: 'Text for the empty state in the coverage page',
  },
  widgetDrillDownSentimentTypePositive: {
    id: 'WIDGET_DRILL_DOWN_SENTIMENT_TYPE_POSITIVE',
    defaultMessage: 'positive',
    description: 'Sentiment type => positive',
  },
  widgetDrillDownSentimentTypeNegative: {
    id: 'WIDGET_DRILL_DOWN_SENTIMENT_TYPE_NEGATIVE',
    defaultMessage: 'negative',
    description: 'Sentiment type => negative',
  },
  widgetDrillDownSentimentTypeNeutral: {
    id: 'WIDGET_DRILL_DOWN_SENTIMENT_TYPE_NEUTRAL',
    defaultMessage: 'neutral',
    description: 'Sentiment type => neutral',
  },
  widgetDrillDownSentimentTypeUndetermined: {
    id: 'WIDGET_DRILL_DOWN_SENTIMENT_TYPE_UNDETERMINED',
    defaultMessage: 'undetermined',
    description: 'Sentiment type => undetermined',
  },
  widgetDrilldownChartLoadingErrorTitle: {
    id: 'WIDGET_DRILL_DOWN_CHART_LOADING_ERROR_TITLE',
    description:
      'Title text displayed when a widget drilldown chart has a problem loading',
    defaultMessage: 'Sorry, something went wrong.',
  },
  widgetDrilldownChartLoadingErrorTextTryAgain: {
    id: 'WIDGET_DRILL_DOWN_CHART_LOADING_ERROR_TEXT_TRY_AGAIN',
    description:
      'Text displayed when a widget drilldown chart has a problem loading. Try again?',
    defaultMessage: "We couldn't load the widget. Try again?",
  },
  widgetDrilldownChartLoadingErrorReloadWidgetText: {
    id: 'WIDGET_DRILL_DOWN_CHART_LOADING_ERROR_RELOAD_WIDGET_TEXT',
    description:
      'Text displayed when a widget drilldown chart has a problem loading, indicating to reload the widget',
    defaultMessage: 'Reload widget',
  },
  addTagButton: {
    id: 'WIDGET_DRILL_DOWN_LABEL_ADD_TAG_BUTTON',
    description: 'Drilldown Article List: Label add tag button',
    defaultMessage: 'Add Tag',
  },
  publicationDateDefaultLabel: {
    id: 'DRILLDOWN_PUBLICATION_DATE_COLUMN_LABEL',
    defaultMessage: 'Date',
    description: 'Label for the published date column',
  },
  bulkLimitExceededWarning: {
    id: 'DRILLDOWN_BULK_LIMIT_EXCEEDED',
    defaultMessage:
      'You are exceeding the {BULK_LIMIT} selection limit for bulk operations',
    description:
      'warning message when the user exceed the bulk selection number',
  },
  tvEyesRadioClipFromPublisherTitle: {
    id: 'TV_EYES_RADIO_CLIP_FROM_PUBLISHER_TITLE',
    defaultMessage: 'Radio clip from {PUBLISHER} - {DATE}',
    description: 'Custom TVeyes radio clip title',
  },
};
