import { SUBSCRIBED_ALERTS_ENDPOINT } from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';
import { performGet, performPost } from 'services/rest-service/rest-service';

export const GET_RECIPIENT_ALERT_INFORMATION =
  'alertsSubscription/GET_RECIPIENT_ALERT_INFORMATION';
export const GET_RECIPIENT_ALERT_INFORMATION_SUCCESS =
  'alertsSubscription/GET_RECIPIENT_ALERT_INFORMATION_SUCCESS';
export const GET_RECIPIENT_ALERT_INFORMATION_ERROR =
  'alertsSubscription/GET_RECIPIENT_ALERT_INFORMATION_ERROR';
export const RECIPIENT_UNSUBSCRIBE_SUCCESS =
  'alertsSubscription/RECIPIENT_UNSUBSCRIBE_SUCCESS';
export const TOGGLE_ALERTS = 'alertsSubscription/TOGGLE_ALERTS';
export const TOGGLE_SINGLE_ALERT = 'alertsSubscription/TOGGLE_SINGLE_ALERT';
export const UPDATE_SUBSCRIBED_ALERT =
  'alertsSubscription/UPDATE_SUBSCRIBED_ALERT';
export const ACCESS_ALERT_PREFERENCES =
  'alertsSubscription/UPDATE_SUBSCRIBED_ALERT';

const initialState = {
  recipient: {},
  subscribedAlerts: [],
  history: [],
  loading: false,
  hasAccess: false,
};

const alertsSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECIPIENT_ALERT_INFORMATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_RECIPIENT_ALERT_INFORMATION_SUCCESS: {
      return {
        ...state,
        recipient: action.recipient,
        subscribedAlerts: action.subscribedAlerts,
        history: action.history,
        loading: false,
      };
    }
    case ACCESS_ALERT_PREFERENCES: {
      return {
        ...state,
        loading: false,
        recipient: action.recipient,
        hasAccess: action.hasAccess || false,
      };
    }
    case UPDATE_SUBSCRIBED_ALERT: {
      const alerts = state.subscribedAlerts.filter(
        alert => action.unsubscribedAlertsIds.indexOf(alert.id) < 0,
      );
      return {
        ...state,
        subscribedAlerts: alerts,
        history: state.history.concat(action.log),
        loading: false,
        recipient: action.recipient,
        hasAccess: action.hasAccess || false,
      };
    }
    case GET_RECIPIENT_ALERT_INFORMATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case RECIPIENT_UNSUBSCRIBE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case TOGGLE_ALERTS: {
      return {
        ...state,
        subscribedAlerts: state.subscribedAlerts.map(alert => ({
          ...alert,
          subscribed: action.subscribed,
        })),
      };
    }
    case TOGGLE_SINGLE_ALERT: {
      return {
        ...state,
        subscribedAlerts: state.subscribedAlerts.map(alert =>
          alert.id === action.id
            ? { ...alert, subscribed: !alert.subscribed }
            : alert,
        ),
      };
    }

    default:
      return state;
  }
};

const renderRecipientInformationErrorMessage = () => dispatch => {
  dispatch(
    addPageMessage({
      isNewUI: true,
      text: 'An error occurred retrieving the alerts.',
      status: 'danger',
      ttl: 5000,
    }),
  );
};

export const getRecipientAlertInformation = email => dispatch => {
  dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION });

  performGet(`${SUBSCRIBED_ALERTS_ENDPOINT}?recipient=${email}`)
    .then(response => {
      const recipient = {
        id: response.data.recipient.id,
        email: response.data.recipient.emailAddress,
      };

      const subscribedAlerts = response.data.subscribed
        ? response.data.subscribed.map(alert => ({
            id: alert.id,
            name: alert.title,
            subscribed: true,
          }))
        : [];

      const history = response.data.unsubscribed.historical
        ? response.data.unsubscribed.historical
        : [];

      dispatch({
        type: GET_RECIPIENT_ALERT_INFORMATION_SUCCESS,
        recipient,
        subscribedAlerts,
        history,
      });
    })
    .catch(() => {
      dispatch(renderRecipientInformationErrorMessage());
      dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION_ERROR });
    });
};

export const toggleSingleAlertActionCreator = id => dispatch => {
  dispatch({
    type: TOGGLE_SINGLE_ALERT,
    id,
  });
};

export const toggleAlerts = subscribed => dispatch => {
  dispatch({
    type: TOGGLE_ALERTS,
    subscribed,
  });
};

export const unsubscribeAlertsActionCreator = (
  recipient,
  alerts,
) => dispatch => {
  dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION });
  const unsubscribedAlertsIds = alerts.reduce((accumulator, alert) => {
    if (!alert.subscribed) accumulator.push(alert.id);
    return accumulator;
  }, []);

  performPost(`/dailyClips/recipients/${recipient.id}/unsubscribe`, {
    dailyClipIdsList: unsubscribedAlertsIds,
  })
    .then(response => {
      const subscribedAlerts = response.data.subscribed
        ? response.data.subscribed.map(alert => ({
            id: alert.id,
            name: alert.title,
            subscribed: true,
          }))
        : [];

      const history = response.data.unsubscribed.historical
        ? response.data.unsubscribed.historical
        : [];

      dispatch({
        type: GET_RECIPIENT_ALERT_INFORMATION_SUCCESS,
        subscribedAlerts,
        history,
      });
    })
    .catch(() => {
      dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION_ERROR });
    });
};

export const sendUnsubscribeEmail = recipient => dispatch => {
  dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION });
  performPost('/dailyClips/recipients/unsubscribe_email', { recipient })
    .then(response => {
      if (response.status === 200) {
        if (response.data && response.data.message) {
          dispatch(
            addPageMessage({
              isNewUI: true,
              text: `${response.data.message}.`,
              status: 'danger',
              ttl: 10000,
            }),
          );
          dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION_ERROR });
        } else {
          dispatch(
            addPageMessage({
              isNewUI: true,
              text: 'An email was sent to that address.',
              status: 'success',
              ttl: 5000,
            }),
          );
        }

        dispatch({ type: RECIPIENT_UNSUBSCRIBE_SUCCESS });
      } else {
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: 'Could not get information related to the recipient.',
            status: 'warning',
            ttl: 5000,
          }),
        );
        dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION_ERROR });
      }
    })
    .catch(() => {
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: 'An error occurred retrieving the alerts.',
          status: 'danger',
          ttl: 5000,
        }),
      );
      dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION_ERROR });
    });
};

export const getAlertPreferencesAccess = token => dispatch => {
  dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION });
  performGet(`/dailyClips/recipients/alerts-preferences-access?token=${token}`)
    .then(response => {
      if (response.data) {
        dispatch({
          type: ACCESS_ALERT_PREFERENCES,
          hasAccess:
            !!response.data.userEmail && !!response.data.userEmail.emailAddress,
          recipient: response.data.userEmail,
        });
      } else {
        dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION_ERROR });
      }
    })
    .catch(() => {
      dispatch({ type: GET_RECIPIENT_ALERT_INFORMATION_ERROR });
    });
};

export default alertsSubscriptionReducer;
