import React from 'react';

import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Body, Button, SvgIcon } from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';
import { backlinksPropTypes } from '../story/templates/default-template';

// i18n

import messages from './StoryBacklinksDisplay.messages';

const getLinkProps = (isEditable, backlink) => {
  return isEditable
    ? {}
    : {
        href: backlink.url,
        target: '_blank',
        rel: 'noopener noreferrer',
      };
};

const StoryBacklinksDisplay = ({ backlinks, isEditable }) => {
  const intl = useIntl();
  const validBacklinks = backlinks.filter(b => !!b.url);
  return (
    <StorySection>
      <StorySectionHeader label={intl.formatMessage(messages.title)} />
      <div className={StoryBacklinksDisplay.baseClass}>
        {validBacklinks.map(backlink => (
          <div
            key={backlink.id}
            className={`${StoryBacklinksDisplay.baseClass}__backlink`}
          >
            <a
              className={`${StoryBacklinksDisplay.baseClass}__link`}
              {...getLinkProps(isEditable, backlink)}
            >
              <Body color="link">{backlink.url}</Body>
            </a>
            {backlink.text && (
              <div className={`${StoryBacklinksDisplay.baseClass}__text`}>
                <Body color="light" block>
                  <T
                    {...messages.recommendedAnchor}
                    values={{ BACKLINK_TEXT: backlink.text }}
                  />
                </Body>
              </div>
            )}
          </div>
        ))}
        {validBacklinks.length === 0 && isEditable && (
          <div>
            <Body color="disabled">
              <T {...messages.placeholder} />
            </Body>
            <Button modifiers={['calltoaction', 'inline-flex']} active>
              <SvgIcon icon="plus" width={20} height={20} />
              <span
                className={`${StoryBacklinksDisplay.baseClass}__button-text`}
              >
                <T {...messages.addBacklinks} />
              </span>
            </Button>
          </div>
        )}
      </div>
    </StorySection>
  );
};

StoryBacklinksDisplay.baseClass = 'tk-backlinks-display';

StoryBacklinksDisplay.propTypes = {
  isEditable: PropTypes.bool,
  backlinks: backlinksPropTypes,
};

StoryBacklinksDisplay.defaultProps = {
  isEditable: false,
  backlinks: [],
};

export default StoryBacklinksDisplay;
