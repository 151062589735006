import React, { Component } from 'react';

import PropTypes from 'prop-types';

import messages from '../../../app/src/components/searches/SearchTable.messages';

import Banners from '../../../app/src/pages/EarnedSearch/Components/Banners/Banners';

import Type from '../type';

import withModifiers from '../withModifiers';

import GridTableRow from './GridTableRow';

class GridTable extends Component {
  static baseClass = 'tk-grid-table';

  renderTableHeader({
    columns,
    columnStyle,
    onHeaderClick,
    stickyHeaderAt,
    stickyHeaderWidth,
    isSticky,
  }) {
    return (
      <GridTableRow
        columnStyle={columnStyle}
        onHeaderClick={onHeaderClick}
        rowData={columns}
        stickyHeaderAt={stickyHeaderAt}
        stickyHeaderWidth={stickyHeaderWidth}
        isSticky={isSticky}
        isHeader
      />
    );
  }

  renderTableRows({ columnStyle, data }) {
    return data.map(row => {
      return (
        <GridTableRow
          columnStyle={columnStyle}
          key={row.key}
          rowData={row.cells}
        />
      );
    });
  }

  render() {
    const {
      children,
      className,
      columns,
      columnStyle,
      data,
      onHeaderClick,
      isSticky,
      // eslint-disable no-unused-variable
      isHeader, // avoid non-semantic prop error on outer div below
      showHeader,
      stickyHeaderAt,
      stickyHeaderWidth,
      ...passedProps
    } = this.props;
    const tableHeader = this.renderTableHeader({
      columns,
      columnStyle,
      onHeaderClick,
      stickyHeaderAt,
      stickyHeaderWidth,
      isSticky,
      isHeader,
    });
    const tableRows = this.renderTableRows({ columnStyle, data });

    return (
      <React.Fragment>
        {this.props.showDeletedSearchBanner && (
          <Banners
            description={messages.deletedSearchBannerMessage.defaultMessage}
            isDeletedSearches
          />
        )}
        <div className={`${GridTable.baseClass} ${className}`} {...passedProps}>
          <Type color="light" block>
            <div className={`${GridTable.baseClass}__grid ${className}`}>
              {showHeader && tableHeader}
              {tableRows}
              {children}
            </div>
          </Type>
        </div>
      </React.Fragment>
    );
  }
}

GridTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  columns: PropTypes.array,
  columnStyle: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onHeaderClick: PropTypes.func,
  isSticky: PropTypes.bool,
  isHeader: PropTypes.bool,
  showHeader: PropTypes.bool,
  stickyHeaderAt: PropTypes.number,
  stickyHeaderWidth: PropTypes.string,
  showDeletedSearchBanner: PropTypes.bool,
};

GridTable.defaultProps = {
  isSticky: false,
  isHeader: false,
  children: null,
  className: '',
  columns: [],
  columnStyle: '',
  data: [],
  onHeaderClick: () => null,
  showHeader: true,
  stickyHeaderAt: undefined,
  stickyHeaderWidth: '100',
};

export default withModifiers(GridTable);
