/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';

import classNames from 'classnames';
import T from 'i18n/TranslatedMessage';

import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

import { Button, Checkbox } from '@trendkite/ui';

import messages from './assets-library-modals.messages';
import styles from './browseModuleUpload.module.css';

class MediaBrowseAssetsFromComputer extends React.Component {
  render() {
    const {
      uploadFromComputerFiles,
      checkboxForUploadFromComputer,
    } = this.props;
    return (
      <div className={styles.dummyheight}>
        <div className={styles.browseFromComputerHeader}>
          <p className={styles.browseFromComputerHeaderText}>
            <T {...messages.mediaUploadFromComputer} />
          </p>
          <p className={styles.browseFromComputerSupportedText}>
            <T {...messages.mediaUploadFromComputerSupportedFormats} />
          </p>
          <div className={styles.uploadFromComputerBrowseFilesButton}>
            <Button
              modifiers={['round', 'primary']}
              className={styles.uploadFromComputerBrowseButtonRight}
            >
              <input
                type="file"
                name="file"
                accept="image/jpeg, image/png, image/jpg"
                className={styles.uploadFromComputerBrowseButton}
                onChange={e => this.props.onBrowseFileUploadFromComputer(e)}
              />
              <T {...messages.mediaStudioUploadFromComputerBrowseFiles} />
            </Button>
          </div>
        </div>
        <Dropzone className={styles.row} disableClick>
          <div
            className={styles.row}
            style={{ paddingLeft: '20px', overflow: 'scroll', height: '245px' }}
            onDrop={e =>
              this.props.onBrowseFileUploadFromComputerDragAndDrop(e)
            }
          >
            {uploadFromComputerFiles &&
              uploadFromComputerFiles.length > 0 &&
              uploadFromComputerFiles.map(file => {
                return (
                  <div
                    className={classNames(
                      styles.thumbnailContainerForUploadFromComputer,
                      {
                        [styles.assetsSelected]: file.checked,
                      },
                    )}
                    key={file.id}
                  >
                    <div className={styles.hoverMenuForUploadFromComputer}>
                      <label>
                        <Checkbox
                          data-qa="asny8NpWIIRbh4b7BDS87"
                          className={styles.selectedAsset}
                          selected={file.checked}
                          onClick={e =>
                            this.props.assetsCheckBoxClickFromComputer(
                              file.id,
                              e,
                            )
                          }
                        />
                      </label>
                    </div>
                    <div className={styles.imageContainer}>
                      {/* eslint-disable-next-line jsx-a11y/alt-text */}
                      <img className={styles.imgHolder} src={file.url} />
                    </div>
                    <div className={styles.thumbnailInfo}>
                      <p className={styles.imgName}>{file.name}</p>
                      <p className={styles.imgSize}>
                        <span className={styles.imgSizeDetails}>
                          {file.size} KB
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Dropzone>
        <div className={styles.uploadFromComputerMediaStudioSection}>
          <p className={styles.UploadFromComputerCheckboxSection}>
            <label>
              <Checkbox
                data-qa="te0h9BDbosm-RVg5day-H"
                selected={checkboxForUploadFromComputer}
                onClick={() =>
                  this.props.toggleMsCheckboxForUploadFromComputer()
                }
              />
              <span className={styles.UploadFromComputerCheckboxText}>
                <T {...messages.mediaStudioUploadFromComputerCheckboxText} />
              </span>
            </label>
            <span
              data-qa="Va8F3EDB1Mjm5k2r8k8IK"
              className={styles.uploadFromComputerCheckboxTextLearnMore}
              onClick={() => this.props.toggleDisclaimerDiv()}
            >
              <T
                {...messages.mediaStudioUploadFromComputerCheckboxTextLearnMore}
              />
            </span>
          </p>
          <p className={styles.uploadFromComputerAgrementText}>
            <T {...messages.mediaStudioUploadFromComputerAgrementText} />
          </p>
        </div>
      </div>
    );
  }
}
MediaBrowseAssetsFromComputer.propTypes = {
  uploadFromComputerFiles: PropTypes.array,
  onBrowseFileUploadFromComputer: PropTypes.func,
  onBrowseFileUploadFromComputerDragAndDrop: PropTypes.func,
  toggleMsCheckboxForUploadFromComputer: PropTypes.func,
  checkboxForUploadFromComputer: PropTypes.bool,
  assetsCheckBoxClick: PropTypes.func,
  assetsCheckBoxClickFromComputer: PropTypes.func,
  toggleDisclaimerDiv: PropTypes.func,
};

MediaBrowseAssetsFromComputer.defaultProps = {
  uploadFromComputerFiles: [],
  onBrowseFileUploadFromComputer: () => {},
  onBrowseFileUploadFromComputerDragAndDrop: () => {},
  toggleMsCheckboxForUploadFromComputer: () => {},
  checkboxForUploadFromComputer: false,
  assetsCheckBoxClick: () => {},
  assetsCheckBoxClickFromComputer: () => {},
  toggleDisclaimerDiv: () => {},
};
export default MediaBrowseAssetsFromComputer;
