import { ROOT_LANGUAGE_ID, ROOT_REGION_ID } from '../constants';
import TranslationService from '../translation-service';

import { Language } from '../types/api/Language';
import { PhraseTranslation } from '../types/api/PhraseTranslation';
import { PhraseWithTranslations } from '../types/api/PhraseWithTranslations';
import { Region } from '../types/api/Region';

import { PhraseTranslationViewModel } from '../types/PhraseTranslationViewModel';
import { PhraseViewModel } from '../types/PhraseViewModel';

import { findRootTranslation } from './findRootTranslation';
import { defaultPhraseTranslationComparer } from './itemComparers';

const translationService = new TranslationService();

export function buildPhraseTranslationViewModel(
  language: Language | null,
  region: Region | null,
  rootTranslation: PhraseTranslation | undefined,
  existingTranslation?: PhraseTranslation,
): PhraseTranslationViewModel {
  return {
    phraseTranslationId: existingTranslation?.phraseTranslationId ?? null,
    text: existingTranslation?.text || rootTranslation?.text || '',
    isRootLanguage:
      (language?.languageId ?? ROOT_LANGUAGE_ID) === ROOT_LANGUAGE_ID,
    isRootRegion: (region?.regionId ?? ROOT_REGION_ID) === ROOT_REGION_ID,
    isDefaultUserLanguage:
      language?.code === translationService.DEFAULT_USER_LANG,
    isDefaultUserRegion:
      region?.code === translationService.DEFAULT_USER_REGION,
    isInherited: !existingTranslation,
    languageId: language?.languageId ?? ROOT_LANGUAGE_ID,
    languageName: language?.name ?? '',
    languageCode: language?.code ?? '',
    regionName: region?.name ?? '',
    regionId: region?.regionId ?? null,
    regionCode: region?.code ?? '',
    pendingApiCall: existingTranslation?.pendingApiCall,
  };
}
function findTranslation(p: PhraseWithTranslations, l: Language, r: Region) {
  return p.phraseTranslations.find(
    pt => pt.languageId === l.languageId && pt.regionId === r.regionId,
  );
}

export function buildEditorViewModel(
  p: PhraseWithTranslations,
  languages: Language[],
) {
  const rootTranslation = findRootTranslation(p);
  const rootTranslationViewModel = buildPhraseTranslationViewModel(
    null,
    null,
    rootTranslation,
    undefined,
  );

  const model: PhraseViewModel = {
    phraseId: p.phraseId,
    phraseKey: p.phraseKey,
    categoryName: p.categoryName,
    description: p.description ?? '',
    translations: [rootTranslationViewModel],
  };

  for (const l of languages) {
    for (const r of l.regions) {
      const existingTranslation = findTranslation(p, l, r);
      const regionTranslation = buildPhraseTranslationViewModel(
        l,
        r,
        rootTranslation,
        existingTranslation,
      );
      model.translations.push(regionTranslation);
    }
    // NOTE: For whatever reason, we don't have the "make sure ROOT region for every language"
    // on our default language. No idea why.
    if (l.code === translationService.DEFAULT_USER_LANG) {
      continue;
    }

    // Ensure we have a regional root for the language. I.e. Language/Any region
    if (
      model.translations.some(
        t => t.languageId === l.languageId && t.isRootRegion,
      )
    ) {
      continue;
    }

    const rootRegion = buildPhraseTranslationViewModel(
      l,
      null,
      rootTranslation,
    );
    model.translations.push(rootRegion);
  }

  // Presort the languages since our display logic depends on it for grouping.
  // A bit hacky but it works.
  // WARNING: May need rethink this in case we get user selectable sort ordering at some point in the future
  model.translations.sort(defaultPhraseTranslationComparer);

  return model;
}

export function buildEditorViewModels(
  phrases: PhraseWithTranslations[],
  languages: Language[],
): PhraseViewModel[] {
  const viewModels = phrases.map(p => buildEditorViewModel(p, languages));
  return viewModels;
}
