import React from 'react';

import PropTypes from 'prop-types';

const StorySectionHeader = ({ label }) => (
  <div className="tk-story-section__header">{label}</div>
);

StorySectionHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default StorySectionHeader;
