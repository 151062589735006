import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const FormatCode = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z" />
  </svg>
);
FormatCode.propTypes = { fill: PropTypes.string };
FormatCode.defaultProps = { fill: iconColorsMap.mainicon };

export default FormatCode;
