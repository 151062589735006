import { ROOT_LANGUAGE_ID, ROOT_REGION_ID } from '../constants';
import { PhraseWithTranslations } from '../types/api/PhraseWithTranslations';
import { PhraseViewModel } from '../types/PhraseViewModel';

export function findRootTranslation(p: PhraseWithTranslations) {
  return p.phraseTranslations.find(
    t => t.languageId === ROOT_LANGUAGE_ID && t.regionId === ROOT_REGION_ID,
  );
}

export function findRootTranslationOnViewModel(p: PhraseViewModel) {
  return p.translations.find(t => t.isRootLanguage && t.isRootRegion);
}
