import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';

import {
  ADOBE_ANALYTICS_TYPE,
  ADOBE_ANALYTICS_PHASES,
  GOOGLE_ANALYTICS_PHASES,
  GOOGLE_BASE_URL,
} from 'constants/analytics-integration';
import {
  AUDIENCE_TRACKING_DOMAINS,
  ANALYTICS_INTEGRATION_ENDPOINT,
  ANALYTICS_INTEGRATION_ENDPOINT_BETA,
  SERVICE_ACCOUNT_ENABLED,
  DEVFF_ENDPOINT,
} from 'constants/apis';
import { ANALYTICS_INTEGRATION_TYPES, DEV_FEATURES } from 'constants/constants';

import messages from 'pages/AppSettings/app-settings.messages.js';

import { addPageMessage } from 'reducers/page-messages';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import {
  performGet,
  performPost,
  performPut,
  performDelete,
  performPatch,
} from 'services/rest-service/rest-service';

export const UPDATE_GA_DATA = 'appSettings/UPDATE_GA_DATA';
export const UPDATE_SELECT_DATA = 'appSettings/UPDATE_SELECT_DATA';
export const GET_TRACKING_DOMAINS = 'appSettings/GET_TRACKING_DOMAINS';
export const SET_ADOBE_ACCOUNTS = 'appSettings/SET_ADOBE_ACCOUNTS';
export const SET_ADOBE_CARD_IS_LOADING =
  'appSettings/SET_ADOBE_CARD_IS_LOADING';
export const SET_ADOBE_CARD_PHASE = 'appSettings/SET_ADOBE_CARD_PHASE';
export const GET_USER_VIEWS = 'appSettings/GET_USER_VIEWS';
export const FORMAT_GOOGLE_DATA = 'appSettings/FORMAT_GOOGLE_DATA';
export const ADD_ENABLED_VIEW = 'appSettings/ADD_ENABLED_VIEW';
export const SET_ENABLED_VIEWS = 'appSettings/SET_ENABLED_VIEWS';
export const REMOVE_ENABLED_VIEW = 'appSettings/REMOVE_ENABLED_VIEW';
export const SET_GA_CARD_PHASE = 'appSettings/SET_GA_CARD_PHASE';
export const SET_GA_CARD_IS_LOADING = 'appSettings/SET_GA_CARD_IS_LOADING';
export const UPDATE_GA_COUNTER = 'appSettings/UPDATE_GA_COUNTER';
export const RESTART_GA_COUNTER = 'appSettings/RESTART_GA_COUNTER';
export const GET_SERVICE_ACCOUNT = 'appSettings/GET_SERVICE_ACCOUNT';
export const UPDATE_GA_VERSION = 'appSettings/UPDATE_GA_VERSION';
export const UPDATE_DELETE_GA_CONSTRAINTS_DATA =
  'appSettings/UPDATE_DELETE_GA_CONSTRAINTS_DATA';
export const TOGGLE_DELETE_GA_MODAL = 'appSettings/TOGGLE_DELETE_GA_MODAL';

const initialState = {
  domains: [],
  gaCounter: 0,
  isAdobeCardLoading: false,
  isGaCardLoading: false,
  currentAdobeCardPhase:
    window.appInfo.adobeAccounts && window.appInfo.adobeAccounts.length > 0
      ? ADOBE_ANALYTICS_PHASES.accountsIntegrated
      : ADOBE_ANALYTICS_PHASES.preIntegration,
  adobeAccounts: [],
  GaAccounts: [],
  GaProperties: [],
  GaViews: [],
  GaData: [],
  SelectGaAccounts: [],
  enabledViews: [],
  selectAllProperties: [],
  currentGaCardPhase: GOOGLE_ANALYTICS_PHASES.viewsIntegrated,
  serviceAccount: '',
  gav4: false,
  modelDeleteGaData: [],
  isModalDeleteGaOpen: false,
};

const appSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRACKING_DOMAINS: {
      return {
        ...state,
        domains: action.payload,
      };
    }
    case UPDATE_GA_DATA: {
      return {
        ...state,
        GaData: action.payload,
      };
    }
    case ADD_ENABLED_VIEW: {
      const viewsToAdd = [...state.enabledViews, action.payload];
      return {
        ...state,
        enabledViews: viewsToAdd,
      };
    }
    case SET_ENABLED_VIEWS: {
      return {
        ...state,
        enabledViews: action.payload,
      };
    }
    case REMOVE_ENABLED_VIEW: {
      return {
        ...state,
        enabledViews: action.payload,
      };
    }
    case GET_USER_VIEWS: {
      const views = state.GaViews;
      views.push(action.payload);
      return {
        ...state,
        GaViews: views,
      };
    }
    case FORMAT_GOOGLE_DATA: {
      return {
        ...state,
        SelectGaAccounts: action.payload,
      };
    }
    case SET_ADOBE_ACCOUNTS: {
      return {
        ...state,
        adobeAccounts: action.payload,
      };
    }

    case SET_ADOBE_CARD_IS_LOADING: {
      return {
        ...state,
        isAdobeCardLoading: action.payload,
      };
    }

    case SET_GA_CARD_IS_LOADING: {
      return {
        ...state,
        isGaCardLoading: action.payload,
      };
    }

    case SET_ADOBE_CARD_PHASE: {
      return {
        ...state,
        currentAdobeCardPhase: action.payload,
      };
    }

    case SET_GA_CARD_PHASE: {
      return {
        ...state,
        currentGaCardPhase: action.payload,
      };
    }

    case UPDATE_SELECT_DATA: {
      return {
        ...state,
        SelectGaAccounts: action.payload,
      };
    }

    case UPDATE_GA_COUNTER: {
      return {
        ...state,
        gaCounter: state.gaCounter + 1,
      };
    }
    case RESTART_GA_COUNTER: {
      return {
        ...state,
        gaCounter: 0,
      };
    }

    case GET_SERVICE_ACCOUNT: {
      return {
        ...state,
        serviceAccount: action.payload,
      };
    }

    case UPDATE_GA_VERSION: {
      return {
        ...state,
        gav4: action.payload,
      };
    }

    case UPDATE_DELETE_GA_CONSTRAINTS_DATA: {
      return {
        ...state,
        modalDeleteGaData: action.payload,
      };
    }

    case TOGGLE_DELETE_GA_MODAL: {
      return {
        ...state,
        isModalDeleteGaOpen: !state.isModalDeleteGaOpen,
      };
    }

    default:
      return state;
  }
};

export const getTrackingDomains = () => dispatch => {
  performGet(`${AUDIENCE_TRACKING_DOMAINS}`)
    .then(response => {
      dispatch({
        type: GET_TRACKING_DOMAINS,
        payload: response.data,
      });
    })
    .catch(() => {});
};

export const getGaExistingData = existingData => dispatch => {
  const accountsContainer = [];
  if (existingData && existingData.length > 0) {
    const sortedExistingData = existingData.sort((prevView, nextView) =>
      prevView.name ? prevView.name.localeCompare(nextView.name) : 1,
    );
    const groupAccountsObj = groupBy(
      sortedExistingData,
      account => account.gaAccountId,
    );

    forEach(groupAccountsObj, (value, key) => {
      groupAccountsObj[key] = groupBy(
        groupAccountsObj[key],
        item => item.webPropertyId,
      );
    });

    Object.keys(groupAccountsObj).forEach(accounts => {
      const account = {
        gaAccountId: accounts,
        gaAccountName: sortedExistingData.find(
          gaAccount => gaAccount.gaAccountId === accounts,
        ).gaAccountName,
        properties: Object.keys(groupAccountsObj[accounts]).map(properties => {
          return {
            webPropertyId: properties,
            webPropertyName: sortedExistingData.find(
              gaAccount => gaAccount.webPropertyId === properties,
            ).webPropertyName,
            views: groupAccountsObj[accounts][properties].map(views => {
              return {
                gaId: views.gaId,
                name: views.name,
                alias: views.alias,
                isDefault: views.isDefault,
                enabled: views.enabled,
                lastPolled: views.lastPolled,
                id: views.analyticsId,
                foreignId: views.id,
                dateCreated: views.dateCreated,
              };
            }),
          };
        }),
      };
      accountsContainer.push(account);
    });
    window.appInfo.gaAccounts = existingData;
  }
  dispatch({
    type: UPDATE_GA_DATA,
    payload: accountsContainer,
  });
  return null;
};

export const setGaCardIsLoading = isLoading => ({
  type: SET_GA_CARD_IS_LOADING,
  payload: isLoading,
});

export const setAdobeCardIsLoading = isLoading => ({
  type: SET_ADOBE_CARD_IS_LOADING,
  payload: isLoading,
});

export const setAdobeCardPhase = phase => ({
  type: SET_ADOBE_CARD_PHASE,
  payload: phase,
});

export const setEnabledViews = enabledViews => ({
  type: SET_ENABLED_VIEWS,
  payload: enabledViews,
});

export const setGaSelectData = selectedData => ({
  type: UPDATE_SELECT_DATA,
  payload: selectedData,
});

export const setGaCardPhase = phase => (dispatch, getState) => {
  const currentState = getState().appSettings.currentGaCardPhase;
  if (currentState === GOOGLE_ANALYTICS_PHASES.selectViews) {
    dispatch(setEnabledViews([]));
    dispatch(setGaSelectData([]));
  }
  //WAGZ-218: CX and Product request: Have it simply display the email address and the setup manually page without the user having to click setup manually
  if (
    currentState === GOOGLE_ANALYTICS_PHASES.viewsIntegrated &&
    phase === GOOGLE_ANALYTICS_PHASES.preIntegration
  ) {
    phase = GOOGLE_ANALYTICS_PHASES.manualIntegration;
  } else {
    if (
      currentState === GOOGLE_ANALYTICS_PHASES.manualIntegration &&
      phase === GOOGLE_ANALYTICS_PHASES.preIntegration
    ) {
      phase = GOOGLE_ANALYTICS_PHASES.viewsIntegrated;
    }
  }

  dispatch({ type: SET_GA_CARD_PHASE, payload: phase });
};

export const setAdobeIntegrations = integrations => dispatch => {
  if (integrations.length > 0) {
    dispatch(setAdobeCardPhase(ADOBE_ANALYTICS_PHASES.accountsIntegrated));
  } else {
    dispatch(setAdobeCardPhase(ADOBE_ANALYTICS_PHASES.preIntegration));
  }
  dispatch({ type: SET_ADOBE_ACCOUNTS, payload: integrations });
};

export const setAdobeAccount = data => dispatch => {
  dispatch(setAdobeCardIsLoading(true));
  performPost(`${ANALYTICS_INTEGRATION_ENDPOINT}`, data)
    .then(response => {
      const accounts = response.data.accounts
        .map(account => ({
          id: account.analytics_id,
          name: account.analytics_integration_name,
          isEnabled: account.is_enabled,
          isDefault: account.is_default,
          foreignId: account.analytics_foreign_id,
          alias: account.analytics_integration_alias,
          type: account.analytics_integration_type,
        }))
        .sort((prevIntegration, nextIntegration) =>
          (prevIntegration.alias || prevIntegration.name).localeCompare(
            nextIntegration.alias || nextIntegration.name,
          ),
        );
      dispatch(setAdobeIntegrations(accounts));
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: 'Success! New account added!',
          status: 'success',
          ttl: 3000,
        }),
      );
    })
    .catch(() => {
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: 'Could not set new account, please try later',
          status: 'danger',
        }),
      );
    })
    .finally(() => dispatch(setAdobeCardIsLoading(false)));
};

export const setAppSettingsIntegrations = appInfo => dispatch => {
  window.appInfo = appInfo;
  dispatch(getGaExistingData(appInfo.gaAccounts));
  dispatch(
    setAdobeIntegrations(
      appInfo.analyticsIntegrations
        .reduce((integrations, integration) => {
          if (
            integration.analyticsIntegrationName &&
            integration.analyticsIntegrationType === ADOBE_ANALYTICS_TYPE
          ) {
            integrations.push({
              id: integration.analyticsId,
              name: integration.analyticsIntegrationName,
              isEnabled: integration.isEnabled,
              isDefault: integration.isDefault,
              foreignId: integration.analyticsForeignId,
              alias: integration.analyticsIntegrationAlias,
              type: integration.analyticsIntegrationType,
            });
          }
          return integrations;
        }, [])
        .sort((prevIntegration, nextIntegration) =>
          (prevIntegration.alias || prevIntegration.name).localeCompare(
            nextIntegration.alias || nextIntegration.name,
          ),
        ),
    ),
  );
};

export const setIsLoadingOnAllCards = isLoading => dispatch => {
  dispatch(setAdobeCardIsLoading(isLoading));
  dispatch(setGaCardIsLoading(isLoading));
};

export const getUserViewsLimited = (
  token,
  accountId,
  propertyId,
  SelectGaAccounts,
  findAccount,
) => dispatch => {
  performGet(
    `${GOOGLE_BASE_URL}/${accountId}/webproperties/${propertyId}/profiles`,
    null,
    token,
  ).then(viewsResponse => {
    viewsResponse.data.items.forEach(view => {
      const findProperty = findAccount.properties.find(
        selectProperty => selectProperty.id === propertyId,
      );
      const currentView = {
        id: view.id,
        name: view.name,
        isEnabled: false,
        isDefault: false,
      };
      findProperty.views.push(currentView);
    });
    dispatch({
      type: UPDATE_SELECT_DATA,
      payload: SelectGaAccounts,
    });
  });
};

export const getUserViews = (token, accountId) => (dispatch, getState) => {
  const SelectGaAccounts = getState().appSettings.SelectGaAccounts;
  const findAccount = SelectGaAccounts.find(
    selectAccount => selectAccount.id === accountId,
  );
  findAccount.properties.forEach(property => {
    dispatch({ type: UPDATE_GA_COUNTER });
    const gaCounter = getState().appSettings.gaCounter;
    // GA accepts only 10 requests per second, so when the counter is 10, wait 1.1 seconds to get the next request.
    if (gaCounter > 10) {
      dispatch(
        getUserViewsLimited(
          token,
          accountId,
          property.id,
          SelectGaAccounts,
          findAccount,
        ),
      );
      setTimeout(() => dispatch(setGaCardIsLoading(false)), 1000);
    } else {
      dispatch({ type: RESTART_GA_COUNTER });
      setTimeout(() => {
        dispatch(
          getUserViewsLimited(
            token,
            accountId,
            property.id,
            SelectGaAccounts,
            findAccount,
          ),
        );
        dispatch(setGaCardIsLoading(false));
      }, 1010);
    }
  });
  dispatch({ type: RESTART_GA_COUNTER });
};

export const getUserProperties = token => (dispatch, getState) => {
  const SelectGaAccounts = getState().appSettings.SelectGaAccounts;
  SelectGaAccounts.forEach(account => {
    performGet(
      `${GOOGLE_BASE_URL}/${account.id}/webproperties`,
      null,
      token,
    ).then(propertiesResponse => {
      const findAccount = SelectGaAccounts.find(
        selectAccount => selectAccount.id === account.id,
      );
      propertiesResponse.data.items.forEach(property => {
        const currentProperty = {
          id: property.id,
          name: property.name,
          selectAll: false,
          views: [],
        };
        findAccount.properties.push(currentProperty);
      });
      dispatch(setGaSelectData(SelectGaAccounts));
      dispatch(getUserViews(token, account.id));
    });
  });
};

export const getUserAccounts = token => (dispatch, getState) => {
  dispatch(setGaCardIsLoading(true));
  const SelectGaAccounts = getState().appSettings.SelectGaAccounts;
  performGet(GOOGLE_BASE_URL, null, token).then(accountsResponse => {
    accountsResponse.data.items.forEach(account => {
      const currentAccount = {
        id: account.id,
        name: account.name,
        properties: [],
      };
      SelectGaAccounts.push(currentAccount);
    });
    dispatch(setGaSelectData(SelectGaAccounts));
    dispatch(getUserProperties(token));
  });
};

export const toggleViewEnabled = (accountId, propertyId, viewId) => (
  dispatch,
  getState,
) => {
  const SelectGaAccounts = getState().appSettings.SelectGaAccounts;
  const findAccount = SelectGaAccounts.find(
    selectAccount => selectAccount.id === accountId,
  );
  const findProperty = findAccount.properties.find(
    selectProperty => selectProperty.id === propertyId,
  );
  const findView = findProperty.views.find(
    selectView => selectView.id === viewId,
  );
  findView.isEnabled = !findView.isEnabled;
  const viewEnabled = {
    gaAccountId: findAccount.id,
    gaAccountName: findAccount.name,
    webPropertyId: findProperty.id,
    webPropertyName: findProperty.name,
    gaId: findView.id,
    name: findView.name,
  };
  if (findView.isEnabled === true) {
    dispatch({ type: ADD_ENABLED_VIEW, payload: viewEnabled });
  } else {
    const currentViews = getState().appSettings.enabledViews;
    dispatch(
      setEnabledViews(currentViews.filter(view => view.gaId !== viewId)),
    );
  }
  dispatch(setGaSelectData(SelectGaAccounts));
};

export const setDefaultIntegration = integration => dispatch => {
  dispatch(setIsLoadingOnAllCards(true));
  performPut(
    `${ANALYTICS_INTEGRATION_ENDPOINT}${integration.analytics_id}`,
    {},
    integration,
  )
    .then(res => {
      if (!res.data.errMessage) {
        dispatch(setAppSettingsIntegrations(res.data.appInfo));
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: 'Success! New default added!',
            status: 'success',
            ttl: 3000,
          }),
        );
      } else {
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: 'Could not set new default, please try later',
            status: 'danger',
          }),
        );
      }
    })
    .catch(() =>
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: 'Could not set new default, please try later',
          status: 'danger',
        }),
      ),
    )
    .finally(() => dispatch(setIsLoadingOnAllCards(false)));
};

export const saveEnabledViews = enabledViews => dispatch => {
  if (enabledViews.length === 0) {
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Please select at least one view to add.',
        status: 'danger',
      }),
    );
  } else {
    dispatch(setGaCardIsLoading(true));
    performPost(ANALYTICS_INTEGRATION_ENDPOINT_BETA, enabledViews, null)
      //TODO: SHOW GROWL OF SUCCESS/ERROR TO THE USER
      .then(res => {
        const integrations = res.data;
        if (integrations && integrations.length > 0) {
          const alreadyExistingIntegrationTextList = integrations
            .filter(integration => integration.status === 304)
            .map(integration => integration.name)
            .join(', ');
          if (alreadyExistingIntegrationTextList.length > 0) {
            dispatch(
              addPageMessage({
                isNewUI: true,
                text: `The following views are already mapped to other Trendkite account(s): ${alreadyExistingIntegrationTextList}.`,
                status: 'danger',
              }),
            );
          }
          dispatch(
            getGaExistingData(
              integrations
                .reduce((newIntegrations, integration) => {
                  if (integration.status !== 304) {
                    newIntegrations.push({
                      ...integration.account,
                      analyticsId: integration.integrationInfo.id,
                    });
                  }
                  return newIntegrations;
                }, [])
                .concat(window.appInfo.gaAccounts),
            ),
          );
        }
        dispatch(setGaCardPhase(GOOGLE_ANALYTICS_PHASES.viewsIntegrated));
      })
      .catch(() =>
        dispatch(setGaCardPhase(GOOGLE_ANALYTICS_PHASES.viewsIntegrated)),
      )
      .finally(() => dispatch(setGaCardIsLoading(false)));
  }
};

export const deleteGaView = integration => dispatch => {
  dispatch(setGaCardIsLoading(true));
  performDelete(ANALYTICS_INTEGRATION_ENDPOINT, integration)
    .then(res => {
      const errMessages = res.data.errMessages;
      if (errMessages && !errMessages.gaIntegration) {
        const gaAccounts = window.appInfo.gaAccounts.filter(
          gaIntegration => gaIntegration.id !== integration.foreignId,
        );
        window.appInfo.gaAccounts = gaAccounts;
        dispatch(getGaExistingData(gaAccounts));
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: 'Success! View removed!',
            status: 'success',
            ttl: 3000,
          }),
        );
      } else {
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: 'Could not remove view, please try later',
            status: 'danger',
          }),
        );
      }
    })
    .catch(error => {
      if (error?.response?.status === 409) {
        const tableData = getTableData(error.response.data);

        dispatch({
          type: UPDATE_DELETE_GA_CONSTRAINTS_DATA,
          payload: tableData,
        });

        dispatch({ type: TOGGLE_DELETE_GA_MODAL });
      } else {
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: 'Could not remove view, please try later',
            status: 'danger',
          }),
        );
      }
    })
    .finally(() => dispatch(setGaCardIsLoading(false)));
};

export const selectAllViews = (accountId, propertyId) => (
  dispatch,
  getState,
) => {
  const SelectGaAccounts = getState().appSettings.SelectGaAccounts;
  const GaData = getState().appSettings.GaData;
  const newSelectAccounts = SelectGaAccounts.map(item => {
    return {
      ...item,
    };
  });
  const findAccount = newSelectAccounts.find(
    selectAccount => selectAccount.id === accountId,
  );
  const findProperty = findAccount.properties.find(
    selectProperty => selectProperty.id === propertyId,
  );
  const findGaDataAccount = GaData.find(
    gaAccount => gaAccount.gaAccountId === accountId,
  );
  let findGaDataProperty;
  let findGaDataView;
  if (findGaDataAccount) {
    findGaDataProperty = findGaDataAccount.properties.find(
      gaProperty => gaProperty.webPropertyId === propertyId,
    );
  }
  findProperty.views.forEach(view => {
    if (findGaDataProperty) {
      findGaDataView = findGaDataProperty.views.find(
        gaView => gaView.gaId === view.id,
      );
    }
    if (!findGaDataView) {
      view.isEnabled = !findProperty.selectAll;
      const viewEnabled = {
        gaAccountId: findAccount.id,
        gaAccountName: findAccount.name,
        webPropertyId: findProperty.id,
        webPropertyName: findProperty.name,
        gaId: view.id,
        name: view.name,
        alias: view.alias,
      };
      if (view.isEnabled === true) {
        dispatch({ type: ADD_ENABLED_VIEW, payload: viewEnabled });
      } else {
        const currentViews = getState().appSettings.enabledViews;
        dispatch(
          setEnabledViews(
            currentViews.filter(viewFilter => viewFilter.gaId !== view.id),
          ),
        );
      }
    }
  });
  findProperty.selectAll = !findProperty.selectAll;
  dispatch(setGaSelectData(newSelectAccounts));
};

export const showKite = (text, status, ttl) => dispatch => {
  dispatch(
    addPageMessage({
      isNewUI: true,
      text,
      status,
      ttl: ttl || null,
    }),
  );
};

export const fetchAnalyticsIntegrations = shouldLoad => dispatch => {
  if (shouldLoad) {
    dispatch(setIsLoadingOnAllCards(true));
  }
  performGet(ANALYTICS_INTEGRATION_ENDPOINT)
    .then(res => dispatch(setAppSettingsIntegrations(res.data)))
    .finally(() => dispatch(setIsLoadingOnAllCards(false)));
};

export const getEnabledServiceAccount = () => dispatch => {
  performGet(SERVICE_ACCOUNT_ENABLED).then(res =>
    dispatch({
      type: GET_SERVICE_ACCOUNT,
      payload: res.data.address,
    }),
  );
};

export const submitManualFlow = (account, property, views) => dispatch => {
  dispatch(setGaCardIsLoading(true));
  const parameters = {
    ga_account_id: account,
    ga_profile_ids: views,
    ga_tracking_id: property,
    analytics_integration_type: ANALYTICS_INTEGRATION_TYPES.googleAnalytics.id,
  };
  performPost(ANALYTICS_INTEGRATION_ENDPOINT, parameters)
    .then(res => {
      if (res.status === 201) {
        performGet(ANALYTICS_INTEGRATION_ENDPOINT)
          .then(response => {
            dispatch(getGaExistingData(response.data.gaAccounts));
            dispatch(
              addPageMessage({
                isNewUI: true,
                text: 'Success! View(s) added!',
                status: 'success',
              }),
            );
            dispatch(
              setGaCardPhase(
                window.appInfo.gaAccounts &&
                  window.appInfo.gaAccounts.length > 0
                  ? GOOGLE_ANALYTICS_PHASES.viewsIntegrated
                  : GOOGLE_ANALYTICS_PHASES.preIntegration,
              ),
            );
          })
          .finally(() => dispatch(setGaCardIsLoading(false)));
      } else if (res.status === 200) {
        dispatch(
          addPageMessage({
            isNewUI: true,
            text: 'View(s) duplicated',
            status: 'danger',
          }),
        );
        dispatch(setGaCardIsLoading(false));
      }
    })
    .catch(() => {
      dispatch(
        addPageMessage({
          isNewUI: true,
          text:
            'There was an error trying to add the View(s). Please try again later.',
          status: 'danger',
        }),
      );
      dispatch(setGaCardIsLoading(false));
    });
};

export const fetchGaVersion = (intl, account) => async dispatch => {
  const userCanToggleGaVersion = userHasDevFeatureFlag(
    DEV_FEATURES.canToggleGaVersion,
  );

  if (!userCanToggleGaVersion) {
    return;
  }

  const parameters = {
    account,
    devFeatureName: DEV_FEATURES.googleAnalyticsV4,
  };

  const endpoint = DEVFF_ENDPOINT.replace('{accountId}', account);
  try {
    const response = await performGet(endpoint, parameters);
    const gaVersion = response?.data?.value;
    dispatch({
      type: UPDATE_GA_VERSION,
      payload: gaVersion,
    });
    return gaVersion;
  } catch (e) {
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.fetchGaVersionError),
        status: 'danger',
      }),
    );
  }
  return null;
};

export const submitToggleGa = (intl, account, gav4) => async dispatch => {
  dispatch(setGaCardIsLoading(true));
  const parameters = {
    account: account,
    devFeatureName: DEV_FEATURES.googleAnalyticsV4,
    value: gav4,
  };

  const endpoint = DEVFF_ENDPOINT.replace('{accountId}', account);
  performPatch(endpoint, parameters)
    .then(res => {
      if (res !== null && res.status === 200) {
        window.location.reload();
      } else {
        dispatch(setGaCardIsLoading(false));
      }
    })
    .catch(err => {
      const toggleGaVersionErrorMessage = intl.formatMessage(
        messages.toggleGaVersionError,
        {
          GA_VERSION: gav4 ? intl.formatNumber(4) : intl.formatNumber(3),
        },
      );
      dispatch(setGaCardIsLoading(false));
      dispatch(
        addPageMessage({
          isNewUI: true,
          text: toggleGaVersionErrorMessage,
          status: 'danger',
        }),
      );
      throw new Error(err);
    });
};

export const getTableData = data => {
  const tableData = data?.dashboardsWidgets?.map(item => {
    return {
      dashboardId: item.dashboard_id,
      dashboardTitle: item.dashboard_title,
      widgetId: item.widget_id,
      widgetTitle: item.widget_title,
      accountId: item.account_id,
    };
  });
  return tableData;
};

export const toggleDeleteModal = () => ({ type: TOGGLE_DELETE_GA_MODAL });

export default appSettingsReducer;
