import { STORY_BASE_ENDPOINT } from 'constants/apis';
import { STORY_KIT_SHARE_LEVELS } from 'constants/constants';
import { getCampaignStoriesByCampaignId } from 'reducers/campaigns/campaign-stories';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import {
  performGet,
  performPatch,
  performPost,
  requestMultiple,
} from 'services/rest-service/rest-service';

export const GET_MY_STORIES = 'stories/GET_MY_STORIES';
export const GET_MY_STORIES_ERROR = 'stories/GET_MY_STORIES_ERROR';
export const GET_MY_STORIES_RECEIVED = 'stories/GET_MY_STORIES_RECEIVED';
export const DELETE_STORY_START = 'stories/DELETE_STORY_START';
export const DELETE_STORY_RECEIVED = 'stories/DELETE_STORY_RECEIVED';
export const DELETE_STORY_ERROR = 'stories/DELETE_STORY_ERROR';
export const DUPLICATE_STORY_START = 'stories/DUPLICATE_STORY_START';
export const DUPLICATE_STORY_RECEIVED = 'stories/DUPLICATE_STORY_RECEIVED';
export const DUPLICATE_STORY_ERROR = 'stories/DUPLICATE_STORY_ERROR';
export const ADD_STORY_TO_CAMPAIGN_SUCCESS =
  'stories/ADD_STORY_TO_CAMPAIGN_SUCCESS';
export const SET_STORY_PERMISSIONS_START =
  'stories/SET_STORY_PERMISSIONS_START';
export const SET_STORY_PERMISSIONS_UPDATE =
  'stories/SET_STORY_PERMISSIONS_UPDATE';
export const SET_STORY_PERMISSIONS_SUCCESS =
  'stories/SET_STORY_PERMISSIONS_SUCCESS';
export const SET_STORY_PERMISSIONS_ERROR =
  'stories/SET_STORY_PERMISSIONS_ERROR';
export const SET_SELECTED_STORY_MODIFIED_SHARE_DATA =
  'stories/SET_SELECTED_STORY_MODIFIED_SHARE_DATA';

const initialState = {
  stories: [],
  error: false,
  loadingStories: true,
  selectedStoryModifiedShareData: {},
};

const myStoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_STORIES:
    case DELETE_STORY_START:
    case DUPLICATE_STORY_START:
    case SET_STORY_PERMISSIONS_START:
      return { ...state, loadingStories: true };
    case GET_MY_STORIES_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingStories: false,
      };
    case GET_MY_STORIES_RECEIVED:
      return {
        ...state,
        stories: action.payload.stories,
        loadingStories: false,
      };
    case DELETE_STORY_RECEIVED: {
      const { selectedStories } = action.payload;
      const storyIDs = selectedStories.map(storyInfo => {
        return storyInfo.id;
      });
      const filteredStories = state.stories.filter(
        story => storyIDs.indexOf(story.id) === -1,
      );
      return {
        ...state,
        stories: filteredStories,
        loadingStories: false,
      };
    }
    case DELETE_STORY_ERROR:
      return {
        ...state,
        error: true,
        loadingStories: false,
      };
    case DUPLICATE_STORY_RECEIVED: {
      const { duplicatedStories } = action.payload;

      // API returns ENUM object. Only set the string value as state
      const stories = duplicatedStories.map(ds => {
        ds.state = ds.state.name;
        return ds;
      });

      return {
        ...state,
        stories: [...state.stories, ...stories],
        loadingStories: false,
      };
    }
    case DUPLICATE_STORY_ERROR: {
      return {
        ...state,
        error: true,
        loadingStories: false,
      };
    }
    case ADD_STORY_TO_CAMPAIGN_SUCCESS: {
      return {
        ...state,
      };
    }

    case SET_STORY_PERMISSIONS_UPDATE: {
      const index = state.stories.findIndex(i => i.id === action.payload.id);
      const newStories = [...state.stories];
      newStories[index] = action.payload;
      return {
        ...state,
        stories: newStories,
      };
    }

    case SET_STORY_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        loadingStories: false,
      };
    }

    case SET_SELECTED_STORY_MODIFIED_SHARE_DATA: {
      return {
        ...state,
        selectedStoryModifiedShareData: action.payload,
      };
    }

    default:
      return state;
  }
};

export const setShareStoryData = story => dispatch => {
  dispatch({ type: SET_SELECTED_STORY_MODIFIED_SHARE_DATA, payload: story });
};

export const getMyStoriesActionDispactcher = () => dispatch => {
  dispatch({ type: GET_MY_STORIES });
  performGet(`${STORY_BASE_ENDPOINT}`, null, {
    cache: 'max-age=0, private, must-revalidate',
  })
    .then(response => {
      const payload = response.data;
      dispatch({
        type: GET_MY_STORIES_RECEIVED,
        payload: {
          ...payload,
          stories: payload.results,
        },
      });
    })
    .catch(err => {
      dispatch({ type: GET_MY_STORIES_ERROR, payload: err });
      throw err;
    });
};

export const setStoryPermissionsActionDispatcher = ({
  storyId,
  sharePermission,
  campaignId,
}) => async dispatch => {
  let shared;
  let shareLevel;

  if (sharePermission === 'none') {
    shared = false;
  }
  if (sharePermission === 'r') {
    shared = true;
    shareLevel = STORY_KIT_SHARE_LEVELS.view;
  }
  if (sharePermission === 'rw') {
    shared = true;
    shareLevel = STORY_KIT_SHARE_LEVELS.edit;
  }

  try {
    dispatch({ type: SET_STORY_PERMISSIONS_START });
    const response = await performPatch(`${STORY_BASE_ENDPOINT}/${storyId}`, {
      shared,
      shareLevel,
    });
    dispatch({ type: SET_STORY_PERMISSIONS_UPDATE, payload: response.data });
    dispatch({ type: SET_STORY_PERMISSIONS_SUCCESS });

    if (campaignId) {
      dispatch(getCampaignStoriesByCampaignId(campaignId));
    }
  } catch (e) {
    dispatch({ type: SET_STORY_PERMISSIONS_ERROR });
  }
};

export const deleteStoryByIdActionDispatcher = selectedStories => dispatch => {
  dispatch({ type: DELETE_STORY_START });

  const requestObjs = selectedStories.map(story => {
    return {
      url: `${STORY_BASE_ENDPOINT}/${story.id}`,
      method: 'delete',
    };
  });

  requestMultiple(requestObjs)
    .then(() => {
      dispatch({
        type: DELETE_STORY_RECEIVED,
        payload: { selectedStories },
      });
    })
    .catch(() => {
      dispatch({
        type: DELETE_STORY_ERROR,
      });
    });
};

export const duplicateStoryByIdActionDispatcher = selectedStories => dispatch => {
  dispatch({ type: DUPLICATE_STORY_START });

  const requestObjs = selectedStories.map(story => {
    return {
      url: `${STORY_BASE_ENDPOINT}/${story.id}/duplicate`, // TODO: Determine if new action is required
      method: 'post',
    };
  });

  requestMultiple(requestObjs)
    .then(responses => {
      const stories = responses.reduce((a, r) => {
        a.push(r.data);
        return a;
      }, []);

      dispatch({
        type: DUPLICATE_STORY_RECEIVED,
        payload: {
          duplicatedStories: [...stories],
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DUPLICATE_STORY_ERROR,
      });
    });
};

export const addStoryToCampaignActionCreator = (
  storyId,
  campaigns,
) => async dispatch => {
  const campaignIdList = [];
  try {
    campaigns.forEach(obj => {
      campaignIdList.push(obj.id);
    });
    const response = await performPost(
      `${STORY_BASE_ENDPOINT}/${storyId}/campaign`,
      { campaignIdList },
    );

    if (response.status === 200) {
      dispatch({
        type: ADD_STORY_TO_CAMPAIGN_SUCCESS,
        payload: {
          storyId,
          campaignTitles: campaigns.map(c => c.title),
        },
      });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Successfully add story to campaign.',
          status: 'success',
        }),
      );
    }
  } catch (err) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Failed to add story to campaign',
        status: 'danger',
      }),
    );
  }
};

export default myStoriesReducer;
