import PropTypes from 'prop-types';

export const IntlPropType = PropTypes.shape({
  formatDate: PropTypes.func,
  formatMessage: PropTypes.func,
  formatNumber: PropTypes.func,
  formatTime: PropTypes.func,
});

export const ContactType = PropTypes.shape({
  text: PropTypes.string,
  type: PropTypes.string,
});

export const LocationType = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  region: PropTypes.string,
  subregion: PropTypes.string,
});

export const TopicType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  userRelevance: PropTypes.number,
});

export const DeskType = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  faxAreaCode: PropTypes.number,
  faxCountryCode: PropTypes.number,
  faxNumber: PropTypes.string,
  phoneAreaCode: PropTypes.number,
  phoneCountryCode: PropTypes.number,
  phoneNumber: PropTypes.string,
});

export const BusinessCardType = PropTypes.shape({
  outletID: PropTypes.number,
  isDefaultContact: PropTypes.bool,
  businessCardTitle: PropTypes.string,
});

export const AddressType = PropTypes.shape({
  addressType: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  county: PropTypes.string,
  dma: PropTypes.string,
  isDefault: PropTypes.bool,
  isOCountryCode: PropTypes.string,
  latLong: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  line3: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
  stateCode: PropTypes.string,
  timeZoneAbbreviation: PropTypes.string,
  timeZoneDescription: PropTypes.string,
  timeZoneUTCOffset: PropTypes.number,
  town: PropTypes.string,
});

export const OutletType = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  logo: PropTypes.string,
  image: PropTypes.string,
  id: PropTypes.number,
});

export const SocialProfileType = PropTypes.shape({
  badges: PropTypes.array,
  description: PropTypes.string,
  handle: PropTypes.string,
  networkName: PropTypes.string,
  primaryMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  secondaryMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  url: PropTypes.string,
});

export const TopItemsContentType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
});

export const InfluencerType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  addresses: PropTypes.arrayOf(AddressType),
  businessCards: PropTypes.arrayOf(BusinessCardType),
  twitterFollowersCount: PropTypes.number,
  youTubeFollowersCount: PropTypes.number,
  instagramFollowersCount: PropTypes.number,
  twitchFollowersCount: PropTypes.number,
  globeCount: PropTypes.number,
  outletReach: PropTypes.number,
  socialreach: PropTypes.number,
  engagement: PropTypes.number,
  activity: PropTypes.number,
  contentPublished: PropTypes.string,
  mediaType: PropTypes.string,
  outletFrequency: PropTypes.number,
  newFocus: PropTypes.string,
  outlets: PropTypes.arrayOf(OutletType),
  contacts: PropTypes.arrayOf(ContactType),
  location: LocationType,
  topics: PropTypes.arrayOf(TopicType),
  socialProfiles: PropTypes.arrayOf(SocialProfileType),
  pitchingProfile: PropTypes.string,
  biography: PropTypes.string,
  toptopics: PropTypes.oneOfType([
    PropTypes.arrayOf(TopItemsContentType),
    // TwitterAudienceInsights enforces the following shape,
    // but initial state and other locations don't.
    // Possibly this should supplant the other.
    PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.bool,
      otherDocumentCount: PropTypes.number,
      data: PropTypes.arrayOf(TopItemsContentType),
    }),
  ]),
  toplocations: PropTypes.oneOfType([
    PropTypes.arrayOf(TopItemsContentType),
    // TwitterAudienceInsights enforces the following shape,
    // but initial state and other locations don't.
    // Possibly this should supplant the other.
    PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.bool,
      otherDocumentCount: PropTypes.number,
      data: PropTypes.arrayOf(TopItemsContentType),
    }),
  ]),
});

export const TwitterUserType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  screen_name: PropTypes.string.isRequired,
  profile_image_url_https: PropTypes.string,
  profile_image_url: PropTypes.string,
  verified: PropTypes.bool,
});

export const TweetMediaType = PropTypes.shape({
  media_url_https: PropTypes.string,
  media_url: PropTypes.string,
});

export const TweetContentShape = {
  id: PropTypes.number,
  user: TwitterUserType,
  favorite_count: PropTypes.number,
  retweet_count: PropTypes.number,
  text: PropTypes.string,
  created_at: PropTypes.string,
  entities: PropTypes.shape({
    hashtags: PropTypes.array,
    symbols: PropTypes.array,
    urls: PropTypes.array,
    user_mentions: PropTypes.array,
  }),
  extended_entities: PropTypes.shape({
    media: PropTypes.arrayOf(TweetMediaType),
  }),
};

export const TweetContentType = PropTypes.shape(TweetContentShape);

export const TwitchContentType = PropTypes.shape({
  user_name: PropTypes.string,
  description: PropTypes.string,
  created_at: PropTypes.string,
  language: PropTypes.string,
  title: PropTypes.string,
  thumbnail_url: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  duration: PropTypes.string,
  viewable: PropTypes.string,
  user_id: PropTypes.string,
  id: PropTypes.string,
  published_at: PropTypes.string,
  view_count: PropTypes.number,
});

export const YoutubeContentType = PropTypes.shape({
  thumbnail: PropTypes.string,
  like_count: PropTypes.number,
  description: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  published_at: PropTypes.string,
  view_count: PropTypes.number,
  dislike_count: PropTypes.number,
});

export const TweetType = PropTypes.shape({
  ...TweetContentShape,
  retweeted_status: TweetContentType,
  quoted_status: TweetContentType,
});

export const AlsoFollowsContentType = PropTypes.shape({
  name: PropTypes.string,
  jobRole: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  twitter_handle: PropTypes.string,
  profile_image_url_https: PropTypes.string,
  profile_image_url: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  toptopics: PropTypes.arrayOf(PropTypes.string),
  twitterFollowers: PropTypes.number,
  youTubeFollowers: PropTypes.number,
  instagramFollowers: PropTypes.number,
  twitchFollowers: PropTypes.number,
});
