import {
  OpportunitySearchCriteria,
  Pill,
} from 'pages/Influencers/OpportunityList/types/opportunityTypes';

export enum Actions {
  OPPORTUNITY_BULK_PAGE_SELECT_ALL_OPPORTUNITIES = 'OPPORTUNITY_BULK_PAGE_SELECT_ALL_OPPORTUNITIES',
  OPPORTUNITY_BULK_PAGE_UNSELECT_ALL_OPPORTUNITIES = 'OPPORTUNITY_BULK_PAGE_UNSELECT_ALL_OPPORTUNITIES',
  OPPORTUNITY_SELECTED_ID = 'OPPORTUNITY_SELECTED_ID',
  OPPORTUNITY_UNSELECTED_ID = 'OPPORTUNITY_UNSELECTED_ID',
  OPPORTUNITY_SET_SEARCH_CRITERIA = 'OPPORTUNITY_SET_SEARCH_CRITERIA',
  OPPORTUNITY_CLEAR_SEARCH = 'OPPORTUNITY_CLEAR_SEARCH',
  OPPORTUNITY_TOGGLE_FILTER_PANEL = 'TOGGLE_OPPORTUNITY_FILTER_PANEL',
  OPPORTUNITY_CHOSEN_TOPICS = 'OPPORTUNITY_CHOSEN_TOPICS',
  OPPORTUNITY_SET_FORCED_SEARCH_CRITERIA = 'OPPORTUNITY_SET_FORCED_SEARCH_CRITERIA',
  OPPORTUNITY_SET_DEADLINE_DATE_RANGE = 'OPPORTUNITY_SET_DEADLINE_DATE_RANGE',
}

export type ForcedSearchCriteria = Pick<
  OpportunitySearchCriteria,
  'dataSourceIds'
>;

interface BulkSelectAllOpportunities {
  type: Actions.OPPORTUNITY_BULK_PAGE_SELECT_ALL_OPPORTUNITIES;
  payload: string[];
}

interface BulkUnSelectAllOpportunities {
  type: Actions.OPPORTUNITY_BULK_PAGE_UNSELECT_ALL_OPPORTUNITIES;
}

interface UpdateOpportunitySelectedAction {
  type: Actions.OPPORTUNITY_SELECTED_ID;
  payload: string;
}

interface UpdateOpportunityUnselectedAction {
  type: Actions.OPPORTUNITY_UNSELECTED_ID;
  payload: string;
}

interface UpdateOpportunitySearchCriteriaAction {
  type: Actions.OPPORTUNITY_SET_SEARCH_CRITERIA;
  payload: OpportunitySearchCriteria;
}

interface SetDeadlineDateRangeAction {
  type: Actions.OPPORTUNITY_SET_DEADLINE_DATE_RANGE;
  payload: OpportunitySearchCriteria['deadlineDateRange'];
}

interface ClearOpportunitySearchAction {
  type: Actions.OPPORTUNITY_CLEAR_SEARCH;
}

interface ToggleOpportunityFiltersPanel {
  type: Actions.OPPORTUNITY_TOGGLE_FILTER_PANEL;
}

interface UpdateOpportunityChosenTopicsAction {
  type: Actions.OPPORTUNITY_CHOSEN_TOPICS;
  payload: Pill[];
}

interface SetForcedSearchCriteria {
  type: Actions.OPPORTUNITY_SET_FORCED_SEARCH_CRITERIA;
  payload: ForcedSearchCriteria;
}

export interface OpportunityListState {
  forcedSearchCriteria: ForcedSearchCriteria;
  searchCriteria: OpportunitySearchCriteria;
  allSelected: boolean;
  selectedIds: string[];
  filterPanelOpen: boolean;
  chosenTopics: Pill[];
}

export type OpportunityListActionTypes =
  | BulkSelectAllOpportunities
  | BulkUnSelectAllOpportunities
  | UpdateOpportunitySelectedAction
  | UpdateOpportunityUnselectedAction
  | UpdateOpportunitySearchCriteriaAction
  | SetDeadlineDateRangeAction
  | ClearOpportunitySearchAction
  | ToggleOpportunityFiltersPanel
  | UpdateOpportunityChosenTopicsAction
  | SetForcedSearchCriteria;
