import { createForms } from 'react-redux-form';
import { combineReducers } from 'redux';

import { CONTACTS_RESULTS_LIST } from 'constants/constants';
import campaignListsReducer from 'reducers//campaigns/campaign-lists';
import accountReducer from 'reducers/account';
import activityFeedReducer, {
  createActivityFormReducer,
} from 'reducers/activity';
import adminAccountMangementReducer from 'reducers/admin/account-management';
import alertsSubscriptionReducer from 'reducers/admin/alerts-subscription';
import analysisManagementReducer from 'reducers/admin/analysis-management';
import adminArticleManagementReducer from 'reducers/admin/article-management';
import adminDkimManagementReducer from 'reducers/admin/dkim-management';
import adminFeatureManagementReducer from 'reducers/admin/feature-management';
import adminFeedManagement from 'reducers/admin/feed-management';
import adminMigrationManagementReducer from 'reducers/admin/migration-management';
import adminUserManagementReducer from 'reducers/admin/user-management';
import alertsReducer from 'reducers/alerts';
import appInfoReducer from 'reducers/app-info';
import appSettingsReducer from 'reducers/appSettings/appSettings';
import manageDataReducer from 'reducers/appSettings/manage-data';
import articleListSocialAmp from 'reducers/article-list-social-amp';
import articleListsReducer from 'reducers/article-lists';
import articlesReducer from 'reducers/articles';
import campaignReducer from 'reducers/campaigns/campaign';
import campaignAlertReducer from 'reducers/campaigns/campaign-alerts';
import campaignCategoriesReducer from 'reducers/campaigns/campaign-categories';
import campaignContactsReducer from 'reducers/campaigns/campaign-contacts';
import campaignDashboardReducer from 'reducers/campaigns/campaign-dashboards';
import campaignDetailsSideTrayReducer from 'reducers/campaigns/campaign-details-side-tray';
import campaignListReducer from 'reducers/campaigns/campaign-list';
import campaignMetrics from 'reducers/campaigns/campaign-metrics';
import campaignNewsletterReducer from 'reducers/campaigns/campaign-newsletters';
import campaignReportReducer from 'reducers/campaigns/campaign-reports';
import campaignSearchReducer from 'reducers/campaigns/campaign-searches';
import campaignSideBarReducer from 'reducers/campaigns/campaign-sidebar';
import campaignStoriesReducer from 'reducers/campaigns/campaign-stories';
import campaignWidgetReducer from 'reducers/campaigns/campaign-widgets';
import campaignWizardReducer from 'reducers/campaigns/campaign-wizard';
import contactImagesReducer from 'reducers/contacts/contact-images';
import createContactListWithNamedType from 'reducers/contacts/contact-list';
import contactListInfo from 'reducers/contacts/contact-list-info';
import contactLists from 'reducers/contacts/contact-lists';
import contactListsContactsReducer from 'reducers/contacts/contact-lists-contacts';
import contactListsDrawer from 'reducers/contacts/contact-lists-drawer';
import contactsReducer from 'reducers/contacts/contacts';
import contactsFilter from 'reducers/contacts/contacts-filter';
import contactsSearch from 'reducers/contacts/contacts-search';
import currentNavReducer from 'reducers/current-nav';
import dashboardTemplatesReducer from 'reducers/dashboard-templates';
import dashboardsReducer from 'reducers/dashboards';
import discoveryReducer from 'reducers/discovery/discovery';
import discoveryKeyAuthorsReducer from 'reducers/discovery/discovery-key-authors';
import discoveryMetricsReducr from 'reducers/discovery/discovery-metrics';
import domainAudienceDemographicsReducer from 'reducers/domain-audience-demographics';
import drilldowns from 'reducers/drilldowns';
import exportsReducer from 'reducers/exports';
import facetsReducer from 'reducers/facets';
import networkReducer from 'reducers/network';
import newsletterReducer from 'reducers/newsletters';
import outreachReducer, {
  nylasRedirectModalReducer,
  nylasStateReducer,
  outreachAttachmentsReducer,
  outreachBulkSendReducer,
  outreachComposeFormReducer,
  outreachComposeModalReducer,
  outreachDraftReducer,
  outreachFilteredViewsReducer,
  outreachIntegrationsReducer,
  outreachReconnectModalReducer,
  outreachScheduleModalReducer,
  outreachStoryRecipientsReducer,
  outreachThreadReducer,
} from 'reducers/outreach';
import pageMessages from 'reducers/page-messages';
import reportsReducer from 'reducers/reports';
import searchHistoryReducer from 'reducers/search-history';
import searchReducer from 'reducers/searches';
import selectedContactsReducer from 'reducers/selected-contacts';
import sendContactsToStoryModalReducer from 'reducers/send-contacts-to-story-modal';
import slackReducer from 'reducers/slack';
import socialAmpReducer from 'reducers/social-amp';
import stories, {
  storyContentFormReducer,
  storyPressContactFormReducer,
  storyTitleFormReducer,
} from 'reducers/stories';
import tagListReducer from 'reducers/tag-list';
import tvEyesClipsReducer from 'reducers/tveyes-clips';
import userPreferencesReducers from 'reducers/user-preferences';
import userSettings from 'reducers/user-settings';
import userTagsReducer from 'reducers/user-tags';
import widgetFormReducer from 'reducers/widget-form';

import customerAdminReducer from '../pages/CustomerAdmin/customer-admin-reducer';
import dashboardReducer from '../pages/Dashboard/dashboard-reducer';
import earnedSearchReducer from '../pages/EarnedSearch/state/reducers';
import emailReducer from '../pages/Email/Email-reducer';
import earnedImpactReducer from '../pages/Impact/impact-reducer';
import personaReducer from '../pages/Impact/persona/persona-reducer';
import influencerHubSearchReducer from '../pages/Influencers/InfluencerList/reducers/search';
import influencerSavedListReducer from '../pages/Influencers/InfluencerSavedLists/reducer';
import InfluencerListTitleFormReducer from '../pages/Influencers/reducers/influencer-list-title-form';
import opportunityListReducer from '../pages/Influencers/reducers/opportunity-list/opportunityList';
import influencerPreviewReducer from '../pages/Influencers/reducers/preview';
import privateInfluencerReducer from '../pages/Influencers/reducers/private-influencer/privateInfluencer';
import influencerProfileReducer from '../pages/Influencers/reducers/profile';
import influencerRecentReducer from '../pages/Influencers/reducers/recent';
import influencerReferenceTypeReducer from '../pages/Influencers/reducers/referenceType';
import influencerListsReducer from '../pages/Influencers/reducers/saved-list';
import influencerSearchReducer from '../pages/Influencers/Search/reducer';
import reportReducer from '../pages/Report/report-reducer';
import socialSearchReducer from '../pages/SocialSearch/reducers';
import editTagReducer from '../pages/Tag/state/reducers';
import translationReducer from '../pages/Translate/translation-reducer';
import accessControlGroupReducer from '../pages/UserGroups/reducers/accessControlGroup';

const reducers = {
  accessControlGroup: accessControlGroupReducer,
  account: accountReducer,
  adminAnalysisManagement: analysisManagementReducer,
  adminArticleManagement: adminArticleManagementReducer,
  adminAccountManagement: adminAccountMangementReducer,
  adminDkimManagement: adminDkimManagementReducer,
  adminMigrationManagement: adminMigrationManagementReducer,
  adminUserManagement: adminUserManagementReducer,
  adminFeedManagement: adminFeedManagement,
  adminFeatureManagement: adminFeatureManagementReducer,
  activity: activityFeedReducer,
  alerts: alertsReducer,
  alertsSubscription: alertsSubscriptionReducer,
  appInfo: appInfoReducer,
  appSettings: appSettingsReducer,
  articleLists: articleListsReducer,
  articles: articlesReducer,
  articleListSocialAmp,
  campaign: campaignReducer,
  campaignCategories: campaignCategoriesReducer,
  campaignContactsList: campaignContactsReducer,
  campaignDashboardList: campaignDashboardReducer,
  campaignList: campaignListReducer,
  campaignMetrics,
  campaignWidgets: campaignWidgetReducer,
  campaignNewsletterList: campaignNewsletterReducer,
  campaignReportList: campaignReportReducer,
  campaignSearchList: campaignSearchReducer,
  campaignAlertList: campaignAlertReducer,
  campaignStoriesList: campaignStoriesReducer,
  campaignSidebar: campaignSideBarReducer,
  campaignLists: campaignListsReducer,
  contactImages: contactImagesReducer,
  [CONTACTS_RESULTS_LIST]: createContactListWithNamedType(
    CONTACTS_RESULTS_LIST,
  ),
  contactListInfo,
  contactLists,
  contactListsContacts: contactListsContactsReducer,
  contactListsDrawer,
  contactsByTopicsList: createContactListWithNamedType('contactsByTopicsList'),
  contacts: contactsReducer,
  contactsFilter,
  contactsSearch,
  currentNav: currentNavReducer,
  customerAdmin: customerAdminReducer,
  dashboards: dashboardsReducer,
  dashboard: dashboardReducer,
  discovery: discoveryReducer,
  facets: facetsReducer,
  dashboardTemplates: dashboardTemplatesReducer,
  discoveryKeyAuthors: discoveryKeyAuthorsReducer,
  discoveryMetrics: discoveryMetricsReducr,
  drilldowns,
  earnedImpact: earnedImpactReducer,
  earnedSearch: earnedSearchReducer,
  editTag: editTagReducer,
  email: emailReducer,
  exports: exportsReducer,
  influencerHubSearch: influencerHubSearchReducer,
  influencerSearch: influencerSearchReducer,
  influencerPreview: influencerPreviewReducer,
  influencerRecent: influencerRecentReducer,
  influencerProfile: influencerProfileReducer,
  influencerLists: influencerListsReducer,
  nylas: nylasStateReducer,
  nylasRedirectModal: nylasRedirectModalReducer,
  outreach: outreachReducer,
  outreachAttachments: outreachAttachmentsReducer,
  outreachBulkSend: outreachBulkSendReducer,
  outreachComposeModal: outreachComposeModalReducer,
  outreachReconnectModal: outreachReconnectModalReducer,
  outreachDrafts: outreachDraftReducer,
  outreachFilteredViews: outreachFilteredViewsReducer,
  outreachIntegrations: outreachIntegrationsReducer,
  outreachScheduleModal: outreachScheduleModalReducer,
  outreachStoryRecipients: outreachStoryRecipientsReducer,
  outreachThreads: outreachThreadReducer,
  manageData: manageDataReducer,
  network: networkReducer,
  newsletters: newsletterReducer,
  pageMessages,
  persona: personaReducer,
  reports: reportsReducer,
  report: reportReducer,
  savedLists: influencerSavedListReducer,
  searches: searchReducer,
  searchHistory: searchHistoryReducer,
  selectedContacts: selectedContactsReducer,
  sendContactsToStoryModal: sendContactsToStoryModalReducer,
  slack: slackReducer,
  socialAmp: socialAmpReducer,
  socialSearch: socialSearchReducer,
  stories,
  tvEyesClips: tvEyesClipsReducer,
  tags: tagListReducer,
  translation: translationReducer,
  userPreferences: userPreferencesReducers,
  userSettings,
  userTags: userTagsReducer,
  widgetForm: widgetFormReducer,
  domainAudienceDemographics: domainAudienceDemographicsReducer,
  influencerReferenceTypeReducer,
  privateInfluencer: privateInfluencerReducer,
  opportunityList: opportunityListReducer,
};

const app = combineReducers({
  ...reducers,
  // [1] separating react-redux-forms from above reducers because they break TS typing
  // (including them makes reducers type "any")
  // connected forms
  ...createForms({
    createActivityForm: createActivityFormReducer,
    campaignDetailsSideTray: campaignDetailsSideTrayReducer,
    campaignWizard: campaignWizardReducer,
    storyContentForm: storyContentFormReducer,
    storyPressContactForm: storyPressContactFormReducer,
    storyTitleForm: storyTitleFormReducer,
    outreachComposeForm: outreachComposeFormReducer,
    influencerListTitleForm: InfluencerListTitleFormReducer,
  }),
});

// use this to create a close-enough ReduxState object that omits redux forms state [1]
const appMinusForms = combineReducers(reducers);

// Official recommended approach for typing combined Redux state:
// https://react-redux.js.org/using-react-redux/static-typing#defining-the-root-state-type
export type ReduxState = ReturnType<typeof appMinusForms>;

export default app;
