import { find, isEmpty } from 'lodash';

import { Criteria, Pill, PillId } from './types';

export const getUniquePills = <PillType extends Pill>(
  pills: PillType[],
): PillType[] => {
  const uniquePillIds: PillId[] = [];

  return pills.filter(pill => {
    if (uniquePillIds.indexOf(pill.id) < 0) {
      uniquePillIds.push(pill.id);
      return true;
    }

    return false;
  });
};

export const sortPills = (a: Pill, b: Pill, locale?: string) => {
  if (!!a.selected !== !!b.selected) {
    return b.selected ? 1 : -1;
  }

  if (a.count !== b.count) {
    return (b.count as number) - (a.count as number);
  }

  return (a.name || '').localeCompare(b.name || '', locale);
};

export const formatOutletName = outletArr => {
  const lengthOfOutletArray = outletArr.length;

  if (lengthOfOutletArray === 0) {
    return '';
  }

  const firstOutlet = outletArr[0].name;
  const remainingOutletsCount = lengthOfOutletArray - 1;
  const additionalOutletCount =
    lengthOfOutletArray > 1 ? ` +${remainingOutletsCount}` : '';

  return `${firstOutlet}${additionalOutletCount}`;
};

export const getEmail = contacts => {
  const firstEmail = find(contacts, {
    type: 'email',
  });

  return firstEmail ? firstEmail.text : '';
};

export const getTwitterHandle = influencer => {
  if (
    influencer.influencerTypes.length === 1 &&
    influencer.influencerTypes[0].toLowerCase() === 'social'
  ) {
    const twitter = find(influencer.socialProfiles, {
      networkName: 'twitter',
    });

    return `@${twitter.handle}`;
  }

  return '';
};

export const getCriteria = ({
  booleanCondition = 'OR',
  criteriaOrder = 1,
  filterGroupKey = undefined,
  locationType = '',
  searchId = '',
  searchString = '',
}: Criteria) => ({
  booleanCondition,
  criteriaOrder,
  filterGroupKey,
  locationType,
  searchId,
  searchString,
});

export const getSearchCriteriaForInfluencerType = (
  influencerType,
): Criteria[] => {
  if (influencerType.toLowerCase() === 'outlet') {
    return [
      getCriteria({
        searchString: 'outlet',
        booleanCondition: '',
      }),
    ];
  } else if (influencerType.toLowerCase() === 'influencer') {
    return [
      getCriteria({
        searchString: 'journalist',
      }),
      getCriteria({
        searchString: 'social',
        criteriaOrder: 2,
      }),
    ];
  }
  return [
    getCriteria({
      searchString: influencerType.toLowerCase(),
    }),
  ];
};

export const getInfluencerTypeByCriteria = searchCriteria => {
  const filteredInfluencerCriteria = searchCriteria
    .map((criteria: Criteria) => criteria.searchString)
    .filter((criteria: string) => criteria !== 'Outlet');

  return filteredInfluencerCriteria.length === 1
    ? filteredInfluencerCriteria[0]
    : 'influencer';
};

// Push the id into miniCardData since it's null for some reason inside influencerPreview.influencer
export const influencerPreviewToData = info => ({
  id: `${info.id}`,
  name: info.name || '',
  outlet: formatOutletName(info.outlets || []),
  twitterScreenName: getTwitterHandle(info),
  profileImage: info.image || '',
  miniCardData: {
    ...info,
    id: `${info.id}`,
    name: `${info.name}`,
    outlet: formatOutletName(info.outlets || []),
    profileImage: info.image || '',
    miniCardData: {
      ...info,
      id: `${info.id}`,
    },
  },
});

// Push the location into miniCardData since it's not split out in its own prop like influencers
export const outletPreviewToData = info => ({
  id: `${info.id}`,
  rdsIds: info.rdsIds || '',
  name: info.name || '',
  email: getEmail(info.contacts),
  profileImage: info.image || '',
  country: info.countryName || '',
  mediaType: info.mediaType || '',
  miniCardData: {
    ...info.outlet,
    id: `${info.id}`,
    name: `${info.name}`,
    email: getEmail(info.contacts),
    profileImage: info.image || '',
    country: info.countryName || '',
    miniCardData: {
      ...info,
      id: `${info.id}`,
      location: isEmpty(info.addresses) ? null : info.addresses[0],
    },
  },
});

const OPTED_IN_NEW_SEARCH_HOME_KEY = 'OPTED_IN_NEW_SEARCH_HOME';
export const toggleNewSearchHome = (optIn = false) => {
  localStorage.setItem(OPTED_IN_NEW_SEARCH_HOME_KEY, JSON.stringify(optIn));
};

export const getOptedInNewSearchHome = () => {
  const optedIn = localStorage.getItem(OPTED_IN_NEW_SEARCH_HOME_KEY);
  return optedIn ? JSON.parse(optedIn) : false;
};

export const saltString = (salt: string, text: string): string => {
  if (!salt || !text) return '';

  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('')
    .slice(0, 15);
};
