import React from 'react';

import { FormattedMessage } from 'react-intl';

export type TranslatedMessageProps = ConstructorParameters<
  typeof FormattedMessage
>[0] & {
  className?: string;
};

const TranslatedMessage: React.FC<TranslatedMessageProps> = props => (
  <span data-translated-message={props.defaultMessage}>
    <FormattedMessage {...props} />
  </span>
);

export default TranslatedMessage;
