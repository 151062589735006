import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Inbox = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5a2 2 0 00-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10z" />
  </svg>
);
Inbox.propTypes = { fill: PropTypes.string };
Inbox.defaultProps = { fill: iconColorsMap.mainicon };

export default Inbox;
