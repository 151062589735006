export const UPDATE_FORM_FIELDS = 'private-influencer/UPDATE_FORM_FIELDS';
export const CLEAR_FORM_FIELDS = 'private-influencer/CLEAR_FORM_FIELDS';
export const PRIVATE_INFLUENCERS_LIST_START =
  'private-influencer/PRIVATE_INFLUENCERS_LIST_START';
export const PRIVATE_INFLUENCERS_LIST_SUCCESS =
  'private-influencer/PRIVATE_INFLUENCERS_LIST_SUCCESS';
export const PRIVATE_INFLUENCERS_LIST_ERROR =
  'private-influencer/PRIVATE_INFLUENCERS_LIST_ERROR';
export const PRIVATE_INFLUENCER_SAVE_START =
  'private-influencer/PRIVATE_INFLUENCER_SAVE_START';
export const PRIVATE_INFLUENCER_SAVE_SUCCESS =
  'private-influencer/PRIVATE_INFLUENCER_SAVE_SUCCESS';
export const PRIVATE_INFLUENCER_SAVE_ERROR =
  'private-influencer/PRIVATE_INFLUENCER_SAVE_ERROR';
export const ADD_SELECTED_TOPIC = 'private-influencer/ADD_SELECTED_TOPIC';
export const REMOVE_SELECTED_TOPIC = 'private-influencer/REMOVE_SELECTED_TOPIC';

export interface FormField {
  fieldName: string;
  fieldValue: string;
}

interface UpdateFormFieldsAction {
  type: typeof UPDATE_FORM_FIELDS;
  payload: FormField;
}

interface ClearFormFieldsAction {
  type: typeof CLEAR_FORM_FIELDS;
}

interface PrivateInfluencerListStartAction {
  type: typeof PRIVATE_INFLUENCERS_LIST_START;
  payload?: { totalhits: number; append: boolean };
}

interface PrivateInfluencerListSuccessAction {
  type: typeof PRIVATE_INFLUENCERS_LIST_SUCCESS;
  payload: { data: any; totalhits?: number; append: boolean };
}

interface PrivateInfluencerListErrorAction {
  type: typeof PRIVATE_INFLUENCERS_LIST_ERROR;
  error: string;
}

interface JobRole {
  outletId: string;
  jobRole: string;
}

interface Contact {
  type: string;
  text: string;
}

interface Topic {
  topicId: string;
  topic: string;
}

export class Influencer {
  id: string;
  prefix: string;
  firstName: string;
  lastName: string;
  suffix: string;
  countryName: string;
  email: string;
  phone: string;
  twitterHandle: string;
  title: string;
  jobRole: string;
  jobRoles: JobRole[];
  contacts: Contact[];
  influencerTypes: string[];
  topics: Topic[];
  constructor() {
    this.firstName = '';
    this.lastName = '';
  }
}

export interface PrivateInfluencerState {
  influencer: Influencer;
  loading: boolean;
  error: boolean;
  privateList: {
    data: any;
    totalhits: number;
  };
  topics: {
    data: any[];
  };
}

export type PrivateInfluencerFormActionTypes =
  | UpdateFormFieldsAction
  | ClearFormFieldsAction
  | PrivateInfluencerListStartAction
  | PrivateInfluencerListSuccessAction
  | PrivateInfluencerListErrorAction;
