import uniq from 'lodash/uniq';

import { createSelector } from 'reselect';

import { SUPPORTS_DATE_FILTERING } from 'constants/widgets';

import { articlesSelector } from 'selectors/article';
import { getDescriptionForDrilldownType } from 'services/drilldown-service/drilldown-service';

const drilldownsSelector = state =>
  state.drilldowns ? state.drilldowns.drilldowns : {};

export const currentDrilldownIdSelector = state =>
  state.drilldowns && state.drilldowns.current ? state.drilldowns.current : '';

export const currentDrilldownSelector = createSelector(
  currentDrilldownIdSelector,
  drilldownsSelector,
  (widgetId, drilldowns) => (widgetId ? drilldowns[widgetId] : null),
);

export const currentDrilldownArticleIdsSelector = createSelector(
  currentDrilldownSelector,
  drilldown =>
    drilldown && Array.isArray(drilldown.articleIds)
      ? drilldown.articleIds
      : [],
);

export const currentDrilldownArticlesSelector = createSelector(
  currentDrilldownArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) =>
    articleIds.map(articleId => {
      if (!articles[articleId]) {
        return {};
      }
      let articleDuplicates = [];
      if (articles[articleId].duplicatePublications) {
        articleDuplicates = articles[articleId].duplicatePublications;
      }
      return {
        ...articles[articleId],
        duplicatePublications: articleDuplicates.map(id => articles[id]),
      };
    }),
);

export const currentDrilldownArticlesLoadedCountSelector = createSelector(
  currentDrilldownArticlesSelector,
  articles => (Array.isArray(articles) ? articles.length : NaN),
);

export const currentDrilldownArticlesTotalCountSelector = createSelector(
  currentDrilldownSelector,
  drilldown =>
    drilldown && typeof drilldown.articlesTotalCount === 'number'
      ? drilldown.articlesTotalCount
      : NaN,
);

export const currentDrilldownTitleSelector = createSelector(
  currentDrilldownSelector,
  drilldown => (drilldown && drilldown.title ? drilldown.title : ''),
);

export const currentDrilldownTypeSelector = createSelector(
  currentDrilldownSelector,
  drilldown => (drilldown && drilldown.type ? drilldown.type : ''),
);

export const currentDrilldownHasDateFiltering = createSelector(
  currentDrilldownTypeSelector,
  type => SUPPORTS_DATE_FILTERING.indexOf(type) >= 0,
);

export const currentDrilldownDescriptionSelector = createSelector(
  currentDrilldownTypeSelector,
  type => (type ? getDescriptionForDrilldownType(type) : ''),
);

export const currentDrilldownArticlesLoadingSelector = state =>
  state.articles ? state.articles.loading : true;

export const currentDrilldownArticlesWithDupesCountSelector = createSelector(
  currentDrilldownArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) => {
    const articleCount = articleIds.reduce((count, articleId) => {
      const article = articles[articleId];
      if (!article) {
        return count;
      }
      if (
        article.duplicatePublications &&
        article.duplicatePublications.length
      ) {
        return count + article.duplicatePublications.length;
      }
      return count + 1;
    }, 0);

    return articleCount;
  },
);

export const currentDrilldownArticleIdsSelectedSelector = createSelector(
  currentDrilldownArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) => {
    const selectedArticleIds = articleIds.reduce((ids, articleId) => {
      const article = articles[articleId];

      if (!article) {
        return ids;
      }

      if (article.duplicatePublications) {
        article.duplicatePublications.forEach(id => {
          if (articles[id] && articles[id].selected) {
            ids.push(id);
          }
        });
      }

      if (article.selected) {
        ids.push(article.id);
      }

      return ids;
    }, []);

    return uniq(selectedArticleIds);
  },
);

export const currentDrilldownArticlesSelectedCountSelector = createSelector(
  currentDrilldownArticleIdsSelectedSelector,
  articleIds => (Array.isArray(articleIds) ? articleIds.length : NaN),
);

export const currentDrilldownArticlesSelectedAllExpandedSelector = createSelector(
  currentDrilldownArticlesSelector,
  articles => {
    return articles.reduce((allExpanded, article) => {
      if (article.selected && !article.expandedMetrics) {
        allExpanded = false;
      }
      return allExpanded;
    }, true);
  },
);

export const currentDrilldownArticlesAllExpandedSelector = createSelector(
  currentDrilldownArticlesSelector,
  articles => {
    return articles.reduce((allExpanded, article) => {
      if (!article.expandedMetrics) {
        allExpanded = false;
      }
      return allExpanded;
    }, true);
  },
);
