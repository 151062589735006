import EditTagActions from 'pages/Tag/state/actions/EditTagActions';
import { ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS } from 'reducers/user-tags';

import { EditTagAction, EditTagState } from '../../tagsTypes';

const INITIAL_STATE: EditTagState = {
  id: 0,
  dateCreated: '',
  lastUpdated: '',
  user: {
    id: 0,
    username: '',
  },
  tag: '',
  shared: false,
  visible: false,
  articleCount: 0,
  accessControlGroups: [],
  auditLog: {
    lastUpdatedTimestamp: '',
    lastUpdatedBy: '',
  },
  type: {
    enumType: '',
    name: '',
  },
};

const editTagReducer = (
  state: EditTagState = INITIAL_STATE,
  action: EditTagAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case EditTagActions.SET_TAG_DATA:
      return {
        ...state,
        ...payload,
      };

    case EditTagActions.SET_TAG_AUDIT_LOG_DATA:
      return {
        ...state,
        auditLog: payload,
      };

    case ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS: {
      const { responseData = { tags: [] } } = payload;
      const articlesCount =
        state.articleCount + (responseData?.tags.length || 0);
      return {
        ...state,
        articleCount: articlesCount,
      };
    }

    case EditTagActions.SET_TAG_SHARED_AND_ACCESS_CONTROL_GROUPS: {
      const { shared, accessControlGroups } = payload;
      return {
        ...state,
        shared,
        accessControlGroups,
      };
    }

    case EditTagActions.CLEAN_DATA: {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default editTagReducer;
