export default {
  menuPropsCoverage: {
    id: 'MENU-PROPS_COVERAGE',
    defaultMessage: 'Coverage',
    description: 'Across the App > Top Navigation Bar: Coverage',
  },
  menuPropsCoverageEarnedMedia: {
    id: 'MENU-PROPS_COVERAGE_EARNED_MEDIA',
    defaultMessage: 'Earned Media',
    description: 'Across the App > Top Navigation Bar: Coverage - Earned Media',
  },
  menuPropsCoverageSocialMedia: {
    id: 'MENU-PROPS_COVERAGE_SOCIAL_MEDIA',
    defaultMessage: 'Social Media',
    description: 'Across the App > Top Navigation Bar: Coverage - Social Media',
  },
  menuPropsCoverageSavedSearches: {
    id: 'MENU-PROPS_COVERAGE_SAVED_SEARCHES',
    defaultMessage: 'Saved Searches',
    description:
      'Across the App > Top Navigation Bar: Coverage - Saved Searches',
  },
  menuPropsCoverageTags: {
    id: 'MENU-PROPS_COVERAGE_TAGS',
    defaultMessage: 'Tags',
    description: 'Across the App > Top Navigation Bar: Coverage - Tags',
  },
  menuPropsInfluencers: {
    id: 'MENU-PROPS_INFLUENCERS',
    defaultMessage: 'Influencers',
    description: 'Across the App > Top Navigation Bar: Influencers',
  },
  menuPropsInfluencersEarnedMedia: {
    id: 'MENU-PROPS_INFLUENCERS_EARNED_MEDIA',
    defaultMessage: 'Earned Media',
    description:
      'Across the App > Top Navigation Bar: Influencers - Earned Media',
  },
  menuPropsInfluencersDiscover: {
    id: 'MENU-PROPS_INFLUENCERS_DISCOVER',
    defaultMessage: 'Discover',
    description: 'Across the App > Top Navigation Bar: Influencers - Discover',
  },
  menuPropsInfluencersMessageCenter: {
    id: 'MENU-PROPS_INFLUENCERS_MESSAGE_CENTER',
    defaultMessage: 'Message Center',
    description:
      'Across the App > Top Navigation Bar: Influencers - Message Center',
  },
  menuPropsInfluencersSearchMediaContacts: {
    id: 'MENU-PROPS_INFLUENCERS_SEARCH_MEDIA_CONTACTS',
    defaultMessage: 'Search Media Contacts',
    description:
      'Across the App > Top Navigation Bar: Influencers - Search Media Contacts',
  },
  menuPropsInfluencersSavedLists: {
    id: 'MENU-PROPS_INFLUENCERS_SAVED_LISTS',
    defaultMessage: 'Saved Lists',
    description:
      'Across the App > Top Navigation Bar: Influencers - Saved Lists',
  },
  menuPropsInfluencersSocialMedia: {
    id: 'MENU-PROPS_INFLUENCERS_SOCIAL_MEDIA',
    defaultMessage: 'Social Media',
    description:
      'Across the App > Top Navigation Bar: Influencers - Social Media',
  },
  menuPropsInfluencersSearchInfluencers: {
    id: 'MENU-PROPS_INFLUENCERS_SEARCH_INFLUENCERS',
    defaultMessage: 'Search Influencers',
    description:
      'Across the App > Top Navigation Bar: Influencers - Search Influencers',
  },
  menuPropsInfluencersBetaInfluencers: {
    id: 'MENU-PROPS_INFLUENCERS_BETA_INFLUENCERS',
    defaultMessage: 'Influencers',
    description:
      'Across the App > Top Navigation Bar: Influencers BETA - Influencers',
  },
  menuPropsContacts: {
    id: 'MENU-PROPS_CONTACTS',
    defaultMessage: 'Contacts',
    description: 'Across the App > Top Navigation Bar: Contacts',
  },
  menuPropsContactsSearch: {
    id: 'MENU-PROPS_CONTACTS_SEARCH',
    defaultMessage: 'Search',
    description: 'Across the App > Top Navigation Bar: Contacts - Search',
  },
  menuPropsContactsList: {
    id: 'MENU-PROPS_CONTACTS_LIST',
    defaultMessage: 'Lists',
    description: 'Across the App > Top Navigation Bar: Contacts - Lists',
  },
  menuPropsInfluencersBetaSearchInfluencers: {
    id: 'MENU-PROPS_INFLUENCERS_BETA_SEARCH_INFLUENCERS',
    defaultMessage: 'Search Contacts',
    description:
      'Across the App > Top Navigation Bar: Influencers BETA - Search Contacts',
  },
  menuPropsInfluencersBetaSavedLists: {
    id: 'MENU-PROPS_INFLUENCERS_BETA_SAVED_LISTS',
    defaultMessage: 'Saved Lists',
    description:
      'Across the App > Top Navigation Bar: Influencers BETA - Saved Lists',
  },
  menuPropsInfluencersBetaMessageCenter: {
    id: 'MENU-PROPS_INFLUENCERS_BETA_MESSAGE_CENTER',
    defaultMessage: 'Message Center',
    description:
      'Across the App > Top Navigation Bar: Influencers BETA - Message Center',
  },
  menuPropsCampaigns: {
    id: 'MENU-PROPS_CAMPAIGNS',
    defaultMessage: 'Campaigns',
    description: 'Across the App > Top Navigation Bar: Campaigns',
  },
  menuPropsCampaignsMyCampaigns: {
    id: 'MENU-PROPS_CAMPAIGNS_MY_CAMPAIGNS',
    defaultMessage: 'My Campaigns',
    description:
      'Across the App > Top Navigation Bar: Campaigns - My Campaigns',
  },
  menuPropsCampaignsStories: {
    id: 'MENU-PROPS_CAMPAIGNS_STORIES',
    defaultMessage: 'Stories',
    description: 'Across the App > Top Navigation Bar: Campaigns - Stories',
  },
  menuPropsCampaignsSsoLinkWithPrNewswire: {
    id: 'MENU-PROPS_CAMPAIGNS_SSO_LINK_WITH_PRNEWSWIRE',
    defaultMessage: 'PRWeb',
    description:
      'Across the App > Top Navigation Bar: Campaigns - SSO Link lable with  Pr Newswire',
  },
  menuPropsCampaignsSsoLinkWithoutPrNewswire: {
    id: 'MENU-PROPS_CAMPAIGNS_SSO_LINK_WITHOUT_PRNEWSWIRE',
    defaultMessage: 'Distribute',
    description:
      'Across the App > Top Navigation Bar: Campaigns - SSO Link lable without Pr Newswire',
  },
  menuPropsAnalytics: {
    id: 'MENU-PROPS_ANALYTICS',
    defaultMessage: 'Analytics',
    description: 'Across the App > Top Navigation Bar: Analytics',
  },
  menuPropsAnalyticsNewReports: {
    id: 'MENU-PROPS_ANALYTICS_NEW_REPORTS',
    defaultMessage: 'Reports',
    description: 'Across the App > Top Navigation Bar: Analytics - New Reports',
  },
  menuPropsAnalyticsMyDashboards: {
    id: 'MENU-PROPS_ANALYTICS_MY_DASHBOARDS',
    defaultMessage: 'My Dashboards',
    description: 'Across the App > Top Navigation Bar: Analytics - Analytics',
  },
  menuPropsAnalyticsImpactForEarnedMedia: {
    id: 'MENU-PROPS_ANALYTICS_IMPACT_FOR_EARNED_MEDIA',
    defaultMessage: 'Impact for Earned Media',
    description:
      'Across the App > Top Navigation Bar: Analytics - Impact for Earned Media',
  },
  menuPropsAnalyticsImpactAudienceActivations: {
    id: 'MENU-PROPS_ANALYTICS_IMPACT_AUDIENCE_ACTIVATIONS',
    defaultMessage: 'Impact Audience Activations',
    description:
      'Across the App > Top Navigation Bar: Analytics - Impact Audience Activations',
  },
  menuPropsAnalyticsDashboards: {
    id: 'MENU-PROPS_ANALYTICS_DASHBOARDS',
    defaultMessage: 'Dashboards',
    description: 'Across the App > Top Navigation Bar: Analytics - Dashboards',
  },
  menuPropsAnalyticsDashboards2: {
    id: 'MENU-PROPS_ANALYTICS_DASHBOARDS2',
    defaultMessage: 'Dashboards 2.0',
    description:
      'Across the App > Top Navigation Bar: Analytics - Dashboards 2.0',
  },
  menuPropsAnalyticsCisionInsights: {
    id: 'MENU-PROPS_ANALYTICS_CISION_INSIGHTS',
    defaultMessage: 'Cision Insights',
    description:
      'Across the App > Top Navigation Bar: Analytics - Cision Insights',
  },
  menuPropsReports: {
    id: 'MENU-PROPS_REPORTS',
    defaultMessage: 'Reports',
    description: 'Across the App > Top Navigation Bar: Reports',
  },
  menuPropsAlerts: {
    id: 'MENU-PROPS_ALERTS',
    defaultMessage: 'Alerts',
    description: 'Across the App > Top Navigation Bar: Alerts',
  },
  menuPropsAlertsDigests: {
    id: 'MENU-PROPS_ALERTS_DIGESTS',
    defaultMessage: 'Digests',
    description: 'Across the App > Top Navigation Bar: Alerts - Digests',
  },
  menuPropsAlertsNewsletters: {
    id: 'MENU-PROPS_ALERTS_NEWSLETTERS',
    defaultMessage: 'Newsletters',
    description: 'Across the App > Top Navigation Bar: Alerts - Newsletters',
  },
  menuPropsSettings: {
    id: 'MENU-PROPS_SETTINGS',
    defaultMessage: 'Settings',
    description: 'Across the App > Top Navigation Bar: Settings',
  },
  menuPropsSettingsUser: {
    id: 'MENU-PROPS_SETTINGS_USER',
    defaultMessage: 'User',
    description: 'Across the App > Top Navigation Bar: Settings - User',
  },
  menuPropsSettingsIntegrations: {
    id: 'MENU-PROPS_SETTINGS_INTEGRATIONS',
    defaultMessage: 'Integrations',
    description: 'Across the App > Top Navigation Bar: Settings - Integrations',
  },
  menuPropsSettingsEmail: {
    id: 'MENU-PROPS_SETTINGS_EMAIL',
    defaultMessage: 'Email',
    description: 'Across the App > Top Navigation Bar: Settings - Email',
  },
  menuPropsHelp: {
    id: 'MENU-PROPS_HELP',
    defaultMessage: 'Help',
    description: 'Across the App > Top Navigation Bar: Help',
  },
  menuPropsHelpProductUpdates: {
    id: 'MENU-PROPS_HELP_PRODUCT_UPDATES',
    defaultMessage: 'Product Updates',
    description: 'Across the App > Top Navigation Bar: Help - Product Updates',
  },
  menuPropsHelpCreateATicket: {
    id: 'MENU-PROPS_HELP_CREATE_A_TICKET',
    defaultMessage: 'Create a Ticket',
    description: 'Across the App > Top Navigation Bar: Help - Create a Ticket',
  },
  menuPropsHelpKnowledgeGuide: {
    id: 'MENU-PROPS_HELP_KNOWLEDGE_GUIDE',
    defaultMessage: 'Knowledge Guide',
    description: 'Across the App > Top Navigation Bar: Help - Knowledge Guide',
  },
  menuPropsHelpChat: {
    id: 'MENU-PROPS_HELP_CHAT',
    defaultMessage: 'Chat',
    description: 'Across the App > Top Navigation Bar: Help - Chat',
  },
  menuPropsMarketing: {
    id: 'MENU-PROPS_MARKETING',
    defaultMessage: 'Want to learn more about Cision?',
    description:
      'Across the App > Top Navigation Bar: Marketing - Want to learn more about Cision?',
  },
  menuPropsInfluencersBetaOpportunityFeed: {
    id: 'MENU-PROPS_INFLUENCERS_OPPORTUNITY_FEED',
    defaultMessage: 'Opportunity Feed',
    description:
      'Across the App > Top Navigation Bar: Influencers NEW - Opportunity Feed',
  },
  menuPropsInfluencersEditorialCalendar: {
    id: 'MENU-PROPS_INFLUENCERS_EDITORIAL_CALENDAR',
    defaultMessage: 'Editorial Calendars',
    description:
      'Across the App > Top Navigation Bar: Contacts - Editorial Calendars',
  },
  menuPropsInfluencersMediaRequests: {
    id: 'MENU-PROPS_INFLUENCERS_MEDIA_REQUESTS',
    defaultMessage: 'Media Requests',
    description:
      'Across the App > Top Navigation Bar: Influencers NEW - Media Requests',
  },
  menuPropsSocialListening: {
    id: 'MENU-PROPS_SOCIAL_LISTENING',
    defaultMessage: 'Social Listening',
    description: 'Across the App > Top Navigation Bar: Social Listening',
  },
  menuPropsSocialListeningTooltip: {
    id: 'MENU-PROPS_SOCIAL_LISTENING_TOOLTIP',
    defaultMessage:
      'Introducing a new way to gain actionable insights that improves customer perception of your brand.',
    description:
      'Across the App > Top Navigation Bar: Social Listening tooltip',
  },
  menuPropsSocialListeningUpsellTooltip: {
    id: 'MENU-PROPS_SOCIAL_LISTENING_UPSELL_TOOLTIP',
    defaultMessage:
      'Bring social media data into your workflow. Demo our new social listening app.',
    description:
      'Across the App > Top Navigation Bar: Social Listening upsell tooltip',
  },
  menuPropsSocialListeningUpsellLink: {
    id: 'MENU-PROPS_SOCIAL_LISTENING_UPSELL_LINK',
    defaultMessage: 'Learn more',
    description:
      'Across the App > Top Navigation Bar: Link text inside Social Listening upsell tooltip',
  },
};
