import React from 'react';
import PropTypes from 'prop-types';

const TabMenuItem = ({ children }) => (
  <li className="tk-tab-menu__item">{children}</li>
);

TabMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabMenuItem;
