import React, { Component } from 'react';

import delay from 'lodash/delay';
import PropTypes from 'prop-types';

import { withModifiers } from '@trendkite/ui';

import EmailAttachmentsGroup from '../email-attachments-group';
import EmailBodyControl from '../email-body-control';
import EmailEditorToolbar from '../email-editor-toolbar';

const toolbarId = 'tk-email-body-editor-toolbar';

// This will include body, signature, and attachments
class EmailContentGroup extends Component {
  controlRef = React.createRef();
  scrollingRef = React.createRef();

  handleAddedAttachment = () => {
    this.scrollingRef.current.scrollTo({
      top: this.scrollingRef.current.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  handleAreaClick = () => {
    if (!this.controlRef.current) {
      return;
    }

    if (this.controlRef.current.focus) {
      this.controlRef.current.focus();
    }
  };

  render() {
    const {
      bodyValue,
      className,
      bodyModel,
      templatesActive,
      templatesToUse,
    } = this.props;
    return (
      <div className={className}>
        <div className="tk-email-content-group__toolbar">
          <EmailEditorToolbar
            onAddedAttachment={() => delay(this.handleAddedAttachment, 250)}
            toolbarId={toolbarId}
            templatesActive={templatesActive}
            templatesToUse={templatesToUse}
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          data-qa="1Eexzu6d4G4WfwCe5FBtX"
          className="tk-email-content-group__editing-area"
          role="button"
          tabIndex={-1}
          onClick={this.handleAreaClick}
          ref={this.scrollingRef}
        >
          <div className="tk-email-content-group__body">
            <EmailBodyControl
              model={bodyModel}
              value={bodyValue}
              toolbarId={toolbarId}
              ref={this.controlRef}
              scrollingContainer=".tk-email-content-group__editing-area"
              placeholder={this.props.placeholder}
            />
          </div>
          <div className="tk-email-content-group__attachments">
            <EmailAttachmentsGroup />
          </div>
        </div>
      </div>
    );
  }
}

EmailContentGroup.baseClass = 'tk-email-content-group';

EmailContentGroup.propTypes = {
  bodyValue: PropTypes.string,
  className: PropTypes.string,
  bodyModel: PropTypes.string,
  templatesActive: PropTypes.bool,
  templatesToUse: PropTypes.array,
  placeholder: PropTypes.string,
};

EmailContentGroup.defaultProps = {
  bodyValue: '',
  className: 'tk-email-content-group',
  bodyModel: 'outreachComposeForm.body',
  templatesActive: false,
  templatesToUse: ['First name', 'Full name', 'Story link'],
  placeholder: '',
};

export default withModifiers(EmailContentGroup);
