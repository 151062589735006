import moment from 'moment';
import { actions } from 'react-redux-form';

const initialState = {
  type: [
    {
      label: 'Phone Call',
      id: 'phone_call',
    },
  ],
  date: moment().valueOf(),
  time: '',
  description: '',
  activitySubtypeId: null,
};

const formModel = 'createActivityForm';

const createActivityFormReducer = (state = initialState) => {
  return state;
};

export const resetCreateActivityForm = () => dispatch => {
  dispatch(actions.reset(formModel));
};

export default createActivityFormReducer;
