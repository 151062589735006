import React, { useState } from 'react';

import { Button } from '@cision/rover-ui';

import { Dropdown, DropdownMenu } from '@trendkite/ui/index';
import MenuItem from 'components/FeatureMenu/MenuItem';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';

import {
  getOverrideFeatures,
  toggleDevFeatures,
} from 'services/feature-service/feature-service';

import messages from './FeatureMenu.messages';
import styles from './FeatureMenu.module.css';

const FeatureMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [overrideFeatures, setOverrideFeatures] = useState(
    getOverrideFeatures(),
  );
  const toggleFeature = feature => {
    feature.enabled = !feature.enabled;
    setOverrideFeatures([...overrideFeatures]);
    setIsDirty(true);
  };
  return (
    <div
      data-qa="feature-menu-container"
      className={styles.featureMenu}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <div
          className={styles.menuCaption}
          onMouseEnter={() => setIsOpen(true)}
        >
          <TranslatedMessage {...messages.featureMenuTitle} />
        </div>
        <DropdownMenu position="bottomCenter" isOpen={isOpen}>
          <div className={styles.menuContainer}>
            {overrideFeatures.map(feature => (
              <MenuItem
                key={feature.name}
                label={feature.name}
                isChecked={feature.enabled}
                onToggle={() => toggleFeature(feature)}
              />
            ))}
            <Button
              data-qa="tUhL60UUF1qJ_CecioaNb"
              level="primary"
              disabled={!isDirty}
              className={styles.actionButton}
              onClick={() => {
                toggleDevFeatures(overrideFeatures);
                setIsOpen(false);
              }}
            >
              <TranslatedMessage {...globalMessages.save} />
            </Button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default FeatureMenu;
