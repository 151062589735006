export default {
  fontSizeSmall: {
    id: 'WYSIWYG_TOOLBAR_FONT_SIZE_SMALL',
    defaultMessage: 'Small',
    description: 'Label of the small option for fontsize on the WysiwygToolbar',
  },
  fontSizeNormal: {
    id: 'WYSIWYG_TOOLBAR_FONT_SIZE_NORMAL',
    defaultMessage: 'Normal',
    description:
      'Label of the normal option for fontsize on the WysiwygToolbar',
  },
  fontSizeLarge: {
    id: 'WYSIWYG_TOOLBAR_FONT_SIZE_LARGE',
    defaultMessage: 'Large',
    description: 'Label of the large option for fontsize on the WysiwygToolbar',
  },
  fontSizeHuge: {
    id: 'WYSIWYG_TOOLBAR_FONT_SIZE_HUGE',
    defaultMessage: 'Huge',
    description: 'Label of the huge option for fontsize on the WysiwygToolbar',
  },
  // FONTS
  fontsSanSerif: {
    id: 'WYSIWYG_TOOLBAR_FONTS_SAN_SERIF',
    defaultMessage: 'San Serif',
    description:
      'Label of the San Serif option for fonts on the WysiwygToolbar',
  },
  fontsSerif: {
    id: 'WYSIWYG_TOOLBAR_FONTS_SERIF',
    defaultMessage: 'Serif',
    description: 'Label of the Serif option for fonts on the WysiwygToolbar',
  },
  fontsMonospace: {
    id: 'WYSIWYG_TOOLBAR_FONTS_MONOSPACE',
    defaultMessage: 'Monospace',
    description:
      'Label of the Monospace option for fonts on the WysiwygToolbar',
  },
};
