import keyBy from 'lodash/keyBy';
import moment from 'moment';

import {
  USER_TAGS_ENDPOINT,
  TAG_BASE_ENDPOINT,
  ARTICLE_EXTRACTION_ENDPOINT,
  ARTICLE_SAVE_ENDPOINT,
  BULK_ADD_TAGS_TO_ARTICLE_ENDPOINT,
  ARTICLE_V3_BY_URL_ENDPOINT,
} from 'constants/apis';
import { TAGGED_ARTICLE_STATUS } from 'constants/tags';
import messages from 'pages/Tag/Tag.messages';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import {
  performDelete,
  performGet,
  performPut,
  performPost,
  csrfPerformPost,
} from 'services/rest-service/rest-service';
import { getCurrentUserId } from 'services/user-service/user-service';
import 'custom-event-polyfill';

import { verifyTagRemoval } from 'utils/tag/tag-utils';

export const DELETE_TAG_SUCCESS = 'tags/DELETE_TAG_SUCCESS';
export const FETCH_USER_TAGS = 'tags/GET_USER_TAGS';
export const GET_USER_TAGS_ERROR = 'tags/GET_USER_TAGS_ERROR';
export const GET_USER_TAGS_RECEIVED = 'tags/GET_USER_TAGS_RECEIVED';
export const SHARE_TAG_SUCCESS = 'tags/SHARE_TAG_SUCCESS';
export const TOGGLE_DELETE_MODAL = 'tags/TOGGLE_DELETE_MODAL';
export const SET_SELECTED_TAG = 'tags/SET_SELECTED_TAG';
export const TOGGLE_SHARE_MODAL = 'tags/TOGGLE_SHARE_MODAL';
export const TOGGLE_BULK_TAG_MODAL = 'tags/TOGGLE_BULK_TAG_MODAL';
export const ADD_ARTICLE_TO_TAG_BY_URL = 'tags/ADD_ARTICLE_TO_TAG_BY_URL';
export const ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS =
  'tags/ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS';
export const ADD_ARTICLE_TO_TAG_BY_URL_ERROR =
  'tags/ADD_ARTICLE_TO_TAG_BY_URL_ERROR';
export const EMPTY_BULK_TAG_MODAL_STATE = 'tags/EMPTY_BULK_TAG_MODAL_STATE';
export const SET_TAGGED_ARTICLES = 'tags/SET_TAGGED_ARTICLES';
export const SET_IS_ADD_URL_SCREEN_HIDDEN = 'tags/SET_IS_ADD_URL_SCREEN_HIDDEN';
export const CREATE_TAG_ERROR = 'tags/CREATE_TAG_ERROR';
export const CREATE_TAG_SUCCESS = 'tags/CREATE_TAG_SUCCESS';
export const CLEAR_SELECTED_TAG = 'tags/CLEAR_SELECTED_TAG';
export const BULK_ADD_ARTICLE_TO_TAG_BY_URL =
  'tags/BULK_ADD_ARTICLE_TO_TAG_BY_URL';
export const BULK_ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS =
  'tags/BULK_ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS';
export const BULK_ADD_ARTICLE_TO_TAG_BY_URL_NEW_ARTICLE =
  'tags/BULK_ADD_ARTICLE_TO_TAG_BY_URL_NEW_ARTICLE';
export const BULK_ADD_ARTICLE_TO_TAG_BY_URL_EXISTING_ARTICLE =
  'tags/BULK_ADD_ARTICLE_TO_TAG_BY_URL_EXISTING_ARTICLE';
export const BULK_ADD_ARTICLE_TO_TAG_BY_URL_ERROR =
  'tags/BULK_ADD_ARTICLE_TO_TAG_BY_URL_ERROR';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userTags: null,
  sharedTags: null,
  deleteModal: false,
  selectedTag: {},
  shareModalOpen: false,
  bulkTagModalOpen: false,
  articles: [],
  isAddUrlScreenHidden: false,
};

const findAndSetArticleStatus = (status, priority, url, data) => article => {
  if (article.url === url) {
    return { ...article, status, priority, data };
  }
  return article;
};

const findAndSetArticlesStatus = (status, priority, urls, data) => article => {
  if (urls.includes(article.url)) {
    return { ...article, status, priority, data };
  }
  return article;
};

const userTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_TAG_SUCCESS: {
      const userTags = { ...state.userTags };
      delete userTags[action.payload.tagId];
      return {
        ...state,
        userTags,
        selectedTag: {},
      };
    }
    case FETCH_USER_TAGS:
      return { ...state, loading: true };
    case GET_USER_TAGS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: true,
      };
    case GET_USER_TAGS_RECEIVED:
      return {
        ...state,
        loading: false,
        loaded: true,
        userTags: keyBy(action.payload.userTags, tag => tag.id),
        sharedTags: keyBy(action.payload.sharedTags, tag => tag.id),
      };
    case TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    case SET_SELECTED_TAG:
      return {
        ...state,
        selectedTag: action.payload,
      };
    case SHARE_TAG_SUCCESS:
      return {
        ...state,
        userTags: {
          ...state.userTags,
          [action.payload.tagId]: {
            ...state.userTags[action.payload.tagId],
            shared: true,
          },
        },
        selectedTag: {},
      };
    case TOGGLE_SHARE_MODAL:
      return {
        ...state,
        shareModalOpen: !state.shareModalOpen,
      };
    case TOGGLE_BULK_TAG_MODAL:
      return {
        ...state,
        bulkTagModalOpen: !state.bulkTagModalOpen,
      };
    case ADD_ARTICLE_TO_TAG_BY_URL: {
      const { url } = action.payload;
      const articles = state.articles.map(
        findAndSetArticleStatus(
          TAGGED_ARTICLE_STATUS.processing.id,
          TAGGED_ARTICLE_STATUS.processing.priority,
          url,
        ),
      );
      return {
        ...state,
        articles,
      };
    }
    case ADD_ARTICLE_TO_TAG_BY_URL_ERROR: {
      const { error, url } = action.payload;
      const articles = state.articles.map(
        findAndSetArticleStatus(
          TAGGED_ARTICLE_STATUS.failed.id,
          TAGGED_ARTICLE_STATUS.failed.priority,
          url,
        ),
      );
      return {
        ...state,
        error,
        articles,
      };
    }
    case ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS: {
      const { url } = action.payload;
      const articles = state.articles.map(
        findAndSetArticleStatus(
          TAGGED_ARTICLE_STATUS.tagged.id,
          TAGGED_ARTICLE_STATUS.tagged.priority,
          url,
        ),
      );
      return {
        ...state,
        articles,
      };
    }
    case BULK_ADD_ARTICLE_TO_TAG_BY_URL: {
      const { articlesUrl } = action.payload;
      const articles = state.articles.map(
        findAndSetArticlesStatus(
          TAGGED_ARTICLE_STATUS.processing.id,
          TAGGED_ARTICLE_STATUS.processing.priority,
          articlesUrl,
        ),
      );
      return {
        ...state,
        articles,
      };
    }
    case BULK_ADD_ARTICLE_TO_TAG_BY_URL_ERROR: {
      const { error, articlesUrl } = action.payload;
      const articles = [];
      articlesUrl.forEach(url => {
        articles.push(
          ...state.articles.map(
            findAndSetArticleStatus(
              TAGGED_ARTICLE_STATUS.failed.id,
              TAGGED_ARTICLE_STATUS.failed.priority,
              url,
            ),
          ),
        );
      });
      return {
        ...state,
        error,
        articles,
      };
    }
    case BULK_ADD_ARTICLE_TO_TAG_BY_URL_NEW_ARTICLE: {
      const { url, responseData } = action.payload;
      const articles = state.articles.map(
        findAndSetArticleStatus(
          TAGGED_ARTICLE_STATUS.toBeTagged.id,
          TAGGED_ARTICLE_STATUS.toBeTagged.priority,
          url,
          responseData,
        ),
      );
      return {
        ...state,
        articles,
      };
    }
    case BULK_ADD_ARTICLE_TO_TAG_BY_URL_EXISTING_ARTICLE: {
      const { url, responseData } = action.payload;
      const articles = state.articles.map(
        findAndSetArticleStatus(
          TAGGED_ARTICLE_STATUS.exists.id,
          TAGGED_ARTICLE_STATUS.exists.priority,
          url,
          responseData,
        ),
      );
      return {
        ...state,
        articles,
      };
    }
    case BULK_ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS: {
      const { taggedArticles } = action.payload;
      const articles = state.articles.map(
        findAndSetArticlesStatus(
          TAGGED_ARTICLE_STATUS.tagged.id,
          TAGGED_ARTICLE_STATUS.tagged.priority,
          taggedArticles.map(article => article.url),
        ),
      );
      return {
        ...state,
        articles,
      };
    }
    case EMPTY_BULK_TAG_MODAL_STATE: {
      return {
        ...state,
        articles: [],
        selectedTag: {},
        isAddUrlScreenHidden: false,
      };
    }
    case CLEAR_SELECTED_TAG: {
      return {
        ...state,
        selectedTag: {},
      };
    }
    case SET_TAGGED_ARTICLES: {
      return { ...state, articles: action.payload };
    }
    case SET_IS_ADD_URL_SCREEN_HIDDEN: {
      return { ...state, isAddUrlScreenHidden: action.payload };
    }
    case CREATE_TAG_ERROR: {
      return { ...state, error: action.payload };
    }
    case CREATE_TAG_SUCCESS: {
      const { articles } = state;
      return {
        ...state,
        selectedTag: articles.length ? action.payload : {},
      };
    }
    default:
      return state;
  }
};

export const fetchUserTags = () => async dispatch => {
  dispatch({ type: FETCH_USER_TAGS });
  try {
    const response = await performGet(TAG_BASE_ENDPOINT);
    const result = {
      sharedTags: [],
      userTags: [],
    };
    const activeUserId = getCurrentUserId();

    if (response.status === 200 && Array.isArray(response.data.tags)) {
      response.data.tags.forEach(tag => {
        const tagBelongsToActiveUser = activeUserId === tag?.user?.id;
        tag.owner = tag.user ? tag.user.username : '';
        if (!tagBelongsToActiveUser && tag.shared) {
          result.sharedTags.push(tag);
        } else {
          result.userTags.push(tag);
        }
      });
    }
    dispatch({
      type: GET_USER_TAGS_RECEIVED,
      payload: result,
    });
  } catch (e) {
    dispatch({ type: GET_USER_TAGS_ERROR, payload: e });
  }
};

export const clearSelectedTag = () => ({ type: CLEAR_SELECTED_TAG });

export const toggleShareModal = () => ({ type: TOGGLE_SHARE_MODAL });

export const toggleBulkTagModal = () => ({ type: TOGGLE_BULK_TAG_MODAL });

export const shareTag = (tagId, messages) => async dispatch => {
  try {
    await performPut(`${TAG_BASE_ENDPOINT}/${tagId}`, { shared: true });
    dispatch({
      type: SHARE_TAG_SUCCESS,
      payload: {
        tagId,
      },
    });
    dispatch({ type: TOGGLE_SHARE_MODAL });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: messages.success,
        status: 'success',
      }),
    );
    dispatch(clearSelectedTag());
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: messages.error,
        status: 'danger',
      }),
    );
  }
};

export const toggleDeleteModal = () => ({ type: TOGGLE_DELETE_MODAL });

export const setSelectedTag = tagId => ({
  type: SET_SELECTED_TAG,
  payload: tagId,
});

export const deleteTag = (tagId, tagName, formatMessage) => async dispatch => {
  try {
    const response = await performDelete(`${USER_TAGS_ENDPOINT}/${tagId}`);
    dispatch({
      type: DELETE_TAG_SUCCESS,
      payload: {
        tagId,
      },
    });
    dispatch({ type: TOGGLE_DELETE_MODAL });
    const { proccessingAsAsync } = response.data || {};
    if (proccessingAsAsync) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: formatMessage(messages.deleteTagInProgress),
          isLoading: true,
        }),
      );
      verifyTagRemoval(formatMessage, dispatch, tagId, tagName);
    } else {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: formatMessage(messages.deleteTagSuccessfully),
          status: 'success',
        }),
      );
    }
    dispatch(clearSelectedTag());
  } catch (e) {
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: formatMessage(messages.deleteTagError),
        status: 'danger',
      }),
    );
  }
};

export const emptyBulkTagModalState = () => ({
  type: EMPTY_BULK_TAG_MODAL_STATE,
});

export const setTaggedArticles = articles => ({
  type: SET_TAGGED_ARTICLES,
  payload: articles,
});

export const setIsAddUrlScreenHidden = isScreenHidden => ({
  type: SET_IS_ADD_URL_SCREEN_HIDDEN,
  payload: isScreenHidden,
});

const getValidArticles = articles => {
  const articlesToBeLinked = [];
  const articlesToBeSaved = [];
  articles.forEach(articleMap => {
    if (articleMap.status === TAGGED_ARTICLE_STATUS.exists.id) {
      articlesToBeLinked.push(articleMap.data);
    } else if (articleMap.status === TAGGED_ARTICLE_STATUS.toBeTagged.id) {
      articlesToBeSaved.push(articleMap.data);
    }
  });
  return { articlesToBeLinked, articlesToBeSaved };
};

export const tagArticles = (articles, tagId) => async dispatch => {
  try {
    const selectedArticles = [];
    const tagIds = [tagId];
    const { articlesToBeLinked, articlesToBeSaved } = getValidArticles(
      articles,
    );
    if (articlesToBeLinked.length === 0 && articlesToBeSaved.length === 0) {
      return;
    }
    dispatch({
      type: BULK_ADD_ARTICLE_TO_TAG_BY_URL,
      payload: {
        articlesUrl: [
          ...(articlesToBeLinked?.map(article => article.original_url) || []),
          ...(articlesToBeSaved?.map(article => article.original_url) || []),
        ],
      },
    });
    if (articlesToBeSaved.length > 0) {
      const saveArticleResponse = await performPost(
        `${ARTICLE_V3_BY_URL_ENDPOINT}/bulkSave`,
        { articles: articlesToBeSaved },
      );
      selectedArticles.push(
        ...saveArticleResponse.data.map(article => ({
          duplicateTrendKiteArticleId: article.duplicateTrendKiteArticleId,
          url: article.original_url,
        })),
      );
    }

    selectedArticles.push(
      ...articlesToBeLinked.map(article => ({
        duplicateTrendKiteArticleId: article.duplicateTrendKiteArticleId,
        url: article.original_url,
      })),
    );

    if (selectedArticles.length > 0) {
      await performPost(BULK_ADD_TAGS_TO_ARTICLE_ENDPOINT, {
        selectedArticles: selectedArticles.map(
          article => article.duplicateTrendKiteArticleId,
        ),
        tagIds,
      });
      dispatch({
        type: BULK_ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS,
        payload: {
          taggedArticles: selectedArticles,
        },
      });
    }
  } catch (err) {
    const validArticlesUrl = articles
      .filter(
        article =>
          article.status === TAGGED_ARTICLE_STATUS.exists.id ||
          article.status === TAGGED_ARTICLE_STATUS.toBeTagged.id,
      )
      .map(article => article.original_url);
    dispatch({
      type: BULK_ADD_ARTICLE_TO_TAG_BY_URL_ERROR,
      payload: { error: err.message, articlesUrl: validArticlesUrl },
    });
  }
};

export const addArticleToTagByUrl = (url, tagId) => async dispatch => {
  const selectedArticles = [];
  const tagIds = [tagId];
  dispatch({ type: ADD_ARTICLE_TO_TAG_BY_URL, payload: { url } });
  try {
    const fetchedArticle = await performGet(ARTICLE_EXTRACTION_ENDPOINT, {
      url,
    });
    if (fetchedArticle.data.type === 'error') {
      dispatch({
        type: ADD_ARTICLE_TO_TAG_BY_URL_ERROR,
        payload: { error: fetchedArticle.data, url },
      });
      return;
    }

    if (!fetchedArticle.data.duplicateTrendKiteArticleId) {
      if (fetchedArticle.data.published) {
        fetchedArticle.data.published = moment(
          fetchedArticle.data.published,
        ).valueOf();
      } else {
        dispatch({
          type: ADD_ARTICLE_TO_TAG_BY_URL_ERROR,
          payload: { error: fetchedArticle.data, url },
        });
        return;
      }
      const savedArticle = await performPost(
        ARTICLE_SAVE_ENDPOINT,
        fetchedArticle.data,
      );
      selectedArticles.push(savedArticle.data.duplicateTrendKiteArticleId);
    } else {
      selectedArticles.push(fetchedArticle.data.duplicateTrendKiteArticleId);
    }
    const addTagToArticle = await performPost(
      BULK_ADD_TAGS_TO_ARTICLE_ENDPOINT,
      { selectedArticles, tagIds },
    );
    dispatch({
      type: ADD_ARTICLE_TO_TAG_BY_URL_SUCCESS,
      payload: { responseData: addTagToArticle.data, url },
    });
  } catch (err) {
    dispatch({
      type: ADD_ARTICLE_TO_TAG_BY_URL_ERROR,
      payload: { error: err.message, url },
    });
  }
};

export const extractArticlesByUrl = url => async dispatch => {
  dispatch({ type: ADD_ARTICLE_TO_TAG_BY_URL, payload: { url } });
  try {
    const fetchedArticle = await performGet(
      `${ARTICLE_V3_BY_URL_ENDPOINT}/get`,
      {
        url,
      },
    );

    if (fetchedArticle.data.type === 'error') {
      dispatch({
        type: BULK_ADD_ARTICLE_TO_TAG_BY_URL_ERROR,
        payload: { error: fetchedArticle.data, url },
      });
    } else if (!fetchedArticle.data.duplicateTrendKiteArticleId) {
      if (!fetchedArticle.data.published) {
        dispatch({
          type: BULK_ADD_ARTICLE_TO_TAG_BY_URL_ERROR,
          payload: { bulkAddError: fetchedArticle.data, url },
        });
        return;
      }
      fetchedArticle.data.published = moment(
        fetchedArticle.data.published,
      ).valueOf();
      dispatch({
        type: BULK_ADD_ARTICLE_TO_TAG_BY_URL_NEW_ARTICLE,
        payload: { responseData: fetchedArticle.data, url },
      });
    } else {
      dispatch({
        type: BULK_ADD_ARTICLE_TO_TAG_BY_URL_EXISTING_ARTICLE,
        payload: { responseData: fetchedArticle.data, url },
      });
    }
  } catch (err) {
    dispatch({
      type: ADD_ARTICLE_TO_TAG_BY_URL_ERROR,
      payload: { error: err.message, url },
    });
  }
};

export const createTag = (
  tagName,
  articles,
  messages,
  handler,
) => async dispatch => {
  let response;
  try {
    response = await csrfPerformPost(USER_TAGS_ENDPOINT, { tag: tagName });
  } catch (err) {
    dispatch({ type: CREATE_TAG_ERROR, payload: err.message });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: messages.error,
        status: 'danger',
      }),
    );
  }
  const newTag = response.data;
  handler && handler();
  dispatch({ type: CREATE_TAG_SUCCESS, payload: newTag });
  dispatch(
    addPageMessageWithDefaultTimeout({
      text: messages.success,
      status: 'success',
    }),
  );
  articles.forEach(article =>
    dispatch(addArticleToTagByUrl(article.url, newTag.id)),
  );
};

export default userTagsReducer;
