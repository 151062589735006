export default {
  usSalesforceChat: {
    id: 'SHOW_US_SALESFORCE_CHAT',
    defaultMessage: 'true',
    description: 'DO NOT EDIT ME',
  },
  ukSalesforceChat: {
    id: 'SHOW_SALESFORCE_CHAT',
    defaultMessage: 'false',
    description: 'DO NOT EDIT ME',
  },
};
