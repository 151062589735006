/* eslint-disable jsx-a11y/label-has-associated-control,
 jsx-a11y/no-noninteractive-element-interactions,
  jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';

import classNames from 'classnames';

import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';
import { injectIntl } from 'react-intl';

import {
  SvgIcon,
  Loader,
  SearchInput,
  RadioButton,
  Checkbox,
  ConfirmModal,
} from '@trendkite/ui';

import {
  BROWSE_ASSET_LIBRARY_TABS,
  ASSET_VALID_UPLOAD_STATUS,
} from '../../../app/src/constants/constants';

import messages from './assets-library-modals.messages';

import styles from './browseModuleUpload.module.css';

class MediaBrowseAssetsPanel extends Component {
  renderProgressSection = sectionData => {
    const { intl } = this.props;
    const uploadProgressValue =
      Math.round(100 * (sectionData.uploadStatus / 8)) / 100;
    const formattedUploadProgressValue = intl.formatNumber(
      uploadProgressValue,
      { style: 'percent' },
    );
    return (
      <div className={styles.progressBarContainer}>
        <div className={styles.progress}>
          <div
            className={styles.progressBar}
            role="progressbar"
            aria-valuenow={Math.round(100 * (sectionData.uploadStatus / 8))}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {' '}
          </div>
        </div>
        <div className={styles.progressState}>
          <T
            {...messages.percentComplete}
            values={{ PERCENT: formattedUploadProgressValue }}
          />
        </div>
      </div>
    );
  };
  render() {
    const {
      mediaAllJson,
      mediaImageJson,
      mediaDocJson,
      mediaVideoJson,
      isScreenForBrowseFilesFromList,
      currentMediaSection,
      activeTab,
      clickedAssetId,
      showEditDiv,
      selectedAsset,
      downloadableAssetIDs,
      dragOver,
      showDeleteConfirmationModalForSingleFile,
      showUploadOptionsFromEmail,
    } = this.props.data;
    const {
      intl,
      isLoading,
      mediaUrl,
      mediaToken,
      isFromDownloadable,
    } = this.props;
    return (
      <div className={styles.rightPane} style={{ width: '95%' }}>
        <ConfirmModal
          confirmButtonText={intl.formatMessage(
            messages.confirmationDeleteTextForSingleFile,
          )}
          header={intl.formatMessage(
            messages.confirmationModalHeaderForSingelFile,
          )}
          isOpen={showDeleteConfirmationModalForSingleFile}
          message={intl.formatMessage(
            messages.confirmationModalTextForSingleFile,
          )}
          onCancel={() => this.props.onCancelConfirmationModalForSingleFile()}
          onConfirm={() =>
            this.props.onConfirmConfirmationModalForSingleFile(false)
          }
        />
        <div className={styles.row}>
          <div style={{ width: '66.6%', margin: '20px 0 0 20px' }}>
            <SearchInput
              modifiers={['leftbutton']}
              handleChange={this.props.handleChange}
              performSearchCallback={this.props.onSearchText}
            />
          </div>
          <div className={styles.assetSorting} style={{ width: '33.3%' }}>
            <div className={styles.sortingContent}>
              <div className={styles.sortSection}>
                <SvgIcon icon="desc" width={20} height={20} />
                <div className={styles.sortSection}>
                  <label className={styles.labelSort}>
                    <T {...messages.sortBy} />:
                  </label>
                  {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                  <select
                    className={styles.sortDropdown}
                    onChange={e => this.props.sort(e.target.value)}
                    id="sel1"
                  >
                    <option value="recent">
                      {intl.formatMessage(messages.sortByMostRecent)}
                    </option>
                    <option value="alphabetical">
                      {intl.formatMessage(messages.sortByAlphabetical)}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.row}
          style={{ paddingTop: '25px', paddingLeft: '33px' }}
        >
          {!showUploadOptionsFromEmail && (
            <ul className={styles.tabs}>
              <li
                data-qa="dwFiyKZdKv6S40JHsgyIH"
                className={classNames(styles.liStyle, {
                  [styles.current]:
                    activeTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_ALL,
                })}
                onClick={() =>
                  this.props.tabClicks(BROWSE_ASSET_LIBRARY_TABS.MEDIA_ALL)
                }
              >
                <T
                  {...messages.tabsHeadingAll}
                  values={{ NUM_ITEMS: mediaAllJson ? mediaAllJson.length : 0 }}
                />
              </li>
              <li
                data-qa="SxGtKnw1rADdjDmwDtfta"
                className={classNames(styles.liStyle, {
                  [styles.current]:
                    activeTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_IMAGES,
                })}
                onClick={() =>
                  this.props.tabClicks(BROWSE_ASSET_LIBRARY_TABS.MEDIA_IMAGES)
                }
              >
                <T
                  {...messages.tabsHeadingImages}
                  values={{
                    NUM_ITEMS: mediaImageJson ? mediaImageJson.length : 0,
                  }}
                />{' '}
              </li>
              <li
                data-qa="r4Es3YSHp1BCFbCS1INwO"
                className={classNames(styles.liStyle, {
                  [styles.current]:
                    activeTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_VIDEOS,
                })}
                onClick={() =>
                  this.props.tabClicks(BROWSE_ASSET_LIBRARY_TABS.MEDIA_VIDEOS)
                }
              >
                <T
                  {...messages.tabsHeadingVideos}
                  values={{
                    NUM_ITEMS: mediaVideoJson ? mediaVideoJson.length : 0,
                  }}
                />{' '}
              </li>
              <li
                data-qa="fKejE-fS5xJkmGfWR-oAf"
                className={classNames(styles.liStyle, {
                  [styles.current]:
                    activeTab === BROWSE_ASSET_LIBRARY_TABS.MEDIA_DOCS,
                })}
                onClick={() =>
                  this.props.tabClicks(BROWSE_ASSET_LIBRARY_TABS.MEDIA_DOCS)
                }
              >
                <T
                  {...messages.tabsHeadingDocs}
                  values={{ NUM_ITEMS: mediaDocJson ? mediaDocJson.length : 0 }}
                />
              </li>
            </ul>
          )}
          {showUploadOptionsFromEmail && (
            <ul className={styles.tabs}>
              <li
                data-qa="RdwX4vGqz_r9AZnb521ZK"
                className={classNames(styles.liStyle, {
                  [styles.current]: true,
                })}
                onClick={() =>
                  this.props.tabClicks(BROWSE_ASSET_LIBRARY_TABS.MEDIA_IMAGES)
                }
              >
                <T
                  {...messages.tabsHeadingImages}
                  values={{
                    NUM_ITEMS: mediaImageJson ? mediaImageJson.length : 0,
                  }}
                />
              </li>
            </ul>
          )}

          {isLoading && isScreenForBrowseFilesFromList && (
            <div className={styles.modalLoader}>
              <Loader />
            </div>
          )}

          <Dropzone className={styles.tabContent} disableClick>
            <div
              id="tab-1"
              className={classNames(styles.tabContent, {
                [styles.dragOver]: dragOver,
              })}
              onDragLeave={() => this.props.toggleDragStyleChange('leave')}
              onDragEnter={() => this.props.toggleDragStyleChange('over')}
              onDrop={e => this.props.onChangeHandler(e)}
            >
              {currentMediaSection && mediaAllJson.length > 0 ? (
                currentMediaSection.map(data => {
                  const downloadUrl =
                    data.url?.replace('get', 'download') ?? '';
                  return (
                    <div
                      className={classNames(styles.thumbnailContainer, {
                        [styles.currentSelected]:
                          selectedAsset.id === data.id ||
                          downloadableAssetIDs.includes(data.id),
                      })}
                      key={data.id}
                    >
                      <div className={styles.thumbnailImage}>
                        <div className={styles.hoverMenu}>
                          <div
                            data-qa="v2PMXbcl6C-FxoTFwNBSf"
                            onClick={() => this.props.assetClick(data.id)}
                            className={styles.dotsSection}
                          >
                            <span className={styles.dot}> </span>
                            <span className={styles.dot}> </span>
                            <span className={styles.dot}> </span>
                          </div>
                          {isFromDownloadable &&
                            data.uploadStatus === ASSET_VALID_UPLOAD_STATUS && (
                              <label
                                className={
                                  styles.selectedContainerForDownloadable
                                }
                              >
                                <Checkbox
                                  data-qa="Cfxx6e55B72uSofDBeJLw"
                                  onClick={e =>
                                    this.props.handleSelectedAsset(data, e)
                                  }
                                  className={styles.selectedAsset}
                                  selected={downloadableAssetIDs.includes(
                                    data.id,
                                  )}
                                />
                                <span
                                  className={
                                    styles.checkmarkCheckboxForDownloadable
                                  }
                                >
                                  {' '}
                                </span>
                              </label>
                            )}
                          {!isFromDownloadable &&
                            data.uploadStatus === ASSET_VALID_UPLOAD_STATUS && (
                              <label className={styles.selectedContainer}>
                                <span
                                  data-qa="iq4yUGML3odDaEgffvESh"
                                  onClick={e =>
                                    this.props.handleSelectedAsset(data, e)
                                  }
                                >
                                  <RadioButton
                                    className={styles.selectedAsset}
                                    selected={selectedAsset.id === data.id}
                                  />
                                </span>
                                <span className={styles.checkmark}> </span>
                              </label>
                            )}
                          {showEditDiv && clickedAssetId === data.id && (
                            <div className={styles.editMenu}>
                              <div
                                data-qa="MwUJx60XXKIpNAJEYSLY3"
                                disabled={data.isArchived}
                                className={classNames({
                                  [styles.assetNoAction]:
                                    data.isArchived ||
                                    data.uploadStatus !==
                                      ASSET_VALID_UPLOAD_STATUS,
                                })}
                                onClick={() => this.props.handleEditClick(data)}
                              >
                                <T {...messages.showEditDivWithEditOption} />
                              </div>
                              <div
                                data-qa="oLmouLvdTb2nVCKE8yzgu"
                                disabled={data.isArchived}
                                className={classNames(styles.deleteAsset, {
                                  [styles.assetNoAction]: data.isArchived,
                                })}
                                onClick={() =>
                                  this.props.handleDeleteClick(data)
                                }
                              >
                                <T {...messages.showEditDivWithDeleteOption} />
                              </div>
                              <div
                                className={classNames(styles.downloadAsset, {
                                  [styles.assetNoAction]:
                                    data.uploadStatus !==
                                    ASSET_VALID_UPLOAD_STATUS,
                                })}
                              >
                                <a
                                  href={`${mediaUrl}${downloadUrl}${mediaToken}&p=original`}
                                >
                                  <T
                                    {...messages.showEditDivWithDownloadOption}
                                  />
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                        {data.uploadStatus === ASSET_VALID_UPLOAD_STATUS && (
                          <div className={styles.imageContainer}>
                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                            <img
                              className={styles.imgHolder}
                              src={`${mediaUrl}${data.url}${mediaToken}&p=medium`}
                            />
                          </div>
                        )}
                        {data.uploadStatus !== ASSET_VALID_UPLOAD_STATUS && (
                          <div>{this.renderProgressSection(data)}</div>
                        )}
                      </div>
                      <div className={styles.thumbnailInfo}>
                        <p className={styles.imgName}>
                          {data.name}.{data.extension}
                        </p>
                        <p className={styles.imgSize}>
                          <span className={styles.imgSizeDetails}>
                            {data.sizeInKb} KB
                          </span>
                          <span className={styles.imgDimension}>
                            {data.width} x {data.height}
                          </span>
                          {data.isArchived && (
                            <span className={styles.assetIsArchived}>
                              <SvgIcon icon="no" width={18} height={18} />
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className={styles.noTextLabel}>
                  <T {...messages.showNoAssetsText} />
                </span>
              )}
              {dragOver && (
                <div className={styles.dropFilesSection}>
                  <span className={styles.dropFilesText}>
                    <T {...messages.showDropFilesText} />
                  </span>
                </div>
              )}
            </div>
          </Dropzone>
        </div>
      </div>
    );
  }
}
MediaBrowseAssetsPanel.propTypes = {
  data: PropTypes.shape({
    clickedAssetId: PropTypes.string,
    showEditDiv: PropTypes.bool,
    selectedAsset: PropTypes.object,
    isScreenForBrowseFilesFromList: PropTypes.bool,
    activeTab: PropTypes.number,
    mediaAllJson: PropTypes.array,
    mediaImageJson: PropTypes.array,
    mediaVideoJson: PropTypes.array,
    downloadableAssetIDs: PropTypes.array,
    dragOver: PropTypes.bool,
    showDeleteConfirmationModalForSingleFile: PropTypes.bool,
    showUploadOptionsFromEmail: PropTypes.bool,
    mediaDocJson: PropTypes.array,
    currentMediaSection: PropTypes.array,
    searchText: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isFromDownloadable: PropTypes.bool,
  mediaUrl: PropTypes.string,
  mediaToken: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    formatNumber: PropTypes.func.isRequired,
  }).isRequired,
  onSearchText: PropTypes.func,
  handleChange: PropTypes.func,
  sort: PropTypes.func,
  tabClicks: PropTypes.func,
  assetClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  onCancelConfirmationModalForSingleFile: PropTypes.func,
  onConfirmConfirmationModalForSingleFile: PropTypes.func,
  handleSelectedAsset: PropTypes.func,
  onChangeHandler: PropTypes.func,
  toggleDragStyleChange: PropTypes.func,
};
MediaBrowseAssetsPanel.defaultProps = {
  data: {
    clickedAssetId: null,
    showEditDiv: false,
    selectedAsset: {},
    isScreenForBrowseFilesFromList: true,
    activeTab: null,
    mediaAllJson: [],
    mediaImageJson: [],
    mediaVideoJson: [],
    downloadableAssetIDs: [],
    dragOver: false,
    showDeleteConfirmationModalForSingleFile: false,
    showUploadOptionsFromEmail: false,
    mediaDocJson: [],
    currentMediaSection: '',
    searchText: '',
  },
  isLoading: false,
  isFromDownloadable: false,
  mediaUrl: '',
  mediaToken: '',
  onSearchText: () => {},
  handleChange: () => {},
  sort: () => {},
  tabClicks: () => {},
  assetClick: () => {},
  handleEditClick: () => {},
  handleDeleteClick: () => {},
  onCancelConfirmationModalForSingleFile: () => {},
  onConfirmConfirmationModalForSingleFile: () => {},
  handleSelectedAsset: () => {},
  onChangeHandler: () => {},
  toggleDragStyleChange: () => {},
};

export default injectIntl(MediaBrowseAssetsPanel);
