import isUndefined from 'lodash/isUndefined';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import moment from 'moment';

import {
  ARTICLES_SORT_OPTIONS,
  ARTICLE_METRICS_UVM_FIELDS,
  EXPORT_METRICS,
  DEFAULT_DATE_SEARCH_FORMAT,
} from 'constants/constants';
import { removeWhitespaces } from 'utils/strings';

function getPeriodFromDataPointString(dataPoint) {
  /*
    Parses our custom dataPoint strings, i.e.
      'dataPoint=613561.week.3/12/18-3/18/18'
    and turns them into better objects, like
      {
        dateString: '3/12/18-3/18/18',
        scale: 'week',
        prefix: '613561',
      }
  */
  const scales = ['day', 'week', 'month'];

  const period = scales.reduce(
    (result, thisScale) => {
      if (
        dataPoint.indexOf(thisScale) === 0 ||
        dataPoint.indexOf(thisScale) >= 0
      ) {
        result.scale = thisScale;
      }

      return result;
    },
    { scale: '', dateString: '', prefix: '' },
  );

  if (dataPoint.indexOf(`.${period.scale}`) >= 0) {
    period.dateString = dataPoint.substr(
      dataPoint.indexOf(`.${period.scale}`) + `.${period.scale}`.length + 1,
    );
    period.prefix = dataPoint.substr(0, dataPoint.indexOf(`.${period.scale}`));
  }

  if (dataPoint.indexOf(`${period.scale}`) === 0) {
    period.dateString = dataPoint.substr(`${period.scale}`.length);
  }

  period.dateString = decodeURIComponent(period.dateString);

  return period;
}

export const getDatesFromDataPointString = dataPoint => {
  let thisMoment = moment();
  let endDate = thisMoment.toDate();
  let startDate = thisMoment.subtract(90, 'days').toDate(); // .subtract mutates the moment, so this is second
  const period = getPeriodFromDataPointString(dataPoint);

  // Old-school dataPoints have funky formatted date strings
  if (period.dateString) {
    switch (period.scale) {
      case 'day':
        // day.8/03/17
        thisMoment = moment(period.dateString, 'M/DD/YY');
        startDate = thisMoment.toDate();
        endDate = thisMoment.endOf('day').toDate();
        break;
      case 'week':
        // week.7/31/17-8/06/17
        thisMoment = moment(period.dateString.split('-')[0], 'M/DD/YY');
        startDate = thisMoment.toDate();
        thisMoment = moment(period.dateString.split('-')[1], 'M/DD/YY');
        endDate = thisMoment.endOf('day').toDate();
        break;
      case 'month':
        // month.Jul 2017
        thisMoment = moment(period.dateString, 'MMM YYYY');
        startDate = thisMoment.toDate();
        endDate = thisMoment
          .add(1, 'months')
          .subtract(1, 'days')
          .endOf('day')
          .toDate();
        break;
      default:
        break;
    }
  }

  return {
    startDate,
    endDate,
    period: period.scale || 'week',
    hasDateScale: !!period.scale,
  };
};

export const getDataPointStringFromDates = (
  startDate,
  endDate,
  oldDataPoint,
) => {
  const oldPeriod = oldDataPoint
    ? getPeriodFromDataPointString(oldDataPoint)
    : {};

  if (oldDataPoint && !oldPeriod.scale) {
    return oldDataPoint; // This dataPoint doesn't support dates
  }

  let newDataPoint = `week.${moment(startDate).format('M/D/YY')}-${moment(
    endDate,
  ).format('M/D/YY')}`;

  newDataPoint = oldPeriod.prefix
    ? `${oldPeriod.prefix}.${newDataPoint}`
    : newDataPoint;

  return newDataPoint;
};

export const getSplitSortFromUrlParams = ({ sort }) => {
  // Default sort is sort-date-desc
  const sortDirection = sort.indexOf('asc') >= 0 ? 'asc' : 'desc';
  const sortField = ARTICLES_SORT_OPTIONS.reduce((result, option) => {
    return sort.indexOf(option.id) >= 0 ? option.id.substr(5) : result;
  }, 'date');

  return {
    sortField,
    sortDirection,
  };
};

// For creating get requests with list filters
export const getApiQueryParamsFromList = list => {
  return list && list.queryParams ? list.queryParams : null;
};

// Return query string params for the browser location
export const getBrowserQueryParamsFromList = list => {
  const browserQueryParams =
    list && list.queryParams
      ? pick(list.queryParams, [
          'analyticsIntegrationId',
          'dataPoint',
          'sort',
          'selectedSearchid',
          'pageNum',
          'viewFilters',
        ])
      : {};
  return pickBy(browserQueryParams, param => {
    if (typeof param === 'number' && isNaN(param)) {
      return false;
    }
    return param !== null && typeof param !== 'undefined';
  }); // Deliberately leaving space for 0 and false
};

// For ingesting the location on drilldown page load
export const getListFromUrlParams = (
  urlParams,
  id,
  type,
  defaultAnalyticsIntegrationId,
) => {
  // ?selectedSearchId=608523&dataPoint=day.10%2F1%2F17&pageNum=1&pageSize=100&sort=sort-date-asc
  // const parsedDataPoint =
  const paramsToAdd = urlParams || {};
  const dates = paramsToAdd.dataPoint
    ? getDatesFromDataPointString(paramsToAdd.dataPoint)
    : {};
  const period = dates.period || '';
  const endDate = paramsToAdd.endDate || dates.endDate || '';
  const startDate = paramsToAdd.startDate || dates.startDate || '';
  const pageNum = !isNaN(parseInt(paramsToAdd.pageNum, 10))
    ? parseInt(paramsToAdd.pageNum, 10)
    : NaN;
  if (paramsToAdd.viewFilters && !Array.isArray(paramsToAdd.viewFilters)) {
    paramsToAdd.viewFilters = [paramsToAdd.viewFilters];
  }

  const list = {
    analyticsIntegrationId:
      paramsToAdd.analyticsIntegrationId || defaultAnalyticsIntegrationId,
    endDate,
    id: paramsToAdd.id || id,
    isDateFilteringEnabled: !!period && paramsToAdd.dataPoint !== '0',
    period,
    queryParams: {
      ...paramsToAdd,
      pageNum,
    },
    startDate,
    type: paramsToAdd.type || type,
    hasDateScale: dates.hasDateScale,
  };

  return pickBy(list, entry => !isUndefined(entry));
};

// For the
export const getApiQueryParamsFromUrlParams = (
  urlParams,
  id,
  type,
  defaultAnalyticsIntegrationId,
) => {
  return getApiQueryParamsFromList(
    getListFromUrlParams(urlParams, id, type, defaultAnalyticsIntegrationId),
  );
};

const getSearchExportParamsFromList = list => {
  const { id } = list;
  const { startDate, endDate } = list.queryParams;
  const articleListId = id.includes('.') && Number(id.split('.').pop());
  const customStartDate = moment
    .utc(startDate)
    .format(DEFAULT_DATE_SEARCH_FORMAT);
  const customEndDate = moment.utc(endDate).format(DEFAULT_DATE_SEARCH_FORMAT);

  return {
    data_point: `range.${customStartDate}-${customEndDate}`,
    articleListId,
  };
};

export const getExportParamsFromList = (
  dashboardId,
  storyId,
  list,
  userMetrics,
) => {
  const columns = [...EXPORT_METRICS, ...userMetrics];
  const { analyticsIntegrationId, queryParams } = list;
  const {
    dataPoint: data_point,
    keywordFilterText,
    sort,
    viewFilters,
    widgetId,
  } = queryParams; // eslint-disable-line camelcase

  let exportQueryParams = {
    _: moment().valueOf(),
    analyticsIntegrationId,
    columns,
    dash_id: dashboardId,
    keywordFilterText,
    data_point,
    sort,
    storyId,
    tagId: null,
    viewFilters,
    widgetId,
  };

  if (storyId) {
    const { endDate, newQueryText, startDate } = queryParams;

    exportQueryParams = {
      ...exportQueryParams,
      dateEnd: moment.utc(endDate).valueOf(),
      dateStart: moment.utc(startDate).valueOf(),
      queryFilter: newQueryText,
    };
  }

  if (!widgetId) {
    exportQueryParams = {
      ...exportQueryParams,
      ...getSearchExportParamsFromList(list),
    };
  }

  return exportQueryParams;
};

export const isUvmMetric = currentMetric => {
  currentMetric = removeWhitespaces(currentMetric);
  return ARTICLE_METRICS_UVM_FIELDS.some(
    metric => metric.toLowerCase() === currentMetric.toLowerCase(),
  );
};
