import { CAMPAIGN_CATEGORIES_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

export const GET_CAMPAIGN_CATEGORIES_DATA =
  'campaign-categories/GET_CAMPAIGN_CATEGORIES_DATA';
export const GET_CAMPAIGN_CATEGORIES_DATA_RECEIVED =
  'campaign-categories/GET_CAMPAIGN_CATEGORIES_DATA_RECEIVED';
export const GET_CAMPAIGN_CATEGORIES_DATA_ERROR =
  'campaign-categories/GET_CAMPAIGN_CATEGORIES_DATA_ERROR';

const initialState = {
  campaignCategories: [],
  error: false,
  loading: true,
};

const campaignCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_CATEGORIES_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_CAMPAIGN_CATEGORIES_DATA_RECEIVED:
      return {
        ...state,
        campaignCategories: action.payload,
        error: false,
        loading: false,
      };
    case GET_CAMPAIGN_CATEGORIES_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const getCampaignCategories = () => dispatch => {
  dispatch({ type: GET_CAMPAIGN_CATEGORIES_DATA });

  performGet(`${CAMPAIGN_CATEGORIES_ENDPOINT}`)
    .then(response => {
      dispatch({
        type: GET_CAMPAIGN_CATEGORIES_DATA_RECEIVED,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_CAMPAIGN_CATEGORIES_DATA_ERROR });
    });
};

export default campaignCategoriesReducer;
