import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

class Menu extends Component {
  static baseClass = 'tk-dropdownMenu';

  static duration = 100;

  render() {
    const { children, isOpen, position, width } = this.props;
    const style = { width };

    const mainClass = classnames(
      Menu.baseClass,
      `${Menu.baseClass}--${position}`,
    );

    return (
      <CSSTransition
        classNames={`${Menu.baseClass}--fade`}
        in={isOpen}
        timeout={Menu.duration}
        unmountOnExit
      >
        <div
          className={mainClass}
          style={style}
          data-qa="MZ0Ryzf8gNh--Bh6tCJN8"
        >
          <div className={`${Menu.baseClass}-contents`}>{children}</div>
        </div>
      </CSSTransition>
    );
  }
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  position: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'bottomCenter',
    'bottomLeft',
    'bottomRight',
    'left',
    'topLeft',
    'topRight',
  ]),
  width: PropTypes.string,
};

Menu.defaultProps = {
  position: 'bottom',
  width: 'auto',
};

export default Menu;
