export const SET_OWNER_FILTER = 'analytics/SET_OWNER_FILTER';
export const SET_SEARCH_FILTER = 'analytics/SET_SEARCH_FILTER';
export const SET_JUST_FILTERED = 'analytics/SET_JUST_FILTERED';
export const TOGGLE_SEARCHOPEN = 'analytics/TOGGLE_SEARCHOPEN';
export const CLEAR_FILTERS = 'analytics/CLEAR_FILTERS';

export const initialState = {
  owner: 'all',
  category: 'all',
  search: '',
  searchOpen: false,
};

const listFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OWNER_FILTER:
      return {
        ...state,
        owner: action.payload.ownerFilter,
      };
    case SET_SEARCH_FILTER:
      return {
        ...state,
        search: action.payload.searchFilter,
      };
    case TOGGLE_SEARCHOPEN:
      return {
        ...state,
        searchOpen: action.payload.isOpen,
      };
    case CLEAR_FILTERS: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export const setOwnerFilter = ownerFilter => ({
  type: SET_OWNER_FILTER,
  payload: { ownerFilter },
});

export const setSearchFilter = searchFilter => ({
  type: SET_SEARCH_FILTER,
  payload: { searchFilter },
});

export const toggleSearchOpen = isOpen => ({
  type: TOGGLE_SEARCHOPEN,
  payload: { isOpen },
});

export const clearListFilters = () => ({ type: CLEAR_FILTERS });

export default listFilterReducer;
