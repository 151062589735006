import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Responsive } from '@cision/rover-ui';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Cookies from 'universal-cookie';

import { PageHeader } from '@trendkite/ui';

import {
  DEV_FEATURE_OVERRIDE_COOKIE_KEY,
  IMPERSONATING_COOKIE_KEY,
  DEV_FEATURES,
  INFLUENCERS_HUB_URL,
  STORIES_URL,
  ARTICLES_URL,
  UNIFIED_SETTINGS_URL,
  FEATURES,
  BASE_URL,
  WELCOME_HUB_URL,
} from 'trendkite-front-end-app/src/constants/constants';

import TranslationService from 'trendkite-front-end-app/src/pages/Translate/translation-service';

import { getCanUseWelcomeHub } from 'trendkite-front-end-app/src/selectors/canUse/canUse';

import {
  userHasDevFeatureFlag,
  userHasFeatureFlag,
} from 'trendkite-front-end-app/src/services/feature-service/feature-service';
import { logImpersonateEnd } from 'trendkite-front-end-app/src/utils/log-impersonate-end';

import Menu from './menu';
import ResponsiveNav from './responsive-nav';
import SecondaryMenu from './SecondaryMenu';
import './universal-header.scss';

const translationService = new TranslationService();

const validateMonitoringFfRedirect = locationPath => {
  const monitoringGreenList = [
    BASE_URL,
    INFLUENCERS_HUB_URL,
    STORIES_URL,
    ARTICLES_URL,
    UNIFIED_SETTINGS_URL,
  ];

  if (!userHasFeatureFlag(FEATURES.monitoring)) {
    return monitoringGreenList.some(entry => locationPath.startsWith(entry));
  }

  return true;
};

const DEFAULT_BREAKPOINT_MIN_WIDTHS = {
  xs: 0,
  sm: 700,
  md: 900,
  lg: 901,
};

const UniversalNavHeader = ({
  chatEnabled,
  cisionCompanyId,
  currentNav,
  firstName,
  isAuthed,
  isUnfixed,
  loginNavEnabled,
  logoutUrl,
  userIsImpersonating,
  username,
}) => {
  const intl = useIntl();
  const logoRef = useRef(null);
  const menuLgRef = useRef(null);
  const secondaryRef = useRef(null);

  const redirectOnLoad = useCallback(() => {
    if (!isAuthed) {
      return;
    }

    const pathname = window.location.href.slice(window.location.origin.length);

    if (!validateMonitoringFfRedirect(pathname)) {
      window.location.href = INFLUENCERS_HUB_URL;
    }
  }, [isAuthed]);

  const redirectListener = useCallback(
    event => {
      if (!isAuthed) {
        return;
      }

      event.stopPropagation();
      const { hash } = event.currentTarget.location;

      if (!validateMonitoringFfRedirect(`/${hash}`)) {
        window.location.href = INFLUENCERS_HUB_URL;
      }
    },
    [isAuthed],
  );

  useEffect(() => {
    redirectOnLoad();
    window.addEventListener('popstate', redirectListener);

    return () => window.removeEventListener('popstate', redirectListener);
  }, [redirectListener, redirectOnLoad]);

  const endImpersonation = () => {
    const cookies = new Cookies();
    cookies.remove(IMPERSONATING_COOKIE_KEY, {
      path: '/',
    });
    cookies.remove(DEV_FEATURE_OVERRIDE_COOKIE_KEY);

    //do it here
    logImpersonateEnd();
    window.location = '/';
  };

  const showUsSalesforceChat =
    chatEnabled && translationService.showUsSalesforceChatForLocale(intl);
  const showUkSalesforceChat =
    chatEnabled && translationService.showUkSalesforceChatForLocale(intl);

  const browseLinkEnabled = userHasDevFeatureFlag(
    DEV_FEATURES.browseAssetLibraryForStandalonePage,
  );

  const canUseWelcomeHub = getCanUseWelcomeHub();

  const clickUrl = canUseWelcomeHub ? `/#${WELCOME_HUB_URL}` : '/main';

  const [lgMinWidth, setLgMinWidth] = useState(
    DEFAULT_BREAKPOINT_MIN_WIDTHS.lg,
  );

  const updateLgMinWidth = useCallback(() => {
    if (!menuLgRef.current || !logoRef.current || !secondaryRef.current) return;

    const menuChildrenWidth = [...menuLgRef.current.children].reduce(
      (a, c) => (a += c.offsetWidth),
      0,
    );

    let nextLgMinWidth =
      menuChildrenWidth +
      logoRef.current.offsetWidth +
      secondaryRef.current.offsetWidth;

    nextLgMinWidth =
      nextLgMinWidth > DEFAULT_BREAKPOINT_MIN_WIDTHS.md
        ? nextLgMinWidth
        : DEFAULT_BREAKPOINT_MIN_WIDTHS.md + 1;

    if (lgMinWidth !== nextLgMinWidth) setLgMinWidth(nextLgMinWidth);
  }, [lgMinWidth]);

  const breakpoints = useMemo(() => {
    const lgBreakpointMap =
      lgMinWidth !== DEFAULT_BREAKPOINT_MIN_WIDTHS.lg ? { lg: lgMinWidth } : {};

    const nextBreakpointsMap = {
      ...DEFAULT_BREAKPOINT_MIN_WIDTHS,
      ...lgBreakpointMap,
    };

    return Object.entries(nextBreakpointsMap).map(([k, v]) => ({
      minWidth: v,
      name: k,
    }));
  }, [lgMinWidth]);

  return (
    <Responsive.Container customBreakpoints={breakpoints}>
      <PageHeader unfixed={isUnfixed}>
        <Responsive.Element>
          <ResponsiveNav
            logo={<PageHeader.Logo ref={logoRef} url={clickUrl} />}
            menu={
              <Menu
                cisionCompanyId={cisionCompanyId}
                currentNav={currentNav}
                endImpersonation={endImpersonation}
                firstName={firstName}
                isAuthed={isAuthed}
                loginNavEnabled={loginNavEnabled}
                logoutUrl={logoutUrl}
                menuLgRef={menuLgRef}
                userIsImpersonating={userIsImpersonating}
                username={username}
              />
            }
            updateLgMinWidth={updateLgMinWidth}
            secondaryNav={
              <SecondaryMenu
                browseLinkEnabled={browseLinkEnabled}
                usSalesforceChatEnabled={showUsSalesforceChat}
                ukSalesforceChatEnabled={showUkSalesforceChat}
                endImpersonation={endImpersonation}
                firstName={firstName}
                isAuthed={isAuthed}
                loginNavEnabled={loginNavEnabled}
                logoutUrl={logoutUrl}
                ref={secondaryRef}
                userIsImpersonating={userIsImpersonating}
                username={username}
              />
            }
          />
        </Responsive.Element>
      </PageHeader>
    </Responsive.Container>
  );
};

UniversalNavHeader.propTypes = {
  chatEnabled: PropTypes.bool,
  cisionCompanyId: PropTypes.number,
  currentNav: PropTypes.string,
  firstName: PropTypes.string,
  isAuthed: PropTypes.bool,
  isUnfixed: PropTypes.bool,
  loginNavEnabled: PropTypes.bool,
  logoutUrl: PropTypes.string.isRequired,
  userIsImpersonating: PropTypes.bool,
  username: PropTypes.string,
};

UniversalNavHeader.defaultProps = {
  chatEnabled: true,
  cisionCompanyId: NaN,
  currentNav: '',
  firstName: '',
  isAuthed: false,
  isUnfixed: false,
  loginNavEnabled: true,
  userIsImpersonating: false,
  username: '',
};

UniversalNavHeader.baseClass = 'universal-nav-header';

export default UniversalNavHeader;
