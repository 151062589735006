import { ADMIN_USER_IMPERSONATE_URL } from 'trendkite-front-end-app/src/constants/apis';

import { performPost } from 'trendkite-front-end-app/src/services/rest-service/rest-service';

export const logImpersonateEnd = async () => {
  const currentUser = window.activeUser;

  try {
    await performPost(`${ADMIN_USER_IMPERSONATE_URL}/${currentUser.id}/end`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to send request to backend: ', e);
  }
};
