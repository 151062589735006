import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/unionBy';
import uniq from 'lodash/uniq';

import {
  INFLUENCER_HUB_ENDPOINT,
  INFLUENCER_GET_PRIVATE_INFLUENCERS_URL,
  INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT,
  INFLUENCER_SAVED_LISTS_CSV_EXPORT,
  INFLUENCER_GET_UNSUBSCRIBED_INFLUENCERS_URL,
} from 'constants/apis';
import { SAVED_LIST_INFLUENCERS_NUM_LIMIT } from 'constants/constants';
import { Influencer as InfluencerType } from 'pages/Influencers/types';
import { performPost } from 'services/rest-service/rest-service';

import { CHANGE_PAGE_NUMBER } from '../../Search/reducer';
import { fullSearchCriteriaSelector } from '../../Search/selectors';

const SET_ALL_NEWLY_ADDED_INFLUENCERS = 'SET_ALL_NEWLY_ADDED_INFLUENCERS';
const CLEAR_ALL_NEWLY_ADDED_INFLUENCERS = 'CLEAR_ALL_NEWLY_ADDED_INFLUENCERS';

const INFLUENCERS_SELECT_INFLUENCER_ID =
  'ihub-search/INFLUENCERS_SELECT_INFLUENCER_ID';
const INFLUENCERS_UNSELECT_INFLUENCER_ID =
  'ihub-search/INFLUENCERS_UNSELECT_INFLUENCER_ID';

export const INFLUENCERS_BULK_PAGE_SELECT_ALL_INFLUENCERS =
  'ihub-search/INFLUENCERS_BULK_PAGE_SELECT_ALL_INFLUENCERS';
export const INFLUENCERS_BULK_PAGE_UNSELECT_ALL_INFLUENCERS =
  'ihub-search/INFLUENCERS_BULK_PAGE_UNSELECT_ALL_INFLUENCERS';

export const INFLUENCERS_BULK_PAGE_MULTI_UNSELECT_ALL_INFLUENCERS =
  'ihub-search/INFLUENCERS_BULK_PAGE_MULTI_UNSELECT_ALL_INFLUENCERS';

export const FETCH_ALL_INFLUENCERS_START =
  'ihub-search/FETCH_ALL_INFLUENCERS_START';
export const FETCH_ALL_INFLUENCERS_SUCCESS =
  'ihub-search/FETCH_ALL_INFLUENCERS_SUCCESS';
export const FETCH_ALL_INFLUENCERS_ERROR =
  'ihub-search/FETCH_ALL_INFLUENCERS_ERROR';
export const INFLUENCERS_BULK_SELECT_ALL_INFLUENCERS =
  'ihub-search/INFLUENCERS_BULK_SELECT_ALL_INFLUENCERS';

export const INFLUENCERS_BULK_MULTI_SELECT_ALL_INFLUENCERS =
  'ihub-search/INFLUENCERS_BULK_MULTI_SELECT_ALL_INFLUENCERS';

export const INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS =
  'ihub-search/INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS';

export const INFLUENCERS_ON_SEARCH = 'ihub-search/INFLUENCERS_ON_SEARCH';

export const INFLUENCERS_BULK_PAGE_SELECT_AND_UPDATE_ALL_INFLUENCERS =
  'ihub-search/INFLUENCERS_BULK_PAGE_SELECT_AND_UPDATE_ALL_INFLUENCERS';

export const TOGGLE_SEARCH_RESULT_FILTER_PANEL =
  'influencer-hub/TOGGLE_SEARCH_RESULT_FILTER_PANEL';
export const HIDE_SEARCH_RESULT_FILTER_PANEL =
  'influencer-hub/HIDE_SEARCH_RESULT_FILTER_PANEL';
export const SHOW_SEARCH_RESULT_FILTER_PANEL =
  'influencer-hub/SHOW_SEARCH_RESULT_FILTER_PANEL';

export const FETCH_ALL_ADD_TO_LIST = 'addToList';
export const FETCH_ALL_PERSONALIZED_PITCH = 'personalizedPitch';
export const FETCH_ALL_EMAIL_ANNOUNCEMENT = 'emailAnnouncement';
export const FETCH_ALL_BULK_REMOVE = 'bulkRemove';
export const INFLUENCER_ALL_SELECTED = 'ihub-search/INFLUENCER_ALL_SELECTED';
export const SELECT_INFLUENCER_AFTER_PAGE_CHANGE =
  'ihub-search/SELECT_INFLUENCER_AFTER_PAGE_CHANGE';

export const INFLUENCERS_MULTI_SELECT_ALL_INFLUENCERS_SEARCHED =
  'ihub-search/INFLUENCERS_MULTI_SELECT_ALL_INFLUENCERS_SEARCHED';

interface InfluencerWithPage extends InfluencerType {
  page: number;
}

export const initialState = {
  selectedIds: [] as string[],
  selectedInfluencers: [] as InfluencerWithPage[],
  allInfluencersAddedToList: [],
  selectedIdsAfterSearch: [],
  allInfluencersOnPageSelected: false,
  allInfluencersSelected: false,
  allInfluencersSelectedError: false,
  isMultipageSelection: false,
  filterPanelOpen: true,
  loadingAllInfluencersSelection: false,
};

const currentPageSize = 50;

const getAllInfluencersOnPageSelected = ({
  currentPageSelectedCount,
  currentPageCount,
  totalResultCount,
}: {
  currentPageSelectedCount: number;
  currentPageCount: number;
  totalResultCount?: number;
}) =>
  currentPageSelectedCount >= currentPageCount ||
  !!(totalResultCount && currentPageSelectedCount === totalResultCount);

const getIsMultipageSelection = ({
  pagesWithSelections,
  currentPageNumber,
}: {
  pagesWithSelections: number[];
  currentPageNumber?: number;
}) =>
  pagesWithSelections.length > 1
    ? true
    : !!(
        pagesWithSelections.length &&
        currentPageNumber &&
        !pagesWithSelections.find(p => p === currentPageNumber)
      );

const influencerHubSearchReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_ALL_NEWLY_ADDED_INFLUENCERS: {
      return {
        ...state,
        allInfluencersAddedToList: action.payload,
      };
    }
    case CLEAR_ALL_NEWLY_ADDED_INFLUENCERS: {
      return {
        ...state,
        allInfluencersAddedToList: [],
      };
    }
    case INFLUENCERS_SELECT_INFLUENCER_ID: {
      const selectedInfluencers = uniqBy(
        [...state.selectedInfluencers, action.payload.influencer],
        'id',
      );

      const validateInfluencer = selectedInfluencers
        .map(influencer => influencer.id)
        .filter(Boolean);

      if (!validateInfluencer?.length) return state;

      const selectedIds = [...state.selectedIds, action.payload.influencer.id];
      const selectedCurrentPage = selectedInfluencers.filter(
        influencer => influencer.page === action.payload.currentPage,
      );

      const selectedFromPages = uniq([
        ...selectedInfluencers.map(influencer => influencer.page),
      ]);

      const allInfluencersOnPageSelected = getAllInfluencersOnPageSelected({
        currentPageSelectedCount: selectedCurrentPage.length,
        currentPageCount: currentPageSize,
        totalResultCount: action.payload.totalResults,
      });

      const isMultipageSelection = getIsMultipageSelection({
        pagesWithSelections: selectedFromPages,
      });

      return {
        ...state,
        selectedIds,
        selectedInfluencers,
        allInfluencersOnPageSelected,
        isMultipageSelection,
      };
    }
    case INFLUENCER_ALL_SELECTED: {
      return {
        ...state,
        allInfluencersSelected: action.payload.allInfluencersSelected,
      };
    }

    case INFLUENCERS_UNSELECT_INFLUENCER_ID: {
      return {
        ...state,
        selectedIds: state.selectedIds.filter(
          sid => !isEqual(sid, action.payload.id),
        ),
        selectedInfluencers: state.selectedInfluencers.filter(
          influencer => !isEqual(influencer.id, action.payload.id),
        ),
        allInfluencersOnPageSelected: false,
      };
    }

    case INFLUENCERS_BULK_PAGE_SELECT_ALL_INFLUENCERS: {
      const ids = action.payload.map(influencer => influencer.id);
      return {
        ...state,
        selectedIds: [...state.selectedIds, ...ids],
        selectedInfluencers: uniqBy(
          [...state.selectedInfluencers, ...action.payload],
          'id',
        ),
        allInfluencersOnPageSelected: true,
      };
    }

    case INFLUENCERS_BULK_PAGE_UNSELECT_ALL_INFLUENCERS: {
      if (action.payload) {
        const filterInfluencers = state.selectedInfluencers.filter(
          influencer => influencer.page !== action.payload,
        );
        const ids = filterInfluencers.map(influencer => influencer.id);
        return {
          ...state,
          selectedIds: ids,
          selectedInfluencers: filterInfluencers,
          allInfluencersOnPageSelected: false,
        };
      }
      return {
        ...state,
        selectedIds: [],
        selectedInfluencers: [],
        allInfluencersOnPageSelected: false,
      };
    }

    case INFLUENCERS_BULK_PAGE_MULTI_UNSELECT_ALL_INFLUENCERS: {
      if (action.payload) {
        const { influencerIds, influencers } = action.payload;
        return {
          ...state,
          selectedIds: influencerIds,
          selectedInfluencers: influencers,
          allInfluencersOnPageSelected: false,
        };
      }
      return {
        ...state,
        selectedIds: [],
        selectedInfluencers: [],
        allInfluencersOnPageSelected: false,
      };
    }

    case INFLUENCERS_BULK_MULTI_SELECT_ALL_INFLUENCERS: {
      const ids = action.payload.map(influencer => influencer.id);
      return {
        ...state,
        selectedIds: uniq([...state.selectedIds, ...ids]),
        selectedInfluencers: uniqBy(
          [...state.selectedInfluencers, ...action.payload],
          'id',
        ),
        allInfluencersSelected: true,
        loadingAllInfluencersSelection: false,
      };
    }

    case INFLUENCERS_MULTI_SELECT_ALL_INFLUENCERS_SEARCHED: {
      return {
        ...state,
        selectedIdsAfterSearch: action.payload,
      };
    }

    case INFLUENCERS_BULK_SELECT_ALL_INFLUENCERS: {
      return {
        ...state,
        allInfluencersSelected: true,
      };
    }

    case FETCH_ALL_INFLUENCERS_START: {
      return {
        ...state,
        loadingAllInfluencersSelection: true,
        allInfluencersSelectedError: false,
      };
    }

    case FETCH_ALL_INFLUENCERS_SUCCESS: {
      return {
        ...state,
        selectedIds: action.payload || state.selectedIds,
      };
    }

    case FETCH_ALL_INFLUENCERS_ERROR: {
      return {
        ...state,
        allInfluencersSelectedError: true,
        loadingAllInfluencersSelection: false,
      };
    }

    case INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS: {
      return {
        ...state,
        allInfluencersOnPageSelected: false,
        allInfluencersSelected: false,
        allInfluencersSelectedError: false,
        selectedIds: [],
        selectedInfluencers: [],
        loadingAllInfluencersSelection: false,
      };
    }

    case INFLUENCERS_ON_SEARCH: {
      return {
        ...state,
        allInfluencersOnPageSelected: false,
        allInfluencersSelected: false,
        allInfluencersSelectedError: false,
        loadingAllInfluencersSelection: false,
      };
    }

    case TOGGLE_SEARCH_RESULT_FILTER_PANEL: {
      return {
        ...state,
        filterPanelOpen: !state.filterPanelOpen,
      };
    }

    case HIDE_SEARCH_RESULT_FILTER_PANEL: {
      return {
        ...state,
        filterPanelOpen: false,
      };
    }

    case SHOW_SEARCH_RESULT_FILTER_PANEL: {
      return {
        ...state,
        filterPanelOpen: true,
      };
    }

    case INFLUENCERS_BULK_PAGE_SELECT_AND_UPDATE_ALL_INFLUENCERS: {
      if (action.payload.length > 0) {
        return {
          ...state,
          selectedInfluencers: action.payload,
          allInfluencersOnPageSelected: true,
        };
      }

      return state;
    }

    case SELECT_INFLUENCER_AFTER_PAGE_CHANGE: {
      return {
        ...state,
        selectedInfluencers: action.payload,
      };
    }

    case CHANGE_PAGE_NUMBER: {
      const selectedCurrentPage = state.selectedInfluencers.filter(
        influencer => influencer.page === action.payload.pageNumber,
      );

      const selectedFromPages = uniq(
        state.selectedInfluencers.map(influencer => influencer.page),
      );

      const allInfluencersOnPageSelected = getAllInfluencersOnPageSelected({
        currentPageSelectedCount: selectedCurrentPage.length,
        currentPageCount: currentPageSize,
      });

      const isMultipageSelection = getIsMultipageSelection({
        pagesWithSelections: selectedFromPages,
        currentPageNumber: action.payload.pageNumber,
      });

      return {
        ...state,
        // Potential Bug: This does not take into account when there are less than 50 items on the page
        // and the user selects each one individually
        allInfluencersOnPageSelected,
        isMultipageSelection,
      };
    }

    default:
      return state;
  }
};

export const selectInfluencerInSearchActionDispatcher = (
  influencer,
  pageSelected?,
) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      currentPageNumber,
      totalResults,
    } = state.influencerSearch.searchResults;

    dispatch({
      type: INFLUENCERS_SELECT_INFLUENCER_ID,
      payload: {
        influencer: { ...influencer, page: pageSelected || currentPageNumber },
        currentPage: pageSelected || currentPageNumber,
        totalResults,
      },
    });

    const updatedState = getState();
    const {
      totalResults: updatedTotalResults,
    } = updatedState.influencerSearch.searchResults;

    const {
      influencerHubSearch: { selectedIds },
    } = updatedState;

    if (selectedIds.length === updatedTotalResults) {
      dispatch({
        type: INFLUENCER_ALL_SELECTED,
        payload: {
          allInfluencersSelected: true,
        },
      });
    }
  } catch (error) {
    return error;
  }
};

export const unselectInfluencerInSearchActionDispatcher = (
  influencer,
  pageSelected?,
) => (dispatch, getState) => {
  const state = getState();
  const { id } = influencer;
  const { currentPageNumber } = state.influencerSearch.searchResults;
  const page = pageSelected || currentPageNumber;
  dispatch({
    type: INFLUENCERS_UNSELECT_INFLUENCER_ID,
    payload: { id, page },
  });
};

export const bulkSelectAllInfluencerInSearch = (influencers, pageSelected) => {
  const influencersWithPage = influencers.map(influencer => ({
    ...influencer,
    page: pageSelected,
  }));
  return {
    type: INFLUENCERS_BULK_PAGE_SELECT_ALL_INFLUENCERS,
    payload: influencersWithPage,
  };
};

export const bulkSelectAllInfluencerInCurrentPage = influencers => (
  dispatch,
  getState,
) => {
  const state = getState();
  const {
    influencerSearch: {
      searchResults: { currentPageNumber },
    },
    influencerHubSearch: { selectedIds },
  } = state;

  const influencersWithPage = influencers
    .filter(influencer => selectedIds.includes(influencer.id))
    .map(influencer => ({
      ...influencer,
      page: currentPageNumber,
    }));
  dispatch({
    type: INFLUENCERS_BULK_PAGE_SELECT_AND_UPDATE_ALL_INFLUENCERS,
    payload: influencersWithPage,
  });
};

export const bulkUnselectAllInfluencerInSearch = (pageSelected?: number) => ({
  type: INFLUENCERS_BULK_PAGE_UNSELECT_ALL_INFLUENCERS,
  payload: pageSelected,
});

export const bulkMultiUnselectAllInfluencerInSearch = (
  influencers,
  pageSelected,
) => (dispatch, getState) => {
  const state = getState();
  const influencersWithPage = influencers.map(influencer => ({
    ...influencer,
    page: pageSelected,
  }));
  const influencerIds = influencers.map(influencer => influencer.id);
  const newSelectedIds = state.influencerHubSearch.selectedIds.filter(
    id => !influencerIds.includes(id),
  );

  const newSelectedInfluencers = state.influencerHubSearch.selectedInfluencers.filter(
    se => !influencersWithPage.some(o => o.id === se.id),
  );

  dispatch({
    type: INFLUENCERS_BULK_PAGE_MULTI_UNSELECT_ALL_INFLUENCERS,
    payload: {
      influencerIds: newSelectedIds,
      influencers: newSelectedInfluencers,
    },
  });
  dispatch({
    type: INFLUENCER_ALL_SELECTED,
    payload: {
      allInfluencersSelected: false,
    },
  });
};

export const bulkSelectAllInfluencers = () => ({
  type: INFLUENCERS_BULK_SELECT_ALL_INFLUENCERS,
});

const fetchAllInfluencersInTheSearch = async (state, maxSize, onlyId) => {
  const selectedListId = state.influencerSearch.pageState.selectedListId;

  let searchEndpoint = `${INFLUENCER_HUB_ENDPOINT}/search`;
  if (selectedListId === 'private') {
    searchEndpoint = INFLUENCER_GET_PRIVATE_INFLUENCERS_URL;
  } else if (selectedListId === 'unsubscribed') {
    searchEndpoint = INFLUENCER_GET_UNSUBSCRIBED_INFLUENCERS_URL;
  } else if (selectedListId) {
    searchEndpoint = INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT.replace(
      '<listId>',
      selectedListId,
    );
  }

  const searchCriteria = fullSearchCriteriaSelector(state);
  const searchCriteriaFullList = {
    ...searchCriteria,
    pagenumber: 1,
    pagesize: maxSize,
    source: onlyId ? ['_id'] : undefined,
  };

  const { data } = await performPost(searchEndpoint, searchCriteriaFullList);
  return data?.data ?? [];
};

export const bulkMultiSelectAllInfluencers = (search = false) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const onlyId = true;
  try {
    if (!search) {
      dispatch({ type: FETCH_ALL_INFLUENCERS_START });
    }
    const influencers = await fetchAllInfluencersInTheSearch(
      state,
      SAVED_LIST_INFLUENCERS_NUM_LIMIT,
      onlyId,
    );
    const influencerIds = influencers.map(influencer => influencer.id);

    if (search) {
      dispatch({
        type: INFLUENCERS_MULTI_SELECT_ALL_INFLUENCERS_SEARCHED,
        payload: influencerIds,
      });
    } else {
      dispatch({
        type: INFLUENCERS_BULK_MULTI_SELECT_ALL_INFLUENCERS,
        payload: influencers,
      });
    }
    return influencerIds;
  } catch (e) {
    dispatch({ type: FETCH_ALL_INFLUENCERS_ERROR });
  }
};

export const bulkUnselectAllInfluencers = () => dispatch => {
  dispatch({
    type: INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS,
  });
};

export const clearAllAddedInfluencers = () => dispatch => {
  dispatch({
    type: CLEAR_ALL_NEWLY_ADDED_INFLUENCERS,
  });
};

export const fetchNewInfluencers = (influencers, pageNumber) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const {
    influencerHubSearch: { selectedIds },
  } = state;
  const selectedInfluencersInThisPage = influencers
    .filter(influencer => selectedIds.includes(influencer.id))
    .map(influencer => ({
      ...influencer,
      page: pageNumber,
    }));

  dispatch({
    type: SELECT_INFLUENCER_AFTER_PAGE_CHANGE,
    payload: selectedInfluencersInThisPage,
  });
};

export const setAllNewlyAddedInfluencersIds = ({
  idsToAdd = [],
}: {
  idsToAdd: string[];
}) => async (dispatch, getState) => {
  const {
    influencerHubSearch: { allInfluencersAddedToList },
  } = getState();

  const previouslyAddedInfluencerIds = allInfluencersAddedToList || [];
  const allSelectedIdsWithRemovedDuplicates = [
    ...new Set([...previouslyAddedInfluencerIds, ...idsToAdd]),
  ];

  dispatch({
    type: SET_ALL_NEWLY_ADDED_INFLUENCERS,
    payload: allSelectedIdsWithRemovedDuplicates,
  });
};

const fetchAllMapper = {
  [FETCH_ALL_ADD_TO_LIST]: {},
  [FETCH_ALL_PERSONALIZED_PITCH]: {
    onlyId: false,
    maxSize: 200,
  },
  [FETCH_ALL_EMAIL_ANNOUNCEMENT]: {},
  [INFLUENCER_SAVED_LISTS_CSV_EXPORT]: {},
  [FETCH_ALL_BULK_REMOVE]: {},
};

export type PrefetchAllInfluencersOptions = {
  callback?: (result: any[]) => any;
  setAllSelected?: boolean;
};

export const preFetchAllInfluencers = (
  caller: string,
  { callback, setAllSelected = false }: PrefetchAllInfluencersOptions = {},
) => async (dispatch, getState) => {
  const state = getState();
  const { onlyId = true, maxSize = 5000 } = fetchAllMapper[caller];

  let influencers = state.influencerHubSearch.selectedInfluencers;
  let influencerIds = state.influencerHubSearch.selectedIds;

  const shouldFetchAllInfluencersInTheSearch =
    state.influencerHubSearch.allInfluencersSelected;

  if (shouldFetchAllInfluencersInTheSearch) {
    dispatch({ type: FETCH_ALL_INFLUENCERS_START });

    try {
      influencers = await fetchAllInfluencersInTheSearch(
        state,
        maxSize,
        onlyId,
      );

      influencerIds = influencers.map(influencer => influencer.id);

      dispatch({
        type: FETCH_ALL_INFLUENCERS_SUCCESS,
        payload: setAllSelected ? influencerIds : undefined,
      });
    } catch (e) {
      dispatch({ type: FETCH_ALL_INFLUENCERS_ERROR });
    }
  }

  const result = onlyId ? influencerIds : influencers;

  return callback ? callback(result) : result;
};

export const toggleFilterButton = () => dispatch => {
  dispatch({ type: TOGGLE_SEARCH_RESULT_FILTER_PANEL });
};

export const hideSearchResultFilterPanelDisptcher = () => dispatch =>
  dispatch({ type: HIDE_SEARCH_RESULT_FILTER_PANEL });

export default influencerHubSearchReducer;
