import Cookies from 'universal-cookie';

import { getDevFeatureLocalOverrides } from 'trendkite-front-end-app/src/components/DevFeatureLocalOverrides/service';
import {
  DEV_FEATURE_OVERRIDE_COOKIE_KEY,
  USER_ROLES,
} from 'trendkite-front-end-app/src/constants/constants';

const getAccountDevFeatureFlags = () => {
  return window.activeUser?.devFeatures ?? [];
};

export const userHasDevFeatureFlag = devFeature => {
  if (getAccountDevFeatureFlags().includes(devFeature)) {
    return true;
  }

  if (getDevFeatureLocalOverrides().includes(devFeature)) {
    return true;
  }

  return false;
};

export const userHasAllDevFeatureFlags = devFeatures => {
  const activeDevFeatureFlags = [].concat(
    getAccountDevFeatureFlags(),
    getDevFeatureLocalOverrides(),
  );

  return devFeatures.every(f => activeDevFeatureFlags.includes(f));
};

export const userHasSomeDevFeatureFlags = devFeatures => {
  const accountDevFeatureFlags = getAccountDevFeatureFlags();
  if (devFeatures.some(f => accountDevFeatureFlags.includes(f))) {
    return true;
  }

  const devFeatureLocalOverrides = getDevFeatureLocalOverrides();
  if (devFeatures.some(f => devFeatureLocalOverrides.includes(f))) {
    return true;
  }

  return false;
};

export const userHasFeatureFlag = function (feature) {
  return (
    window.activeUser &&
    window.activeUser.features &&
    window.activeUser.features.indexOf(feature) !== -1
  );
};

export const userHasAllFeatureFlags = function (features) {
  let result = true;

  features.forEach(feature => {
    if (!userHasFeatureFlag(feature)) {
      result = false;
    }
  });

  return result;
};

export const userHasSomeFeatureFlags = features =>
  features.reduce((result, feature) => {
    if (userHasFeatureFlag(feature)) {
      result = true;
    }

    return result;
  }, false);

export const getOverrideFeatures = () => {
  const cookies = new Cookies();
  const devFeaturesOverrides = cookies.get(DEV_FEATURE_OVERRIDE_COOKIE_KEY);
  return Object.entries(devFeaturesOverrides || {}).reduce(
    (devFeatureList, [FEATURE_NAME, { e: enabled }]) => {
      return devFeatureList.concat({ name: FEATURE_NAME, enabled: !!enabled });
    },
    [],
  );
};

export const toggleDevFeatures = devFeatures => {
  const cookies = new Cookies();
  const devFeaturesOverrides = cookies.get(DEV_FEATURE_OVERRIDE_COOKIE_KEY);

  devFeatures.forEach(({ name, enabled }) => {
    devFeaturesOverrides[name].e = Number(enabled);
  });

  cookies.set(DEV_FEATURE_OVERRIDE_COOKIE_KEY, devFeaturesOverrides);
  window.location.reload();
};

export const activeUserWithOverride = (activeUser = window.activeUser) => {
  const { devFeatures, roles } = activeUser;
  if (roles && !roles.some(role => role === USER_ROLES.featureToggle)) {
    return activeUser;
  }
  const cookies = new Cookies();
  const devFeaturesOverrides =
    cookies.get(DEV_FEATURE_OVERRIDE_COOKIE_KEY) || [];

  activeUser.devFeatures = devFeatures
    ? devFeatures.reduce((devFeatureList, devFeature) => {
        const devFeatureOverride = devFeaturesOverrides[devFeature];
        if (devFeatureOverride) {
          return devFeatureOverride.e
            ? devFeatureList.concat(devFeature)
            : devFeatureList;
        }
        return devFeatureList.concat(devFeature);
      }, [])
    : devFeatures;
  return activeUser;
};
