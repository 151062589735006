import React, { useMemo, useState } from 'react';

import { Dropdown } from '@cision/rover-ui';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import {
  Button as TkUiButton,
  Addon,
  SvgIcon,
  IconButton,
  DateRangePicker,
  FloatingPanel,
} from '@trendkite/ui';

import { DATE_RANGE_OPTIONS_WITH_UNDEFINED_DAYS } from 'components/widget/constants';
import { getDateRangeCompareDayDifference } from 'components/widgetV3/utils';
import WidgetV3DateRange from 'components/widgetV3/widget-v3-date-range';
import {
  DATE_RANGE_COMPARE_TYPES,
  DATE_RANGE_COMPARE_TYPES_MESSAGES,
} from 'components/widgetWizard/constants';
import styles from 'components/widgetWizard/steps/steps.module.css';
import TranslatedMessage from 'i18n/TranslatedMessage';
import {
  updateDateRangeV3ActionCreator,
  clearDateRangeCompareTypeActionCreator,
  updateDateRangeCompareTypeActionCreator,
  updateCustomDateRangeCompareV3ActionCreator,
} from 'reducers/widget-form';
import {
  DATE_RANGE_KEYS,
  getTimestampsForAnyRange,
  DATE_RANGES,
  getTimestampsForRange,
} from 'utils/date/date-util';

import Header from './components/header';

import messages from './section-wizard.messages';

const DateRangeV3 = ({
  canAddDateRangeCompare,
  dateRange,
  dateRangeCompareType,
  dateRangeCompare,
  widgetId,
  intl,
  showTrailing,
  dateRangeLimitOneYear,
  dateRangeLimitMessage,
  iconButtonDisabledMessage,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [customCalendarOpen, setCustomCalendarOpen] = useState(false);
  const { type: dateRangeInitVal } = dateRange || {};
  const selectedDateRangeCompareType =
    dateRangeCompareType || DATE_RANGE_COMPARE_TYPES.previousPeriod;
  let endTimeInitVal;
  let startTimeInitVal;
  if (DATE_RANGE_OPTIONS_WITH_UNDEFINED_DAYS?.includes(dateRangeInitVal)) {
    const { endTime, startTime, previousStart } = getTimestampsForRange(
      dateRangeInitVal,
    );
    const difference = moment.utc(endTime).diff(startTime, 'days');
    const previousEnd = moment
      .utc(previousStart)
      .add(difference, 'days')
      .valueOf();
    startTimeInitVal = previousStart;
    endTimeInitVal = previousEnd;
  } else {
    let startingDate = dateRangeCompare.startDate;
    let endingDate = dateRangeCompare.endDate;

    if (!(startingDate && endingDate)) {
      const { endTime, startTime } = getTimestampsForRange(dateRangeInitVal);
      startingDate = startTime;
      endingDate = endTime;
    }

    endTimeInitVal = dateRangeCompare
      ? moment.utc(endingDate).valueOf()
      : moment().utc().subtract(1, 'days').endOf('day').valueOf();
    startTimeInitVal = dateRangeCompare
      ? moment.utc(startingDate).valueOf()
      : moment()
          .utc()
          .subtract({ days: DATE_RANGES[dateRangeInitVal]?.trailing_days })
          .valueOf();
  }
  const [startTime, setStartTime] = useState(
    moment.utc(startTimeInitVal).valueOf(),
  );
  const [endTime, setEndTime] = useState(moment.utc(endTimeInitVal).valueOf());
  const allowedRangesText = !canAddDateRangeCompare
    ? 'a date range'
    : 'up to 2 date ranges';
  const dateRangeCompareTypeMessage = compareType => {
    const compareTypeMessage = DATE_RANGE_COMPARE_TYPES_MESSAGES[compareType];
    if (compareType !== DATE_RANGE_COMPARE_TYPES.previousPeriod) {
      return { ...compareTypeMessage };
    }
    const dayDifference = getDateRangeCompareDayDifference(dateRange);
    return { ...compareTypeMessage, values: { DAY_DIFFERENCE: dayDifference } };
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const updateDateRange = ({ dateRange: newDateRange }) => {
    const { startDate, endDate, type } = newDateRange;
    setCustomCalendarOpen(newDateRange === DATE_RANGE_KEYS.CUSTOM);
    const { startTime, endTime } = getTimestampsForAnyRange(
      type,
      startDate,
      endDate,
    );
    setStartTime(startTime);
    setEndTime(endTime);

    dispatch(
      updateDateRangeV3ActionCreator({
        type,
        startDate: startTime,
        endDate: endTime,
      }),
    );
  };

  const handleCustomDateSelected = () => {
    setCustomCalendarOpen(!customCalendarOpen);
  };

  const updateDateRangeCompareType = type => {
    dispatch(updateDateRangeCompareTypeActionCreator(type));
  };

  const clearDateRangeCompareType = () => {
    dispatch(clearDateRangeCompareTypeActionCreator());
  };

  const handleDateRangeCompareSelect = type => {
    handleToggle();
    updateDateRangeCompareType(type);
    if (type === DATE_RANGE_COMPARE_TYPES.customStartDate) {
      handleCustomDateSelected();
    }
  };

  const handleCustomDateSave = (startDate, endDate) => {
    dispatch(
      updateCustomDateRangeCompareV3ActionCreator({
        startDate: moment.utc(startDate).startOf('day').valueOf(),
        endDate: moment.utc(endDate).endOf('day').valueOf(),
      }),
    );
    setCustomCalendarOpen(false);
  };

  const handleCustomDateCancel = () => {
    setCustomCalendarOpen(false);
  };

  const handleDateRangeChange = start => {
    setStartTime(start);
    const dayDifference = getDateRangeCompareDayDifference(dateRange);
    const currentEndTime = moment(start)
      .utc()
      .add({ days: dayDifference })
      .valueOf();
    setEndTime(currentEndTime);
  };

  const {
    values: selectedDateRangeCompareMessageValues,
    ...selectedDateRangeCompareMessage
  } = dateRangeCompareTypeMessage(selectedDateRangeCompareType);
  const selectedDateRangeCompareText = intl.formatMessage(
    selectedDateRangeCompareMessage,
    selectedDateRangeCompareMessageValues,
  );

  const popperDropdownConfig = useMemo(
    () => ({
      options: {
        placement: 'top-end',
        strategy: 'fixed',
      },
    }),
    [],
  );

  return (
    <div className={styles.sectionWrapper}>
      <Header
        title={intl.formatMessage(messages.titleRangeSection)}
        subTitle={intl.formatMessage(messages.subtitleRangeSection, {
          ALLOWED_RANGES_TEXT: allowedRangesText,
        })}
        showIconButton
        iconButtonDisabled={!!dateRangeCompareType || !canAddDateRangeCompare}
        iconButtonDisabledMessage={iconButtonDisabledMessage}
        onIconButtonClick={() =>
          updateDateRangeCompareType(selectedDateRangeCompareType)
        }
      />
      <WidgetV3DateRange
        editable
        dateRange={dateRange}
        onDateRangeUpdate={opts => updateDateRange(opts)}
        buttonModifiers={['round', 'tertiary', 'flex']}
        widgetId={widgetId}
        showTrailing={showTrailing}
        popperDropdownConfig={popperDropdownConfig}
        dateRangeLimitOneYear={dateRangeLimitOneYear}
        dateRangeLimitMessage={dateRangeLimitMessage}
      />
      {dateRangeCompareType && (
        <div className={styles.dropdownWrapper}>
          <Dropdown isOpen={isOpen} onToggle={handleToggle}>
            <div className={styles.dateCompareWrapper}>
              <TkUiButton
                data-qa="Zet-IfHoSoU8F2vhHh-sL"
                onClick={handleToggle}
                modifiers={['round', 'tertiary', 'inline-flex']}
                style={{ width: 'initial' }}
              >
                <TranslatedMessage
                  {...messages.labelForSelectedDateRangeCompareButton}
                  values={{
                    SELECTED_DATE_RANGE_COMPARE_TEXT: selectedDateRangeCompareText,
                  }}
                />
                <Addon>
                  <SvgIcon
                    icon={isOpen ? 'arrowDropUp' : 'arrowDropDown'}
                    width={24}
                    height={24}
                    modifiers={['block']}
                  />
                </Addon>
              </TkUiButton>
              <div className={styles.deleteDateRangeWrapper}>
                <IconButton
                  data-qa="2ThCTPou0Iw77YS07vgw7"
                  icon="deleteIcon"
                  compact
                  onClick={() => clearDateRangeCompareType()}
                  size="large"
                />
              </div>
            </div>
            <Dropdown.Menu style={{ background: 'white', padding: '5px' }}>
              {Object.values(DATE_RANGE_COMPARE_TYPES).map(type => (
                <Dropdown.Menu.Item
                  onClick={() => handleDateRangeCompareSelect(type)}
                  key={type}
                  data-qa="widget-footer-list-options-v3"
                >
                  <span className={styles.itemContainer}>
                    <TranslatedMessage {...dateRangeCompareTypeMessage(type)} />
                  </span>
                </Dropdown.Menu.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {customCalendarOpen && (
        <div className={styles.customCalendarPicker}>
          <FloatingPanel>
            <DateRangePicker
              startDate={startTime}
              endDate={endTime}
              onCancel={handleCustomDateCancel}
              onSave={handleCustomDateSave}
              onChange={handleDateRangeChange}
              allowChangeProps
              disableEndCalendar
              showWarningMessage
              textMessageWarning={intl.formatMessage(
                messages.textWarningCustomCalendar,
              )}
            />
          </FloatingPanel>
        </div>
      )}
    </div>
  );
};

DateRangeV3.propTypes = {
  canAddDateRangeCompare: PropTypes.bool.isRequired,
  dateRange: PropTypes.object.isRequired,
  dateRangeCompareType: PropTypes.string,
  widgetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  dateRangeCompare: PropTypes.object,
  showTrailing: PropTypes.bool,
  dateRangeLimitOneYear: PropTypes.bool,
  dateRangeLimitMessage: PropTypes.string,
  iconButtonDisabledMessage: PropTypes.string,
};

DateRangeV3.defaultProps = {
  dateRangeCompare: null,
  widgetId: null,
  showTrailing: true,
  dateRangeLimitOneYear: false,
  dateRangeLimitMessage: '',
  iconButtonDisabledMessage: '',
};

export default DateRangeV3;
