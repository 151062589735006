import React from 'react';

import classNames from 'classnames';
import globalMessages from 'i18n/Global.messages';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

function GridTileTitle(props) {
  const {
    fluid,
    isHero,
    isOverflowAllowed,
    isLegacyLink,
    linkedTitle,
    maxLength,
    title,
    url,
  } = props;
  const intl = useIntl();
  const isDefaultTitle = title === 'Untitled Story';
  let displayTitle = title || '';

  const baseClass = isLegacyLink ? 'grid-tile__title' : 'tk-grid-tile__title';
  const mainClasses = classNames(
    baseClass,
    { [`${baseClass}--placeholder`]: isDefaultTitle },
    { [`${baseClass}--hero`]: isHero },
    { [`${baseClass}--fluid`]: fluid },
    { [`${baseClass}--overflow`]: isOverflowAllowed },
    {
      [`${baseClass}--untitled`]:
        displayTitle ===
        `[${intl.formatMessage(globalMessages.alternativeTitleForStory)}]`,
    },
  );

  if (displayTitle.length > maxLength) {
    displayTitle = `${displayTitle.slice(0, maxLength)}…`;
  }

  if (!linkedTitle) {
    return <span className={mainClasses}>{displayTitle}</span>;
  }

  if (isLegacyLink) {
    return (
      <a
        href={window.location.origin + url}
        className={mainClasses}
        title={title}
      >
        {title}
      </a>
    );
  }

  return (
    <div className={mainClasses}>
      <Link className={mainClasses} title={title} to={url}>
        {displayTitle}
      </Link>
    </div>
  );
}

GridTileTitle.propTypes = {
  fluid: PropTypes.bool,
  isHero: PropTypes.bool,
  isOverflowAllowed: PropTypes.bool,
  isLegacyLink: PropTypes.bool.isRequired,
  linkedTitle: PropTypes.bool,
  maxLength: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

GridTileTitle.defaultProps = {
  fluid: false,
  isHero: false,
  isOverflowAllowed: false,
  linkedTitle: true,
};

export default GridTileTitle;
