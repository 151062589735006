import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../svg-icon';

const ActivityBlock = ({ icon, children }) => {
  const baseClass = 'tk-activity-block';

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__icon`}>
        <SvgIcon icon={icon} width={20} height={20} />
      </div>
      <div className={`${baseClass}__content`}>{children}</div>
    </div>
  );
};

ActivityBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ActivityBlock;
