import moment from 'moment';
import { actions } from 'react-redux-form';

export const RESET_CAMPAIGN_DETAILS_SIDE_TRAY =
  'campaign-details-side-tray/RESET_CAMPAIGN_DETAILS';
export const UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY =
  'campaign-details-side-tray/UPDATE_CAMPAIGN_DETAILS';
export const UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_ERROR =
  'campaign-details-side-tray/UPDATE_CAMPAIGN_DETAILS_ERROR';
export const UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_SUCCESS =
  'campaign-details-side-tray/UPDATE_CAMPAIGN_DETAILS_SUCCESS';

const initialState = {
  campaignCategory: {},
  campaignDescription: '',
  campaignTitle: '',
  campaignDateRange: '',
  startDate: null,
  endDate: null,
  error: false,
  loading: false,
};

const campaignDetailsSideTrayReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    case UPDATE_CAMPAIGN_DETAILS_SIDE_TRAY_SUCCESS: {
      const newState = { ...state };
      if (typeof action.payload !== 'undefined') {
        newState.campaignCategory = action.payload.category || {};
        newState.campaignDescription = action.payload.description || '';
        newState.campaignTitle = action.payload.title || '';
        newState.campaignDateRange = action.payload.rangeType || '';
        newState.startDate = action.payload.startDate
          ? moment.utc(action.payload.startDate).valueOf()
          : null;
        newState.endDate = action.payload.endDate
          ? moment.utc(action.payload.endDate).valueOf()
          : null;
      }
      return {
        ...newState,
        error: false,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export const resetDetailsFormStateActionCreator = () => dispatch =>
  dispatch(actions.reset('campaignDetailsSideTray'));

export default campaignDetailsSideTrayReducer;
