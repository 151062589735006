export default {
  blankTemplateTitle: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_TITLE_BLANK_TEMPLATE',
    description: 'Dashboards v2.0: dashboard template option blank template title',
    defaultMessage: 'Blank',
  },
  blankTemplateDescription: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_DESCRIPTION_BLANK_TEMPLATE',
    description: 'Dashboards v2.0: dashboard template option blank template description',
    defaultMessage: 'Do it your way! You can add as many widgets as you\'d like in a dashboard.',
  },
  brandTemplateTitle: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_TITLE_BRAND',
    description: 'Dashboards v2.0: dashboard template option brand title',
    defaultMessage: 'Quick Starter - Brand',
  },
  brandTemplateDescription: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_DESCRIPTION_BRAND',
    description: 'Dashboards v2.0: dashboard template option brand description',
    defaultMessage: 'Take a deeper look into more metrics about your brand.',
  },
  competitiveTemplateTitle: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_TITLE_COMPETITIVE_ANAYLSIS',
    description: 'Dashboards v2.0: dashboard template option competitive anaylsis title',
    defaultMessage: 'Quick Starter - Competitive',
  },
  competitiveTemplateDescription: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_DESCRIPTION_COMPETITIVE_ANAYLSIS',
    description: 'Dashboards v2.0: dashboard template option competitive anaylsis description',
    defaultMessage: 'Compare metrics on earned media performance between you and competitors.',
  },
  overviewTemplateTitle: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_TITLE_OVERVIEW_ANAYLSIS',
    description: 'Dashboards v2.0: dashboard template option overview anaylsis title',
    defaultMessage: 'Quick Starter - Overview',
  },
  overviewTemplateDescription: {
    id: 'DASHBOARD_CREATION_TEMPLATE_OPTION_DESCRIPTION_OVERVIEW_ANAYLSIS',
    description: 'Dashboards v2.0: dashboard template option overview anaylsis description',
    defaultMessage: 'View common metrics around earned media performance.',
  },
};
