export default {
  feedId: {
    id: 'FEED_ID_FEED_LIST_LABEL',
    defaultMessage: 'Feed ID',
    description: 'Message for the feed id label of the feeds list',
  },
  feedLink: {
    id: 'FEED_LINK_FEED_LIST_LABEL',
    defaultMessage: 'Feed Link',
    description: 'Message for the feed link label of the feeds list',
  },
  createdOn: {
    id: 'FEED_CREATED_ON_FEED_LIST_LABEL',
    defaultMessage: 'Created On',
    description: 'Message for the feed created date label of the feeds list',
  },
  modifiedOn: {
    id: 'FEED_MODIFIED_ON_FEED_LIST_LABEL',
    defaultMessage: 'Modified On',
    description: 'Message for the feed modified date label of the feeds list',
  },
  widgetTitleId: {
    id: 'WIDGET_TITLE_ID_FEED_LIST_LABEL',
    defaultMessage: 'Widget Title (ID)',
    description: 'Message for the widget title and ID label of the feeds list',
  },
  dashboardAccountId: {
    id: 'DASHBOARD_ACCOUNT_ID_FEED_LIST_LABEL',
    defaultMessage: 'Account ID',
    description: 'Message for the dashboard account id label of the feeds list',
  },
  newFeed: {
    id: 'NEW_FEED_VIEW_BUTTON_LABEL',
    defaultMessage: 'New Feed',
    description: 'Message for the new feed button label',
  },
  noFeedsFoundMsg: {
    id: 'NO_FEEDS_FOUND_MESSAGE',
    defaultMessage: 'No feeds found.',
    description: 'Message shown when there were no feeds to retrieve.',
  },
  feedSearchPlaceholder: {
    id: 'FEED_SEARCH_PLACEHOLDER',
    defaultMessage: 'Type an account id',
    description: 'Placeholder message for the feed search bar.',
  },
  feedDeleteOption: {
    id: 'FEED_MENU_DELETE_OPTION',
    defaultMessage: 'Delete Feed',
    description: 'Delete text for the feed menu option.',
  },
  feedEditOption: {
    id: 'FEED_MENU_EDIT_OPTION',
    defaultMessage: 'Edit Feed',
    description: 'Edit text for the feed menu option.',
  },
  createFeedSuccessMessage: {
    id: 'CREATE_FEED_SUCCESS',
    defaultMessage: 'Success! Your feed has been created.',
    description: 'Feeds list: create feed success kite message',
  },
  createFeedFailureMessage: {
    id: 'CREATE_FEED_FAILURE',
    defaultMessage:
      'Oops! Something went wrong with your create. Please try again.',
    description: 'Feeds list: create feed failure kite message',
  },
  deleteFeedSuccessMessage: {
    id: 'DELETE_FEED_SUCCESS',
    defaultMessage: 'Success! Your feed has been deleted.',
    description: 'Feeds list: delete feed success kite message',
  },
  deleteFeedFailureMessage: {
    id: 'DELETE_FEED_FAILURE',
    defaultMessage:
      'Oops! Something went wrong with your delete. Please try again.',
    description: 'Feeds list: delete feed failure kite message',
  },
};
