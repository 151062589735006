import brandwatchLogo from '../assets/brandwatch-logo.svg';
import cisionLogo from '../assets/cision-logo-white.svg';
import addToGroup from '../assets/icons/add-to-group.svg';
import alerts from '../assets/icons/alerts.svg';
import arrowDown from '../assets/icons/arrow-down.svg';
import arrowDown2 from '../assets/icons/arrow-down2.svg';
import arrowDropDown from '../assets/icons/arrow-drop-down.svg';
import arrowDropUp from '../assets/icons/arrow-drop-up.svg';
import arrowLeft from '../assets/icons/arrow-left.svg';
import arrowLeft2 from '../assets/icons/arrow-left2.svg';
import arrowLeft3 from '../assets/icons/arrow-left3.svg';
import arrowRight from '../assets/icons/arrow-right.svg';
import arrowRight2 from '../assets/icons/arrow-right2.svg';
import arrowRight3 from '../assets/icons/arrow-right3.svg';
import arrowUp from '../assets/icons/arrow-up.svg';
import arrowUp2 from '../assets/icons/arrow-up2.svg';
import attach from '../assets/icons/attach.svg';
import authors from '../assets/icons/authors.svg';
import bars from '../assets/icons/bars.svg';
import binIcon from '../assets/icons/bin.svg';
import blankTemplate from '../assets/icons/blank-template.svg';
import blogPlaceholder from '../assets/icons/blog-placeholder.svg';
import bobStuck from '../assets/icons/bob_stuck.svg';
import bookmarks from '../assets/icons/bookmarks.svg';
import brandTemplate from '../assets/icons/brand-template.svg';
import bulkMail from '../assets/icons/bulk-mail.svg';
import bullhorn from '../assets/icons/bullhorn.svg';
import calendar from '../assets/icons/calendar.svg';
import camera from '../assets/icons/camera.svg';
import campaignsThinIcon from '../assets/icons/campaigns-thin.svg';
import campaignsIcon from '../assets/icons/campaigns.svg';
import cardView from '../assets/icons/card-view.svg';
import chartSeo from '../assets/icons/chart-seo.svg';
import chartSparkline from '../assets/icons/chart-sparkline.svg';
import chat from '../assets/icons/chat.svg';
import check from '../assets/icons/check.svg';
import checkboxChecked from '../assets/icons/checkbox-checked.svg';
import checkboxIndeterminate from '../assets/icons/checkbox-indeterminate.svg';
import checkboxUnchecked from '../assets/icons/checkbox-unchecked.svg';
import checkmark from '../assets/icons/checkmark.svg';
import chevronDownIcon from '../assets/icons/chevron-down.svg';
import chevronRightIcon from '../assets/icons/chevron-right.svg';
import chevronUpIcon from '../assets/icons/chevron-up.svg';
import circulation from '../assets/icons/circulation.svg';
import cisionLogoGreen from '../assets/icons/cision-logo-green.svg';
import clipboard from '../assets/icons/clipboard.svg';
import close from '../assets/icons/close.svg';
import coffee from '../assets/icons/coffee.svg';
import cog from '../assets/icons/cog.svg';
import comment from '../assets/icons/comment.svg';
import competitiveTemplate from '../assets/icons/competitive-template.svg';
import contactList from '../assets/icons/contact-list.svg';
import copyToClipboard from '../assets/icons/copy-to-clipboard.svg';
import crossIcon from '../assets/icons/cross.svg';
import dashboardTiles from '../assets/icons/dashboard-tiles.svg';
import dashboardIcon from '../assets/icons/dashboard.svg';
import deleteIcon from '../assets/icons/delete-icon.svg';
import dinner from '../assets/icons/dinner.svg';
import documentIcon from '../assets/icons/document.svg';
import dollarSign from '../assets/icons/dollar-sign.svg';
import donutChartIcon from '../assets/icons/donut-chart-icon.svg';
import dowJonesLogo from '../assets/icons/dowjones_logo.svg';
import download from '../assets/icons/download.svg';
import drinks from '../assets/icons/drinks.svg';
import ellipsis from '../assets/icons/ellipsis.svg';
import empty1 from '../assets/icons/empty-1.svg';
import empty2 from '../assets/icons/empty-2.svg';
import emptyJournalists from '../assets/icons/empty-top-journalists.svg';
import emptyStateNoData from '../assets/icons/empty_state_no_data.svg';
import exchange from '../assets/icons/exchange.svg';
import expandAll from '../assets/icons/expandall.svg';
import eye from '../assets/icons/eye.svg';
import eye2 from '../assets/icons/eye2.svg';
import facebookIcon from '../assets/icons/facebook.svg';
import filter from '../assets/icons/filter.svg';
import financialTimesLogo from '../assets/icons/financial-times-logotype.svg';
import financialTimesMark from '../assets/icons/financial-times-mark.svg';
import flag from '../assets/icons/flag.svg';
import forum from '../assets/icons/forum.svg';
import globe from '../assets/icons/globe.svg';
import gmail from '../assets/icons/gmail.svg';
import google from '../assets/icons/google.svg';
import googlePlus from '../assets/icons/googleplus.svg';
import hamburger from '../assets/icons/hamburger.svg';
import hamburgerPlus from '../assets/icons/hamburgerPlus2.svg';
import hamburgerX from '../assets/icons/hamburgerX.svg';
import heartLike from '../assets/icons/heartLike.svg';
import horizontalBarChartIcon from '../assets/icons/horizontal-bar-chart-icon.svg';
import hyperlink from '../assets/icons/hyperlink.svg';
import image from '../assets/icons/image.svg';
import infoFilled from '../assets/icons/info-filled.svg';
import infoQuestion from '../assets/icons/info-question.svg';
import info from '../assets/icons/info.svg';
import instagram from '../assets/icons/instagram.svg';
import kebabs from '../assets/icons/kebabs.svg';
import letterClosed from '../assets/icons/letter-closed.svg';
import letterOpen from '../assets/icons/letter-open.svg';
import like from '../assets/icons/like.svg';
import linkedInLogo from '../assets/icons/linkedin-logo.svg';
import listAlt from '../assets/icons/list-alt.svg';
import listVerified from '../assets/icons/list-verified.svg';
import listViewActive from '../assets/icons/list-view-active.svg';
import listView from '../assets/icons/list-view.svg';
import list from '../assets/icons/list.svg';
import lexisNexisIconColored from '../assets/icons/ln-icon-color.svg';
import lexisNexisLogo from '../assets/icons/ln-logo.svg';
import location from '../assets/icons/location.svg';
import lock from '../assets/icons/lock.svg';
import lunch from '../assets/icons/lunch.svg';
import magazine from '../assets/icons/magazine.svg';
import mailArchive from '../assets/icons/mail-archive.svg';
import mailDraft from '../assets/icons/mail-draft.svg';
import email from '../assets/icons/mail.svg';
import mainVerticalBarsBars from '../assets/icons/main-bars.svg';
import mainBolt from '../assets/icons/main-bolt.svg';
import mainEdit from '../assets/icons/main-edit.svg';
import mainInfo from '../assets/icons/main-info.svg';
import mainWeb from '../assets/icons/main-web.svg';
import manuallyAddedPlaceholder from '../assets/icons/manually-added-placeholder.svg';
import mapPin from '../assets/icons/map-pin.svg';
import mentionGroup from '../assets/icons/mention-group.svg';
import messageBubble from '../assets/icons/message-bubble.svg';
import metricAdEquivalency from '../assets/icons/metric-ad-equivalency.svg';
import metricImpact from '../assets/icons/metric-impact.svg';
import metricMobileReadership from '../assets/icons/metric-mobile-readership.svg';
import metricReadership from '../assets/icons/metric-readership.svg';
import metricSentiment from '../assets/icons/metric-sentiment.svg';
import metricSocialShares from '../assets/icons/metric-social-shares.svg';
import metricTotalReadership from '../assets/icons/metric-total-readership.svg';
import minus from '../assets/icons/minus.svg';
import MozLogo from '../assets/icons/moz-logo.svg';
import negativeSentiment from '../assets/icons/negative_sentiment.svg';
import neutralSentiment from '../assets/icons/neutral_sentiment.svg';
import newTabIcon from '../assets/icons/new-tab.svg';
import news from '../assets/icons/news.svg';
import newsletterIcon from '../assets/icons/newsletter.svg';
import no from '../assets/icons/no.svg';
import noImageThumb from '../assets/icons/no_image_thumb.svg';
import noRadioThumb from '../assets/icons/no_radio_thumb.svg';
import noVideoThumb from '../assets/icons/no_video_thumb.svg';
import note from '../assets/icons/note.svg';
import nothingIcon from '../assets/icons/nothing.svg';
import noticeIcon from '../assets/icons/notice.svg';
import onlineNewsPlaceholder from '../assets/icons/online-news-placeholder.svg';
import outlook from '../assets/icons/outlook.svg';
import overviewTemplate from '../assets/icons/overview-template.svg';
import pencilIcon from '../assets/icons/pencil.svg';
import personPinCircle from '../assets/icons/person-pin-circle.svg';
import personaAllAudience from '../assets/icons/persona-all-audience.svg';
import persona from '../assets/icons/persona.svg';
import phone from '../assets/icons/phone.svg';
import pinterest from '../assets/icons/pinterest.svg';
import plus from '../assets/icons/plus.svg';
import podcastPlaceholder from '../assets/icons/podcast-placeholder.svg';
import podcast from '../assets/icons/podcast.svg';
import positiveSentiment from '../assets/icons/positive_sentiment.svg';
import power from '../assets/icons/power.svg';
import tagIcon from '../assets/icons/price-tag.svg';
import printPlaceholder from '../assets/icons/print-placeholder.svg';
import profile from '../assets/icons/profile.svg';
import radioDefaultIcon from '../assets/icons/radio-default.svg';
import radioDisabledIcon from '../assets/icons/radio-disabled.svg';
import radioHoverIcon from '../assets/icons/radio-hover.svg';
import radioPlaceholder from '../assets/icons/radio-placeholder.svg';
import radioSelectedIcon from '../assets/icons/radio-selected.svg';
import reddit from '../assets/icons/reddit.svg';
import refresh from '../assets/icons/refresh.svg';
import replyAll from '../assets/icons/reply-all.svg';
import reply from '../assets/icons/reply.svg';
import reportBodyCopy from '../assets/icons/report-body-copy.svg';
import reportHeadline from '../assets/icons/report-headline.svg';
import reportImageWidget from '../assets/icons/report-image-widget.svg';
import reportSlideIcon from '../assets/icons/report-slide.svg';
import reportWidget from '../assets/icons/report-widget.svg';
import reportsThinIcon from '../assets/icons/reports-thin.svg';
import reportsIcon from '../assets/icons/reports.svg';
import restore from '../assets/icons/restore.svg';
import retweetNew from '../assets/icons/retweet-new.svg';
import retweet from '../assets/icons/retweet.svg';
import searchIcon from '../assets/icons/search.svg';
import searches from '../assets/icons/searches.svg';
import sentimentFace from '../assets/icons/sentiment.svg';
import shareUser from '../assets/icons/share-user.svg';
import shareIcon from '../assets/icons/share.svg';
import shared from '../assets/icons/shared.svg';
import similarWebLogo from '../assets/icons/similarweb-logo.svg';
import slack from '../assets/icons/slack.svg';
import sortAmountAsc from '../assets/icons/sort-amount-asc.svg';
import sortAmountDesc from '../assets/icons/sort-amount-desc.svg';
import sparklineChartIcon from '../assets/icons/sparkline-chart-icon.svg';
import square from '../assets/icons/square.svg';
import userStackAddIcon from '../assets/icons/stack-users-add.svg';
import userStack from '../assets/icons/stack-users.svg';
import standardNegativeSentiment from '../assets/icons/standard_negative_sentiment.svg';
import standardNeutralSentiment from '../assets/icons/standard_neutral_sentiment.svg';
import standardPositiveSentiment from '../assets/icons/standard_positive_sentiment.svg';
import standardUndeterminedSentiment from '../assets/icons/standard_undetermined_sentiment.svg';
import starEmptyIcon from '../assets/icons/star-empty.svg';
import starFilledIcon from '../assets/icons/star-filled.svg';
import storyThinIcon from '../assets/icons/story-thin.svg';
import storyIcon from '../assets/icons/story.svg';
import svg from '../assets/icons/svg.svg';
import temperatureIcon from '../assets/icons/temperature.svg';
import thumbsDown from '../assets/icons/thumbs-down.svg';
import thumbsUp from '../assets/icons/thumbs-up.svg';
import timerOff from '../assets/icons/timer-off.svg';
import timer from '../assets/icons/timer.svg';
import tkFlag from '../assets/icons/tk-flag.svg';
import tkLogo from '../assets/icons/tk-logo-full.svg';
import tvPlaceholder from '../assets/icons/tv-placeholder.svg';
import tv from '../assets/icons/tv.svg';
import twitch from '../assets/icons/twitch.svg';
import twitter from '../assets/icons/twitter.svg';
import upload from '../assets/icons/upload.svg';
import userRelationship from '../assets/icons/user-relationship.svg';
import user from '../assets/icons/user.svg';
import usersIcon from '../assets/icons/users.svg';
import verificationBadge from '../assets/icons/verification-badge.svg';
import verified from '../assets/icons/verified.svg';
import verticalBarChartIcon from '../assets/icons/vertical-bar-chart-icon.svg';
import tableView from '../assets/icons/view-table.svg';
import view from '../assets/icons/view.svg';
import wordCloud from '../assets/icons/word-cloud.svg';
import youtube from '../assets/icons/youtube.svg';
import connectively from '../assets/logos/Connectively.svg';

export default {
  wordCloud,
  addToGroup,
  alerts,
  arrowDown,
  arrowDown2,
  arrowDropDown,
  arrowDropUp,
  arrowLeft,
  arrowLeft2,
  arrowLeft3,
  arrowRight,
  arrowRight2,
  arrowRight3,
  arrowUp,
  arrowUp2,
  authors,
  asc: sortAmountAsc,
  attach,
  bin: binIcon,
  bars,
  brandwatchLogo,
  bookmarks,
  bulkMail,
  calendar,
  camera,
  campaigns: {
    default: campaignsIcon,
    thin: campaignsThinIcon,
  },
  cardView,
  chartSeo,
  chat,
  checkboxChecked,
  checkboxIndeterminate,
  checkboxUnchecked,
  checkmark,
  chevronDown: chevronDownIcon,
  chevronUp: chevronUpIcon,
  chevronRight: chevronRightIcon,
  circulation,
  cisionLogo,
  cisionLogoGreen,
  clipboard,
  close,
  coffee,
  cog,
  comment,
  cross: crossIcon,
  desc: sortAmountDesc,
  deleteIcon,
  dashboard: dashboardIcon,
  dashboardTiles,
  dinner,
  document: documentIcon,
  'dollar-sign': dollarSign,
  download,
  drinks,
  ellipsis,
  expandAll,
  exchange,
  eye,
  eye2,
  facebook: facebookIcon,
  filter,
  'financial-times-logotype': financialTimesLogo,
  'financial-times-mark': financialTimesMark,
  forum,
  gmail,
  googlePlus,
  google,
  hamburger,
  hamburgerPlus,
  hamburgerX,
  heartLike,
  horizontalBarChartIcon,
  image,
  info,
  'info-filled': infoFilled,
  'info-question': infoQuestion,
  instagram,
  letterClosed,
  letterOpen,
  like,
  linkedin: linkedInLogo,
  list,
  listViewActive,
  listView,
  lock,
  lunch,
  mailArchive,
  mainEdit,
  mentionGroup,
  messageBubble,
  minus,
  newsletter: newsletterIcon,
  'new-tab': newTabIcon,
  no,
  note,
  nothing: nothingIcon,
  notice: noticeIcon,
  mailDraft,
  outlook,
  pencil: pencilIcon,
  pinterest,
  plus,
  power,
  podcast,
  radioDefault: radioDefaultIcon,
  radioDisabled: radioDisabledIcon,
  radioHover: radioHoverIcon,
  radioSelected: radioSelectedIcon,
  reddit,
  refresh,
  restore,
  reply,
  replyAll,
  reports: {
    default: reportsIcon,
    thin: reportsThinIcon,
  },
  reportSlideIcon,
  reportBodyCopy,
  reportHeadline,
  reportImageWidget,
  reportWidget,
  retweet,
  retweetNew,
  search: searchIcon,
  searches,
  sentiment: temperatureIcon,
  sentimentFace,
  positiveSentiment,
  profile,
  negativeSentiment,
  neutralSentiment,
  standardPositiveSentiment,
  standardNeutralSentiment,
  standardNegativeSentiment,
  standardUndeterminedSentiment,
  share: shareIcon,
  shareUser,
  shared,
  square,
  'star-empty': starEmptyIcon,
  'star-filled': starFilledIcon,
  story: {
    default: storyIcon,
    thin: storyThinIcon,
  },
  svg,
  tableView,
  tag: tagIcon,
  'tk-flag': tkFlag,
  'tk-logo': tkLogo,
  timer,
  timerOff,
  tv,
  twitter,
  twitch,
  upload,
  user,
  users: usersIcon,
  userStack,
  userStackAdd: userStackAddIcon,
  view,
  'ln-icon': lexisNexisIconColored,
  'ln-logo': lexisNexisLogo,
  'dow-jones-icon': dowJonesLogo,
  'moz-logo': MozLogo,
  slack,
  'similar-web-logo': similarWebLogo,
  hyperlink,
  youtube,
  phone,
  email,
  mapPin,
  location,
  thumbsDown,
  thumbsUp,
  flag,
  check,
  globe,
  'copy-to-clipboard': copyToClipboard,
  'contact-list': contactList,
  chartSparkline,
  listAlt,
  userRelationship,
  bullhorn,
  verificationBadge,
  verified,
  mainInfo,
  mainBolt,
  mainWeb,
  persona,
  'persona-all-audience': personaAllAudience,
  'person-pin-circle': personPinCircle,
  donutChartIcon,
  sparklineChartIcon,
  verticalBarChartIcon,
  'list-verified': listVerified,
  kebabs,
  news,
  mainVerticalBarsBars,
  noImageThumb,
  noRadioThumb,
  noVideoThumb,
  bobStuck,
  emptyJournalists,
  emptyStateNoData,
  metricAdEquivalency,
  metricImpact,
  metricMobileReadership,
  metricReadership,
  metricSentiment,
  metricSocialShares,
  metricTotalReadership,
  magazine,
  empty1,
  empty2,
  competitiveTemplate,
  overviewTemplate,
  blankTemplate,
  brandTemplate,
  radioPlaceholder,
  blogPlaceholder,
  manuallyAddedPlaceholder,
  onlineNewsPlaceholder,
  podcastPlaceholder,
  printPlaceholder,
  tvPlaceholder,
  connectively,
};
