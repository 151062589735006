import {
  NEW_DASHBOARD_ENDPOINT,
  GET_DASHBOARD_SYSTEM_TEMPLATES,
  DASHBOARD_ENDPOINT_V2,
} from 'constants/apis';
import { FORBIDDEN_STATUS_CODE } from 'constants/constants';
import { updateDashboardArchiveStatus } from 'pages/Dashboard/dashboard-hub-reducer';

import dashboardMessages from 'pages/Dashboard/dashboard.messages';
import reportMessages from 'pages/Report/report.messages';
import listsActionBarMessages from 'pages/Report/ReportListContainer/ReportListActionBar/report-list-action-bar.messages';

import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';

import { getAnalyticsSetToShare } from 'selectors/dashboards/dashboard-hub';
import {
  performPatch,
  performPut,
  performGet,
} from 'services/rest-service/rest-service';

import {
  AnalyticsListActionCreatorArgs,
  AnalyticsListResultsCollation,
} from '../../analytics-config';
import { clearAnalyticsSelection } from '../AnalyticsListActionBar/analytics-selected-reducer';

import { campaignAnalyticsActionCreator } from './analytics-campaign-reducer';
import { recentlyViewedAnalyticsActionCreator } from './analytics-last-viewed-reducer';
import { listArchivedAnalyticsActionCreator } from './analytics-list-archived-reducer';
import {
  ownedAnalyticsActionCreator,
  updateDashboardsWithId,
} from './analytics-owned-reducer';

import { sidebarAnalyticsActionCreator } from './analytics-sidebar-reducer';
import { starredAnalyticsActionCreator } from './analytics-starred-reducer';

export const LIST_ANALYTICS = 'analytics/LIST_ANALYTICS';
export const LIST_ANALYTICS_SUCCESS = 'analytics/LIST_ANALYTICS_SUCCESS';
export const LIST_ANALYTICS_ERROR = 'analytics/LIST_ANALYTICS_ERROR';
export const UPDATE_ANALYTIC = 'analytics/UPDATE_ANALYTIC';
export const UPDATE_ANALYTIC_SUCCESS = 'analytics/UPDATE_ANALYTIC_SUCCESS';
export const UPDATE_ANALYTIC_ERROR = 'analytics/UPDATE_ANALYTIC_ERROR';
export const DELETE_ANALYTIC = 'analytics/DELETE_ANALYTIC';
export const DELETE_ANALYTIC_SUCCESS = 'analytics/DELETE_ANALYTIC_SUCCESS';
export const DELETE_ANALYTIC_ERROR = 'analytics/DELETE_ANALYTIC_ERROR';
export const DELETE_ANALYTIC_TRASHCAN = 'analytics/DELETE_ANALYTIC_TRASHCAN';
export const DELETE_ANALYTIC_TRASHCAN_SUCCESS =
  'analytics/DELETE_ANALYTIC_TRASHCAN_SUCCESS';
export const DELETE_ANALYTIC_TRASHCAN_ERROR =
  'analytics/DELETE_ANALYTIC_TRASHCAN_ERROR';
export const RESTORE_ANALYTIC_UNTRASHCAN =
  'analytics/RESTORE_ANALYTIC_UNTRASHCAN';
export const RESTORE_ANALYTIC_UNTRASHCAN_SUCCESS =
  'analytics/RESTORE_ANALYTIC_UNTRASHCAN_SUCCESS';
export const RESTORE_ANALYTIC_UNTRASHCAN_ERROR =
  'analytics/RESTORE_ANALYTIC_UNTRASHCAN_ERROR';
export const RESTORE_ANALYTIC = 'analytics/RESTORE_ANALYTIC';
export const RESTORE_ANALYTIC_SUCCESS = 'analytics/RESTORE_ANALYTIC_SUCCESS';
export const RESTORE_ANALYTIC_ERROR = 'analytics/RESTORE_ANALYTIC_ERROR';
export const STAR_ANALYTIC = 'analytics/STAR_ANALYTIC';
export const STAR_ANALYTIC_SUCCESS = 'analytics/STAR_ANALYTIC_SUCCESS';
export const STAR_ANALYTIC_ERROR = 'analytics/STAR_ANALYTIC_ERROR';
export const UNSTAR_ANALYTIC = 'analytics/UNSTAR_ANALYTIC';
export const UNSTAR_ANALYTIC_SUCCESS = 'analytics/UNSTAR_ANALYTIC_SUCCESS';
export const UNSTAR_ANALYTIC_ERROR = 'analytics/UNSTAR_ANALYTIC_ERROR';
export const SHARE_ANALYTICS = 'analytics/SHARE_ANALYTICS';
export const SHARE_ANALYTICS_SUCCESS = 'analytics/SHARE_ANALYTICS_SUCCESS';
export const SHARE_ANALYTICS_ERROR = 'analytics/SHARE_ANALYTICS_ERROR';
export const UNSHARE_ANALYTICS = 'analytics/UNSHARE_ANALYTICS';
export const UNSHARE_ANALYTICS_SUCCESS = 'analytics/UNSHARE_ANALYTICS_SUCCESS';
export const UNSHARE_ANALYTICS_ERROR = 'analytics/UNSHARE_ANALYTICS_ERROR';
export const SET_SHARED_ALL_ANALYTICS = 'analytics/SET_SHARED_ALL_ANALYTICS';
export const GET_SYSTEM_TEMPLATES = 'analytics/GET_SYSTEM_TEMPLATES';
export const GET_SYSTEM_TEMPLATES_SUCCESS =
  'analytics/GET_SYSTEM_TEMPLATES_SUCCESS';
export const GET_SYSTEM_TEMPLATES_METRICS =
  'analytics/GET_SYSTEM_TEMPLATES_METRICS';
export const GET_SYSTEM_TEMPLATES_METRICS_SUCCESS =
  'analytics/GET_SYSTEM_TEMPLATES_METRICS_SUCCESS';
export const GET_SYSTEM_TEMPLATES_METRICS_ERROR =
  'analytics/GET_SYSTEM_TEMPLATES_METRICS_ERROR';
export const GET_SYSTEM_TEMPLATES_ERROR =
  'analytics/GET_SYSTEM_TEMPLATES_ERROR';

export const RESET = 'analytics/RESET_ALL_ANALYTICS_STATE';

export const UPDATE_TITLE_ALL_ANALYTICS =
  'analytics/UPDATE_TITLE_ALL_ANALYTICS';

export const initialState = {
  error: false,
  loading: true,
  items: [],
  listFilters: {
    filtering: false,
    search: '',
  },
  updatingAnalytic: false,
  updatingAnalyticError: null,
  deletingAnalytic: false,
  deletingAnalyticError: null,
  starringAnalytic: false,
  starringAnalyticError: null,
  updatingViewedAnalytic: false,
  updatingViewedAnalyticError: null,
  paginationCount: null,
  ownerFilterAnalytics: [],
  sharingUnsharingAnalytic: false,
  sharingUnsharingAnalyticError: null,
  systemTemplates: [],
  isLoadingTemplateMetrics: false,
  resultsCollation: {
    pageNumber: 1,
    sort: 'lastUpdated',
    sortDirection: 'desc',
    ownerFilter: 'all',
  } as AnalyticsListResultsCollation,
  sharedAnalytics: [],
  unsharedAnalytics: [],
};

export const analyticsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHARED_ALL_ANALYTICS:
      return {
        ...state,
        items: getAnalyticsSetToShare({
          analytics: state.items,
          isShared: action.payload.isShared,
          idsToCheckForSharing: action.payload.analyticIds,
        }),
      };
    case LIST_ANALYTICS:
      return {
        ...state,
        loading: true,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: null,
      };
    case LIST_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        filtering: action.payload.filtering,
        search: action.payload.search,
        listFilters: {
          filtering: action.payload.filtering,
          search: action.payload.search,
        },
        error: null,
        items: action.payload.appendItems
          ? [...state.items, ...action.payload.items]
          : action.payload.items,
        paginationCount: action.payload.paginationCount,
        ownerFilterAnalytics: action.payload.isFilter
          ? state.ownerFilterAnalytics
          : action.payload.items,
        resultsCollation: action.payload.resultsCollation,
      };
    case LIST_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: action.payload.error,
      };
    case UPDATE_ANALYTIC:
      return {
        ...state,
        updatingAnalytic: true,
        updatingAnalyticError: null,
      };
    case UPDATE_ANALYTIC_SUCCESS:
      return {
        ...state,
        updatingAnalytic: false,
        updatingAnalyticError: null,
      };
    case UPDATE_ANALYTIC_ERROR:
      return {
        ...state,
        updatingAnalytic: false,
        updatingAnalyticError: true,
      };
    case STAR_ANALYTIC:
      return {
        ...state,
        starringAnalytic: true,
        starringAnalyticError: null,
      };
    case STAR_ANALYTIC_SUCCESS:
      return {
        ...state,
        starringAnalytic: false,
        starringAnalyticError: null,
        starredAnalytics: action.payload.starredAnalytics,
      };
    case STAR_ANALYTIC_ERROR:
      return {
        ...state,
        starringAnalytic: false,
        starringAnalyticError: true,
      };
    case UNSTAR_ANALYTIC:
      return {
        ...state,
        unstarringAnalytic: true,
        unstarringAnalyticError: null,
      };
    case UNSTAR_ANALYTIC_SUCCESS:
      return {
        ...state,
        unstarringAnalytic: false,
        unstarringAnalyticError: null,
        unstarredAnalytics: action.payload.unstarredAnalytics,
      };
    case UNSTAR_ANALYTIC_ERROR:
      return {
        ...state,
        unstarringAnalytic: false,
        unstarringAnalyticError: true,
      };
    case DELETE_ANALYTIC:
      return {
        ...state,
        deletingAnalytic: true,
        deletingAnalyticError: null,
      };
    case DELETE_ANALYTIC_SUCCESS:
      return {
        ...state,
        deletingAnalytic: false,
        deletingAnalyticError: null,
      };
    case DELETE_ANALYTIC_ERROR:
      return {
        ...state,
        deletingAnalytic: false,
        deletingAnalyticError: null,
      };
    case RESTORE_ANALYTIC:
      return {
        ...state,
        restoringAnalytic: true,
        restoringAnalyticError: null,
      };
    case RESTORE_ANALYTIC_SUCCESS:
      return {
        ...state,
        restoringAnalytic: false,
        restoringAnalyticError: null,
      };
    case RESTORE_ANALYTIC_ERROR:
      return {
        ...state,
        restoringAnalytic: false,
        restoringAnalyticError: null,
      };
    case SHARE_ANALYTICS:
      return {
        ...state,
        sharingAnalytic: true,
        sharingAnalyticError: null,
      };
    case SHARE_ANALYTICS_SUCCESS:
      return {
        ...state,
        sharedAnalytics: action.payload.sharedAnalytics,
        sharingAnalytic: false,
        sharingAnalyticError: null,
      };
    case SHARE_ANALYTICS_ERROR:
      return {
        ...state,
        sharingAnalytic: false,
        sharingAnalyticError: action.payload.error,
      };
    case UNSHARE_ANALYTICS:
      return {
        ...state,
        unsharingAnalytic: true,
        unsharingAnalyticError: null,
      };
    case UNSHARE_ANALYTICS_SUCCESS:
      return {
        ...state,
        unsharedAnalytics: action.payload.unsharedAnalytics,
        sharedAnalytics: [],
        unsharingAnalytic: false,
        unsharingAnalyticError: null,
      };
    case UNSHARE_ANALYTICS_ERROR:
      return {
        ...state,
        unsharingAnalytic: false,
        unsharingAnalyticError: action.payload.error,
      };
    case GET_SYSTEM_TEMPLATES:
      return {
        ...state,
        error: null,
      };
    case GET_SYSTEM_TEMPLATES_SUCCESS:
      return {
        ...state,
        error: null,
        systemTemplates: action.payload.templates,
      };
    case GET_SYSTEM_TEMPLATES_METRICS:
      return {
        ...state,
        error: null,
        isLoadingTemplateMetrics: true,
      };
    case GET_SYSTEM_TEMPLATES_METRICS_SUCCESS:
      return {
        ...state,
        error: null,
        isLoadingTemplateMetrics: false,
        systemTemplates: action.payload.templates,
      };
    case GET_SYSTEM_TEMPLATES_METRICS_ERROR:
      return {
        ...state,
        isLoadingTemplateMetrics: false,
        error: action.payload.error,
      };
    case GET_SYSTEM_TEMPLATES_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case RESET:
      return {
        ...initialState,
      };
    case UPDATE_TITLE_ALL_ANALYTICS:
      return {
        ...state,
        items: updateDashboardsWithId(
          state.items,
          action.payload.dashboardId,
          action.payload.newTitle,
        ),
      };
    default:
      return state;
  }
};

export const resetAllAnalyticsList = () => ({
  type: RESET,
});

export const getItemCount = analytics => analytics.data?.metaData.count ?? 0;

export const allAnalyticActionCreator = ({
  endpointBase = NEW_DASHBOARD_ENDPOINT,
  search = null,
  ownerId = null,
  resultsCollation = initialState.resultsCollation,
  campaigns,
  isInitialLoad = false,
  appendItems = false,
}: AnalyticsListActionCreatorArgs = {}) => async dispatch => {
  const params = {
    ...resultsCollation,
    search,
    ownerId,
    campaigns,
  };
  const isFilter = !!search || !!ownerId;
  dispatch({ type: LIST_ANALYTICS });

  try {
    const allAnalytics = await performGet(endpointBase, params);

    dispatch({
      type: LIST_ANALYTICS_SUCCESS,
      payload: {
        items: allAnalytics.data.results,
        appendItems,
        paginationCount: getItemCount(allAnalytics),
        filtering: isFilter,
        search,
        isFilter,
        resultsCollation,
      },
    });

    const actionCreatorArgs = {
      endpointBase,
      isAlreadyFiltered: isFilter,
    };

    dispatch(sidebarAnalyticsActionCreator(actionCreatorArgs));
    if (isInitialLoad) {
      dispatch(recentlyViewedAnalyticsActionCreator(actionCreatorArgs));
      dispatch(campaignAnalyticsActionCreator(actionCreatorArgs));
      dispatch(starredAnalyticsActionCreator(actionCreatorArgs));
    }
  } catch (e) {
    dispatch({
      type: LIST_ANALYTICS_ERROR,
      payload: { error: e.response?.data },
    });
  }
};

// Centralizes code to fetch lists of items: all, owned, shared, starred, last-viewed.
//
export const processAnalyticResults = async ({ params = {}, endpointBase }) => {
  const response = await performGet(endpointBase, params);
  const items = response.data.results;
  const itemCount = getItemCount(response);

  return { items, itemCount };
};

export const updateAnalyticActionCreator = ({
  analytic,
  isSharingUpdate = false,
  endpointBase,
  idKey = 'id',
  onSuccessCallback = () => {},
}) => async dispatch => {
  dispatch({ type: UPDATE_ANALYTIC });

  try {
    await performPut(
      `${endpointBase}/${analytic[idKey]}`,
      { ...analytic },
      isSharingUpdate && analytic.shared ? { shouldShareSearches: true } : {},
    );
    dispatch({ type: UPDATE_ANALYTIC_SUCCESS });

    onSuccessCallback();
  } catch (e) {
    dispatch({
      type: UPDATE_ANALYTIC_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const starAnalyticActionCreator = (
  ids,
  endpointBase,
) => async dispatch => {
  dispatch({ type: STAR_ANALYTIC });

  try {
    const result = await performPatch(`${endpointBase}/star`, { ids });
    dispatch({
      type: STAR_ANALYTIC_SUCCESS,
      payload: { starredAnalytics: result.data },
    });
    dispatch(starredAnalyticsActionCreator({ endpointBase }));
    dispatch(
      sidebarAnalyticsActionCreator({
        endpointBase,
        doCountReload: true,
      }),
    );
    dispatch(clearAnalyticsSelection());
  } catch (e) {
    dispatch({
      type: STAR_ANALYTIC_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const unstarAnalyticActionCreator = (
  ids,
  endpointBase,
) => async dispatch => {
  dispatch({ type: UNSTAR_ANALYTIC });

  try {
    const result = await performPatch(`${endpointBase}/unstar`, { ids });
    dispatch({
      type: UNSTAR_ANALYTIC_SUCCESS,
      payload: { unstarredAnalytics: result.data },
    });
    dispatch(starredAnalyticsActionCreator({ endpointBase }));
    dispatch(
      sidebarAnalyticsActionCreator({
        endpointBase,
        doCountReload: true,
      }),
    );
    dispatch(clearAnalyticsSelection());
  } catch (e) {
    dispatch({
      type: UNSTAR_ANALYTIC_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const deleteAnalyticActionCreator = ({
  ids,
  endpointBase,
  intl,
  campaigns,
  getAnalyticsList,
  shouldDispatchAnalytics = false,
}) => async dispatch => {
  dispatch({ type: DELETE_ANALYTIC });

  try {
    await performPatch(`${endpointBase}/archive`, { ids });

    dispatch({ type: DELETE_ANALYTIC_SUCCESS });
    dispatch(clearAnalyticsSelection());
    if (getAnalyticsList) {
      dispatch(getAnalyticsList({ endpointBase, campaigns }));
    }
    dispatch(
      sidebarAnalyticsActionCreator({
        endpointBase,
        doCountReload: true,
      }),
    );
    if (endpointBase === '/api/v2/report') {
      dispatch(
        addPageMessage({
          text: intl.formatMessage(reportMessages.archiveReportSuccessfully),
          status: 'success',
          ttl: 6000,
        }),
      );
    } else if (endpointBase === '/api/v2/dashboard') {
      dispatch(updateDashboardArchiveStatus(ids, true));
      if (shouldDispatchAnalytics) {
        dispatch(ownedAnalyticsActionCreator());
        dispatch(listArchivedAnalyticsActionCreator());
      }

      dispatch(
        addPageMessage({
          text: intl.formatMessage(
            dashboardMessages.archiveDashboardSuccessfully,
          ),
          status: 'success',
          ttl: 6000,
        }),
      );
    }
  } catch (err) {
    const { response } = err;
    if (response.status === FORBIDDEN_STATUS_CODE) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(
            listsActionBarMessages.reportListArchiveActionErrorNotOwner,
          ),
          status: 'danger',
        }),
      );
    }
    dispatch({
      type: DELETE_ANALYTIC_ERROR,
      payload: {
        error: err.response?.data,
      },
    });
  }
};

export const restoreAnalyticActionCreator = ({
  ids,
  endpointBase,
  getAnalyticsList,
  intl,
  shouldDispatchAnalytics = false,
}) => async dispatch => {
  dispatch({ type: RESTORE_ANALYTIC });
  try {
    await performPatch(`${endpointBase}/restore`, { ids });

    dispatch({ type: RESTORE_ANALYTIC_SUCCESS });
    dispatch(clearAnalyticsSelection());
    if (getAnalyticsList) {
      dispatch(getAnalyticsList({ endpointBase }));
    }
    dispatch(
      sidebarAnalyticsActionCreator({
        endpointBase,
        doCountReload: true,
      }),
    );

    dispatch(updateDashboardArchiveStatus(ids, false));
    if (shouldDispatchAnalytics) {
      dispatch(ownedAnalyticsActionCreator());
      dispatch(listArchivedAnalyticsActionCreator());
    }

    if (intl) {
      dispatch(
        addPageMessage({
          text: intl.formatMessage(
            dashboardMessages.unarchivedDashboardSuccessfully,
          ),
          status: 'success',
          ttl: 6000,
        }),
      );
    }
  } catch (e) {
    dispatch({
      type: RESTORE_ANALYTIC_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const shareAnalyticActionCreator = ({
  ids,
  endpointBase,
  sharedListDispatch,
}) => async dispatch => {
  dispatch({ type: SHARE_ANALYTICS });

  try {
    const result = await performPatch(
      `${endpointBase}/share`,
      { ids },
      { shareDashboards: true },
    );

    dispatch({
      type: sharedListDispatch,
      payload: { isShared: true, analyticIds: result.data.map(a => a.id) },
    });
    dispatch({
      type: SHARE_ANALYTICS_SUCCESS,
      payload: { sharedAnalytics: result.data },
    });
    dispatch(clearAnalyticsSelection());
  } catch (e) {
    dispatch({
      type: SHARE_ANALYTICS_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const unshareAnalyticActionCreator = ({
  ids,
  endpointBase,
  sharedListDispatch,
}) => async dispatch => {
  dispatch({ type: UNSHARE_ANALYTICS });

  try {
    const result = await performPatch(
      `${endpointBase}/share`,
      { ids },
      { shareDashboards: false },
    );

    dispatch({
      type: sharedListDispatch,
      payload: { isShared: false, analyticIds: result.data.map(a => a.id) },
    });
    dispatch({
      type: UNSHARE_ANALYTICS_SUCCESS,
      payload: { unsharedAnalytics: result.data },
    });
    dispatch(clearAnalyticsSelection());
  } catch (e) {
    dispatch({
      type: UNSHARE_ANALYTICS_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const restoreDashboardsActionCreator = ({
  ids,
  intl,
  getAnalyticsList,
}) => async dispatch => {
  dispatch({ type: RESTORE_ANALYTIC_UNTRASHCAN });
  try {
    await performPatch(`${DASHBOARD_ENDPOINT_V2}/unTrashcan`, { ids });
    dispatch({ type: RESTORE_ANALYTIC_UNTRASHCAN_SUCCESS });
    dispatch(clearAnalyticsSelection());

    if (getAnalyticsList) {
      dispatch(getAnalyticsList());
    }
    dispatch(
      addPageMessage({
        text: intl.formatMessage(
          dashboardMessages.dashboardsSuccessfullyRestored,
          {
            DASHBOARD_COUNT: ids?.length || 0,
          },
        ),
        status: 'success',
        ttl: 5 * 1000,
      }),
    );
  } catch (e) {
    dispatch({
      type: RESTORE_ANALYTIC_UNTRASHCAN_ERROR,
      payload: {
        error: e?.response?.data,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(dashboardMessages.dashboardsRestoredError, {
          DASHBOARD_COUNT: ids?.length || 0,
        }),
        status: 'danger',
      }),
    );
  }
};

export const deleteDashboardsActionCreator = ({
  ids,
  intl,
  globalMessages,
  getAnalyticsList,
  allowsUndo = false,
  performsRedirect = () => {},
}) => async dispatch => {
  dispatch({ type: DELETE_ANALYTIC_TRASHCAN });
  try {
    await performPatch(`${DASHBOARD_ENDPOINT_V2}/trashcan`, { ids });
    dispatch({ type: DELETE_ANALYTIC_TRASHCAN_SUCCESS });
    dispatch(clearAnalyticsSelection());

    if (getAnalyticsList) {
      dispatch(getAnalyticsList());
    }

    dispatch(
      addPageMessage({
        text: intl.formatMessage(
          dashboardMessages.dashboardSuccessfullyDeleted,
        ),
        status: 'success',
        ttl: 10 * 1000,
        action: () =>
          dispatch(
            restoreDashboardsActionCreator({ ids, intl, getAnalyticsList }),
          ),
        actionText: allowsUndo ? intl.formatMessage(globalMessages.undo) : null,
        actionRightAlign: true,
        hideDismiss: true,
      }),
    );

    performsRedirect();
  } catch (e) {
    dispatch({
      type: DELETE_ANALYTIC_TRASHCAN_ERROR,
      payload: {
        error: e?.response?.data,
      },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(dashboardMessages.dashboardDeleteError),
        status: 'danger',
      }),
    );
  }
};

//This is only for dashboard creation
export const getDashboardSystemTemplatesActionCreator = () => async dispatch => {
  dispatch({ type: GET_SYSTEM_TEMPLATES });
  try {
    const response = await performGet(GET_DASHBOARD_SYSTEM_TEMPLATES);
    dispatch({
      type: GET_SYSTEM_TEMPLATES_SUCCESS,
      payload: { templates: response.data },
    });
  } catch (e) {
    dispatch({ type: GET_SYSTEM_TEMPLATES_ERROR, payload: { error: e } });
  }
};

export const getDashboardSystemTemplateMetricsActionCreator = template => async (
  dispatch,
  getState,
) => {
  dispatch({ type: GET_SYSTEM_TEMPLATES_METRICS });
  try {
    const dashboardData = await performGet(
      `${NEW_DASHBOARD_ENDPOINT}/${template.id}/widgetMetrics`,
    );
    const layout = dashboardData?.data?.layout;

    const templates = getState().dashboard.all.systemTemplates;
    const templateIndex = templates.findIndex(t => t.id === template.id);
    templates[templateIndex].layout = layout;

    dispatch({
      type: GET_SYSTEM_TEMPLATES_METRICS_SUCCESS,
      payload: { templates },
    });
  } catch (e) {
    dispatch({
      type: GET_SYSTEM_TEMPLATES_METRICS_ERROR,
      payload: { error: e },
    });
  }
};

export default analyticsListReducer;
