import { actions } from 'react-redux-form';

const INFLUENCER_LIST_FORM = 'influencerListTitleForm';
export const initialState = {
  title: '',
};

const InfluencerListTitleFormReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const loadInfluencerListTitleForm = title => dispatch => {
  dispatch(actions.load(INFLUENCER_LIST_FORM, { title }));
};

export const submitInfluencerListTitleForm = () => dispatch =>
  dispatch(actions.submit(INFLUENCER_LIST_FORM));

export const dispatchChangeInputAction = (prop, value) => dispatch =>
  dispatch(actions.change(`${INFLUENCER_LIST_FORM}.${prop}`, value));

export default InfluencerListTitleFormReducer;
