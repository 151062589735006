import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * TextArea
 */
class TextArea extends Component {
  state = {
    value: this.props.value,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    if (!isEqual(this.state.value, nextProps.value)) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  onChange = e => {
    const value = e.target.value;

    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    const {
      className,
      placeholder,
      type,
      error,
      disabled,
      readonly,
      resize,
      rows,
      cols,
      id,
      style,
    } = this.props;

    const classes = classNames(
      'tk-form-control',
      'tk-form-control--textarea',
      className,
      {
        'tk-form-control--error': error,
        'tk-form-control--disabled': disabled,
        [`tk-form-control--resize-${resize}`]: resize,
      },
    );

    return (
      <textarea
        style={style}
        className={classes}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        rows={rows}
        cols={cols}
        value={this.state.value}
        onChange={this.onChange}
        id={id}
      />
    );
  }
}

TextArea.defaultProps = {
  className: '',
  cols: 50,
  disabled: false,
  error: false,
  onChange: () => {},
  placeholder: '',
  readonly: false,
  resize: '',
  rows: 4,
  type: 'text',
  value: '',
  id: '',
  style: {},
};

TextArea.propTypes = {
  /** Optional custom class names **/
  className: PropTypes.string,
  /** How many columns */
  cols: PropTypes.number,
  /** Is this input disabled? */
  disabled: PropTypes.bool,
  /** Is this input currently valid? */
  error: PropTypes.bool,
  /** Is this input readonly? */
  readonly: PropTypes.bool,
  /** Can you resize the textarea? */
  resize: PropTypes.oneOf(['horizontal', 'vertical', 'both', 'none']),
  /** How many rows */
  rows: PropTypes.number,
  /** onChange callback on entry */
  onChange: PropTypes.func,
  /** Placeholder text for button */
  placeholder: PropTypes.string,
  /** What type of input it is */
  type: PropTypes.string,
  /** Default value */
  value: PropTypes.string,
  /** id for form control purposes */
  id: PropTypes.string,
  /** styles for the textarea html component */
  style: PropTypes.object,
};

export default TextArea;
