import React from 'react';

import { useIntl } from 'react-intl';

import T from 'i18n/TranslatedMessage';

import {
  ALERTS_URL,
  BASE_URL,
  CAMPAIGNS_URL,
  CONTACTS_URL,
  DASHBOARD_HOME_URL,
  DASHBOARD_URL,
  REPORT_URL,
  DEV_FEATURES,
  DISCOVERY_URL,
  FEATURES,
  IMPACT_URL,
  INFLUENCERS_HUB_URL,
  INFLUENCERS_LOGIN_URL,
  INFLUENCERS_MESSAGE_CENTER_URL,
  INFLUENCERS_EDCALS_FEED_URL,
  INFLUENCERS_MEDIA_REQUEST_FEED_URL,
  INFLUENCERS_SAVED_LISTS_URL,
  INSIGHTS_BASE_URL,
  LEARN_MORE_MARKETING_URL,
  MESSAGE_CENTER_BASE_URL,
  NAV_PAGES,
  NEW_NEWSLETTER_BASE_URL,
  REPORTS_BASE_URL,
  SEARCH_URL,
  EARNED_SEARCH_URL,
  STORIES_URL,
  TAGS_URL,
  UNIFIED_SETTINGS_BASE_URL,
  UNION_METRICS_LOGIN_URL,
  MARKETING_URLS,
  SOCIAL_LISTENING_URL,
} from 'trendkite-front-end-app/src/constants/constants';

import {
  getCanUseBrandwatchEmbedded,
  getCanUseBrandwatchUpsellLinkCanada,
  getCanUseCampaigns,
} from 'trendkite-front-end-app/src/selectors/canUse/canUse';

import userDropDownMessages from '../userDropDown.messages';

import FeatureStatusBadge from './feature-status-badge';
import getRegionalUrl from './getRegionalUrl';
import messages from './menu-props.messages';

import {
  SocialListeningNavLabel,
  SocialListeningNavTooltip,
  SocialListeningUpsellLabel,
  SocialListeningUpsellTooltip,
} from './socialListeningMenuProps';

import Spacer from './Spacer';

const getCisionHost = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'prspainternal.vocusdr.com';
    case 'dev.trendkite.com':
      return 'prspainternal.vocusdr.com';
    case 'staging.trendkite.com':
      return 'prspareleased.vocusdr.com';
    default:
      return 'app.cision.com';
  }
};

const buildCisionUrl = (landingPage, companyId) =>
  `https://${getCisionHost()}/#/tk-login?companyId=${companyId}&landing=${landingPage}`;

const getCoverageProps = ({
  currentNav,
  userHasDevFeatureFlag,
  userHasFeatureFlag,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  if (userHasDevFeatureFlag(DEV_FEATURES.influencerHubOnly)) {
    return null;
  }

  const hasMonitoringFeatureFlag = userHasFeatureFlag(FEATURES.monitoring);
  const hasEarnedSearchesDevFeatureFlag =
    userHasDevFeatureFlag(DEV_FEATURES.simpleEarnedSearch) ||
    userHasDevFeatureFlag(DEV_FEATURES.advancedEarnedSearch);

  return {
    id: 'coverage-nav-button',
    label: intl.formatMessage(messages.menuPropsCoverage),
    subOptions: [
      {
        id: 'earned-media-link',
        isCurrentNav: currentNav === NAV_PAGES.earnedMedia,
        label: intl.formatMessage(messages.menuPropsCoverageEarnedMedia),
        location: hasMonitoringFeatureFlag
          ? DISCOVERY_URL
          : MARKETING_URLS.earnedMediaMonitoring,
      },
      {
        id: 'social-media-link',
        label: intl.formatMessage(messages.menuPropsCoverageSocialMedia),
        location: UNION_METRICS_LOGIN_URL,
        hide: !userHasFeatureFlag(FEATURES.unionMetrics),
      },
      {
        id: 'searches-link',
        isCurrentNav: currentNav === NAV_PAGES.searches,
        label: intl.formatMessage(messages.menuPropsCoverageSavedSearches),
        location: hasEarnedSearchesDevFeatureFlag
          ? EARNED_SEARCH_URL
          : SEARCH_URL,
        hide: !hasMonitoringFeatureFlag,
      },
      {
        id: 'tags-link',
        isCurrentNav: currentNav === NAV_PAGES.tags,
        label: intl.formatMessage(messages.menuPropsCoverageTags),
        location: TAGS_URL,
        hide: !hasMonitoringFeatureFlag,
      },
    ],
  };
};

const getPpcInfluencerProps = ({
  cisionCompanyId,
  currentNav,
  userHasDevFeatureFlag,
  userHasFeatureFlag,
  userHasSomeFeatureFlags,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  const userHasAnyOldInfluencersFeaturesFlags = userHasSomeFeatureFlags([
    FEATURES.pinpointContacts,
    FEATURES.cisionMCD,
    FEATURES.influencers,
  ]);

  const userMeetsNewValidation =
    userHasDevFeatureFlag(DEV_FEATURES.oldInfluencers) &&
    userHasAnyOldInfluencersFeaturesFlags;

  const userCanAccessOldInfluencers = userMeetsNewValidation;

  if (!userCanAccessOldInfluencers) {
    return null;
  }

  return {
    id: 'contacts-nav-button',
    label: intl.formatMessage(messages.menuPropsInfluencers),
    subOptions: [
      {
        id: 'earned-media-text',
        label: intl.formatMessage(messages.menuPropsInfluencersEarnedMedia),
        isSubOptionHeader: true,
        hide:
          !userHasFeatureFlag(FEATURES.pinpointContacts) &&
          !userHasFeatureFlag(FEATURES.cisionMCD) &&
          !!cisionCompanyId,
      },
      {
        id: 'discover-link',
        isCurrentNav: currentNav === NAV_PAGES.discover,
        label: intl.formatMessage(messages.menuPropsInfluencersDiscover),
        location: CONTACTS_URL,
        hide: !userHasFeatureFlag(FEATURES.pinpointContacts),
      },
      {
        id: 'message-center-link',
        isCurrentNav: currentNav === NAV_PAGES.messageCenter,
        label: intl.formatMessage(messages.menuPropsInfluencersMessageCenter),
        location: MESSAGE_CENTER_BASE_URL,
        hide: !userHasFeatureFlag(FEATURES.pinpointContacts),
      },
      {
        id: 'cision-mcd-search-link',
        label: intl.formatMessage(
          messages.menuPropsInfluencersSearchMediaContacts,
        ),
        location: buildCisionUrl('contact', cisionCompanyId),
        hide: !(userHasFeatureFlag(FEATURES.cisionMCD) && cisionCompanyId),
      },
      {
        id: 'cision-mcd-list-link',
        isCurrentNav: currentNav === NAV_PAGES.savedLists,
        label: intl.formatMessage(messages.menuPropsInfluencersSavedLists),
        // eslint-disable-next-line no-nested-ternary
        location: buildCisionUrl('contact-list', cisionCompanyId),
        hide: !(userHasFeatureFlag(FEATURES.cisionMCD) && cisionCompanyId),
      },
      {
        id: 'social-media-text',
        label: intl.formatMessage(messages.menuPropsInfluencersSocialMedia),
        isSubOptionHeader: true,
        hide: !userHasFeatureFlag(FEATURES.influencers),
      },
      {
        id: 'influencers-link',
        label: intl.formatMessage(
          messages.menuPropsInfluencersSearchInfluencers,
        ),
        location: INFLUENCERS_LOGIN_URL,
        hide: !userHasFeatureFlag(FEATURES.influencers),
      },
    ],
  };
};

const getInfluencerProps = ({
  currentNav,
  userHasDevFeatureFlag,
  userHasFeatureFlag,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  const hasConnect = userHasFeatureFlag(FEATURES.connect);

  if (!hasConnect) {
    return null;
  }

  return {
    id: 'influencers-nav-button',
    label: (
      <span>
        <T {...messages.menuPropsContacts} />
        {userHasDevFeatureFlag(DEV_FEATURES.influencerHubBeta) ? (
          <>
            <Spacer />
            <FeatureStatusBadge />
          </>
        ) : null}
      </span>
    ),
    subOptions: [
      {
        id: 'contacts-search-link',
        isCurrentNav: currentNav === NAV_PAGES.connect,
        label: intl.formatMessage(messages.menuPropsContactsSearch),
        location: hasConnect ? INFLUENCERS_HUB_URL : MARKETING_URLS.connect,
      },
      {
        id: 'contacts-list-link',
        isCurrentNav: currentNav === NAV_PAGES.influencerSavedLists,
        label: intl.formatMessage(messages.menuPropsContactsList),
        location: hasConnect
          ? INFLUENCERS_SAVED_LISTS_URL
          : MARKETING_URLS.connect,
      },
      {
        id: 'contacts-message-center-link',
        isCurrentNav: currentNav === NAV_PAGES.influencerMessageCenter,
        label: intl.formatMessage(
          messages.menuPropsInfluencersBetaMessageCenter,
        ),
        location: hasConnect
          ? INFLUENCERS_MESSAGE_CENTER_URL
          : MARKETING_URLS.connect,
        hide: !userHasDevFeatureFlag(DEV_FEATURES.jorts),
      },
      {
        id: 'contacts-opportunity-feed-link',
        isCurrentNav: currentNav === NAV_PAGES.influencerOpportunityFeed,
        label: intl.formatMessage(
          messages.menuPropsInfluencersEditorialCalendar,
        ),
        location: INFLUENCERS_EDCALS_FEED_URL,
        hide: !userHasFeatureFlag(FEATURES.editorialCalendars),
      },
      {
        id: 'contacts-media-requests-link',
        isCurrentNav: currentNav === NAV_PAGES.influencerMediaRequests,
        label: intl.formatMessage(messages.menuPropsInfluencersMediaRequests),
        location: INFLUENCERS_MEDIA_REQUEST_FEED_URL,
        hide: !userHasFeatureFlag(FEATURES.mediaRequests),
      },
    ],
  };
};

const getCampaignProps = ({
  cisionCompanyId,
  currentNav,
  userHasDevFeatureFlag,
  userHasFeatureFlag,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  if (userHasDevFeatureFlag(DEV_FEATURES.influencerHubOnly)) {
    return null;
  }

  const canGetToPrwebViaSso =
    userHasFeatureFlag(FEATURES.prwebSso) && !!cisionCompanyId;

  return {
    id: 'campaigns-nav-button',
    label: intl.formatMessage(messages.menuPropsCampaigns),
    subOptions: [
      {
        id: 'campaigns-link',
        isCurrentNav: currentNav === NAV_PAGES.campaigns,
        label: intl.formatMessage(messages.menuPropsCampaignsMyCampaigns),
        location: userHasDevFeatureFlag(DEV_FEATURES.devCampaignConnectPremium)
          ? getCanUseCampaigns()
            ? CAMPAIGNS_URL
            : MARKETING_URLS.earnedMediaMonitoring // TODO: new upsell link. Connect Basic upsell?
          : userHasFeatureFlag(FEATURES.monitoring)
          ? CAMPAIGNS_URL
          : MARKETING_URLS.earnedMediaMonitoring,
      },
      {
        id: 'stories-link',
        isCurrentNav: currentNav === NAV_PAGES.stories,
        label: intl.formatMessage(messages.menuPropsCampaignsStories),
        location: !userHasFeatureFlag(FEATURES.storyHub)
          ? MARKETING_URLS.stories
          : STORIES_URL,

        hide: false,
      },
      {
        id: 'prweb-sso-link',
        label: intl.formatMessage(
          messages.menuPropsCampaignsSsoLinkWithoutPrNewswire,
        ),
        location: buildCisionUrl('activities', cisionCompanyId),
        hide: !canGetToPrwebViaSso,
      },
    ],
  };
};

const getAnalyticsProps = ({
  currentNav,
  userHasDevFeatureFlag,
  userHasFeatureFlag,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  if (userHasDevFeatureFlag(DEV_FEATURES.influencerHubOnly)) {
    return null;
  }

  if (!userHasFeatureFlag(FEATURES.monitoring)) {
    return null;
  }

  const userOnlyHasNewDashboard = () =>
    !userHasDevFeatureFlag(DEV_FEATURES.oldDashboards) &&
    userHasDevFeatureFlag(DEV_FEATURES.newDashboards);

  const userOnlyHasNewReports = () =>
    !userHasDevFeatureFlag(DEV_FEATURES.oldDashboards) &&
    userHasDevFeatureFlag(DEV_FEATURES.newReports);

  const dashboardMessage = intl.formatMessage(
    messages.menuPropsAnalyticsDashboards,
  );

  const reportMenuOptionLabel = intl.formatMessage(
    messages.menuPropsAnalyticsNewReports,
  );

  const impactForEarnedMediaUrl = MARKETING_URLS.earnedMediaImpact;
  return {
    id: 'analytics-nav-button',
    label: intl.formatMessage(messages.menuPropsAnalytics),
    subOptions: [
      {
        id: 'new-dashboards-link',
        isCurrentNav: currentNav === NAV_PAGES.newDashboards,
        label: userOnlyHasNewDashboard() ? (
          dashboardMessage
        ) : (
          <>
            <FeatureStatusBadge />
            <Spacer />
            {dashboardMessage}
          </>
        ),
        location: DASHBOARD_URL,
        hide: !userHasDevFeatureFlag(DEV_FEATURES.newDashboards),
      },
      {
        id: 'new-reports-link',
        isCurrentNav: currentNav === NAV_PAGES.newReports,
        label: userOnlyHasNewReports() ? (
          reportMenuOptionLabel
        ) : (
          <>
            <FeatureStatusBadge />
            <Spacer />
            {reportMenuOptionLabel}
          </>
        ),
        location: REPORT_URL,
        hide: !userHasDevFeatureFlag(DEV_FEATURES.newReports),
      },
      {
        id: 'dashboards-link',
        isCurrentNav: currentNav === NAV_PAGES.dashboards,
        label: intl.formatMessage(messages.menuPropsAnalyticsMyDashboards),
        location: DASHBOARD_HOME_URL,
        hide: !userHasDevFeatureFlag(DEV_FEATURES.oldDashboards),
      },
      {
        id: 'impact-link',
        isCurrentNav: currentNav === NAV_PAGES.earnedImpact,
        label: intl.formatMessage(
          messages.menuPropsAnalyticsImpactForEarnedMedia,
        ),
        location: userHasFeatureFlag(FEATURES.earnedImpact)
          ? IMPACT_URL
          : impactForEarnedMediaUrl,
      },
      {
        id: 'cision-insights-link',
        label: intl.formatMessage(messages.menuPropsAnalyticsCisionInsights),
        location: INSIGHTS_BASE_URL,
        hide: !userHasFeatureFlag(FEATURES.cisionInsights),
        inNewTab: true,
      },
    ],
  };
};

const getReportsProps = ({
  currentNav,
  userHasDevFeatureFlag,
  userHasFeatureFlag,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  const isVisible =
    userHasFeatureFlag(FEATURES.monitoring) &&
    userHasDevFeatureFlag(DEV_FEATURES.oldDashboards);

  if (!isVisible) {
    return null;
  }

  return {
    id: 'reports-nav-button',
    isCurrentNav: currentNav === NAV_PAGES.reports,
    label: intl.formatMessage(messages.menuPropsReports),
    location: REPORTS_BASE_URL,
  };
};

const getAlertsProps = ({
  currentNav,
  userHasDevFeatureFlag,
  userHasFeatureFlag,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  const hasMonitoringFeatureFlag = userHasFeatureFlag(FEATURES.monitoring);

  if (
    userHasDevFeatureFlag(DEV_FEATURES.influencerHubOnly) ||
    !hasMonitoringFeatureFlag
  ) {
    return null;
  }

  return {
    // DO NOT CHANGE THE ID!!!!!!
    // JS magic in grails project - alerts-module.js
    id: 'alerts-nav-button',
    label: intl.formatMessage(messages.menuPropsAlerts),
    // no isCurrentNav for digests/alerts because js in angular
    // app handles currentNav display
    subOptions: [
      {
        // DO NOT CHANGE THE ID!!!!!!
        // JS magic in grails project - alerts-module.js
        id: 'digests-link',
        label: intl.formatMessage(messages.menuPropsAlertsDigests),
        location: ALERTS_URL,
        hide: !hasMonitoringFeatureFlag,
      },
      {
        // DO NOT CHANGE THE ID!!!!!!
        // JS magic in grails project - alerts-module.js
        id: 'newsletters-link',
        isCurrentNav: currentNav === NAV_PAGES.newsletter,
        label: intl.formatMessage(messages.menuPropsAlertsNewsletters),
        location: `${BASE_URL}${NEW_NEWSLETTER_BASE_URL}`,
        hide: !userHasFeatureFlag(FEATURES.newsletter),
      },
    ],
  };
};

const getSocialListeningProps = ({ currentNav }) => {
  if (getCanUseBrandwatchEmbedded()) {
    return {
      id: 'social-listening-nav-button',
      label: <SocialListeningNavLabel />,
      isCurrentNav: currentNav === NAV_PAGES.socialListening,
      tooltip: <SocialListeningNavTooltip />,
      location: SOCIAL_LISTENING_URL,
    };
  }

  const canUseBrandwatchUpsellLinkCanada = getCanUseBrandwatchUpsellLinkCanada();
  const accountRegion = canUseBrandwatchUpsellLinkCanada ? 'ca' : undefined;
  let userLanguage = window.activeUser?.language?.split('-')?.[0] || 'en';
  userLanguage = userLanguage.toLowerCase();
  userLanguage = ['en', 'fr'].indexOf(userLanguage) >= 0 ? userLanguage : 'en';

  const brandwatchUpsellUrl = getRegionalUrl({
    page: 'brandwatchUpsell',
    region: accountRegion,
    language: userLanguage,
  });

  if (brandwatchUpsellUrl) {
    return {
      id: 'social-listening-upsell-nav-button',
      label: <SocialListeningUpsellLabel />,
      tooltip: (
        <SocialListeningUpsellTooltip
          brandwatchUpsellUrl={brandwatchUpsellUrl}
        />
      ),
      location: brandwatchUpsellUrl,
      inNewTab: true,
    };
  }

  return null;
};

const getMenuProps = props =>
  [
    getCoverageProps(props),
    getPpcInfluencerProps(props),
    getInfluencerProps(props),
    getCampaignProps(props),
    getAnalyticsProps(props),
    getReportsProps(props),
    getAlertsProps(props),
    getSocialListeningProps(props),
  ].filter(i => !!i);

const getSettingsProps = ({ userHasDevFeatureFlag }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  const settingsProps = {
    id: 'setting-nav-button',
    label: intl.formatMessage(messages.menuPropsSettings),
    subOptions: [
      {
        id: 'user-link',
        label: intl.formatMessage(messages.menuPropsSettingsUser),
        location: `/#${UNIFIED_SETTINGS_BASE_URL}/user`,
      },
      {
        id: 'integrations-link',
        label: intl.formatMessage(messages.menuPropsSettingsIntegrations),
        location: `/#${UNIFIED_SETTINGS_BASE_URL}/account/integrations`,
      },
      {
        hide: !userHasDevFeatureFlag(DEV_FEATURES.jorts),
        id: 'email-link',
        label: intl.formatMessage(messages.menuPropsSettingsEmail),
        location: `/#${UNIFIED_SETTINGS_BASE_URL}/account/mail`,
      },
    ],
  };

  return settingsProps;
};

const getHelpProps = ({ userIsImpersonating }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();

  return {
    id: 'help-nav-button',
    label: intl.formatMessage(messages.menuPropsHelp),
    subOptions: [
      {
        id: 'create-a-ticket-link',
        label: intl.formatMessage(messages.menuPropsHelpCreateATicket),
        location: intl.formatMessage(userDropDownMessages.createATicketURL),
        inNewTab: true,
      },
      {
        id: 'knowledge-guide-link',
        label: intl.formatMessage(messages.menuPropsHelpKnowledgeGuide),
        location: intl.formatMessage(userDropDownMessages.newKnowledgeGuideURL),
        inNewTab: true,
      },
      {
        hide: userIsImpersonating,
        iconLeft: 'chat',
        id: 'chat-button',
        label: intl.formatMessage(messages.menuPropsHelpChat),
        onClick: () => window.$zopim.livechat.window.show(),
      },
    ],
  };
};

const getMarketingProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  return {
    id: 'marketing-nav-button',
    label: intl.formatMessage(messages.menuPropsMarketing),
    location: LEARN_MORE_MARKETING_URL,
  };
};

export const getSecondaryMenuProps = ({
  isAuthed,
  loginNavEnabled,
  ...props
}) => {
  if (!isAuthed && loginNavEnabled) {
    return [getMarketingProps(props)];
  }
  if (isAuthed) {
    return [getSettingsProps(props), getHelpProps(props)];
  }

  return [];
};

export default getMenuProps;
