export default {
  manageSearchesLink: {
    id: 'MANAGE_SEARCHES_LINK',
    description: 'Label for the manage searches link text',
    defaultMessage: 'Manage Searches',
  },
  topContent: {
    id: 'DISCOVERY_TOP_CONTENT',
    description: 'Label for the top content option in the discovery tab menu',
    defaultMessage: 'Top Content',
  },
  allMentions: {
    id: 'DISCOVERY_ALL_MENTIONS',
    description: 'Label for the all mentions option in the discovery tab menu',
    defaultMessage: 'All Mentions',
  },
  topJournalists: {
    id: 'DISCOVERY_TOP_JOURNALISTS',
    description:
      'Label for the top journalists option in the discovery tab menu',
    defaultMessage: 'Top Journalists',
  },
  discoverySearchesEmptyStateTitle: {
    id: 'DISCOVERY_SEARCHES_EMPTY_STATE_TITLE',
    description: "Empty state title when users don't have searches",
    defaultMessage: "You don't have any searches.",
  },
  discoverySearchesEmptyStateText: {
    id: 'DISCOVERY_SEARCHES_EMPTY_STATE_TEXT',
    description: "Empty state text when users don't have searches",
    defaultMessage:
      "You don't have any saved or shared searches. Once you’ve created a search it will be available in the left navigation under the category you define.",
  },
  discoveryTotalMentionsTitle: {
    id: 'DISCOVERY_TOTAL_MENTIONS_TITLE',
    description: 'Discovery total mentions title',
    defaultMessage: 'Total Mentions',
  },
  discoverySocialSharesTitle: {
    id: 'DISCOVERY_SOCIAL_SHARES_TITLE',
    description: 'Discovery social shares title',
    defaultMessage: 'Social Shares',
  },
  discoveryAVGSeoImpactTitle: {
    id: 'DISCOVERY_AVG_SEO_IMPACT_TITLE',
    description: 'Discovery AVG SEO Impact title',
    defaultMessage: 'Avg. SEO Impact',
  },
  discoveryBacklinkVisits: {
    id: 'DISCOVERY_BACKLINK_VISITS_TITLE',
    description: 'Discovery Backlink visits title',
    defaultMessage: 'Backlink Visits',
  },
  discoveryWebsiteVisitors: {
    id: 'DISCOVERY_WESBSITE_VISITORS_TITLE',
    description: 'Discovery website vistors title',
    defaultMessage: 'Website Visitors',
  },
  dataNoExistsForMetric: {
    id: 'DISCOVERY_NO_DATA_EXISTS_FOR_METRIC_MESSAGE',
    description:
      'Discovery alternative message when no data was found for metrics',
    defaultMessage: 'Looks like there is currently no data available.',
  },
  discoveryDeltaLabel: {
    id: 'DISCOVERY_DELTA_LABEL_ALTERNATIVE_TEXT',
    description: 'Discovery delta label alternative text',
    defaultMessage: 'vs. Previous { customDate }',
  },
  discoveryPerformanceMetricDeltaText: {
    id: 'DISCOVERY_PERFORMANCE_LABEL_DELTA_TEXT',
    description: 'Discovery performance metric delta text',
    defaultMessage: 'No change',
  },
  discoveryMetricsMetaBarTitle: {
    id: 'DISCOVERY_METRICS_META_BAR_TITLE',
    description: 'Discovery metrics meta bar title',
    defaultMessage: 'Showing metrics for {title}',
  },
  discoverySharedWithMyTeamTitle: {
    id: 'DISCOVERY_SHARED_WITH_MY_TEAM_TITLE',
    description: 'Discovery subtitle Shared with my team title',
    defaultMessage: 'Shared with your team',
  },
  discoveryOnlyICanSeeThisTitle: {
    id: 'DISCOVERY_ONLY_I_CAN_SEE_THIS_TITLE',
    description: 'Discovery subTitle Only I Can See This',
    defaultMessage: 'Only I can see this',
  },
  discoveryUserAccountPrintLicenseError: {
    id: 'DISCOVERY_USER_ACCOUNT_HAS_NOT_PRINT_LICENSE_SET_CORRECTLY',
    description: 'Message to show when the user is not set correctly',
    defaultMessage:
      'This account does not have a correct print license attached to the account.',
  },
  discoveryDefaultErrorMessage: {
    id: 'DISCOVERY_DEFAULT_ERROR_MESSAGE',
    description: 'Discovery > Default message shown when an error occurr',
    defaultMessage: 'Error occurred while trying to load metrics.',
  },
};
