export default {
  createATicketURL: {
    id: 'SUPPORT_TICKET_URL',
    defaultMessage:
      'https://cision.atlassian.net/servicedesk/customer/portal/1019',
    description: 'Create a Ticket URL',
  },
  newKnowledgeGuideURL: {
    id: 'NEW_KNOWLEDGE_GUIDE_URL',
    defaultMessage:
      'https://cision.atlassian.net/servicedesk/customer/portal/1019',
    description: 'New Knowledge Guide URL',
  },
  newHelpSiteURL: {
    id: 'NEW_HELP_SITE_URL',
    defaultMessage:
      'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/25765216446?src=-840912917',
    description: 'New help site URL',
  },
  settings: {
    id: 'USER_DROPDOWN_SETTINGS',
    defaultMessage: 'Settings',
    description: 'Message for user settings option',
  },
  admin: {
    id: 'USER_DROPDOWN_ADMIN',
    defaultMessage: 'Admin',
    description: 'Message for admin pages option',
  },
  assetLibrary: {
    id: 'USER_DROPDOWN_ASSET_LIBRARY',
    defaultMessage: 'Asset Library',
    description: 'Message for asset library option',
  },
  createATicket: {
    id: 'USER_DROPDOWN_CREATE_A_TICKET',
    defaultMessage: 'Create a Ticket',
    description: 'Message for create a ticket option',
  },
  knowledgeGuide: {
    id: 'USER_DROPDOWN_KNOWLEDGE_GUIDE',
    defaultMessage: 'Knowledge Guide',
    description: 'Message for knowledge guide option',
  },
  logOut: {
    id: 'USER_DROPDOWN_LOG_OUT',
    defaultMessage: 'Log Out',
    description: 'Message for log out option',
  },
  helpSite: {
    id: 'USER_DROPDOWN_HELP_SITE',
    defaultMessage: 'Help Site',
    description: 'Message for help site option',
  },
  helpTooltipLabel: {
    id: 'SECONDARY_MENU_HELP_TOOLTIP',
    defaultMessage: 'Help for this page',
    description: 'Message for help for this page',
  },
  chatTooltipLabel: {
    id: 'SECONDARY_MENU_CHAT_TOOLTIP',
    defaultMessage: 'Chat with Support',
    description: 'Message for Chat with support',
  },
  parlorTooltipLabel: {
    id: 'SECONDARY_MENU_PARLOR_TOOLTIP',
    defaultMessage: 'Feedback Hub',
    description: 'Message for Feedback Hub',
  },
  blankTooltipLabel: {
    id: 'BLANK_TOOLTIP',
    defaultMessage: '',
    description: 'Blank Tooltip',
  },
};
