import memoize from 'lodash/memoize';
import moment from 'moment';
import { createSelector } from 'reselect';

import { DEFAULT_ARTICLE_LIST_PAGE_SIZE } from 'constants/constants';
import { initialArticleListState } from 'reducers/article-lists';
import {
  defaultAnalyticsIntegrationSelector,
  enabledAnalyticsIntegrationsSelector,
} from 'selectors/app-info';
import { pendingExportsByIdsSelector } from 'selectors/exports';
import { getApiQueryParamsFromList } from 'services/article-lists/article-lists';

export const articleListsObjSelector = state => state.articleLists || {};

export const articleListsSelector = createSelector(
  articleListsObjSelector,
  articleListsObj => (articleListsObj ? articleListsObj.articleLists : {}),
);

export const currentArticleListIdSelector = state =>
  state.articleLists ? state.articleLists.current : null;

export const articleListByIdSelector = createSelector(
  articleListsSelector,
  lists =>
    memoize(listId => {
      if (!lists) {
        return {};
      }
      const list = lists[listId] || initialArticleListState;
      return {
        ...list,
        queryParams: {
          ...list.queryParams,
          analyticsIntegrationId: !isNaN(
            parseInt(list.queryParams.analyticsIntegrationId, 10),
          )
            ? parseInt(list.queryParams.analyticsIntegrationId, 10)
            : NaN,
          pageNum: !isNaN(parseInt(list.queryParams.pageNum, 10))
            ? parseInt(list.queryParams.pageNum, 10)
            : 1,
          pageSize: !isNaN(parseInt(list.queryParams.pageSize, 10))
            ? parseInt(list.queryParams.pageSize, 10)
            : DEFAULT_ARTICLE_LIST_PAGE_SIZE,
        },
        endDate: moment(
          isNaN(list.endDate) ? list.endDate : Number(list.endDate),
        )
          .utc()
          .toDate(),
        startDate: moment(
          isNaN(list.startDate) ? list.startDate : Number(list.startDate),
        )
          .utc()
          .toDate(),
      };
    }),
);

export const currentArticleListSelector = createSelector(
  currentArticleListIdSelector,
  articleListByIdSelector,
  (listId, listByIdSelector) => listByIdSelector(listId),
);

export const currentArticleListArticleIdsSelector = createSelector(
  currentArticleListSelector,
  list => (list ? list.articleIds : []),
);

export const currentArticleListQueryParamsSelector = createSelector(
  currentArticleListSelector,
  list =>
    list
      ? getApiQueryParamsFromList(list)
      : getApiQueryParamsFromList(initialArticleListState),
);

export const currentArticleListDataPointSelector = createSelector(
  currentArticleListQueryParamsSelector,
  queryParams =>
    queryParams && queryParams.dataPoint
      ? queryParams.dataPoint
      : initialArticleListState.queryParams.dataPoint,
);

export const currentArticleListPendingExportsSelector = createSelector(
  currentArticleListSelector,
  pendingExportsByIdsSelector,
  (list, getPendingExportsByIds) =>
    list
      ? getPendingExportsByIds(list.exportIds)
      : getPendingExportsByIds(initialArticleListState.exportIds),
);

export const currentArticleListPageNumSelector = createSelector(
  currentArticleListQueryParamsSelector,
  queryParams =>
    queryParams && queryParams.pageNum
      ? queryParams.pageNum
      : initialArticleListState.queryParams.pageNum,
);

export const currentArticleListArticleCountSelector = createSelector(
  currentArticleListSelector,
  list =>
    list && typeof list.articleCount === 'number' ? list.articleCount : NaN,
);

export const currentArticleListStartDateSelector = createSelector(
  currentArticleListSelector,
  list => (list ? list.startDate : initialArticleListState.startDate),
);

export const currentArticleListKeywordFilterTextSelector = createSelector(
  currentArticleListQueryParamsSelector,
  queryParams =>
    queryParams && queryParams.keywordFilterText
      ? queryParams.keywordFilterText
      : initialArticleListState.queryParams.keywordFilterText,
);

export const currentArticleListEndDateSelector = createSelector(
  currentArticleListSelector,
  list => (list ? list.endDate : initialArticleListState.endDate),
);

export const currentArticeListHasDateScaleSelector = createSelector(
  currentArticleListSelector,
  list => (list ? list.hasDateScale : false),
);

export const currentArticleListAnalyticsIntegrationSelector = createSelector(
  currentArticleListQueryParamsSelector,
  enabledAnalyticsIntegrationsSelector,
  defaultAnalyticsIntegrationSelector,
  (queryParams, integrations, defaultIntegration) => {
    const queriedIntegrationId = queryParams
      ? queryParams.analyticsIntegrationId
      : '';
    const queriedIntegration = integrations.filter(
      integration => integration.analyticsId === queriedIntegrationId,
    );

    if (queriedIntegration.length) {
      return queriedIntegration[0];
    }

    if (defaultIntegration) {
      return defaultIntegration;
    }

    return null;
  },
);

export const currentArticleListAnalyticsIntegrationTypeSelector = createSelector(
  currentArticleListAnalyticsIntegrationSelector,
  integration => (integration ? integration.analyticsIntegrationType : ''),
);
