export default {
  adobeIntegration: {
    id: 'ANALYTICS_INTEGRATION_ADOBE_INTEGRATION',
    description: 'Analytics integration: Label for the Adobe integration',
    defaultMessage: 'Adobe',
  },
  gaIntegration: {
    id: 'ANALYTICS_INTEGRATION_GA_INTEGRATION',
    description: 'Analytics integration: Label for the GA integration',
    defaultMessage: 'GA',
  },
};
