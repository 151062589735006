export const removeWhitespaces = string => string.replace(/\s+/g, '');

export const pluralizeWord = (num, noun, plural) => {
  const pluralNoun = plural || `${noun}s`;
  return num === 1 ? noun : pluralNoun;
};

export const pluralizeCount = (num, noun, plural) => {
  const pluralNoun = pluralizeWord(num, noun, plural);
  return `${num} ${pluralNoun}`;
};

export const escapeForRegExp = text =>
  text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

export const isFqdn = text => {
  const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  return urlRegex.test(text);
};

export const hasProtocol = text => /^https?:\/\//.test(text);

export const linkWithProtocol = item => {
  if (!hasProtocol(item)) {
    return `https://${item}`;
  }
  return item;
};

export const PHRASES_REGEX_PATTERN = new RegExp(/"(.*?)"/g);

export const getPhrasesFromText = text => {
  return text.match(PHRASES_REGEX_PATTERN) || [];
};

export const getWordsListFromText = text => {
  const words = text
    .replace(PHRASES_REGEX_PATTERN, '')
    .replace(/\(|\)/g, '')
    .trim();
  return words ? words.split(/\s+/) : [];
};

export const phrasesKeywordsCounter = text => {
  const phrasesFound = getPhrasesFromText(text);
  const wordsList = getWordsListFromText(text);
  return phrasesFound.length + wordsList.length;
};

const HIDE_TEXT_MASK = '••••••••';
/*
  Utils to hide all text of a word less the first letter:
  Example:
  'Text to hide' ==> 'T••••••••'
*/
export function hideText(
  text,
  replaceWord = text.substring(1),
  textMask = HIDE_TEXT_MASK,
) {
  return text.replace(replaceWord, textMask);
}

/*
  This create an email mask hiding part of the email text:
    Example:
      someEmail@someText.de --> s••••••••@••••••••.de
      someEmail@someText.co.de --> s••••••••@••••••••.co.de
 */
export function createEmailMask(email) {
  const firstEmailPart = email.substring(0, email.indexOf('@'));
  const secondEmailPart = email.substring(email.indexOf('@') + 1);
  const replaceWord = secondEmailPart.substring(
    0,
    secondEmailPart.indexOf('.'),
  );

  return `${hideText(firstEmailPart)}@${hideText(
    secondEmailPart,
    replaceWord,
  )}`;
}

export function twitterMask(handler) {
  return handler ? handler.replace(/[a-zA-Z0-9\\_]/g, '*') : '';
}

const stringWithNumbersRegex = new RegExp('^[0-9]+$');
export const containsOnlyNumbers = (text = '') =>
  stringWithNumbersRegex.test(text);

export default {
  pluralizeCount,
  pluralizeWord,
  removeWhitespaces,
  hideText,
  createEmailMask,
  twitterMask,
  containsOnlyNumbers,
};
