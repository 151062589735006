import { NEW_DASHBOARD_ENDPOINT } from 'constants/apis';

import { campaignAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-campaign-reducer';
import { recentlyViewedAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-last-viewed-reducer';
import { sidebarAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-sidebar-reducer';

import { starredAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-starred-reducer';

import {
  AnalyticsListActionCreatorArgs,
  AnalyticsListResultsCollation,
} from '../../analytics-config';

import { processAnalyticResults } from './analytics-list-reducer';
import { updateDashboardsWithId } from './analytics-owned-reducer';

export const LIST_SHARED_ANALYTICS = 'analytics/LIST_SHARED_ANALYTICS';
export const LIST_SHARED_ANALYTICS_SUCCESS =
  'analytics/LIST_SHARED_ANALYTICS_SUCCESS';
export const LIST_SHARED_ANALYTICS_ERROR =
  'analytics/LIST_SHARED_ANALYTICS_ERROR';
export const SET_ANALYTIC_SHARING = 'analytics/SET_ANALYTIC_SHARING';
export const SET_ANALYTIC_SHARING_SUCCESS = 'analytics/SET_ANALYTIC_SUCCESS';
export const SET_ANALYTIC_SHARING_ERROR = 'analytics/SET_ANALYTIC_ERROR';

export const RESET = 'analytics/RESET_SHARED_ANALYTICS_STATE';

export const UPDATE_TITLE_SHARED_ANALYTICS =
  'analytics/UPDATE_TITLE_SHARED_ANALYTICS';

export const initialState = {
  error: false,
  loading: true,
  listFilters: {
    filtering: false,
    search: '',
  },
  items: [],
  paginationCount: null,
  ownerFilterAnalytics: [],
  resultsCollation: {
    pageNumber: 1,
    sort: 'lastUpdated',
    sortDirection: 'desc',
    ownerFilter: 'all',
  } as AnalyticsListResultsCollation,
};

const sharedAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SHARED_ANALYTICS:
      return {
        ...state,
        loading: true,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: null,
      };
    case LIST_SHARED_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        listFilters: {
          filtering: action.payload.filtering,
          search: action.payload.search,
        },
        error: null,
        items: action.payload.appendItems
          ? [...state.items, ...action.payload.items]
          : action.payload.items,
        paginationCount: action.payload.paginationCount,
        ownerFilterAnalytics: action.payload.isFilter
          ? state.ownerFilterAnalytics
          : action.payload.items,
        resultsCollation: action.payload.resultsCollation,
      };
    case LIST_SHARED_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        listFilters: {
          ...state.listFilters,
          filtering: false,
        },
        error: action.payload.error,
      };

    case RESET:
      return {
        ...initialState,
      };
    case UPDATE_TITLE_SHARED_ANALYTICS:
      return {
        ...state,
        items: updateDashboardsWithId(
          state.items,
          action.payload.dashboardId,
          action.payload.newTitle,
        ),
      };
    default:
      return state;
  }
};

export const resetSharedAnalyticsList = () => ({
  type: RESET,
});

export const sharedAnalyticsActionCreator = ({
  endpointBase = NEW_DASHBOARD_ENDPOINT,
  search = null,
  ownerId = null,
  isAlreadyFiltered = false,
  resultsCollation = initialState.resultsCollation,
  isInitialLoad = false,
  doCountReload = false,
  appendItems = false,
}: AnalyticsListActionCreatorArgs = {}) => async dispatch => {
  const params = {
    ...resultsCollation,
    owner: 'OTHER',
    search,
    ownerId,
  };
  const isFilter = !!search || !!ownerId;
  dispatch({ type: LIST_SHARED_ANALYTICS });

  try {
    const actionCreatorArgs = {
      endpointBase,
      isAlreadyFiltered: isFilter,
    };
    const { items, itemCount } = await processAnalyticResults({
      params,
      endpointBase,
    });

    dispatch({
      type: LIST_SHARED_ANALYTICS_SUCCESS,
      payload: {
        items: items,
        appendItems,
        paginationCount: itemCount,
        filtering: isAlreadyFiltered || isFilter,
        search,
        isFilter,
        resultsCollation,
      },
    });
    dispatch(
      sidebarAnalyticsActionCreator({
        endpointBase,
        doCountReload,
      }),
    );
    if (isInitialLoad) {
      dispatch(recentlyViewedAnalyticsActionCreator(actionCreatorArgs));
      dispatch(campaignAnalyticsActionCreator(actionCreatorArgs));
      dispatch(starredAnalyticsActionCreator(actionCreatorArgs));
    }
  } catch (e) {
    dispatch({
      type: LIST_SHARED_ANALYTICS_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export default sharedAnalyticReducer;
