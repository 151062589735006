import {
  RecipientItem,
  RecipientItemType,
} from './components/EmailCampaignRecipientList/EmailCampaignRecipientTypes';

declare global {
  interface Window {
    STATIC_BASEPATH: string;
  }
}

/* eslint-disable prefer-promise-reject-errors */
type EmailMockedResponse = Readonly<{
  data: any;
  status: number;
  statusText: string;
}>;

const attachmentEndpoint = /\/api\/outreach\/bulk\/drafts\/(.+)\/attachments$/g;
const createEmailDraftEndpoint = /\/api\/outreach\/bulk\/drafts$/g;
const createEmailDraftFromCampaignEndpoint = /\/api\/outreach\/bulk\/drafts\/campaign\/(.+)$/g;
const deleteGetUpdateEmailDraftEndpoint = /\/api\/outreach\/bulk\/drafts\/(.+)$/g;
const optOutAddressEndpoint = /\/api\/account\/settings$/g;
const sendEmailEndpoint = /\/api\/outreach\/bulk\/drafts\/(.+)\/send$/g;
const sendEmailPreviewEndpoint = /\/api\/outreach\/bulk\/drafts\/(.+)\/send\/test$/g;

export const getEmailDraftDemoData = {
  additionalRecipients: [] as any[],
  attachments: [] as any[],
  bcc: [] as any[],
  body: `
    <p>
      <strong>
        Introducing New Dashboards & Reports
      </strong>
    </p>

    <p>
      <strong><em>The latest innovation in Cision’s next generation Communications Cloud</em></strong>
    </p>

    <p>
      One in four communicators say their biggest challenge is effectively measuring the business impact of
      their PR programs.<sup>1</sup> Not anymore. With the launch of its powerful new Analytics Dashboards and Interactive Reports,
      Cision is providing communicators with an intuitive, adaptable, and clear way to showcase their data and tie earned
      media back to business outcomes.
    </p>

    <a href="https://comms.cision.com/dashboards-and-reports">Learn more here</a>

    <p>
      The Analytics Dashboards and Interactive Reports solution is the latest addition to Cision's Next Generation
      Communications Cloud, where communicators can quickly target and engagemedia as well as measure and share impact.
      Cision's over 7 million global sources can be transformed into actionable, executive-ready business intelligence.
      Share of voice, social engagement, search engine optimization impact, brand sentiment, and referrals from earned
      media to company websites are among key metrics that can be tracked.
    </p>

    <p>
      The new solution also incorporates Cision Impact data with validated audience reach and resonance metrics in one dashboard.
      Customers can opt to take metrics to the next level, capturing not only who saw their news coverage but what those viewers
      did next to connect actions to revenue.
    </p>

    <p>
      &ldquo;In a year of unprecedented events and economic challenges, the pressure to prove the ROI of earned media efforts is intensifying&ldquo;
      said Abel Clark, CEO of Cision. &ldquo;Working closely with our customers, Cision is solving one of the biggest problems PR professionals
      face with a faster and simpler way to gather business impact data and demonstrate the value they deliver to their organizations.&ldquo;
    </p>

    <p>Analytics Dashboards and Interactive Reports include:</p>

    <img src="${window.STATIC_BASEPATH}/images/email-announcements-demo-image-1.png" style="width:500px" />

    <img src="${window.STATIC_BASEPATH}/images/email-announcements-demo-image-2.png" style="width:500px" />

    <ul>
      <li>
        <p>
          <strong>Visually-engaging analytics:</strong> Fully-customizable widgets make it easy to create compelling visuals of earned media
          value and uncover new insights with competitive coverage data.
        </p>
      </li>
      <li>
        <p>
          <strong>Reporting priorities at one's fingertips:</strong> Communicators can quickly build sleek, interactive reports right from the
          dashboards and share them with their business executives and teams.
        </p>
      </li>
      <li>
      <p>
        <strong>Mobile and tablet-friendly layouts:</strong> Communicators can access earned media intelligence anytime and anywhere.
      </p>
      </li>
    </ul>

    <p>
      To learn more about Analytics Dashboards and Interactive Reports, <a href="https://c212.net/c/link/?t=0&l=en&o=2987102-1&h=3782176437&u=https%3A%2F%2Fwww.cision.com%2Fus%2Fresources%2Fwebinars-events%2Fcomms-lessons-from-black-friday-cyber-monday%2F&a=%2C+register+here+for+Cision%27s+webinar+on+December+1st.">register here for Cision's webinar on December 1st</a>. The solution is
      now available in the United States, United Kingdom, and Canada.
    </p>

    <p>
      <sup>1</sup> Source: <em>Cision and PRWeek's 2020 Comms Report</em>
    </p>

    <p>
      <strong>About Cision</strong> <br />
      Cision is a leading global provider of earned media software and wire distribution services to public relations and marketing communications
      professionals. Cision's solutions allow users to identify key influencers, craft and distribute strategic content, and measure meaningful impact.
      Cision has offices in 24 countries throughout the Americas, EMEA, and APAC. For more information about Cision's award-winning products and services,
      including the Cision Communications Cloud®, visit <a href="https://www.cision.com">www.cision.com</a> and follow Cision on Twitter @Cision.
    </p>
  `,
  cc: [] as any[],
  contactMergeFields: [] as any[],
  emailType: {
    enumType: 'com.trendkite.outreach.client.model.EmailType',
    name: 'BULKCAMPAIGN',
  },
  from: 'press@cision.com',
  id: 'mock-document-id-123',
  senderName: [] as any,
  optOutSettings: null,
  outreachId: 1234,
  replyTo: 'John Smith <John.Smith@cision.com>' as string | any[],
  subject:
    'Demonstrate Real Business Impact with Cision’s New Analytics Dashboards and Interactive Reports',
  to: [] as any[],
  translations: [] as any[],
  campaignIds: [] as any[],
  campaigns: [] as any[],
  sendAt: null as any,
  timezone: null as any,
  ianaTimezone: null as any,
  title: null as any,
  linkTrackingEnabled: null as any,
};

export const mockVerifiedEmails = [
  {
    emailAddress: 'press@cision.com',
    type: 'INTEGRATION',
    verified: true,
  },
];

const getOptOutAddressDemoData = {
  id: 42,
  json_object: {
    addressLine1: '130 E Randolph St',
    addressLine2: '7th Floor',
    city: 'Chicago',
    companyName: 'Cision, Ltd.',
    country: 'United States',
    state: 'IL',
    zipCode: '60601',
  },
};

const attachmentDemoData = {
  ...getEmailDraftDemoData,
  attachments: [
    {
      contentDisposition: 'attachment',
      contentId: null,
      contentType: 'image/png',
      fileId: '865a7fa9-78aa-4f19-bf0b-888fa6222635',
      filename: '256.png',
      id: 1,
      size: 585,
    },
  ],
};

const influencerList = {
  listId: 'qAMdK3cBOAygtWjvFWTV',
  listName: 'List of Awesome People',
  owner: 'David Leon',
  hasAccess: false,
  influencersCount: 3,
  isDeleted: false,
};

export const mockedRecipientItems: RecipientItem[] = [
  {
    id: '0001',
    type: RecipientItemType.List,
    recipient: undefined,
    list: influencerList,
  },
  {
    id: '0002',
    type: RecipientItemType.Recipient,
    recipient: {
      avatar: null,
      email: 'james.gibson@gmail.com',
      firstName: 'James',
      fullName: null,
      influencerId: 'contact_1',
      lastName: 'Gibson',
      salutation: 'Mr.',
    },
  },
  {
    id: '0003',
    type: RecipientItemType.Recipient,
    recipient: {
      avatar: null,
      email: 'dshirley@outlook.com',
      firstName: 'Debra',
      fullName: 'Debra Shirley',
      influencerId: 'contact_2',
      lastName: 'Shirley',
      salutation: 'Ms.',
    },
  },
  {
    id: '0004',
    type: RecipientItemType.Recipient,
    recipient: {
      avatar: null,
      email: 'gina.harding@gmail.com',
      firstName: 'Gina',
      fullName: 'Gina Harding',
      influencerId: 'contact_3',
      lastName: 'Harding',
      salutation: 'Ms.',
    },
  },
  {
    id: '0005',
    type: RecipientItemType.Recipient,
    recipient: {
      avatar: null,
      email: 'lorrainesteck@gmail.com',
      firstName: 'Lorraine',
      fullName: 'Lorraine Steck',
      influencerId: 'contact_4',
      lastName: 'Steck',
      salutation: 'Dr.',
    },
  },
];

const getMockedRecipientsTotal = () => {
  let sum = 0;
  for (let i = 0; i < mockedRecipientItems.length; i++) {
    mockedRecipientItems[i].type === 'List'
      ? (sum += mockedRecipientItems[i].list?.influencersCount ?? 0)
      : (sum += 1);
  }
  return sum;
};

export const mockedRecipientItemsTotal = mockedRecipientItems.length;
export const mockedRecipientsTotal = getMockedRecipientsTotal();

const createResponse = (
  data: any,
  status = 200,
  statusText = 'OK',
): EmailMockedResponse => ({
  data,
  status,
  statusText,
});

export const performMockDelete = async (
  path,
  data: any = {},
): Promise<EmailMockedResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (path.match(deleteGetUpdateEmailDraftEndpoint)) {
        resolve(createResponse({}));
        return;
      }

      reject({
        response: createResponse({}, 404, 'Not Found'),
      });
    }, 250);
  });
};

export const performMockGet = async (
  path,
  params: any = {},
): Promise<EmailMockedResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (path.match(optOutAddressEndpoint)) {
        if (params && params.name && params.name === 'OptOutAddress') {
          resolve(createResponse(getOptOutAddressDemoData));
          return;
        }

        resolve(createResponse({}));
        return;
      } else if (path.match(deleteGetUpdateEmailDraftEndpoint)) {
        resolve(createResponse(getEmailDraftDemoData));
        return;
      }

      reject({
        response: createResponse({}, 404, 'Not Found'),
      });
    }, 250);
  });
};

export const performMockPatch = async (
  path,
  data: any = {},
): Promise<EmailMockedResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (path.match(deleteGetUpdateEmailDraftEndpoint)) {
        resolve(createResponse({}));
        return;
      }

      reject({
        response: createResponse({}, 404, 'Not Found'),
      });
    }, 250);
  });
};

export const performMockPost = async (
  path,
  data: any = {},
): Promise<EmailMockedResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (path.match(attachmentEndpoint)) {
        resolve(createResponse(attachmentDemoData));
        return;
      } else if (
        path.match(createEmailDraftEndpoint) ||
        path.match(createEmailDraftFromCampaignEndpoint)
      ) {
        resolve(createResponse(getEmailDraftDemoData));
        return;
      } else if (path.match(optOutAddressEndpoint)) {
        if (data && data.name && data.name === 'OptOutAddress') {
          resolve(
            createResponse({
              ...data.json_object,
              id: 42,
            }),
          );
          return;
        }

        resolve(createResponse({}));
        return;
      } else if (path.match(sendEmailEndpoint)) {
        resolve(createResponse({}));
        return;
      } else if (path.match(sendEmailPreviewEndpoint)) {
        resolve(createResponse({}));
        return;
      }

      reject({
        response: createResponse({}, 404, 'Not Found'),
      });
    }, 250);
  });
};

export const performMockPut = async (
  path,
  data: any = {},
): Promise<EmailMockedResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (path.match(optOutAddressEndpoint)) {
        resolve(createResponse({}));
        return;
      }

      reject({
        response: createResponse({}, 404, 'Not Found'),
      });
    }, 250);
  });
};
