import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Addon,
  Button as TkUiButton,
  Dropdown,
  DropdownMenu,
  FloatingPanel,
  FormGroup,
  FormLabel,
  Modal,
  Select,
  SvgIcon,
  TextArea,
  Tooltip,
  Type,
} from '@trendkite/ui';
import SlackIntegrationListEntry from 'components/article/SlackIntegrationListEntry';
import { DEV_FEATURES } from 'constants/constants';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';

import {
  deleteSlackIntegrationsActionCreator,
  slackShareActionCreator,
  toggleSlackModalActionCreator,
} from 'reducers/slack';
import { hasDevFeatureFlagSelector } from 'selectors/account';
import { slackIntegrationsSelector } from 'selectors/slack';

import slackIntegrationPropTypes from 'types/slack-integration';
import { getSlackAuthUrl } from 'utils/slack';

import messages from './slack.messages';

const ADD_NEW_SLACK_INTEGRATION_ID = 'NEW_SLACK';

const getSlackIntegrationOptions = ({
  slackIntegrations,
  hasSlackV2AuthFeatureFlag,
  intl,
}) => {
  const addNewOption = {
    id: ADD_NEW_SLACK_INTEGRATION_ID,
    label: intl.formatMessage(messages.addNewChannel),
    isAction: true,
  };

  if (!slackIntegrations) {
    return [addNewOption];
  }

  const integrationOptions = slackIntegrations
    .map(integration => {
      return {
        id: integration.webhookChannelId,
        label: integration.webhookChannel,
        subtitle:
          integration.needsReauth && hasSlackV2AuthFeatureFlag ? (
            <Type color="danger">
              <TranslatedMessage {...messages.needsReauth} />
            </Type>
          ) : null,
        needsReauth: integration.needsReauth,
        isAction: false,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  integrationOptions.push(addNewOption);

  return uniqBy(integrationOptions, 'id');
};

const mapStateToProps = state => ({
  slackIntegrations: slackIntegrationsSelector(state),
  isOpen: state.slack.slackModalOpen,
  shareUrl: state.slack.shareUrl,
  articleId: state.slack.articleId,
  articleTypeLabel: state.slack.articleTypeLabel,
  hasSlackV2AuthFeatureFlag: hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.slackV2Auth,
  ),
});

const mapDispatchToProps = dispatch => ({
  deleteSlackIntegrations: () =>
    dispatch(deleteSlackIntegrationsActionCreator()),
  slackShare: (articleId, comment, shareUrl, webhookChannelId, intl) =>
    dispatch(
      slackShareActionCreator(
        articleId,
        comment,
        shareUrl,
        webhookChannelId,
        intl,
      ),
    ),
  toggle: () => dispatch(toggleSlackModalActionCreator()),
});

class SlackShareArticleModal extends Component {
  state = {
    slackIntegrationOptions: getSlackIntegrationOptions(this.props),
    currentSlackIntegration: null,
    comment: '',
    optionsMenuVisible: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    const propsToCheck = ['slackIntegrations'];

    if (
      !isEqual(pick(this.props, propsToCheck), pick(nextProps, propsToCheck))
    ) {
      this.setState({
        slackIntegrationOptions: getSlackIntegrationOptions(nextProps),
      });
    }
  }

  onChangeSlackComment = e => {
    this.setState({
      comment: e,
    });
  };

  onChangeSlackIntegration = slackIntegration => {
    if (!slackIntegration || !slackIntegration.length) {
      return;
    }
    if (
      slackIntegration[0].id === ADD_NEW_SLACK_INTEGRATION_ID ||
      (slackIntegration[0].needsReauth && this.props.hasSlackV2AuthFeatureFlag)
    ) {
      window.location = this.getSlackAuthAndShareUrl();
    }

    this.setState({
      currentSlackIntegration: slackIntegration[0],
    });
  };

  getSlackAuthAndShareUrl = () => {
    // The callback state will tell our server to initiate sharing after auth is complete.
    // In the future, we could add "auth-only" buttons, but for now, we only share articles
    const callbackState = {
      action: 'share',
      payload: {
        url: window.location.href,
        shareUrl: this.props.shareUrl || window.location.href,
        articleId: this.props.articleId,
        articleTypeLabel: this.props.articleTypeLabel,
      },
      // Slack says to include a random number to insure the request originated with us
      // but the verification isn't implemented yet.
      nonce: uuidv4(),
    };

    return getSlackAuthUrl(window.location.href, callbackState);
  };

  handleOptionsMenuToggle = () => {
    this.setState({
      optionsMenuVisible: !this.state.optionsMenuVisible,
    });
  };

  slackShareToChannel = () => {
    const { articleId, slackShare, intl } = this.props;

    const { comment, currentSlackIntegration } = this.state;

    if (!currentSlackIntegration || !currentSlackIntegration.id) {
      return;
    }
    slackShare(
      articleId,
      comment,
      window.location.href,
      currentSlackIntegration.id,
      intl,
    );
  };

  deleteSlackIntegrations = () => {
    this.props.deleteSlackIntegrations();
    this.setState({ optionsMenuVisible: false });
    this.props.toggle();
  };

  render() {
    const {
      articleTypeLabel,
      isOpen,
      slackIntegrations,
      toggle,
      intl,
    } = this.props;

    const {
      comment,
      currentSlackIntegration,
      optionsMenuVisible,
      slackIntegrationOptions,
    } = this.state;

    return (
      <Modal
        toggle={toggle}
        isOpen={isOpen}
        wrapClassName="article-slack-share-modal"
        size="sm"
      >
        <Modal.Header toggle={toggle}>
          <div className="modal-titleText">
            <TranslatedMessage
              {...messages.title}
              values={{ ARTICLE_TYPE: articleTypeLabel }}
            />
          </div>
          <div className="article-slack-share-modal__options-menu">
            <Dropdown
              isOpen={optionsMenuVisible}
              toggle={this.handleOptionsMenuToggle}
            >
              <Tooltip
                content={intl.formatMessage(messages.options)}
                key="optionsMenuToggle"
              >
                <TkUiButton
                  data-qa="N59UwrvjdSU4tgVce32Vr"
                  modifiers={['flex', 'seethrough', 'small']}
                  onClick={this.handleOptionsMenuToggle}
                >
                  <Addon>
                    <SvgIcon height={18} icon="ellipsis" width={18} />
                  </Addon>
                </TkUiButton>
              </Tooltip>
              <DropdownMenu isOpen={optionsMenuVisible} position="bottomLeft">
                <FloatingPanel>
                  <TkUiButton
                    data-qa="lk3ZxR9pxurb3NiVr79Yq"
                    disabled={!slackIntegrations.length}
                    modifiers={['listEntry']}
                    onClick={this.deleteSlackIntegrations}
                  >
                    <TranslatedMessage {...messages.deauthorizeAll} />
                  </TkUiButton>
                </FloatingPanel>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Modal.Header>
        <Modal.Body className="text-justify--left article-slack-share-modal__body">
          <div className="article-slack-share-modal__description">
            <TranslatedMessage {...messages.description} />
          </div>
          {slackIntegrationOptions && !!slackIntegrationOptions.length && (
            <div>
              <FormLabel htmlFor="slackIntegrations">
                <TranslatedMessage {...messages.shareTo} />
              </FormLabel>
              <Select
                EntryComponent={SlackIntegrationListEntry}
                id="slackIntegrations"
                omitRadio
                onChange={this.onChangeSlackIntegration}
                options={slackIntegrationOptions}
                placeholder={intl.formatMessage(messages.selectChannel)}
              />
              <br />

              <FormGroup>
                <FormLabel htmlFor="slackComment">
                  <TranslatedMessage {...messages.addComments} />
                </FormLabel>
                <TextArea
                  id="slackComment"
                  onChange={this.onChangeSlackComment}
                  placeholder={intl.formatMessage(messages.enterText)}
                  resize="vertical"
                  value={comment}
                />
              </FormGroup>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="article-slack-share-modal__footer">
          <Modal.Action>
            <div className="article-slack-share-modal__confirm">
              <TkUiButton
                data-qa="iJIHNiutu44ptH0Inrbf7"
                disabled={!currentSlackIntegration}
                onClick={this.slackShareToChannel}
                modifiers={['round', 'primary', 'large', 'block']}
              >
                <TranslatedMessage {...globalMessages.share} />
              </TkUiButton>
            </div>
          </Modal.Action>
        </Modal.Footer>
      </Modal>
    );
  }
}

SlackShareArticleModal.propTypes = {
  articleId: PropTypes.string.isRequired,
  articleTypeLabel: PropTypes.string.isRequired,
  deleteSlackIntegrations: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  slackIntegrations: PropTypes.arrayOf(slackIntegrationPropTypes),
  slackShare: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  shareUrl: PropTypes.string,
  hasSlackV2AuthFeatureFlag: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

SlackShareArticleModal.defaultProps = {
  slackIntegrations: [],
  shareUrl: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SlackShareArticleModal));
