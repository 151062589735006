import moment, { MomentInput, Moment } from 'moment';

import {
  DATE_RANGE_COMPARE_TYPES,
  DATE_RANGE_OPTIONS_WITH_UNDEFINED_DAYS,
  GRANULARITY_TYPES,
} from 'components/widget/constants';
import { TIME_UNITS, UTC_DATE_FORMAT_INTL } from 'constants/constants';

import { msPerSec, msPerMinute, msPerHour, msPerDay } from '../times/times';

import messages from './date-util.messages';

const MILLISECONDS_IN_MINUTE = 60000;
export const msPerMonth = msPerDay * 30;

/**
 * these ranges were taken from moment.js, we want a similar behavior
 * https://momentjs.com/docs/#/customization/relative-time-threshold/
 */
export const relativeTimeThreshold = {
  [TIME_UNITS.second]: 45,
  [TIME_UNITS.minute]: 45,
  [TIME_UNITS.hour]: 22,
  [TIME_UNITS.day]: 26,
  [TIME_UNITS.month]: 11,
};

type Timestamps = {
  startTime: number;
  endTime: number;
  previousEnd: number;
  previousStart: number;
};
type DateRange = {
  label: string;
  labelTranslationKey: string;
  shortLabel: string;
  shortLabelTranslationKey: string;
  trailing_days?: number;
};

type DateRangeOption = DateRange & {
  data: {
    key: string;
    shortLabel: string;
  };
};

export type DateRangeValues = {
  endDate: Moment;
  startDate: Moment;
  previousEnd: Moment;
  previousStart: Moment;
};

export const DATE_RANGES = {
  SINCE_LAUNCH: {
    label: 'Since Published',
    labelTranslationKey: 'sinceLaunchLabel',
    shortLabel: 'Since Published',
    shortLabelTranslationKey: 'sinceLaunchShortLabel',
  },
  SINCE_YESTERDAY: {
    label: 'Since Yesterday',
    labelTranslationKey: 'sinceYesterdayLabel',
    shortLabel: 'Since Yesterday',
    shortLabelTranslationKey: 'sinceYesterdayShortLabel',
  },
  TRAILING_7: {
    label: 'Trailing 7 Days',
    labelTranslationKey: 'trailing7Label',
    shortLabel: '7 Days',
    shortLabelTranslationKey: 'trailing7ShortLabel',
    trailing_days: 7,
  },
  TRAILING_14: {
    label: 'Trailing 14 Days',
    labelTranslationKey: 'trailing14Label',
    shortLabel: '14 Days',
    shortLabelTranslationKey: 'trailing14ShortLabel',
    trailing_days: 14,
  },
  TRAILING_30: {
    label: 'Trailing 30 Days',
    labelTranslationKey: 'trailing30Label',
    shortLabel: '30 Days',
    shortLabelTranslationKey: 'trailing30ShortLabel',
    trailing_days: 30,
  },
  TRAILING_90: {
    label: 'Trailing 90 Days',
    labelTranslationKey: 'trailing90Label',
    shortLabel: '90 Days',
    shortLabelTranslationKey: 'trailing90ShortLabel',
    trailing_days: 90,
  },
  WEEK_TO_DATE: {
    label: 'Week to Date',
    labelTranslationKey: 'weekToDateLabel',
    shortLabel: 'Week',
    shortLabelTranslationKey: 'weekToDateShortLabel',
  },
  MONTH_TO_DATE: {
    label: 'Month to Date',
    labelTranslationKey: 'monthToDateLabel',
    shortLabel: 'Month',
    shortLabelTranslationKey: 'monthToDateShortLabel',
  },
  QUARTER_TO_DATE: {
    label: 'Quarter to Date',
    labelTranslationKey: 'quarterToDateLabel',
    shortLabel: 'Quarter',
    shortLabelTranslationKey: 'quarterToDateShortLabel',
  },
  YEAR_TO_DATE: {
    label: 'Year to Date',
    labelTranslationKey: 'yearToDateLabel',
    shortLabel: 'Year',
    shortLabelTranslationKey: 'yearToDateShortLabel',
  },
  LAST_WEEK: {
    label: 'Last week',
    labelTranslationKey: 'lastWeekLabel',
    shortLabel: 'Week',
    shortLabelTranslationKey: 'lastWeekShortLabel',
  },
  LAST_MONTH: {
    label: 'Last Month',
    labelTranslationKey: 'lastMonthLabel',
    shortLabel: 'Month',
    shortLabelTranslationKey: 'lastMonthShortLabel',
  },
  LAST_QUARTER: {
    label: 'Last Quarter',
    labelTranslationKey: 'lastQuarterLabel',
    shortLabel: 'Quarter',
    shortLabelTranslationKey: 'lastQuarterShortLabel',
  },
  LAST_YEAR: {
    label: 'Last Year',
    labelTranslationKey: 'lastYearLabel',
    shortLabel: 'Year',
    shortLabelTranslationKey: 'lastYearShortLabel',
  },
  CUSTOM: {
    label: 'Custom',
    labelTranslationKey: 'customLabel',
    shortLabel: 'Period',
    shortLabelTranslationKey: 'customShortLabel',
  },
};

export type DateRangeKey = keyof typeof DATE_RANGES;

type DateRangeKeys = {
  [key in DateRangeKey]: key;
};

type scaleUnit = {
  scale: string;
  value: number;
};

export const DATE_RANGE_KEYS = Object.keys(DATE_RANGES).reduce(
  (result, rangeKey) => {
    result[rangeKey] = rangeKey;
    return result;
  },
  {} as DateRangeKeys,
);

type DateRangeOptions = {
  [key in DateRangeKey]: DateRangeOption;
};

export const DATE_RANGE_OPTIONS = Object.keys(DATE_RANGES).reduce(
  (result, key) => {
    const range = DATE_RANGES[key];

    result[key] = {
      ...range,
      data: {
        key,
        shortLabel: range.shortLabel,
      },
    };

    return result;
  },
  {} as DateRangeOptions,
);

const isValidDateString = (string, format) =>
  moment.utc(string, format, true).isValid();

export const convertDateStringToDate = (string, format) => {
  if (!isValidDateString(string, format)) {
    return null;
  }

  return moment.utc(string, format, true).toDate();
};

export const isValidDateRange = (startDate, endDate) =>
  startDate !== undefined && endDate !== undefined && endDate - startDate >= 0;

export const getCurrentRange = (
  rangeType: string,
  options = DATE_RANGE_OPTIONS,
): DateRangeOption | Record<string, unknown> => {
  return options[rangeType] || {};
};

export const getDatesForRange = (
  range: keyof DateRangeKeys,
  forcedStartDate?: MomentInput,
  forcedEndDate?: MomentInput,
): DateRangeValues => {
  // forcedStartDate and forcedEndDate are optional. If present, they'll do the ordinary range logic and then overwrite the start or end.
  let endDate = moment.utc().endOf('day');
  let startDate = moment.utc(endDate).subtract(7, 'days').startOf('day');
  let previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
  let previousStart = moment
    .utc(previousEnd)
    .subtract(7, 'days')
    .startOf('day');
  switch (range) {
    case DATE_RANGE_KEYS.SINCE_YESTERDAY:
      startDate = moment.utc(endDate).subtract(1, 'days').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment
        .utc(previousEnd)
        .subtract(1, 'days')
        .startOf('day');
      break;
    case DATE_RANGE_KEYS.TRAILING_7:
      startDate = moment.utc(endDate).subtract(7, 'days').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment
        .utc(previousEnd)
        .subtract(7, 'days')
        .startOf('day');
      break;
    case DATE_RANGE_KEYS.TRAILING_14:
      startDate = moment.utc(endDate).subtract(14, 'days').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment
        .utc(previousEnd)
        .subtract(14, 'days')
        .startOf('day');
      break;
    case DATE_RANGE_KEYS.TRAILING_30:
      startDate = moment.utc(endDate).subtract(30, 'days').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment
        .utc(previousEnd)
        .subtract(30, 'days')
        .startOf('day');
      break;
    case DATE_RANGE_KEYS.WEEK_TO_DATE:
      startDate = moment.utc(endDate).startOf('isoWeek').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('isoWeek').startOf('day');
      break;
    case DATE_RANGE_KEYS.MONTH_TO_DATE:
      startDate = moment.utc(endDate).startOf('month').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('month').startOf('day');
      break;
    case DATE_RANGE_KEYS.QUARTER_TO_DATE:
      startDate = moment.utc(endDate).startOf('quarter').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('quarter').startOf('day');
      break;
    case DATE_RANGE_KEYS.YEAR_TO_DATE:
      startDate = moment.utc(endDate).startOf('year').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('year').startOf('day');
      break;
    case DATE_RANGE_KEYS.LAST_WEEK: {
      const dayLastWeek = moment.utc().subtract(7, 'days').startOf('day');
      endDate = moment.utc(dayLastWeek).endOf('week');
      startDate = moment.utc(dayLastWeek).startOf('week');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('week');
      break;
    }
    case DATE_RANGE_KEYS.LAST_MONTH: {
      const dayLastMonth = moment.utc().subtract(1, 'months').startOf('day');
      endDate = moment.utc(dayLastMonth).endOf('month');
      startDate = moment.utc(dayLastMonth).startOf('month');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('month');
      break;
    }
    case DATE_RANGE_KEYS.LAST_QUARTER: {
      const dayLastQuarter = moment
        .utc()
        .subtract(1, 'quarters')
        .startOf('day');
      endDate = moment.utc(dayLastQuarter).endOf('quarter');
      startDate = moment.utc(dayLastQuarter).startOf('quarter');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('quarter');
      break;
    }
    case DATE_RANGE_KEYS.LAST_YEAR: {
      const dayLastYear = moment.utc().subtract(1, 'years').startOf('day');
      endDate = moment.utc(dayLastYear).endOf('year');
      startDate = moment.utc(dayLastYear).startOf('year');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment.utc(previousEnd).startOf('year');
      break;
    }
    case DATE_RANGE_KEYS.TRAILING_90:
      startDate = moment.utc(endDate).subtract(90, 'days').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment
        .utc(previousEnd)
        .subtract(90, 'days')
        .startOf('day');
      break;
    default:
      startDate = moment.utc(endDate).subtract(90, 'days').startOf('day');
      previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
      previousStart = moment
        .utc(previousEnd)
        .subtract(90, 'days')
        .startOf('day');
      break;
  }

  if (forcedStartDate || forcedStartDate === 0) {
    startDate = moment.utc(forcedStartDate);
  }

  if (forcedEndDate || forcedEndDate === 0) {
    endDate = moment.utc(forcedEndDate);
  }

  return {
    endDate,
    startDate,
    previousEnd,
    previousStart,
  };
};

const dateIsToday = date => !date || moment.utc(date).isSame(new Date(), 'day');

const getEndDateForTwitter = (endDate?: MomentInput) => {
  // Prevent going to future end of today, which causes Twitter api error
  return dateIsToday(endDate)
    ? moment.utc().subtract(10, 'seconds')
    : moment.utc(endDate);
};

export const getDatesRangeForSocialSearch = (
  range: keyof DateRangeKeys,
  forcedStartDate?: MomentInput,
  forcedEndDate?: MomentInput,
): DateRangeValues => {
  const endDate = getEndDateForTwitter(forcedEndDate);
  return getDatesForRange(range, forcedStartDate, endDate);
};

export const convertUtcTimestampToLocale = utcDate =>
  utcDate - moment().utcOffset() * MILLISECONDS_IN_MINUTE;

export const getCustomDatesForRange = customDate => {
  const { customStartDate, customEndDate } = customDate;
  const dayDifference = moment(customEndDate).diff(
    moment(customStartDate),
    'days',
  );
  const endDate = moment.utc(customEndDate);
  const startDate = moment
    .utc(endDate)
    .subtract(dayDifference, 'days')
    .startOf('day');
  const previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
  const previousStart = moment
    .utc(previousEnd)
    .subtract(dayDifference, 'days')
    .startOf('day');

  return {
    endDate,
    startDate,
    previousEnd,
    previousStart,
  };
};

export const getTimestampsForCustomRange = (startDate, endDate) => {
  if (isValidDateRange(startDate, endDate)) {
    const difference = moment.utc(endDate).diff(startDate, 'days');
    const previousEnd = moment.utc(startDate).subtract(1, 'days').endOf('day');
    const previousStart = moment
      .utc(previousEnd)
      .subtract(difference, 'days')
      .startOf('day');
    return {
      endTime: endDate.valueOf(),
      startTime: startDate.valueOf(),
      previousEnd: previousEnd.valueOf(),
      previousStart: previousStart.valueOf(),
    };
  }
  return null;
};

export const getTimestampsForRange = (
  range,
  forcedStartDate?,
  forcedEndDate?,
) => {
  const dates = getDatesForRange(range, forcedStartDate, forcedEndDate);
  return {
    endTime: dates.endDate.valueOf(),
    startTime: dates.startDate.valueOf(),
    previousEnd: dates.previousEnd.valueOf(),
    previousStart: dates.previousStart.valueOf(),
  };
};

export const getTimestampsForAnyRange = (rangeType, start?, end?) => {
  if (rangeType === DATE_RANGE_KEYS.CUSTOM && start && end) {
    return getTimestampsForCustomRange(start, end);
  }

  return getTimestampsForRange(rangeType);
};

export const getStartOfDay = date => {
  return moment.utc(date).startOf('day').valueOf();
};

export const getOneSecondBeforeMidnight = date => {
  return moment
    .utc(date)
    .startOf('day')
    .add(23, 'hours')
    .add(59, 'minutes')
    .add(59, 'seconds')
    .valueOf();
};

export const getTimestampsForAnySocialRange = (rangeType, start?, end?) => {
  const endDate = getEndDateForTwitter(end);
  if (rangeType === DATE_RANGE_KEYS.CUSTOM && start && end) {
    return getTimestampsForCustomRange(start, end);
  }
  return getTimestampsForRange(rangeType, start, endDate);
};

export const getISODatesForRange = (
  range,
  forcedStartDate?,
  forcedEndDate?,
) => {
  const dates = getDatesForRange(range, forcedStartDate, forcedEndDate);
  return {
    endTime: dates.endDate.toISOString(),
    startTime: dates.startDate.toISOString(),
  };
};

export const getISODatesForCustomRange = (startDate, endDate) => {
  if (isValidDateRange(startDate, endDate)) {
    return {
      endTime: endDate.toISOString(),
      startTime: startDate.toISOString(),
    };
  }
  return null;
};

export const convertDateStringToRangeTimeStamps = (date, granularityType) => {
  if (!moment.utc(date).isValid()) {
    return null;
  }
  const endDate = moment.utc(date).endOf('day');

  if (granularityType === GRANULARITY_TYPES.WEEK) {
    endDate.add(6, 'days');
  } else if (granularityType === GRANULARITY_TYPES.MONTH) {
    endDate.add(1, 'months').subtract('1', 'days');
  } else if (granularityType === GRANULARITY_TYPES.YEAR) {
    endDate.add(1, 'years').subtract('1', 'days');
  }

  return {
    startDate: moment.utc(date).startOf('day').valueOf(),
    endDate: endDate.valueOf(),
  };
};

export const buildDateRangeCompareTimestamps = (
  dateRangeCompareType,
  dateRange,
  customCompareStartDate,
  customCompareEndDate,
) => {
  const { startDate, endDate, type } = dateRange || {};
  const { startTime, endTime, previousStart } =
    getTimestampsForAnyRange(type, startDate, endDate) || {};
  let compareStartDate;
  let compareEndDate;

  if (dateRangeCompareType === DATE_RANGE_COMPARE_TYPES.customStartDate) {
    if (DATE_RANGE_OPTIONS_WITH_UNDEFINED_DAYS?.includes(type)) {
      const difference = moment.utc(endTime).diff(startTime, 'days');
      const previousEnd = moment
        .utc(previousStart)
        .add(difference, 'days')
        .valueOf();
      compareStartDate = previousStart;
      compareEndDate = previousEnd;
    } else {
      compareStartDate = customCompareStartDate;
      compareEndDate = customCompareEndDate;
    }
  } else if (dateRangeCompareType === DATE_RANGE_COMPARE_TYPES.previousYear) {
    compareStartDate = moment.utc(startTime).subtract(1, 'year').valueOf();
    compareEndDate = moment.utc(endTime).subtract(1, 'year').valueOf();
  } else if (dateRangeCompareType === DATE_RANGE_COMPARE_TYPES.previousPeriod) {
    const compareTimes = getTimestampsForAnyRange(type, startTime, endTime);
    compareStartDate = compareTimes?.previousStart;
    compareEndDate = compareTimes?.previousEnd;
  }

  return { compareStartDate, compareEndDate };
};

export function getLast30DaysRange(): Timestamps | null {
  return getTimestampsForAnyRange(DATE_RANGE_OPTIONS.TRAILING_30.data.key);
}

/**
 * why do we need our own implementation?
 * https://github.com/tc39/proposal-intl-relative-time#intlrelativetimeformatprototypeformatvalue-unit
 */
export function getBestScaleUnit(startDate, endDate): scaleUnit | undefined {
  const ranges = [
    {
      scale: TIME_UNITS.second,
      maxMiliSec: relativeTimeThreshold[TIME_UNITS.second] * msPerSec,
      convert: value => moment.duration(value).asSeconds(),
    },
    {
      scale: TIME_UNITS.minute,
      maxMiliSec: relativeTimeThreshold[TIME_UNITS.minute] * msPerMinute,
      convert: value => moment.duration(value).asMinutes(),
    },
    {
      scale: TIME_UNITS.hour,
      maxMiliSec: relativeTimeThreshold[TIME_UNITS.hour] * msPerHour,
      convert: value => moment.duration(value).asHours(),
    },
    {
      scale: TIME_UNITS.day,
      maxMiliSec: relativeTimeThreshold[TIME_UNITS.day] * msPerDay,
      convert: value => moment.duration(value).asDays(),
    },
    {
      scale: TIME_UNITS.month,
      maxMiliSec: relativeTimeThreshold[TIME_UNITS.month] * msPerMonth,
      convert: value => moment.duration(value).asMonths(),
    },
    {
      scale: TIME_UNITS.year,
      maxMiliSec: Number.MAX_SAFE_INTEGER,
      convert: value => moment.duration(value).asYears(),
    },
  ];
  if (!isValidDateRange(startDate, endDate)) {
    return;
  }
  const difference = moment.utc(endDate).diff(startDate);
  const bestScale = ranges.find(({ maxMiliSec }) => difference < maxMiliSec);
  if (!bestScale) {
    return;
  }

  return {
    scale: bestScale.scale,
    value: Math.round(bestScale.convert(difference)),
  };
}

/**
 * All the calculations are made using second like the unit to represent the difference between the two dates
 */
export const getLastEdited = (lastEditedDate, intl) => {
  if (!lastEditedDate) {
    return '';
  }
  const currentDateTime = moment.utc();
  lastEditedDate = moment.utc(lastEditedDate);
  const oneMinute = 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  const elapsedSeconds = currentDateTime.diff(lastEditedDate, 'seconds');
  let lastEdited;
  if (elapsedSeconds < 30) {
    lastEdited = intl.formatMessage(messages.justNow);
  } else if (elapsedSeconds < oneMinute) {
    lastEdited = intl.formatMessage(messages.secondsAgo, {
      SECONDS: elapsedSeconds,
    });
  } else if (elapsedSeconds < oneHour) {
    lastEdited = intl.formatMessage(messages.minutesAgo, {
      MINUTES: (elapsedSeconds / oneMinute) | 0,
    });
  } else if (elapsedSeconds < oneDay) {
    lastEdited = intl.formatMessage(messages.hoursAgo, {
      HOURS: (elapsedSeconds / oneHour) | 0,
    });
  } else if (elapsedSeconds < oneDay * 2) {
    lastEdited = intl.formatMessage(messages.yesterday);
  } else if (elapsedSeconds < oneDay * 7) {
    lastEdited = intl.formatMessage(messages.daysAgo, {
      DAYS: (elapsedSeconds / oneDay) | 0,
    });
  } else {
    lastEdited = intl.formatDate(lastEditedDate, UTC_DATE_FORMAT_INTL);
  }
  return lastEdited;
};

export const getDateRangeDataPoint = (startDate, endDate) => {
  return (
    moment.utc(startDate).format('M/DD/YY') +
    '-' +
    moment.utc(endDate).format('M/DD/YY')
  );
};

export const translateOptions = (options, intl) => {
  return Object.entries(options).reduce((acc, [key, option]) => {
    return {
      ...acc,
      [key]: {
        ...(option as any),
        label: intl.formatMessage(
          messages[(option as any).labelTranslationKey],
        ),
      },
    };
  }, {});
};
