import pick from 'lodash/pick';
import { actions, track } from 'react-redux-form';
import shortid from 'shortid';

import { performPost } from 'services/rest-service/rest-service';

const initialState = {
  /**
    When sending a message from a contact list, add the list ID here,
    to facilitate future queries.
  */
  authorLists: [],
  /**
    When sending a message from an influencer list, add the list ID here,
    to facilitate future queries.
  */
  influencerLists: [],
  /**
    When sending a message to a TK contact, add the ID here,
    to facilitate future queries. (At some point, we'll hopefully
    backfill author IDs in the outreach service, but not yet.)
  */
  authors: [],
  /**
    When sending a message to a Cision influencer, add the ID here,
    to facilitate future queries. (At some point, we'll hopefully
    backfill influencer IDs in the outreach service, but not yet.)
  */
  influencers: [],
  bcc: [],
  body: '',
  campaigns: [],
  cc: [],
  contexts: [],
  id: null,
  filteredViewId: null,
  from: null,
  replyToMessageId: '',
  schedule: undefined,
  stories: [],
  subject: '',
  to: [],
  /**
    When sending a reply, we check the original message's participants
    for emails associated with available outreach integrations.
    This list of integrations ensures the user will reply from an
    address that was on the thread.
  */
  participatingIntegrations: [],
  attachments: [],
};

const formModel = 'outreachComposeForm';

const pickComposeFormVals = vals => {
  const keys = [
    'authorLists',
    'authors',
    'influencerLists',
    'influencers',
    'bcc',
    'body',
    'campaigns',
    'cc',
    'contexts',
    'id',
    'from',
    'replyToMessageId',
    'schedule',
    'stories',
    'subject',
    'to',
    'participatingIntegrations',
    'attachments',
  ];
  return pick(vals, keys);
};

const outreachComposeFormReducer = (state = initialState) => {
  return state;
};

export const removeAttachmentActionCreator = index => dispatch => {
  dispatch(actions.remove(`${formModel}.attachments[]`, index));
};

export const addAttachmentsActionCreator = attachments => dispatch => {
  const fileModels = attachments.map(f => ({
    tempId: shortid.generate(),
    preview: f.preview,
    filename: f.name,
    size: f.size,
    contentType: f.type,
    attachment: f,
    loading: true,
    progress: 0,
    error: null,
  }));

  fileModels.forEach(async m => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { attachment, ...reduxModel } = m;
      dispatch(actions.push(`${formModel}.attachments`, reduxModel));

      const uploadConfig = {
        onUploadProgress: event =>
          dispatch(
            actions.change(
              track(`${formModel}.attachments[].progress`, {
                tempId: m.tempId,
              }),
              (event.loaded * 100) / event.total,
            ),
          ),
      };

      const fileData = new FormData();
      fileData.append('file', m.attachment);

      const response = await performPost(
        '/api/outreach/file',
        fileData,
        null,
        null,
        uploadConfig,
      );
      if (response && response.data) {
        dispatch(
          actions.change(
            track(`${formModel}.attachments[]`, { tempId: m.tempId }),
            { ...response.data, tempId: m.tempId },
          ),
        );
      } else {
        dispatch(
          actions.merge(
            track(`${formModel}.attachments[]`, { tempId: m.tempId }),
            { error: 'Unknown Error', loading: false },
          ),
        );
      }
    } catch (error) {
      dispatch(
        actions.merge(
          track(`${formModel}.attachments[]`, { tempId: m.tempId }),
          { error, loading: false },
        ),
      );
    }
  });
};

export const resetComposeForm = () => dispatch => {
  dispatch(actions.reset(formModel));
};

export const resetFormToTemplateActionDispatcher = (
  templateFields,
  options = {},
) => dispatch => {
  const formFields = {
    ...initialState,
    ...pickComposeFormVals(templateFields),
    filteredViewId: options.filteredViewId,
  };
  dispatch(actions.load(formModel, formFields));
  dispatch(actions.setPristine(formModel));
};

export default outreachComposeFormReducer;
