export default {
  sinceLaunchLabel: {
    id: 'DATE-UTIL_SINCE_LAUNCH_LABEL',
    defaultMessage: 'Since Published',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the period from launch',
  },
  sinceYesterdayLabel: {
    id: 'DATE-UTIL_SINCE_YESTERDAY_LABEL',
    defaultMessage: 'Since Yesterday',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates a period from yesterday',
  },
  trailing7Label: {
    id: 'DATE-UTIL_TRAILING_7_LABEL',
    defaultMessage: 'Trailing 7 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last 7 days',
  },
  trailing14Label: {
    id: 'DATE-UTIL_TRAILING_14_LABEL',
    defaultMessage: 'Trailing 14 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last 14 days',
  },
  trailing30Label: {
    id: 'DATE-UTIL_TRAILING_30_LABEL',
    defaultMessage: 'Trailing 30 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last 30 days',
  },
  trailing90Label: {
    id: 'DATE-UTIL_TRAILING_90_LABEL',
    defaultMessage: 'Trailing 90 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last 90 days',
  },
  weekToDateLabel: {
    id: 'DATE-UTIL_WEEK_TO_DATE_LABEL',
    defaultMessage: 'Week to Date',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last week to date',
  },
  monthToDateLabel: {
    id: 'DATE-UTIL_MONTH_TO_DATE_LABEL',
    defaultMessage: 'Month to Date',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last month to date',
  },
  quarterToDateLabel: {
    id: 'DATE-UTIL_QUARTER_TO_DATE_LABEL',
    defaultMessage: 'Quarter to Date',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last quarter to date',
  },
  yearToDateLabel: {
    id: 'DATE-UTIL_YEAR_TO_DATE_LABEL',
    defaultMessage: 'Year to Date',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last year to date',
  },
  lastWeekLabel: {
    id: 'DATE-UTIL_LAST_WEEK_LABEL',
    defaultMessage: 'Last week',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last full week',
  },
  lastMonthLabel: {
    id: 'DATE-UTIL_LAST_MONTH_LABEL',
    defaultMessage: 'Last Month',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last full month',
  },
  lastQuarterLabel: {
    id: 'DATE-UTIL_LAST_QUARTER_LABEL',
    defaultMessage: 'Last Quarter',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last full quarter',
  },
  lastYearLabel: {
    id: 'DATE-UTIL_LAST_YEAR_LABEL',
    defaultMessage: 'Last Year',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates the last full year',
  },
  customLabel: {
    id: 'DATE-UTIL_CUSTOM_LABEL',
    defaultMessage: 'Custom',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option that indicates a custom period',
  },
  sinceLaunchShortLabel: {
    id: 'DATE-UTIL_SINCE_LAUNCH_SHORT_LABEL',
    defaultMessage: 'Since Published',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the period from launch',
  },
  sinceYesterdayShortLabel: {
    id: 'DATE-UTIL_SINCE_YESTERDAY_SHORT_LABEL',
    defaultMessage: 'Since Yesterday',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates a period from yesterday',
  },
  allTimeShortLabel: {
    id: 'DATE-UTIL_ALL_TIME_SHORT_LABEL',
    defaultMessage: 'All Time',
    description:
      'Influencer Page > Activity Tab. Dropdown button on the top right. Date range option (in short format) that includes all time',
  },
  trailing7ShortLabel: {
    id: 'DATE-UTIL_TRAILING_7_SHORT_LABEL',
    defaultMessage: '7 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last 7 days',
  },
  trailing14ShortLabel: {
    id: 'DATE-UTIL_TRAILING_14_SHORT_LABEL',
    defaultMessage: '14 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last 14 days',
  },
  trailing30ShortLabel: {
    id: 'DATE-UTIL_TRAILING_30_SHORT_LABEL',
    defaultMessage: '30 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last 30 days',
  },
  trailing90ShortLabel: {
    id: 'DATE-UTIL_TRAILING_90_SHORT_LABEL',
    defaultMessage: '90 Days',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last 90 days',
  },
  weekToDateShortLabel: {
    id: 'DATE-UTIL_WEEK_TO_DATE_SHORT_LABEL',
    defaultMessage: 'Week',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last week to date',
  },
  monthToDateShortLabel: {
    id: 'DATE-UTIL_MONTH_TO_DATE_SHORT_LABEL',
    defaultMessage: 'Month',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last month to date',
  },
  quarterToDateShortLabel: {
    id: 'DATE-UTIL_QUARTER_TO_DATE_SHORT_LABEL',
    defaultMessage: 'Quarter',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last quarter to date',
  },
  yearToDateShortLabel: {
    id: 'DATE-UTIL_YEAR_TO_DATE_SHORT_LABEL',
    defaultMessage: 'Year',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last year to date',
  },
  lastWeekShortLabel: {
    id: 'DATE-UTIL_LAST_WEEK_SHORT_LABEL',
    defaultMessage: 'Week',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last full week',
  },
  lastMonthShortLabel: {
    id: 'DATE-UTIL_LAST_MONTH_SHORT_LABEL',
    defaultMessage: 'Month',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last full month',
  },
  lastQuarterShortLabel: {
    id: 'DATE-UTIL_LAST_QUARTER_SHORT_LABEL',
    defaultMessage: 'Quarter',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last full quarter',
  },
  lastYearShortLabel: {
    id: 'DATE-UTIL_LAST_YEAR_SHORT_LABEL',
    defaultMessage: 'Year',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates the last full year',
  },
  customShortLabel: {
    id: 'DATE-UTIL_CUSTOM_SHORT_LABEL',
    defaultMessage: 'Period',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range option (in  short format) that indicates a custom period',
  },
  customDateRangeFormatForDropdown: {
    id: 'DATE-UTIL_CUSTOM_DATE_RANGE_FORMAT_FOR_DROPDOWN',
    defaultMessage: '{START_DATE} - {END_DATE}',
    description:
      'Analytics > Impact For Earned Media. Dropdown button on the top right. Date range format for custom selector ',
  },
  customDateRangeTotalDays: {
    id: 'CUSTOM_DATE_RANGE_TOTAL_DAYS',
    description: 'Discovery custom date range total days',
    defaultMessage: '{totalDays} days',
  },
  customDateRangeSeparatorForDropdown: {
    id: 'CUSTOM_DATE_RANGE_SEPARATOR',
    description: 'Separator text between start and end dates',
    defaultMessage: 'to',
  },
  justNow: {
    id: 'DATE_UTIL_LAST_EDITED_JUST_NOW_LABEL',
    description: 'Term to indicate that something was made just now',
    defaultMessage: 'just now',
  },
  secondsAgo: {
    id: 'DATE_UTIL_LAST_EDITED_SECONDS_AGO_LABEL',
    description: 'Term to indicate that something was made some seconds ago',
    defaultMessage: '{SECONDS} seconds ago',
  },
  minutesAgo: {
    id: 'DATE_UTIL_LAST_EDITED_MINUTES_AGO_LABEL',
    description: 'Term to indicate that something was made some minutes ago',
    defaultMessage: '{MINUTES} minute(s) ago',
  },
  hoursAgo: {
    id: 'DATE_UTIL_LAST_EDITED_HOURS_AGO_LABEL',
    description: 'Term to indicate that something was made some hours ago',
    defaultMessage: '{HOURS} hour(s) ago',
  },
  yesterday: {
    id: 'DATE_UTIL_LAST_EDITED_YESTERDAY_LABEL',
    description: 'Term to indicate that something was made yesterday',
    defaultMessage: 'Yesterday',
  },
  daysAgo: {
    id: 'DATE_UTIL_LAST_EDITED_DAYS_AGO_LABEL',
    description: 'Term to indicate that something was made some days ago',
    defaultMessage: '{DAYS} day(s) ago',
  },
};
