import React from 'react';

import { Tooltip } from '@cision/rover-ui';

import './menu-item-conditional-tooltip.scss';

const MenuItemConditionalTooltip = ({
  children,
  tooltip = undefined,
  ...passedProps
}: {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
}) =>
  tooltip ? (
    <Tooltip
      content={tooltip}
      tooltipProps={{
        className: `${MenuItemConditionalTooltip.baseClass}__tooltip`,
      }}
      direction="bottom"
      showOnHover
      {...passedProps}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );

MenuItemConditionalTooltip.baseClass = 'menu-item-conditional-tooltip';

export default MenuItemConditionalTooltip;
