import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const TwitterLike = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M8.76402116,6.10996867 C7.31534392,6.10996867 6.04550265,7.58205478 6.04550265,9.25994056 C6.04550265,13.5086352 10.9439153,17.8427986 12,17.8900313 C13.0571429,17.8427986 17.9555556,13.5086352 17.9555556,9.25994056 C17.9555556,7.58205478 16.6846561,6.10996867 15.2359788,6.10996867 C13.4751323,6.10996867 12.4920635,8.28267331 12.4835979,8.30404049 C12.3227513,8.72013816 11.6772487,8.72013816 11.5174603,8.30404049 C11.5089947,8.28267331 10.5259259,6.10996867 8.76402116,6.10996867 L8.76402116,6.10996867 Z M12,19 L11.9904762,19 C10.1915344,18.9640132 5,13.9798377 5,9.25994056 C5,6.98939674 6.75873016,5 8.76402116,5 C10.3587302,5 11.431746,6.16957185 12,7.01976062 C12.568254,6.16957185 13.6412698,5 15.2359788,5 C17.2412698,5 19,6.98939674 19,9.25994056 C19,13.9798377 13.8095238,18.9640132 12.0095238,19 L12,19 Z" />
  </svg>
);
TwitterLike.propTypes = { fill: PropTypes.string };
TwitterLike.defaultProps = { fill: iconColorsMap.twitterlike };
export default TwitterLike;
