import filter from 'lodash/filter';
import memoize from 'lodash/memoize';
import pick from 'lodash/pick';
import { createSelector } from 'reselect';

const exportsSelector = state => (state.exports ? state.exports.exports : {});

export const exportsByIdsSelector = createSelector(exportsSelector, exports =>
  memoize(exportIds => exportIds.map(exportId => exports[exportId])),
);

export const pendingExportsByIdsSelector = createSelector(
  exportsSelector,
  exports => memoize(exportIds => filter(pick(exports, exportIds), 'pending')),
);
