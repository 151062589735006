export const CLOSE = 'contacts-to-story-modal/CLOSE';
export const SET_CONTACT_IDS = 'contacts-to-story-modal/SET_CONTACT_IDS';
export const TOGGLE = 'contacts-to-story-modal/TOGGLE';

const initialState = {
  contactIds: [],
  isModalOpen: false,
};

const sendContactsToStoryModalReducer = (
  state = { ...initialState },
  action,
) => {
  switch (action.type) {
    case CLOSE:
      return {
        ...state,
        isModalOpen: false,
      };
    case SET_CONTACT_IDS:
      return {
        ...state,
        contactIds: action.payload.contactIds || [],
      };
    case TOGGLE: {
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    }
    default:
      return state;
  }
};

export const closeActionCreator = () => ({ type: CLOSE });

export const setContactIdsActionCreator = ({ contactIds }) => ({
  type: SET_CONTACT_IDS,
  payload: {
    contactIds,
  },
});

export const toggleActionCreator = () => ({ type: TOGGLE });

export default sendContactsToStoryModalReducer;
