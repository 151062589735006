import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

const StoryPage = ({ children, className, style }) => {
  const mainClass = classnames(StoryPage.baseClass, className);

  return (
    <div className={mainClass} style={style}>
      {children}
    </div>
  );
};

StoryPage.baseClass = 'story-page';

StoryPage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

StoryPage.defaultProps = {
  children: null,
  className: '',
  style: {},
};

export default StoryPage;
