import { performGet } from 'services/rest-service/rest-service';

export const LIST_ANALYTICS = 'analytics_combined/LIST_ANALYTICS';
export const LIST_ANALYTICS_SUCCESS =
  'analytics_combined/LIST_ANALYTICS_SUCCESS';
export const LIST_ANALYTICS_ERROR = 'analytics_combined/LIST_ANALYTICS_ERROR';
export const CLEAR_ANALYTICS = 'analytics_combined/CLEAR_ANALYTICS';

export const initialState = {
  error: false,
  loading: true,
  items: [],
  paginationCount: null,
};

const analyticsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ANALYTICS:
      return {
        ...state,
        items: [],
        loading: true,
        error: null,
      };
    case LIST_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.dashboard,
        paginationCount: action.payload.count,
      };
    case LIST_ANALYTICS_ERROR:
      return {
        ...state,
        paginationCount: null,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_ANALYTICS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const getAnalyticsActionCreator = ({
  endpointBase,
  category = null,
  owner = null,
  ownerId = null,
  pageNumber = 1,
  pageSize = 10,
  sort = 'lastUpdated',
  sortDirection = 'desc',
  search = null,
  starredOnly = false,
}) => async dispatch => {
  const params = {
    category,
    owner,
    ownerId,
    pageNumber,
    pageSize,
    search,
    sort,
    sortDirection,
    starredOnly,
  };

  if (starredOnly) {
    params.starredOnly = true;
  }

  dispatch({ type: LIST_ANALYTICS });
  try {
    const result = await performGet(`${endpointBase}`, params);
    dispatch({
      type: LIST_ANALYTICS_SUCCESS,
      payload: { dashboard: result.data.results, count: result.data.count },
    });
  } catch (e) {
    dispatch({
      type: LIST_ANALYTICS_ERROR,
      payload: { error: e.response?.data },
    });
  }
};

export const clearAnalytics = () => ({ type: CLEAR_ANALYTICS });

export default analyticsListReducer;
