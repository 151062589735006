import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GenerateMediaComponent = baseClassName => {
  /**
   * @type {React.FC<{
   *  [rest:string]: any;
   * }>}
   */
  const Component = props => {
    const { className, ...rest } = props;
    return <div {...rest} className={classNames(baseClassName, className)} />;
  };

  Component.propTypes = {
    className: PropTypes.string,
  };

  Component.defaultProps = {
    className: null,
  };

  return Component;
};

const Media = GenerateMediaComponent('tk-media');
const Body = GenerateMediaComponent('tk-media__body');
const Item = GenerateMediaComponent('tk-media__item');

// TS-friendly way to extend - https://stackoverflow.com/a/60021394/1339100
export default Object.assign(Media, { Body, Item });
