import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import SvgIcon from '../svg-icon';
import Loader from '../loader';

const IconButton: React.FC<Record<string, any>> = props => {
  const {
    size,
    color,
    grayHover,
    compact,
    highlight,
    icon,
    isLoading,
    ...passedProps
  } = props;

  const modifiers = ['flex', size];

  if (grayHover) {
    modifiers.push('grayHover');
  } else {
    modifiers.push('iconOnly');
  }

  if (highlight) {
    modifiers.push('highlight');
  }

  if (compact) {
    modifiers.push('seethrough');
  } else {
    modifiers.push('circle');
    modifiers.push(color);
  }

  const iconSize = size === 'small' ? 14 : 18;

  return (
    <Button {...passedProps} modifiers={modifiers}>
      {isLoading ? (
        <div className="icon-button_loader">
          <Loader size="tiny" />
        </div>
      ) : (
        <SvgIcon icon={icon} width={iconSize} height={iconSize} />
      )}
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  grayHover: PropTypes.bool,
  color: PropTypes.oneOf([
    'danger',
    'primary',
    'primaryTeal',
    'primaryGreen',
    'secondary',
    'seethrough-inverse-rebrand',
    'tertiary',
  ]),
  highlight: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  isLoading: PropTypes.bool,
};

IconButton.defaultProps = {
  isLoading: false,
  grayHover: false,
  compact: false,
  color: 'tertiary',
  highlight: false,
  size: 'large',
};

export default IconButton;
