export default {
  showFeedLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_SHOW_FEED_LABEL',
    defaultMessage: 'Show Feed',
    description: 'Admin Page > Edit Feed: Show feed label',
  },
  editFeedLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_LABEL',
    defaultMessage: 'Edit Feed',
    description: 'Admin Page > Edit Feed: Edit feed label',
  },
  updateFeedLabel: {
    id: 'ADMIN_PAGE_UPDATE_FEED_LABEL',
    defaultMessage: 'Update Feed',
    description: 'Admin Page > Edit Feed: Update feed label',
  },
  showFeedDashboardTitleLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_DASHBOARD_TITLE_LABEL',
    defaultMessage: 'Dashboard',
    description: 'Admin Page > Edit Feed: Show feed dashboard title label',
  },
  feedIdLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_ID_LABEL',
    defaultMessage: 'Feed ID',
    description: 'Admin Page > Edit Feed: Feed id label',
  },
  feedLinkLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_FEED_LINKLABEL',
    defaultMessage: 'Feed Link',
    description: 'Admin Page > Edit Feed: Feed name label',
  },
  dateCreatedLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_DATE_CREATED_LABEL',
    defaultMessage: 'Date Created',
    description: 'Admin Page > Edit Feed: Date created label',
  },
  dateLastUpdatedLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_DATE_LAST_UPDATED_LABEL',
    defaultMessage: 'Date Last Updated',
    description: 'Admin Page > Edit Feed: Date last updated label',
  },
  widgetTitleIdLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_WIDGET_TITLE_ID_LABEL',
    defaultMessage: 'Widget Title (ID)',
    description: 'Admin Page > Edit Feed: Widget title/ID label',
  },
  accountIdLabel: {
    id: 'ADMIN_PAGE_EDIT_FEED_ACCOUNT_ID_LABEL',
    defaultMessage: 'Account ID',
    description: 'Admin Page > Edit Feed: Account ID label',
  },
  notAvailableValue: {
    id: 'ADMIN_PAGE_EDIT_FEED_NOT_AVAILABLE_VALUE',
    defaultMessage: 'Not available',
    description: 'Admin Page > Edit Feed: Not available value',
  },
  enterErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_FEED_ENTER_ERROR_MESSAGE',
    defaultMessage: 'Please enter a',
    description: 'Admin Page > Edit Feed: Enter error message',
  },
  getFeedErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_FEED_GET_FEED_ERROR_MESSAGE',
    defaultMessage: 'Could not get Feed Id {FEED_ID}',
    description: 'Admin Page > Edit Feed: Get feed error message',
  },
  getFeedsErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_FEED_GET_FEEDS_ERROR_MESSAGE',
    defaultMessage: 'Could not get Feeds',
    description: 'Admin Page > Edit Feed: Get feeds error message',
  },
  editFeedSuccessMsg: {
    id: 'ADMIN_PAGE_EDIT_FEED_SUCCESS_MESSAGE',
    defaultMessage: 'Success! You have edited {FEED_NAME}',
    description: 'Admin Page > Edit Feed: Edit feed success message',
  },
  editFeedDefaultErrorMsg: {
    id: 'ADMIN_PAGE_EDIT_FEED_DEFAULT_ERROR_MESSAGE',
    defaultMessage: 'Could not edit feed {FEED_NAME}, please review the data.',
    description:
      'Admin Page > Edit Feed: Default text for editing a feed error message',
  },
  undefinedWidget: {
    id: 'ADMIN_PAGE_EDIT_FEED_UNDEFINED_WIDGET',
    defaultMessage: 'undefined',
    description: 'Admin Page > Edit Feed: Widget value undefined',
  },
};
