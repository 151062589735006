import React, { Component } from 'react';

import { Responsive } from '@cision/rover-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import GridTableCell from './GridTableCell';

class GridTableRow extends Component {
  onCellClick = (cell, onHeaderClick) => {
    if (cell && cell.sortable && onHeaderClick) {
      return () => onHeaderClick(cell.key);
    } else if (cell && cell.onClick) {
      return () => cell.onClick();
    }
    return null;
  };

  render() {
    const {
      className,
      columnStyle,
      rowData,
      isHeader,
      isSticky,
      modifiers,
      onHeaderClick,
      onClick,
      stickyHeaderAt,
      stickyHeaderWidth,
      ...passedProps
    } = this.props;
    const baseClass = 'tk-grid-table__row';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseClass}--${modifier}`)
      : [];

    const mainClass = classnames(baseClass, className, prefixedModifiers, {
      [`${baseClass}--header`]: isHeader,
    });

    const styles = {
      // This should get updated to something IE-friendly but still configurable
      gridTemplateColumns: columnStyle,
    };

    const getMergedStyles = (context = []) => {
      let sticky =
        typeof stickyHeaderAt === 'number' &&
        isHeader &&
        !context.includes('container-xs-down')
          ? {
              top: `${stickyHeaderAt}px`,
              position: isSticky ? 'sticky' : 'fixed',
              zIndex: 3,
              width: stickyHeaderWidth,
              background: 'white',
            }
          : {};

      sticky =
        isHeader && context.includes('container-xs-down')
          ? { display: 'none' }
          : sticky;
      return { ...sticky, ...styles };
    };

    const clickProps = {};
    if (onClick) {
      clickProps.onClick = onClick;
      clickProps.role = 'button';
    }
    return (
      <Responsive.Context.Consumer>
        {context => (
          <div
            className={mainClass}
            style={getMergedStyles(context)}
            {...passedProps}
            {...clickProps}
          >
            {rowData.map((cell, i) => (
              <GridTableCell
                data-qa={cell.dataQA}
                id={cell.key}
                isHeader={isHeader}
                isSorting={cell.isSorting}
                key={`${cell.key || 'empty_col_' + i}`}
                onClick={this.onCellClick(cell, onHeaderClick)}
                modifiers={cell.modifiers}
                sortable={cell.sortable}
                sortDirection={cell.sortDirection}
              >
                {cell.content}
              </GridTableCell>
            ))}
          </div>
        )}
      </Responsive.Context.Consumer>
    );
  }
}

GridTableRow.propTypes = {
  className: PropTypes.string,
  columnStyle: PropTypes.string,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any,
      dataQA: PropTypes.any,
      isSorting: PropTypes.any,
      key: PropTypes.any,
      modifiers: PropTypes.any,
      onClick: PropTypes.func,
      sortable: PropTypes.any,
      sortDirection: PropTypes.any,
    }),
  ),
  isHeader: PropTypes.bool,
  isSticky: PropTypes.bool,
  modifiers: PropTypes.array,
  onHeaderClick: PropTypes.func,
  onClick: PropTypes.func,
  stickyHeaderAt: PropTypes.number,
  stickyHeaderWidth: PropTypes.string,
};

GridTableRow.defaultProps = {
  className: '',
  columnStyle: '',
  rowData: [],
  isHeader: false,
  isSticky: false,
  modifiers: [],
  onHeaderClick: undefined,
  onClick: undefined,
  stickyHeaderAt: undefined,
  stickyHeaderWidth: '100%',
};

export default GridTableRow;
