import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Progress = ({ modifiers, value }) => {
  const isIndeterminate = isNaN(value);
  const fillWidth = value * 100; // Turns to a percentage
  const gradientWidth = Math.ceil(10000 / fillWidth); // If the fill is 50% wide, the gradient should be 200% of the fill width

  const baseClass = 'tk-progress';
  const prefixedModifiers = Array.isArray(modifiers)
    ? modifiers.map(modifier => `${baseClass}--${modifier}`)
    : [];

  const mainClass = classNames(baseClass, prefixedModifiers, {
    [`${baseClass}--indeterminate`]: isIndeterminate,
  });

  return (
    <div className={mainClass}>
      <div className={`${baseClass}__fill-container`}>
        <div
          className={`${baseClass}__fill-bar`}
          style={{
            backgroundSize: `${gradientWidth}% 100%`,
            width: `${fillWidth}%`,
          }}
        />
      </div>
    </div>
  );
};

Progress.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.oneOf(['large', 'small', 'xlarge'])),
  value: PropTypes.number,
};

Progress.defaultProps = {
  modifiers: [],
  value: NaN,
};

export default Progress;
