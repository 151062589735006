import { Quill } from 'react-quill';

const Embed = Quill.import('blots/embed');

/**
 * Can be triggered by including a span in the value passed in of the type
 * <span class="tk-ql-template-string" data-template-key="Full name"></span>
 * The data-template-key attribute determines the template token's label in edit mode.
 * Substituting real data for send or preview is handled by components/outreach/utils.js
 */
const templateStringAttributes = ['data-template-key'];

class TemplateString extends Embed {
  static blotName = 'templateString';
  static tagName = 'SPAN';
  static className = 'tk-ql-template-string';

  static create(value) {
    const node = super.create(value);

    templateStringAttributes.forEach(attribute => {
      if (value[attribute]) {
        node.setAttribute(attribute, value[attribute]);
      }
    });

    node.innerHTML = `{{${value['data-template-key']}}}`;
    return node;
  }

  static value = domNode =>
    templateStringAttributes.reduce((result, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        result[attribute] = domNode.getAttribute(attribute);
      }

      return result;
    }, {});
}

export default TemplateString;
