export const loadState = stateId => {
  try {
    const state = localStorage.getItem(stateId);
    if (state) {
      return JSON.parse(state);
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const saveState = (stateId, state) => {
  try {
    localStorage.setItem(stateId, JSON.stringify(state));
  } catch (error) {
    // continue regardless of error
  }
};
