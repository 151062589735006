import {
  INFLUENCER_SAVED_LISTS_ENDPOINT,
  INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT,
  INFLUENCER_SAVED_LISTS_ADD_MEMBERS_WITH_VIRTUAL_SELECTION_ENDPOINT,
  INFLUENCER_GET_SAVED_LISTS,
  INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT,
} from 'constants/apis';
import { SAVED_LIST_INFLUENCERS_NUM_LIMIT } from 'constants/constants';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { performPut, performPost } from 'services/rest-service/rest-service';

import addInfluencerTrayMessages from '../InfluencerList/components/AddInfluencerTray/AddInfluencerTray.messages';
import { INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS } from '../InfluencerList/reducers/search';
import {
  bustSavedListsCache,
  getSavedListsActionCreator,
  updateSharingPermissions,
  INFLUENCERS_CUSTOM_LIST_START,
  INFLUENCERS_CUSTOM_LIST_SUCCESS,
  INFLUENCERS_CUSTOM_LIST_FINISH_LOADING,
  SET_SELECTED_SAVED_LIST,
  TOGGLE_DUPLICATE_LIST_MODAL,
} from '../InfluencerSavedLists/reducer';

import {
  GET_INFLUENCERS,
  GET_INFLUENCERS_SUCCESS,
  GET_INFLUENCERS_ERROR,
  REMOVE_INFLUENCERS_FROM_LIST,
  REMOVE_INFLUENCERS_FROM_LIST_SUCCESS,
  REMOVE_INFLUENCERS_FROM_LIST_ERROR,
  changePageNumber,
} from '../Search/reducer';

import { flattenLists } from './utils/flattenLists';
export const INFLUENCERS_DUPLICATE_LIST_START =
  'ihub/INFLUENCERS_DUPLICATE_LIST_START';
export const INFLUENCERS_DUPLICATE_LIST_SUCCESS =
  'ihub/INFLUENCERS_DUPLICATE_LIST_SUCCESS';
export const INFLUENCERS_DUPLICATE_LIST_START_ERROR =
  'ihub/INFLUENCERS_DUPLICATE_LIST_START_ERROR';
export const INFLUENCERS_ADD_TO_SAVED_LIST =
  'ihub/INFLUENCERS_ADD_TO_SAVED_LIST';
export const INFLUENCERS_ADD_TO_SAVED_LIST_SUCCESS =
  'ihub/INFLUENCERS_ADD_TO_SAVED_LIST_SUCCESS';
export const INFLUENCERS_ADD_TO_SAVED_LIST_ERROR =
  'ihub/INFLUENCERS_ADD_TO_SAVED_LIST_ERROR';
export const INFLUENCERS_RENAME_SAVED_LIST =
  'ihub/INFLUENCERS_RENAME_SAVED_LIST';
export const INFLUENCERS_RENAME_SAVED_LIST_SUCCESS =
  'ihub/INFLUENCERS_RENAME_SAVED_LIST_SUCCESS';
export const INFLUENCERS_RENAME_SAVED_LIST_ERROR =
  'ihub/INFLUENCERS_RENAME_SAVED_LIST_ERROR';
export const GET_SAVED_LISTS = 'ihub/GET_SAVED_LISTS';
export const GET_SAVED_LISTS_SUCCESS = 'ihub/GET_SAVED_LISTS_SUCCESS';
export const GET_SAVED_LISTS_ERROR = 'ihub/GET_SAVED_LISTS_ERROR';
export const GET_SAVED_LISTS_BY_INFLUENCER =
  'ihub/GET_SAVED_LISTS_BY_INFLUENCER';
export const GET_SAVED_LISTS_BY_INFLUENCER_SUCCESS =
  'ihub/GET_SAVED_LISTS_BY_INFLUENCER_SUCCESS';
export const GET_SAVED_LISTS_BY_INFLUENCER_ERROR =
  'ihub/GET_SAVED_LISTS_BY_INFLUENCER_ERROR';
export const INFLUENCERS_CUSTOM_LIST_ERROR =
  'ihub/INFLUENCERS_CUSTOM_LIST_ERROR';
export const SELECT_INFLUENCER_SAVED_LIST = 'ihub/SELECT_INFLUENCER_SAVED_LIST';
export const TOGGLE_REMOVE_INFLUENCER_MODAL =
  'ihub/TOGGLE_REMOVE_INFLUENCER_MODAL';
export const SET_CLICKED_INFLUENCER = 'ihub/SET_CLICKED_INFLUENCER';
export const RESET_EXISTING_SAVED_LISTS = 'ihub/RESET_EXISTING_SAVED_LISTS';
export const LIST_RENAMED = 'ihub/LIST_RENAMED';

export const initialState = {
  loading: false,
  duplicateListModalLoader: false,
  error: false,
  activeId: '',
  influencerIds: [],
  savedLists: [],
  existingLists: [],
  selectedList: null, // TODO: build shape when connect to api
  removeInfluencerModalOpen: false,
  selectedInfluencer: null,
  isListRenamed: false,
  isRenaming: false,
};

const influencerListsReducer = (
  state = {
    ...initialState,
  },
  action,
) => {
  switch (action.type) {
    case GET_SAVED_LISTS: {
      return {
        ...state,
        loading: true,
        error: false,
        activeId: action.payload,
      };
    }

    case GET_SAVED_LISTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        savedLists: action.payload,
      };
    }

    case GET_SAVED_LISTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case GET_SAVED_LISTS_BY_INFLUENCER: {
      return {
        ...state,
        loading: true,
        error: false,
        influencerIds: action.payload,
      };
    }

    case GET_SAVED_LISTS_BY_INFLUENCER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        existingLists: action.payload,
      };
    }

    case GET_SAVED_LISTS_BY_INFLUENCER_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case INFLUENCERS_CUSTOM_LIST_ERROR: {
      return {
        ...state,
        customLoading: false,
        error: true,
      };
    }

    case INFLUENCERS_ADD_TO_SAVED_LIST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case INFLUENCERS_ADD_TO_SAVED_LIST_SUCCESS: {
      const { existingLists } = state;
      return {
        ...state,
        loading: false,
        error: false,
        existingLists: [...existingLists, ...action.payload],
      };
    }

    case INFLUENCERS_ADD_TO_SAVED_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case INFLUENCERS_RENAME_SAVED_LIST: {
      return {
        ...state,
        loading: true,
        isRenaming: true,
        error: false,
      };
    }

    case INFLUENCERS_RENAME_SAVED_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        isRenaming: false,
        error: false,
      };
    }

    case INFLUENCERS_RENAME_SAVED_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        isRenaming: false,
        error: true,
      };
    }

    case INFLUENCERS_DUPLICATE_LIST_START: {
      return {
        ...state,
        duplicateListModalLoader: true,
        error: false,
      };
    }

    case INFLUENCERS_DUPLICATE_LIST_SUCCESS: {
      return {
        ...state,
        duplicateListModalLoader: false,
        error: false,
      };
    }

    case INFLUENCERS_DUPLICATE_LIST_START_ERROR: {
      return {
        ...state,
        duplicateListModalLoader: false,
        error: true,
      };
    }

    case LIST_RENAMED: {
      return {
        ...state,
        isListRenamed: action.payload,
      };
    }

    case SELECT_INFLUENCER_SAVED_LIST: {
      return {
        ...state,
        loading: false,
        error: false,
        selectedList: action.payload,
      };
    }

    case TOGGLE_REMOVE_INFLUENCER_MODAL: {
      return {
        ...state,
        removeInfluencerModalOpen: !state.removeInfluencerModalOpen,
      };
    }

    case SET_CLICKED_INFLUENCER: {
      return {
        ...state,
        selectedInfluencer: action.payload,
      };
    }

    case RESET_EXISTING_SAVED_LISTS: {
      return {
        ...state,
        existingLists: [],
      };
    }

    default:
      return state;
  }
};

export const resetExistingSavedLists = () => ({
  type: RESET_EXISTING_SAVED_LISTS,
});

export const toggleRemoveInfluencerModal = () => ({
  type: TOGGLE_REMOVE_INFLUENCER_MODAL,
});

export const setClickedInfluencer = influencer => ({
  type: SET_CLICKED_INFLUENCER,
  payload: influencer,
});

export const listRenamed = isListRenamed => ({
  type: LIST_RENAMED,
  payload: isListRenamed,
});

export const timeout = ms => Promise(resolve => setTimeout(resolve, ms));

export const addInfluencerToListActionDispatcher = (
  influencerIds,
  savedLists,
  intl,
  messages,
) => async dispatch => {
  bustSavedListsCache();

  dispatch({
    type: INFLUENCERS_ADD_TO_SAVED_LIST,
  });

  const savedListIds = Array.from(
    new Set(
      savedLists.map(l => ({
        id: l.id,
        selectedInfluencersIds: l.influencerIds,
        name: l.name,
      })),
    ),
  );

  const influencerCount = influencerIds.length;
  const maxExceeded = savedListIds.filter(list => {
    if (list.selectedInfluencersIds) {
      return (
        list.selectedInfluencersIds.length + influencerCount >
        SAVED_LIST_INFLUENCERS_NUM_LIMIT
      );
    }
    return influencerCount > SAVED_LIST_INFLUENCERS_NUM_LIMIT;
  });

  const listOfInfluencersToAdd = savedListIds.filter(list => {
    if (list.selectedInfluencersIds) {
      return (
        list.selectedInfluencersIds.length + influencerCount <=
        SAVED_LIST_INFLUENCERS_NUM_LIMIT
      );
    }
    return influencerCount <= SAVED_LIST_INFLUENCERS_NUM_LIMIT;
  });

  let response;
  try {
    maxExceeded.forEach(item =>
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.addInfluencerToListError, {
            LIST_LIMIT: SAVED_LIST_INFLUENCERS_NUM_LIMIT,
            ITEM_NAME: item.name,
            INFLUENCER_COUNT: influencerCount,
          }),
          status: 'danger',
        }),
      ),
    );

    const calls = listOfInfluencersToAdd.map(listId => {
      return performPut(`${INFLUENCER_SAVED_LISTS_ENDPOINT}/${listId.id}`, {
        addInfluencerIds: influencerIds,
        removeInfluencerIds: [],
      });
    });

    response = await Promise.all(calls);

    const addedIds = response.map(r => r.data && r.data.data && r.data.data.id);
    const addedNames = addedIds.map(
      id => savedLists.find(l => l.id === id).name,
    );

    if (maxExceeded.length === 0) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.addInfluencerToListSuccess, {
            ADDED_NAMES: addedNames.join(', '),
            INFLUENCER_COUNT: influencerCount,
          }),
          status: 'success',
        }),
      );
    }
    if (influencerCount === 1) {
      dispatch(
        getListsMembershipByInfluencerIdActionDispatcher(influencerIds[0], {
          size: 50,
          append: false,
          from: 0,
        }),
      );
    }
  } catch (e) {
    dispatch({
      type: INFLUENCERS_ADD_TO_SAVED_LIST_ERROR,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.addInfluencerToListGenericError),
        status: 'danger',
      }),
    );
  }
};

export const getListsWithMembersCountGreaterThanMaxAllowed = (
  savedLists,
  influencerCount,
  maximumAllowed,
) => {
  return savedLists.filter(list =>
    list.influencerIds
      ? list.influencerIds.length + influencerCount > maximumAllowed
      : influencerCount > maximumAllowed,
  );
};

export const getListsWithMembersCountLessThanMaxAllowed = (
  savedLists,
  influencerCount,
  maximumAllowed,
) => {
  return savedLists.filter(list =>
    list.influencerIds
      ? list.influencerIds.length + influencerCount <= maximumAllowed
      : influencerCount <= maximumAllowed,
  );
};

const displayAddListMembersErrorMessage = errorMessage => dispatch => {
  dispatch({
    type: INFLUENCERS_ADD_TO_SAVED_LIST_ERROR,
  });
  dispatch(
    addPageMessageWithDefaultTimeout({
      text: errorMessage,
      status: 'danger',
    }),
  );
};

const addMembersToExistingLists = async (
  listsThatMeetTheMaximumMembersRequirement,
  listRequest,
  url,
) => {
  const calls = listsThatMeetTheMaximumMembersRequirement.map(list => {
    const endpoint = url.replace('<listId>', list.id);
    return performPut(endpoint, listRequest);
  });

  const response = await Promise.all(calls);
  return response;
};

export const addListMembersWithVirtualSelectionActionDispatcher = ({
  intl,
  messages,
  influencerCount,
  listRequest,
  savedLists,
}) => async dispatch => {
  bustSavedListsCache();

  dispatch({
    type: INFLUENCERS_ADD_TO_SAVED_LIST,
  });

  const listsThatExceedTheMaxMembersAllowed = getListsWithMembersCountGreaterThanMaxAllowed(
    savedLists,
    influencerCount,
    SAVED_LIST_INFLUENCERS_NUM_LIMIT,
  );

  const listsThatMeetTheMaximumMembersRequirement = getListsWithMembersCountLessThanMaxAllowed(
    savedLists,
    influencerCount,
    SAVED_LIST_INFLUENCERS_NUM_LIMIT,
  );

  let response;
  try {
    if (listsThatExceedTheMaxMembersAllowed.length > 0) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.addInfluencerToListError, {
            LIST_LIMIT: SAVED_LIST_INFLUENCERS_NUM_LIMIT,
            ITEM_NAME: listsThatExceedTheMaxMembersAllowed
              .map(l => l.name)
              .join(', '),
            INFLUENCER_COUNT: influencerCount,
          }),
          status: 'danger',
        }),
      );
    }

    response = await addMembersToExistingLists(
      listsThatMeetTheMaximumMembersRequirement,
      listRequest,
      INFLUENCER_SAVED_LISTS_ADD_MEMBERS_WITH_VIRTUAL_SELECTION_ENDPOINT,
    );

    const addedIds = response.map(r => r?.data?.data?.id);
    const addedNames = addedIds.map(
      id => savedLists.find(l => l.id === id)?.name,
    );

    if (addedNames.length > 0) {
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.addInfluencerToListSuccess, {
            ADDED_NAMES: addedNames.join(', '),
            INFLUENCER_COUNT: influencerCount,
          }),
          status: 'success',
        }),
      );
    }
  } catch (e) {
    dispatch(
      displayAddListMembersErrorMessage(
        intl.formatMessage(messages.addInfluencerToListGenericError),
      ),
    );
  }
};

export const updateDuplicateListActionDispatcher = (
  savedListId,
  duplicateListName,
  intl,
  trayMessages,
  messages,
  onError,
  listPermission,
) => async dispatch => {
  const addInfluencerTrayMessages = trayMessages;

  bustSavedListsCache();

  dispatch({
    type: INFLUENCERS_DUPLICATE_LIST_START,
  });

  try {
    const res = await performPut(
      `${INFLUENCER_SAVED_LISTS_ENDPOINT}/${savedListId}`,
      {
        addInfluencerIds: [],
        removeInfluencerIds: [],
        renameTo: duplicateListName,
      },
    );

    dispatch({
      type: TOGGLE_DUPLICATE_LIST_MODAL,
      payload: duplicateListName,
    });

    if (res?.data?.data?.id) {
      await dispatch(
        updateSharingPermissions({
          intl,
          listPermission,
          addInfluencerTrayMessages,
          savedListId,
        }),
      );
    }

    dispatch(getSavedListsActionCreator({}));

    dispatch({
      type: INFLUENCERS_DUPLICATE_LIST_SUCCESS,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.updateDuplicateListSuccess),
        status: 'success',
      }),
    );
  } catch (err) {
    dispatch({
      type: INFLUENCERS_DUPLICATE_LIST_START_ERROR,
    });

    if (onError) {
      onError();
    }

    const errorText = intl.formatMessage(
      err.message.includes('code 409')
        ? messages.duplicateListNameError
        : messages.updateDuplicatedListError,
    );

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: errorText,
        status: 'danger',
      }),
    );
  }
};
export const renameListActionDispatcher = (
  savedList,
  newListName,
  intl,
  messages,
  onError,
) => async dispatch => {
  bustSavedListsCache();

  dispatch({
    type: INFLUENCERS_RENAME_SAVED_LIST,
  });
  try {
    await performPut(`${INFLUENCER_SAVED_LISTS_ENDPOINT}/${savedList.id}`, {
      addInfluencerIds: [],
      removeInfluencerIds: [],
      renameTo: newListName,
    });
    dispatch({
      type: INFLUENCERS_RENAME_SAVED_LIST_SUCCESS,
    });
    localStorage.setItem(
      SET_SELECTED_SAVED_LIST,
      JSON.stringify({ ...savedList, name: newListName }),
    );
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.renameListSuccess, {
          NEWLISTNAME: newListName,
        }),
        status: 'success',
      }),
    );
    dispatch(listRenamed(true));
  } catch (err) {
    dispatch(listRenamed(false));
    dispatch({
      type: INFLUENCERS_RENAME_SAVED_LIST_ERROR,
    });
    if (onError) {
      onError();
    }

    const errorText = intl.formatMessage(
      err.message.includes('code 409')
        ? messages.duplicateListNameError
        : messages.renameListError,
    );
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: errorText,
        status: 'danger',
      }),
    );
  }
};

export const setSelectedSavedListActionDispatcher = savedListId => ({
  type: GET_SAVED_LISTS_BY_INFLUENCER_SUCCESS,
  payload: [savedListId],
});

export const getListsMembershipByInfluencerIdActionDispatcher = (
  influencerId,
  { append = false, name = '', size = 50, matchType = 'exact', pageNumber = 1 },
) => async dispatch => {
  try {
    dispatch({
      type: INFLUENCERS_CUSTOM_LIST_START,
    });
    let listIds = [];
    const responseMemberSearch = await performPost(INFLUENCER_GET_SAVED_LISTS, {
      influencerIds: [influencerId],
      pageNumber,
      pageSize: size,
    });
    const data = responseMemberSearch.data && responseMemberSearch.data.data;
    const payload = {
      append,
      data: listIds,
      totalLists: 0,
      currentPageNumber: 0,
    };

    if (data.length === 0 || data[0]?.list?.length === 0) {
      dispatch({
        type: INFLUENCERS_CUSTOM_LIST_SUCCESS,
        payload,
      });
      dispatch({
        type: INFLUENCERS_CUSTOM_LIST_FINISH_LOADING,
        payload: { areAllSidebarListsVisible: false },
      });
      return;
    }

    const memberLists = data.length > 0 ? data[0].lists : [];
    listIds = memberLists.map(list => list.id);

    const sortBy = 'name';
    const sortOrder = 'asc';
    const responseSearch = await performPut(
      INFLUENCER_SAVED_LISTS_SEARCH_ENDPOINT,
      {
        name,
        pageNumber,
        matchType,
        size,
        sortBy,
        sortOrder,
        includeDeleted: false,
        visibility: 'user',
        listIds,
      },
    );

    payload.totalLists = responseSearch?.data?.total || 0;
    payload.data = responseSearch?.data?.hits;
    payload.pageNumber = pageNumber;
    const haveMoreCustomLists = listIds.length >= size;
    dispatch({
      type: INFLUENCERS_CUSTOM_LIST_SUCCESS,
      payload,
    });
    dispatch({
      type: INFLUENCERS_CUSTOM_LIST_FINISH_LOADING,
      payload: { areAllSidebarListsVisible: haveMoreCustomLists },
    });
  } catch (e) {
    dispatch({
      type: INFLUENCERS_CUSTOM_LIST_ERROR,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Failed to get lists of the current influencer.',
        status: 'danger',
      }),
    );
  }
};

export const getSelectedSavedListsActionDispatcher = influencerIds => async dispatch => {
  dispatch(getSavedListsActionCreator({ reset: false }));
  dispatch({
    type: GET_SAVED_LISTS_BY_INFLUENCER,
    payload: influencerIds,
  });
  try {
    let lists = [];
    const hasAtLeastOneInfluencer = influencerIds.length;

    if (hasAtLeastOneInfluencer) {
      const response = await performPost(INFLUENCER_GET_SAVED_LISTS, {
        influencerIds: influencerIds,
      });
      const data = response?.data?.data;

      const hasOnlyOneInfluencer = influencerIds.length === 1;
      const hasMoreThanOneInfluencer = influencerIds.length > 1;

      if (hasOnlyOneInfluencer) {
        const memberLists = data.length > 0 ? data[0].lists : [];

        lists = memberLists.map(l => l.id);
      }

      if (hasMoreThanOneInfluencer) {
        const flattenedLists = flattenLists(data);
        const listOfIds = flattenedLists.map(list => list.id);

        lists = [...new Set(listOfIds)];
      }
    }

    dispatch({
      type: GET_SAVED_LISTS_BY_INFLUENCER_SUCCESS,
      payload: lists,
    });
  } catch (e) {
    dispatch({
      type: GET_SAVED_LISTS_BY_INFLUENCER_ERROR,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Failed to get lists of the current influencer.',
        status: 'danger',
      }),
    );
  }
};

export const selectList = list => ({
  type: SELECT_INFLUENCER_SAVED_LIST,
  payload: list,
});

export const fetchInfluencerListMembers = async (listId, params = {}) => {
  const { pagesize = 50, pagenumber = 1, contact = null } = params;
  const endpoint = INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT.replace(
    '<listId>',
    listId,
  );

  const response = await performPost(endpoint, {
    pagesize,
    pagenumber,
    contact,
  });

  return response;
};

export const getListOfInfluencers = (
  savedListId,
  params = {},
) => async dispatch => {
  const {
    append = false,
    pagesize = 50,
    pagenumber = 1,
    contact = null,
  } = params;

  dispatch({
    type: GET_INFLUENCERS,
    append,
  });

  try {
    const response = await fetchInfluencerListMembers(savedListId, {
      pagesize,
      pagenumber,
      contact,
    });

    const {
      data: hits,
      currentpagenumber: currentPageNumber,
      totalpages: totalPages,
      pagesize: pageSize,
      totalhits: totalHits,
    } = response.data;

    dispatch({
      type: GET_INFLUENCERS_SUCCESS,
      append,
      hits,
      pagination: {
        currentPageNumber,
        pageSize,
        totalHits,
        totalPages,
      },
    });
  } catch (e) {
    dispatch({
      type: GET_INFLUENCERS_ERROR,
      payload: e.message,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Failed to get influencers.',
        status: 'danger',
      }),
    );
  }
};

export const removeInfluencerFromList = (
  influencerId,
  selectedList,
  intl,
) => async (dispatch, getState) => {
  bustSavedListsCache();

  const {
    influencerSearch: { pageState },
  } = getState();

  dispatch({
    type: REMOVE_INFLUENCERS_FROM_LIST,
  });
  dispatch({
    type: TOGGLE_REMOVE_INFLUENCER_MODAL,
  });
  try {
    const listId = selectedList.id;
    await performPut(`${INFLUENCER_SAVED_LISTS_ENDPOINT}/${listId}`, {
      addInfluencerIds: [],
      removeInfluencerIds: [influencerId],
    });
    dispatch({
      type: REMOVE_INFLUENCERS_FROM_LIST_SUCCESS,
      payload: [influencerId],
    });
    dispatch(changePageNumber(pageState.searchCriteria.pagenumber));
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          addInfluencerTrayMessages.removeInfluencerFromList,
        ),
        status: 'success',
      }),
    );
    dispatch(
      getListsMembershipByInfluencerIdActionDispatcher(influencerId, {
        size: 50,
        append: false,
        from: 0,
      }),
    );
  } catch (e) {
    dispatch({
      type: REMOVE_INFLUENCERS_FROM_LIST_ERROR,
      payload: e.message,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          addInfluencerTrayMessages.removeInfluencerFromListError,
        ),
        status: 'danger',
      }),
    );
  }
};

export const bulkRemoveInfluencersFromList = (
  influencerIds,
  selectedList,
  intl,
) => async (dispatch, getState) => {
  bustSavedListsCache();

  const {
    influencerSearch: { pageState },
  } = getState();

  dispatch({
    type: REMOVE_INFLUENCERS_FROM_LIST,
  });
  try {
    const listId = selectedList
      ? selectedList.id
      : JSON.parse(localStorage.getItem(SET_SELECTED_SAVED_LIST))?.id;

    await performPut(`${INFLUENCER_SAVED_LISTS_ENDPOINT}/${listId}`, {
      addInfluencerIds: [],
      removeInfluencerIds: influencerIds,
    });
    dispatch({
      type: REMOVE_INFLUENCERS_FROM_LIST_SUCCESS,
      payload: influencerIds,
    });
    dispatch(changePageNumber(pageState.searchCriteria.pagenumber));
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          addInfluencerTrayMessages.bulkRemoveInfluencerFromList,
          { AMOUNT_INFLUENCERS: influencerIds.length },
        ),
        status: 'success',
      }),
    );
    dispatch({
      type: INFLUENCERS_BULK_UNSELECT_ALL_INFLUENCERS,
    });
  } catch (e) {
    dispatch({
      type: REMOVE_INFLUENCERS_FROM_LIST_ERROR,
      payload: e.message,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(
          addInfluencerTrayMessages.bulkRemoveInfluencerFromListError,
        ),
        status: 'danger',
      }),
    );
  }
};

export default influencerListsReducer;
