/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import withModifiers from '../withModifiers';
// eslint-disable-next-line import/no-named-as-default
import withPadding from '../withPadding';

interface Props {
  autoComplete?: string;
  /** Is this input disabled? */
  disabled?: boolean;
  /** Is this input currently valid? */
  error?: boolean;
  focus?: boolean;
  maxlength?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onIconClick?(): void;
  /** Placeholder text for input */
  placeholder?: string;
  style?: React.CSSProperties;
  /** What type of input it is */
  type?: string;
  value?: string;
  className?: string;
  id?: string;
  autoFocus?: boolean;
  icon?: React.ReactNode;
  ref?: React.RefObject<HTMLInputElement>;
}

/**
 * Input
 * generic form input for text
 */
export class Input extends Component<Props> {
  Element?: HTMLInputElement;
  static baseClass = 'tk-form-control';

  static propTypes = {
    autoComplete: PropTypes.string,
    /** Is this input disabled? */
    disabled: PropTypes.bool,
    /** Is this input currently valid? */
    error: PropTypes.bool,
    focus: PropTypes.bool,
    maxlength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,

    /** Placeholder text for input */
    placeholder: PropTypes.string,
    style: PropTypes.object,
    /** What type of input it is */
    type: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    autoFocus: PropTypes.bool,
    icon: PropTypes.node,
    onIconClick: PropTypes.func,
  };

  static defaultProps = {
    autoComplete: 'on',
    disabled: false,
    error: false,
    focus: false,
    maxlength: undefined,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyPress: () => {},
    onKeyUp: () => {},
    onIconClick: () => {},
    placeholder: undefined,
    style: {},
    type: 'text',
    value: '',
    className: '',
    id: undefined,
    autoFocus: false,
    icon: null,
  };

  componentDidMount = () => {
    const { focus } = this.props;
    document.addEventListener('keyup', this.handleKeyUp, false);

    if (focus) {
      this.setFocus(focus);
    }
  };

  componentDidUpdate = prevProps => {
    const { focus: prevFocus } = prevProps;
    const { focus } = this.props;

    if (focus !== prevFocus) {
      this.setFocus(focus);
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('keyup', this.handleKeyUp);
  };

  setFocus = focus => {
    if (focus) {
      this.focus();
    } else {
      this.blur();
    }
  };

  setElement = element => {
    this.Element = element;
  };

  handleKeyUp = event => {
    if (this.Element === document.activeElement) {
      if (event.key === 'Escape') {
        this.blur();
      }
    }
  };

  blur = () => {
    if (this.Element?.blur) {
      this.Element.blur();
    }
  };

  focus = () => {
    if (this.Element?.focus) {
      this.Element.focus();
      if (this.props.type === 'text') {
        const valueLength = this.Element.value.length;
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          this.Element?.setSelectionRange(valueLength, valueLength);
        }, 0);
      }
    }
  };

  render() {
    const {
      className,
      disabled,
      error,
      focus,
      maxlength: maxLength,
      icon,
      onIconClick,
      ...passedProps
    } = this.props;

    const mainClass = classnames(Input.baseClass, className, {
      [`${Input.baseClass}--disabled`]: disabled,
      [`${Input.baseClass}--error`]: error,
      [`${Input.baseClass}--focus`]: focus,
    });

    const inputWithIconClass = classnames('form-control', {
      'form-control--disabled': disabled,
      'form-control--error': error,
      'form-control--focus': focus,
    });

    const inputWithIcon = (
      <div className={classnames('form-control-container')}>
        <input
          {...passedProps}
          className={inputWithIconClass}
          disabled={disabled}
          maxLength={maxLength}
          ref={this.setElement}
        />
        <div
          data-qa="vR7frlzVqnrHU_PssVQnE"
          className="form-calendar-icon"
          onClick={onIconClick}
        >
          {icon}
        </div>
      </div>
    );

    const inputWithOutIcon = (
      <input
        {...passedProps}
        className={mainClass}
        disabled={disabled}
        maxLength={maxLength}
        ref={this.setElement}
      />
    );

    return icon ? inputWithIcon : inputWithOutIcon;
  }
}

export default withPadding(withModifiers(Input), { padding: '8px 20px' });
