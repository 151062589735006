import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  DisplaySM,
  ConfirmModal,
  Media,
  StatusBar,
  SvgIcon,
} from '@trendkite/ui';

import Attachment from 'components/outreach/attachment';
import { removeAttachmentActionCreator } from 'reducers/outreach/compose-form';

const mapStateToProps = state => ({
  attachments: state.outreachComposeForm.attachments,
  fileSizeError:
    state.forms.outreachComposeForm.attachments &&
    state.forms.outreachComposeForm.attachments.$form.errors.fileSize,
});

const mapDispatchToProps = dispatch => ({
  onRemoveAttachment: index => dispatch(removeAttachmentActionCreator(index)),
});

class EmailAttachmentsGroup extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.fileSizeError &&
      !prevState.prevFileSizeError &&
      !prevState.openError
    ) {
      return {
        openError: true,
        prevFileSizeError: nextProps.fileSizeError,
      };
    }

    return {
      prevFileSizeError: nextProps.fileSizeError,
    };
  }

  state = {
    openError: false,
    prevFileSizeError: this.props.fileSizeError,
  };

  handleConfirm = () => {
    this.setState({ openError: false });
  };

  handleRemoveAttachment = (event, index) => {
    event.stopPropagation();
    this.props.onRemoveAttachment(index);
  };

  renderAttachment(attachment, index) {
    const removeHandler = e => this.handleRemoveAttachment(e, index);
    if (attachment.error) {
      return (
        <StatusBar type="danger" onCancel={removeHandler}>
          <Media>
            <Media.Item>
              <SvgIcon color="danger" icon="notice" height={24} width={24} />
            </Media.Item>
            <Media.Body style={{ marginLeft: '8px' }}>
              <DisplaySM>
                &ldquo;{attachment.filename}&rdquo; failed to upload. Please
                remove and try again.
              </DisplaySM>
            </Media.Body>
          </Media>
        </StatusBar>
      );
    }
    return (
      <Attachment
        isLoading={attachment.loading}
        removable
        filename={attachment.filename}
        filetype={attachment.contentType}
        size={attachment.size}
        loadingPercent={attachment.progress}
        onRemove={removeHandler}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.attachments.map((f, i) => (
          <div
            key={f.tempId || f.id}
            className="tk-email-attachments-group__attachment"
          >
            {this.renderAttachment(f, i)}
          </div>
        ))}
        <ConfirmModal
          isOpen={this.state.openError}
          header="Message Size Limit Exceeded"
          message="Your message has exceeded the 25 MB limit. Please reduce your number of attachments."
          status="danger"
          confirmButtonText="OK"
          onConfirm={this.handleConfirm}
        />
      </React.Fragment>
    );
  }
}

EmailAttachmentsGroup.propTypes = {
  onRemoveAttachment: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  fileSizeError: PropTypes.bool,
};

EmailAttachmentsGroup.defaultProps = {
  attachments: [],
  fileSizeError: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailAttachmentsGroup);
