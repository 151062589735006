import { combineReducers } from 'redux';

import filters from './filtersReducer';
import searchBuilder from './searchBuilderReducer';
import searches from './searchesReducer';

export default combineReducers({
  searchBuilder,
  filters,
  searches,
});
