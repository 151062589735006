// import {
//   INFLUENCER_HUB_ENDPOINT,
//   INFLUENCER_SAVED_LISTS_LIST_MEMBERS_ENDPOINT,
// } from 'constants/apis';
// import { IHUB_ADVANCED_SEARCH_TYPES, IHUB_SORT_BY_TYPES } from 'constants/constants';

// import { fullSearchCriteriaSelector } from '../Search/selectors';
// import {
//   INFLUENCER_GET_PRIVATE_INFLUENCERS_URL,
// } from 'constants/apis';

// import { performPost } from 'services/rest-service/rest-service';

import {
  Influencer,
  PrivateInfluencerState,
  PrivateInfluencerFormActionTypes,
  UPDATE_FORM_FIELDS,
  CLEAR_FORM_FIELDS,
  PRIVATE_INFLUENCERS_LIST_START,
  PRIVATE_INFLUENCERS_LIST_SUCCESS,
  PRIVATE_INFLUENCERS_LIST_ERROR,
} from './types';

const initialState: PrivateInfluencerState = {
  influencer: new Influencer(),
  loading: false,
  error: false,
  privateList: {
    totalhits: 0,
    data: [],
  },
  topics: {
    data: [],
  },
};

const privateInfluencerReducer = (
  state = { ...initialState },
  action: PrivateInfluencerFormActionTypes,
): PrivateInfluencerState => {
  switch (action.type) {
    case UPDATE_FORM_FIELDS:
      return {
        ...state,
        influencer: {
          ...state.influencer,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };

    case CLEAR_FORM_FIELDS:
      return initialState;

    case PRIVATE_INFLUENCERS_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
        privateList: {
          ...state.privateList,
          data: [...(!action.payload?.append ? [] : state.privateList.data)],
        },
      };
    }

    case PRIVATE_INFLUENCERS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        privateList: {
          ...state.privateList,
          totalhits: action.payload.totalhits ?? 0,
          data: [
            ...(action.payload.append ? state.privateList.data : []),
            ...action.payload.data,
          ],
        },
      };
    }

    case PRIVATE_INFLUENCERS_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        privateList: {
          ...state.privateList,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};

export default privateInfluencerReducer;
