export const CAMPAIGN_UPDATE_SELECTED_SEARCH =
  'campaign-sidebar/CAMPAIGN_UPDATE_SELECTED_SEARCH';
export const TOGGLE_SIDEBAR = 'campaigns-sidebar/TOGGLE_SIDEBAR';
export const SET_CONTENT = 'campaign-sidebar/SET_CONTENT';
export const ADD_SELECTED_SEARCHID = 'campaign-sidebar/ADD_SELECTED_SEARCHID';
export const REMOVE_SELECTED_SEARCHID =
  'campaign-sidebar/REMOVE_SELECTED_SEARCHID';
export const RESET_SIDEBAR = 'campaign-sidebar/RESET_SIDEBAR';
export const RESET_SELECTED_SEARCH_IDS =
  'campaign-sidebar/RESET_SELECTED_SEARCH_IDS';
export const UPDATE_CONTACTS_SIDEBAR =
  'campaign-sidebar/UPDATE_CONTACTS_SIDEBAR';

export const initialState = {
  open: false,
  content: 'default',
  selectedSearchIds: [],
  contacts: {
    selected: [],
    query: '',
    filters: [],
  },
  willReset: false,
};

const campaignSideBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SIDEBAR: {
      return { ...initialState, willReset: true };
    }

    case RESET_SELECTED_SEARCH_IDS: {
      return { ...state, selectedSearchIds: [] };
    }

    case CAMPAIGN_UPDATE_SELECTED_SEARCH: {
      return {
        ...state,
        selectedSearchIds: action.payload.selectedSearchIds,
        loading: false,
        error: false,
      };
    }

    case ADD_SELECTED_SEARCHID: {
      const selectedSearchIds = [
        ...state.selectedSearchIds,
        action.payload.searchId,
      ];
      return {
        ...state,
        selectedSearchIds,
        loading: false,
        error: false,
      };
    }

    case REMOVE_SELECTED_SEARCHID: {
      const { searchId } = action.payload;
      const selectedSearchIds = [
        ...state.selectedSearchIds.filter(id => id !== searchId),
      ];

      return {
        ...state,
        selectedSearchIds,
        loading: false,
        error: false,
      };
    }

    case TOGGLE_SIDEBAR:
      return { ...state, open: !state.open, willReset: false };

    case SET_CONTENT:
      return { ...state, content: action.payload.content };

    case UPDATE_CONTACTS_SIDEBAR:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          ...action.payload.sidebar,
        },
      };

    default:
      return state;
  }
};

export const resetSidebar = () => ({ type: RESET_SIDEBAR });

export const resetSelectedSearchIds = () => ({
  type: RESET_SELECTED_SEARCH_IDS,
});

export const setSelectedSearchIds = newSearches => {
  return {
    type: CAMPAIGN_UPDATE_SELECTED_SEARCH,
    payload: { selectedSearchIds: newSearches },
  };
};

export const addSelectedSearchId = searchId => {
  return { type: ADD_SELECTED_SEARCHID, payload: { searchId } };
};

export const removeSelectedSearchId = searchId => {
  return { type: REMOVE_SELECTED_SEARCHID, payload: { searchId } };
};

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });

export const setContent = content => ({
  type: SET_CONTENT,
  payload: { content },
});

export const updateContactsSidebar = sidebar => {
  return { type: UPDATE_CONTACTS_SIDEBAR, payload: { sidebar } };
};

export default campaignSideBarReducer;
