import React from 'react';

import PropTypes from 'prop-types';

import { IconButton } from '../button';

const LargeCheckbox = ({ disabled, size, selected, onClick, id, dataQA }) => {
  const icon = selected ? 'checkmark' : 'plus';
  const color = selected ? 'primaryGreen' : undefined;
  return (
    <IconButton
      id={id}
      onClick={onClick}
      size={size}
      icon={icon}
      color={color}
      disabled={disabled}
      data-qa={dataQA}
    />
  );
};

LargeCheckbox.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'large']),
  id: PropTypes.string,
  dataQA: PropTypes.string,
};

LargeCheckbox.defaultProps = {
  disabled: false,
  selected: false,
  onClick: () => {},
  size: 'large',
  id: undefined,
  dataQA: '',
};

export default LargeCheckbox;
