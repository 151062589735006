import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from '../svg-icon';

const baseClass = 'tk-grid-table__cell';

const renderStatusArrow = (isHeader, isSorting, sortable, sortDirection) => {
  if (
    !isHeader ||
    !isSorting ||
    !sortable ||
    !['asc', 'desc'].includes(sortDirection)
  ) {
    return null;
  }

  const icon = sortDirection === 'asc' ? 'chevronUp' : 'chevronDown';
  return (
    <div className={`${baseClass}__arrow`}>
      <SvgIcon icon={icon} modifiers={['left-small', 'sub']} />
    </div>
  );
};

const GridTableCell = ({
  children,
  isHeader,
  isSorting,
  onClick,
  modifiers,
  sortable,
  sortDirection,
  ...passedProps
}) => {
  const prefixedModifiers = Array.isArray(modifiers)
    ? modifiers.map(modifier => `${baseClass}--${modifier}`)
    : [];

  const mainClass = classnames(baseClass, prefixedModifiers, {
    [`${baseClass}--clickable`]: onClick,
    [`${baseClass}--sortable`]: isHeader && sortable,
    [`${baseClass}--active`]: isHeader && sortable && isSorting,
  });

  const arrow = renderStatusArrow(isHeader, isSorting, sortable, sortDirection);

  const clickProps = {};
  if (onClick) {
    clickProps.onClick = onClick;
    clickProps.role = 'button';
    clickProps.tabIndex = '-1';
  }

  return (
    <div className={mainClass} {...passedProps} {...clickProps}>
      <div className={`${baseClass}__content`}>
        {children}
        {arrow}
      </div>
    </div>
  );
};

GridTableCell.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  isHeader: PropTypes.bool,
  isSorting: PropTypes.bool,
  onClick: PropTypes.func,
  modifiers: PropTypes.array,
  sortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc', '']),
};

GridTableCell.defaultProps = {
  children: null,
  id: '',
  isHeader: false,
  isSorting: false,
  onClick: null,
  modifiers: [],
  sortable: false,
  sortDirection: '',
};

export default GridTableCell;
