import React from 'react';

import PropTypes from 'prop-types';

import { CustomPicker } from 'react-color';
import {
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';
import { useIntl } from 'react-intl';

import Button from '../../button';
import { Body } from '../../type';

import Pointer from './Pointer.jsx';
import messages from './SimpleColorPicker.messages';
import './simple-color-picker.scss';

const resetButtonModifiers = ['round', 'tertiary'];

const SimpleColorPicker = props => {
  const intl = useIntl();
  const { hex } = props.color;
  const { descriptionText, resetButtonText, onResetColor } = props;
  const styles = {
    swatch: { background: hex },
  };
  return (
    <div className="tk-simple-color-picker">
      <div className="tk-simple-color-picker__saturation">
        <Saturation {...props} />
      </div>
      <div className="tk-simple-color-picker__hue">
        <Hue {...props} direction="vertical" pointer={Pointer} />
      </div>
      <div className="tk-simple-color-picker__legend">
        <Body className="tk-simple-color-picker__description">
          {descriptionText}
        </Body>
        <Body>{intl.formatMessage(messages.hexCode)}</Body>
        <div className="tk-simple-color-picker__editable-input-group">
          <EditableInput
            className="tk-simple-color-picker__editable-input"
            value={props.hex}
            onChange={props.editableBoxHandler}
          />
          <div
            style={styles.swatch}
            className="tk-simple-color-picker__swatch"
          />
        </div>
        <Button
          data-qa="UwYU7hi7tVQGiQRwxhpC1"
          className="tk-simple-color-picker__reset_button"
          modifiers={resetButtonModifiers}
          onClick={onResetColor}
        >
          {resetButtonText}
        </Button>
      </div>
    </div>
  );
};

SimpleColorPicker.propTypes = {
  onChangeComplete: PropTypes.func.isRequired,
  editableBoxHandler: PropTypes.func.isRequired,
  hex: PropTypes.string,
  color: PropTypes.shape({
    hex: PropTypes.string,
  }).isRequired,
  descriptionText: PropTypes.string.isRequired,
  resetButtonText: PropTypes.string.isRequired,
  onResetColor: PropTypes.func.isRequired,
};

SimpleColorPicker.defaultProps = {
  onChangeComplete: null,
  hex: '',
};

export default CustomPicker(SimpleColorPicker);
