import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '../forms/Checkbox';
import RadioButton from '../radio-button';
import SvgIcon from '../svg-icon';

const ListEntrySmall = ({
  entry,
  onSelect,
  className,
  multiselect,
  removeIcon,
  scrollIntoView,
  selected,
  disabled,
  ...passedProps
}) => {
  const buttonRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const addingDisabled = useMemo(() => disabled && disabled.max, [disabled]);

  const handleSelect = useCallback(
    e => {
      e.stopPropagation();
      onSelect(entry);
    },
    [entry, onSelect],
  );

  const { icon, title, subtitle, svgIcon, dataQA } = entry;
  const classes = classNames(
    'tk-list-entry',
    'tk-list-entry--small',
    className,
  );

  useEffect(() => {
    if (scrollIntoView) {
      if (!multiselect && selected && !scrolled) {
        setScrolled(true);
        buttonRef.current.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [multiselect, scrollIntoView, scrolled, selected]);

  return (
    <button
      data-qa="YyqeuViQjCgUjMwzhx6cE"
      className={classes}
      onClick={handleSelect}
      type="button"
      ref={buttonRef}
      {...passedProps}
    >
      {!multiselect && (
        <div className="tk-list-entry__single-select">
          <RadioButton selected={selected} dataQA={dataQA} />
        </div>
      )}
      {multiselect && (
        <>
          {addingDisabled && !selected ? (
            <div className="tk-list-entry__checkbox">
              <Checkbox disabled />
            </div>
          ) : (
            <div className="tk-list-entry__checkbox">
              <Checkbox
                data-qa="KkDZiZ27wu_2z4dZbEN4S"
                onClick={
                  handleSelect /*SmallCheckboxes don't bubble native click events*/
                }
                selected={selected}
              />
            </div>
          )}
        </>
      )}
      {icon && (
        <div className="tk-list-entry__icon">
          <i className={icon} />
        </div>
      )}
      {svgIcon && (
        <div className="tk-list-entry__icon">
          <SvgIcon icon={svgIcon} />
        </div>
      )}
      <div className="tk-list-entry__data">
        <div className="tk-list-entry__data-title">{title}</div>
        {subtitle && (
          <div className="tk-list-entry__data-subtitle">{subtitle}</div>
        )}
      </div>
    </button>
  );
};

ListEntrySmall.defaultProps = {
  className: '',
  disabled: undefined,
  multiselect: false,
  onSelect: () => {},
  removeIcon: false,
  selected: false,
  scrollIntoView: false,
};

ListEntrySmall.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.shape({
    max: PropTypes.bool,
  }),
  entry: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    svgIcon: PropTypes.string,
    dataQA: PropTypes.string,
  }).isRequired,
  multiselect: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  removeIcon: PropTypes.bool,
  selected: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
};

export default ListEntrySmall;
