export const SHOW_CONTACT_LIST_DRAWER =
  'contact-lists-drawer/SHOW_CONTACT_LIST_DRAWER';

const defaultState = {
  authorId: undefined,
  authorName: undefined,
  clearSelectedContactsOnSuccess: false,
  listIsRequested: false,
  hasContactMethods: false,
  contacts: [],
  selectedLists: [],
  visible: false,
};

const contactListsDrawer = (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_CONTACT_LIST_DRAWER: {
      return {
        ...state,
        visible: true,
        clearSelectedContactsOnSuccess:
          action.payload.clearSelectedContactsOnSuccess,
        contacts: action.payload.contacts,
      };
    }
    case 'SHOW_CONTACT_LISTS_DRAWER': {
      return {
        ...state,
        visible: true,
        authorId: action.authorId,
        authorName: action.authorName,
        hasContactMethods: action.hasContactMethods,
        listIsRequested: action.listIsRequested,
      };
    }
    case 'HIDE_CONTACT_LISTS_DRAWER': {
      return {
        ...state,
        visible: false,
        authorId: undefined,
        authorName: undefined,
        hasContactMethods: false,
        listIsRequested: false,
      };
    }
    default:
      return state;
  }
};

export const showContactListsDrawer = (
  contacts,
  clearSelectedContactsOnSuccess = true,
) => ({
  type: SHOW_CONTACT_LIST_DRAWER,
  payload: { contacts, clearSelectedContactsOnSuccess },
});

export default contactListsDrawer;
