import React from 'react';
import PropTypes from 'prop-types';

const Addon = ({ children }) => {
  const baseClass = 'tk-buttonAddon';
  return <span className={baseClass}>{children}</span>;
};

Addon.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Addon;
