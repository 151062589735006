import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const FormattingUnderline = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z" />
  </svg>
);
FormattingUnderline.propTypes = { fill: PropTypes.string };
FormattingUnderline.defaultProps = { fill: iconColorsMap.mainicon };

export default FormattingUnderline;
