export default {
  copy: {
    id: 'REPORT_SIDEBAR_COPY',
    description: 'Copy text on sidebar slide kebab menu',
    defaultMessage: 'Copy',
  },
  delete: {
    id: 'REPORT_SIDEBAR_DELETE',
    description: 'Delete text on sidebar slide kebab menu',
    defaultMessage: 'Delete',
  },
  newSlideCaption: {
    id: 'REPORT_SIDEBAR_NEW_SLIDE_CAPTION',
    description: 'Default title for new slides',
    defaultMessage: 'Custom Slide',
  },
};
