import moment from 'moment';

import { NEW_DASHBOARD_ENDPOINT } from 'constants/apis';
import { performPut } from 'services/rest-service/rest-service';

import { processAnalyticResults } from './analytics-list-reducer';

export const LAST_VIEWED_ANALYTICS = 'analytics/LAST_VIEWED_ANALYTICS';
export const LAST_VIEWED_ANALYTICS_SUCCESS =
  'analytics/LAST_VIEWED_ANALYTICS_SUCCESS';
export const LAST_VIEWED_ANALYTICS_ERROR =
  'analytics/LAST_VIEWED_ANALYTICS_ERROR';
export const SET_LAST_VIEWED_ANALYTIC = 'analytics/SET_LAST_VIEWED_ANALYTIC';
export const SET_LAST_VIEWED_ANALYTIC_SUCCESS =
  'analytics/SET_LAST_VIEWED_ANALYTIC_SUCCESS';
export const SET_LAST_VIEWED_ANALYTIC_ERROR =
  'analytics/SET_LAST_VIEWED_ANALYTIC_ERROR';

export const initialState = {
  error: false,
  loading: true,
  items: [],
};

const insertAnalytic = (state, itemToInsert) => {
  return state.items.map(item =>
    item.id === itemToInsert.id ? itemToInsert : item,
  );
};

const recentlyViewedAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAST_VIEWED_ANALYTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LAST_VIEWED_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.items,
      };
    case LAST_VIEWED_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SET_LAST_VIEWED_ANALYTIC:
      return {
        ...state,
        updatingViewedAnalytic: true,
        updatingViewedAnalyticError: null,
      };
    case SET_LAST_VIEWED_ANALYTIC_SUCCESS:
      return {
        ...state,
        updatingViewedAnalytic: false,
        updatingViewedAnalyticError: null,
        items: insertAnalytic(state, action.payload.viewedItem),
      };
    case SET_LAST_VIEWED_ANALYTIC_ERROR:
      return {
        ...state,
        updatingViewedAnalytic: false,
        updatingViewedAnalyticError: true,
      };
    default:
      return state;
  }
};

export const recentlyViewedAnalyticsActionCreator = ({
  endpointBase = NEW_DASHBOARD_ENDPOINT,
  pageNumber = 1,
  sort = 'lastUpdated',
  sortDirection = 'desc',
} = {}) => async dispatch => {
  const params = { pageNumber, sort, sortDirection };
  dispatch({ type: LAST_VIEWED_ANALYTICS });

  try {
    const { items } = await processAnalyticResults({ params, endpointBase });
    dispatch({
      type: LAST_VIEWED_ANALYTICS_SUCCESS,
      payload: { items: items },
    });
  } catch (e) {
    dispatch({
      type: LAST_VIEWED_ANALYTICS_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export const setLastViewedAnalyticActionCreator = (
  id,
  endpointBase = NEW_DASHBOARD_ENDPOINT,
) => async dispatch => {
  dispatch({ type: SET_LAST_VIEWED_ANALYTIC });

  try {
    const result = await performPut(`${endpointBase}/${id}/viewed`, {
      lastViewed: moment.utc().format(),
    });

    dispatch({
      type: SET_LAST_VIEWED_ANALYTIC_SUCCESS,
      payload: { viewedItem: result.data },
    });
  } catch (e) {
    dispatch({
      type: SET_LAST_VIEWED_ANALYTIC_ERROR,
      payload: {
        error: e.response?.data,
      },
    });
  }
};

export default recentlyViewedAnalyticReducer;
