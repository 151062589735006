import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { Errors } from 'react-redux-form';

import SvgIcon from '../svg-icon';

import ErrorWithIntl from './ErrorWithIntl';

// i18n

const EditableAreaError = props => (
  <ErrorWithIntl block padding="5px 0 0" {...props} />
);

const withEditableArea = (
  DisplayComponent,
  EditingComponent,
  WrapperComponent,
) => {
  class EditableAreaComponent extends Component {
    static baseClass = 'tk-editable-area';

    state = {
      focus: false,
      isEditing: false,
      height: 0,
    };

    componentDidMount = () => {
      document.addEventListener('keyup', this.onKeyUp, false);
      if (
        this.element &&
        this.element.clientHeight !== this.state.clientHeight
      ) {
        this.setState({ height: this.element.clientHeight });
      }
    };

    componentWillUnmount = () => {
      document.removeEventListener('keyup', this.onKeyUp, false);
    };

    onKeyUp = event => {
      if (
        !this.state.isEditing &&
        event.key === 'Enter' &&
        this.button &&
        document.activeElement === this.button
      ) {
        this.onClickedArea();
      }
    };

    onClickedArea = () => {
      this.setState({ isEditing: true, focus: true });
      if (this.editingComponentRef && this.editingComponentRef.startEditing) {
        this.editingComponentRef.startEditing();
      }
      this.props.onStartEditing();
      document.removeEventListener('keyup', this.onKeyUp, false);
    };

    onEndEditing = () => {
      this.setState({ isEditing: false, focus: false });
      this.props.onStopEditing();
      document.addEventListener('keyup', this.onKeyUp, false);
    };

    onSubmit = event => {
      if (this.state.isEditing) {
        this.props.onSubmit(event);
      }
    };

    wrapIfExists(node) {
      if (WrapperComponent) {
        return <WrapperComponent>{node}</WrapperComponent>;
      }
      return node;
    }

    renderWrappedDisplayComponent() {
      const { isEditable } = this.props;
      const { height } = this.state;

      // center icon if small area
      const iconStyle = {};
      if (height < 20 * 2) {
        iconStyle.top = '50%';
        iconStyle.transform = 'translateY(-50%)';
      }

      const displayBaseClass = `${EditableAreaComponent.baseClass}__display`;
      const displayClass = classNames(displayBaseClass, {
        [`${displayBaseClass}--disabled`]: !isEditable,
      });

      let displayProps = {
        className: displayClass,
        ref: ch => (this.button = ch),
      };

      if (isEditable) {
        displayProps = {
          ...displayProps,
          role: 'button',
          tabIndex: 0,
          onClick: this.onClickedArea,
        };
      }

      const displayComponent = this.wrapIfExists(
        <DisplayComponent {...this.props} />,
      );

      return (
        <div {...displayProps}>
          {displayComponent}
          {isEditable && (
            <div
              className={`${EditableAreaComponent.baseClass}__indicator`}
              style={iconStyle}
            >
              <SvgIcon icon="pencil" width={18} height={18} />
            </div>
          )}
        </div>
      );
    }

    renderWrappedEditingComponent() {
      const { inputModelName } = this.props;
      const { isEditing, focus } = this.state;

      const editorClass = classNames(
        `${EditableAreaComponent.baseClass}__editor`,
        {
          [`${EditableAreaComponent.baseClass}__editor--hidden`]: !isEditing,
        },
      );

      return (
        <div className={editorClass}>
          {this.wrapIfExists(
            <EditingComponent
              {...this.props}
              focus={focus}
              inputModelName={inputModelName}
              onEndEditing={this.onEndEditing}
              onSubmit={this.onSubmit}
              ref={r => (this.editingComponentRef = r)}
            />,
          )}
        </div>
      );
    }

    renderErrors = () => {
      const { customErrors, errorMessages, inputModelName } = this.props;

      return (
        <div className={`${EditableAreaComponent.baseClass}__errors`}>
          {inputModelName && (
            <Errors
              component={EditableAreaError}
              model={`.${inputModelName}`}
              show="touched"
              messages={errorMessages}
            />
          )}
          {customErrors}
        </div>
      );
    };

    render() {
      const { isEditable } = this.props;
      const { isEditing } = this.state;
      const wrappedEditingComponent = isEditable
        ? this.renderWrappedEditingComponent()
        : null;
      const wrappedDisplayComponent = !isEditing
        ? this.renderWrappedDisplayComponent()
        : null;
      const errors = !isEditing ? this.renderErrors() : null;
      const mainClass = classNames(EditableAreaComponent.baseClass, {
        [`${EditableAreaComponent.baseClass}--editing`]: isEditing,
      });

      return (
        <div className={mainClass} ref={ref => (this.element = ref)}>
          {wrappedEditingComponent}
          {wrappedDisplayComponent}
          {errors}
        </div>
      );
    }
  }

  const displayComponentName =
    DisplayComponent.displayName || DisplayComponent.name || 'DisplayComponent';
  const editingComponentName =
    EditingComponent.displayName || EditingComponent.name || 'EditingComponent';
  const wrapperComponentName =
    WrapperComponent &&
    (WrapperComponent.displayName ||
      WrapperComponent.name ||
      'WrapperComonent');
  EditableAreaComponent.displayName = `withEditableArea(${displayComponentName}, ${editingComponentName}, ${wrapperComponentName})`;

  EditableAreaComponent.propTypes = {
    customErrors: PropTypes.node,
    errorMessages: PropTypes.object,
    inputModelName: PropTypes.string,
    isEditable: PropTypes.bool,
    onStartEditing: PropTypes.func,
    onStopEditing: PropTypes.func,
    onSubmit: PropTypes.func,
    updateOn: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
  };

  EditableAreaComponent.defaultProps = {
    customErrors: null,
    errorMessages: {},
    inputModelName: '',
    isEditable: false,
    onStartEditing: () => {},
    onStopEditing: () => {},
    onSubmit: () => {},
    updateOn: 'change',
  };

  return injectIntl(EditableAreaComponent);
};

export default withEditableArea;
