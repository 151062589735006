import React from 'react';
import PropTypes from 'prop-types';

import withModifiers from '../withModifiers';

export const Toolbar = ({ children, className, id }) => {
  const toolbarId = id || 'wysiwyg-toolbar';

  if (Array.isArray(children)) {
    children = children.map((child, index) => (
      <div
        className={`${Toolbar.baseClass}__tool`}
        key={`tool-${child.key ? child.key : index}`}
      >
        {child}
      </div>
    ));
  }

  return (
    <div className={className} id={toolbarId}>
      {children}
    </div>
  );
};

Toolbar.baseClass = 'tk-toolbar';

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  /** modifiers will be handled by withModifiers but are left here for documentation purposes */
  modifiers: PropTypes.arrayOf(
    PropTypes.oneOf([
      // eslint-disable-line react/no-unused-prop-types
      'wrap',
    ]),
  ),
};

Toolbar.defaultProps = {
  className: '',
  id: '',
  modifiers: [],
};

export default withModifiers(Toolbar);
