export default {
  titleDataSection: {
    id: 'TITLE_DATA_SECTION_WIZARD',
    description: 'The title of the data section',
    defaultMessage: 'Data',
  },
  searchesDataSectionSubtitle: {
    id: 'SIDEBAR_SEARCHES_DATA_SECTION_WIZARD_SUBTITLE',
    description: 'The subtitle of the data section for searches',
    defaultMessage: `Select {DATA_LIMIT, plural,
      one {a search}
      other {up to # searches}
     }`,
  },
  tagsDataSectionSubtitle: {
    id: 'SIDEBAR_TAGS_DATA_SECTION_WIZARD_SUBTITLE',
    description: 'The subtitle of the data section for tags',
    defaultMessage: `Select {DATA_LIMIT, plural,
     one {a tag}
     other {up to # tags}
    }`,
  },
  searchesLabelButtonDataSection: {
    id: 'SEARCHES_LABEL_BUTTON_DATA_SECTION',
    description:
      'The label of the button in the data section when the widget uses searches',
    defaultMessage: 'Add Searches',
  },
  tagsLabelButtonDataSection: {
    id: 'TAGS_LABEL_BUTTON_DATA_SECTION',
    description:
      'The label of button in the data section when the widget uses tags',
    defaultMessage: 'Add Tags',
  },
  titleChartSection: {
    id: 'CHART_SECTION_TITLE',
    description: 'The title of the chart section',
    defaultMessage: 'Chart type',
  },
  subtitleChartSection: {
    id: 'CHART_SECTION_SUBTITLE',
    description: 'The subtitle of the chart section',
    defaultMessage: 'Metric {METRIC_NUMBER}: selecting',
  },
  dropdownsWordCloud: {
    id: 'DROPDOWN_CHART_WORD_CLOUD',
    description: 'Word cloud dropdown option name',
    defaultMessage: 'Word cloud',
  },
  dropdownsChartArea: {
    id: 'DROPDOWN_CHART_AREA',
    description: 'Area dropdown option name',
    defaultMessage: 'Area Chart',
  },
  dropdownsChartDonut: {
    id: 'DROPDOWN_CHART_DONUT',
    description: 'Donut dropdown option name',
    defaultMessage: 'Donut Chart',
  },
  dropdownsChartLine: {
    id: 'DROPDOWN_CHART_LINE',
    description: 'Line dropdown option name',
    defaultMessage: 'Line Chart',
  },
  dropdownsHorizontalBar: {
    id: 'DROPDOWN_CHART_HORIZONTAL_BAR',
    description: 'Horizontal bar dropdown option name',
    defaultMessage: 'Horizontal Bar Chart',
  },
  dropdownsVerticalBar: {
    id: 'DROPDOWN_CHART_VERTICAL_BAR',
    description: 'Vertical bar dropdown option name',
    defaultMessage: 'Vertical Bar Chart',
  },
  dropdownsMap: {
    id: 'DROPDOWN_CHART_MAP',
    description: 'Map chart dropdown option name',
    defaultMessage: 'Map',
  },
  dropdownsArticleList: {
    id: 'ARTICLE_LIST_CHART_MAP',
    description: 'article list chart dropdown option name',
    defaultMessage: 'ArticleList',
  },
  totalCountCategory: {
    id: 'TOTAL_COUNT_CATEGORY',
    description: 'Total Count category',
    defaultMessage: 'Total Count',
  },
  trendOverTimeCategory: {
    id: 'TREND_OVER_TIME_CATEGORY',
    description: 'Trend over time category',
    defaultMessage: 'Trend Over Time',
  },
  adobeAnalyticsCategory: {
    id: 'ADOBE_ANALYTICS_CATEGORY',
    description: 'Adobe Analytics category',
    defaultMessage: 'Adobe Analytics',
  },
  googleAnalyticsCategory: {
    id: 'GOOGLE_ANALYTICS_CATEGORY',
    description: 'Google Analytics category',
    defaultMessage: 'Google Analytics',
  },
  multiDimensionalCategory: {
    id: 'MULTI_DIMENSIONAL_CATEGORY',
    description: 'Multi dimensional category',
    defaultMessage: 'Multi Dimensional(apply to all metrics)',
  },
  titleMetricSection: {
    id: 'METRIC_SECTION_TITLE',
    description: 'The title of the metric section',
    defaultMessage: 'Metrics',
  },
  subtitleMetricSection: {
    id: 'METRIC_SECTION_SUBTITLE',
    description: 'The subtitle of the metric section',
    defaultMessage: 'Select a metric',
  },
  subtitleEmptyMetricSection: {
    id: 'EMPTY_METRIC_SECTION_SUBTITLE',
    description: 'The subtitle of the metric section if empty metrics',
    defaultMessage: 'Select up to 2 metrics',
  },
  dropdownMetricEarned: {
    id: 'DROPDOWN_METRIC_EARNED',
    description: 'Earned dropdown metric option name',
    defaultMessage: 'Earned Media Mentions',
  },
  dropdownSocialAmp: {
    id: 'DROPDOWN_METRIC_SOCIAL_AMP',
    description: 'Social amplification metric option name',
    defaultMessage: 'Social Shares',
  },
  dropdownWorldCloud: {
    id: 'DROPDOWN_METRIC_WORD_CLOUD',
    description: 'Word cloud metric option name',
    defaultMessage: 'Word',
  },
  dropdownSocialSearch: {
    id: 'DROPDOWN_METRIC_SOCIAL_SEARCH',
    description: 'Social search metric option name',
    defaultMessage: 'Total Posts',
  },
  dropdownSentiment: {
    id: 'DROPDOWN_METRIC_SENTIMENT',
    description: 'Sentiment metric option name',
    defaultMessage: 'Sentiment',
  },
  dropdownArticleImpact: {
    id: 'DROPDOWN_METRIC_AVG_ARTICLE_IMPACT',
    description: 'Average article impact option name',
    defaultMessage: 'Article Impact',
  },
  dropdownsReadership: {
    id: 'DROPDOWN_METRIC_READERSHIP',
    description: 'Readership dropdown metric option name',
    defaultMessage: 'Readership',
  },
  dropdownsMobileReadership: {
    id: 'DROPDOWN_METRIC_MOBILE_READERSHIP',
    description: 'Mobile readership dropdown metric option name',
    defaultMessage: 'Mobile Readership',
  },

  dropdownsTotalReadership: {
    id: 'DROPDOWN_METRIC_TOTAL_READERSHIP',
    description: 'Total readership dropdown metric option name',
    defaultMessage: 'Total Readership',
  },
  dropdownsMobileReadershipDescription: {
    id: 'DROPDOWN_METRIC_MOBILE_READERSHIP_DESCRIPTION',
    description: 'Mobile readership dropdown metric option description',
    defaultMessage: 'Online potential audience for mobile devices',
  },
  dropdownsTotalReadershipDescription: {
    id: 'DROPDOWN_METRIC_TOTAL_READERSHIP_DESCRIPTION',
    description: 'Total readership dropdown metric option description',
    defaultMessage: 'Online potential audience for all devices',
  },
  dropdownMobileVisits: {
    id: 'DROPDOWN_METRIC_MOBILE_VISITS',
    description: 'Mobile visits dropdown metric option name',
    defaultMessage: 'Mobile Visits',
  },
  dropdownsDesktopReadership: {
    id: 'DROPDOWN_METRIC_DESKTOP_READERSHIP',
    description: 'Desktop readership dropdown metric option name',
    defaultMessage: 'Desktop Readership',
  },
  dropdownsDesktopReadershipDescription: {
    id: 'DROPDOWN_METRIC_DESKTOP_READERSHIP_DESCRIPTION',
    description: 'Desktop readership dropdown metric option descriptiono',
    defaultMessage: 'Online potential audience for non-mobile devices',
  },
  dropdownAggregateReadership: {
    id: 'DROPDOWN_METRIC_AGGREGATE_READERSHIP',
    description: 'Aggregate readership dropdown metric option name',
    defaultMessage: 'Aggregate Readership',
  },
  dropdownAVE: {
    id: 'DROPDOWN_METRIC_AVE',
    description: 'Ad Value Equilvalency dropdown metric option name',
    defaultMessage: 'Ad Value Equivalency (AVE)',
  },
  dropdownTag: {
    id: 'DASHBOARD_SIDEBAR_DROPDOWN_METRIC_TAG',
    description: 'Tag dropdown metric option name',
    defaultMessage: 'Tag',
  },
  dropdownTagDescription: {
    id: 'DASHBOARD_SIDEBAR_DROPDOWN_METRIC_TAG_DESCRIPTION',
    description: 'Tag dropdown metric option description',
    defaultMessage: 'Articles with the selected Tag applied',
  },
  dropdownHighestReadership: {
    id: 'DASHBOARD_SIDEBAR_DROPDOWN_HIGHEST_READERSHIP',
    description: 'Highest Readership dropdown metric option name',
    defaultMessage: 'Highest Readership',
  },
  dropdownImpactScore: {
    id: 'DASHBOARD_SIDEBAR_DROPDOWN_IMPACT_SCORE',
    description: 'Impact score dropdown metric option name',
    defaultMessage: 'Impact Score',
  },
  uniquePrArticlesMetricLabel: {
    id: 'DASHBOARD_LABEL_UNIQUE_PR_ARTICLES_METRIC',
    description: 'Unique PR Articles pill label',
    defaultMessage: 'Unique PR Articles',
  },
  prTrafficMetricLabel: {
    id: 'DASHBOARD_LABEL_PR_TRAFFIC_LABEL_METRIC',
    description: 'PR Referral Traffic pill label',
    defaultMessage: 'PR Referral Traffic',
  },
  dropdownTotalSessions: {
    id: 'DROPDOWN_V3_METRIC_Total_SESSIONS',
    description: 'V3 Total Sessions dropdown metric option name',
    defaultMessage: 'Total Sessions',
  },
  dropdownTotalSessionsDescription: {
    id: 'Total_SESSIONS_DESCRIPTION',
    defaultMessage: 'Site session traffic from article backlinks.',
    description: 'Session traffic from the selected analytics account',
  },
  titleRangeSection: {
    id: 'RANGE_SECTION_TITLE',
    description: 'Section date range title',
    defaultMessage: 'Date Range',
  },
  subtitleRangeSection: {
    id: 'RANGE_SECTION_SUBTITLE',
    description: 'The subtitle of the date range section',
    defaultMessage: 'Select {ALLOWED_RANGES_TEXT}',
  },
  groupBySearches: {
    id: 'GROUP_BY_SEARCHES',
    description: 'Searches label for the group by dropdown',
    defaultMessage: 'Searches (Default)',
  },
  groupByMediaTypeLabel: {
    id: 'GROUP_BY_MEDIA_TYPE_LABEL',
    description: 'Media type label for the group by dropdown',
    defaultMessage: 'Media Type',
  },
  groupBySocialLabel: {
    id: 'GROUP_BY_SOCIAL_LABEL',
    description: 'Social label for the group by dropdown',
    defaultMessage: 'Social',
  },
  groupBySocialSearchPostsLabel: {
    id: 'GROUP_BY_SOCIAL_SEARCH_POSTS_LABEL',
    description: 'Social search posts label for the group by dropdown',
    defaultMessage: 'Posts',
  },
  groupByOutletLabel: {
    id: 'GROUP_BY_OUTLET_LABEL',
    description: 'Outlet label for the group by dropdown',
    defaultMessage: 'Outlet',
  },
  groupByArticleLabel: {
    id: 'GROUP_BY_ARTICLE_LABEL',
    description: 'Article label for the group by dropdown',
    defaultMessage: 'Article',
  },
  groupBySentimentLabel: {
    id: 'GROUP_BY_SENTIMENT_LABEL',
    description: 'Sentiment label for the group by dropdown',
    defaultMessage: 'Sentiment',
  },
  groupByDomainAuthorityLabel: {
    id: 'GROUP_BY_DOMAIN_AUTHORITY_LABEL',
    description: 'Domain Authority label for the group by dropdown',
    defaultMessage: 'Domain Authority',
  },
  groupByCountryLabel: {
    id: 'GROUP_BY_COUNTRY_LABEL',
    description: 'Country label for the group by dropdown',
    defaultMessage: 'Country',
  },
  groupByStateProvinceLabel: {
    id: 'GROUP_BY_STATE_PROVINCE_LABEL',
    description: 'State/Province label for the group by dropdown',
    defaultMessage: 'State/Province',
  },
  groupByCityLabel: {
    id: 'GROUP_BY_CITY_LABEL',
    description: 'City label for the group by dropdown',
    defaultMessage: 'City',
  },
  groupByPublishDateLabel: {
    id: 'GROUP_BY_PUBLISH_DATE_LABEL',
    description: 'Publish Date label for the group by dropdown',
    defaultMessage: 'Publish Date',
  },
  labelButtonChartTypeSection: {
    id: 'LABEL_BUTTON_CHART_TYPE_SECTION',
    description: 'The label of button in the chart type section',
    defaultMessage: 'Select Chart Type',
  },
  subTitleChartTypeLabelNotFound: {
    id: 'SUBTITLE_CHART_TYPE_LABEL_NOT_FOUND',
    description: 'Label to be shown whenever the metric is not found',
    defaultMessage: 'Metric label not found',
  },
  subTitleNoChartTypeLabel: {
    id: 'SUBTITLE_NO_CHART_TYPE_LABEL',
    description: 'Label to be shown whenever there is no metric selected',
    defaultMessage: 'selecting',
  },
  labelForChartTypeButton: {
    id: 'LABEL_FOR_CHART_TYPE_BUTTON',
    description: 'The label for each select chart type button',
    defaultMessage: 'Metric {METRIC_ID} : {METRIC_LABEL}',
  },
  labelForSelectedDateRangeCompareButton: {
    id: 'LABEL_FOR_SELECTED_DATE_RANGE_COMPARE_BUTTON',
    description:
      'The label of button in the date range section for custom date range',
    defaultMessage: 'vs. {SELECTED_DATE_RANGE_COMPARE_TEXT}',
  },
  labelCustomDateRange: {
    id: 'LABEL_CUSTOM_DATE_RANGE',
    description:
      'The label of the custom date range option in the date range compare dropdown for the date range section',
    defaultMessage: 'Custom Start Date',
  },
  labelRemoveDateRange: {
    id: 'LABEL_REMOVE_DATE_RANGE',
    description:
      'The label of the custom date range option to remove dropdown option to compare date range',
    defaultMessage: 'Remove',
  },
  labelPreviousYearDateRange: {
    id: 'LABEL_PREVIOUS_YEAR_DATE_RANGE',
    description:
      'The label of the previous year option in the date range compare dropdown for the date range section',
    defaultMessage: 'Previous Year',
  },
  labelPreviousPeriodDateRange: {
    id: 'LABEL_PREVIOUS_PERIOD_DATE_RANGE',
    description:
      'The label of the previous period option in the date range compare dropdown for the date range section',
    defaultMessage: 'Previous {DAY_DIFFERENCE} days',
  },
  textWarningCustomCalendar: {
    id: 'TEXT_WARNING_CUSTOM_CALENDAR',
    description:
      'Warning text to be displayed in the custom calendar, if you need it',
    defaultMessage:
      'Comparison end date will be automatically calculated based on primary date range',
  },
  noAnalyticsLabel: {
    id: 'NO_ANALYTICS_DROPDOWN_LABEL',
    description:
      'Text to display in analytic account select if there are none for the account',
    defaultMessage: 'No connected analytics accounts',
  },
  noGroupByDropdownLabel: {
    id: 'NO_GROUP_BY_DROPDOWN_LABEL',
    description: 'Text to display if there is no current group by selected',
    defaultMessage: 'Choose',
  },
  dropdownsImpactTopJournalists: {
    id: 'DROPDOWN_CHART_TOP_JOURNALISTS_BAR',
    description: 'Top journalists dropdown option name',
    defaultMessage: 'Top Journalists Chart',
  },
  dropdownsImpactSnapshot: {
    id: 'DROPDOWN_IMPACT_SNAPSHOT',
    description: 'Impact Snapshot dropdown option name',
    defaultMessage: 'Impact Snapshot Chart',
  },
};
