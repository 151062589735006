export const SET_NYLAS_CALLBACK_STATE = 'outreach/SET_NYLAS_CALLBACK_STATE';

const initialState = {
  composeTo: [],
};

const nylasCallbackStateReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_NYLAS_CALLBACK_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const setNylasCallbackStateActionCreator = callbackState => ({
  type: SET_NYLAS_CALLBACK_STATE,
  payload: callbackState,
});

export default nylasCallbackStateReducer;
