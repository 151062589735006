export default {
  adminArticleSearchArticleTitle: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_SEARCH_ARTICLE_TITLE',
    defaultMessage: 'Search for an Article',
    description:
      'Admin > Article Management > Search Article: Search for an Article',
  },
  adminArticleFieldLabel: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_FIELD_LABEL',
    defaultMessage: 'Field',
    description: 'Admin > Article Management > Search Article: Field',
  },
  adminArticleValueLabel: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_VALUE_LABEL',
    defaultMessage: 'Value',
    description: 'Admin > Article Management > Search Article: Value',
  },
  adminArticleSearchButton: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_SEARCH_BUTTON',
    defaultMessage: 'Search',
    description: 'Admin > Article Management > Search Article: Search',
  },
  adminArticleSocialRefreshButton: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_SOCIAL_REFRESH_BUTTON',
    defaultMessage: 'Social Refresh',
    description: 'Admin > Article Management > Search Article: Social Refresh',
  },
  adminArticleSocialDataPlaceholder: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_SOCIAL_DATA_PLACEHOLDER',
    defaultMessage: 'Social Data:',
    description: 'Admin > Article Management > Search Article: Social Data',
  },
  adminArticleResultPlaceholder: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_RESULT_PLACEHOLDER',
    defaultMessage:
      '{ARTICLE_RESULT_COUNT} {ARTICLE_RESULT_COUNT, plural, =1 {result} other {results}} found:',
    description: 'Admin > Article Management > Search Article: Result Found',
  },
  adminArticleSocialRefreshErrorMessage: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_SOCIAL_REFRESH_ERROR_MESSAGE',
    defaultMessage: 'Sorry no result match with you social refresh search.',
    description:
      'Admin > Article Management > Search Article: Social Refresh Message',
  },
  adminArticleIdOption: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_ARTICLE_ID_OPTION',
    defaultMessage: 'Article Id',
    description:
      'Admin > Article Management > Search Article: Article Id Option',
  },
  adminArticleSequenceIdOption: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_SEQUENCE_ID_OPTION',
    defaultMessage: 'Sequence Id',
    description:
      'Admin > Article Management > Search Article: Sequence Id Option',
  },
  adminArticleHeadlineOption: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_HEADLINE_OPTION',
    defaultMessage: 'Headline',
    description: 'Admin > Article Management > Search Article: Headline Option',
  },
  adminArticleTextOption: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_TEXT_OPTION',
    defaultMessage: 'Text',
    description: 'Admin > Article Management > Search Article: Text Option',
  },
  adminArticleURLOption: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_URL_OPTION',
    defaultMessage: 'URL',
    description: 'Admin > Article Management > Search Article: URL Option',
  },
  adminArticleSubmittedByOption: {
    id: 'ADMIN_ARTICLE_MANAGEMENT_SUBMITTED_BY_OPTION',
    defaultMessage: 'Submitted By',
    description:
      'Admin > Article Management > Search Article: Submitted By Option',
  },
};
