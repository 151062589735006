import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import moment from 'moment';
import qs from 'qs';

import { DEFAULT_ARTICLE_LIST_PAGE_SIZE } from 'constants/constants';
import { defaultAnalyticsIntegrationIdSelector } from 'selectors/app-info';
import { currentArticleListSelector } from 'selectors/article-lists';
import {
  getApiQueryParamsFromList,
  getApiQueryParamsFromUrlParams,
  getListFromUrlParams,
} from 'services/article-lists/article-lists';

export const ADD_EXPORT_ID = 'tk/article-lists/ADD_EXPORT_ID';
export const SET_ARTICLE_LIST = 'tk/article-lists/SET_ARTICLE_LIST';
export const REMOVE_ARTICLE_LIST = 'tk/article-lists/REMOVE_ARTICLE_LIST';
export const RESET_ARTICLE_LIST_QUERY_PARAMS =
  'tk/artiSETlists/RESET_ARTICLE_LIST_QUERY_PARAMS';
export const SHOW_KEYWORD_FILTER_ERROR =
  'tk/article-lists/SHOW_KEYWORD_FILTER_ERROR';
export const SET_ARTICLE_LIST_ARTICLES =
  'tk/article-lists/SET_ARTICLE_LIST_ARTICLES';
export const SET_ARTICLE_LIST_LOADING =
  'tk/article-lists/SET_ARTICLE_LIST_LOADING';

export const initialState = {
  articleLists: {},
};

const nowUtcMoment = moment().utc();
const defaultEndDate = nowUtcMoment.endOf('day').toISOString();
const defaultStartDate = nowUtcMoment
  .subtract(90, 'days')
  .startOf('day')
  .toISOString();
const valuesInUrl = qs.parse(window.location.href);

export const initialArticleListState = {
  endDate: defaultEndDate,
  exportIds: [],
  id: '',
  isDateFilteringEnabled: true,
  period: 'day',
  queryParams: {
    analyticsIntegrationId: valuesInUrl.analyticsId,
    dataPoint: 'trailing.90',
    includeAdultContent: false,
    keywordFilterText: '',
    newSearchText: '',
    pageNum: 1,
    pageSize: DEFAULT_ARTICLE_LIST_PAGE_SIZE,
    selectedSearchId: '',
    simpleSearch: false,
    sort: 'sort-date-desc',
    viewFilters: [],
  },
  startDate: defaultStartDate,
  type: '',
  hasDateScale: true,
  loading: false,
  fetched: false,
  articleIds: [],
  totalArticleCount: 0,
  displayWrongKeywordFilterMessageKite: false,
};

const articleListsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_ARTICLE_LIST: {
      const newListProps = {
        ...initialArticleListState,
        ...state.articleLists[action.payload.id],
        ...action.payload,
        queryParams: {
          ...initialArticleListState.queryParams,
          ...(state.articleLists[action.payload.id]
            ? state.articleLists[action.payload.id].queryParams
            : {}),
          ...action.payload.queryParams,
        },
      };

      const omitProps = [
        'loading',
        'fetched',
        'articleIds',
        'totalArticleCount',
      ];
      /**
       * The reducer and the ArticleListContainer component are highly dependent on each other.  If the ArticleListContainer
       * component gets remounted (whether that remount is desired or not) it will fire off a SET_ARTICLE_LIST.  Many times
       * there is actually no difference in the properties of the list, yet we were resetting loading, articleIds, and totalArticleCount.
       * This was causing the display to get locked into a loading state due to when and how articles are retrieved.
       *
       * Now check to see if this set is a no-op, ie no properties are actually changing, and do not update the properties related
       * to article retrieval.
       */
      const noOp = isEqual(
        omit(state.articleLists[action.payload.id], omitProps),
        omit(newListProps, omitProps),
      );

      return {
        ...state,
        displayWrongKeywordFilterMessageKite: false,
        articleLists: {
          [action.payload.id]: {
            ...newListProps,
            loading: noOp ? newListProps.loading : false,
            fetched: noOp ? newListProps.fetched : false,
            articleIds: noOp ? newListProps.articleIds : [],
            totalArticleCount: noOp ? newListProps.totalArticleCount : 0,
          },
        },
        current: [action.payload.id],
      };
    }
    case SET_ARTICLE_LIST_LOADING:
      return {
        ...state,
        articleLists: {
          [action.payload.id]: {
            ...state.articleLists[action.payload.id],
            loading: true,
          },
        },
      };
    case SET_ARTICLE_LIST_ARTICLES:
      return {
        ...state,
        articleLists: {
          [action.payload.id]: {
            ...state.articleLists[action.payload.id],
            articleIds: action.payload.articleIds,
            totalArticleCount: action.payload.totalArticleCount,
            loading: false,
            fetched: true,
          },
        },
      };
    case REMOVE_ARTICLE_LIST: {
      const newState = {
        ...state,
        articleLists: {
          ...state.articleLists,
        },
      };
      delete newState.articleLists[action.payload];
      return newState;
    }
    case RESET_ARTICLE_LIST_QUERY_PARAMS: {
      return {
        ...state,
        articleLists: {
          [action.payload.id]: {
            ...state.articleLists[action.payload.id],
            queryParams: {
              ...state.articleLists[action.payload.id].queryParams,
              ...action.payload.parametersToReset,
            },
          },
        },
      };
    }
    case SHOW_KEYWORD_FILTER_ERROR: {
      return {
        ...state,
        displayWrongKeywordFilterMessageKite: true,
      };
    }
    case ADD_EXPORT_ID: {
      return {
        ...state,
        articleLists: {
          ...state.articleLists,
          [action.payload.listId]: {
            ...state.articleLists[action.payload.listId],
            exportIds: state.articleLists[
              action.payload.listId
            ].exportIds.concat([action.payload.exportId]),
          },
        },
      };
    }
    default:
      return state;
  }
};

export const setArticleList = (id, type, urlParams) => (dispatch, getState) => {
  if (!id) {
    return;
  }

  const state = getState();
  const defaultAnalyticsIntegrationId = defaultAnalyticsIntegrationIdSelector(
    getState(),
  );

  const queryParams = {
    ...getApiQueryParamsFromList(currentArticleListSelector(state)),
    ...getApiQueryParamsFromUrlParams(urlParams),
  };

  const list = getListFromUrlParams(
    queryParams,
    id,
    type,
    defaultAnalyticsIntegrationId,
  );

  dispatch({
    type: SET_ARTICLE_LIST,
    payload: list,
  });
};

export const addExportIdToArticleList = (listId, exportId) => dispatch => {
  if (!listId || !exportId) {
    return;
  }

  dispatch({
    type: ADD_EXPORT_ID,
    payload: {
      listId,
      exportId,
    },
  });
};

export const resetArticleListQueryParams = ({ parametersToReset }) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { id } = currentArticleListSelector(state);

  if (!id) return;

  dispatch({
    type: RESET_ARTICLE_LIST_QUERY_PARAMS,
    payload: {
      id,
      parametersToReset,
    },
  });
};

export default articleListsReducer;
