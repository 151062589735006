import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Enlarge = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
  </svg>
);
Enlarge.propTypes = { fill: PropTypes.string };
Enlarge.defaultProps = { fill: iconColorsMap.mainicon };

export default Enlarge;
