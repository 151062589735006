import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../button';

const baseClass = 'tk-empty-state';

const EmptyState = ({
  callsToAction,
  centerText,
  heroImage,
  imageUrl,
  imageStyle,
  modifiers,
  smallWrapper,
  style,
  bodyStyle,
  titleStyle,
  text,
  title,
  svgSmall,
  svgCompact,
  svgMedium,
  svgLarge,
  wide,
  className,
}) => {
  const prefixedModifiers = Array.isArray(modifiers)
    ? modifiers.map(modifier => `${baseClass}--${modifier}`)
    : [];
  const mainClass = classnames(baseClass, prefixedModifiers, className, {
    [`${baseClass}--has-image`]: imageUrl && !heroImage,
    [`${baseClass}__small`]: smallWrapper,
    [`${baseClass}--has-hero-image`]: imageUrl && heroImage,
    [`${baseClass}--wide`]: wide,
  });
  const svgClass = classnames(`${baseClass}__svg`, {
    [`${baseClass}__svg--small`]: svgSmall && !svgCompact && !svgMedium,
    [`${baseClass}__svg--compact`]: svgCompact && !svgMedium,
    [`${baseClass}__svg--medium`]: svgMedium,
    [`${baseClass}__svg--large`]: svgLarge,
  });

  return (
    <div className={mainClass} style={style} data-qa="empty-state-container">
      {imageUrl && (
        <div
          className={`${baseClass}__image`}
          data-qa="empty-state-image"
          style={imageStyle}
        >
          <svg className={svgClass}>
            <use xlinkHref={imageUrl} />
          </svg>
        </div>
      )}
      <div
        className={`${baseClass}__body ${
          centerText ? `${baseClass}__centered` : ''
        }`}
        style={bodyStyle}
      >
        {title && (
          <div className={`${baseClass}__heading`} style={titleStyle}>
            {title}
          </div>
        )}
        <div className={`${baseClass}__text`} data-qa="empty-state-text">
          {text}
        </div>
        {!!callsToAction.length && (
          <div className={`${baseClass}__ctas`}>
            {callsToAction.map(cta => {
              if (cta.component) {
                return (
                  <div
                    className={`${baseClass}__cta-container`}
                    key={cta.key || cta.text}
                  >
                    <div className={`${baseClass}__cta`}>{cta.component}</div>
                  </div>
                );
              }

              return (
                <div
                  className={`${baseClass}__cta-container`}
                  key={cta.key || cta.text}
                >
                  <div className={`${baseClass}__cta`}>
                    <Button
                      data-qa="M42cy0Zhe2QeXY6xXWl3R"
                      modifiers={cta.modifiers || ['block', 'primary', 'round']}
                      onClick={cta.onClick}
                    >
                      {cta.text}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  callsToAction: PropTypes.arrayOf(
    PropTypes.shape({
      /** Pass a custom component instead of the stock button */
      component: PropTypes.node,
      /** Used for array unique keys, falls back to `text` prop */
      key: PropTypes.string,
      /** Modifiers for the stock button */
      modifiers: PropTypes.arrayOf(PropTypes.string),
      /** Click handler for the stock button */
      onClick: PropTypes.func,
      /** Text content for the stock button */
      text: PropTypes.string,
    }),
  ),
  /** should text be centered? */
  centerText: PropTypes.bool,
  /** vertical placement of image */
  heroImage: PropTypes.bool,
  /** svg urls only */
  imageUrl: PropTypes.string,
  imageStyle: PropTypes.object,
  modifiers: PropTypes.arrayOf(
    PropTypes.oneOf([
      'error',
      'sidetray',
      'card',
      'large-text',
      'primary',
      'round',
    ]),
  ),
  /** Props to see if the container should be small */
  smallWrapper: PropTypes.bool,
  style: PropTypes.object,
  bodyStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  title: PropTypes.node,
  text: PropTypes.node.isRequired,
  svgSmall: PropTypes.bool,
  svgCompact: PropTypes.bool,
  svgMedium: PropTypes.bool,
  svgLarge: PropTypes.bool,
  wide: PropTypes.bool,
  className: PropTypes.string,
};

EmptyState.defaultProps = {
  callsToAction: [],
  centerText: false,
  heroImage: false,
  imageStyle: {},
  imageUrl: '',
  modifiers: [],
  smallWrapper: false,
  style: {},
  bodyStyle: {},
  titleStyle: {},
  title: '',
  svgSmall: false,
  svgCompact: false,
  svgMedium: false,
  svgLarge: false,
  wide: false,
  className: '',
};

export default EmptyState;
