import find from 'lodash/find';
import get from 'lodash/get';
import take from 'lodash/take';

import {
  getDraftThreadsResponse,
  draftIdGenerator,
  getMessagesResponse,
  getThreadsResponse,
  integrations,
  messages,
  threads,
  myMailMessageCount,
  drafts,
  bulkDraftGenerator,
} from './mockData';

export const performGet = async path => {
  switch (path) {
    case '/api/outreach/integration':
      return {
        data: integrations,
      };
    case '/api/outreach/bulk': // real endpoint requires bulk id
      return {
        data: bulkDraftGenerator(),
      };
    case '/api/outreach/draft':
      return {
        data: drafts['tk_draft-1.1'],
      };
    default: {
      return {
        data: {
          error: {
            message: 'Failed to catch up above',
          },
          messages: [],
          status: 400,
        },
      };
    }
  }
};

export const performDelete = async (path, params = {}) => {
  switch (path) {
    case `api/outreach/draft/${params.id}`: {
      return {
        data: {
          status: 200,
        },
      };
    }
    case `api/outreach/draft/schedule/${params.id}`: {
      return {
        data: {
          status: 200,
        },
      };
    }
    case `api/outreach/bulk/schedule/${params.id}`: {
      return {
        data: {
          status: 200,
        },
      };
    }
    default: {
      return {
        data: {
          error: {
            message: "Couldn't perform delete",
          },
        },
        status: 200,
      };
    }
  }
};

export const performPost = async (path, params = {}) => {
  // eslint-disable-next-line no-console
  console.debug(path, params);
  switch (path) {
    case '/api/outreach/threads/query': {
      let results = threads;
      if (get(params, 'filters.unread')) {
        results = results.filter(t => !!t.unread);
      }

      if (get(params, 'filters.hasReply') === false) {
        results = results.filter(
          t =>
            t.lastMessageReceivedTimestamp &&
            t.lastMessageReceivedTimestamp !== t.lastMessageSentTimestamp,
        );
      }

      if (
        get(params, 'filters.authors') &&
        get(params, 'filters.authors').length > 0
      ) {
        results = results.filter(t => {
          return find(t.participants, p => p.email === 'jane.doe@example.com');
        });
      }

      if (params.search) {
        const lowerCaseSearch = params.search.toLocaleLowerCase();
        results = results.filter(
          thread =>
            thread.snippet.toLocaleLowerCase().indexOf(lowerCaseSearch) >= 0 ||
            thread.subject.toLocaleLowerCase().indexOf(lowerCaseSearch) >= 0,
        );
      }

      return {
        data: getThreadsResponse(results),
      };
    }
    case '/api/outreach/draft': {
      // API contract dictates that the first-time and subsequent saves will be
      // the same endpoint and will create a draft ID if one doesn't already exist
      const id = params.id || draftIdGenerator();
      return {
        data: {
          ...params,
          id,
          snippet: params.body.substring(5, 30).trim(),
        },
      };
    }
    case '/api/outreach/bulk': {
      const id = params.id || draftIdGenerator();
      return {
        data: {
          ...params,
          id,
        },
      };
    }
    case `/api/outreach/bulk/schedule/${params.id}`: {
      return {
        data: {
          status: 200,
        },
      };
    }
    case '/api/outreach/drafts/query': {
      let results = [
        drafts['tk_draft-1.1'],
        drafts['tk_draft-1.2'],
        drafts['tk_draft-1.3'],
        drafts['tk_draft-1.4'],
        drafts['tk_draft-1.5'],
      ];

      if (
        get(params, 'filters.authors') &&
        get(params, 'filters.authors').length > 0 &&
        params.mockDataContact
      ) {
        const mockContact = params.mockDataContact;
        results = results.map(d => {
          const updatedTo = d.to.map(contact => ({
            ...contact,
            email: mockContact.emails[0],
            id: mockContact.id,
            name: mockContact.fullName,
            substitutions: {
              'Full name': mockContact.fullName,
              'First name': mockContact.firstName,
              'Last name': mockContact.lastName,
              Publisher: mockContact.publications[0],
            },
          }));
          return { ...d, to: updatedTo };
        });
      }

      if (params.search) {
        const lowerCaseSearch = params.search.toLocaleLowerCase();
        results = results.filter(
          thread =>
            thread.snippet.toLocaleLowerCase().indexOf(lowerCaseSearch) >= 0 ||
            thread.subject.toLocaleLowerCase().indexOf(lowerCaseSearch) >= 0,
        );
      }

      if (params.limit) {
        results = take(results, params.limit);
      }

      return {
        data: getDraftThreadsResponse(results),
      };
    }
    case `/api/outreach/draft/schedule/${params.id}`: {
      const schedule = {
        timestamp: params.timestamp,
        timezone: params.timezone,
      };

      return {
        data: {
          draft: {
            ...params.draft,
            schedule,
          },
        },
      };
    }
    case '/api/outreach/messages/query': {
      let results = [
        messages['tk_message-1.1'],
        messages['tk_message-1.2'],
        messages['tk_message-1.3'],
        messages['tk_message-1.4'],
        messages['tk_message-1.5'],
      ];

      if (params.search) {
        const lowerCaseSearch = params.search.toLocaleLowerCase();
        results = results.filter(
          message =>
            message.body.toLocaleLowerCase().indexOf(lowerCaseSearch) >= 0 ||
            message.subject.toLocaleLowerCase().indexOf(lowerCaseSearch) >= 0,
        );
      }

      return {
        data: getMessagesResponse(results),
      };
    }
    case '/api/outreach/messages/stats':
      return {
        data: myMailMessageCount,
      };
    default: {
      // Too lazy to look up what the error would be if there's no recognized route.
      return {
        data: {
          error: {
            message: "Couldn't get threads",
          },
          aggregations: null,
          pagination: null,
          results: [],
        },
        status: 400,
      };
    }
  }
};

export default {
  performGet,
  performPost,
};
