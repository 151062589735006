export default {
  buttonText: {
    id: 'LIST-VISOR-CONTROLS_BUTTON_TEXT',
    defaultMessage: `{COUNT, plural,
      =0 {Filters}
      other {Filters (#)}}`,
    description:
      'Displays the pluralized word Filters and the number of active filters in the list visor (if any)',
  },
};
