import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BarStat extends Component {
  state = {
    show: false,
  };

  componentDidMount() {
    if (!this.state.show) {
      requestAnimationFrame(() => this.setState({ show: true }));
    }
  }

  render() {
    const { percent, showLabel } = this.props;
    const { show } = this.state;
    const percentString = `${percent}%`;
    let widthPercent = '0px';
    if (show) {
      if (percent > 0 && percent < 1) {
        widthPercent = '1px';
      } else {
        widthPercent = percentString;
      }
    }
    return (
      <div className={BarStat.baseClass}>
        <div className={`${BarStat.baseClass}__bar`}>
          <div
            className={`${BarStat.baseClass}__bar-fill`}
            style={{ width: `${widthPercent}` }}
          />
        </div>
        {showLabel && (
          <div className={`${BarStat.baseClass}__label`}>
            {parseInt(percent, 10)}%
          </div>
        )}
      </div>
    );
  }
}

BarStat.propTypes = {
  percent: PropTypes.number.isRequired,
  showLabel: PropTypes.bool,
};

BarStat.defaultProps = {
  showLabel: false,
};

BarStat.baseClass = 'bar-stat';

export default BarStat;
