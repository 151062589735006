import { createSelector } from 'reselect';

export const facetsErrorSelector = state => !!state.facets.error;

export const facetsLoadingSelector = state => !!state.facets.loading;

export const facetsByIdSelector = state => state.facets.facetsById || {};

export const facetsArraySelector = createSelector(
  facetsByIdSelector,
  facetsById => Object.keys(facetsById).map(facetId => facetsById[facetId]),
);
