import objectFilter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import { userIdSelector } from 'selectors/account';
import { alertsSelector } from 'selectors/alerts';
import { articlesSelector } from 'selectors/article';
import { searchEntriesForListSelector } from 'selectors/search';
import { myStoriesListSelector } from 'selectors/stories/story-hub';

const ownedTitleSortComparator = userId => (a, b) => {
  if (a.userId === userId && b.userId !== userId) {
    return -1;
  } else if (a.userId !== userId && b.userId === userId) {
    return 1;
  }
  return a.title.localeCompare(b.title);
};

const titleCreatedSortComparator = (a, b) => {
  const cmp = a.title.localeCompare(b.title);
  if (cmp === 0) {
    const dateA = Date.parse(a.dateCreated);
    const dateB = Date.parse(b.dateCreated);

    return dateA - dateB;
  }
  return cmp;
};

export const currentCampaignSelector = state =>
  ((state || {}).campaign || {}).campaign;

export const currentCampaignArticleIdsSelector = state => {
  if (state.campaign.campaign && state.campaign.campaign.articleIds) {
    return state.campaign.campaign.articleIds;
  }

  return [];
};

export const campaignArticlesSelector = createSelector(
  currentCampaignArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) =>
    articleIds.map(articleId => {
      if (!articles[articleId]) {
        return {};
      }
      return {
        ...articles[articleId],
        duplicatePublications: (
          articles[articleId].duplicatePublications || []
        ).map(id => articles[id]),
      };
    }),
);

export const currentCampaignArticlesLoadedCountSelector = createSelector(
  currentCampaignArticleIdsSelector,
  articleIds => {
    return articleIds ? articleIds.length : NaN;
  },
);

export const currentCampaignArticlesWithDupesCountSelector = createSelector(
  currentCampaignArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) => {
    return articleIds.reduce((count, articleId) => {
      const article = articles[articleId];
      if (!article) {
        return count;
      }
      if (
        article.duplicatePublications &&
        article.duplicatePublications.length
      ) {
        return count + article.duplicatePublications.length;
      }
      return count + 1;
    }, 0);
  },
);

export const currentCampaignArticlesTotalCountSelector = state => {
  if (state.campaign.campaign && state.campaign.campaign.totalArticleCount) {
    return state.campaign.campaign.totalArticleCount;
  }

  return NaN;
};

export const currentCampaignArticleIdsSelectedSelector = createSelector(
  currentCampaignArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) => {
    return uniq(
      articleIds.reduce((ids, articleId) => {
        const article = articles[articleId];

        if (!article) {
          return ids;
        }

        if (article.duplicatePublications) {
          article.duplicatePublications.forEach(id => {
            if (articles[id] && articles[id].selected) {
              ids.push(id);
            }
          });
        }

        if (article.selected) {
          ids.push(article.id);
        }

        return ids;
      }, []),
    );
  },
);

export const currentCampaignArticlesSelectedCountSelector = createSelector(
  currentCampaignArticleIdsSelectedSelector,
  articleIds => {
    return Array.isArray(articleIds) ? articleIds.length : NaN;
  },
);

export const currentCampaignArticlesSelectedAllExpandedSelector = createSelector(
  campaignArticlesSelector,
  articles => {
    return articles.reduce((allExpanded, article) => {
      if (article.selected && !article.expandedMetrics) {
        allExpanded = false;
      }
      return allExpanded;
    }, true);
  },
);

export const campaignCategoriesSelector = state => {
  if (!state.campaignCategories.campaignCategories.categories) {
    return [];
  }

  return state.campaignCategories.campaignCategories.categories.map(
    category => {
      return {
        data: category,
        id: category.id,
        label: category.name,
        selected: false,
      };
    },
  );
};

export const campaignCategoriesWithSelectedSelector = createSelector(
  campaignCategoriesSelector,
  currentCampaignSelector,
  (categories, currentCampaign) => {
    if (!isEmpty(currentCampaign) && !isEmpty(currentCampaign.category)) {
      const currentCategory = currentCampaign.category;
      return categories.map(category => {
        if (category.id === currentCategory.id) {
          category.selected = true;
        }
        return category;
      });
    }
    return categories;
  },
);

export const campaignSidebarSelector = state => state.campaignSidebar || {};

/** Campaign Searches **/

const campaignSearches = state =>
  state.hasArchiveDeleteDashboardFF
    ? state.campaignSearchList.campaignSearches.filter(
        search => !search.isArchived && !search.isDeleted,
      )
    : state.campaignSearchList.campaignSearches;

export const archivedCampaignSearchesSelector = state =>
  state.campaignSearchList.campaignSearches.filter(
    search => search.isArchived,
  ) || [];

export const deletedCampaignSearchesSelector = state =>
  state.campaignSearchList.campaignSearches.filter(
    search => search.isDeleted,
  ) || [];

export const primarySearchSelector = createSelector(
  currentCampaignSelector,
  currentCampaign => {
    const primarySearchId = ((currentCampaign || {}).primarySearch || {}).id;
    return typeof primarySearchId !== 'undefined' ? [primarySearchId] : [];
  },
);

export const selectedSearchIdsSelector = createSelector(
  currentCampaignSelector,
  campaignSidebarSelector,
  primarySearchSelector,
  (currentCampaign, campaignSidebar, primarySearchId) => {
    const currentlySelectedSearchIds = campaignSidebar.selectedSearchIds;
    return typeof currentlySelectedSearchIds !== 'undefined' &&
      currentlySelectedSearchIds.length > 0
      ? currentlySelectedSearchIds
      : primarySearchId;
  },
);

export const filterSearchEntriesForCampaignSelector = createSelector();

export const searchEntriesForCampaignEdit = createSelector(
  searchEntriesForListSelector,
  campaignSearches,
  primarySearchSelector,
  (searches, currentSearches, primarySearch) => {
    const currentSearchIds = currentSearches.map(s => s.id);
    return objectFilter(
      searches,
      search =>
        !currentSearchIds.includes(search.id) && search.id !== primarySearch.id,
    );
  },
);

/** Campaign Dashboards **/

export const accountDashboardsSelector = state =>
  (state.dashboards || {}).dashboards || [];

export const sortedAccountDashboardsSelector = createSelector(
  userIdSelector,
  accountDashboardsSelector,
  (userId, dashes) => dashes.sort(ownedTitleSortComparator(userId)),
);

const campaignDashboardList = state => state.campaignDashboardList || {};
export const currentCampaignDashboardsSelector = state =>
  campaignDashboardList(state).campaignDashboards || [];

export const archivedCampaignDashboardsSelector = state =>
  campaignDashboardList(state).archivedDashboards || [];

export const deletedCampaignDashboardsSelector = state =>
  campaignDashboardList(state).deletedDashboards || [];

export const currentCampaignStoriesSelector = state =>
  (state.campaignStoryList || {}).campaignStories || [];

export const dashboardsUnassociatedWithCampaignSelector = createSelector(
  sortedAccountDashboardsSelector,
  currentCampaignDashboardsSelector,
  (allDashboards, currentDashboards) => {
    let unusedDashboards = [];
    if (allDashboards && currentDashboards && currentDashboards.length > 0) {
      const currentDashIds = currentDashboards.map(d => d.id);
      unusedDashboards = objectFilter(
        allDashboards,
        dash => !currentDashIds.includes(dash.id),
      );
    } else if (allDashboards) {
      unusedDashboards = allDashboards;
    }
    return unusedDashboards;
  },
);

/** Campaign Reports **/

export const accountReportsSelector = state => state.reports.reports;

export const sortedAccountReportsSelector = createSelector(
  userIdSelector,
  accountReportsSelector,
  (userId, reports) => reports.sort(ownedTitleSortComparator(userId)),
);

export const currentCampaignReportsSelector = state =>
  (state.campaignReportList || {}).reports || [];

export const reportsUnassociatedWithCampaignSelector = createSelector(
  sortedAccountReportsSelector,
  currentCampaignReportsSelector,
  (allReports, currentReports) => {
    let unusedReports = [];
    if (allReports && currentReports && currentReports.length > 0) {
      const currentReportIds = currentReports.map(r => r.id);
      unusedReports = objectFilter(
        allReports,
        report => !currentReportIds.includes(report.id),
      );
    } else if (allReports) {
      unusedReports = allReports;
    }
    return unusedReports;
  },
);

/** Campaign Newsletters **/
export const newslettersSelector = state => state.newsletters.newsletters;

export const sortedNewslettersSelector = createSelector(
  newslettersSelector,
  newsletters => newsletters.sort((a, b) => a.title.localeCompare(b.title)),
);

export const currentCampaignNewslettersSelector = state =>
  (state.campaignNewsletterList || {}).newsletters || [];

export const newslettersUnassociatedWithCampaignSelector = createSelector(
  sortedNewslettersSelector,
  currentCampaignNewslettersSelector,
  (allNewsletters, currentNewsletters) => {
    let unusedNewsletters = [];
    if (allNewsletters && currentNewsletters && currentNewsletters.length > 0) {
      const currentNewsletterIds = currentNewsletters.map(n => n.id);
      unusedNewsletters = objectFilter(
        allNewsletters,
        newsletter => !currentNewsletterIds.includes(newsletter.id),
      );
    } else if (allNewsletters) {
      unusedNewsletters = allNewsletters;
    }
    return unusedNewsletters;
  },
);

/** Campaign Alerts **/
export const sortedAlertsSelector = createSelector(alertsSelector, alerts =>
  alerts.sort((a, b) => titleCreatedSortComparator(a, b)),
);

export const storiesTypeSelector = createSelector(
  myStoriesListSelector,
  stories => stories.map(story => ({ type: 'stories', ...story })),
);

export const sortedStoriesSelector = createSelector(
  storiesTypeSelector,
  stories => stories.sort((a, b) => a.title.localeCompare(b.title)),
);

export const currentCampaignInfluencerListSelector = state =>
  (state.campaignLists || {}).lists || [];

export const campaignsForUserSelector = state =>
  state.campaignList?.campaigns || [];

export const campaignsTotalSelector = state =>
  state.campaignList?.pagination?.total || 0;

export const campaignsFilteredTotalSelector = state =>
  state.campaignList?.pagination?.filteredTotal || 0;

export const campaignsOwnedByListSelector = state =>
  state.campaignList?.campaignsOwnedByList || [];

export const campaignsCategoriesListSelector = state =>
  state.campaignList?.campaignsCategoriesList || [];

export const campaignRenderHeaderItemsSelector = state =>
  state.campaignLists?.renderHeaderItems || false;

export const campaignsLoadingSelector = state =>
  !!state.campaignList?.loadingCampaigns;

export const campaignsLoadedSelector = state =>
  !!state.campaignList?.loadedCampaigns;

export const campaignsWithSavedListsSelector = createSelector(
  campaignsForUserSelector,
  campaigns =>
    campaigns.filter(campaign => !isEmpty(campaign.influencerListIds)),
);
