import React, { Component } from 'react';

import classNames from 'classnames';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';
import compact from 'lodash/compact';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import PropTypes from 'prop-types';

import Button, { Addon } from '../../button';
import { List, ListEntrySmall } from '../../list';
import Modal from '../../modal';
import SvgIcon from '../../svg-icon';
import TextTruncate from '../../text-truncate';
import withModifiers from '../../withModifiers';

import messages from './ListVisorFilterPill.messages';

class ListVisorFilterPill extends Component {
  static baseClass = 'tk-list-visor-filter-pill';

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.menuOpen &&
      !isEqual(prevState.selectedIds, nextProps.value)
    ) {
      return {
        selectedIds: [...nextProps.value] || [],
      };
    }

    return null;
  }

  state = {
    menuOpen: false,
    selectedIds: [...this.props.value] || [],
    query: null,
  };

  lastModifierFilterInMessageCenter = this.props.lastModifierFilter;

  getMenuItemFromOption(option) {
    if (!option.id) {
      return {};
    }

    return {
      ...option,
      title: option.label,
      data: {
        ...option.data,
      },
    };
  }

  getFilteredItems = () => {
    const { options } = this.props;
    const { query } = this.state;
    let menuItems = options.map(this.getMenuItemFromOption);

    if (query) {
      menuItems = menuItems.filter(
        option =>
          option.label.toLowerCase().indexOf(query.toLowerCase()) !== -1,
      );
    }

    return menuItems;
  };

  confirmSelectionChange(newSelectedIds) {
    const { onChange, isSameValueOnChangeAllowed, value } = this.props;

    if (
      !isEqual(newSelectedIds.sort(), value.sort()) ||
      isSameValueOnChangeAllowed
    ) {
      onChange(newSelectedIds.sort());
    }
  }

  toggleMenu = () => {
    this.setState(({ menuOpen }) => ({ menuOpen: !menuOpen }));
  };

  handleApplyClick = () => {
    this.confirmSelectionChange(this.state.selectedIds);
    this.toggleMenu();
  };

  handleCancelClick = () => {
    this.toggleMenu();
    if (this.lastModifierFilterInMessageCenter) {
      this.props.setClickCancelledThenDefaultDate(true);
    }
    this.setState({ selectedIds: [...this.props.value] });
  };

  handleClickClear = () => {
    this.setState({ selectedIds: [] });
    this.toggleMenu();
    this.confirmSelectionChange([]);
  };

  handleDropdownMenuToggle = e => {
    const { menuOpen, selectedIds } = this.state;

    if (e) {
      e.stopPropagation();
    }

    if (menuOpen) {
      this.confirmSelectionChange(selectedIds);
    }

    this.toggleMenu();
  };

  handleRadioSelect = childSelections => {
    const { onSelect } = this.props;
    this.setState(() => {
      if (isEmpty(childSelections)) {
        return { selectedIds: [] };
      }

      const selectedIds = childSelections.map(selection => selection.id);
      return {
        selectedIds,
      };
    });

    onSelect(childSelections);
  };

  handleListSelect = childSelections => {
    this.setState(prev => {
      if (isEmpty(childSelections)) {
        return { selectedIds: [] };
      }

      const newSelectedIds = union(
        prev.selectedIds,
        compact(childSelections).map(selection => selection.id),
      );
      return {
        selectedIds: [...newSelectedIds],
      };
    });
  };

  handleListDelete = id => {
    this.setState(prev => ({
      selectedIds: prev.selectedIds.filter(prevId => prevId !== id),
    }));
  };

  handleSelectAll = childSelections => {
    this.setState(() => {
      return {
        selectedIds: childSelections,
      };
    });
  };

  handleDeleteAll = childSelections => {
    this.setState(() => {
      return {
        selectedIds: childSelections,
      };
    });
  };

  handleSearchChange = query => {
    this.setState({ query });
  };

  onModalClosed = () => {
    this.setState({ query: null });
    this.props.onModalClosed();
  };

  renderLabel() {
    const { label, options, value } = this.props;
    const menuItems = options.map(this.getMenuItemFromOption);

    const selectionData = value.reduce(
      (selectionDatum, id) => {
        const filteredItem = menuItems.filter(item => item.id === id)[0];
        const title =
          filteredItem && (filteredItem.pillLabel || filteredItem.title);

        selectionDatum.firstLabel = selectionDatum.firstLabel || title;
        selectionDatum.count += 1;
        return selectionDatum;
      },
      {
        firstLabel: '',
        count: 0,
      },
    );

    const dataSummary =
      selectionData.count > 1
        ? `${selectionData.firstLabel} +${selectionData.count - 1}`
        : selectionData.firstLabel;
    const separator = label && dataSummary ? ': ' : '';
    return `${label}${separator}${dataSummary}`;
  }

  renderButton() {
    const {
      locked,
      isDisabled,
      modifiers,
      onBlur,
      onFocus,
      value,
    } = this.props;

    const label = this.renderLabel();
    const buttonColors = locked
      ? ['tertiary']
      : intersection(modifiers, [
          'clearable-pill',
          'fancy-pill',
          'primary',
          'primaryTeal',
          'secondary',
          'seethrough',
          'tertiary',
        ]);

    if (!buttonColors.length) {
      buttonColors.push('tertiary');
    }

    const buttonModifiers = [...buttonColors, 'flex', 'round', 'medium'];

    const disabled = locked || isDisabled ? { disabled: true } : null;

    return (
      <Button
        data-qa="Yaq69ONFIqr218fhInTO7"
        {...disabled}
        modifiers={[...buttonModifiers]}
        onClick={this.handleDropdownMenuToggle}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <TextTruncate>{label}</TextTruncate>
        {locked && !!value.length && (
          <Addon>
            <SvgIcon modifiers={['block']} icon="lock" width={12} height={12} />
          </Addon>
        )}
        {!isDisabled && !locked && !!value.length && (
          <Addon>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              data-qa="SPCYyqsfyo7jF2w0UHjto"
              onClick={this.handleClickClear}
              role="button"
              tabIndex="0"
            >
              <SvgIcon
                modifiers={['block']}
                icon="cross"
                width={12}
                height={12}
              />
            </div>
          </Addon>
        )}
      </Button>
    );
  }

  render() {
    const {
      className,
      isApplyFilterButtonDisabled,
      label,
      locked,
      modalClassName,
      multiselect,
      options,
      style,
    } = this.props;
    const { menuOpen, selectedIds } = this.state;
    const {
      handleApplyClick,
      handleCancelClick,
      handleListSelect,
      handleListDelete,
      handleRadioSelect,
      handleSearchChange,
    } = this;

    const button = this.renderButton();
    const menuItems = options.map(this.getMenuItemFromOption);

    const mainClass = classNames(ListVisorFilterPill.baseClass, className, {
      [`${ListVisorFilterPill.baseClass}--locked`]: locked,
      [`${ListVisorFilterPill.baseClass}--active`]: !!selectedIds.length,
    });

    return (
      <div className={mainClass} style={style}>
        {button}
        <Modal
          isOpen={menuOpen}
          size="sm"
          toggle={this.toggleMenu}
          onClosed={this.onModalClosed}
          className={modalClassName}
        >
          <Modal.Header toggle={this.toggleMenu}>{label}</Modal.Header>
          <Modal.Body padding="0" scrollable>
            <List
              clearable={menuItems.length > 1}
              entries={this.getFilteredItems()}
              EntryComponent={ListEntrySmall}
              modifiers={['round']}
              multiselect={multiselect}
              onSelect={multiselect ? handleListSelect : handleRadioSelect}
              onDelete={handleListDelete}
              onSearchChange={handleSearchChange}
              searchable={menuItems.length > 10}
              onSelectAllChange={this.handleSelectAll}
              onDeleteAllChange={this.handleDeleteAll}
              selected={selectedIds}
              showTray={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <div
              className={`${ListVisorFilterPill.baseClass}__confirmation-item`}
            >
              <Button
                data-qa="wKFax_azDtzKWEJg9H4Wb"
                onClick={handleCancelClick}
                modifiers={['block', 'round', 'tertiary']}
              >
                <TranslatedMessage {...globalMessages.cancel} />
              </Button>
            </div>
            <div
              className={`${ListVisorFilterPill.baseClass}__confirmation-item`}
            >
              <Button
                data-qa="C1SA3DusVPqv4oRpgmePw"
                onClick={handleApplyClick}
                modifiers={['block', 'round', 'primary']}
                disabled={isApplyFilterButtonDisabled}
              >
                <TranslatedMessage {...messages.applyFilter} />
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ListVisorFilterPill.defaultProps = {
  className: '',
  modalClassName: '',
  label: '',
  locked: false,
  isDisabled: false,
  modifiers: [],
  multiselect: true,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onModalClosed: () => {},
  onSelect: () => {},
  isSameValueOnChangeAllowed: false,
  isApplyFilterButtonDisabled: false,
  options: [],
  style: {},
  value: [],
  setClickCancelledThenDefaultDate: () => {},
  lastModifierFilter: false,
};

ListVisorFilterPill.propTypes = {
  className: PropTypes.string,
  modalClassName: PropTypes.string,
  label: PropTypes.node,
  /** Renders a disabled filter pill with a lock button */
  locked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  multiselect: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onModalClosed: PropTypes.func,
  onSelect: PropTypes.func,
  isSameValueOnChangeAllowed: PropTypes.bool,
  isApplyFilterButtonDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      pillLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  style: PropTypes.object,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  setClickCancelledThenDefaultDate: PropTypes.func,
  lastModifierFilter: PropTypes.bool,
};

export default withModifiers(ListVisorFilterPill);
