export default {
  filters: {
    id: 'LIST-VISOR-DRAWER_FILTERS',
    defaultMessage: 'Filters',
    description:
      'Word "Filters" indicating any filters applied to current results displaying',
  },
  clearAll: {
    id: 'LIST-VISOR-DRAWER_CLEAR_ALL',
    defaultMessage: 'Clear All',
    description: 'Action to reset all currently applied filters',
  },
};
