import PropTypes from 'prop-types';

export const propTypes = PropTypes.shape({
  /** TODO: Total results ignoring pagination */
  total: PropTypes.number,
  /** TODO: Results marked unread by the email client */
  unread: PropTypes.number,
  /** TODO: Results sent by the integration owner */
  sent: PropTypes.number,
  /** TODO: Results opened by the recipient, according to trackers */
  opened: PropTypes.number,
  /** TODO: Results that have at least one reply from the recipient */
  hasReply: PropTypes.number,
});

export const defaultProps = {
  total: 0,
  unread: 0,
  sent: 0,
  opened: 0,
  hasReply: 0,
};

export default {
  propTypes,
  defaultProps,
};
