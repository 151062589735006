import 'custom-event-polyfill';
import React, { Fragment, Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  Addon,
  DisplaySM,
  Button,
  Dropdown,
  DropdownMenu,
  FloatingPanel,
  SvgIcon,
} from '@trendkite/ui';
import TranslatedMessage from 'i18n/TranslatedMessage';
import { TK_EVENT_LOGOUT } from 'trendkite-front-end-app/src/constants/constants';
import { getCanUseAdminPages } from 'trendkite-front-end-app/src/selectors/canUse/canUse';

import messages from './userDropDown.messages.js';

class UserDropdown extends Component {
  state = {
    userDropdownOpen: false,
  };

  componentDidMount() {
    this.logoutOnly = false;
    if (typeof window.logoutOnly !== 'undefined') {
      this.logoutOnly = window.logoutOnly;
    }

    this.appUrlBase = '';
    if (typeof window.appUrlBase !== 'undefined') {
      this.appUrlBase = window.appUrlBase;
    }

    this.logoutEvent = new CustomEvent(TK_EVENT_LOGOUT);
    this.logoutAction = () => {
      window.localStorage.removeItem('searchId');
      window.location = this.props.logoutUrl;
    };

    window.addEventListener(TK_EVENT_LOGOUT, this.logoutAction);
  }

  componentWillUnmount() {
    window.removeEventListener(TK_EVENT_LOGOUT, this.logoutAction);
  }

  toggleUserDropdown = () => {
    this.setState({
      userDropdownOpen: !this.state.userDropdownOpen,
    });
  };

  logout = () => {
    window.dispatchEvent(this.logoutEvent);
  };

  render() {
    const { intl, browseLinkEnabled } = this.props;
    const first_name = decodeURIComponent(this.props.firstName);
    return (
      <div className="secondary-menu-item__user">
        <Dropdown
          isOpen={this.state.userDropdownOpen}
          toggle={this.toggleUserDropdown}
        >
          <Button
            data-qa="UR2puThsCWaLfwDF-eN9s"
            modifiers={['inline-flex', 'seethrough-inverse-rebrand']}
            onClick={this.toggleUserDropdown}
          >
            <SvgIcon icon="user" modifiers={['right']} width={24} height={24} />
            <DisplaySM color="inherit">{first_name}</DisplaySM>
            <Addon>
              <i className="tk-icon-arrow-down2" />
            </Addon>
          </Button>
          <DropdownMenu
            isOpen={this.state.userDropdownOpen}
            position="bottomLeft"
          >
            <FloatingPanel>
              {getCanUseAdminPages() && (
                <Button
                  data-qa="tYbbCG7pXL2icShl-fX_R"
                  modifiers={['listEntry']}
                  location={`${this.appUrlBase}/#/admin`}
                >
                  <TranslatedMessage {...messages.admin} />
                </Button>
              )}
              <Button
                data-qa="userSettings"
                modifiers={['listEntry']}
                location={`${this.appUrlBase}/#/settings/user`}
              >
                <TranslatedMessage {...messages.settings} />
              </Button>

              {!this.logoutOnly && (
                <Fragment>
                  {/*<Button*/}
                  {/*  modifiers={['listEntry']}*/}
                  {/*  location={`${this.props.supportBaseUrl}/hc/en-us/categories/360001065474-Product-Updates`}*/}
                  {/*>*/}
                  {/*  Product Updates*/}
                  {/*</Button>*/}
                  {browseLinkEnabled && (
                    <Button
                      data-qa="assetLibraryHeader"
                      modifiers={['listEntry']}
                      location={`${this.appUrlBase}/#/stories/browse-asset-library`}
                    >
                      <TranslatedMessage {...messages.assetLibrary} />
                    </Button>
                  )}
                  <Button
                    data-qa="createATicketHeader"
                    modifiers={['listEntry']}
                    location={intl.formatMessage(messages.createATicketURL)}
                    inNewTab
                  >
                    <TranslatedMessage {...messages.createATicket} />
                  </Button>
                  <Button
                    data-qa="knowledgeGuideHeader"
                    modifiers={['listEntry']}
                    location={intl.formatMessage(messages.newHelpSiteURL)}
                    inNewTab
                  >
                    <TranslatedMessage {...messages.helpSite} />
                  </Button>
                </Fragment>
              )}
              {this.props.logoutUrl && (
                <Fragment>
                  <div className="dropdown-divider" />
                  <Button
                    data-qa="n7QYiXMtM6coA_0lhLJz9"
                    modifiers={['listEntry']}
                    onClick={this.logout}
                  >
                    <TranslatedMessage {...messages.logOut} />
                  </Button>
                </Fragment>
              )}
            </FloatingPanel>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

UserDropdown.propTypes = {
  firstName: PropTypes.string,
  logoutUrl: PropTypes.string,
  intl: PropTypes.object.isRequired,
  browseLinkEnabled: PropTypes.bool,
};

UserDropdown.defaultProps = {
  logoutUrl: '/logout',
  firstName: '',
  browseLinkEnabled: false,
};

export default injectIntl(UserDropdown);
