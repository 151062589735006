import React from 'react';

import ReactDOM from 'react-dom';

import '@cision/rover-ui/dist/rover-ui.css';
import './styles/tailwind.css';
import './styles/style.scss';

// import configs
import './config/pendo';
import './config/churnzero';
import './config/gtag';
import './config/datadog';
// import geocode / centroid data
import './assets/geocoder/geocoder';

// import React containers
import App from './pages/App';

ReactDOM.render(<App />, document.getElementById('root'));
