import React, { Fragment, useState } from 'react';

import { Media } from '@cision/rover-ui';
import PropTypes from 'prop-types';

import { Body, IconButton, SideTray, SvgIcon } from '@trendkite/ui';

import Footer from './Footer';
import MenuItem from './menu-item';
import './menu-sm.scss';

const MenuSm = ({
  endImpersonation,
  firstName,
  isAuthed,
  loginNavEnabled,
  logoutUrl,
  menuProps,
  secondaryMenuProps,
  userIsImpersonating,
  username,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [subMenuKey, setSubMenuKey] = useState(null);

  return (
    <Fragment>
      <IconButton
        color="seethrough-inverse-rebrand"
        data-qa="vY9LSgTpEoMQjDlF3XPs7"
        icon="hamburger"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        size="large"
      />
      <SideTray
        direction="t"
        onClose={() => setIsMenuOpen(false)}
        visible={isMenuOpen}
        width="100vw"
      >
        <SideTray.Header className={`${MenuSm.baseClass}__side-tray-header`}>
          <Media>
            <Media.Item style={{ width: '40px' }} />
            <Media.Body style={{ textAlign: 'center', alignSelf: 'center' }}>
              <Body color="inverse" size="lg">
                {firstName}
              </Body>
            </Media.Body>
            <Media.Item>
              <IconButton
                color="seethrough-inverse-rebrand"
                data-qa="7l1Fo1Ju0hJZJ1y7LGCeA"
                icon="cross"
                onClick={() => setIsMenuOpen(false)}
                size="large"
              />
            </Media.Item>
          </Media>
        </SideTray.Header>
        <SideTray.Body className={`${MenuSm.baseClass}__side-tray-body`}>
          <div className={`${MenuSm.baseClass}__section`}>
            {menuProps.map(menuItemProps => {
              if (!menuItemProps.subOptions) {
                return (
                  <MenuItem
                    id={menuItemProps.id}
                    key={menuItemProps.id}
                    label={menuItemProps.label}
                    location={`${window.appUrlBase || ''}${
                      menuItemProps.location
                    }`}
                    tooltip={menuItemProps.tooltip}
                  />
                );
              }

              return (
                <MenuItem
                  data-qa="rh_OTqJNU8Exyc_EKKysS"
                  iconRight={
                    <SvgIcon
                      icon="chevronDown"
                      style={{
                        transformOrigin: 'center center',
                        transform: 'rotate(-90deg)',
                      }}
                    />
                  }
                  id={menuItemProps.id}
                  key={menuItemProps.id}
                  label={menuItemProps.label}
                  onClick={() => setSubMenuKey(menuItemProps.id)}
                />
              );
            })}
          </div>
        </SideTray.Body>
        <SideTray.Footer
          className={`${MenuSm.baseClass}__side-tray-footer`}
          style={{
            padding: '0',
            borderStyle: 'none',
          }}
        >
          <Footer
            endImpersonation={endImpersonation}
            isAuthed={isAuthed}
            loginNavEnabled={loginNavEnabled}
            logoutUrl={logoutUrl}
            secondaryMenuProps={secondaryMenuProps}
            setSubMenuKey={setSubMenuKey}
            userIsImpersonating={userIsImpersonating}
            username={username}
          />
        </SideTray.Footer>
      </SideTray>
      <SideTray
        direction="r"
        onClose={() => setSubMenuKey(null)}
        visible={!!subMenuKey}
        width="100vw"
      >
        <SideTray.Header className={`${MenuSm.baseClass}__side-tray-header`}>
          <Media>
            <Media.Item style={{ marginRight: '20px' }}>
              <IconButton
                color="seethrough-inverse-rebrand"
                data-qa="QUjYAFMLKYF0p6CBrhFwx"
                icon="arrowLeft2"
                onClick={() => setSubMenuKey(null)}
                size="large"
              />
            </Media.Item>
            <Media.Body style={{ alignSelf: 'center', textAlign: 'center' }}>
              {subMenuKey ? (
                <Body color="inverse" size="lg">
                  {
                    [...menuProps, ...secondaryMenuProps].filter(
                      menuItemProps => menuItemProps.id === subMenuKey,
                    )[0].label
                  }
                </Body>
              ) : null}
            </Media.Body>
            <Media.Item style={{ width: '40px', marginLeft: '20px' }} />
          </Media>
        </SideTray.Header>
        <SideTray.Body className={`${MenuSm.baseClass}__side-tray-body`}>
          <div className={`${MenuSm.baseClass}__section`}>
            {[...menuProps, ...secondaryMenuProps]
              .filter(menuItemProps => menuItemProps.id === subMenuKey)
              .map(menuItemProps => {
                const subOptions = menuItemProps.subOptions;

                if (!subOptions || !subOptions.length) {
                  return null;
                }

                return subOptions
                  .filter(option => !option.hide)
                  .map(option => (
                    <MenuItem
                      data-qa="sPKdBmA-7QHbnu9U5eoZa"
                      iconLeft={
                        option.iconLeft ? (
                          <SvgIcon icon={option.iconLeft} />
                        ) : undefined
                      }
                      id={option.id}
                      inNewTab={option.inNewTab}
                      isCurrentNav={option.isCurrentNav}
                      isSubOptionHeader={option.isSubOptionHeader}
                      key={`${menuItemProps.id}__${option.id}`}
                      label={option.label}
                      location={option.location}
                      onClick={() => {
                        setIsMenuOpen(false);
                        setSubMenuKey(null);

                        if (option.onClick) {
                          option.onClick();
                        }
                      }}
                    />
                  ));
              })}
          </div>
        </SideTray.Body>
      </SideTray>
    </Fragment>
  );
};

MenuSm.baseClass = 'header-menu-sm';

MenuSm.propTypes = {
  endImpersonation: PropTypes.func,
  firstName: PropTypes.string,
  isAuthed: PropTypes.bool,
  loginNavEnabled: PropTypes.bool,
  logoutUrl: PropTypes.string,
  menuProps: PropTypes.arrayOf(PropTypes.object).isRequired,
  secondaryMenuProps: PropTypes.arrayOf(PropTypes.object).isRequired,
  userIsImpersonating: PropTypes.bool,
  username: PropTypes.string,
};

MenuSm.defaultProps = {
  endImpersonation: () => {},
  firstName: '',
  isAuthed: false,
  loginNavEnabled: false,
  logoutUrl: '',
  userIsImpersonating: false,
  username: '',
};

export default MenuSm;
