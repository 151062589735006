export default {
  addTags: {
    id: 'ARTICLE_TAG_SELECTOR_ADD_TAGS',
    defaultMessage: 'Add Tags',
    description: 'Heading title for add tags modal',
  },
  removeTags: {
    id: 'ARTICLE_TAG_SELECTOR_REMOVE_TAGS',
    defaultMessage: 'Remove Tags',
    description: 'Heading title for remove tags modal',
  },
  articleTagSelectorModalEmptyStateNoAttachedTags: {
    id: 'ARTICLE_TAG_SELECTOR_MODAL_EMPTY_STATE_NO_ATTACHED_TAGS',
    defaultMessage: 'The current selection has no attached tags.',
    description: 'First message inside the empty state when no data was loaded',
  },
  articleTagSelectorModalEmptyStateSecondMessage: {
    id: 'ARTICLE_TAG_SELECTOR_MODAL_EMPTY_STATE_SECOND_MESSAGE',
    defaultMessage: 'Select articles with tags to remove them.',
    description:
      'Second message inside the empty state when no data was loaded',
  },
  addNewTag: {
    id: 'ARTICLE_TAG_SELECTOR_ADD_A_TAG',
    defaultMessage: 'Add a tag',
    description: 'Placeholder text for the tags modal text input',
  },
  myTags: {
    id: 'ARTICLE_TAG_SELECTOR_MY_TAGS',
    defaultMessage: 'My Tags',
    description: 'Title for my tags section of tag accordion',
  },
  sharedTags: {
    id: 'ARTICLE_TAG_SELECTOR_SHARED_TAGS',
    defaultMessage: 'Shared With Me',
    description: 'Title for the shared tags section of tag accordion',
  },
  tagArticlesErrorMessage: {
    id: 'ARTICLE_TAG_SELECTOR_ERROR_MESSAGE',
    defaultMessage: 'There was a problem making the changes to the article',
    description:
      'Error message is shown when an error occurred on article tag process',
  },
  bulkUnTagArticlesSuccess: {
    id: 'ARTICLE_TAG_SELECTOR_BULK_UN_TAG_ARTICLE_SUCCESS',
    defaultMessage: 'Tags update successfully',
    description:
      'Kite message shown when the delete tags process finishes correctly',
  },
  bulkUnTagArticlesError: {
    id: 'ARTICLE_TAG_SELECTOR_BULK_UN_TAG_ARTICLE_ERROR',
    defaultMessage: 'Sorry, some tags could not be updated. Please try again',
    description:
      'Kite message shown when the delete tags process finishes with some errors',
  },
};
