export default {
  companyLogo: {
    id: 'STORY-LOGO_COMPANY_LOGO_PLACEHOLDER',
    defaultMessage: 'Company Logo',
    description:
      'Campaigns > Story Kit > Story Edit Page: company logo image placeholder',
  },
  uploadImage: {
    id: 'STORY-LOGO_UPLOAD_IMAGE',
    defaultMessage: 'Upload image',
    description:
      'Campaigns > Story Kit > Story Edit Page: company logo upload image link',
  },
  uploadProgess: {
    id: 'STORY-LOGO_UPLOAD_PROGRESS',
    defaultMessage: 'uploading {PROGRESS}% complete',
    description:
      'Campaigns > Story Kit > Story Edit Page: company logo upload progress in percentage',
  },
  undefinedUploadProgress: {
    id: 'STORY-LOGO_UNDEFINED_UPLOAD_PROGRESS',
    defaultMessage: 'uploading...',
    description:
      'Campaigns > Story Kit > Story Edit Page: company logo undefined upload progress',
  },
};
