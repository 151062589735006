import React from 'react';

import classNames from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import Fade from './Fade';
import Portal from './Portal';
import {
  getOriginalBodyPadding,
  conditionallyUpdateScrollbar,
  setScrollbarWidth,
  TransitionTimeouts,
} from './utils';

const centeredOptions = {
  default: 'default',
  absolute: 'absolute',
};

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.element = null;
    this.originalBodyPadding = null;
    this.handleBackdropMouseDown = this.handleBackdropMouseDown.bind(this);
    this.handleBackdropMouseUp = this.handleBackdropMouseUp.bind(this);
    this.onOpened = this.onOpened.bind(this);
    this.onClosed = this.onClosed.bind(this);

    this.state = {
      isOpen: props.isOpen,
    };

    if (props.isOpen) {
      this.init();
    }
  }

  componentDidMount() {
    if (this.props.onEnter) {
      this.props.onEnter();
    }

    if (this.state.isOpen && this.props.autoFocus) {
      this.setFocus();
    }

    if (this.state.isOpen) {
      window.addEventListener('keyup', this.handleEscape);
    }

    this.isMountedHack = true;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.isOpen && !this.state.isOpen) {
      this.init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.autoFocus && this.state.isOpen && !prevState.isOpen) {
      this.setFocus();
    }

    if (this.state.isOpen && !prevState.isOpen) {
      window.addEventListener('keyup', this.handleEscape);
    }

    if (!this.state.isOpen && prevState.isOpen) {
      window.removeEventListener('keyup', this.handleEscape);
    }
  }

  componentWillUnmount() {
    if (this.props.onExit) {
      this.props.onExit();
    }

    if (this.state.isOpen) {
      window.removeEventListener('keyup', this.handleEscape);
      this.destroy();
    }

    this.isMountedHack = false;
  }

  onOpened(node, isAppearing) {
    this.props.onOpened();
    (this.props.modalTransition.onEntered || (() => {}))(node, isAppearing);
  }

  onClosed(node) {
    // so all methods get called before it is unmounted
    this.props.onClosed();
    (this.props.modalTransition.onExited || (() => {}))(node);
    this.destroy();

    if (this.isMountedHack) {
      this.setState({ isOpen: false });
    }
  }

  setFocus() {
    if (
      this.dialog &&
      this.dialog.parentNode &&
      typeof this.dialog.parentNode.focus === 'function'
    ) {
      this.dialog.parentNode.focus();
    }
  }

  /**
   * We cannot use onClick for the backdrop because a click and drag event will treat the target as wherever
   * the mouseup occurs.  This means if the user selects some text but mouseups on the backdrop, there is no way
   * to know that it was not a click on the backdrop.
   */
  handleBackdropMouseDown(e) {
    if (this.props.shouldStopPropagation) {
      e.stopPropagation();
    }

    if (!this.props.isOpen || this.props.backdrop !== true) return;

    const container = this.dialog;

    if (e.target && !container.contains(e.target) && this.props.toggle) {
      this.backdropMousedown = true;
    }
  }

  handleBackdropMouseUp(e) {
    if (this.props.shouldStopPropagation) {
      e.stopPropagation();
    }

    if (
      !this.props.isOpen ||
      this.props.backdrop !== true ||
      !this.backdropMousedown
    ) {
      return;
    }

    const container = this.dialog;

    if (
      e.target &&
      !container.contains(e.target) &&
      this.props.toggle &&
      this.backdropMousedown
    ) {
      this.props.toggle(e);
    }

    this.backdropMousedown = false;
  }

  handleEscape = e => {
    if (
      this.state.isOpen &&
      this.props.keyboard &&
      e.keyCode === 27 &&
      this.props.toggle
    ) {
      this.props.toggle(e);
    }
  };

  init() {
    this.element = document.createElement('div');
    this.element.setAttribute('tabindex', '-1');
    this.element.style.position = 'relative';
    this.element.style.zIndex = this.props.zIndex;
    this.element.className = classNames(
      'tk-modal-depth-wrapper',
      this.props.nodeClassName,
    );
    this.originalBodyPadding = getOriginalBodyPadding();

    conditionallyUpdateScrollbar();

    document.body.appendChild(this.element);

    if (!this.bodyClassAdded) {
      document.body.className = classNames(
        document.body.className,
        'tk-modal-open',
      );
      this.bodyClassAdded = true;
    }
  }

  destroy() {
    if (this.element) {
      document.body.removeChild(this.element);
      this.element = null;
    }

    if (this.bodyClassAdded) {
      const modalOpenClassName = 'tk-modal-open';
      // Use regex to prevent matching `modal-open` as part of a different class, e.g. `my-modal-opened`
      const modalOpenClassNameRegex = new RegExp(
        `(^| )${modalOpenClassName}( |$)`,
      );
      document.body.className = document.body.className
        .replace(modalOpenClassNameRegex, ' ')
        .trim();
      this.bodyClassAdded = false;
    }

    setScrollbarWidth(this.originalBodyPadding);
  }

  renderModalDialog() {
    const propsToOmit = Object.keys(Modal.propTypes);
    const attributes = omit(this.props, propsToOmit);
    const dialogBaseClass = 'tk-modal__dialog';

    return (
      <div
        {...attributes}
        className={classNames(dialogBaseClass, this.props.className, {
          [`tk-modal--${this.props.size}`]: this.props.size,
          [`${dialogBaseClass}--centered`]:
            this.props.centered === true ||
            this.props.centered === centeredOptions.default,
          [`${dialogBaseClass}--centered-absolute`]:
            this.props.centered === centeredOptions.absolute,
          [`${dialogBaseClass}--aligned`]: this.props.aligned,
        })}
        role="document"
        ref={c => {
          this.dialog = c;
        }}
      >
        <div
          className={classNames(
            'tk-modal__content',
            this.props.contentClassName,
          )}
        >
          {this.props.children}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isOpen) {
      const {
        modern,
        wrapClassName,
        modalClassName,
        backdropClassName,
        isOpen,
        backdrop,
        role,
        labelledBy,
        external,
      } = this.props;

      const modalAttributes = {
        onMouseDown: this.handleBackdropMouseDown,
        onMouseUp: this.handleBackdropMouseUp,
        style: { display: !modern ? 'block' : null },
        'aria-labelledby': labelledBy,
        role,
        tabIndex: '-1',
      };

      const hasTransition = this.props.fade;
      const modalTransition = {
        ...Fade.defaultProps,
        ...this.props.modalTransition,
        baseClass: hasTransition ? this.props.modalTransition.baseClass : '',
        timeout: hasTransition ? this.props.modalTransition.timeout : 0,
      };
      const backdropTransition = {
        ...Fade.defaultProps,
        ...this.props.backdropTransition,
        baseClass: hasTransition ? this.props.backdropTransition.baseClass : '',
        timeout: hasTransition ? this.props.backdropTransition.timeout : 0,
      };

      return (
        <Portal node={this.element}>
          <div className={classNames('tk-modal-wrap', wrapClassName)}>
            <Fade
              {...modalAttributes}
              {...modalTransition}
              in={isOpen}
              onEntered={this.onOpened}
              onExited={this.onClosed}
              className={classNames('tk-modal', modalClassName, {
                'tk-modal--open': isOpen,
                'tk-modal--modern': modern,
              })}
            >
              {external}
              {this.renderModalDialog()}
            </Fade>
            <Fade
              {...backdropTransition}
              in={isOpen && !!backdrop}
              className={classNames('tk-modal__backdrop', backdropClassName)}
            />
          </div>
        </Portal>
      );
    }

    return null;
  }
}

const FadePropTypes = PropTypes.shape(Fade.propTypes);
Modal.propTypes = {
  isOpen: PropTypes.bool,
  shouldStopPropagation: PropTypes.bool,
  autoFocus: PropTypes.bool,
  centered: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(centeredOptions)),
    PropTypes.bool,
  ]),
  modern: PropTypes.bool,
  aligned: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'ssmd', 'smd', 'md', 'lg', 'xl', 'xxl']),
  toggle: PropTypes.func,
  keyboard: PropTypes.bool,
  role: PropTypes.string,
  labelledBy: PropTypes.string,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  children: PropTypes.node,
  nodeClassName: PropTypes.string,
  className: PropTypes.string,
  wrapClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  backdropClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  external: PropTypes.node,
  fade: PropTypes.bool,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backdropTransition: FadePropTypes,
  modalTransition: FadePropTypes,
};

Modal.defaultProps = {
  isOpen: false,
  autoFocus: true,
  modern: false,
  centered: false,
  aligned: false,
  size: 'md',
  toggle: null,
  role: 'dialog',
  labelledBy: '',
  backdrop: true,
  keyboard: true,
  zIndex: 1050,
  fade: true,
  onOpened: () => {},
  onClosed: () => {},
  onEnter: null,
  onExit: null,
  children: null,
  nodeClassName: '',
  className: '',
  wrapClassName: '',
  modalClassName: '',
  backdropClassName: '',
  shouldStopPropagation: true,
  contentClassName: '',
  external: null,
  modalTransition: {
    timeout: TransitionTimeouts.Modal,
  },
  backdropTransition: {
    mountOnEnter: true,
    timeout: TransitionTimeouts.Fade, // uses standard fade transition
  },
};

export default Modal;
