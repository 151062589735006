import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { withResponsiveModifiers } from '@trendkite/ui';

import StoryInsightsBody from './StoryInsightsBody';
import StoryInsightsHead from './StoryInsightsHead';

const StoryInsights = ({ children, className, head, style }) => {
  const mainClass = classnames(StoryInsights.baseClass, className);

  return (
    <div className={mainClass} style={style}>
      <div className={`${StoryInsights.baseClass}__container`}>
        {head && <StoryInsightsHead>{head}</StoryInsightsHead>}
        <StoryInsightsBody>{children}</StoryInsightsBody>
      </div>
    </div>
  );
};

StoryInsights.baseClass = 'story-insights';

StoryInsights.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  head: PropTypes.node,
  style: PropTypes.object,
};

StoryInsights.defaultProps = {
  className: '',
  head: null,
  style: {},
};

export default withResponsiveModifiers(StoryInsights);
