import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Loader, Modal, Type } from '@trendkite/ui';

import { hasDevFeatureFlagSelector } from 'selectors/account';
import { getNylasAuthUrl } from 'utils/nylas';

const mapStateToProps = state => ({
  additionalState: state.nylasRedirectModal.additionalState,
  hasDevFeatureFlag: hasDevFeatureFlagSelector(state),
  isOpen: state.nylasRedirectModal.isModalOpen,
  redirect: state.nylasRedirectModal.redirect,
});

class OutreachRedirectModal extends Component {
  componentDidMount = () => {
    if (this.props.isOpen) {
      setTimeout(() => {
        this.handleTimeout();
      }, 1500);
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.isOpen && !prevProps.isOpen) {
      setTimeout(() => {
        this.handleTimeout();
      }, 1500);
    }
  };

  handleTimeout = () => {
    const { additionalState, hasDevFeatureFlag, redirect } = this.props;

    window.location = getNylasAuthUrl({
      callbackState: {
        ...additionalState,
        redirect,
      },
      hasDevFeatureFlag,
    });
  };

  render() {
    const { isOpen, companyName } = this.props;

    return (
      <Modal isOpen={isOpen} size="sm" style={{ textAlign: 'center' }}>
        <Modal.Body style={{ padding: '32px' }}>
          <Loader />
          <br />
          <Type size="lg">
            Redirecting you to Nylas, our email integration partner to connect
            your email…
          </Type>
          <br />
          <br />
          <Type color="light">
            You will be redirected to {companyName} after your email is
            connected.
          </Type>
        </Modal.Body>
      </Modal>
    );
  }
}

OutreachRedirectModal.propTypes = {
  additionalState: PropTypes.object,
  hasDevFeatureFlag: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  redirect: PropTypes.string,
  companyName: PropTypes.string,
};

OutreachRedirectModal.defaultProps = {
  additionalState: {},
  redirect: `/${window.location.hash}`,
  companyName: 'TrendKite',
};

export default connect(mapStateToProps)(OutreachRedirectModal);
