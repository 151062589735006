import React from 'react';

import classNames from 'classnames';

import SvgIcon from '../svg-icon';

interface Props {
  id?: string;
  className?: string;
  selected?: boolean;
  dataQA?: string | null;
}

const RadioButton: React.FC<Props> = ({
  selected = false,
  className = '',
  dataQA = null,
  ...props
}) => {
  const defaultIcon = (
    <SvgIcon width={16} height={16} icon="radioDefault" data-qa={dataQA} />
  );
  const selectedIcon = (
    <SvgIcon width={16} height={16} icon="radioSelected" data-qa={dataQA} />
  );

  const icon = selected ? selectedIcon : defaultIcon;

  const styles = classNames(
    'tk-radio-btn',
    {
      'tk-radio-btn--selected': selected,
    },
    className,
  );

  return (
    <div {...props} className={styles}>
      {icon}
    </div>
  );
};

export default RadioButton;
