import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const SpikeAlert = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M12 23c1.1 0 1.99-.89 1.99-1.99h-3.98c0 1.1.89 1.99 1.99 1.99zm7-6v-6c0-3.35-2.36-6.15-5.5-6.83V1.5h-3v2.67C7.36 4.85 5 7.65 5 11v6l-2 2v1h18v-1l-2-2zm-6-1h-2v-2h2v2zm0-4h-2V8h2v4z" />
  </svg>
);
SpikeAlert.propTypes = { fill: PropTypes.string };
SpikeAlert.defaultProps = { fill: iconColorsMap.mainicon };

export default SpikeAlert;
