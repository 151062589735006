import moment from 'moment';

import { THREAD_TYPES } from 'components/outreach/constants';
import { MOCK_ANNOUNCEMENT } from 'components/outreach/thread-container/data';
import { DEV_FEATURES, USER_ROLES } from 'constants/constants';
import { OUTREACH_MESSAGE_STATUSES } from 'constants/outreach-message';
import {
  hasDevFeatureFlagSelector,
  impersonatorSelector,
  impersonatorHasAnyRoleSelector,
} from 'selectors/account';

export const hasMockOutreachDataSelector = state =>
  hasDevFeatureFlagSelector(state)(DEV_FEATURES.jortsMockData) ||
  (impersonatorSelector(state) &&
    !impersonatorHasAnyRoleSelector(state)([USER_ROLES.impersonatorOutreach]));

const daysAgo = (numDays = -1) => {
  let days = numDays;
  if (numDays < 0) {
    days = Math.floor(Math.random() * Math.floor(14));
  }
  return moment().subtract(days, 'days').valueOf();
};

export const draftIdGenerator = () => {
  return `tk_draft-${Math.floor(Math.random() * 100)}`;
};

export const bulkIdGenerator = () => {
  return `tk_bulk-${Math.floor(Math.random() * 100)}`;
};

export const contactGenerator = contactName => ({
  contactType: 'Custom',
  email: `${contactName.toLowerCase().trim().split(' ').join('.')}@example.com`,
  id: Math.floor(Math.random() * 100),
  name: `${contactName}`,
  /* for templating functionality */
  substitutions: {
    'Full name': contactName,
    'First name': contactName.split(' ')[0],
    'Last name': contactName.split(' ')[1],
    Publisher: 'www.nytimes.com',
  },
});

export const draftGenerator = id => ({
  accountId: 'nylas_account-X',
  ownerId: 1,
  id: id || draftIdGenerator(),
  messageId: null,
  threadId: null,
  replyToMessageId: null,
  subject: 'Subject TBD',
  dateCreated: daysAgo(1),
  body: '<p>Hello, what kind of </p>',
  status: OUTREACH_MESSAGE_STATUSES.draft,
  messageType: null,
  from: {
    contactType: 'User',
    email: 'example@cision.com',
    id: 1,
    name: 'Example Integration',
  },
  replyTo: [],
  to: [],
  cc: [],
  bcc: [],
  attachments: [],
  contexts: [],
  campaigns: [],
  stories: [],
  authors: [],
  authorLists: [],
  snippet: 'Hello, what kind of',
  folder: null,
  labels: [],
});

export const messageGenerator = ({ messageId, threadId }) => ({
  accountId: 'nylas_account-X',
  ownerId: 1,
  id: `tk_message-${threadId || 'X'}.${messageId}`,
  messageId: `tk_message-${threadId || 'X'}.${messageId}`,
  threadId: `nylas_thread-${threadId || 'X'}`,
  replyToMessageId: null,
  subject: 'Looking for your thoughts',
  // dateCreated: null, // Might be used in the future for drafts
  dateDelivered: null,
  // dateReceived: null, // obsolete -- replaced by dateDelivered
  dateDeleted: null,
  dateOpened: null,
  unread: false,
  starred: false,
  body: '<p>Hello</p>',
  status: OUTREACH_MESSAGE_STATUSES.sent,
  messageType: null,
  from: [],
  replyTo: [],
  to: [],
  cc: [],
  bcc: [],
  attachments: [],
  contexts: [],
  campaigns: [],
  stories: [],
  authors: [],
  authorLists: [],
  snippet: 'Hello',
  folder: null,
  labels: [],
});

export const contacts = {
  me: {
    ...contactGenerator('Me'),
    email: 'example@cision.com',
  },
  chris: {
    ...contactGenerator('Chris Garcia'),
    'contact-type': 'User',
    email: 'chris.garcia@cision.com',
    name: 'Chris Garcia',
  },
  john: {
    ...contactGenerator('John Smith'),
    'contact-type': 'Author',
  },
  jane: {
    ...contactGenerator('Jane Doe'),
    'contact-type': 'Author',
  },
};

export const getBulkDraftContacts = () => {
  const names = [
    'Andrew Selepak',
    'Maria Bruce',
    'Wyatt Fisher',
    'Curtis Bizelli',
    'Jason Myers',
    'Shelly Grieshop',
    'Paige Arnof-Fenn',
    'Rhianna Daniels Hile',
  ];

  return names.map(name => ({
    ...contactGenerator(name),
    contacts: [
      {
        contactType: 'email',
        value: `${name.toLowerCase().trim().split(' ').join('.')}@test.com`,
      },
    ],
    contactType: 'Influencer',
    emails: [`${name.toLowerCase().trim().split(' ').join('.')}@test.com`],
    fullName: name,
    image: { url: null },
    publications: [],
  }));
};

export const getBulkDraftValues = (templateValues = {}) => ({
  id: 'tk_draft-1.5',
  from: {
    contactType: 'User',
    email: 'example@cision.com',
    id: 1,
    name: 'Example Integration',
  },
  to: [contacts.jane],
  cc: [],
  bcc: [],
  campaigns: [],
  subject: 'This is mock draft',
  body: 'Hi Jane, how are you',
  templatizedBody:
    'Hi <span class="tk-ql-template-string" data-template-key="First name"></span>, how are you',
  replyToMessageId: '',
  stories: [],
  contexts: [],
  attachments: [],
  ...templateValues,
});

export const bulkDraftGenerator = (templateValues = {}) => ({
  id: 'tk_draft-1.5',
  template: getBulkDraftValues(templateValues),
  drafts: [
    getBulkDraftValues({
      ...templateValues,
      to: [contactGenerator('First Person')],
      contact: contactGenerator('First Person'),
    }),
    getBulkDraftValues({
      ...templateValues,
      to: [contactGenerator('Second Person')],
      contact: contactGenerator('Second Person'),
      personalized: true,
      subject: 'Hi friend!',
    }),
    getBulkDraftValues({
      ...templateValues,
      to: [contactGenerator('Another Individual')],
      contact: contactGenerator('Another Individual'),
    }),
  ],
});

export const threadGenerator = ({ threadId }) => ({
  accountId: 'nylas_account_id',
  authorLists: [],
  authors: [],
  campaigns: [],
  dateLastMessageDelivered: daysAgo(),
  dateOpened: null,
  draftIds: [],
  folders: [],
  hasAttachments: null,
  hasReplied: false,
  id: `id-${threadId}`,
  labels: [],
  messageIds: [],
  object: null,
  participants: [contacts.me, contacts.john],
  snippet: 'Some lorem ipsum goes here',
  starred: false,
  stories: [],
  subject: 'Mock Data',
  threadId: `tk_thread-${threadId}`,
  threadType: THREAD_TYPES.PITCH,
  unread: false,
  userInitiated: true,
  version: null,
});

export const savedBulkDraft = id => ({
  bulkId: id,
  id,
  accountId: 1966,
  snippet: 'This is a bulk message to many recipients',
  fromTk: true,
  stories: [],
  subject: 'This is a subject',
  to: [contacts.jane, contacts.john],
});

export const drafts = {
  'tk_draft-1.1': {
    ...draftGenerator('tk_draft-1.1'),
    body:
      '<p>Hi,</p><p>How are you? We’re looking for journalists to tell our story</p>',
    snippet: 'Hi, How are you? We’re looking for journalists to tell our story',
    from: contacts.me,
    to: [contacts.jane],
  },
  'tk_draft-1.2': {
    ...draftGenerator('tk_draft-1.2'),
    from: contacts.me,
    to: [contacts.john],
    replyToMessageId: 'tk_message-1.1',
  },
  'tk_draft-1.3': {
    ...draftGenerator('tk_draft-1.3'),
    from: contacts.me,
    to: [contacts.jane],
    subject: 'This is a third draft!',
  },
  'tk_draft-1.4': {
    ...draftGenerator('tk_draft-1.4'),
    body:
      '<p>Hi,</p><p>How are you? We’re looking for journalists to tell our story</p>',
    snippet: 'Hi, How are you? We’re looking for journalists to tell our story',
    from: contacts.me,
    to: [contacts.jane],
    schedule: {
      timestamp: moment().valueOf(),
      timezone: 'America/Chicago',
    },
  },
  'tk_draft-1.5': {
    ...savedBulkDraft('tk_draft-1.5'),
    from: contacts.me,
  },
};

export const messages = {
  'tk_message-1.1': {
    // INITIAL SEND FROM PR
    ...messageGenerator({ threadId: 1, messageId: 1 }),
    body:
      '<p>Hi,</p><p>How are you? We’re looking for journalists to tell our story and thought you’d potentially be interested in hearing about us. Could we schedule some time to chat this week?</p><p>Thanks!</p>',
    snippet:
      'Hi, How are you? We’re looking for journalists to tell our story and thought you’d potentially be interested',
    dateDelivered: daysAgo(5),
    dateOpened: daysAgo(4),
    unread: false,
    status: OUTREACH_MESSAGE_STATUSES.sent,
    from: [contacts.me],
    to: [contacts.jane],
  },
  'tk_message-1.2': {
    // RESPONSE FROM JOURNALIST
    ...messageGenerator({ threadId: 1, messageId: 2 }),
    body:
      '<p>Hey there. Sure. I’d love to chat. How does 2pm on Monday sound?</p>',
    snippet: 'Hey there. Sure. I’d love to chat. How does 2pm on Monday sound?',
    dateDelivered: daysAgo(4),
    status: OUTREACH_MESSAGE_STATUSES.received,
    from: [contacts.jane],
    to: [contacts.me],
    replyToMessageId: 'tk_message-1.1',
  },
  'tk_message-1.3': {
    // RESPONSE FROM PR
    ...messageGenerator({ threadId: 1, messageId: 3 }),
    body:
      '<p>Hi again,</p><p>2pm on Monday sounds great. My phone number is 555-555-5555. Excited to speak with you!</p><p>Thanks!</p><br /><p>On Monday Jane wrote:</p><br /><blockquote><p>Hey there. Sure. I’d love to chat. How does 2pm on Monday sound?</p><br /><p>On Monday You wrote:</p><br /><blockquote><p>Hi,</p><p>How are you? We’re looking for journalists to tell our story and thought you’d potentially be interested in hearing about us. Could we schedule some time to chat this week?</p><p>Thanks!</p></blockquote></blockquote>',
    snippet:
      'Hi again, 2pm on Monday sounds great. My phone number is 555-555-5555. Excited to speak with you! Thanks!',
    dateDelivered: daysAgo(3),
    dateOpened: daysAgo(3),
    unread: false,
    status: OUTREACH_MESSAGE_STATUSES.sent,
    from: [contacts.me],
    to: [contacts.jane],
    replyToMessageId: 'tk_message-1.2',
  },
  'tk_message-1.4': {
    // FOLLOW-UP FROM PR
    ...messageGenerator({ threadId: 1, messageId: 4 }),
    body:
      "<p>Hey there﻿,</p><p>It was great to speak to you on Monday. I just wanted to reach out to follow up on your coverage of our forthcoming product launch. Is there anything else you need in order for you to write your story? Please don't hesitate to let me know.</p><p>Thanks again!</p>",
    snippet: 'Hey there﻿, It was great to speak to you on Monday.',
    dateDelivered: daysAgo(0),
    dateOpened: daysAgo(0),
    status: OUTREACH_MESSAGE_STATUSES.sent,
    from: [contacts.me],
    to: [contacts.jane],
    replyToMessageId: 'tk_message-1.3',
  },
  'tk_message-1.5': {
    // RESPONSE FROM JOURNALIST
    ...messageGenerator({ threadId: 1, messageId: 5 }),
    body:
      '<p>It was great to speak with you too. I’m writing the story now and will be in touch if I need anything. I’ll also follow-up when I have a release date for the article.</p>',
    snippet:
      'It was great to speak with you too. I’m writing the story now and will be in touch if I need anything. I’ll also follow-up when I have a release date for the article.',
    dateDelivered: daysAgo(0),
    status: OUTREACH_MESSAGE_STATUSES.received,
    from: [contacts.jane],
    to: [contacts.me],
    replyToMessageId: 'tk_message-1.4',
  },
};

const generateMockMessageIds = numOfIds =>
  [...Array(numOfIds).keys()].map(id => 'tk_message-1.' + id);

const threadDetails = [
  {
    subject: 'Looking for your thoughts',
    snippet:
      'It was great to speak with you too. I’m writing the story now and will be in touch if I need anything. I’ll also follow-up when I have a release date for the article.',
    dateLastMessageDelivered: daysAgo(0),
    dateOpened: daysAgo(0),
    hasReplied: true,
    participants: [contacts.me, contacts.jane],
    messageIds: [
      'tk_message-1.1',
      'tk_message-1.2',
      'tk_message-1.3',
      'tk_message-1.4',
      'tk_message-1.5',
    ],
  },
  {
    subject: MOCK_ANNOUNCEMENT.subject,
    snippet:
      "As of February 1st, Cision's comprehensive monitoring solution includes over 25,000 podcasts through a partnership with TVEyes",
    title: MOCK_ANNOUNCEMENT.title,
    dateLastMessageDelivered: daysAgo(1),
    dateOpened: daysAgo(0),
    participants: MOCK_ANNOUNCEMENT.recipients,
    threadType: THREAD_TYPES.EMAIL_ANNOUNCEMENT,
  },
  {
    subject: 'About your coverage',
    snippet: 'Hi. Thanks so much for covering us this past week.',
    dateLastMessageDelivered: daysAgo(1),
    dateOpened: daysAgo(0),
    participants: [contacts.me, contacts.jane],
    messageIds: generateMockMessageIds(10),
  },
  {
    subject: 'Interview Request',
    snippet:
      'Hello. I am with the New York Times and am working on a story which involves your company.',
    dateLastMessageDelivered: daysAgo(1),
    dateOpened: daysAgo(0),
    userInitiated: false,
    messageIds: generateMockMessageIds(129),
  },
  {
    subject: 'Interested in coverage?',
    snippet:
      'Thanks for getting in touch. Sure, I would be interested in covering your story. Can you provide some extra information?',
    dateLastMessageDelivered: daysAgo(1),
    hasReplied: true,
    participants: [contacts.me, contacts.jane],
    messageIds: ['tk_message-1.1', 'tk_message-1.2'],
  },
  {
    subject: 'Interested in coverage?',
    snippet:
      'Hello again. How was your weekend? We’re doing a product launch and I’d like to know if you have interest in doing an interview.',
    dateLastMessageDelivered: daysAgo(2),
    participants: [contacts.me, contacts.jane],
    messageIds: generateMockMessageIds(5),
  },
  {
    subject: MOCK_ANNOUNCEMENT.subject,
    snippet:
      "As of February 1st, Cision's comprehensive monitoring solution includes over 25,000 podcasts through a partnership with TVEyes",
    title: MOCK_ANNOUNCEMENT.title,
    dateLastMessageDelivered: daysAgo(1),
    dateOpened: daysAgo(0),
    participants: MOCK_ANNOUNCEMENT.recipients,
    recipients: MOCK_ANNOUNCEMENT.recipients,
    threadType: THREAD_TYPES.EMAIL_ANNOUNCEMENT,
  },
  {
    subject: 'Interested in coverage?',
    snippet:
      'Hi there. Given that you’ve reported on our field for decades now, I’d like to know if you’d be interested in covering a new product that we’ll be launching.',
    dateLastMessageDelivered: daysAgo(3),
    unread: true,
    participants: [contacts.me, contacts.jane],
    messageIds: generateMockMessageIds(5),
  },
  {
    subject: 'Thank you for the coverage!',
    snippet: 'We loved your piece. Thank you for taking the time to write it!',
    dateLastMessageDelivered: daysAgo(3),
    dateOpened: daysAgo(1),
    messageIds: generateMockMessageIds(3),
  },
  {
    subject: 'Need a quote',
    snippet:
      'Hello. I am looking to cover your product launch and would like a quote from an executive and someone from the R&D team. Thank you!',
    dateLastMessageDelivered: daysAgo(4),
    userInitiated: false,
    dateOpened: daysAgo(4),
    messageIds: generateMockMessageIds(53),
  },
  {
    subject: 'Interesting story',
    snippet:
      'Just wanted to pass this story along since I thought it might interest you.',
    dateLastMessageDelivered: daysAgo(5),
    messageIds: generateMockMessageIds(2),
  },
  {
    subject: 'Meet while you’re in town?',
    snippet: 'Let’s grab dinner next week. Do you like burritos?',
    dateLastMessageDelivered: daysAgo(6),
    hasReplied: true,
    participants: [contacts.me, contacts.jane],
    messageIds: generateMockMessageIds(54),
  },
  {
    subject: 'About your coverage',
    snippet: 'Hi. Thanks so much for covering us this past week.',
    dateLastMessageDelivered: daysAgo(7),
    dateOpened: daysAgo(3),
    messageIds: generateMockMessageIds(12),
  },
  {
    subject: 'Interview Request',
    snippet:
      'Hello. I am with the New York Times and am working on a story which involves your company.',
    dateLastMessageDelivered: daysAgo(8),
    dateOpened: daysAgo(8),
    userInitiated: false,
    messageIds: generateMockMessageIds(21),
  },
  {
    subject: 'Interested in coverage?',
    snippet:
      'Thanks for getting in touch. Sure, I would be interested in covering your story. Can you provide some extra information?',
    dateLastMessageDelivered: daysAgo(9),
    hasReplied: true,
    messageIds: generateMockMessageIds(1),
  },
  {
    subject: 'Interested in coverage?',
    snippet:
      'Hello again. How was your weekend? We’re doing a product launch and I’d like to know if you have interest in doing an interview.',
    dateLastMessageDelivered: daysAgo(9),
    messageIds: generateMockMessageIds(8),
  },
  {
    subject: 'Interested in coverage?',
    snippet:
      'Hi there. Given that you’ve reported on our field for decades now, I’d like to know if you’d be interested in covering a new product that we’ll be launching.',
    dateLastMessageDelivered: daysAgo(10),
    messageIds: generateMockMessageIds(91),
  },
  {
    subject: 'Thank you for the coverage!',
    snippet: 'We loved your piece. Thank you for taking the time to write it!',
    dateLastMessageDelivered: daysAgo(11),
    dateOpened: daysAgo(1),
    participants: [contacts.jane, contacts.john],
    messageIds: generateMockMessageIds(23),
  },
  {
    subject: 'Need a quote',
    snippet:
      'Hello. I am looking to cover your product launch and would like a quote from an executive and someone from the R&D team. Thank you!',
    dateLastMessageDelivered: daysAgo(12),
    userInitiated: false,
    unread: true,
    messageIds: generateMockMessageIds(12),
  },
  {
    subject: 'Interesting story',
    snippet:
      'Just wanted to pass this story along since I thought it might interest you.',
    dateLastMessageDelivered: daysAgo(13),
    dateOpened: daysAgo(11),
    participants: [contacts.jane, contacts.john],
    messageIds: generateMockMessageIds(1),
  },
  {
    subject: 'Meet while you’re in town?',
    snippet: 'Let’s grab dinner next week. Do you like burritos?',
    dateLastMessageDelivered: daysAgo(14),
    hasReplied: true,
    messageIds: generateMockMessageIds(3),
  },
];

export const threads = threadDetails.map((detail, i) => ({
  ...threadGenerator({ threadId: i + 1 }),
  ...threadDetails[i],
}));

export const integrations = {
  domains: [
    {
      valid: true,
      requested: true,
      public: false,
      domainName: 'test.com',
      verified: true,
      exists: true,
      status: 'Created',
    },
  ],
  emailAccounts: [
    {
      id: 1,
      uuid: 'example3-8739-4976-8746-d4f5950bbec1',
      senderName: 'Cision Example',
      emailAddress: 'example@cision.com',
      integrationId: 'example',
      lastSynced: moment().format(),
      status: 'RUNNING',
      type: 'INTEGRATION',
      outreachIntegrationType: 'GMAIL',
      user: {
        id: window.activeUser.id,
      },
    },
  ],
  integrations: [
    {
      id: 1,
      uuid: 'example3-8739-4976-8746-d4f5950bbec1',
      senderName: 'Cision Example',
      emailAddress: 'example@cision.com',
      integrationId: 'example',
      lastSynced: moment().format(),
      status: 'RUNNING',
      outreachIntegrationType: 'GMAIL',
      user: {
        id: window.activeUser.id,
      },
    },
  ],
  userDefaultIntegrationId: 1,
};

export const myMailMessageCount = {
  account: {
    total: 93763,
    unread: 73374,
    draft: 546,
    scheduled: 98,
  },
  user: {
    total: 4699,
  },
};

export const getDraftThreadsResponse = threadObjs => ({
  aggregations: {
    total: threadObjs.length,
  },
  pagination: {
    offset: 0,
    limit: 25,
    total: threadObjs.length,
    pageNumber: 0,
    pageSize: 100,
  },
  results: [...threadObjs],
});

export const getThreadsResponse = threadObjs => ({
  aggregations: {
    total: threadObjs.length,
    unread: threadObjs.filter(t => !!t.unread).length,
    sent: threadObjs.filter(t => !!t.lastMessageSentTimestamp).length,
    opened: threadObjs.filter(t => !!t.opened).length,
    hasReply: threadObjs.filter(
      t =>
        t.lastMessageReceivedTimestamp &&
        t.lastMessageReceivedTimestamp !== t.lastMessageSentTimestamp,
    ).length,
  },
  pagination: {
    offset: 0,
    limit: 25,
    total: threadObjs.length,
    pageNumber: 0,
    pageSize: 100,
  },
  results: [...threadObjs],
});

export const getMessagesResponse = messageObjs => {
  const replyIds = messageObjs
    .filter(m => !!m.replyToMessageId)
    .map(m => m.replyToMessageId);
  const hasReplyCount = messageObjs.filter(m => replyIds.indexOf(m.id) >= 0)
    .length;

  return {
    aggregations: {
      total: messageObjs.length,
      unread: messageObjs.filter(m => !!m.unread).length,
      sent: messageObjs.filter(m => !!m.lastMessageSentTimestamp).length,
      opened: messageObjs.filter(m => !!m.opened).length,
      hasReply: hasReplyCount,
    },
    pagination: {
      offset: 0,
      limit: 25,
      total: messageObjs.length,
      pageNumber: 0,
      pageSize: 100,
    },
    results: [...messageObjs],
  };
};

export default {
  integrations,
  messages,
  threads,
  myMailMessageCount,
};
