import { STORY_BASE_ENDPOINT } from 'constants/apis';
import {
  performGet,
  performPost,
  performDelete,
} from 'services/rest-service/rest-service';

export const GET_KEY_MESSAGES = 'stories/GET_KEY_MESSAGES';
export const GET_KEY_MESSAGES_RECEIVED = 'stories/GET_KEY_MESSAGES_RECEIVED';
export const GET_KEY_MESSAGES_ERROR = 'stories/GET_KEY_MESSAGES_ERROR';
export const CREATE_KEY_MESSAGE = 'stories/CREATE_KEY_MESSAGE';
export const CREATE_KEY_MESSAGE_RECEIVED =
  'stories/CREATE_KEY_MESSAGE_RECEIVED';
export const CREATE_KEY_MESSAGE_ERROR = 'stories/CREATE_KEY_MESSAGE_ERROR';
export const DELETE_KEY_MESSAGE = 'stories/DELETE_KEY_MESSAGE';
export const DELETE_KEY_MESSAGE_RECEIVED =
  'stories/DELETE_KEY_MESSAGE_RECEIVED';
export const DELETE_KEY_MESSAGE_ERROR = 'stories/DELETE_KEY_MESSAGE_ERROR';

const initialState = {
  deleting: null,
  error: false,
  keyMessages: [],
};

const storyKeyMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KEY_MESSAGES:
      return state;
    case GET_KEY_MESSAGES_RECEIVED: {
      const keyMessages = action.payload;
      return {
        ...state,
        error: false,
        ...keyMessages,
      };
    }
    case DELETE_KEY_MESSAGE: {
      const { deleting } = action.payload;
      return {
        ...state,
        deleting,
      };
    }
    case DELETE_KEY_MESSAGE_RECEIVED: {
      const { deletedTag } = action.payload;
      const keyMessages = state.keyMessages;
      const updatedKeyMessages = keyMessages.filter(keyMessage => {
        return keyMessage.id !== deletedTag.id;
      });
      return {
        ...state,
        deleting: null,
        keyMessages: updatedKeyMessages,
      };
    }
    case DELETE_KEY_MESSAGE_ERROR:
      return {
        ...state,
        deleting: null,
        error: true,
      };
    case CREATE_KEY_MESSAGE: {
      return {
        ...state,
      };
    }
    case CREATE_KEY_MESSAGE_ERROR:
    case GET_KEY_MESSAGES_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export const getKeyMessagesByStoryIdActionDispatcher = id => dispatch => {
  dispatch({ type: GET_KEY_MESSAGES });

  performGet(`${STORY_BASE_ENDPOINT}/${id}/key-message`)
    .then(response => {
      dispatch({
        type: GET_KEY_MESSAGES_RECEIVED,
        payload: {
          keyMessages: response.data,
        },
      });
    })
    .catch(err => {
      dispatch({ type: GET_KEY_MESSAGES_ERROR, payload: err });
      throw err;
    });
};

export const addKeyMessagesByStoryIdActionDispatcher = (
  id,
  keyMessages,
) => async dispatch => {
  dispatch({
    type: CREATE_KEY_MESSAGE,
  });

  performPost(`${STORY_BASE_ENDPOINT}/${id}/key-message`, { keyMessages })
    .then(response => {
      dispatch({
        type: GET_KEY_MESSAGES_RECEIVED,
        payload: {
          keyMessages: response.data,
        },
      });
    })
    .catch(error => {
      dispatch({ type: GET_KEY_MESSAGES_ERROR, payload: error });
    });
};

export const deleteKeyMessageByIdActionDispatcher = (
  storyId,
  keyMessageId,
) => dispatch => {
  dispatch({
    type: DELETE_KEY_MESSAGE,
    payload: {
      deleting: keyMessageId,
    },
  });

  performDelete(`${STORY_BASE_ENDPOINT}/${storyId}/key-message/${keyMessageId}`)
    .then(response => {
      dispatch({
        type: DELETE_KEY_MESSAGE_RECEIVED,
        payload: {
          deletedTag: response.data,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DELETE_KEY_MESSAGE_ERROR,
      });
    });
};

export default storyKeyMessagesReducer;
