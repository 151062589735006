import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import qs from 'qs';
import sanitizeHtml from 'sanitize-html';

import { isValidEmail } from '@trendkite/ui';

import {
  OUTREACH_CONTACT_TYPES,
  TK_TEST_EMAIL,
} from 'constants/outreach-message';
import { removeSensitiveParamsFromUrl } from 'utils/links/links';

const sanitizeHtmlConfig = {
  allowedTags: [
    'a',
    'b',
    'blockquote',
    'br',
    'caption',
    'code',
    'div',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h6',
    'hr',
    'i',
    'img',
    'li',
    'ol',
    'p',
    'pre',
    'span',
    'strike',
    'strong',
    's',
    'table',
    'tbody',
    'td',
    'thead',
    'tr',
    'th',
    'u',
    'ul',
  ],
  allowedAttributes: {
    '*': ['style'],
    a: ['href'],
    img: ['*'],
    table: ['*'],
  },
};

export const getBodyAsSafeHtml = html => sanitizeHtml(html, sanitizeHtmlConfig);

export const getDefaultIntegrationAsContact = integration => ({
  contactType: 'User',
  id: integration.id,
  name: integration.senderName,
  email: integration.emailAddress,
});

export const getBodyAsText = body => {
  const htmlParser = document.createElement('div');
  htmlParser.insertAdjacentHTML('afterbegin', body);
  htmlParser.querySelectorAll('style').forEach(e => e.remove());
  return htmlParser.textContent;
};

export const templatizeHtml = (html, substitutions) => {
  if (!html) return html;
  const htmlParser = document.createElement('div');
  htmlParser.insertAdjacentHTML('afterbegin', html);

  htmlParser.querySelectorAll('.tk-ql-template-string').forEach(el => {
    const templateValue = document.createTextNode(
      get(substitutions, el.getAttribute('data-template-key')) || '',
    );
    el.parentNode.replaceChild(templateValue, el);
  });

  htmlParser.querySelectorAll('.tk-ql-template-link').forEach(el => {
    const link = get(substitutions, el.getAttribute('data-template-key')) || {};
    const { url = '', title = '' } = link;
    const templateLink = document.createElement('A');
    templateLink.setAttribute('href', url);
    const templateText = document.createTextNode(title);
    templateLink.appendChild(templateText);
    el.parentNode.replaceChild(templateLink, el);
  });

  return htmlParser.innerHTML;
};

export const templatizeAndSanitizeHtml = (html, substitutions) => {
  return sanitizeHtml(templatizeHtml(html, substitutions), sanitizeHtmlConfig);
};

/*
  For use testing and demoing outreach:
    Given the sender's email and a target email,
    constructs a fake email that will send back to the sender,
    but with the target email encoded in the address.
    For testing outreach without spamming contacts.
*/
export const buildFauxEmail = targetEmail => {
  const sourceEmail = TK_TEST_EMAIL;
  const sourceEmailPrefix = `${sourceEmail.split('@')[0]}+`;
  const sourceEmailSuffix = `@${sourceEmail.split('@')[1]}`;
  return `${sourceEmailPrefix}${encodeURIComponent(
    targetEmail,
  )}${sourceEmailSuffix}`;
};

/*
  Extracts a real email from a faux email, so that replying to a faux email doesn't
  result in double encoding.
*/
export const extractRealEmail = email => {
  // email = 'tk.outreach.test+reese%40nytimes.com@gmail.com';
  let result = email;
  const sourceEmailPrefix = `${TK_TEST_EMAIL.split('@')[0]}+`;
  const sourceEmailSuffix = `@${TK_TEST_EMAIL.split('@')[1]}`;

  if (
    email.startsWith(sourceEmailPrefix) &&
    email.endsWith(sourceEmailSuffix)
  ) {
    const emailMiddle = email.substring(
      sourceEmailPrefix.length,
      email.length - sourceEmailSuffix.length,
    );
    result = isValidEmail(decodeURIComponent(emailMiddle))
      ? decodeURIComponent(emailMiddle)
      : result;
  }

  return result;
};

export const buildPreviewThreadFromSentMessage = message => ({
  accountId: null,
  authorLists: [],
  authors: [],
  campaigns: [],
  draftIds: [],
  dateLastMessageDelivered: message.dateDelivered,
  id: message.threadId || '',
  labels: [],
  messageIds: [message.id],
  object: 'thread',
  participants: [message.from, ...message.to, ...message.cc, ...message.bcc],
  snippet: getBodyAsText(message.body) || '',
  stories: [],
  subject: message.subject || '',
  unread: true,
});

export const prepareContactsForBulkOutreach = contacts => {
  try {
    return contacts
      .map(c => {
        const emails = c.contacts
          .filter(
            e =>
              e.contactType === 'Email' && !e.dateOutdated && !e.dateSuppressed,
          )
          .map(e => e.value);

        return {
          id: parseInt(c.id, 10),
          contactType: OUTREACH_CONTACT_TYPES.author,
          // used for ListEntryWithAvatar
          contacts: emails.map(email => ({
            contactType: 'Email',
            value: email,
          })),
          emails,
          publications: c.publications,
          image: c.image,
          fullName: c.fullName,
          substitutions: {
            'First name': c.firstName,
            'Last name': c.lastName,
            'Full name': c.fullName,
          },
        };
      })
      .filter(c => c.emails.length > 0);
  } catch (e) {
    return [];
  }
};

export const prepareInfluencersForBulkOutreach = influencers => {
  const isWellFormed = influencer => typeof influencer.name === 'string';
  try {
    return influencers
      .filter(isWellFormed)
      .map(influencer => {
        const emails = [
          ...new Set(
            influencer.contacts
              .filter(c => c.type === 'email' && c.text !== null)
              .map(c => c.text),
          ),
        ];

        const nameSplitter = influencer.name.lastIndexOf(' ');
        const firstName = influencer.name.substring(0, nameSplitter);
        const lastName = influencer.name.substring(nameSplitter + 1);

        const getGermanGreeting = () => {
          const prefix = influencer.prefix?.toLowerCase();
          const lastName = influencer.lastName;
          const defaultGermanGreeting = 'Sehr geehrte Damen und Herren';

          if (!(prefix && lastName)) {
            return defaultGermanGreeting;
          }

          const germanPrefixesUppercase = prefix
            .split(' ')
            .map(germanWord => {
              return germanWord.charAt(0).toUpperCase() + germanWord.slice(1);
            })
            .join(' ');

          if (
            prefix.startsWith('frau') ||
            prefix.startsWith('ms') ||
            prefix.startsWith('msr') ||
            prefix.startsWith('miss') ||
            prefix.startsWith('fr')
          ) {
            return `Sehr geehrte 
            ${germanPrefixesUppercase}
             ${lastName}`;
          }

          if (
            prefix.startsWith('herr') ||
            prefix.startsWith('mr') ||
            prefix.startsWith('hr')
          ) {
            return `Sehr geehrter ${germanPrefixesUppercase} ${lastName}`;
          }

          return defaultGermanGreeting;
        };

        return {
          id: influencer.id,
          contactType: OUTREACH_CONTACT_TYPES.influencer,
          // used for ListEntryWithAvatar
          contacts: emails.map(email => ({
            contactType: 'Email',
            value: email,
          })),
          emails,
          publications: influencer.outlets.map(o => o.name),
          image: {
            url: influencer.image,
          },
          fullName: influencer.name,
          substitutions: {
            'First name': influencer.firstName || firstName || '',
            'Last name': influencer.lastName || lastName || '',
            'Full name': influencer.name,
            'German greeting': getGermanGreeting(),
          },
        };
      })
      .filter(influencer => influencer.emails.length > 0);
  } catch (e) {
    return [];
  }
};

export const prepareRecipientsForBulkOutreach = (
  recipients,
  defaultSubstitutions = {},
) => {
  let contacts;
  try {
    contacts = recipients
      .map(r => ({
        contactId: r.contactId !== null ? parseInt(r.contactId, 10) : null,
        influencerId: r.influencerId,
        recipientId: r.id,
        // used for ListEntryWithAvatar
        contacts: r.emails.map(email => ({
          contactType: 'Email',
          value: email,
        })),
        image: {
          loading: r.imageLoading,
          url: r.imageUrl,
        },
        emails: r.emails,
        fullName: r.name || '',
        contactType: r?.influencerId
          ? OUTREACH_CONTACT_TYPES.influencer
          : OUTREACH_CONTACT_TYPES.author,
        name: r.name || '',
        publications: r.publications,
        substitutions: {
          ...defaultSubstitutions,
          'First name': r.firstName,
          'Full name': r.name,
          'Story link': {
            ...defaultSubstitutions['Story link'],
            url: r.storyUrl,
          },
        },
      }))
      .filter(c => !isEmpty(c.emails));
  } catch (e) {
    contacts = [];
  }

  return contacts;
};

export const getDraftTemplateAndMessagesFromBulk = bulkInfo => {
  const { contacts, templateFields, customMessagesByContactIds } = bulkInfo;
  const messageStoryId = bulkInfo.context.storyId;

  const preppedMessages = contacts.map(contact => {
    const allContactEmails = contact.emails.map(email => ({
      contactType: 'Author',
      email,
      firstName: contact.firstName,
      id: contact.id,
      name: contact.fullName,
    }));

    const contexts = [
      {
        contextType: 'bulkMessageType',
        id: has(customMessagesByContactIds, contact.id)
          ? 'PERSONALIZED'
          : 'DEFAULT',
      },
    ];

    if (messageStoryId) {
      contexts.push({
        contextType: 'recipientId',
        id: contact.recipientId,
      });
    }

    const messageFields = {
      ...templateFields,
      to: [],
      ...customMessagesByContactIds[contact.id],
    };

    const message = {
      ...messageFields,
      contexts,
      body: templatizeAndSanitizeHtml(messageFields.body, {
        ...templateFields.substitutions,
        ...contact.substitutions,
      }),
      templatizedBody: messageFields.body,
      /*
        TODO:
        Sunset numeric IDs if possible, and stop determining influencer vs. author based on typeof contact.id https://jira.cision.com/browse/EVER-3089
      */
      authors:
        contact.contactType === OUTREACH_CONTACT_TYPES.author &&
        !isNaN(parseInt(contact.id, 10))
          ? [parseInt(contact.id, 10)]
          : [],
      influencers:
        contact.contactType === OUTREACH_CONTACT_TYPES.influencer
          ? [contact.id]
          : [],
      stories: messageStoryId ? [messageStoryId] : [],
      // TODO: Make to[].contactType use "Influencer" after outreach-service supports it
      to: isEmpty(messageFields.to) ? allContactEmails : messageFields.to,
      personalized: has(customMessagesByContactIds, contact.id),
    };

    return message;
  });

  return {
    messages: preppedMessages,
    messageStoryId,
    template: templateFields,
  };
};

export const getFromFieldFromIntegration = outreachIntegration => {
  if (
    !outreachIntegration ||
    !outreachIntegration.emailAddress ||
    !outreachIntegration.id
  ) {
    return null;
  }

  return {
    contactType: OUTREACH_CONTACT_TYPES.user,
    email: outreachIntegration.emailAddress,
    id: outreachIntegration.id,
    name: outreachIntegration.senderName || '',
  };
};

export const getParticipatingIntegrations = ({
  integrations,
  message = {},
}) => {
  const allParticipantsAndLurkers = [
    ...(message.bcc || []),
    ...(message.cc || []),
    ...(message.to || []),
    ...(message.from || []),
  ];

  return integrations.filter(
    integration =>
      !!allParticipantsAndLurkers.filter(
        contact =>
          contact.email?.toLowerCase() ===
          integration.emailAddress?.toLowerCase(),
      ).length,
  );
};

export const getFormattedDateWithTimestamp = ({ timestamp, timezone }) => {
  const date = moment(timestamp).tz(timezone);
  return `${date.format('dddd, MMM D')} at ${date.format(
    'LT',
  )} (${timezone.replace('_', ' ')})`;
};

export const getTimeInTimezoneFromSchedule = ({ timestamp, timezone }) => {
  const date = moment(timestamp).tz(timezone);
  return `${date.format('LT')}`;
};

export const getFormattedDateWithTimestampInLocalTime = ({ timestamp }) => {
  const date = moment(timestamp);
  return `${date.format('dddd, MMM D')} at ${date.format('LT')}`;
};

export const storeComposeModalValues = composeModalValues =>
  window.sessionStorage.setItem(
    'composeModalValues',
    JSON.stringify(composeModalValues),
  );

export const handleComposeModalRedirect = ({
  openComposeModalWithValues,
  location,
  history,
}) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (!queryParams.openComposeModal) {
    return;
  }

  const storedComposeModalValuesString = window.sessionStorage.getItem(
    'composeModalValues',
  );
  let composeModalValues;

  try {
    composeModalValues = JSON.parse(storedComposeModalValuesString);
  } catch (e) {
    window.sessionStorage.setItem('composeModalValues', JSON.stringify({}));
  }

  if (composeModalValues) {
    openComposeModalWithValues(composeModalValues);
  }

  removeSensitiveParamsFromUrl({
    history,
    location,
    params: ['openComposeModal'],
  });
};

export default {
  getBodyAsSafeHtml,
  getBodyAsText,
  getFromFieldFromIntegration,
  getParticipatingIntegrations,
  extractRealEmail,
  buildFauxEmail,
  prepareContactsForBulkOutreach,
  getDraftTemplateAndMessagesFromBulk,
  getDefaultIntegrationAsContact,
};
