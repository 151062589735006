import React from 'react';

function useClickOutside<T extends HTMLElement>(
  onClickOutside: (e: Event) => void,
) {
  const domNode = React.useRef<T>(null);

  React.useEffect(() => {
    const onClick = (e: Event) => {
      if (
        (!domNode.current || !domNode.current.contains(e.target as Node)) &&
        onClickOutside
      )
        onClickOutside(e);
    };

    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  });

  return domNode;
}

export default useClickOutside;
