export default {
  actionBarSelectAll: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SELECT_ALL',
    defaultMessage: 'Select all',
    description:
      'Dashboards 2.0: Article Drilldown: Text on the button to select all articles.',
  },
  actionBarSelectCurrentPage: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SELECT_CURRENT_PAGE',
    defaultMessage: 'Select current page',
    description:
      'Dashboards 2.0: Article Drilldown: Text on the button to select all articles from the current page.',
  },
  actionBarSelectedAll: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SELECTED_ALL',
    defaultMessage: '{TOTAL_COUNT} selected',
    description:
      'Dashboards 2.0: Article Drilldown: Text indication that all articles are selected.',
  },
  actionBarSelectedSome: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SELECTED_SOME',
    defaultMessage: '{SELECTED_COUNT} of {TOTAL_COUNT} selected',
    description:
      'Dashboards 2.0: Article Drilldown: Text indication that some articles are selected.',
  },
  actionBarRemove: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_REMOVE',
    defaultMessage: 'Remove',
    description:
      'Dashboards 2.0: Text on the button to remove selected article from list.',
  },
  actionBarTooltipRemove: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_TOOLTIP_REMOVE',
    defaultMessage: 'Remove Selected Articles',
    description:
      'Dashboards 2.0: Text in the tooltip of button to remove selected article from list.',
  },
  actionBarTag: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_TAG',
    defaultMessage: 'Tag',
    description:
      'Dashboards 2.0: Text on the button to tag selected articles from list.',
  },
  actionBarTooltipTag: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_TOOLTIP_TAG',
    defaultMessage: 'Tag Selected Articles',
    description:
      'Dashboards 2.0: Text on the tooltip of tag selected articles button',
  },
  actionBarArticleLimiter: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_ARTICLE_LIMITER',
    defaultMessage: 'per page',
    description:
      'Dashboards 2.0: Text on the tooltip for the Article Limiter dropdown',
  },
  actionBarTooltipTagOptions: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_TOOLTIP_TAG_OPTIONS',
    defaultMessage: 'Tag Options',
    description:
      'Dashboards 2.0: Text on the tooltip for the Tag Options dropdown',
  },
  actionBarRefreshSocial: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_REFRESH_SOCIAL',
    defaultMessage: 'Refresh Social',
    description: 'Dashboards 2.0: Text on the button to refresh social.',
  },
  actionBarAddArticleUrls: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_ADD_ARTICLE_URLS',
    defaultMessage: 'Add Article URLs',
    description: 'Dashboards 2.0: Text on the button to bulk article add.',
  },
  actionBarSentiment: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SENTIMENT',
    defaultMessage: 'Sentiment',
    description: 'Dashboards 2.0: Text on the button to select sentiment.',
  },
  actionBarTooltipSentiment: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_TOOLTIP_SENTIMENT',
    defaultMessage: 'Set Sentiment',
    description: 'Dashboards 2.0: Text on the tooltip of set sentiment button.',
  },
  actionBarPositive: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SENTIMENT_POSITIVE',
    defaultMessage: 'Positive',
    description:
      'Dashboards 2.0: Text on the button to select positive sentiment.',
  },
  actionBarNeutral: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SENTIMENT_NEUTRAL',
    defaultMessage: 'Neutral',
    description:
      'Dashboards 2.0: Text on the button to select neutral sentiment.',
  },
  actionBarNegative: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_SENTIMENT_NEGATIVE',
    defaultMessage: 'Negative',
    description:
      'Dashboards 2.0: Text on the button to select negative sentiment.',
  },
  actionBarFilters: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_FILTERS',
    defaultMessage: `Filters { COUNT, plural,
      =0 {}
      other {(#)}
    }`,
    description:
      'Dashboards 2.0: Text on the button that toggles the article filtering sidebar.',
  },
  actionEarnedSearchesFilterBy: {
    id: 'EARNED_SEARCHES_DRILLDOWN_LIST_ACTION_BAR_FILTER_BY_ALL_SELECTED',
    defaultMessage: 'All Media Outlets',
    description:
      'Earned Searches, Text that displays in the Filter by (Mediatype) dropdown when selecting all options..',
  },
  socialDataUpdating: {
    id: 'SOCIAL_DATA_UPDATING_INFO_MSG',
    defaultMessage: 'Social data request is being submitted.',
    description: 'Information kite message about social data being updated',
  },
  socialDataUpdateSuccess: {
    id: 'SOCIAL_DATA_UPDATE_SUCCESS_INFO_MSG',
    defaultMessage:
      'Social data request has been submitted, refresh your page in a few minutes to see results.',
    description:
      'Information kite message about social data successfully updated',
  },
  socialDataUpdateError: {
    id: 'SOCIAL_DATA_UPDATE_ERROR_INFO_MSG',
    defaultMessage:
      'There was a problem submitting a social data request, please try again.',
    description: 'Information kite message about a social update error',
  },
  articleFilterInputPlaceHolder: {
    id: 'ARTICLE_FILTER_INPUT_PLACEHOLDER',
    defaultMessage: 'Enter keyword...',
    description: 'Enter keyword for the drilldown filter',
  },
  selectColumnsTooltip: {
    id: 'SELECT_COLUMNS_TOOLTIP',
    defaultMessage: 'Select Columns',
    description: 'Select Columns tooltip for the drilldown',
  },
  searchTooltip: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_TOOLTIP_SEARCH',
    defaultMessage: 'Search',
    description:
      'Dashboards 2.0: Text on the tooltip of search articles button',
  },
  actionBarTooltipExportResults: {
    id: 'DASHBOARDS_DRILLDOWN_LIST_ACTION_BAR_TOOLTIP_EXPORT_RESULTS',
    defaultMessage: 'Export Results',
    description: 'Dashboards 2.0: Text on the tooltip of export results button',
  },
};
