import { defaultTemplate, storyValidator } from '@trendkite/stories';

import { STORY_BASE_ENDPOINT } from 'constants/apis';
import {
  PAGE_MESSAGE_DEFAULT_TTL,
  STORY_KIT_SHARE_LEVELS,
} from 'constants/constants';
import { storyStates } from 'constants/story-constants';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
  Message,
} from 'reducers/page-messages';
import { getSearchBySearchId } from 'reducers/searches';
import { loadStoryContentFormActionCreator } from 'reducers/stories/story-content-form';
import { loadStoryPressContactFormActionCreator } from 'reducers/stories/story-press-contact-form';
import { loadStoryTitleFormActionCreator } from 'reducers/stories/story-title-form';
import {
  storySelector,
  storyBuilderSelector,
  isStorySavingSelector,
  isStorySavePendingSelector,
} from 'selectors/stories';

import {
  performPost,
  performPut,
  performGet,
} from 'services/rest-service/rest-service';
import { getCurrentUserId } from 'services/user-service/user-service';

// ADD_BULK_TO_STORY is for testing purposes only. Pass bulkId, schedule object, or both
export const ADD_BULK_TO_STORY = 'story/ADD_BULK_TO_STORY';
export const CREATE_STORY = 'story/CREATE_STORY';
export const CREATE_STORY_SUCCESS = 'story/CREATE_STORY_SUCCESS';
export const CREATE_STORY_ERROR = 'story/CREATE_STORY_ERROR';
export const CHANGE_STORY_TITLE = 'story/CHANGE_STORY_TITLE';
export const CHANGE_STORY_CONTENT = 'story/CHANGE_STORY_CONTENT';
export const GET_STORY = 'story/GET_STORY';
export const GET_STORY_SUCCESS = 'story/GET_STORY_SUCCESS';
export const GET_STORY_ERROR = 'story/GET_STORY_ERROR';
export const GET_STORY_GLOBAL = 'story/GET_STORY_GLOBAL';
export const GET_STORY_GLOBAL_SUCCESS = 'story/GET_STORY_GLOBAL_SUCCESS';
export const GET_STORY_GLOBAL_ERROR = 'story/GET_STORY_GLOBAL_ERROR';
export const RESET_STORY = 'story/RESET_STORY';
export const SAVE_STORY = 'story/SAVE_STORY';
export const STORY_INVALID = 'story/STORY_INVALID';
export const SAVE_STORY_ERROR = 'story/SAVE_STORY_ERROR';
export const SAVE_STORY_SUCCESS = 'story/SAVE_STORY_SUCCESS';
export const SAVE_STORY_PENDING = 'story/SAVE_STORY_PENDING';
export const SAVE_STORY_RETRY_PENDING = 'story/SAVE_STORY_RETRY_PENDING';
export const CANCEL_RETRY = 'story/CANCEL_RETRY';
export const LAUNCH_STORY = 'story/LAUNCH_STORY';
export const LAUNCH_STORY_ERROR = 'story/LAUNCH_STORY_ERROR';
export const LAUNCH_STORY_SUCCESS = 'story/LAUNCH_STORY_SUCCESS';
export const PUBLISH_CHANGES = 'story/PUBLISH_CHANGES';
export const PUBLISH_CHANGES_SUCCESS = 'story/PUBLISH_CHANGES_SUCCESS';
export const PUBLISH_CHANGES_ERROR = 'story/PUBLISH_CHANGES_ERROR';
export const PAUSE_STORY = 'story/PAUSE_STORY';
export const PAUSE_STORY_SUCCESS = 'story/PAUSE_STORY_SUCCESS';
export const PAUSE_STORY_ERROR = 'story/PAUSE_STORY_ERROR';
export const GET_STORY_PERFORMANCE_METRICS =
  'story/GET_STORY_PERFORMANCE_METRICS';
export const GET_STORY_PERFORMANCE_METRICS_SUCCESS =
  'story/GET_STORY_PERFORMANCE_METRICS_SUCCESS';
export const GET_STORY_PERFORMANCE_METRICS_ERROR =
  'story/GET_STORY_PERFORMANCE_METRICS_ERROR';
export const SET_STORY = 'story/SET_STORY';

const initialState = {
  bulkId: null,
  error: null,
  isLaunching: false,
  isPublishing: false,
  isLoading: false,
  isSaving: false,
  isSavePending: false,
  lastSavedStory: null,
  story: null,
  isInvalid: false,
  validationErrors: null,
  retryTimeout: null,
  retryDelay: NaN,
  schedule: null,
};

const storyBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STORY:
      return { ...initialState };
    case CREATE_STORY:
    case GET_STORY:
      return {
        ...state,
        isLoading: true,
        story: null,
        error: null,
        isInvalid: false,
        validationErrors: null,
      };
    case GET_STORY_GLOBAL:
      return {
        ...state,
        isLoading: true,
        globalStory: null,
        error: null,
        isInvalid: false,
        validationErrors: null,
      };
    case CREATE_STORY_SUCCESS:
    case GET_STORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        story: action.payload.story,
        lastSavedStory: action.payload.story,
      };
    case GET_STORY_GLOBAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        story: {
          ...state.story,
          openRateGlobal: action.payload.story.openRate,
          averageTimeOnPageGlobal: action.payload.story.averageTimeOnPage,
          conversionRateGlobal: action.payload.story.conversionRate,
          dailyVisitorsGlobal: action.payload.story.dailyVisitors,
          totalPageVisitsGlobal: action.payload.story.totalPageVisits,
          currentVisitorsGlobal: action.payload.story.currentVisitors,
        },
      };
    case CREATE_STORY_ERROR:
    case GET_STORY_ERROR:
    case GET_STORY_GLOBAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case CHANGE_STORY_TITLE:
      return {
        ...state,
        story: {
          ...state.story,
          title: action.payload.title,
        },
      };
    case CHANGE_STORY_CONTENT: {
      const story = { ...state.story };
      if (
        story.state === storyStates.live ||
        story.state === storyStates.paused
      ) {
        if (!story.draftContent) {
          story.draftContent = action.payload.content;
        } else {
          story.draftContent = {
            ...story.draftContent,
            ...action.payload.content,
          };
        }
      } else {
        story.content = {
          ...story.content,
          ...action.payload.content,
        };
      }

      return {
        ...state,
        story,
      };
    }
    case SAVE_STORY_PENDING:
      return {
        ...state,
        isSavePending: true,
      };
    case SAVE_STORY_RETRY_PENDING:
      return {
        ...state,
        isSavePending: true,
        retryTimeout: action.payload.retryTimeout,
        retryDelay: action.payload.retryDelay,
      };
    case SAVE_STORY:
      return {
        ...state,
        isSaving: true,
        isSavePending: false,
        validactionErrors: null,
        isInvalid: false,
        retryTimeout: null,
        retryDelay: NaN,
        error: null,
      };
    case SAVE_STORY_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isSaving: false,
      };
    case SAVE_STORY_SUCCESS:
      return {
        ...state,
        isSaving: false,
        lastSavedStory: action.payload.story,
        story: {
          ...state.story,
          ...action.payload.story,
        },
      };
    case CANCEL_RETRY:
      return {
        ...state,
        retryTimeout: null,
      };
    case STORY_INVALID:
      return {
        ...state,
        isInvalid: true,
        validationErrors: action.payload.errors,
      };
    case LAUNCH_STORY:
      return {
        ...state,
        isInvalid: false,
        isLaunching: true,
        validationErrors: null,
      };
    case LAUNCH_STORY_SUCCESS:
      return {
        ...state,
        isLaunching: false,
        story: {
          ...state.story,
          ...action.payload.story,
        },
      };
    case LAUNCH_STORY_ERROR:
      return {
        ...state,
        isLaunching: false,
        error: action.payload.error,
      };
    case PUBLISH_CHANGES:
      return {
        ...state,
        isPublishing: true,
        error: null,
        validationErrors: null,
      };
    case ADD_BULK_TO_STORY:
      return {
        ...state,
        ...action.payload,
      };
    case PUBLISH_CHANGES_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        story: {
          ...state.story,
          ...action.payload.story,
        },
      };
    case PUBLISH_CHANGES_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: action.payload.error,
      };
    case PAUSE_STORY:
      return {
        ...state,
        story: {
          ...state.story,
          state: storyStates.paused,
        },
      };
    case PAUSE_STORY_ERROR:
      return {
        ...state,
        story: {
          ...state.story,
          state: storyStates.live,
        },
      };
    case SET_STORY:
      return {
        ...state,
        story: action.payload,
      };
    default:
      return state;
  }
};

export const setStory = story => ({
  type: SET_STORY,
  payload: story,
});

export const cancelRetry = () => (dispatch, getState) => {
  const { retryTimeout: currentTimeout } = storyBuilderSelector(getState());
  if (currentTimeout) {
    clearTimeout(currentTimeout);
    dispatch({ type: CANCEL_RETRY });
  }
};

const saveStory = async (dispatch, getState) => {
  const state = getState();
  const currentStory = storySelector(state);
  const currentUserId = getCurrentUserId();
  const isStoryOwner = currentUserId === currentStory.userId;

  if (
    !isStoryOwner &&
    currentStory.shareLevel === STORY_KIT_SHARE_LEVELS.view
  ) {
    return;
  }

  cancelRetry()(dispatch, getState);
  dispatch({ type: SAVE_STORY });
  try {
    const result = await performPut(
      `${STORY_BASE_ENDPOINT}/${currentStory.id}`,
      currentStory,
    );
    if (isStorySavePendingSelector(getState())) {
      saveStory(dispatch, getState);
    } else {
      dispatch({ type: SAVE_STORY_SUCCESS, payload: { story: result.data } });
    }
  } catch (e) {
    const retryDelay = 30000;
    const retryTimeout = setTimeout(
      () => saveStory(dispatch, getState),
      retryDelay,
    );
    dispatch({
      type: SAVE_STORY_RETRY_PENDING,
      payload: { retryTimeout, retryDelay },
    });
    dispatch({
      type: SAVE_STORY_ERROR,
      payload: { error: (e.response && e.response.data) || 'Unknown error' },
    });
    throw e;
  }
};

const storySavePending = (dispatch, getState, forceSave) => {
  const currentState = getState();
  if (isStorySavingSelector(currentState) || !currentState.network.isOnline) {
    dispatch({ type: SAVE_STORY_PENDING });
  } else if (forceSave || !currentState.stories.storyBuilder.retryTimeout) {
    saveStory(dispatch, getState);
  }
};

export const triggerTryStorySave = () => (dispatch, getState) =>
  storySavePending(dispatch, getState, true);

const changeStory = actionCreator => (...args) => async (
  dispatch,
  getState,
) => {
  dispatch(actionCreator(...args));
  storySavePending(dispatch, getState);
};

export const resetStoryActionCreator = () => ({ type: RESET_STORY });
export const changeStoryTitle = changeStory(title => ({
  type: CHANGE_STORY_TITLE,
  payload: { title },
}));
export const changeStoryContent = changeStory(content => ({
  type: CHANGE_STORY_CONTENT,
  payload: { content },
}));

const getStoryGlobal = async (id, dispatch) => {
  let result;
  dispatch({ type: GET_STORY_GLOBAL });
  try {
    result = await performGet(
      `${STORY_BASE_ENDPOINT}/${id}?withGlobalAnalytics=true`,
      null,
    );
  } catch (e) {
    dispatch({ type: GET_STORY_GLOBAL_ERROR, payload: { error: e.message } });
    throw e;
  }

  if (result) {
    dispatch({
      type: GET_STORY_GLOBAL_SUCCESS,
      payload: { story: result.data },
    });
  }
};

export const getStoryActionCreator = (
  id,
  withGlobalAnalytics = false,
) => async dispatch => {
  let result;
  dispatch({ type: GET_STORY });

  try {
    result = await performGet(`${STORY_BASE_ENDPOINT}/${id}`, null, {
      cache: 'max-age=0, private, must-revalidate',
    });
  } catch (e) {
    dispatch({ type: GET_STORY_ERROR, payload: { error: e.message } });
    throw e;
  }

  if (result) {
    dispatch({ type: GET_STORY_SUCCESS, payload: { story: result.data } });
    dispatch(loadStoryContentFormActionCreator(result.data));
    dispatch(loadStoryPressContactFormActionCreator(result.data));
    dispatch(loadStoryTitleFormActionCreator(result.data));
    if (result.data.searchId && result.data.state !== storyStates.draft) {
      dispatch(getSearchBySearchId(result.data.searchId));
    }
  }

  if (withGlobalAnalytics) {
    getStoryGlobal(id, dispatch);
  }
};

export const createStoryActionCreator = () => async dispatch => {
  dispatch({ type: CREATE_STORY });

  try {
    const result = await performPost(STORY_BASE_ENDPOINT, {
      state: storyStates.draft,
      title: '',
      templateVersion: defaultTemplate.defaultProps.name,
      content: defaultTemplate.defaultProps.defaultContent,
      shared: false,
    });
    dispatch({ type: CREATE_STORY_SUCCESS, payload: { story: result.data } });
  } catch (e) {
    dispatch({ type: CREATE_STORY_ERROR, payload: { error: e.response.data } });
  }
};

export const invalidateStoryActionCreator = validationResult => async dispatch => {
  dispatch({
    type: STORY_INVALID,
    payload: { errors: validationResult.errors },
  });
};

export const launchStoryActionCreator = story => async dispatch => {
  const validationResult = storyValidator(story);

  if (!validationResult.valid) {
    dispatch({
      type: STORY_INVALID,
      payload: { errors: validationResult.errors },
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'Cannot publish story. Some content fields are invalid.',
        status: 'danger',
      }),
    );
    return;
  }

  dispatch({ type: LAUNCH_STORY });

  try {
    const launchStory = { ...story, state: storyStates.live };
    const result = await performPut(
      `${STORY_BASE_ENDPOINT}/${launchStory.id}`,
      launchStory,
    );
    dispatch({ type: LAUNCH_STORY_SUCCESS, payload: { story: result.data } });
  } catch (e) {
    dispatch({ type: LAUNCH_STORY_ERROR, payload: { error: e.response.data } });
  }
};

export const launchStoryByIdActionCreator = storyId => async dispatch => {
  let story;
  try {
    const storyResult = await performGet(
      `${STORY_BASE_ENDPOINT}/${storyId}`,
      null,
      { cache: 'max-age=0, private, must-revalidate' },
    );
    story = storyResult.data;
  } catch (e) {
    throw new Error('Could not publish Story Kit. Please try again later.');
  }

  if (story.state === storyStates.live) {
    return story;
  }

  dispatch({ type: LAUNCH_STORY });
  const validationResult = storyValidator(story);
  if (!validationResult.valid) {
    dispatch({
      type: LAUNCH_STORY_ERROR,
      payload: { error: validationResult.errors },
    });
    throw new Error(
      'Cannot publish the Story Kit. Some content fields are invalid.',
    );
  }

  try {
    const launchStory = { ...story, state: storyStates.live };
    const launchResult = await performPut(
      `${STORY_BASE_ENDPOINT}/${launchStory.id}`,
      launchStory,
    );
    dispatch({
      type: LAUNCH_STORY_SUCCESS,
      payload: { story: launchResult.data },
    });
    return launchResult.data;
  } catch (e) {
    throw new Error('Could not publish Story Kit. Please try again later.');
  }
};

export const publishChangesActionCreator = story => async dispatch => {
  const validationResult = storyValidator(story);

  if (!validationResult.valid) {
    dispatch({
      type: STORY_INVALID,
      payload: { errors: validationResult.errors },
    });
    dispatch(
      addPageMessage(
        new Message({
          status: 'danger',
          text: 'Cannot publish changes. Some content fields are invalid.',
        }),
      ),
    );
    return;
  }

  dispatch({ type: PUBLISH_CHANGES });

  try {
    const newStory = {
      ...story,
      state: storyStates.live,
      content: story.draftContent,
      draftContent: null,
    };
    const result = await performPut(
      `${STORY_BASE_ENDPOINT}/${newStory.id}`,
      newStory,
    );
    dispatch({
      type: PUBLISH_CHANGES_SUCCESS,
      payload: { story: result.data },
    });
    dispatch(
      addPageMessage(
        new Message({
          status: 'success',
          text: 'Well done! Your changes are now live!',
          ttl: PAGE_MESSAGE_DEFAULT_TTL,
        }),
      ),
    );
  } catch (e) {
    dispatch({
      type: PUBLISH_CHANGES_ERROR,
      payload: { error: e.response.data },
    });
    dispatch(
      addPageMessage(
        new Message({
          status: 'danger',
          text: 'Error publishing changes.',
          ttl: PAGE_MESSAGE_DEFAULT_TTL,
        }),
      ),
    );
  }
};

export const pauseStoryActionCreator = story => async dispatch => {
  dispatch({ type: PAUSE_STORY });

  try {
    const pausedStory = { ...story, state: storyStates.paused };
    await performPut(`${STORY_BASE_ENDPOINT}/${pausedStory.id}`, pausedStory);
    dispatch({ type: PAUSE_STORY_SUCCESS });
  } catch (e) {
    dispatch({ type: PAUSE_STORY_ERROR, payload: { error: e.response.data } });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'There was a problem pausing your story. Please try again later.',
        status: 'danger',
      }),
    );
    throw e;
  }
};

export default storyBuilderReducer;
