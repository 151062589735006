export default {
  title: {
    id: 'STORY-COMPANY-BACKGROUND-DISPLAY_TITLE',
    defaultMessage: 'Company Background',
    description:
      'Campaigns > Story Kit > Story Edit Page: content company background title',
  },
  placeholder: {
    id: 'STORY-COMPANY-BACKGROUND-DISPLAY_PLACEHOLDER',
    defaultMessage: 'Add a company background to this story (Click to Edit)',
    description:
      'Campaigns > Story Kit > Story Edit Page: content company background placeholder',
  },
};
