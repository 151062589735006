import moment from 'moment';

import { WIZARD_STEPS, METRICS } from 'components/widgetWizard/constants';
import { SETTING_TAB } from 'components/widgetWizard/steps/custom-chart-form';
import { getKeyMessageDataSources } from 'components/widgetWizard/utils';
import {
  NEW_DASHBOARD_ENDPOINT,
  SEARCH_DETAIL_ENDPOINT,
  TAG_BASE_ENDPOINT,
} from 'constants/apis';
import {
  DATA_SOURCES,
  DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS,
  DATA_SOURCE_TYPES,
  WIDGET_TYPES,
  V3_METRIC_FIELDS,
} from 'constants/constants';
import { reportSlides } from 'pages/Report/report-hub';
import { addPageMessage } from 'reducers/page-messages';
import { performPut, performPost } from 'services/rest-service/rest-service';
import { DATE_RANGE_KEYS } from 'utils/date/date-util';

export const UPDATE_KEY_MESSAGES = 'dashbosards/sidebar/UPDATE_KEY_MESSAGES';
export const UPDATE_KEY_MESSAGE_TITLE = 'widgetForm/UPDATE_KEY_MESSAGE_TITLE';
export const UPDATE_KEY_MESSAGE_BOOLEAN =
  'widgetForm/UPDATE_KEY_MESSAGE_BOOLEAN';
export const UPDATE_EXISTING_KEY_MESSAGE =
  'widgetForm/UPDATE_EXISTING_KEY_MESSAGE';
export const CLEAR_KEY_MESSAGE_EDITING = 'widgetForm/CLEAR_KEY_MESSAGE_EDITING';
export const UPDATE_KEY_MESSAGES_ERROR = 'widgetForm/UPDATE_KEY_MESSAGES_ERROR';

export const GET_TRANSLATED_KEY_MESSAGES =
  'widgetForm/GET_TRANSLATED_KEY_MESSAGES';
export const GET_TRANSLATED_KEY_MESSAGES_ERROR =
  'widgetForm/GET_TRANSLATED_KEY_MESSAGES_ERROR';
export const GET_TRANSLATED_KEY_MESSAGES_SUCCESS =
  'widgetForm/GET_TRANSLATED_KEY_MESSAGES_SUCCESS';

export const UPDATE_STEP = 'widgetForm/UPDATE_STEP';
export const UPDATE_STEPS = 'widgetForm/UPDATE_STEPS';

export const SWITCH_TAB = 'widgetForm/SWITCH_TAB';

export const CLEAR_VISUALIZATION_TYPE = 'widgetForm/CLEAR_VISUALIZATION_TYPE';

export const UPDATE_TITLE = 'widgetForm/UPDATE_TITLE';
export const CLEAR_TITLE = 'widgetForm/CLEAR_TITLE';

export const UPDATE_ANALYTICS = 'widgetForm/UPDATE_ANALYTICS';

export const UPDATE_V3_DATE_RANGE = 'widgetForm/UPDATE_V3_DATE_RANGE';

export const UPDATE_DATE_RANGE_COMPARE_TYPE =
  'widgetForm/UPDATE_DATE_RANGE_COMPARE_TYPE';
export const UPDATE_CUSTOM_DATE_RANGE_V3_COMPARE_TYPE =
  'widgetForm/UPDATE_CUSTOM_DATE_RANGE_V3_COMPARE_TYPE';
export const CLEAR_DATE_RANGE_COMPARE_TYPE =
  'widgetForm/CLEAR_DATE_RANGE_COMPARE_TYPE';

export const EDIT_EXISTING_WIDGET_V3 = 'widgetForm/EDIT_EXISTING_WIDGET_V3';

export const EDIT_EXISTING_KEY_MESSAGES_V3 =
  'widgetForm/EDIT_EXISTING_KEY_MESSAGES_V3';

export const EDIT_EXISTING_SEARCHES = 'widgetForm/EDIT_EXISTING_SEARCHES';

export const RESET_SIDEBAR = 'widgetForm/RESET_SIDEBAR';

export const CLEAR_SELECTED_SEARCHES = 'widgetForm/CLEAR_SELECTED_SEARCHES';

export const SET_SEARCH_MULTIPLE = 'widgetForm/SET_SEARCH_MULTIPLE';

export const UPDATE_GROUP_BY = 'widgetForm/UPDATE_GROUP_BY';

export const UPDATE_DIMENSION_V3 = 'widgetForm/UPDATE_DIMENSION_V3';

// V3 Only
export const UPDATE_FROM_BESPOKE_OPTION =
  'widgetForm/UPDATE_FROM_BESPOKE_OPTION';
export const UPDATE_DATA_SOURCES = 'widgetForm/ADD_DATA_SOURCES';
export const REMOVE_DATA_SOURCE = 'widgetForm/REMOVE_DATA_SOURCE';

export const REMOVE_PRIMARY_METRIC = 'widgetForm/REMOVE_PRIMARY_METRIC';
export const ADD_PRIMARY_METRIC = 'widgetForm/ADD_PRIMARY_METRIC';
export const UPDATE_PRIMARY_METRIC = 'widgetForm/UPDATE_PRIMARY_METRIC';
export const UPDATE_PRIMARY_METRIC_DIMENSIONS =
  'widgetForm/UPDATE_PRIMARY_METRIC_DIMENSIONS';
export const UPDATE_PRIMARY_METRIC_VISUALIZATION_TYPE =
  'widgetForm/UPDATE_PRIMARY_METRIC_VISUALIZATION_TYPE';

export const REMOVE_SECONDARY_METRIC = 'widgetForm/REMOVE_SECONDARY_METRIC';
export const ADD_SECONDARY_METRIC = 'widgetForm/ADD_SECONDARY_METRIC';
export const UPDATE_SECONDARY_METRIC = 'widgetForm/UPDATE_SECONDARY_METRIC';
export const UPDATE_SECONDARY_METRIC_DIMENSIONS =
  'widgetForm/UPDATE_SECONDARY_METRIC_DIMENSIONS';
export const UPDATE_SECONDARY_METRIC_VISUALIZATION_TYPE =
  'widgetForm/UPDATE_SECONDARY_METRIC_VISUALIZATION_TYPE';

export const SET_ACTIVE_METRIC = 'widgetForm/SET_ACTIVE_METRIC';
export const CLEAR_ACTIVE_METRIC = 'widgetForm/CLEAR_ACTIVE_METRIC';

export const TOGGLE_DATA_SOURCES_SHARED =
  'widgetForm/TOGGLE_DATA_SOURCES_SHARED';
export const CREATE_NEW_WIDGET_SEARCH_IN_PROCESS =
  'widgetForm/CREATE_NEW_WIDGET_SEARCH_IN_PROCESS';

export const CLEAR_TRANSLATED_KEY_MESSAGES =
  'widgetForm/CLEAR_TRANSLATED_KEY_MESSAGES';

export const SET_SELECTED_WIDGET_ID = 'widgetForm/SET_SELECTED_WIDGET_ID';

export const CLEAR_SELECTED_WIDGET_ID = 'widgetForm/CLEAR_SELECTED_WIDGET_ID';

const getSelectedItemIdsForWidgetV3 = dataSources => {
  return dataSources
    .filter(
      ds =>
        Object.values(DATA_SOURCE_TYPES).includes(ds.sourceType) &&
        (ds.source === DATA_SOURCES.trendkiteElasticSearch ||
          ds.source === DATA_SOURCES.trendkiteSocial ||
          ds.source === DATA_SOURCES.cidImpact),
    )
    .map(x => x.sourceId);
};

const getWidgetFormForWidgetV3 = widget => {
  const metrics = widget.metrics;
  let primaryMetric;
  let secondaryMetric;
  let keyMessageDataSources = [];

  const dateRangeCompareMetric = metrics.find(m => m.isDateRangeCompare);
  const gaMetric = metrics.find(
    m =>
      m.measure?.field === V3_METRIC_FIELDS.sessions ||
      m.measure?.field === V3_METRIC_FIELDS.prTraffic,
  );

  if (dateRangeCompareMetric) {
    primaryMetric = metrics.find(m => !m.isDateRangeCompare);
    secondaryMetric = null;
  } else if (gaMetric) {
    primaryMetric = metrics.find(
      m =>
        m.measure?.field !== V3_METRIC_FIELDS.sessions &&
        m.measure?.field !== V3_METRIC_FIELDS.prTraffic,
    );
    secondaryMetric = metrics.find(
      m =>
        m.measure?.field === V3_METRIC_FIELDS.sessions ||
        m.measure?.field === V3_METRIC_FIELDS.prTraffic,
    );
  } else {
    primaryMetric = metrics[0];
    secondaryMetric = metrics[1];
  }

  if (primaryMetric) {
    keyMessageDataSources = getKeyMessageDataSources(primaryMetric);
  }

  const form = {
    prevStep: null,
    title: widget.title,
    dateRangeCompareType: dateRangeCompareMetric?.dateRangeCompareType,
    customDateRangeV3: {
      startDate: dateRangeCompareMetric?.startDate
        ? moment.utc(dateRangeCompareMetric.startDate).valueOf()
        : null,
      endDate: dateRangeCompareMetric?.endDate
        ? moment.utc(dateRangeCompareMetric.endDate).valueOf()
        : null,
    },
    dateRangeType: primaryMetric?.dateRangeType || DATE_RANGE_KEYS.TRAILING_90,
    startDate: primaryMetric?.startDate,
    endDate: primaryMetric?.endDate,
    dataSourcesV3: primaryMetric?.dataSources || [],
    selectedItemIds: getSelectedItemIdsForWidgetV3(
      primaryMetric ? primaryMetric.dataSources : [],
    ),
    keyMessages: keyMessageDataSources.map(({ sourceId, metadata }) => ({
      searchId: sourceId,
      title: metadata?.sourceModel.title,
      simple: metadata?.sourceModel.simple,
      search: metadata?.sourceModel.search,
    })),
    selectedAnalyticId: gaMetric
      ? gaMetric.dataSources?.find(
          ds => ds.sourceType === 'ANALYTICS_INTEGRATION',
        )?.sourceId
      : null,
    widgetId: widget.id,
  };

  if (primaryMetric) {
    form.primaryMetric = primaryMetric;
  }

  if (secondaryMetric) {
    form.secondaryMetric = secondaryMetric;
  }

  return form;
};

const initialState = {
  tab: SETTING_TAB,
  step: WIZARD_STEPS.selectWidgetType,
  steps: [],
  prevStep: null,
  visualizationType: null,
  selectedItemIds: [],
  title: '',
  metrics: ['ARTICLE_MENTION'],
  dataSourceType: 'SEARCH',
  groupBy: 'DAY',
  dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
  startDate: null,
  endDate: null,
  dateRangeCompareType: null,
  customDateRangeV3: null,
  selectedAnalyticId: null,
  keyMessages: [],
  translatedKeyMessages: {
    messages: null,
    loading: false,
    error: null,
  },
  widgetId: null,
  editingExistingKeyMessageIndex: -1,
  editingKeyMessageTitle: '',
  editingKeyMessageBoolean: '',
  // v3 only
  primaryMetric: null,
  secondaryMetric: null,
  dataSourcesV3: [],
  dataSourcesShared: false,
  isCreatingNewWidgetSearch: false,
  selectedWidgetId: null,
};

const widgetFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STEP:
      return {
        ...state,
        prevStep: state.step,
        step: action.payload,
      };
    case UPDATE_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case SWITCH_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case CLEAR_VISUALIZATION_TYPE:
      return {
        ...state,
        visualizationType: null,
      };
    case UPDATE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case CLEAR_TITLE:
      return {
        ...state,
        title: '',
      };
    case UPDATE_DIMENSION_V3:
      return {
        ...state,
        dimensionsV3: action.payload,
      };
    case UPDATE_ANALYTICS:
      return {
        ...state,
        selectedAnalyticId: action.payload,
      };
    case UPDATE_V3_DATE_RANGE:
      return {
        ...state,
        dateRangeType: action.payload.dateRangeType,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case UPDATE_DATE_RANGE_COMPARE_TYPE:
      return {
        ...state,
        dateRangeCompareType: action.payload,
      };
    case UPDATE_CUSTOM_DATE_RANGE_V3_COMPARE_TYPE:
      return {
        ...state,
        customDateRangeV3: action.payload,
      };
    case UPDATE_KEY_MESSAGES:
      return {
        ...state,
        keyMessages: action.payload,
      };
    case UPDATE_KEY_MESSAGES_ERROR:
      return {
        ...state,
        translatedKeyMessages: {
          messages: null,
          loading: false,
          error: null,
        },
      };
    case UPDATE_KEY_MESSAGE_TITLE:
      return {
        ...state,
        editingKeyMessageTitle: action.payload,
      };
    case UPDATE_KEY_MESSAGE_BOOLEAN:
      return {
        ...state,
        editingKeyMessageBoolean: action.payload,
      };
    case UPDATE_EXISTING_KEY_MESSAGE:
      return {
        ...state,
        editingExistingKeyMessageIndex: action.payload,
      };
    case CLEAR_KEY_MESSAGE_EDITING:
      return {
        ...state,
        editingExistingKeyMessageIndex: -1,
        editingKeyMessageBoolean: '',
        editingKeyMessageTitle: '',
      };
    case GET_TRANSLATED_KEY_MESSAGES:
      return {
        ...state,
        translatedKeyMessages: {
          messages: null,
          loading: true,
          error: null,
        },
      };
    case GET_TRANSLATED_KEY_MESSAGES_SUCCESS:
      return {
        ...state,
        translatedKeyMessages: {
          messages: action.payload.keyMessages,
          loading: false,
          error: null,
        },
      };
    case GET_TRANSLATED_KEY_MESSAGES_ERROR:
      return {
        ...state,
        translatedKeyMessages: {
          loading: false,
          error: action.payload.error,
        },
      };
    case CLEAR_DATE_RANGE_COMPARE_TYPE:
      return {
        ...state,
        dateRangeCompareType: null,
      };
    case RESET_SIDEBAR:
      return {
        ...initialState,
      };
    case CLEAR_SELECTED_SEARCHES:
      return {
        ...state,
        selectedItemIds: [],
      };
    case EDIT_EXISTING_WIDGET_V3: {
      const widgetForm = getWidgetFormForWidgetV3(action.payload);
      return {
        ...state,
        ...widgetForm,
        step: WIZARD_STEPS.customChartForm,
      };
    }
    case EDIT_EXISTING_KEY_MESSAGES_V3: {
      const widgetForm = getWidgetFormForWidgetV3(action.payload);
      return {
        ...state,
        ...widgetForm,
        step: WIZARD_STEPS.addKeyMessages,
      };
    }
    case EDIT_EXISTING_SEARCHES: {
      const searches = action.payload.dataSources.filter(
        ({ type }) => type === 'SEARCH',
      );
      const keyMessageDataSources = action.payload.dataSources.filter(
        ({ type }) => type === 'KEY_MESSAGE',
      );
      return {
        ...state,
        step: WIZARD_STEPS.selectSearch,
        prevStep: null,
        visualizationType: action.payload.visualizationType,
        selectedItemIds: searches.map(x => x.searchId),
        title: action.payload.title,
        metrics: action.payload.metrics,
        dateRangeType: action.payload.dateRangeType,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        dateRangeCompareType: action.payload.dateRangeCompareType,
        groupBy: action.payload.groupBy,
        widgetId: action.payload.id,
        keyMessages: keyMessageDataSources.map(
          ({ searchId, keyMessage: km }) => ({
            searchId,
            title: km.title,
            simple: km.simple,
            search: km.search,
            type: 'KEY_MESSAGE',
          }),
        ),
      };
    }
    case UPDATE_GROUP_BY:
      return {
        ...state,
        groupBy: action.payload,
      };
    case UPDATE_FROM_BESPOKE_OPTION: {
      // V3 ONLY
      const widgetForm = getWidgetFormForWidgetV3(action.payload);
      return {
        ...state,
        ...widgetForm,
      };
    }
    case UPDATE_DATA_SOURCES: // V3 ONLY
      return {
        ...state,
        dataSourcesV3: action.payload,
        selectedItemIds: getSelectedItemIdsForWidgetV3(action.payload),
      };
    case REMOVE_DATA_SOURCE: // V3 ONLY
      return {
        ...state,
        dataSourcesV3: action.payload,
        selectedItemIds: getSelectedItemIdsForWidgetV3(action.payload),
      };
    case REMOVE_PRIMARY_METRIC: // V3 ONLY
      return {
        ...state,
        primaryMetric: null,
      };
    case ADD_PRIMARY_METRIC: // V3 ONLY
      return {
        ...state,
        primaryMetric: action.payload,
      };
    case UPDATE_PRIMARY_METRIC: // V3 ONLY
      return {
        ...state,
        primaryMetric: action.payload,
      };
    case UPDATE_PRIMARY_METRIC_DIMENSIONS: // V3 ONLY
      return {
        ...state,
        primaryMetric: {
          ...state.primaryMetric,
          dimensions: action.payload,
        },
      };
    case UPDATE_PRIMARY_METRIC_VISUALIZATION_TYPE: // V3 ONLY
      return {
        ...state,
        primaryMetric: {
          ...state.primaryMetric,
          visualizationType: action.payload,
        },
      };
    case REMOVE_SECONDARY_METRIC: // V3 ONLY
      return {
        ...state,
        secondaryMetric: null,
      };
    case ADD_SECONDARY_METRIC: // V3 ONLY
      return {
        ...state,
        secondaryMetric: action.payload,
      };
    case UPDATE_SECONDARY_METRIC: // V3 ONLY
      return {
        ...state,
        secondaryMetric: action.payload,
      };
    case UPDATE_SECONDARY_METRIC_DIMENSIONS: // V3 ONLY
      return {
        ...state,
        secondaryMetric: {
          ...state.secondaryMetric,
          dimensions: action.payload,
        },
      };
    case UPDATE_SECONDARY_METRIC_VISUALIZATION_TYPE: // V3 ONLY
      return {
        ...state,
        secondaryMetric: {
          ...state.secondaryMetric,
          visualizationType: action.payload,
        },
      };
    case SET_ACTIVE_METRIC: // V3 ONLY
      if (action.payload === METRICS.primaryMetric && state.primaryMetric) {
        return {
          ...state,
          primaryMetric: {
            ...state.primaryMetric,
            active: true,
          },
          ...(state.secondaryMetric
            ? {
                secondaryMetric: { ...state.secondaryMetric, active: false },
              }
            : {}),
        };
      } else if (
        action.payload === METRICS.secondaryMetric &&
        state.secondaryMetric
      ) {
        return {
          ...state,
          secondaryMetric: {
            ...state.secondaryMetric,
            active: true,
          },
          ...(state.primaryMetric
            ? {
                primaryMetric: { ...state.primaryMetric, active: false },
              }
            : {}),
        };
      }
      return state;
    case TOGGLE_DATA_SOURCES_SHARED:
      return {
        ...state,
        dataSourcesShared: !state.dataSourcesShared,
      };
    case CREATE_NEW_WIDGET_SEARCH_IN_PROCESS:
      return {
        ...state,
        isCreatingNewWidgetSearch: action.payload,
      };
    case CLEAR_ACTIVE_METRIC:
      if (action.payload === METRICS.primaryMetric) {
        return {
          ...state,
          primaryMetric: {
            ...state.primaryMetric,
            active: false,
          },
        };
      } else if (action.payload === METRICS.secondaryMetric) {
        return {
          ...state,
          secondaryMetric: {
            ...state.secondaryMetric,
            active: false,
          },
        };
      }
      return state;
    case CLEAR_TRANSLATED_KEY_MESSAGES:
      return {
        ...state,
        translatedKeyMessages: {
          messages: null,
          loading: false,
          error: null,
        },
      };

    case SET_SELECTED_WIDGET_ID:
      return { ...state, selectedWidgetId: action.payload };

    case CLEAR_SELECTED_WIDGET_ID:
      return {
        ...state,
        selectedWidgetId: null,
      };

    default:
      return state;
  }
};

export const updateDimensionV3ActionCreator = (
  dimensionsV3 = [],
) => dispatch => {
  dispatch({
    type: UPDATE_DIMENSION_V3,
    payload: dimensionsV3,
  });
};

export const updateStepActionCreator = step => ({
  type: UPDATE_STEP,
  payload: step,
});

export const updateStepsActionCreator = steps => ({
  type: UPDATE_STEPS,
  payload: steps,
});

export const switchTabActionCreator = tab => ({
  type: SWITCH_TAB,
  payload: tab,
});

export const clearVisualizationTypeActionCreator = () => ({
  type: CLEAR_VISUALIZATION_TYPE,
});

export const updateGroupByActionCreator = groupBy => ({
  type: UPDATE_GROUP_BY,
  payload: groupBy,
});

export const updateTitleActionCreator = title => ({
  type: UPDATE_TITLE,
  payload: title,
});
export const clearTitleActionCreator = () => ({ type: CLEAR_TITLE });

export const updateKeyMessagesActionCreator = keyMessages => ({
  type: UPDATE_KEY_MESSAGES,
  payload: keyMessages,
});
export const updateKeyMessageTitleActionCreator = title => ({
  type: UPDATE_KEY_MESSAGE_TITLE,
  payload: title,
});
export const updateKeyMessageBooleanActionCreator = value => ({
  type: UPDATE_KEY_MESSAGE_BOOLEAN,
  payload: value,
});
export const updateExistingKeyMessageActionCreator = existing => ({
  type: UPDATE_EXISTING_KEY_MESSAGE,
  payload: existing,
});
export const clearKeyMessagesEditingActionCreator = () => ({
  type: CLEAR_KEY_MESSAGE_EDITING,
});

export const updateAnalyticsActionCreator = id => ({
  type: UPDATE_ANALYTICS,
  payload: id,
});

export const updateDateRangeV3ActionCreator = dateRangeV3 => ({
  type: UPDATE_V3_DATE_RANGE,
  payload: {
    dateRangeType: dateRangeV3.type,
    startDate: dateRangeV3.startDate,
    endDate: dateRangeV3.endDate,
  },
});

export const updateDateRangeCompareTypeActionCreator = dateRangeCompareType => ({
  type: UPDATE_DATE_RANGE_COMPARE_TYPE,
  payload: dateRangeCompareType,
});

export const updateCustomDateRangeCompareV3ActionCreator = customDateRangeCompareType => ({
  type: UPDATE_CUSTOM_DATE_RANGE_V3_COMPARE_TYPE,
  payload: customDateRangeCompareType,
});

export const clearDateRangeCompareTypeActionCreator = () => ({
  type: CLEAR_DATE_RANGE_COMPARE_TYPE,
});

export const resetSidebarActionCreator = () => ({ type: RESET_SIDEBAR });

export const clearSelectedSearchesActionCreator = () => ({
  type: CLEAR_SELECTED_SEARCHES,
});

export const editExistingWidgetActionCreator = (id, fromReport = false) => (
  dispatch,
  getState,
) => {
  const state = getState();
  let widget;
  if (fromReport) {
    const currentSlide = reportSlides(state).find(slide => slide.isCurrent);
    const slideTile = currentSlide.layout.find(
      x => x.contentType === WIDGET_TYPES.widgetV3 && x.content.id === id,
    );
    widget = slideTile?.content;
  } else {
    widget = state.dashboard.dashboardHub.widgetV3ById[id];
  }
  if (widget) {
    dispatch({
      type: EDIT_EXISTING_WIDGET_V3,
      payload: widget,
    });
  }
};

export const editExistingKeyMessagesActionCreator = (
  id,
  fromReport = false,
) => (dispatch, getState) => {
  const state = getState();
  let widget;
  if (fromReport) {
    const currentSlide = reportSlides(state).find(slide => slide.isCurrent);
    const slideTile = currentSlide.layout.find(
      x => x.contentType === WIDGET_TYPES.widgetV3 && x.content.id === id,
    );
    widget = slideTile?.content;
  } else {
    widget = state.dashboard.dashboardHub.widgetV3ById[id];
  }
  if (widget) {
    dispatch({
      type: EDIT_EXISTING_KEY_MESSAGES_V3,
      payload: widget,
    });
  }
};

export const editExistingSearchesActionCreator = id => (dispatch, getState) => {
  const state = getState();
  const widget = state.dashboard.dashboardHub.widgetsById[id];
  if (widget) {
    dispatch({ type: EDIT_EXISTING_SEARCHES, payload: widget });
  }
};

// V3 ONLY
export const updateFromBespokeOptionActionCreator = option => dispatch => {
  dispatch({
    type: UPDATE_FROM_BESPOKE_OPTION,
    payload: option,
  });
};

// V3 ONLY
export const addDataSourcesActionCreator = (
  source,
  type,
  title,
  ids,
  multiple,
) => (dispatch, getState) => {
  const state = getState();

  const currentSources = state.widgetForm.dataSourcesV3;
  let dataSources = ids.map(id => ({
    source: source,
    sourceType: type,
    sourceId: id,
    metadata: {
      sourceModel: {
        id,
        [DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS[type] || 'title']: title,
      },
      type,
    },
  }));

  if (multiple && currentSources.length) {
    dataSources = currentSources.concat(dataSources);
  }

  dispatch({ type: UPDATE_DATA_SOURCES, payload: dataSources });
};

// V3 ONLY
export const removeDataSourceActionCreator = (source, type, itemId) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const currentDataSourcesForType = state.widgetForm.dataSourcesV3.filter(
    x => x.source === source && x.sourceType === type,
  );
  const dataSources = currentDataSourcesForType.filter(
    x => itemId !== x.sourceId,
  );

  dispatch({ type: REMOVE_DATA_SOURCE, payload: dataSources });
};

// V3 ONLY
export const removeAllDataSourcesByTypeActionCreator = (source, type) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const dataSources = state.widgetForm.dataSourcesV3.filter(
    x => x.source === source && x.sourceType !== type,
  );
  dispatch({ type: REMOVE_DATA_SOURCE, payload: dataSources });
};

export const removePrimaryMetricActionCreator = () => ({
  type: REMOVE_PRIMARY_METRIC,
});

export const addPrimaryMetricActionCreator = metric => ({
  type: ADD_PRIMARY_METRIC,
  payload: metric,
});

export const updatePrimaryMetricActionCreator = metric => ({
  type: UPDATE_PRIMARY_METRIC,
  payload: metric,
});

export const updatePrimaryMetricDimensionActionCreator = dimensions => ({
  type: UPDATE_PRIMARY_METRIC_DIMENSIONS,
  payload: dimensions,
});

export const updatePrimaryMetricVisualzationTypeActionCreator = visualzationType => ({
  type: UPDATE_PRIMARY_METRIC_VISUALIZATION_TYPE,
  payload: visualzationType,
});

export const removeSecondaryMetricActionCreator = () => ({
  type: REMOVE_SECONDARY_METRIC,
});

export const addSecondaryMetricActionCreator = metric => ({
  type: ADD_SECONDARY_METRIC,
  payload: metric,
});

export const updateSecondaryMetricActionCreator = metric => ({
  type: UPDATE_SECONDARY_METRIC,
  payload: metric,
});

export const updateSecondaryMetricDimensionActionCreator = dimensions => ({
  type: UPDATE_SECONDARY_METRIC_DIMENSIONS,
  payload: dimensions,
});

export const updateKeyMessagesErrorActionCreator = () => ({
  type: UPDATE_KEY_MESSAGES_ERROR,
});

export const updateSecondaryMetricVisualzationTypeActionCreator = visualzationType => ({
  type: UPDATE_SECONDARY_METRIC_VISUALIZATION_TYPE,
  payload: visualzationType,
});

export const setCurrentlyEditedMetricActionCreator = metricKey => ({
  type: SET_ACTIVE_METRIC,
  payload: metricKey,
});

export const bulkShareDataSourcesActionCreater = (
  dataSources,
  successMessage,
  dataSourceType,
) => async dispatch => {
  try {
    const ids = dataSources.filter(({ shared }) => !shared).map(({ id }) => id);

    if (dataSourceType === DATA_SOURCE_TYPES.search) {
      await performPut(`${SEARCH_DETAIL_ENDPOINT}/share`, {
        searchIds: ids,
        viewOnly: true,
      });
    } else if (dataSourceType === DATA_SOURCE_TYPES.tag) {
      await performPut(`${TAG_BASE_ENDPOINT}/share`, { tagIds: ids });
    }

    dispatch(
      addPageMessage({
        isNewUI: true,
        text: successMessage,
        status: 'success',
        ttl: 5000,
      }),
    );
  } catch (e) {
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: e.response?.data?.error,
        status: 'danger',
        ttl: 5000,
      }),
    );
  }
};

export const getTranslatedKeyMessagesActionCreator = keyMessages => async dispatch => {
  dispatch({ type: GET_TRANSLATED_KEY_MESSAGES });

  try {
    const result = await performPost(
      `${NEW_DASHBOARD_ENDPOINT}/translated-key-messages`,
      keyMessages,
    );
    dispatch({
      type: GET_TRANSLATED_KEY_MESSAGES_SUCCESS,
      payload: { keyMessages: result.data },
    });
  } catch (e) {
    dispatch({
      type: GET_TRANSLATED_KEY_MESSAGES_ERROR,
      payload: {
        error: {
          status: e.response.status,
          message: e.response?.data?.message,
        },
      },
    });
    throw e;
  }
};

export const toggleDataSourcesSharedActionCreator = () => ({
  type: TOGGLE_DATA_SOURCES_SHARED,
});

export const clearCurrentlyEditedMetricActioncreator = metricKey => ({
  type: CLEAR_ACTIVE_METRIC,
  payload: metricKey,
});

export const editAllExistingSearches = () => dispatch => {
  dispatch({ type: UPDATE_STEP, payload: WIZARD_STEPS.updateSearches });
};

export const isCreatingNewWidgetSearchActionCreator = payload => ({
  type: CREATE_NEW_WIDGET_SEARCH_IN_PROCESS,
  payload: payload,
});

export const clearTranslatedKeyMessagesActionCreator = () => ({
  type: CLEAR_TRANSLATED_KEY_MESSAGES,
});

export const setSelectedWidgetId = widgetId => ({
  type: SET_SELECTED_WIDGET_ID,
  payload: widgetId,
});

export const clearSelectedWidgetId = () => ({
  type: CLEAR_SELECTED_WIDGET_ID,
});

export default widgetFormReducer;
