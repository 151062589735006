export default {
  connectEmailHeader: {
    id: 'OUTREACH-NOT-CONNECTED_CONNECT_EMAIL_HEADER',
    defaultMessage: 'Connect Your Email Account',
    description:
      'Title text indicating that a user will need to connect their email to use Outreach',
  },
  connectEmailMessage: {
    id: 'OUTREACH-NOT-CONNECTED_CONNECT_EMAIL_MESSAGE',
    defaultMessage:
      'Before you can send a message, please connect your email to {COMPANY_NAME}. We support:',
    description:
      'Informing a user they will need to connect their email to use outreach for their company',
  },
  connectNow: {
    id: 'OUTREACH-NOT-CONNECTED_CONNECT_NOW_BUTTON',
    defaultMessage: 'Connect Now',
    description:
      'Button text to trigger email connection flow to enable Outreach',
  },
  outreachIntegrationError: {
    id: 'OUTREACH-INTEGRATION-ERROR',
    defaultMessage: 'An error occurred while integrating your account',
    description:
      'Kite message shown when an error occurs during email connection process',
  },
  editOutreachIntegrationError: {
    id: 'OUTREACH-INTEGRATION-EDIT-ERROR',
    defaultMessage:
      'Hmm, something went wrong. Unable to save your edits, please try again later.',
    description: 'Informing a user that an error occurred while editing',
  },
  outreactIntegrationAndVerificationError: {
    id: 'OUTREACH-INTEGRATION-AND-VERIFICATION-ERROR',
    defaultMessage: 'Hmm, something went wrong. Please try again later.',
    description:
      'Informing a user that an error accurred while getting integrations or verifications',
  },
  deleteOutreachIntegrationSuccess: {
    id: 'OUTREACH-INTEGRATION-DELETE-SUCCESS',
    defaultMessage: 'Integration has been removed successfully.',
    description:
      'Informing a user that the integration has been removed successfully',
  },
  deleteOutreachIntegrationSuccessAlt: {
    id: 'OUTREACH-INTEGRATION-DELETE-SUCCESS-ALT',
    defaultMessage:
      'Integration has been removed and the deletion has been initiated. This process could take up to 5 hours to complete.',
    description:
      'Informing a user that the integration have been removed and the deletion has been initiated.',
  },
  resendVerificationEmailSuccess: {
    id: 'OUTREACH-RESEND-VERIFICATION-EMAIL-SUCCESS',
    defaultMessage: 'A verification email has been sent to {EMAIL_ADDRESS}. ',
    description:
      'A toast message informing a user that a verification email message has been sent',
  },
  resendVerificationEmailError: {
    id: 'OUTREACH-RESEND-VERIFICATION-EMAIL-ERROR',
    defaultMessage: 'An error occurred while sending your verification email. ',
    description: 'A toast message informing a user that an error occurred.',
  },
  outreachDeleteThreadSuccess: {
    id: 'OUTREACH-DELETE-THREAD-SUCCESS',
    defaultMessage: 'Email thread has been deleted',
    description:
      'Text to show on kite after an email thread has been successfully deleted',
  },
  outreachDeletePitchSuccess: {
    id: 'OUTREACH-DELETE-PITCH-SUCCESS',
    defaultMessage: 'Email pitch has been deleted',
    description:
      'Text to show on kite after an email pitch has been successfully deleted',
  },
  outreachDeleteThreadError: {
    id: 'OUTREACH-DELETE-THREAD-ERROR',
    defaultMessage: 'An error ocurred while trying to delete the email thread',
    description:
      'Text to show on kite after an error ocurred while trying to delete an email thread',
  },
  outreachDeletePitchError: {
    id: 'OUTREACH-DELETE-PITCH-ERROR',
    defaultMessage: 'An error ocurred while trying to delete the email pitch',
    description:
      'Text to show on kite after an error ocurred while trying to delete an email pitch',
  },
  outreachComposeModalTitle: {
    id: 'OUTREACH-COMPOSE-MODAL-TITLE',
    defaultMessage: 'Compose',
    description: 'Text to show in the Compose modal as a title',
  },
  outreachComposeModalDiscard: {
    id: 'OUTREACH-COMPOSE-MODAL-DISCARD',
    defaultMessage: 'Discard',
    description: 'Text for the Discard button in the Compose modal',
  },
  outreachComposeModalSendTest: {
    id: 'OUTREACH-COMPOSE-MODAL-SEND-TEST',
    defaultMessage: 'Send me a test email',
    description: 'Text for the test email button in the Compose modal',
  },
  outreachComposeModalSend: {
    id: 'OUTREACH-COMPOSE-MODAL-SEND',
    defaultMessage: 'Send now',
    description: 'Text for the Send button in the Compose modal',
  },
  outreachComposeModalWillBeSentTo: {
    id: 'OUTREACH-COMPOSE-MODAL-WILL-BE-SENT-TO',
    defaultMessage: 'This message will be sent <a>{SCHEDULE}</a>',
    description: 'Text for the scheduling message',
  },
  outreachComposeModalRemove: {
    id: 'OUTREACH-COMPOSE-MODAL-REMOVE',
    defaultMessage: 'Remove',
    description: 'Text for the remove button',
  },
  outreachComposeModalScheduleButton: {
    id: 'OUTREACH-COMPOSE-MODAL-SCHEDULE-BUTTON',
    defaultMessage: '{EDIT} Schedule',
    description: 'Text for the schedule button',
  },
  outreachComposeModalScheduleFailedScheduleTitle: {
    id: 'OUTREACH-COMPOSE-MODAL-SCHEDULE-FAILED-SCHEDULE-TITLE',
    defaultMessage: 'Failed to Schedule Message',
    description:
      'Text for the title shown when a message failed to be scheduled',
  },
  outreachComposeModalScheduleFailedScheduleBody: {
    id: 'OUTREACH-COMPOSE-MODAL-SCHEDULE-FAILED-SCHEDULE-BODY',
    defaultMessage:
      "Sorry, something went wrong and we couldn't schedule this message. Please try again.",
    description:
      'Text for the warning shown when a message failed to be scheduled',
  },
  outreachUnscheduleSuccess: {
    id: 'OUTREACH-UNSCHEDULE-EMAIL-SUCCESS',
    defaultMessage: 'The email was unscheduled successfully',
    description:
      'Success Toast/Popup when email has been successfully unscheduled',
  },
  outreachUnscheduleBulkSuccess: {
    id: 'OUTREACH-UNSCHEDULE-EMAIL-BULK-SUCCESS',
    defaultMessage: 'All emails were unscheduled successfully',
    description:
      'Success Toast/Popup when email has been successfully unscheduled',
  },
  outreachDeleteTrashMessageSuccess: {
    id: 'OUTREACH-DELETE-TRASH_MESSAGE-SUCCESS',
    defaultMessage: 'Message has been deleted permanently',
    description:
      'Text to show on kite after an trash message has been successfully deleted',
  },
  outreachDeleteTrashMessageFailure: {
    id: 'OUTREACH-DELETE-TRASH_MESSAGE-FAILURE',
    defaultMessage: 'Failed to delete message',
    description: 'Text to show on kite when failed to delete trash message',
  },
  outreachDeleteMessageSuccessToTrashFolder: {
    id: 'OUTREACH-DELETE-MESSAGE-SUCCESS-TO-TRASH-FOLDER',
    defaultMessage:
      'Your message was moved to the trash folder! It will be permanently deleted in 90 days, unless restored.',
    description:
      'Text to show on kite after an email message has been successfully deleted and moved to trash folder',
  },
  outreachDeleteThreadSuccessToTrashFolder: {
    id: 'OUTREACH-DELETE-THREAD-SUCCESS-TO-TRASH_FOLDER',
    defaultMessage:
      'Your thread was moved to the trash folder! It will be permanently deleted in 90 days, unless restored.',
    description:
      'Text to show on kite after an email thread has been successfully deleted and moved to trash folder',
  },
  outreachRestoreTrashSuccessMessage: {
    id: 'OUTREACH-RESTORE-SUCCESS-MESSAGE',
    defaultMessage: 'Message restored successfully',
    description: 'Text for the Restore message success',
  },
  outreachRestoreTrashErrorMessage: {
    id: 'OUTREACH-RESTORE-TRASH-ERROR-MESSAGE',
    defaultMessage: 'Error restoring message',
    description: 'Text for the Restore message error',
  },
  requiredBodyPlaceholder: {
    id: 'OUTREACH-REQUIRED-BODY-PLACEHOLDER',
    defaultMessage: 'Required',
    description: 'Placeholder text for the body of the email',
  },
};
