import includes from 'lodash/includes';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';

import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { performPost } from 'services/rest-service/rest-service';

const initialState = {
  loading: false,
  error: false,
  data: [],
  selected: [],
};

const affiliateMediaReducerFactory = namespace => {
  const GET_AFFILIATE_MEDIA_START = `ihub/${namespace}/GET_AFFILIATE_MEDIA_START`;
  const GET_AFFILIATE_MEDIA_SUCCESS = `ihub/${namespace}/GET_AFFILIATE_MEDIA_SUCCESS`;
  const GET_AFFILIATE_MEDIA_ERROR = `ihub/${namespace}/GET_AFFILIATE_MEDIA_ERROR`;
  const SELECT_AFFILIATE_MEDIA = `ihub/${namespace}/SELECT_AFFILIATE_MEDIA`;
  const UNSELECT_AFFILIATE_MEDIA = `ihub/${namespace}/UNSELECT_AFFILIATE_MEDIA`;
  const CLEAR_SELECTED_AFFILIATE_MEDIA = `ihub/${namespace}/CLEAR_SELECTED_AFFILIATE_MEDIA`;

  const getAffiliateMediaForInfluencer = (
    outletId,
    size = 30,
  ) => async dispatch => {
    dispatch({ type: GET_AFFILIATE_MEDIA_START });

    let payload = [];
    const id = parseInt(`${outletId}`.replace(/[^\d]/g, ''), 10);

    try {
      const response = await performPost(
        `${INFLUENCER_HUB_ENDPOINT}/affiliate-media`,
        {
          id,
          size,
          offset: 0,
        },
      );
      payload = (response && response.data && response.data.data) || [];
    } catch (e) {
      dispatch({ type: GET_AFFILIATE_MEDIA_ERROR, payload: e });
    }

    dispatch({ type: GET_AFFILIATE_MEDIA_SUCCESS, payload });
  };

  const affiliateMediaReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case GET_AFFILIATE_MEDIA_START:
        return {
          ...state,
          loading: true,
          error: false,
          data: [],
        };

      case GET_AFFILIATE_MEDIA_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: [...action.payload],
        };

      case GET_AFFILIATE_MEDIA_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case SELECT_AFFILIATE_MEDIA:
        return {
          ...state,
          selected: uniq(state.selected.concat(action.payload)),
        };

      case UNSELECT_AFFILIATE_MEDIA:
        return {
          ...state,
          selected: [],
        };

      case CLEAR_SELECTED_AFFILIATE_MEDIA:
        return {
          ...state,
          selected: remove(
            state.selected,
            val => !includes(action.payload, val),
          ),
        };

      default:
        return state;
    }
  };

  const clearAffiliateMedia = () => ({ type: CLEAR_SELECTED_AFFILIATE_MEDIA });
  const selectAffiliateMedia = ids => async dispatch => {
    dispatch({ type: SELECT_AFFILIATE_MEDIA, payload: ids });
  };
  const unselectAffiliateMedia = ids => async dispatch => {
    dispatch({ type: UNSELECT_AFFILIATE_MEDIA, payload: ids });
  };

  return {
    GET_AFFILIATE_MEDIA_SUCCESS,
    GET_AFFILIATE_MEDIA_START,
    GET_AFFILIATE_MEDIA_ERROR,
    getAffiliateMediaForInfluencer,
    clearAffiliateMedia,
    selectAffiliateMedia,
    unselectAffiliateMedia,
    affiliateMediaReducer,
  };
};

export default affiliateMediaReducerFactory;
