import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Error } from '../forms';
import globalMessages from '../Global.messages';

import defaultMessages from './withEditable.messages';

const ErrorWithIntl = ({
  children: initChildren,
  intl,
  messages,
  ...passedProps
}) => {
  let children = initChildren;

  if (typeof children === 'string' && globalMessages[children]) {
    children = intl.formatMessage(globalMessages[children]);
  }

  if (typeof children === 'string' && defaultMessages[children]) {
    children = intl.formatMessage(defaultMessages[children]);
  }

  if (typeof children === 'string' && messages[children]) {
    children = intl.formatMessage(messages[children]);
  }

  return <Error {...passedProps}>{children}</Error>;
};

ErrorWithIntl.propTypes = {
  children: PropTypes.string.isRequired,
  messages: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

ErrorWithIntl.defaultProps = {
  messages: {},
};

export default injectIntl(ErrorWithIntl);
