import { actions } from 'react-redux-form';

const initialState = {
  title: '',
};

const storyTitleFormReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const loadStoryTitleFormActionCreator = story => dispatch => {
  const title = story && story.title ? story.title : '';
  dispatch(actions.load('storyTitleForm', { title }));
};

export const resetStoryTitleFormActionCreator = () => dispatch => {
  dispatch(actions.reset('storyTitleForm'));
};

export default storyTitleFormReducer;
