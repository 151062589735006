import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from '../svg-icon';

import './logo.scss';

const baseClass = 'page-header__logo';

const Logo = ({ forwardedRef, isPublic, url }) => {
  const mainClass = classNames(baseClass, {
    [`${baseClass}--public`]: isPublic,
  });

  return (
    <a href={url} className={mainClass} ref={forwardedRef}>
      <span>
        <SvgIcon icon="cisionLogo" width={85.7} height={25} />
      </span>
    </a>
  );
};

Logo.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  isPublic: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  isPublic: false,
  text: 'Trendkite',
};

export default React.forwardRef((props, ref) => (
  <Logo {...props} forwardedRef={ref} />
));
