import { DOMAIN_DEMOGRAPHICS_ENDPOINT } from 'constants/apis';

import processDemographicsToArray from 'services/demographics-service/demographics-service';
import { performGet } from 'services/rest-service/rest-service';

export const GET_AUDIENCE_DEMOGRAPHICS = 'domain/GET_AUDIENCE_DEMOGRAPHICS';
export const GET_AUDIENCE_DEMOGRAPHICS_SUCCESS =
  'domain/GET_AUDIENCE_DEMOGRAPHICS_SUCCESS';
export const GET_AUDIENCE_DEMOGRAPHICS_ERROR =
  'domain/GET_AUDIENCE_DEMOGRAPHICS_ERROR';

const initialState = {
  loading: false,
  error: false,
  data: {},
};

const domainAudienceDemographicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIENCE_DEMOGRAPHICS:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_AUDIENCE_DEMOGRAPHICS_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false,
        data: action.payload,
      };
    }
    case GET_AUDIENCE_DEMOGRAPHICS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const getDemographicsByDomainActionDispatcher = domainName => dispatch => {
  dispatch({ type: GET_AUDIENCE_DEMOGRAPHICS });
  performGet(
    `${DOMAIN_DEMOGRAPHICS_ENDPOINT}?domainName=${domainName}`,
    null,
    null,
    null,
    true, // cache this - why not prevent calls for the same data as the user goes through articles?
  )
    .then(response => {
      const r = response.data;

      const ages = processDemographicsToArray(r.ages, ['0-11', '12-17']);
      const genders = processDemographicsToArray(r.genders);

      dispatch({
        type: GET_AUDIENCE_DEMOGRAPHICS_SUCCESS,
        payload: {
          ages,
          genders,
        },
      });
    })
    .catch(error => {
      dispatch({ type: GET_AUDIENCE_DEMOGRAPHICS_ERROR });
      throw error;
    });
};

export default domainAudienceDemographicsReducer;
