import { createSelector } from 'reselect';

export const dashboardObj = state => state.dashboard || {};

export const archivedAnalyticsSelector = createSelector(
  dashboardObj,
  dashboard => (dashboard && dashboard.archived) || {},
);

export const archivedAnalyticsListSelector = createSelector(
  archivedAnalyticsSelector,
  items => items.items || [],
);

export const archivedAnalyticsLoadingSelector = createSelector(
  archivedAnalyticsSelector,
  items => items.loading,
);

export const archivedAnalyticsFilteringSelector = createSelector(
  archivedAnalyticsSelector,
  dashboard => (dashboard && dashboard.listFilters?.filtering) || null,
);

export const archivedAnalyticsSearchSelector = createSelector(
  archivedAnalyticsSelector,
  dashboard => (dashboard && dashboard.listFilters?.search) || null,
);

export const archivedAnalyticsSidebarCountSelector = createSelector(
  archivedAnalyticsSelector,
  dashboard => (dashboard && dashboard.sidebarCount) || null,
);

export const archivedAnalyticsPaginationCountSelector = createSelector(
  archivedAnalyticsSelector,
  dashboard => (dashboard && dashboard.paginationCount) || null,
);

export const archivedAnalyticsForOwnerSelector = createSelector(
  archivedAnalyticsSelector,
  dashboard => (dashboard && dashboard.ownerFilterAnalytics) || null,
);

export const archivedAnalyticsResultsCollationSelector = createSelector(
  archivedAnalyticsSelector,
  dashboard => (dashboard && dashboard.resultsCollation) || null,
);
