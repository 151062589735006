import './modal.scss';
import ModalComp from './Modal';
import Header, { ModalHeaderType } from './Header';
import Body, { ModalBodyType } from './Body';
import Footer, { FooterType } from './Footer';
import Action, { ActionType } from './Action';
import Actions, { ActionsType } from './Actions';

interface ModalInterface {
  Header: ModalHeaderType;
  Body: ModalBodyType;
  Footer: FooterType;
  Action: ActionType;
  Actions: ActionsType;
}

const Modal = Object.assign(ModalComp, {
  Header,
  Body,
  Footer,
  Action,
  Actions,
} as ModalInterface);

export default Modal;
