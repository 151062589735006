import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './page-header.scss';

const baseClass = 'page-header';

const PageHeader = ({ unfixed, isPublic, children }) => (
  <header
    className={classNames(baseClass, {
      [`${baseClass}--unfixed`]: unfixed,
      [`${baseClass}--public`]: isPublic,
    })}
  >
    {children}
  </header>
);

PageHeader.propTypes = {
  unfixed: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isPublic: PropTypes.bool,
};

PageHeader.defaultProps = {
  unfixed: false,
  isPublic: false,
};

export default PageHeader;
