import React from 'react';
import { Toggle, Type } from '@trendkite/ui/index';
import styles from './MenuItem.module.css';

const MenuItem = ({ label, isChecked, onToggle }) => {
  return (
    <div className={styles.menuItem}>
      <Type>{label}</Type>
      <div className={styles.actions}>
        <Toggle
          checked={isChecked}
          onToggle={() => onToggle(!isChecked)}
          modifiers={['primaryBlue']}
        />
      </div>
    </div>
  );
};

export default MenuItem;
