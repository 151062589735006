export default {
  name: {
    id: 'NAME',
    defaultMessage: 'Name',
    description: 'Global term for something labelled "name" in the app',
  },
  email: {
    id: 'EMAIL',
    defaultMessage: 'Email',
    description: 'Global term for something labelled "email" in the app',
  },
  phone: {
    id: 'PHONE',
    defaultMessage: 'Phone',
    description: 'Global term for something labelled "phone" in the app',
  },
  fax: {
    id: 'FAX',
    defaultMessage: 'Fax',
    description: 'Global term for something labelled "fax" in the app',
  },
  cancel: {
    id: 'CANCEL',
    defaultMessage: 'Cancel',
    description: 'Global term for cancelling something in the app.',
  },
  decline: {
    id: 'DECLINE',
    defaultMessage: 'Decline',
    description: 'Global term for declining something in the app.',
  },
  accept: {
    id: 'ACCEPT',
    defaultMessage: 'Accept',
    description: 'Global term for accepting something in the app.',
  },
  contact: {
    id: 'GLOBAL_CONTACT',
    defaultMessage: 'Contact',
    description: 'Global term for contact',
  },
  contacts: {
    id: 'GLOBAL_CONTACTS',
    defaultMessage: 'Contacts',
    description: 'Global term for contacts',
  },
  privateContacts: {
    id: 'GLOBAL_PRIVATE_CONTACTS',
    defaultMessage: 'Private Contacts',
    description:
      'Global term for contacts that were saved by the user for their own private usage',
  },
  confirm: {
    id: 'CONFIRM_TERM',
    defaultMessage: 'Confirm',
    description: 'Global term for confirm something in the app.',
  },
  apply: {
    id: 'APPLY',
    description: 'Global term for applying something in the app.',
    defaultMessage: 'Apply',
  },
  send: {
    id: 'SEND',
    defaultMessage: 'Send',
    description: 'Global term for sending something in the app.',
  },
  upload: {
    id: 'UPLOAD',
    description: 'Global term for uploading something in the app.',
    defaultMessage: 'Upload',
  },
  add: {
    id: 'ADD',
    defaultMessage: 'Add',
    description: 'Global term for adding something in the app.',
  },
  undo: {
    id: 'UNDO',
    defaultMessage: 'Undo',
    description: 'Global term to undo something in the app.',
  },
  delete: {
    id: 'DELETE',
    defaultMessage: 'Delete',
    description: 'Global term to delete something in the app.',
  },
  edit: {
    id: 'EDIT',
    defaultMessage: 'Edit',
    description: 'Global term to change/edit something in the app.',
  },
  ok: {
    id: 'OK',
    defaultMessage: 'OK',
    description: 'Global term to acknowledge something',
  },
  save: {
    id: 'SAVE',
    defaultMessage: 'Save',
    description: 'Global term to indicate that something should be saved',
  },
  saveChanges: {
    id: 'SAVE_CHANGES',
    defaultMessage: 'Save Changes',
    description: 'Global term to indicate that something should be saved.',
  },
  close: {
    id: 'CLOSE',
    defaultMessage: 'Close',
    description: 'Global term to indicate that something can be closed.',
  },
  back: {
    id: 'BACK',
    defaultMessage: 'Back',
    description:
      'Global term to indicate that you can navigate back to the previous location.',
  },
  clear: {
    id: 'CLEAR',
    defaultMessage: 'Clear',
    description: 'Global term to indicate that something can be cleared.',
  },
  clearSelection: {
    id: 'CLEAR_SELECTION',
    defaultMessage: 'Clear Selection',
    description:
      'Global term to indicate one or more selected items can be deselected.',
  },
  remove: {
    id: 'REMOVE',
    defaultMessage: 'Remove',
    description: 'Global term to indicate that something can be removed.',
  },
  discard: {
    id: 'DISCARD',
    defaultMessage: 'Discard',
    description: 'Global term to indicate that something can be discarded.',
  },
  personalizedPitch: {
    id: 'PERSONALIZED_PITCH',
    defaultMessage: 'Personalized Pitch',
    description: 'Global term for the personalized pitch area of the app.',
  },
  emailCampaign: {
    id: 'EMAIL_CAMPAIGN',
    defaultMessage: 'Email Announcement',
    description: 'Global term for the email announcement area of the app.',
  },
  refresh: {
    id: 'REFRESH',
    defaultMessage: 'Refresh',
    description: 'Global term to indicate that something can be Refreshed.',
  },
  urls: {
    id: 'URLS',
    defaultMessage: 'URLs',
    description: 'Global term to refer to URLs.',
  },
  create: {
    id: 'CREATE',
    defaultMessage: 'Create',
    description: 'Global term to indicate that something can be Created.',
  },
  createNewSearch: {
    id: 'CREATE_NEW_SEARCH',
    description: 'Global term to create a new search.',
    defaultMessage: 'Create a New Search',
  },
  basicSearch: {
    id: 'SIMPLE_EARNED_SEARCH',
    description: 'Global term for a simple search.',
    defaultMessage: 'Simple Earned Search',
  },
  advancedEarnedSearch: {
    id: 'ADVANCED_EARNED_SEARCH',
    description: 'Global term for an advanced earned search',
    defaultMessage: 'Advanced Earned Search',
  },
  date: {
    id: 'DATE',
    defaultMessage: 'date',
    description: 'Global term to refer to date.',
  },
  spend: {
    id: 'SPEND',
    defaultMessage: 'spend',
    description: 'Global term to refer to spend.',
  },
  impressions: {
    id: 'IMPRESSIONS',
    defaultMessage: 'impressions',
    description: 'Global term to refer to impressions.',
  },
  clicks: {
    id: 'CLICKS',
    defaultMessage: 'clicks',
    description: 'Global term to refer to clicks.',
  },
  cpm: {
    id: 'CPM',
    defaultMessage: 'cpm',
    description: 'Global term to refer to cpm.',
  },
  cpc: {
    id: 'CPC',
    defaultMessage: 'cpc',
    description: 'Global term to refer to cpc.',
  },
  ctr: {
    id: 'CTR',
    defaultMessage: 'ctr',
    description: 'Global term to refer to ctr.',
  },
  sortBy: {
    id: 'SORT_BY',
    defaultMessage: 'Sort by',
    description:
      'Global verb-adverb for sorting by something (usually a dropdown of options)',
  },
  filter: {
    id: 'FILTER',
    defaultMessage: 'Filter',
    description: 'Global verb-adverb for filtering through a given list',
  },
  filterBy: {
    id: 'FILTER_BY',
    defaultMessage: 'Filter by',
    description:
      'Global verb-adverb for filtering by something (usually a dropdown of options)',
  },
  filterByVerb: {
    id: 'FILTER_BY_VERB',
    defaultMessage: 'Filter by: {verb}',
    description: 'Global verb-adverb for filter by something',
  },
  filteringByVerb: {
    id: 'FILTERING_BY_VERB',
    defaultMessage: 'Filter by {verb}',
    description: 'Global verb-adverb for filtering by something',
  },
  loadMore: {
    id: 'LOAD_MORE',
    defaultMessage: 'Load More',
    description: 'Global term for loading more results from a given list',
  },
  keyword: {
    id: 'KEYWORD',
    defaultMessage: 'Keyword',
    description: 'Global term for a keyword',
  },
  topic: {
    id: 'TOPIC',
    defaultMessage: 'Topic',
    description: 'Global term for a topickk',
  },
  domain: {
    id: 'DOMAIN',
    defaultMessage: 'domain',
    description: 'Global term to refer to domain.',
  },
  descending: {
    id: 'DESCENDING',
    defaultMessage: 'Descending',
    description: 'Global term for descending order',
  },
  ascending: {
    id: 'ASCENDING',
    defaultMessage: 'Ascending',
    description: 'Global term for ascending order',
  },
  noDataAvailable: {
    id: 'NO_DATA_AVAILABLE',
    defaultMessage: 'No data available',
    description: 'Global term to refer that no data available',
  },
  saveAndExitButton: {
    id: 'SAVE_AND_EXIT',
    defaultMessage: 'Save and Exit',
    description: 'Save and exit button for submitting forms',
  },
  preview: {
    id: 'PREVIEW',
    defaultMessage: 'Preview',
    description: 'Preview resource before submitting',
  },
  city: {
    id: 'CITY',
    defaultMessage: 'city',
    description: 'Global term for a location of the type city',
  },
  county: {
    id: 'COUNTY',
    defaultMessage: 'county',
    description: 'Global term for a location of the type county',
  },
  country: {
    id: 'COUNTRY',
    defaultMessage: 'country',
    description: 'Global term for a location of the type country',
  },
  regionState: {
    id: 'REGION_STATE',
    defaultMessage: 'region/state',
    description: 'Global term for a location of the type region/state',
  },
  state: {
    id: 'STATE',
    defaultMessage: 'state',
    description: 'Global term for a location of the type state',
  },
  metropolitanArea: {
    id: 'METROPOLITAN_AREA',
    defaultMessage: 'metropolitan area',
    description: 'Metropolitan area term to refer to metropolitan area',
  },
  dma: {
    id: 'DMA',
    defaultMessage: 'DMA',
    description:
      'A location of the type "DMA", captialized because it\'s an acronym',
  },
  targetArea: {
    id: 'TARGET_AREA',
    defaultMessage: 'Target Area',
    description:
      'A location of the type "Target Area", captialized because it\'s an acronym',
  },
  in: {
    id: 'IN_CONNECTOR_WORD',
    defaultMessage: 'in',
    description: "Global term to refer to 'in' connector word",
  },
  views: {
    id: 'VIEWS',
    defaultMessage: 'views',
    description: "Global term to refer to 'views' word",
  },
  uniqueViewers: {
    id: 'UNIQUE_VIEWERS',
    defaultMessage: 'unique viewers',
    description: "Global term to refer to 'unique viewers' word",
  },
  conversions: {
    id: 'CONVERSIOMS',
    defaultMessage: 'conversions',
    description: "Global term to refer to 'conversions' word",
  },
  percentOfSearchViews: {
    id: 'PERCENT_OF_SEARCH_VIEWS',
    defaultMessage: '% of search views',
    description: "Global term to refer to '% of search views' phrase",
  },
  insert: {
    id: 'INSERT',
    defaultMessage: 'Insert',
    description: 'Global term to insert something in the app.',
  },
  import: {
    id: 'IMPORT',
    defaultMessage: 'Import',
    description: 'Global term to import something in the app.',
  },
  coverage: {
    id: 'COVERAGE',
    description: 'Global term to refer to the coverage section title',
    defaultMessage: 'Coverage',
  },
  browseLink: {
    id: 'BROWSE_LINK',
    description: 'Global term to refer to the browse link text',
    defaultMessage: 'Browse',
  },
  searches: {
    id: 'SEARCHES',
    description: 'Global term to refer to searches',
    defaultMessage: 'Searches',
  },
  tags: {
    id: 'TAGS',
    description: 'Global term to refer to Articles tags',
    defaultMessage: 'Tags',
  },
  invalidEmail: {
    id: 'INVALID_EMAIL',
    description:
      'Appears with form inputs that contain invalid email addresses',
    defaultMessage: 'Invalid email address',
  },
  invalidEmailBlocked: {
    id: 'INVALID_EMAIL_BLOCKED',
    description:
      'Appears with form inputs that contain blocked email addresses',
    defaultMessage: 'This email address is blocked',
  },
  invalidPhone: {
    id: 'INVALID_PHONE',
    description: 'Appears with form inputs that contain invalid phone numbers',
    defaultMessage: 'Invalid phone number',
  },
  invalidTagNameMessage: {
    id: 'INVALID_TAG_NAME_MESSAGE',
    description: "Text for the invalid tag name error's message",
    defaultMessage:
      'Allowed characters are letters, numbers, spaces and special characters.',
  },
  invalidTagNameLengthMessage: {
    id: 'INVALID_TAG_NAME_LENGTH_MESSAGE',
    description: "Text for the invalid tag name length error's message",
    defaultMessage: 'Whoops! The character minimum is 3 and the limit is 40.',
  },
  invalidTagNameUniqueness: {
    id: 'INVALID_TAG_NAME_UNIQUENESS_MESSAGE',
    description: 'Text when tag name exists on the user list',
    defaultMessage:
      'Whoops! A tag with the same name already exists. Please try a different name.',
  },
  invalidTwitter: {
    id: 'INVALID_TWITTER',
    description:
      'Appears with form inputs that contain invalid Twitter handles',
    defaultMessage: 'Invalid Twitter handle',
  },
  invalidUrlPrefix: {
    id: 'INVALID_URL_PREFIX',
    description:
      'Appears with form inputs that contain urls not starting with http/s.',
    defaultMessage: 'URLs should start with http:// or https://',
  },
  averageDailyUniqueViewers: {
    id: 'AVERAGE_DAILY_UNIQUE_VIEWERS',
    defaultMessage: 'Average Daily Unique Viewers',
    description:
      "Global term to refer to 'average daily unique viewers' metric",
  },
  clustersTitle: {
    id: 'CLUSTERS_TITLE',
    description: 'Global term to refer to a cluster title',
    defaultMessage: 'Cluster {CLUSTER_NUMBER}',
  },
  clustersRefineButtonText: {
    id: 'CLUSTERS_REFINE_BUTTON_TEXT',
    description:
      'Global term to refer to the text of the refine button in a cluster',
    defaultMessage: 'Refine Cluster',
  },
  impactLabel: {
    id: 'IMPACT_LABEL',
    description: 'Global term to refer to the impact of an article',
    defaultMessage: 'Impact',
  },
  impactReportLabel: {
    id: 'IMPACT_REPORT_LABEL',
    description: 'Global term to refer to the impact report of an article',
    defaultMessage: 'Impact Report',
  },
  sharesLabel: {
    id: 'SHARES_LABEL',
    description: 'Global term to refer to the number of shares of an article',
    defaultMessage: 'Shares',
  },
  next: {
    id: 'NEXT',
    description: 'Global term to refer to the next value within a sequence',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'PREVIOUS',
    description: 'Global term to refer to the previous value within a sequence',
    defaultMessage: 'Previous',
  },
  owner: {
    id: 'OWNER',
    description: 'Global term for something labelled "owner" in the app',
    defaultMessage: 'Owner',
  },
  deletedBy: {
    id: 'Deleted By',
    description: 'Global term for something labelled "deleted by" in the app',
    defaultMessage: 'Deleted By',
  },
  category: {
    id: 'CATEGORY',
    description: 'Global term for something labelled "category" in the app',
    defaultMessage: 'Category',
  },
  lastModified: {
    id: 'LAST_MODIFIED',
    description: 'Global term to refer to the last modified date of something',
    defaultMessage: 'Last Modified',
  },
  dateDeleted: {
    id: 'DATE_DELETED',
    description:
      'Global term to refer to the deleted date of a search in the app',
    defaultMessage: 'Date Deleted',
  },
  actions: {
    id: 'ACTIONS',
    description: 'Global term for something labelled "actions" in the app',
    defaultMessage: 'Actions',
  },
  on: {
    id: 'ON',
    description: 'Global term to refer to the on status of something',
    defaultMessage: 'On',
  },
  off: {
    id: 'OFF',
    description: 'Global term to refer to the off status of something',
    defaultMessage: 'Off',
  },
  setDefault: {
    id: 'SET_DEFAULT',
    description: 'Global term to refer to the set default text',
    defaultMessage: 'Set Default',
  },
  noDefault: {
    id: 'NO_DEFAULT',
    description: 'Global term to refer to the no default text',
    defaultMessage: 'No Default',
  },
  resetToDefault: {
    id: 'RESET_TO_DEFAULT',
    description: 'Global term to refer to the reset to default action',
    defaultMessage: 'Reset to Default',
  },
  openInNewTab: {
    id: 'OPEN_IN_NEW_TAB',
    description: 'Global term to refer to the open in new tab action',
    defaultMessage: 'Open in new tab',
  },
  error: {
    id: 'ERROR',
    description: 'Global term to refer to the error text',
    defaultMessage: 'Error',
  },
  success: {
    id: 'SUCCESS',
    description: 'Global term to refer to the success text',
    defaultMessage: 'Success',
  },
  done: {
    id: 'DONE',
    description: 'Global term to refer to a finished action',
    defaultMessage: 'Done',
  },
  goBack: {
    id: 'GO_BACK_LABEL',
    description: 'Global term that refers to return to the previous page',
    defaultMessage: 'Go Back',
  },
  streamSearchActionBarLocationMessage: {
    id: 'STREAM_SEARCH_ACTION_BAR_LOCATION_FILTER',
    defaultMessage: 'Location',
    description: 'Filter pill that opens the location modal',
  },
  language: {
    id: 'LANGUAGE',
    defaultMessage: 'Language',
    description: 'Global term for language',
  },
  streamSearchActionBarSentimentMessage: {
    id: 'STREAM_SEARCH_ACTION_BAR_SENTIMENT_FILTER',
    defaultMessage: 'Sentiment',
    description: 'Filter pill that opens the sentiment modal',
  },
  streamSearchActionBarMediaMessage: {
    id: 'STREAM_SEARCH_ACTION_BAR_MEDIA_FILTER',
    defaultMessage: 'Media',
    description: 'Filter pill that opens the media modal',
  },
  followerCount: {
    id: 'FOLLOWER_COUNT',
    defaultMessage: 'Follower Count',
    description: 'Global term to refer to the followers counters',
  },
  genericErrorForUnsuccessfulRequest: {
    id: 'GENERIC_ERROR_FOR_UNSUCCESSFUL_REQUEST',
    description:
      'Global generic error for any unsuccessful request made to the server',
    defaultMessage: 'Something went wrong, please try again later.',
  },
  errorEmptyStateTitle: {
    id: 'ERROR_EMPTY_STATE_TITLE',
    description: 'Global title for the error empty state',
    defaultMessage: 'Something went wrong',
  },
  errorEmptyStateText: {
    id: 'ERROR_EMPTY_STATE_TEXT',
    description: 'Global text for the error empty state',
    defaultMessage: 'Sorry, something went wrong with displaying results',
  },
  callToActionRetry: {
    id: 'CALL_TO_ACTION_RETRY_TEXT',
    description: 'Global text for the call to action retry',
    defaultMessage: 'Retry Now',
  },
  noResultsEmptyStateTitle: {
    id: 'NO_RESULTS_EMPTY_STATE_TITLE',
    description: 'Global title for the no results empty state',
    defaultMessage: 'No Results',
  },
  saveFeed: {
    id: 'SOCIAL_FEEDS_SAVE_TEXT',
    description: 'Term to refer to the action of saving a social feed',
    defaultMessage: 'Save Feed',
  },
  saveAndUpdateFeed: {
    id: 'SOCIAL_FEEDS_SAVE_AND_UPDATE_TEXT',
    description: 'Global term for save and update label',
    defaultMessage: 'Save and Update',
  },
  editFeed: {
    id: 'SOCIAL_FEED_EDIT_TEXT',
    description: 'Global term for edit label',
    defaultMessage: 'Edit Feed',
  },
  sentiment: {
    id: 'SENTIMENT',
    description: 'Sentiment term',
    defaultMessage: 'Sentiment',
  },
  noMin: {
    id: 'NO_MIN',
    defaultMessage: 'No min',
    description: 'Global term to refer to the no min text',
  },
  noMax: {
    id: 'NO_MAX',
    defaultMessage: 'No max',
    description: 'Global term to refer to the no max text',
  },
  only: {
    id: 'ONLY',
    defaultMessage: 'Only',
    description:
      'Global term to refer to the Only text, mostly used select/pick only 1 item from a collection',
  },
  andCriteria: {
    id: 'AND_CRITERIA',
    description: 'Global term to refer to the AND criteria',
    defaultMessage: 'AND',
  },
  orCriteria: {
    id: 'OR_CRITERIA',
    description: 'Global term to refer to the OR criteria',
    defaultMessage: 'OR',
  },
  notCriteria: {
    id: 'NOT_CRITERIA',
    description: 'Global term to refer to the NOT criteria',
    defaultMessage: 'NOT',
  },
  exit: {
    id: 'EXIT',
    description: 'Global term to refer to  the exit action',
    defaultMessage: 'Exit',
  },
  schedule: {
    id: 'SCHEDULE',
    description: 'Global term to refer to  the schedule action',
    defaultMessage: 'Schedule',
  },
  yes: {
    id: 'YES',
    description: 'Global term to refer to yes',
    defaultMessage: 'Yes',
  },
  loading: {
    id: 'LOADING',
    description: 'Global term to refer to loading',
    defaultMessage: 'loading',
  },
  skip: {
    id: 'SKIP',
    description: 'Global term to refer to skipping something in the app',
    defaultMessage: 'skip',
  },
  selectAll: {
    id: 'SELECT_ALL',
    description:
      'Global term to refer to selecting all items from a collection',
    defaultMessage: 'Select All',
  },
  deselectAll: {
    id: 'DESELECT_ALL',
    description:
      'Global term to refer to deselecting all items from a collection',
    defaultMessage: 'Deselect All',
  },
  numberOfItemsSelected: {
    id: 'NUMBER_OF_ITEMS_SELECTED',
    defaultMessage: '{SELECTED_COUNT} of {TOTAL_COUNT} selected',
    description: 'Message indicating how many items are currently selected.',
  },
  search: {
    id: 'SEARCH',
    description: 'Global term to refer the search word',
    defaultMessage: 'Search',
  },
  campaigns: {
    id: 'CAMPAIGNS',
    description: 'Global term to refer to the campaigns',
    defaultMessage: 'Campaigns',
  },
  campaign: {
    id: 'CAMPAIGN',
    description: 'Global term to refer to the campaign',
    defaultMessage: 'Campaign',
  },
  campaignName: {
    id: 'CAMPAIGN_NAME',
    description: 'Global term to refer to the campaign name',
    defaultMessage: 'Campaign Name',
  },
  myCampaigns: {
    id: 'MY_CAMPAIGNS',
    description: 'Global term to refer to my campaigns',
    defaultMessage: 'My Campaigns',
  },
  you: {
    id: 'YOU_PRONOUN',
    description: 'Global term to refer to the you pronoun',
    defaultMessage: 'You',
  },
  activeIn: {
    id: 'ACTIVE_IN',
    description: 'Global term to refer to the active in text',
    defaultMessage: 'Active in',
  },
  share: {
    id: 'SHARE_ACTION',
    description: 'Global term to refer to the share action',
    defaultMessage: 'Share',
  },
  update: {
    id: 'UPDATE_ACTION',
    description: 'Global term to refer to the update action',
    defaultMessage: 'Update',
  },
  continue: {
    id: 'CONTINUE_ACTION',
    description: 'Global term to refer to the continue action',
    defaultMessage: 'Continue',
  },
  permissions: {
    id: 'PERMISSIONS_LABEL',
    description: 'Global term to refer to the permissions text',
    defaultMessage: 'Permissions',
  },
  shareSettings: {
    id: 'SHARE_SETTINGS_LABEL',
    description: 'Global term to refer to the share settings text',
    defaultMessage: 'Share Settings',
  },
  doNotShowMeThisAgain: {
    id: 'DO_NOT_SHOW_ME_THIS_AGAIN',
    description: 'Global term to refer to the do not show me this again text',
    defaultMessage: 'Do not show me this again',
  },
  title: {
    id: 'TITLE',
    description: 'Global term to refer to a title within the app',
    defaultMessage: 'Title',
  },
  sent: {
    id: 'SENT',
    description: 'Global term to indicate the something was sent',
    defaultMessage: 'Sent',
  },
  goToContacts: {
    id: 'GO_TO_CONTACTS_ACTION',
    description: 'Global term to refer to the go to contacts action',
    defaultMessage: 'Go To Contacts',
  },
  showMore: {
    id: 'SHOW_MORE',
    description:
      'Global term to indicate displaying more elements within a collection',
    defaultMessage: 'Show More',
  },
  showLess: {
    id: 'SHOW_LESS',
    description:
      'Global term to indicate displaying less elements within a collection',
    defaultMessage: 'Show Less',
  },
  favorites: {
    id: 'FAVORITES_LABEL',
    description: 'Global term to refer to the favorites text',
    defaultMessage: 'Favorites',
  },
  alternativeTitleForStory: {
    id: 'UNTITLED_STORY',
    defaultMessage: 'Untitled Story',
    description: 'Alternative title text for stories',
  },
  deleteStory: {
    id: 'DELETE_STORY',
    defaultMessage: 'Delete Story',
    description: 'Delete story text',
  },
  duplicateStory: {
    id: 'DUPLICATE_STORY',
    defaultMessage: 'Duplicate Story',
    description: 'Duplicate story text',
  },
  myStoriesTitle: {
    id: 'MY_STORIES_TITLE',
    defaultMessage: 'My Stories',
    description: 'My stories title',
  },
  draftText: {
    id: 'DRAFT_TEXT',
    defaultMessage: 'Draft',
    description: 'Draft text',
  },
  pausedText: {
    id: 'PAUSES_TEXT',
    defaultMessage: 'Paused',
    description: 'Paused text',
  },
  liveText: {
    id: 'LIVE_TEXT',
    defaultMessage: 'Live',
    description: 'Live text',
  },
  clearAllText: {
    id: 'CLEAR_ALL_TEXT',
    defaultMessage: 'Clear All',
    description: 'Clear all text',
  },
  removeAllText: {
    id: 'REMOVE_ALL_TEXT',
    defaultMessage: 'Remove All',
    description: 'Remove All text',
  },
  overview: {
    id: 'OVERVIEW',
    description: 'Global term to refer to the overview action',
    defaultMessage: 'Overview',
  },
  dashboard: {
    id: 'DASHBOARD',
    description: 'Global term to refer to the dashboard',
    defaultMessage: 'Dashboard',
  },
  dashboards: {
    id: 'DASHBOARDS',
    description: 'Global term to refer to the dashboards',
    defaultMessage: 'Dashboards',
  },
  pagingLabel: {
    id: 'PAGING_LABEL',
    description: 'The label of a paging component',
    defaultMessage:
      '{START_INDEX, number} - {END_INDEX, number} of {TOTAL, number}',
  },
  noData: {
    id: 'NO_DATA',
    description: 'Global term to refer when there is no data',
    defaultMessage: 'no data',
  },
  allSearches: {
    id: 'ALL_SEARCHES',
    description: 'Global term to refer to select all searches from a list',
    defaultMessage: 'All Searches',
  },
  mySearches: {
    id: 'MY_SEARCHES',
    description: 'Global term to refer to select personal searches from a list',
    defaultMessage: 'My Searches',
  },
  sharedWithMe: {
    id: 'SHARED_WITH_SEARCHES',
    description:
      'Global term to refer to select all searches shared with the user from a list',
    defaultMessage: 'Shared With Me',
  },
  archivedSearches: {
    id: 'ARCHIVES_SEARCHES',
    description:
      'Global term to refer to select all archives searches from a list',
    defaultMessage: 'Archived',
  },
  deletedSearches: {
    id: 'DELETED_SEARCHES',
    description:
      'Global term to refer to select all deleted searches from a list',
    defaultMessage: 'Deleted Searches',
  },
  categories: {
    id: 'CATEGORIES',
    description: 'Global term to refer to open categories accordion',
    defaultMessage: 'Categories',
  },
  or: {
    id: 'OR',
    description: 'Global term to refer to the OR word',
    defaultMessage: 'Or',
  },
  admin: {
    id: 'ADMIN',
    description: 'Global term to refer to the admin role',
    defaultMessage: 'Admin',
  },
  user: {
    id: 'USER',
    description: 'Global term to refer to the user type',
    defaultMessage: 'User',
  },
  role: {
    id: 'ROLE',
    description: 'Global term to refer to a role of a user',
    defaultMessage: 'Role',
  },
  spikeAlerts: {
    id: 'SPIKE_ALERTS',
    description:
      'Global term to refer to the spike alerts applied to something',
    defaultMessage: 'Spike Alerts',
  },
  spikeAlertsDescription: {
    id: 'SPIKE_ALERTS_ON_SEARCHES_DESCRIPTION',
    description: 'Global term to refer to the spike alerts description text',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Email that is triggered from a search when there have been more articles coming through than usual. This type of alert is slightly different from the daily digests because it is set up at the search level. ',
  },
  private: {
    id: 'PRIVATE',
    defaultMessage: 'Private',
    description: 'Global term to refer to the private access permission',
  },
  viewOnly: {
    id: 'VIEW_ONLY',
    description: 'Global term to refer to the view only permission',
    defaultMessage: 'View Only',
  },
  fullAccess: {
    id: 'FULL_ACCESS',
    description: 'Global term to refer to the full access permission',
    defaultMessage: 'Full Access',
  },
  everyone: {
    id: 'ACCESS_CONTROL_GROUPS_EVERYONE',
    defaultMessage: 'Everyone',
    description: 'Global term to refer to EVERYONE access permission',
  },
  custom: {
    id: 'ACCESS_CONTROL_GROUPS_CUSTOM',
    defaultMessage: 'Custom',
    description: 'Global term to refer to CUSTOM access permission',
  },
  sameAsEveryone: {
    id: 'SAME_AS_EVERYONE',
    defaultMessage: 'Same as Everyone',
    description:
      'Global term to refer to the same as everyone access permission',
  },
  outlet: {
    id: 'OUTLET',
    description: 'Global term to refer to Outlet name',
    defaultMessage: 'Outlet',
  },
  downloadFiles: {
    id: 'DOWNLOAD_FILES',
    defaultMessage: 'Download Files',
    description:
      'This is the text of a button that shows a dropdown of options to export a contact saved list',
  },
  exportList: {
    id: 'EXPORT_CSV',
    defaultMessage: 'Export CSV',
    description:
      'This is a menu option that allows the user to export a CSV of a saved list',
  },
  seeLess: {
    id: 'SEE_LESS',
    defaultMessage: 'See Less',
    description: 'Text to represent a button to show less UI',
  },
  seeMore: {
    id: 'SEE_MORE',
    defaultMessage: 'See More',
    description: 'Text to represent a button to show more UI',
  },
  seeXMore: {
    id: 'SEE_X_MORE',
    defaultMessage: 'See {X} More',
    description: 'Text to represent a button to show less UI',
  },
  metric: {
    id: 'METRIC',
    defaultMessage: 'Metric',
    description: 'Metric',
  },
  performance: {
    id: 'PERFORMANCE',
    defaultMessage: 'Performance',
    description: 'Performance',
  },
  totalConversions: {
    id: 'IMPACT_TOTAL_CONVERSIONS',
    defaultMessage: 'Impact Total Conversions',
    description: 'Impact Total Conversions',
  },
  notProvided: {
    id: 'INFO_NOT_PROVIDED',
    defaultMessage: 'Not provided',
    description:
      "Fallback text when a piece of info isn't provided by an external source.",
  },
  paginationLabel: {
    id: 'PAGINATION_LABEL',
    defaultMessage: '{START_INDEX} - {END_INDEX} of {TOTAL}',
    description:
      'Message for the label of the pagination numbers for any table',
  },
  sharedBy: {
    id: 'SHARED_BY_LABEL',
    description: 'Text for indicate who shared an object',
    defaultMessage: 'Shared by {USER}',
  },
  createdBy: {
    id: 'CREATED_BY_LABEL',
    description: 'Text for indicate who created the search',
    defaultMessage: 'Created by {USER}',
  },
  lastEdited: {
    id: 'LAST_EDITED_LABEL',
    description:
      'Text for indicate when was the object last update and who update it',
    defaultMessage: 'Last edited {LAST_UPDATED_TEXT} by {USER}',
  },
  medium: {
    id: 'MEDIUM',
    defaultMessage: 'Medium',
    description: 'Global text to represent a medium value',
  },
  high: {
    id: 'HIGH',
    defaultMessage: 'High',
    description: 'Global text to represent a high value',
  },
  list: {
    id: 'GLOBAL_LIST',
    defaultMessage: 'List',
    description: 'Global term for list',
  },
  low: {
    id: 'LOW',
    defaultMessage: 'Low',
    description: 'Global text to represent a low value',
  },
  allAudience: {
    id: 'ALL_AUDIENCE',
    defaultMessage: 'All Audience',
    description: 'Text to represent All Audience title or text',
  },
  included: {
    id: 'INCLUDED',
    defaultMessage: 'included',
    description: 'Text to represent included',
  },
  totalViews: {
    id: 'TOTAL_VIEWS',
    defaultMessage: 'Total Views',
    description: 'Text to represent Total Views',
  },
  dailyUniqueViews: {
    id: 'DAILY_UNIQUE_VIEWS',
    defaultMessage: 'Daily Unique Viewers',
    description: 'To represent daily Unique Viewers title or text',
  },
  totalPerformance: {
    id: 'TOTAL_PERFORMANCT',
    defaultMessage: 'Total Performance',
    description: 'To represent Total Performance title or text',
  },
  article: {
    id: 'ARTICLE',
    defaultMessage: 'Article',
    description: 'To represent article title or text',
  },
  articles: {
    id: 'ARTICLES',
    defaultMessage: 'Articles',
    description: 'To represent articles title or text',
  },
  author: {
    id: 'AUTHOR',
    defaultMessage: 'Author',
    description: 'To represent author title or text',
  },
  all: {
    id: 'ALL',
    defaultMessage: 'All',
    description: 'To represent all title or text',
  },
  createCampaign: {
    id: 'CREATE_CAMPAIGN',
    defaultMessage: 'Create Campaign',
    description: 'Global text for campaign creation',
  },
  deleteArticlesSuccess: {
    id: 'DELETE_ARTICLES_SUCCESS',
    defaultMessage:
      'Successfully removed {TOTAL_ARTICLES_COUNT, plural, =1 {article} other {articles}}',
    description: 'Delete articles success toast message',
  },
  deleteArticlesError: {
    id: 'DELETE_ARTICLES_ERROR',
    defaultMessage:
      'There was a problem removing the {TOTAL_ARTICLES_COUNT, plural, =1 {article} other {articles}}',
    description: 'Delete articles error message',
  },
  failed: {
    id: 'FAILED',
    defaultMessage: 'Failed',
    description: 'term to indicate that someting failed',
  },
  copied: {
    id: 'COPIED',
    defaultMessage: 'copied',
    description: 'term to indicate that something was copied',
  },
  simplePagination: {
    id: 'SIMPLE_PAGINATOR_BY_RESULTS',
    defaultMessage: '{START_INDEX}-{END_INDEX} of {PAGINATION_COUNT}',
    description: 'Simple pagination Label',
  },
  outletName: {
    id: 'OUTLET_NAME',
    defaultMessage: 'Outlet Name',
    description: 'Global text for Outlet Name',
  },
  pubDate: {
    id: 'PUB_DATE',
    defaultMessage: 'Pub. Date',
    description: 'Global text for Pub Date',
  },
  applyChanges: {
    id: 'APPLY_CHANGES',
    defaultMessage: 'Apply Changes',
    description: 'Term to indicate that some changes are going to be applied',
  },
  sentDate: {
    id: 'SENT_DATE',
    defaultMessage: 'Sent Date',
    description: 'Global term to refer to the sent date of something',
  },
  status: {
    id: 'STATUS',
    defaultMessage: 'Status',
    description: 'Global term to refer to the status date of something',
  },
  export: {
    id: 'EXPORT',
    defaultMessage: 'Export',
    description:
      'Global term to refer to export text button to export a file of the content',
  },
  exportLoading: {
    id: 'EXPORT_FILE_LOADING_TEXT',
    defaultMessage: 'Hang in there, while we prepare your export',
    description:
      'Global text to refer when export a file of the content is loading',
  },
  exportError: {
    id: 'EXPORT_FILE_ERROR_TEXT',
    defaultMessage: 'Whoops, the file export failed. Please, try again.',
    description:
      'Global text to refer when export a file of the content is failed',
  },
  report: {
    id: 'REPORT',
    defaultMessage: 'Report',
    description: 'Global term to refer to the report word',
  },
  totalConversionsRate: {
    id: 'TOTAL_CONVERSIONS_RATE',
    description: 'Global term to refer to the Total Conversion Rate text',
    defaultMessage: 'Total Conversion Rate',
  },
  type: {
    id: 'GLOBAL_TYPE',
    defaultMessage: 'Type',
    description: 'Global term to refer to types',
  },
  startRange: {
    id: 'GLOBAL_RANGE_START',
    defaultMessage: 'Start:',
    description: 'Global term to refer to the "start" label for ranges ',
  },
  endRange: {
    id: 'GLOBAL_RANGE_END',
    defaultMessage: 'End:',
    description: 'Global term to refer to the "end" label for ranges',
  },
  tweets: {
    id: 'GLOBAL_TWEETS',
    defaultMessage: 'Tweets',
    description: 'Global term to refer to the tweets label',
  },
  filteringByPhrase: {
    id: 'GLOBAL_FILTERING_BY_PHRASE',
    defaultMessage: 'Filtering by {phrase}',
    description: 'Global phrase when using a filter text',
  },
  addToCampaignText: {
    id: 'ADD_TO_CAMPAIGN_TEXT',
    description:
      'Text for add dashboard to campaign on the dropdown action menu',
    defaultMessage: 'Add to Campaign',
  },
  new: {
    id: 'GLOBAL_NEW',
    description: 'Global phrase for something new',
    defaultMessage: 'New',
  },
  simplePaginationLabel: {
    id: 'SIMPLE_PAGINATION_LABEL',
    defaultMessage: '{pageNumber} of {pageCount}',
    description: 'Label for the simple pagination component',
  },
  entityName: {
    id: 'ENTITY_NAME_PREFIX',
    defaultMessage: '{PREFIX} {NAME}',
    description: 'prefix entity name followed by the entity translation',
  },
  no: {
    id: 'NO',
    description: 'Global term to refer to no',
    defaultMessage: 'No',
  },
  broadcasts: {
    id: 'GLOBAL_BROADCAST',
    defaultMessage: 'Broadcasts',
    description: 'To represent broadcasts title or text',
  },
  privacyPolicy: {
    id: 'GLOBAL_PRIVACY_POLICY',
    defaultMessage: 'Privacy Policy',
    description: 'Text to refer to the privacy policy',
  },
  addToList: {
    id: 'GLOBAL_ADD_TO_LIST',
    defaultMessage: 'Add to list',
    description: 'Term to refer to the add to list action',
  },
  moreItems: {
    id: 'GLOBAL_MORE_ITEMS',
    defaultMessage: '{ITEMS_COUNT} more',
    description:
      'Global term to refer to the amount of items not being displayed',
  },
  beta: {
    id: 'GLOBAL_BETA',
    defaultMessage: 'Beta',
    description: 'Global term to refer to a beta feature',
  },
  addArticle: {
    id: 'GLOBAL_ADD_ARTICLE',
    defaultMessage: 'Add Article',
    description: 'Global term to refer to the add article action',
  },
  selectColumns: {
    id: 'GLOBAL_SELECT_COLUMNS',
    defaultMessage: 'Select Columns',
    description:
      'Global term to refer to the select columns action within the drilldown or another section',
  },
  lastView: {
    id: 'GLOBAL_LAST_VIEWED',
    defaultMessage: 'Last Viewed',
    description: 'Global term for something labelled "Last Viewed" in the app',
  },
  archive: {
    id: 'GLOBAL_ARCHIVE',
    defaultMessage: 'Archive',
    description: 'Global verb for archiving something',
  },
  journalistType: {
    id: 'JOURNALIST_TYPE',
    defaultMessage: 'journalist',
    description: 'Global term for journalist influencer type.',
  },
  outletType: {
    id: 'OUTLET_TYPE',
    description: 'Global term for outlet influencer type',
    defaultMessage: 'outlet',
  },
  socialInfluencerType: {
    id: 'SOCIAL_INFLUENCER_TYPE',
    description: 'Global term for outlet influencer type',
    defaultMessage: 'social influencer',
  },
  dismiss: {
    id: 'GLOBAL_DISMISS',
    defaultMessage: 'Dismiss',
    description: 'Global term for dismiss',
  },
  listView: {
    id: 'LIST_VIEW',
    defaultMessage: 'List View',
    description: 'Global term for list view',
  },
  detailView: {
    id: 'DETAIL_VIEW',
    defaultMessage: 'Detail View',
    description: 'Global term for detail view',
  },
  pageNumber: {
    id: 'PAGE_NUMBER_GENERIC',
    defaultMessage: 'Page {PAGE_NUMBER}',
    description: 'Global term for a Page $number message',
  },
  slides: {
    id: 'SLIDES',
    defaultMessage: 'Slides',
    description: 'Global term for slides',
  },
};
