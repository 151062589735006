import React, { Component } from 'react';

import { Button } from '@cision/rover-ui';
import PropTypes from 'prop-types';

/* TK "external" dependencies */
import { Modal } from '@trendkite/ui';

/* App - non-components */

/* App - components */

class OutreachDiscardModal extends Component {
  onCancel = () => {
    this.props.onCancel();
  };

  onClickConfirm = () => {
    this.props.onConfirm();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} size="sm" toggle={this.onCancel}>
        <Modal.Header toggle={this.onClickDiscard}>
          Discard Message?
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to exit? You will lose all content in this
          message.
        </Modal.Body>
        <Modal.Footer>
          <Modal.Actions>
            <Modal.Action>
              <Button
                data-qa="hgq8NDSrQkXgnqOpFAs-w"
                level="tertiary"
                size="lg"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Modal.Action>
            <Modal.Action>
              <Button
                data-qa="HW3oGcFlzne2ol12DNUtQ"
                level="primary"
                size="lg"
                onClick={this.onClickConfirm}
              >
                Yes, Exit
              </Button>
            </Modal.Action>
          </Modal.Actions>
        </Modal.Footer>
      </Modal>
    );
  }
}

OutreachDiscardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default OutreachDiscardModal;
