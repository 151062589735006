import uniq from 'lodash/uniq';

import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { updateTotalMentionsWidget } from 'reducers/campaigns/campaign-widgets';

import { addPageMessage } from 'reducers/page-messages';
import { performGet, performDelete } from 'services/rest-service/rest-service';
import { getDisplayNamesByIds } from 'services/user-service/user-service';

import messages from '../../pages/Campaigns/Campaigns.messages';

import { UPDATE_CAMPAIGN_PROPERTY } from './campaign';
import { setSelectedSearchIds } from './campaign-sidebar';

export const GET_CAMPAIGN_SEARCH_DATA =
  'campaigns/searches/GET_CAMPAIGN_SEARCH_DATA';
export const GET_CAMPAIGN_SEARCH_DATA_RECEIVED =
  'campaigns/searches/GET_CAMPAIGN_SEARCH_DATA_RECEIVED';
export const GET_CAMPAIGN_SEARCH_DATA_ERROR =
  'campaigns/searches/GET_CAMPAIGN_SEARCH_DATA_ERROR';
export const GET_CAMPAIGN_SEARCH_OWNERS =
  'campaigns/searches/GET_CAMPAIGN_SEARCH_OWNERS';
export const GET_CAMPAIGN_SEARCH_OWNERS_ERROR =
  'campaigns/searches/GET_CAMPAIGN_SEARCH_OWNERS_ERROR';
export const GET_CAMPAIGN_SEARCH_OWNERS_SUCCESS =
  'campaigns/searches/GET_CAMPAIGN_SEARCH_OWNERS_SUCCESS';
export const DELETE_CAMPAIGN_SEARCH =
  'campaigns/searches/DELETE_CAMPAIGN_SEARCH';
export const DELETE_CAMPAIGN_SEARCH_SUCCESS =
  'campaigns/searches/DELETE_CAMPAIGN_SEARCH_SUCCESS';
export const DELETE_CAMPAIGN_SEARCH_ERROR =
  'campaigns/searches/DELETE_CAMPAIGN_SEARCH_ERROR';
export const DELETE_CAMPAIGN_SEARCHES =
  'campaigns/searches/DELETE_CAMPAIGN_SEARCHES';
export const DELETE_CAMPAIGN_SEARCHES_SUCCESS =
  'campaigns/searches/DELETE_CAMPAIGN_SEARCHES_SUCCESS';
export const DELETE_CAMPAIGN_SEARCHES_ERROR =
  'campaigns/searches/DELETE_CAMPAIGN_SEARCHES_ERROR';

export const initialState = {
  campaignSearches: [],
  error: false,
  loading: true,
};

const campaignSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_SEARCH_DATA: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CAMPAIGN_SEARCH_DATA_RECEIVED: {
      return {
        ...state,
        campaignSearches: action.payload,
        error: false,
        loading: false,
      };
    }

    case GET_CAMPAIGN_SEARCH_DATA_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    case GET_CAMPAIGN_SEARCH_OWNERS_SUCCESS: {
      const displayNames = action.payload;
      const searchesWithNames = [...state.campaignSearches];
      searchesWithNames.forEach(
        search => (search.ownedBy = displayNames[search.userId] || ''),
      );
      return {
        ...state,
        campaignSearches: [...searchesWithNames],
      };
    }

    case DELETE_CAMPAIGN_SEARCH: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_CAMPAIGN_SEARCH_SUCCESS: {
      const { searchId } = action.payload;

      return {
        campaignSearches: state.campaignSearches.filter(
          search => search.id !== searchId,
        ),
        loading: false,
        error: false,
      };
    }

    case DELETE_CAMPAIGN_SEARCH_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    case DELETE_CAMPAIGN_SEARCHES: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_CAMPAIGN_SEARCHES_SUCCESS: {
      const { searchIds } = action.payload;

      return {
        campaignSearches: state.campaignSearches.filter(
          search => !searchIds.includes(search.id),
        ),
        loading: false,
        error: false,
      };
    }

    case DELETE_CAMPAIGN_SEARCHES_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    default:
      return state;
  }
};

const getSearchOwnerNames = ownerIds => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_SEARCH_OWNERS });

  try {
    const displayNames = await getDisplayNamesByIds(ownerIds);
    dispatch({
      type: GET_CAMPAIGN_SEARCH_OWNERS_SUCCESS,
      payload: displayNames,
    });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_SEARCH_OWNERS_ERROR });
    throw e;
  }
};

export const getCampaignSearchesByCampaignId = campaignId => dispatch => {
  dispatch({ type: GET_CAMPAIGN_SEARCH_DATA });

  performGet(`${CAMPAIGN_ENDPOINT}/${campaignId}/searches`)
    .then(response => {
      const searches = response.data;
      searches.forEach(search => {
        search.svgIcon = 'search';
        search.type = 'search';
      });
      dispatch({
        type: GET_CAMPAIGN_SEARCH_DATA_RECEIVED,
        payload: response.data,
      });
      const ownerIds = uniq(searches.map(search => search.userId));
      if (ownerIds.length > 0) {
        dispatch(getSearchOwnerNames(ownerIds));
      }
    })
    .catch(() => {
      dispatch({ type: GET_CAMPAIGN_SEARCH_DATA_ERROR });
    });
};

export const deleteCampaignSearchFromCampaign = (
  campaignId,
  searchId,
  intl,
) => async (dispatch, getState) => {
  const campaignSearches = getState().campaignSearchList.campaignSearches;
  dispatch({ type: DELETE_CAMPAIGN_SEARCH });

  const url = `${CAMPAIGN_ENDPOINT}/${campaignId}/searches/${searchId}`;

  try {
    await performDelete(url);
    dispatch({ type: DELETE_CAMPAIGN_SEARCH_SUCCESS, payload: { searchId } });
    const searchIds = campaignSearches
      .map(search => search.id)
      .filter(search => search !== searchId);
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: { searches: searchIds },
    });
    dispatch(setSelectedSearchIds(searchIds));
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.searchesDeletedSuccess, { COUNT: 1 }),
        status: 'success',
        ttl: 3000,
      }),
    );
    dispatch(updateTotalMentionsWidget());
  } catch (e) {
    dispatch({ type: DELETE_CAMPAIGN_SEARCH_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.searchesDeletedFailed, { COUNT: 1 }),
        status: 'danger',
      }),
    );
    throw e;
  }
};

export const deleteCampaignSearchesFromCampaign = (
  campaignId,
  searchIds,
  intl,
) => async (dispatch, getState) => {
  dispatch({ type: DELETE_CAMPAIGN_SEARCHES });

  const url = `${CAMPAIGN_ENDPOINT}/${campaignId}/searches`;
  try {
    await performDelete(url, { searchIds });
    dispatch({
      type: DELETE_CAMPAIGN_SEARCHES_SUCCESS,
      payload: { searchIds },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.searchesDeletedSuccess, {
          COUNT: searchIds.length,
        }),
        status: 'success',
        ttl: 3000,
      }),
    );
    const campaignSearchIds = getState().campaignSearchList.campaignSearches.map(
      search => search.id,
    );
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: { searches: campaignSearchIds },
    });
    dispatch(updateTotalMentionsWidget());
  } catch (e) {
    dispatch({ type: DELETE_CAMPAIGN_SEARCHES_ERROR, payload: { searchIds } });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.searchesDeletedFailed, {
          COUNT: searchIds.length,
        }),
        status: 'danger',
      }),
    );
    throw e;
  }
};

export default campaignSearchReducer;
