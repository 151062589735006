import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import shortid from 'shortid';

import Avatar from '../avatar/Avatar';
import globalMessages from '../Global.messages';
import Loader from '../loader/Loader';

import SvgIcon from '../svg-icon/SvgIcon';

class SelectionTray extends Component {
  state = {
    expanded: false,
  };

  onDelete = entry => this.props.onEntryDelete(entry.id);
  onEntrySelect = entry =>
    this.props.onEntrySelect(this.props.entries[entry.id]);
  toggleTray = () => this.setState({ expanded: !this.state.expanded });

  clearSelected = e => {
    e.stopPropagation();
    this.props.onDeleteAll();
  };

  renderAvatar = (name, imageObject) => {
    const imageUrl = imageObject.url || '';
    return <Avatar size="small" loading={false} url={imageUrl} name={name} />;
  };

  renderSelectedEntry = entry => {
    const title = entry.titleString ? entry.titleString : entry.title;
    const icon = entry.svgIcon ? (
      <SvgIcon
        width={18}
        height={18}
        icon={entry.svgIcon}
        modifiers={['block']}
      />
    ) : (
      <i className={entry.icon} />
    );
    const image =
      typeof entry.image === 'object'
        ? this.renderAvatar(title, entry.image)
        : null;

    return (
      <div
        data-qa="5kQN5ckCqgNzNtNL6uC10"
        className="tk-select-tray__entry"
        onKeyUp={() => this.onEntrySelect(entry)}
        onClick={() => this.onEntrySelect(entry)}
        role="button"
        tabIndex={-1}
        key={shortid.generate()}
      >
        {image ? (
          <div className="tk-select-tray__entry-image">{image}</div>
        ) : (
          <div className="tk-select-tray__entry-icon">{icon}</div>
        )}
        <div className="tk-select-tray__entry-content">
          <div className="tk-select-tray__entry-data">
            <div className="tk-select-tray__entry-title">{title}</div>
            <div className="tk-select-tray__entry-subtitle">
              {entry.subtitle}
            </div>
          </div>
          <div
            data-qa="TV2wku7zsusg96zrJEKDB"
            className="tk-select-tray__entry-remove"
            onKeyUp={() => this.onDelete(entry)}
            onClick={() => this.onDelete(entry)}
            role="button"
            tabIndex={0}
          >
            <SvgIcon
              width={12}
              height={12}
              icon="cross"
              modifiers={['block']}
            />
          </div>
        </div>
      </div>
    );
  };

  renderSelectedList() {
    const { entries, selected, sortComparator } = this.props;
    const entriesArray = Array.isArray(entries)
      ? entries
      : Object.keys(entries).map(entryKey => entries[entryKey]);

    return entriesArray
      .filter(entry => selected.indexOf(`${entry.id}`) >= 0)
      .sort(sortComparator)
      .map(entry => this.renderSelectedEntry(entry));
  }

  renderEntryLabel = () => {
    const { entryType, selected, intl } = this.props;
    if (typeof entryType === 'string') {
      return entryType;
    }

    if (selected.length === 1 && entryType.singular) {
      return entryType.singular;
    }

    return entryType.plural || intl.formatMessage(globalMessages.entries);
  };

  renderTray() {
    const { expanded } = this.state;
    const {
      max,
      selected,
      showChevronIcon,
      selectedItemsCountLoading,
      intl,
    } = this.props;
    const className = classNames('tk-select-tray', this.props.className);
    const selectedEntries = this.renderSelectedList();
    const entryLabel = this.renderEntryLabel();
    const toggleIcon = expanded ? 'chevronDown' : 'chevronUp';

    return (
      <div className={className}>
        <div
          data-qa="ZJRjb8UGv_pimyGmVFpaM"
          className="tk-select-tray__header"
          onKeyUp={this.toggleTray}
          onClick={this.toggleTray}
          role="button"
          tabIndex={0}
        >
          {selectedItemsCountLoading && (
            <span data-qa="SegHAqiqW2fOtQrpvPhj3" className="t-ml-2 t-my-1">
              <Loader size="tiny" />
            </span>
          )}
          <div className="tk-select-tray__header__count">
            {max !== Infinity ? (
              <span>
                {intl.formatMessage(globalMessages.listSelectedItems, {
                  SELECTED_ITEMS_AMOUNT: this.props.selectedItemsCount
                    ? this.props.selectedItemsCount
                    : selected.length,
                  MAX_AMOUNT: max,
                  ENTRY_LABEL: entryLabel,
                })}
              </span>
            ) : (
              <span>
                {intl.formatMessage(globalMessages.infinityListSelectedItems, {
                  SELECTED_ITEMS_AMOUNT: this.props.selectedItemsCount
                    ? this.props.selectedItemsCount
                    : selected.length,
                  ENTRY_LABEL: entryLabel,
                })}
              </span>
            )}
          </div>
          <div
            data-qa="vCQFmGelU_9j1JcnBTRVZ"
            className="tk-select-tray__header__clear-selected"
            role="button"
            onKeyUp={this.clearSelected}
            onClick={this.clearSelected}
            tabIndex={0}
          >
            {intl.formatMessage(globalMessages.clearAll)}
          </div>
          {showChevronIcon && (
            <div className="tk-select-tray__header__toggle">
              <SvgIcon icon={toggleIcon} width={12} height={12} />
            </div>
          )}
        </div>
        {selected.length > 0 && expanded && (
          <div className="tk-select-tray__content">
            <div className="tk-select-tray__content-entries">
              {selectedEntries}
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const shouldRenderTray =
      this.props.selectedItemsCount > 0 || this.props.selected.length > 0;
    return shouldRenderTray ? this.renderTray() : null;
  }
}

SelectionTray.defaultProps = {
  entryType: {
    singular: 'Entry',
    plural: 'Entries',
  },
  className: '',
  max: Infinity,
  onEntrySelect: () => {},
  sortComparator: () => {},
  sortEntries: entries => entries,
  showChevronIcon: true,
  selectedItemsCountLoading: false,
};

SelectionTray.propTypes = {
  className: PropTypes.string,
  entryType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  ]),
  /** A comprehensive list of entries with metadata */
  entries: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        subtitle: PropTypes.node,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        titleString: PropTypes.string,
      }),
    ),
    PropTypes.object,
  ]).isRequired,
  max: PropTypes.number,
  onEntryDelete: PropTypes.func.isRequired,
  onEntrySelect: PropTypes.func,
  onDeleteAll: PropTypes.func.isRequired,
  /** An array of IDs that the "entries" are filtered against to produce the final list */
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** For custom sort logic */
  sortComparator: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  selectedItemsCount: PropTypes.number,
  showChevronIcon: PropTypes.bool,
  selectedItemsCountLoading: PropTypes.bool,
};

export default injectIntl(SelectionTray);
