import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Select } from '@trendkite/ui';

import { propTypes as outreachContactPropTypes } from 'types/outreach-contact';

/**
  This component wraps standard tk-ui Select with some logic to
  parse / unparse Select props for options and values into
  email recipient objects
*/
class FromSelect extends Component {
  onChange = from => {
    const { onChange, options } = this.props;
    const selectedValues = options.filter(
      option => from.indexOf(option.id) >= 0,
    );
    onChange(selectedValues.length ? selectedValues[0] : null);
  };

  render() {
    const { options, value } = this.props;

    const selectOptions = options.map(option => ({
      id: option.id,
      label: option.name ? `${option.name} (${option.email})` : option.email,
      data: {
        ...option,
      },
    }));

    const selectValue = selectOptions
      .filter(option => option.id === (value && value.id))
      .map(option => option.id);

    return (
      <Select
        {...this.props}
        buttonProps={{ style: { paddingLeft: '10px', paddingRight: '20px' } }}
        modifiers={['seethrough']}
        onChange={this.onChange}
        options={selectOptions}
        value={selectValue}
      />
    );
  }
}

FromSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: outreachContactPropTypes,
  options: PropTypes.array,
};

FromSelect.defaultProps = {
  value: [],
  options: [],
  fromDropdownChanged: () => {},
};

export default FromSelect;
