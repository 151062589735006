import React, { useMemo } from 'react';

import compact from 'lodash/compact';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import { isShareOfVoice } from 'components/widgetV3/utils';

import widgetMessages from '../../widgetV3/widget-v3-date-range.messages';
import Data from '../sections/data';
import DateRangeV3 from '../sections/date-range-v3';
import MetricV3 from '../sections/metric-v3';
import VisualizationTypeV3 from '../sections/visualization-type-v3';
import { getDateRangeCompareAllowedV3, STANDARD_SEARCH_LIMIT } from '../utils';

import messages from './form.messages';
import styles from './steps.module.css';

export const SETTING_TAB = 0;
export const FILTERS_TAB = 1;

const CustomChartForm = ({
  nextStepCallback,
  dateRangeCompareType,
  onRemoveDataItem,
  dataLimit,
  dataItemsCount,
  selectedAnalyticId,
  widgetId,
  intl,
  onAddMetric,
  dateRangeV3,
  dateRangeCompareV3,
  dataSectionSourceType,
  showTrailing,
  primaryMetric,
  secondaryMetric,
  showFilters,
}) => {
  const canAddDateRangeCompare = getDateRangeCompareAllowedV3({
    primaryMetric,
    secondaryMetric,
    dataItemsCount,
  });

  const dateRangeDisabledMessage = useMemo(() => {
    const metrics = compact([primaryMetric, secondaryMetric]);

    if (isShareOfVoice(metrics) && !canAddDateRangeCompare) {
      const maxDateRangeSearches = Math.ceil(STANDARD_SEARCH_LIMIT / 2);
      return intl.formatMessage(
        messages.warningSecondaryDateOnlyAllowedWithXSearchesOrFewer,
        {
          NUMBER_OF_SEARCHES: maxDateRangeSearches,
        },
      );
    }
  }, [canAddDateRangeCompare, intl, primaryMetric, secondaryMetric]);

  return (
    <div className={styles.container}>
      <div className={styles.settingsContainer}>
        <MetricV3
          primaryMetric={primaryMetric}
          secondaryMetric={secondaryMetric}
          selectedAnalyticId={selectedAnalyticId}
          onAddMetric={onAddMetric}
          selectedItemsCount={dataItemsCount}
        />
        <Data
          nextStepCallback={nextStepCallback}
          dataLimit={dataLimit}
          sourceType={dataSectionSourceType}
          onRemoveItem={onRemoveDataItem}
          primaryMetric={primaryMetric}
          secondaryMetric={secondaryMetric}
        />
        <DateRangeV3
          canAddDateRangeCompare={canAddDateRangeCompare}
          dateRange={dateRangeV3}
          dateRangeCompareType={dateRangeCompareType}
          dateRangeCompare={dateRangeCompareV3}
          widgetId={widgetId}
          intl={intl}
          showTrailing={showTrailing}
          dateRangeLimitOneYear={
            primaryMetric?.visualizationType === 'WORD_CLOUD'
          }
          dateRangeLimitMessage={intl.formatMessage(
            widgetMessages.widgetWordCloudDateRangeLimit,
          )}
          iconButtonDisabledMessage={dateRangeDisabledMessage}
        />
        <VisualizationTypeV3
          nextStepCallback={nextStepCallback}
          primaryMetric={primaryMetric}
          secondaryMetric={secondaryMetric}
          intl={intl}
        />
      </div>
    </div>
  );
};

CustomChartForm.propTypes = {
  nextStepCallback: PropTypes.func.isRequired,
  dateRangeV3: PropTypes.object.isRequired,
  dateRangeCompareType: PropTypes.string,
  dateRangeCompareV3: PropTypes.object,
  onRemoveDataItem: PropTypes.func.isRequired,
  dataLimit: PropTypes.number.isRequired,
  dataItemsCount: PropTypes.number.isRequired,
  selectedAnalyticId: PropTypes.number,
  widgetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  intl: PropTypes.object.isRequired,
  onAddMetric: PropTypes.func.isRequired,
  dataSectionSourceType: PropTypes.string.isRequired,
  showTrailing: PropTypes.bool,
  primaryMetric: PropTypes.object,
  secondaryMetric: PropTypes.object,
  showFilters: PropTypes.bool,
};

CustomChartForm.defaultProps = {
  dateRangeCompareType: null,
  hasKeyMessages: false,
  widgetId: null,
  dateRangeCompareV3: {},
  selectedAnalyticId: null,
  showTrailing: true,
  primaryMetric: null,
  secondaryMetric: null,
  showFilters: true,
};

export default injectIntl(CustomChartForm);
