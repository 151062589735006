import { TAG_TYPE } from 'constants/constants';
import formatTag from 'utils/tag/tag-utils';

const tagNameMinLength = 3;
const tagNameMaxLength = 40;
const validTagNameRegexPattern = /^[\p{L}\p{N}\p{P}\p{Sc}\p{Sk}\p{Sm}\p{Zs}]+$/gu;

export const buildSelectedTags = (query, selectedTags) => {
  const currentQuery = formatTag(query);
  return currentQuery
    ? [
        ...selectedTags,
        {
          id: currentQuery,
          tag: currentQuery,
          type: { name: TAG_TYPE.REGULAR },
        },
      ]
    : [...selectedTags];
};

export const filterForTagsToCreate = (existingTagIds, selectedTags) => {
  return selectedTags
    .filter(tag => existingTagIds.indexOf(tag.id) === -1)
    .map(tag => tag.tag);
};

export const filterForTagsToAdd = (existingTagIds, selectedTags) => {
  return selectedTags
    .filter(tag => existingTagIds.indexOf(tag.id) > -1)
    .map(tag => tag.id);
};

export const isTagNameLengthInValid = tagName => {
  return (
    !tagName.trim().length ||
    tagName.length < tagNameMinLength ||
    tagName.length > tagNameMaxLength
  );
};

export const tagNameIsUnique = (
  tagName,
  currentTagList = [],
  currentUserLoggedId,
) =>
  currentTagList.every(
    tag =>
      tag.tag !== tagName ||
      (tag.tag === tagName && tag.user.id !== currentUserLoggedId),
  );

export const isTagNameValid = tagName => {
  return tagName.match(validTagNameRegexPattern) !== null;
};
