export default {
  newAccountLabel: {
    id: 'ADMIN_PAGE_ACCOUNT_MANAGEMENT_LIST_NEW_ACCOUNT_LABEL',
    defaultMessage: 'New Account',
    description:
      'Admin Page > Account Management > Account List: New account label',
  },
  filterAccountPlaceHolder: {
    id: 'ADMIN_PAGE_ACCOUNT_MANAGEMENT_FILTER_ACCOUNT_PLACEHOLDER',
    defaultMessage: 'Filter By Account Name',
    description:
      'Admin Page > Account Management > Account List: Filter account by account name placeholder',
  },
  noAccountFoundMsg: {
    id: 'ADMIN_PAGE_ACCOUNT_MANAGEMENT_NO_ACCOUNT_FOUND_MSG',
    defaultMessage: 'Account Not Found',
    description:
      'Admin Page > Account Management > Account List: Account not found message',
  },
  getAccountsErrorMsg: {
    id: 'ADMIN_PAGE_ACCOUNT_MANAGEMENT_GET_ACCOUNTS_ERROR_MESSAGE',
    defaultMessage: 'Could not get accounts',
    description:
      'Admin Page > Account Management > Account List: Get accounts error message',
  },
  deactivateAccountSuccessMsg: {
    id: 'ADMIN_PAGE_ACCOUNT_MANAGEMENT_DEACTIVATE_ACCOUNT_SUCCESS_MSG',
    defaultMessage:
      'The account {ACCOUNT_ID} has been successfully deactivated',
    description:
      'Admin Page > Account Management > Account List: Successfully deactivate account message',
  },
  deactivateAccountErrorMsg: {
    id: 'ADMIN_PAGE_ACCOUNT_MANAGEMENT_DEACTIVATE_ACCOUNT_ERROR_MSG',
    defaultMessage:
      'There was a problem during account {ACCOUNT_ID} deactivation',
    description:
      'Admin Page > Account Management > Account List: Error deactivate account message',
  },
};
