export default {
  createTag: {
    id: 'BULK_TAG_ARTICLES_MODAL_CREATE_A_TAG',
    defaultMessage: 'Create a New Tag',
    description:
      "Message for the modal's title when users want to create a tag",
  },
  tagArticleAs: {
    id: 'BULK_TAG_ARTICLES_MODAL_TAG_ARTICLES_AS',
    defaultMessage: 'Tag Articles as “{TAG_NAME}”',
    description:
      "Message for the modal's title when users want to create a tag",
  },
  articleLinks: {
    id: 'BULK_TAG_ARTICLES_MODAL_ARTICLE_LINKS',
    defaultMessage: 'Article Links',
    description: 'Message for the article links text',
  },
  tagNameInputLabel: {
    id: 'BULK_TAG_ARTICLES_MODAL_TAG_NAME_INPUT_LABEL',
    defaultMessage: 'Tag Name',
    description: "Message for the tag name input's label",
  },
  tagNameInputPlaceholder: {
    id: 'BULK_TAG_ARTICLES_MODAL_TAG_NAME_INPUT_PLACEHOLDER_ADD_NEW_TAG',
    defaultMessage: 'Add a Tag',
    description: "Message for the tag name input's placeholder ",
  },
  tagArticles: {
    id: 'BULK_TAG_ARTICLES_MODAL_TAG_ARTICLES',
    defaultMessage: 'Tag Articles',
    description: 'Message for the tag articles action button',
  },
  tagArticlesDescription: {
    id: 'BULK_TAG_ARTICLES_MODAL_TAG_ARTICLES_DESCRIPTION',
    defaultMessage:
      'Paste one URL per line, up to {URLS_LIMIT} total. Only valid URLs to {GUIDE_LINK} content can be tagged.',
    description: 'Message for the description of the tagging articles process',
  },
  checkArticlesButtonLabel: {
    id: 'BULK_TAG_ARTICLES_MODAL_CHECK_ARTICLES',
    defaultMessage: 'Check URLs',
    description:
      'Bulk Add Articles Modal > Message for the check articles action button',
  },
  urlsAmountRange: {
    id: 'BULK_TAG_ARTICLES_MODAL_URLS_AMOUNT_RANGE',
    defaultMessage: '{START_VALUE}/{END_VALUE}',
    description:
      "Message for the range of the amount of articles' urls allowed in the text area",
  },
  loadingArticlesDescription: {
    id: 'BULK_TAG_ARTICLES_MODAL_LOADING_ARTICLES_DESCRIPTION',
    defaultMessage:
      'It may take a few minutes for articles to show up in our system. For help, {DOCS_LINK} or contact support.',
    description:
      'Message shown when the system is loading the articles that will be tagged',
  },
  taggedArticles: {
    id: 'BULK_TAG_ARTICLES_MODAL_TAGGED_ARTICLES',
    defaultMessage: `Tagged {COUNT, plural, 
        =1 {# article} 
        other {# articles}} 
    as {TAG_NAME}`,
    description: 'Message for the amount of tagged articles',
  },
  noArticlesTagged: {
    id: 'BULK_TAG_ARTICLES_MODAL_NO_ARTICLES_TAGGED',
    defaultMessage: 'No articles could be tagged',
    description: "Message for the no articles could be tagged error's text",
  },
  supported: {
    id: 'BULK_TAG_ARTICLES_MODAL_SUPPORTED_TEXT',
    defaultMessage: 'supported',
    description: 'Message for the supported text',
  },
  checkTheDocs: {
    id: 'BULK_TAG_ARTICLES_MODAL_CHECK_THE_DOCS_TEXT',
    defaultMessage: 'check the docs',
    description:
      "Message for the docs' link shown in the loading articles message",
  },
  createTagSuccess: {
    id: 'BULK_TAG_ARTICLES_MODAL_CREATE_TAG_SUCCESS',
    defaultMessage: 'Successfully created tag "{TAG_NAME}".',
    description: 'Message to show when a tag was created successfully',
  },
  createTagError: {
    id: 'BULK_TAG_ARTICLES_MODAL_CREATE_TAG_ERROR',
    defaultMessage: 'Failed to create tag.',
    description:
      'Message to show when there was an error when trying to create a tag',
  },
  articleTagTableHeader: {
    id: 'BULK_TAG_ARTICLES_MODAL_ARTICLE_TAG_TABLE_HEADER',
    defaultMessage:
      '{FAILED_ARTICLES_COUNT} Failed • {TAGGED_ARTICLES_COUNT} Tagged • {PENDING_ARTICLES_COUNT} Pending',
    description:
      'Bulk Tag Articles Modal > Article Table: Message to show the count of articles failed, tagged, or pending',
  },
};
