import React, { useState, useEffect, useCallback } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import FloatingPanel from '../floating-panel';
import { Input, FormGroup, FormLabel } from '../forms';
import { Body } from '../type';
import useClickOutside from '../use-click-outside';

import CalendarDatePicker from './CalendarDatePicker';

import { getDateString } from './dateUtils.js';
import messages from './input-date-picker.messages';

type Props = {
  id: string;
  className?: string;
  date: number | moment.Moment | string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  floatingCalendarStyle?: object;
  hideLabel?: boolean;
  label?: string;
  minDate: number | moment.Moment | null;
  onBlur: (...args) => void;
  onFocus?: () => void;
  openCalendarDatepickerOnFocus?: boolean;
  error?: string;
  isDisable?: boolean;
  icon?: React.ReactNode;
  monthFormat?: string;
  utcOffset?: string | number;
};

const BASE_CLASS = 'tk-input-datepicker';

const InputDatePicker: React.FC<Props> = ({
  id,
  className,
  date,
  floatingCalendarStyle,
  hideLabel,
  label,
  minDate,
  onBlur,
  onFocus,
  openCalendarDatepickerOnFocus,
  error,
  isDisable,
  icon,
  monthFormat,
  utcOffset,
}) => {
  const intl = useIntl();
  const invalidDateMessage = intl.formatMessage(messages.invalidDateMessage);

  useEffect(() => {
    setDateString(
      getDateString({
        intl,
        date,
        monthFormat,
        dayFormat: 'numeric',
        utcOffset,
      }),
    );
    setInputErrors(moment.utc(date, 'x').isValid() ? '' : invalidDateMessage);
  }, [date, monthFormat, utcOffset, intl, invalidDateMessage]);

  const [dateString, setDateString] = useState(
    getDateString({ intl, date, monthFormat, dayFormat: 'numeric', utcOffset }),
  );
  const [inputErrors, setInputErrors] = useState(
    moment.utc(date, 'x').isValid() ? '' : invalidDateMessage,
  );
  const [calendarOpen, setCalendarOpen] = useState(false);

  const onInputFocus = () => {
    if (openCalendarDatepickerOnFocus) {
      setCalendarOpen(true);
    }

    onFocus && onFocus();
  };

  const onCalendarChange = (selected: any) => {
    setCalendarOpen(false);
    onBlur(selected.valueOf());
  };

  const handleClickOutside = useCallback(() => setCalendarOpen(false), [
    setCalendarOpen,
  ]);
  const clickRef = useClickOutside<HTMLDivElement>(handleClickOutside);

  const calendarMoment = date.valueOf();
  const errorMessage = error || inputErrors;

  return (
    <div ref={clickRef} className={`${BASE_CLASS} ${className}`}>
      <FormGroup modifiers={['position-relative', 'no-margin']}>
        {!hideLabel && (
          <FormLabel htmlFor={id} modifiers={['within-input']}>
            <Body color={isDisable ? 'disabled' : 'light'}>{label}</Body>
          </FormLabel>
        )}
        <Input
          id={id}
          onBlur={() => onBlur(date.valueOf())}
          onFocus={() => onInputFocus()}
          onIconClick={() => onInputFocus()}
          value={dateString}
          paddingLeft={hideLabel ? undefined : '50px'}
          error={errorMessage.length > 0}
          disabled={isDisable}
          icon={icon}
        />
        {errorMessage && (
          <Body block color="danger">
            <span data-qa="DatePicker-input-error">{errorMessage}</span>
          </Body>
        )}
        {calendarOpen && (
          <div
            className={`${BASE_CLASS}__floating-calendar`}
            style={floatingCalendarStyle}
          >
            <FloatingPanel>
              <CalendarDatePicker
                onDateChange={onCalendarChange}
                selectedMoment={calendarMoment}
                handleClickOutside={() => setCalendarOpen(false)}
                minDate={minDate}
                onSelect={() => setCalendarOpen(false)}
                utcOffset={utcOffset}
              />
            </FloatingPanel>
          </div>
        )}
      </FormGroup>
    </div>
  );
};

InputDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object as PropTypes.Validator<moment.Moment>,
    PropTypes.string,
  ]).isRequired,
  floatingCalendarStyle: PropTypes.object,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object as PropTypes.Validator<moment.Moment>,
  ]),
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  openCalendarDatepickerOnFocus: PropTypes.bool,
  error: PropTypes.string,
  isDisable: PropTypes.bool,
  icon: PropTypes.node,
  monthFormat: PropTypes.string,
  utcOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InputDatePicker.defaultProps = {
  className: '',
  floatingCalendarStyle: {},
  hideLabel: false,
  label: 'Start:',
  minDate: null,
  onFocus: () => {},
  openCalendarDatepickerOnFocus: false,
  error: '',
  isDisable: false,
  icon: undefined,
  monthFormat: 'short',
  utcOffset: '+00:00',
};

export default InputDatePicker;
