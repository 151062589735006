import { loadState, saveState } from 'utils/local-storage-state';

export const UPDATE_METRICS_FILTER = 'userPreferences/UPDATE_METRICS_FILTER';
export const DISABLE_SHARED_CAMPAIGN_WARNING =
  'userPreferences/UPDATE_SHARED_CAMPAIGN_WARNING';
export const UPDATE_DASHBOARD_DRILLDOWN_COLUMNS =
  'userPreferences/UPDATE_DASHBOARD_DRILLDOWN_COLUMNS';
export const UPDATE_WIDGET_DRILLDOWN_ARRANGEABLE_COLUMNS =
  'userPreferences/UPDATE_WIDGET_DRILLDOWN_ARRANGEABLE_COLUMNS';
export const UPDATE_EARNED_SEARCH_ARRANGEABLE_COLUMNS =
  'userPreferences/UPDATE_EARNED_SEARCH_ARRANGEABLE_COLUMNS';
export const UPDATE_TAG_ARRANGEABLE_COLUMNS =
  'userPreferences/UPDATE_TAG_ARRANGEABLE_COLUMNS';
export const UPDATE_FALLBACK_ARRANGEABLE_COLUMNS =
  'userPreferences/UPDATE_FALLBACK_ARRANGEABLE_COLUMNS';

const defaultColumns = [
  'OUTLET',
  'AUTHOR',
  'PUB_DATE',
  'SHARES',
  'SEO_IMPACT',
  'IMPACT_SCORE',
  'TOTAL_READERSHIP',
  'SENTIMENT',
  'AVE',
].map(id => ({ id, checked: true }));

const defaultArrangeableColumns = {
  widgetDrilldown: [...defaultColumns],
  earnedSearch: [...defaultColumns],
  tag: [...defaultColumns],
  fallback: [...defaultColumns],
};

const retrieveUserPreferencesFromLocalStorage = () => {
  const userPreferences = loadState('user-preferences');
  if (userPreferences && !userPreferences.arrangeableColumns) {
    return {
      ...userPreferences,
      arrangeableColumns: defaultArrangeableColumns,
    };
  }
  return userPreferences;
};

export const initialState = retrieveUserPreferencesFromLocalStorage() ?? {
  metricsFilters: [],
  arrangeableColumns: defaultArrangeableColumns,
  disableSharedCampaignWarning: false,
};

const userPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_METRICS_FILTER: {
      const newState = {
        ...state,
        metricsFilters: action.payload.metricsFilters,
      };

      saveState('user-preferences', newState);
      return newState;
    }
    case DISABLE_SHARED_CAMPAIGN_WARNING: {
      const newState = {
        ...state,
        disableSharedCampaignWarning: true,
      };
      saveState('user-preferences', newState);
      return newState;
    }
    case UPDATE_DASHBOARD_DRILLDOWN_COLUMNS: {
      const newState = {
        ...state,
        dashboardDrilldownColumns: action.payload.columns,
      };

      saveState('user-preferences', newState);
      return newState;
    }
    case UPDATE_WIDGET_DRILLDOWN_ARRANGEABLE_COLUMNS: {
      const newState = {
        ...state,
        arrangeableColumns: {
          ...state.arrangeableColumns,
          widgetDrilldown: action.payload.columns,
        },
      };

      saveState('user-preferences', newState);
      return newState;
    }
    case UPDATE_EARNED_SEARCH_ARRANGEABLE_COLUMNS: {
      const newState = {
        ...state,
        arrangeableColumns: {
          ...state.arrangeableColumns,
          earnedSearch: action.payload.columns,
        },
      };

      saveState('user-preferences', newState);
      return newState;
    }
    case UPDATE_TAG_ARRANGEABLE_COLUMNS: {
      const newState = {
        ...state,
        arrangeableColumns: {
          ...state.arrangeableColumns,
          tag: action.payload.columns,
        },
      };

      saveState('user-preferences', newState);
      return newState;
    }
    case UPDATE_FALLBACK_ARRANGEABLE_COLUMNS: {
      const newState = {
        ...state,
        arrangeableColumns: {
          ...state.arrangeableColumns,
          fallback: action.payload.columns,
        },
      };

      saveState('user-preferences', newState);
      return newState;
    }
    default:
      return state;
  }
};

export const updateMetricFilters = metricsFilters => ({
  type: UPDATE_METRICS_FILTER,
  payload: { metricsFilters },
});

export const updateDashboardDrilldownColumns = columns => ({
  type: UPDATE_DASHBOARD_DRILLDOWN_COLUMNS,
  payload: { columns },
});

export const updateWidgetDrilldownArrangeableColumns = columns => ({
  type: UPDATE_WIDGET_DRILLDOWN_ARRANGEABLE_COLUMNS,
  payload: { columns },
});

export const updateEarnedSearchArrangeableColumns = columns => ({
  type: UPDATE_EARNED_SEARCH_ARRANGEABLE_COLUMNS,
  payload: { columns },
});

export const updateTagArrangeableColumns = columns => ({
  type: UPDATE_TAG_ARRANGEABLE_COLUMNS,
  payload: { columns },
});

export const updateFallbackArrangeableColumns = columns => ({
  type: UPDATE_FALLBACK_ARRANGEABLE_COLUMNS,
  payload: { columns },
});

export const disableSharedCampaignWarning = () => ({
  type: DISABLE_SHARED_CAMPAIGN_WARNING,
});

export default userPreferencesReducer;
