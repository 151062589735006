const iconColorsMap = {
  mainicon: 'currentColor',
  exchange: '#0071BB',
  facebook: '#3B579D',
  googleblue: '#4285F4',
  googlegreen: '#34A853',
  googleyellow: '#FBBC05',
  googlered: '#EA4335',
  googleplus: '#DB4437',
  instagram: '#0A0B09',
  linkedin: '#0077B5',
  outlook: '#EB3C00',
  pinterest: '#BD081C',
  redditred: '#FF4500',
  redditwhite: '#FFFFFE',
  retweet: '#677078',
  slackblue: '#36C5F0',
  slackgreen: '#2EB67D',
  slackred: '#E01E5A',
  slackyellow: '#ECB22E',
  twitch: '#6441A4',
  twitter: '#1DA1F2',
  twittercomment: '#677078',
  twitteremail: '#677078',
  twitterlike: '#677078',
  twitterverified: '#1CA1F2',
  youtube: 'red',
};

export default iconColorsMap;
