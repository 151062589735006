import {
  CRITICAL_MENTION_TYPE,
  DATA_SOURCE,
  MEDIA_TYPES,
  TV_EYES_TYPE,
  PODCAST,
  NLA_LICENSED_CONTENT,
  NCA_LICENSED_CONTENT,
  BLOOMBERG_DATA_SOURCE_PREFIX,
} from 'constants/constants';

export const isFinancialTimes = (publisherUrl, dataSource) => {
  return (
    publisherUrl &&
    publisherUrl.includes(MEDIA_TYPES.financialTimes.apiKey) &&
    (dataSource === DATA_SOURCE.financialTimes.apiKey ||
      dataSource === DATA_SOURCE.moreover.apiKey)
  );
};

export const isNLA = (sourceAccount = []) => {
  return Array.isArray(sourceAccount)
    ? sourceAccount.includes(NLA_LICENSED_CONTENT)
    : false;
};

export const isNCA = (sourceAccount = []) => {
  return Array.isArray(sourceAccount)
    ? sourceAccount.includes(NCA_LICENSED_CONTENT)
    : false;
};

export const isLexisNexis = dataSource => {
  return dataSource === DATA_SOURCE.moreover.apiKey;
};

export const isNewYorkTimes = (mediaType, dataSource) => {
  return (
    (mediaType === MEDIA_TYPES.news.apiKey ||
      mediaType === MEDIA_TYPES.printData.apiKey) &&
    (dataSource === DATA_SOURCE.newYorkTimesOnline.apiKey ||
      dataSource === DATA_SOURCE.newYorkTimesPrint.apiKey)
  );
};

export const isBloomberg = (mediaType, dataSource) =>
  mediaType === MEDIA_TYPES.news.apiKey &&
  dataSource?.toLowerCase().includes(BLOOMBERG_DATA_SOURCE_PREFIX);

export const isWashingtonPost = (mediaType, dataSource) => {
  return (
    mediaType === MEDIA_TYPES.news.apiKey &&
    dataSource === DATA_SOURCE.washingtonPost.apiKey
  );
};

export const isFactiva = dataSource => {
  return (
    dataSource === DATA_SOURCE.factiva.apiKey ||
    dataSource === DATA_SOURCE.factivaDowJones.apiKey ||
    dataSource === DATA_SOURCE.factivaGlobeAndMail.apiKey
  );
};

export const isRestrictedContent = articleData => {
  const {
    dataSource,
    mediaType,
    publisherUrl,
    sourceAccount = [],
  } = articleData;
  return (
    isLexisNexis(dataSource) ||
    isNewYorkTimes(mediaType, dataSource) ||
    isFinancialTimes(publisherUrl, dataSource) ||
    isNCA(sourceAccount) ||
    isNLA(sourceAccount) ||
    isBloomberg(mediaType, dataSource) ||
    isWashingtonPost(mediaType, dataSource)
  );
};

export const isTvEyes = mediaDisplayType =>
  mediaDisplayType === TV_EYES_TYPE || mediaDisplayType === PODCAST;
export const isPodcast = mediaDisplayType => mediaDisplayType === PODCAST;
export const isCriticalMention = mediaDisplayType =>
  mediaDisplayType === CRITICAL_MENTION_TYPE;
export const isCedrom = dataSource => dataSource === DATA_SOURCE.cedrom.apiKey;
export const isCedromPrint = dataSource =>
  dataSource === DATA_SOURCE.cedromPrint.apiKey;
export const isNewYorkTimesPrint = dataSource =>
  dataSource === DATA_SOURCE.newYorkTimesPrint.apiKey;
