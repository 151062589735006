import difference from 'lodash/difference';

import { WIDGET_DETAIL_ENDPOINT } from 'constants/apis';
import * as constants from 'constants/constants';
import { getSearchBySearchId } from 'reducers/searches';
import { performGet } from 'services/rest-service/rest-service';

// TODO: Combine articles list reducer and article details reducer into one reducer file?
export const GET_DRILLDOWN = 'drilldowns/GET_DRILLDOWN';
export const GET_DRILLDOWN_RECEIVED = 'drilldowns/GET_DRILLDOWN_RECEIVED';
export const GET_DRILLDOWN_ERROR = 'drilldowns/GET_DRILLDOWN_ERROR';
export const REMOVE_DRILLDOWN_ARTICLES = 'drilldowns/REMOVE_DRILLDOWN_ARTICLES';
export const UNSET_CURRENT_DRILLDOWN = 'drilldowns/UNSET_CURRENT_DRILLDOWN';
export const UPDATE_DRILLDOWN_ARTICLES = 'drilldowns/UPDATE_DRILLDOWN_ARTICLES';

export const initialState = {
  loading: true,
  current: null,
  error: false,
  dashboardId: '',
  drilldowns: {},
  orderedMetrics: constants.ORDERED_METRICS,
};

export const emptyDrilldownState = {
  articleIds: [],
  articlesTotalCount: NaN,
  authorsCount: NaN,
  dashboardId: '',
  id: '',
  orderedMetrics: constants.ORDERED_METRICS,
  pageMax: '',
  pageMaxNum: null,
  pageNext: '',
  pagePrevious: '',
  sort: '',
  type: '',
};

const drilldownsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRILLDOWN:
      return {
        ...state,
        loading: true,
      };
    case GET_DRILLDOWN_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_DRILLDOWN_RECEIVED:
      return {
        ...state,
        loading: false,
        drilldowns: {
          ...state.drilldowns,
          [action.payload.id]: {
            ...emptyDrilldownState,
            ...action.payload,
          },
        },
        current: action.payload.id,
        analyticsIntegrationId: action.payload.analyticsIntegrationId,
        dashboardId: action.payload.dashboardId,
      };
    case REMOVE_DRILLDOWN_ARTICLES: {
      const oldArticleIds = [...state.drilldowns[action.payload.id].articleIds];
      const newArticleIds = difference(
        oldArticleIds,
        action.payload.removeArticleIds,
      );
      const removedCount = oldArticleIds.length - newArticleIds.length;

      return {
        ...state,
        drilldowns: {
          ...state.drilldowns,
          [action.payload.id]: {
            ...state.drilldowns[action.payload.id],
            articleIds: newArticleIds,
            articlesTotalCount:
              state.drilldowns[action.payload.id].articlesTotalCount -
              removedCount,
          },
        },
      };
    }
    case UPDATE_DRILLDOWN_ARTICLES:
      return {
        ...state,
        drilldowns: {
          ...state.drilldowns,
          [action.payload.id]: {
            ...state.drilldowns[action.payload.id],
            ...action.payload,
          },
        },
        analyticsIntegrationId: `${action.payload.analyticsIntegrationId}`,
      };
    default:
      return state;
  }
};

export const getDrilldown = (widgetId, urlParams) => dispatch => {
  dispatch({ type: GET_DRILLDOWN });

  performGet(`${WIDGET_DETAIL_ENDPOINT}/${widgetId}`)
    .then(response => {
      const searches = response.data.searches.map(search => search.id);
      const selectedSearchId =
        urlParams && urlParams.selectedSearchId
          ? urlParams.selectedSearchId
          : '';

      dispatch({
        type: GET_DRILLDOWN_RECEIVED,
        payload: {
          analyticsIntegrationId: urlParams.analyticsIntegrationId,
          dashboardId: response.data.dashboard.id.toString(),
          id: widgetId,
          primarySearch: response.data.primarySearch,
          searches,
          selectedSearchId,
          title: response.data.name,
          type: response.data.widgetType.name,
        },
      });

      if (searches.length === 1) {
        dispatch(getSearchBySearchId(response.data.primarySearch.id));
      }

      if (searches.length > 1) {
        dispatch(getSearchBySearchId(selectedSearchId));
      }
    })
    .catch(error => {
      dispatch({ type: GET_DRILLDOWN_ERROR, payload: error });
      throw error;
    });
};

export const setPlaceholderDrilldown = ({ id, title }) => dispatch => {
  dispatch({
    type: GET_DRILLDOWN_RECEIVED,
    payload: {
      id,
      title,
    },
  });
};

export default drilldownsReducer;
