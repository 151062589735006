import { LocalStorage } from 'ttl-localstorage';

import {
  getCurrentUserAccountId,
  getCurrentUserId,
  getCurrentUserLanguage,
} from '../user-service/user-service';

type ParamsType = {
  [key: string]: any;
};

type ContentsType = any;

const keyPrefix = '__restServiceCache:';
const defaultCacheTtl = 10 * 60; // 10 min in seconds
const cache = LocalStorage;
cache.timeoutInSeconds = defaultCacheTtl;
cache.synchronous = true;

const getKeys = () => {
  const keys: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key) keys.push(key);
  }
  return keys;
};

const getUserSettings = () => ({
  accountId: getCurrentUserAccountId(),
  userId: getCurrentUserId(),
  language: getCurrentUserLanguage(),
});

const getKey = (params: ParamsType) =>
  `${keyPrefix}${JSON.stringify({
    userSettings: getUserSettings(),
    requestParams: params,
  })}`;

const clear = (): void => cache.clear();

const del = (params: ParamsType): void =>
  cache.removeKey(getKey({ ...params }));

const get = (params: ParamsType): { [key: string]: any } =>
  cache.get(getKey({ ...params }));

const deleteKeysByUrl = (urlMatcher: RegExp | string): void => {
  const keys = getKeys();

  keys
    .filter(key => key.startsWith(keyPrefix))
    .map(key => {
      let url: string | undefined;

      try {
        url = JSON.parse(key.substring(keyPrefix.length)).requestParams.url;
      } catch (error) {}

      return {
        key,
        url,
      };
    })
    .filter(({ url }) => {
      if (url === undefined) return true;
      if (urlMatcher instanceof RegExp) return urlMatcher.test(url);
      return url === urlMatcher;
    })
    .forEach(({ key }) => {
      cache.removeKey(key);
    });
};

const put = (params: ParamsType, contents: ContentsType, ttl?: number): void =>
  cache.put(getKey({ ...params }), contents, ttl);

export default {
  clear,
  delete: del,
  deleteKeysByUrl,
  get,
  getKey,
  put,
};
