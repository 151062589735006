import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Control } from 'react-redux-form';

import { Input } from '@trendkite/ui';

import formMessages from '../email-form.messages';

const CustomInputControl = props => (
  <Input
    {...props}
    modifiers={['seethrough', 'email-header']}
    padding="10px 20px"
  />
);

const EmailRecipientsControl = props => {
  const intl = useIntl();
  const [isSubjectFieldTouched, setIsSubjectFieldTouched] = useState(false);
  const [isSubjectFieldValid, setIsSubjectFieldValid] = useState(false);

  const baseClass = 'email-subject-control';

  const { className, model, ...otherProps } = props;

  useEffect(() => {
    setIsSubjectFieldTouched(props.isSubjectFieldTouched);
    setIsSubjectFieldValid(props.isSubjectFieldValid);
  }, [props.isSubjectFieldTouched, props.isSubjectFieldValid]);

  return (
    <div
      {...otherProps}
      className={classNames(`${baseClass} ${className}`, {
        [`${baseClass}--error`]: isSubjectFieldTouched && !isSubjectFieldValid,
      })}
      data-qa="email-subject-control"
    >
      <Control
        component={CustomInputControl}
        model={`${model}`}
        mapProps={{
          value: props => props.viewValue,
        }}
        placeholder={intl.formatMessage(formMessages.subjectRequiredLabel)}
      />
    </div>
  );
};

EmailRecipientsControl.propTypes = {
  className: PropTypes.string,
  model: PropTypes.string.isRequired,
  isSubjectFieldValid: PropTypes.bool,
  isSubjectFieldTouched: PropTypes.bool,
  viewValue: PropTypes.string.isRequired,
};

EmailRecipientsControl.defaultProps = {
  className: '',
  isSubjectFieldValid: false,
  isSubjectFieldTouched: false,
};

export default EmailRecipientsControl;
