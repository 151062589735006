export const ADD_KEYWORD = 'ADD_KEYWORD';
export const DELETE_KEYWORD = 'DELETE_KEYWORD';
export const SHOW_KEYWORD_ERROR = 'SHOW_KEYWORD_ERROR';
export const CLEAN_UP_ALL_KEYWORD_ERRORS = 'CLEAN_UP_ALL_KEYWORD_ERRORS';

export const SOCIAL_STREAM_CREATE_FILTER_LOCATION =
  'SOCIAL_STREAM_CREATE_FILTER_LOCATION';
export const SOCIAL_STREAM_CREATE_FILTER_LANGUAGE =
  'SOCIAL_STREAM_CREATE_FILTER_LANGUAGE';
export const SOCIAL_STREAM_CREATE_FILTER_SENTIMENT =
  'SOCIAL_STREAM_CREATE_FILTER_SENTIMENT';
export const SOCIAL_STREAM_CREATE_FILTER_MEDIA =
  'SOCIAL_STREAM_CREATE_FILTER_MEDIA';
export const SOCIAL_STREAM_CREATE_FILTER_FOLLOWER_COUNT =
  'SOCIAL_STREAM_CREATE_FILTER_FOLLOWER_COUNT';
export const SOCIAL_STREAM_SET_PREVIEW_CRITERIA =
  'SOCIAL_STREAM_SET_PREVIEW_CRITERIA';

export const SET_ACTIVE_KEYWORD_CONTAINER_ID =
  'SET_ACTIVE_KEYWORD_CONTAINER_ID';

export const APPLY_QUERY_BUILDER = 'APPLY_QUERY_BUILDER';
export const TAKE_QUERY_SNAPSHOT = 'TAKE_QUERY_SNAPSHOT';
export const SET_ADVANCED_QUERY = 'SET_ADVANCED_QUERY';
export const SET_IS_VALID_SEARCH = 'SET_IS_VALID_SEARCH';
export const ALLOW_SAVE_QUERY = 'ALLOW_SAVE_QUERY';

export const SET_MAX_MENTIONS = 'SET_MAX_MENTIONS';
export const SET_MENTION_COUNT = 'SET_MENTION_COUNT';
export const SET_SOCIAL_SEARCH_BUILDER_DATA = 'SET_SOCIAL_SEARCH_BUILDER_DATA';
