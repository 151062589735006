import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

import { requestInterceptors, responseInterceptors } from './interceptors';

const shouldBeCached = {
  '/api/influencer-hub/reference': true,
  '/api/influencer-hub/reference?types=all': true,
};

const cache = setupCache({
  maxAge: 60 * 1000, // 1m
  exclude: {
    filter: ({ url }) => !shouldBeCached[url],
  },
});

const axiosWithCache = axios.create({
  adapter: cache.adapter,
});

axiosWithCache.interceptors.request.use(requestInterceptors);
axiosWithCache.interceptors.response.use(responseInterceptors);

// The intent is this should only be specifically called in dashboard and
// then does not need to support the darn interceptors for Audience intoduced
// for influencers
const MAX_SERIAL_COUNT = 1;
const INTERVAL_SERIAL = 250;
let SERIAL_REQUESTS = 0;
const serialQueue: string[] = [];

export const axiosWithCacheAndSerial = axios.create({
  adapter: cache.adapter,
});

axiosWithCacheAndSerial.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const turl: string = config.url ? config.url : config.toString();
      if (serialQueue.indexOf(turl) === -1) {
        serialQueue.push(turl);
      }
      if (SERIAL_REQUESTS < MAX_SERIAL_COUNT && serialQueue[0] === turl) {
        serialQueue.shift();
        SERIAL_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_SERIAL);
  });
});

axiosWithCacheAndSerial.interceptors.response.use(
  function (response) {
    SERIAL_REQUESTS = Math.max(0, SERIAL_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function (error) {
    SERIAL_REQUESTS = Math.max(0, SERIAL_REQUESTS - 1);
    return Promise.reject(error);
  },
);

// The intent is this should only be specifically called in dashboard and
// then does not need to support the darn interceptors for Audience intoduced
// for influencers
const MAX_REQUESTS_COUNT = 3;
const INTERVAL_MS = 250;
let PENDING_REQUESTS = 0;
const limitQueue: string[] = [];

export const axiosWithCacheAndLimit = axios.create({
  adapter: cache.adapter,
});

axiosWithCacheAndLimit.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const turl: string = config.url ? config.url : config.toString();
      if (limitQueue.indexOf(turl) === -1) {
        limitQueue.push(turl);
      }
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT && limitQueue[0] === turl) {
        limitQueue.shift();
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

axiosWithCacheAndLimit.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  },
);

export default axiosWithCache;

export const axiosWithNumbersAsStrings = axios.create();

axiosWithNumbersAsStrings.defaults.transformResponse = [
  function (data) {
    const regex = /("articleId":)([0-9]+)/g;
    try {
      if (!data || !data.includes('articleId')) {
        return JSON.parse(data);
      }

      const transformedResponse = JSON.parse(
        data.replace(regex, (_, jsonKey, keyValue) => {
          return jsonKey + '"' + keyValue + '"';
        }),
      );

      return transformedResponse;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        'Unable to transform the response from numbers to strings...',
      );
      return JSON.parse(data);
    }
  },
];
