import { RecipientItem } from './components/EmailCampaignRecipientList/EmailCampaignRecipientTypes';

export type ImageErrorCallback = (file: File) => void;
export type ImageLoadedCallback = (
  id: number,
  url: string,
  attachmentId: number,
) => void;
export type ImageOpenCallback = (
  imageUploadCallback: (
    imageFile: Omit<ImageUploadCallback, 'id' | 'imageLoadedCallback'>,
  ) => void,
  imageInsertCallback: (url: string, alt: string) => void,
) => void;
export type ImageUploadCallback = (
  id: number,
  file: File,
  imageLoadedCallback: ImageLoadedCallback,
  fileContentDisposition: string,
) => void;

export type InsertTranslatedDocumentCallback = (content: string) => void;
export type ImportFromWordCallback = (
  document: File,
  insertTranslatedDocument: InsertTranslatedDocumentCallback,
) => void;
export type ImportFromWordOpenDialogCallback = (
  selectDocument: (file: File) => void,
) => void;

export enum EmailAddDeleteRecipientError {
  None,
  NotAuthorized,
  NotFound,
  Unknown,
}

export enum EmailCreateDraftError {
  None,
  NoEmails,
  MissingEmails,
  Unknown,
}

export enum EmailCopyDraftError {
  None,
  NotFound,
  Unknown,
}

export enum EmailDeleteDraftError {
  None,
  NotFound,
  NotAuthorized,
  Unknown,
}

export enum FormFieldError {
  None,
  Required,
}

export enum EmailGetDraftError {
  None,
  NotFound,
  Unknown,
}

export enum EmailGetOptOutError {
  None,
  Unknown,
}

export enum EmailGetRecipientsError {
  None,
  NotAuthorized,
  NotFound,
  Unknown,
}

export enum EmailSendError {
  None,
  NotFound,
  NotAuthorized,
  NotVerified,
  TooLarge,
  Unknown,
  InvalidScheduledTime,
}

export enum EmailUnscheduleError {
  None,
  Other = 1,
}

export enum EmailUnscheduleBulkError {
  None,
  Other = 1,
}

export enum EmailSendPreviewError {
  None,
  NotFound,
  NotAuthorized,
  NotVerified,
  Unknown,
}

export enum EmailSetOptOutError {
  None,
  NotAuthorized,
  BadRequest,
  NoContent,
  Unknown,
}

export enum EmailUpdateDraftError {
  None,
  NotFound,
  NotAuthorized,
  TooLarge,
  Unknown,
}

export enum EmailOptOutFields {
  AddressLineOne,
  AddressLineTwo,
  City,
  CompanyName,
  Country,
  State,
  ZipCode,
  FullOptOutAddress,
}

export enum FileContentDisposition {
  ATTACHMENT = 'attachment',
  INLINE = 'inline',
}

export type ImportFromWordErrors = Readonly<{
  unauthorized: string;
  badRequest: string;
  requestTooLong: string;
  serverError: string;
  unsupportedMediaType: string;
}>;

export type ImportFromWordProgressMessages = Readonly<{
  uploadProgressMessage: string;
  processingMessage: string;
  completedMessage: string;
}>;

export type EmailFormField = Readonly<{
  isValid: boolean;
  isDirty: boolean;
  error: FormFieldError;
}>;

export type EmailFormFields = Readonly<{
  recipients: EmailFormField;
  from: EmailFormField;
  // senderName: EmailFormField;
  subject: EmailFormField;
  body: EmailFormField;
}>;

export type EmailOptOutAddress = Readonly<{
  addressLine1: string;
  addressLine2: string;
  city: string;
  companyName: string;
  country: string;
  id: number;
  state: string;
  zipCode: string;
  fullOptOutAddress: string;
}>;

export type EmailRecipient = Readonly<{
  name: string;
  email: string;
}>;

export type EmailDocument = Readonly<{
  additionalRecipients: EmailRecipient[];
  body: string;
  from: EmailRecipient[];
  id: string;
  isCopy: boolean;
  optOutSettings: EmailOptOutAddress;
  replyTo: EmailRecipient[];
  sendToSelf: boolean;
  subject: string;
  title?: string;
  senderName: EmailRecipient[];
}>;

export type EmailVerificationType = Readonly<{
  emailAddress: string;
  error: string;
  loading: boolean;
  verified: boolean;
}>;

export type EmailError = Readonly<{
  type: number;
  value: string;
}>;

export type EmailErrors = Readonly<{
  addRecipients: EmailError;
  createEmailDraft: EmailError;
  copyEmailDraft: EmailError;
  deleteEmailDraft: EmailError;
  deleteRecipients: EmailError;
  getEmailDraft: EmailError;
  getOptOutAddress: EmailError;
  getRecipients: EmailError;
  sendEmail: EmailError;
  sendEmailPreview: EmailError;
  setOptOutAddress: EmailError;
  updateEmailDraft: EmailError;
  unscheduleEmail: EmailError;
  unscheduleEmailBulk: EmailError;
}>;

export type EmailLoading = Readonly<{
  addRecipients: boolean;
  createEmailDraft: boolean;
  copyEmailDraft: boolean;
  deleteAttachment: boolean;
  deleteEmailDraft: boolean;
  deleteImages: boolean;
  deleteRecipients: boolean;
  getEmailDraft: boolean;
  getOptOutAddress: boolean;
  getRecipients: boolean;
  sendEmail: boolean;
  sendEmailPreview: boolean;
  setOptOutAddress: boolean;
  updateEmailDraft: boolean;
  getVirtualSelectionRecipientsCount: boolean;
  getVerifiedEmails: boolean;
  getSenderAuthorizationStatus: boolean;
  deleteVerifiedEmail: boolean;
  unscheduleEmailBulk: boolean;
}>;

export type EmailAttachment = Readonly<{
  attachmentId: number;
  contentType: string;
  errored: boolean;
  filename: string;
  loading: boolean;
  localId: number;
  progress: number;
  size: number;
}>;

export type EmailImageAttachment = Readonly<{
  attachmentId: number;
  contentType: string;
  errored: boolean;
  filename: string;
  imageId: number;
  loading: boolean;
  progress: number;
  size: number;
}>;

export type EmailImportFromWord = Readonly<{
  cancelled: boolean;
}>;

export type EmailScheduling = Readonly<{
  scheduled: boolean;
  timestamp: number;
  utcOffset: string;
  invalidDate: boolean;
  minTime: string;
  utcTimezone: string;
}>;

export type SenderAuthorizationStatus = Readonly<{
  authorized: boolean;
}>;

export type DeletingImagesProgress = Readonly<{
  image: number;
  total: number;
  progress: number;
}>;

export type EmailCurrentFileData = {
  fileName: string;
  fileSize: number;
};

export type EmailState = Readonly<{
  attachments: EmailAttachment[];
  attachmentsTotalSize: number;
  currentFileData: EmailCurrentFileData;
  defaultOptOutAddress: EmailOptOutAddress;
  deletingImagesProgress: DeletingImagesProgress;
  document: EmailDocument;
  editorContentSize: number;
  emailVerification: EmailVerificationType;
  entryLocation: string;
  emailSettingsFullOptOutAddressToggle: boolean;
  error: EmailErrors;
  field: EmailFormFields;
  images: EmailImageAttachment[];
  loading: EmailLoading;
  optOutAddressEditing: boolean;
  recipientItems: RecipientItem[];
  recipientItemsTotal: number;
  recipientsTotal: number;
  updatePending: boolean;
  importFromWord: EmailImportFromWord;
  scheduling: EmailScheduling;
  campaignIds: number[];
  // email-specific hydrated campaigns, which come from the /drafts/{id} resource.
  campaigns: any[];
  virtualSelectionRecipientsCount: number;
  verifiedEmails: any[];
  senderAuthorizationStatus: SenderAuthorizationStatus;
  linkTrackingEnabled: boolean;
  emailChanged: boolean;
  initialLinkTrackingReset: any;
}>;

export type DatasetCriteria = {
  listId: string;
  hasEmail: boolean;
};

export type Selection = {
  datasetCriteria: DatasetCriteria;
  selectAll: boolean;
  delta: string[];
};

export type Operation = {
  op: string;
  selection: Selection;
};

export type AddRecipientListVirtualSelectionCriteria = {
  ops: Operation[];
};

export type AddRecipientsSideTrayVirtualSelection = {
  listId: string;
  selectAll: boolean;
  delta: string[];
};
