export default {
  deleteTooltipText: {
    id: 'ARTICLES_DELETE_TOOLTIP_DESCRIPTION',
    description: 'Delete button icon description text',
    defaultMessage: 'Delete',
  },
  kiteTitleWrongKeyWord: {
    id: 'DISPLAY_WRONG_KEY_WORD_MESSAGE_KITE',
    description: 'Message to advice user about wrong key word typed',
    defaultMessage: 'Search Failed',
  },
  selectAllArticlesCheckBox: {
    id: 'SELECT_ALL_ARTICLES_CHECKBOX_MESSAGE',
    description: 'Description for the Select all articles checkbox',
    defaultMessage: 'Select All Articles',
  },
  noArticlesFoundBody: {
    id: 'ARTICLE_LIST_EMPTY_MESSAGE_BODY',
    description: 'Body of message when Article list is in an empty state',
    defaultMessage:
      'No articles found. Try expanding the date range or filters.',
  },
  noArticlesFoundHeadline: {
    id: 'ARTICLE_LIST_EMPTY_MESSAGE_HEADLINE',
    description: 'Header of message when Article list is in an empty state',
    defaultMessage: 'Sorry.',
  },
  tagTooltipText: {
    id: 'ARTICLE_LIST_TAG_TOOLTIP_DESCRIPTION',
    description: 'Campaign > Tag button icon description text',
    defaultMessage: 'Tag Selected Articles',
  },
  sentimentTooltipText: {
    id: 'ARTICLE_LIST_SENTIMENT_TOOLTIP_DESCRIPTION',
    description: 'Campaign > Sentiment button icon description text',
    defaultMessage: 'Set Sentiment',
  },
  expandSelectedArticlesTooltipText: {
    id: 'ARTICLE_LIST_EXPAND_SELECTED_ARTICLES_TOOLTIP_DESCRIPTION',
    description:
      'Campaign > Expand selected articles button icon description text',
    defaultMessage: 'Expand Selected Articles',
  },
  articlesCountLabel: {
    id: 'ARTICLE_LIST_ARTICLES_COUNT_LABEL',
    defaultMessage: '{pageArticleCount} of {totalArticleCount} selected',
    description:
      'This meta bar is located above the articles list, this label shows the count of articles retrieved when all articles are selected',
  },
  starArticleSuccess: {
    id: 'ARTICLE_DETAIL_STAR_ARTICLE_SUCCESS',
    defaultMessage: 'Saved top {ARTICLE_TYPE_LABEL}',
    description: 'Kite message is shown when an article is starred success',
  },
  starArticleError: {
    id: 'ARTICLE_DETAIL_STAR_ARTICLE_ERROR',
    defaultMessage: 'There was a problem saving this top {ARTICLE_TYPE_LABEL}',
    description:
      'Kite message is shown when an error occurred starring an article',
  },
  unStarArticleSuccess: {
    id: 'ARTICLE_DETAIL_UNSTAR_ARTICLE_SUCCESS',
    defaultMessage: 'Removed top {ARTICLE_TYPE_LABEL}.',
    description: 'Kite message is shown when an article is unstarred success',
  },
  unStarArticleError: {
    id: 'ARTICLE_DETAIL_UNSTAR_ARTICLE_ERROR',
    defaultMessage:
      'There was a problem removing this top {ARTICLE_TYPE_LABEL}.',
    description:
      'Kite message is shown when an error occurred unstarring an article',
  },
  createCustomClipSuccess: {
    id: 'ARTICLE_DETAIL_CREATE_CUSTOM_CLIP_SUCCESS',
    defaultMessage: 'Successfully set broadcast clip!',
    description:
      'Kite message is shown when a custom clip is set to an article successfully',
  },
  createCustomClipError: {
    id: 'ARTICLE_DETAIL_CREATE_CUSTOM_CLIP_ERROR',
    defaultMessage: 'There was a problem setting the broadcast clip.',
    description:
      'Kite message is shown when an error ocurred setting a custom clip to an article',
  },
  resetCustomClipSuccess: {
    id: 'ARTICLE_DETAIL_RESET_CUSTOM_CLIP_SUCCESS',
    defaultMessage: 'Successfully reset broadcast clip!',
    description:
      'Kite message is shown when a custom clip is reset successfully',
  },
  resetCustomClipError: {
    id: 'ARTICLE_DETAIL_RESET_CUSTOM_CLIP_ERROR',
    defaultMessage: 'There was a problem resetting the broadcast clip.',
    description:
      'Kite message is shown when an error ocurred resetting a custom clip to an article',
  },
  removeArticleFromSearchSuccess: {
    id: 'ARTICLE_DETAIL_REMOVE_ARTICLE_SUCCESS',
    defaultMessage: 'Successfully removed article from search!',
    description:
      'Kite message is shown when an article is removed successfully',
  },
  removeArticleFromSearchError: {
    id: 'ARTICLE_DETAIL_REMOVE_ARTICLE_ERROR',
    defaultMessage: 'There was a problem removing the article from search!',
    description:
      'Kite message is shown when an error ocurred removing an article from search',
  },
};
