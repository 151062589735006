import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmbeddedVideo from './EmbeddedVideo';
import { Body } from '../type';

class EmbeddedVideoTile extends Component {
  static baseClass = 'tk-embedded-video-tile';

  render() {
    return (
      <div className={`${EmbeddedVideoTile.baseClass}`}>
        <EmbeddedVideo {...this.props} />
        {this.props.title && (
          <div className={`${EmbeddedVideoTile.baseClass}__footer`}>
            <Body>{this.props.title}</Body>
          </div>
        )}
      </div>
    );
  }
}

EmbeddedVideoTile.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  hasHoverState: PropTypes.bool,
};

EmbeddedVideoTile.defaultProps = {
  title: '',
  hasHoverState: true,
};

export default EmbeddedVideoTile;
