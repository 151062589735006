export default {
  searchIDColumn: {
    id: 'SEARCH_TABLE_SEARCH_ID_COLUMN',
    defaultMessage: 'Search ID',
    description: 'Message for the search ID column in the search table',
  },
  defaultChartColorColumn: {
    id: 'SEARCH_TABLE_DEFAULT_CHART_COLOR_COLUMN',
    defaultMessage: 'Default Chart Color',
    description:
      'Message for the default chart color column in the search table',
  },
  spikeAlertsColumn: {
    id: 'SEARCH_TABLE_SPIKE_ALERTS_COLUMN',
    defaultMessage: 'Spike Alerts',
    description: 'Message for the spikes alert column in the search table',
  },
  spikeAlertsActive: {
    id: 'SEARCH_TABLE_SPIKE_ALERTS_ACTIVE',
    defaultMessage: 'Spike Alert Active',
    description: 'Message for the spikes alerts active state',
  },
  editSearchAction: {
    id: 'SEARCH_TABLE_EDIT_SEARCH_ACTION',
    defaultMessage: 'Edit',
    description: 'Message for the edit search action in the search table',
  },
  viewSearchAction: {
    id: 'SEARCH_TABLE_VIEW_SEARCH_ACTION',
    defaultMessage: 'View',
    description: 'Message for the view search action in the search table',
  },
  duplicateSearchAction: {
    id: 'SEARCH_TABLE_DUPLICATE_SEARCH_ACTION',
    defaultMessage: 'Duplicate',
    description: 'Message for the duplicate search action in the search table',
  },
  shareSearchAction: {
    id: 'SEARCH_TABLE_SHARE_SEARCH_ACTION',
    defaultMessage: 'Share',
    description: 'Message for the share search action in the search table',
  },
  turnSpikeAlertsOn: {
    id: 'SEARCH_TABLE_TURN_SPIKE_ALERTS_ON',
    defaultMessage: 'Turn Spike Alerts On',
    description:
      'Message for the turn spike alerts on action in the search table',
  },
  turnSpikeAlertsOff: {
    id: 'SEARCH_TABLE_TURN_SPIKE_ALERTS_OFF',
    defaultMessage: 'Turn Spike Alerts Off',
    description:
      'Message for the turn spike alerts off action in the search table',
  },
  editDefaultChartColorAction: {
    id: 'SEARCH_TABLE_EDIT_DEFAULT_CHART_COLOR_ACTION',
    defaultMessage: 'Edit Default Chart Color',
    description:
      'Message for the edit default chart color action in the search table',
  },
  addToCampaignAction: {
    id: 'SEARCH_TABLE_ADD_TO_CAMPAIGN_ACTION',
    defaultMessage: 'Add to Campaign',
    description: 'Message for the add to campaign action in the search table',
  },
  changePermissionsAction: {
    id: 'SEARCH_TABLE_CHANGE_PERMISSIONS_ACTION',
    defaultMessage: 'Change Permissions',
    description:
      'Message for the change permissions action in the search table',
  },
  archiveSearchAction: {
    id: 'SEARCH_TABLE_ARCHIVE_SEARCH_ACTION',
    defaultMessage: 'Archive',
    description: 'Message for the archive search action in the search table',
  },
  restoreSearchAction: {
    id: 'SEARCH_TABLE_RESTORE_SEARCH_ACTION',
    defaultMessage: 'Restore',
    description: 'Message for restore archived search in the search table',
  },
  seeHistoryAction: {
    id: 'SEARCH_TABLE_SEE_HISTORY_ACTION',
    defaultMessage: 'See History',
    description: 'Message for the see history action in the search table',
  },
  allCampaignsDefaultLabel: {
    id: 'SEARCH_TABLE_ALL_CAMPAIGNS_DEFAULT_LABEL',
    description: 'Default label for the All Campaigns label',
    defaultMessage: 'All Campaigns',
  },
  allCategoriesDefaultLabel: {
    id: 'SEARCH_TABLE_ALL_CATEGORIES_DEFAULT_LABEL',
    description: 'Default label for the All Categories label',
    defaultMessage: 'All Categories',
  },
  allSearches: {
    id: 'SEARCH_TABLE_ALL_SEARCHES',
    defaultMessage: 'All Searches',
    description: 'Label for all searches filter option',
  },
  mySearches: {
    id: 'SEARCH_TABLE_MY_SEARCHES',
    defaultMessage: 'My Searches',
    description: 'Label for the "my searches" filter option',
  },
  sharedWithMe: {
    id: 'SEARCH_TABLE_SHARED_WITH_ME',
    defaultMessage: 'Shared with me',
    description: 'Label for the shared with me filter option',
  },
  systemSearches: {
    id: 'SEARCH_TABLE_SYSTEM_SEARCHES',
    defaultMessage: 'System Searches:',
    description: 'Message for the system searches label in the search table',
  },
  findASearch: {
    id: 'SEARCH_TABLE_FIND_A_SEARCH',
    defaultMessage: 'Find a search',
    description: 'Message for the find a search placeholder',
  },
  searchesResults: {
    id: 'SEARCH_TABLE_SEARCHES_RESULTS',
    defaultMessage:
      'Showing {START_VALUE} - {END_VALUE} of {SEARCHES_AMOUNT} searches',
    description: 'Message for the searches results text',
  },
  idCopiedOnSuccess: {
    id: 'SEARCH_TABLE_ID_COPIED_SUCCESS',
    defaultMessage: 'ID copied to your clipboard.',
    description: 'kite message to indicate that the id was successfully copied',
  },
  idCopiedOnError: {
    id: 'SEARCH_TABLE_ID_COPIED_ERROR',
    defaultMessage: 'Failed to copy ID to your clipboard.',
    description:
      'kite message to indicate that there was an error while copying the search id',
  },
  selectACategory: {
    id: 'SEARCH_TABLE_SELECT_A_CATEGORY_OPTION',
    defaultMessage: 'Select a Category',
    description:
      'Coverage > Saved Searches > Category column: Select a category option displayed when the search does not have a category',
  },
  trashCanSearchAction: {
    id: 'SEARCH_TABLE_DELETE_SEARCH_ACTION',
    defaultMessage: 'Delete',
    description: 'Message for the delete search action in the search table',
  },
  unTrashCanSearchAction: {
    id: 'SEARCH_TABLE_DELETE_RESTORE_SEARCH_ACTION',
    defaultMessage: 'Restore',
    description: 'Message for restore deleted search in the search table',
  },
  deletedSearchBannerMessage: {
    id: 'DELETED_SEARCH_BANNER_MESSAGE',
    defaultMessage:
      'Items in Deleted Searches folder are permanently deleted after 30 days.',
    description: 'Message for deleted search banner',
  },
};
