export const storyStates = {
  draft: 'DRAFT',
  live: 'LIVE',
  paused: 'PAUSED',
};

export const pitchStage = {
  draft: 'DRAFT',
  opportunity: 'OPPORTUNITY',
  pitched: 'PITCHED',
  rejected: 'REJECTED',
  revoked: 'REVOKED',
  secured: 'SECURED',
};

export const recipientResponseTypes = {
  yes: 'YES',
  maybe: 'MAYBE',
  no: 'NO',
};

export const recipientResponseTimestampFormat = 'MMM D YYYY [at] h:mma';

export const MAXIMUM_RECIPIENTS = 200;
