/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';

import classNames from 'classnames';
import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { SvgIcon, Addon, Type, Button } from '@trendkite/ui';

import messages from './assets-library-modals.messages';
import styles from './browseModuleUpload.module.css';

const mediaLink = { position: 'absolute', left: '44px', top: '36px' };
const mediaLinkForUploadLink = {
  position: 'absolute',
  left: '19px',
  marginTop: '3px',
};
class MediaPopupLeftPanel extends React.Component {
  render() {
    const {
      isScreenForFileUpload,
      isScreenForBrowseFilesFromList,
      mediaLinkClicked,
      disclaimerFlag,
      mediaLinkUploadFromComputer,
      mediaLinkUploadFromUrl,
      showUploadOptionsFromEmail,
      showRestUploadOptionsFromLink,
    } = this.props.data;
    const { showOtherUploadOptions } = this.props;

    return (
      <div className={styles.leftPane} style={{ width: '25%' }}>
        <div>
          <div>
            <div
              className={classNames(styles.verticalTab, {
                [styles.verticalTabSelected]: mediaLinkClicked,
              })}
            >
              <div
                data-qa="ECkFFAQr0LUkpScipm-0A"
                className={styles.tabText}
                onClick={this.props.onMediaLinkClick}
              >
                <span className={styles.mediaIconFileUpload}>
                  <SvgIcon icon="image" width={20} height={20} />
                </span>
                <Type
                  weight={mediaLinkClicked ? 'bold' : 'light'}
                  color="dark"
                  style={mediaLink}
                >
                  <T {...messages.mediaStudioLink} />
                </Type>
              </div>
            </div>
            {(showUploadOptionsFromEmail ||
              showRestUploadOptionsFromLink ||
              showOtherUploadOptions) && (
              <div>
                <div
                  className={classNames(styles.verticalTab, {
                    [styles.verticalTabSelected]: mediaLinkUploadFromComputer,
                  })}
                >
                  <div
                    data-qa="hFw_0Ks-jfzhH5epWpIWZ"
                    className={styles.tabText}
                    onClick={this.props.onMediaFromComputer}
                  >
                    <span className={styles.mediaIconFileUpload}>
                      <SvgIcon icon="image" width={20} height={20} />
                    </span>
                    <Type
                      weight={mediaLinkUploadFromComputer ? 'bold' : 'light'}
                      color="dark"
                      style={mediaLinkForUploadLink}
                    >
                      <T {...messages.mediaUploadFromComputer} />
                    </Type>
                  </div>
                </div>
                <div
                  className={classNames(styles.verticalTab, {
                    [styles.verticalTabSelected]: mediaLinkUploadFromUrl,
                  })}
                >
                  <div
                    data-qa="3dpTLK8AIgUjTa-0SCNYe"
                    className={styles.tabText}
                    onClick={this.props.onMediaFromUrl}
                  >
                    <span className={styles.mediaIconFileUpload}>
                      <SvgIcon icon="image" width={20} height={20} />
                    </span>
                    <Type
                      weight={mediaLinkUploadFromUrl ? 'bold' : 'light'}
                      color="dark"
                      style={mediaLinkForUploadLink}
                    >
                      <T {...messages.mediaUploadFromUrl} />
                    </Type>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isScreenForFileUpload &&
            isScreenForBrowseFilesFromList &&
            !showUploadOptionsFromEmail && (
              <div className={styles.bottomInfoSectionWrapper}>
                <div className={styles.uploadFileInfo}>
                  <p>
                    <T {...messages.mediaStudioFileSupport} />:<br />{' '}
                    <T {...messages.mediaStudioFileSupportTypes} />
                  </p>
                </div>
                <div>
                  <Button
                    modifiers={['round', 'primary']}
                    disabled={disclaimerFlag}
                  >
                    <input
                      type="file"
                      name="file"
                      className={styles.leftPanelUploadBtnInsideLibrary}
                      onChange={e => this.props.onChange(e)}
                      multiple="multiple"
                    />
                    <Addon>
                      <SvgIcon
                        width={14}
                        height={14}
                        icon="upload"
                        className={styles.uploadIconForLeftPane}
                      />
                    </Addon>
                    <T {...messages.mediaStudioUploadFiles} />
                  </Button>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

MediaPopupLeftPanel.propTypes = {
  data: PropTypes.shape({
    isScreenForFileUpload: PropTypes.bool,
    isScreenForBrowseFilesFromList: PropTypes.bool,
    mediaLinkClicked: PropTypes.bool,
    disclaimerFlag: PropTypes.bool,
    mediaLinkUploadFromComputer: PropTypes.bool,
    mediaLinkUploadFromUrl: PropTypes.bool,
    showUploadOptionsFromEmail: PropTypes.bool,
    showRestUploadOptionsFromLink: PropTypes.bool,
  }),
  showOtherUploadOptions: PropTypes.bool,
  onMediaLinkClick: PropTypes.func,
  onMediaFromComputer: PropTypes.func,
  onMediaFromUrl: PropTypes.func,
  onChange: PropTypes.func,
};

MediaPopupLeftPanel.defaultProps = {
  data: {
    isScreenForFileUpload: false,
    isScreenForBrowseFilesFromList: true,
    mediaLinkClicked: false,
    disclaimerFlag: false,
    mediaLinkUploadFromComputer: false,
    mediaLinkUploadFromUrl: false,
    showUploadOptionsFromEmail: false,
    showRestUploadOptionsFromLink: false,
  },
  showOtherUploadOptions: false,
  onMediaLinkClick: () => {},
  onMediaFromComputer: () => {},
  onMediaFromUrl: () => {},
  onChange: () => {},
};

export default MediaPopupLeftPanel;
