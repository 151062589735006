export default {
  customDateRangeStringConnector: {
    id: 'CUSTOM_DATE_RANGE_STRING_CONNECTOR',
    description: 'Connector in text string in between two dates',
    defaultMessage: 'to',
  },
  widgetDateRangeChoose: {
    id: 'WIDGET_DATE_RANGE_CHOOSE',
    description: "Default label when trailing or custom isn't selected",
    defaultMessage: 'Choose',
  },
  widgetDateRangeTrailingSeven: {
    id: 'WIDGET_DATE_RANGE_TRAILING_7',
    description: 'label for when date range for widget is set for past 7 days',
    defaultMessage: 'Trailing 7 Days',
  },
  widgetDateRangeTrailingThirty: {
    id: 'WIDGET_DATE_RANGE_TRAILING_30',
    description: 'label for when date range for widget is set for past 30 days',
    defaultMessage: 'Trailing 30 Days',
  },
  widgetDateRangeTrailingNinety: {
    id: 'WIDGET_DATE_RANGE_TRAILING_90',
    description: 'label for when date range for widget is set for past 90 days',
    defaultMessage: 'Trailing 90 Days',
  },
  widgetCustomDateRange: {
    id: 'WIDGET_DATE_RANGE_CUSTOM',
    description: 'The label of the custom date range option',
    defaultMessage: 'Custom',
  },
  widgetVsPreviousYear: {
    id: 'WIDGET_DATE_RANGE_PREVIOUS_YEAR',
    description: 'label for when date range is being compated to previous year',
    defaultMessage: 'vs Previous Year',
  },
  widgetVsPreviousPeriod: {
    id: 'WIDGET_DATE_RANGE_PREVIOUS_PERIOD',
    description:
      'label for when date range is being compated to previous period',
    defaultMessage: 'vs Previous Period',
  },
  widgetVsCustomDateRange: {
    id: 'WIDGET_DATE_RANGE_VS_CUSTOM',
    description:
      'The label of the custom date range option in the date range compare dropdown for the date range section',
    defaultMessage: 'vs Custom',
  },
  widgetWordCloudDateRangeLimit: {
    id: 'WORDCLOUD_DATE_RANGE_LIMIT',
    description: 'The label of the word cloud date range limit',
    defaultMessage: 'Word Cloud queries are limited to a one year date range.',
  },
  widgetCustomDateRangeLimit: {
    id: 'WIDGET_DATE_RANGE_LIMIT',
    description: 'The label of the custom date range limit',
    defaultMessage: 'Queries are limited to a one year date range.',
  },
};
