import take from 'lodash/take';

export const returnScheduledDrafts = (drafts, query) => {
  // if filtering for unscheduled only, return empty array for scheduled drafts
  if (query.filters.draft) {
    return [];
  }

  let scheduledDraftResults = drafts.filter(draft => draft.schedule);

  // if filtering for scheduled only & there is a draft limit, apply limit
  if (!query.filters.scheduled && query.draftsLimit) {
    scheduledDraftResults = take(scheduledDraftResults, query.draftsLimit);
  }

  return scheduledDraftResults;
};

export const returnUnscheduledDrafts = (drafts, query) => {
  // if filtering for scheduled only, return empty array for unscheduled drafts
  if (query.filters.scheduled) {
    return [];
  }

  let unscheduledDraftResults = drafts.filter(draft => !draft.schedule);

  // if filtering for unscheduled only & there is a draft limit, apply limit
  if (!query.filters.draft && query.draftsLimit) {
    unscheduledDraftResults = take(unscheduledDraftResults, query.draftsLimit);
  }

  return unscheduledDraftResults;
};
