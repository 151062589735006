import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import qs from 'qs';

import {
  CONTACT_BASE_ENDPOINT,
  CONTACT_IMAGES_ENDPOINT,
  CONTACT_LISTS_ENDPOINT,
  CONTACT_NOTE_BASE_ENDPOINT,
  CONTACT_NOTES_ENDPOINT,
  CONTACT_SOCIAL_HISTORY_ENDPOINT,
  CONTACT_SOCIAL_TIMELINE_ENDPOINT,
} from 'constants/apis';
import * as constants from 'constants/constants';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
} from 'reducers/page-messages';
import { userHasFeatureFlag } from 'services/feature-service/feature-service';
import {
  performGet,
  performPost,
  performPut,
  performDelete,
} from 'services/rest-service/rest-service';

import messages from '../../pages/Stories/StoryHub/StoryHubRecipientsContainer.messages';

export const CONTACTS_BY_TOPICS_LIST = 'contactsByTopicsList';

export const ADD_CONTACTS = 'contacts/ADD_CONTACTS';
export const ADD_CONTACT_TO_LIST = 'contacts/ADD_CONTACT_TO_LIST';
export const ADD_CONTACT_TO_LIST_RECEIVED =
  'contacts/ADD_CONTACT_TO_LIST_RECEIVED';
export const ADD_CONTACT_TO_LIST_ERROR = 'contacts/ADD_CONTACT_TO_LIST_ERROR';
export const GET_CONTACT = 'contacts/GET_CONTACT';
export const GET_CONTACT_RECEIVED = 'contacts/GET_CONTACT_RECEIVED';
export const GET_CONTACT_ERROR = 'contacts/GET_CONTACT_ERROR';
export const GET_CONTACT_IMAGE = 'contacts/GET_CONTACT_IMAGE';
export const GET_CONTACT_IMAGE_RECEIVED = 'contacts/GET_CONTACT_IMAGE_RECEIVED';
export const GET_CONTACT_IMAGE_ERROR = 'contacts/GET_CONTACT_IMAGE_ERROR';
export const GET_CONTACT_NOTES = 'contacts/GET_CONTACT_NOTES';
export const GET_CONTACT_NOTES_ERROR = 'contacts/GET_CONTACT_NOTES_ERROR';
export const CONTACT_NOTES_LOADING = 'contacts/GET_CONTACT_NOTES_LOADING';
export const GET_CONTACT_SOCIAL_STREAM = 'contacts/GET_CONTACT_SOCIAL_STREAM';
export const GET_CONTACT_SOCIAL_STREAM_RECEIVED =
  'contacts/GET_CONTACT_SOCIAL_STREAM_RECEIVED';
export const GET_CONTACT_SOCIAL_STREAM_ERROR =
  'contacts/GET_CONTACT_SOCIAL_STREAM_ERROR';
export const GET_CONTACT_SOCIAL_STREAM_EMPTY_LIST =
  'contacts/GET_CONTACT_SOCIAL_STREAM_EMPTY_LIST';
export const GET_CONTACT_TOPICS = 'contacts/GET_CONTACT_TOPICS';
export const GET_CONTACT_TOPICS_RECEIVED =
  'contacts/GET_CONTACT_TOPICS_RECEIVED';
export const GET_CONTACT_TOPICS_ERROR = 'contacts/GET_CONTACT_TOPICS_ERROR';
export const GET_CONTACTS_BY_TOPICS = 'contacts/GET_CONTACTS_BY_TOPICS';
export const GET_CONTACTS_BY_TOPICS_RECEIVED =
  'contacts/GET_CONTACTS_BY_TOPICS_RECEIVED';
export const GET_CONTACTS_BY_TOPICS_ERROR =
  'contacts/GET_CONTACTS_BY_TOPICS_ERROR';
export const UPDATE_CONTACT_NOTE = 'contacts/UPDATE_CONTACT_NOTE';
export const UPDATE_CONTACT_NOTE_ERROR = 'contacts/UPDATE_CONTACT_NOTE_ERROR';
export const ADD_CONTACT_NOTE = 'contacts/ADD_CONTACT_NOTE';
export const ADD_CONTACT_NOTE_ERROR = 'contacts/ADD_CONTACT_NOTE_ERROR';
export const DELETE_CONTACT_NOTE = 'contacts/DELETE_CONTACT_NOTE';
export const DELETE_CONTACT_NOTE_ERROR = 'contacts/DELETE_CONTACT_NOTE_ERROR';
export const SET_RELEVANT_ONLY = 'contacts/SET_RELEVANT_ONLY';
export const TOGGLE_RELEVANT_ONLY_FILTER =
  'contacts/TOGGLE_RELEVANT_ONLY_FILTER';
export const UNSET_CURRENT_CONTACT = 'contacts/UNSET_CURRENT_CONTACT';
export const UPDATE_CONTACT_ARTICLES = 'contacts/UPDATE_CONTACT_ARTICLES';
export const UPDATE_CONTACT_LISTS = 'contacts/UPDATE_CONTACT_LISTS';
export const UPDATE_RELATIONSHIP_STATUS = 'contacts/UPDATE_RELATIONSHIP_STATUS';
export const UPDATE_RELATIONSHIP_STATUS_SUCCESS =
  'contacts/UPDATE_RELATIONSHIP_STATUS_SUCCESS';
export const UPDATE_RELATIONSHIP_STATUS_ERROR =
  'contacts/UPDATE_RELATIONSHIP_STATUS_ERROR';

const createMediaObj = () => ({
  loading: false,
  error: false,
  isLoadingMore: false,
  data: {},
  filters: {
    limit: constants.AUTHOR_SOCIAL_TAB_ITEMS_PER_REQUEST,
  },
  nextPageToken: '',
  prevPageToken: '',
});

const initialState = {
  loading: true,
  current: null,
  error: null,
  contacts: {},
  relevantOnly: true,
  socialStream: {
    youtube: createMediaObj(),
    twitter: createMediaObj(),
  },
  notes: [],
  loadingRelationshipStatus: false,
  topics: {
    loading: true,
    error: false,
    data: [],
  },
  notesHits: 0,
  AreAuthorNotesLoading: false,
};

const initialContact = {
  id: -1,
  favorited: false,
  fullName: '',
  publications: [],
  image: {
    id: '',
    url: '',
    loading: true,
  },
  lists: [],
  priority: 0,
  requested: false,
  loading: true,
  error: false,
};

const buildRequestBodyForImages = author => {
  if (author.id && author.twitterHandles && author.twitterHandles.length) {
    return {
      authors: [
        {
          id: author.id,
          twitterUsername: author.twitterHandles[0], // there may be multiple twitter handles, so use the first
        },
      ],
    };
  }

  if (author.id) {
    return { authors: [{ id: author.id }] };
  }

  return { authors: [] };
};

const sortSocialItems = (items, criteria = 'ASCENDING') => {
  const sortedList = items.sort((a, b) => {
    if (a.published_at > b.published_at) {
      return 1;
    }

    if (a.published_at < b.published_at) {
      return -1;
    }
    return 0;
  });
  if (criteria === 'DESCENDING') {
    return sortedList.reverse();
  }
  return sortedList;
};

const contactsReducer = (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case GET_CONTACT_NOTES: {
      return {
        ...state,
        notes: action.payload.notes,
        notesHits: action.payload.noteHits,
        AreAuthorNotesLoading: false,
      };
    }
    case GET_CONTACT_NOTES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        AreAuthorNotesLoading: false,
      };
    }
    case CONTACT_NOTES_LOADING: {
      return {
        ...state,
        AreAuthorNotesLoading: true,
      };
    }
    case GET_CONTACT_SOCIAL_STREAM: {
      const isLoadingMore = !!action.payload.isLoadingMore;
      return {
        ...state,
        socialStream: {
          ...state.socialStream,
          [action.payload.type]: {
            ...state.socialStream[action.payload.type],
            loading: !isLoadingMore,
            error: false,
            isLoadingMore,
          },
        },
      };
    }
    case GET_CONTACT_SOCIAL_STREAM_ERROR: {
      return {
        ...state,
        socialStream: {
          ...state.socialStream,
          [action.payload.type]: {
            ...state.socialStream[action.payload.type],
            filters: action.payload.filters || {
              ...initialState.socialStream[action.payload.type].filters,
            },
            loading: false,
            error: false,
            isLoadingMore: false,
          },
        },
      };
    }
    case GET_CONTACT_SOCIAL_STREAM_RECEIVED: {
      return {
        ...state,
        socialStream: {
          ...state.socialStream,
          [action.payload.type]: {
            ...state.socialStream[action.payload.type],
            loading: false,
            error: false,
            isLoadingMore: false,
            data: action.payload.data,
            nextPageToken: action.payload.nextPageToken,
            prevPageToken: action.payload.prevPageToken,
            filters: action.payload.filters || {
              ...initialState.socialStream[action.payload.type].filters,
            },
          },
        },
      };
    }
    case GET_CONTACT_SOCIAL_STREAM_EMPTY_LIST: {
      return {
        ...state,
        socialStream: {
          ...state.socialStream,
          [action.payload.type]: {
            filters: action.payload.filters || {
              ...initialState.socialStream[action.payload.type].filters,
            },
            ...state.socialStream[action.payload.type],
            loading: false,
            error: false,
            isLoadingMore: false,
            nextPageToken: action.payload.nextPageToken,
          },
        },
      };
    }
    case GET_CONTACT_TOPICS: {
      return {
        ...state,
        topics: {
          loading: true,
          error: false,
          topics: [],
        },
      };
    }
    case GET_CONTACT_TOPICS_RECEIVED: {
      return {
        ...state,
        topics: {
          loading: false,
          error: false,
          data: action.payload,
        },
      };
    }
    case GET_CONTACT_TOPICS_ERROR: {
      return {
        ...state,
        topics: {
          loading: false,
          error: true,
        },
      };
    }
    case GET_CONTACTS_BY_TOPICS: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case GET_CONTACTS_BY_TOPICS_RECEIVED: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case GET_CONTACTS_BY_TOPICS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case SET_RELEVANT_ONLY: {
      return { ...state, relevantOnly: action.payload };
    }
    case TOGGLE_RELEVANT_ONLY_FILTER: {
      return { ...state, relevantOnly: !state.relevantOnly };
    }
    case ADD_CONTACTS: {
      const contacts = { ...state.contacts };
      const newContacts = keyBy(action.payload.contacts, contact => contact.id);
      Object.keys(newContacts).forEach(newContactId => {
        contacts[newContactId] = newContacts[newContactId];
      });

      return {
        ...state,
        contacts,
      };
    }
    case GET_CONTACT:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.id]: {
            ...action.payload,
            loading: true,
          },
        },
        current: action.payload.id,
      };
    case GET_CONTACT_ERROR:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.id]: {
            ...state.contacts[action.payload.id],
            loading: false,
            error: true,
          },
        },
      };
    case GET_CONTACT_RECEIVED:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.id]: {
            ...state.contacts[action.payload.id],
            ...action.payload,
            loading: false,
            error: false,
          },
        },
        current: action.payload.id,
      };
    case GET_CONTACT_IMAGE:
      newState = {
        ...state,
      };
      newState.contacts[newState.current].image.loading = true;
      return newState;
    case GET_CONTACT_IMAGE_RECEIVED: {
      newState = {
        ...state,
      };
      const currentContact = { ...newState.contacts[newState.current] };
      currentContact.image = action.image;
      newState.contacts[newState.current] = currentContact;
      return newState;
    }
    case GET_CONTACT_IMAGE_ERROR:
      newState = {
        ...state,
        contacts: {
          ...state.contacts,
          [state.current]: {
            ...state.contacts[state.current],
            image: {
              ...state.contacts[state.current].image,
              loading: false,
            },
          },
        },
      };
      return newState;
    case UPDATE_CONTACT_LISTS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.contactId]: {
            ...state.contacts[action.payload.contactId],
            authorListReferences: action.payload.lists,
            listsLoading: false,
          },
        },
      };
    case UPDATE_CONTACT_ARTICLES:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.contactId]: {
            ...state.contacts[action.payload.contactId],
            articleIds: action.payload.articleIds,
          },
        },
      };
    case UNSET_CURRENT_CONTACT:
      return {
        ...state,
        current: null,
      };
    case ADD_CONTACT_TO_LIST:
      return {
        ...state,
        loading: true,
      };
    case ADD_CONTACT_TO_LIST_RECEIVED:
      return {
        ...state,
        loading: false,
      };
    case ADD_CONTACT_TO_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_RELATIONSHIP_STATUS:
      return {
        ...state,
        loadingRelationshipStatus: true,
      };
    case UPDATE_RELATIONSHIP_STATUS_SUCCESS:
      return {
        ...state,
        loadingRelationshipStatus: false,
        contacts: {
          ...state.contacts,
          [action.payload.contactId]: {
            ...state.contacts[action.payload.contactId],
            relationshipStatus: action.payload.status,
          },
        },
      };
    case UPDATE_RELATIONSHIP_STATUS_ERROR:
      return {
        ...state,
        loadingRelationshipStatus: false,
      };
    default:
      return state;
  }
};

export const addContacts = contacts => ({
  type: ADD_CONTACTS,
  payload: { contacts },
});

export const addContactToList = (contactId, listId) => dispatch => {
  dispatch({ type: ADD_CONTACT_TO_LIST });
  performPut(`${CONTACT_LISTS_ENDPOINT}/${listId}/authors`, {
    authorIds: [contactId],
  })
    .then(() => {
      dispatch({ type: ADD_CONTACT_TO_LIST_RECEIVED });
    })
    .catch(error => {
      dispatch({ type: ADD_CONTACT_TO_LIST_ERROR, payload: { error } });
      throw error;
    });
};

export const getContactById = (
  id,
  defaultName = '',
  priority = 0,
) => dispatch => {
  let contact = {
    ...initialContact,
    id,
    fullName: defaultName || '',
  };
  const priorityParam = priority > 0 ? `?priority=${priority}` : '';

  dispatch({ type: GET_CONTACT, payload: contact });

  performGet(`${CONTACT_BASE_ENDPOINT}/${id}${priorityParam}`)
    .then(contactResponse => {
      contact = {
        ...contact,
        ...contactResponse.data,
        requested:
          contactResponse.data.contacts.length === 0 &&
          (contactResponse.data.priority ===
            constants.CONTACTS_PRIORITIES.addToList ||
            priority === constants.CONTACTS_PRIORITIES.addToList ||
            contactResponse.data.priority ===
              constants.CONTACTS_PRIORITIES.authorCard ||
            priority === constants.CONTACTS_PRIORITIES.authorCard),
        loading: false,
      };

      dispatch({ type: GET_CONTACT_RECEIVED, payload: contact });
      return contactResponse;
    })
    .then(contactResponse => {
      dispatch({ type: GET_CONTACT_IMAGE });

      performPost(
        CONTACT_IMAGES_ENDPOINT,
        buildRequestBodyForImages(contactResponse.data),
      )
        .then(imageResponse => {
          const image = {
            id,
            url: '',
            loading: false,
          };

          if (
            Array.isArray(imageResponse.data) &&
            imageResponse.data.length &&
            imageResponse.data[0].imageUrl
          ) {
            image.url = imageResponse.data[0].imageUrl;
          }

          dispatch({ type: GET_CONTACT_IMAGE_RECEIVED, image });
        })
        .catch(() => {
          dispatch({ type: GET_CONTACT_IMAGE_ERROR });
        });

      if (userHasFeatureFlag(constants.FEATURES.pinpointContacts)) {
        performPost(`${CONTACT_LISTS_ENDPOINT}/membership/`, {
          authorIds: [id],
        })
          .then(membershipResponse => {
            let lists = [];

            if (membershipResponse.data && membershipResponse.data[id]) {
              lists = membershipResponse.data[id];
            }

            dispatch({
              type: UPDATE_CONTACT_LISTS,
              payload: {
                contactId: id,
                lists,
              },
            });
          })
          .catch(error => {
            const lists = [];

            dispatch({
              type: UPDATE_CONTACT_LISTS,
              payload: {
                contactId: id,
                lists,
              },
            });

            throw error;
          });
      }
    })
    .catch(error => {
      dispatch({ type: GET_CONTACT_ERROR, payload: { error, id } });
      dispatch({ type: GET_CONTACT_IMAGE_ERROR });
      throw error;
    });
};

export const getContactNotes = (
  contactId,
  visibility,
  order,
  pageNum,
) => dispatch => {
  dispatch({ type: CONTACT_NOTES_LOADING, payload: {} });
  performGet(
    `${CONTACT_NOTE_BASE_ENDPOINT}/${contactId}${CONTACT_NOTES_ENDPOINT}?visibility=${visibility}&order=${order}&page=${pageNum}`,
    {},
  )
    .then(response => {
      const notes = response.data.notes;
      dispatch({
        type: GET_CONTACT_NOTES,
        payload: { notes, noteHits: response.data.hits },
      });
    })
    .catch(error => {
      dispatch({ type: GET_CONTACT_NOTES_ERROR, payload: { error } });
      throw error;
    });
};

export const getContactSocialStream = (type, filters) => dispatch => {
  if (!filters && !filters.mediaId) {
    dispatch({
      type: GET_CONTACT_SOCIAL_STREAM_ERROR,
      payload: { type, filters },
    });
  }

  let urlBase;
  let mediaId;
  let startDate;
  let endDate;
  let pageToken = '';
  let limit = constants.AUTHOR_SOCIAL_TAB_ITEMS_PER_REQUEST;

  if (type === 'twitter') {
    urlBase = CONTACT_SOCIAL_TIMELINE_ENDPOINT;
    mediaId = `user=${filters && filters.mediaId}`;
  } else if (type === 'youtube') {
    urlBase = CONTACT_SOCIAL_HISTORY_ENDPOINT;
    mediaId = `channelId=${filters && filters.mediaId}`;
  }

  if (filters.startDate && filters.endDate) {
    startDate = `&startDate=${filters.startDate}`;
    endDate = `&endDate=${filters.endDate}`;
  }

  if (filters.nextPageToken) {
    pageToken = `&pageToken=${filters.nextPageToken}`;
  }

  if (filters.prevPageToken) {
    pageToken = `&pageToken=${filters.prevPageToken}`;
  }

  if (filters.limit) {
    limit = filters.limit;
  }

  dispatch({
    type: GET_CONTACT_SOCIAL_STREAM,
    payload: { type, isLoadingMore: !!filters && !!filters.isLoadingMore },
  });

  performGet(
    `${urlBase}/?site=${type}&${mediaId}${startDate}${endDate}&limit=${limit}${pageToken}`,
  )
    .then(response => {
      const data = response.data.data;
      if (type === 'youtube') {
        if (!data.items || (isEmpty(data.items) && filters.isLoadingMore)) {
          dispatch({
            type: GET_CONTACT_SOCIAL_STREAM_EMPTY_LIST,
            payload: { type, filters, nextPageToken: null },
          });
          return;
        }
        if (filters.order) {
          if (type === 'youtube') {
            data.items = sortSocialItems(data.items, filters.order);
          }
        }
      }
      dispatch({
        type: GET_CONTACT_SOCIAL_STREAM_RECEIVED,
        payload: {
          type,
          filters,
          isLoadingMore: filters.isLoadingMore || false,
          nextPageToken: data.next_page_token || null,
          prevPageToken: data.prev_page_token || null,
          data,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_CONTACT_SOCIAL_STREAM_ERROR,
        payload: { error, filters },
      });
    });
};

export const newContactNote = (
  contactId,
  note,
  showShared,
  order,
) => async dispatch => {
  const url = `${CONTACT_NOTE_BASE_ENDPOINT}/${contactId}${CONTACT_NOTES_ENDPOINT}`;

  try {
    await performPost(url, { ...note });
    dispatch({ type: ADD_CONTACT_NOTE });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'All set. Your note has been added.',
        status: 'success',
        ttl: 5000,
      }),
    );
    dispatch(getContactNotes(contactId, showShared, order, 0));
  } catch (e) {
    dispatch({ type: ADD_CONTACT_NOTE_ERROR });
  }

  return dispatch(getContactNotes(contactId, showShared, order));
};

export const deleteContactNote = (
  contactId,
  note,
  showShared,
  order,
) => async dispatch => {
  const url = `${CONTACT_NOTE_BASE_ENDPOINT}/${contactId}${CONTACT_NOTES_ENDPOINT}/${note.id}`;

  try {
    await performDelete(url);
    dispatch({ type: DELETE_CONTACT_NOTE });
  } catch (e) {
    dispatch({ DELETE_CONTACT_NOTE_ERROR });
  }

  return dispatch(getContactNotes(contactId, showShared, order));
};

export const updateContactNote = (
  contactId,
  note,
  showShared,
  order,
) => async dispatch => {
  const url = `${CONTACT_NOTE_BASE_ENDPOINT}/${contactId}${CONTACT_NOTES_ENDPOINT}/${note.id}`;

  try {
    await performPut(url, { ...note });
    dispatch({ type: UPDATE_CONTACT_NOTE });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'All set. Your note has been updated.',
        status: 'success',
        ttl: 5000,
      }),
    );
  } catch (e) {
    dispatch({ type: UPDATE_CONTACT_NOTE_ERROR });
  }

  return dispatch(getContactNotes(contactId, showShared, order));
};

export const updateContactRelationshipStatus = (
  contactId,
  status,
  intl,
) => async dispatch => {
  dispatch({ type: UPDATE_RELATIONSHIP_STATUS });

  try {
    await performPut(`/api/authors/${contactId}/relationship-status`, {
      relationshipStatus: status,
      authorId: contactId,
    });
    dispatch({
      type: UPDATE_RELATIONSHIP_STATUS_SUCCESS,
      payload: { contactId, status },
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.setRelationshipStatusSuccess),
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({ type: UPDATE_RELATIONSHIP_STATUS_ERROR });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.setRelationshipStatusFailed),
        status: 'danger',
      }),
    );
  }
};

export const updateBulkRelationshipStatus = (
  authorIds,
  relationshipStatus,
  intl,
) => async dispatch => {
  dispatch({ type: UPDATE_RELATIONSHIP_STATUS });

  try {
    await performPut('/api/authors/relationship-status', {
      authorIds,
      relationshipStatus,
    });
    dispatch({
      type: UPDATE_RELATIONSHIP_STATUS_SUCCESS,
      payload: { authorIds, relationshipStatus },
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.setRelationshipStatusSuccess),
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({ type: UPDATE_RELATIONSHIP_STATUS_ERROR });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(messages.setRelationshipStatusFailed),
        status: 'danger',
      }),
    );
  }
};

export const setRelevantOnly = relevantOnly => dispatch =>
  dispatch({ type: SET_RELEVANT_ONLY, payload: relevantOnly });

export const toggleRelevantFilter = () => ({
  type: TOGGLE_RELEVANT_ONLY_FILTER,
  payload: {},
});

export const unsetCurrentContact = () => dispatch =>
  dispatch({ type: UNSET_CURRENT_CONTACT });

export const getAuthorProfilesByIds = async ({ authorIds }) => {
  let response = await performGet(
    `${CONTACT_BASE_ENDPOINT}?${qs.stringify(
      { ids: authorIds },
      { indices: false },
    )}`,
  );
  response = Array.isArray(response.data) ? response.data : null;

  if (!response) {
    throw new Error("Couldn't get recipients.");
  }

  return response;
};

export default contactsReducer;
