export default {
  error: {
    id: 'WORKSPACES_APP_SRC_COMPONENTS_OMNI_BAR_ERROR',
    description: 'Error message when a search fails',
    defaultMessage: 'Something went wrong.',
  },
  placeholder: {
    id: 'WORKSPACES_APP_SRC_COMPONENTS_OMNI_BAR_PLACEHOLDER',
    description: 'Placeholder text for search input',
    defaultMessage: 'Search',
  },
};
