import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { IntlPropType } from 'trendkite-front-end-app/src/pages/Influencers/common-prop-types';

import { isValidEmail } from '../../validators';
import withModifiers from '../../withModifiers';
import ListControl from '../list-control';
import ListControlDefaultItem from '../list-control-default-item';

import messages from './EmailListControl.messages';

const defaultProps = {
  autoComplete: true,
  className: '',
  disabled: false,
  inputTextDisabled: false,
  max: null,
  min: 0,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  placeholder: 'Add emails separated by commas',
  field: '',
  values: [],
  verifiedEmailAddress: '',
  fromValues: [],
  senderNameValues: [],
  setFromWithSenderName: () => {},
  isFromOutreachComposeForm: false,
};
class EmailListControl extends Component {
  static baseClass = 'tk-email-list-control';

  state = {
    isInvalidEmail: { valid: false, email: '' },
  };

  handleClearEmail = () => {
    const newValidEmail = { valid: false, email: '' };
    this.setState({ isInvalidEmail: newValidEmail });
  };

  handleFieldErrors = emailNotCompleteDetails => {
    if (!this.props?.setFieldErrors) {
      return;
    }

    const { field = '', fieldErrors, setFieldErrors } = this.props;
    const fieldErrorExists = fieldErrors?.includes(field);

    if (emailNotCompleteDetails.emailNotComplete) {
      if (!fieldErrorExists) {
        setFieldErrors([...fieldErrors, field]);
      }
    } else {
      if (fieldErrorExists) {
        setFieldErrors([
          ...fieldErrors.filter(fieldError => fieldError !== field),
        ]);
      }
    }
  };

  onChange = genericValues => {
    this.props.onChange(genericValues.map(value => value.data));
  };

  renderEmailComponent = props => {
    const onClick = this.props.isFromOutreachComposeForm ? null : undefined;

    return (
      <ListControlDefaultItem
        {...props}
        data-qa="emailList"
        onClick={onClick}
        value={{
          ...props.value,
          label: props.value.label
            ? `${props.value.label} (${props.value.id})`
            : undefined,
        }}
      />
    );
  };

  getGenericValueFromEmailAndName = value => {
    return {
      id: value.email,
      label: value.name,
      data: { ...value },
    };
  };

  parseFullNameFromString = prevValueString => {
    const emptyNameField = '';
    const valueIncludesInvalidCharacters = ['<', '>', '@'].some(char =>
      prevValueString?.includes(char),
    );
    const invalidName =
      isValidEmail(prevValueString) || valueIncludesInvalidCharacters;

    if (!invalidName) {
      const formattedName = this.getGenericValueFromEmailAndName({
        email: prevValueString,
        name: emptyNameField,
      });
      return formattedName;
    } else {
      const newValidName = {
        valid: false,
        email: prevValueString,
      };
      this.setState({ isInvalidEmail: newValidName });
    }
    return null;
  };

  parseEmailAndNameFromString = prevValueString => {
    /**
      Takes a string like
        * bob@example.com
        * Bob Example <bob@example.com>
        * “Bobo” <bob@example.com>

      And turns it into an object like:
      ```
      {
        id: 'bob@example.com',
        label: 'Bobo',
        data: {
          email: 'bob@example.com',
          name: 'Bobo',
        }
      }
      ```
    */
    let email = '';
    let name = '';

    const openBracket = prevValueString.indexOf('<');
    const closeBracket =
      prevValueString.substring(openBracket).indexOf('>') + openBracket;

    if (openBracket >= 0 && closeBracket > openBracket) {
      email = prevValueString.substring(openBracket + 1, closeBracket).trim();
      name = prevValueString.substring(0, openBracket).trim();

      if (
        name.indexOf('“') === 0 &&
        name.lastIndexOf('”') === name.length - 1
      ) {
        name = name.substring(1, name.length - 1).trim();
      }
    } else {
      email = prevValueString.trim();
    }

    //if the text to parse is just a set of brackets (Bug reported in EVER-14932), set email string to the original text
    //so that it's properly validated instead of validating empty email string and rendering an empty pill
    if (openBracket > -1 && closeBracket > -1 && !email) {
      email = prevValueString.trim();
    }

    const newValidEmail = { valid: isValidEmail(email), email };
    this.setState({ isInvalidEmail: newValidEmail });

    if (isValidEmail(email)) {
      return this.getGenericValueFromEmailAndName({ email, name });
    }

    return null;
  };

  render() {
    const {
      className,
      values,
      field,
      verifiedEmailAddress,
      fromValues,
      setFromWithSenderName,
      senderNameValues,
      ...otherProps
    } = this.props;

    const mainClass = classNames(EmailListControl.baseClass, className);
    const isFrom = field === 'from';

    const genericValues = values.map(contact =>
      this.getGenericValueFromEmailAndName(contact),
    );

    const { email, valid } = this.state.isInvalidEmail;

    const emailNotCompleteDetails = {
      emailNotComplete: email.length > 0 && !valid,
      email: email,
    };
    this.handleFieldErrors(emailNotCompleteDetails);

    const unsetVerifiedEmailAddressState =
      this.props.unsetVerifiedEmailAddressState ?? undefined;

    const handleDropdownToggle = this.props.handleDropdownToggle;

    const parseListItemString =
      field === 'senderName'
        ? this.parseFullNameFromString
        : this.parseEmailAndNameFromString;

    const defaultFocusedField = field === 'senderName' ?? field === 'replyTo';

    return (
      <div
        className={mainClass}
        style={
          isFrom
            ? {
                width: '95%',
                display: 'flex',
                flexDirection: 'row',
              }
            : {}
        }
      >
        <ListControl
          {...otherProps}
          field={field}
          placeholder={
            defaultProps.placeholder === otherProps?.placeholder
              ? this.props.intl.formatMessage(messages.emailListPlaceholder)
              : otherProps?.placeholder
          }
          CustomListItemComponent={this.renderEmailComponent}
          customParseListItem={parseListItemString}
          onChange={this.onChange}
          isFocused={defaultFocusedField}
          style={
            isFrom
              ? {
                  maxHeight: '98px',
                  flexWrap: 'wrap',
                  overflowX: 'hidden',
                  background: 'transparent',
                }
              : { maxHeight: '98px', flexWrap: 'wrap', overflowX: 'hidden' }
          }
          values={genericValues}
          emailNotCompleteDetails={emailNotCompleteDetails}
          handleClearEmail={this.handleClearEmail}
          verifiedEmailAddress={isFrom ? verifiedEmailAddress : ''}
          unsetVerifiedEmailAddressState={unsetVerifiedEmailAddressState}
          handleDropdownToggle={handleDropdownToggle}
          fromValues={fromValues}
          setFromWithSenderName={setFromWithSenderName}
          senderNameValues={senderNameValues}
        />
      </div>
    );
  }
}
EmailListControl.propTypes = {
  autoComplete: PropTypes.bool,
  /** Additional class/es to add to the control */
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputTextDisabled: PropTypes.bool,
  /** Max number of items in the list */
  max: PropTypes.number,
  /** Min number of items in the list */
  min: PropTypes.number,
  onBlur: PropTypes.func,
  /** The top-level onChange only fires when a list item is added / removed */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  /** An array of objects, each of which has a unique 'email' and an optional 'name' */
  values: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  intl: IntlPropType.isRequired,
  field: PropTypes.string.isRequired,
  setFieldErrors: PropTypes.func,
  fieldErrors: PropTypes.array,
  verifiedEmailAddress: PropTypes.string,
  unsetVerifiedEmailAddressState: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.undefined,
  ]),
  handleDropdownToggle: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.undefined,
  ]),
  fromValues: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  setFromWithSenderName: PropTypes.func,
  senderNameValues: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  isFromOutreachComposeForm: PropTypes.bool,
};

EmailListControl.defaultProps = defaultProps;

export default withModifiers(injectIntl(EmailListControl));
