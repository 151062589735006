import { EARNED_SEARCHES_FILTERS_OPERATORS } from 'constants/constants';
import FiltersActions from 'pages/EarnedSearch/state/actions/filtersActions';

import { ActionType, Filter, FiltersState } from '../types/filters';

export const FiltersInitialState: Filter = {
  operator: EARNED_SEARCHES_FILTERS_OPERATORS.is,
  values: [],
};

export const MEDIA_TYPE_KEY = 'mediaType';
export const CONTENT_TYPE_KEY = 'contentType';
export const REGION_KEY = 'region';
export const LOCATION_KEY = 'location';
export const LANGUAGE_KEY = 'language';
export const LOCATION_KEY_V2 = 'locationV2';
export const LANGUAGE_KEY_V2 = 'languageV2';

const initialState: FiltersState = {
  [MEDIA_TYPE_KEY]: { ...FiltersInitialState },
  [CONTENT_TYPE_KEY]: { ...FiltersInitialState },
  [REGION_KEY]: { ...FiltersInitialState },
  [LOCATION_KEY]: { ...FiltersInitialState },
  [LOCATION_KEY_V2]: { ...FiltersInitialState },
  [LANGUAGE_KEY]: { ...FiltersInitialState },
  [LANGUAGE_KEY_V2]: { ...FiltersInitialState },
  isLoading: false,
  filterLoading: '',
  error: '',
};

function filtersReducer(
  state: FiltersState = initialState,
  action: ActionType,
): FiltersState {
  const { payload, type } = action;

  switch (type) {
    case FiltersActions.SET_MODAL_ERROR:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
      };

    case FiltersActions.RESET_MODAL_ERROR:
      return {
        ...state,
        error: '',
      };

    case FiltersActions.SET_FILTER_VALUES:
      return {
        ...state,
        [payload.filterType]: payload.filterValues,
        isLoading: false,
      };
    case FiltersActions.SET_MODAL_LOADING_STATUS:
      return {
        ...state,
        isLoading: payload.status,
        filterLoading: payload.filterType,
      };

    case FiltersActions.RESET_FILTERS:
      return {
        ...initialState,
      };

    case FiltersActions.SET_LANGUAGE_FILTER:
      return {
        ...state,
        [LANGUAGE_KEY]: { ...payload.language },
        [LANGUAGE_KEY_V2]: { ...payload.language },
      };
    case FiltersActions.SET_LANGUAGE_V2_FILTER:
      return {
        ...state,
        [LANGUAGE_KEY_V2]: { ...payload },
      };

    case FiltersActions.SET_LOCATION_V2_FILTER:
      return {
        ...state,
        [LOCATION_KEY_V2]: { ...payload },
      };

    case FiltersActions.RESET_LANGUAGE_FILTER:
      return {
        ...state,
        [LANGUAGE_KEY]: { ...FiltersInitialState },
        [LANGUAGE_KEY_V2]: { ...FiltersInitialState },
      };

    case FiltersActions.SET_CONTENT_TYPE_FILTER:
      return {
        ...state,
        [CONTENT_TYPE_KEY]: { ...payload.contentType },
      };

    case FiltersActions.RESET_CONTENT_TYPE_FILTER:
      return {
        ...state,
        [CONTENT_TYPE_KEY]: { ...FiltersInitialState },
      };

    case FiltersActions.SET_MEDIA_TYPE_FILTER:
      return {
        ...state,
        [MEDIA_TYPE_KEY]: { ...payload.mediaType },
      };

    case FiltersActions.RESET_MEDIA_TYPE_FILTER:
      return {
        ...state,
        [MEDIA_TYPE_KEY]: { ...FiltersInitialState },
      };

    case FiltersActions.SET_REGION_FILTER:
      return {
        ...state,
        [REGION_KEY]: { ...payload.region },
      };

    case FiltersActions.RESET_REGION_FILTER:
      return {
        ...state,
        [REGION_KEY]: { ...FiltersInitialState },
      };

    case FiltersActions.SET_LOCATION_FILTER:
      return {
        ...state,
        [LOCATION_KEY]: { ...payload.location },
        [LOCATION_KEY_V2]: { ...payload.location },
      };

    case FiltersActions.RESET_LOCATION_FILTER:
      return {
        ...state,
        [LOCATION_KEY]: { ...FiltersInitialState },
        [LOCATION_KEY_V2]: { ...FiltersInitialState },
      };

    case FiltersActions.SET_FILTERS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default filtersReducer;
