import React, { Component } from 'react';

import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Checkbox from '../Checkbox';
import Input from '../Input';

import messages from './PasswordInput.messages';

/**
 * PasswordInput
 * Input form for passwords with a Show Password option
 */
export class PasswordInput extends Component {
  static baseClass = 'tk-password-input';

  state = {
    showPassword: false,
  };

  handleShowPasswordClick = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { disabled } = this.props;

    return (
      <div className={PasswordInput.baseClass}>
        <Input
          {...this.props}
          className="tk-password-input__input"
          type={this.state.showPassword ? 'text' : 'password'}
        />
        <div className="tk-password-input__show-password">
          <div className="tk-password-input__checkbox">
            <Checkbox
              data-qa="SGacSUFQODwTSFQbcQMCr"
              disabled={disabled}
              onClick={this.handleShowPasswordClick}
              selected={this.state.showPassword}
            />
          </div>
          <TranslatedMessage {...messages.showPasswordPlaceholder} />
        </div>
      </div>
    );
  }
}

PasswordInput.defaultProps = {
  disabled: false,
};

PasswordInput.propTypes = {
  disabled: PropTypes.bool,
  // All other props are passthrough to the Input, refer to Input's PropTypes for details
};

export default injectIntl(PasswordInput);
