import {
  SocialSearchState,
  SocialSearchActionTypes,
  SOCIAL_STREAM_TOGGLE_SEARCH_OPTION,
  SOCIAL_STREAM_SET_SELECTED_SEARCH_ID,
  SOCIAL_STREAM_UPDATE_SEARCH_CRITERIA,
  SOCIAL_STREAM_CLEAR_SEARCH_CRITERIA,
  SOCIAL_STREAM_SET_SELECTED_DATE_OPTION,
} from './types';

export const initialState: SocialSearchState = {
  socialMonitoringSearchRequest: {
    includeAll: [],
    includeAny: [],
    excludeAny: [],
  },
  openedSocialSearchOptionIds: [],
  selectedSearch: {
    id: 0,
    name: '',
    shared: false,
    viewOnly: false,
  },
  socialProfileSearches: [],
  keywordSearches: [],
  selectedDateOption: { id: 'TRAILING_30', label: 'trailing30Label' },
};

const socialSearchReducer = (
  state = { ...initialState },
  action: SocialSearchActionTypes,
): SocialSearchState => {
  switch (action.type) {
    case SOCIAL_STREAM_TOGGLE_SEARCH_OPTION: {
      const openedSocialSearchOptionIds = [
        ...state.openedSocialSearchOptionIds,
      ];

      const openedItemIndex = openedSocialSearchOptionIds?.findIndex(
        s => s === action.payload,
      );

      const isOpen = openedItemIndex >= 0;

      if (isOpen) {
        openedSocialSearchOptionIds.splice(openedItemIndex, 1);
      } else {
        openedSocialSearchOptionIds.push(action.payload);
      }
      return {
        ...state,
        openedSocialSearchOptionIds,
      };
    }

    case SOCIAL_STREAM_SET_SELECTED_SEARCH_ID: {
      return {
        ...state,
        selectedSearch: action.payload,
      };
    }

    case SOCIAL_STREAM_UPDATE_SEARCH_CRITERIA: {
      return {
        ...state,
        socialMonitoringSearchRequest: action.payload,
      };
    }

    case SOCIAL_STREAM_CLEAR_SEARCH_CRITERIA: {
      return {
        ...state,
        socialMonitoringSearchRequest: {
          includeAll: [],
          includeAny: [],
          excludeAny: [],
        },
      };
    }

    case SOCIAL_STREAM_SET_SELECTED_DATE_OPTION: {
      return {
        ...state,
        selectedDateOption: action.payload,
      };
    }

    default:
      return state;
  }
};

export default socialSearchReducer;
