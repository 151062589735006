import React, { useState } from 'react';

import { Dropdown } from '@cision/rover-ui';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button as TkUiButton, SvgIcon, Addon } from '@trendkite/ui';

import messages from 'components/widgetWizard/sections/section-wizard.messages';

import styles from 'components/widgetWizard/steps/steps.module.css';

import {
  analyticByIdSelector,
  adobeAnalyticsSelector,
  googleAnalyticsSelector,
} from 'components/widgetWizard/widget-wizard-selectors';

type AnaltyicsDropdownProps = {
  buttonStyles?: string[];
  onSelect: (option: any) => void;
  selectedAnalyticId: number;
};

export const AnalyticsDropdown = ({
  buttonStyles,
  onSelect,
  selectedAnalyticId,
}: AnaltyicsDropdownProps) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const googleAnalyticOptions = useSelector(googleAnalyticsSelector);
  const adobeAnalyticOptions = useSelector(adobeAnalyticsSelector);
  const analyticOptionsById = useSelector(analyticByIdSelector);

  const selectedAnalytic = analyticOptionsById[selectedAnalyticId];

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = option => {
    onSelect(option);
    onToggle();
  };

  const getAnalyticLabel = analytic => {
    const label =
      analytic.analyticsIntegrationAlias || analytic.analyticsIntegrationName;
    if (analytic.analyticsIntegrationType === 'GOOGLE_ANALYTICS') {
      // eslint-disable-next-line
      // @ts-ignore
      const googleAnalyticsFull = window?.appInfo?.gaAccounts || [];
      const matchingGoogleAnalytic = googleAnalyticsFull.find(
        ga => ga.analyticsId === analytic.analyticsId,
      );
      return matchingGoogleAnalytic
        ? `${label} (${matchingGoogleAnalytic.gaId})`
        : label;
    }

    return label;
  };

  const selectedAnalyticLabel = selectedAnalytic
    ? getAnalyticLabel(selectedAnalytic)
    : intl.formatMessage(messages.noAnalyticsLabel);

  const menuItem = option => {
    const style = {};
    if (option.analyticsId === selectedAnalyticId) {
      // eslint-disable-next-line
      // @ts-ignore
      style.backgroundColor = '#c9e8f2';
    }

    const dataQa = option.analyticsIntegrationName
      ? option.analyticsIntegrationName.replace(/\s+/g, '-')
      : `nullIntegrationName-${option.analyticsId}`;

    return (
      <Dropdown.Menu.Item
        onClick={() => handleSelect(option)}
        key={`analyticOption-${option.analyticsId}`}
        style={style}
        data-qa={`analytics-dropdown-${dataQa}`}
      >
        <span className={styles.itemContainer}>{getAnalyticLabel(option)}</span>
      </Dropdown.Menu.Item>
    );
  };
  let buttonModifiers = ['tertiary', 'flex'];
  if (buttonStyles?.length) {
    buttonModifiers = buttonModifiers.concat(buttonStyles);
  }

  return (
    <Dropdown
      isOpen={isOpen}
      // eslint-disable-next-line
      // @ts-ignore
      onToggle={onToggle}
      style={{ display: 'flex', width: '100%' }}
      data-qa="analytics-dropdown"
    >
      <TkUiButton
        data-qa="pgty774Pfukx99qtDunUW"
        onClick={onToggle}
        modifiers={buttonModifiers}
        style={{
          minWidth: '0',
        }}
      >
        <span
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {selectedAnalyticLabel}
        </span>
        <Addon>
          <SvgIcon
            icon={isOpen ? 'arrowDropUp' : 'arrowDropDown'}
            width={24}
            height={24}
            modifiers={['block']}
          />
        </Addon>
      </TkUiButton>
      <Dropdown.Menu style={{ background: 'white', padding: '5px' }}>
        {googleAnalyticOptions?.length > 0 && (
          <>
            <Dropdown.Menu.Item>
              {intl.formatMessage(messages.googleAnalyticsCategory)}
            </Dropdown.Menu.Item>
            {googleAnalyticOptions.map(option => menuItem(option))}
          </>
        )}
        {adobeAnalyticOptions?.length > 0 && (
          <>
            <Dropdown.Menu.Item>
              {intl.formatMessage(messages.adobeAnalyticsCategory)}
            </Dropdown.Menu.Item>
            {adobeAnalyticOptions.map(option => menuItem(option))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AnalyticsDropdown;
