import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const baseClass = 'tk-splash';

const Splash = ({ callToAction, imageUrl, mainContent, title }) => {
  const mainClass = classnames(baseClass, {
    [`${baseClass}--has-image`]: imageUrl,
  });

  return (
    <div className={`${mainClass} ${baseClass}__centered`}>
      <div className={`${baseClass}__image`}>
        <svg className={`${baseClass}__svg`}>
          <use xlinkHref={imageUrl} />
        </svg>
      </div>
      <div className={`${baseClass}__body`}>
        <div className={`${baseClass}__heading`}>{title}</div>
        <div className={`${baseClass}__main-content`}>{mainContent}</div>
        <div className={`${baseClass}__ctas`}>
          <div className={`${baseClass}__cta-container`}>
            <div className={`${baseClass}__cta`}>{callToAction.component}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Splash.propTypes = {
  callToAction: PropTypes.shape({
    /** Pass a custom component instead of the stock button */
    component: PropTypes.node.isRequired,
  }).isRequired,
  /** svg urls only */
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  mainContent: PropTypes.node.isRequired,
};

export default Splash;
