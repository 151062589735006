import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Dropzone from 'react-dropzone';
import { useIntl } from 'react-intl';

import SvgIcon from '../svg-icon';
import Toolbar from '../toolbar';

import messages from './WysiwygToolbar.messages';

const SizeOption = props => {
  const intl = useIntl();
  const sizeOptions = [
    { value: '10px', label: intl.formatMessage(messages.fontSizeSmall) },
    { value: '', label: intl.formatMessage(messages.fontSizeNormal) },
    { value: '18px', label: intl.formatMessage(messages.fontSizeLarge) },
    { value: '32px', label: intl.formatMessage(messages.fontSizeHuge) },
  ];

  return renderSelect(sizeOptions, props);
};

const FontOption = props => {
  const intl = useIntl();
  //TODO: Create an abstraction to support external fonts
  const fontOptions = [
    { value: '', label: intl.formatMessage(messages.fontsSanSerif) },
    { value: 'serif', label: intl.formatMessage(messages.fontsSerif) },
    { value: 'monospace', label: intl.formatMessage(messages.fontsMonospace) },
  ];

  return renderSelect(fontOptions, props);
};

const renderSelect = (options, props) => (
  <select {...props} defaultValue="">
    {options.map(optionItem => (
      <option value={optionItem.value} key={optionItem.value}>
        {optionItem.label}
      </option>
    ))}
  </select>
);

const TemplateOption = props => (
  <select {...props} defaultValue="">
    {props.templates.map(template => (
      <option key={template} value={template}>{`{{${template}}}`}</option>
    ))}
  </select>
);

TemplateOption.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const AttachmentButton = props => (
  <Dropzone {...props}>
    <div className="tk-wysiwyg-toolbar__attachment-button">
      <SvgIcon icon="attach" width={18} height={18} />
    </div>
  </Dropzone>
);

const optionTagMap = option => {
  switch (option) {
    case 'align':
    case 'color':
      return 'select';
    case 'font':
      return FontOption;
    case 'size':
      return SizeOption;
    case 'template-string':
      return TemplateOption;
    case 'attachment':
      return AttachmentButton;
    default:
      return 'button';
  }
};

const renderToolbarOptionCreator = attachmentHandler => option => {
  let optionName = option;
  let value;
  let optionProps;
  if (typeof option !== 'string') {
    // right now we only support string options or a "list" object
    optionName = option.optionName || 'list';
    value = option.list;
    optionProps = option.props || {};
    if (optionName === 'attachment') {
      optionProps.onDrop = attachmentHandler;
    }
  }

  const Tag = optionTagMap(optionName);
  const props = {
    key: `${optionName}${value}`,
    className: `ql-${optionName}`,
    ...optionProps,
  };

  if (value) {
    props.value = value;
  }

  return <Tag {...props} />;
};

/** Toolbar used for a WysiwygEditor
 * must have a unique Id to connect it to the editor
 * any children with a class starting with "ql-" will be seen by the editor and
 * the functionality of the option will be added by quill directly in the DOM
 */
const WysiwygToolbar = ({
  id: toolbarId,
  options,
  wrap,
  onAddedAttachments,
  onMouseDown,
  usePortal,
  positionTarget,
  portalClassName,
  portalOffsets,
  show,
}) => {
  const [portalStyle, setPortalStyle] = useState({});
  const [portalRef, setPortalRef] = useState();

  useEffect(() => {
    if (usePortal && positionTarget) {
      const documentRect = document.body.getBoundingClientRect();
      const targetRect = positionTarget.getBoundingClientRect();
      const rect = portalRef?.getBoundingClientRect();

      if (rect && targetRect.left + rect.width > documentRect.width) {
        setPortalStyle({
          position: 'absolute',
          top: targetRect.top + (portalOffsets?.top || 0),
          right: 10,
        });
      } else {
        setPortalStyle({
          position: 'absolute',
          top: targetRect.top + (portalOffsets?.top || 0),
          left: targetRect.left + (portalOffsets?.left || 0),
        });
      }
    }
  }, [usePortal, positionTarget, portalOffsets, portalRef, show]);

  const toolbar = (
    <div
      className="tk-wysiwyg-toolbar"
      id={toolbarId}
      onMouseDown={onMouseDown}
      ref={setPortalRef}
      role="menu"
      tabIndex={0}
    >
      <Toolbar modifiers={wrap ? ['wrap'] : []}>
        {options.map(renderToolbarOptionCreator(onAddedAttachments))}
      </Toolbar>
    </div>
  );

  return usePortal
    ? ReactDOM.createPortal(
        <div style={portalStyle} className={portalClassName}>
          {toolbar}
        </div>,
        document.body,
      )
    : toolbar;
};

WysiwygToolbar.propTypes = {
  /** unique id for the toolbar, also pass this to Editor */
  id: PropTypes.string,
  /** Quill options to include in this toolbar */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.oneOf([
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'color',
        'align',
        'link',
        'spacer',
      ]),
      PropTypes.shape({
        list: PropTypes.oneOf(['bullet', 'ordered']).isRequired,
      }),
      // TK custom options
      PropTypes.shape({
        optionName: PropTypes.oneOf(['template-string', 'attachment']),
        optionProps: PropTypes.object,
      }),
    ]),
  ),
  /** whether the toolbar should wrap to the next line */
  wrap: PropTypes.bool,
  onAddedAttachments: PropTypes.func,
  onMouseDown: PropTypes.func,
  usePortal: PropTypes.bool,
  portalClassName: PropTypes.string,
  positionTarget: PropTypes.any,
  portalOffsets: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  // Currently has no effect other than triggering the useEffect to cause the toolbar to potentially change its location
  show: PropTypes.bool,
};

WysiwygToolbar.defaultProps = {
  id: '',
  options: [
    'bold',
    'italic',
    'underline',
    'color',
    'align',
    { list: 'bullet' },
    { list: 'ordered' },
    'link',
  ],
  wrap: false,
  onAddedAttachments: () => {},
  onMouseDown: () => {},
  usePortal: false,
  positionTarget: null,
  portalClassName: '',
  show: true,
};

export default WysiwygToolbar;
