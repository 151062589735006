import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

// Filtered views
export const activityFeedFilteredViewsByIdSelector = state =>
  state.activity.filteredViewsById || {};

export const getActivityFeedFilteredViewSelectorById = memoize(id =>
  createSelector(
    activityFeedFilteredViewsByIdSelector,
    activityFeedFilteredViewsById => activityFeedFilteredViewsById[id] || {},
  ),
);

export const getActivityByFilteredViewIdSelector = memoize(id =>
  createSelector(
    activityFeedFilteredViewsByIdSelector,
    activityFeedFilteredViewsById =>
      (activityFeedFilteredViewsById[id] &&
        activityFeedFilteredViewsById[id].activities) ||
      [],
  ),
);

export const getActivityLoadingByFilteredViewIdSelector = memoize(id =>
  createSelector(
    activityFeedFilteredViewsByIdSelector,
    activityFeedFilteredViewsById =>
      activityFeedFilteredViewsById[id]
        ? activityFeedFilteredViewsById[id].loading
        : true,
  ),
);

export const getActivityErrorByFilteredViewIdSelector = memoize(id =>
  createSelector(
    activityFeedFilteredViewsByIdSelector,
    activityFeedFilteredViewsById =>
      activityFeedFilteredViewsById[id] &&
      activityFeedFilteredViewsById[id].error,
  ),
);

export const filteredViewTypeaheadActivitiesLoadingSelectorById = memoize(id =>
  createSelector(
    getActivityFeedFilteredViewSelectorById(id),
    filteredView => filteredView && !!filteredView.loadingTypeaheadActivity,
  ),
);

export const filteredViewTypeaheadActivitiesSelectorById = memoize(
  filteredViewId =>
    createSelector(
      getActivityFeedFilteredViewSelectorById(filteredViewId),
      filteredView => filteredView && (filteredView.typeaheadActivities || []),
    ),
);

export const activityCreateFormSelector = state =>
  state.forms && state.forms.createActivityForm;
export const activityCreateFormValuesSelector = state =>
  state.createActivityForm;

export const getActivitySavingSelector = state =>
  state.activity.savingActivity || false;
export const getActivitySavingErrorSelector = state =>
  state.activity.savingActivityError || '';
export const getActivityUpdatingSelector = state =>
  state.activity.updatingActivity || false;
export const getActivityUpdatingErrorSelector = state =>
  state.activity.updatingActivityError || '';

export const getActivityRemovingSelector = state =>
  state.activity.removingActivity || false;
export const getActivityTypesSelector = state =>
  state.activity.activityTypes || [];
export const getActivityTypesSelectorError = state =>
  state.activity.activityTypesError || false;
export const getActivityRemovingErrorSelector = state =>
  state.activity.removingActivityError || false;
