import { createSelector } from 'reselect';

import {
  CONTENT_TYPE_KEY,
  LANGUAGE_KEY,
  LOCATION_KEY,
  MEDIA_TYPE_KEY,
  REGION_KEY,
} from 'pages/EarnedSearch/state/reducers/filtersReducer';
import { Filter } from 'pages/EarnedSearch/state/types/filters';

export const mediaTypeFilterSelector = state =>
  state.earnedSearch.filters.mediaType;

export const contentTypeFilterSelector = state =>
  state.earnedSearch.filters.contentType;

export const regionFilterSelector = state => state.earnedSearch.filters.region;

export const allFiltersSelector = state => state.earnedSearch.filters;

export const allFiltersWithDataSelector = createSelector(
  allFiltersSelector,
  (filters: Filter[]) => {
    let allowedFiltersToUse = [MEDIA_TYPE_KEY, CONTENT_TYPE_KEY, REGION_KEY];
    allowedFiltersToUse = [...allowedFiltersToUse, LANGUAGE_KEY, LOCATION_KEY];
    return Object.keys(filters)
      .filter(filter => allowedFiltersToUse.includes(filter))
      .reduce((acc, filterKey) => {
        if (filters[filterKey].values.length > 0) {
          const filterValues = filters[filterKey].values.map(
            (filterValueData: any) => filterValueData.value,
          );
          acc[filterKey] = {
            operator: filters[filterKey].operator,
            values: filterValues,
          };
        }
        return acc;
      }, {});
  },
);

export const modalLoadingStatusSelector = (state, filterType) =>
  state.earnedSearch?.filters?.isLoading &&
  state.earnedSearch?.filters?.filterLoading === filterType;

export const filterValuesSelector = (state, filterType) =>
  state.earnedSearch.filters[filterType];

export const getModalError = state => state.earnedSearch?.filters?.error;

export default {
  mediaTypeFilterSelector,
  contentTypeFilterSelector,
  regionFilterSelector,
  allFiltersWithDataSelector,
};
