export default {
  addWidgetTrayTitle: {
    id: 'ADD_WIDGET_TRAY_TITLE',
    defaultMessage: 'Add a Widget',
    description: 'Add a widget side Tray Title',
  },
  createWidget: {
    id: 'WIDGET_WIZARD_CREATE_WIDGET',
    defaultMessage: 'Create a widget',
    description: 'Label for the create widget button',
  },
  selectWidget: {
    id: 'WIDGET_WIZARD_SELECT_WIDGET',
    defaultMessage: 'Select a widget',
    description: 'Select a widget side tray title',
  },
  selectWidgetTypeTitle: {
    id: 'SELECT_WIDGET_TRAY_TITLE',
    defaultMessage: 'Select a Widget Type',
    description: 'Select a Widget Type side tray title',
  },
  addSearchesTrayTitle: {
    id: 'ADD_SEARCHES_TRAY_TITLE',
    defaultMessage: 'Add Searches',
    description: 'Add searches side Tray Title',
  },
  customWidgetrayTitle: {
    id: 'ADD_CUSTOM_WIDGET_TRAY_TITLE',
    defaultMessage: 'Custom Widget Settings',
    description: 'Custom widget settings side Tray Title',
  },
  addKeyMessagesTrayTitle: {
    id: 'ADD_KEY_MESSAGES_TRAY_TITLE',
    defaultMessage: 'Add Key Messages',
    description: 'Add key messages side Tray Title',
  },
  backButton: {
    id: 'ADD_WIDGET_SECTION_BACK_BUTTON',
    defaultMessage: 'Back',
    description: 'Back button',
  },
  cancelButton: {
    id: 'ADD_WIDGET_SECTION_CANCEL_BUTTON',
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  },
  addWidgetButton: {
    id: 'ADD_WIDGET_FORM_BUTTON',
    defaultMessage: 'Add Widget',
    description: 'Add widget button',
  },
  saveButton: {
    id: 'SAVE_KEY_MSG_BUTTON',
    defaultMessage: 'Save',
    description: 'Save button',
  },
  saveFiltersButton: {
    id: 'SAVE_WIDGET_FILTERS_MSG_BUTTON',
    defaultMessage: 'Save Filters',
    description: 'Save widget filters button',
  },
  saveAndAddButton: {
    id: 'SAVE_AND_ADD_KEY_MSG_BOOLEAN_BUTTON',
    defaultMessage: 'Save and Add',
    description: 'Save and add button',
  },
  addKeyMessagesButton: {
    id: 'ADD_KEY_MESSAGES_BUTTON',
    defaultMessage: 'Add Key Messages',
    description: 'Add key messages button',
  },
  selectChartTypeTitle: {
    id: 'SELECT_CHART_TYPE_TITLE',
    defaultMessage: 'Metric {METRIC_ID}: Select Chart Type',
    description: 'Select chart type title',
  },
  addTagTrayTitle: {
    id: 'ADD_TAG_TRAY_TITLE',
    defaultMessage: 'Add Tag',
    description: 'Add tag side Tray Title',
  },
  alternativeTitleForTag: {
    id: 'ALTERNATIVE_TITLE_FOR_TAG',
    defaultMessage: 'Untitled Tag',
    description: 'Alternative title for tag',
  },
  selectMetricTrayTitle: {
    id: 'SELECT_METRIC_TRAY_TITLE',
    defaultMessage: 'Select Metric',
    description:
      'Select metric side Tray Title for selecting or editing a metric step',
  },
  selectAnalyticsAccountTrayTitle: {
    id: 'SELECT_ANALYTICS_ACCOUNT_TRAY_TITLE',
    defaultMessage: 'Select Analytics Account',
    description:
      'Select analytics account Tray Title for selecting an analytics account',
  },
  selectAnalyticsAccountButton: {
    id: 'ADD_ANALYTICS_ACCOUNT_BUTTON',
    defaultMessage: 'Add Analytics Account',
    description: 'Add analytics account button',
  },
  updateSearchTitle: {
    id: 'UPDATE_SEARCH_TRAY_TITLE',
    defaultMessage: 'Select Search',
    description: 'Update search side Tray Title',
  },
  removeWidgetNotificaitonText: {
    id: 'WIDGET_NOTIFICATION_TEXT',
    description: 'Widget remove text notification',
    defaultMessage: 'Widget Removed',
  },
  widgetAdded: {
    id: 'WIDGET_ADDED',
    description: 'Global term to refer to when a widget was added',
    defaultMessage: 'Widget Added',
  },
  widgetEdited: {
    id: 'WIDGET_EDITED',
    description: 'Global term to refer to when a widget was edited',
    defaultMessage: 'Widget Edited',
  },
  booleanKeyMessageSyntaxError: {
    id: 'BOOLEAN_KEY_MESSAGE_ERROR',
    description:
      'Error text to display when there is a syntax error in a Boolean Key Message',
    defaultMessage:
      "Key Messages can't end in 'AND', 'OR', or unmatched or empty ' ( ) { } [ ] < > \"\" '",
  },
};
