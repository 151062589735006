export const adminFeedsSelector = state =>
  state.adminFeedManagement.feeds || [];
export const adminFeedSelector = state => ({
  ...(state.adminFeedManagement.feed || {}),
  dashboardWidgets: (
    state.adminFeedManagement.feedDashboardWidgets || []
  ).filter(w => w.type === 'WIDGET_V3'),
  selectedWidgetTitle: state.adminFeedManagement.feedSelectedWidgetTitle || '',
  dashboardTitle: state.adminFeedManagement.feedDashboardTitle || '',
});

export const adminFeedIsLoadingSelector = state =>
  state.adminFeedManagement.loading || false;
export const adminFeedsPaginatorSelector = state =>
  state.adminFeedManagement.paginator;
export const adminFeedCountsSelector = state =>
  state.adminFeedManagement.feedCounts || {};
export const adminFeedActionStatusSelector = state =>
  state.adminFeedManagement.actionStatus || '';
