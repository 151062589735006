import { isString } from 'lodash';

import {
  ACCOUNT_CREATE_ENDPOINT,
  ACCOUNT_GET_ENDPOINT,
  ACCOUNT_TYPE_ADMIN_ENDPOINT,
  ACCOUNT_CLEAR_EXISTING_CLICKS_ENDPOINT,
  ACCOUNT_DEACTIVATE_ENDPOINT,
} from 'constants/apis';
import listMessages from 'pages/Admin/AccountManagement/AccountList/AccountList.messages';
import addMessages from 'pages/Admin/AccountManagement/AddAccount/AddAccount.messages';
import editMessages from 'pages/Admin/AccountManagement/EditAccount/EditAccount.messages';
import addUserMessages from 'pages/Admin/UserManagement/AddUser/AddUser.messages';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import {
  performGet,
  performPost,
  performPut,
} from 'services/rest-service/rest-service';

export const GET_ACCOUNTS = 'adminAccountManagement/GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS =
  'adminAccountManagement/GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'adminAccountManagement/GET_ACCOUNTS_ERROR';
export const CLEAR_ACCOUNTS = 'adminAccountManagement/CLEAR_ACCOUNTS';

export const DEACTIVATE_ACCOUNT = 'adminAccountManagement/DEACTIVATE_ACCOUNT';
export const DEACTIVATE_ACCOUNT_SUCCESS =
  'adminAccountManagement/DEACTIVATE_ACCOUNT_SUCCESS';
export const DEACTIVATE_ACCOUNT_ERROR =
  'adminAccountManagement/DEACTIVATE_ACCOUNT_ERROR';

export const GET_ACCOUNT = 'adminAccountManagement/GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'adminAccountManagement/GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'adminAccountManagement/GET_ACCOUNT_ERROR';

export const GET_ACCOUNT_TYPES = 'adminAccountManagement/GET_ACCOUNT_TYPES';
export const GET_ACCOUNT_TYPES_SUCCESS =
  'adminAccountManagement/GET_ACCOUNT_TYPES_SUCCESS';
export const GET_ACCOUNT_TYPES_ERROR =
  'adminAccountManagement/GET_ACCOUNT_TYPES_ERROR';

export const ADD_ACCOUNT = 'adminAccountManagement/ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'adminAccountManagement/ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_ERROR = 'adminAccountManagement/ADD_ACCOUNT_ERROR';

export const UPDATE_ACCOUNT = 'adminAccountManagement/UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS =
  'adminAccountManagement/UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR =
  'adminAccountManagement/UPDATE_ACCOUNT_ERROR';

export const CLEAR_EXISTING_CLICKS =
  'adminAccountManagement/CLEAR_EXISTING_CLICKS';
export const CLEAR_EXISTING_CLICKS_SUCCESS =
  'adminAccountManagement/CLEAR_EXISTING_CLICKS_SUCCESS';
export const CLEAR_EXISTING_CLICKS_ERROR =
  'adminAccountManagement/CLEAR_EXISTING_CLICKS_ERROR';

const additionalHeaders = {
  'X-API-UI': true,
};

export const initialState = {
  account: null,
  accountTypes: [],
  accounts: [],
  paginator: {},
  loading: false,
  error: null,
};

const adminAccountManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload.accounts,
        paginator: action.payload.paginator,
        loading: false,
        error: null,
      };
    case GET_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: [],
        paginator: null,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_ACCOUNTS:
      return {
        ...state,
        accounts: [],
      };
    case GET_ACCOUNT:
      return {
        ...state,
        account: null,
        loading: true,
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload.account,
        loading: false,
        error: null,
      };
    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        account: null,
        loading: false,
        error: action.payload.error,
      };
    case DEACTIVATE_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DEACTIVATE_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_ACCOUNT_TYPES:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: action.payload.accountTypes,
        loading: false,
        error: null,
      };
    case GET_ACCOUNT_TYPES_ERROR:
      return {
        ...state,
        accountTypes: [],
        loading: false,
        error: action.payload.error,
      };
    case ADD_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload.account,
        loading: false,
        error: null,
      };
    case UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_EXISTING_CLICKS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CLEAR_EXISTING_CLICKS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_EXISTING_CLICKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const clearAccounts = () => dispatch => {
  dispatch({ type: CLEAR_ACCOUNTS });
};

export const getAccounts = (params, intl) => dispatch => {
  dispatch({ type: GET_ACCOUNTS });
  const fullParams = { ...params, ...{ max: 100 } };
  return performGet(ACCOUNT_GET_ENDPOINT, fullParams, additionalHeaders)
    .then(resp => {
      if (resp.status === 200 && resp.data) {
        const { paginator, accounts } = resp.data;
        dispatch({
          type: GET_ACCOUNTS_SUCCESS,
          payload: {
            paginator,
            accounts,
          },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_ACCOUNTS_ERROR, payload: { error: e.response } });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(listMessages.getAccountsErrorMsg),
          status: 'danger',
        }),
      );
    });
};

export const deactivateAccount = (params, intl) => async dispatch => {
  dispatch({ type: DEACTIVATE_ACCOUNT });
  try {
    await performPost(ACCOUNT_DEACTIVATE_ENDPOINT, params);
    dispatch({ type: DEACTIVATE_ACCOUNT_SUCCESS });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(listMessages.deactivateAccountSuccessMsg, {
          ACCOUNT_ID: params.accountId,
        }),
        status: 'success',
      }),
    );
  } catch (e) {
    dispatch({
      type: DEACTIVATE_ACCOUNT_ERROR,
      payload: { error: e.response },
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: intl.formatMessage(listMessages.deactivateAccountErrorMsg, {
          ACCOUNT_ID: params.accountId,
        }),
        status: 'danger',
      }),
    );
  }
};

export const getAccount = (accountId, intl) => async dispatch => {
  dispatch({ type: GET_ACCOUNT });
  performGet(ACCOUNT_GET_ENDPOINT + '/' + accountId, null, additionalHeaders)
    .then(response => {
      if (response.status === 200 && response.data) {
        const account = response.data;
        account.features.sort((a, b) => a.name.localeCompare(b.name));
        account.devFeatures.sort((a, b) => a.name.localeCompare(b.name));
        dispatch({
          type: GET_ACCOUNT_SUCCESS,
          payload: { account },
        });
      }
    })
    .catch(e => {
      dispatch({ type: GET_ACCOUNT_ERROR, payload: { error: e.response } });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(editMessages.getAccountErrorMsg, {
            ACCOUNT_ID: accountId,
          }),
          status: 'danger',
        }),
      );
    });
};

export const getAccountTypes = () => async dispatch => {
  dispatch({ type: GET_ACCOUNT_TYPES });
  performGet(ACCOUNT_TYPE_ADMIN_ENDPOINT)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({
          type: GET_ACCOUNT_TYPES_SUCCESS,
          payload: { accountTypes: response.data },
        });
      }
    })
    .catch(e => {
      dispatch({
        type: GET_ACCOUNT_TYPES_ERROR,
        payload: { error: e.response },
      });
    });
};

export const clearExistingClicksAction = (
  accountId,
  intl,
) => async dispatch => {
  dispatch({ type: CLEAR_EXISTING_CLICKS });
  const formData = `accountId=${accountId}&_action_Clear+Clicks=Clear+Clicks`;
  performPost(
    ACCOUNT_CLEAR_EXISTING_CLICKS_ENDPOINT,
    formData,
    {},
    { 'Content-Type': 'application/x-www-form-urlencoded' },
  )
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({ type: CLEAR_EXISTING_CLICKS_SUCCESS });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(
              editMessages.clearExistingClicksSuccessMsg,
            ),
            status: 'success',
          }),
        );
      }
    })
    .catch(e => {
      dispatch({
        type: CLEAR_EXISTING_CLICKS_ERROR,
        payload: { error: e.response },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(
            editMessages.clearExistingClicksDefaultErrorMsg,
          ),
          status: 'danger',
        }),
      );
    });
};

export const createAccountAction = (account, intl) => dispatch => {
  dispatch({ type: ADD_ACCOUNT });
  return performPost(ACCOUNT_CREATE_ENDPOINT, account)
    .then(response => {
      if (response.status === 200 && response.data) {
        dispatch({ type: ADD_ACCOUNT_SUCCESS });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(
              addMessages.adminAccountSuccessCreatedMessage,
              {
                ACCOUNT_NAME: account.name,
              },
            ),
            status: 'success',
          }),
        );
      }
    })
    .catch(e => {
      dispatch({ type: ADD_ACCOUNT_ERROR, payload: { error: e.response } });
      const { status } = e.response;
      const { error } = e.response?.data;
      const text =
        status === 409 && error === 'Invalid sfdcId'
          ? addUserMessages.invalidGlobalCustomerIdErrorMsg
          : status === 409 && error === 'Duplicate sfdcId'
          ? addUserMessages.accountDuplicateIdErrorMsg
          : addMessages.adminAccountErrorCreatedMessage;
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(text),
          status: 'danger',
        }),
      );
      throw e.response;
    });
};

export const editAccountAction = (account, intl) => async dispatch => {
  dispatch({ type: UPDATE_ACCOUNT });
  return performPut(
    ACCOUNT_GET_ENDPOINT + '/' + account.id,
    account,
    null,
    additionalHeaders,
  )
    .then(response => {
      const isValidResponse =
        response.status === 200 && response.data && !isString(response.data);

      const currentState = isValidResponse
        ? {
            type: UPDATE_ACCOUNT_SUCCESS,
            payload: { account: response.data },
          }
        : {
            type: UPDATE_ACCOUNT_ERROR,
            payload: { error: response.data },
          };
      const message = isValidResponse
        ? {
            text: intl.formatMessage(editMessages.editAccountSuccessMsg, {
              ACCOUNT_NAME: account.name,
            }),
            status: 'success',
          }
        : {
            text: intl.formatMessage(editMessages.editAccountDefaultErrorMsg, {
              ACCOUNT_NAME: account.name,
            }),
            status: 'danger',
          };

      dispatch(currentState);
      dispatch(addPageMessageWithDefaultTimeout(message));
      if (!isValidResponse) {
        throw response.data;
      }
    })
    .catch(e => {
      if (account.name && account.accountType?.id && account.gcId) {
        dispatch({
          type: UPDATE_ACCOUNT_ERROR,
          payload: { error: e.response },
        });
        const { status } = e.response;
        const text =
          status === 409
            ? intl.formatMessage(editMessages.alreadyExistsErrorMsg)
            : intl.formatMessage(editMessages.editAccountDefaultErrorMsg, {
                accountname: account.name,
              });
        dispatch(addPageMessageWithDefaultTimeout({ text, status: 'danger' }));
        throw e.response;
      } else {
        dispatch({
          type: UPDATE_ACCOUNT_SUCCESS,
          payload: { account: account },
        });
        dispatch(
          addPageMessageWithDefaultTimeout({
            text: intl.formatMessage(editMessages.editAccountSuccessMsg, {
              ACCOUNT_NAME: account.id,
            }),
            status: 'success',
          }),
        );
      }
    });
};

export default adminAccountManagementReducer;
