import React from 'react';

import PropTypes from 'prop-types';

import { SvgIcon } from '@trendkite/ui';

const fileMap = {
  'application/pdf':
    'https://d1mh775eulqdhu.cloudfront.net/static-assets/pdf.png',
};

const getSvg = filetype => {
  if (filetype === 'image/svg+xml') {
    return 'svg';
  }

  if (filetype.startsWith('image')) {
    return 'image';
  }

  return 'document';
};

const getUrl = (thumbnailUrl, filetype) => {
  if (thumbnailUrl) {
    return thumbnailUrl;
  }

  return fileMap[filetype];
};

const FileThumbnail = ({ filename, thumbnailUrl, filetype }) => {
  const fileUrl = getUrl(thumbnailUrl, filetype);
  if (fileUrl) {
    return (
      <img
        className="file-thumbnail"
        alt={filename}
        src={getUrl(thumbnailUrl, filetype)}
      />
    );
  }

  const fileSvg = getSvg(filetype);
  return <SvgIcon icon={fileSvg} width={40} height={40} />;
};

FileThumbnail.propTypes = {
  filename: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  filetype: PropTypes.string.isRequired,
};

FileThumbnail.defaultProps = {
  thumbnailUrl: '',
};

export default FileThumbnail;
