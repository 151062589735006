/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';

import axios from 'axios';
import TranslatedMessage from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Control, Errors, LocalForm, actions, track } from 'react-redux-form';

import shortid from 'shortid';

import {
  Button,
  ErrorWithIntl,
  Input,
  TextArea,
  SvgIcon,
  Caption,
  Tooltip,
  Dropdown,
  DropdownMenu,
  FloatingPanel,
  IconButton,
} from '@trendkite/ui';

import { ASSET_POPUP_GET_STORY_ENDPOINT } from '../../../app/src/constants/apis';
import globalMessages from '../../../app/src/Global.messages';
import BrowseModalUpload from '../browse-asset-library/BrowseModalUpload';
import StorySection, { StorySectionHeader } from '../story-section';
import FileInput from '../story/fields/FileInput';
import {
  validators,
  errorMessageI18nKeys,
} from '../story/templates/default-template';

// i18n

import messages from './EditStorySpokespeople.messages';

const createEmptySpokesperson = () => ({
  id: shortid.generate(),
  firstName: '',
  lastName: '',
  jobTitle: '',
  twitter: '',
  linkedin: '',
  quotes: '',
  image: '',
  about: '',
});

const EditStoryVideosError = props => (
  <ErrorWithIntl messages={messages} block {...props} />
);

const formModel = 'storySpokespeopleForm';

class EditStorySpokespeople extends Component {
  static baseClass = 'tk-spokespeople-edit';

  static getDerivedStateFromProps(props, state) {
    if (props.spokespeople !== state.propsSpokespeople) {
      return {
        spokespeople: props.spokespeople,
        propsSpokespeople: props.spokespeople,
      };
    }

    return null;
  }

  state = {
    spokespeople: [],
    propsSpokespeople: [],
    isValid: false,
    isEditing: true,
    isheaderDropdownOpen: false,
    isFromSpokePersonWidget: true,
  };

  componentDidMount() {
    if (this.state.spokespeople.length === 0) {
      this.onAddSpokesperson();
    } else {
      this.dispatch(
        actions.change(`${formModel}.spokespeople`, this.state.spokespeople),
      );
    }
  }

  onDiscard = () => {
    const { propsSpokespeople } = this.state;
    const tempSpokespeople = [...propsSpokespeople];
    if (tempSpokespeople.length < 1) {
      tempSpokespeople.push(createEmptySpokesperson());
    }
    this.setState({ isEditing: false, spokespeople: tempSpokespeople });
    this.props.onEndEditing();
  };

  onFormSubmit = form => {
    const spokespeople = form.spokespeople.filter(
      person => !!person.firstName && !!person.lastName,
    );

    this.props.dispatchMergeFormAction('storyContentForm', { spokespeople });
    this.props.onEndEditing();
  };

  onFormUpdate = form => {
    this.setState({ isValid: form.$form.valid });
  };

  onFormChange = values => {
    this.setState({ spokespeople: [...values.spokespeople] });
  };

  onImageUpload = (id, acceptedFiles, text) => {
    if (!acceptedFiles || acceptedFiles.length <= 0) return;
    const model = track(`${formModel}.spokespeople[].image`, { id });
    const logoUploadPath =
      text !== undefined && text.length > 0
        ? ASSET_POPUP_GET_STORY_ENDPOINT
        : this.props.logoUploadPath;
    const data = new FormData();
    if (text === undefined) {
      const file = acceptedFiles[0];
      data.append('image', file);
      data.append('type', 'story');
      data.append('maxSize', 480);
    } else if (text.length > 0) {
      data.append('id', acceptedFiles.id);
      data.append('type', 'ms-story-logo');
      data.append('isTK', acceptedFiles.isTK);
    }
    axios.post(logoUploadPath, data).then(res => {
      const spokespeople = this.state.spokespeople.map(spokesperson => {
        const person = { ...spokesperson };

        if (person.id === id) {
          person.image = encodeURI(res.data.logo);
        }

        return person;
      });

      this.setState({ spokespeople });
      this.dispatch(actions.change(model, encodeURI(res.data.logo)));
    });
  };

  onAddSpokesperson = () => {
    const spokespeople = [...this.state.spokespeople];
    const newPerson = createEmptySpokesperson();

    spokespeople.push(newPerson);

    this.setState({ spokespeople });

    this.dispatch(actions.push(`${formModel}.spokespeople`, newPerson));
  };

  onClearSpokesperson = index => {
    const newPerson = createEmptySpokesperson();
    const cleared = this.state.spokespeople.map(a => a);
    cleared[index] = newPerson;
    this.replaceFields(cleared);
    this.setState({ spokespeople: cleared });
  };

  onClearAllSpokesperson = () => {
    const emptyArray = this.state.spokespeople.map(() =>
      createEmptySpokesperson(),
    );
    this.replaceFields(emptyArray);
    this.setState({ spokespeople: emptyArray, isheaderDropdownOpen: false });
  };

  onRemoveSpokesperson = (id, index) => {
    if (this.state.spokespeople.length < 2) {
      this.onRemoveAllSpokesperson();
      return;
    }
    const spokespeople = this.state.spokespeople.filter(
      person => person.id !== id,
    );

    this.setState({ spokespeople });
    this.dispatch(actions.remove(`${formModel}.spokespeople`, index));
    this.dispatch(actions.change(`${formModel}.spokespeople`, spokespeople));
  };

  onRemoveAllSpokesperson = () => {
    this.setState({ isheaderDropdownOpen: false });
    this.dispatch(actions.change(`${formModel}.spokespeople`, []));
    this.dispatch(actions.submit(formModel));
    this.props.onEndEditing();
  };

  // Magic method, gets called by withEditable hoc
  startEditing() {
    if (this.state.spokespeople.length === 0) {
      this.onAddSpokesperson();
    }
    this.setState({ isEditing: true });
  }

  generateClassName = child =>
    child
      ? `${EditStorySpokespeople.baseClass}__${child}`
      : EditStorySpokespeople.baseClass;

  replaceFields = people => {
    this.dispatch(actions.change(`${formModel}.spokespeople`, []));

    people.forEach(p => {
      this.dispatch(actions.push(`${formModel}.spokespeople`, p));
    });
  };

  renderSpokespeople = () => {
    return this.state.spokespeople.map(this.renderSpokesperson);
  };

  renderSpokesperson = (person, index) => {
    const { spokespeople, isFromSpokePersonWidget } = this.state;
    const { intl, browseLink } = this.props;

    const baseClass = this.generateClassName('spokesperson-form');
    const imageStyle = person.image
      ? { backgroundImage: `url(${person.image})` }
      : {};
    const peopleActions = (
      <div className={`${baseClass}__header-actions`}>
        <span
          data-qa="2F9a7OBtsyYciRDGOvCbA"
          onClick={() => this.onClearSpokesperson(index)}
          role="button"
          tabIndex="-1"
        >
          <TranslatedMessage {...globalMessages.clear} />
        </span>
        <span
          data-qa="Wn-T9cdOAGi03gUVoLrVF"
          onClick={() => this.onRemoveSpokesperson(person.id, index)}
          role="button"
          tabIndex="-1"
        >
          <TranslatedMessage {...globalMessages.remove} />
        </span>
      </div>
    );

    return (
      <div key={person.id} className={baseClass}>
        <div className={`${baseClass}__header`}>
          <Caption color="light">
            <TranslatedMessage
              {...messages.spokespersonIndex}
              values={{ NUMBER: index + 1 }}
            />
          </Caption>
          {spokespeople.length > 0 && peopleActions}
        </div>
        <div className={`${baseClass}__fields`}>
          <div className={`${baseClass}__personal`}>
            <div className={`${baseClass}__personal__info`}>
              <div className={`${baseClass}__field`}>
                <Control.text
                  component={Input}
                  placeholder={intl.formatMessage(
                    messages.firstNamePlaceholder,
                  )}
                  model={track('.spokespeople[].firstName', { id: person.id })}
                  validators={validators.spokespeople.firstName}
                  persist
                />
                <Errors
                  className="errors"
                  component={EditStoryVideosError}
                  model={track('.spokespeople[].firstName', { id: person.id })}
                  messages={errorMessageI18nKeys.spokespeople.firstName}
                  show="touched"
                />
              </div>
              <div className={`${baseClass}__field`}>
                <Control.text
                  component={Input}
                  placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                  model={track('.spokespeople[].lastName', { id: person.id })}
                  validators={validators.spokespeople.firstName}
                  persist
                />
                <Errors
                  className="errors"
                  component={EditStoryVideosError}
                  model={track('.spokespeople[].lastName', { id: person.id })}
                  messages={errorMessageI18nKeys.spokespeople.lastName}
                  show="touched"
                />
              </div>
            </div>
            <div className={`${baseClass}__personal__avatar`}>
              {browseLink && (
                <div>
                  <div
                    className={`${baseClass}__personal__avatar__image`}
                    style={imageStyle}
                  />
                  <div className={`${baseClass}__field`}>
                    <BrowseModalUpload
                      isFromSpokePersonWidget={isFromSpokePersonWidget}
                      personId={person.id}
                      handleSelectedFilesfromPopUp={this.onImageUpload}
                    />
                  </div>
                </div>
              )}
              {!browseLink && (
                <Control
                  model={track('.spokespeople[].image', { id: person.id })}
                  className={`${baseClass}__personal__avatar__input`}
                  component={FileInput}
                  onDrop={acceptedFiles =>
                    this.onImageUpload(person.id, acceptedFiles)
                  }
                >
                  <div
                    className={`${baseClass}__personal__avatar__image`}
                    style={imageStyle}
                  />
                  <div className={`${baseClass}__personal__avatar__upload`}>
                    <div className={`${baseClass}__field`}>
                      <SvgIcon icon="upload" />
                      &nbsp;&nbsp;
                      <TranslatedMessage {...globalMessages.upload} />
                    </div>
                  </div>
                </Control>
              )}
            </div>
          </div>
          <div className={`${baseClass}__field`}>
            <Control.text
              component={Input}
              placeholder={intl.formatMessage(messages.jobPlaceholder)}
              model={track('.spokespeople[].jobTitle', { id: person.id })}
              persist
            />
          </div>
          <div className={`${baseClass}__field`}>
            <Control.textarea
              component={TextArea}
              placeholder={intl.formatMessage(messages.aboutPlaceholder)}
              model={track('.spokespeople[].about', { id: person.id })}
              persist
            />
          </div>
          <div className={`${baseClass}__field`}>
            <Control.text
              component={Input}
              placeholder={intl.formatMessage(messages.twitterPlaceholder)}
              model={track('.spokespeople[].twitter', { id: person.id })}
              persist
            />
          </div>
          <div className={`${baseClass}__field`}>
            <Control.text
              component={Input}
              placeholder={intl.formatMessage(messages.linkedInPlaceholder)}
              model={track('.spokespeople[].linkedin', { id: person.id })}
              validators={validators.spokespeople.linkedin}
              persist
            />
            <Errors
              className="errors"
              component={EditStoryVideosError}
              model={track('.spokespeople[].linkedin', { id: person.id })}
              messages={errorMessageI18nKeys.spokespeople.linkedin}
              show="touched"
            />
          </div>
          <div className={`${baseClass}__field`}>
            <Control.textarea
              component={TextArea}
              placeholder={intl.formatMessage(
                messages.approvedQuotesPlaceholder,
              )}
              model={track('.spokespeople[].quotes', { id: person.id })}
              persist
            />
          </div>
        </div>
      </div>
    );
  };

  renderForm = () => {
    const { spokespeople } = this.state;

    return (
      <LocalForm
        component="div"
        model={formModel}
        initialState={{ spokespeople }}
        onUpdate={this.onFormUpdate}
        onChange={this.onFormChange}
        onSubmit={this.onFormSubmit}
        getDispatch={d => (this.dispatch = d)}
      >
        {spokespeople && this.renderSpokespeople()}
      </LocalForm>
    );
  };

  render() {
    const { isValid, isEditing, isheaderDropdownOpen } = this.state;
    const { intl } = this.props;

    return (
      <StorySection>
        <div className={this.generateClassName('top-header')}>
          <StorySectionHeader
            label={intl.formatMessage(messages.editSpokespeopleheader)}
          />
          <Dropdown
            isOpen={isheaderDropdownOpen}
            toggle={() =>
              this.setState({ isheaderDropdownOpen: !isheaderDropdownOpen })
            }
          >
            <Tooltip
              content={intl.formatMessage(globalMessages.actions)}
              className="tk-spokespeople-edit__header-menu"
            >
              <IconButton
                data-qa="xtk2sGGgwVIU0fznfwbbz"
                onClick={() =>
                  this.setState({ isheaderDropdownOpen: !isheaderDropdownOpen })
                }
                icon="ellipsis"
                compact
              />
            </Tooltip>
            <DropdownMenu isOpen={isheaderDropdownOpen} position="bottomRight">
              <FloatingPanel>
                <Button
                  data-qa="P8vdRyH9zUzaAbMhTT1H3"
                  modifiers={['listEntry']}
                  onClick={this.onClearAllSpokesperson}
                >
                  <TranslatedMessage {...globalMessages.clearAllText} />
                </Button>
                <Button
                  data-qa="vmQTTJrvRpUjefNNivHHg"
                  modifiers={['listEntry']}
                  onClick={this.onRemoveAllSpokesperson}
                >
                  <TranslatedMessage {...globalMessages.removeAllText} />
                </Button>
              </FloatingPanel>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className={this.generateClassName()}>
          {isEditing && this.renderForm()}
          <div className={this.generateClassName('add')}>
            <Button
              data-qa="QWdN2isrNEVU_gYxd8O9j"
              modifiers={['calltoaction', 'inline-flex']}
              onClick={this.onAddSpokesperson}
              role="button"
              active
            >
              <SvgIcon icon="plus" width={20} height={20} />
              <span className={this.generateClassName('button-text')}>
                <TranslatedMessage {...messages.addSpokesperson} />
              </span>
            </Button>
          </div>
          <div className={this.generateClassName('submit-buttons')}>
            <div className={this.generateClassName('submit-buttons--cancel')}>
              <Button
                data-qa="gNXgw4P0G9silY3cxNFef"
                modifiers={['round', 'tertiary', 'block']}
                onClick={this.onDiscard}
                role="button"
              >
                <TranslatedMessage {...globalMessages.discard} />
              </Button>
            </div>
            <div className={this.generateClassName('submit-buttons--submit')}>
              <Button
                data-qa="gfxBiTXFzq-ImpOKDBHJC"
                disabled={!isValid}
                modifiers={['round', 'primary', 'block']}
                onClick={() => this.dispatch(actions.submit(formModel))}
                role="button"
              >
                <TranslatedMessage {...globalMessages.save} />
              </Button>
            </div>
          </div>
        </div>
      </StorySection>
    );
  }
}

EditStorySpokespeople.propTypes = {
  spokespeople: PropTypes.array,
  onEndEditing: PropTypes.func.isRequired,
  dispatchMergeFormAction: PropTypes.func.isRequired,
  logoUploadPath: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  browseLink: PropTypes.bool,
};

EditStorySpokespeople.defaultProps = {
  spokespeople: [],
};

export default injectIntl(EditStorySpokespeople, { forwardRef: true });
