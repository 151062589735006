import { combineReducers } from 'redux';

import analyticsCampaignReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-campaign-reducer';
import dashboardCombinedReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-combined-reducer';
import recentlyViewedAnalyticReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-last-viewed-reducer';
import archivedAnalyticReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-archived-reducer';
import deletedAnalyticReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-deleted-reducer';
import analyticsListFeedReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-feed-reducer';
import legacyAnalyticReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-legacy-reducer';
import analyticsListReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-reducer';
import ownedAnalyticReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-owned-reducer';
import sharedAnalyticReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-shared-reducer';
import analyticsSidebarReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-sidebar-reducer';
import starredAnalyticReducer from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-starred-reducer';
import articleListReducer from 'pages/WidgetDrilldown/drilldown-reducer';

import listFilterReducer from '../Analytics/AnalyticsListContainer/AnalyticsListActionBar/analytics-list-filter-reducer';
import selectedReducer from '../Analytics/AnalyticsListContainer/AnalyticsListActionBar/analytics-selected-reducer';

import dashboardHubReducer from './dashboard-hub-reducer';
import dashboardCreateReducer from './DashboardCreateWizard/dashboard-create-reducer';

const dashboardReducer = combineReducers({
  articleList: articleListReducer,
  combined: dashboardCombinedReducer,
  dashboardHub: dashboardHubReducer,
  all: analyticsListReducer,
  lastViewed: recentlyViewedAnalyticReducer,
  owned: ownedAnalyticReducer,
  shared: sharedAnalyticReducer,
  starred: starredAnalyticReducer,
  archived: archivedAnalyticReducer,
  deleted: deletedAnalyticReducer,
  legacy: legacyAnalyticReducer,
  allForFeed: analyticsListFeedReducer,
  selectedAnalytics: selectedReducer,
  listFilters: listFilterReducer,
  createForm: dashboardCreateReducer,
  sidebarCounts: analyticsSidebarReducer,
  campaigns: analyticsCampaignReducer,
});

export default dashboardReducer;
