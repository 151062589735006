/* eslint-disable max-len */

export const SUPPORTS_DATE_FILTERING = [
  'TOTAL_MENTIONS',
  'TOTAL_COVERAGE_VS_WEB_TRAFFIC',
  'PR_VS_NON_PR',
  'SHARE_OF_VOICE_TIME',
];

export const WIDGET_DESCRIPTIONS = {
  // example decription object:
  // TOTAL_MENTIONS: 'Total Mentions shows total coverage based on your search. One mention is equal to one piece of coverage.'
  TOTAL_MENTIONS:
    'Total Mentions shows total coverage based on your search. One mention is equal to one piece of coverage (you could have an article where your brand is talked about 5 times, but it still equals 1 mention in this chart).',
  TOTAL_COVERAGE_VS_WEB_TRAFFIC:
    'Total Coverage vs Total Web Traffic shows the potential impact your total news coverage has on your total website traffic. Using correlational analysis, you can find the media outlets, articles, and blog posts that are driving the most traffic to your website and that are causing the most conversions',
  PR_VS_NON_PR:
    "PR Web Traffic vs Non-PR Web Traffic shows you how much web traffic is from articles and blog posts with backlinks. It only shows traffic from articles and blog posts that we've linked directly from your Google Analytics to our database of articles.",
  SHARE_OF_VOICE:
    'Share of Voice Comparison shows how much content your main search is returning compared to any other topics of interest. It can also be used to measure the share of voice for coverage on different verticals in your industry or anything else you care about. The percentages you see with this widget are based off the total news returned for all the various searches in this widget.',
  KEY_MESSAGES:
    'Key Messages helps you find how many times key topics and phrases were mentioned as it relates to your main search. Simply type in any keyword (i.e. iPhone) for a simple search or click the magnifying glass to enter in a more complex boolean search.',
  TOP_ARTICLES:
    'Top Content allows you to select and showcase the content that is most valuable to you across the app.',
  TOP_PUBLISHERS_VISITS:
    'Top Publishers by Website Visitors shows the publications that contain articles that are driving the most number of unique visitors to your website. This includes traffic with and without backlinks. Therefore, it shows direct traffic, indirect traffic and referral traffic. The number of visitors shown is an aggregated total based on the search timeframe selected.',
  SOCIAL_MEDIA:
    'Social Amplification shows you how many times each news article and blog post from your search results has been shared across Facebook, LinkedIn, Twitter, Google+, and Pinterest.',
  SHARE_OF_VOICE_TIME:
    'Share of Voice Over Time shows the total coverage for your main search and total coverage for any competitors you enter in the Share of Voice Comparison widget.',
  SENTIMENTS:
    'Sentiment shows the tonality of your total coverage. Cision uses entity extraction and advanced Natural Language Processing (NLP) technology to determine the tonality of keywords throughout each piece of coverage.',
  INTERNATIONAL_MENTIONS:
    'International Mentions shows your total coverage broken down by country. Simply click on any country to view the individual articles, blog posts, etc., for each country.',
  US_MENTIONS_BY_LOCATION:
    'U.S. Mentions by Location shows you the top U.S. states where you have been mentioned.',
  LOCAL:
    'Local Coverage shows you a granular breakdown of how much coverage you have in each major U.S. city.',
  // AVE: 'Ad Value Equivalency shows how much the editorial coverage would cost if it were placed as an advertisement with the same media outlet. For online news and blogs, it is calculated by multiplying together the readership, potential viewership, and average ad cost. For television broadcasts, cost per minute is displayed as an estimate of the advertising cost for a minute of airtime in each broadcast segment (these values are provided by industry leader, SQAD).',
  US_VS_INTERNATIONAL_MENTIONS:
    'U.S. vs International Mentions shows how much of your total coverage is coming from publications in the United States compared to publications outside of the United States.',
  SEO_IMPACT:
    "SEO Impact is a measure of a website's domain authority which predicts how competitive it will be in Google search. The larger the SEO Impact score, the higher ranked that website will be in search engine results. Getting a mention in a high SEO impact publication means more visability for you brand.",
  TOP_PUBLISHERS_BY_ARTICLE_IMPACT:
    'Top Publishers by Impact allows you to see at a glance which publications are having greatest impact on awareness & engagement. These are publications with high readership, intensely positive or negative sentiment, and social sharing.',
  HIGHEST_READERSHIP:
    'Highest Readership displays the five articles in your set of coverage that were written in media outlets with the highest readership.',
  // AGGREGATE_READERSHIP: 'Aggregate Readership displays the total readership for each unique publisher in your search results.',
};

/* eslint-enable max-len */
