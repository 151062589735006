import PropTypes from 'prop-types';

export const propTypes = PropTypes.shape({
  // TODO: Type should be required, once API is fixed
  contactType: PropTypes.oneOf([
    /** Arbitrary email / name */
    'Custom',
    /** TK author */
    'Author',
    /** Cision OneInfluencer */
    'Influencer',
    /** TK outreach integration */
    'User',
    /** TODO: Remove null handling when the API is patched */
    null,
  ]),
  email: PropTypes.string.isRequired,
  /** Influencers use strings, contacts use numbers */
  // TODO: Switch service-outreach to use string IDs and convert contact IDs to strings.
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
});

export const defaultProps = {
  contactType: 'Custom',
  id: null,
  name: '',
};

export default {
  propTypes,
  defaultProps,
};
