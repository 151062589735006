/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';

interface ModalBodyProps {
  className?: ClassValue;
  scrollable?: boolean;
  tag?: React.ElementType;
}

export type ModalBodyType = React.FC<ModalBodyProps>;

const ModalBody: ModalBodyType = ({
  className = '',
  tag: Tag = 'div',
  scrollable = false,
  ...attributes
}) => {
  const classes = classNames(className, 'tk-modal__body', {
    'tk-modal__body--scrollable': scrollable,
  });

  return <Tag {...attributes} className={classes} />;
};

export default ModalBody;
