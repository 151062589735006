import { combineReducers } from 'redux';

import keywordStreamBuilder from './KeywordStreamSearchBuilder';
import profileStreamSearchBuilder from './ProfileStreamSearchBuilder';
import sidebar from './socialSearch';

export default combineReducers({
  sidebar,
  keywordStreamBuilder,
  profileStreamSearchBuilder,
});
