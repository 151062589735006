import React from 'react';
import PropTypes from 'prop-types';
import reactHtmlParser, { convertNodeToElement } from 'react-html-parser';

const validNodes = [
  'div',
  'p',
  'ul',
  'ol',
  'li',
  'a',
  'br',
  'span',
  'strong',
  'em',
  'u',
];

const transform = node => {
  if (node.type === 'tag' && !validNodes.includes(node.name)) {
    return null;
  }

  // use default tranform by returning undefined
  return undefined;
};

const transformDisableLinks = (node, index) => {
  if (node.type === 'tag' && !validNodes.includes(node.name)) {
    return null;
  }

  if (node.name === 'a') {
    delete node.attribs.href;
    return convertNodeToElement(node, index, transformDisableLinks);
  }

  return undefined;
};

const parseHtml = (html, disableLinks) =>
  reactHtmlParser(html, {
    transform: disableLinks ? transformDisableLinks : transform,
  });

const WysiwygDisplay = ({ content, disableLinks }) => {
  const parsedHtml = parseHtml(content, disableLinks);
  return (
    <div
      className={`tk-wysiwyg-display${
        disableLinks ? ' tk-wysiwyg-display--disabled-links' : ''
      }`}
      data-ignore-pdf-export-correction-styles
    >
      {parsedHtml}
    </div>
  );
};

WysiwygDisplay.propTypes = {
  content: PropTypes.string,
  disableLinks: PropTypes.bool,
};

WysiwygDisplay.defaultProps = {
  content: '',
  disableLinks: false,
};

export default WysiwygDisplay;
