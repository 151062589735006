import uniqBy from 'lodash/uniqBy';

import {
  hydrateWidgetData,
  sortDataByDataSources,
} from 'components/widgetV3/utils';
import { REPORT_PRESENTATION_URL, WIDGET_TYPES } from 'constants/constants';

import { featureFilterData } from 'pages/Dashboard/dashboard-hub-reducer';

import { SLIDE_LAYOUT } from './constants';

const CHART_HEIGHT_OFFSET = 204;
const CHART_HEIGHT_OFFSET_VIEW_MODE = 224;
const CHART_TRESHOLD = 150;

export const buildReportShareLink = report =>
  report.legacy
    ? `report?id=${report.uuid}`
    : `${REPORT_PRESENTATION_URL}/${report.uuid}`;

export const getChartHeightSlide = ({
  gridHeight,
  gridWidth,
  colWidth,
  rowHeight,
  isPieChartType,
  viewMode,
}) => {
  const currentChartHeightOffset = viewMode
    ? CHART_HEIGHT_OFFSET_VIEW_MODE
    : CHART_HEIGHT_OFFSET;

  let chartHeight = 0;
  if (
    gridHeight > gridWidth ||
    (gridHeight === gridWidth && isPieChartType && !viewMode)
  ) {
    chartHeight = (colWidth * gridWidth) / 2;
  } else {
    let maxHeight = rowHeight * gridHeight - CHART_TRESHOLD;
    if (maxHeight < 0) {
      maxHeight = 0;
    }
    chartHeight = (rowHeight * gridHeight) / 2 + currentChartHeightOffset;
    if (chartHeight > maxHeight) {
      chartHeight = maxHeight;
    }
  }

  return chartHeight;
};

export const getCampaignById = (selectedCampaignId, allCampaigns = []) => {
  if (!allCampaigns) return undefined;

  const campaignFound = allCampaigns.find(
    campaign => campaign.id === selectedCampaignId,
  );

  return campaignFound
    ? { id: campaignFound.id, title: campaignFound.title }
    : undefined;
};

export const leaveSelectedCampaignsOnly = (
  selectedCampaigns,
  reportCampaigns,
) => {
  if (!reportCampaigns.length) {
    return selectedCampaigns;
  }
  return selectedCampaigns.filter(
    campaign => !reportCampaigns.some(r => r.id === campaign),
  );
};

export const isReportOwned = report => {
  const userId = window.activeUser?.id;
  const { shared, owner } = report;
  return userId === owner?.id || shared;
};

export const getWidgetsFromSlides = slides => {
  const widgets = [];
  // eslint-disable-next-line no-unused-expressions
  slides?.forEach(slide => {
    // eslint-disable-next-line no-unused-expressions
    slide.layout?.forEach(slideTile => {
      if (
        slideTile.contentType === WIDGET_TYPES.widgetV3 &&
        slideTile.content?.id &&
        slideTile.content?.title
      ) {
        widgets.push({
          id: slideTile.content.id,
          title: slideTile.content.title,
        });
      }
    });
  });
  return uniqBy(widgets, 'id');
};

export const buildSlideTile = (
  id,
  gridX,
  gridY,
  gridWidth,
  gridHeight,
  contentType,
  content,
) => {
  return {
    id,
    gridX,
    gridY,
    gridWidth,
    gridHeight,
    contentType,
    content,
  };
};

export const buildSlideTilePayload = (
  gridX,
  gridY,
  gridWidth,
  gridHeight,
  contentType,
  contentId,
) => {
  return {
    gridX,
    gridY,
    gridWidth,
    gridHeight,
    contentType,
    contentId,
  };
};

export const getProcessedWidgetData = (widget, widgetData) => {
  const hydratedChartData = hydrateWidgetData(widget, widgetData);
  return sortDataByDataSources(featureFilterData(hydratedChartData));
};

export const hasTileAvailable = slideTilesMap => {
  return !!slideTilesMap && slideTilesMap.indexOf(false) >= 0;
};

export const getAvailableTileInSlide = slideTilesMap => {
  const index = slideTilesMap.indexOf(false);
  const gridY = Math.floor(index / SLIDE_LAYOUT.maxColumns);
  const gridX = index - gridY * SLIDE_LAYOUT.maxColumns;
  return { gridX, gridY };
};

export const getSlideTilesStateMap = slide => {
  const tempSpaces = Array(SLIDE_LAYOUT.maxRows * SLIDE_LAYOUT.maxColumns).fill(
    false,
  );
  // eslint-disable-next-line no-unused-expressions
  slide?.layout?.forEach(tile => {
    const initialY = tile.gridY;
    const initialX = tile.gridX;
    for (let y = initialY; y < initialY + tile.gridHeight; y++) {
      for (let x = initialX; x < initialX + tile.gridWidth; x++) {
        const index = y * SLIDE_LAYOUT.maxColumns + x;
        tempSpaces[index] = true;
      }
    }
  });
  return tempSpaces;
};
