import './list-visor.scss';

import Body from './list-visor-body';
import Controls from './list-visor-controls';
import DateSelector from './list-visor-date-selector';
import Drawer from './list-visor-drawer';
import FilterPill from './list-visor-filter-pill';
import Head from './list-visor-head';
import ListVisor from './ListVisor';

// TS-friendly way to extend - https://stackoverflow.com/a/60021394/1339100
export default Object.assign(ListVisor, {
  Head,
  Body,
  Controls,
  Drawer,
  FilterPill,
  DateSelector,
});
