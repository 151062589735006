export default {
  addAssetsToStoryMessage: {
    id: 'STORY-DOWNLOADABLE-ASSETS_ADD_ASSETS_TO_STORY_MESSAGE',
    defaultMessage:
      'Add any downloadable assets relevant to this story. For example, product shots or executive headshots.',
    description:
      'Campaigns > Story Kit > Story Edit Page > Downloadable Assets: text to add downloadable assets',
  },
  uploadAssets: {
    id: 'STORY-DOWNLOADABLE-ASSETS_UPLOAD_ASSETS',
    defaultMessage: 'Upload Assets (PNG, JPG, BMP, PDF, SVG)',
    description:
      'Campaigns > Story Kit > Story Edit Page > Downloadable Assets: upload assets link',
  },
};
