import { createSelector } from 'reselect';

export const preferencesObj = state => state.userPreferences || {};

export const arrangeableColumnsSelector = createSelector(
  preferencesObj,
  preferences => preferences?.arrangeableColumns || [],
);

export const widgetDrilldownArrangeableColumnsSelector = createSelector(
  arrangeableColumnsSelector,
  arrangeableColumns => arrangeableColumns.widgetDrilldown || [],
);

export const earnedSearchArrangeableColumnsSelector = createSelector(
  arrangeableColumnsSelector,
  arrangeableColumns => arrangeableColumns.earnedSearch || [],
);

export const tagArrangeableColumnsSelector = createSelector(
  arrangeableColumnsSelector,
  arrangeableColumns => arrangeableColumns.tag || [],
);

export const fallbackArrangeableColumnsSelector = createSelector(
  arrangeableColumnsSelector,
  arrangeableColumns => arrangeableColumns.fallback || [],
);
