import React, { Component } from 'react';

import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import { injectIntl } from 'react-intl';
import { Control } from 'react-redux-form';

import { WysiwygEditor, WysiwygToolbar } from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';

// i18n
import messages from './EditStoryCompanyBackground.messages';

class EditStoryCompanyBackground extends Component {
  handleClickOutside() {
    this.props.onEndEditing();
  }

  render() {
    const { debounce, focus, inputModelName, updateOn, intl } = this.props;
    const safeDebounce =
      updateOn.indexOf('blur') < 0 && updateOn.indexOf('focus') < 0
        ? debounce
        : null; // Debounce makes change events act weird on blur and focus

    return (
      <div>
        <WysiwygToolbar
          className="tk-story-company-background__edit-toolbar"
          id="story-company-background"
        />
        <StorySection>
          <StorySectionHeader
            label={intl.formatMessage(messages.editStoryheader)}
          />
          <div className="tk-story-company-background">
            <div className="tk-story-company-background__text tk-story-company-background__text--edit">
              <Control.text
                component={WysiwygEditor}
                controlProps={{
                  focus,
                  toolbarId: 'story-company-background',
                }}
                debounce={safeDebounce}
                id="tk-story-content-input"
                model={`.${inputModelName}`}
                updateOn={updateOn}
                validateOn={updateOn}
              />
            </div>
          </div>
        </StorySection>
      </div>
    );
  }
}

EditStoryCompanyBackground.propTypes = {
  debounce: PropTypes.number,
  focus: PropTypes.bool.isRequired,
  inputModelName: PropTypes.string,
  onEndEditing: PropTypes.func.isRequired,
  updateOn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

EditStoryCompanyBackground.defaultProps = {
  debounce: 500,
  inputModelName: 'companybackground',
  updateOn: 'change',
};

export default enhanceWithClickOutside(
  injectIntl(EditStoryCompanyBackground, { forwardRef: true }),
);
