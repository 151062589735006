import React from 'react';

import T from 'i18n/TranslatedMessage';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Button, Body, DisplaySM, SvgIcon } from '@trendkite/ui';

import StorySection, { StorySectionHeader } from '../story-section';

// i18n

import messages from './StoryContactDisplay.messages';

const StoryContactDisplay = ({
  pressContact,
  isEditable,
  isStoryPressContactValid,
  errors,
}) => {
  const intl = useIntl();
  const baseClass = 'tk-story-contact';

  if (!isStoryPressContactValid) {
    return (
      <StorySection>
        <StorySectionHeader label={intl.formatMessage(messages.title)} />
        <div className={baseClass}>
          <Body color="disabled">
            <T {...messages.placeholder} />
          </Body>
          {isEditable && (
            <Button modifiers={['calltoaction', 'inline-flex']} active>
              <SvgIcon icon="plus" width={20} height={20} />
              <span className={`${baseClass}__button-text`}>
                <T {...messages.addContact} />
              </span>
            </Button>
          )}
          {isEditable && errors && errors.isRequired && (
            <div className={`${baseClass}__errors`}>
              <Body color="danger">
                <T {...messages.primaryContactRequired} />
              </Body>
            </div>
          )}
        </div>
      </StorySection>
    );
  }

  const { firstName, lastName, company, email, phone, address } = pressContact;

  const linkProps = isEditable
    ? {}
    : {
        href: `mailto:${email}`,
        rel: 'noopener noreferrer',
        target: '_blank',
      };

  return (
    <StorySection>
      <StorySectionHeader label="Contact" />
      <div className={baseClass}>
        <div className={`${baseClass}__name`}>
          <DisplaySM>
            {firstName} {lastName}
          </DisplaySM>
        </div>
        <div className={`${baseClass}__company`}>
          <Body color="light">{company}</Body>
        </div>
        <div className={`${baseClass}__email`}>
          <a className={`${baseClass}__email-link`} {...linkProps}>
            <Body color="link">{email}</Body>
          </a>
        </div>
        <div className={`${baseClass}__phone`}>
          <Body color="light">{phone}</Body>
        </div>
        <div className={`${baseClass}__address`}>
          <Body color="light">{address}</Body>
        </div>
      </div>
    </StorySection>
  );
};

StoryContactDisplay.propTypes = {
  pressContact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  isStoryPressContactValid: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    isRequired: PropTypes.bool,
  }),
};

StoryContactDisplay.defaultProps = {
  pressContact: null,
  isEditable: false,
  errors: null,
};

export default StoryContactDisplay;
