import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import moment from 'moment';

import {
  SourceTypes,
  SubjectTypes,
} from 'components/activity-feed/activity-types';
import { ACTOR_TYPE, AUDIENCE_TYPES } from 'constants/activities';
import {
  ACTIVITIES_ENDPOINT,
  ACTIVITY_TYPES_ENDPOINT,
  INFLUENCER_HUB_ENDPOINT,
  INFLUENCER_ACTIVITY_SEARCH_ENDPOINT,
} from 'constants/apis';
import { DEV_FEATURES } from 'constants/constants';
import { influencerProfileSelector } from 'pages/Influencers/InfluencerProfile/selectors';
import {
  addPageMessage,
  addPageMessageWithDefaultTimeout,
  Message,
} from 'reducers/page-messages';
import {
  hasDevFeatureFlagSelector,
  userIdSelector,
  userFirstNameSelector,
} from 'selectors/account';
import {
  activityCreateFormValuesSelector,
  getActivityFeedFilteredViewSelectorById,
} from 'selectors/activity-feed';
import {
  performGet,
  performPut,
  performDelete,
  performPost,
} from 'services/rest-service/rest-service';
import { defaultProps as defaultActivityFilterProps } from 'types/activity-filter';
import { getTimestampsForAnyRange } from 'utils/date/date-util';

import { roundDown15MinUtc } from 'utils/times/times';

import { performPost as mockPost } from './mockEndpoints';

export const CLEAR_FILTERED_VIEW_TYPEAHEAD =
  'activity-feed/CLEAR_FILTERED_VIEW_TYPEAHEAD';
export const CREATE_FILTERED_VIEW = 'activity-feed/CREATE_FILTERED_VIEW';
export const GET_FILTERED_VIEW_TYPEAHEAD =
  'activity-feed/GET_FILTERED_VIEW_TYPEAHEAD';
export const GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS =
  'activity-feed/GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS';
export const UPDATE_FILTERED_VIEW = 'activity-feed/UPDATE_FILTERED_VIEW';
export const GET_ACTIVITY_TYPES = 'activity-feed/GET_ACTIVITY_TYPES';
export const GET_ACTIVITY_TYPES_ERROR =
  'activity-feed/GET_ACTIVITY_TYPES_ERROR';
export const UPDATE_FILTERED_VIEW_WITH_RESULTS =
  'activity-feed/UPDATE_FILTERED_VIEW_WITH_RESULTS';
export const UPDATE_FILTERED_VIEW_WITH_ERROR =
  'activity-feed/UPDATE_FILTERED_VIEW_WITH_ERROR';

export const SAVE_ACTIVITY = 'activity-feed/SAVE_ACTIVITY';
export const SAVE_ACTIVITY_ERROR = 'activity-feed/SAVE_ACTIVITY_ERROR';
export const SAVE_ACTIVITY_SUCCESS = 'activity-feed/SAVE_ACTIVITY_SUCCESS';

export const REMOVE_ACTIVITY = 'activity-feed/REMOVE_ACTIVITY';
export const REMOVE_ACTIVITY_ERROR = 'activity-feed/REMOVE_ACTIVITY_ERROR';
export const REMOVE_ACTIVITY_SUCCESS = 'activity-feed/REMOVE_ACTIVITY_SUCCESS';

export const UPDATE_ACTIVITY = 'activity-feed/UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_ERROR = 'activity-feed/UPDATE_ACTIVITY_ERROR';
export const UPDATE_ACTIVITY_SUCCESS = 'activity-feed/UPDATE_ACTIVITY_SUCCESS';

const initialState = {
  filteredViewsById: {},
  savingActivity: false,
  savingActivityError: null,
  currentPage: 1,
};

const activityFeedReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CLEAR_FILTERED_VIEW_TYPEAHEAD: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            loadingTypeaheadActivity: false,
            typeaheadActivities: [],
          },
        },
      };
    }
    case CREATE_FILTERED_VIEW: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...action.payload.filteredView,
            loading: true,
          },
        },
      };
    }
    case GET_FILTERED_VIEW_TYPEAHEAD: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            loadingTypeaheadActivity: true,
          },
        },
      };
    }
    case GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            loadingTypeaheadActivity: false,
            typeaheadActivities: [...action.payload.activities],
          },
        },
      };
    }
    case REMOVE_ACTIVITY: {
      return {
        ...state,
        removingActivity: true,
        removingActivityError: null,
      };
    }
    case REMOVE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        removingActivity: false,
        removingActivityError: null,
      };
    }
    case REMOVE_ACTIVITY_ERROR: {
      return {
        ...state,
        removingActivity: false,
        removingActivityError: true,
      };
    }
    case UPDATE_ACTIVITY: {
      return {
        ...state,
        updatingActivity: true,
        updatingActivityError: null,
      };
    }
    case UPDATE_ACTIVITY_ERROR: {
      return {
        ...state,
        updatingActivity: false,
        updatingActivityError: action.payload.error,
      };
    }
    case UPDATE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        updatingActivity: false,
        updatingActivityError: null,
      };
    }
    case SAVE_ACTIVITY: {
      return {
        ...state,
        savingActivity: true,
        savingActivityError: null,
      };
    }
    case SAVE_ACTIVITY_ERROR: {
      return {
        ...state,
        savingActivity: false,
        savingActivityError: action.payload.error,
      };
    }
    case SAVE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        savingActivity: false,
        savingActivityError: null,
      };
    }
    case UPDATE_FILTERED_VIEW: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            ...action.payload.filteredView,
            loading: true,
          },
        },
        currentPage: action.payload.pageNumber,
      };
    }
    case UPDATE_FILTERED_VIEW_WITH_ERROR: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            error: true,
            activities: [],
            loading: false,
          },
        },
      };
    }
    case UPDATE_FILTERED_VIEW_WITH_RESULTS: {
      return {
        ...state,
        filteredViewsById: {
          ...state.filteredViewsById,
          [action.payload.id]: {
            ...state.filteredViewsById[action.payload.id],
            error: false,
            activities: action.payload.activities,
            count: action.payload.count,
            totalPages: action.payload.totalPages,
            loading: false,
          },
        },
      };
    }
    case GET_ACTIVITY_TYPES: {
      return {
        ...state,
        activityTypes: action.payload.activityTypes,
      };
    }
    case GET_ACTIVITY_TYPES_ERROR: {
      return {
        ...state,
        activityTypesError: action.payload.error,
      };
    }
    default:
      return state;
  }
};

const getDatesForQuery = filters => {
  const dates = {};

  if (filters.dateRangeType === 'CUSTOM') {
    dates.endDate = filters.endDate;
    dates.startDate = filters.startDate;
  }
  if (
    filters.dateRangeType !== 'ALL_TIME' &&
    filters.dateRangeType !== 'CUSTOM'
  ) {
    const datesForRange = getTimestampsForAnyRange(filters.dateRangeType);
    dates.startDate = datesForRange.startTime;
    dates.endDate = datesForRange.endTime;
  }
  return dates;
};

const buildMomentHappened = time => roundDown15MinUtc(moment(time).valueOf());
const buildDateHappenedTimeStamp = time => buildMomentHappened(time).valueOf();
const buildDateHappenedIsoString = time => buildMomentHappened(time).format(); // '2019-12-09T15:04:05+04:00',

export const getFilteredViewTypeaheadActionCreator = ({
  audienceType: customAudienceType,
  id,
  typeaheadFilteredViewProps,
}) => async (dispatch, getState) => {
  const audienceType = customAudienceType || AUDIENCE_TYPES.author;
  const state = getState();

  dispatch({
    type: GET_FILTERED_VIEW_TYPEAHEAD,
    payload: { id },
  });

  const dates = getDatesForQuery(typeaheadFilteredViewProps.filters);
  const useMockData = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.activityFeedMockData,
  );
  const currentContact =
    state.contacts && state.contacts.current
      ? state.contacts.contacts[state.contacts.current]
      : null;
  const contactId = currentContact && currentContact.id;

  const query = {
    pagination: {
      offset: 0,
      limit: 5,
    },
    sort: {
      field: 'date_happened',
      order: 'Desc',
    },
    search: typeaheadFilteredViewProps.search,
    filters: {
      ...omit(typeaheadFilteredViewProps.filters, [
        'startDate',
        'endDate',
        'dateRangeType',
      ]),
      ...dates,
      audienceIds: [contactId],
      actorType: ACTOR_TYPE,
      audienceType,
    },
  };

  let activities = [];
  let count = 0;

  if (useMockData) {
    const { data } = await mockPost(`${ACTIVITIES_ENDPOINT}/query`, {
      audienceType,
    });
    activities = data.activities;
    count = data.count;
  } else {
    try {
      const { data } = await performPost(`${ACTIVITIES_ENDPOINT}/query`, query);
      const results = data.results[0];
      activities = results && results.activities;
      count = activities && activities.length;
    } catch (e) {
      return dispatch({
        type: UPDATE_FILTERED_VIEW_WITH_ERROR,
        payload: { id },
      });
    }
  }

  return dispatch({
    type: GET_FILTERED_VIEW_TYPEAHEAD_SUCCESS,
    payload: { id, activities, count },
  });
};

export const clearFilteredViewTypeaheadActionCreator = ({ id }) => ({
  type: CLEAR_FILTERED_VIEW_TYPEAHEAD,
  payload: { id },
});

export const getActivityTypesActionCreator = () => async dispatch => {
  let types;

  try {
    const { data } = await performGet(ACTIVITY_TYPES_ENDPOINT);
    types = data.activityTypes;
  } catch (error) {
    return dispatch({
      type: GET_ACTIVITY_TYPES_ERROR,
      payload: {
        error: true,
      },
    });
  }

  return dispatch({
    type: GET_ACTIVITY_TYPES,
    payload: {
      activityTypes: types,
    },
  });
};

export const updateFilteredViewActionCreator = ({
  audienceType: customAudienceType,
  id,
  filteredView,
}) => async (dispatch, getState) => {
  const { filters, pagination, sort, search } = filteredView;
  const audienceType = customAudienceType || AUDIENCE_TYPES.author;
  const state = getState();
  const useMockData = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.activityFeedMockData,
  );
  const dates = getDatesForQuery(filteredView.filters);

  let query;
  let body;

  let activities = [];
  let count = 0;
  let totalPages = 0;

  dispatch({
    type: UPDATE_FILTERED_VIEW,
    payload: {
      id,
      filteredView: {
        filters,
        pagination,
        sort,
        search,
      },
      pageNumber: Math.floor(pagination.offset / pagination.limit) + 1,
    },
  });
  const influencer = influencerProfileSelector(state).info;
  const influencerId = influencer?.id || '';

  if (audienceType === AUDIENCE_TYPES.influencer && !isEmpty(influencerId)) {
    let occurredDates = {};
    if (!isEmpty(dates)) {
      occurredDates = {
        occurredStart: new Date(dates.startDate),
        occurredEnd: new Date(dates.endDate),
      };
    }

    body = {
      source: {
        sourceExternalId: influencerId,
      },
      subject: [
        {
          subjectExternalId: influencerId,
        },
      ],
      from: pagination.offset,
      size: pagination.limit,
      sortBy: 'occurred_at',
      sortOrder: sort.order,
      ...occurredDates,
    };
  }
  if (audienceType === AUDIENCE_TYPES.author) {
    const currentContact =
      state.contacts && state.contacts.current
        ? state.contacts.contacts[state.contacts.current]
        : null;
    const contactId = currentContact && currentContact.id;

    query = {
      pagination: {
        limit: pagination.limit,
        offset: pagination.offset,
      },
      sort,
      search,
      filters: {
        ...omit(filters, ['startDate', 'endDate', 'dateRangeType']),
        ...dates,
        audienceIds: [contactId],
        actorType: ACTOR_TYPE,
        audienceType,
      },
    };
  }

  if (useMockData) {
    const { data } = await mockPost(`${ACTIVITIES_ENDPOINT}/query`, {
      audienceType,
    });
    activities = data.activities;
    count = data.count;
  } else {
    try {
      if (
        audienceType === AUDIENCE_TYPES.influencer &&
        !isEmpty(influencerId)
      ) {
        const {
          data: { hits = [], total = 0, totalPages: totalActPages = 0 },
        } = await performPost(INFLUENCER_ACTIVITY_SEARCH_ENDPOINT, body);
        activities = hits;
        count = total;
        totalPages = totalActPages;
      }
      if (audienceType === AUDIENCE_TYPES.author) {
        const { data } = await performPost(
          `${ACTIVITIES_ENDPOINT}/query`,
          query,
        );
        const results = data.results[0];
        activities = results && results.activities;
        count = activities && activities.length;
      }
    } catch (e) {
      return dispatch({
        type: UPDATE_FILTERED_VIEW_WITH_ERROR,
        payload: { id },
      });
    }
  }

  return dispatch({
    type: UPDATE_FILTERED_VIEW_WITH_RESULTS,
    payload: { id, activities, count, totalPages },
  });
};

export const createFilteredViewActionCreator = ({
  audienceType,
  id,
  nextFilteredViewProps,
}) => async dispatch => {
  const filteredView = {
    ...defaultActivityFilterProps,
    ...nextFilteredViewProps,
  };

  await dispatch({
    type: CREATE_FILTERED_VIEW,
    payload: { id, filteredView },
  });

  return dispatch(
    updateFilteredViewActionCreator({ audienceType, id, filteredView }),
  );
};

export const updateActivityActionCreator = ({
  activity,
  audienceType,
  filteredViewId,
}) => async (dispatch, getState) => {
  const state = getState();
  const updateActivityFormData = activityCreateFormValuesSelector(state);
  const { dateCreated, contentJson, id } = activity || {};
  const { metadata } = contentJson || {};
  let body;
  let influencerId;

  dispatch({
    type: UPDATE_ACTIVITY,
  });

  if (audienceType === AUDIENCE_TYPES.influencer) {
    const { info: influencer } = influencerProfileSelector(state);
    influencerId = (influencer && influencer.id) || ''; // In the fallback '' case, the API returns an error, and the normal error kite pops.

    const userId = userIdSelector(state);
    const firstName = userFirstNameSelector(state);
    const {
      description,
      time,
      type,
      activitySubtypeId,
    } = updateActivityFormData;
    body = {
      id,
      activityTypeId: type[0].id,
      activitySubtypeId,
      source: {
        sourceTypeId: SourceTypes.USER,
        sourceExternalId: userId,
      },
      subject: [
        {
          subjectTypeId: SourceTypes.INFLUENCER,
          subjectExternalId: influencerId,
        },
      ],
      automated: false,
      description,
      data: getActivityData(firstName, influencer),
      occurredAt: buildDateHappenedIsoString(time[0]),
    };
  } else {
    body = {
      id,
      activityType: updateActivityFormData.type[0].id,
      dateCreated,
      dateHappened: buildDateHappenedTimeStamp(updateActivityFormData.time[0]),
      contentJson: {
        text: updateActivityFormData.description,
        metadata,
      },
    };
  }

  try {
    if (audienceType === AUDIENCE_TYPES.influencer) {
      await performPost(`${INFLUENCER_HUB_ENDPOINT}/activity`, body);
    } else {
      await performPut(`${ACTIVITIES_ENDPOINT}/${id}`, body);
    }

    dispatch({
      type: UPDATE_ACTIVITY_SUCCESS,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'All set. Your activity was updated successfully.',
        status: 'success',
      }),
    );

    const filteredView = getActivityFeedFilteredViewSelectorById(
      filteredViewId,
    )(state);
    dispatch(
      updateFilteredViewActionCreator({
        id: filteredViewId,
        filteredView: {
          ...filteredView,
        },
        audienceType,
      }),
    );
  } catch (e) {
    dispatch({
      type: UPDATE_ACTIVITY_ERROR,
      payload: {
        error: e.response && e.response.data,
      },
    });

    dispatch(
      addPageMessage(
        new Message({
          status: 'danger',
          text: 'Sorry, that activity could not be updated. Please try again.',
        }),
      ),
    );
  }
};

export const createActivityActionCreator = ({
  audienceType,
  filteredViewId,
}) => async (dispatch, getState) => {
  const state = getState();
  const createActivityFormData = activityCreateFormValuesSelector(state);
  let body;
  let influencerId;

  dispatch({
    type: SAVE_ACTIVITY,
  });

  if (audienceType === AUDIENCE_TYPES.influencer) {
    const { info: influencer } = influencerProfileSelector(state);
    influencerId = (influencer && influencer.id) || ''; // In the fallback '' case, the API returns an error, and the normal error kite pops.

    const userId = userIdSelector(state);
    const firstName = userFirstNameSelector(state);
    const {
      description,
      time,
      type,
      activitySubtypeId,
    } = createActivityFormData;
    body = {
      activityTypeId: type[0].id,
      activitySubtypeId,
      source: {
        sourceTypeId: SourceTypes.USER,
        sourceExternalId: userId,
      },
      subject: [
        {
          subjectTypeId: SubjectTypes.INFLUENCER,
          subjectExternalId: influencerId,
        },
      ],
      automated: false,
      description,
      data: getActivityData(firstName, influencer),
      occurredAt: buildDateHappenedIsoString(time[0]),
    };
  } else {
    const currentContact =
      state.contacts && state.contacts.current
        ? state.contacts.contacts[state.contacts.current]
        : null;

    body = {
      type: createActivityFormData.type[0].id,
      audienceType: AUDIENCE_TYPES.author,
      text: createActivityFormData.description,
      timestamp: buildDateHappenedTimeStamp(createActivityFormData.time[0]),
      audiences: [
        {
          id: currentContact.id,
          name: currentContact.fullName,
        },
      ],
    };
  }

  try {
    if (audienceType === AUDIENCE_TYPES.influencer) {
      await performPost(`${INFLUENCER_HUB_ENDPOINT}/activity`, body);
    } else {
      await performPost(`${ACTIVITIES_ENDPOINT}`, body);
    }

    dispatch({
      type: SAVE_ACTIVITY_SUCCESS,
    });

    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'All set. Your activity was saved successfully.',
        status: 'success',
      }),
    );

    const filteredView = getActivityFeedFilteredViewSelectorById(
      filteredViewId,
    )(state);
    dispatch(
      updateFilteredViewActionCreator({
        audienceType,
        filteredView: {
          ...filteredView,
        },
        id: filteredViewId,
      }),
    );
  } catch (e) {
    dispatch({
      type: SAVE_ACTIVITY_ERROR,
      payload: {
        error: e.response && e.response.data,
      },
    });

    dispatch(
      addPageMessage(
        new Message({
          status: 'danger',
          text: 'Sorry, that activity could not be saved. Please try again.',
        }),
      ),
    );
  }
};

export const removeActivityActionCreator = ({
  activityId,
  audienceType,
}) => async dispatch => {
  dispatch({
    type: REMOVE_ACTIVITY,
  });

  try {
    if (audienceType === AUDIENCE_TYPES.influencer) {
      await performDelete(`${INFLUENCER_HUB_ENDPOINT}/activity/${activityId}`);
    } else {
      await performDelete(`${ACTIVITIES_ENDPOINT}/${activityId}`);
    }

    dispatch({
      type: REMOVE_ACTIVITY_SUCCESS,
    });
    dispatch(
      addPageMessageWithDefaultTimeout({
        text: 'All set. Your activity was removed successfully.',
        status: 'success',
      }),
    );
  } catch (error) {
    dispatch({
      type: REMOVE_ACTIVITY_ERROR,
    });
    dispatch(
      addPageMessage(
        new Message({
          status: 'danger',
          text: 'Sorry, that activity could not be removed. Please try again.',
        }),
      ),
    );
  }
};

const getActivityData = (username, influencer) => {
  const { isPrivate, name, influencerTypes, countryName } = influencer;
  return `User: ${username} - ${
    isPrivate ? 'Private ' : ''
  }Influencer: ${name} - InfluencerTypes: ${influencerTypes.join(
    ',',
  )} - Country: ${countryName}`;
};

export default activityFeedReducer;
