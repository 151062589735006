import React, { useMemo, useState } from 'react';

import { Options } from '@popperjs/core/lib/popper-lite';
import { usePopper } from 'react-popper';

import PopperDropdownContext, { PopperData } from './context';
import PopperContent from './PopperContent';
import PopperTrigger from './PopperTrigger';

import './popper-dropdown.scss';

interface PopperDropdownProps {
  isOpen: boolean;
  usePortal?: boolean;
  options: Options;
  children: React.ReactNode;
  portalNode?: HTMLElement;
}

type PopperDropdownType = React.FC<PopperDropdownProps> & {
  Trigger: typeof PopperTrigger;
  Content: typeof PopperContent;
};

const DEFAULT_OPTIONS: Options = {
  placement: 'bottom-end',
  strategy: 'absolute',
  modifiers: [],
};

const PopperDropdown: PopperDropdownType = ({
  isOpen,
  options = {},
  usePortal = false,
  portalNode,
  children,
}) => {
  const [triggerRef, setTriggerRef] = useState(null);
  const [popperRef, setPopperRef] = useState<any>(null);
  const { styles, attributes } = usePopper(triggerRef, popperRef, {
    ...DEFAULT_OPTIONS,
    ...options,
  });

  const popperData: PopperData = useMemo(
    () => ({
      styles: { ...styles.popper },
      attributes: { ...attributes.popper },
    }),
    [styles, attributes],
  );

  return (
    <PopperDropdownContext.Provider
      value={{
        isOpen: isOpen,
        usePortal: usePortal,
        popperData,
        portalNode,
        setTriggerRef: setTriggerRef,
        setPopperRef: setPopperRef,
      }}
    >
      {children}
    </PopperDropdownContext.Provider>
  );
};

PopperDropdown.Trigger = PopperTrigger;
PopperDropdown.Content = PopperContent;

export default PopperDropdown;
