import React from 'react';

import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Button from '../../button';
import Media from '../../media';
import SvgIcon from '../../svg-icon';
import Tooltip from '../../tooltip';

// i18n
import messages from './ListVisorControls.messages';

export const BulkActions = props => (
  <React.Fragment>
    <Media.Item {...props} />
    <Media.Body />
  </React.Fragment>
);

export const Filters = ({
  children,
  modifiers,
  listVisorControlsMarginRight = '20px',
}) => {
  const maxWidth =
    modifiers && modifiers.includes('large-max-width') ? '340px' : '500px';
  if (React.Children.count(children) === 0) {
    return null;
  }

  return React.Children.map(
    children,
    child =>
      child && (
        <Media.Item
          key={child.key}
          style={{
            position: 'relative',
            marginRight: listVisorControlsMarginRight,
            maxWidth,
          }}
        >
          {child}
        </Media.Item>
      ),
  );
};

const ListVisorControls = props => {
  const {
    children,
    filterCount,
    hasFilters,
    hasViewSelection,
    isListViewSelected,
    isDrawerOpen,
    onToggleCallback,
    onToggleListViewCallback,
    style,
    ...passprops
  } = props;

  const intl = useIntl();

  let svgClass = '';
  const modifiers = ['inline-flex', 'seethrough'];
  if (filterCount > 0) {
    svgClass = `${ListVisorControls.baseClass}__filter-dropdown--active`;
    modifiers.push('bold-text');
  } else if (isDrawerOpen) {
    svgClass = `${ListVisorControls.baseClass}__filter-dropdown--open`;
  }

  let filterButton = null;
  let viewSelectionButton = null;

  if (hasFilters) {
    filterButton = (
      <Button
        data-qa="jrJIyIxva49OFr1QvOZng"
        modifiers={modifiers}
        onClick={onToggleCallback}
      >
        <span style={{ display: 'inline-flex' }} className={svgClass}>
          <SvgIcon
            icon="filter"
            height={20}
            width={20}
            modifiers={['right-small']}
          />
        </span>
        <TranslatedMessage
          {...messages.buttonText}
          values={{ COUNT: filterCount }}
          tagName="div"
        />
        <SvgIcon
          icon={isDrawerOpen ? 'chevronUp' : 'chevronDown'}
          modifiers={['left-small']}
        />
      </Button>
    );
  }

  if (hasViewSelection) {
    const viewButton = (isListView, isSelected) => {
      const buttonText = isListView
        ? globalMessages.listView
        : globalMessages.detailView;

      const icon = isListView ? 'listView' : 'cardView';
      const viewToggleSelectionButton =
        ListVisorControls.baseClass + '__view-toggle-selection-button';
      const isButtonSelected = isSelected
        ? ListVisorControls.baseClass +
          '__view-toggle-selection-button-selected'
        : '';

      return (
        <Tooltip
          content={
            <span className={`${ListVisorControls.baseClass}__tooltip`}>
              {intl.formatMessage(buttonText)}
            </span>
          }
        >
          <Button
            data-qa="view-selection-button"
            className={`${viewToggleSelectionButton} ${isButtonSelected}`}
            disabled={isSelected}
            onClick={() => onToggleListViewCallback(isListView)}
          >
            <SvgIcon modifiers={['flex']} icon={icon} height={22} width={22} />
          </Button>
        </Tooltip>
      );
    };

    viewSelectionButton = (
      <Media.Item>
        <div
          className={`${ListVisorControls.baseClass}__view-toggle-selection`}
        >
          {/* Card View */}
          {viewButton(false, !isListViewSelected)}

          {/* List View */}
          {viewButton(true, isListViewSelected)}
        </div>
      </Media.Item>
    );
  }

  return (
    <div {...passprops} className={ListVisorControls.baseClass} style={style}>
      <Media style={{ alignItems: 'center', height: '100%' }}>
        {children}
        <Media.Item>{filterButton}</Media.Item>
        {viewSelectionButton}
      </Media>
    </div>
  );
};

ListVisorControls.propTypes = {
  children: PropTypes.node,
  filterCount: PropTypes.number,
  hasFilters: PropTypes.bool,
  hasViewSelection: PropTypes.bool,
  isListViewSelected: PropTypes.bool,
  /**
   * Changes the direction of the chevron SVG to indicate if the drawer is open/closed
   * */
  isDrawerOpen: PropTypes.bool,
  /**
   * Function is triggered when the user clicks on the "Filters" button or chevron
   * */
  onToggleCallback: PropTypes.func,
  onToggleListViewCallback: PropTypes.func,
  style: PropTypes.object,
};

ListVisorControls.defaultProps = {
  children: null,
  filterCount: 0,
  hasFilters: false,
  hasViewSelection: false,
  isListViewSelected: false,
  isDrawerOpen: false,
  onToggleCallback: () => {},
  onToggleListViewCallback: () => {},
};

ListVisorControls.baseClass = 'tk-list-visor-controls';

ListVisorControls.BulkActions = BulkActions;
ListVisorControls.Filters = Filters;

export default ListVisorControls;
