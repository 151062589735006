import find from 'lodash/find';

import { CAMPAIGN_ENDPOINT, SEARCH_LIST_ENDPOINT_V2 } from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';
import {
  performGet,
  requestMultiple,
} from 'services/rest-service/rest-service';
import {
  DATE_RANGE_KEYS,
  getTimestampsForRange,
  getTimestampsForCustomRange,
} from 'utils/date/date-util';

export const GET_CAMPAIGN_METRICS_DATA =
  'campaign/metrics/GET_CAMPAIGN_METRICS_DATA';
export const GET_CAMPAIGN_METRICS_DATA_RECEIVED =
  'campaign/metrics/GET_CAMPAIGN_METRICS_DATA_RECEIVED';
export const GET_CAMPAIGN_METRICS_DATA_ERROR =
  'campaign/metrics/GET_CAMPAIGN_METRICS_DATA_ERROR';
export const GET_CAMPAIGN_SEARCH_METRICS =
  'campaign/metrics/GET_CAMPAIGN_SEARCH_METRICS';
export const GET_CAMPAIGN_SEARCH_METRICS_SUCCESS =
  'campaign/metrics/GET_CAMPAIGN_SEARCH_METRICS_SUCCESS';
export const GET_CAMPAIGN_SEARCH_METRICS_ERROR =
  'campaign/metrics/GET_CAMPAIGN_SEARCH_METRICS_ERROR';
export const SET_CAMPAIGN_METRICS_DATE_RANGE =
  'campaign/metrics/SET_CAMPAIGN_METRICS_DATE_RANGE';

export const initialState = {
  metrics: {},
  dateRange: '',
  compareMetrics: {},
  startTime: null,
  endTime: null,
  compareStart: null,
  compareEnd: null,
  error: false,
  loading: true,
};

const campaignMetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_METRICS_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_CAMPAIGN_METRICS_DATA_RECEIVED:
      return {
        ...state,
        metrics: action.payload,
        error: false,
        loading: false,
      };
    case GET_CAMPAIGN_METRICS_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case SET_CAMPAIGN_METRICS_DATE_RANGE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CAMPAIGN_SEARCH_METRICS:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CAMPAIGN_SEARCH_METRICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    }
    case GET_CAMPAIGN_SEARCH_METRICS_ERROR:
      return {
        ...state,
        metrics: {},
        compareMetrics: {},
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const setCampaignMetricsDateRange = (
  dateRange,
  startTime,
  endTime,
) => dispatch => {
  let times = {
    startTime,
    endTime,
  };

  if (dateRange === DATE_RANGE_KEYS.CUSTOM) {
    times = getTimestampsForCustomRange(startTime, endTime);
  } else {
    times = getTimestampsForRange(dateRange);
  }

  if (!times) {
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Please select a valid date range',
        status: 'danger',
        ttl: 3000,
      }),
    );
    return;
  }

  dispatch({
    type: SET_CAMPAIGN_METRICS_DATE_RANGE,
    payload: {
      dateRange,
      startTime: times.startTime,
      endTime: times.endTime,
      compareStart: times.previousStart,
      compareEnd: times.previousEnd,
    },
  });
};

export const getPrimarySearchMetrics = (
  primarySearchId,
  startDate,
  endDate,
  compareStart,
  compareEnd,
) => async dispatch => {
  if (!startDate || !endDate || !primarySearchId) return;

  dispatch({ type: GET_CAMPAIGN_SEARCH_METRICS });
  const url = `${SEARCH_LIST_ENDPOINT_V2}/${primarySearchId}/metrics`;
  const metricReq = {
    data: {},
    params: { startDate, endDate },
    method: 'get',
    url,
  };
  const compareReq = {
    data: {},
    params: {
      startDate: compareStart,
      endDate: compareEnd,
    },
    method: 'get',
    url,
  };

  try {
    const result = await requestMultiple([metricReq, compareReq]);
    const metrics = find(result, { config: { params: { endDate, startDate } } })
      .data;
    const compareMetrics = find(result, {
      config: { params: { startDate: compareStart, endDate: compareEnd } },
    }).data;
    dispatch({
      type: GET_CAMPAIGN_SEARCH_METRICS_SUCCESS,
      payload: { metrics, compareMetrics },
    });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_SEARCH_METRICS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Error occurred while trying to load data for this campaign.',
        status: 'danger',
        ttl: 3000,
      }),
    );
    throw e;
  }
};

export const getCampaignMetrics = (campaignId, domainId) => dispatch => {
  dispatch({ type: GET_CAMPAIGN_METRICS_DATA });

  const params = {
    domainId,
    skipCache: true,
  };

  performGet(`${CAMPAIGN_ENDPOINT}/${campaignId}/metrics`, params)
    .then(response => {
      dispatch({
        type: GET_CAMPAIGN_METRICS_DATA_RECEIVED,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_CAMPAIGN_METRICS_DATA_ERROR });
    });
};

export default campaignMetricsReducer;
