import React from 'react';

import { RadioButton } from '@trendkite/ui';

export interface PermissionOptionProps {
  classNameModifier?: string;
  permission: string;
  onSelect: () => void;
  selected: boolean;
  label: string;
  dataQA: string;
}

const PermissionOption: React.FC<PermissionOptionProps> = ({
  classNameModifier = '',
  permission,
  onSelect,
  selected,
  label,
  dataQA,
}: PermissionOptionProps) => {
  const handleOnKeyPress = evt => {
    if (evt.key === 'Enter' || evt.key === ' ') {
      onSelect();
    }
  };

  return (
    <div
      id={permission}
      data-qa={dataQA}
      style={{ cursor: 'pointer' }}
      className={`t-flex t-flex-row ${classNameModifier}`}
      onKeyPress={handleOnKeyPress}
      role="button"
      tabIndex={0}
      onClick={onSelect}
    >
      <RadioButton
        dataQA="erPd425Axkgcg__Xd9xSj"
        selected={selected}
        className="t-flex t-self-center t-mr-1"
      />
      <div className="t-text-gray-800 t-leading-6">{label}</div>
    </div>
  );
};

export default PermissionOption;
