/* eslint-disable react/prop-types */
import React from 'react';

import classNames from 'classnames';

import { IconButton } from '../button';
import SvgIcon from '../svg-icon';
import { DisplaySM } from '../type';

type StatusString = 'danger' | 'warning' | 'success' | 'info' | 'none';

interface ModalHeaderProps extends React.HTMLAttributes<HTMLElement> {
  closeAriaLabel?: string;
  status?: StatusString;
  toggle?: () => void | null;
  toggleClassName?: string;
  wrapTag?: React.ElementType;
  [prop: string]: any;
}

export type ModalHeaderType = React.FC<ModalHeaderProps>;

function getStatusIcon(status: StatusString | void): string {
  switch (status) {
    case 'danger':
    case 'warning':
      return 'notice';
    case 'success':
      return 'checkmark';
    default:
      return 'info-filled';
  }
}

const ModalHeader: ModalHeaderType = ({
  children = null,
  className = '',
  closeAriaLabel = 'Close',
  status = 'none',
  toggle,
  toggleClassName = '',
  wrapTag: Tag = 'div',
  ...attributes
}) => {
  let closeButton: React.ReactNode;
  let statusIcon: React.ReactNode;

  const hasStatus = status !== 'none';

  const classes = classNames(
    'tk-modal__header',
    hasStatus
      ? `tk-modal__header--color-${status} tk-modal__headerBorderDivider__${status}`
      : 'tk-modal__header--color-neutral',
    className,
  );

  if (toggle) {
    const toggleClasses = classNames(toggleClassName, 'tk-modal__header-close');
    closeButton = (
      <div className={toggleClasses}>
        <IconButton
          data-qa="xNuXT3_YdMIQ1W98bftPx"
          compact
          icon="cross"
          onClick={toggle}
          aria-label={closeAriaLabel}
        />
      </div>
    );
  }

  if (hasStatus) {
    const icon = getStatusIcon(status);

    statusIcon = (
      <div className="tk-modal__status-icon">
        <SvgIcon
          icon={icon}
          color={status}
          modifiers={['right']}
          width={24}
          height={24}
        />
      </div>
    );
  }

  const InnerContent = (
    <>
      <DisplaySM block style={{ flexGrow: '1' }} className="t-text-gray-900">
        <div className="tk-modal__title">
          {statusIcon}
          <div className="tk-modal__header-children">{children}</div>
        </div>
      </DisplaySM>
      {closeButton}
    </>
  );

  return (
    <Tag {...attributes} className={classes}>
      {InnerContent}
    </Tag>
  );
};

export default ModalHeader;
