import React from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import classnames from 'classnames';
import iconMap from './icons.js';
import withColors from '../withColors';

const SvgIcon = ({
  className,
  height,
  icon,
  modifiers,
  width,
  ...passedProps
}) => {
  if (has(iconMap, icon)) {
    const baseClass = 'tk-svg-icon';
    let prefixedModifiers = [];

    if (Array.isArray(modifiers)) {
      prefixedModifiers = [icon, ...modifiers].map(
        modifier => `${baseClass}--${modifier}`,
      );
    }

    const mainClass = classnames(baseClass, prefixedModifiers, className);

    const iconObj = iconMap[icon];

    let iconHref;
    if (iconObj.thin) {
      if (Math.min(width, height) >= 20) {
        iconHref = iconObj.thin.id;
      } else {
        iconHref = iconObj.default.id;
      }
    } else {
      iconHref = iconObj.id;
    }

    return (
      <svg className={mainClass} width={width} height={height} {...passedProps}>
        <use xlinkHref={`#${iconHref}`} />
      </svg>
    );
  }

  return null;
};

SvgIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number,
};

SvgIcon.defaultProps = {
  className: null,
  height: 14,
  modifiers: [],
  width: 14,
};

export default withColors()(SvgIcon);
