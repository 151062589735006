export type SavedList = {
  listId: string;
  listName: string;
  owner: string;
  hasAccess: boolean;
  isDeleted: boolean;
  influencersCount: number;
};

export type Recipient = {
  influencerId: string;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  salutation?: string | null;
  email?: string | null;
  avatar?: string | null;
  stats?: number | null;
  private?: boolean | null;
};

export enum RecipientItemType {
  List = 'List',
  Recipient = 'Recipient',
}

export type RecipientItemBase = {
  id: string;
  type: RecipientItemType;
  recipient?: Recipient;
  list?: SavedList;
};

export type RecipientItem = ({ recipient: Recipient } | { list: SavedList }) &
  RecipientItemBase;

export type RecipientItemWithList = RecipientItem & {
  list: SavedList;
};

export type RecipientItemWithRecipient = RecipientItem & {
  recipient: Recipient;
};

export function isRecipientItemAList(
  item: RecipientItemWithList | RecipientItemWithRecipient,
): item is RecipientItemWithList {
  return item?.type === RecipientItemType.List;
}
