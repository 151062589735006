import values from 'lodash/values';

export const X_AXIS_HEIGHT = 25;
export const X_AXIS_TICK_LABEL_WIDTH = 25;
export const Y_AXIS_WIDTH = 60;
export const Y_AXIS_LABEL_WIDTH = 12;
export const BASE_FONT_COLOR = '#677078';
export const BASE_LIGHT_FONT_COLOR = '#677078';
export const TRENDKITE_BLUE = '#0092C2';
export const DIVIDER_COLOR = '#EBECED';
export const DIVIDER_COLOR_LIGHT = '#CCCFD2';
export const CHART_LINE_COLOR = '#1aafdd';
export const CHART_SEMANTIC_COLORS = {
  tealDark: '#2dafac',
  tealLight: '#89ebe8',
  tealBase: '#39dbd8',
  tealAlt: '#7ee1e0',
  greenDark: '#009e71',
  green: '#a1f0d7',
  greenBase: '#00c68d',
  greenAlt: '#40d7ad',
  blueDark: '#0177b2',
  blueLight: '#65c1ea',
  blueBase: '#0092c2',
  blueAlt: '#44aee3',
  purpleDark: '#6b5da3',
  purpleLight: '#b5ade1',
  purpleBase: '#8575cd',
  purpleAlt: '#c9c3e8',
  magentaDark: '#cb509e',
  magentaLight: '#ffa4de',
  magentaBase: '#ff64c6',
  magentaAlt: '#ffc2e9',
  redDark: '#cb4e55',
  redLight: '#ffa2a7',
  redBase: '#ff626b',
  redAlt: '#ffbbbe',
  yellowDark: '#fdd500',
  yellowLight: '#fdf5d0',
  yellowBase: '#fde568',
  yellowAlt: '#fff2a6',
};
export const EXPANDED_CHART_SEMANTIC_COLORS = {
  tealLight: CHART_SEMANTIC_COLORS.tealLight,
  tealBase: CHART_SEMANTIC_COLORS.tealBase,
  green: CHART_SEMANTIC_COLORS.green,
  greenBase: CHART_SEMANTIC_COLORS.greenBase,
  blueLight: CHART_SEMANTIC_COLORS.blueLight,
  blueBase: CHART_SEMANTIC_COLORS.blueBase,
  purpleLight: CHART_SEMANTIC_COLORS.purpleLight,
  purpleBase: CHART_SEMANTIC_COLORS.purpleBase,
  magentaLight: CHART_SEMANTIC_COLORS.magentaLight,
  magentaBase: CHART_SEMANTIC_COLORS.magentaBase,
  redLight: CHART_SEMANTIC_COLORS.redLight,
  redBase: CHART_SEMANTIC_COLORS.redBase,
  yellowLight: CHART_SEMANTIC_COLORS.yellowLight,
  yellowBase: CHART_SEMANTIC_COLORS.yellowBase,
};
export const BASE_CHART_SEMANTIC_COLORS = {
  tealBase: CHART_SEMANTIC_COLORS.tealBase,
  greenBase: CHART_SEMANTIC_COLORS.greenBase,
  blueBase: CHART_SEMANTIC_COLORS.blueBase,
  purpleBase: CHART_SEMANTIC_COLORS.purpleBase,
  magentaBase: CHART_SEMANTIC_COLORS.magentaBase,
  redBase: CHART_SEMANTIC_COLORS.redBase,
  yellowBase: CHART_SEMANTIC_COLORS.yellowBase,
};
export const BASE_CHART_SENTIMENT_COLORS = {
  green: CHART_SEMANTIC_COLORS.greenBase,
  gray: DIVIDER_COLOR_LIGHT,
  red: CHART_SEMANTIC_COLORS.redBase,
};
export const BASE_CHART_SEO_IMPACT_COLORS = {
  blueLight: CHART_SEMANTIC_COLORS.blueLight,
  green: CHART_SEMANTIC_COLORS.green,
  magentaLight: CHART_SEMANTIC_COLORS.magentaLight,
  tealLight: CHART_SEMANTIC_COLORS.tealLight,
  purpleLight: CHART_SEMANTIC_COLORS.purpleLight,
};

const NEW_CHART_COLOR_SEQUENCE = [
  CHART_SEMANTIC_COLORS.blueBase,
  CHART_SEMANTIC_COLORS.greenBase,
  CHART_SEMANTIC_COLORS.purpleBase,
  CHART_SEMANTIC_COLORS.tealBase,
  CHART_SEMANTIC_COLORS.magentaBase,
  CHART_SEMANTIC_COLORS.redBase,
  CHART_SEMANTIC_COLORS.yellowBase,
  CHART_SEMANTIC_COLORS.blueLight,
  CHART_SEMANTIC_COLORS.green,
  CHART_SEMANTIC_COLORS.purpleLight,
  CHART_SEMANTIC_COLORS.tealLight,
  CHART_SEMANTIC_COLORS.magentaLight,
  CHART_SEMANTIC_COLORS.redLight,
  CHART_SEMANTIC_COLORS.yellowLight,
  CHART_SEMANTIC_COLORS.blueAlt,
  CHART_SEMANTIC_COLORS.greenAlt,
  CHART_SEMANTIC_COLORS.purpleAlt,
  CHART_SEMANTIC_COLORS.tealAlt,
  CHART_SEMANTIC_COLORS.magentaAlt,
  CHART_SEMANTIC_COLORS.redAlt,
  CHART_SEMANTIC_COLORS.yellowAlt,
  CHART_SEMANTIC_COLORS.purpleDark,
  CHART_SEMANTIC_COLORS.greenDark,
  CHART_SEMANTIC_COLORS.magentaDark,
  CHART_SEMANTIC_COLORS.yellowDark,
];

// Sort after the call to values as the resultant order is not guaranteed
export const CHART_DATA_COLORS = NEW_CHART_COLOR_SEQUENCE;
export const EXPANDED_CHART_COLORS = NEW_CHART_COLOR_SEQUENCE;
export const BASE_CHART_COLORS = values(BASE_CHART_SEMANTIC_COLORS).sort();
export const BASE_SENTIMENT_COLORS = values(BASE_CHART_SENTIMENT_COLORS).sort();
export const BASE_SEO_IMPACT_COLORS = values(
  BASE_CHART_SEO_IMPACT_COLORS,
).sort();
export const getColorArrayFromCount = (count, isSentiment = false) => {
  if (isSentiment) return BASE_SENTIMENT_COLORS;
  if (count > 14) return CHART_DATA_COLORS;
  if (count > 7) return EXPANDED_CHART_COLORS;
  return BASE_CHART_COLORS;
};

export const CAMPAIGNS_LINE_CHART = {
  height: 25,
  width: 220,
  style: {
    data: {
      stroke: TRENDKITE_BLUE,
      strokeWidth: 1,
    },
  },
};

export const SOCIAL_AMP_CHART = {
  barWidth: 15,
};

export const BUBBLE_CHART_TOOLTIP_WIDTH = 116;
export const BUBBLE_CHART_TOOLTIP_TOP_OFFSET = 5;
export const PROMINENT_OUTLETS_BUBBLE_CHART_TOOLTIP_WIDTH = 280;

export const DASHBOARD_CHART_MAX_HEIGHT = 588;
export const DASHBOARD_CHART_MED_HEIGHT = 216;
export const DASHBOARD_CHART_MIN_HEIGHT = 152;

export const LEFT_AXIS_COLOR = '#63BF52';
export const RIGHT_AXIS_COLOR = '#0092C2';
