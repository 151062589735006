import React, { Component } from 'react';

import { Icon } from '@cision/rover-ui';
import classNames from 'classnames';
import globalMessages from 'i18n/Global.messages';
import TranslatedMessage from 'i18n/TranslatedMessage';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';

import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import Button, { Addon } from '../button';
import ComponentType from '../component-type';
import Dropdown, { DropdownMenu } from '../dropdown';
import FloatingPanel from '../floating-panel';
import List from '../list/List';
import ListEntrySmall from '../list/ListEntrySmall';
import Loader from '../loader';
import SvgIcon from '../svg-icon';
import TextTruncate from '../text-truncate';

import Tooltip from '../tooltip';
import './select.scss';

class Select extends Component {
  state = {
    menuOpen: false,
    menuItems: [],
    previouslySelectedIds: [],
    selectedIds: [],
  };

  componentDidMount() {
    this.handleOptionsInjection(this.props);
    this.handleDefaultSelection();
  }

  componentDidUpdate(prevProps) {
    const { betterSelectMode, reset } = this.props;

    const propsToCheck = ['betterSelectMode', 'options'];

    if (
      betterSelectMode &&
      !isEqual(
        pick(prevProps, [...propsToCheck, 'value']),
        pick(this.props, [...propsToCheck, 'value']),
      )
    ) {
      // We only care about the value prop if we're in betterSelectMode
      this.handleOptionsInjection(this.props);
    } else if (
      !isEqual(pick(prevProps, propsToCheck), pick(this.props, propsToCheck))
    ) {
      this.handleOptionsInjection(this.props);
    }

    if (reset === true) {
      this.resetSelected();
    }
  }

  getMenuItemFromOption(option) {
    if (!option.id) {
      return {};
    }

    return {
      ...option,
      title: option.label,
      data: {
        ...option.data,
      },
    };
  }

  confirmSelectionChange(newSelectedIds) {
    const { onChange, options, betterSelectMode } = this.props;
    const { previouslySelectedIds } = this.state;

    if (
      !betterSelectMode &&
      !isEqual(newSelectedIds.sort(), previouslySelectedIds.sort())
    ) {
      const newSelectedOptions = options.filter(
        option => newSelectedIds.indexOf(option.id) >= 0,
      );

      onChange(newSelectedOptions);
    }

    if (
      betterSelectMode &&
      !isEqual(newSelectedIds.sort(), previouslySelectedIds.sort())
    ) {
      onChange(newSelectedIds.sort());
    }

    this.setState({
      previouslySelectedIds: [...newSelectedIds],
      selectedIds: [...newSelectedIds],
    });
  }

  createOnlyButtonMenuItems(menuItems) {
    const menuItemsWithOnlyButton = menuItems.map(menuItem => ({
      ...menuItem,
      title: this.createOnlyButtonMenuItem(menuItem),
    }));
    return menuItemsWithOnlyButton;
  }

  createOnlyButtonMenuItem(menuItem) {
    return (
      <div className="select-menu-item">
        <p className="select-menu-item__label">{menuItem.label || ''}</p>
        <span
          data-qa="uAozzdTnUrJQEUCbr2oXn"
          className="select-menu-item__only-selector"
          role="button"
          onClick={e => {
            e.stopPropagation();
            this.setState({ selectedIds: [menuItem.id] });
          }}
          onKeyUp={event => {
            if ([' ', 'Spacebar', 'Enter'].find(event.key)) {
              this.setState({ selectedIds: [menuItem.id] });
            }
          }}
          tabIndex={0}
        >
          <TranslatedMessage {...globalMessages.only} />
        </span>
      </div>
    );
  }

  resetSelected() {
    this.setState({
      previouslySelectedIds: [],
      selectedIds: [],
      menuOpen: false,
    });

    this.handleDefaultSelection();
  }

  handleDefaultSelection() {
    const { defaultSelection } = this.props;

    if (defaultSelection) {
      this.confirmSelectionChange([defaultSelection]);
    }
  }

  handleOptionsInjection(props) {
    const { betterSelectMode, options, value } = props;

    if (options) {
      let newSelectedIds;

      if (!betterSelectMode) {
        newSelectedIds = options.reduce(
          (idsArray, thisOption) =>
            thisOption.selected
              ? idsArray.push(thisOption.id) && idsArray
              : idsArray,
          [],
        );
      } else {
        newSelectedIds = value;
      }

      this.setState({
        menuItems: options.map(this.getMenuItemFromOption),
        selectedIds: [...newSelectedIds],
        previouslySelectedIds: [...newSelectedIds],
      });
    }
  }

  toggleMenu() {
    const { menuOpen } = this.state;

    this.setState({
      menuOpen: !menuOpen,
    });
  }

  handleApplyClick = () => {
    this.confirmSelectionChange(this.state.selectedIds);
    this.toggleMenu();
  };

  handleCancelClick = () => {
    const { previouslySelectedIds } = this.state;

    this.toggleMenu();

    this.setState({
      selectedIds: [...previouslySelectedIds],
    });
  };

  handleDropdownMenuToggle = e => {
    if (e) {
      e.stopPropagation();
    }
    const { menuOpen, selectedIds } = this.state;

    if (menuOpen) {
      this.confirmSelectionChange(selectedIds);
    }

    this.toggleMenu();
  };

  handleListSelect = childSelections => {
    const { multiple } = this.props;
    const newSelectedIds = childSelections.map(selection => selection.id);

    if (!multiple) {
      this.confirmSelectionChange(newSelectedIds);
      this.toggleMenu();
      return;
    }

    this.setState({
      selectedIds: [...newSelectedIds],
    });
  };

  renderLabel() {
    const {
      allItemsSelectedMessage,
      label,
      multiple,
      placeholder,
      options,
    } = this.props;
    const { menuItems, selectedIds } = this.state;

    if (label) {
      return label;
    }

    if (!selectedIds.length && multiple) {
      return placeholder || 'Make a selection';
    }

    if (!selectedIds.length && menuItems.length) {
      return placeholder || menuItems[0].title || menuItems[0].id;
    }

    const selectionData = selectedIds.reduce(
      (selectionDatum, id) => {
        selectionDatum.firstLabel =
          selectionDatum.firstLabel ||
          menuItems.filter(item => item.id === id)[0].title;

        selectionDatum.count += 1;
        return selectionDatum;
      },
      {
        firstLabel: '',
        count: 0,
      },
    );

    const someItemsSelectedMessage =
      selectionData.count > 1
        ? `${selectionData.firstLabel} +${selectionData.count - 1}`
        : selectionData.firstLabel;

    return selectionData.count === options.length && options.length > 1
      ? allItemsSelectedMessage
      : someItemsSelectedMessage;
  }

  renderButton() {
    const {
      buttonProps,
      disabled,
      hideAddon,
      modifiers,
      onBlur,
      onFocus,
      working,
    } = this.props;

    const label = this.renderLabel();
    const buttonColors = intersection(modifiers, [
      'primary',
      'primaryTeal',
      'secondary',
      'seethrough',
      'tertiary',
    ]);
    const buttonSizes = intersection(modifiers, ['large', 'medium', 'small']);
    const roverLook = modifiers.some(m => m === 'rover-look');

    if (!buttonColors.length) {
      buttonColors.push('tertiary');
    }

    const buttonModifiers = [...buttonColors, ...buttonSizes, 'flex'];
    const { className: buttonClass } = buttonProps;

    return (
      <Button
        data-qa="JYPFzEG_6yeTO_-VIogbc"
        {...buttonProps}
        className={classNames({
          roundedButton: roverLook,
          [buttonClass]: buttonClass,
        })}
        disabled={disabled}
        modifiers={[...buttonModifiers]}
        onClick={this.handleDropdownMenuToggle}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <TextTruncate>{label}</TextTruncate>
        {!hideAddon && (
          <Addon>
            {!working && (
              <Addon>
                {roverLook ? (
                  <Icon
                    className="dropdownIcon"
                    name="arrow-drop-down"
                    height={25}
                    width={25}
                  />
                ) : (
                  <SvgIcon icon="arrowDown" width={8} height={8} />
                )}
              </Addon>
            )}
            {working && <Loader size="tiny" style={{ marginTop: 0 }} />}
          </Addon>
        )}
      </Button>
    );
  }

  render() {
    const {
      className,
      classModifier = '',
      disabled,
      EntryComponent,
      error,
      listMaxHeight,
      menuModifiers,
      modifiers,
      multiple,
      tooltip,
      working,
      withOnlyButton,
      scrollIntoView,
      searchable,
      onSearchChange,
      onTypingAhead,
    } = this.props;
    const dataQa = this.props['data-qa'];
    const { menuOpen, menuItems, selectedIds } = this.state;

    const seethrough = modifiers.indexOf('seethrough') >= 0;
    const { handleApplyClick, handleCancelClick, handleListSelect } = this;
    const baseClass = 'tk-form-control';
    const button = this.renderButton();
    const menuOptions =
      multiple && withOnlyButton
        ? this.createOnlyButtonMenuItems(menuItems)
        : menuItems;

    const mainClass = classNames(
      `${baseClass}--select`,
      {
        [`${baseClass}--error`]: error,
        [`${baseClass}--disabled`]: disabled,
        [`${baseClass}--working`]: working,
      },
      className,
    );

    const menuPosition =
      modifiers.indexOf('bottomLeft') >= 0 ? 'bottomLeft' : 'bottom';
    const menuWidth =
      seethrough || menuPosition === 'bottomLeft' ? 'auto' : '100%'; // 100% width is for menus that are flush on both sides

    return (
      <div className={mainClass} data-qa={dataQa}>
        <Dropdown
          modifiers={modifiers}
          isOpen={menuOpen}
          toggle={this.handleDropdownMenuToggle}
        >
          {!menuOpen && <Tooltip content={tooltip}>{button}</Tooltip>}
          {menuOpen && button}
          <DropdownMenu
            isOpen={menuOpen}
            position={menuPosition}
            width={menuWidth}
          >
            <FloatingPanel modifiers={menuModifiers}>
              <span className={classModifier} data-qa="Select:List">
                <List
                  EntryComponent={EntryComponent}
                  entries={menuOptions}
                  maxHeight={listMaxHeight}
                  modifiers={['round']}
                  scrollIntoView={scrollIntoView}
                  multiselect={multiple}
                  onSelect={handleListSelect}
                  selected={selectedIds}
                  showTray={false}
                  searchable={searchable}
                  onSearchChange={onSearchChange}
                  onTypingAhead={onTypingAhead}
                />
              </span>
              {multiple && (
                <div className={`${baseClass}__confirmation`}>
                  <div className={`${baseClass}__confirmation-item`}>
                    <Button
                      data-qa="98PtB0dqpz9VeA4MYcy7u"
                      onClick={handleCancelClick}
                      modifiers={['block', 'round', 'tertiary']}
                    >
                      <TranslatedMessage {...globalMessages.cancel} />
                    </Button>
                  </div>
                  <div className={`${baseClass}__confirmation-item`}>
                    <Button
                      data-qa="DMuzY1Vl9H2620b0PCAho"
                      onClick={handleApplyClick}
                      modifiers={['block', 'round', 'primary']}
                    >
                      <TranslatedMessage {...globalMessages.apply} />
                    </Button>
                  </div>
                </div>
              )}
              <div />
            </FloatingPanel>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

Select.defaultProps = {
  allItemsSelectedMessage: '',
  buttonProps: {},
  className: '',
  'data-qa': undefined,
  disabled: false,
  EntryComponent: ListEntrySmall,
  error: false,
  hideAddon: false,
  label: null,
  listMaxHeight: '',
  modifiers: [],
  menuModifiers: [],
  multiple: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: [],
  placeholder: '',
  reset: false,
  /**
   In `betterSelectMode`, the Select object
   takes an array of ids as `value`, and returns
   an array of selected ids in `onChange`.
   */
  betterSelectMode: false,
  tooltip: '',
  working: false,
  defaultSelection: null,
  withOnlyButton: false,
  searchable: false,
  scrollIntoView: false,
  onSearchChange: () => {},
  onTypingAhead: () => {},
};

Select.propTypes = {
  allItemsSelectedMessage: PropTypes.string,
  buttonProps: PropTypes.object,
  className: PropTypes.string,
  classModifier: PropTypes.string,
  'data-qa': PropTypes.string,
  disabled: PropTypes.bool,
  EntryComponent: ComponentType,
  error: PropTypes.bool,
  hideAddon: PropTypes.bool,
  label: PropTypes.node,
  listMaxHeight: PropTypes.string,
  menuModifiers: PropTypes.arrayOf(PropTypes.oneOf(['scrollable', 'small'])),
  modifiers: PropTypes.arrayOf(
    PropTypes.oneOf([
      'bottomLeft',
      'inline-block',
      'seethrough',
      'small',
      'rover-look',
    ]),
  ),
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  reset: PropTypes.bool,
  betterSelectMode: PropTypes.bool,
  tooltip: PropTypes.node,
  working: PropTypes.bool,
  defaultSelection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withOnlyButton: PropTypes.bool,
  searchable: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
  onSearchChange: PropTypes.func,
  onTypingAhead: PropTypes.func,
};

export default Select;
