import React, { Component } from 'react';

import PropTypes from 'prop-types';

import './banners.scss';

class Banners extends Component {
  render() {
    const {
      isDeletedSearches,
      EmailCampign,
      WarningNullEmailRecipient,
      description,
    } = this.props;

    const bannerClassName = isDeletedSearches
      ? 'errorBanner deletedSearchesBanner'
      : EmailCampign
      ? 'errorBanner'
      : WarningNullEmailRecipient
      ? 'warningEABanner'
      : 'banner';

    const svgClassName =
      EmailCampign || isDeletedSearches
        ? 'tk-svg-icon--color-notice-error'
        : 'tk-svg-icon--color-warning';

    const svgIconColor =
      EmailCampign || isDeletedSearches ? 'danger' : 'warning';

    return (
      <div className={bannerClassName}>
        <svg
          className={`tk-svg-icon tk-svg-icon--notice tk-svg-icon--right ${svgClassName}`}
          width="24"
          height="24"
          color={svgIconColor}
        >
          <use xlinkHref="#notice" />
        </svg>
        <p>{description}</p>
      </div>
    );
  }
}

Banners.propTypes = {
  description: PropTypes.string,
  EmailCampign: PropTypes.boolean,
  isDeletedSearches: PropTypes.boolean,
  WarningNullEmailRecipient: PropTypes.boolean,
};

Banners.defaultProps = {
  description: '',
  EmailCampign: false,
  isDeletedSearches: false,
  WarningNullEmailRecipient: false,
};

export default Banners;
