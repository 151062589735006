import moment from 'moment';

import { WIDGET_DRILLDOWN_URL, DATA_SOURCE_TYPES } from 'constants/constants';
import {
  convertDateStringToRangeTimeStamps,
  getTimestampsForAnyRange,
} from 'utils/date/date-util';

export const isWidgetFormValid = (visualizationType, metrics, dataSources) => {
  return (
    visualizationType !== null &&
    !!metrics.length &&
    dataSources &&
    !!dataSources.length
  );
};

export const buildWidgetV3DrilldownPath = widgetId =>
  `${WIDGET_DRILLDOWN_URL}/${widgetId}`;

export const buildDrilldownDates = dateRange => {
  const { type, startDate: rangeStart, endDate: rangeEnd } = dateRange || {};
  const { startTime, endTime } = getTimestampsForAnyRange(
    type,
    rangeStart,
    rangeEnd,
  );

  return { startDate: startTime, endDate: endTime };
};

export const buildDateRangeFromPayload = (
  payload,
  granularityType,
  minDate,
  maxDate,
  useCompareDate = false,
) => {
  const day = moment
    .utc(useCompareDate ? payload.rawCompareDate : payload.rawDate)
    .format('MMM-DD-YYYY');
  const { startDate, endDate } =
    convertDateStringToRangeTimeStamps(
      useCompareDate ? payload.rawCompareDate : payload.rawDate,
      granularityType,
    ) || {};

  return {
    day,
    startDate: useCompareDate
      ? startDate
      : Math.max(startDate, minDate || startDate),
    endDate: useCompareDate ? endDate : Math.min(endDate, maxDate || endDate),
  };
};

export const buildWidgetListTooltip = (widgetObj, widgetsIds) => {
  return [...widgetsIds]
    .map(widgetId => widgetObj[widgetId])
    .sort((a, b) =>
      a.title !== b.title ? a.title.localeCompare(b.title) : a.id - b.id,
    )
    .reduce((tooltipAcc, { title }, index) => {
      return `${tooltipAcc} ${title}${
        index !== widgetsIds.length - 1 ? '\n' : ''
      }`;
    }, '');
};

export const getWidgetSearchIdsHash = metrics => {
  const allSearchIds = metrics?.reduce((totalSearchIds, metric) => {
    return totalSearchIds.concat(
      metric.dataSources
        ?.filter(
          source =>
            source.sourceType === DATA_SOURCE_TYPES.search ||
            source.sourceType === DATA_SOURCE_TYPES.parentSearch,
        )
        .reduce((searchIds, ds) => {
          return searchIds.concat(ds.sourceId);
        }, ''),
    );
  }, '');

  return hashCode(allSearchIds);
};

const hashCode = s => {
  let hash = 0;
  let i;
  let chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
