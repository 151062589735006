import {
  ADD_TERM,
  DELETE_TERM,
  ADD_ERROR,
  REMOVE_ERROR,
  CLEAR_ERROR_FROM_ALL,
  SELECT_CATEGORY,
  SET_SEARCH_BUILDER_DATA,
  SET_ADVANCED_SEARCH_QUERY,
  CLEAN_UP,
  TOGGLE_ADULT_CONTENT,
  TOGGLE_QUALITY_FILTER,
  PICK_CHART_COLOR,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_UPDATE_IN_PROGRESS,
  SET_SEARCH_DATA_LOADING,
  TOGGLE_SYSTEM_SEARCH,
  SET_SYSTEM_SEARCH_PUB_STATUS,
  SET_ACTIVE_IN,
  SET_AUDIT_LOG,
  UPDATE_SEARCH_DATA,
  SET_SEARCH_TITLE,
  SET_SEARCH_UPDATE_IN_PROGRESS,
  UPDATE_SHARE_PERMISSIONS,
  SET_SEARCH_SHARED_AND_ACCESS_CONTROL_GROUPS,
  CHANGE_CONVERT_TO_ADVANCED_SEARCH_STATUS,
  UPDATE_PROCESS_SUCCESSFUL,
} from '../actions/searchBuilderActions';

export const SEARCH_CRITERIA_KEYS = {
  mustCriteria: 'mustCriteria',
  anyCriteria: 'anyCriteria',
  notCriteria: 'notCriteria',
};

const removeTerm = (list, term) =>
  list.filter(currentTerm => currentTerm !== term);

const removePillFromBox = (list, term) =>
  list.filter(
    ({ operator, value }) =>
      !(value === term.value && operator === term.operator),
  );

const initialState = {
  [SEARCH_CRITERIA_KEYS.mustCriteria]: [],
  [SEARCH_CRITERIA_KEYS.anyCriteria]: [],
  [SEARCH_CRITERIA_KEYS.notCriteria]: [],
  errors: {
    [SEARCH_CRITERIA_KEYS.mustCriteria]: [],
    [SEARCH_CRITERIA_KEYS.anyCriteria]: [],
    [SEARCH_CRITERIA_KEYS.notCriteria]: [],
  },
  accessControlGroups: [],
  shared: false,
  selectedCategory: '',
  search: '',
  searchTitle: null,
  includeAdultContent: false,
  colorTag: null,
  systemSearch: false,
  systemSearchPubStatus: null,
  subscription: null,
  subscriptionUpdateInProgress: false,
  isLoadingData: true,
  viewOnly: true,
  tksTrackerId: '',
  activeIn: {
    alerts: [],
    campaigns: [],
    dashboards: [],
  },
  lastSaved: '',
  dateDeleted: '',
  dateArchived: '',
  auditLog: {
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '',
  },
  user: {
    id: 0,
    username: '',
  },
  qualityFilter: false,
  searchUpdateInProgress: false,
  id: undefined,
  simple: undefined,
  canConvertToAdvancedSearch: true,
  updateProcessSuccessful: false,
};

const searchBuilderViewReducer = (state = {...initialState}, { type, payload }) => {
  switch (type) {
    case UPDATE_PROCESS_SUCCESSFUL: {
      return {
        ...state,
        updateProcessSuccessful: payload,
      };
    }

    case CHANGE_CONVERT_TO_ADVANCED_SEARCH_STATUS: {
      return {
        ...state,
        canConvertToAdvancedSearch: payload,
      };
    }

    case ADD_TERM: {
      const { criteriaKey, term } = payload;
      return {
        ...state,
        [criteriaKey]: [...state[criteriaKey], term],
      };
    }

    case DELETE_TERM: {
      const { criteriaKey, term } = payload;
      return {
        ...state,
        [criteriaKey]: removePillFromBox(state[criteriaKey], term),
      };
    }

    case ADD_ERROR: {
      const { criteriaKey, errorMessage } = payload;
      return {
        ...state,
        errors: {
          ...state.errors,
          [criteriaKey]: [errorMessage, ...state.errors[criteriaKey]],
        },
      };
    }

    case REMOVE_ERROR: {
      const { criteriaKey, errorMessage } = payload;
      return {
        ...state,
        errors: {
          ...state.errors,
          [criteriaKey]: removeTerm(state.errors[criteriaKey], errorMessage),
        },
      };
    }

    case SET_ADVANCED_SEARCH_QUERY:
      return {
        ...state,
        search: payload,
      };

    case CLEAR_ERROR_FROM_ALL: {
      const { errorMessage } = payload;
      return {
        ...state,
        errors: Object.keys(state.errors).reduce(
          (errors, key) => ({
            ...errors,
            [key]: removeTerm(state.errors[key], errorMessage),
          }),
          {},
        ),
      };
    }

    case SELECT_CATEGORY: {
      const { categoryId } = payload;
      return { ...state, selectedCategory: categoryId };
    }

    case SET_SEARCH_BUILDER_DATA:
      return { ...state, ...payload };

    case CLEAN_UP:
      return { ...initialState };

    case TOGGLE_ADULT_CONTENT: {
      return {
        ...state,
        includeAdultContent: !state.includeAdultContent,
      };
    }
    case TOGGLE_QUALITY_FILTER: {
      return {
        ...state,
        qualityFilter: !state.qualityFilter,
      };
    }
    case PICK_CHART_COLOR: {
      const { color } = payload;
      return {
        ...state,
        colorTag: color,
      };
    }

    case SET_SUBSCRIPTION: {
      const { subscription } = payload;
      return {
        ...state,
        subscription,
      };
    }

    case SET_SUBSCRIPTION_UPDATE_IN_PROGRESS: {
      const { subscriptionUpdateInProgress } = payload;
      return {
        ...state,
        subscriptionUpdateInProgress,
      };
    }

    case SET_SEARCH_DATA_LOADING: {
      const { isLoading: isLoadingData } = payload;
      return {
        ...state,
        isLoadingData,
      };
    }

    case TOGGLE_SYSTEM_SEARCH:
      return {
        ...state,
        systemSearch: !state.systemSearch,
      };

    case SET_SYSTEM_SEARCH_PUB_STATUS:
      return {
        ...state,
        systemSearchPubStatus: payload,
      };

    case SET_ACTIVE_IN:
      return {
        ...state,
        activeIn: payload,
      };

    case SET_AUDIT_LOG:
      return {
        ...state,
        auditLog: payload,
      };

    case UPDATE_SEARCH_DATA:
      return {
        ...state,
        ...payload,
      };
    case SET_SEARCH_TITLE:
      return {
        ...state,
        searchTitle: payload,
      };

    case SET_SEARCH_UPDATE_IN_PROGRESS: {
      const { searchUpdateInProgress } = payload;
      return { ...state, searchUpdateInProgress };
    }

    case UPDATE_SHARE_PERMISSIONS: {
      const { shared, viewOnly } = payload;
      return {
        ...state,
        shared,
        viewOnly,
      };
    }

    case SET_SEARCH_SHARED_AND_ACCESS_CONTROL_GROUPS:{
      const {shared, accessControlGroups} = payload;
      return {
        ...state,
        shared,
        accessControlGroups,
      }
    }

    default:
      return state;
  }
};

export default searchBuilderViewReducer;
