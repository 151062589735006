export const SHOW_REPORT_SIDEBAR = 'report/SHOW_REPORT_SIDEBAR';
export const HIDE_REPORT_SIDEBAR = 'report/HIDE_REPORT_SIDEBAR';

export const SHOW_REPORT_CHANGED = 'report/SHOW_REPORT_CHANGED';
export const SHOW_REPORT_UNCHANGED = 'report/SHOW_REPORT_UNCHANGED';

export interface ReportWrapper {
  isSidebarOpen?: boolean,
}

// TODO: with only one property now applicable to a separate wrapper state, this whole reducer may now be a
//  candidate for deletion, and isSidebarOpen restored to local state. Let's see if anything else arises before the
//  end of R2 that would have an appropriate home here, and if not, nuke the mofo.
//
export const initialState: ReportWrapper = {
  isSidebarOpen: true,
};

const reportWrapperReducer = (
  state = { ...initialState },
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case SHOW_REPORT_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: true,
      };
    case HIDE_REPORT_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: false,
      };
    default:
      return state;
  }
};
export const toggleSidebarCollapsedActionCreator = () => (
  dispatch,
  getState,
) => {
  const state = getState();

  dispatch({
    type: state.report.reportWrapper.isSidebarOpen
      ? HIDE_REPORT_SIDEBAR
      : SHOW_REPORT_SIDEBAR,
  });
};

export default reportWrapperReducer;
