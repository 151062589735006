import { listArchivedAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-archived-reducer';
import { listDeletedAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-deleted-reducer';
import { legacyAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-legacy-reducer';
import { allAnalyticActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-reducer';
import { ownedAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-owned-reducer';
import { sharedAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-shared-reducer';
import { sidebarAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-sidebar-reducer';
import { starredAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-starred-reducer';
import {
  archivedAnalyticsFilteringSelector,
  archivedAnalyticsForOwnerSelector,
  archivedAnalyticsListSelector,
  archivedAnalyticsLoadingSelector,
  archivedAnalyticsSearchSelector,
  archivedAnalyticsResultsCollationSelector,
} from 'pages/Dashboard/DashboardListContainer/dashboard-list-archived-selector';
import {
  deletedAnalyticsFilteringSelector,
  deletedAnalyticsForOwnerSelector,
  deletedAnalyticsListSelector,
  deletedAnalyticsLoadingSelector,
  deletedAnalyticsSearchSelector,
  deletedAnalyticsResultsCollationSelector,
} from 'pages/Dashboard/DashboardListContainer/dashboard-list-deleted-selector';
import {
  allAnalyticsFilteringSelector,
  allAnalyticsForOwnerSelector,
  allAnalyticsLoadingSelector,
  allAnalyticsSearchSelector,
  allAnalyticsResultsCollationSelector,
  getAnalyticDeletingSelector,
  getAnalyticUpdatingSelector,
  listAnalyticSelector,
  ownedAnalyticsFilteringSelector,
  ownedAnalyticsForOwnerSelector,
  ownedAnalyticsLoadingSelector,
  ownedAnalyticsSearchSelector,
  ownedAnalyticsSelector,
  ownedAnalyticsResultsCollationSelector,
  sharedAnalyticsFilteringSelector,
  sharedAnalyticsForOwnerSelector,
  sharedAnalyticsLoadingSelector,
  sharedAnalyticsSearchSelector,
  sharedAnalyticsSelector,
  sharedAnalyticsResultsCollationSelector,
  starredAnalyticsFilteringSelector,
  starredAnalyticsForOwnerSelector,
  starredAnalyticsLoadingSelector,
  starredAnalyticsSearchSelector,
  starredAnalyticsSelector,
  starredAnalyticsResultsCollationSelector,
  legacyAnalyticsFilteringSelector,
  legacyAnalyticsForOwnerSelector,
  legacyAnalyticsLoadingSelector,
  legacyAnalyticsSearchSelector,
  legacyAnalyticsSelector,
  legacyAnalyticsResultsCollationSelector,
} from 'pages/Dashboard/DashboardListContainer/dashboard-list-selector';
import { Report } from 'pages/Report/report-hub';
import {
  allAnalyticsPaginationCountSelector,
  ownedAnalyticsPaginationCountSelector,
  sharedAnalyticsPaginationCountSelector,
  starredAnalyticsPaginationCountSelector,
  archivedAnalyticsPaginationCountSelector,
  deletedAnalyticsPaginationCountSelector,
} from 'selectors/analytics-pagination-selector';
import {
  allAnalyticsSidebarCountSelector,
  archivedAnalyticsSidebarCountSelector,
  ownedAnalyticsSidebarCountSelector,
  sharedAnalyticsSidebarCountSelector,
  starredAnalyticsSidebarCountSelector,
  legacyAnalyticsSidebarCountSelector,
  deletedAnalyticsSidebarCountSelector,
} from 'selectors/analytics-sidebar-selector';
import { Dashboard } from 'selectors/dashboards/dashboard-hub';

/* ****************************
 *** CONFIGURATION
 **************************** */

export type Analytic = Dashboard | Report;
export type AnalyticProps = Dashboard & Report;

// Various features that might be hidden based on Reports or Dashboards.  Currently, the list is used to hide things
// shown in R2 since Reports functionality on the list page is a subset of the D2 list page.
//
export type AnalyticsFeatures =
  | 'ownerFilter'
  | 'shareAnalytic'
  | 'generateReportFromDashboard';

// Dashboard or Report shape.  Will be particularized for variations in each instance, and this type should be
// applied in
// various selectors, actionCreators, etc.
//
export interface AnalyticItem {
  archived: boolean;
  dateCreated: string;
  id: number | string;
  lastUpdated: string;
  starred: boolean;
  title: string;
  searchesCount?: number;
  deletedSearches?: boolean;
  legacy?: boolean;
  deleted?: boolean;
}

// Establishes defaults in the absence of prop-types defaultProps capability.  Somewhat clunky.
//
export class AnalyticItemObject implements AnalyticItem {
  archived: false;
  dateCreated: '';
  id: 0;
  lastUpdated: '';
  starred: false;
  title: '';
  constructor(props) {
    Object.keys(props).forEach(key => {
      if (props[key] !== undefined) {
        this[key] = props[key];
      }
    });
  }
}

export interface Messages {
  id: string;
  description: string;
  defaultMessage: string;
}

// Base configuration for each type of D2/R2 list (starred, owned, etc.).  Static values, values set by action
// creators, etc.
//
export interface AnalyticsListConfig {
  path: string;
  icon?: string;
  label?: string;
  messages?: string[];
  useIntl?: any;
  totalCount?: number;
  items?: any[];
  getAnalyticsList?: (arg: any) => [];
  paginationCount?: number;
  analyticsLoading?: boolean;
  analyticsFiltering?: boolean;
  analyticsSearch?: string;
  ownerFilterAnalytics?: Dashboard[];
  modifiers?: Array<'stuck-top'>;
  resultsCollation?: AnalyticsListResultsCollation;
  sharedListDispatch?: string;
  loadingListSelector?: any;
  addLabelNew?: boolean;
}

export interface AnalyticsListResultsCollation {
  pageNumber?: number;
  sort?: string;
  sortDirection?: 'asc' | 'desc';
  ownerFilter?: number | string;
}

// Configuration applicable to all lists.
//
export interface AnalyticsListsConfig {
  // @TODO: To be completely rigorous about configuration structure, these two belong in some kind of uber, top-level
  //  D2/R2 config interface.
  //
  isDashboards?: boolean;
  isReports?: boolean;
  idKey: string;
  getIdLink: (any) => string;

  analyticsIcon: string;
  // @TODO: There should be a messages container object holding these.
  //
  messages: any;
  listsContainerMessages: Record<string, Messages>;
  listsSidebarMessages: Record<string, Messages>;
  listsActionBarMessages: Record<string, Messages>;
  listsGridMessages: Record<string, Messages>;
  listsRecentlyViewedMessages: Record<string, Messages>;
  tableMessages: Record<string, Messages>;

  lists: Map<string, AnalyticsListConfig>;
  hideFeatures?: AnalyticsFeatures[];
  columns: {
    extraLarge: (
      args,
    ) => {
      items: any[];
      styles: string;
    };
    medium: (
      args,
    ) => {
      items: any[];
      styles: string;
    };
  };
  rows: {
    extraLarge: (
      args,
    ) => {
      items: any[];
    };
    medium: (
      args,
    ) => {
      items: any[];
    };
  };
  getSortOptions?: (
    messages: Record<string, Messages>,
  ) => AnalyticsSortOptions[];
  recentAnalyticLink: (analytic) => string;
  noneCreatedComponentElements: Record<string, any>;
  page: string;
}

export interface AnalyticsSortOptions {
  id: 'lastUpdated' | 'title' | 'owner.username';
  label: string;
}

// Passed down from top-level props (e.g. in <Report />).
//
export interface AnalyticsData {
  allAnalytics: AnalyticItemObject[];
  allAnalyticsCount: number | null;
  allAnalyticsPaginationCount: number | null;
  allAnalyticsLoading: boolean;
  allAnalyticsFiltering: boolean;
  allAnalyticsSearch: string;
  allAnalyticsForOwnerFilter: Array<Record<string, string>>;
  allAnalyticsResultsCollation: AnalyticsListResultsCollation;
  ownedAnalytics: AnalyticItemObject[];
  ownedAnalyticsCount: number | null;
  ownedAnalyticsPaginationCount: number | null;
  ownedAnalyticsLoading: boolean;
  ownedAnalyticsFiltering: boolean;
  ownedAnalyticsSearch: string;
  ownedAnalyticsForOwnerFilter: Array<Record<string, string>>;
  ownedAnalyticsResultsCollation: AnalyticsListResultsCollation;
  sharedAnalytics: AnalyticItemObject[];
  sharedAnalyticsCount: number | null;
  sharedAnalyticsPaginationCount: number | null;
  sharedAnalyticsLoading: boolean;
  sharedAnalyticsFiltering: boolean;
  sharedAnalyticsSearch: string;
  sharedAnalyticsForOwnerFilter: Array<Record<string, string>>;
  sharedAnalyticsResultsCollation: AnalyticsListResultsCollation;
  starredAnalytics: AnalyticItemObject[];
  starredAnalyticsCount: number | null;
  starredAnalyticsPaginationCount: number | null;
  starredAnalyticsLoading: boolean;
  starredAnalyticsFiltering: boolean;
  starredAnalyticsSearch: string;
  starredAnalyticsForOwnerFilter: Array<Record<string, string>>;
  starredAnalyticsResultsCollation: AnalyticsListResultsCollation;
  archivedAnalytics: AnalyticItemObject[];
  archivedAnalyticsCount: number | null;
  archivedAnalyticsPaginationCount: number | null;
  archivedAnalyticsLoading: boolean;
  archivedAnalyticsFiltering: boolean;
  archivedAnalyticsSearch: string;
  archivedAnalyticsForOwnerFilter: Array<Record<string, string>>;
  archivedAnalyticsResultsCollation: AnalyticsListResultsCollation;
  deletedAnalytics: AnalyticItemObject[];
  deletedAnalyticsCount: number | null;
  deletedAnalyticsPaginationCount: number | null;
  deletedAnalyticsLoading: boolean;
  deletedAnalyticsFiltering: boolean;
  deletedAnalyticsSearch: string;
  deletedAnalyticsForOwnerFilter: Array<Record<string, string>>;
  deletedAnalyticsResultsCollation: AnalyticsListResultsCollation;
  legacyAnalytics: AnalyticItemObject[];
  legacyAnalyticsCount: number | null;
  legacyAnalyticsLoading: boolean;
  legacyAnalyticsFiltering: boolean;
  legacyAnalyticsSearch: string;
  legacyAnalyticsForOwnerFilter: Array<Record<string, string>>;
  legacyAnalyticsResultsCollation: AnalyticsListResultsCollation;

  // @TODO : Type these reselect return types.
  //
  updatingAnalytic: any;
  deletingAnalytic: any;
}

// Top-level props plus mapDispatchToProps items.
//
export interface AnalyticsComponentData extends AnalyticsData {
  location: any;
  getAnalyticsList: (arg: any) => any;
  setNav: (args) => any;
}

// Defaults for AnalyticsComponentData.
//
export class AnalyticsComponentDataObject implements AnalyticsComponentData {
  location: Record<string, unknown>;
  allAnalytics: [];
  allAnalyticsCount: 0;
  allAnalyticsPaginationCount: 0;
  allAnalyticsLoading: false;
  allAnalyticsFiltering: false;
  allAnalyticsSearch: '';
  allAnalyticsForOwnerFilter: [];
  allAnalyticsResultsCollation: Record<string, unknown>;
  ownedAnalytics: [];
  ownedAnalyticsCount: 0;
  ownedAnalyticsPaginationCount: 0;
  ownedAnalyticsLoading: false;
  ownedAnalyticsFiltering: false;
  ownedAnalyticsSearch: '';
  ownedAnalyticsForOwnerFilter: [];
  ownedAnalyticsResultsCollation: Record<string, never>;
  sharedAnalytics: [];
  sharedAnalyticsCount: 0;
  sharedAnalyticsPaginationCount: 0;
  sharedAnalyticsLoading: false;
  sharedAnalyticsFiltering: false;
  sharedAnalyticsSearch: '';
  sharedAnalyticsForOwnerFilter: [];
  sharedAnalyticsResultsCollation: Record<string, never>;
  starredAnalytics: [];
  starredAnalyticsCount: 0;
  starredAnalyticsPaginationCount: 0;
  starredAnalyticsLoading: false;
  starredAnalyticsFiltering: false;
  starredAnalyticsSearch: '';
  starredAnalyticsForOwnerFilter: [];
  starredAnalyticsResultsCollation: Record<string, never>;
  archivedAnalytics: [];
  archivedAnalyticsCount: 0;
  archivedAnalyticsPaginationCount: 0;
  archivedAnalyticsLoading: false;
  archivedAnalyticsFiltering: false;
  archivedAnalyticsSearch: '';
  archivedAnalyticsForOwnerFilter: [];
  archivedAnalyticsResultsCollation: Record<string, never>;
  deletedAnalytics: [];
  deletedAnalyticsCount: 0;
  deletedAnalyticsPaginationCount: 0;
  deletedAnalyticsLoading: false;
  deletedAnalyticsFiltering: false;
  deletedAnalyticsSearch: '';
  deletedAnalyticsForOwnerFilter: [];
  deletedAnalyticsResultsCollation: Record<string, unknown>;
  legacyAnalytics: [];
  legacyAnalyticsCount: 0;
  legacyAnalyticsLoading: false;
  legacyAnalyticsFiltering: false;
  legacyAnalyticsSearch: '';
  legacyAnalyticsForOwnerFilter: [];
  legacyAnalyticsResultsCollation: Record<string, unknown>;
  updatingAnalytic: null;
  deletingAnalytic: null;
  getAnalyticsList: (arg: any) => any;
  setNav: () => Record<string, never>;

  constructor(props: AnalyticsComponentData) {
    Object.keys(props).forEach(key => {
      if (props[key] !== undefined) {
        this[key] = props[key];
      }
    });
  }
}

export interface AnalyticsListActionCreatorArgs {
  endpointBase?: string;
  pageNumber?: number;
  sort?: 'lastUpdated' | 'title' | 'owner.username';
  sortDirection?: 'asc' | 'desc';
  search?: string | null;
  category?: string | null;
  ownerId?: number | null;
  isAlreadyFiltered?: boolean;
  resultsCollation?: AnalyticsListResultsCollation;
  accountId?: number | null;
  campaigns?: number[];
  isInitialLoad?: boolean;
  doCountReload?: boolean;
  appendItems?: boolean;
}

/* ****************************
 *** HELPERS
 **************************** */

export const mapAnalyticStateToProps = (state): AnalyticsData => {
  return {
    allAnalytics: listAnalyticSelector(state).items,
    allAnalyticsCount: allAnalyticsSidebarCountSelector(state),
    allAnalyticsPaginationCount: allAnalyticsPaginationCountSelector(state),
    allAnalyticsLoading: allAnalyticsLoadingSelector(state),
    allAnalyticsFiltering: allAnalyticsFilteringSelector(state),
    allAnalyticsSearch: allAnalyticsSearchSelector(state),
    allAnalyticsForOwnerFilter: allAnalyticsForOwnerSelector(state),
    allAnalyticsResultsCollation: allAnalyticsResultsCollationSelector(state),

    archivedAnalytics: archivedAnalyticsListSelector(state),
    archivedAnalyticsCount: archivedAnalyticsSidebarCountSelector(state),
    archivedAnalyticsPaginationCount: archivedAnalyticsPaginationCountSelector(
      state,
    ),
    archivedAnalyticsLoading: archivedAnalyticsLoadingSelector(state),
    archivedAnalyticsFiltering: archivedAnalyticsFilteringSelector(state),
    archivedAnalyticsSearch: archivedAnalyticsSearchSelector(state),
    archivedAnalyticsForOwnerFilter: archivedAnalyticsForOwnerSelector(state),
    archivedAnalyticsResultsCollation: archivedAnalyticsResultsCollationSelector(
      state,
    ),

    deletedAnalytics: deletedAnalyticsListSelector(state),
    deletedAnalyticsCount: deletedAnalyticsSidebarCountSelector(state),
    deletedAnalyticsPaginationCount: deletedAnalyticsPaginationCountSelector(
      state,
    ),
    deletedAnalyticsLoading: deletedAnalyticsLoadingSelector(state),
    deletedAnalyticsFiltering: deletedAnalyticsFilteringSelector(state),
    deletedAnalyticsSearch: deletedAnalyticsSearchSelector(state),
    deletedAnalyticsForOwnerFilter: deletedAnalyticsForOwnerSelector(state),
    deletedAnalyticsResultsCollation: deletedAnalyticsResultsCollationSelector(
      state,
    ),

    ownedAnalytics: ownedAnalyticsSelector(state),
    ownedAnalyticsCount: ownedAnalyticsSidebarCountSelector(state),
    ownedAnalyticsPaginationCount: ownedAnalyticsPaginationCountSelector(state),
    ownedAnalyticsLoading: ownedAnalyticsLoadingSelector(state),
    ownedAnalyticsFiltering: ownedAnalyticsFilteringSelector(state),
    ownedAnalyticsSearch: ownedAnalyticsSearchSelector(state),
    ownedAnalyticsForOwnerFilter: ownedAnalyticsForOwnerSelector(state),
    ownedAnalyticsResultsCollation: ownedAnalyticsResultsCollationSelector(
      state,
    ),

    sharedAnalytics: sharedAnalyticsSelector(state),
    sharedAnalyticsCount: sharedAnalyticsSidebarCountSelector(state),
    sharedAnalyticsPaginationCount: sharedAnalyticsPaginationCountSelector(
      state,
    ),
    sharedAnalyticsLoading: sharedAnalyticsLoadingSelector(state),
    sharedAnalyticsFiltering: sharedAnalyticsFilteringSelector(state),
    sharedAnalyticsSearch: sharedAnalyticsSearchSelector(state),
    sharedAnalyticsForOwnerFilter: sharedAnalyticsForOwnerSelector(state),
    sharedAnalyticsResultsCollation: sharedAnalyticsResultsCollationSelector(
      state,
    ),

    starredAnalytics: starredAnalyticsSelector(state),
    starredAnalyticsCount: starredAnalyticsSidebarCountSelector(state),
    starredAnalyticsPaginationCount: starredAnalyticsPaginationCountSelector(
      state,
    ),
    starredAnalyticsLoading: starredAnalyticsLoadingSelector(state),
    starredAnalyticsFiltering: starredAnalyticsFilteringSelector(state),
    starredAnalyticsSearch: starredAnalyticsSearchSelector(state),
    starredAnalyticsForOwnerFilter: starredAnalyticsForOwnerSelector(state),
    starredAnalyticsResultsCollation: starredAnalyticsResultsCollationSelector(
      state,
    ),

    legacyAnalytics: legacyAnalyticsSelector(state),
    legacyAnalyticsCount: legacyAnalyticsSidebarCountSelector(state),
    legacyAnalyticsLoading: legacyAnalyticsLoadingSelector(state),
    legacyAnalyticsFiltering: legacyAnalyticsFilteringSelector(state),
    legacyAnalyticsSearch: legacyAnalyticsSearchSelector(state),
    legacyAnalyticsForOwnerFilter: legacyAnalyticsForOwnerSelector(state),
    legacyAnalyticsResultsCollation: legacyAnalyticsResultsCollationSelector(
      state,
    ),

    updatingAnalytic: getAnalyticUpdatingSelector(state),
    deletingAnalytic: getAnalyticDeletingSelector(state),
  };
};

// This construct, which only adds a key/value pair to an object if the value is defined, is ugly enough to
// warrant its own helper instead of spread operators out the wazoo.
// Specifically it returns the defined values from one object whose keys match the values from another object;
//
// The specific application is:
// 1. Associate specific values from a particular kind of list with generic keys, (e.g. key the count for a list of
// dashboards with 'totalCount' rather than 'sharedAnalyticsCount').
// 2. Filter out undef values.
//
export const normalizeAnalyticsListProps = (props, propKeyValues) =>
  Object.keys(propKeyValues).reduce((accum, curr) => {
    if (props[propKeyValues[curr]] !== undefined) {
      accum[curr] = props[propKeyValues[curr]];
    }
    return accum;
  }, {});

// Turn array of dashboard config objects into path-keyed config objects for easy lookup.
//
export const analyticsListsObjectify = config =>
  config.reduce((accum, curr) => {
    accum[curr.path] = curr;
    return accum;
  }, {});

export const mapAnalyticsProps = (props, propKeys) =>
  propKeys.reduce((accum, curr) => {
    accum[curr] = props[curr];
    return accum;
  }, {});

export const mergeAnalyticsListsConfig = (configurator, props) =>
  configurator({
    allAnalyticActionCreator,
    ownedAnalyticsActionCreator,
    sharedAnalyticsActionCreator,
    starredAnalyticsActionCreator,
    listArchivedAnalyticsActionCreator,
    listDeletedAnalyticsActionCreator,
    legacyAnalyticsActionCreator,
    sidebarAnalyticsActionCreator,

    ...mapAnalyticsProps(props, [
      'allAnalytics',
      'allAnalyticsCount',
      'allAnalyticsPaginationCount',
      'allAnalyticsLoading',
      'allAnalyticsFiltering',
      'allAnalyticsSearch',
      'allAnalyticsForOwnerFilter',
      'allAnalyticsResultsCollation',
      'ownedAnalytics',
      'ownedAnalyticsCount',
      'ownedAnalyticsPaginationCount',
      'ownedAnalyticsLoading',
      'ownedAnalyticsFiltering',
      'ownedAnalyticsSearch',
      'ownedAnalyticsForOwnerFilter',
      'ownedAnalyticsResultsCollation',
      'sharedAnalytics',
      'sharedAnalyticsCount',
      'sharedAnalyticsPaginationCount',
      'sharedAnalyticsLoading',
      'sharedAnalyticsFiltering',
      'sharedAnalyticsSearch',
      'sharedAnalyticsForOwnerFilter',
      'sharedAnalyticsResultsCollation',
      'starredAnalytics',
      'starredAnalyticsCount',
      'starredAnalyticsPaginationCount',
      'starredAnalyticsLoading',
      'starredAnalyticsFiltering',
      'starredAnalyticsSearch',
      'starredAnalyticsForOwnerFilter',
      'starredAnalyticsResultsCollation',
      'archivedAnalytics',
      'archivedAnalyticsCount',
      'archivedAnalyticsPaginationCount',
      'archivedAnalyticsLoading',
      'archivedAnalyticsFiltering',
      'archivedAnalyticsSearch',
      'archivedAnalyticsForOwnerFilter',
      'archivedAnalyticsResultsCollation',
      'deletedAnalytics',
      'deletedAnalyticsCount',
      'deletedAnalyticsPaginationCount',
      'deletedAnalyticsLoading',
      'deletedAnalyticsFiltering',
      'deletedAnalyticsSearch',
      'deletedAnalyticsForOwnerFilter',
      'deletedAnalyticsResultsCollation',
      'legacyAnalytics',
      'legacyAnalyticsCount',
      'legacyAnalyticsLoading',
      'legacyAnalyticsFiltering',
      'legacyAnalyticsSearch',
      'legacyAnalyticsForOwnerFilter',
      'legacyAnalyticsResultsCollation',
    ]),

    intl: props.intl,
  });
