import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const FormatParagraphCenter = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M7 15v2h10v-2H7zm-4 6h18v-2H3v2zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3 3v2h18V3H3z" />
  </svg>
);
FormatParagraphCenter.propTypes = { fill: PropTypes.string };
FormatParagraphCenter.defaultProps = { fill: iconColorsMap.mainicon };

export default FormatParagraphCenter;
