export const TOGGLE_ANALYTIC_SELECTION = 'analytics/TOGGLE_ANALYTIC_SELECTION';
export const TOGGLE_ANALYTICS_SELECTION =
  'analytics/TOGGLE_ANALYTICS_SELECTION';
export const CLEAR_ANALYTICS_SELECTION = 'analytics/CLEAR_ANALYTICS_SELECTION';

export const initialState = {
  ids: [] as string[],
};

const analyticSelectedReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_ANALYTIC_SELECTION: {
      const ids = new Set(state.ids);
      const id = action.payload?.id;
      if (ids.has(id)) {
        ids.delete(id);
      } else {
        ids.add(id);
      }
      return {
        ids: Array.from(ids),
      };
    }
    case TOGGLE_ANALYTICS_SELECTION:
      return {
        ids:
          action.payload.analyticIds.length ===
          action.payload.selectedIds.length
            ? []
            : action.payload.analyticIds,
      };
    case CLEAR_ANALYTICS_SELECTION: {
      return {
        ids: [],
      };
    }
    default:
      return state;
  }
};

export const toggleAnalyticSelection = id => ({
  type: TOGGLE_ANALYTIC_SELECTION,
  payload: { id },
});

export const toggleAnalyticsSelection = (analyticIds, selectedIds) => ({
  type: TOGGLE_ANALYTICS_SELECTION,
  payload: {
    analyticIds,
    selectedIds,
  },
});

export const clearAnalyticsSelection = () => ({
  type: CLEAR_ANALYTICS_SELECTION,
});

export default analyticSelectedReducer;
