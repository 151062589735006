export default {
  clear: {
    id: 'TK_UI_CLEAR',
    defaultMessage: 'Clear',
    description: 'Global term to indicate that something can be cleared.',
  },
  remove: {
    id: 'TK_UI_REMOVE',
    defaultMessage: 'Remove',
    description: 'Global term to indicate that something can be removed.',
  },
  discard: {
    id: 'TK_UI_DISCARD',
    defaultMessage: 'Discard',
    description: 'Global term to indicate that something can be discarded.',
  },
  save: {
    id: 'TK_UI_SAVE',
    defaultMessage: 'Save',
    description: 'Global term to indicate that something should be saved',
  },
  search: {
    id: 'TK_UI_SEARCH',
    defaultMessage: 'Search',
    description: 'Global term to indicate that something should be searched',
  },
  requiredValidationMessage: {
    id: 'TK_UI_REQUIRED_VALIDATION_MESSAGE',
    defaultMessage: 'Required',
    description: 'Global message indicating a form field is required',
  },
  emailValidationMessage: {
    id: 'TK_UI_EMAIL_VALIDATION_MESSAGE',
    defaultMessage: 'Invalid email',
    description:
      'Global message indicating a form field contains an invalid email',
  },
  urlRequiredValidationMessage: {
    id: 'TK_UI_URL_REQUIRED_VALIDATION_MESSAGE',
    defaultMessage: 'A URL is required',
    description: 'Global message indicating a URL form field is required',
  },
  urlValidationMessage: {
    id: 'TK_UI_URL_VALIDATION_MESSAGE',
    defaultMessage: 'Invalid URL',
    description:
      'Global message indicating a form field contains an invalid URL',
  },
  added: {
    id: 'TK_UI_ADDED_MESSAGE',
    defaultMessage: 'Added',
    description: 'Global message indicating that something is already added.',
  },
  listLimitReached: {
    id: 'TK_UI_LIST_LIMIT_REACHED_MESSAGE',
    defaultMessage: 'List Limit Reached',
    description: 'Global message indicating that a list has reached it limit',
  },
  viewOnly: {
    id: 'TK_UI_VIEW_ONLY_MESSAGE',
    defaultMessage: 'View only',
    description: 'Global message indicating the view only permission',
  },
  entries: {
    id: 'TK_UI_ENTRIES_MESSAGE',
    defaultMessage: 'Entries',
    description: 'Global message for the entries text',
  },
  clearAll: {
    id: 'TK_UI_CLEAR_ALL_MESSAGE',
    defaultMessage: 'Clear All',
    description: 'Global message to refer to the clear all action',
  },
  infinityListSelectedItems: {
    id: 'TK_UI_INFINITY_LIST_SELECTED_ITEMS',
    defaultMessage: `{SELECTED_ITEMS_AMOUNT} {ENTRY_LABEL} {SELECTED_ITEMS_AMOUNT, plural,
    =1 {Selected}
    other {Selected}
    }`,
    description:
      'Global message to refer to the number of items selected in a infinity list',
  },
  listSelectedItems: {
    id: 'TK_UI_LIST_SELECTED_ITEMS',
    defaultMessage: `{SELECTED_ITEMS_AMOUNT} / {MAX_AMOUNT} {ENTRY_LABEL} {SELECTED_ITEMS_AMOUNT, plural,
      =1 {Selected}
      other {Selected}
      } `,
    description:
      'Global message to refer to the number of items selected in a infinity list',
  },
};
