import uniq from 'lodash/uniq';
import moment from 'moment';
import { createSelector } from 'reselect';

import { pitchStage } from 'constants/story-constants';
import { articlesSelector } from 'selectors/article';

export const storyObj = state => state.stories || {};
export const storyBuilderSelector = state => storyObj(state).storyBuilder;
export const myStoriesSelector = state => storyObj(state).myStories;
export const storyKeyMessagesSelector = state =>
  storyObj(state).storyKeyMessages;
export const storyRecipientsSelector = state => storyObj(state).storyRecipients;
export const storyCoverageSelector = state =>
  storyObj(state).storyCoverage || {};
export const storyAudienceDemographicsSelector = state =>
  storyObj(state).storyAudienceDemographics.data || { ages: [], genders: [] };
export const storyArticlesLoadingSelector = state =>
  state.articles ? state.articles.loading : true;

export const storySelector = createSelector(
  storyBuilderSelector,
  myStoriesSelector,
  storyKeyMessagesSelector,
  storyRecipientsSelector,
  (storyBuilder, myStories, keyMessages, recipients) => {
    if (!storyBuilder.story) return null;

    return {
      ...storyBuilder.story,
      storyBuilder,
      myStories,
      keyMessages,
      recipients,
      hasKeyMessages: keyMessages.keyMessages.length > 0,
      hasRecipients: recipients.recipients.length > 0,
    };
  },
);

export const storyIdSelector = createSelector(storySelector, story =>
  story ? story.id : NaN,
);

export const storyStateSelector = createSelector(storySelector, story =>
  story ? story.state : '',
);

export const isStoryPublishedSelector = createSelector(
  storySelector,
  story =>
    story && story.state && story.state === pitchStage.live && story.launchDate,
);

export const isStoryPausedSelector = createSelector(
  storySelector,
  story => story && story.state && story.state === pitchStage.pause,
);

const storyDraftContentSelector = createSelector(
  storySelector,
  story => story && story.draftContent,
);

export const storyContentSelector = createSelector(
  storySelector,
  story => story && story.content,
);

export const currentEditingStoryContentSelector = createSelector(
  storyContentSelector,
  storyDraftContentSelector,
  (content, draftContent) => draftContent || content,
);

export const storyHasChangesSelector = createSelector(
  storyDraftContentSelector,
  draftContent => !!draftContent,
);

export const storyTitleSelector = createSelector(
  storySelector,
  story => (story && story.title) || '',
);

export const storyDateUpdatedSelector = createSelector(
  storySelector,
  story => story && story.dateUpdated && moment.utc(story.dateUpdated).toDate(),
);

export const isStorySavingSelector = createSelector(
  storyBuilderSelector,
  storyBuilder => storyBuilder.isSaving,
);

export const isStoryLaunchingOrPublishingSelector = createSelector(
  storyBuilderSelector,
  storyBuilder => storyBuilder.isLaunching || storyBuilder.isPublishing,
);

export const isStorySavePendingSelector = createSelector(
  storyBuilderSelector,
  storyBuilder => storyBuilder.isSavePending,
);

export const currentStorySearchId = createSelector(
  storyBuilderSelector,
  storyBuilder => storyBuilder.story.searchId,
);

export const storyPerformanceMetricsSelector = createSelector(
  storySelector,
  story => {
    return {
      currentVisitors: story.currentVisitors,
      currentVisitingRecipients: story.currentVisitingRecipients,
      openRate: story.openRate,
      openRateDelta: story.openRateDelta === 0 ? '-' : story.openRateDelta,
      averageTimeOnPage: story.averageTimeOnPage,
      averageTimeOnPageDelta:
        story.averageTimeOnPageDelta === 0 ? '-' : story.averageTimeOnPageDelta,
      conversionRate: story.conversionRate,
      converstionRateDelta:
        story.converstionRateDelta === 0 ? '-' : story.converstionRateDelta,
      dailyVisitors: story.dailyVisitors === 0 ? '-' : story.dailyVisitors,
      totalPageVisits: story.totalPageVisits,
      totalPageVisitsDelta:
        story.totalPageVisitsDelta === 0 ? '-' : story.totalPageVisitsDelta,
      openRateGlobal: story.openRateGlobal,
      averageTimeOnPageGlobal: story.averageTimeOnPageGlobal,
      conversionRateGlobal: story.conversionRateGlobal,
      dailyVisitorsGlobal:
        story.dailyVisitorsGlobal === 0 ? '-' : story.dailyVisitorsGlobal,
      totalPageVisitsGlobal: story.totalPageVisitsGlobal,
      currentVisitorsGlobal: story.currentVisitorsGlobal,
    };
  },
);

export const storyArticlesTotalCountSelector = createSelector(
  storyCoverageSelector,
  storyCoverage =>
    storyCoverage && typeof storyCoverage.articlesTotalCount === 'number'
      ? storyCoverage.articlesTotalCount
      : NaN,
);

export const storyArticleIdsSelector = createSelector(
  storyCoverageSelector,
  storyCoverage =>
    storyCoverage.articleIds && Array.isArray(storyCoverage.articleIds)
      ? storyCoverage.articleIds
      : [],
);

export const storyArticlesSelector = createSelector(
  storyArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) =>
    articleIds.map(articleId => {
      if (!articles[articleId]) {
        return {};
      }
      let articleDuplicates = [];
      if (articles[articleId].duplicatePublications) {
        articleDuplicates = articles[articleId].duplicatePublications;
      }
      return {
        ...articles[articleId],
        duplicatePublications: articleDuplicates.map(id => articles[id]),
      };
    }),
);

export const storyArticlesLoadedCountSelector = createSelector(
  storyArticleIdsSelector,
  articleIds => (Array.isArray(articleIds) ? articleIds.length : NaN),
);

export const storyArticlesWithDupesCountSelector = createSelector(
  storyArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) => {
    const articleCount = articleIds.reduce((count, articleId) => {
      const article = articles[articleId];
      if (!article) {
        return count;
      }
      if (
        article.duplicatePublications &&
        article.duplicatePublications.length
      ) {
        return count + article.duplicatePublications.length;
      }
      return count + 1;
    }, 0);

    return articleCount;
  },
);

export const storyArticleIdsSelectedSelector = createSelector(
  storyArticleIdsSelector,
  articlesSelector,
  (articleIds, articles) => {
    const selectedArticleIds = articleIds.reduce((ids, articleId) => {
      const article = articles[articleId];

      if (!article) {
        return ids;
      }

      if (article.duplicatePublications) {
        article.duplicatePublications.forEach(id => {
          if (articles[id] && articles[id].selected) {
            ids.push(id);
          }
        });
      }

      if (article.selected) {
        ids.push(article.id);
      }

      return ids;
    }, []);

    return uniq(selectedArticleIds);
  },
);

export const storyArticlesSelectedCountSelector = createSelector(
  storyArticleIdsSelectedSelector,
  articleIds => (Array.isArray(articleIds) ? articleIds.length : NaN),
);

export const storyArticlesSelectedAllExpandedSelector = createSelector(
  storyArticlesSelector,
  articles => {
    return articles.reduce((allExpanded, article) => {
      if (article.selected && !article.expandedMetrics) {
        allExpanded = false;
      }
      return allExpanded;
    }, true);
  },
);

export const storyRecipientsBreakdownSelector = createSelector(
  storyRecipientsSelector,
  storyRecipients => storyRecipients.outreachMetrics,
);

export const myStoriesListSelector = createSelector(
  myStoriesSelector,
  myStories => myStories.stories || [],
);

export const myStoriesLoadingSelector = createSelector(
  myStoriesSelector,
  myStories => myStories.loadingStories,
);

export const selectedStoryModifiedSharedData = createSelector(
  myStoriesSelector,
  myStories => (myStories ? myStories.selectedStoryModifiedShareData : {}),
);
