import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
  Takes an array of strings passed as a 'modifiers' props and the passed component's static baseClass.
  Extends the className prop of the passed component with BEM-style modifier classes.
*/
const withModifiers = BaseComponent => {
  const ModifiedComponent = ({ forwardedRef, ...otherProps }) => {
    const { className, modifiers } = otherProps;
    const baseClass = BaseComponent.baseClass || '';
    const prefixedModifiers = Array.isArray(modifiers)
      ? modifiers.map(modifier => `${baseClass}--${modifier}`)
      : [];
    const modifiedClassName = classNames(
      baseClass,
      className || '',
      prefixedModifiers,
    );

    const wrappedComponent = (
      <BaseComponent
        {...otherProps}
        className={modifiedClassName}
        ref={forwardedRef}
      />
    );

    return wrappedComponent;
  };

  ModifiedComponent.displayName = `withModifiers(${
    BaseComponent.displayName || BaseComponent.name || 'BaseComponent'
  })`;

  ModifiedComponent.propTypes = {
    ...BaseComponent.propTypes,
    forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    modifiers: PropTypes.arrayOf(PropTypes.string),
  };

  ModifiedComponent.defaultProps = {
    ...BaseComponent.defaultProps,
    forwardedRef: null,
    modifiers: [],
  };

  const ForwardRefComponent = React.forwardRef((props, ref) => (
    <ModifiedComponent {...props} forwardedRef={ref} />
  ));

  ForwardRefComponent.displayName = `ForwardRef(${ModifiedComponent.displayName})`;

  return ForwardRefComponent;
};

export default withModifiers;
