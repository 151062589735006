import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Body, Caption } from '../type';

export const ChartLegendDot = ({ color, style }) => (
  <div className="chart-legend-dot" style={{ borderColor: color, ...style }} />
);

const ChartLegendItem = ({
  name,
  value,
  color,
  titleProps,
  subtitle,
  subtitleValue,
  subtitleProps,
  subtitleValueProps,
  dotStyle,
  valueProps,
}) => (
  <div className="chart-legend-container">
    <div data-qa="chart-legend-item" className="chart-legend-item">
      <div data-qa="chart-legend-dot" className="chart-legend-item__color">
        <ChartLegendDot color={color} style={dotStyle} />
      </div>
      <div
        data-qa="chart-legend-title"
        className={classNames('chart-legend-item__title')}
      >
        <Caption {...titleProps} weight="bold">
          {name}
        </Caption>
      </div>
      <div data-qa="chart-legend-value" className="chart-legend-item__value">
        <Body {...valueProps}>{value}</Body>
      </div>
    </div>
    {subtitle && (
      <div className="chart-legend-subtitle">
        <div className="chart-legend-dot chart-legend-item__color" />
        <div
          data-qa="chart-legend-subtitle"
          className={classNames('chart-legend-item__title')}
        >
          <Caption {...subtitleProps} weight="bold">
            {subtitle}
          </Caption>
        </div>
        <div
          data-qa="chart-legend-subtitle-value"
          className="chart-legend-item__value"
        >
          <Body {...subtitleValueProps}>{subtitleValue}</Body>
        </div>
      </div>
    )}
  </div>
);

ChartLegendDot.propTypes = {
  color: PropTypes.string.isRequired,
  style: PropTypes.object,
};

ChartLegendItem.propTypes = {
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subtitleProps: PropTypes.object,
  subtitleValueProps: PropTypes.object,
  titleProps: PropTypes.object,
  valueProps: PropTypes.object,
  dotStyle: PropTypes.object,
};

ChartLegendItem.defaultProps = {
  color: 'transparent',
  value: '0',
  name: null,
  subtitle: null,
  subtitleValue: '0',
  subtitleProps: undefined,
  subtitleValueProps: undefined,
  titleProps: undefined,
  valueProps: undefined,
  dotStyle: undefined,
};

export default ChartLegendItem;
