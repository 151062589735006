/* eslint-disable react/prop-types */
import React from 'react';

interface ActionsProps {
  className?: string;
  styles?: Record<string, any>;
}

export type ActionsType = React.FC<ActionsProps>;

const Actions: ActionsType = ({ children, className = '', styles = {} }) => {
  return (
    <div className={`tk-modal-actions ${className}`} style={styles}>
      {children}
    </div>
  );
};

export default Actions;
