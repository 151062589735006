import React, { Fragment } from 'react';

import Numeral from 'numeral';

import { ScoreMeter, SvgIcon } from '@trendkite/ui';
import {
  VALUE_FORMATTER_DISPLAY_FORMATS,
  MEDIA_TYPES,
  ARTICLE_METRICS,
  SENTIMENT_TYPES,
  DRILLDOWN_COLUMNS_KEYS,
} from 'constants/constants';

import articleMetricsMessages from 'constants/constants.messages';

import displayValueFormatter from 'services/display-value-formatter/display-value-formatter';

import messages from './form.messages.js';

export const SEO_IMPACT_COLUMN = 'SEO_IMPACT';
const MOBILE_READERSHIP_COLUMN = 'MOBILE_READERSHIP';
export const DESKTOP_READERSHIP_COLUMN = 'DESKTOP_READERSHIP';
export const DESKTOP_READERSHIP_NON_MOBILE_UVM_COLUMN =
  'DESKTOP_READERSHIP_NON_MOBILE_UVM';
const TOTAL_READERSHIP_COLUMN = 'TOTAL_READERSHIP';

export const READERSHIP_COLUMNS = [
  MOBILE_READERSHIP_COLUMN,
  DESKTOP_READERSHIP_COLUMN,
  TOTAL_READERSHIP_COLUMN,
];

export const AVAILABLE_COLUMNS = [
  DRILLDOWN_COLUMNS_KEYS.outlet,
  DRILLDOWN_COLUMNS_KEYS.author,
  'MEDIA_TYPE',
  DRILLDOWN_COLUMNS_KEYS.publicationDate,
  'SHARES',
  SEO_IMPACT_COLUMN,
  'IMPACT_SCORE',
  'SENTIMENT',
  TOTAL_READERSHIP_COLUMN,
  'CIRCULATION',
  'LOCAL_VIEWERSHIP',
  'NATIONAL_VIEWERSHIP',
  'AVE',
  MOBILE_READERSHIP_COLUMN,
  DESKTOP_READERSHIP_COLUMN,
  'COUNTRY',
  'STATE',
  'CITY',
];

export const GA_ANALYTICS_COLUMNS = [
  'GA_SESSIONS',
  'GA_PAGE_VIEWS',
  'GA_AVG_SESSIONS_DURATION',
  'GA_PERCENT_NEW_SESSIONS',
  'GA_NEW_USERS',
  'GA_BOUNCE_RATE',
  'GA_PAGE_VIEWS_PER_SESSION',
  'GA_GOAL_COMPLETIONS_ALL',
  'GA_GOAL_CONVERSION_RATE_ALL',
  'GA_GOAL_VALUE_ALL',
  'GA_TRANSACTION_REVENUE',
];

export const ADOBE_ANALYTICS_COLUMNS = [
  'ADOBE_BOUNCE_RATE',
  'ADOBE_CHECKOUTS',
  'ADOBE_ORDERS',
  'ADOBE_REVENUE',
  'ADOBE_PAGE_VIEWS',
  'ADOBE_TOTAL_TIME_SPENT',
  'ADOBE_VISITS',
  'ADOBE_VISITORS',
];

export const renderSentiment = (sentiment, renderData = {}) => {
  const {
    height = 20,
    width = 20,
    renderText = true,
    formatMessage,
  } = renderData;
  const translateSentiment = formatMessage
    ? {
        [SENTIMENT_TYPES.positive]: formatMessage(
          messages.widgetDrillDownSentimentTypePositive,
        ),
        [SENTIMENT_TYPES.negative]: formatMessage(
          messages.widgetDrillDownSentimentTypeNegative,
        ),
        [SENTIMENT_TYPES.neutral]: formatMessage(
          messages.widgetDrillDownSentimentTypeNeutral,
        ),
        [SENTIMENT_TYPES.undetermined]: formatMessage(
          messages.widgetDrillDownSentimentTypeUndetermined,
        ),
      }
    : undefined;
  return (
    <Fragment>
      <SvgIcon
        modifiers={['right-small']}
        icon={`${sentiment.toLowerCase()}Sentiment`}
        height={height}
        width={width}
      />
      {renderText && formatMessage ? translateSentiment[sentiment] : sentiment}
    </Fragment>
  );
};

const getScoreMeter = value => (
  <div style={{ width: '50px', textAlign: 'center' }}>
    <ScoreMeter value={value} />
  </div>
);

const formatNumber = format => value => {
  if (value === -1) {
    return '-';
  }
  return Numeral(value).format(format);
};

const renderReaderships = (isCirculationColumn, value, mediaType) => {
  const isArticlePrintMediaType = mediaType === MEDIA_TYPES.printData.apiKey;
  if (!isCirculationColumn && isArticlePrintMediaType) {
    value = -1;
  } else if (isCirculationColumn && !isArticlePrintMediaType) {
    value = -1;
  }
  return formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.number)(value);
};

export const renderAnalyticColumn = (value, format) => {
  if ((!value || value < 0) && value !== '-') {
    value = '-';
  }
  return displayValueFormatter(value, format);
};

export default {
  MENTION: {
    label: messages.mentionDefaultLabel,
    sort: 'sort-headline',
    key: 'mention',
    exportLabel: 'headline',
  },
  [DRILLDOWN_COLUMNS_KEYS.author]: {
    label: messages.authorDefaultLabel,
    sort: 'sort-author',
    key: 'author',
    exportLabel: 'author',
  },
  AUTHOR_ID: {
    key: 'authorId',
  },
  MEDIA_TYPE: {
    label: messages.mediaTypeDefaultLabel,
    sort: 'sort-mediatype',
    key: 'mediaType',
    exportLabel: 'mediaType',
  },
  AVE: {
    label: messages.aveDefaultLabel,
    sort: 'sort-ad-equiv',
    key: 'adEquivalency',
    renderer: formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.shortCurrencyUSD),
    exportLabel: 'adEquiv',
  },
  ADOBE_TOTAL_TIME_SPENT: {
    label: articleMetricsMessages.constantsAdobeTotalTimeSpentArticleMetric,
    sort: 'sort-adobe-total-time-spent',
    key: 'adobeTotalTimeSpent',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobeTotalTimeSpent.format),
    exportLabel: 'adobeTotalTimeSpent',
    configId: 'adobeTotalTimeSpent',
  },
  GA_BOUNCE_RATE: {
    label: articleMetricsMessages.constantsGaBounceRateArticleMetric,
    sort: 'sort-ga-bouncerate',
    key: 'gaBounceRate',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaBounceRate.format),
    exportLabel: 'gaBounceRate',
    configId: 'gaBounceRate',
  },
  ADOBE_CHECKOUTS: {
    label: articleMetricsMessages.constantsAdobeCheckoutsArticleMetric,
    sort: 'sort-adobe-checkouts',
    key: 'adobeCheckouts',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobeCheckouts.format),
    exportLabel: 'adobeCheckouts',
    configId: 'adobeCheckouts',
  },
  DESKTOP_READERSHIP: {
    label: messages.desktopReadershipDefaultLabel,
    sort: 'sort-desktop-readership',
    key: 'readership',
    renderer: (value, { mediaType }) =>
      renderReaderships(false, value, mediaType),
    exportLabel: 'desktopReadership',
  },
  DESKTOP_READERSHIP_NON_MOBILE_UVM: {
    label: messages.desktopReadershipMobileUvmLabel,
    sort: 'sort-desktop-readership',
    key: 'readership',
    renderer: (value, { mediaType }) =>
      renderReaderships(false, value, mediaType),
    exportLabel: 'readership',
  },
  IMPACT_SCORE: {
    label: messages.articleImpactDefaultLabel,
    sort: 'sort-impact-score',
    key: 'impactScore',
    renderer: getScoreMeter,
    exportLabel: 'impactScore',
  },
  MOBILE_READERSHIP: {
    label: messages.mobileReadershipDefaultLabel,
    sort: 'sort-mobile-readership',
    key: 'mobileReadership',
    renderer: formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.number),
    exportLabel: 'mobileReadership',
  },
  ADOBE_ORDERS: {
    label: articleMetricsMessages.constantsAdobeOrdersArticleMetric,
    sort: 'sort-adobe-orders',
    key: 'adobeOrders',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobeOrders.format),
    exportLabel: 'adobeOrders',
    configId: 'adobeOrders',
  },
  [DRILLDOWN_COLUMNS_KEYS.outlet]: {
    label: messages.outletDefaultLabel,
    sort: 'sort-publisher',
    key: 'publisher',
    exportLabel: 'mediaType',
  },
  [DRILLDOWN_COLUMNS_KEYS.publicationDate]: {
    label: messages.pubDateDefaultLabel,
    sort: 'sort-date',
    key: 'publishDate',
    exportLabel: 'date',
  },
  ADOBE_REVENUE: {
    label: articleMetricsMessages.constantsAdobeRevenueArticleMetric,
    sort: 'sort-adobe-revenue',
    key: 'adobeRevenue',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobeRevenue.format),
    exportLabel: 'adobeRevenue',
    configId: 'adobeRevenue',
  },
  SENTIMENT: {
    label: messages.sentimentDefaultLabel,
    sort: 'sort-sentiment',
    key: 'sentiment',
    renderer: renderSentiment,
    exportLabel: 'sentiment',
  },
  SEO_IMPACT: {
    label: messages.domainAuthorityDefaultLabel,
    sort: 'sort-seo-impact',
    key: 'seoImpact',
    renderer: formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.number),
    exportLabel: 'seoImpact',
  },
  SHARES: {
    label: messages.sharesDefaultLabel,
    sort: 'sort-shares',
    key: 'socialSum',
    renderer: formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.number),
    exportLabel: 'shares',
  },
  TOTAL_READERSHIP: {
    label: messages.totalReadershipDefaultLabel,
    sort: 'sort-total-readership',
    key: 'totalReadership',
    renderer: formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.number),
    exportLabel: 'totalReadership',
  },
  COUNTRY: {
    label: messages.countryDefaultLabel,
    sort: 'sort-country',
    key: 'country',
    exportLabel: null,
  },
  STATE: {
    label: messages.stateDefaultLabel,
    sort: 'sort-state',
    key: 'state',
    exportLabel: null,
  },
  CITY: {
    label: messages.cityDefaultLabel,
    sort: 'sort-city',
    key: 'city',
    exportLabel: null,
  },
  LOCAL_VIEWERSHIP: {
    label: messages.localViewershipDefaultLabel,
    sort: 'sort-local-viewership',
    key: 'localViewership',
    renderer: formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.number),
    exportLabel: 'localViewership',
  },
  NATIONAL_VIEWERSHIP: {
    label: messages.nationalViewershipDefaultLabel,
    sort: 'sort-national-viewership',
    key: 'nationalViewership',
    renderer: formatNumber(VALUE_FORMATTER_DISPLAY_FORMATS.number),
    exportLabel: 'nationalViewership',
  },
  CIRCULATION: {
    label: messages.circulation,
    sort: 'sort-circulation',
    key: 'readership',
    renderer: (value, { mediaType }) =>
      renderReaderships(true, value, mediaType),
    exportLabel: 'circulation',
  },
  GA_SESSIONS: {
    label: articleMetricsMessages.constantsGaSessionsArticleMetric,
    sort: 'sort-ga-sessions',
    key: 'gaSessions',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaSessions.format),
    exportLabel: 'gaSessions',
    configId: 'gaSessions',
  },
  GA_PAGE_VIEWS: {
    label: articleMetricsMessages.constantsGaPageviewsArticleMetric,
    sort: 'sort-ga-pageviews',
    key: 'gaPageviews',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaPageviews.format),
    exportLabel: 'gaPageviews',
    configId: 'gaPageviews',
  },
  GA_AVG_SESSIONS_DURATION: {
    label: articleMetricsMessages.constantsGaAvgSessionDurationArticleMetric,
    sort: 'sort-ga-avgsessionduration',
    key: 'gaAvgSessionDuration',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaAvgSessionDuration.format),
    exportLabel: 'gaAvgSessionDuration',
    configId: 'gaAvgSessionDuration',
  },
  GA_PERCENT_NEW_SESSIONS: {
    label: articleMetricsMessages.constantsGaPercentNewSessionsArticleMetric,
    sort: 'sort-ga-percentnewsessions',
    key: 'gaPercentNewSessions',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaPercentNewSessions.format),
    exportLabel: 'gaPercentNewSessions',
    configId: 'gaPercentNewSessions',
  },
  GA_NEW_USERS: {
    label: articleMetricsMessages.constantsGaNewUsersArticleMetric,
    sort: 'sort-ga-newusers',
    key: 'gaNewUsers',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaNewUsers.format),
    exportLabel: 'gaNewUsers',
    configId: 'gaNewUsers',
  },
  GA_PAGE_VIEWS_PER_SESSION: {
    label: articleMetricsMessages.constantsGaPageviewsPerSessionArticleMetric,
    sort: 'sort-ga-pageviewspersession',
    key: 'gaPageviewsPerSession',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaPageviewsPerSession.format),
    exportLabel: 'gaPageviewsPerSession',
    configId: 'gaPageviewsPerSession',
  },
  GA_GOAL_COMPLETIONS_ALL: {
    label: articleMetricsMessages.constantsGaGoalCompletionsAllArticleMetric,
    sort: 'sort-ga-goalcompletionsall',
    key: 'gaGoalCompletionsAll',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaGoalCompletionsAll.format),
    exportLabel: 'gaGoalCompletionsAll',
    configId: 'gaGoalCompletionsAll',
  },
  GA_GOAL_CONVERSION_RATE_ALL: {
    label: articleMetricsMessages.constantsGaGoalConversionRateAllArticleMetric,
    sort: 'sort-ga-goalconversionrateall',
    key: 'gaGoalConversionRateAll',
    renderer: value =>
      renderAnalyticColumn(
        value,
        ARTICLE_METRICS.gaGoalConversionRateAll.format,
      ),
    exportLabel: 'gaGoalConversionRateAll',
    configId: 'gaGoalConversionRateAll',
  },
  GA_GOAL_VALUE_ALL: {
    label: articleMetricsMessages.constantsGaGoalValueAllArticleMetric,
    sort: 'sort-ga-goalvalueall',
    key: 'gaGoalValueAll',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaGoalValueAll.format),
    exportLabel: 'gaGoalValueAll',
    configId: 'gaGoalValueAll',
  },
  GA_TRANSACTION_REVENUE: {
    label: articleMetricsMessages.constantsGaTransactionRevenueArticleMetric,
    sort: 'sort-ga-transactionrevenue',
    key: 'gaTransactionRevenue',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.gaTransactionRevenue.format),
    exportLabel: 'gaTransactionRevenue',
    configId: 'gaTransactionRevenue',
  },
  ADOBE_BOUNCE_RATE: {
    label: articleMetricsMessages.constantsAdobeBounceRateArticleMetric,
    sort: 'sort-adobe-bounce-rate',
    key: 'adobeBounceRate',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobeBounceRate.format),
    exportLabel: 'adobeBounceRate',
    configId: 'adobeBounceRate',
  },
  ADOBE_PAGE_VIEWS: {
    label: articleMetricsMessages.constantsAdobePageviewsArticleMetric,
    sort: 'sort-adobe-pageviews',
    key: 'adobePageviews',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobePageviews.format),
    exportLabel: 'adobePageviews',
    configId: 'adobePageviews',
  },
  ADOBE_VISITS: {
    label: articleMetricsMessages.constantsAdobeVisitsArticleMetric,
    sort: 'sort-adobe-visits',
    key: 'adobeVisits',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobeVisits.format),
    exportLabel: 'adobeVisits',
    configId: 'adobeVisits',
  },
  ADOBE_VISITORS: {
    label: articleMetricsMessages.constantsAdobeVisitorsArticleMetric,
    sort: 'sort-adobe-visitors',
    key: 'adobeVisitors',
    renderer: value =>
      renderAnalyticColumn(value, ARTICLE_METRICS.adobeVisitors.format),
    exportLabel: 'adobeVisitors',
    configId: 'adobeVisitors',
  },
};
