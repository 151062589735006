import React from 'react';

export type PopperData = {
  styles: Record<string, any>;
  attributes: Record<string, any>;
};

type ContextType = {
  isOpen: boolean;
  usePortal: boolean;
  popperData: PopperData;
  portalNode?: HTMLElement;
  setTriggerRef: (ref) => void;
  setPopperRef: (ref) => void;
};

const DEFAULT_CONTEXT_VALUE: ContextType = {
  isOpen: false,
  usePortal: false,
  popperData: { styles: {}, attributes: {} },
  setTriggerRef: () => null,
  setPopperRef: () => null,
};

const PopperDropdownContext = React.createContext(DEFAULT_CONTEXT_VALUE);
export default PopperDropdownContext;
