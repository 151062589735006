import { USER_INFO_ENDPOINT } from '../../constants/apis';
import { performPost } from '../rest-service/rest-service';

export const getCurrentUserId = () =>
  window.activeUser?.id === -1 ? null : window.activeUser?.id || null;

export const getCurrentUserName = () => window.activeUser?.username || '';

export const getCurrentUserImpersonating = () =>
  window.activeUser?.impersonatorId === ''
    ? null
    : window.activeUser?.impersonatorId ?? null;

export const getCurrentUserAccountId = () =>
  window.activeUser?.account?.id ?? null;

export const getCurrentUserLanguage = () => window.activeUser?.language ?? null;

export const getDisplayNamesByIds = idArray =>
  performPost(USER_INFO_ENDPOINT, { ids: idArray })
    .then(response => {
      const displayNames = {};
      response.data.forEach(info => {
        let display;
        if (info.first) {
          display = info.first;
        }
        if (info.last) {
          display += ` ${info.last}`;
        }
        if (!info.first) {
          display = info.username;
        }
        displayNames[info.userId] = display;
      });
      return displayNames;
    })
    .catch(error => {
      return error;
    });

export const objectBelongsToActiveUser = objectOwnerId => {
  const activeUserId = getCurrentUserId();
  return activeUserId && activeUserId === objectOwnerId;
};

export const searchIsSharedAndIsFullAccess = (shared, viewOnly) => {
  return shared && !viewOnly;
};

const getAnalyticsIntegrations = () => {
  return window.appInfo.analyticsIntegrations || [];
};

export const getAnalyticsIntegrationsEnabled = () => {
  return getAnalyticsIntegrations().filter(
    analyticIntegration => analyticIntegration.isEnabled,
  );
};
