import {
  ACCOUNT_GET_BY_FEATURE_ENDPOINT,
  ACCOUNT_GET_BY_DEV_FEATURE_ENDPOINT,
} from 'constants/apis';
import messages from 'pages/Admin/Analysis/Features/Features.messages';

import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';
import { performGet } from 'services/rest-service/rest-service';

export const GET_ACCOUNTS = 'analysisManagement/GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'analysisManagement/GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'analysisManagement/GET_ACCOUNTS_ERROR';

export const initialState = {
  accounts: {},
  accountsLoading: {},
  devAccounts: {},
  devAccountsLoading: {},
  loading: false,
  error: null,
};

const analysisManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        [action.payload.keyLoading]: {
          ...state[action.payload.keyLoading],
          [action.payload.feature]: true,
        },
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        [action.payload.keyAccounts]: {
          ...state[action.payload.keyAccounts],
          ...action.payload.accounts,
        },
        [action.payload.keyLoading]: {
          ...state[action.payload.keyLoading],
          [action.payload.feature]: false,
        },
        error: null,
      };
    case GET_ACCOUNTS_ERROR:
      return {
        ...state,
        [action.payload.keyLoading]: {
          ...state[action.payload.keyLoading],
          [action.payload.feature]: false,
        },
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export const getAccountsByFeature = (
  feature,
  intl,
  isDevFeature = false,
) => dispatch => {
  const keyLoading = isDevFeature ? 'devAccountsLoading' : 'accountsLoading';
  dispatch({ type: GET_ACCOUNTS, payload: { feature, keyLoading } });
  return performGet(
    isDevFeature
      ? ACCOUNT_GET_BY_DEV_FEATURE_ENDPOINT
      : ACCOUNT_GET_BY_FEATURE_ENDPOINT,
    { [isDevFeature ? 'devFeature' : 'feature']: feature },
  )
    .then(resp => {
      if (resp.status === 200 && resp.data) {
        const { results } = resp.data;
        const accounts = { [feature]: results.filter(acc => acc.id > 0) };
        dispatch({
          type: GET_ACCOUNTS_SUCCESS,
          payload: {
            accounts,
            feature,
            keyAccounts: isDevFeature ? 'devAccounts' : 'accounts',
            keyLoading,
          },
        });
      }
    })
    .catch(e => {
      dispatch({
        type: GET_ACCOUNTS_ERROR,
        payload: { error: e.response, feature, keyLoading },
      });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: intl.formatMessage(messages.getAccountsErrorMsg),
          status: 'danger',
        }),
      );
    });
};

export default analysisManagementReducer;
