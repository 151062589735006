import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import ComponentType from '../component-type';
import SvgIcon from '../svg-icon';

import AccordionListEntry from './AccordionListEntry';

class AccordionList extends Component {
  state = {
    collapsed: {},
  };

  onSelect = entry => {
    this.props.onSelect(entry);
  };

  toggle = section => {
    this.setState({
      collapsed: {
        ...this.state.collapsed,
        [section.id]: !this.state.collapsed[section.id],
      },
    });
  };

  render() {
    const { className, EntryComponent, entries, basicStyle } = this.props;
    const { collapsed } = this.state;

    const baseListClass = 'tk-accordion-list';

    const listClasses = classNames(baseListClass, className);

    const listMembers = entries.map(section => {
      return (
        <div
          className={
            !basicStyle
              ? classNames(`${baseListClass}__section`, {
                  [`${baseListClass}__section--collapsed`]: collapsed[
                    section.id
                  ],
                })
              : classNames(`${baseListClass}__basicStyle-section`, {
                  [`${baseListClass}__basicStyle-section--collapsed`]: collapsed[
                    section.id
                  ],
                })
          }
          key={section.id}
        >
          {!basicStyle && (
            /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
            <div
              data-qa="1AldJIrLq-tAYHtiZl_9H"
              className={`${baseListClass}__section-header`}
              onClick={() => this.toggle(section)}
              role="button"
              tabIndex="0"
            >
              {section.title}
              <SvgIcon icon="arrowDown" height={7} width={7} />
            </div>
          )}
          {basicStyle && (
            /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
            <div
              data-qa="4kmgoL-uHc4-poEml-bEq"
              className={`${baseListClass}__basicStyle-section-header`}
              onClick={() => this.toggle(section)}
              role="button"
              tabIndex="0"
            >
              {section.title}
              <SvgIcon
                icon="chevronDown"
                height={7}
                width={7}
                modifiers={['left']}
              />
            </div>
          )}
          <div
            className={
              basicStyle
                ? `${baseListClass}__basicStyle-entries`
                : `${baseListClass}__entries`
            }
          >
            {section.entries &&
              section.entries.map(entry => {
                return (
                  <EntryComponent
                    entry={entry}
                    disabled={entry.disabled}
                    key={entry.id}
                    onSelect={this.onSelect}
                    selected={entry.selected}
                  />
                );
              })}
          </div>
        </div>
      );
    });

    return (
      <div className={`${baseListClass}-container`}>
        <div className={listClasses}>
          <div className={`${baseListClass}-entries`}>{listMembers}</div>
        </div>
      </div>
    );
  }
}

AccordionList.propTypes = {
  className: PropTypes.string,
  EntryComponent: ComponentType,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      entries: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.object,
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
          title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
            .isRequired,
        }),
      ),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
  basicStyle: PropTypes.bool,
};

AccordionList.defaultProps = {
  className: '',
  EntryComponent: AccordionListEntry,
  entries: [],
  onSelect: () => {},
};

export default AccordionList;
