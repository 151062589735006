import { getCurrentUserId } from 'services/user-service/user-service';

const gTagId =
  window.PAGE_DATA?.environment.toLowerCase() === 'production'
    ? 'G-4G5M0DH2J2'
    : 'G-JXG0NDHEPV';
const gtagLink = `https://www.googletagmanager.com/gtag/js?id=${gTagId}`;

/* eslint-disable */
!(function () {
  var e = window;
  var t = document,
    a = function () {
      var e = t.createElement('script');
      (e.type = 'text/javascript'), (e.async = !0), (e.src = gtagLink);
      var a = t.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(e, a);
    };
  'complete' === document.readyState
    ? a()
    : e.attachEvent
    ? e.attachEvent('onload', a)
    : e.addEventListener('load', a, !1);
})();

window.dataLayer = window.dataLayer || [];
const gtag = function () {
  window.dataLayer.push(arguments);
};
gtag('js', new Date());

gtag('config', gTagId, {
  send_page_view: false,
  user_id: getCurrentUserId(),
});
