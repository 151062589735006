import uniq from 'lodash/uniq';

import { CAMPAIGN_ENDPOINT } from 'constants/apis';
import { addPageMessage } from 'reducers/page-messages';
import { performGet, performPut } from 'services/rest-service/rest-service';
import {
  getCurrentUserId,
  getDisplayNamesByIds,
} from 'services/user-service/user-service';

import messages from '../../pages/Campaigns/Campaigns.messages';

import { UPDATE_CAMPAIGN_PROPERTY } from './campaign';

export const GET_CAMPAIGN_NEWSLETTERS =
  'campaigns/newsletters/GET_CAMPAIGN_NEWSLETTERS';
export const GET_CAMPAIGN_NEWSLETTERS_RECEIVED =
  'campaigns/newsletters/GET_CAMPAIGN_NEWSLETTERS_RECEIVED';
export const GET_CAMPAIGN_NEWSLETTERS_ERROR =
  'campaigns/newsletters/GET_CAMPAIGN_NEWSLETTERS_ERROR';
export const DELETE_CAMPAIGN_NEWSLETTER =
  'campaigns/newsletters/DELETE_CAMPAIGN_NEWSLETTER';
export const DELETE_CAMPAIGN_NEWSLETTER_SUCCESS =
  'campaigns/newsletters/DELETE_CAMPAIGN_NEWSLETTER_SUCCESS';
export const DELETE_CAMPAIGN_NEWSLETTER_ERROR =
  'campaigns/newsletters/DELETE_CAMPAIGN_NEWSLETTER_ERROR';
export const GET_CAMPAIGN_NEWSLETTER_OWNERS =
  'campaigns/newsletters/GET_CAMPAIGN_NEWSLETTER_OWNERS';
export const GET_CAMPAIGN_NEWSLETTER_OWNERS_SUCCESS =
  'campaigns/newsletters/GET_CAMPAIGN_NEWSLETTER_OWNERS_SUCCESS';
export const GET_CAMPAIGN_NEWSLETTER_OWNERS_ERROR =
  'campaigns/newsletters/GET_CAMPAIGN_NEWSLETTER_OWNERS_ERROR';

export const initialState = {
  newsletters: [],
  loading: false,
  error: false,
};

const campaignNewslettersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_NEWSLETTERS: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }

    case GET_CAMPAIGN_NEWSLETTERS_RECEIVED: {
      const { newsletters } = action.payload;
      return {
        loading: false,
        error: false,
        newsletters,
      };
    }

    case GET_CAMPAIGN_NEWSLETTERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case GET_CAMPAIGN_NEWSLETTER_OWNERS_SUCCESS: {
      const displayNames = action.payload;
      const newslettersWithNames = [...state.newsletters];
      newslettersWithNames.forEach(
        nl => (nl.ownedBy = displayNames[nl.userId] || ''),
      );
      return {
        ...state,
        newsletters: [...newslettersWithNames],
      };
    }

    case DELETE_CAMPAIGN_NEWSLETTER: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }

    case DELETE_CAMPAIGN_NEWSLETTER_SUCCESS: {
      const { newsletters } = action.payload;

      return {
        ...state,
        loading: false,
        newsletters,
      };
    }

    case DELETE_CAMPAIGN_NEWSLETTER_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
};

const getNewsletterOwnerNames = ownerIds => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_NEWSLETTER_OWNERS });

  try {
    const displayNames = await getDisplayNamesByIds(ownerIds);
    dispatch({
      type: GET_CAMPAIGN_NEWSLETTER_OWNERS_SUCCESS,
      payload: displayNames,
    });
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_NEWSLETTER_OWNERS_ERROR });
    throw e;
  }
};

export const getCampaignNewslettersByCampaignId = campaignId => async dispatch => {
  dispatch({ type: GET_CAMPAIGN_NEWSLETTERS });

  try {
    const currentUserId = getCurrentUserId();
    const newslettersRequest = await performGet(
      `${CAMPAIGN_ENDPOINT}/${campaignId}/newsletter`,
    );
    const newsletters = newslettersRequest.data.map(newsletter => ({
      ...newsletter,
      svgIcon: 'newsletter',
      url: `/alerts#/newsletter/edit/${newsletter.id}`,
      owned: newsletter.userId === currentUserId,
      shared: newsletter.userId !== currentUserId,
    }));
    dispatch({
      type: GET_CAMPAIGN_NEWSLETTERS_RECEIVED,
      payload: { newsletters },
    });
    const ownerIds = uniq(newsletters.map(nl => nl.userId));
    if (ownerIds.length > 0) {
      dispatch(getNewsletterOwnerNames(ownerIds));
    }
  } catch (e) {
    dispatch({ type: GET_CAMPAIGN_NEWSLETTERS_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: 'Failed to fetch newsletters for your campaign.',
        status: 'danger',
      }),
    );
  }
};

export const deleteCampaignNewslettersById = (
  campaignId,
  newsletterIds,
  intl,
) => async (dispatch, getState) => {
  const campaignNewsletters = getState().campaignNewsletterList.newsletters;
  const updatedList = campaignNewsletters.filter(
    newsletter => newsletterIds.indexOf(newsletter.id) === -1,
  );

  dispatch({ type: DELETE_CAMPAIGN_NEWSLETTER });

  try {
    await performPut(`${CAMPAIGN_ENDPOINT}/${campaignId}`, {
      newsletters: updatedList.map(newsletter => newsletter.id),
    });
    dispatch({
      type: DELETE_CAMPAIGN_NEWSLETTER_SUCCESS,
      payload: { newsletters: updatedList },
    });
    dispatch({
      type: UPDATE_CAMPAIGN_PROPERTY,
      payload: { newsletters: updatedList.map(newsletter => newsletter.id) },
    });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.newslettersDeletedSuccess),
        status: 'success',
        ttl: 3000,
      }),
    );
  } catch (e) {
    dispatch({ type: DELETE_CAMPAIGN_NEWSLETTER_ERROR });
    dispatch(
      addPageMessage({
        isNewUI: true,
        text: intl.formatMessage(messages.newslettersDeletedFailed),
        status: 'danger',
      }),
    );
  }
};

export default campaignNewslettersReducer;
