export const TOGGLE_RECONNECT_MODAL = 'outreach/TOGGLE_RECONNECT_MODAL';
export const CLOSE_RECONNECT_MODAL = 'outreach/CLOSE_RECONNECT_MODAL';

export const CONTINUE_PATH_COMPOSE_MODAL = 'compose-modal';
export const CONTINUE_PATH_BULK_SEND = 'bulk-send';

const initialState = {
  isOpen: false,
  continuePath: '',
  continueState: null,
};

const outreachReconnectModalReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CLOSE_RECONNECT_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    case TOGGLE_RECONNECT_MODAL: {
      return {
        ...state,
        isOpen: !state.isOpen,
        continuePath: action.payload ? action.payload.continuePath : '',
        continueState: action.payload ? action.payload.continueState : null,
      };
    }
    default:
      return state;
  }
};

export const closeReconnectModalActionCreator = () => ({
  type: CLOSE_RECONNECT_MODAL,
});

export const toggleReconnectModalActionCreator = ({
  continuePath,
  continueState,
}) => ({
  type: TOGGLE_RECONNECT_MODAL,
  payload: { continuePath, continueState },
});

export default outreachReconnectModalReducer;
