const prodAppID = '55afe9b8-9f3d-4565-a9b8-0d9c2a130d86';
const prodClientToken = 'pub896522749f046fe10d7901dc8e907391';

// Vendor code
/* eslint-disable */
// prettier-ignore

(function (h, o, u, n, d) {
  h = h[d] = h[d] || {
    q: [],
    onReady: function (c) {
      h.q.push(c);
    },
  };
  d = o.createElement(u);
  d.async = 1;
  d.src = n;
  n = o.getElementsByTagName(u)[0];
  n.parentNode.insertBefore(d, n);
})(
  window,
  document,
  'script',
  'https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js',
  'DD_RUM',
);

const getEnvironment = () => window.PAGE_DATA.environment.toLowerCase();

window.DD_RUM.onReady(function () {
  const env = getEnvironment();
  if (env == 'production') {
    window.DD_RUM.init({
      applicationId: prodAppID,
      clientToken: prodClientToken,
      site: 'datadoghq.com',
      service: 'app2.cision.com',
      env: 'prod',
      version: window.PAGE_DATA.version,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0, // since fullstory has a similar feature, we don't want to add too much overhead to all the users
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls: [
        {
          match: 'https://app2.cision.com',
          propagatorTypes: ['datadog', 'tracecontext'],
        },
      ],
    });
    window.DD_RUM.startSessionReplayRecording();
  }
});

/* eslint-enable */
