import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { injectIntl } from 'react-intl';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/de';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/nl';
import 'moment/locale/nb';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';
import 'moment/locale/en-ca';

import withModifiers from '../withModifiers';

const CalendarDatePicker = props => {
  let calendarLocale = props.intl.locale;
  // Hacks for locales that aren't official or doesn't match what moment supports
  switch (calendarLocale) {
    case 'no-no': // Norwegians are mean, no is not actually a thing
      calendarLocale = 'nb';
      break;
    case 'en-se': // this is a hack to launch in Sweden with english phrases, but swedish formatting
      calendarLocale = 'sv';
      break;
  }

  const selectedDate = moment
    .utc(props.selectedMoment, 'x')
    .utcOffset(props.utcOffset);

  const filterDates = date => {
    // Disable dates from today and onwards
    return date.isBefore(moment().add(1, 'day').startOf('day'));
  };

  return (
    <div className={`${CalendarDatePicker.baseClass} ${props.className}`}>
      <ReactDatePicker
        inline
        selectsStart={props.selectsStart}
        selectsEnd={props.selectsEnd}
        minDate={moment.utc(props.minDate).utcOffset(props.utcOffset)}
        maxDate={moment.utc(props.maxDate).utcOffset(props.utcOffset)}
        startDate={moment.utc(props.startDate, 'x').utcOffset(props.utcOffset)}
        endDate={moment.utc(props.endDate, 'x').utcOffset(props.utcOffset)}
        selected={selectedDate}
        onChange={props.onDateChange}
        onSelect={props.onSelect}
        onClickOutside={props.onClickOutside}
        locale={calendarLocale}
        openToDate={props.focusOnSelected ? selectedDate : undefined}
        filterDate={props.disableFutureDates ? filterDates : null}
      />
    </div>
  );
};

CalendarDatePicker.baseClass = 'tk-calendar-datepicker';

CalendarDatePicker.propTypes = {
  intl: PropTypes.object.isRequired,
  className: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(moment),
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(moment),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(moment),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(moment),
  ]),
  onDateChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectsEnd: PropTypes.bool,
  selectsStart: PropTypes.bool,
  selectedMoment: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(moment),
  ]).isRequired,
  onClickOutside: PropTypes.func,
  utcOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  focusOnSelected: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
};

CalendarDatePicker.defaultProps = {
  className: '',
  minDate: null,
  maxDate: null,
  selectsEnd: false,
  selectsStart: false,
  startDate: null,
  endDate: null,
  onClickOutside: () => {},
  utcOffset: '+00:00',
  focusOnSelected: false,
  disableFutureDates: false,
};

export default injectIntl(withModifiers(CalendarDatePicker));
