import React from 'react';

import { Button, Media } from '@cision/rover-ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Loader } from '@trendkite/ui';
import TranslatedMessage from 'i18n/TranslatedMessage';

import messages from './EntitiesList.messages';
import styles from './EntitiesList.module.css';

const EntitiesList = ({
  className,
  entities,
  getExternalUrlFromEntity,
  getUrlFromEntity,
  headMessageKey,
  idKey,
  info,
  nameKey,
  onClickEntity,
  ...passedProps
}) => {
  if (!info.isLoading && !entities.length) {
    return null;
  }

  return (
    <div
      className={classNames(styles.EntitiesList, className)}
      {...passedProps}
    >
      <hr className={styles.separator} />
      <ul className={styles.list}>
        <li className={styles.listHead}>
          <Media>
            <Media.Body>
              <TranslatedMessage {...messages[headMessageKey]} />
            </Media.Body>
            {info.isFetching && (
              <Media.Item>
                <Loader size="tiny" />
              </Media.Item>
            )}
          </Media>
        </li>
        {info.isLoading && <Loader className={styles.listLoading} />}
        {entities
          .filter(entity => !!entity[idKey] && !!entity[nameKey])
          .slice(0, 5)
          .map(entity => (
            <li className={styles.item} key={entity[idKey]}>
              <Button
                data-qa="hLilBWRRCjU1nbofRGO32"
                className={styles.itemButton}
                href={getExternalUrlFromEntity(entity)}
                level="link"
                onClick={onClickEntity}
                tag={
                  (getUrlFromEntity(entity) && Link) ||
                  (getExternalUrlFromEntity(entity) && 'a') ||
                  'button'
                }
                to={getUrlFromEntity(entity)}
              >
                {entity[nameKey]}
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};

EntitiesList.propTypes = {
  className: PropTypes.string,
  info: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }).isRequired,
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  headMessageKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  getExternalUrlFromEntity: PropTypes.func,
  getUrlFromEntity: PropTypes.func,
  onClickEntity: PropTypes.func,
};

EntitiesList.defaultProps = {
  className: '',
  getExternalUrlFromEntity: () => {},
  getUrlFromEntity: () => {},
  onClickEntity: () => {},
};

export default EntitiesList;
