import { NEWSLETTER_LIST_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

export const GET_NEWSLETTER_LIST = 'newsletters/GET_NEWSLETTER_LIST';
export const GET_NEWSLETTER_LIST_RECEIVED =
  'newsletters/GET_NEWSLETTER_LIST_RECEIVED';
export const GET_OWNED_NEWSLETTERS_TOTAL =
  'newsletters/GET_OWNED_NEWSLETTERS_TOTAL';
export const GET_NEWSLETTER_LIST_RECEIVED_HITS_BY_CAMPAIGN =
  'newsletters/GET_NEWSLETTER_LIST_RECEIVED_HITS_BY_CAMPAIGN';
export const GET_NEWSLETTER_LIST_ERROR =
  'newsletters/GET_NEWSLETTER_LIST_ERROR';

export const GET_NEWSLETTER_COUNTS = 'newsletters/GET_NEWSLETTER_COUNTS';

export const initialState = {
  error: false,
  loading: false,
  newsletters: [],
  hits: 0,
  hitsByCampaign: 0,
  sharedNewslettersTotal: 0,
  ownedNewslettersTotal: 0,
  allNewslettersTotal: 0,
};

const newsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWSLETTER_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_NEWSLETTER_LIST_RECEIVED:
      return {
        ...state,
        error: false,
        loading: false,
        newsletters: action.payload.newsletters,
      };
    case GET_NEWSLETTER_LIST_RECEIVED_HITS_BY_CAMPAIGN:
      return {
        ...state,
        hitsByCampaign: action.payload.hitsByCampaign,
      };
    case GET_NEWSLETTER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_NEWSLETTER_COUNTS:
      return {
        ...state,
        hits: action.payload.hits,
        sharedNewslettersTotal: action.payload.sharedNewslettersTotal,
        ownedNewslettersTotal: action.payload.ownedNewslettersTotal,
        allNewslettersTotal: action.payload.allNewslettersTotal,
      };
    default:
      return state;
  }
};

export const getNewsletters = () => dispatch => {
  dispatch({ type: GET_NEWSLETTER_LIST });
  performGet(`${NEWSLETTER_LIST_ENDPOINT}`)
    .then(response => {
      const data = response.data || [];
      data.newsletters.forEach(d => {
        d.svgIcon = 'newsletter';
        d.type = 'newsletters';
      });
      dispatch({
        type: GET_NEWSLETTER_LIST_RECEIVED,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_NEWSLETTER_LIST_ERROR });
    });
};

export default newsletterReducer;
