export const SET_CURRENT_NAV = 'currentNav/SET_CURRENT_NAV';
export const CLEAR_CURRENT_NAV = 'currentNav/CLEAR_CURRENT_NAV';

const initialState = {
  page: null,
};

const currentNavReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_CURRENT_NAV: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case CLEAR_CURRENT_NAV: {
      return {
        ...state,
        page: null,
      };
    }
    default:
      return state;
  }
};

export const setCurrentNav = nav => ({
  type: SET_CURRENT_NAV,
  payload: nav,
});

export const clearCurrentNav = () => ({ type: CLEAR_CURRENT_NAV });

export default currentNavReducer;
