export default {
  importContactListPreferencesListName: {
    id: 'IMPORT_CONTACT_LIST_PREFERENCES_LIST_NAME',
    description: 'Label for the Preferences list name',
    defaultMessage: 'List Name:',
  },
  importContactListPreferencesListShareSettings: {
    id: 'IMPORT_CONTACT_LIST_PREFERENCES_LIST_SHARE_SETTINGS',
    description: 'Label for the Preferences list share settings',
    defaultMessage: 'List Share Settings:',
  },
  importContactListPreferencesPrivateOptionLabel: {
    id: 'IMPORT_CONTACT_LIST_PREFERENCES_PRIVATE_OPTION_LABEL',
    description: 'Label for the Preferences Private option',
    defaultMessage: 'Private (Only Visible to You)',
  },
  importContactListPreferencesViewOnlyOptionLabel: {
    id: 'IMPORT_CONTACT_LIST_PREFERENCES_VIEW_ONLY_ACCESS_OPTION_LABEL',
    description: 'Label for the Preferences view only option',
    defaultMessage: 'View Only (can only view list, no editing allowed)',
  },
  importContactListPreferencesFullAccessOption: {
    id: 'IMPORT_CONTACT_LIST_PREFERENCES_FULL_ACCESS_OPTION_LABEL',
    description: 'Label for the Preferences Full Access option',
    defaultMessage: 'Full Access (can add, edit, and delete)',
  },
};
