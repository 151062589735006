import personaMan1Selected from '../../assets/icons/persona/persona-man-1-selected.svg';
import personaMan1 from '../../assets/icons/persona/persona-man-1.svg';
import personaMan2Selected from '../../assets/icons/persona/persona-man-2-selected.svg';
import personaMan2 from '../../assets/icons/persona/persona-man-2.svg';
import personaMan3Selected from '../../assets/icons/persona/persona-man-3-selected.svg';
import personaMan3 from '../../assets/icons/persona/persona-man-3.svg';
import personaMan4Selected from '../../assets/icons/persona/persona-man-4-selected.svg';
import personaMan4 from '../../assets/icons/persona/persona-man-4.svg';
import personaMan5Selected from '../../assets/icons/persona/persona-man-5-selected.svg';
import personaMan5 from '../../assets/icons/persona/persona-man-5.svg';
import personaMan6Selected from '../../assets/icons/persona/persona-man-6-selected.svg';
import personaMan6 from '../../assets/icons/persona/persona-man-6.svg';
import personaMan7Selected from '../../assets/icons/persona/persona-man-7-selected.svg';
import personaMan7 from '../../assets/icons/persona/persona-man-7.svg';
import personaWoman1Selected from '../../assets/icons/persona/persona-woman-1-selected.svg';
import personaWoman1 from '../../assets/icons/persona/persona-woman-1.svg';
import personaWoman2Selected from '../../assets/icons/persona/persona-woman-2-selected.svg';
import personaWoman2 from '../../assets/icons/persona/persona-woman-2.svg';
import personaWoman3Selected from '../../assets/icons/persona/persona-woman-3-selected.svg';
import personaWoman3 from '../../assets/icons/persona/persona-woman-3.svg';
import personaWoman4Selected from '../../assets/icons/persona/persona-woman-4-selected.svg';
import personaWoman4 from '../../assets/icons/persona/persona-woman-4.svg';
import personaWoman5Selected from '../../assets/icons/persona/persona-woman-5-selected.svg';
import personaWoman5 from '../../assets/icons/persona/persona-woman-5.svg';
import personaWoman6Selected from '../../assets/icons/persona/persona-woman-6-selected.svg';
import personaWoman6 from '../../assets/icons/persona/persona-woman-6.svg';
import personaWoman7Selected from '../../assets/icons/persona/persona-woman-7-selected.svg';
import personaWoman7 from '../../assets/icons/persona/persona-woman-7.svg';

import { API_BASE_URL } from '../../constants/apis';
import { UTC_DATE_FORMAT_INTL } from '../../constants/constants';

export const IMPACT_PERSONA_ENDPOINT = `${API_BASE_URL}/earned-impact/persona`;
export const MANAGE_IMPACT_PERSONA_METADATA_ENDPOINT = `${API_BASE_URL}/earned-impact/persona/metadata`;
export const MANAGE_IMPACT_PERSONA_PREVIEW_ENDPOINT = `${API_BASE_URL}/earned-impact/persona/preview`;
export const MANAGE_IMPACT_PERSONA_CONVERSION_NAMES_ENDPOINT = `${API_BASE_URL}/earned-impact/persona/conversion-names`;
export const IMPACT_PERSONAS_ENDPOINT = `${API_BASE_URL}/earned-impact/search`;

export const avatars = [
  [personaWoman3, personaWoman3Selected, 'Persona Woman 3'],
  [personaMan5, personaMan5Selected, 'Persona Man 5'],
  [personaWoman4, personaWoman4Selected, 'Persona Woman 4'],
  [personaMan3, personaMan3Selected, 'Persona Man 3'],
  [personaWoman7, personaWoman7Selected, 'Persona Woman 7'],
  [personaMan4, personaMan4Selected, 'Persona Man 4'],
  [personaWoman1, personaWoman1Selected, 'Persona Woman 1'],
  [personaMan6, personaMan6Selected, 'Persona Man 6'],
  [personaWoman2, personaWoman2Selected, 'Persona Woman 2'],
  [personaMan2, personaMan2Selected, 'Persona Man 2'],
  [personaWoman6, personaWoman6Selected, 'Persona Woman 6'],
  [personaMan1, personaMan1Selected, 'Persona Man 1'],
  [personaWoman5, personaWoman5Selected, 'Persona Woman 5'],
  [personaMan7, personaMan7Selected, 'Persona Man 7'],
];

export const IMPACT_SEARCHES_PAGE_SIZE = 15;
export const MAX_URL_LENGTH = 2048;
export const AMOUNT_ARTICLES_LIMIT = 40;

export const CMP_NUMBER = 10;
export const ONE_MILLION = 1;

export const CURRENCY_NUMBER_FORMAT_OPTIONS = {
  style: 'currency',
  currency: 'USD',
};

export const dateOptions = UTC_DATE_FORMAT_INTL;

export const shortenDateOptions = {
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
};

export enum MessagesStatus {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
}

//react-grid-layout grid layout config
export const WIDGET_LAYOUT_SPACE = 16;
export const WIDGET_LAYOUT_SPACE_DIMENSIONS = [
  WIDGET_LAYOUT_SPACE,
  WIDGET_LAYOUT_SPACE,
];

export const TOOLTIP_CUSTOM_WIDTH = 226;
export const DEFAULT_COLLAPSE_TRANSITION = 300;
export const TOTAL_VIEWS = 'totalViews';
export const UNIQUE_COUNT = 'uniqueCount';
export const TOTAL_CONVERSIONS = 'totalConversions';
export const CONVERSION_COUNT = 'conversionCount';
export const CONVERSION_RATE = 'conversionRate';
export const VIEWS_METRIC_ONE = 'viewsOne';
export const VIEWS_METRIC_TWO = 'viewsTwo';
export const OUTLET_ONE = 'outletOne';
export const OUTLET_TWO = 'outletTwo';
export const ARTICLES_COUNT_ONE = 'articlesCountOne';
export const ARTICLES_COUNT_TWO = 'articlesCountTwo';

export const ATTRIBUTIONS_PAGE = 'attributionDrilldown';

export const TOP_CONTRIBUTING_URLS_PAGE_LIMIT = 40;
export const TOP_CONTRIBUTING_URLS_INITIAL_OFFSET = 0;

export const LABEL_CHART_INSIDE_LEFT_PROP = 'insideLeft';
export const LABEL_CHART_INSIDE_RIGHT_PROP = 'insideRight';
export const LABEL_CHART_LABEL_90_ANGLE_PROP = 90;
export const LABEL_CHART_LABEL_COLOR_PROP = '#677078';
