import flattenDeep from 'lodash/flattenDeep';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import moment from 'moment';

import {
  extractRealEmail,
  getParticipatingIntegrations,
  getDefaultIntegrationAsContact,
} from 'components/outreach/utils';

import { OUTREACH_ENDPOINT } from 'constants/apis';
import {
  DEV_FEATURES,
  OUTREACH_MESSAGE_TIMESTAMP_FORMAT,
} from 'constants/constants';
import { OUTREACH_INTEGRATION_ERROR_TYPES } from 'constants/outreach-integration';
import { OUTREACH_MESSAGE_STATUSES } from 'constants/outreach-message';

import { resetFormToTemplateActionDispatcher } from 'reducers/outreach/compose-form';
import { openComposeModalActionCreator } from 'reducers/outreach/outreach-compose-modal';
import {
  CONTINUE_PATH_COMPOSE_MODAL,
  toggleReconnectModalActionCreator,
} from 'reducers/outreach/outreach-reconnect-modal';
import {
  closeScheduleModalActionCreator,
  toggleScheduleModalErrorActionCreator,
} from 'reducers/outreach/outreach-schedule-modal';
import { addPageMessageWithDefaultTimeout } from 'reducers/page-messages';

import { ADD_BULK_TO_STORY } from 'reducers/stories/story-hub';
import { userIdSelector, hasDevFeatureFlagSelector } from 'selectors/account';
import { contactByIdSelector } from 'selectors/contacts';
import {
  outreachComposeFormValuesSelector,
  defaultOrFirstOutreachIntegrationSelector,
  ownedIntegrationsSelector,
  hasInvalidOwnedIntegrationsSelector,
} from 'selectors/outreach';
import {
  returnScheduledDrafts,
  returnUnscheduledDrafts,
} from 'services/drafts-service/drafts-service';
import {
  performPost,
  performGet,
  performDelete,
} from 'services/rest-service/rest-service';
import { defaultProps as outreachContactDefaultProps } from 'types/outreach-contact';
import {
  convertDateTimeSelectionToUnix,
  unixTimeIsInFuture,
} from 'utils/times/times';

import {
  performPost as performMockPost,
  performDelete as performMockDelete,
  performGet as performMockGet,
} from './mockEndpoints';

export const DELETE_DRAFT_MESSAGE = 'outreach/DELETE_DRAFT_MESSAGE';
export const DELETE_DRAFT_MESSAGE_ERROR = 'outreach/DELETE_DRAFT_MESSAGE_ERROR';
export const DELETE_DRAFT_MESSAGE_SUCCESS =
  'outreach/DELETE_DRAFT_MESSAGE_SUCCESS';

export const GET_DRAFTS = 'outreach/GET_DRAFTS';
export const GET_DRAFTS_ERROR = 'outreach/GET_DRAFTS_ERROR';
export const GET_DRAFTS_SUCCESS = 'outreach/GET_DRAFTS_SUCCESS';

export const SAVE_DRAFT_MESSAGE = 'outreach/SAVE_DRAFT_MESSAGE';
export const SAVE_DRAFT_MESSAGE_ERROR = 'outreach/SAVE_DRAFT_MESSAGE_ERROR';
export const SAVE_DRAFT_MESSAGE_SUCCESS = 'outreach/SAVE_DRAFT_MESSAGE_SUCCESS';

export const SAVE_DRAFT_SCHEDULE = 'outreach/SAVE_DRAFT_SCHEDULE';
export const SAVE_DRAFT_SCHEDULE_ERROR = 'outreach/SAVE_DRAFT_SCHEDULE_ERROR';
export const SAVE_DRAFT_SCHEDULE_SUCCESS =
  'outreach/SAVE_DRAFT_SCHEDULE_SUCCESS';

export const REMOVE_DRAFT_SCHEDULE = 'outreach/REMOVE_DRAFT_SCHEDULE';
export const REMOVE_DRAFT_SCHEDULE_ERROR =
  'outreach/REMOVE_DRAFT_SCHEDULE_ERROR';
export const REMOVE_DRAFT_SCHEDULE_SUCCESS =
  'outreach/REMOVE_DRAFT_SCHEDULE_SUCCESS';

const initialState = {
  deletingDraftScheduleError: null,
  deletingDraftError: null,
  draftsById: {},
  draftsError: null,
  draftsLoading: false,
  currentPage: 1,
  pageSize: 25,
  savingDraft: false,
  savingDraftError: null,
  savingSchedule: false,
  savingScheduleError: null,
};

const outreachDraftReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case DELETE_DRAFT_MESSAGE:
      return {
        ...state,
        deletingDraftError: null,
      };
    case DELETE_DRAFT_MESSAGE_ERROR:
      return {
        ...state,
        deletingDraftError: action.payload,
      };
    case DELETE_DRAFT_MESSAGE_SUCCESS: {
      const newDraftsById = omit(
        {
          ...state.draftsById,
        },
        action.payload,
      );
      return {
        ...state,
        deletingDraftError: null,
        draftsById: newDraftsById,
      };
    }
    case GET_DRAFTS:
      return {
        ...state,
        currentPage: action.payload.pageNumber,
        draftsLoading: true,
      };
    case GET_DRAFTS_ERROR:
      return {
        ...state,
        draftsLoading: false,
        draftsError: true,
      };
    case GET_DRAFTS_SUCCESS:
      return {
        ...state,
        draftsLoading: false,
        draftsError: false,
        draftsById: {
          ...state.draftsById,
          ...action.payload,
        },
      };
    case SAVE_DRAFT_MESSAGE: {
      return {
        ...state,
        savingDraft: true,
        savingDraftError: null,
      };
    }
    case SAVE_DRAFT_MESSAGE_ERROR: {
      return {
        ...state,
        savingDraft: false,
        savingDraftError: action.payload,
      };
    }
    case SAVE_DRAFT_MESSAGE_SUCCESS: {
      const savedDraft = { ...action.payload };
      return {
        ...state,
        savingDraft: false,
        savingDraftError: null,
        draftsById: {
          ...state.draftsById,
          [savedDraft.id]: {
            ...savedDraft,
          },
        },
      };
    }
    case SAVE_DRAFT_SCHEDULE: {
      return {
        ...state,
        savingSchedule: true,
        savingScheduleError: null,
      };
    }
    case SAVE_DRAFT_SCHEDULE_ERROR: {
      return {
        ...state,
        savingSchedule: false,
        savingScheduleError: action.payload,
      };
    }
    case SAVE_DRAFT_SCHEDULE_SUCCESS: {
      const savedDraft = { ...action.payload };
      return {
        ...state,
        savingSchedule: false,
        savingScheduleError: null,
        draftsById: {
          ...state.draftsById,
          [savedDraft.id]: {
            ...savedDraft,
          },
        },
      };
    }
    case REMOVE_DRAFT_SCHEDULE: {
      return {
        ...state,
        deletingDraftScheduleError: null,
      };
    }
    case REMOVE_DRAFT_SCHEDULE_ERROR: {
      return {
        ...state,
        deletingDraftScheduleError: action.payload,
      };
    }
    case REMOVE_DRAFT_SCHEDULE_SUCCESS: {
      const id = action.payload;
      const draft = state.draftsById[id];
      return {
        ...state,
        deletingDraftScheduleError: null,
        draftsById: {
          ...state.draftsById,
          [id]: {
            ...draft,
            schedule: null,
          },
        },
      };
    }
    default:
      return state;
  }
};

/*
 * Draft-related utils
 */
const getDraftsByIdFromQueryResponse = results =>
  results.reduce(
    (result, draft) => {
      if (draft.id && result.draftIds.indexOf(draft.id) < 0) {
        result.draftsById[draft.id] = draft;
        result.draftIds.push(draft.id);
      }
      return result;
    },
    { draftIds: [], draftsById: {} },
  );

const getContact = contact => ({
  ...outreachContactDefaultProps,
  ...pickBy(contact),
});

/*
 * Methods for SAVING drafts
 */

const postOutreachDraftMessagesActionCreator = (
  message,
  useMockEndpoint = true,
) => {
  if (useMockEndpoint) {
    return performMockPost(`${OUTREACH_ENDPOINT}/draft`, message);
  }
  return performPost(`${OUTREACH_ENDPOINT}/draft`, message);
};

export const openComposeModalWithValuesActionCreator = (
  vals,
  { saveDraft = false, filteredViewId },
  skipInvalidCheck = false,
) => (dispatch, getState) => {
  const state = getState();
  const hasInvalidOutreachIntegrations = hasInvalidOwnedIntegrationsSelector(
    state,
  );

  const hasSendgridPitchFF = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.sendgridPitch,
  );

  if (
    !skipInvalidCheck &&
    hasInvalidOutreachIntegrations &&
    !hasSendgridPitchFF
  ) {
    return dispatch(
      toggleReconnectModalActionCreator({
        continuePath: CONTINUE_PATH_COMPOSE_MODAL,
        continueState: {
          vals,
          saveDraft,
          filteredViewId,
        },
      }),
    );
  }

  const messageFormValues = outreachComposeFormValuesSelector(state);
  const defaultIntegration = defaultOrFirstOutreachIntegrationSelector(state);
  const useMockData = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsMockData,
  );

  if (
    !saveDraft ||
    !hasDevFeatureFlagSelector(state)(DEV_FEATURES.jortsDrafts)
  ) {
    dispatch(
      resetFormToTemplateActionDispatcher({ ...vals }, { filteredViewId }),
    );
    return dispatch(openComposeModalActionCreator());
  }

  const message = {
    ...messageFormValues,
    from: getDefaultIntegrationAsContact(defaultIntegration),
    ...vals,
    status: OUTREACH_MESSAGE_STATUSES.draft,
  };

  dispatch({ type: SAVE_DRAFT_MESSAGE });

  return postOutreachDraftMessagesActionCreator(message, useMockData)
    .then(results => {
      const draftVals = {
        ...vals,
        from: message.from,
        id: useMockData ? results.data.id : parseInt(results.data.id, 10),
      };

      dispatch(
        resetFormToTemplateActionDispatcher(draftVals, { filteredViewId }),
      );
      dispatch({ type: SAVE_DRAFT_MESSAGE_SUCCESS, payload: results.data });
      dispatch(openComposeModalActionCreator());
    })
    .catch(() => {
      // TODO: Handle error from real API. Also, retry if unsuccessful?
      dispatch({ type: SAVE_DRAFT_MESSAGE_ERROR });
      dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Could not save draft',
          status: 'danger',
        }),
      );
    });
};

export const saveDraftOutreachMessageActionDispatcher = (
  silently = true,
) => async (dispatch, getState) => {
  const state = getState();
  const ownerId = userIdSelector(state);
  const useMockData = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsMockData,
  );
  const messageFormValues = outreachComposeFormValuesSelector(state);

  // prevents drafts that weren't initialized to be accidentally saved as new drafts
  if (!messageFormValues.id) return null;

  const message = {
    ...messageFormValues,
    status: OUTREACH_MESSAGE_STATUSES.draft,
    ownerId,
    id: useMockData ? messageFormValues.id : parseInt(messageFormValues.id, 10),
  };
  dispatch({ type: SAVE_DRAFT_MESSAGE });

  return postOutreachDraftMessagesActionCreator(message, useMockData)
    .then(result => {
      dispatch({ type: SAVE_DRAFT_MESSAGE_SUCCESS, payload: result.data });
      if (!silently) {
        let text = 'Message was saved as draft';
        if (messageFormValues.subject) {
          text = `Message ${messageFormValues.subject} was saved as draft`;
        }

        dispatch(
          addPageMessageWithDefaultTimeout({
            text,
            status: 'success',
          }),
        );
      }
    })
    .catch(error => {
      dispatch({ type: SAVE_DRAFT_MESSAGE_ERROR, payload: error });

      dispatch(
        addPageMessageWithDefaultTimeout({
          text: `Could not save ${messageFormValues.subject} as draft`,
          status: 'danger',
        }),
      );
    });
};

export const setFormForReplyActionCreator = ({ message }) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const shouldBlockEmail = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsEmailBlocker,
  );
  const hasJortsDrafts = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsDrafts,
  );
  const integrations = ownedIntegrationsSelector(state);
  const subject = message.subject.startsWith('Re:')
    ? message.subject
    : `Re: ${message.subject}`;
  const participatingIntegrations = getParticipatingIntegrations({
    integrations,
    message,
  });
  // TODO: Remove isArray check when the message fetching API stops modeling from as an array
  const prevFrom = Array.isArray(message.from)
    ? getContact(message.from[0])
    : getContact(message.from);
  const dateTime = moment(message.dateDelivered);
  const dateTimePhrase = dateTime.isValid()
    ? `On ${dateTime.format(OUTREACH_MESSAGE_TIMESTAMP_FORMAT)} `
    : '';
  const authors = [...message.authors];
  const influencers = [...message.influencers];

  let to = [{ ...prevFrom }];
  if (some(integrations, { emailAddress: prevFrom.email })) {
    to = [...message.to];
  }

  const body = `<br/><blockquote>${dateTimePhrase}${
    prevFrom.name || prevFrom.email
  } wrote:<br/>${message.body}</blockquote>`;

  if (shouldBlockEmail) {
    to = to.map(recipient => ({
      ...recipient,
      email: extractRealEmail(
        recipient.email,
        integrations.map(integration => integration.emailAddress),
      ),
    }));
  }

  dispatch(
    openComposeModalWithValuesActionCreator(
      {
        authors,
        influencers,
        participatingIntegrations,
        subject,
        to,
        body,
        replyToMessageId: message.messageId || '',
      },
      { saveDraft: hasJortsDrafts },
    ),
  );
};

/* Function for saving draft schedule - dummy method for now */
const saveDraftOutreachScheduleActionCreator = (
  messageId,
  params,
) => async () => {
  return performMockPost(
    `${OUTREACH_ENDPOINT}/draft/schedule/${messageId}`,
    params,
  );
};

/* Function for deleting draft schedule - dummy method for now */
const removeDraftoutreachScheduleActionCreator = id => async () => {
  return performMockDelete(`${OUTREACH_ENDPOINT}/draft/schedule/${id}`);
};

export const saveDraftOutreachScheduleActionDispatcher = (
  date,
  time,
  timezone,
  id,
) => async (dispatch, getState) => {
  dispatch({ type: SAVE_DRAFT_SCHEDULE });
  const tz = timezone.zone;
  const state = getState();
  const draft = state.outreachDrafts.draftsById[id];

  if (!draft) {
    dispatch({ type: SAVE_DRAFT_SCHEDULE_ERROR, payload: 'No draft found' });
    return dispatch(toggleScheduleModalErrorActionCreator());
  }
  const timestamp = convertDateTimeSelectionToUnix(date, time, tz);

  // we check for this in UI but added check for selecting date/time in past
  if (!unixTimeIsInFuture(timestamp)) {
    dispatch({
      type: SAVE_DRAFT_SCHEDULE_ERROR,
      payload: 'Cannot select time in past',
    });
    return dispatch(toggleScheduleModalErrorActionCreator());
  }

  const params = {
    timestamp,
    timezone: timezone.zone,
    id,
    draft,
  };

  return dispatch(saveDraftOutreachScheduleActionCreator(id, params))
    .then(result => {
      const newDraft = result.data.draft;
      dispatch({ type: SAVE_DRAFT_SCHEDULE_SUCCESS, payload: newDraft });
      dispatch(resetFormToTemplateActionDispatcher({ ...newDraft }));
      dispatch(closeScheduleModalActionCreator());
    })
    .catch(error => {
      dispatch({ type: SAVE_DRAFT_SCHEDULE_ERROR, payload: error.message });
      dispatch(toggleScheduleModalErrorActionCreator());
    });
};

export const removeDraftOutreachScheduleActionDispatcher = draftId => async (
  dispatch,
  getState,
) => {
  dispatch({ type: REMOVE_DRAFT_SCHEDULE });
  const state = getState();

  return dispatch(removeDraftoutreachScheduleActionCreator(draftId))
    .then(() => {
      dispatch({ type: REMOVE_DRAFT_SCHEDULE_SUCCESS, payload: draftId });
      dispatch(
        resetFormToTemplateActionDispatcher({
          ...state.outreachDrafts.draftsById[draftId],
          schedule: null,
        }),
      );
      dispatch(closeScheduleModalActionCreator());
    })
    .catch(error => {
      dispatch({ type: REMOVE_DRAFT_SCHEDULE_ERROR, payload: error });
      return dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Sorry, your schedule could not be removed. Please try again.',
          status: 'danger',
        }),
      );
    });
};

export const addBulkDraftToDraftListActionDispatcher = bulkData => dispatch => {
  const { id, template, drafts } = bulkData;
  const participants = drafts.map(draft => ({
    ...draft.to,
  }));

  const bulkDraftPreview = {
    bulkId: id,
    id,
    accountId: 1966,
    snippet: 'This is a bulk message to many recipients',
    fromTk: true,
    hasAttachments: template.hasAttachments,
    stories: template.stories,
    subject: template.subject,
    to: flattenDeep(participants),
  };

  dispatch({
    type: ADD_BULK_TO_STORY,
    payload: id,
  });
  dispatch({
    type: SAVE_DRAFT_MESSAGE_SUCCESS,
    payload: bulkDraftPreview,
  });
};

/*
 * Methods for GETTING drafts
 */
export const getOutreachDraftsWithQuery = async (
  { query },
  useMockDataFor = null,
) => {
  const params = {
    ...query,
    mockDataContact: useMockDataFor,
  };

  const response = await performMockPost(
    `${OUTREACH_ENDPOINT}/drafts/query`,
    params,
  );
  return response.data;

  /*
   * TODO: Enable non-mock query once endpoint live
   * const { data } = await performPost(`${OUTREACH_ENDPOINT}/drafts/query`, params);
   * return data;
   */
};

export const getOutreachDraftsWithQueryActionCreator = ({
  query,
  useMockDataFor,
  fetchScheduledDrafts,
}) => async dispatch => {
  const { pagination } = query;

  if (query.filters.draft || query.filters.scheduled) {
    dispatch({
      type: GET_DRAFTS,
      payload: {
        pageNumber: Math.floor(pagination.offset / pagination.limit) + 1,
      },
    });
  } else {
    dispatch({
      type: GET_DRAFTS,
      payload: {},
    });
  }

  let response;

  try {
    response = await getOutreachDraftsWithQuery({ query }, useMockDataFor);
  } catch (e) {
    dispatch({
      type: GET_DRAFTS_ERROR,
      payload: {
        error: {
          type: OUTREACH_INTEGRATION_ERROR_TYPES.generic,
        },
      },
    });
    throw e;
  }

  const {
    aggregations: draftAggregations,
    pagination: paginationResponse,
  } = response;
  const { results } = response;

  // set draftIds and draftsById to empty array/objects if filtering for unread threads
  if (query.filters.unread) {
    dispatch({ type: GET_DRAFTS_SUCCESS, payload: [] });
    return {
      draftAggregations,
      pagination: paginationResponse,
      draftIds: [],
      draftsById: {},
    };
  }

  let scheduledDrafts = [];
  if (fetchScheduledDrafts) {
    scheduledDrafts = returnScheduledDrafts(results, query);
  }
  const unscheduledDrafts = returnUnscheduledDrafts(results, query);

  const allDrafts = scheduledDrafts.concat(unscheduledDrafts);
  const { draftIds, draftsById } = getDraftsByIdFromQueryResponse(allDrafts);

  dispatch({ type: GET_DRAFTS_SUCCESS, payload: draftsById });

  return {
    draftAggregations,
    pagination: paginationResponse,
    draftIds,
    draftsById,
  };
};

export const getOutreachDraftsActionCreator = ({ query }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const useMockDataFor =
    hasDevFeatureFlagSelector(state)(DEV_FEATURES.jortsMockData) &&
    contactByIdSelector(state)(query.filters.authors[0]);
  const fetchScheduledDrafts = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsSchedule,
  );

  return dispatch(
    getOutreachDraftsWithQueryActionCreator({
      query,
      useMockDataFor,
      fetchScheduledDrafts,
    }),
  );
};

const getOutreachDraftById = (draftId, useMockEndpoint) => {
  if (useMockEndpoint) {
    return performMockGet(`${OUTREACH_ENDPOINT}/draft`);
  }
  return performGet(`${OUTREACH_ENDPOINT}/draft/${draftId}`);
};

export const getAndOpenModalForDraftActionDispatcher = draftId => (
  dispatch,
  getState,
) => {
  const state = getState();
  const useMockEndpoint = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsMockData,
  );

  getOutreachDraftById(draftId, useMockEndpoint)
    .then(result => {
      dispatch(openComposeModalWithValuesActionCreator(result.data));
    })
    .catch(() => {
      return dispatch(
        addPageMessageWithDefaultTimeout({
          text: 'Sorry, we could not retrieve that draft',
          status: 'danger',
        }),
      );
    });
};

/*
 * Methods for DELETING drafts
 */
const deleteOutreachDraftMessage = (draftId, useMockEndpoint = true) => {
  if (useMockEndpoint) {
    return performMockDelete(`${OUTREACH_ENDPOINT}/draft/${draftId}`);
  }
  return performDelete(`${OUTREACH_ENDPOINT}/draft/${draftId}`);
};

export const deleteDraftOutreachMessageActionDispatcher = (
  draftId,
  useMockData,
) => async dispatch => {
  const draftErrorMessage =
    'Sorry, your draft could not be discarded. Please try again.';

  dispatch({ type: DELETE_DRAFT_MESSAGE });

  deleteOutreachDraftMessage(draftId, useMockData)
    .then(() => {
      dispatch({
        type: DELETE_DRAFT_MESSAGE_SUCCESS,
        payload: draftId,
      });
    })
    .catch(error => {
      dispatch({ type: DELETE_DRAFT_MESSAGE_ERROR, payload: error.message });
      return dispatch(
        addPageMessageWithDefaultTimeout({
          text: draftErrorMessage,
          status: 'danger',
        }),
      );
    });
};

export const deleteOutreachDraftMessageById = draftId => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const hasJortsDrafts = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsDrafts,
  );
  const hasMockData = hasDevFeatureFlagSelector(state)(
    DEV_FEATURES.jortsMockData,
  );

  if (hasJortsDrafts) {
    dispatch(deleteDraftOutreachMessageActionDispatcher(draftId, hasMockData));
  }

  return null;
};

export default outreachDraftReducer;
