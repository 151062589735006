import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DateRangePicker } from '../../datepicker';
import { ExpandableDropdown } from '../../dropdown';
import FloatingPanel from '../..//floating-panel';

export default class ActivityListVisorDateSelector extends Component {
  state = {
    customDateRangePickerOpen: false,
  };

  onCustomDateSelect = (start, end) => {
    this.onDateRangeSelect('CUSTOM', start, end);
    this.toggleCustomSelectorDropdown();
  };

  onDateRangeSelect = (rangeType, startDate, endDate) => {
    const { getDatesForRange, onUpdateDates } = this.props;

    if (rangeType === 'CUSTOM') {
      if (!startDate) {
        const dateRangeData = getDatesForRange(this.props.dateRangeType);
        startDate = moment(dateRangeData.startDate).valueOf();
        endDate = moment(dateRangeData.endDate).valueOf();
      }
    } else if (rangeType === 'ALL_TIME') {
      startDate = null;
      endDate = null;
    } else {
      const dateRangeData = getDatesForRange(rangeType);
      startDate = moment(dateRangeData.startDate).valueOf();
      endDate = moment(dateRangeData.endDate).valueOf();
    }
    onUpdateDates(rangeType, startDate, endDate);
  };

  getCustomRangeData = (startDate, endDate) => {
    const dateRangeLabels = {
      label: `${moment
        .utc(startDate)
        .format(this.props.defaultDateFormat)} - ${moment
        .utc(endDate)
        .format(this.props.defaultDateFormat)}`,
      shortLabel: '',
    };
    const dateRangeData = {
      startDate: moment(startDate),
      endDate: moment(endDate),
    };
    startDate = moment(dateRangeData.startDate).valueOf();
    endDate = moment(dateRangeData.endDate).valueOf();

    return {
      dateRangeLabels,
      dateRangeData,
      startDate,
      endDate,
    };
  };

  toggleCustomSelectorDropdown = () => {
    this.setState(prevState => ({
      customDateRangePickerOpen: !prevState.customDateRangePickerOpen,
    }));
  };

  render() {
    const {
      dateRangeOptions,
      dateRangeType,
      defaultDateFormat,
      endDate,
      getDatesForRange,
      startDate,
    } = this.props;

    let rangeData = dateRangeOptions[dateRangeType];
    let dates = {
      startDate,
      endDate,
    };

    if (dateRangeType === 'CUSTOM') {
      rangeData = this.getCustomRangeData(startDate, endDate).dateRangeLabels;
    } else {
      dates = getDatesForRange(dateRangeType);
    }

    return (
      <React.Fragment>
        <ExpandableDropdown
          dropdownOptions={dateRangeOptions}
          buttonModifiers={['seethrough']}
          onItemClick={this.onDateRangeSelect}
          buttonText={rangeData.label}
          includeMore={false}
          dropdownOptionModifiers={{
            ALL_TIME: {
              showOption: true,
            },
            TRAILING_7: {
              showOption: true,
            },
            TRAILING_30: {
              showOption: true,
            },
            TRAILING_90: {
              showOption: true,
            },
            CUSTOM: {
              showOption: true,
              callback: this.toggleCustomSelectorDropdown,
            },
          }}
        />
        {this.state.customDateRangePickerOpen && (
          <div
            className={`${ActivityListVisorDateSelector.baseClass}__datepicker-dropdown`}
          >
            <FloatingPanel>
              <DateRangePicker
                dateFormat={defaultDateFormat}
                startDate={moment(dates.startDate).valueOf()}
                endDate={moment(dates.endDate).valueOf()}
                onCancel={this.toggleCustomSelectorDropdown}
                onSave={this.onCustomDateSelect}
              />
            </FloatingPanel>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ActivityListVisorDateSelector.baseClass = 'tk-list-visor-date-picker';

ActivityListVisorDateSelector.propTypes = {
  dateRangeOptions: PropTypes.object.isRequired,
  dateRangeType: PropTypes.string.isRequired,
  defaultDateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.number,
  getDatesForRange: PropTypes.func.isRequired,
  onUpdateDates: PropTypes.func.isRequired,
  startDate: PropTypes.number,
};

ActivityListVisorDateSelector.defaultProps = {
  endDate: undefined,
  startDate: undefined,
};
