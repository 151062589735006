import difference from 'lodash/difference';

import { requestMultiple } from 'services/rest-service/rest-service';

export const UPDATE_STORY_ARTICLES = 'story/UPDATE_STORY_ARTICLES';
export const REMOVE_STORY_ARTICLES = 'story/REMOVE_STORY_ARTICLES';
export const GET_STORY_COVERAGE_METRICS = 'story/GET_STORY_COVERAGE_METRICS';
export const GET_STORY_COVERAGE_METRICS_SUCCESS =
  'story/GET_STORY_COVERAGE_METRICS_SUCCESS';
export const GET_STORY_COVERAGE_METRICS_ERROR =
  'story/GET_STORY_COVERAGE_METRICS_ERROR';

const initialState = {
  articleIds: [],
  totalArticleCount: 0,
  loadingMetrics: false,
  metricsError: '',
  metrics: null,
  compareMetrics: null,
  metricsRangeType: 'SINCE_LAUNCH',
  metricsStartDate: NaN,
  metricsEndDate: NaN,
  metricsPreviousStartDate: NaN,
  metricsPreviousEndDate: NaN,
};

const storyCoverageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STORY_ARTICLES:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_STORY_ARTICLES: {
      const oldArticleIds = state.articleIds || [];
      const newArticleIds = difference(
        oldArticleIds,
        action.payload.removeArticleIds,
      );
      const removedCount = oldArticleIds.length - newArticleIds.length;

      return {
        ...state,
        articleIds: newArticleIds,
        totalArticleCount: state.totalArticleCount - removedCount,
      };
    }
    case GET_STORY_COVERAGE_METRICS: {
      const {
        payload: {
          rangeType,
          startDate,
          endDate,
          previousStartDate,
          previousEndDate,
        },
      } = action;
      return {
        ...state,
        loadingMetrics: true,
        metrics: null,
        compareMetrics: null,
        metricsRangeType: rangeType,
        metricsStartDate: startDate,
        metricsEndDate: endDate,
        metricsPreviousStartDate: previousStartDate,
        metricsPreviousEndDate: previousEndDate,
      };
    }
    case GET_STORY_COVERAGE_METRICS_SUCCESS:
      return {
        ...state,
        loadingMetrics: false,
        metrics: action.payload.data.metrics,
        compareMetrics: action.payload.data.compareMetrics,
      };
    case GET_STORY_COVERAGE_METRICS_ERROR:
      return {
        ...state,
        loadingMetrics: false,
        metricsError: action.payload.error,
      };
    default:
      return state;
  }
};

export const getStoryCoverageMetrics = (
  storyId,
  rangeType,
  startDate,
  endDate,
  previousStartDate,
  previousEndDate,
) => async dispatch => {
  dispatch({
    type: GET_STORY_COVERAGE_METRICS,
    payload: {
      rangeType,
      startDate,
      endDate,
      previousStartDate,
      previousEndDate,
    },
  });
  const reqOptions = {
    method: 'get',
    url: `/api/story/${storyId}/coverage/metrics`,
  };
  try {
    const [metricsResponse, compareResponse] = await requestMultiple([
      {
        ...reqOptions,
        params: { endDate, startDate },
      },
      {
        ...reqOptions,
        params: { endDate: previousEndDate, startDate: previousStartDate },
      },
    ]);
    if (
      metricsResponse &&
      metricsResponse.data &&
      compareResponse &&
      compareResponse.data
    ) {
      dispatch({
        type: GET_STORY_COVERAGE_METRICS_SUCCESS,
        payload: {
          data: {
            metrics: metricsResponse.data,
            compareMetrics: compareResponse.data,
          },
        },
      });
    } else {
      dispatch({
        type: GET_STORY_COVERAGE_METRICS_ERROR,
        payload: { data: 'Not found' },
      });
    }
  } catch (e) {
    dispatch({ type: GET_STORY_COVERAGE_METRICS_ERROR, payload: { data: e } });
  }
};

export default storyCoverageReducer;
