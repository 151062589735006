import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const ListAdd = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z" />
  </svg>
);
ListAdd.propTypes = { fill: PropTypes.string };
ListAdd.defaultProps = { fill: iconColorsMap.mainicon };

export default ListAdd;
