import { OUTREACH_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

const GET_IMAGE_URL_SUCCESS = 'outreach/GET_IMAGE_URL_SUCCESS';

const initialState = {
  imageSignedUrls: {},
};

const outreachAttachmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGE_URL_SUCCESS: {
      return {
        ...state,
        imageSignedUrls: {
          ...state.imageSignedUrls,
          [action.payload.id]: action.payload.url,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export const getSignedUrl = imageId => async dispatch => {
  const response = await performGet(
    `${OUTREACH_ENDPOINT}/file/${imageId}/secure-link`,
  );

  if (response) {
    dispatch({
      type: GET_IMAGE_URL_SUCCESS,
      payload: { id: imageId, url: response.data.signedUrl },
    });
  }
};

export default outreachAttachmentsReducer;
