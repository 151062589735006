import Type, {
  Caption,
  Body,
  Subhead,
  Headline,
  DisplaySM,
  DisplayMD,
  DisplayLG,
  DisplayXL,
} from './Type';
import './type.scss';

export {
  Caption,
  Body,
  Subhead,
  Headline,
  DisplaySM,
  DisplayMD,
  DisplayLG,
  DisplayXL,
};

export default Type;
