export default {
  modalTitle: {
    id: 'DASHBOARD_BULK_SEARCH_MODAL_TITLE',
    description: 'Title for the dashboard bulk search modal',
    defaultMessage: 'Replace Widget Searches',
  },
  updateErrorByWidget: {
    id: 'DASHBOARD_BULK_SEARCH_UPDATE_ERROR_BY_WIDGET',
    description:
      'Error message to indicate that there was an error updating a widget',
    defaultMessage: 'Failed to update widget ({widgetName})',
  },
  untitleWidget: {
    id: 'DASHBOARD_UNTITLED_WIDGET',
    description: 'Message to indicate untitled widget',
    defaultMessage: 'Untitled Widget',
  },
};
