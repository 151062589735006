import compact from 'lodash/compact';
import memoize from 'lodash/memoize';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';

import { createSelector } from 'reselect';

import { ADDED_ARTICLE_STATUS } from 'constants/constants';

import { loggedInSelector, userIdSelector } from 'selectors/account';

export const searchesObjSelector = state => state.searches || {};

export const socialSearchesSelector = createSelector(
  searchesObjSelector,
  searchesObj => searchesObj?.socialSearches,
);

export const searchesSelector = createSelector(
  searchesObjSelector,
  searchesObj =>
    typeof searchesObj.searches !== 'undefined'
      ? searchesObj.searches
      : searchesObj,
);

export const sharedSearchesSelector = createSelector(
  searchesObjSelector,
  searchesObj => searchesObj.sharedSearches,
);

export const allSearchesMapSelector = createSelector(
  searchesObjSelector,
  searchesObj => ({ ...searchesObj.sharedSearches, ...searchesObj.searches }),
);

export const allSocialSearchesMapSelector = createSelector(
  searchesObjSelector,
  searchesObj => ({
    ...[
      ...searchesObj.socialSearches.shared,
      ...searchesObj.socialSearches.owned,
    ],
  }),
);
export const allSearchesAsArraySelector = createSelector(
  searchesObjSelector,
  searchesObj =>
    uniqBy(
      values(searchesObj.sharedSearches).concat(values(searchesObj.searches)),
      'id',
    ),
);

export const currentSearchIdSelector = state =>
  searchesObjSelector(state).current
    ? searchesObjSelector(state).current.toString()
    : '';

export const latestSearchIdSelector = state =>
  searchesObjSelector(state).latest
    ? searchesObjSelector(state).latest.toString()
    : '';

export const currentSearchSelector = createSelector(
  currentSearchIdSelector,
  searchesSelector,
  (searchId, searches) => (searchId && searches ? searches[searchId] : null),
);

export const searchByIdSelector = createSelector(
  allSearchesMapSelector,
  allSearchesMap => memoize(searchId => allSearchesMap[searchId] || null),
);

export const searchByIdsSelector = createSelector(
  allSearchesMapSelector,
  allSearchesMap =>
    memoize(searchIds =>
      compact(searchIds.map(id => allSearchesMap[id] || null)),
    ),
);

export const currentSearchTitleSelector = createSelector(
  currentSearchSelector,
  search => (search ? search.title : ''),
);

export const currentSearchOwnerIdSelector = createSelector(
  currentSearchSelector,
  search => (search && search.user && search.user.id ? search.user.id : null),
);

export const systemSearchesAsArraySelector = createSelector(
  searchesObjSelector,
  searchesObj => values(searchesObj.systemSearches) || [],
);

export const systemSearchesCountSelector = createSelector(
  searchesObjSelector,
  searchesObj => searchesObj.systemSearchesCount,
);

export const currentSearchIsEditableSelector = createSelector(
  currentSearchOwnerIdSelector,
  currentSearchSelector,
  userIdSelector,
  loggedInSelector,
  (currentSearchOwnerId, currentSearch, userId, loggedIn) =>
    !!(
      currentSearch &&
      currentSearch.shared &&
      !currentSearch.viewOnly &&
      loggedIn
    ) || currentSearchOwnerId === userId,
);

export const searchEntriesForListSelector = createSelector(
  searchesSelector,
  sharedSearchesSelector,
  (searches, sharedSearches) => {
    const searchListEntries = values(searches).map(search => {
      return {
        id: search.id,
        infoIcon: search.shared ? 'users' : '',
        subtitle: search.taxonomyDisplayName || '',
        svgIcon: search.svgIcon,
        title: search.title,
        titleString: search.title || '',
        type: search.type,
        earnedImpactSearchName: search.earnedImpactSearchName,
        userId: search.userId,
      };
    });

    const sharedSearchListEntries = values(sharedSearches).map(sharedSearch => {
      return {
        id: sharedSearch.id,
        infoIcon: 'users',
        subtitle: sharedSearch.taxonomyDisplayName || '',
        svgIcon: sharedSearch.svgIcon,
        title: sharedSearch.title,
        titleString: sharedSearch.title || '',
        type: sharedSearch.type,
        earnedImpactSearchName: sharedSearch.earnedImpactSearchName,
        userId: sharedSearch.userId,
      };
    });

    const alphabeticallySortedSearchEntries = uniqBy(
      sharedSearchListEntries.concat(searchListEntries),
      search => search.id,
    ).sort((a, b) => a.titleString.localeCompare(b.titleString));
    return alphabeticallySortedSearchEntries;
  },
);

const sortSearchesAlphabetically = searches => {
  return uniqBy(searches, search => search.id).sort((a, b) =>
    a.titleString.localeCompare(b.titleString),
  );
};

export const socialSearchEntriesForListSelector = createSelector(
  socialSearchesSelector,
  socialSearches => {
    const ownedSearches = socialSearches?.owned?.map(search => {
      return {
        id: search.id,
        svgIcon: search.svgIcon,
        title: search.name,
        titleString: search.name,
        subtitle: '',
        isSocial: true,
      };
    });

    const sharedSearches = socialSearches?.shared.map(sharedSearch => {
      return {
        id: sharedSearch.id,
        infoIcon: 'users',
        title: sharedSearch.name,
        titleString: sharedSearch.name,
        subtitle: '',
        isSocial: true,
      };
    });
    const searches = ownedSearches.concat(sharedSearches);
    return sortSearchesAlphabetically(searches);
  },
);

export const systemSearchesPubStatusSelector = createSelector(
  searchesObjSelector,
  searchesObj => searchesObj.systemSearchPubStatus,
);

export const socialTrackersSelector = createSelector(
  searchesObjSelector,
  searchesObj => searchesObj.socialTrackers,
);

export const socialTrackerLoadingStatesSelector = createSelector(
  searchesObjSelector,
  searchesObj => ({
    loading: searchesObj.loadingSocialTrackers,
    error: searchesObj.gettingSocialTrackersError,
    success: searchesObj.gettingSocialTrackersSuccess,
  }),
);

export const searchesListFiltersAndSortSelector = createSelector(
  searchesObjSelector,
  searchesObj => ({
    sort: searchesObj.sort,
    filter: searchesObj.filter,
    campaignOrCategoryId: searchesObj.campaignOrCategoryId,
  }),
);

//---- Bulk Article Add

export const articlesSelector = createSelector(
  searchesObjSelector,
  searchesObj => searchesObj.userAddedArticles || [],
);

export const statusCountSelector = createSelector(
  articlesSelector,
  articles => statusId =>
    articles.reduce(
      (acc, article) => (article.status === statusId ? acc + 1 : acc),
      0,
    ),
);

export const sortedArticlesSelector = createSelector(
  articlesSelector,
  articles => articles.sort((a, b) => a.priority - b.priority),
);

export const isModalProcessingSelector = createSelector(
  articlesSelector,
  articles =>
    articles.some(
      article =>
        article.status === ADDED_ARTICLE_STATUS.processing.id ||
        article.status === ADDED_ARTICLE_STATUS.pending.id,
    ),
);
