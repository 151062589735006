export const CHANGED_TO_ONLINE = 'network/CHANGED_TO_ONLINE';
export const CHANGED_TO_OFFLINE = 'network/CHANGED_TO_OFFLINE';
export const START_LISTENING = 'network/START_LISTENING';
export const STOP_LISTENING = 'network/STOP_LISTENING';

const initialState = {
  isOnline: true,
  listener: null,
};

export const networkReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGED_TO_ONLINE:
      return {
        ...state,
        isOnline: true,
      };
    case CHANGED_TO_OFFLINE:
      return {
        ...state,
        isOnline: false,
      };
    case START_LISTENING:
      return {
        ...state,
        ...action.payload,
      };
    case STOP_LISTENING:
      return {
        ...state,
        listener: null,
        isOnline: true,
      };
    default:
      return state;
  }
};

export const startListeningForOnlineActionCreator = () => (
  dispatch,
  getState,
) => {
  let { listener } = getState().network;

  if (
    listener ||
    !window ||
    !window.navigator ||
    typeof window.navigator.onLine === 'undefined'
  ) {
    return;
  }

  listener = setInterval(() => {
    const { isOnline: oldValue } = getState().network;
    const isOnline = window.navigator.onLine;

    if (isOnline && !oldValue) {
      dispatch({ type: CHANGED_TO_ONLINE });
    } else if (!isOnline && oldValue) {
      dispatch({ type: CHANGED_TO_OFFLINE });
    }
  }, 1000);

  dispatch({ type: START_LISTENING, payload: { listener } });
};

export const stopListeningForOnlineActionCreator = () => (
  dispatch,
  getState,
) => {
  const { listener } = getState().network;

  if (!listener) {
    return;
  }

  clearInterval(listener);

  dispatch({ type: STOP_LISTENING });
};

export default networkReducer;
