import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const Move = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z" />
  </svg>
);
Move.propTypes = { fill: PropTypes.string };
Move.defaultProps = { fill: iconColorsMap.mainicon };

export default Move;
