import { NEW_DASHBOARD_ENDPOINT } from 'constants/apis';
import {
  DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS,
  DATA_SOURCE_TYPES,
  DATA_SOURCES,
  DEV_FEATURES,
} from 'constants/constants';
import { allAnalyticActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-list-reducer';
import { ownedAnalyticsActionCreator } from 'pages/Analytics/AnalyticsListContainer/reducers/analytics-owned-reducer';
import { addPageMessage } from 'reducers/page-messages';
import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import { performPost } from 'services/rest-service/rest-service';
import { DATE_RANGE_KEYS } from 'utils/date/date-util';

import { clearTranslatedKeyMessagesActionCreator } from '../../../reducers/widget-form';
import { setDashboardsListSideTrayOpen } from '../dashboard-hub-reducer';

import { WIZARD_STEPS, TEMPLATE_DATA_SOURCE_TYPES } from './constants';

export const UPDATE_STEP = 'dashboardCreateForm/UPDATE_STEP';
export const UPDATE_STEPS = 'dashboardCreateForm/UPDATE_STEPS';
export const TOGGLE_SIDE_TRAY = 'dashboardCreateForm/TOGGLE_SIDE_TRAY';
export const UPDATE_TEMPLATE_ID = 'dashboardCreateForm/UPDATE_TEMPLATE_ID';

export const UPDATE_DATA_SOURCES = 'dashboardCreateForm/ADD_DATA_SOURCES';
export const REMOVE_DATA_SOURCE = 'dashboardCreateForm/REMOVE_DATA_SOURCE';
export const CLEAR_DATA_SOURCES = 'dashboardCreateForm/CLEAR_DATA_SOURCES';

export const CLEAR_PRIMARY_SEARCH = 'dashboardCreateForm/CLEAR_PRIMARY_SEARCH';
export const SET_PRIMARY_SEARCH = 'dashboardCreateForm/SET_PRIMARY_SEARCH';
export const SET_SECONDARY_SEARCHES =
  'dashboardCreateForm/SET_SECONDARY_SEARCHES';
export const SET_TAG = 'dashboardCreateForm/SET_TAG';
export const SET_DATA_SOURCE_SELECTION =
  'dashboardCreateForm/SET_DATA_SOURCE_SELECTION';

export const UPDATE_KEY_MESSAGES = 'dashboardCreateForm/UPDATE_KEY_MESSAGES';
export const UPDATE_KEY_MESSAGE_TITLE =
  'dashboardCreateForm/UPDATE_KEY_MESSAGE_TITLE';
export const UPDATE_KEY_MESSAGE_BOOLEAN =
  'dashboardCreateForm/UPDATE_KEY_MESSAGE_BOOLEAN';
export const UPDATE_EXISTING_KEY_MESSAGE =
  'dashboardCreateForm/UPDATE_EXISTING_KEY_MESSAGE';
export const CLEAR_KEY_MESSAGE_EDITING =
  'dashboardCreateForm/CLEAR_KEY_MESSAGE_EDITING';

export const UPDATE_DASHBOARD_TITLE =
  'dashboardCreateForm/UPDATE_DASHBOARD_TITLE';
export const UPDATE_DATE_RANGE = 'dashboardCreateForm/UPDATE_DATE_RANGE';

export const GENERATE_DASHBOARD = 'dashboardCreateForm/GENERATE_DASHBOARD';
export const GENERATE_DASHBOARD_SUCCESS =
  'dashboardCreateForm/GENERATE_DASHBOARD_SUCCESS';
export const GENERATE_DASHBOARD_ERROR =
  'dashboardCreateForm/GENERATE_DASHBOARD_ERROR';

export const CLEAR_FORM = 'dashboardCreateForm/CLEAR_FORM';

export const TOGGLE_SEARCH_BUILDER_MODAL =
  'dashboardCreateForm/TOGGLE_SEARCH_BUILDER_MODAL';

const initialState = {
  step: WIZARD_STEPS.templateSelection,
  title: '',
  dateRangeType: DATE_RANGE_KEYS.TRAILING_30,
  startDate: null,
  endDate: null,
  steps: [],
  sideTrayOpen: false,
  isSearchBuilderModalOpen: false,
  searchBuilderModalType: null,
  dataSourceType: TEMPLATE_DATA_SOURCE_TYPES.primarySearch,
  primarySearch: null,
  secondarySearches: [],
  dataSources: [],
  selectedDataSourceIds: [],
  keyMessages: [],
  keyMessageTitle: '',
  keyMessageSearch: '',
  editingExistingKeyMessageIndex: -1,
  tag: null,
  generateDashboardSuccess: false,
  generatingDashboard: false,
  dashboardId: null,
  templateId: null,
};

const dashboardCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDE_TRAY:
      return {
        ...state,
        sideTrayOpen: !state.sideTrayOpen,
        dataSourceType: action.payload.dataSourceType,
        selectedDataSourceIds: action.payload.selectedIds,
        dataSources: action.payload.dataSources,
      };
    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case UPDATE_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case SET_PRIMARY_SEARCH:
      return {
        ...state,
        primarySearch: state.dataSources[0],
      };
    case CLEAR_PRIMARY_SEARCH:
      return {
        ...state,
        primarySearch: null,
      };
    case SET_SECONDARY_SEARCHES:
      return {
        ...state,
        secondarySearches: state.dataSources.slice(),
      };
    case SET_TAG:
      return {
        ...state,
        tag: state.dataSources[0],
      };
    case CLEAR_DATA_SOURCES:
      return {
        ...state,
        dataSources: [],
        selectedDataSourceIds: [],
      };
    case UPDATE_DATA_SOURCES:
      return {
        ...state,
        dataSources: action.payload.dataSources,
        selectedDataSourceIds: action.payload.ids,
      };
    case REMOVE_DATA_SOURCE:
      return {
        ...state,
        dataSources: state.dataSources.filter(
          ({ sourceId }) => sourceId !== action.payload,
        ),
        selectedDataSourceIds: state.selectedDataSourceIds.filter(
          id => id !== action.payload,
        ),
      };
    case UPDATE_KEY_MESSAGES:
      return {
        ...state,
        keyMessages: action.payload,
      };
    case UPDATE_KEY_MESSAGE_TITLE:
      return {
        ...state,
        keyMessageTitle: action.payload,
      };
    case UPDATE_KEY_MESSAGE_BOOLEAN:
      return {
        ...state,
        keyMessageSearch: action.payload,
      };
    case UPDATE_EXISTING_KEY_MESSAGE:
      return {
        ...state,
        editingExistingKeyMessageIndex: action.payload,
      };
    case CLEAR_KEY_MESSAGE_EDITING:
      return {
        ...state,
        editingExistingKeyMessageIndex: -1,
        keyMessageTitle: '',
        keyMessageSearch: '',
      };
    case UPDATE_DASHBOARD_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case UPDATE_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload,
      };
    case UPDATE_DATE_RANGE:
      return {
        ...state,
        dateRangeType: action.payload.dateRangeType,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case GENERATE_DASHBOARD:
      return {
        ...state,
        generatingDashboard: true,
      };
    case GENERATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        generateDashboardSuccess: true,
        generatingDashboard: false,
        dashboardId: action.payload,
        keyMessages: [],
      };
    case GENERATE_DASHBOARD_ERROR:
      return {
        ...state,
        generatingDashboard: false,
      };
    case CLEAR_FORM:
      return {
        ...initialState,
      };
    case TOGGLE_SEARCH_BUILDER_MODAL:
      return {
        ...state,
        isSearchBuilderModalOpen: action.payload.isOpen,
        searchBuilderModalType: action.payload.searchType,
      };
    default:
      return state;
  }
};

export const updateStepActionCreator = step => ({
  type: UPDATE_STEP,
  payload: step,
});

export const updateStepsActionCreator = steps => ({
  type: UPDATE_STEPS,
  payload: steps,
});

export const addDataSourcesActionCreator = (
  source,
  type,
  title,
  ids,
  multiple,
) => (dispatch, getState) => {
  const state = getState();
  const currentSources = state.dashboard.createForm.dataSources;

  let dataSources = ids.map(id => ({
    source: source,
    sourceType: type,
    sourceId: id,
    metadata: {
      sourceModel: {
        id,
        [DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS[type] || 'title']: title,
      },
      type,
    },
  }));

  if (multiple && currentSources.length) {
    dataSources = currentSources.concat(dataSources);
  }

  dispatch({
    type: UPDATE_DATA_SOURCES,
    payload: {
      dataSources,
      ids: dataSources.map(ds => ds.sourceId),
    },
  });
};

export const removeDataSourceActionCreator = id => dispatch => {
  dispatch({ type: REMOVE_DATA_SOURCE, payload: id });
};

export const toggleSideTrayActionCreator = dataSourceType => (
  dispatch,
  getState,
) => {
  const state = getState();
  let dataSources = [];

  if (dataSourceType === TEMPLATE_DATA_SOURCE_TYPES.primarySearch) {
    dataSources = [{ ...state.dashboard.createForm.primarySearch }];
  } else if (dataSourceType === TEMPLATE_DATA_SOURCE_TYPES.secondarySearches) {
    dataSources = state.dashboard.createForm.secondarySearches.slice();
  } else if (dataSourceType === TEMPLATE_DATA_SOURCE_TYPES.tag) {
    dataSources = [{ ...state.dashboard.createForm.tag }];
  }

  dispatch({
    type: TOGGLE_SIDE_TRAY,
    payload: {
      dataSourceType,
      selectedIds: dataSources.map(({ sourceId }) => sourceId),
      dataSources,
    },
  });
};

export const setSearchBuilderModalOpenActionCreator = (
  isOpen,
  searchType,
) => dispatch => {
  dispatch({
    type: TOGGLE_SEARCH_BUILDER_MODAL,
    payload: { isOpen, searchType },
  });
};

export const clearDataSourcesActionActionCreator = () => ({
  type: CLEAR_DATA_SOURCES,
});

export const clearPrimarySearchActionCreator = () => ({
  type: CLEAR_PRIMARY_SEARCH,
});

export const setPrimarySearchActionCreator = () => ({
  type: SET_PRIMARY_SEARCH,
});

export const setSecondarySearchesActionCreator = () => ({
  type: SET_SECONDARY_SEARCHES,
});

export const updateKeyMessagesActionCreator = keyMessages => ({
  type: UPDATE_KEY_MESSAGES,
  payload: keyMessages,
});

export const updateKeyMessageTitleActionCreator = title => ({
  type: UPDATE_KEY_MESSAGE_TITLE,
  payload: title,
});

export const updateKeyMessageBooleanActionCreator = value => ({
  type: UPDATE_KEY_MESSAGE_BOOLEAN,
  payload: value,
});

export const updateExistingKeyMessageActionCreator = existing => ({
  type: UPDATE_EXISTING_KEY_MESSAGE,
  payload: existing,
});

export const clearKeyMessagesEditingActionCreator = () => ({
  type: CLEAR_KEY_MESSAGE_EDITING,
});

export const updateDashboardTitleActionCreator = title => ({
  type: UPDATE_DASHBOARD_TITLE,
  payload: title,
});

export const updateTemplateIdActionCreator = id => ({
  type: UPDATE_TEMPLATE_ID,
  payload: id,
});

export const updateDateRangeActionCreator = dateRange => ({
  type: UPDATE_DATE_RANGE,
  payload: {
    dateRangeType: dateRange.type,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  },
});

export const setTagActionCreator = () => ({
  type: SET_TAG,
});

export const clearDashboardCreationForm = () => ({
  type: CLEAR_FORM,
});

export const generateDashboardFromTemplateActionCreator = successMessage => async (
  dispatch,
  getState,
) => {
  try {
    const {
      title,
      dateRangeType,
      keyMessages,
      startDate,
      endDate,
      tag,
      primarySearch,
      secondarySearches,
      templateId,
    } = getState().dashboard.createForm;
    dispatch({ type: GENERATE_DASHBOARD });
    const result = await performPost(
      `${NEW_DASHBOARD_ENDPOINT}/${templateId}/template`,
      {
        title,
        dateRangeType,
        startDate,
        endDate,
        primarySearch,
        secondarySearches,
        keyMessages: keyMessages.map(km => ({
          metadata: {
            type: DATA_SOURCE_TYPES.childSearch,
            sourceModel: { ...km },
          },
          source: DATA_SOURCES.trendkiteElasticSearch,
          sourceType: DATA_SOURCE_TYPES.childSearch,
        })),
        tag,
      },
    );
    dispatch({ type: GENERATE_DASHBOARD_SUCCESS, payload: result.data.id });
    dispatch(clearTranslatedKeyMessagesActionCreator());
    dispatch(
      addPageMessage({
        text: successMessage,
        status: 'success',
        ttl: 6000,
      }),
    );

    const hasDashboardNavigationImproved = userHasDevFeatureFlag(
      DEV_FEATURES.dashboardNavigationImproved,
    );

    if (hasDashboardNavigationImproved) {
      dispatch(setDashboardsListSideTrayOpen(false));
      dispatch(allAnalyticActionCreator());
      dispatch(ownedAnalyticsActionCreator());
    }
  } catch (e) {
    dispatch({ type: GENERATE_DASHBOARD_ERROR });
    dispatch(
      addPageMessage({
        text: e.response?.data?.error,
        status: 'danger',
        ttl: 6000,
      }),
    );
  }
};

export default dashboardCreateReducer;
