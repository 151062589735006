import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import FeatureMenu from 'components/FeatureMenu/FeatureMenu';
import {
  FEATURE_MENU_WHITELIST_VALIDATORS,
  USER_ROLES,
} from 'constants/constants';
import {
  hasAnyRoleSelector,
  impersonatorSelector,
  impersonatorHasAnyRoleSelector,
} from 'selectors/account';

const ConnectedFeatureMenu = () => {
  const canUserToggleFeatures = useSelector(hasAnyRoleSelector)([
    USER_ROLES.featureToggle,
  ]);
  const isImpersonating = useSelector(state => impersonatorSelector(state));
  const canImpersonatorToggleFeatures = useSelector(
    impersonatorHasAnyRoleSelector,
  )([USER_ROLES.featureToggle]);
  const canToggleFeatures =
    (!isImpersonating && canUserToggleFeatures) ||
    (isImpersonating && canImpersonatorToggleFeatures);
  const { pathname } = useLocation();
  if (!canToggleFeatures) {
    return null;
  }

  const routeShowsFeatureMenu = FEATURE_MENU_WHITELIST_VALIDATORS.some(
    routeValidator => routeValidator(`/#${pathname}`),
  );

  return routeShowsFeatureMenu ? <FeatureMenu /> : null;
};

export default ConnectedFeatureMenu;
