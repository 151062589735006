import { INFLUENCER_HUB_ENDPOINT } from 'constants/apis';
import { performGet } from 'services/rest-service/rest-service';

import { AUDIENCE_TYPE } from '../Search/constants';

const GET_REFERENCE_DATA_START = 'ihub/GET_REFERENCE_DATA_START';
const GET_REFERENCE_DATA_SUCCESS = 'ihub/GET_REFERENCE_DATA_SUCCESS';
const GET_REFERENCE_DATA_ERROR = 'ihub/GET_REFERENCE_DATA_ERROR';

const initialState = {
  loading: false,
  error: false,
  data: [],
  audienceTypeData: {},
};

const influencerReferenceTypeReducer = (
  state = { ...initialState },
  action,
) => {
  switch (action.type) {
    case GET_REFERENCE_DATA_START:
      return {
        ...state,
        loading: true,
        error: false,
        data: [],
      };

    case GET_REFERENCE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        audienceTypeData: action.audienceTypeData,
      };

    case GET_REFERENCE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        data: action.error,
      };

    default:
      return state;
  }
};

export const getReferenceDataActionDispatcher = () => async dispatch => {
  dispatch({ type: GET_REFERENCE_DATA_START });
  try {
    const response = await performGet(
      `${INFLUENCER_HUB_ENDPOINT}/reference?types=all`,
    );
    const payload = response && response.data.reference;
    let audienceTypeData = {};
    payload.map(res => {
      if (res.referenceType === 'audience-types') {
        audienceTypeData = Object.assign(
          ...res.data.map(ref => ({ [ref.id]: ref.description })),
        );
        res.data.map(resData => {
          switch (resData.id) {
            case 1:
              resData.description = AUDIENCE_TYPE.consumer;
              break;
            case 2:
              resData.description = AUDIENCE_TYPE.trade;
              break;
            case 3:
              resData.description = AUDIENCE_TYPE.technicalScientificAcademic;
              break;
          }
        });
      }
    });
    dispatch({ type: GET_REFERENCE_DATA_SUCCESS, payload, audienceTypeData });
  } catch (error) {
    dispatch({
      type: GET_REFERENCE_DATA_ERROR,
      error: { message: 'something went wrong' },
    });
  }
};

export default influencerReferenceTypeReducer;
