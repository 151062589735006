import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SvgIcon } from '@trendkite/ui';

class SlackIntegrationListEntry extends Component {
  onSelect = () => {
    const { entry, onSelect } = this.props;
    onSelect(entry);
  };

  render() {
    const { entry, selected } = this.props;
    const { icon, title, subtitle, svgIcon, isAction = false } = entry;
    const baseClass = 'tk-list-entry';
    const mainClass = classNames(`${baseClass} ${baseClass}--small`, {
      [`${baseClass}--selected`]: selected,
      [`${baseClass}--action`]: isAction,
    });

    return (
      <button
        data-qa="eZmW-YqFgQ8iPkeZLVYrG"
        className={mainClass}
        onClick={this.onSelect}
        type="button"
      >
        {icon && (
          <div className={`${baseClass}__icon`}>
            <i className={icon} />
          </div>
        )}
        {svgIcon && (
          <div className={`${baseClass}__icon`}>
            <SvgIcon icon={svgIcon} />
          </div>
        )}
        <div className={`${baseClass}__data`}>
          <div className={`${baseClass}__data-title`}>{title}</div>
          {subtitle && (
            <div className={`${baseClass}__data-subtitle`}>{subtitle}</div>
          )}
        </div>
      </button>
    );
  }
}

SlackIntegrationListEntry.defaultProps = {
  multiselect: false,
  onSelect: () => {},
  selected: false,
};

SlackIntegrationListEntry.propTypes = {
  entry: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    svgIcon: PropTypes.string,
    isAction: PropTypes.bool,
  }).isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default SlackIntegrationListEntry;
