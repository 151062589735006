import { createSelector } from 'reselect';

import translationSelector from 'pages/Translate/translation-selector';
import TranslationService from 'pages/Translate/translation-service';

const translationService = new TranslationService();

function getBrowserLanguage(languageRegions) {
  const browserLanguage = (
    window.navigator?.languages?.[0] ?? ''
  ).toLowerCase();
  if (languageRegions.find(l => l.languageTag === browserLanguage)) {
    return browserLanguage;
  } else if (browserLanguage === 'sv') {
    return 'sv-se';
  } else {
    return null;
  }
}

export const settingsSelector = state =>
  state.userSettings ? state.userSettings.settings : {};

export const firstNameSelector = createSelector(
  settingsSelector,
  settings => settings.firstName || window.activeUser.firstname,
);

export const settingsLanguageSelector = createSelector(
  settingsSelector,
  settings => settings.language,
);

const mapLanguagesToLanguageRegions = languages => {
  const regionsByLanguage = languages.map(language => {
    return language.regions.map(region => {
      const languageCode = language.code;
      const regionCode = region.code;
      return {
        id: region.regionId,
        langugeId: language.languageId,
        languageCode,
        languageName: language.name,
        regionName: region.name,
        regionCode,
        languageTag: translationService.getLanguageTag({
          languageCode,
          regionCode,
        }),
        isDefault: translationService.isDefaultUserLanguageRegion({
          languageCode,
          regionCode,
        }),
      };
    });
  });
  return [].concat(...regionsByLanguage);
};

export const languageRegionsSelector = state => {
  const { languages, languagesLoaded } = translationSelector(state);
  if (languagesLoaded) {
    const languagesRegions = mapLanguagesToLanguageRegions(languages);
    languagesRegions.sort(
      (a, b) =>
        a.languageName.localeCompare(b.languageName) ||
        a.regionName.localeCompare(b.regionName),
    );
    return languagesRegions;
  }
  return [];
};

export const activeLanguageSelector = state => {
  const initialLoadLanguage = state.account?.language;
  const currentSettingsLanguage = settingsLanguageSelector(state);
  const defaultLanguage = translationService.getDefaultLanguageTag();
  const browserLanguage = getBrowserLanguage(languageRegionsSelector(state));

  return (
    currentSettingsLanguage ||
    initialLoadLanguage ||
    browserLanguage ||
    defaultLanguage
  );
};

export const activeLanguageRegionSelector = state => {
  const activeLanguage = activeLanguageSelector(state);
  const languageRegions = languageRegionsSelector(state);
  return languageRegions.find(l => l.languageTag === activeLanguage);
};

export const languagesLoadingSelector = state => translationSelector(state);

export const userGroupsSelector = state => state.userSettings.userGroups || {};
