import React from 'react';
import PropTypes from 'prop-types';
import iconColorsMap from './colors';

const TwitterEmail = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    {...props}
  >
    <defs>
      <path
        id="share-by-email-a"
        d="M17.4250665,17.006959 L6.57493354,17.006959 C6.28568173,17.006959 6.05066464,16.7836002 6.05066464,16.5109416 L6.05066464,9.45602415 L11.1178883,12.6514631 C11.3858716,12.8214974 11.6932017,12.9060116 12.0005317,12.9060116 C12.3078618,12.9060116 12.6151918,12.8214974 12.8821117,12.6524692 L17.9503988,9.45602415 L17.9503988,16.5109416 C17.9503988,16.7836002 17.7143183,17.006959 17.4250665,17.006959 M6.57493354,6.993041 L17.4250665,6.993041 C17.7143183,6.993041 17.9503988,7.21639977 17.9503988,7.49006456 L17.9503988,8.29395489 L12.3153057,11.8485789 C12.1238891,11.9683072 11.8761109,11.9693133 11.6857577,11.8475727 L6.05066464,8.29395489 L6.05066464,7.49006456 C6.05066464,7.21639977 6.28568173,6.993041 6.57493354,6.993041 M17.4250665,6 L6.57493354,6 C5.70717812,6 5,6.66907018 5,7.49006456 L5,16.5109416 C5,17.3319359 5.70717812,18 6.57493354,18 L17.4250665,18 C18.2938853,18 19,17.3319359 19,16.5109416 L19,7.49006456 C19,6.66907018 18.2938853,6 17.4250665,6"
      />
    </defs>
    <use xlinkHref="#share-by-email-a" />
  </svg>
);
TwitterEmail.propTypes = { fill: PropTypes.string };
TwitterEmail.defaultProps = { fill: iconColorsMap.twitteremail };
export default TwitterEmail;
