import { createSelector } from 'reselect';

export const dashboardObj = state => state.dashboard || {};

export const deletedAnalyticsSelector = createSelector(
  dashboardObj,
  dashboard => (dashboard && dashboard.deleted) || {},
);

export const deletedAnalyticsListSelector = createSelector(
  deletedAnalyticsSelector,
  items => items.items || [],
);

export const deletedAnalyticsLoadingSelector = createSelector(
  deletedAnalyticsSelector,
  items => items.loading,
);

export const deletedAnalyticsFilteringSelector = createSelector(
  deletedAnalyticsSelector,
  dashboard => (dashboard && dashboard.listFilters?.filtering) || null,
);

export const deletedAnalyticsSearchSelector = createSelector(
  deletedAnalyticsSelector,
  dashboard => (dashboard && dashboard.listFilters?.search) || null,
);

export const deletedAnalyticsSidebarCountSelector = createSelector(
  deletedAnalyticsSelector,
  dashboard => (dashboard && dashboard.sidebarCount) || null,
);

export const deletedAnalyticsPaginationCountSelector = createSelector(
  deletedAnalyticsSelector,
  dashboard => (dashboard && dashboard.paginationCount) || null,
);

export const deletedAnalyticsForOwnerSelector = createSelector(
  deletedAnalyticsSelector,
  dashboard => (dashboard && dashboard.ownerFilterAnalytics) || null,
);

export const deletedAnalyticsResultsCollationSelector = createSelector(
  deletedAnalyticsSelector,
  dashboard => (dashboard && dashboard.resultsCollation) || null,
);
